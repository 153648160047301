import React, { useRef } from "react";
import Accordion from "react-bootstrap/Accordion";
import check from "../assets/images/check.png";
import dualcar from "../../src/assets/images/dualcar.webp";
import carbnr1 from "../../src/assets/images/carbnr1.webp";
import Sidebarinner from "../components/Sidebar-inner";
import phonecall from "../assets/images/phonecall.png";
import Footer from "../components/footer";
import { Helmet } from "react-helmet";

const ECarinsurance = () => {
  const sidebarRef = useRef(null);

  const handleScrollToSidebar = () => {
    if (sidebarRef.current) {
      sidebarRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };
  return (
    <>
    <Helmet>
        <meta charSet="utf-8" />
        <title>ECar Car Insurance Accident Claim Phone Number</title>
        <meta
          name="description"
          content=" Call the ECar Car Insurance Accident Claim Phone Number 0800 772 0850 for quick support and efficient claims handling of your no-fault accident claim in the UK.  "
        />
        <link
          rel="canonical"
          href="https://insurance-accident-claims.co.uk/ecar-insurance "
        />
      </Helmet>
      <section className="innernav">
        <div className="container">
          <div className="row">
            <div className="col-md-6 col-sm-6">
              <div className="left-logo">
                <h3>
                  Accident<span className="sipo"> Claims</span>
                </h3>
              </div>
            </div>
            <div className="col-md-6 col-sm-6">
              <div className="left-cta">
                <div class="headercnt">
                  <div class="chatmsg">
                    <img
                      src={phonecall}
                      className="phonecall"
                      alt="phonecall "
                    />
                  </div>
                  <div class="box-body">
                    <h3 class="nav-info-box-title">Toll Free Number </h3>
                    <p>
                      <a href="tel:0800 772 0850">0800 772 0850</a>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section
        className="newbnr"
        style={{ backgroundImage: "url(" + carbnr1 + ")" }}
      >
        <div className="container">
          <div className="row">
            <div className="col-xxl-8 col-xl-8 col-md-10 col-12">
              <div className="innerbnr">
                <h1>
                  <span className="onespan"> Car accident </span>{" "}
                  <span className="sci"> insurance claims  </span> management experts
                </h1>
                <p className="brokerage">
                  In the event of car accidents, we will handle everything from
                  vehicle recovery to replacement cars and claims assistance.
                </p>
                <div className="bnrbtn">
                  <a class="db-btn-border btn-rollover phone" href="tel:0800 772 0850">
                    Road Accident Claim Help?{" "}
                    <i class="fa-solid fa-arrow-right"></i>
                  </a>
                </div>
                <div className="bnrbtn">
                <a className="db-btn-border btn-rollover desktop"  onClick={handleScrollToSidebar}>
                    Road Accident Claim Help? <i className="fa-solid fa-arrow-right"></i>
                </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="stickysection">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="group-4-8">
                <div className="cl8 tf-tab">
                  <div className="content-tab">
                    <div className="inner-content">
                      <h2>
                        ECar insurance claims assistance without
                        <br />
                        <span className="light">
                          any financial burden{" "}
                        </span>{" "}
                      </h2>
                      <p className="disciplines">
                        If there is a time you have been involved in a road
                        traffic accident then call the{" "}
                        <strong> ECar accident claims number </strong> as we
                        will offer immediate assistance to you, but without any
                        hidden charges. We aim to provide{" "}
                        <strong> ECar insurance accident support </strong>{" "}
                        services that not only are professional and friendly but
                        also reduce the stress and hassle associated with your{" "}
                        <strong> ECar accident claim. </strong>
                      </p>
                      <hr />
                      <p className="bold-text">No additional costs</p>
                      <p className="bottom-te">
                        We do not need any upfront or excess fees. By calling us
                        on the <strong> ECar claims line </strong> for accident
                        management solutions, there is no need to spend
                        thousands of pounds before hitting the road again.{" "}
                      </p>
                      <hr />
                      <p className="bold-text">
                        Protecting your no-claims bonus
                      </p>
                      <p className="bottom-te">
                        We understand the importance of keeping your hard-earned
                        discounts intact. Therefore, our best efforts are made
                        towards managing the process of filing a car insurance
                        accident claim effectively t so that it doesn’t have a
                        negative impact on your No-claims bonus whatsoever.
                      </p>
                      <hr />
                      <p className="bold-text">Trouble-free repairs</p>
                      <p className="bottom-te">
                        In the UK, we have a dependable web of authorised
                        repairers famed for their specialisation in high-quality
                        repairs. When you call us on the{" "}
                        <strong>ECar claims contact number, </strong>
                        we will organise your car repairs through our trusted
                        repair partners, who use genuine parts sourced from
                        manufacturers.{" "}
                      </p>
                      <hr />
                      <p className="bold-text">
                        Zero-priced replacement vehicle{" "}
                      </p>
                      <p className="bottom-te">
                        Tell us what you want by dialling the{" "}
                        <strong>ECar car insurance claim number,</strong>
                        and we will provide you with an equivalent replacement
                        vehicle to enable you to continue your daily activities
                        without interruptions.{" "}
                      </p>
                      <hr />
                      <p className="bold-text">All-round claim handling</p>
                      <p className="bottom-te">
                        Our team of professionals is dedicated to managing all
                        aspects of{" "}
                        <strong> ECar insurance accident claims, </strong>such
                        as uninsured losses and claims management, thereby
                        relieving you from the hassles associated with these
                        issues.
                      </p>
                      <hr />
                      <p className="bold-text">Repair monitoring and updates</p>
                      <p className="bottom-te">
                        Starting from scratch until the end, we shall oversee
                        the whole process of repairing, hence informing you
                        about how far vehicle repairs have gone.
                      </p>
                      <hr />
                      <p className="bold-text">Tailored support</p>
                      <p className="bottom-te">
                        You will be allocated a dedicated claims handler when
                        you reach us at the{" "}
                        <strong> ECar car accident helpline, </strong>who
                        remains your one point of contact throughout the entire
                        process of the{" "}
                        <strong>ECar insurance accident claim.</strong>
                      </p>
                      <hr />
                      <p className="bold-text">Safe vehicle storage</p>
                      <p className="bottom-te">
                        When you have a no-fault car accident, we will store
                        your damaged vehicle in our safe storage yards to
                        safeguard it from further damage or theft.{" "}
                      </p>
                      <hr />
                      <p className="bold-text">Expert assistance</p>
                      <p className="bottom-te">
                        We have a team of highly trained experts and solicitors
                        who have been deeply engaged in{" "}
                        <strong> ECar insurance accident claims </strong>
                        management. You can trust us to handle your claim
                        diligently.{" "}
                      </p>
                      <hr />

                      <p className="bold-text">Quick claim processing</p>
                      <p className="bottom-te">
                        Our claim processes are simplified such that your claim
                        is processed as fast as possible without unnecessary
                        delays.{" "}
                      </p>
                    </div>
                  </div>
                </div>
                <div className="cl4" ref={sidebarRef}>
                  <Sidebarinner />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="photographs">
        <div className="container">
          <div className="row">
            <h2 className="road">
              We’ll provide the best possible support for{" "}
              <span className="light">
                you <br />
                to get back on track
              </span>{" "}
            </h2>
            <div className="col-xxl-6 col-xl-6 col-md-12 col-12">
              <div className="fourbox">
                <ul>
                  <li>
                    <img src={check} className="check" alt="" />
                    In case of a non-fault road traffic accident, ring us on our{" "}
                    <strong>
                      {" "}
                      car accident helpline{" "}
                      <a href="tel:0800 772 0850">0800 772 0850</a>{" "}
                    </strong>{" "}
                    and we shall instantly give you all the necessary assistance
                    to set you right on course.
                  </li>
                  <hr />
                  <li>
                    <img src={check} className="check" alt="" />
                    After evaluating your{" "}
                    <strong> ECar insurance accident claim, </strong>our panel
                    of experienced solicitors will take you through the options
                    available for pursuing your rights.
                  </li>
                  <hr />
                  <li>
                    <img src={check} className="check" alt="" />
                    So that your daily routines continue undisturbed, we offer a
                    substitute vehicle to use at no cost.
                  </li>
                  <hr />
                  <li>
                    <img src={check} className="check" alt="" />
                    From negotiating accidental repairs with insurers to
                    recovering uninsured losses and corresponding with other
                    parties concerned, our committed team will handle every
                    aspect of your claim.
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-xxl-6 col-xl-6 col-md-12 col-12">
              <div className="fourbox">
                <ul>
                  <li>
                    <img src={check} className="check" alt="" />
                    When you call us on the{" "}
                    <strong> ECar claims contact number, </strong>we will assess
                    the extent of your vehicle’s damage and arrange to have it
                    repaired by a trusted repairer.
                  </li>
                  <hr />
                  <li>
                    <img src={check} className="check" alt="" />
                    We will introduce you to a dedicated UK claims specialist
                    who can help you recover the highest compensation possible
                    for all your losses.
                  </li>
                  <hr />
                  <li>
                    <img src={check} className="check" alt="" />
                    Following a no-fault road accident, we will ensure that your
                    rights are protected and that every party treats you fairly.
                  </li>
                  <hr />
                  <li>
                    <img src={check} className="check" alt="" />
                    Once your vehicle repairs are done, we will agree with you
                    when you would like to collect it from our premises.
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="carprocess">
        <div className="container ">
          <div className="row">
            <div className="col-xxl-12 col-xxl-12 col-md-12 col-12">
              <h2 className="trusted-title">
                {" "}
                Our broad array of <br />
                <span className="light">services</span>{" "}
              </h2>
            </div>
          </div>
          <div className="row" id="possible">
            <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-12">
              <div className="claim">
                <ul>
                  <li>
                    <span className="sleej">Vehicle recovery:</span>We
                    understand the agony and frustrations that haunt you after a
                    no-fault car accident. Therefore, we have employed qualified
                    professionals to recover your vehicle and keep it safe in
                    our storage yards.
                  </li>
                  <li>
                    <span className="sleej">Vehicle repairs:</span>Through our
                    great network of approved repairers who conduct high-quality
                    work within short periods, we provide top-notch repairs for
                    your damaged car.
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-12">
              <div className="claim" id="blue">
                <ul>
                  <li>
                    <span className="sleej">Vehicle storage:</span> If you are
                    unfortunate to be involved in an accident, which is not your
                    fault, you will not have to worry about the state of your
                    car. We will put it in our secure yards and keep it there so
                    that no other person can access it. You may reach us
                    directly through the{" "}
                    <strong> ECar accident claims helpline </strong>if you would
                    like to keep your car vehicle safe until your claim
                    assessment with no extra charges.
                  </li>
                  <li>
                    <span className="sleej">Replacement car:</span>While we fix
                    your car, we shall also offer a substitute car that matches
                    yours so that you can continue with other important
                    activities without any barriers. Whatever kind of vehicle
                    you own, we will provide another one similar to it that will
                    better serve your daily needs. A free replacement vehicle is
                    just a phone call away at the{" "}
                    <strong> ECar accident claims number. </strong>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-12">
              <div className="claim">
                <ul>
                  <li>
                    <span className="sleej">Injury claims:</span>
                    You deserve compensation for your damages and injuries. Our
                    highly experienced team of claim solicitors specialise in
                    personal injury cases, and they will fully analyse your case
                    to ensure you receive adequate compensation for the damages
                    and injuries suffered. Just dial the{" "}
                    <strong> ECar claims contact number </strong>to get started
                    on making your claim.
                  </li>
                  <li>
                    <span className="sleej">Claim management:</span>Our From
                    filing paperwork to settling claims, we will handle every
                    intricacy involved in your{" "}
                    <strong> ECar insurance accidents claims. </strong>to get
                    started.
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="yellowcta">
        <div className="container">
          <div className="row">
            <div className="cta2">
              <div className="col-xxl-8 col-xl-8 col-lg-8 col-12">
                <div className="legal">
                  <h3 className="experienced">
                    Got more questions?{" "}
                    <span className="light">Talk to our specialists</span>{" "}
                  </h3>
                  <p className="professionals">
                    Get in touch with our no-fault claim experts via{" "}
                    <strong>
                      car insurance claims contact number{" "}
                      <a className="cpt-no" href="tel:0800 772 0850">
                        {" "}
                        0800 772 0850{" "}
                      </a>{" "}
                    </strong>
                    to get the best advice to pursue a successful{" "}
                    <strong> ECar accident claim. </strong>
                  </p>
                </div>
              </div>
              <div className="col-xxl-4 col-xl-4 col-lg-4 col-12">
              <div class="process">
                  <a class="tf-button style-1 phone" href="tel:0800 772 0850">
                    Get in touch<span class="icon-keyboard_arrow_right"></span>
                  </a>
                </div>
                <div class="process">
                    <a className="tf-button style-1 desktop"  onClick={handleScrollToSidebar}>
                        Get in touch<span className="icon-keyboard_arrow_right"></span>
                    </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <search className="updatedform">
        <div className="container">
          <div className="row text-center">
            <div className="col-xxl-12 col-xl-12 col-md-12 col-12">
              <h2 className="whiteheading">
                {" "}
                Why should you choose us to handle your <br />
                <span className="light">accident management? </span>
              </h2>
              <p className="sustained">
                We are experts in accident management who provide the best
                possible help after a non-fault car accident. When you dial the{" "}
                <strong> ECar accident number, </strong>we will take away from
                your stress and concerns by offering our free{" "}
                <strong> ECar insurance accident support </strong> services.
              </p>
            </div>
          </div>
          <div className="row">
            <div className="col-xxl-4 col-xl-4 col-lg-6 col-md-12 col-12">
              <div className="Claimant">
                <h5> Immediate assistance </h5>
                <p className="collision">
                  Get on the phone with us via the{" "}
                  <strong> ECar car accident helpline </strong>to ensure
                  emergency solutions to get back on route. When you call, we
                  shall send immediate help to the crash scene.
                </p>

                <h5>Monitoring of repairs</h5>
                <p className="collision">
                  We will be constantly monitoring your vehicle repairs and
                  giving you information about the progress of your vehicle
                  repair step by step.{" "}
                </p>

                <h5>Collection of vehicles in an efficient manner</h5>
                <p className="collision">
                  Your vehicle will be collected once its repairs are done. Call
                  us through, the <strong> ECar claims contact number </strong>{" "}
                  to set up a suitable date and place for picking up your car.
                </p>
              </div>
            </div>
            <div className="col-xxl-4 col-xl-4 col-lg-6 col-md-12 col-12">
              <div className="Claimant">
                <h5>Quality repairs guaranteed</h5>
                <p className="collision">
                  After calling us at the{" "}
                  <strong> ECar car insurance claim number, </strong>we would
                  see to it that all repairs on your vehicle meet the
                  manufacturer’s standards, preserving originality as well as a
                  warranty of the vehicle.
                </p>
                <h5> Personalised support and guidance </h5>
                <p className="collision">
                  When filing an{" "}
                  <strong> ECar insurance accident claim </strong>with us, we
                  will give you a dedicated claims handler who will be your
                  point of contact throughout and keep you updated at every
                  stage.
                </p>

                <h5>Your own choice of repairer</h5>
                <p className="collision">
                  We provide you with the flexibility of choosing your own
                  repairer to guarantee your car is fixed to your satisfaction.{" "}
                </p>
              </div>
            </div>
            <div className="col-xxl-4 col-xl-4 col-lg-12 col-md-12 col-12">
              <div className="Claimant">
                <h5>Experienced solicitors</h5>
                <p className="collision">
                  We have an extensive network of experienced, professional
                  solicitors who work as fast as possible to handle all aspects
                  of your <strong> ECar insurance accident claim. </strong>
                </p>

                <h5> 24/7 support </h5>
                <p className="collision">
                  Car accidents occur at any time. It means that regardless of
                  the hour we are always there for our clients in case they
                  require emergency assistance.
                </p>

                <h5>Expedited claims settlement</h5>
                <p className="collision">
                  We are here to get the job done without protracted paperwork
                  or follow-up phone calls after the{" "}
                  <strong> ECar insurance accident claim </strong>has been
                  filed.
                </p>
              </div>
            </div>
          </div>
        </div>
      </search>

      <section className="Accident-claim">
        <div className="container">
          <div className="row">
            <div className="needed">
              <h2 className="trusted-title">
                How can you maximise your Ecar <br />
                <span className="light"> insurance accident claim?</span>
              </h2>
              <p className="complexities">
                Getting through accident claims can be a stressful process.
                However, taking some basic steps after an accident can help you
                obtain fair compensation for injuries incurred.{" "}
              </p>
            </div>
            <hr />
          </div>
          <div className="row" id="incdes">
            <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-12">
              <div className="Preparing">
                <ul>
                  <li>
                    <div className="seemingly">
                      <h5 className="Seek">
                        Check for injuries and keep safe:
                      </h5>
                      <p>
                        {" "}
                        First things first, right after an accident that is no
                        fault at all, one should go to a place that will be safe
                        from traffic movement and then proceed to check for
                        injuries on themselves and other passengers.{" "}
                      </p>
                    </div>
                  </li>
                  <li>
                    <div className="seemingly">
                      <h5 className="Seek">Gather evidence: </h5>
                      <p>
                        When an accident occurs, it is important to gather some
                        important forms of information such as photographs of
                        the damaged vehicles, accident scenes and injured
                        victims. Secondly, you can collect contacts of witnesses
                        who viewed the scene in addition to those drivers
                        involved in the same incident.
                      </p>
                    </div>
                  </li>
                  <li>
                    <div className="seemingly">
                      <h5 className="Seek">Get medical help:</h5>
                      <p>
                        Minor or not, you should always seek medical attention
                        when you are hurt and document whatever happens
                        afterwards so that your case is watertight.
                      </p>
                    </div>
                  </li>
                  <li>
                    <div className="seemingly">
                      <h5 className="Seek">
                        Notify authorities about vehicle collision
                      </h5>
                      <p>
                        Notify the police about the car crash and ask them for
                        police report copies. For immediate assistance or
                        roadside emergency support call us via{" "}
                        <strong> ECar car accident helpline. </strong>
                      </p>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
            <div
              className="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-12"
              id="dualcardiv"
            >
              <img src={dualcar} alt="" className="dualcar" />
            </div>
            <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-12">
              <div className="Preparing">
                <ul>
                  <li>
                    <div className="seemingly">
                      <h5 className="Seek">Keep detailed records:</h5>
                      <p>
                        Keep proper details which show that there were expenses
                        incurred thereafter like medical bills plus
                        transportation charges, legal costs, lost income or any
                        other out-of-the-pocket expense.{" "}
                      </p>
                    </div>
                  </li>
                  <li>
                    <div className="seemingly">
                      <h5 className="Seek">
                        {" "}
                        Be aware of your rights and options{" "}
                      </h5>
                      <p>
                        Get in touch with us on the{" "}
                        <strong> Ecar accident claim phone number </strong>to
                        know the rights you possess as well as the types of
                        damages that are compensable.
                      </p>
                    </div>
                  </li>
                  <li>
                    <div className="seemingly">
                      <h5 className="Seek">
                        Do not say yes to an initial offer:
                      </h5>
                      <p>
                        An insurer can give you an initial sum so that they can
                        save money. Refrain from taking such a settlement from
                        the insurance company as it may eliminate your right to
                        further seek compensation for any loss or injury
                        incurred as a result of an accident.{" "}
                      </p>
                    </div>
                  </li>
                  <li>
                    <div className="seemingly">
                      <h5 className="Seek">Begin your claim now </h5>
                      <p>
                        Contact our experienced solicitors for{" "}
                        <strong> ECar insurance accident claims </strong> today.
                        Our no-win-no-fee solicitors will help you get what is
                        rightfully yours when it comes to your losses.
                      </p>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="whyus">
        <div className="container">
          <div className="row">
            <div className="col-xxl-12 col-md-12">
              <div className="why">
                <h2 className="choose">
                  Streamlined and simplified ECar insurance accident{" "}
                  <span className="light">claims process</span>
                </h2>
                <p className="complexities">
                  Making <strong> ECar insurance accident claims </strong>could
                  never be easier with us. With our fast, simple and plain
                  sailing process on claims, you will be able to start off your
                  no-fault claim easily and quickly too. Call us at{" "}
                  <a href="tel:0800 772 0850">0800 772 0850</a> and let our team
                  guide you through your claim.
                </p>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-xxl-3 col-xl-6 col-lg-6 col-md-6 col-12">
              <div className="box-service">
                <img src={check} className="check" alt="" />
                {/* <img src={experience} className="experience" alt="" /> */}
                <h4>Get in touch with us</h4>
                <p>
                  Call the <strong> ECar accident number </strong>immediately if
                  involved in a non-fault accident.
                </p>
              </div>
            </div>
            <div className="col-xxl-3 col-xl-6 col-lg-6 col-md-6 col-12">
              <div className="box-service">
                <img src={check} className="check" alt="" />
                <h4> Provide details </h4>
                <p>
                  Tell us about the accident, including the date, time, and
                  place and give a detailed description of how it happened.
                </p>
              </div>
            </div>
            <div className="col-xxl-3 col-xl-6 col-lg-6 col-md-6 col-12">
              <div className="box-service">
                <img src={check} className="check" alt="" />
                <h4> Submit evidence </h4>
                <p>
                  If you have any key evidence related to the accident such as
                  the photos of where it occurred, eyewitness statements and
                  CCTV videos among others, present them to us as part of your
                  claim initiation.{" "}
                </p>
              </div>
            </div>
            <div className="col-xxl-3 col-xl-6 col-lg-6 col-md-6 col-12">
              <div className="box-service">
                <img src={check} className="check" alt="" />
                <h4>Review and resolution </h4>
                <p>
                  Our team will review your claim once we have received all the
                  needed information along with the necessary documents and will
                  work their best to resolve this matter in a timely manner.{" "}
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="faqs">
        <div className="container">
          <div className="row">
            <div className="col-xxl-12 col-xl-12 col-md-12 col-12">
              <div className="fq">
                <h2>
                  {" "}
                  Frequently Asked{" "}
                  <span className="light">Questions (FAQ)</span>
                </h2>
                <p className="complexities">
                  Got questions about{" "}
                  <strong> ECar insurance accident claims? </strong>We have them
                  answered here.
                </p>
              </div>
              <div className="allfaq">
                <Accordion defaultActiveKey="0">
                  <Accordion.Item eventKey="0">
                    <Accordion.Header>
                      What evidence do I need to provide for a successful ECar
                      insurance accident claim?
                    </Accordion.Header>
                    <Accordion.Body>
                      The more proof you can provide us with, the easier it will
                      be to prove your innocence and get an equitable amount of
                      compensation for you. Some things that may help us
                      strengthen your claim are pictures of the place where the
                      accident took place, contact addresses or phone numbers of
                      eye-witnesses and receipts from the expenses incurred by
                      you subsequently after the car crash.
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="1">
                    <Accordion.Header>
                      Who covers the costs of the ECar insurance accident
                      support services?
                    </Accordion.Header>
                    <Accordion.Body>
                      If you have been involved in a no-fault car crash, you are
                      entitled to{" "}
                      <strong> ECar insurance accident support </strong>
                      services. The cost of replacing the car and repairing it
                      will be claimed back from the at-fault party's insurer.
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="2">
                    <Accordion.Header>
                      Will I get a credit hire following a road crash?
                    </Accordion.Header>
                    <Accordion.Body>
                      If your vehicle is unroadworthy and you need a replacement
                      one, please contact us on our{" "}
                      <strong> ECar car accident helpline. </strong> We shall
                      give you another car as soon as yours is no longer usable.
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="3">
                    <Accordion.Header>
                      What benefits do I get by making an ECar insurance
                      accident claim?{" "}
                    </Accordion.Header>
                    <Accordion.Body>
                      There are many advantages that come with making a claim
                      through us such as:
                      <ul className="listfaq">
                        <li className="faqli">No charge</li>
                        <li className="faqli">
                          No payment of excess premiums to your insurers
                        </li>
                        <li className="faqli">
                          Retention of your no-claims discount
                        </li>
                        <li className="faqli">
                          Maintenance of your entitlements under the policy
                        </li>
                      </ul>
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="4">
                    <Accordion.Header>
                      Is it worthwhile seeking advice from an accident
                      management company?
                    </Accordion.Header>
                    <Accordion.Body>
                      Road traffic accidents are both terrifying and irritating.
                      Accident management companies provide ease in dealing with
                      insurers, unlike their insurers. Besides, making a claim
                      to any accident management company like ours would enable
                      you to recover maximum compensation for all types of
                      losses including personal injury among others.
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="5">
                    <Accordion.Header>
                      May I get my car repaired from the garage of my choice?{" "}
                    </Accordion.Header>
                    <Accordion.Body>
                      Yes, we can have your car fixed in your own repair shop if
                      you give us their details.{" "}
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="6">
                    <Accordion.Header>
                      What happens if my car is unrepairable?{" "}
                    </Accordion.Header>
                    <Accordion.Body>
                      We will offer you a similar vehicle for your damaged one
                      if your vehicle is a complete right off.
                    </Accordion.Body>
                  </Accordion.Item>
                </Accordion>
              </div>
            </div>
          </div>
        </div>
      </section>

      <Footer />
    </>
  );
};

export default ECarinsurance;
