import React, { useRef } from "react";
import Accordion from "react-bootstrap/Accordion";
import check from "../assets/images/check.png";
import dualcar from "../../src/assets/images/dualcar.webp";
import carbnr1 from "../../src/assets/images/carbnr1.webp";
import Sidebarinner from "../components/Sidebar-inner";
import phonecall from "../assets/images/phonecall.png";
import Footer from "../components/footer";
import { Helmet } from "react-helmet";

const Bewiser = () => {
  const sidebarRef = useRef(null);

  const handleScrollToSidebar = () => {
    if (sidebarRef.current) {
      sidebarRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };
  return (
    <>
        <Helmet>
        <meta charSet="utf-8" />
        <title>Be Wiser Car Insurance Accident Claim Phone Number:</title>
        <meta
          name="description"
          content="Get in touch with our professional team via Be Wiser Car Insurance Accident Claim Phone Number 0800 772 0850 for efficient and reliable no-fault claim assistance. "
        />
        <link
          rel="canonical"
          href="https://insurance-accident-claims.co.uk/bewiser"
        />
      </Helmet>
      <section className="innernav">
        <div className="container">
          <div className="row">
            <div className="col-md-6 col-sm-6">
              <div className="left-logo">
                <h3>
                  Accident<span className="sipo"> Claims</span>
                </h3>
              </div>
            </div>
            <div className="col-md-6 col-sm-6">
              <div className="left-cta">
                <div class="headercnt">
                  <div class="chatmsg">
                    <img
                      src={phonecall}
                      className="phonecall"
                      alt="phonecall "
                    />
                  </div>
                  <div class="box-body">
                    <h3 class="nav-info-box-title">Toll Free Number </h3>
                    <p>
                      <a href="tel:0800 772 0850">0800 772 0850</a>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section
        className="newbnr"
        style={{ backgroundImage: "url(" + carbnr1 + ")" }}
      >
        <div className="container">
          <div className="row">
            <div className="col-xxl-8 col-xl-8 col-md-10 col-12">
              <div className="innerbnr">
                <h1>
                  <span className="onespan">Expert car insurance claim </span>{" "}
                  <span className="sci"> management </span> assistance{" "}
                </h1>
                <p className="brokerage">
                  We’re here to make your life easier after a non-fault car
                  accident by giving emergency support and assistance.{" "}
                </p>
                <div className="bnrbtn">
                  <a class="db-btn-border btn-rollover phone" href="tel:0800 772 0850">
                    Road Accident Claim Help?{" "}
                    <i class="fa-solid fa-arrow-right"></i>
                  </a>
                </div>
                <div className="bnrbtn">
                <a className="db-btn-border btn-rollover desktop"  onClick={handleScrollToSidebar}>
                    Road Accident Claim Help? <i className="fa-solid fa-arrow-right"></i>
                </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="stickysection">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="group-4-8">
                <div className="cl8 tf-tab">
                  <div className="content-tab">
                    <div className="inner-content">
                      <h2>
                        No-fuss claims management <br />
                        <span className="light">with zero excess </span>{" "}
                      </h2>
                      <p className="disciplines">
                        In the UK, we focus on assisting those who have suffered
                        injury as a result of no-fault car accidents to get back
                        on the road through our complete accident management
                        assistance and claims support. Our team is highly
                        dedicated and our solicitors are professionals who will
                        not only save you but also handle your{" "}
                        <strong> Be Wiser car insurance claim </strong> right
                        from the beginning till the end. Seek expert advice for
                        your claim today without being charged anything extra by
                        dialling <a href="tel:0800 772 0850">0800 772 0850</a>{" "}
                      </p>
                      <hr />
                      <p className="bold-text">No Excess</p>
                      <p className="bottom-te">
                        The guilty party’s insurer will bear all costs involved
                        which implies that you will not be subjected to paying
                        any excess fees.{" "}
                      </p>
                      <hr />
                      <p className="bold-text">NCB status unchanged</p>
                      <p className="bottom-te">
                        Since this <strong> Be Wiser insurance claim </strong>{" "}
                        does not go against your own policy, you keep your
                        no-claims bonus and discounts untouched.
                      </p>
                      <hr />
                      <p className="bold-text">Unbeatable experience</p>
                      <p className="bottom-te">
                        Unlike insurers, we specialise solely in no-fault car
                        accident claims. This way, our solicitors work
                        tirelessly to get the best outcome for your case.{" "}
                      </p>
                      <hr />
                      <p className="bold-text">Comprehensive services</p>
                      <p className="bottom-te">
                        We’ve got you covered every step of the way. We’ll help
                        you with your Be Wiser insurance claim complexities and
                        provide emergency assistance so that you can get back on
                        the road as fast as possible.
                      </p>
                      <hr />
                      <p className="bold-text">No-win-no fee services </p>
                      <p className="bottom-te">
                        If your <strong> Be Wiser car insurance claim </strong>{" "}
                        is unsuccessful, you’re not obliged to pay any legal
                        fees.
                      </p>
                      <hr />
                      <p className="bold-text">Friendly and supportive team</p>
                      <p className="bottom-te">
                        When you call us on the{" "}
                        <strong> Be Wiser car insurance claim number </strong>Be
                        Wiser car insurance claim number{" "}
                        <strong> Be Wiser insurance claim. </strong>
                      </p>
                      <hr />
                      <p className="bold-text">Expert claim handler</p>
                      <p className="bottom-te">
                        We will appoint a dedicated claims handler who will put
                        in their best efforts to deliver you an exceptional
                        claim solution and recover all the costs from the
                        negligent driver without any hassle.{" "}
                      </p>
                      <hr />
                      <p className="bold-text">Professional advice</p>
                      <p className="bottom-te">
                        If you want to make a{" "}
                        <strong>Be Wiser car insurance claim </strong>, we can
                        help you get free no-obligation advice from experienced
                        solicitors to help you know your rights and options.{" "}
                      </p>
                      <hr />
                      <p className="bold-text">
                        High-quality and guaranteed repairs
                      </p>
                      <p className="bottom-te">
                        Our vast network of repairers who are approved by the
                        manufacturer specialises in performing guaranteed
                        repairs with original parts and paint. Get in touch with
                        us to schedule high-quality repairs at the{" "}
                        <strong>Be Wiser insurance claim phone number. </strong>{" "}
                      </p>
                      <hr />

                      <p className="bold-text">
                        {" "}
                        A comparable replacement vehicle{" "}
                      </p>
                      <p className="bottom-te">
                        During your vehicle’s repair, we will provide you with a
                        like-for-like replacement car that is the same make,
                        model and size as yours.{" "}
                      </p>
                    </div>
                  </div>
                </div>
                <div className="cl4" ref={sidebarRef}>
                  <Sidebarinner />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="photographs">
        <div className="container">
          <div className="row">
            <h2 className="road">
              We’ll take care of all the complexities of{" "}
              <span className="light">
                your <br />
                claim as you recover{" "}
              </span>{" "}
            </h2>
            <div className="col-xxl-6 col-xl-6 col-md-12 col-12">
              <div className="fourbox">
                <ul>
                  <li>
                    <img src={check} className="check" alt="" />
                    From assessing damages to haggling with insurers and
                    processing your claim from initiation to settlement, we will
                    take care of everything.
                  </li>
                  <hr />
                  <li>
                    <img src={check} className="check" alt="" />
                    Upon contacting our dedicated team through a{" "}
                    <strong> Be Wiser insurance claim phone number </strong> ,
                    they will understand what you need and find a good
                    replacement car that will help you continue your journey
                    again.
                  </li>
                  <hr />
                  <li>
                    <img src={check} className="check" alt="" />
                    We shall book your vehicle repairs in the UK through one of
                    our manufacturer-approved repair centres.
                  </li>
                  <hr />
                  <li>
                    <img src={check} className="check" alt="" />
                    If necessary, we can talk to parties involved in the
                    incident which will enable you to concentrate on your
                    recovery.
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-xxl-6 col-xl-6 col-md-12 col-12">
              <div className="fourbox">
                <ul>
                  <li>
                    <img src={check} className="check" alt="" />
                    We are in partnership with a team of no-fault car accident
                    claims experts who are professional solicitors. When you
                    dial the{" "}
                    <strong> Be Wiser car insurance claim number </strong> , you
                    will get the right advice and guidance for your claim.
                  </li>
                  <hr />
                  <li>
                    <img src={check} className="check" alt="" />
                    We will appoint an independent engineer to inspect your
                    vehicle.
                  </li>
                  <hr />
                  <li>
                    <img src={check} className="check" alt="" />
                    Contact us through the{" "}
                    <strong> Be Wiser insurance claim number </strong> and, we
                    shall collect your damaged vehicle from the scene and secure
                    it safely in our storage yards.
                  </li>
                  <hr />
                  <li>
                    <img src={check} className="check" alt="" />
                    Our support is comprehensive for all costs you have incurred
                    from the other party’s insurer who was at fault.
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="carprocess">
        <div className="container ">
          <div className="row">
            <div className="col-xxl-12 col-xxl-12 col-md-12 col-12">
              <h2 className="trusted-title">
                {" "}
                We offer a wide range of solutions <br />
                <span className="light">
                  to tackle accident management{" "}
                </span>{" "}
              </h2>
            </div>
          </div>
          <div className="row" id="possible">
            <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-12">
              <div className="claim">
                <ul>
                  <li>
                    <span className="sleej">Vehicle recovery:</span> Is your car
                    undrivable because of a road accident? Contact us on the{" "}
                    <strong> Be Wiser insurance claim phone number </strong>for
                    fast recovery of your vehicle. Our recovery agents will pick
                    up your crashed vehicle from the accident site and take it
                    away to a safe place.
                  </li>
                  <li>
                    <span className="sleej">Approved repairs:</span>If you need
                    to fix your car instantly, call the{" "}
                    <strong> Be Wiser car insurance claim number. </strong> We
                    will get your vehicle repaired by an approved repairer in
                    the UK who will restore your car to its pre-accident state
                    while making it more valuable in the market.
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-12">
              <div className="claim" id="blue">
                <ul>
                  <li>
                    <span className="sleej">Safe storage :</span> If your
                    vehicle is not roadworthy, leaving it by the roadside or
                    outside your house may not be safe. We are here to help you
                    relieve stress by keeping your vehicle in our yards free
                    from damage and decay. Get in touch with us at{" "}
                    <a href="tel:0800 772 0850">0800 772 0850</a> for safe
                    storage of your vehicle in our well-maintained yards.
                  </li>
                  <li>
                    <span className="sleej">Replacement vehicle : </span> We
                    understand how difficult it can be to find yourself without
                    transportation. Our dedicated team will have you back on the
                    road as soon as possible by offering like-for-like
                    replacement vehicles delivered on the same day. You can hold
                    onto the replacement vehicle until your own car is repaired
                    or until your <strong>Be Wiser accident claim </strong>gets
                    settled. All expenses involving this substitute automobile
                    will be regained from the insurance company of the fault
                    party.
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-12">
              <div className="claim">
                <ul>
                  <li>
                    <span className="sleej">Personal injury support:</span>
                    Our solicitors will handle your{" "}
                    <strong> Be Wiser insurance claim </strong>to secure the
                    best result based on the extent of harm and damages you’ve
                    sustained.
                  </li>
                  <li>
                    <span className="sleej">Claims management:</span> To ensure
                    your claim gets settled in minimum time, we will take care
                    of everything, from initiation to documentation, and
                    settlement.
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="yellowcta">
        <div className="container">
          <div className="row">
            <div className="cta2">
              <div className="col-xxl-8 col-xl-8 col-lg-8 col-12">
                <div className="legal">
                  <h3 className="experienced">
                    Got more questions?{" "}
                    <span className="light">Talk to our specialists </span>{" "}
                  </h3>
                  <p className="professionals">
                    Get in touch with our no-fault claim experts via{" "}
                    <strong>
                      car insurance claim number{" "}
                      <a className="cpt-no" href="tel:0800 772 0850">
                        {" "}
                        0800 772 0850{" "}
                      </a>{" "}
                    </strong>{" "}
                    to get the best advice for your{" "}
                    <strong> Be Wiser car insurance claim. </strong>
                  </p>
                </div>
              </div>
              <div className="col-xxl-4 col-xl-4 col-lg-4 col-12">
              <div class="process">
                  <a class="tf-button style-1 phone" href="tel:0800 772 0850">
                    Get in touch<span class="icon-keyboard_arrow_right"></span>
                  </a>
                </div>
                <div class="process">
                    <a className="tf-button style-1 desktop"  onClick={handleScrollToSidebar}>
                        Get in touch<span className="icon-keyboard_arrow_right"></span>
                    </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <search className="updatedform">
        <div className="container">
          <div className="row text-center">
            <div className="col-xxl-12 col-xl-12 col-md-12 col-12">
              <h2 className="whiteheading">
                {" "}
                What makes us
                <br />
                <span className="light"> different? </span>
              </h2>
              <p className="sustained">
                In case you have been involved in a non-fault car accident, we
                are here to provide you with the finest solutions in terms of
                accident management as well as claims support. Whether you need
                vehicle recovery services or require a replacement vehicle or
                legal advice to know your rights and options, we have got your
                back.
              </p>
            </div>
          </div>
          <div className="row">
            <div className="col-xxl-4 col-xl-4 col-lg-6 col-md-12 col-12">
              <div className="Claimant">
                <h5>No cost services</h5>
                <p className="collision">
                  We don’t demand upfront payments for our services so that when
                  these accidents happen, we come over without stress.
                </p>

                <h5>Quick response</h5>
                <p className="collision">
                  We work round the clock to provide prompt assistance and
                  resolve your problems regarding accident management.{" "}
                </p>

                <h5>Same-day replacement car</h5>
                <p className="collision">
                  Using the{" "}
                  <strong> Be Wiser car insurance claim number </strong> , you
                  can call us for immediate help to make sure you get a
                  replacement vehicle without delay.
                </p>
              </div>
            </div>
            <div className="col-xxl-4 col-xl-4 col-lg-6 col-md-12 col-12">
              <div className="Claimant">
                <h5>Fast claim settlement</h5>
                <p className="collision">
                  We have a simplified and transparent process for settling
                  <strong> Be Wiser insurance claims </strong> which guarantees
                  a fast conclusion of your case.
                </p>

                <h5>24x7 recovery service </h5>
                <p className="collision">
                  Our recovery agents are always available to assist you pick up
                  your damaged car at any hour.
                </p>

                <h5>Free consultation without any obligation</h5>
                <p className="collision">
                  Contact us using the
                  <strong> Be Wiser insurance claim number </strong>for a free
                  and no-obligation review of your case to understand your
                  rights and options on how to proceed with it.{" "}
                </p>
              </div>
            </div>
            <div className="col-xxl-4 col-xl-4 col-lg-12 col-md-12 col-12">
              <div className="Claimant">
                <h5>Experienced solicitors </h5>
                <p className="collision">
                  We have well-experienced solicitors with extensive experience
                  in handling personal injury claims. They can give good advice
                  and support you throughout the process.
                </p>

                <h5>Personalised guidance</h5>
                <p className="collision">
                  A qualified solicitor will evaluate the specific circumstances
                  surrounding your{" "}
                  <strong> Be Wiser car insurance claim </strong> and give
                  tailored guidance regarding possible steps towards obtaining a
                  positive outcome from the claim.
                </p>
                <h5>Fast claims settlement</h5>
                <p className="collision">
                  Our smooth claim process allows us to prepare and initiate
                  your claim quickly and efficiently so that you can get maximum
                  compensation.
                </p>
              </div>
            </div>
          </div>
        </div>
      </search>

      <section className="Accident-claim">
        <div className="container">
          <div className="row">
            <div className="needed">
              <h2 className="trusted-title">
                How can you get maximum compensation for a <br />
                <span className="light"> Be Wiser insurance claim?</span>
              </h2>
              <p className="complexities">
                A road accident can disturb your life and create overwhelming
                worry. When you get compensation for all the losses and
                injuries, you will be able to recover from a car accident. But
                in order to increase the settlement value and maximise your
                compensation, there are certain steps that should be followed.{" "}
              </p>
            </div>
            <hr />
          </div>
          <div className="row" id="incdes">
            <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-12">
              <div className="Preparing">
                <ul>
                  <li>
                    <div className="seemingly">
                      <h5 className="Seek">Seek medical attention :</h5>
                      <p>
                        {" "}
                        Ensure that your injuries have been diagnosed correctly
                        and treated by getting medical attention quickly.
                        Additionally, it will establish an important record of
                        what has happened to you thereby strengthening your{" "}
                        <strong>Be Wiser insurance claim.</strong>{" "}
                      </p>
                    </div>
                  </li>
                  <li>
                    <div className="seemingly">
                      <h5 className="Seek"> Document damages: </h5>
                      <p>
                        All costs related to your claim such as medical bills,
                        prescription charges, transport expenses, legal fees or
                        any other costs which you may have incurred should be
                        recorded.{" "}
                      </p>
                    </div>
                  </li>
                  <li>
                    <div className="seemingly">
                      <h5 className="Seek">Collect evidence:</h5>
                      <p>
                        Take photos and videos of where the accident occurred,
                        including statements by those who saw it occurring as
                        well as access CCTV footage if available. You can also
                        try getting a copy of the police report so that it
                        strengthens your case.
                      </p>
                    </div>
                  </li>
                  <li>
                    <div className="seemingly">
                      <h5 className="Seek">
                        Don’t rush for a quick settlement{" "}
                      </h5>
                      <p>
                        Do not rush into accepting the first offer made for
                        settlement. Instead, discuss with the solicitor before
                        agreeing on whether or not you are being fairly
                        compensated for all losses sustained, including personal
                        injuries.
                      </p>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
            <div
              className="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-12"
              id="dualcardiv"
            >
              <img src={dualcar} alt="" className="dualcar" />
            </div>
            <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-12">
              <div className="Preparing">
                <ul>
                  <li>
                    <div className="seemingly">
                      <h5 className="Seek">No social media:</h5>
                      <p>
                        Avoid the use of social media until your{" "}
                        <strong> Be Wiser insurance claim </strong>is settled.
                        The other party can use these posts to reduce the
                        compensation.{" "}
                      </p>
                    </div>
                  </li>
                  <li>
                    <div className="seemingly">
                      <h5 className="Seek"> Exchange details</h5>
                      <p>
                        Share names, contacts, car details and insurance policy
                        details with other drivers involved in the accident.
                      </p>
                    </div>
                  </li>
                  <li>
                    <div className="seemingly">
                      <h5 className="Seek">Hire a no-fault claim solicitor:</h5>
                      <p>
                        On the{" "}
                        <strong>Be Wiser car insurance claim number</strong> ,
                        you can reach us for guidance and advice from an
                        experienced no-fault solicitor who will see to it that
                        your compensation is maximised.
                      </p>
                    </div>
                  </li>
                  <li>
                    <div className="seemingly">
                      <h5 className="Seek">Make a report of your claim</h5>
                      <p>
                        Call us on the{" "}
                        <strong> Be Wiser insurance claim number </strong> when
                        you have collected all evidence and important
                        information to start up your claims process.
                      </p>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="whyus">
        <div className="container">
          <div className="row">
            <div className="col-xxl-12 col-md-12">
              <div className="why">
                <h2 className="choose">
                  Our hassle-free and{" "}
                  <span className="light">smooth claims process</span>
                </h2>
                <p className="complexities">
                  Making a <strong> Be Wiser car insurance claim </strong>with
                  us means that you will never have to go through long queues or
                  complicated processes. With our simple but effective
                  procedure, it’s possible to make one’s claim in just four
                  steps.{" "}
                </p>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-xxl-3 col-xl-6 col-lg-6 col-md-6 col-12">
              <div className="box-service">
                <img src={check} className="check" alt="" />
                {/* <img src={experience} className="experience" alt="" /> */}
                <h4>Contact us</h4>
                <p>
                  Call and talk to our team on the insurance claim phone number{" "}
                  <a href="tel:0800 772 0850"> 0800 772 0850 </a> and arrange
                  for a free first meeting with an experienced attorney.
                </p>
              </div>
            </div>
            <div className="col-xxl-3 col-xl-6 col-lg-6 col-md-6 col-12">
              <div className="box-service">
                <img src={check} className="check" alt="" />
                <h4>Share accident details</h4>
                <p>
                  From the moment of the accident, its whereabouts up to how it
                  happened, make sure you tell us everything.
                </p>
              </div>
            </div>
            <div className="col-xxl-3 col-xl-6 col-lg-6 col-md-6 col-12">
              <div className="box-service">
                <img src={check} className="check" alt="" />
                <h4>Submit evidence </h4>
                <p>
                  Provide any vital information such as pictures, videos, police
                  report copy, eyewitness statements or anything else that can
                  make your <string> Be Wiser insurance claim </string> solid.
                </p>
              </div>
            </div>
            <div className="col-xxl-3 col-xl-6 col-lg-6 col-md-6 col-12">
              <div className="box-service">
                <img src={check} className="check" alt="" />
                <h4>Claim submission </h4>
                <p>
                  We will submit your{" "}
                  <string> Be Wiser car insurance claim </string> after getting
                  all supporting evidence and documentation ready and ensure a
                  quick resolution as soon as possible.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="faqs">
        <div className="container">
          <div className="row">
            <div className="col-xxl-12 col-xl-12 col-md-12 col-12">
              <div className="fq">
                <h2>
                  {" "}
                  Frequently Asked{" "}
                  <span className="light">Questions (FAQ)</span>
                </h2>
                <p className="complexities">
                  Got questions about the{" "}
                  <string> Be Wiser car insurance claim?</string> We have them
                  answered here.
                </p>
              </div>
              <div className="allfaq">
                <Accordion defaultActiveKey="0">
                  <Accordion.Item eventKey="0">
                    <Accordion.Header>
                      Do I need to pay any excess?
                    </Accordion.Header>
                    <Accordion.Body>
                      No excess has to be paid for our services because we shall
                      recover all costs from the other party’s insurer.
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="1">
                    <Accordion.Header>
                      Can I choose my own repairer?
                    </Accordion.Header>
                    <Accordion.Body>
                      Definitely! We allow you to select your preferred
                      repairer. If you prefer choosing your own repairer then we
                      can arrange your vehicle repairs from your chosen repair
                      shop.
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="2">
                    <Accordion.Header>
                      If I decide to use your services does this mean that I
                      will lose my current no-claims bonus?
                    </Accordion.Header>
                    <Accordion.Body>
                      No. Our experienced claims handlers will file a direct
                      claim against the third party, ensuring your no-claims
                      bonus and insurance premiums stay intact.
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="3">
                    <Accordion.Header>
                      How long can I keep the replacement vehicle?
                    </Accordion.Header>
                    <Accordion.Body>
                      You can keep the replacement vehicle until your car is
                      repaired if it has been damaged and isn’t drivable
                      anymore.
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="4">
                    <Accordion.Header>
                      Will I receive a similar replacement car?
                    </Accordion.Header>
                    <Accordion.Body>
                      We aim to give you a like-for-like replacement vehicle
                      depending on what your own car was. When you phone us
                      through the{" "}
                      <string> Be Wiser car insurance claim number </string>, we
                      will provide a suitable replacement vehicle.
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="5">
                    <Accordion.Header>
                      Are repairs guaranteed for vehicles?{" "}
                    </Accordion.Header>
                    <Accordion.Body>
                      Yes. The manufacturer-approved repairs we offer come with
                      a guarantee for as long as you own the car.
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="6">
                    <Accordion.Header>
                      Can you compensate me fully for all my accident losses?{" "}
                    </Accordion.Header>
                    <Accordion.Body>
                      Some of the expenses you can claim include medical bills,
                      transportation costs, rental cars, vehicle repairs, lost
                      wages, physical and emotional pain compensation, property
                      damage compensation or any other out-of-pocket fees
                      associated with this accident.
                    </Accordion.Body>
                  </Accordion.Item>
                </Accordion>
              </div>
            </div>
          </div>
        </div>
      </section>

      <Footer />
    </>
  );
};

export default Bewiser;
