import React from "react";
import insurancelogo from "../../src/assets/images/insurancelogo.png";
import thankuo from "../../src/assets/images/thankuo.png";
import phonecall from "../assets/images/phonecall.png";
import Footer from "../components/footer";
const Thankyou = () => {
  return (
    <>
      <title>Thank You</title>
      <link rel="canonical" href="https://insurance-accident-claims.co.uk/Thank-you " />
      <section className="innernav">
        <div className="container">
          <div className="row">
            <div className="col-md-6 col-sm-6">
              <div className="left-logo">
                {/* <img src={insurancelogo} className='insurancelogo' alt='Insurance Logo' /> */}
                <h3>Accident Claims</h3>
              </div>
            </div>
            <div className="col-md-6 col-sm-6">
              <div className="left-cta">
                <div class="headercnt">
                  <div class="chatmsg">
                    <img src={phonecall} className="phonecall" alt="phonecall " />
                  </div>
                  <div class="box-body">
                    <h3 class="nav-info-box-title">Toll Free Number </h3>
                    <p><a href="tel:0800 772 0850">0800 772 0850</a>
                    </p>
                  </div>
                </div>
              </div>


            </div>
          </div>
        </div>
      </section>
      <section className="Thankyou1">
        <div className="container">
          <div className="row">
            <div className="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-12">
              <div className="Thankouter">
                <h2 class="site-header__title" data-lead-id="site-header-title">Thank You!</h2>
                <div class="main-content">
                  <img src={thankuo} className="thankuo" alt="" />
                  <p class="main-content__body" data-lead-id="main-content-body">Thank you for taking out your valuable time to fill out our form. We’ve got your details, and we will be in touch shortly to discuss the next steps. Looking forward to serving you in the best possible way. </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>




      <Footer />


    </>
  );
};

export default Thankyou;
