import React, { useRef } from "react";
import insurancelogo from "../../src/assets/images/insurancelogo.png";
import Accordion from "react-bootstrap/Accordion";
import check from "../assets/images/check.png";
import dualcar from "../../src/assets/images/dualcar.webp";
import carbnr1 from "../../src/assets/images/carbnr1.webp";
import Sidebarinner from "../components/Sidebar-inner";
import phonecall from "../assets/images/phonecall.png";
import Footer from "../components/footer";
import { Helmet } from "react-helmet";

const Zurich = () => {
  const sidebarRef = useRef(null);

  const handleScrollToSidebar = () => {
    if (sidebarRef.current) {
      sidebarRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Zurich Insurance Claims Number | Zurich Claims Number</title>
        <meta
          name="description"
          content="Want to get your claim started? Get in touch with our team on Zurich Car Insurance Claims Contact Number 0800 772 0850 for prompt assistance and expert guidance."
        />
        <link
          rel="canonical"
          href="https://insurance-accident-claims.co.uk/zurich"
        />
      </Helmet>
      <section className="innernav">
        <div className="container">
          <div className="row">
            <div className="col-md-6 col-sm-6">
              <div className="left-logo">
                {/* <img src={insurancelogo} className='insurancelogo' alt='Insurance Logo' /> */}
                <h3>Accident Claims</h3>
              </div>
            </div>
            <div className="col-md-6 col-sm-6">
              <div className="left-cta">
                <div class="headercnt">
                  <div class="chatmsg">
                    <img
                      src={phonecall}
                      className="phonecall"
                      alt="phonecall "
                    />
                  </div>
                  <div class="box-body">
                    <h3 class="nav-info-box-title">Toll Free Number </h3>
                    <p>
                      <a href="tel:0800 772 0850">0800 772 0850</a>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section
        className="newbnr"
        style={{ backgroundImage: "url(" + carbnr1 + ")" }}
      >
        <div className="container">
          <div className="row">
            <div className="col-xxl-8 col-xl-8 col-md-10 col-12">
              <div className="innerbnr">
                <h1>
                  <span className="onespan">Accident And Claims </span>{" "}
                  <span className="sci">Management Experts</span> In The UK
                </h1>
                <p className="brokerage">
                  Have you been involved in a no-fault car accident? Speak to us
                  at the zurich motor claims number for emergency support and
                  claims assistance after the accident.
                </p>

                <div className="bnrbtn">
                  <a class="db-btn-border btn-rollover phone" href="tel:0800 772 0850">
                    Start your claim{" "}
                    <i class="fa-solid fa-arrow-right"></i>
                  </a>
                </div>
                <div className="bnrbtn">
                <a className="db-btn-border btn-rollover desktop"  onClick={handleScrollToSidebar}>
                  Start your claim <i className="fa-solid fa-arrow-right"></i>
                </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="stickysection">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="group-4-8">
                <div className="cl8 tf-tab">
                  <div className="content-tab">
                    <div className="inner-content">
                      <h2>
                        Zurich car claims management with
                        <span className="light"> zero deductible </span>
                      </h2>
                      <p className="disciplines">
                        We understand how stressful it can be to deal with the
                        aftermath of car accidents. With this in mind, we are
                        committed to providing end-to-end accident management
                        solutions without excess or upfront charges. Call our
                        team at the{" "}
                        <strong>
                          zurich car insurance claims contact number
                        </strong>{" "}
                        to get immediate support after a road accident.
                      </p>
                      <hr />
                      <p className="bold-text">No cost to you </p>
                      <p className="bottom-te">
                        Our accident management solutions ensure you get the
                        best support and assistance without any financial
                        burden. With one call at the{" "}
                        <strong>zurich car insurance accident helpline</strong>,
                        you can make a <strong>Zurich accident claim</strong>{" "}
                        without paying any excess.
                      </p>
                      <hr />
                      <p className="bold-text">No effect on NCB </p>
                      <p className="bottom-te">
                        When you choose us for making{" "}
                        <strong>zurich car claims</strong>, you not only get
                        access to comprehensive accident management solutions
                        but also get an opportunity to safeguard your no-claims
                        bonus. We will make sure that your no-claims bonus stays
                        intact and protected.
                      </p>
                      <hr />
                      <p className="bold-text">
                        Quality and guaranteed repairs{" "}
                      </p>
                      <p className="bottom-te">
                        We protect the future value of your car by arranging
                        your vehicle repairs from a trusted repairer, using
                        genuine and high-quality parts and materials.
                      </p>
                      <hr />
                      <p className="bold-text">Suitable courtesy car</p>
                      <p className="bottom-te">
                        We will supply you with a suitable replacement vehicle
                        that matches your needs and preferences right at your
                        doorstep to keep you mobile while your damaged car is
                        undergoing repairs.
                      </p>
                      <hr />
                      <p className="bold-text">Friendly and supportive team </p>
                      <p className="bottom-te">
                        Get in touch with our dedicated team at the{" "}
                        <strong>
                          motor claims contact number{" "}
                          <a href="tel:0800 772 0850">0800 772 0850</a>
                        </strong>{" "}
                        for emergency assistance after a road accident. Our
                        friendly and supportive team will remove the anxiety and
                        make your experience as smooth as possible.
                      </p>
                      <hr />
                      <p className="bold-text">24/7 assistance </p>
                      <p className="bottom-te">
                        Our team works fast and round the clock to get you back
                        on the road in the minimum possible time following a car
                        accident.
                      </p>
                      <hr />
                      <p className="bold-text">Full nationwide coverage </p>
                      <p className="bottom-te">
                        With a vast network of trusted partners and repair
                        centres across the UK, we’re able to provide timely
                        assistance when you need it the most. Contact us at the{" "}
                        <strong>
                          zurich car insurance claims contact number
                        </strong>
                        , and we will make sure that you’re never left stranded.
                      </p>
                      <hr />
                      <p className="bold-text">Continuous claims tracking </p>
                      <p className="bottom-te">
                        From the moment you file a{" "}
                        <strong>zurich accident claim</strong> with us, we will
                        continuously track and monitor your{" "}
                        <strong>zurich auto insurance claim</strong> to keep you
                        informed about the updates.
                      </p>
                      <hr />
                      <p className="bold-text">Expert claim advice </p>
                      <p className="bottom-te">
                        Our panel of solicitors have vast experience in handling{" "}
                        <strong>zurich car claims</strong>. When you call us at
                        the <strong>zurich motor claims contact number</strong>{" "}
                        to initiate your claim, our solicitors will provide the
                        best guidance to help you secure the best outcome.
                      </p>
                      <hr />
                      <p className="bold-text">Expedited claim settlement </p>
                      <p className="bottom-te">
                        We aim to settle your{" "}
                        <strong>zurich accident claim</strong> in minimum time
                        without any complex paperwork or lengthy follow-ups.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="cl4" ref={sidebarRef}>
                  <Sidebarinner />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="photographs">
        <div className="container">
          <div className="row">
            <h2 className="road">
              We’re here to take care of your accident <br />
              <span className="light">
                {" "}
                management needs from start to finish
              </span>{" "}
            </h2>
            <div className="col-xxl-6 col-xl-6 col-md-12 col-12">
              <div className="fourbox">
                <ul>
                  <li>
                    <img src={check} className="check" alt="" />
                    If you’re unfortunate to have been involved in a no-fault
                    car accident, contact us at the{" "}
                    <strong>zurich car insurance accident helpline</strong> to
                    make a successful claim.
                  </li>
                  <hr />
                  <li>
                    <img src={check} className="check" alt="" />
                    We will remove the stress, hassles and complexities involved
                    in the <strong>zurich auto insurance claims</strong> through
                    our expert guidance and continuous assistance.
                  </li>
                  <hr />
                  <li>
                    <img src={check} className="check" alt="" />
                    From dealing with the insurers to keeping you mobile by
                    providing a replacement car, we will manage everything for
                    you.
                  </li>
                  <hr />
                  <li>
                    <img src={check} className="check" alt="" />
                    We will arrange a reliable and trusted manufacturer-approved
                    repairer to repair your damaged vehicle to the highest
                    standards.
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-xxl-6 col-xl-6 col-md-12 col-12">
              <div className="fourbox">
                <ul>
                  <li>
                    <img src={check} className="check" alt="" />
                    Reach out to us at{" "}
                    <strong>
                      car insurance claims contact number{" "}
                      <a href="tel:0800 772 0850">0800 772 0850</a>
                    </strong>{" "}
                    to get a like-for-like replacement vehicle for the duration
                    of your claim.
                  </li>
                  <hr />
                  <li>
                    <img src={check} className="check" alt="" />
                    We will provide you with a dedicated claims handler who will
                    guide you throughout the claims process.
                  </li>
                  <hr />
                  <li>
                    <img src={check} className="check" alt="" />
                    We will stay in touch with your vehicle repairer and keep
                    you informed about the progress of your repairs.
                  </li>
                  <hr />
                  <li>
                    <img src={check} className="check" alt="" />
                    Once your vehicle repairs are complete, we will inspect your
                    vehicle and make arrangements for the delivery of your
                    repaired vehicle.
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="carprocess">
        <div className="container ">
          <div className="row">
            <div className="col-xxl-12 col-xxl-12 col-md-12 col-12">
              <h2 className="trusted-title">
                The comprehensive range of <br />
                <span className="light">services we offer </span>
              </h2>
            </div>
          </div>
          <div className="row" id="possible">
            <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-12">
              <div className="claim">
                <ul>
                  <li>
                    <span className="sleej">Vehicle recovery</span> <br />
                    Struggling to get an accident recovery after a no-fault car
                    accident? Contact us at the{" "}
                    <strong>zurich car insurance claims contact number</strong>.
                    We will immediately dispatch our recovery team to ensure
                    prompt and swift recovery of your vehicle.
                  </li>
                  <li>
                    <span className="sleej">Accidental repairs </span>
                    <br />
                    With just one call on the{" "}
                    <strong>zurich car insurance accident helpline</strong>, you
                    can save yourself from the stress of arranging your vehicle
                    repairs on your own. We will organise guaranteed repairs of
                    your damaged vehicle from our extensive network of trusted
                    repairers.
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-12">
              <div className="claim" id="blue">
                <ul>
                  <li>
                    <span className="sleej">Vehicle storage </span> <br />
                    If your vehicle is not in a drivable condition, we will make
                    arrangements to store your damaged vehicle in our safe and
                    secure storage yards. Our storage facilities allow for safe
                    storage of your damaged vehicle until further assessment,
                    protecting it from further damage and deterioration.
                  </li>
                  <li>
                    <span className="sleej">Replacement vehicle </span> <br />
                    We understand that being left without a vehicle can be
                    inconvenient and stressful. As a part of our accident
                    management services, we will provide you with a replacement
                    vehicle suited to your daily needs to get you back on the
                    road safely. We will recover the costs of the replacement
                    vehicle from the at-fault party’s insurer.
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-12">
              <div className="claim">
                <ul>
                  <li>
                    <span className="sleej">Injury claims </span>
                    <br />
                    Have you suffered injuries in a no-fault car accident? Speak
                    to our team via{" "}
                    <strong>zurich motor claims contact number</strong> to make
                    a successful personal injury claim. Our experienced
                    solicitors can help you get compensated for your injuries.
                  </li>
                  <li>
                    <span className="sleej">Claims management </span> <br />
                    With their vast experience and expertise, our solicitors
                    will manage your <strong>zurich car claims</strong> from
                    start to finish, leaving you stress-free.
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="yellowcta">
        <div className="container">
          <div className="row">
            <div className="cta2">
              <div className="col-xxl-8 col-xl-8 col-lg-8 col-12">
                <div className="legal">
                  <h3 className="experienced">
                    Got more questions?{" "}
                    <span className="light">Talk to our specialists </span>
                  </h3>
                  <p className="professionals">
                    Get in touch with our no-fault claim experts via{" "}
                    <strong>
                      car insurance claims contact number
                      <a href="tel:0800 772 0850">0800 772 0850</a>
                    </strong>{" "}
                    to get the best advice to pursue a successful{" "}
                    <strong>zurich accident claim.</strong>
                  </p>
                </div>
              </div>
              <div className="col-xxl-4 col-xl-4 col-lg-4 col-12">
              <div class="process">
                  <a class="tf-button style-1 phone" href="tel:0800 772 0850">
                    Get in touch<span class="icon-keyboard_arrow_right"></span>
                  </a>
                </div>
                <div class="process">
                    <a className="tf-button style-1 desktop"  onClick={handleScrollToSidebar}>
                        Get in touch<span className="icon-keyboard_arrow_right"></span>
                    </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <search className="updatedform">
        <div className="container">
          <div className="row text-center">
            <div className="col-xxl-12 col-xl-12 col-md-12 col-12">
              <h2 className="whiteheading">
                The advantages of choosing us
                <span className="light">
                  {" "}
                  for accident management assistance
                </span>
              </h2>
              <p className="sustained">
                As a leading provider of accident management assistance, we help
                reduce the complexity, stress and hassle by providing a vast
                spectrum of services. From vehicle recovery to claims
                management, we have got you covered.
              </p>
            </div>
          </div>
          <div className="row">
            <div className="col-xxl-4 col-xl-4 col-lg-6 col-md-12 col-12">
              <div className="Claimant">
                <h5>24/7 assistance </h5>
                <p className="collision">
                  Whether you want to arrange your vehicle repairs, need a
                  replacement vehicle or seek guidance on{" "}
                  <strong>zurich auto insurance claims</strong>, we’re available
                  round the clock to assist you.
                </p>
                <h5>No excess </h5>
                <p className="collision">
                  When you choose us for your accident management needs, you
                  don’t have to pay any excess or upfront fees.
                </p>
                <h5>Reliable partners </h5>
                <p className="collision">
                  From experienced solicitors to reputed repairers, we have a
                  trusted network of partners to provide high-quality services.
                </p>
              </div>
            </div>
            <div className="col-xxl-4 col-xl-4 col-lg-6 col-md-12 col-12">
              <div className="Claimant">
                <h5>Hassle-free vehicle replacement </h5>
                <p className="collision">
                  We will swiftly arrange a like-for-like replacement vehicle
                  that suits your needs to keep you mobile while your damaged
                  car is under repairs.
                </p>
                <h5>Extensive repair network </h5>
                <p className="collision">
                  We have a broad network of top-tier repairers in the UK
                  skilled in providing exceptional and guaranteed repairs that
                  exceed expectations.
                </p>
                <h5>Experienced panel of solicitors </h5>
                <p className="collision">
                  Our strong network of experienced solicitors have the
                  expertise to handle{" "}
                  <strong>zurich auto insurance claims</strong> as efficiently
                  as possible.
                </p>
              </div>
            </div>
            <div className="col-xxl-4 col-xl-4 col-lg-12 col-md-12 col-12">
              <div className="Claimant">
                <h5>Full-fledged insurance claim assistance </h5>
                <p className="collision">
                  From claim assessment to settlement, we provide continuous
                  support throughout the process.
                </p>
                <h5>Manufacturer-approved repairs </h5>
                <p className="collision">
                  Our repair partners are known for providing exceptional
                  standards of work and high-grade repairs using only genuine
                  parts.
                </p>
                <h5>Swift claim resolution </h5>
                <p className="collision">
                  Our streamlined claims management process allows us to prepare
                  and initiate your claim promptly and effectively to secure a
                  maximum amount of compensation for you.
                </p>
              </div>
            </div>
          </div>
        </div>
      </search>

      <section className="Accident-claim">
        <div className="container">
          <div className="row">
            <div className="needed">
              <h2 className="trusted-title">
                How can you maximise the compensation for the
                <br />
                <span className="light"> zurich accident claim?</span>
              </h2>
              <p className="complexities">
                Dealing with a no-fault car accident can be a stressful
                experience, and proving your innocence is even more challenging.
                Therefore, you need to know the right steps to prove your
                innocence and increase your chances of obtaining fair
                compensation.
              </p>
            </div>
            <hr />
          </div>
          <div className="row" id="incdes">
            <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-12">
              <div className="Preparing">
                <ul>
                  <li>
                    <div className="seemingly">
                      <h5 className="Seek">Call emergency services</h5>
                      <p>
                        Try to gather as much information as possible regarding
                        the accident and call us at the{" "}
                        <strong>zurich motor claims number</strong> to arrange
                        vehicle recovery for your damaged vehicle.
                      </p>
                    </div>
                  </li>
                  <li>
                    <div className="seemingly">
                      <h5 className="Seek">Report the accident </h5>
                      <p>
                        Contact the police to file a report that can be crucial
                        evidence to make your claim stronger.
                      </p>
                    </div>
                  </li>
                  <li>
                    <div className="seemingly">
                      <h5 className="Seek">Check injuries </h5>
                      <p>
                        After the accident, it’s important to prioritise your
                        safety. Check yourself or any passengers for major or
                        minor injuries.
                      </p>
                    </div>
                  </li>
                  <li>
                    <div className="seemingly">
                      <h5 className="Seek">Get medical attention</h5>
                      <p>
                        If your injury requires urgent attention, immediately
                        seek medical help to get appropriate treatment.
                      </p>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
            <div
              className="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-12"
              id="dualcardiv"
            >
              <img src={dualcar} alt="" className="dualcar" />
            </div>
            <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-12">
              <div className="Preparing">
                <ul>
                  <li>
                    <div className="seemingly">
                      <h5 className="Seek">Organise vehicle repairs </h5>
                      <p>
                        If you have your own repairer, we will contact them to
                        get repair estimates and arrange an appropriate date for
                        repairs. If you don’t have your preferred repairer, we
                        will suggest names from our network.
                      </p>
                    </div>
                  </li>
                  <li>
                    <div className="seemingly">
                      <h5 className="Seek">Gather information </h5>
                      <p>
                        If possible, collect evidence and other vital
                        information from the accident scene, including name,
                        address and contact information of all drivers, eye
                        witness statements or any other information that may
                        strengthen your claim.
                      </p>
                    </div>
                  </li>
                  <li>
                    <div className="seemingly">
                      <h5 className="Seek">Note the damages </h5>
                      <p>
                        Take photos and videos of the damage to your vehicle
                        before sending it for repairs. This will provide clear
                        evidence of the extent of the damage to your vehicle.
                      </p>
                    </div>
                  </li>
                  <li>
                    <div className="seemingly">
                      <h5 className="Seek">Contact a solicitor </h5>
                      <p>
                        If you wish to make a successful claim to secure
                        compensation for the damages and injuries, reach out to
                        an experienced solicitor who can guide you through the
                        process. Contact us at the{" "}
                        <strong>zurich motor claims contact number</strong> to
                        speak to our knowledgeable solicitors.
                      </p>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="whyus">
        <div className="container">
          <div className="row">
            <div className="col-xxl-12 col-md-12">
              <div className="why">
                <h2 className="choose">
                  Fast-paced and stress-free
                  <span className="light">
                    <br /> zurich car claims process
                  </span>
                </h2>
                <p className="complexities">
                  Want to make a <strong>zurich accident claim?</strong> The
                  quickest way to make a claim is to get in touch with us at the{" "}
                  <strong>zurich car insurance claims contact number</strong>,
                  and we will help you claim compensation for the injuries and
                  damages suffered due to the car accident.
                </p>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-xxl-3 col-xl-6 col-lg-6 col-md-6 col-12">
              <div className="box-service">
                <img src={check} className="check" alt="" />
                <h4>Contact our team</h4>
                <p>
                  Get in touch with our team via our{" "}
                  <strong>
                    zurich car insurance accident helpline on{" "}
                    <a href="tel:0800 772 0850">0800 772 0850</a>
                  </strong>{" "}
                  to get expert advice for your claim.
                </p>
              </div>
            </div>
            <div className="col-xxl-3 col-xl-6 col-lg-6 col-md-6 col-12">
              <div className="box-service">
                <img src={check} className="check" alt="" />
                <h4>Provide details </h4>
                <p>
                  Share essential details about the accident, including date,
                  time, location and the circumstances behind the collision.
                </p>
              </div>
            </div>
            <div className="col-xxl-3 col-xl-6 col-lg-6 col-md-6 col-12">
              <div className="box-service">
                <img src={check} className="check" alt="" />
                <h4>Share evidence </h4>
                <p>
                  Submit all vital evidence, such as photographs of the accident
                  scene, eyewitness statements, CCTV videos or any other crucial
                  information.
                </p>
              </div>
            </div>
            <div className="col-xxl-3 col-xl-6 col-lg-6 col-md-6 col-12">
              <div className="box-service">
                <img src={check} className="check" alt="" />
                <h4>Claim review and resolution </h4>
                <p>
                  Our team will thoroughly review your claim and work diligently
                  to resolve it in the shortest possible time.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="faqs">
        <div className="container">
          <div className="row">
            <div className="col-xxl-12 col-xl-12 col-md-12 col-12">
              <div className="fq">
                <h2>
                  {" "}
                  Frequently Asked{" "}
                  <span className="light">Questions (FAQ)</span>
                </h2>
                <p className="complexities">
                  Got questions about <strong>zurich car claims</strong>? We
                  have them answered here.{" "}
                </p>
              </div>
              <div className="allfaq">
                <Accordion defaultActiveKey="0">
                  <Accordion.Item eventKey="0">
                    <Accordion.Header>
                      Do I need to make a claim against my own insurance policy?
                    </Accordion.Header>
                    <Accordion.Body>
                      No. You can reach out to us via the{" "}
                      <strong>
                        zurich car insurance claims contact number
                      </strong>
                      , and we will advise you on the best course of action
                      without paying any policy excess.
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="1">
                    <Accordion.Header>
                      What type of vehicle will I receive as a replacement for
                      my own vehicle?
                    </Accordion.Header>
                    <Accordion.Body>
                      We will arrange a like-for-like replacement vehicle
                      similar to your own vehicle in terms of size and quality.
                      You can let us know your needs and preferences through a
                      call on the <strong>zurich motor claims number</strong>,
                      and we will provide you with a replacement vehicle that
                      suits your needs.
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="2">
                    <Accordion.Header>
                      Who pays for your accident management services?
                    </Accordion.Header>
                    <Accordion.Body>
                      We and our partners will recover all the costs related to
                      repairs, replacement vehicles or other expenses from the
                      at-fault party’s insurer.
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="3">
                    <Accordion.Header>
                      Can you arrange my vehicle repairs from the garage of my
                      own choice?
                    </Accordion.Header>
                    <Accordion.Body>
                      Yes. You can share the details of your preferred repairer
                      with us, and we will coordinate with them to ensure a
                      smooth repair process.
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="4">
                    <Accordion.Header>
                      Can you help if my vehicle is beyond repair?
                    </Accordion.Header>
                    <Accordion.Body>
                      Absolutely. If your vehicle has been written off, we will
                      provide you with a replacement car and negotiate with the
                      at-fault party to ensure you receive the best settlement
                      value.
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="5">
                    <Accordion.Header>
                      Will I lose my no-claims bonus if I make a claim?
                    </Accordion.Header>
                    <Accordion.Body>
                      No. When you make a <strong>zurich accident claim</strong>{" "}
                      with us, we will ensure that your no-claims bonus stays
                      intact.
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="6">
                    <Accordion.Header>
                      What should I do if the third-party insurers contact me
                      directly?
                    </Accordion.Header>
                    <Accordion.Body>
                      If the third-party insurers contact you directly, you can
                      refer them to us if you choose to make your claim with us.
                      We will deal with them and handle all the communications
                      on your behalf.
                    </Accordion.Body>
                  </Accordion.Item>
                </Accordion>
              </div>
            </div>
          </div>
        </div>
      </section>

      <Footer />
    </>
  );
};

export default Zurich;
