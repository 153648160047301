import React, { useRef } from "react";
import insurancelogo from "../../src/assets/images/insurancelogo.png";
import Accordion from "react-bootstrap/Accordion";
import check from "../assets/images/check.png";
import dualcar from "../../src/assets/images/dualcar.webp";
import liverpool from "../../src/assets/images/liverpool.webp";
import Sidebarinner from "../components/Sidebar-inner";
import phonecall from "../assets/images/phonecall.png";
import { Helmet } from "react-helmet";
import Footer from "../components/footer";

const LiverpoolVictoria = () => {
  const sidebarRef = useRef(null);

  const handleScrollToSidebar = () => {
    if (sidebarRef.current) {
      sidebarRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>LV Insurance Claims Number | LV Car Insurance Claims</title>
        <meta
          name="description"
          content="Need assistance in making a no-fault accident claim? Get in touch with our solicitors via the LV Insurance Accident Claims Contact Number 0800 772 0850 for expert guidance. "
        />
        <link
          rel="canonical"
          href="https://insurance-accident-claims.co.uk/lv-insurance-claims"
        />
      </Helmet>
      <section className="innernav">
        <div className="container">
          <div className="row">
            <div className="col-md-6 col-sm-6">
              <div className="left-logo">
                {/* <img src={insurancelogo} className='insurancelogo' alt='Insurance Logo' /> */}
                <h3>Accident Claims</h3>
              </div>
            </div>
            <div className="col-md-6 col-sm-6">
              <div className="left-cta">
                <div class="headercnt">
                  <div class="chatmsg">
                    <img
                      src={phonecall}
                      className="phonecall"
                      alt="phonecall "
                    />
                  </div>
                  <div class="box-body">
                    <h3 class="nav-info-box-title">Toll Free Number </h3>
                    <p>
                      <a href="tel:0800 772 0850">0800 772 0850</a>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section
        className="newbnr"
        style={{ backgroundImage: "url(" + liverpool + ")" }}
      >
        <div className="container">
          <div className="row">
            <div className="col-xxl-7 col-xl-7 col-md-10 col-12">
              <div className="innerbnr">
                <h1>
                  <span className="onespan">Dedicated Experts In </span>{" "}
                  <span className="sci">Accident insurance claims</span> Management{" "}
                </h1>
                <p className="brokerage">
                  When car accidents happen, we manage every aspect on your
                  behalf, from vehicle recovery to replacement car and claims
                  assistance.
                </p>
                <div className="bnrbtn">
                  <a class="db-btn-border btn-rollover phone" href="tel:0800 772 0850">
                    Begin your claim{" "}
                    <i class="fa-solid fa-arrow-right"></i>
                  </a>
                </div>
                <div className="bnrbtn">
                  <a className="db-btn-border btn-rollover desktop" onClick={handleScrollToSidebar}>
                    Begin your claim <i className="fa-solid fa-arrow-right"></i>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="stickysection">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="group-4-8">
                <div className="cl8 tf-tab">
                  <div className="content-tab">
                    <div className="inner-content">
                      <h2>
                        Liverpool Victoria insurance accident <br />
                        <span className="light">
                          support without upfront costs
                        </span>{" "}
                      </h2>
                      <p className="disciplines">
                        If you have had a car accident, please contact us at{" "}
                        <strong>
                          {" "}
                          accident claims number{" "}
                          <a href="tel:0800 772 0850">0800 772 0850</a>
                        </strong>
                        . We will offer you immediate assistance without any
                        hidden costs. We strive to provide you with Liverpool{" "}
                        <strong>Victoria insurance accident support</strong>{" "}
                        services that are not only professional and friendly but
                        also reduce the stress and hassles related to the{" "}
                        <strong>
                          Liverpool Victoria insurance accident claim
                        </strong>
                        .
                      </p>
                      <hr />
                      <p className="bold-text">Zero hidden fees </p>
                      <p className="bottom-te">
                        We offer{" "}
                        <strong>
                          Liverpool Victoria insurance accident support
                        </strong>{" "}
                        services without any upfront or excess fees. When you
                        get in touch with us on{" "}
                        <strong>Liverpool Victoria accident number</strong> for
                        accident management solutions, you don’t have to pay
                        thousands of pounds to get back on the road.
                      </p>
                      <hr />
                      <p className="bold-text">No impact on no-claims bonus</p>
                      <p className="bottom-te">
                        We understand the significance of protecting your
                        hard-earned discounts and benefits. Therefore, we put in
                        our best efforts to manage the{" "}
                        <strong>
                          Liverpool Victoria insurance accident claim
                        </strong>{" "}
                        process efficiently to avoid any adverse effect on your
                        no-claims bonus.{" "}
                      </p>
                      <hr />
                      <p className="bold-text">Hassle-free repairs</p>
                      <p className="bottom-te">
                        We have a reliable network of trusted repairers in the
                        UK who are known for their expertise in providing
                        high-quality repairs. When you contact us on the{" "}
                        <strong>
                          {" "}
                          claims contact number{" "}
                          <a href="tel:0800 772 0850">0800 772 0850</a>
                        </strong>
                        , we will coordinate your vehicle repairs from our
                        trusted repair partners using original parts sourced
                        from manufacturers.
                      </p>
                      <hr />
                      <p className="bold-text">Cost-free replacement vehicle</p>
                      <p className="bottom-te">
                        As per your preferences, we will provide you with a
                        comparable replacement vehicle when you call us on the{" "}
                        <strong>
                          Liverpool Victoria car insurance claim number
                        </strong>{" "}
                        to help you continue your daily routine with minimum
                        disruption.
                      </p>
                      <hr />
                      <p className="bold-text">Comprehensive claim handling</p>
                      <p className="bottom-te">
                        Our dedicated team will handle every aspect of the{" "}
                        <strong>
                          Liverpool Victoria insurance accident claim
                        </strong>
                        , from uninsured losses to claims management, relieving
                        you of the stress of managing these issues.
                      </p>
                      <hr />
                      <p className="bold-text">Repair monitoring and updates</p>
                      <p className="bottom-te">
                        We will oversee the repair process from start to finish
                        and keep you informed about the progress of your vehicle
                        repairs.
                      </p>
                      <hr />
                      <p className="bold-text">Personalised support</p>
                      <p className="bottom-te">
                        When you call us at the{" "}
                        <strong>
                          Liverpool Victoria car accident helpline
                        </strong>
                        , you’ll be assigned a dedicated claims handler who will
                        be your single point of contact throughout the{" "}
                        <strong>
                          Liverpool Victoria insurance accident claim
                        </strong>{" "}
                        process.
                      </p>
                      <hr />
                      <p className="bold-text">Secure storage options</p>
                      <p className="bottom-te">
                        Following a no-fault car accident, we will provide you
                        with the most safe and secure storage options for your
                        vehicle, protecting it from further damage.{" "}
                      </p>
                      <hr />
                      <p className="bold-text">Professional services </p>
                      <p className="bottom-te">
                        Our team comprises skilled professionals and solicitors
                        with extensive experience in{" "}
                        <strong>
                          Liverpool Victoria insurance accident claims
                        </strong>{" "}
                        management. You can trust that your claim will be
                        handled in the most diligent manner with us.
                      </p>
                      <hr />

                      <p className="bold-text">Quick claim resolution</p>
                      <p className="bottom-te">
                        With our streamlined claim process, we ensure that your
                        claim gets processed in the minimum possible time
                        without any unnecessary delays.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="cl4" ref={sidebarRef}>
                  <Sidebarinner />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="photographs">
        <div className="container">
          <div className="row">
            <h2 className="road">
              We will help you in the best possible way <br />
              <span className="light">to hit the road back </span>{" "}
            </h2>
            <div className="col-xxl-6 col-xl-6 col-md-12 col-12">
              <div className="fourbox">
                <ul>
                  <li>
                    <img src={check} className="check" alt="" />
                    Following a no-fault accident, when you call us at{" "}
                    <strong>
                      car accident helpline{" "}
                      <a href="tel:0800 772 0850">0800 772 0850</a>
                    </strong>
                    , we will provide immediate assistance to get you back on
                    the road to recovery.
                  </li>
                  <hr />
                  <li>
                    <img src={check} className="check" alt="" />
                    Our experienced panel of solicitors will evaluate your{" "}
                    <strong>
                      Liverpool Victoria insurance accident claim
                    </strong>{" "}
                    and help you know your rights and options.
                  </li>
                  <hr />
                  <li>
                    <img src={check} className="check" alt="" />
                    We will provide you with a courtesy car to ensure you
                    continue performing your routine activities without
                    disruption.
                  </li>
                  <hr />
                  <li>
                    <img src={check} className="check" alt="" />
                    Our dedicated team will manage every aspect of your claim,
                    including accidental repairs, liaising with insurers and
                    recovery of uninsured losses.
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-xxl-6 col-xl-6 col-md-12 col-12">
              <div className="fourbox">
                <ul>
                  <li>
                    <img src={check} className="check" alt="" />
                    When you call us at the{" "}
                    <strong>
                      Liverpool Victoria accident claim phone number
                    </strong>
                    , we will determine the extent of damages to your vehicle
                    and arrange for repairs from a trusted repairer.
                  </li>
                  <hr />
                  <li>
                    <img src={check} className="check" alt="" />
                    We will introduce you to a dedicated claims specialist in
                    the UK who will help you get maximum compensation for your
                    losses.
                  </li>
                  <hr />
                  <li>
                    <img src={check} className="check" alt="" />
                    We will protect your rights after a no-fault car accident
                    and ensure you're treated fairly by all the parties
                    involved.
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="carprocess">
        <div className="container ">
          <div className="row">
            <div className="col-xxl-12 col-xxl-12 col-md-12 col-12">
              <h2 className="trusted-title">
                {" "}
                Our comprehensive range of Liverpool Victoria <br />
                <span className="light">insurance support services </span>{" "}
              </h2>
            </div>
          </div>
          <div className="row" id="possible">
            <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-12">
              <div className="claim">
                <ul>
                  <li>
                    <span className="sleej">Vehicle recovery </span> <br />
                    We understand the stress and frustration that you experience
                    after a no-fault car accident. Therefore, we offer
                    professional vehicle recovery services round the clock to
                    get your vehicle transported to a safe location.
                  </li>
                  <li>
                    <span className="sleej">Vehicle repairs </span>
                    <br />
                    We offer a comprehensive repair solution for your damaged
                    vehicle through our vast network of manufacturer-approved
                    repairers who carry out the highest-quality repairs quickly
                    and efficiently.
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-12">
              <div className="claim" id="blue">
                <ul>
                  <li>
                    <span className="sleej">Vehicle storage</span> <br />
                    After being involved in a no-fault car accident, you don’t
                    have to stress about your damaged vehicle. We will store
                    your vehicle in our safe storage yards to prevent further
                    security risks and damages. You can contact us at the{" "}
                    <strong>
                      {" "}
                      accident claims helpline{" "}
                      <a href="tel:0800 772 0850">0800 772 0850</a>
                    </strong>{" "}
                    to get your vehicle stored in our facility for the entire
                    process without additional costs.
                  </li>
                  <li>
                    <span className="sleej">Replacement car </span> <br />
                    For the duration of your vehicle repairs, we will provide
                    you with a like-for-like replacement vehicle to ensure no
                    interruption to your day-to-day activities. Irrespective of
                    what you drive, we will provide you with a replacement
                    vehicle best suited to your daily needs. Reach out to us at
                    the{" "}
                    <strong>
                      Liverpool Victoria accident claims number
                    </strong>{" "}
                    to get a cost-free replacement vehicle.
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-12">
              <div className="claim">
                <ul>
                  <li>
                    <span className="sleej">Injury claim </span>
                    <br />
                    You’re entitled to get compensation for your losses and
                    injuries. Our experienced panel of solicitors specialise in
                    handling personal injury claims and will thoroughly assess
                    your case to secure a fair amount of compensation for your
                    losses and injuries. Get in touch with us at{" "}
                    <strong>
                      {" "}
                      claims contact number{" "}
                      <a href="tel:0800 772 0850">0800 772 0850</a>
                    </strong>{" "}
                    to start your claim.
                  </li>
                  <li>
                    <span className="sleej">Claims management </span> <br />
                    From initial documentation to claims settlement, we will
                    handle all the complexities of your{" "}
                    <strong>
                      Liverpool Victoria insurance accident claims
                    </strong>
                    .
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="yellowcta">
        <div className="container">
          <div className="row">
            <div className="cta2">
              <div className="col-xxl-8 col-xl-8 col-lg-8 col-12">
                <div className="legal">
                  <h3 className="experienced">
                    Have more questions?{" "}
                    <span className="light"> Contact our experts </span>{" "}
                  </h3>
                  <p className="professionals">
                    Speak to our knowledgeable no-fault claim experts for
                    tailored assistance for the Liverpool Victoria insurance
                    accident claim.
                  </p>
                </div>
              </div>
              <div className="col-xxl-4 col-xl-4 col-lg-4 col-12">
                <div class="process">
                  <a class="tf-button style-1 phone" href="tel:0800 772 0850">
                    Get in touch<span class="icon-keyboard_arrow_right"></span>
                  </a>
                </div>
                <div class="process">
                  <a className="tf-button style-1 desktop" onClick={handleScrollToSidebar}>
                    Get in touch<span className="icon-keyboard_arrow_right"></span>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <search className="updatedform">
        <div className="container">
          <div className="row text-center">
            <div className="col-xxl-12 col-xl-12 col-md-12 col-12">
              <h2 className="whiteheading">
                What makes us the best choice for your <br />{" "}
                <span className="light">accident management needs? </span>
              </h2>
              <p className="sustained">
                As accident management experts, we’re dedicated to providing the
                best possible assistance after a no-fault car accident. When you
                contact us at the{" "}
                <strong>
                  {" "}
                  accident number <a href="tel:0800 772 0850">0800 772 0850</a>
                </strong>
                , we will alleviate your stress and worries through our no-cost{" "}
                <strong>
                  Liverpool Victoria insurance accident support services
                </strong>
                .{" "}
              </p>
            </div>
          </div>
          <div className="row">
            <div className="col-xxl-4 col-xl-4 col-lg-6 col-md-12 col-12">
              <div className="Claimant">
                <h5>Immediate assistance </h5>
                <p className="collision">
                  Reach out to our friendly team via the{" "}
                  <strong>Liverpool Victoria car accident helpline</strong> to
                  get emergency help to get back on the road. Upon receiving
                  your call, we will immediately dispatch assistance to the
                  accident scene.
                </p>

                <h5>Repair monitoring</h5>
                <p className="collision">
                  We will consistently monitor your vehicle repairs and keep you
                  updated about the status of your vehicle repairs every step of
                  the way.
                </p>

                <h5>Convenient vehicle collection</h5>
                <p className="collision">
                  Once your vehicle repairs are completed, we will arrange the
                  collection of your vehicle. Contact us at{" "}
                  <strong>Liverpool Victoria claims contact number</strong> to
                  schedule a convenient time and location for the collection of
                  your vehicle
                </p>
              </div>
            </div>
            <div className="col-xxl-4 col-xl-4 col-lg-6 col-md-12 col-12">
              <div className="Claimant">
                <h5>Guaranteed quality repairs </h5>
                <p className="collision">
                  When you get in touch with us at the{" "}
                  <strong>Liverpool Victoria car insurance claim number</strong>
                  , we will ensure that your vehicle repairs meet manufacturer
                  standards, preserving the originality and warranty of your
                  vehicle.
                </p>

                <h5>Personalised support and guidance </h5>
                <p className="collision">
                  We will assign you a dedicated claims handler who will serve
                  as a single point of contact throughout your{" "}
                  <strong>Liverpool Victoria insurance accident claim</strong>{" "}
                  process and keep you informed at every step.
                </p>

                <h5>Freedom to choose your own repairer </h5>
                <p className="collision">
                  We offer you the flexibility to choose your own repairer to
                  ensure your vehicle is repaired to your satisfaction.
                </p>
              </div>
            </div>
            <div className="col-xxl-4 col-xl-4 col-lg-12 col-md-12 col-12">
              <div className="Claimant">
                <h5>Experienced solicitors</h5>
                <p className="collision">
                  We have a strong network of experienced and professional
                  solicitors who will manage your{" "}
                  <strong>Liverpool Victoria insurance accident claim</strong>{" "}
                  as efficiently as possible.
                </p>

                <h5>24/7 support </h5>
                <p className="collision">
                  Car accidents happen when least expected. So, no matter what
                  time of the day, we are available round the clock to provide
                  emergency assistance to our clients.
                </p>

                <h5>Expedited claims settlement </h5>
                <p className="collision">
                  We strive to resolve your{" "}
                  <strong>Liverpool Victoria insurance accident claim</strong>{" "}
                  quickly without any tedious paperwork and follow-up calls.
                </p>
              </div>
            </div>
          </div>
        </div>
      </search>

      <section className="Accident-claim">
        <div className="container">
          <div className="row">
            <div className="needed">
              <h2 className="trusted-title">
                How you can maximise Liverpool Victoria <br />
                <span className="light">insurance accident claim</span>
              </h2>
              <p className="complexities">
                Dealing with accident claims can be a stressful process.
                However, taking a few essential steps after a no-fault car
                accident can help you secure fair compensation for the damages
                suffered.
              </p>
            </div>
            <hr />
          </div>
          <div className="row" id="incdes">
            <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-12">
              <div className="Preparing">
                <ul>
                  <li>
                    <div className="seemingly">
                      <h5 className="Seek">Check for injuries and stay safe</h5>
                      <p>
                        Immediately after the no-fault car accident, move to a
                        secure location, away from the traffic and check
                        yourself and other passengers for injuries.
                      </p>
                    </div>
                  </li>
                  <li>
                    <div className="seemingly">
                      <h5 className="Seek">Collect evidence </h5>
                      <p>
                        Gather vital evidence, such as photographs of the
                        damaged vehicles, accident scene, and injuries
                        sustained. Also, collect contact information of the
                        eyewitnesses and other drivers involved in the accident.
                      </p>
                    </div>
                  </li>
                  <li>
                    <div className="seemingly">
                      <h5 className="Seek">Get medical help </h5>
                      <p>
                        Even if you have sustained minor injuries, make sure to
                        seek help from medical professionals and document your
                        injuries and treatment to strengthen your claim.
                      </p>
                    </div>
                  </li>
                  <li>
                    <div className="seemingly">
                      <h5 className="Seek">Report car accident </h5>
                      <p>
                        <strong>Report car accident</strong> to the police and
                        get a copy of the police report. Get emergency roadside
                        assistance and{" "}
                        <strong> insurance accident support</strong> by giving
                        us a call at the{" "}
                        <strong>
                          {" "}
                          car accident helpline{" "}
                          <a href="tel:0800 772 0850">0800 772 0850</a>
                        </strong>
                        .
                      </p>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
            <div
              className="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-12"
              id="dualcardiv"
            >
              <img src={dualcar} alt="" className="dualcar" />
            </div>
            <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-12">
              <div className="Preparing">
                <ul>
                  <li>
                    <div className="seemingly">
                      <h5 className="Seek">Maintain detailed records</h5>
                      <p>
                        Keep an accurate record of all the expenses incurred as
                        a result of the accident, such as medical bills,
                        travelling expenses, repair costs etc.
                      </p>
                    </div>
                  </li>
                  <li>
                    <div className="seemingly">
                      <h5 className="Seek">Know your rights and options</h5>
                      <p>
                        Call us at the{" "}
                        <strong>
                          Liverpool Victoria accident claim phone number
                        </strong>{" "}
                        to speak to our experienced solicitors to know your
                        rights and understand the type of damages for which
                        you’re entitled to receive compensation.
                      </p>
                    </div>
                  </li>
                  <li>
                    <div className="seemingly">
                      <h5 className="Seek">Don’t agree to early settlement </h5>
                      <p>
                        Insurance companies may offer a first settlement to
                        minimise their costs. Refrain from accepting such a
                        settlement from the insurance company, as it might waive
                        your right to pursue further compensation for your
                        losses and injuries.
                      </p>
                    </div>
                  </li>
                  <li>
                    <div className="seemingly">
                      <h5 className="Seek">Start your claim </h5>
                      <p>
                        Reach out to our experienced solicitors to start your{" "}
                        <strong>
                          Liverpool Victoria insurance accident claim
                        </strong>
                        . Our no-fault claim solicitors will help you get fair
                        compensation for your losses.
                      </p>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="whyus">
        <div className="container">
          <div className="row">
            <div className="col-xxl-12 col-md-12">
              <div className="why">
                <h2 className="choose">
                  Liverpool Victoria insurance{" "}
                  <span className="light">
                    <br /> accident claims process
                  </span>
                </h2>
                <p className="complexities">
                  Making a{" "}
                  <strong>Liverpool Victoria insurance accident claim</strong>{" "}
                  could not have been simpler with us. With our quick, easy and
                  straightforward claim process, you can start your no-fault
                  claim quickly and efficiently. Call us at the{" "}
                  <strong>
                    {" "}
                    accident claims helpline{" "}
                    <a href="tel:0800 772 0850">0800 772 0850</a>
                  </strong>{" "}
                  to see how our friendly and professional team can help you
                  with your claim.
                </p>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-xxl-3 col-xl-6 col-lg-6 col-md-6 col-12">
              <div className="box-service">
                <img src={check} className="check" alt="" />
                <h4>Contact us </h4>
                <p>
                  Reach out to our team as soon as possible after a no-fault
                  accident through the{" "}
                  <strong>Liverpool Victoria accident number</strong>.
                </p>
              </div>
            </div>
            <div className="col-xxl-3 col-xl-6 col-lg-6 col-md-6 col-12">
              <div className="box-service">
                <img src={check} className="check" alt="" />
                <h4>Provide details </h4>
                <p>
                  Share details of the accident, including the date, time,
                  location and a detailed description of how the accident
                  happened.
                </p>
              </div>
            </div>
            <div className="col-xxl-3 col-xl-6 col-lg-6 col-md-6 col-12">
              <div className="box-service">
                <img src={check} className="check" alt="" />
                <h4>Submit evidence</h4>
                <p>
                  If you have any vital evidence related to the accident, such
                  as photographs of the accident scene, eyewitness statements,
                  and CCTV videos, share it with us when initiating your claim.
                </p>
              </div>
            </div>
            <div className="col-xxl-3 col-xl-6 col-lg-6 col-md-6 col-12">
              <div className="box-service">
                <img src={check} className="check" alt="" />
                <h4>Review and resolution </h4>
                <p>
                  Once we have all the required information and documents, our
                  team will review your claim and work tirelessly to resolve it
                  as soon as possible.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="faqs">
        <div className="container">
          <div className="row">
            <div className="col-xxl-12 col-xl-12 col-md-12 col-12">
              <div className="fq">
                <h2>
                  {" "}
                  Frequently Asked{" "}
                  <span className="light">Questions (FAQ)</span>
                </h2>
                <p className="complexities">
                  Got questions about{" "}
                  <strong>Liverpool Victoria insurance accident claims?</strong>{" "}
                  We have them answered here.
                </p>
              </div>
              <div className="allfaq">
                <Accordion defaultActiveKey="0">
                  <Accordion.Item eventKey="0">
                    <Accordion.Header>
                      What evidence do I need to provide for a successful
                      Liverpool Victoria insurance accident claim?
                    </Accordion.Header>
                    <Accordion.Body>
                      The more evidence you have, the easier it will be for us
                      to prove your innocence and secure a fair amount of
                      compensation for you. Some evidence which may help us
                      strengthen your claim includes photos of the accident
                      scene, contact details and statements of eyewitnesses and
                      the receipt of the expenses incurred by you after the
                      accident.
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="1">
                    <Accordion.Header>
                      What if my vehicle is beyond repair?
                    </Accordion.Header>
                    <Accordion.Body>
                      If your vehicle is a total loss, we will provide you with
                      a replacement vehicle matching your own. Our experienced
                      solicitors will negotiate the best settlement for your
                      vehicle.
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="2">
                    <Accordion.Header>
                      Who pays for the Liverpool Victoria insurance accident
                      support services?
                    </Accordion.Header>
                    <Accordion.Body>
                      If you have been involved in a no-fault car accident,
                      you’re legally entitled to the Liverpool Victoria
                      insurance accident support services. We will recover the
                      expenses related to the replacement vehicle and vehicle
                      repairs from the insurance company of the at-fault party.
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="3">
                    <Accordion.Header>
                      Am I liable for a credit hire following a no-fault car
                      accident?
                    </Accordion.Header>
                    <Accordion.Body>
                      Yes. You’re liable for a credit hire if the accident is
                      not your fault. If your vehicle is undrivable and you’re
                      looking for a replacement vehicle, you can call us at
                      Liverpool Victoria car accident helpline. We’ll provide
                      you with a replacement vehicle until yours is off the
                      road.
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="4">
                    <Accordion.Header>
                      What perks do I get by making a Liverpool Victoria
                      insurance accident claim?
                    </Accordion.Header>
                    <Accordion.Body>
                      By making your claim with us, you enjoy a myriad of
                      benefits, such as:
                      <ul>
                        <li>1. No cost to you</li>
                        <li>2. No excess payment to your insurer</li>
                        <li>3. Protection of your no-claims bonus </li>
                        <li>4. No affect on your insurance rights</li>
                      </ul>
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="5">
                    <Accordion.Header>
                      Is it worth seeking help from an accident management
                      company?
                    </Accordion.Header>
                    <Accordion.Body>
                      Car accidents are daunting and frustrating. Unlike your
                      insurer, accident management companies offer you peace of
                      mind and eliminate your stress. Moreover, claiming to an
                      accident management company like us will help you get the
                      best compensation for your losses and injuries.
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="6">
                    <Accordion.Header>
                      Can I get my vehicle repaired from my preferred garage?
                    </Accordion.Header>
                    <Accordion.Body>
                      Yes. You can provide us with the details of your repairer,
                      and we’ll get your vehicle repaired from your preferred
                      repair shop.
                    </Accordion.Body>
                  </Accordion.Item>
                </Accordion>
              </div>
            </div>
          </div>
        </div>
      </section>

      <Footer />
    </>
  );
};

export default LiverpoolVictoria;
