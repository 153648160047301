import React, { useRef } from "react";
import Accordion from "react-bootstrap/Accordion";
import check from "../assets/images/check.png";
import dualcar from "../../src/assets/images/dualcar.webp";
import morethan from "../../src/assets/images/morethan.webp";
import Sidebarinner from "../components/Sidebar-inner";
import phonecall from "../assets/images/phonecall.png";
import Footer from "../components/footer";
import { Helmet } from "react-helmet";

const Firstinsurance = () => {
    const sidebarRef = useRef(null);

    const handleScrollToSidebar = () => {
        if (sidebarRef.current) {
            sidebarRef.current.scrollIntoView({ behavior: "smooth" });
        }
    };
    return (
        <>
        <Helmet>
        <meta charSet="utf-8" />
        <title>First Insurance Car Insurance Claim Phone Number</title>
        <meta
          name="description"
          content="Searching for reliable no-fault car accident claim assistance in the UK? Get in touch with our team on the First Insurance Car Insurance Claim Phone Number 0800 772 0850. "
        />
        <link
          rel="canonical"
          href="https://insurance-accident-claims.co.uk/First-insurance"
        />
      </Helmet>
            <section className="innernav">
                <div className="container">
                    <div className="row">
                        <div className="col-md-6 col-sm-6">
                            <div className="left-logo">
                                <h3>
                                    Accident<span className="sipo"> Claims</span>
                                </h3>
                            </div>
                        </div>
                        <div className="col-md-6 col-sm-6">
                            <div className="left-cta">
                                <div class="headercnt">
                                    <div class="chatmsg">
                                        <img
                                            src={phonecall}
                                            className="phonecall"
                                            alt="phonecall "
                                        />
                                    </div>
                                    <div class="box-body">
                                        <h3 class="nav-info-box-title">Toll Free Number </h3>
                                        <p>
                                            <a href="tel:0800 772 0850">0800 772 0850</a>
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section
                className="newbnr"
                style={{ backgroundImage: "url(" + morethan + ")" }}
            >
                <div className="container">
                    <div className="row">
                        <div className="col-xxl-7 col-xl-7 col-md-10 col-12">
                            <div className="innerbnr">
                                <h1>
                                    <span className="onespan">No-fault car accident  </span>{" "}
                                    <span className="sci"> claims specialists   </span> in the UK
                                </h1>
                                <p className="brokerage">
                                    From dealing with vehicle recovery to arranging repairs and handling your claim, we will take care of everything to minimise your stress after a no-fault car accident.
                                </p>
                                <div className="bnrbtn">
                                    <a class="db-btn-border btn-rollover phone" href="tel:0800 772 0850">
                                        Road Accident Claim Help?{" "}
                                        <i class="fa-solid fa-arrow-right"></i>
                                    </a>
                                </div>
                                <div className="bnrbtn">
                                    <a className="db-btn-border btn-rollover desktop" onClick={handleScrollToSidebar}>
                                        Road Accident Claim Help? <i className="fa-solid fa-arrow-right"></i>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="stickysection">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="group-4-8">
                                <div className="cl8 tf-tab">
                                    <div className="content-tab">
                                        <div className="inner-content">
                                            <h2>
                                                Full claims management at zero

                                                <span className="light"> deductible </span>{" "}
                                            </h2>
                                            <p className="disciplines">
                                                Involvement in a non-liability car crash can be very overwhelming. However, we are here to assist you in reducing the anxiety and concerns associated with managing the<strong>
                                                    {" "}
                                                    First motor insurance claims.
                                                </strong>{" "}
                                                As such, we offer repair arrangements as well as replacement vehicles, among other accident support services, without any charges.

                                            </p>
                                            <hr />
                                            <p className="bold-text">No excess</p>
                                            <p className="bottom-te"> When you file your <strong>First auto claim,</strong> there is no excess to be paid. Besides, such payments are not your responsibility as we shall demand remuneration directly from the insurer of the third party involved.                            </p>
                                            <hr />
                                            <p className="bold-text">Authorised repairs </p>
                                            <p className="bottom-te">
                                                We only use manufacturer-approved repairers who fix your car using genuine parts and paintwork.                                   </p>
                                            <hr />
                                            <p className="bold-text">
                                                Unrivalled knowledge
                                            </p>
                                            <p className="bottom-te">
                                                We specialise in protecting people who have been involved in no-fault car accidents. Our solicitors work tirelessly on your behalf and aim to get you the highest possible compensation for a   <strong>First auto claim.</strong>
                                            </p>
                                            <hr />
                                            <p className="bold-text">No-win, No-Fee </p>
                                            <p className="bottom-te">
                                                Our solicitors work on a no-win, no-fee basis. You do not have to pay any legal costs if your <strong>First car accident claim</strong>  fails.                                       </p>
                                            <hr />
                                            <p className="bold-text">Like-for-like replacement</p>
                                            <p className="bottom-te">
                                                Meanwhile, we will give you a similar replacement vehicle whilst your car is being repaired, ensuring that you can get back on the road as soon as possible.                </p>
                                            <hr />
                                            <p className="bold-text">No impact on NCB</p>
                                            <p className="bottom-te">
                                                Since we make the <strong>First car insurance claims </strong> directly against the other party, it means that your insurance premiums will not go up, and you will not lose your no-claims bonus. Dial the
                                                <strong>First car insurance claims number</strong>  to begin filing a claim with us.

                                            </p>
                                            <hr />
                                            <p className="bold-text">Experienced claims handler</p>
                                            <p className="bottom-te">
                                                When contacting us through the <strong>First auto insurance claims phone number</strong> for making a claim, you will be given an experienced claims handler handling only your case.                      </p>
                                            <hr />
                                            <p className="bold-text">Full-fledged claims management</p>
                                            <p className="bottom-te">
                                                We have solicitors who are experienced in handling claims. They will guide you from initiation to completion of the <strong>   First auto insurance claims. </strong>
                                            </p>
                                            <hr />
                                            <p className="bold-text">Customised assistance </p>
                                            <p className="bottom-te">
                                                We appreciate the different peculiarities of each individual <strong>First motor insurance claim.</strong> So, our solicitors provide tailored guidance depending on the individual circumstances of each matter.
                                            </p>
                                            <hr />

                                            <p className="bold-text"> Free advice - no obligation</p>
                                            <p className="bottom-te">
                                                Call our solicitors at <strong> First auto insurance claims phone number </strong> for free consultation without obligation about what rights and choices you have available.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div className="cl4" ref={sidebarRef}>
                                    <Sidebarinner />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="photographs">
                <div className="container">
                    <div className="row">
                        <h2 className="road">
                            We will lessen the hassle of dealing with the aftermath of   {" "}
                            <span className="light">

                                your   <br /> car accident
                            </span>{" "}
                        </h2>
                        <div className="col-xxl-6 col-xl-6 col-md-12 col-12">
                            <div className="fourbox">
                                <ul>
                                    <li>
                                        <img src={check} className="check" alt="" />
                                        If you call us on the car insurance claim number <strong>
                                            {" "}
                                            <a href="tel:0800 772 0850">0800 772 0850</a>{" "}
                                        </strong> we will ask you about what happened and help you fix it in a timely manner.

                                    </li>
                                    <hr />
                                    <li>
                                        <img src={check} className="check" alt="" />
                                        If your vehicle is not in use, we will give you an identical one so that you won’t have any difficulties in your regular life.                                  </li>
                                    <hr />
                                    <li>
                                        <img src={check} className="check" alt="" />
                                        We will organise an independent engineer to assess the amount of damage to your vehicle.
                                    </li>
                                    <hr />
                                    <li>
                                        <img src={check} className="check" alt="" />
                                        We can talk to your car repairer and organise for them to carry out repairs that will see your vehicle returned to its former state before the accident took place.                               </li>
                                </ul>
                            </div>
                        </div>
                        <div className="col-xxl-6 col-xl-6 col-md-12 col-12">
                            <div className="fourbox">
                                <ul>
                                    <li>
                                        <img src={check} className="check" alt="" />
                                        We are going to communicate on our behalf by expertly negotiating with all the parties involved for a legitimate <strong>First car accident claim </strong> settlement.                               </li>
                                    <hr />
                                    <li>
                                        <img src={check} className="check" alt="" />
                                        Again, we’ll contact and keep track of how far repairs are going through conversations with your repairer until they’re done.                                  </li>
                                    <hr />
                                    <li>
                                        <img src={check} className="check" alt="" />
                                        Once it is repaired, we’ll make arrangements for it to be dropped off and collection made on our replacement vehicle.                               </li>
                                    <hr />
                                    <li>
                                        <img src={check} className="check" alt="" />
                                        A specialist claims manager specific to addressing complexities surrounding this <strong> First motor insurance claim </strong>  is appointed immediately to ensure a fast settlement process.      </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="carprocess">
                <div className="container ">
                    <div className="row">
                        <div className="col-xxl-12 col-xxl-12 col-md-12 col-12">
                            <h2 className="trusted-title">
                                {" "}
                                Car accident management <br />
                                <span className="light"> solutions  </span>{" "}
                            </h2>
                        </div>
                    </div>
                    <div className="row" id="possible">
                        <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-12">
                            <div className="claim">
                                <ul>
                                    <li>
                                        <span className="sleej">Car recovery:</span> Call us on the <strong >First car insurance contact number, </strong> and we will get your damaged vehicle towed from where it was involved in an accident. Once our team of accident recovery experts is alerted about the incident, they will move to the scene and take your car to a safer place.

                                    </li>
                                    <li>
                                        <span className="sleej">Vehicle repairs:</span>   Our car repairs are geared towards getting you back on track as fast as possible. To this effect, we guarantee that all replacement parts are obtained from authorised dealers, thus giving you the assurance of quality workmanship.
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-12">
                            <div className="claim" id="blue">
                                <ul>
                                    <li>
                                        <span className="sleej">Vehicle storage:</span> Do you need a place to keep your car after an accident? We provide storage facilities for keeping such vehicles. If your vehicle cannot be driven or you can’t leave it along the roadside, contact us via the insurance claims helpline at {" "}
                                        <a href="tel:0800 772 0850">0800 772 0850</a>{" "} We will recover your vehicle from the accident scene and store it absolutely free in our yards.
                                    </li>
                                    <li>
                                        <span className="sleej">Temporary replacement car:</span> Innocent UK drivers are entitled to having a temporary hire vehicle following a road crash. We will give you like-for-like temporary alternative cars with similar sizes, standards and specifications to yours.
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-12">
                            <div className="claim">
                                <ul>
                                    <li>
                                        <span className="sleej">Personal injury claims </span>
                                        Did you get injured in a car accident? Call us today through the  <strong> First car insurance claim number  </strong> and file a personal injury case. Our solicitors will walk with you throughout the entire process to make sure that the <strong> First car insurance claim </strong> is successful.
                                    </li>
                                    <li>
                                        <span className="sleej">Claim management:</span>  We shall take care of everything until your claim is settled once we receive information about the accident.

                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="yellowcta">
                <div className="container">
                    <div className="row">
                        <div className="cta2">
                            <div className="col-xxl-8 col-xl-8 col-lg-8 col-12">
                                <div className="legal">
                                    <h3 className="experienced">
                                        Got more questions? Talk {" "}
                                        <span className="light"> to our specialists
                                        </span>{" "}
                                    </h3>
                                    <p className="professionals">
                                        Get in touch with our no-fault claim experts via
                                        {" "}
                                        <strong> car insurance contact number{" "}
                                            <a className="cpt-no" href="tel:0800 772 0850">
                                                {" "}
                                                0800 772 0850{" "}
                                            </a>{" "}
                                        </strong>
                                        to get the best advice for the
                                        <strong>First insurance claims. </strong>
                                    </p>
                                </div>
                            </div>
                            <div className="col-xxl-4 col-xl-4 col-lg-4 col-12">
                                <div class="process">
                                    <a class="tf-button style-1 phone" href="tel:0800 772 0850">
                                        Get in touch<span class="icon-keyboard_arrow_right"></span>
                                    </a>
                                </div>
                                <div class="process">
                                    <a className="tf-button style-1 desktop" onClick={handleScrollToSidebar}>
                                        Get in touch<span className="icon-keyboard_arrow_right"></span>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <search className="updatedform">
                <div className="container">
                    <div className="row text-center">
                        <div className="col-xxl-12 col-xl-12 col-md-12 col-12">
                            <h2 className="whiteheading">
                                {" "}
                                Why are we your one-stop shop for the best accident
                                <span className="light">  management solutions?
                                </span>
                            </h2>
                            <p className="sustained">
                                We are dedicated to giving comprehensive assistance, starting from managing complicated claims to offering comprehensive advice throughout the process.         </p>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-xxl-4 col-xl-4 col-lg-6 col-md-12 col-12">
                            <div className="Claimant">
                                <h5>Guaranteed repairs</h5>
                                <p className="collision">
                                    Our repairers are manufacturer-approved and specialists in providing guaranteed quality repairs, so they will start working on your car as soon as possible.


                                </p>

                                <h5>Expert claim handling </h5>
                                <p className="collision">
                                    Our solicitors will evaluate <strong>First auto insurance claims </strong>  and consult various parties involved in accidents to expedite resolution.                           </p>

                                <h5>Prompt roadside assistance </h5>
                                <p className="collision">
                                    After calling the <strong>  First car insurance claim number, </strong>  we will send our recovery team right away to ensure that you and your car are removed from danger.

                                </p>
                            </div>
                        </div>
                        <div className="col-xxl-4 col-xl-4 col-lg-6 col-md-12 col-12">
                            <div className="Claimant">
                                <h5>Free legal advice
                                </h5>
                                <p className="collision">
                                    We can connect you with solicitors specialising in the compensation of faultless car accidents for free so as to know what to do before making a <strong> First car insurance claim. </strong> </p>
                                <h5>In-depth expertise </h5>
                                <p className="collision">
                                    Our solicitors have great working knowledge to provide advice, irrespective of how intricate your claim might seem, in guiding and assisting you in getting the best result for your <strong> First auto claim. </strong>                        </p>

                                <h5>Same-day replacement </h5>
                                <p className="collision">
                                    On the same day, we will give you another vehicle that fits your needs so as not to cause any inconvenience on your part.
                                </p>
                            </div>
                        </div>
                        <div className="col-xxl-4 col-xl-4 col-lg-12 col-md-12 col-12">
                            <div className="Claimant">
                                <h5>Comprehensive services </h5>
                                <p className="collision">
                                    From picking up vehicles at accident scenes and arranging repairs to handling your<strong>First car Insurance claim, </strong> we’ve got you covered.                             </p>

                                <h5>No upfront costs  </h5>
                                <p className="collision">
                                    Any expenses incurred by you for repairs or other related expenditures would be reimbursed by the at-fault party’s insurer. Hence, you don’t need to make any payments.               </p>

                                <h5>24/7 support </h5>
                                <p className="collision">
                                    Our roadside emergency assistance is available throughout the day and night. Dial   <a className="cpt-no" href="tel:0800 772 0850">
                                        {" "}
                                        0800 772 0850{" "}
                                    </a>{" "}
                                    to get personalised advice for your claim.   </p>
                            </div>
                        </div>
                    </div>
                </div>
            </search>

            <section className="Accident-claim">
                <div className="container">
                    <div className="row">
                        <div className="needed">
                            <h2 className="trusted-title">
                            What will make your First auto insurance claim to <br />
                                <span className="light">win maximum compensation?</span>
                            </h2>
                            <p className="complexities">
                            When you have been engaged in a car accident that is not your fault, you are entitled to receive compensation for the injuries and damages from the other party. Nevertheless, it is upon you to prove how much money you deserve for a <strong>First auto insurance claim. </strong>  Here are some of the steps to take after a no-fault car accident that could help increase your compensation.             </p>
                        </div>
                        <hr />
                    </div>
                    <div className="row" id="incdes">
                        <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-12">
                            <div className="Preparing">
                                <ul>
                                    <li>
                                        <div className="seemingly">
                                            <h5 className="Seek">Seek Medical Attention:</h5>
                                            <p>
                                                {" "}
                                                Ensure you see a doctor soon enough for any treatment needed for your injuries. The medical report may provide strong evidence that would solidify a <strong>First car insurance claim. </strong>                                      </p>
                                        </div>
                                    </li>
                                    <li>
                                        <div className="seemingly">
                                            <h5 className="Seek">
                                            Report Accident:{" "}
                                            </h5>
                                            <p>
                                            Once again, there's a need to alert the police about the road traffic accident immediately. Such police records are admissible as pieces of proof in support of your assertions.                                    </p>
                                        </div>
                                    </li>
                                    <li>
                                        <div className="seemingly">
                                            <h5 className="Seek">Gather Proof:</h5>
                                            <p>Start collecting proof like pictures of the scene, vehicles involved, injuries and CCTV videos so that your<strong>First auto insurance claim </strong> becomes more compelling.                                   </p>
                                        </div>
                                    </li>
                                    <li>
                                        <div className="seemingly">
                                            <h5 className="Seek">Keep Track of Financial Losses:</h5>
                                            <p>
                                            Maintain a journal detailing all expenses incurred due to the incident, including hospital bills, travel charges, legal fees, salary forfeiture benefits or any compensations paid.                                           </p>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div
                            className="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-12"
                            id="dualcardiv"
                        >
                            <img src={dualcar} alt="" className="dualcar" />
                        </div>
                        <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-12">
                            <div className="Preparing">
                                <ul>
                                    <li>
                                        <div className="seemingly">
                                            <h5 className="Seek">Do not use social media:</h5>
                                            <p>
                                            Avoid using social media until your <strong>First car insurance claim </strong> is resolved. In this way, the other driver’s solicitor can not use your statements and posts against you in order to lower the compensation.                  </p>
                                        </div>
                                    </li>
                                    <li>
                                        <div className="seemingly">
                                            <h5 className="Seek">Ask for eyewitnesses’ contacts: </h5>
                                            <p>
                                                Request for the contact details of the car accident witnesses and take their statements.                                           </p>
                                        </div>
                                    </li>
                                    <li>
                                        <div className="seemingly">
                                            <h5 className="Seek">
                                            Hire a no-fault claim solicitor  :
                                            </h5>
                                            <p>
                                            Call us using our <strong> First car insurance claims phone number,</strong> and we will get an experienced solicitor to investigate your accident so that he can help you get maximum compensation.
                                            </p>
                                        </div>
                                    </li>
                                    <li>
                                        <div className="seemingly">
                                            <h5 className="Seek">
                                            Initiate your claim :
                                            </h5>
                                            <p>
                                            Once all the supporting documents are ready for filing a <strong> First auto claim,</strong> call us through the<strong>First auto insurance claims phone number </strong>  to begin your claim process.
                                            </p>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="whyus">
                <div className="container">
                    <div className="row">
                        <div className="col-xxl-12 col-md-12">
                            <div className="why">
                                <h2 className="choose">
                                4 easy steps to make your First auto  {" "}
                                    <span className="light">insurance claim </span>
                                </h2>
                                <p className="complexities">
                                Make First auto insurance claims with our process solutions without any complications or worries about paperwork. Call us on the <strong>First car insurance claims phone number </strong> right away so that we can start assisting you with your case.                        </p>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-xxl-3 col-xl-6 col-lg-6 col-md-6 col-12">
                            <div className="box-service">
                                <img src={check} className="check" alt="" />
                                {/* <img src={experience} className="experience" alt="" /> */}
                                <h4>Get in touch  </h4>
                                <p>
                                To request a free initial consultation regarding your claim, give us a call at the <strong> First auto insurance claims phone number </strong> </p>
                            </div>
                        </div>
                        <div className="col-xxl-3 col-xl-6 col-lg-6 col-md-6 col-12">
                            <div className="box-service">
                                <img src={check} className="check" alt="" />
                                <h4>Give details  </h4>
                                <p>
                                Provide full information about the accident, indicating when it happened, people involved and injuries sustained by anyone.</p>
                            </div>
                        </div>
                        <div className="col-xxl-3 col-xl-6 col-lg-6 col-md-6 col-12">
                            <div className="box-service">
                                <img src={check} className="check" alt="" />
                                <h4>Submit documents </h4>
                                <p>
                                We need the evidence you’ve collected from the accident scene and other papers that will help us begin your claim.                              </p>
                            </div>
                        </div>
                        <div className="col-xxl-3 col-xl-6 col-lg-6 col-md-6 col-12">
                            <div className="box-service">
                                <img src={check} className="check" alt="" />
                                <h4>Claim initiation </h4>
                                <p>
                                Your <strong>First auto insurance claim </strong>  will be submitted on your behalf, and we’ll provide you with regular updates on its progress. 

                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="faqs">
                <div className="container">
                    <div className="row">
                        <div className="col-xxl-12 col-xl-12 col-md-12 col-12">
                            <div className="fq">
                                <h2>
                                    {" "}
                                    Frequently Asked{" "}
                                    <span className="light">Questions</span>
                                </h2>
                                <p className="complexities">
                                    Got questions about the <strong> First car insurance claims? </strong>  We have them answered here.

                                </p>
                            </div>
                            <div className="allfaq">
                                <Accordion defaultActiveKey="0">
                                    <Accordion.Item eventKey="0">
                                        <Accordion.Header>
                                        How fast can my First motor accident claim be handled?                                </Accordion.Header>
                                        <Accordion.Body>
                                        All cases of <strong> First car insurance claims </strong> are different. Thus, the time taken in processing these claims is dependent on each individual case. While simple claims may take only several days to settle, more complex ones can consume much time. Our team of experts will keep you informed on your <strong>First car accident claim </strong>  progress until it is finally resolved so that you stay updated.                </Accordion.Body>
                                    </Accordion.Item>
                                    <Accordion.Item eventKey="1">
                                        <Accordion.Header>
                                        What types of materials are used for repairing my damaged vehicle?
                                        </Accordion.Header>
                                        <Accordion.Body>
                                        Our repairers use genuine parts and paint from our trusted UK network so as to assure clients their vehicles will be repaired using original manufacturer-approved parts. Thus, your vehicle will have to be repaired using parts that are approved by manufacturers. 
                                        </Accordion.Body>
                                    </Accordion.Item>
                                    <Accordion.Item eventKey="2">
                                        <Accordion.Header>
                                        How long will the replacement car be available to me in case my vehicle is declared a total loss?
                                        </Accordion.Header>
                                        <Accordion.Body>
                                        If your car is deemed a write-off, we’ll offer you another vehicle that you can retain till full compensation for your car’s worth.          </Accordion.Body>
                                    </Accordion.Item>
                                    <Accordion.Item eventKey="3">
                                        <Accordion.Header>
                                        Can I have my car fixed by my chosen repairer?
                                        </Accordion.Header>
                                        <Accordion.Body>
                                        Yes! We will reach out to your chosen garage after you have shared their contact details with us. 
                                        </Accordion.Body>
                                    </Accordion.Item>
                                    <Accordion.Item eventKey="4">
                                        <Accordion.Header>
                                        Do I need to call the police in case of an accident?

                                        </Accordion.Header>
                                        <Accordion.Body>
                                        Yes! Instead of driving away unperturbed, as other parties may try to do, once the motor vehicle has been involved in a no-fault auto crash, the first thing one should do is call the police immediately. The accident report generated by the police could contain vital information relating to the event, which might help strengthen your claim. This report generally includes important facts about what transpired during that time and place.                         </Accordion.Body>
                                    </Accordion.Item>
                                    <Accordion.Item eventKey="5">
                                        <Accordion.Header>
                                        Who pays for my <strong> First car insurance claim?</strong>{" "}
                                        </Accordion.Header>
                                        <Accordion.Body>
                                        Every cent you pay because of a non-fault road mishap shall be refunded back by the insurer of the faulty driver. They are legally obliged to settle all expenses incurred by you following an accident.                  </Accordion.Body>
                                    </Accordion.Item>
                                    <Accordion.Item eventKey="6">
                                        <Accordion.Header>
                                        Will I lose my no-claims discount?{" "}
                                        </Accordion.Header>
                                        <Accordion.Body>
                                        Your no-claims bonus remains protected if someone else caused your injury, and your own motor policy does not cover any cost at all. You will not lose any discounts earned through hard work nor face additional charges on premiums paid towards insurance policies when such situations arise.

                                        </Accordion.Body>
                                    </Accordion.Item>
                                </Accordion>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <Footer />
        </>
    );
};

export default Firstinsurance;
