import React, { useRef } from "react";
import insurancelogo from "../../src/assets/images/insurancelogo.png";
import Accordion from "react-bootstrap/Accordion";
import check from "../assets/images/check.png";
import dualcar from "../../src/assets/images/dualcar.webp";
import carbnr1 from "../../src/assets/images/carbnr1.webp";
import Sidebarinner from "../components/Sidebar-inner";
import phonecall from "../assets/images/phonecall.png";
import Footer from "../components/footer";
import { Helmet } from "react-helmet";

const Esure = () => {
  const sidebarRef = useRef(null);

  const handleScrollToSidebar = () => {
    if (sidebarRef.current) {
      sidebarRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Esure Car Insurance Accident Claims Number </title>
        <meta
          name="description"
          content="Contact Esure for car insurance accident claims. Dial our dedicated Accident Claims Number 0800 772 0850 for expert support and prompt assistance."
        />
        <link
          rel="canonical"
          href="https://insurance-accident-claims.co.uk/Esure"
        />
      </Helmet>
      <section className="innernav">
        <div className="container">
          <div className="row">
            <div className="col-md-6 col-sm-6">
              <div className="left-logo">
                {/* <img src={insurancelogo} className='insurancelogo' alt='Insurance Logo' /> */}
                <h3>Accident Claims</h3>
              </div>
            </div>
            <div className="col-md-6 col-sm-6">
              <div className="left-cta">
                <div class="headercnt">
                  <div class="chatmsg">
                    <img
                      src={phonecall}
                      className="phonecall"
                      alt="phonecall "
                    />
                  </div>
                  <div class="box-body">
                    <h3 class="nav-info-box-title">Toll Free Number </h3>
                    <p>
                      <a href="tel:0800 772 0850">0800 772 0850</a>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section
        className="newbnr"
        style={{ backgroundImage: "url(" + carbnr1 + ")" }}
      >
        <div className="container">
          <div className="row">
            <div className="col-xxl-8 col-xl-8 col-md-10 col-12">
              <div className="innerbnr">
                <h1>
                  <span className="onespan">No-Fault Accident</span>{" "}
                  <span className="sci">  insurance claims </span> In The UK
                </h1>
                <p className="brokerage">
                  We’re here to provide you with end-to-end accident management
                  solutions, right from vehicle recovery to claims management.
                </p>

                <div className="bnrbtn">
                  <a class="db-btn-border btn-rollover phone" href="tel:0800 772 0850">
                    Start your claim{" "}
                    <i class="fa-solid fa-arrow-right"></i>
                  </a>
                </div>
                <div className="bnrbtn">
                <a className="db-btn-border btn-rollover desktop"  onClick={handleScrollToSidebar}>
                    Start your claim <i className="fa-solid fa-arrow-right"></i>
                </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="stickysection">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="group-4-8">
                <div className="cl8 tf-tab">
                  <div className="content-tab">
                    <div className="inner-content">
                      <h2>
                        No-fault Esure insurance accident <br />
                        <span className="light">
                          support without any hidden cost{" "}
                        </span>
                      </h2>
                      <p className="disciplines">
                        We understand that it can be time-consuming and
                        stressful to deal with the aftermath of a no-fault car
                        accident. Therefore, we aim to provide our clients with
                        the best{" "}
                        <strong>Esure insurance accident support</strong> after
                        a no-fault accident without putting any financial stress
                        on you.
                      </p>
                      <hr />
                      <p className="bold-text">No hidden or surprise costs </p>
                      <p className="bottom-te">
                        We are here to support you after a no-fault car accident
                        without straining you financially. So, when you call us
                        through the{" "}
                        <strong>
                          {" "}
                          accident number{" "}
                          <a href="tel:0800 772 0850">0800 772 0850</a>,
                        </strong>{" "}
                        you don't have to pay any excess costs or hidden charges
                        that may drain you financially.
                      </p>
                      <hr />
                      <p className="bold-text">
                        No impact on your insurance policy
                      </p>
                      <p className="bottom-te">
                        We understand the significance of protecting your
                        insurance premiums. When you get in touch with us via
                        Esure claims contact number to make your claim, we won’t
                        initiate a claim against your own insurance policy,
                        ensuring your premiums stay protected and unaffected
                      </p>
                      <hr />
                      <p className="bold-text">
                        No-claims bonus remains untouched
                      </p>
                      <p className="bottom-te">
                        Your no-claims bonus remains unaffected when you make
                        your <strong>Esure insurance accident </strong>claim
                        with us. You can call us at{" "}
                        <strong>Esure car insurance claim number </strong>to
                        avoid unexpected costs and concerns related to your NCB.
                        We promise to safeguard your valuable rewards and
                        premiums.
                      </p>
                      <hr />
                      <p className="bold-text">
                        Authentic repairs using genuine parts
                      </p>
                      <p className="bottom-te">
                        We provide you with a full-fledged solution for
                        accidental repairs to restore your accident to
                        pre-accident condition. Call us on{" "}
                        <strong>
                          {" "}
                          car accident helpline{" "}
                          <a href="tel:0800 772 0850">0800 772 0850</a>{" "}
                        </strong>
                        to get your vehicle repaired by trustworthy repairers
                        without any hidden costs.
                      </p>
                      <hr />
                      <p className="bold-text">Immediate vehicle recovery </p>
                      <p className="bottom-te">
                        Our foremost priority is to ensure quick and safe
                        recovery of your vehicle after the no-fault accident. We
                        are available 24/7 to provide you with emergency
                        assistance and recover your vehicle instantly after the
                        no-fault accident.
                      </p>
                      <hr />
                      <p className="bold-text">No-fault claim experts </p>
                      <p className="bottom-te">
                        After being involved in a no-fault accident, you can
                        contact us at{" "}
                        <strong>
                          {" "}
                          accident claim phone number{" "}
                          <a href="tel:0800 772 0850">0800 772 0850</a>.{" "}
                        </strong>{" "}
                        We will connect with a no-fault claim specialist who
                        will manage your claim until it gets resolved.
                      </p>
                      <hr />
                      <p className="bold-text">Professional advice </p>
                      <p className="bottom-te">
                        We have a panel of experienced no-fault solicitors who
                        have a track record of handling thousands of no-fault
                        claims. So, when you call us at the
                        <strong> Esure accident claim phone number,</strong>{" "}
                        you’ll get trusted advice from an expert solicitor.
                      </p>
                      <hr />
                      <p className="bold-text">Dedicated team </p>
                      <p className="bottom-te">
                        We are here to save you from all the hassles after a
                        no-fault car accident. You can reach out to us via{" "}
                        <strong>Esure accident claims number </strong>to get
                        assistance following an accident. Our dedicated team
                        will provide immediate support to get you back on the
                        road in minimum time.
                      </p>
                      <hr />
                      <p className="bold-text">Free claim consultation </p>
                      <p className="bottom-te">
                        We ensure your quick recovery after a no-fault accident.
                        Therefore, we provide you with free assistance for your{" "}
                        <strong>Esure insurance accident claim </strong>when you
                        contact us via <strong>Esure accident number. </strong>
                      </p>
                      <hr />
                      <p className="bold-text">Fast-paced claim process </p>
                      <p className="bottom-te">
                        You can confidently make your{" "}
                        <strong>Esure insurance accident claim</strong> with us
                        for a quick settlement. Our trustworthy team works
                        tirelessly to expedite every step of the claim process
                        and ensure swift resolution.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="cl4" ref={sidebarRef}>
                  <Sidebarinner />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="photographs">
        <div className="container">
          <div className="row">
            <h2 className="road">
              We help you restore your peace of mind <br />
              <span className="light"> after a no-fault car accident</span>{" "}
            </h2>
            <div className="col-xxl-6 col-xl-6 col-md-12 col-12">
              <div className="fourbox">
                <ul>
                  <li>
                    <img src={check} className="check" alt="" />
                    We are experts in providing
                    <strong> Esure accident management support</strong> with a
                    track record of helping thousands of drivers in the UK get
                    back on the road.
                  </li>
                  <hr />
                  <li>
                    <img src={check} className="check" alt="" />
                    When you call us at
                    <strong>
                      {" "}
                      car accident helpline{" "}
                      <a href="tel:0800 772 0850">0800 772 0850</a>,
                    </strong>{" "}
                    we will provide you with a free-of-cost replacement vehicle
                    so that you can continue with your routine activities.
                  </li>
                  <hr />
                  <li>
                    <img src={check} className="check" alt="" />
                    Furthermore, we will assess your{" "}
                    <strong>Esure insurance accident claim </strong>and collect
                    essential information and documents to initiate your claim.
                  </li>
                  <hr />
                  <li>
                    <img src={check} className="check" alt="" />
                    We will also coordinate the repairs of your damaged vehicle
                    using genuine parts from the garage of your choice. Reach
                    out to us at <strong>Esure car accident helpline </strong>
                    for high-quality accidental repairs.
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-xxl-6 col-xl-6 col-md-12 col-12">
              <div className="fourbox">
                <ul>
                  <li>
                    <img src={check} className="check" alt="" />
                    We will ensure quick repairs of your vehicle through a
                    network of manufacturer-approved body shops.
                  </li>
                  <hr />
                  <li>
                    <img src={check} className="check" alt="" />
                    In case you’ve sustained injuries in a no-fault accident,
                    you can get in touch with us on{" "}
                    <strong>Esure accident claims number</strong> to get trusted
                    advice from our experienced solicitors regarding your{" "}
                    <strong>Esure insurance accident claim. </strong>
                  </li>
                  <hr />
                  <li>
                    <img src={check} className="check" alt="" />
                    We will manage your claim process from start to finish and
                    liaise with different parties involved in the accident.
                  </li>
                  <hr />
                  <li>
                    <img src={check} className="check" alt="" />
                    Upon completion of your vehicle repairs, we will contact you
                    to coordinate the collection of your restored vehicle.
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="carprocess">
        <div className="container ">
          <div className="row">
            <div className="col-xxl-12 col-xxl-12 col-md-12 col-12">
              <h2 className="trusted-title">
                Our comprehensive range of Esure insurance <br />
                <span className="light">accident support services </span>
              </h2>
            </div>
          </div>
          <div className="row" id="possible">
            <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-12">
              <div className="claim">
                <ul>
                  <li>
                    <span className="sleej">Vehicle recovery</span> <br />
                    In case you need an accident recovery service to shift your
                    vehicle to another location after a no-fault car accident,
                    you can call us at
                    <strong>
                      {" "}
                      <a href="tel:0800 772 0850">0800 772 0850</a>
                    </strong>
                    . Our accident recovery team will immediately arrive at the
                    accident scene to recover your vehicle.
                  </li>
                  <li>
                    <span className="sleej">Vehicle repairs </span>
                    <br />
                    At no cost to you, we will arrange quick repairs of your
                    damaged vehicle from a trusted repairer using original
                    parts. You can call us at{" "}
                    <strong>Esure car insurance claim number </strong>to get
                    your damaged vehicle repaired from our fast and reliable
                    repair network.
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-12">
              <div className="claim" id="blue">
                <ul>
                  <li>
                    <span className="sleej">Vehicle Storage </span> <br />
                    We offer comprehensive vehicle storage services to ensure
                    your peace of mind after a no-fault car accident. Our
                    storage facility allows safe storage of your damaged vehicle
                    while it awaits repairs or further inspection. Reach out to
                    us via{" "}
                    <strong>
                      {" "}
                      accident claims number{" "}
                      <a href="tel:0800 772 0850">0800 772 0850</a>
                    </strong>
                    to get your vehicle moved to our well-maintained yards
                    without facing unexpected costs.
                  </li>
                  <li>
                    <span className="sleej">Replacement car </span> <br />
                    When your vehicle is in an undrivable state after a no-fault
                    accident, we will provide you with a like-to-like
                    replacement vehicle to ensure that you can continue with
                    your routine activities without hassle. When you contact us
                    via <strong>Esure car accident helpline,</strong> we will
                    arrange a free replacement vehicle that aligns with your
                    unique requirements.
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-12">
              <div className="claim">
                <ul>
                  <li>
                    <span className="sleej">Personal injury claim </span>
                    <br />
                    After a car accident, when you call the{" "}
                    <strong>Esure accident claim phone number </strong>to get
                    advice for your Esure insurance accident claim, we will
                    appoint an experienced solicitor who will provide his
                    personalised guidance and support until your claim gets
                    resolved.
                  </li>
                  <li>
                    <span className="sleej">Claims management </span> <br />
                    We are experts in managing no-fault accident claims in the
                    UK. So, when you contact us through the
                    <strong> accident claims helpline 0800 772 0850 </strong>to
                    discuss your claim with us, we will handle all the
                    complexities of your claim and help you get maximum
                    compensation for your losses.
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="yellowcta">
        <div className="container">
          <div className="row">
            <div className="cta2">
              <div className="col-xxl-8 col-xl-8 col-lg-8 col-12">
                <div className="legal">
                  <h3 className="experienced">
                    Need further assistance? Speak
                    <span className="light">
                      to our no-fault claim experts.{" "}
                    </span>
                  </h3>
                  <p className="professionals">
                    Get in touch with us via{" "}
                    <strong>Esure accident claims number</strong> to get
                    professional advice for your no-fault claim from our expert
                    solicitors.
                  </p>
                </div>
              </div>
              <div className="col-xxl-4 col-xl-4 col-lg-4 col-12">
              <div class="process">
                  <a class="tf-button style-1 phone" href="tel:0800 772 0850">
                    Get in touch<span class="icon-keyboard_arrow_right"></span>
                  </a>
                </div>
                <div class="process">
                    <a className="tf-button style-1 desktop"  onClick={handleScrollToSidebar}>
                        Get in touch<span className="icon-keyboard_arrow_right"></span>
                    </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <search className="updatedform">
        <div className="container">
          <div className="row text-center">
            <div className="col-xxl-12 col-xl-12 col-md-12 col-12">
              <h2 className="whiteheading">
                Why we stand out as your reliable partner
                <span className="light">
                  {" "}
                  for accident management solutions
                </span>
              </h2>
              <p className="sustained">
                Before contacting your insurer, reach out to us through{" "}
                <strong>Esure claims contact number. </strong>With us, you’ll
                get a myriad of benefits that go above and beyond your insurance
                company.
              </p>
            </div>
          </div>
          <div className="row">
            <div className="col-xxl-4 col-xl-4 col-lg-6 col-md-12 col-12">
              <div className="Claimant">
                <h5>No unexpected costs </h5>
                <p className="collision">
                  We won’t burden you with financial stress as we will recover
                  all the costs of our services from the at-fault party’s
                  insurer.
                </p>
                <h5>Expedited claim settlement </h5>
                <p className="collision">
                  We follow a transparent and hassle-free process for managing{" "}
                  <strong>Esure insurance accident claims</strong> to ensure
                  that your claim gets resolved in the minimum possible time.
                </p>
                <h5>24/7 support </h5>
                <p className="collision">
                  Our team is available round-the-clock to provide you with
                  immediate and on-time support after a no-fault car accident.
                </p>
              </div>
            </div>
            <div className="col-xxl-4 col-xl-4 col-lg-6 col-md-12 col-12">
              <div className="Claimant">
                <h5>Highest-quality repairs </h5>
                <p className="collision">
                  We have a reliable network of repairers in the UK who are
                  carefully vetted to ensure the highest quality of repairs. You
                  can call us at{" "}
                  <strong>
                    {" "}
                    accident <a href="tel:0800 772 0850">0800 772 0850</a>
                  </strong>{" "}
                  to get top-notch repairs for your damaged vehicle.
                </p>
                <h5>Free replacement vehicle </h5>
                <p className="collision">
                  When you call us at{" "}
                  <strong>Esure claims contact number,</strong> we will provide
                  you with a comparable replacement vehicle at no cost to you.
                </p>
                <h5>No-obligation advice </h5>
                <p className="collision">
                  Our experienced panel of solicitors will assess the
                  circumstances of your accident and provide you with a free
                  no-obligation advice on the best course of action.
                </p>
              </div>
            </div>
            <div className="col-xxl-4 col-xl-4 col-lg-12 col-md-12 col-12">
              <div className="Claimant">
                <h5>Continuous claim monitoring </h5>
                <p className="collision">
                  We will constantly track the progress of your{" "}
                  <strong>Esure insurance accident claim </strong>and provide
                  you with regular updates at every step.
                </p>
                <h5>Dedicated no-fault claim experts </h5>
                <p className="collision">
                  Our no-fault claim specialists have vast experience in
                  handling <strong>Esure insurance accident claims </strong>and
                  securing maximum compensation for the victims of no-fault
                  accidents.
                </p>
                <h5>Fuss-free services</h5>
                <p className="collision">
                  When you contact us through the{" "}
                  <strong>Esure accident claims number, </strong>we will ensure
                  that every aspect of your claim is handled with utmost
                  precision, making the process as stress-free as possible for
                  you.
                </p>
              </div>
            </div>
          </div>
        </div>
      </search>

      <section className="Accident-claim">
        <div className="container">
          <div className="row">
            <div className="needed">
              <h2 className="trusted-title">
                How to maximise your no-fault
                <br />
                <span className="light"> car accident compensation</span>
              </h2>
              <p className="complexities">
                Following a no-fault accident, there are several steps you must
                follow right away to prove your innocence and maximise your
                claim. Here are a few things you must do to increase your
                chances of securing maximum compensation for your
                <strong> Esure insurance accident claim. </strong>
              </p>
            </div>
            <hr />
          </div>
          <div className="row" id="incdes">
            <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-12">
              <div className="Preparing">
                <ul>
                  <li>
                    <div className="seemingly">
                      <h5 className="Seek">Stay at the accident scene </h5>
                      <p>
                        Don’t flee from the accident scene after a no-fault
                        accident. Instead of driving away, spot a safe place and
                        stop your car.
                      </p>
                    </div>
                  </li>
                  <li>
                    <div className="seemingly">
                      <h5 className="Seek">Call emergency help </h5>
                      <p>
                        Immediately call the
                        <strong>
                          {" "}
                          accident claims helpline{" "}
                          <a href="tel:0800 772 0850">0800 772 0850</a>
                        </strong>
                        to get{" "}
                        <strong>Esure insurance accident support </strong>and
                        report car accident to the police.
                      </p>
                    </div>
                  </li>
                  <li>
                    <div className="seemingly">
                      <h5 className="Seek">Collect evidence </h5>
                      <p>
                        If you’re in good physical condition after a car
                        accident, make sure to collect as much evidence as
                        possible from the accident scene such as photographs of
                        the accident scene, eyewitness statements, CCTV videos
                        or any other crucial piece of evidence that may
                        strengthen your claim.
                      </p>
                    </div>
                  </li>
                  <li>
                    <div className="seemingly">
                      <h5 className="Seek">Get medical treatment </h5>
                      <p>
                        Seek immediate medical treatment for your injuries after
                        a no-fault accident. The longer you wait to get medical
                        help for your injuries, the harder it will be for you to
                        prove that the injuries were caused due to an accident.
                      </p>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
            <div
              className="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-12"
              id="dualcardiv"
            >
              <img src={dualcar} alt="" className="dualcar" />
            </div>
            <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-12">
              <div className="Preparing">
                <ul>
                  <li>
                    <div className="seemingly">
                      <h5 className="Seek">
                        Don’t accept the initial settlement{" "}
                      </h5>
                      <p>
                        Avoid accepting the initial settlement offer from the
                        insurance companies as these companies offer a quick,
                        lowball settlement. Once you accept the settlement, you
                        can’t pursue the claim further.
                      </p>
                    </div>
                  </li>
                  <li>
                    <div className="seemingly">
                      <h5 className="Seek">
                        Exchange information with the other driver{" "}
                      </h5>
                      <p>
                        Exchange vital information with the other driver
                        including name, vehicle details, insurance policy
                        details, and driver’s licence number.
                      </p>
                    </div>
                  </li>
                  <li>
                    <div className="seemingly">
                      <h5 className="Seek">Arrange for vehicle recovery </h5>
                      <p>
                        If your vehicle is not in drivable condition, make
                        arrangements for your vehicle recovery by giving us a
                        call at{" "}
                        <strong>
                          {" "}
                          accident number{" "}
                          <a href="tel:0800 772 0850">0800 772 0850</a>.{" "}
                        </strong>
                      </p>
                    </div>
                  </li>
                  <li>
                    <div className="seemingly">
                      <h5 className="Seek">
                        Make your Esure insurance accident claim{" "}
                      </h5>
                      <p>
                        After you’ve collected all the evidence and gathered all
                        the documents, you can call our{" "}
                        <strong>Esure claims department </strong>via{" "}
                        <strong>Esure claims contact number </strong>to make a
                        successful claim.
                      </p>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="whyus">
        <div className="container">
          <div className="row">
            <div className="col-xxl-12 col-md-12">
              <div className="why">
                <h2 className="choose">
                  Simple and straight forward Esure
                  <span className="light">
                    <br /> insurance accident claim process
                  </span>
                </h2>
                <p className="complexities">
                  You can get in touch with us at Esure car insurance claim
                  number to make a successful claim with us. We have a
                  streamlined claim process with minimum paperwork hassles and
                  complexities.
                </p>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-xxl-3 col-xl-6 col-lg-6 col-md-6 col-12">
              <div className="box-service">
                <img src={check} className="check" alt="" />
                <h4>Talk to our experts </h4>
                <p>
                  Give us a call at{" "}
                  <strong>Esure accident claim phone number</strong> to{" "}
                  <strong>report car accident</strong> and kickstart your claim
                  process with us.
                </p>
              </div>
            </div>
            <div className="col-xxl-3 col-xl-6 col-lg-6 col-md-6 col-12">
              <div className="box-service">
                <img src={check} className="check" alt="" />
                <h4>Share accident details </h4>
                <p>
                  Provide us with vital information related to your no-fault
                  claim, including the date, time, location and the reason
                  behind the accident.
                </p>
              </div>
            </div>
            <div className="col-xxl-3 col-xl-6 col-lg-6 col-md-6 col-12">
              <div className="box-service">
                <img src={check} className="check" alt="" />
                <h4>Submit crucial evidence </h4>
                <p>
                  We may require vital evidence to establish the claim in your
                  favor. So, reach out to our team and submit all the essential
                  evidence you’ve collected from the accident scene to our
                  solicitors.
                </p>
              </div>
            </div>
            <div className="col-xxl-3 col-xl-6 col-lg-6 col-md-6 col-12">
              <div className="box-service">
                <img src={check} className="check" alt="" />
                <h4>Claim resolution </h4>
                <p>
                  After having compiled all the important evidence related to
                  your claim, we will initiate and finalise your claim on your
                  behalf, seeking compensation you deserve for your injuries.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="faqs">
        <div className="container">
          <div className="row">
            <div className="col-xxl-12 col-xl-12 col-md-12 col-12">
              <div className="fq">
                <h2>
                  {" "}
                  Frequently Asked{" "}
                  <span className="light">Questions (FAQ)</span>
                </h2>
                <p className="complexities">
                  Got more questions about our no-fault Esure insurance accident
                  support services. We have them answered below.{" "}
                </p>
              </div>
              <div className="allfaq">
                <Accordion defaultActiveKey="0">
                  <Accordion.Item eventKey="0">
                    <Accordion.Header>
                      Will using Esure insurance accident support services
                      affect my insurance premiums?
                    </Accordion.Header>
                    <Accordion.Body>
                      No. Our accident management services won’t affect your
                      insurance premiums in any manner as we won’t initiate a
                      claim against your own insurance policy.
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="1">
                    <Accordion.Header>
                      If the at-fault party’s insurance company offers me an
                      out-of-court settlement, should I agree to it?
                    </Accordion.Header>
                    <Accordion.Body>
                      No. you must avoid rushing for an out-of-court settlement
                      by insurance companies. Once you accept their offer, you
                      won’t be able to pursue your claim further and get
                      deserving compensation for your losses.
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="2">
                    <Accordion.Header>
                      How quickly can you provide me with a replacement vehicle
                      after a no-fault car accident?
                    </Accordion.Header>
                    <Accordion.Body>
                      We know that it’s stressful to deal with no-fault
                      accidents. Therefore, we will arrange a like-for-like
                      replacement vehicle for you within 24 hours. You can keep
                      the replacement vehicle until your vehicle is fully
                      restored to its pre-accident condition.
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="3">
                    <Accordion.Header>
                      What if I want to get my vehicle repaired by the repairer
                      of my choice?
                    </Accordion.Header>
                    <Accordion.Body>
                      We offer you the freedom to select your preferred repairer
                      for your vehicle repairs. You can provide us with the
                      details of your preferred repairer and we will work with
                      them to coordinate the repairs of your damaged vehicle.
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="4">
                    <Accordion.Header>
                      How long will it take to repair my damaged vehicle?
                    </Accordion.Header>
                    <Accordion.Body>
                      There are various factors that affect the duration of your
                      vehicle repairs such as the extent of damages,
                      availability of manufacturer-approved parts etc. We will
                      get an estimate from the repairer and provide you with
                      details of the repair duration.
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="5">
                    <Accordion.Header>
                      What damages and losses can I claim for while making an
                      Esure insurance accident claim?
                    </Accordion.Header>
                    <Accordion.Body>
                      You can claim compensation for vehicle damage, medical
                      expenses, lost wages, and the emotional, physical and
                      mental pain that you’ve suffered as a result of the
                      accident. For better guidance, you can call at our contact
                      number <a href="tel:0800 772 0850">0800 772 0850</a> to
                      get professional advice on the damages that you can claim.
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="6">
                    <Accordion.Header>
                      How much will my Esure insurance accident claim get
                      resolved?
                    </Accordion.Header>
                    <Accordion.Body>
                      There are several factors that may affect the duration of
                      your claim settlement. However, when you contact us on the
                      Esure accident claims helpline, we will expedite your
                      claim process to ensure that it gets settled in minimum
                      time.
                    </Accordion.Body>
                  </Accordion.Item>
                </Accordion>
              </div>
            </div>
          </div>
        </div>
      </section>

      <Footer />
    </>
  );
};

export default Esure;
