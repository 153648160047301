import React, { useRef } from "react";
import Accordion from "react-bootstrap/Accordion";
import check from "../assets/images/check.png";
import dualcar from "../../src/assets/images/dualcar.webp";
import morethan from "../../src/assets/images/morethan.webp";
import Sidebarinner from "../components/Sidebar-inner";
import phonecall from "../assets/images/phonecall.png";
import Footer from "../components/footer";
import { Helmet } from "react-helmet";

const Huddleinsurance = () => {
    const sidebarRef = useRef(null);

    const handleScrollToSidebar = () => {
        if (sidebarRef.current) {
            sidebarRef.current.scrollIntoView({ behavior: "smooth" });
        }
    };
    return (
        <>
        <Helmet>
        <meta charSet="utf-8" />
        <title>Huddle Insurance Car Insurance Claim Phone Number</title>
        <meta
          name="description"
          content="Looking for expert guidance to make a successful no-fault car accident claim? Reach out to our team on the Huddle Insurance Car Insurance Claim Phone Number 0800 772 0850. "
        />
        <link
          rel="canonical"
          href="https://insurance-accident-claims.co.uk/Huddle-insurance"
        />
      </Helmet>
            <section className="innernav">
                <div className="container">
                    <div className="row">
                        <div className="col-md-6 col-sm-6">
                            <div className="left-logo">
                                <h3>
                                    Accident<span className="sipo"> Claims</span>
                                </h3>
                            </div>
                        </div>
                        <div className="col-md-6 col-sm-6">
                            <div className="left-cta">
                                <div class="headercnt">
                                    <div class="chatmsg">
                                        <img
                                            src={phonecall}
                                            className="phonecall"
                                            alt="phonecall "
                                        />
                                    </div>
                                    <div class="box-body">
                                        <h3 class="nav-info-box-title">Toll Free Number </h3>
                                        <p>
                                            <a href="tel:0800 772 0850">0800 772 0850</a>
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section
                className="newbnr"
                style={{ backgroundImage: "url(" + morethan + ")" }}
            >
                <div className="container">
                    <div className="row">
                        <div className="col-xxl-7 col-xl-7 col-md-10 col-12">
                            <div className="innerbnr">
                                <h1>
                                    <span className="onespan">Fast and reliable accident    </span>{" "}
                                    <span className="sci">  insurance claims services   </span> in the UK
                                </h1>
                                <p className="brokerage">
                                We will take you through the whole process to ensure a quick and seamless return back to driving, from the beginning assessment to the closure of the claim.
                                </p>
                                <div className="bnrbtn">
                                    <a class="db-btn-border btn-rollover phone" href="tel:0800 772 0850">
                                        Road Accident Claim Help?{" "}
                                        <i class="fa-solid fa-arrow-right"></i>
                                    </a>
                                </div>
                                <div className="bnrbtn">
                                    <a className="db-btn-border btn-rollover desktop" onClick={handleScrollToSidebar}>
                                        Road Accident Claim Help? <i className="fa-solid fa-arrow-right"></i>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="stickysection">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="group-4-8">
                                <div className="cl8 tf-tab">
                                    <div className="content-tab">
                                        <div className="inner-content">
                                            <h2>
                                            We’re experts in managing no-fault 

                                                <span className="light"> accident claims  </span>{" "}
                                            </h2>
                                            <p className="disciplines">
                                            We have been handling many claims, and we are among the leading Huddle insurance claims providers in the UK. To start your claim process today, call us at the Huddle car insurance phone number. We will deal with your<strong>  Huddle car insurance claim </strong>without any excess.       

                                            </p>
                                            <hr />
                                            <p className="bold-text">No policy excess</p>
                                            <p className="bottom-te">  You won’t lose your no-claims bonus if you don’t make a <strong> Huddle car insurance claim </strong> with us, and your insurance premiums will stay intact.                             </p>
                                            <hr />
                                            <p className="bold-text">No-claims bonus safety</p>
                                            <p className="bottom-te">
                                            When making a <strong> Huddle insurance claim </strong>  with us, there is no need to worry about losing your no-claims bonus, as we will make sure it stays. We’ll protect all those valuable discounts you’ve earned along the way.                                  </p>
                                            <hr />
                                            <p className="bold-text">
                                            Quick recovery of vehicles 
                                            </p>
                                            <p className="bottom-te">
                                            After a car accident, we guarantee that you will never be left stranded on the road again. Call us now for assistance on our emergency line at 
                                            <strong>
                                            {" "}
                                            <a href="tel:0800 772 0850">0800 772 0850</a>{" "}
                                        </strong> and we shall arrange immediate safe transportation of your vehicle to our secured yards. 
                                            </p>
                                            <hr />
                                            <p className="bold-text">Authorised garages</p>
                                            <p className="bottom-te">
                                            Our trusted association with manufacturer-approved repairers ensures top-quality repairs for your vehicle.                                       </p>
                                            <hr />
                                            <p className="bold-text">Replacement vehicle of similar make and model </p>
                                            <p className="bottom-te">
                                            When your car is being repaired or has been written off, we will offer you a replacement one that is nearly exactly the same as yours.        </p>
                                            <hr />
                                            <p className="bold-text">A skilled team of solicitors </p>
                                            <p className="bottom-te">
                                            Our well-trained solicitors have a history of dealing with <strong> Huddle insurance claims </strong> meticulously and ensuring that victims of non-fault road accidents receive maximum compensation. 

                                            </p>
                                            <hr />
                                            <p className="bold-text">  Fast processing of claims </p>
                                            <p className="bottom-te">
                                            The quick settlement of your <strong> Huddle car insurance claim </strong> is principally due to our simple claim process, which saves you from paperwork and reduces calls for follow-ups.                    </p>
                                            <hr />
                                            <p className="bold-text">Free advice with no obligation </p>
                                            <p className="bottom-te">
                                            For free, no-obligation advice on insurance claims, call us on  
                                            <strong>
                                            {" "}
                                            <a href="tel:0800 772 0850">0800 772 0850</a>{" "}
                                        </strong>and you’ll be guided by our competent solicitors on how to proceed with your claim. 

                                            </p>
                                            <hr />
                                            <p className="bold-text">No-win-no fee </p>
                                            <p className="bottom-te">
                                            Our solicitors work on a no-win, no-fee basis. If you lose your case, you don't have to pay anything. 
                                            </p>
                                            <hr />

                                            <p className="bold-text">24/7 support</p>
                                            <p className="bottom-te">
                                            We always remain around in case of anything. Should you require help with any non-fault car accident immediately, please get in touch with our auto insurance claims team on
                                            <strong>
                                            {" "}
                                            <a href="tel:0800 772 0850">0800 772 0850</a>{" "}
                                        </strong>
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div className="cl4" ref={sidebarRef}>
                                    <Sidebarinner />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="photographs">
                <div className="container">
                    <div className="row">
                        <h2 className="road">
                        We want to take the pain out of   {" "}
                            <span className="light">

                            handling   <br />   a car accident
                            </span>{" "}
                        </h2>
                        <div className="col-xxl-6 col-xl-6 col-md-12 col-12">
                            <div className="fourbox">
                                <ul>
                                    <li>
                                        <img src={check} className="check" alt="" />
                                        If you experience a car accident, please call the <strong>Huddle insurance claims number </strong> for urgent help.

                                    </li>
                                    <hr />
                                    <li>
                                        <img src={check} className="check" alt="" />
                                        We will carry out a comprehensive appraisal to determine if there is any negligent party who has caused your losses and against whom we can make a claim                                  </li>
                                    <hr />
                                    <li>
                                        <img src={check} className="check" alt="" />
                                        We will support you in making <strong> Huddle insurance claims </strong>so that you can get remunerated for the damages that have been done.
                                    </li>
                                    <hr />
                                    <li>
                                        <img src={check} className="check" alt="" />
                                        From there, one of our team will arrange the collection of your vehicle from the incident site as well as liaise with our approved repairer network for repairing it.                              </li>
                                </ul>
                            </div>
                        </div>
                        <div className="col-xxl-6 col-xl-6 col-md-12 col-12">
                            <div className="fourbox">
                                <ul>
                                    <li>
                                        <img src={check} className="check" alt="" />
                                        If repairing it seems unattainable, an independent engineer can be arranged to value your written-off vehicle                         </li>
                                    <hr />
                                    <li>
                                        <img src={check} className="check" alt="" />
                                        Until when your car is repaired, we offer an appropriate alternative vehicle                                 </li>
                                    <hr />
                                    <li>
                                        <img src={check} className="check" alt="" />
                                        The repairs will be assessed regularly, and frequent updates will be provided to you.                           </li>
                                    <hr />
                                    <li>
                                        <img src={check} className="check" alt="" />
                                        Our solicitors will help you with fair compensation for your losses. Call us on <strong>
                                            {" "}
                                            <a href="tel:0800 772 0850">0800 772 0850</a>{" "}
                                        </strong> to get started.    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="carprocess">
                <div className="container ">
                    <div className="row">
                        <div className="col-xxl-12 col-xxl-12 col-md-12 col-12">
                            <h2 className="trusted-title">
                                {" "}
                                Our wide range of accident management<br />
                                <span className="light">services  </span>{" "}
                            </h2>
                        </div>
                    </div>
                    <div className="row" id="possible">
                        <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-12">
                            <div className="claim">
                                <ul>
                                    <li>
                                        <span className="sleej">Vehicle recovery:</span>   Reach out to us through the<strong> Huddle car insurance contact number </strong>  for vehicle recovery following a car accident. Right from the scene of the accident, our accident recovery services will safely transport your damaged vehicle away to a secure location with minimum inconvenience. 

                                    </li>
                                    <li>
                                        <span className="sleej">Approved repairs:</span>To have your vehicle repaired after a non-fault car accident, please contact our team at the <strong> Huddle insurance claim number.</strong>  Without any extra charge, we shall arrange for your car repairs from reputable and dependable repairers across the UK.
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-12">
                            <div className="claim" id="blue">
                                <ul>
                                    <li>
                                        <span className="sleej">Secure storage:</span>  Concerned about what you can do with your vehicle after a not-at-fault car crash? Dial the<strong> Huddle insurance claims number. </strong>  In our well-maintained storage yards, we offer worry-free vehicle storage services to protect your car as it goes through the claim process.
                                    </li>
                                    <li>
                                        <span className="sleej">Replacement car:</span>  When your car cannot be repaired, you qualify for a replacement one. For an identical substitute to allow you to stay on course without experiencing difficulties, call us on  <strong>
                                            {" "}
                                            <a href="tel:0800 772 0850">0800 772 0850</a>{" "}
                                        </strong> using our emergency helpline. You can keep the replacement vehicle until the settlement of your claim.
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-12">
                            <div className="claim">
                                <ul>
                                    <li>
                                        <span className="sleej">Injury claim support  </span>
                                        The best method of discovering if you are eligible for personal injury claims is by talking to us through the<strong> Huddle car insurance contact number.</strong>  In order to achieve compensation for your injuries, our solicitors will offer advice and guidance. 
                                    </li>
                                    <li>
                                        <span className="sleej">Claim management :</span>  We provide comprehensive support through the process of Huddle insurance claims, such as document handling, negotiation with insurers, arranging vehicle repair services and providing replacement vehicles whenever necessary.

                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="yellowcta">
                <div className="container">
                    <div className="row">
                        <div className="cta2">
                            <div className="col-xxl-8 col-xl-8 col-lg-8 col-12">
                                <div className="legal">
                                    <h3 className="experienced">
                                        Got more questions? Talk {" "}
                                        <span className="light"> to our specialists
                                        </span>{" "}
                                    </h3>
                                    <p className="professionals">
                                        Get in touch with our no-fault claim experts via
                                        {" "}
                                        <strong> car insurance contact number{" "}
                                            <a className="cpt-no" href="tel:0800 772 0850">
                                                {" "}
                                                0800 772 0850{" "}
                                            </a>{" "}
                                        </strong>
                                        to get the best advice for the
                                        <strong>Huddle insurance claims.  </strong>
                                    </p>
                                </div>
                            </div>
                            <div className="col-xxl-4 col-xl-4 col-lg-4 col-12">
                                <div class="process">
                                    <a class="tf-button style-1 phone" href="tel:0800 772 0850">
                                        Get in touch<span class="icon-keyboard_arrow_right"></span>
                                    </a>
                                </div>
                                <div class="process">
                                    <a className="tf-button style-1 desktop" onClick={handleScrollToSidebar}>
                                        Get in touch<span className="icon-keyboard_arrow_right"></span>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <search className="updatedform">
                <div className="container">
                    <div className="row text-center">
                        <div className="col-xxl-12 col-xl-12 col-md-12 col-12">
                            <h2 className="whiteheading">
                                {" "}
                                What sets us
                                <span className="light">  apart?
                                </span>
                            </h2>
                            <p className="sustained">
                            Should you be involved in a no-fault car accident, we will do everything possible to alleviate your anxiety and make sure that you don’t have to worry about anything else. Here are a few benefits among many others when you choose our service for your claims management.        </p>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-xxl-4 col-xl-4 col-lg-6 col-md-12 col-12">
                            <div className="Claimant">
                                <h5>24-hour vehicle recovery </h5>
                                <p className="collision">
                                Do you need immediate vehicle recovery? Call us at the <strong> Huddle insurance claim number, </strong> and we will reach you within the shortest time, irrespective of the hour of contact.


                                </p>

                                <h5>Repairs with guarantees</h5>
                                <p className="collision">
                                Your car will receive repairs from one of our reliable repairers, guaranteeing its quality.                         </p>

                                <h5>No-claim on policy</h5>
                                <p className="collision">
                                We do not file any kind of claim with respect to your own policy, keeping your insurance premiums intact.

                                </p>
                            </div>
                        </div>
                        <div className="col-xxl-4 col-xl-4 col-lg-6 col-md-12 col-12">
                            <div className="Claimant">
                                <h5>Free replacement car 
                                </h5>
                                <p className="collision">
                                You can be mobile since we give like-for-like cars without any upfront charges or costs. </p>
                                <h5>A reliable network of partners</h5>
                                <p className="collision">
                                In the UK, we have established an unbreakable network of approved repairers and proficient solicitors who will give you nothing but top-notch services.                       </p>

                                <h5>Fast claims settlements </h5>
                                <p className="collision">
                                Our experienced solicitors negotiate with the insurer from the side that is to blame for a prompt settlement of <strong> Huddle motor insurance claims.</strong> 
                                </p>
                            </div>
                        </div>
                        <div className="col-xxl-4 col-xl-4 col-lg-12 col-md-12 col-12">
                            <div className="Claimant">
                                <h5>Instant help </h5>
                                <p className="collision">
                                We are available round the clock to provide emergency assistance and get you back on the road as soon as possible after a no-fault car accident.                            </p>

                                <h5> Personalised assistance </h5>
                                <p className="collision">
                                Your claim will be handled directly by a dedicated claims handler, who will update you at every stage of its progress.            </p>

                                <h5>Zero excess payments </h5>
                                <p className="collision">
                                We do not want you to suffer from additional payments or pay from your own pocket. </p>
                            </div>
                        </div>
                    </div>
                </div>
            </search>

            <section className="Accident-claim">
                <div className="container">
                    <div className="row">
                        <div className="needed">
                            <h2 className="trusted-title">
                            How can you increase the chances of <br />
                                <span className="light">obtaining fair compensation? 
                                </span>
                            </h2>
                            <p className="complexities">
                            Being involved in a no-fault car accident is stressful enough, but dealing with claims processes is even more infuriating. To maximise your chances of getting fair compensation, you must prove that it was not your fault. Here are some steps to follow after being in a vehicle accident so as to get a reasonable amount in compensation.             </p>
                        </div>
                        <hr />
                    </div>
                    <div className="row" id="incdes">
                        <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-12">
                            <div className="Preparing">
                                <ul>
                                    <li>
                                        <div className="seemingly">
                                            <h5 className="Seek">Ensure Your Safety :</h5>
                                            <p>
                                                {" "}
                                                Park your car at a safe place and check yourself and your passengers if there are any severe or minor injuries.                                   </p>
                                        </div>
                                    </li>
                                    <li>
                                        <div className="seemingly">
                                            <h5 className="Seek">
                                            Seek medical attention:{" "}
                                            </h5>
                                            <p>
                                            If anyone in your car was injured, immediately consult with a healthcare provider.                               </p>
                                        </div>
                                    </li>
                                    <li>
                                        <div className="seemingly">
                                            <h5 className="Seek">Take notes:</h5>
                                            <p> Capture important details from the accident scene, including photos of where the accident occurred, vehicles involved, damages, serious injuries and registration numbers of all the vehicles.                                 </p>
                                        </div>
                                    </li>
                                    <li>
                                        <div className="seemingly">
                                            <h5 className="Seek">Call the police:</h5>
                                            <p>
                                            Immediately inform the police about the accident you were involved in, as the traffic department report is very vital when filing <strong>Huddle insurance claims. </strong>                                           </p>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div
                            className="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-12"
                            id="dualcardiv"
                        >
                            <img src={dualcar} alt="" className="dualcar" />
                        </div>
                        <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-12">
                            <div className="Preparing">
                                <ul>
                                    <li>
                                        <div className="seemingly">
                                            <h5 className="Seek">Swap contacts :</h5>
                                            <p>
                                            Give other drivers your contact details and insurance information, such as names, phone numbers, addresses and insurance policy numbers.                 </p>
                                        </div>
                                    </li>
                                    <li>
                                        <div className="seemingly">
                                            <h5 className="Seek">Get witness information: </h5>
                                            <p>
                                            Write down details concerning witnesses, such as their names, contact numbers and addresses, so you can reach them later on.                                         </p>
                                        </div>
                                    </li>
                                    <li>
                                        <div className="seemingly">
                                            <h5 className="Seek">
                                            Account for costs incurred:
                                            </h5>
                                            <p>
                                            Make sure that all bills relating to repairs made or medical treatment are kept until they have been settled so that you can make a claim for them.
                                            </p>
                                        </div>
                                    </li>
                                    <li>
                                        <div className="seemingly">
                                            <h5 className="Seek">
                                            Contact an expert in no-fault claims :
                                            </h5>
                                            <p>
                                            In order to make a no-fault claim, please call us on our insurance claims helpline
                                            <strong>
                                            {" "}
                                            <a href="tel:0800 772 0850">0800 772 0850</a>{" "}
                                        </strong> for expert advice on how to proceed with your claim.
                                            </p>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="whyus">
                <div className="container">
                    <div className="row">
                        <div className="col-xxl-12 col-md-12">
                            <div className="why">
                                <h2 className="choose">
                                Want to make a no-fault  {" "}
                                    <span className="light"> claim? Start now </span>
                                </h2>
                                <p className="complexities">
                                We are aware that making a Huddle car insurance claim after an accident that was not your fault may seem like a daunting task. Hence, we are here to ensure it is easy and direct for you. You can start by contacting us through the <strong> Huddle car insurance phone number. </strong>                    </p>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-xxl-3 col-xl-6 col-lg-6 col-md-6 col-12">
                            <div className="box-service">
                                <img src={check} className="check" alt="" />
                                {/* <img src={experience} className="experience" alt="" /> */}
                                <h4>Let’s get in touch </h4>
                                <p>
                                Conversely, talk to our team via the Huddle car insurance contact number about your case. </p>
                            </div>
                        </div>
                        <div className="col-xxl-3 col-xl-6 col-lg-6 col-md-6 col-12">
                            <div className="box-service">
                                <img src={check} className="check" alt="" />
                                <h4>Share details  </h4>
                                <p>
                                Explain how the accident occurred with details such as time, date, and location of occurrence.</p>
                            </div>
                        </div>
                        <div className="col-xxl-3 col-xl-6 col-lg-6 col-md-6 col-12">
                            <div className="box-service">
                                <img src={check} className="check" alt="" />
                                <h4>Provide proof </h4>
                                <p>
                                Submit any necessary documents that support your claims. These can include photographs of the area where the accident took place, statements from people who saw it happening or even police records and CCTV footage.                            </p>
                            </div>
                        </div>
                        <div className="col-xxl-3 col-xl-6 col-lg-6 col-md-6 col-12">
                            <div className="box-service">
                                <img src={check} className="check" alt="" />
                                <h4>Claim processing  </h4>
                                <p>
                                All papers will be checked before taking further steps regarding the claim. Our panel of professionals will be updating you on each stage done at their end.


                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="faqs">
                <div className="container">
                    <div className="row">
                        <div className="col-xxl-12 col-xl-12 col-md-12 col-12">
                            <div className="fq">
                                <h2>
                                    {" "}
                                    Frequently Asked{" "}
                                    <span className="light">Questions</span>
                                </h2>
                                <p className="complexities">
                                    Got questions about the <strong> Huddle insurance claims? </strong>  We have them answered here.

                                </p>
                            </div>
                            <div className="allfaq">
                                <Accordion defaultActiveKey="0">
                                    <Accordion.Item eventKey="0">
                                        <Accordion.Header>
                                        Would I be required to pay any excess for the replacement vehicle?                              </Accordion.Header>
                                        <Accordion.Body>
                                        No, all costs for the replacement vehicle would be recovered from the other driver’s insurer. However, this may not always be the case if you fail to comply, make a report that is false and misleading in relation to your claim, or hire a solicitor without our knowledge.               </Accordion.Body>
                                    </Accordion.Item>
                                    <Accordion.Item eventKey="1">
                                        <Accordion.Header>
                                        How long will it take for my claim to get settled?
                                        </Accordion.Header>
                                        <Accordion.Body>
                                        We try to settle your <strong> Huddle car insurance claim </strong>  as quickly as possible. The timing of settling claims varies according to the individual circumstances of each case. We shall keep you informed about the progress of your case after making an initial contact with us.
                                        </Accordion.Body>
                                    </Accordion.Item>
                                    <Accordion.Item eventKey="2">
                                        <Accordion.Header>
                                        Will the insurance company of the person at fault pay for the cost of recovering my car?
                                        </Accordion.Header>
                                        <Accordion.Body>
                                        Absolutely, all of the vehicle recovery expenses will be covered by the at-fault party’s insurer. In order to have your claim for vehicle recovery considered, you may need to provide certain evidence, such as an accident report or photos.        </Accordion.Body>
                                    </Accordion.Item>
                                    <Accordion.Item eventKey="3">
                                        <Accordion.Header>
                                        Can I get a hire car on credit after an accident where it wasn’t my fault?
                                        </Accordion.Header>
                                        <Accordion.Body>
                                        Yes. You can get a credit hire if you were not at fault in the accident. Call us on our <strong> Huddle insurance claims number </strong>  if your motor vehicle is written off or being repaired and you require another vehicle. We will give you another vehicle that is like-for-like until yours is back on the road. 
                                        </Accordion.Body>
                                    </Accordion.Item>
                                    <Accordion.Item eventKey="4">
                                        <Accordion.Header>
                                        Will I lose my NCB if it’s not my fault?

                                        </Accordion.Header>
                                        <Accordion.Body>
                                        Making your claim with us allows you to avoid any possibility of claiming from your own policy, protecting those hard-earned bonuses and discounts.                        </Accordion.Body>
                                    </Accordion.Item>
                                    <Accordion.Item eventKey="5">
                                        <Accordion.Header>
                                        Is it possible for me to choose which repair shop I want for my car repairs?{" "}
                                        </Accordion.Header>
                                        <Accordion.Body>
                                        Certainly! In case you would like your car repaired by your own repairer, we will make the arrangements. We will talk to your chosen repairer to see to it that the best standards of quality are observed and repairs done in good time.                 </Accordion.Body>
                                    </Accordion.Item>
                                    <Accordion.Item eventKey="6">
                                        <Accordion.Header>
                                        Which vehicle do I get instead of mine?{" "}
                                        </Accordion.Header>
                                        <Accordion.Body>
                                        We will arrange an equivalent replacement vehicle similar in size, quality and functionality to your own vehicle. Just let us know what you need through a phone call at the <strong> Huddle insurance claim number, </strong>  and we will provide a suitable car for you.

                                        </Accordion.Body>
                                    </Accordion.Item>
                                </Accordion>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <Footer />
        </>
    );
};

export default Huddleinsurance;
