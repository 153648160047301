import React, { useRef } from "react";
import insurancelogo from "../../src/assets/images/insurancelogo.png";
import Accordion from "react-bootstrap/Accordion";
import check from "../assets/images/check.png";
import dualcar from "../../src/assets/images/dualcar.webp";
import morethan from "../../src/assets/images/morethan.webp";
import Sidebarinner from "../components/Sidebar-inner";
import phonecall from "../assets/images/phonecall.png";
import { Helmet } from "react-helmet";
import Footer from "../components/footer";

const Nationwide = () => {
  const sidebarRef = useRef(null);

  const handleScrollToSidebar = () => {
    if (sidebarRef.current) {
      sidebarRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Nationwide Insurance Company Claims | Nationwide Claim Number</title>
        <meta name="Are you looking for trusted and timely claims assistance? Get in touch with us via the Nationwide Building Society Accident Insurance Claim Number 0800 772 0850. " />
        <link
          rel="canonical"
          href="https://insurance-accident-claims.co.uk/Nationwide "
        />
      </Helmet>
      <section className="innernav">
        <div className="container">
          <div className="row">
            <div className="col-md-6 col-sm-6">
              <div className="left-logo">
                {/* <img src={insurancelogo} className='insurancelogo' alt='Insurance Logo' /> */}
                <h3>Accident Claims</h3>
              </div>
            </div>
            <div className="col-md-6 col-sm-6">
              <div className="left-cta">
                <div class="headercnt">
                  <div class="chatmsg">
                    <img
                      src={phonecall}
                      className="phonecall"
                      alt="phonecall "
                    />
                  </div>
                  <div class="box-body">
                    <h3 class="nav-info-box-title">Toll Free Number </h3>
                    <p>
                      <a href="tel:0800 772 0850">0800 772 0850</a>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section
        className="newbnr"
        style={{ backgroundImage: "url(" + morethan + ")" }}
      >
        <div className="container">
          <div className="row">
            <div className="col-xxl-7 col-xl-7 col-md-10 col-12">
              <div className="innerbnr">
                <h1>
                  <span className="onespan">Best accident</span>{" "}
                  <span className="sci"> insurance claims </span> solutions in The UK{" "}
                </h1>
                <p className="brokerage">
                  We’re the UK’s leading accident management experts,
                  specialising in providing the best solutions after a no-fault
                  car accident.{" "}
                </p>
                <div className="bnrbtn">
                  <a
                    class="db-btn-border btn-rollover phone"
                    href="tel:0800 772 0850"
                  >
                    Begin your claim <i class="fa-solid fa-arrow-right"></i>
                  </a>
                </div>
                <div className="bnrbtn">
                  <a
                    className="db-btn-border btn-rollover desktop"
                    onClick={handleScrollToSidebar}
                  >
                    Begin your claim <i className="fa-solid fa-arrow-right"></i>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="stickysection">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="group-4-8">
                <div className="cl8 tf-tab">
                  <div className="content-tab">
                    <div className="inner-content">
                      <h2>
                        {" "}
                        Exceptional Nationwide Building Society{" "}
                        <span className="light">
                          insurance accident support with zero excess
                        </span>{" "}
                      </h2>
                      <p className="disciplines">
                        Being involved in a no-fault car accident can be a
                        stressful experience. Besides dealing with the injuries
                        and damages, you’ll also have to manage the complexities
                        of your insurance claim. We’re here to provide you with
                        the best{" "}
                        <strong>
                          Nationwide Building Society insurance accident support
                        </strong>{" "}
                        without any excess.
                      </p>
                      <hr />
                      <p className="bold-text">No upfront costs </p>
                      <p className="bottom-te">
                        From vehicle recovery to claim assistance, we offer a
                        wide range of{" "}
                        <strong>
                          Nationwide Building Society insurance accident support{" "}
                        </strong>
                        without burdening you with excess payments
                      </p>
                      <hr />
                      <p className="bold-text">
                        No claims bonus remains protected{" "}
                      </p>
                      <p className="bottom-te">
                        When you call us at our accident number{" "}
                        <a href="tel:0800 772 0850">0800 772 0850</a>, you can
                        rest assured that your hard-earned discounts will remain
                        intact.{" "}
                      </p>
                      <hr />
                      <p className="bold-text">Professional standards </p>
                      <p className="bottom-te">
                        We provide professional{" "}
                        <strong>
                          Nationwide Building Society insurance accident support{" "}
                        </strong>
                        services to secure maximum compensation for your{" "}
                        <strong>Nationwide Building Society</strong> insurance
                        accident claim.
                      </p>
                      <hr />
                      <p className="bold-text">Experienced solicitors </p>
                      <p className="bottom-te">
                        We have a strong network of highly experienced and
                        professional solicitors in the UK who will handle your
                        claim as efficiently as possible.
                      </p>
                      <hr />
                      <p className="bold-text">Free initial consultation </p>
                      <p className="bottom-te">
                        Our solicitors will assess your claim, offer
                        professional advice and guide you throughout the claims
                        process at no cost to you.
                      </p>
                      <hr />
                      <p className="bold-text">
                        Industry-leading experience and expertise{" "}
                      </p>
                      <p className="bottom-te">
                        Our vast experience in handling{" "}
                        <strong>
                          Nationwide Building Society insurance accident claims{" "}
                        </strong>
                        has made us true experts. When you call us at the{" "}
                        <strong>
                          Nationwide Building Society accident claims number
                        </strong>
                        , we will achieve higher compensation for you compared
                        to other accident management companies.{" "}
                      </p>
                      <hr />
                      <p className="bold-text">
                        Strong network of repair and credit hire partners{" "}
                      </p>
                      <p className="bottom-te">
                        From top-notch repairers to reputed credit hire
                        companies and experienced solicitors, we have a strong
                        network of reliable partners who help us provide
                        high-quality services.
                      </p>
                      <hr />
                      <p className="bold-text">Fast claim settlement </p>
                      <p className="bottom-te">
                        Call us at the{" "}
                        <strong>
                          Nationwide Building Society accident claims helpline
                        </strong>{" "}
                        to start your claim with us. With our expedited claims
                        process, we ensure your{" "}
                        <strong>
                          Nationwide Building Society insurance accident claim{" "}
                        </strong>
                        is settled promptly and without hassle.{" "}
                      </p>
                      <hr />
                      <p className="bold-text">
                        Immediate replacement vehicle{" "}
                      </p>
                      <p className="bottom-te">
                        We will supply a like-for-like replacement vehicle the
                        same day to ensure you continue your routine activities
                        without interruption.
                      </p>
                      <hr />

                      <p className="bold-text">
                        Hassle-free and easy vehicle storage{" "}
                      </p>
                      <p className="bottom-te">
                        We have highly safe and secure storage yards to store
                        your damaged vehicle to prevent further deterioration
                        and damage.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="cl4" ref={sidebarRef}>
                  <Sidebarinner />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="photographs">
        <div className="container">
          <div className="row">
            <h2 className="road">
              We handle your claim while <br />
              <span className="light">you sit back and relax </span>{" "}
            </h2>
            <div className="col-xxl-6 col-xl-6 col-md-12 col-12">
              <div className="fourbox">
                <ul>
                  <li>
                    <img src={check} className="check" alt="" />
                    Following a no-fault car accident, when you contact us at
                    our car accident claims helpline{" "}
                    <a href="tel:0800 772 0850">0800 772 0850</a>, we will
                    record the accident details and provide immediate
                    assistance.
                  </li>
                  <hr />
                  <li>
                    <img src={check} className="check" alt="" />
                    Our experienced solicitors will analyse and review your{" "}
                    <strong>
                      Nationwide Building Society insurance accident claim{" "}
                    </strong>
                    and assist you in exploring the options available to pursue
                    a successful claim.
                  </li>
                  <hr />
                  <li>
                    <img src={check} className="check" alt="" />
                    Call us at the{" "}
                    <strong>
                      Nationwide Building Society accident claims number
                    </strong>
                    . We will quickly provide you with a replacement car for the
                    duration of your car repairs.
                  </li>
                  <hr />
                  <li>
                    <img src={check} className="check" alt="" />
                    We are experts in handling{" "}
                    <strong>
                      Nationwide Building Society insurance accident claims{" "}
                    </strong>
                    from start to finish. From managing paperwork to ensuring
                    quick settlement, we are your trusted partners along the
                    way.
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-xxl-6 col-xl-6 col-md-12 col-12">
              <div className="fourbox">
                <ul>
                  <li>
                    <img src={check} className="check" alt="" />
                    We will handle the entire claim process so that you can
                    recover quickly and get back on track.
                  </li>
                  <hr />
                  <li>
                    <img src={check} className="check" alt="" />
                    We negotiate fair settlement offers to secure maximum
                    compensation for your injuries and losses.
                  </li>
                  <hr />
                  <li>
                    <img src={check} className="check" alt="" />
                    Our accident management team will coordinate with trusted
                    repairers and garages to ensure high-quality repairs.
                  </li>
                  <hr />
                  <li>
                    <img src={check} className="check" alt="" />
                    We will safeguard your insurance rights throughout the{" "}
                    <strong>
                      Nationwide Building Society insurance accident claim
                    </strong>{" "}
                    process, providing you with peace of mind.
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="carprocess">
        <div className="container ">
          <div className="row">
            <div className="col-xxl-12 col-xxl-12 col-md-12 col-12">
              <h2 className="trusted-title">
                {" "}
                Nationwide Building Society <br />
                <span className="light">insurance support services </span>{" "}
              </h2>
            </div>
          </div>
          <div className="row" id="possible">
            <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-12">
              <div className="claim">
                <ul>
                  <li>
                    <span className="sleej">Vehicle recovery</span> <br />
                    When you contact us at the{" "}
                    <strong>
                      Nationwide Building Society accident claims number
                    </strong>
                    , you get access to speedy and professional vehicle recovery
                    services to move your damaged vehicle to a safe location.
                  </li>
                  <li>
                    <span className="sleej">Vehicle repairs </span>
                    <br />
                    Want top-notch repairs for your damaged vehicle? Get in
                    touch with us at the{" "}
                    <strong>
                      Nationwide Building Society accident number.
                    </strong>{" "}
                    We have a vast network of approved repaired networks in the
                    UK that meet the highest standards of quality.
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-12">
              <div className="claim" id="blue">
                <ul>
                  <li>
                    <span className="sleej">Vehicle storage </span> <br />
                    After a no-fault car accident, your vehicle must be stored
                    in a safe place to avoid further damage. We will recover
                    your vehicle from the accident scene as soon as you call the{" "}
                    <strong>
                      Nationwide Building Society accident claims number
                    </strong>{" "}
                    and store it in our safe storage facilities until
                    assessment.
                  </li>
                  <li>
                    <span className="sleej">Replacement car </span> <br />
                    You’re entitled to a courtesy car following a no-fault car
                    accident. We will provide you with a temporary vehicle to
                    get you back on the road. With our like-for-like replacement
                    vehicle, you can continue with your daily tasks and
                    responsibilities without disruption. Simply call us at the
                    Report{" "}
                    <strong>
                      Nationwide Building Society accident contact number{" "}
                    </strong>
                    and we will arrange a replacement car for you within 24
                    hours.
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-12">
              <div className="claim">
                <ul>
                  <li>
                    <span className="sleej">Injury claim </span>
                    <br />
                    In case you've sustained injuries as a result of the
                    accident, we will help you make a successful claim. Our
                    claims department{" "}
                    <strong>Nationwide Building Society insurance</strong> will
                    support you through every step of the process and help you
                    get the rightful compensation.
                  </li>
                  <li>
                    <span className="sleej">Claims management </span> <br />
                    From initial documentation to claims settlement, we will
                    handle every aspect of your <strong></strong>Nationwide
                    Building Society insurance accident claim to make the
                    process stress-free.
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="yellowcta">
        <div className="container">
          <div className="row">
            <div className="cta2">
              <div className="col-xxl-8 col-xl-8 col-lg-8 col-12">
                <div className="legal">
                  <h3 className="experienced">
                    Need further assistance?{" "}
                    <span className="light"> Speak to our experts </span>{" "}
                  </h3>
                  <p className="professionals">
                    Reach out to our dedicated team through our accident claims
                    number <a href="tel:0800 772 0850">0800 772 0850</a>
                    to discuss your claim and know your options to get the
                    maximum compensation.
                  </p>
                </div>
              </div>
              <div className="col-xxl-4 col-xl-4 col-lg-4 col-12">
                <div class="process">
                  <a class="tf-button style-1 phone" href="tel:0800 772 0850">
                    Get in touch<span class="icon-keyboard_arrow_right"></span>
                  </a>
                </div>
                <div class="process">
                  <a
                    className="tf-button style-1 desktop"
                    onClick={handleScrollToSidebar}
                  >
                    Get in touch
                    <span className="icon-keyboard_arrow_right"></span>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <search className="updatedform">
        <div className="container">
          <div className="row text-center">
            <div className="col-xxl-12 col-xl-12 col-md-12 col-12">
              <h2 className="whiteheading">
                Why choose us for your <br />{" "}
                <span className="light">accident management needs </span>
              </h2>
              <p className="sustained">
                We provide the best accident management solutions without any
                extra cost to help you hit the road again. From the moment you
                report the{" "}
                <strong>Nationwide Building Society road traffic </strong>
                accident to us, our dedicated team will take charge and manage
                everything from start to finish. Call us at the{" "}
                <strong>
                  Nationwide Building Society accident claims
                </strong>{" "}
                number to begin your claim with us
              </p>
            </div>
          </div>
          <div className="row">
            <div className="col-xxl-4 col-xl-4 col-lg-6 col-md-12 col-12">
              <div className="Claimant">
                <h5>Comprehensive services </h5>
                <p className="collision">
                  From vehicle recovery to replacement car and claims
                  management, we offer a wide spectrum of accident management
                  solutions.
                </p>

                <h5>No excess to pay </h5>
                <p className="collision">
                  We will recover all the expenses from the at-fault driver’s
                  insurance company. So, you don’t have to pay any excess.
                </p>

                <h5>Quick vehicle recovery </h5>
                <p className="collision">
                  Our team is available round the clock to promptly assist you
                  with your vehicle recovery needs.
                </p>
              </div>
            </div>
            <div className="col-xxl-4 col-xl-4 col-lg-6 col-md-12 col-12">
              <div className="Claimant">
                <h5>High-quality repairs </h5>
                <p className="collision">
                  We will ensure that your vehicle receives repairs of the
                  highest standards using original parts.
                </p>

                <h5>Comparable replacement </h5>
                <p className="collision">
                  We will provide you with a replacement vehicle similar to your
                  own in terms of make, model and size.
                </p>

                <h5>Easy and fast claim processing </h5>
                <p className="collision">
                  With our streamlined claims process, we strive to get you the
                  resolution in the shortest possible time.
                </p>
              </div>
            </div>
            <div className="col-xxl-4 col-xl-4 col-lg-12 col-md-12 col-12">
              <div className="Claimant">
                <h5>Dedicated claims handler </h5>
                <p className="collision">
                  We will assign you a dedicated claims handler who will serve
                  as your single point of contact throughout the claim process.
                </p>

                <h5>Insurance Liaison </h5>
                <p className="collision">
                  We will communicate with all the insurance parties to save you
                  from the hassle and stress.
                </p>

                <h5>Post-repair inspection </h5>
                <p className="collision">
                  After completion of your vehicle repairs, we will conduct a
                  post-repair inspection of your vehicle to verify the quality
                  of the repairs.
                </p>
              </div>
            </div>
          </div>
        </div>
      </search>

      <section className="Accident-claim">
        <div className="container">
          <div className="row">
            <div className="needed">
              <h2 className="trusted-title">
                How to increase the chances of <br />
                <span className="light">receiving fair compensation </span>
              </h2>
              <p className="complexities">
                <strong>
                  {" "}
                  Nationwide Building Society road traffic accidents{" "}
                </strong>
                can be stressful and might leave you wondering how to handle
                unexpected costs. Here are a few steps you must follow to the
                chances of obtaining full compensation for your losses and
                injuries.
              </p>
            </div>
            <hr />
          </div>
          <div className="row" id="incdes">
            <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-12">
              <div className="Preparing">
                <ul>
                  <li>
                    <div className="seemingly">
                      <h5 className="Seek">Check the injuries </h5>
                      <p>
                        Move out of the traffic and check yourself and the
                        passengers involved for any major or minor injuries.{" "}
                      </p>
                    </div>
                  </li>
                  <li>
                    <div className="seemingly">
                      <h5 className="Seek">Seek medical attention </h5>
                      <p>
                        Immediately seek medical help from a qualified medical
                        professional and get your injuries treated without any
                        delay.
                      </p>
                    </div>
                  </li>
                  <li>
                    <div className="seemingly">
                      <h5 className="Seek">Call emergency help </h5>
                      <p>
                        Contact us via the{" "}
                        <strong>
                          Nationwide Building Society car accident claims
                          helpline{" "}
                        </strong>
                        to get emergency assistance and get back on track
                        swiftly and safely.
                      </p>
                    </div>
                  </li>
                  <li>
                    <div className="seemingly">
                      <h5 className="Seek">
                        Report the accident to the police{" "}
                      </h5>
                      <p>
                        Call the police and report the Nationwide Building
                        Society road traffic accident as soon as possible. They
                        will thoroughly document the accident and will take
                        adequate measures to address the situation.
                      </p>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
            <div
              className="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-12"
              id="dualcardiv"
            >
              <img src={dualcar} alt="" className="dualcar" />
            </div>
            <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-12">
              <div className="Preparing">
                <ul>
                  <li>
                    <div className="seemingly">
                      <h5 className="Seek">Collect evidence </h5>
                      <p>
                        Make sure to collect vital information from the accident
                        scene that will help you prove your innocence. While
                        waiting for the police, gather crucial evidence such as
                        photographs of the accident scene, vehicle involved and
                        eyewitness statements.
                      </p>
                    </div>
                  </li>
                  <li>
                    <div className="seemingly">
                      <h5 className="Seek"> Document vehicle damage </h5>
                      <p>
                        Before getting your vehicle repaired, take pictures of
                        the damaged vehicle to assess the damage. This will help
                        you prove the other driver’s fault.
                      </p>
                    </div>
                  </li>
                  <li>
                    <div className="seemingly">
                      <h5 className="Seek">Exchange information </h5>
                      <p>
                        Exchange information with the other driver, including
                        name, contact information, insurance details and other
                        driver’s vehicle details.
                      </p>
                    </div>
                  </li>
                  <li>
                    <div className="seemingly">
                      <h5 className="Seek">Start your claim</h5>
                      <p>
                        Call us at our accident claims number{" "}
                        <a href="tel:0800 772 0850">0800 772 0850</a> to
                        initiate your no-fault claim. We will assign you a
                        dedicated claims handler who will oversee every aspect
                        of your claim.{" "}
                      </p>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="whyus">
        <div className="container">
          <div className="row">
            <div className="col-xxl-12 col-md-12">
              <div className="why">
                <h2 className="choose">
                  Simplified claim process for{" "}
                  <span className="light">
                    <br /> smooth claim resolution{" "}
                  </span>
                </h2>
                <p className="complexities">
                  Making a no-fault claim involves complicated paperwork and
                  long wait times. With our streamlined and effortless process,
                  you can submit your claim easily and quickly without any
                  hassle. Contact us at the{" "}
                  <strong>
                    Nationwide Building Society car accident claims helpline{" "}
                  </strong>
                  to start your claim.
                </p>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-xxl-3 col-xl-6 col-lg-6 col-md-6 col-12">
              <div className="box-service">
                <img src={check} className="check" alt="" />
                <h4>Contact us </h4>
                <p>
                  Reach out to our team via the Nationwide Building Society
                  accident number to begin your claims with us.
                </p>
              </div>
            </div>
            <div className="col-xxl-3 col-xl-6 col-lg-6 col-md-6 col-12">
              <div className="box-service">
                <img src={check} className="check" alt="" />
                <h4>Provide information </h4>
                <p>
                  Share the details of the accident with us, including the date,
                  time, location and description of what happened.
                </p>
              </div>
            </div>
            <div className="col-xxl-3 col-xl-6 col-lg-6 col-md-6 col-12">
              <div className="box-service">
                <img src={check} className="check" alt="" />
                <h4>Submit documentation </h4>
                <p>
                  Submit relevant documentation that you have such as photos of
                  the damaged vehicle, police reports or eyewitness statements.
                </p>
              </div>
            </div>
            <div className="col-xxl-3 col-xl-6 col-lg-6 col-md-6 col-12">
              <div className="box-service">
                <img src={check} className="check" alt="" />
                <h4>Claim confirmation and resolution </h4>
                <p>
                  Once we have all the documents with us, we will assess your
                  claim and provide a confirmation receipt. When your claim gets
                  processed, you’ll receive deserving compensation.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="faqs">
        <div className="container">
          <div className="row">
            <div className="col-xxl-12 col-xl-12 col-md-12 col-12">
              <div className="fq">
                <h2>
                  {" "}
                  Frequently Asked{" "}
                  <span className="light">Questions (FAQ)</span>
                </h2>
                <p className="complexities">
                  Got questions about the Nationwide Building Society insurance
                  accident claim? We have them answered here.{" "}
                </p>
              </div>
              <div className="allfaq">
                <Accordion defaultActiveKey="0">
                  <Accordion.Item eventKey="0">
                    <Accordion.Header>
                      Am I eligible to make a claim for minor injuries sustained
                      due to the accident?
                    </Accordion.Header>
                    <Accordion.Body>
                      Yes. You’re eligible to make a claim for minor injuries
                      such as soft tissue injuries, minor fractures and crush
                      injuries. Our personal injury solicitors will assess your
                      situation and advise you whether your injury qualifies for
                      a claim or not.
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="1">
                    <Accordion.Header>
                      Can I get my vehicle repaired from my preferred garage?
                    </Accordion.Header>
                    <Accordion.Body>
                      Absolutely. You can share the details of your preferred
                      repairer with us and we will schedule the repairs of your
                      vehicle from the garage of your choice.
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="2">
                    <Accordion.Header>
                      Can you help if my vehicle is immobile after a no-fault
                      accident?
                    </Accordion.Header>
                    <Accordion.Body>
                      If you’ve been a victim of a no-fault car accident and
                      your vehicle is immobile, we will arrange for the recovery
                      of your vehicle. Contact us via the Nationwide Building
                      Society car accident claims helpline and our team will
                      ensure quick recovery of the vehicle to a safe and secure
                      place until further assessment.
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="3">
                    <Accordion.Header>
                      For how long can you store my vehicle in your secure
                      yards?
                    </Accordion.Header>
                    <Accordion.Body>
                      We will store your vehicles in our yards until your
                      vehicle gets repaired or is entirely written off.
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="4">
                    <Accordion.Header>
                      Will my accident premium be affected as a result of a
                      no-fault claim?
                    </Accordion.Header>
                    <Accordion.Body>
                      No. We won’t initiate a claim against your own insurance
                      policy which means that your insurance premium and no
                      claim bonus won’t get affected.
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="5">
                    <Accordion.Header>
                      What happens if my car is written off?
                    </Accordion.Header>
                    <Accordion.Body>
                      If your car is entirely written off, we will provide you
                      with a replacement vehicle that you can keep until you
                      receive full payment for your damaged vehicle.
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="6">
                    <Accordion.Header>
                      Do I need to notify my insurer?
                    </Accordion.Header>
                    <Accordion.Body>
                      No. We will handle that for you. We will inform your
                      insurer on your behalf and will remain in touch with you
                      and your insurer throughout the process.
                    </Accordion.Body>
                  </Accordion.Item>
                </Accordion>
              </div>
            </div>
          </div>
        </div>
      </section>

      <Footer />
    </>
  );
};

export default Nationwide;
