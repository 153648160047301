import React, { useRef } from "react";
import Accordion from "react-bootstrap/Accordion";
import check from "../assets/images/check.png";
import dualcar from "../../src/assets/images/dualcar.webp";
import carbnr1 from "../../src/assets/images/carbnr1.webp";
import Sidebarinner from "../components/Sidebar-inner";
import phonecall from "../assets/images/phonecall.png";
import Footer from "../components/footer";
import { Helmet } from "react-helmet";

const Ladybirdinsurance = () => {
  const sidebarRef = useRef(null);

  const handleScrollToSidebar = () => {
    if (sidebarRef.current) {
      sidebarRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Ladybird Car Insurance Accident Claim Phone Number:</title>
        <meta
          name="description"
          content="Contact our team via the Ladybird Car Insurance accident claim phone number 0800 772 0850 for dedicated claim support from experienced solicitors in the UK. "
        />
        <link
          rel="canonical"
          href="https://insurance-accident-claims.co.uk/ladybird-insurance"
        />
      </Helmet>
      <section className="innernav">
        <div className="container">
          <div className="row">
            <div className="col-md-6 col-sm-6">
              <div className="left-logo">
                <h3>
                  Accident<span className="sipo"> Claims</span>
                </h3>
              </div>
            </div>
            <div className="col-md-6 col-sm-6">
              <div className="left-cta">
                <div class="headercnt">
                  <div class="chatmsg">
                    <img
                      src={phonecall}
                      className="phonecall"
                      alt="phonecall "
                    />
                  </div>
                  <div class="box-body">
                    <h3 class="nav-info-box-title">Toll Free Number </h3>
                    <p>
                      <a href="tel:0800 772 0850">0800 772 0850</a>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section
        className="newbnr"
        style={{ backgroundImage: "url(" + carbnr1 + ")" }}
      >
        <div className="container">
          <div className="row">
            <div className="col-xxl-8 col-xl-8 col-md-10 col-12">
              <div className="innerbnr">
                <h1>
                  <span className="onespan">Car insurance </span>{" "}
                  <span className="sci">  claims </span>management services
                </h1>
                <p className="brokerage">
                  From recovering your vehicle to submitting a non-fault claim,
                  we have handled all your crash management requirements.
                </p>
                <div className="bnrbtn">
                  <a class="db-btn-border btn-rollover phone" href="tel:0800 772 0850">
                    Road Accident Claim Help?{" "}
                    <i class="fa-solid fa-arrow-right"></i>
                  </a>
                </div>
                <div className="bnrbtn">
                <a className="db-btn-border btn-rollover desktop"  onClick={handleScrollToSidebar}>
                    Road Accident Claim Help? <i className="fa-solid fa-arrow-right"></i>
                </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="stickysection">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="group-4-8">
                <div className="cl8 tf-tab">
                  <div className="content-tab">
                    <div className="inner-content">
                      <h2>
                        We offer dedicated free
                        <br />
                        <span className="light">claims support </span>{" "}
                      </h2>
                      <p className="disciplines">
                        We are accident specialists specialising in helping
                        drivers in the UK who have suffered any road traffic
                        accident that was not their fault. From dealing with
                        insurers to getting you back on the road, we've got it
                        covered. Call us at{" "}
                        <strong> Ladybird car insurance claim number </strong>{" "}
                        for a claims journey devoid of hassle from inception
                        till completion.
                      </p>
                      <hr />
                      <p className="bold-text"> No additional cost </p>
                      <p className="bottom-te">
                        Our services come along with handling the entire{" "}
                        <strong> Ladybird car insurance claim </strong> without
                        additional fees or upfront charges. You don’t need to
                        pay thousands of pounds if you choose us for accident
                        management and{" "}
                        <strong> Ladybird insurance claims </strong> assistance.
                      </p>
                      <hr />
                      <p className="bold-text">Approved repairers</p>
                      <p className="bottom-te">
                        Through our network of approved vehicle repairers that
                        utilise genuine parts, we will quickly organise repairs
                        for your damaged vehicle.
                      </p>
                      <hr />
                      <p className="bold-text">NCB protection</p>
                      <p className="bottom-te">
                        We focus primarily on protecting your no-claims bonus,
                        which is why our no-fault claim experts handle{" "}
                        <strong>Ladybird insurance claims </strong>
                        with professional ease so that you do not lose any
                        hard-earned discounts and NCB.
                      </p>
                      <hr />
                      <p className="bold-text">
                        Comparable replacement vehicle{" "}
                      </p>
                      <p className="bottom-te">
                        Contact us on the{" "}
                        <strong>Ladybird insurance claim number,</strong>
                        and we will get you back on track soon after an accident
                        by offering a similar-sized replacement vehicle.{" "}
                      </p>
                      <hr />
                      <p className="bold-text">Quick car recovery </p>
                      <p className="bottom-te">
                        We have emergency roadside service all day, every day,
                        to ensure that you are not off the road for long. You
                        can reach us at our car insurance claim number{" "}
                        <a href="tel:0800 772 0850">0800 772 0850</a> to have
                        your vehicle recovered faster in case of an accident.
                      </p>
                      <hr />
                      <p className="bold-text">Experts advice</p>
                      <p className="bottom-te">
                        Our solicitors are top in their industry, and they give
                        advice based on <strong> Ladybird motor claims </strong>{" "}
                        as well as help with compensation for damages caused.
                      </p>
                      <hr />
                      <p className="bold-text"> Independent and trusted </p>
                      <p className="bottom-te">
                        We aim to stay independent throughout the whole process
                        of <strong> Ladybird car insurance claims </strong>by
                        offering impartial assistance.
                      </p>
                      <hr />
                      <p className="bold-text">Speedy claim settlement</p>
                      <p className="bottom-te">
                        Owing to our simple and straightforward process, our
                        clients’ <strong> Ladybird motor claims </strong>
                        are settled within a short span of time, making them get
                        back in line quickly.{" "}
                      </p>
                      <hr />
                      <p className="bold-text">Free advice</p>
                      <p className="bottom-te">
                        It is possible for our solicitors to give free advice
                        about what chances or rights you have prior to any
                        financial obligations being met so that one can make
                        informed choices.{" "}
                      </p>
                      <hr />

                      <p className="bold-text"> Experienced claims handler </p>
                      <p className="bottom-te">
                        We will assign one claims handler, who will handle all
                        communications with you and guide you in selecting
                        various options for pursuing the{" "}
                        <strong> Ladybird motor claim. </strong>
                      </p>
                    </div>
                  </div>
                </div>
                <div className="cl4" ref={sidebarRef}>
                  <Sidebarinner />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="photographs">
        <div className="container">
          <div className="row">
            <h2 className="road">
              We take the hassle out of dealing{" "}
              <span className="light">
                with <br />
                your car accident
              </span>{" "}
            </h2>
            <div className="col-xxl-6 col-xl-6 col-md-12 col-12">
              <div className="fourbox">
                <ul>
                  <li>
                    <img src={check} className="check" alt="" />
                    We cover everything from instant notification of claims and
                    arranging a courtesy vehicle up to ensuring high-quality
                    repair works.
                  </li>
                  <hr />
                  <li>
                    <img src={check} className="check" alt="" />
                    If your vehicle cannot be driven, we can arrange prompt
                    accident recovery when you phone the{" "}
                    <strong> Ladybird car insurance claim number. </strong>
                  </li>
                  <hr />
                  <li>
                    <img src={check} className="check" alt="" />
                    From the beginning of{" "}
                    <strong> Ladybird car insurance claims </strong>to its
                    settlement, we shall handle everything.
                  </li>
                  <hr />
                  <li>
                    <img src={check} className="check" alt="" />
                    Contact us through our{" "}
                    <strong> Ladybird car insurance claim number </strong> so
                    that we may provide a comparable replacement vehicle while
                    yours is off-road.
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-xxl-6 col-xl-6 col-md-12 col-12">
              <div className="fourbox">
                <ul>
                  <li>
                    <img src={check} className="check" alt="" />
                    We shall direct you to our pool of experienced solicitors so
                    that your claim gets dealt with properly.
                  </li>
                  <hr />
                  <li>
                    <img src={check} className="check" alt="" />
                    Insurance companies, repair garages and legal entities are
                    all included in our team’s coordinating role in your{" "}
                    <string> Ladybird motor claims. </string>
                  </li>
                  <hr />
                  <li>
                    <img src={check} className="check" alt="" />
                    We will assist you to claim the cost and damages from the
                    insurer of the at-fault party.
                  </li>
                  <hr />
                  <li>
                    <img src={check} className="check" alt="" />
                    Throughout your{" "}
                    <strong> Ladybird car insurance claim </strong> process, you
                    will be given personalised guidance and support by our
                    dedicated claims handler.
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="carprocess">
        <div className="container ">
          <div className="row">
            <div className="col-xxl-12 col-xxl-12 col-md-12 col-12">
              <h2 className="trusted-title">
                {" "}
                A comprehensive range of accident <br />
                <span className="light">management services</span>{" "}
              </h2>
            </div>
          </div>
          <div className="row" id="possible">
            <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-12">
              <div className="claim">
                <ul>
                  <li>
                    <span className="sleej">Car recovery:</span>If you are left
                    stranded with a car that cannot move on the road, call our
                    team on the{" "}
                    <strong> Ladybird car insurance claim number. </strong> We
                    shall send out recovery agents to recover your vehicle
                    without any excess on your policy as soon as possible.
                  </li>
                  <li>
                    <span className="sleej">Vehicle repairs:</span>Our accident
                    repair centres handle a wide variety of car repairs. You can
                    rely on us for excellent work using genuine parts.
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-12">
              <div className="claim" id="blue">
                <ul>
                  <li>
                    <span className="sleej">Vehicle storage :</span> When you
                    dial the <strong> Ladybird insurance claim number, </strong>
                    we pick up your car and keep it safe in storage until your
                    claim is assessed. Our secure CCTV-monitored storage
                    facilities will give additional protection against break-ins
                    or vandalism of your vehicle, thereby averting damage to it.
                  </li>
                  <li>
                    <span className="sleej">Replacement vehicle :</span>Getting
                    into road traffic accidents can be very disruptive to one’s
                    life. We can help you get back to normal by providing you
                    with a replacement vehicle that is the same model as your
                    own. If you want a replacement car, call us on the{" "}
                    <strong> Ladybird car insurance claim number </strong> and
                    avoid this inconvenience.
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-12">
              <div className="claim">
                <ul>
                  <li>
                    <span className="sleej">Personal injury support:</span>
                    Have you suffered any injuries as a result of the accident?
                    Let our dedicated professionals assist in securing worthy
                    compensation for the bodily harm and losses incurred. To
                    begin, contact our team via the
                    <strong> Ladybird insurance claim number. </strong>
                  </li>
                  <li>
                    <span className="sleej">Claims management:</span>We are your
                    trusted people all through the process of claim initiation
                    to settlement, assisting you every step of the way.
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="yellowcta">
        <div className="container">
          <div className="row">
            <div className="cta2">
              <div className="col-xxl-8 col-xl-8 col-lg-8 col-12">
                <div className="legal">
                  <h3 className="experienced">
                    Got more questions?{" "}
                    <span className="light">Talk to our specialists </span>{" "}
                  </h3>
                  <p className="professionals">
                    Get in touch with our no-fault claim experts via{" "}
                    <strong>
                      car insurance claim number{" "}
                      <a className="cpt-no" href="tel:0800 772 0850">
                        {" "}
                        0800 772 0850{" "}
                      </a>{" "}
                    </strong>
                    to get the best advice regarding{" "}
                    <strong>Ladybird insurance claims.</strong>
                  </p>
                </div>
              </div>
              <div className="col-xxl-4 col-xl-4 col-lg-4 col-12">
              <div class="process">
                  <a class="tf-button style-1 phone" href="tel:0800 772 0850">
                    Get in touch<span class="icon-keyboard_arrow_right"></span>
                  </a>
                </div>
                <div class="process">
                    <a className="tf-button style-1 desktop"  onClick={handleScrollToSidebar}>
                        Get in touch<span className="icon-keyboard_arrow_right"></span>
                    </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <search className="updatedform">
        <div className="container">
          <div className="row text-center">
            <div className="col-xxl-12 col-xl-12 col-md-12 col-12">
              <h2 className="whiteheading">
                {" "}
                Why do we stand out <br />
                <span className="light">from others? </span>
              </h2>
              <p className="sustained">
                Throughout the whole{" "}
                <strong> Ladybird car insurance claim </strong>process, we have
                control over everything after an accident so as to ensure the
                best outcomes for <strong> Ladybird motor claims. </strong>
              </p>
            </div>
          </div>
          <div className="row">
            <div className="col-xxl-4 col-xl-4 col-lg-6 col-md-12 col-12">
              <div className="Claimant">
                <h5> Round-the-clock assistance </h5>
                <p className="collision">
                  Accidents don’t choose suitable times to happen. So we are
                  open twenty-four hours to give roadside aid immediately after
                  no-fault accidents.
                </p>

                <h5>Stress-free claims management</h5>
                <p className="collision">
                  If you would like to make a claim today, please call{" "}
                  <a href="tel:0800 772 0850">0800 772 0850</a> now and talk
                  with our team. We will walk with you through each stage and
                  cater to all the intricacies involved until your case is
                  closed.
                </p>

                <h5> Claims expert </h5>
                <p className="collision">
                  We will assign you a claims expert who can handle paperwork
                  and phone calls and make decisions, leaving you free to focus
                  on your recovery.
                </p>
              </div>
            </div>
            <div className="col-xxl-4 col-xl-4 col-lg-6 col-md-12 col-12">
              <div className="Claimant">
                <h5>Best solicitors </h5>
                <p className="collision">
                  We work with experienced and professional solicitors across
                  the country to handle your{" "}
                  <strong> Ladybird insurance claims </strong> efficiently and
                  quickly.
                </p>
                <h5> Safe storage </h5>
                <p className="collision">
                  We will keep the vehicle in our secure compounds until it is
                  repaired or reviewed for a claim.
                </p>

                <h5> Instant replacement vehicle </h5>
                <p className="collision">
                  When an accident occurs and you need another vehicle, we
                  understand how important this is. We have a hotline number for
                  Ladybird Insurance claims that when called upon, we
                  immediately issue a temporary car to keep you moving.{" "}
                </p>
              </div>
            </div>
            <div className="col-xxl-4 col-xl-4 col-lg-12 col-md-12 col-12">
              <div className="Claimant">
                <h5> No waiting period </h5>
                <p className="collision">
                  When you are in the hour of need, we are always beside you.
                  Our response time is usually very swift without any waiting
                  time or unwanted delays.
                </p>

                <h5> Cost-free services </h5>
                <p className="collision">
                  Moreover, we give free assistance on{" "}
                  <strong> Ladybird auto insurance claims </strong> without
                  spending any money out of your pocket.
                </p>

                <h5>Quality repairs</h5>
                <p className="collision">
                  We collaborate with approved repairers who provide a guarantee
                  for the necessary repairs of your car. Call us now at{" "}
                  <strong> Ladybird insurance claims number </strong> if you
                  want genuine repairs done on your car.
                </p>
              </div>
            </div>
          </div>
        </div>
      </search>

      <section className="Accident-claim">
        <div className="container">
          <div className="row">
            <div className="needed">
              <h2 className="trusted-title">
                How can you increase compensation for <br />
                <span className="light"> Ladybird insurance claims?</span>
              </h2>
              <p className="complexities">
                A car accident can be a life-altering incident that is
                unimaginable and stressful. Starting by seeking fair{" "}
                <strong> Ladybird motor claims </strong> is the first step to
                your healing process. Having had a car accident where nobody was
                at fault, here are some of the things you should do in order to
                get maximum compensation.{" "}
              </p>
            </div>
            <hr />
          </div>
          <div className="row" id="incdes">
            <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-12">
              <div className="Preparing">
                <ul>
                  <li>
                    <div className="seemingly">
                      <h5 className="Seek">Seek treatment:</h5>
                      <p>
                        When there has been an accident with no fault from any
                        driver, first inspect yourself and your passengers to
                        identify injuries and take them to the hospital right
                        away.
                      </p>
                    </div>
                  </li>
                  <li>
                    <div className="seemingly">
                      <h5 className="Seek">Note damages: </h5>
                      <p>
                        Keep track of all costs arising from a car accident,
                        such as medical bills, drug fees, transport costs or
                        other receipts.
                      </p>
                    </div>
                  </li>
                  <li>
                    <div className="seemingly">
                      <h5 className="Seek">Don’t settle quickly.:</h5>
                      <p>
                        In most cases, when negotiations commence insurance
                        companies usually start off by offering lower settlement
                        amounts. Never agree on the initial offer made as it may
                        not fully compensate you for your losses and injuries
                        sustained.
                      </p>
                    </div>
                  </li>
                  <li>
                    <div className="seemingly">
                      <h5 className="Seek">
                        {" "}
                        Avoid posting on social networks{" "}
                      </h5>
                      <p>
                        Do not post anything on social media. Your posts can be
                        used against you by the opposing party during settlement
                        discussions for personal injury damages.
                      </p>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
            <div
              className="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-12"
              id="dualcardiv"
            >
              <img src={dualcar} alt="" className="dualcar" />
            </div>
            <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-12">
              <div className="Preparing">
                <ul>
                  <li>
                    <div className="seemingly">
                      <h5 className="Seek">Take photographs and videos:</h5>
                      <p>
                        Record pictures or videos of the accident scene,
                        including the state of vehicles, road marks, scratch
                        marks or any visible dents. The visual records can be
                        very helpful in backing up your claim.
                      </p>
                    </div>
                  </li>
                  <li>
                    <div className="seemingly">
                      <h5 className="Seek"> Get ahold of eye-witnesses </h5>
                      <p>
                        Contact eyewitnesses and take their statements. Their
                        accounts might provide crucial details that will help
                        you win your case.
                      </p>
                    </div>
                  </li>
                  <li>
                    <div className="seemingly">
                      <h5 className="Seek">Consult a solicitor :</h5>
                      <p>
                        A no-fault claims solicitor can enable you to get
                        maximum compensation on{" "}
                        <strong> Ladybird car insurance claims. </strong> If you
                        need advice from our qualified solicitors, reach us via
                        the{" "}
                        <string> Ladybird car insurance claim number. </string>
                      </p>
                    </div>
                  </li>
                  <li>
                    <div className="seemingly">
                      <h5 className="Seek">Start your claim </h5>
                      <p>
                        If you have gathered evidence and collected all the
                        necessary papers, call our team through the{" "}
                        <strong> Ladybird car insurance claim number </strong>{" "}
                        to initiate a non-fault claim.
                      </p>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="whyus">
        <div className="container">
          <div className="row">
            <div className="col-xxl-12 col-md-12">
              <div className="why">
                <h2 className="choose">
                  Four simple steps to making your{" "}
                  <span className="light"> Ladybird motor claims </span>
                </h2>
                <p className="complexities">
                  Car accidents are stressful, but filing a no-fault car
                  accident claim on top is not fun as well! You can make your
                  easy <strong> Ladybird car insurance </strong>claim in 4
                  simple steps with clear processes for claiming benefits
                  without involving long battles dial{" "}
                  <a href="tel:0800 772 0850">0800 772 0850</a> to make your
                  claim today.
                </p>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-xxl-3 col-xl-6 col-lg-6 col-md-6 col-12">
              <div className="box-service">
                <img src={check} className="check" alt="" />
                {/* <img src={experience} className="experience" alt="" /> */}
                <h4>Connect with us</h4>
                <p>
                  Call us at our Ladybird car insurance claims number to report
                  the accident and ask for emergency support.
                </p>
              </div>
            </div>
            <div className="col-xxl-3 col-xl-6 col-lg-6 col-md-6 col-12">
              <div className="box-service">
                <img src={check} className="check" alt="" />
                <h4>Give the accident details</h4>
                <p>
                  Please provide accident details and have your policy number
                  ready when you call our team
                </p>
              </div>
            </div>
            <div className="col-xxl-3 col-xl-6 col-lg-6 col-md-6 col-12">
              <div className="box-service">
                <img src={check} className="check" alt="" />
                <h4>Provide relevant documents</h4>
                <p>
                  Fill in the claim form and attach evidence and documents to
                  substantiate it.{" "}
                </p>
              </div>
            </div>
            <div className="col-xxl-3 col-xl-6 col-lg-6 col-md-6 col-12">
              <div className="box-service">
                <img src={check} className="check" alt="" />
                <h4>Claim settlement</h4>
                <p>
                  Wait for compensation after your claim has been processed.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="faqs">
        <div className="container">
          <div className="row">
            <div className="col-xxl-12 col-xl-12 col-md-12 col-12">
              <div className="fq">
                <h2>
                  {" "}
                  Frequently Asked{" "}
                  <span className="light">Questions (FAQ)</span>
                </h2>
                <p className="complexities">
                  Got questions about the{" "}
                  <strong> Ladybird car insurance claim? </strong>We have them
                  answered here.
                </p>
              </div>
              <div className="allfaq">
                <Accordion defaultActiveKey="0">
                  <Accordion.Item eventKey="0">
                    <Accordion.Header>
                      What does it cost to get help with managing my car
                      accident?
                    </Accordion.Header>
                    <Accordion.Body>
                      No, there will be no extra charges on this one. If you are
                      innocent we shall demand reimbursement from the other
                      party’s insurer as a result of which there should not be
                      any additional expenses on your part.
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="1">
                    <Accordion.Header>
                      Could I get assistance in repairing my vehicle after being
                      involved in an accident?
                    </Accordion.Header>
                    <Accordion.Body>
                      Yes, contact us at{" "}
                      <strong>
                        {" "}
                        the Ladybird car insurance claim number,{" "}
                      </strong>{" "}
                      and we can help arrange guaranteed repairs through our
                      trusted repair network.
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="2">
                    <Accordion.Header>
                      I was involved in a road traffic accident. What next?
                    </Accordion.Header>
                    <Accordion.Body>
                      Contact us immediately on the emergency helpline for{" "}
                      <strong> Ladybird insurance claim </strong> Assistance,
                      and we will get back to you as soon as possible.
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="3">
                    <Accordion.Header>
                      Can you help me arrange my car repair with my chosen
                      repairer?{" "}
                    </Accordion.Header>
                    <Accordion.Body>
                      Sure. You can give us the details of your repairer in
                      order to fix your vehicle for you. We will then deal with
                      them in order to get your vehicle repaired fast and at the
                      highest possible standard.
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="4">
                    <Accordion.Header>
                      What if my vehicle is a write-off?
                    </Accordion.Header>
                    <Accordion.Body>
                      If we find that your car is beyond economical repair, we
                      will negotiate with the other party’s insurance company
                      for equitable compensation for the market value of it as
                      well as any other consequential losses. In addition, we
                      will provide a courtesy car until you receive payment.
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="5">
                    <Accordion.Header>
                      What if I get contacted by a third party after an
                      accident?{" "}
                    </Accordion.Header>
                    <Accordion.Body>
                      If you instruct us on this, transfer the call from their
                      side or their insurers or solicitors towards us. They will
                      be handled by us for you.
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="6">
                    <Accordion.Header>
                      Who would attend to my claim?{" "}
                    </Accordion.Header>
                    <Accordion.Body>
                      Our team will collect all accident information and
                      introduce you to our panel of experienced solicitors
                      specialising in{" "}
                      <strong> Ladybird motor insurance claims, </strong> who
                      will take care of your claim.
                    </Accordion.Body>
                  </Accordion.Item>
                </Accordion>
              </div>
            </div>
          </div>
        </div>
      </section>

      <Footer />
    </>
  );
};

export default Ladybirdinsurance;
