import React, { useRef } from "react";
import Accordion from "react-bootstrap/Accordion";
import check from "../assets/images/check.png";
import dualcar from "../../src/assets/images/dualcar.webp";
import churchill from "../../src/assets/images/churchill.webp";
import Sidebarinner from "../components/Sidebar-inner";
import phonecall from "../assets/images/phonecall.png";
import Footer from "../components/footer";
import { Helmet } from "react-helmet";

const Bymiles = () => {
    const sidebarRef = useRef(null);

    const handleScrollToSidebar = () => {
        if (sidebarRef.current) {
            sidebarRef.current.scrollIntoView({ behavior: "smooth" });
        }
    };
    return (
        <>
        <Helmet>
        <meta charSet="utf-8" />
        <title>By Miles Car Insurance Accident Claim Phone Number</title>
        <meta
          name="description"
          content="Experienced a no-fault accident? We’re here to provide quick and efficient claims support. Call us on the By Miles Car Insurance Accident Claim Phone Number 0800 772 0850. "
        />
        <link
          rel="canonical"
          href="https://insurance-accident-claims.co.uk/Bymiles"
        />
      </Helmet>
            <section className="innernav">
                <div className="container">
                    <div className="row">
                        <div className="col-md-6 col-sm-6">
                            <div className="left-logo">
                                <h3>
                                    Accident<span className="sipo"> Claims</span>
                                </h3>
                            </div>
                        </div>
                        <div className="col-md-6 col-sm-6">
                            <div className="left-cta">
                                <div class="headercnt">
                                    <div class="chatmsg">
                                        <img
                                            src={phonecall}
                                            className="phonecall"
                                            alt="phonecall "
                                        />
                                    </div>
                                    <div class="box-body">
                                        <h3 class="nav-info-box-title">Toll Free Number </h3>
                                        <p>
                                            <a href="tel:0800 772 0850">0800 772 0850</a>
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section
                className="newbnr"
                style={{ backgroundImage: "url(" + churchill + ")" }}
            >
                <div className="container">
                    <div className="row">
                        <div className="col-xxl-7 col-xl-7 col-md-10 col-12">
                            <div className="innerbnr">
                                <h1>
                                    <span className="onespan">Speedy and dependable </span>{" "}
                                    <span className="sci">no-fault claims </span> assistance
                                </h1>
                                <p className="brokerage">
                                    We are here to support you from the moment you seek assistance until the time you get back on track after a no-fault car accident.

                                </p>
                                <div className="bnrbtn">
                                    <a class="db-btn-border btn-rollover phone" href="tel:0800 772 0850">
                                        Road Accident Claim Help?{" "}
                                        <i class="fa-solid fa-arrow-right"></i>
                                    </a>
                                </div>
                                <div className="bnrbtn">
                                    <a className="db-btn-border btn-rollover desktop" onClick={handleScrollToSidebar}>
                                        Road Accident Claim Help? <i className="fa-solid fa-arrow-right"></i>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="stickysection">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="group-4-8">
                                <div className="cl8 tf-tab">
                                    <div className="content-tab">
                                        <div className="inner-content">
                                            <h2>
                                                We’re experts in handling no-fault claims
                                                <br />
                                                <span className="light"> in the UK </span>{" "}
                                            </h2>
                                            <p className="disciplines">
                                                Having handled thousands of cases successfully, we are among the leading providers of <strong>
                                                    {" "}
                                                    By Miles insurance claims
                                                </strong>{" "}
                                                throughout the UK. If you wish to file a claim, contact us on By Miles car insurance contact number. We will process your
                                                <strong>
                                                    {" "}
                                                    By Miles car insurance claims
                                                </strong>{" "}
                                                at no fee.
                                            </p>
                                            <hr />
                                            <p className="bold-text">No extra charges or excess</p>
                                            <p className="bottom-te">
                                                In other words, if it was not your fault that caused the accident,  there will be no more costs, resulting in an insurance excess that can drive up your premiums.                                    </p>
                                            <hr />
                                            <p className="bold-text">No effect on no-claims bonuses</p>
                                            <p className="bottom-te">
                                                You do not have to lose your no-claims bonus if you use our services to make a claim, as we won’t make a claim against your own insurance policy.                                            </p>
                                            <hr />
                                            <p className="bold-text">
                                                Quick recovery
                                            </p>
                                            <p className="bottom-te">
                                                Make a call on <strong> By Miles car insurance contact number </strong> emergency line, and we shall recover your vehicle promptly from the accident scene.                                          </p>
                                            <hr />
                                            <p className="bold-text">Approved repair garages</p>
                                            <p className="bottom-te">
                                                We will make sure your car is brought to the required standards via approved manufacturer garages.                                            </p>
                                            <hr />
                                            <p className="bold-text">Like-for-like replacement</p>
                                            <p className="bottom-te">
                                                At times in the event of an accident or when your vehicle is not roadworthy, you can get a similar car as yours.                                           </p>
                                            <hr />
                                            <p className="bold-text">Seasoned legal team</p>
                                            <p className="bottom-te">
                                                Our skilled solicitors are knowledgeable on how to go about <strong> By Miles insurance claims </strong> and have helped victims of no-fault accidents secure fair compensation.                                             </p>
                                            <hr />
                                            <p className="bold-text">Speedy claims processing</p>
                                            <p className="bottom-te">
                                                When you claim with us, paperwork should be the last thing on your mind. Our claim process is efficient and has minimal paperwork.                                           </p>
                                            <hr />
                                            <p className="bold-text"> No-obligation advice </p>
                                            <p className="bottom-te">
                                                For any inquiries regarding<strong>  By Miles insurance claims,</strong>  call us on<strong>
                                                    {" "}
                                                    <a href="tel:0800 772 0850">0800 772 0850</a>{" "}
                                                </strong> Our solicitors will guide you about what to do next as pertains to your case.                                           </p>
                                            <hr />
                                            <p className="bold-text">No win-no fee</p>
                                            <p className="bottom-te">
                                                Our solicitors follow a policy of no-win, no fee for handling car insurance claims. This means there are zero losses in case of an unfavourable outcome.                                           </p>
                                            <hr />

                                            <p className="bold-text">24/7 assistance</p>
                                            <p className="bottom-te">
                                                In case an accident happens and you need someone who can offer emergency help as well as advice, just get in touch with us. Ring our car insurance claims department for immediate assistance after a non-fault motor collision at<strong>
                                                    {" "}
                                                    <a href="tel:0800 772 0850">0800 772 0850</a>{" "}
                                                </strong>                                             </p>
                                        </div>
                                    </div>
                                </div>
                                <div className="cl4" ref={sidebarRef}>
                                    <Sidebarinner />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="photographs">
                <div className="container">
                    <div className="row">
                        <h2 className="road">
                            You need not worry about anything as we are experts  {" "}
                            <span className="light">
                                in<br />
                                handling accidents.
                            </span>{" "}
                        </h2>
                        <div className="col-xxl-6 col-xl-6 col-md-12 col-12">
                            <div className="fourbox">
                                <ul>
                                    <li>
                                        <img src={check} className="check" alt="" />
                                        The <strong>  By Miles car insurance contact number </strong> should be dialled for emergency assistance after a car accident.

                                    </li>
                                    <hr />
                                    <li>
                                        <img src={check} className="check" alt="" />
                                        We will find out if any party is responsible for the accident and claim against them on your behalf                                   </li>
                                    <hr />
                                    <li>
                                        <img src={check} className="check" alt="" />
                                        Our assistance will also include helping you file <strong>  By Miles insurance claims </strong>  in order to be compensated for any losses that you might have incurred.                                  </li>
                                    <hr />
                                    <li>
                                        <img src={check} className="check" alt="" />
                                        Our team will retrieve your car from the crash site and arrange its fixing through our network of dependable repairers who are approved by manufacturers.                                  </li>
                                </ul>
                            </div>
                        </div>
                        <div className="col-xxl-6 col-xl-6 col-md-12 col-12">
                            <div className="fourbox">
                                <ul>
                                    <li>
                                        <img src={check} className="check" alt="" />
                                        We will call in an independent engineer to assess the damage or loss resulting from the accident.                                     </li>
                                    <hr />
                                    <li>
                                        <img src={check} className="check" alt="" />
                                        A temporary replacement vehicle will be given to you until your claim is resolved.                                   </li>
                                    <hr />
                                    <li>
                                        <img src={check} className="check" alt="" />
                                        We follow up on all repair works of your vehicle for updates and keep you informed about the progress.                                 </li>
                                    <hr />
                                    <li>
                                        <img src={check} className="check" alt="" />
                                        Call us now at <strong>
                                            {" "}
                                            <a href="tel:0800 772 0850">0800 772 0850</a>{" "}
                                        </strong>  where we can talk more about making a <strong> By Miles insurance claim</strong> for compensation of damages and losses.                                   </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="carprocess">
                <div className="container ">
                    <div className="row">
                        <div className="col-xxl-12 col-xxl-12 col-md-12 col-12">
                            <h2 className="trusted-title">
                                {" "}
                                Accident management services <br />
                                <span className="light"> we offer </span>{" "}
                            </h2>
                        </div>
                    </div>
                    <div className="row" id="possible">
                        <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-12">
                            <div className="claim">
                                <ul>
                                    <li>
                                        <span className="sleej">Vehicle recovery:</span>    Contact , <strong> By Miles car insurance contact number </strong> to have your vehicle recovered after a crash. We will ensure the immediate removal of your vehicle from the accident scene and subsequent safe storage in our yards.
                                    </li>
                                    <li>
                                        <span className="sleej">Approved repairs:</span> In case you need your car fixed, reach out to our team through the <strong>  By Miles insurance claim number </strong>    We will arrange your vehicle repairs from trusted garages across the UK, where there will be no extra charges for repairing your car.
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-12">
                            <div className="claim" id="blue">
                                <ul>
                                    <li>
                                        <span className="sleej">Vehicle storage:</span>    Are you concerned about keeping your car safe following a non-fault incident? Reach us through our <strong>By Miles insurance claim number. </strong> We have clean and well-kept yards to store your vehicle as you await the processing of your claim.
                                    </li>
                                    <li>
                                        <span className="sleej">Replacement vehicles:</span> If your car cannot be repaired, call the emergency helpline 
                                        <strong>
                                            {" "}
                                            <a href="tel:0800 772 0850">0800 772 0850</a>{" "}
                                        </strong>
                                        for a like-for-like replacement vehicle that will keep things going smoothly for you without any worries. You can keep it until such time when everything concerning your claim is sorted out.
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-12">
                            <div className="claim">
                                <ul>
                                    <li>
                                        <span className="sleej">Injury compensation help:</span>
                                        Want to get compensation for the injuries suffered? Call us on the <strong> By Miles insurance claim number. </strong>  Our solicitors will advise you on how to claim for personal injuries sustained.                                 </li>
                                    <li>
                                        <span className="sleej">Claims management:</span>  From paperwork and liaising with insurers to organising repairs for your car and supplying a replacement vehicle, we will deal with everything.
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="yellowcta">
                <div className="container">
                    <div className="row">
                        <div className="cta2">
                            <div className="col-xxl-8 col-xl-8 col-lg-8 col-12">
                                <div className="legal">
                                    <h3 className="experienced">
                                    Have more questions? Talk to {" "}
                                        <span className="light">our experts. 
                                        </span>{" "}
                                    </h3>
                                    <p className="professionals">
                                    Talk to our no-fault claims specialists through car insurance contact number 
                                        {" "}
                                        <strong>{" "}
                                            <a className="cpt-no" href="tel:0800 772 0850">
                                                {" "}
                                                0800 772 0850{" "}
                                            </a>{" "}
                                        </strong>
                                        and get expert advice about
                                        <strong> By Miles insurance claims.  </strong>
                                    </p>
                                </div>
                            </div>
                            <div className="col-xxl-4 col-xl-4 col-lg-4 col-12">
                                <div class="process">
                                    <a class="tf-button style-1 phone" href="tel:0800 772 0850">
                                        Get in touch<span class="icon-keyboard_arrow_right"></span>
                                    </a>
                                </div>
                                <div class="process">
                                    <a className="tf-button style-1 desktop" onClick={handleScrollToSidebar}>
                                        Get in touch<span className="icon-keyboard_arrow_right"></span>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <search className="updatedform">
                <div className="container">
                    <div className="row text-center">
                        <div className="col-xxl-12 col-xl-12 col-md-12 col-12">
                            <h2 className="whiteheading">
                                {" "}
                                What makes us
                                <span className="light"> different?
                                </span>
                            </h2>
                            <p className="sustained">
                            We shall take it upon ourselves to relieve you from anxiety occasioned by non-fault road accidents. The following are some of the advantages that come with you choosing us over others in terms of handling claims                            </p>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-xxl-4 col-xl-4 col-lg-6 col-md-12 col-12">
                            <div className="Claimant">
                                <h5>24/7 breakdown recovery</h5>
                                <p className="collision">
                                Do not hesitate to use the <strong>   By Miles insurance claim number </strong>  in case you want accident recovery at all times. Our team is available round the clock to recover you and your vehicle from the accident scene. 

                                </p>

                                <h5>Guaranteed repairs</h5>
                                <p className="collision">
                                Your vehicle repair will be provided by our manufacturer-repairers, guaranteeing quality repairs using original parts and paint.                              </p>

                                <h5>No increase in your premium</h5>
                                <p className="collision">
                                Your insurance premium won’t be impacted as we will not make a claim against your own insurance policy.                                </p>
                            </div>
                        </div>
                        <div className="col-xxl-4 col-xl-4 col-lg-6 col-md-12 col-12">
                            <div className="Claimant">
                                <h5>Free replacement car
                                </h5>
                                <p className="collision">
                                To keep you moving, we can offer you a similar replacement motor vehicle without any upfront charges.</p>
                                <h5>Dependable network </h5>
                                <p className="collision">
                                We have established a firm network of solicitors and car repairers to offer you excellent services.                               </p>

                                <h5>Quick settlement </h5>
                                <p className="collision">
                                By expertly negotiating with the other driver’s solicitors, our seasoned legal professionals ensure that your <strong> By Miles car insurance claim </strong> will be settled very soon.
                                </p>
                            </div>
                        </div>
                        <div className="col-xxl-4 col-xl-4 col-lg-12 col-md-12 col-12">
                            <div className="Claimant">
                                <h5>Rapid response</h5>
                                <p className="collision">
                                We also guarantee immediate emergency assistance immediately after any non-fault accident                              </p>

                                <h5>Tailored guidance</h5>
                                <p className="collision">
                                Your claims rank first in our priority list. Thus, we shall assign you a claims handler who will personally handle your case and update you on progress step by step.                             </p>

                                <h5>No extra fees</h5>
                                <p className="collision">
                                We will recover all expenses from the other party’s insurer, saving you from making any excess payments.                           </p>
                            </div>
                        </div>
                    </div>
                </div>
            </search>

            <section className="Accident-claim">
                <div className="container">
                    <div className="row">
                        <div className="needed">
                            <h2 className="trusted-title">
                            How can you improve the chances of getting <br />
                                <span className="light"> full compensation?</span>
                            </h2>
                            <p className="complexities">
                            It is unfortunate to be involved in an accident that was not your fault. But it is even more irritating having to go through the claims process. To increase your chances of obtaining deserving compensation, prove beyond reasonable doubt that you were not at fault. Here are the steps to take if you want to be fairly compensated after a road crash.
                            </p>
                        </div>
                        <hr />
                    </div>
                    <div className="row" id="incdes">
                        <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-12">
                            <div className="Preparing">
                                <ul>
                                    <li>
                                        <div className="seemingly">
                                            <h5 className="Seek">Ensure you are safe.:</h5>
                                            <p>
                                                {" "}
                                                Ensure your safety and check whether you or any of your passengers have sustained any injuries                                        </p>
                                        </div>
                                    </li>
                                    <li>
                                        <div className="seemingly">
                                            <h5 className="Seek">
                                            Seek medical attention :{" "}
                                            </h5>
                                            <p>
                                            Seeking medical assistance is necessary for accident victims. If there have been any injuries incurred by anyone in your car, it is better to locate a good doctor as soon as possible.                                           </p>
                                        </div>
                                    </li>
                                    <li>
                                        <div className="seemingly">
                                            <h5 className="Seek">Collect evidence:</h5>
                                            <p>
                                            Collect as much evidence as possible, including photographs of the accident scene, injuries, vehicle damages, CCTV videos and eyewitness statements to make your claim strong.                                            </p>
                                        </div>
                                    </li>
                                    <li>
                                        <div className="seemingly">
                                            <h5 className="Seek">Call up police:</h5>
                                            <p>
                                            Report what occurred to police authorities and get a copy of the report in order to back up your <strong>By Miles claim. </strong>                                            </p>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div
                            className="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-12"
                            id="dualcardiv"
                        >
                            <img src={dualcar} alt="" className="dualcar" />
                        </div>
                        <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-12">
                            <div className="Preparing">
                                <ul>
                                    <li>
                                        <div className="seemingly">
                                            <h5 className="Seek">Give more details:</h5>
                                            <p>
                                            It would be useful if you exchange information with other drivers involved in the accident.                                          </p>
                                        </div>
                                    </li>
                                    <li>
                                        <div className="seemingly">
                                            <h5 className="Seek">Collect witness details : </h5>
                                            <p>
                                            Details regarding eyewitnesses should be noted so that they can be reached later.                                              </p>
                                        </div>
                                    </li>
                                    <li>
                                        <div className="seemingly">
                                            <h5 className="Seek">
                                            Record all expenses  :
                                            </h5>
                                            <p>
                                            To get compensated for all costs, keep every bill for expenditures that includes medical bills, transportation costs or any other expenses you’ve suffered. 
                                            </p>
                                        </div>
                                    </li>
                                    <li>
                                        <div className="seemingly">
                                            <h5 className="Seek">
                                            Talk to a no-fault claim expert :
                                            </h5>
                                            <p>
                                            For advice on how to make a successful claim, call our helpline number {" "}
                                                <strong>{" "}
                                                    <a className="cpt-no" href="tel:0800 772 0850">
                                                        {" "}
                                                        0800 772 0850 {" "}
                                                    </a>{" "}
                                                </strong>
                                                

                                            </p>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="whyus">
                <div className="container">
                    <div className="row">
                        <div className="col-xxl-12 col-md-12">
                            <div className="why">
                                <h2 className="choose">
                                    Start your claim with  {" "}
                                    <span className="light">us today </span>
                                </h2>
                                <p className="complexities">
                                Making a claim can be a challenge when it is not your fault. Thus, we will help you with this. Start by contacting us through the <strong>By Miles car insurance phone number. </strong>                      </p>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-xxl-3 col-xl-6 col-lg-6 col-md-6 col-12">
                            <div className="box-service">
                                <img src={check} className="check" alt="" />
                                {/* <img src={experience} className="experience" alt="" /> */}
                                <h4>Contact us </h4>
                                <p>
                                Contact us on <strong> By Miles car insurance contact number </strong>  to discuss your claim with our solicitors. </p>
                            </div>
                        </div>
                        <div className="col-xxl-3 col-xl-6 col-lg-6 col-md-6 col-12">
                            <div className="box-service">
                                <img src={check} className="check" alt="" />
                                <h4>Share information</h4>
                                <p>
                                Let us know everything you have about the accident, including time and location, as well as how it occurred. </p>
                            </div>
                        </div>
                        <div className="col-xxl-3 col-xl-6 col-lg-6 col-md-6 col-12">
                            <div className="box-service">
                                <img src={check} className="check" alt="" />
                                <h4>Show evidence</h4>
                                <p>
                                Provide proof in support of your claims like photos or videos, witness statements, police reports etc.                                    </p>
                            </div>
                        </div>
                        <div className="col-xxl-3 col-xl-6 col-lg-6 col-md-6 col-12">
                            <div className="box-service">
                                <img src={check} className="check" alt="" />
                                <h4>Claim initiation</h4>
                                <p>
                                Our experts will commence processing your demands when the valuation is completed. We will keep updating you on each stage. 
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="faqs">
                <div className="container">
                    <div className="row">
                        <div className="col-xxl-12 col-xl-12 col-md-12 col-12">
                            <div className="fq">
                                <h2>
                                    {" "}
                                    Frequently Asked{" "}
                                    <span className="light">Questions</span>
                                </h2>
                                <p className="complexities">
                                    Got questions about the <strong> By Miles insurance claims?  </strong> We have them answered here.
                                </p>
                            </div>
                            <div className="allfaq">
                                <Accordion defaultActiveKey="0">
                                    <Accordion.Item eventKey="0">
                                        <Accordion.Header>
                                        Will I pay extra fees for replacement vehicles?                                      </Accordion.Header>
                                        <Accordion.Body>
                                        No. The at-fault driver's insurer is responsible for any costs associated with replacing your vehicle. You should only make payment if you fail to cooperate, engage a solicitor without our permission or give false details about your matter.                                        </Accordion.Body>
                                    </Accordion.Item>
                                    <Accordion.Item eventKey="1">
                                        <Accordion.Header>
                                        How long will it take to settle my claim?
                                        </Accordion.Header>
                                        <Accordion.Body>
                                        We will make every effort to reach a settlement on the claims as promptly as possible. Once we initiate your claim, we will keep you updated about its progress.                                    </Accordion.Body>
                                    </Accordion.Item>
                                    <Accordion.Item eventKey="2">
                                        <Accordion.Header>
                                        Will the at-fault party’s insurance company pay for vehicle recovery?
                                        </Accordion.Header>
                                        <Accordion.Body>
                                        In fact, all costs associated with towing away the vehicle are taken care of by the fault party’s insurer. You need some kind of proof, like pictures taken at the scene or a police report, so as to show that you have entitlements compensable.                                  </Accordion.Body>
                                    </Accordion.Item>
                                    <Accordion.Item eventKey="3">
                                        <Accordion.Header>
                                        Can I get a credit hire car following a no-fault road accident? 
                                        </Accordion.Header>
                                        <Accordion.Body>
                                        Yes, No-fault drivers can avail themselves of credit-hire cars whenever accidents occur without their fault. If your vehicle has been written off or is being repaired, please call us now on the<strong> By Miles insurance claim number </strong> and we will give you an alternative car from our fleet which is exactly similar to what you had before.                                      </Accordion.Body>
                                    </Accordion.Item>
                                    <Accordion.Item eventKey="4">
                                        <Accordion.Header>
                                        Will I lose my no-claims bonus if I’m not at fault?

                                        </Accordion.Header>
                                        <Accordion.Body>
                                        We don’t initiate any claim against your own policy. Hence, you won’t lose your no-claims bonus.                                 </Accordion.Body>
                                    </Accordion.Item>
                                    <Accordion.Item eventKey="5">
                                        <Accordion.Header>
                                        Is it possible for me to use my garage for repairs?{" "}
                                        </Accordion.Header>
                                        <Accordion.Body>
                                        Yes. You could choose to repair your car at your preferred garage. We’ll schedule your car’s repairs at a place of your choice to ensure fast repairs.                                         </Accordion.Body>
                                    </Accordion.Item>
                                    <Accordion.Item eventKey="6">
                                        <Accordion.Header>
                                        What type of vehicle would I receive if mine is being fixed? {" "}
                                        </Accordion.Header>
                                        <Accordion.Body>
                                        Give us a call on the <strong>By Miles insurance claim number </strong> and tell us what type of vehicle you want. We will provide you with a like-for-like replacement vehicle matching your own. 
                                        </Accordion.Body>
                                    </Accordion.Item>
                                </Accordion>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <Footer />
        </>
    );
};

export default Bymiles;
