import React, { useRef } from "react";
import Accordion from "react-bootstrap/Accordion";
import check from "../assets/images/check.png";
import dualcar from "../../src/assets/images/dualcar.webp";
import hasting from "../../src/assets/images/hasting.webp";
import Sidebarinner from "../components/Sidebar-inner";
import phonecall from "../assets/images/phonecall.png";
import Footer from "../components/footer";
import { Helmet } from "react-helmet";

const Wisedriving = () => {
    const sidebarRef = useRef(null);

    const handleScrollToSidebar = () => {
        if (sidebarRef.current) {
            sidebarRef.current.scrollIntoView({ behavior: "smooth" });
        }
    };
    return (
        <>
        <Helmet>
        <meta charSet="utf-8" />
        <title>WiseDriving Car Insurance Accident Claim Phone Number</title>
        <meta
          name="description"
          content="Make a successful claim with the guidance of our experienced claim specialists. Call us on the WiseDriving Car Insurance Accident Claim Phone Number 0800 772 0850. "
        />
        <link
          rel="canonical"
          href="https://insurance-accident-claims.co.uk/Wise-driving"
        />
      </Helmet>
            <section className="innernav">
                <div className="container">
                    <div className="row">
                        <div className="col-md-6 col-sm-6">
                            <div className="left-logo">
                                <h3>
                                    Accident<span className="sipo"> Claims</span>
                                </h3>
                            </div>
                        </div>
                        <div className="col-md-6 col-sm-6">
                            <div className="left-cta">
                                <div class="headercnt">
                                    <div class="chatmsg">
                                        <img
                                            src={phonecall}
                                            className="phonecall"
                                            alt="phonecall "
                                        />
                                    </div>
                                    <div class="box-body">
                                        <h3 class="nav-info-box-title">Toll Free Number </h3>
                                        <p>
                                            <a href="tel:0800 772 0850">0800 772 0850</a>
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section
                className="newbnr"
                style={{ backgroundImage: "url(" + hasting + ")" }}
            >
                <div className="container">
                    <div className="row">
                        <div className="col-xxl-7 col-xl-7 col-md-10 col-12">
                            <div className="innerbnr">
                                <h1>
                                    <span className="onespan">Quick and hassle-free accident </span>{" "}
                                    <span className="sci">claims  </span> support
                                </h1>
                                <p className="brokerage">
                                We will take care of everything, right from your claim assessment to its settlement, so that you can go back to normal as soon as possible.                               </p>
                                <div className="bnrbtn">
                                    <a class="db-btn-border btn-rollover phone" href="tel:0800 772 0850">
                                        Road Accident Claim Help?{" "}
                                        <i class="fa-solid fa-arrow-right"></i>
                                    </a>
                                </div>
                                <div className="bnrbtn">
                                    <a className="db-btn-border btn-rollover desktop" onClick={handleScrollToSidebar}>
                                        Road Accident Claim Help? <i className="fa-solid fa-arrow-right"></i>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="stickysection">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="group-4-8">
                                <div className="cl8 tf-tab">
                                    <div className="content-tab">
                                        <div className="inner-content">
                                            <h2>
                                            UK’s leading claims 
                                                <br />
                                                <span className="light">management experts</span>{" "}
                                            </h2>
                                            <p className="disciplines">
                                            Our company is one of the leading providers of <strong> WiseDriving insurance claims </strong>  assistance in the United Kingdom, having a track record of handling thousands of 
                                            cases successfully. If you want to make a claim, dial the <strong> WiseDriving car insurance claim number. </strong> We will look after you and your <strong> WiseDriving car insurance claim </strong> at no cost.
                                            </p>
                                            <hr />
                                            <p className="bold-text">Zero excess</p>
                                            <p className="bottom-te">  You don’t have to pay any insurance excess if the accident was not your fault.                                         </p>
                                            <hr />
                                            <p className="bold-text">Secured no-claims bonus</p>
                                            <p className="bottom-te">
                                            When claiming with us, we assure you that our company will protect your hard-earned no-claims bonus.                                            </p>
                                            <hr />
                                            <p className="bold-text">
                                            Quick vehicle recovery
                                            </p>
                                            <p className="bottom-te">
                                            We don’t want you to be stranded on the street helpless after accidents or crashes. In case an accident occurs, call the <strong> WiseDriving car insurance contact number </strong>  for immediate response and safe return at any time if struck anywhere across town.                                            </p>
                                            <hr />
                                            <p className="bold-text">Top-notch repairs   </p>
                                            <p className="bottom-te">
                                            We’ll get your car fixed in the best possible way because we deal with all kinds of garages approved by vehicle manufacturers.                                         </p>
                                            <hr />
                                            <p className="bold-text">Comparable replacement </p>
                                            <p className="bottom-te">
                                            When your car is being repaired, we can give you a similar brand model to use over the period through “like for like hire”                                           </p>
                                            <hr />
                                            <p className="bold-text">Experienced solicitors </p>
                                            <p className="bottom-te">
                                            Our experienced solicitors, who understand the law concerning no-fault accidents, have handled hundreds of<strong> WiseDriving insurance claims </strong>  successfully, ensuring full compensation to victims.
                                            </p>
                                            <hr />
                                            <p className="bold-text">Prompt claims settlement </p>
                                            <p className="bottom-te">
                                            We have our claim process highly effective and efficient, making them easier compared to filling out lots of paperwork or waiting for a long period. Make your claims without the stress of paperwork.                                           </p>
                                            <hr />
                                            <p className="bold-text">Independent and free advice</p>
                                            <p className="bottom-te">
                                            You can contact us on <strong>
                                                    {" "}
                                                    <a href="tel:0800 772 0850">0800 772 0850</a>{" "}
                                                </strong>  if you want to get any clarifications regarding<strong>WiseDriving insurance claims </strong>  at any time without any obligation to do so. Our solicitors will help you make informed decisions on how to proceed with pursuing the case.                                          </p>
                                            <hr />
                                            <p className="bold-text">No win-no fee</p>
                                            <p className="bottom-te">
                                            Our solicitors are no-win, no-fee insurance claims handlers. So, if you lose your case, you pay nothing.                                       </p>
                                            <hr />

                                            <p className="bold-text">24/7 assistance</p>
                                            <p className="bottom-te">
                                            For immediate help or guidance in the event of an accident, please call our car insurance claims line on<strong>
                                                    {" "}
                                                    <a href="tel:0800 772 0850">0800 772 0850</a>{" "}
                                                </strong>    We are available to offer emergency support.
                                                </p>
                                        </div>
                                    </div>
                                </div>
                                <div className="cl4" ref={sidebarRef}>
                                    <Sidebarinner />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="photographs">
                <div className="container">
                    <div className="row">
                        <h2 className="road">
                        We shall ease the burden off your shoulders with expert   {" "}
                            <span className="light">
                            accident <br />
                                management support
                            </span>{" "}
                        </h2>
                        <div className="col-xxl-6 col-xl-6 col-md-12 col-12">
                            <div className="fourbox">
                                <ul>
                                    <li>
                                        <img src={check} className="check" alt="" />
                                        In emergencies following car accidents, use the <strong> WiseDriving car insurance phone number </strong> to contact us.

                                    </li>
                                    <hr />
                                    <li>
                                        <img src={check} className="check" alt="" />
                                        We will establish if there is any party at fault who may be pursued for damages on your behalf.                                 </li>
                                    <hr />
                                    <li>
                                        <img src={check} className="check" alt="" />
                                        We shall assist you in the process of filing <strong> WiseDriving insurance claims </strong>  to help you get compensation for the losses incurred.
                                    </li>
                                    <hr />
                                    <li>
                                        <img src={check} className="check" alt="" />
                                        Our team will recover your vehicle from the accident scene and arrange repair work with our trusted network of manufacturer-approved repairers.                              </li>
                                </ul>
                            </div>
                        </div>
                        <div className="col-xxl-6 col-xl-6 col-md-12 col-12">
                            <div className="fourbox">
                                <ul>
                                    <li>
                                        <img src={check} className="check" alt="" />
                                        In cases where your vehicle cannot be repaired, we will ask an independent engineer to value the damages.                                 </li>
                                    <hr />
                                    <li>
                                        <img src={check} className="check" alt="" />
                                        Until your vehicle is repaired, a temporary replacement vehicle will be provided.                                </li>
                                    <hr />
                                    <li>
                                        <img src={check} className="check" alt="" />
                                        We will monitor the progress of your repairs in order to keep you posted on the status so far.                                 </li>
                                    <hr />
                                    <li>
                                        <img src={check} className="check" alt="" />
                                        Reach out to us on <strong>
                                            {" "}
                                            <a href="tel:0800 772 0850">0800 772 0850</a>{" "}
                                        </strong>  and we will talk more about how you can make a <strong> WiseDriving insurance claim</strong>  to get compensation for damages and losses.                          </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="carprocess">
                <div className="container ">
                    <div className="row">
                        <div className="col-xxl-12 col-xxl-12 col-md-12 col-12">
                            <h2 className="trusted-title">
                                {" "}
                                Our accident management   <br />
                                <span className="light"> services  </span>{" "}
                            </h2>
                        </div>
                    </div>
                    <div className="row" id="possible">
                        <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-12">
                            <div className="claim">
                                <ul>
                                    <li>
                                        <span className="sleej">Vehicle recovery:</span> To get your vehicle recovered after an accident, dial the <strong> WiseDriving car insurance contact number. </strong> Our quick response ensures that your vehicle is safely removed from the site of the collision and transported to our secure yard.    
                                    </li>
                                    <li>
                                        <span className="sleej">Approved repairs:</span> If you want your car repaired, please use the <strong> WiseDriving insurance claims contact number. </strong>  We will organise high-quality repairs of your vehicle from our nationwide network of manufacturer-approved repairers.     

                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-12">
                            <div className="claim" id="blue">
                                <ul>
                                    <li>
                                        <span className="sleej">Vehicle storage:</span>    Would you like to retain your vehicle despite an accident that was not your fault? Do contact us through our <strong> WiseDriving insurance claim number. </strong>  The storage yards we have are well-maintained and provide a safe location to store your vehicles while your claim is ongoing.
                                    </li>
                                    <li>
                                        <span className="sleej">Replacement vehicle:</span> In case your car is beyond fixing, you are eligible for a replacement vehicle. Use the emergency helpline
                                        <strong>
                                            {" "}
                                            <a href="tel:0800 772 0850">0800 772 0850</a>{" "}
                                        </strong>
                                        to reach out to us and get a like-for-like replacement vehicle, which will enable you to continue with your routine without any hassles. It can be kept until your claim is settled.
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-12">
                            <div className="claim">
                                <ul>
                                    <li>
                                        <span className="sleej">Personal injury support </span>
                                        If you dial the <strong> WiseDriving insurance claim number, </strong> you can get advice from our solicitors to claim compensation for your injuries. Our solicitors will offer the necessary guidance to ensure you get fair compensation.                                   </li>
                                    <li>
                                        <span className="sleej">Claims management :</span>  From paperwork processing and negotiation with insurers to arranging for repairs as well as organising replacement vehicles, we will handle everything. 
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="yellowcta">
                <div className="container">
                    <div className="row">
                        <div className="cta2">
                            <div className="col-xxl-8 col-xl-8 col-lg-8 col-12">
                                <div className="legal">
                                    <h3 className="experienced">
                                    Got more questions? Talk to {" "}
                                        <span className="light">our specialists 
                                        </span>{" "}
                                    </h3>
                                    <p className="professionals">
                                    Get in touch with our no-fault claim experts via <strong> car insurance contact number</strong>
                                        {" "}
                                        <strong>{" "}
                                            <a className="cpt-no" href="tel:0800 772 0850">
                                                {" "}
                                                0800 772 0850{" "}
                                            </a>{" "}
                                        </strong>
                                        to get the best advice for the 
                                        <strong> WiseDriving insurance claims. </strong>
                                    </p>
                                </div>
                            </div>
                            <div className="col-xxl-4 col-xl-4 col-lg-4 col-12">
                                <div class="process">
                                    <a class="tf-button style-1 phone" href="tel:0800 772 0850">
                                        Get in touch<span class="icon-keyboard_arrow_right"></span>
                                    </a>
                                </div>
                                <div class="process">
                                    <a className="tf-button style-1 desktop" onClick={handleScrollToSidebar}>
                                        Get in touch<span className="icon-keyboard_arrow_right"></span>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <search className="updatedform">
                <div className="container">
                    <div className="row text-center">
                        <div className="col-xxl-12 col-xl-12 col-md-12 col-12">
                            <h2 className="whiteheading">
                                {" "}
                                Reasons that give us an
                                <span className="light">  edge over others 
                                </span>
                            </h2>
                            <p className="sustained">
                            We will prioritise saving you from the stress of a no-fault car accident. Here are some perks to enjoy once you select us as your claims management partner.                 </p>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-xxl-4 col-xl-4 col-lg-6 col-md-12 col-12">
                            <div className="Claimant">
                                <h5>24/7 car recovery </h5>
                                <p className="collision">
                                Need vehicle recovery services? Call the <strong> WiseDriving insurance claim number, </strong> and we shall recover you from the scene of the accident, irrespective of time.

                                </p>

                                <h5>High-quality repairs</h5>
                                <p className="collision">
                                With our extensive repair network, we can ensure quality repairs for your car using original parts and paint.                           </p>

                                <h5> No hike in insurance premium</h5>
                                <p className="collision">
                                The <strong> WiseDriving car insurance claim  </strong>  will not be made against your own policy. So, there won’t be any difference in your insurance premium.
                                </p>
                            </div>
                        </div>
                        <div className="col-xxl-4 col-xl-4 col-lg-6 col-md-12 col-12">
                            <div className="Claimant">
                                <h5>Cost-free replacement vehicle
                                </h5>
                                <p className="collision">
                                By supplying a like-for-like replacement vehicle at no upfront fee, we keep you mobile.  </p>
                                <h5>Dependable partners</h5>
                                <p className="collision">
                                To offer top-notch services to our clients, we have created an extensive connection with authorised body shops and competent legal experts in Britain.                              </p>

                                <h5>Speedy settlement of claims</h5>
                                <p className="collision">
                                Our experienced solicitors negotiate with other driver’s insurers to ensure your <strong> WiseDriving motor insurance claim </strong>  is settled fairly and quickly.
                                </p>
                            </div>
                        </div>
                        <div className="col-xxl-4 col-xl-4 col-lg-12 col-md-12 col-12">
                            <div className="Claimant">
                                <h5>Immediate assistance</h5>
                                <p className="collision">
                                We promise swift emergency help in the aftermath of a crash that was not your fault without delay.                               </p>

                                <h5>Bespoke assistance</h5>
                                <p className="collision">
                                We will give priority to your claim and assign one person who will be dealing with it directly while keeping you posted at all times.                            </p>

                                <h5>No deductible payments</h5>
                                <p className="collision">
                                There are no extra charges or out-of-pocket expenses incurred by you on our services. We recover all the costs from the other party involved.                        </p>
                            </div>
                        </div>
                    </div>
                </div>
            </search>

            <section className="Accident-claim">
                <div className="container">
                    <div className="row">
                        <div className="needed">
                            <h2 className="trusted-title">
                            How do you raise your odds of acquiring  <br />
                                <span className="light">suitable compensation? </span>
                            </h2>
                            <p className="complexities">
                            An accident is bad enough, especially when it was not your fault. However, coping with claims procedures can be even more stressful. To increase your chances of receiving appropriate compensation, establish beyond reasonable doubt that you were not at fault by presenting proven facts. If you want to get enough money as compensation for personal injuries after a car accident, here’s what you need to do next.                      </p>
                        </div>
                        <hr />
                    </div>
                    <div className="row" id="incdes">
                        <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-12">
                            <div className="Preparing">
                                <ul>
                                    <li>
                                        <div className="seemingly">
                                            <h5 className="Seek">Safety first:</h5>
                                            <p>
                                                {" "}
                                                Stop somewhere safe and check both you and your passengers for any visible or hidden injuries.                                     </p>
                                        </div>
                                    </li>
                                    <li>
                                        <div className="seemingly">
                                            <h5 className="Seek">
                                            Seek immediate medical attention :{" "}
                                            </h5>
                                            <p>
                                            If there is a hurt passenger in the car, seek a qualified physician immediately.                                           </p>
                                        </div>
                                    </li>
                                    <li>
                                        <div className="seemingly">
                                            <h5 className="Seek">Gather Proof:</h5>
                                            <p>  he evidence you must collect from the scene of an accident includes pictures and videos from the accident scene, persons liable, damages caused, serious injuries and dashcam footage.                                        </p>
                                        </div>
                                    </li>
                                    <li>
                                        <div className="seemingly">
                                            <h5 className="Seek">Notify the police:</h5>
                                            <p>
                                            Call the police to report the accident. The police report may provide adequate evidence to support your <strong> WiseDriving car insurance claim.</strong>                                              </p>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div
                            className="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-12"
                            id="dualcardiv"
                        >
                            <img src={dualcar} alt="" className="dualcar" />
                        </div>
                        <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-12">
                            <div className="Preparing">
                                <ul>
                                    <li>
                                        <div className="seemingly">
                                            <h5 className="Seek">Exchange information:</h5>
                                            <p>
                                            If possible, exchange information with other drivers, including name, phone numbers, physical addresses and licence plate numbers.                                       </p>
                                        </div>
                                    </li>
                                    <li>
                                        <div className="seemingly">
                                            <h5 className="Seek">Collect statements from witnesses: </h5>
                                            <p>
                                            Witnesses’ contact details must be collected for later reference.                                              </p>
                                        </div>
                                    </li>
                                    <li>
                                        <div className="seemingly">
                                            <h5 className="Seek">
                                            Record costs incurred:
                                            </h5>
                                            <p>
                                            Even if paid back entirely, still hold receipts for costs incurred through repairs or treatment and other expenses so that you can recover all expenses made by you
                                            </p>
                                        </div>
                                    </li>
                                    <li>
                                        <div className="seemingly">
                                            <h5 className="Seek">
                                            Request guidance from a no-fault solicitor :
                                            </h5>
                                            <p>
                                            Call the helpline number {" "}
                                                <strong>{" "}
                                                    <a className="cpt-no" href="tel:0800 772 0850">
                                                        {" "}
                                                        0800 772 0850 {" "}
                                                    </a>{" "}
                                                </strong>
                                                to receive advice on how to bring about a successful no-fault claim for compensation.

                                            </p>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="whyus">
                <div className="container">
                    <div className="row">
                        <div className="col-xxl-12 col-md-12">
                            <div className="why">
                                <h2 className="choose">
                                Start your claim with our simple and   {" "}
                                    <span className="light"> straightforward process</span>
                                </h2>
                                <p className="complexities">
                                Making a <strong>  WiseDriving motor insurance claim </strong> might be dreadful after an accident which was not caused by you. This is why we want to help you. Call us through the <strong> WiseDriving car insurance phone number </strong> and get started with your claim.                        </p>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-xxl-3 col-xl-6 col-lg-6 col-md-6 col-12">
                            <div className="box-service">
                                <img src={check} className="check" alt="" />
                                {/* <img src={experience} className="experience" alt="" /> */}
                                <h4>Get in touch </h4>
                                <p>
                                Contact our team on the <strong> WiseDriving car insurance contact number  </strong> to discuss your claim.  </p>
                            </div>
                        </div>
                        <div className="col-xxl-3 col-xl-6 col-lg-6 col-md-6 col-12">
                            <div className="box-service">
                                <img src={check} className="check" alt="" />
                                <h4>Tell us more</h4>
                                <p>
                                Please provide all the details of the accident, such as when, where and how it happened.</p>
                            </div>
                        </div>
                        <div className="col-xxl-3 col-xl-6 col-lg-6 col-md-6 col-12">
                            <div className="box-service">
                                <img src={check} className="check" alt="" />
                                <h4>Give evidence</h4>
                                <p>
                                Provide some evidence in support of your claim. They may include pictures of what happened during the accident, eyewitness testimonies, police reports or even CCTV videos.                                </p>
                            </div>
                        </div>
                        <div className="col-xxl-3 col-xl-6 col-lg-6 col-md-6 col-12">
                            <div className="box-service">
                                <img src={check} className="check" alt="" />
                                <h4>Claim processing </h4>
                                <p>
                                We will start your claim after evaluation. Our team will keep informing you about the process.

                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="faqs">
                <div className="container">
                    <div className="row">
                        <div className="col-xxl-12 col-xl-12 col-md-12 col-12">
                            <div className="fq">
                                <h2>
                                    {" "}
                                    Frequently Asked{" "}
                                    <span className="light">Questions</span>
                                </h2>
                                <p className="complexities">
                                Do you have any questions regarding <strong>   WiseDriving insurance claims?</strong> Find them answered here.

                                </p>
                            </div>
                            <div className="allfaq">
                                <Accordion defaultActiveKey="0">
                                    <Accordion.Item eventKey="0">
                                        <Accordion.Header>
                                        Will I be charged extra for having a replacement vehicle?                                 </Accordion.Header>
                                        <Accordion.Body>
                                        We don’t ask you to pay for any expenses incurred in replacing your vehicle. However, If you do not cooperate with us, or if we discover that you have engaged a solicitor without our knowledge, or even when we are sure you lied about your case, then and only then will you be required to make excess payments.                                       </Accordion.Body>
                                    </Accordion.Item>
                                    <Accordion.Item eventKey="1">
                                        <Accordion.Header>
                                        How long does it take for my claim to settle?
                                        </Accordion.Header>
                                        <Accordion.Body>
                                        While we shall do our level best to bring your<strong> WiseDriving car insurance claim </strong> to its conclusion with minimal delay, it is worth mentioning that there might be some peculiarities that may require a longer period of time. We will update you on how your claim is progressing once it has been initiated.                                    </Accordion.Body>
                                    </Accordion.Item>
                                    <Accordion.Item eventKey="2">
                                        <Accordion.Header>
                                        Is the cost of vehicle recovery covered by the at-fault party’s insurance?
                                        </Accordion.Header>
                                        <Accordion.Body>
                                        Yes, an at-fault party’s insurer pays all costs associated with recovering the vehicle. You’re not liable to make any payments for the vehicle recovery.                             </Accordion.Body>
                                    </Accordion.Item>
                                    <Accordion.Item eventKey="3">
                                        <Accordion.Header>
                                        Can I get a credit hire following a no-fault car accident?
                                        </Accordion.Header>
                                        <Accordion.Body>
                                        Yes. A driver who is non-negligent qualifies for credit hire. Contact us through the <strong> WiseDriving insurance claim number </strong>  after your vehicle has been written off, or when it is being fixed by our repairers and we will give you a replacement car like yours to use until yours is back on the road again.                                     </Accordion.Body>
                                    </Accordion.Item>
                                    <Accordion.Item eventKey="4">
                                        <Accordion.Header>
                                        Will I lose my no-claims bonus if I’m not at fault?

                                        </Accordion.Header>
                                        <Accordion.Body>
                                        You can make a claim through us without having to worry about affecting your own policy’s no-claims discount, which might have taken years to accumulate.                           </Accordion.Body>
                                    </Accordion.Item>
                                    <Accordion.Item eventKey="5">
                                        <Accordion.Header>
                                        Can my own garage be used for repairs?{" "}
                                        </Accordion.Header>
                                        <Accordion.Body>
                                        Certainly! We can easily arrange for you to have your vehicle repaired from any garage of your choice. Our company will cooperate with the garage and ensure that only first-class repairs are done and completed in the shortest possible time.                </Accordion.Body>
                                    </Accordion.Item>
                                    <Accordion.Item eventKey="6">
                                        <Accordion.Header>
                                        What kind of car will I get in place of mine? {" "}
                                        </Accordion.Header>
                                        <Accordion.Body>
                                        We shall provide a similar-sized, priced and functional substitute vehicle. Simply contact us on our <strong> WiseDriving insurance claim number </strong> and tell us what you want.

                                        </Accordion.Body>
                                    </Accordion.Item>
                                </Accordion>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <Footer />
        </>
    );
};

export default Wisedriving;
