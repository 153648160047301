import React, { useRef } from "react";
import Accordion from "react-bootstrap/Accordion";
import check from "../assets/images/check.png";
import dualcar from "../../src/assets/images/dualcar.webp";
import carbnr1 from "../../src/assets/images/carbnr1.webp";
import Sidebarinner from "../components/Sidebar-inner";
import phonecall from "../assets/images/phonecall.png";
import Footer from "../components/footer";
import { Helmet } from "react-helmet";

const Postoffice = () => {
  const sidebarRef = useRef(null);

  const handleScrollToSidebar = () => {
    if (sidebarRef.current) {
      sidebarRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Post Office Car Insurance Claims Phone Number</title>
        <meta
          name="description"
          content="Want to file a no-fault accident claim? Call us on the Post Office Car Insurance Claims Phone Number 0800 772 0850 for speedy claim resolution. Contact us today.  "
        />
        <link
          rel="canonical"
          href="https://insurance-accident-claims.co.uk/post-office"
        />
      </Helmet>
      <section className="innernav">
        <div className="container">
          <div className="row">
            <div className="col-md-6 col-sm-6">
              <div className="left-logo">
                <h3>
                  Claim<span className="sipo"> Support</span>
                </h3>
              </div>
            </div>
            <div className="col-md-6 col-sm-6">
              <div className="left-cta">
                <div class="headercnt">
                  <div class="chatmsg">
                    <img
                      src={phonecall}
                      className="phonecall"
                      alt="phonecall "
                    />
                  </div>
                  <div class="box-body">
                    <h3 class="nav-info-box-title">Toll Free Number </h3>
                    <p>
                      <a href="tel:+/">+1 123 203-5105</a>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section
        className="newbnr"
        style={{ backgroundImage: "url(" + carbnr1 + ")" }}
      >
        <div className="container">
          <div className="row">
            <div className="col-xxl-8 col-xl-8 col-md-10 col-12">
              <div className="innerbnr">
                <h1>
                  <span className="onespan">Expert accident</span>{" "}
                  <span className="sci">insurance claims</span> in the UK
                </h1>
                <p className="brokerage">
                  We’re here to ease your stress and burden after a no-fault car
                  accident by providing emergency support and claims assistance.{" "}
                </p>
                <div className="bnrbtn">
                  <a class="db-btn-border btn-rollover phone" href="tel:0800 772 0850">
                    Road Accident Claim Help?{" "}
                    <i class="fa-solid fa-arrow-right"></i>
                  </a>
                </div>
                <div className="bnrbtn">
                <a className="db-btn-border btn-rollover desktop"  onClick={handleScrollToSidebar}>
                    Road Accident Claim Help? <i className="fa-solid fa-arrow-right"></i>
                </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="stickysection">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="group-4-8">
                <div className="cl8 tf-tab">
                  <div className="content-tab">
                    <div className="inner-content">
                      <h2>
                        Effortless claims <br />
                        <span className="light">
                          management with no excess{" "}
                        </span>{" "}
                      </h2>
                      <p className="disciplines">
                        We specialise in helping the victims of no-fault car
                        accidents in the UK get back on track with our
                        comprehensive accident management assistance and claims
                        support. Our highly dedicated team and expert solicitors
                        will not only rescue you but also handle your{" "}
                        <strong>Post Office car insurance claim</strong> from
                        start to finish. Call us on{" "}
                        <strong>
                          <a href="tel:0800 772 0850">0800 772 0850</a>
                        </strong>{" "}
                        if you need expert guidance for your claim without
                        additional costs.
                      </p>
                      <hr />
                      <p className="bold-text">Zero excess </p>
                      <p className="bottom-te">
                        All the costs of our services will be paid by the
                        at-fault party’s insurance company. So, you won’t be
                        burdened with any excess payments.{" "}
                      </p>
                      <hr />
                      <p className="bold-text">No change to NCB status </p>
                      <p className="bottom-te">
                        Since the <strong>Post Office insurance claim</strong>{" "}
                        won’t be initiated against your own insurance policy,
                        your no-claims bonus and discounts will remain
                        unaffected.
                      </p>
                      <hr />
                      <p className="bold-text">Unmatched expertise</p>
                      <p className="bottom-te">
                        Unlike the insurance companies, we specialise in
                        no-fault car accident claims. Our solicitors work
                        tirelessly to ensure the best outcome for your case.
                      </p>
                      <hr />
                      <p className="bold-text">End-to-end services </p>
                      <p className="bottom-te">
                        From handling the complexities of your{" "}
                        <strong> Post Office insurance claim </strong> to
                        providing emergency assistance to get you back on the
                        road, we've got you covered every step of the way.
                      </p>
                      <hr />
                      <p className="bold-text">No win, No fee services</p>
                      <p className="bottom-te">
                        If your <strong>Post Office car insurance claim</strong>{" "}
                        is unsuccessful, you’re not obliged to pay any legal
                        fees.
                      </p>
                      <hr />
                      <p className="bold-text">Friendly and supportive team </p>
                      <p className="bottom-te">
                        When you call us on the{" "}
                        <strong>Post Office car insurance claim number </strong>
                        , our friendly team will guide you through the steps to
                        make a successful{" "}
                        <strong>Post Office insurance claim.</strong>
                      </p>
                      <hr />
                      <p className="bold-text">Expert claim handler </p>
                      <p className="bottom-te">
                        We will appoint a dedicated claims handler who will put
                        in their best efforts to deliver you an exceptional
                        claim solution and recover all the costs from the
                        negligent driver without any hassle.
                      </p>
                      <hr />
                      <p className="bold-text">Professional advice </p>
                      <p className="bottom-te">
                        If you want to make a{" "}
                        <strong>Post Office car insurance claim</strong> , we
                        can help you get free no-obligation advice from
                        experienced solicitors to help you know your rights and
                        options.{" "}
                      </p>
                      <hr />
                      <p className="bold-text">Quality vehicle repairs </p>
                      <p className="bottom-te">
                        Our vast network of manufacturer-approved repairers
                        specialises in providing guaranteed repairs using
                        original parts and paint. Contact us on the{" "}
                        <strong>
                          Post Office insurance claim phone number
                        </strong>{" "}
                        to schedule high-quality repairs for your vehicle.{" "}
                      </p>
                      <hr />

                      <p className="bold-text">
                        Comparable replacement vehicle
                      </p>
                      <p className="bottom-te">
                        For the duration of your vehicle repairs, we will
                        provide you with a like-for-like replacement vehicle
                        equivalent to your own in size, make and model.{" "}
                      </p>
                    </div>
                  </div>
                </div>
                <div className="cl4" ref={sidebarRef}>
                  <Sidebarinner />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="photographs">
        <div className="container">
          <div className="row">
            <h2 className="road">
              We will deal with the complexities{" "}
              <span className="light">
                of <br />
                your claim while you recover
              </span>{" "}
            </h2>
            <div className="col-xxl-6 col-xl-6 col-md-12 col-12">
              <div className="fourbox">
                <ul>
                  <li>
                    <img src={check} className="check" alt="" />
                    From evaluating damages to negotiating with the insurers and
                    managing your claim from initiation to settlement, we have
                    got you covered
                  </li>
                  <hr />
                  <li>
                    <img src={check} className="check" alt="" />
                    When you call us on a{" "}
                    <strong>Post Office insurance claim phone number</strong> ,
                    our dedicated team will understand your requirements and get
                    the best replacement car to help you hit the road again.
                  </li>
                  <hr />
                  <li>
                    <img src={check} className="check" alt="" />
                    We will coordinate with repairers in the UK to schedule your
                    vehicle repairs from the garage of your choice.
                  </li>
                  <hr />
                  <li>
                    <img src={check} className="check" alt="" />
                    If required, we will liaise and communicate with several
                    parties involved in the accident, helping you focus on other
                    crucial aspects.
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-xxl-6 col-xl-6 col-md-12 col-12">
              <div className="fourbox">
                <ul>
                  <li>
                    <img src={check} className="check" alt="" />
                    We work alongside a panel of experienced solicitors who
                    specialise in no-fault car accident claims. So, when you
                    call us on the{" "}
                    <strong>Post Office car insurance claim number </strong>,
                    you can be confident to get the best advice and guidance for
                    your claim.
                  </li>
                  <hr />
                  <li>
                    <img src={check} className="check" alt="" />
                    We will organise an independent engineer for the assessment
                    of your vehicle.
                  </li>
                  <hr />
                  <li>
                    <img src={check} className="check" alt="" />
                    Call us on the{" "}
                    <strong>Post Office insurance claim number</strong> , and
                    we’ll arrange the collection of your damaged vehicle from
                    the accident scene and schedule its delivery after
                    completion.
                  </li>
                  <hr />
                  <li>
                    <img src={check} className="check" alt="" />
                    We will offer comprehensive support to help you recover all
                    the expenses incurred by you from the at-fault party's
                    insurer.
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="carprocess">
        <div className="container ">
          <div className="row">
            <div className="col-xxl-12 col-xxl-12 col-md-12 col-12">
              <h2 className="trusted-title">
                {" "}
                Our wide range of accident <br />
                <span className="light">management solutions</span>{" "}
              </h2>
            </div>
          </div>
          <div className="row" id="possible">
            <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-12">
              <div className="claim">
                <ul>
                  <li>
                    <span className="sleej">Vehicle recovery</span> You can Is
                    your vehicle inoperable due to the car accident? Get in
                    touch with us via the{" "}
                    <strong>Post Office insurance claim phone number</strong>{" "}
                    for quick recovery of your vehicle. Our recovery agents will
                    recover your vehicle from the accident location and
                    transport it to a secure place.
                  </li>
                  <li>
                    <span className="sleej">Approved repairs</span> Contact us
                    at the{" "}
                    <strong>Post Office car insurance claim number</strong> for
                    instant repairs in an approved garage of your choice. Our
                    manufacturer-approved repairers will restore your vehicle to
                    its pre-accident condition and increase its market value.
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-12">
              <div className="claim" id="blue">
                <ul>
                  <li>
                    <span className="sleej">Safe storage </span> If your vehicle
                    is not drivable, it may not be safe to leave it at the
                    roadside or outside your home. We will reduce your stress by
                    storing your vehicle in our storage yards, preventing it
                    from further damage and deterioration. Call us on the{" "}
                    <strong>
                      insurance claim phone number{" "}
                      <a href="tel:0800 772 0850">0800 772 0850</a>
                    </strong>{" "}
                    for a vehicle storage solution for your damaged car.
                  </li>
                  <li>
                    <span className="sleej">Replacement vehicle</span> We
                    understand how challenging it could be to be left without a
                    vehicle. Our dedicated team will get you moving again by
                    providing a like-for-like replacement vehicle on the same
                    day. You can keep the replacement vehicle until your vehicle
                    gets repaired, or you receive compensation for the{" "}
                    <strong>Post Office car insurance claim</strong> . All the
                    costs and expenses related to the replacement vehicle will
                    be recovered from the at-fault party’s insurer.
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-12">
              <div className="claim">
                <ul>
                  <li>
                    <span className="sleej">Personal injury claim</span> Get
                    expert guidance and support to file a claim against the
                    negligent party for the compensation of your injuries and
                    losses. Our solicitors will diligently handle your{" "}
                    <strong>Post Office insurance claim</strong> to ensure you
                    get the best outcome according to the intensity of the
                    injuries you’ve suffered.
                  </li>
                  <li>
                    <span className="sleej">Claim management </span> From the
                    initiation of your{" "}
                    <strong> Post Office car insurance claim </strong> to
                    handling documentation, evaluation and final settlement, we
                    provide comprehensive end-to-end claims management services.
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="yellowcta">
        <div className="container">
          <div className="row">
            <div className="cta2">
              <div className="col-xxl-8 col-xl-8 col-lg-8 col-12">
                <div className="legal">
                  <h3 className="experienced">
                    Got more questions?{" "}
                    <span className="light">Talk to our specialists</span>{" "}
                  </h3>
                  <p className="professionals">
                    Get in touch with our no-fault claim experts via{" "}
                    <strong>
                      car insurance claim number{" "}
                      <a className="cpt-no" href="tel:0800 772 0850">
                        0800 772 0850
                      </a>{" "}
                    </strong>{" "}
                    to get the best advice for your{" "}
                    <strong>Post Office car insurance claim. </strong>
                  </p>
                </div>
              </div>
              <div className="col-xxl-4 col-xl-4 col-lg-4 col-12">
              <div class="process">
                  <a class="tf-button style-1 phone" href="tel:0800 772 0850">
                    Get in touch<span class="icon-keyboard_arrow_right"></span>
                  </a>
                </div>
                <div class="process">
                    <a className="tf-button style-1 desktop"  onClick={handleScrollToSidebar}>
                        Get in touch<span className="icon-keyboard_arrow_right"></span>
                    </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <search className="updatedform">
        <div className="container">
          <div className="row text-center">
            <div className="col-xxl-12 col-xl-12 col-md-12 col-12">
              <h2 className="whiteheading">
                {" "}
                What sets us apart <br />
                <span className="light">from others?</span>
              </h2>
              <p className="sustained">
                We are your one-stop shop for the best accident management
                solutions and claims assistance if you’ve been a victim of a
                no-fault car accident in the UK. Whether you need support in
                vehicle recovery, require assistance with a replacement vehicle
                or need legal advice to know your rights and options, we will
                provide you with the best solution based on your needs.
              </p>
            </div>
          </div>
          <div className="row">
            <div className="col-xxl-4 col-xl-4 col-lg-6 col-md-12 col-12">
              <div className="Claimant">
                <h5>No upfront costs </h5>
                <p className="collision">
                  We offer our services without upfront costs, ensuring you get
                  the best emergency support without any financial burden.
                </p>

                <h5>Rapid response </h5>
                <p className="collision">
                  Our team is available round the clock to ensure you get timely
                  assistance and resolution to your accident management needs.{" "}
                </p>

                <h5>Manufacturer-approved repairers</h5>
                <p className="collision">
                  We partner with manufacturer-approved repairers in the UK to
                  ensure your vehicle is repaired by professionals who adhere to
                  manufacturer specifications and use genuine parts and paint.
                </p>
              </div>
            </div>
            <div className="col-xxl-4 col-xl-4 col-lg-6 col-md-12 col-12">
              <div className="Claimant">
                <h5>Same-day replacement vehicle</h5>
                <p className="collision">
                  Get in touch with us via the{" "}
                  <strong>Post Office car insurance claim number</strong> to get
                  a same-day replacement to ensure minimal disruption to your
                  daily life.
                </p>

                <h5>Expedited claim settlement </h5>
                <p className="collision">
                  Our streamlined process and transparent approach simplify the{" "}
                  <strong>Post Office insurance claim</strong> settlement
                  process, ensuring the timely resolution of your claim.
                </p>

                <h5>24/7 recovery</h5>
                <p className="collision">
                  Our recovery agents are always on hand to assist you with your
                  vehicle recovery needs, no matter the hour.{" "}
                </p>
              </div>
            </div>
            <div className="col-xxl-4 col-xl-4 col-lg-12 col-md-12 col-12">
              <div className="Claimant">
                <h5>No obligation advice </h5>
                <p className="collision">
                  Call us on the{" "}
                  <strong>Post Office insurance claim number</strong> to
                  schedule free no-obligation advice to understand your rights
                  and options to pursue your claim.
                </p>

                <h5>Experienced solicitors</h5>
                <p className="collision">
                  Our solicitors have extensive experience in handling no-fault
                  car accident support. They can offer you the best guidance and
                  support.
                </p>

                <h5>Personalised advice</h5>
                <p className="collision">
                  Our professional and knowledgeable solicitors will assess the
                  specifics of your
                  <strong> Post Office car insurance claim</strong> and provide
                  personalised advice on the best course of action for a
                  favourable outcome.
                </p>
              </div>
            </div>
          </div>
        </div>
      </search>

      <section className="Accident-claim">
        <div className="container">
          <div className="row">
            <div className="needed">
              <h2 className="trusted-title">
                How can you achieve the highest possible <br />
                <span className="light">
                  {" "}
                  compensation for your Post Office insurance claim?
                </span>
              </h2>
              <p className="complexities">
                A car accident can disrupt your life and result in overwhelming
                stress. Getting compensation for the losses and injuries is the
                foremost step towards recovering from the consequences of a car
                accident. However, you must follow the correct steps to increase
                the settlement value and maximise your compensation.{" "}
              </p>
            </div>
            <hr />
          </div>
          <div className="row" id="incdes">
            <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-12">
              <div className="Preparing">
                <ul>
                  <li>
                    <div className="seemingly">
                      <h5 className="Seek">Seek medical attention </h5>
                      <p>
                        {" "}
                        Get immediate medical attention to ensure your injuries
                        are properly diagnosed and treated. It will also
                        establish a crucial record of your condition and make
                        your <strong>Post Office insurance claim</strong>{" "}
                        stronger.{" "}
                      </p>
                    </div>
                  </li>
                  <li>
                    <div className="seemingly">
                      <h5 className="Seek">Document damages</h5>
                      <p>
                        Save all the expenses related to your claim, including
                        medical bills, prescription costs, transportation
                        expenses, legal costs, or other expenses you’ve
                        incurred.{" "}
                      </p>
                    </div>
                  </li>
                  <li>
                    <div className="seemingly">
                      <h5 className="Seek">Collect evidence</h5>
                      <p>
                        Take photographs and videos of the accident scene,
                        collect eyewitness statements, try to access CCTV videos
                        and obtain a copy of the police report to strengthen
                        your claim.
                      </p>
                    </div>
                  </li>
                  <li>
                    <div className="seemingly">
                      <h5 className="Seek">
                        Don’t rush for a quick settlement{" "}
                      </h5>
                      <p>
                        Avoid rushing to accept the initial settlement offer.
                        Instead, discuss the offer with your solicitor to ensure
                        it fairly compensates you for the losses and injuries.
                      </p>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
            <div
              className="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-12"
              id="dualcardiv"
            >
              <img src={dualcar} alt="" className="dualcar" />
            </div>
            <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-12">
              <div className="Preparing">
                <ul>
                  <li>
                    <div className="seemingly">
                      <h5 className="Seek">Refrain from using social media</h5>
                      <p>
                        Until your <strong>Post Office insurance claim </strong>
                        gets resolved, the best bet is to avoid using social
                        media. The posts you share on social media can be used
                        by the other party to lower the compensation.
                      </p>
                    </div>
                  </li>
                  <li>
                    <div className="seemingly">
                      <h5 className="Seek"> Exchange details</h5>
                      <p>
                        Exchange vital information with other drivers involved
                        in the accident, such as name, contact information,
                        vehicle information and insurance details.
                      </p>
                    </div>
                  </li>
                  <li>
                    <div className="seemingly">
                      <h5 className="Seek">Hire a no-fault claim solicitor</h5>
                      <p>
                        Contact us on{" "}
                        <strong>
                          the Post Office car insurance claim number
                        </strong>{" "}
                        to seek advice and guidance from an experienced no-fault
                        claim specialist to maximise your compensation.{" "}
                      </p>
                    </div>
                  </li>
                  <li>
                    <div className="seemingly">
                      <h5 className="Seek">Register your claim </h5>
                      <p>
                        When you’ve collected all the evidence and crucial
                        information, call us on the{" "}
                        <strong>Post Office insurance claim number </strong>to
                        initiate your claim.
                      </p>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="whyus">
        <div className="container">
          <div className="row">
            <div className="col-xxl-12 col-md-12">
              <div className="why">
                <h2 className="choose">
                  Our streamlined and{" "}
                  <span className="light">hassle-free claims process</span>
                </h2>
                <p className="complexities">
                  When making your{" "}
                  <strong>Post Office car insurance claim </strong>with us, you
                  don’t have to go through long queues or complex procedures.
                  With our smooth and fast-paced process, you can make your
                  claim in just 4 steps.{" "}
                </p>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-xxl-3 col-xl-6 col-lg-6 col-md-6 col-12">
              <div className="box-service">
                <img src={check} className="check" alt="" />
                <h4>Contact us</h4>
                <p>
                  Get in touch with our team on the{" "}
                  <strong>
                    insurance claim phone number{" "}
                    <a href="tel:0800 772 0850">0800 772 0850</a>{" "}
                  </strong>
                  to book a free initial consultation with one of our
                  experienced solicitors.
                </p>
              </div>
            </div>
            <div className="col-xxl-3 col-xl-6 col-lg-6 col-md-6 col-12">
              <div className="box-service">
                <img src={check} className="check" alt="" />
                <h4>Share accident details</h4>
                <p>
                  From the time of the accident to the location and the
                  circumstances that resulted in the accident, share every
                  detail with us.
                </p>
              </div>
            </div>
            <div className="col-xxl-3 col-xl-6 col-lg-6 col-md-6 col-12">
              <div className="box-service">
                <img src={check} className="check" alt="" />
                <h4>Submit evidence</h4>
                <p>
                  Hand over all the crucial evidence you’ve gathered from the
                  accident scene, including photographs, videos, copy of the
                  police report, eyewitness statements or any other vital
                  information that could make your{" "}
                  <strong>Post Office insurance claim </strong>solid.
                </p>
              </div>
            </div>
            <div className="col-xxl-3 col-xl-6 col-lg-6 col-md-6 col-12">
              <div className="box-service">
                <img src={check} className="check" alt="" />
                <h4>Claim submission </h4>
                <p>
                  Once we’ve got all supporting evidence and documentation
                  ready, we will submit your{" "}
                  <strong>Post Office car insurance claim </strong>and work
                  towards resolving it at the earliest.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="faqs">
        <div className="container">
          <div className="row">
            <div className="col-xxl-12 col-xl-12 col-md-12 col-12">
              <div className="fq">
                <h2>
                  {" "}
                  Frequently Asked{" "}
                  <span className="light">Questions (FAQ)</span>
                </h2>
                <p className="complexities">
                  Got questions about the{" "}
                  <strong>Post Office car insurance claim? </strong>We have them
                  answered here.
                </p>
              </div>
              <div className="allfaq">
                <Accordion defaultActiveKey="0">
                  <Accordion.Item eventKey="0">
                    <Accordion.Header>
                      Do I need to pay any excess?
                    </Accordion.Header>
                    <Accordion.Body>
                      No. You don’t have to pay any excess for the services as
                      we will recover all the costs from the other party’s
                      insurer.
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="1">
                    <Accordion.Header>
                      Can I choose my own repairer?
                    </Accordion.Header>
                    <Accordion.Body>
                      Absolutely. We provide you the freedom to choose your own
                      repairer. If you’d like to opt for your own repairer, we'd
                      schedule your vehicle repairs from your chosen repair
                      centre.
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="2">
                    <Accordion.Header>
                      Will my no-claims bonus be affected if I use your
                      services?
                    </Accordion.Header>
                    <Accordion.Body>
                      No. Our skilled claim handlers will initiate the claim
                      directly against the other party, leaving your no-claims
                      bonus and insurance premiums intact.
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="3">
                    <Accordion.Header>
                      How long can I keep the replacement vehicle?{" "}
                    </Accordion.Header>
                    <Accordion.Body>
                      If your vehicle is undrivable after the road accident and
                      is undergoing repairs, you can keep the replacement
                      vehicle until the repairs are completed.
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="4">
                    <Accordion.Header>
                      Will the replacement vehicle be the same as my own
                      vehicle?
                    </Accordion.Header>
                    <Accordion.Body>
                      Irrespective of your vehicle type, we strive to provide a
                      like-for-like replacement vehicle that suits your needs.
                      When you call us on the
                      <strong>post office car insurance claim number</strong>,
                      we will provide a comparable replacement vehicle.
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="5">
                    <Accordion.Header>
                      Will the vehicle repairs be under guarantee?{" "}
                    </Accordion.Header>
                    <Accordion.Body>
                      Yes. The repairs provided by our manufacturer-approved
                      repairers are under guarantee. The guarantee lasts as long
                      as you own the vehicle.
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="6">
                    <Accordion.Header>
                      Can you help me recover all the losses I’ve suffered due
                      to the accident?{" "}
                    </Accordion.Header>
                    <Accordion.Body>
                      We can help you recover the expenses and losses you’ve
                      suffered as a result of the car accident, including
                      medical expenses, transportation costs, replacement car
                      costs, vehicle repairs, lost wages, compensation for
                      physical and emotional pain, property damages or any other
                      out-of-pocket expenses you’ve incurred.
                    </Accordion.Body>
                  </Accordion.Item>
                </Accordion>
              </div>
            </div>
          </div>
        </div>
      </section>

      <Footer />
    </>
  );
};

export default Postoffice;
