import React, { useRef } from "react";
import insurancelogo from "../../src/assets/images/insurancelogo.png";
import Accordion from "react-bootstrap/Accordion";
import check from "../assets/images/check.png";
import dualcar from "../../src/assets/images/dualcar.webp";
import bner from "../../src/assets/images/bner.webp";
import Sidebarinner from "../components/Sidebar-inner";
import phonecall from "../assets/images/phonecall.png";
import Footer from "../components/footer";
import { Helmet } from "react-helmet";

const Admiralaccident = () => {
  const sidebarRef = useRef(null);

  const handleScrollToSidebar = () => {
    if (sidebarRef.current) {
      sidebarRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Admiral Car Insurance Claims | Admiral Claims Contact Number</title>
        <meta
          name="description"
          content="Seeking assistance with car accident claims? Get in touch with our team on the Admiral Car Accident Claims Contact Number 0800 772 0850 for independent advice.   "
        />
        <link
          rel="canonical"
          href="https://insurance-accident-claims.co.uk/Admiral-accident-claims"
        />
      </Helmet>
      <section className="innernav">
        <div className="container">
          <div className="row">
            <div className="col-md-6 col-sm-6">
              <div className="left-logo">
                {/* <img src={insurancelogo} className='insurancelogo' alt='Insurance Logo' /> */}
                <h3>Accident Claims</h3>
              </div>
            </div>
            <div className="col-md-6 col-sm-6">
              <div className="left-cta">
                <div class="headercnt">
                  <div class="chatmsg">
                    <img
                      src={phonecall}
                      className="phonecall"
                      alt="phonecall "
                    />
                  </div>
                  <div class="box-body">
                    <h3 class="nav-info-box-title"> 24/7 Helpline</h3>
                    <p>
                      <a href="tel:0800 772 0850">0800 772 0850</a>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section
        className="newbnr"
        style={{ backgroundImage: "url(" + bner + ")" }}
      >
        <div className="container">
          <div className="row">
            <div className="col-xxl-7 col-xl-7 col-md-10 col-12">
              <div className="innerbnr">
                <h1>
                  <span className="onespan">No-Fault</span>{" "}
                  <span className="sci">car accident</span> insurance claims
                </h1>
                <p className="brokerage">
                  From initial{" "}
                  <span className="yellow">report car accident support </span>to
                  entire claim settlement, we handle every aspect of your
                  no-fault claim.{" "}
                </p>
                <div className="bnrbtn">
                  <a
                    class="db-btn-border btn-rollover phone"
                    href="tel:0800 772 0850"
                  >
                    Start your claim with us{" "}
                    <i class="fa-solid fa-arrow-right"></i>
                  </a>
                </div>
                <div className="bnrbtn">
                  <a
                    className="db-btn-border btn-rollover desktop"
                    onClick={handleScrollToSidebar}
                  >
                    Start your claim with us{" "}
                    <i className="fa-solid fa-arrow-right"></i>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="stickysection">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="group-4-8">
                <div className="cl8 tf-tab">
                  <div className="content-tab">
                    <div className="inner-content">
                      <h2>
                        Reliable, affordable and <br />
                        <span className="light">
                          professional claims management{" "}
                        </span>{" "}
                      </h2>
                      <p className="disciplines">
                        We’re dedicated to helping individuals involved in
                        no-fault accidents by offering them the best solution
                        for their needs. When the claims department is busy, and
                        you’re looking for immediate assistance after being
                        involved in a no-fault accident, contact us at our car
                        insurance claim number. With us, there won’t be any
                        hidden charges related to your no-fault claim.
                      </p>
                      <hr />
                      <p className="bold-text">No excess to pay </p>
                      <p className="bottom-te">
                        We offer no-fault claims services based on a no-excess
                        policy. So, when you reach out to us on our{" "}
                        <strong>admiral claims helpline</strong> to start your
                        claim, you don’t have to stress about paying upfront
                        costs or excess fees as we won’t make a claim on your
                        own insurance policy.{" "}
                      </p>
                      <hr />
                      <p className="bold-text">NCB unaffected </p>
                      <p className="bottom-te">
                        Our no-fault claim experts expertly handle the claims
                        process to protect your earned discounts. We won’t
                        initiate a claim against your own insurance policy,
                        leaving your no-claim bonus unaffected. You can contact
                        us through our admiral accident claim phone number to
                        make a claim without affecting your NCB.
                      </p>
                      <hr />
                      <p className="bold-text">High-quality repairs </p>
                      <p className="bottom-te">
                        We partner with a reliable network of repair facilities
                        that offer high-quality repairs using the original
                        parts. Moreover, when you call the{" "}
                        <strong>admiral accident number</strong>, we provide you
                        with the freedom to get your vehicle repaired from your
                        preferred garage.{" "}
                      </p>
                      <hr />
                      <p className="bold-text">No-cost replacement vehicle </p>
                      <p className="bottom-te">
                        You can call us at the{" "}
                        <strong>admiral accident claim</strong> number to get a
                        courtesy car while yours is being repaired. We will
                        provide you with a like-for-like replacement vehicle
                        without any additional charges.{" "}
                      </p>
                      <hr />
                      <p className="bold-text">Dedicated claims handler </p>
                      <p className="bottom-te">
                        We will assign you a dedicated claims handler who will
                        guide you and handle every aspect of your claim to
                        ensure you get the rightful compensation for the
                        injuries and damages sustained in a no-fault accident.{" "}
                      </p>
                      <hr />
                      <p className="bold-text">Quick claim settlement </p>
                      <p className="bottom-te">
                        You can get in touch with us on the{" "}
                        <strong>
                          {" "}
                          accident number{" "}
                          <a href="tel:0800 772 0850">0800 772 0850</a>
                        </strong>
                        , and we will swiftly start your claim process. You
                        don’t wait for too long to get the deserving
                        compensation for your injuries.
                      </p>
                      <hr />
                      <p className="bold-text">Expert solicitor advice </p>
                      <p className="bottom-te">
                        We work with professional and trusted solicitors in the
                        UK. When you call our{" "}
                        <strong>admiral accident claims</strong> number, you’re
                        assured of getting expert advice and guidance at every
                        step of the way. So, to get the best advice related to
                        your claim, get in touch with us on our{" "}
                        <strong>admiral accident number.</strong>{" "}
                      </p>
                      <hr />
                      <p className="bold-text">Transparent approach </p>
                      <p className="bottom-te">
                        We believe in transparency and open communication.
                        Therefore, we keep our{" "}
                        <strong>admiral report accident customers </strong>
                        informed about their claim process at every step. Reach
                        out to us through the{" "}
                        <strong>admiral claims contact number </strong>to start
                        your claim process without any hassle.{" "}
                      </p>
                      <hr />
                      <p className="bold-text">No fault specialists </p>
                      <p className="bottom-te">
                        We’re dedicated solely to handling no-fault accident
                        claims in the UK. Henceforth, we secure the best
                        possible outcomes for our{" "}
                        <strong>admiral report accident customers </strong>using
                        our extensive knowledge and experience.{" "}
                      </p>
                      <hr />

                      <p className="bold-text"> Prompt response </p>
                      <p className="bottom-te">
                        We strive to offer your immediate assistance for your
                        accident management needs. When you contact us on our
                        admiral claims contact number, our trusted professionals
                        will provide immediate assistance to address your needs.{" "}
                      </p>
                    </div>
                  </div>
                </div>
                <div className="cl4" ref={sidebarRef}>
                  <Sidebarinner />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="photographs">
        <div className="container">
          <div className="row">
            <h2 className="road">
              We help you get back{" "}
              <span className="light">
                on <br />
                the road
              </span>{" "}
            </h2>
            <div className="col-xxl-6 col-xl-6 col-md-12 col-12">
              <div className="fourbox">
                <ul>
                  <li>
                    <img src={check} className="check" alt="" />
                    When you get in touch with us on our admiral claims contact
                    number to get{" "}
                    <strong>admiral insurance accident support,</strong> we will
                    understand the details of your accident and claim.
                  </li>
                  <hr />
                  <li>
                    <img src={check} className="check" alt="" />
                    If we think there are favourable chances of securing
                    compensation for you, we will proceed with your claim. Our
                    solicitors will guide you with the further steps and
                    documentation process.
                  </li>
                  <hr />
                  <li>
                    <img src={check} className="check" alt="" />
                    If your vehicle is damaged due to the accident, we will
                    provide you with a free courtesy car that matches your
                    needs. You can contact us on the admiral{" "}
                    <strong>
                      car accident helpline{" "}
                      <a href="tel:0800 772 0850">0800 772 0850</a>
                    </strong>{" "}
                    to request a replacement vehicle.
                  </li>
                  <hr />
                  <li>
                    <img src={check} className="check" alt="" />
                    You can use the free courtesy car until your vehicle gets
                    repaired. In case your vehicle is entirely written off, you
                    can use our car until you get a cheque for its full value.
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-xxl-6 col-xl-6 col-md-12 col-12">
              <div className="fourbox">
                <ul>
                  <li>
                    <img src={check} className="check" alt="" />
                    If you have suffered any injuries due to the no-fault
                    accident, you can reach out to us on the admiral{" "}
                    <strong>car insurance claim </strong>number. We will put you
                    in touch with an experienced solicitor who will move ahead
                    with your claim.
                  </li>
                  <hr />
                  <li>
                    <img src={check} className="check" alt="" />
                    Once your claim gets settled, you’ll get your vehicle after
                    repairs or if your vehicle was fully written off, you’ll
                    receive a cheque amounting to its full value.
                  </li>
                  <hr />
                  <li>
                    <img src={check} className="check" alt="" />
                    When you receive your repaired vehicle, we will collect our
                    replacement car back from you.
                  </li>
                  <hr />
                  <li>
                    <img src={check} className="check" alt="" />
                    Your solicitors will claim compensation for your losses and
                    damages from the at-fault party’s insurance company.
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="carprocess">
        <div className="container ">
          <div className="row">
            <div className="col-xxl-12 col-xxl-12 col-md-12 col-12">
              <h2 className="trusted-title">
                {" "}
                Comprehensive admiral insurance <br />
                <span className="light">accident support services</span>{" "}
              </h2>
            </div>
          </div>
          <div className="row" id="possible">
            <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-12">
              <div className="claim">
                <ul>
                  <li>
                    <span className="sleej">Vehicle Recovery:</span> <br />
                    You can call the{" "}
                    <strong>admiral car accident helpline </strong>to get your
                    damaged vehicle retrieved from the accident scene. Our
                    dedicated round-the-clock vehicle recovery team will
                    facilitate quick recovery of your damaged car from the
                    accident scene and get it transported to a secure location.
                  </li>
                  <li>
                    <span className="sleej">Vehicle Repair:</span> <br />
                    Our professional team will provide you with a complete
                    solution for car accident repairs. When you contact us
                    through the{" "}
                    <strong>
                      {" "}
                      claims contact number{" "}
                      <a href="tel:0800 772 0850">0800 772 0850</a>,
                    </strong>{" "}
                    we will ensure instant vehicle repairs from the garage of
                    your choice.
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-12">
              <div className="claim" id="blue">
                <ul>
                  <li>
                    <span className="sleej">Vehicle Storage:</span> <br />
                    After the no-fault accident, we will make the necessary
                    arrangements to store your vehicle in our secured storage
                    facility until further assessment and to prevent more
                    deterioration. You can call the{" "}
                    <span>
                      {" "}
                      car insurance claim number{" "}
                      <a href="tel:0800 772 0850">0800 772 0850</a>
                    </span>{" "}
                    for hassle-free and safe storage of your vehicle in our
                    well-maintained yards that offer complete security. Also, we
                    will safely store vehicle parts for your ease.
                  </li>
                  <li>
                    <span className="sleej">Replacement Vehicle: </span> <br />
                    You can contact us via the{" "}
                    <strong>admiral accident number </strong>to get a temporary
                    vehicle after the no-fault accident. Our dedicated team will
                    arrange a like-for-like replacement vehicle according to
                    your requirements so that you can continue performing your
                    daily tasks without any worry. Get in touch with us on the
                    admiral <strong>accident claims number </strong>to get a
                    comparable car to get back on the road again
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-12">
              <div className="claim">
                <ul>
                  <li>
                    <span className="sleej">Personal Injury Claim:</span>
                    <br />
                    Our experienced solicitors have vast experience in getting
                    maximum compensation for personal injury claims. As an
                    <strong> admiral report accident customer</strong>, you’ll
                    receive the best support and guidance from our panel of
                    expert solicitors.
                  </li>
                  <li>
                    <span className="sleej">Full claims management:</span>
                    <br /> We provide start-to-end claim management services to
                    ensure<strong> admiral report accident </strong>customers
                    get deserving compensation for their injuries and damages.
                    You can call the admiral car accident helpline to get
                    trusted advice for your claim.
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="yellowcta">
        <div className="container">
          <div className="row">
            <div className="cta2">
              <div className="col-xxl-8 col-xl-8 col-lg-8 col-12">
                <div className="legal">
                  <h3 className="experienced">
                    Got Questions?{" "}
                    <span className="light">Speak to Our Specialists.</span>{" "}
                  </h3>
                  <p className="professionals">
                    Get in touch with us on the{" "}
                    <strong>
                      {" "}
                      accident claims helpline{" "}
                      <a href="tel:0800 772 0850">0800 772 0850</a>
                    </strong>{" "}
                    to schedule a consultation with our experts to address your
                    doubts and start your claim process.
                  </p>
                </div>
              </div>
              <div className="col-xxl-4 col-xl-4 col-lg-4 col-12">
                <div class="process">
                  <a class="tf-button style-1 phone" href="tel:0800 772 0850">
                    Get in touch<span class="icon-keyboard_arrow_right"></span>
                  </a>
                </div>
                <div class="process">
                  <a
                    className="tf-button style-1 desktop"
                    onClick={handleScrollToSidebar}
                  >
                    Get in touch
                    <span className="icon-keyboard_arrow_right"></span>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <search className="updatedform">
        <div className="container">
          <div className="row text-center">
            <div className="col-xxl-12 col-xl-12 col-md-12 col-12">
              <h2 className="whiteheading">
                {" "}
                Why choose us for your no-fault <br />
                <span className="light">accident management needs? </span>
              </h2>
              <p className="sustained">
                Before making a no-fault claim with your insurance company, call
                the <strong>admiral accident claim phone number.</strong> We’ve
                got better solutions to maximize your claim than your own
                insurer.{" "}
              </p>
            </div>
          </div>
          <div className="row">
            <div className="col-xxl-4 col-xl-4 col-lg-6 col-md-12 col-12">
              <div className="Claimant">
                <h5>No upfront charges </h5>
                <p className="collision">
                  There are no hidden or upfront charges. You don’t have to pay
                  any extra charges to get admiral insurance accident support
                  services. We will ensure that the at-fault party’s driver’s
                  insurer pays for all your expenses.
                </p>

                <h5> Effortless claim processing </h5>
                <p className="collision">
                  Our claim-handling approach is simple and streamlined. Call us
                  at the <strong>admiral accident claim phone number</strong>,
                  and we will handle everything, right from damaged vehicle
                  recovery to claim settlement.{" "}
                </p>

                <h5>Expert solicitors </h5>
                <p className="collision">
                  We work with experienced and professional solicitors who
                  handle your claim professionally and efficiently to secure
                  maximum compensation. With us, you can be confident of
                  receiving the best guidance at every step.
                </p>
              </div>
            </div>
            <div className="col-xxl-4 col-xl-4 col-lg-6 col-md-12 col-12">
              <div className="Claimant">
                <h5>24/7 support </h5>
                <p className="collision">
                  We have a dedicated team who is available round the clock to
                  offer timely assistance and support for your no-fault car
                  accident management needs. Get in touch with us on the{" "}
                  <strong>
                    accident claims number{" "}
                    <a href="tel:0800 772 0850">0800 772 0850</a>
                  </strong>{" "}
                  to get emergency assistance following an accident.
                </p>

                <h5>Hassle-free service </h5>
                <p className="collision">
                  You no longer have to chase around after being involved in a
                  no-fault accident. We will assist you and connect you with
                  no-fault claim experts. We will handle all the stress and
                  hassle of your <strong>admiral road traffic accident</strong>{" "}
                  so that you can focus on your recovery.
                </p>

                <h5>High-standard replacement vehicle </h5>
                <p className="collision">
                  We understand that dealing with{" "}
                  <strong>admiral road traffic accidents </strong>is a stressful
                  process. When you contact us on the{" "}
                  <strong>admiral accident number</strong>, our friendly team
                  will provide you with a top-quality replacement vehicle
                  matching your own.{" "}
                </p>
              </div>
            </div>
            <div className="col-xxl-4 col-xl-4 col-lg-12 col-md-12 col-12">
              <div className="Claimant">
                <h5>No obligation advice </h5>
                <p className="collision">
                  Get in touch with us on the admiral{" "}
                  <strong>car insurance claim number </strong>to get a free
                  initial consultation related to your claim without any
                  obligation to proceed further.
                </p>

                <h5> Manufacturer-approved repairs </h5>
                <p className="collision">
                  We ensure your peace of mind by providing high-quality repairs
                  using manufacturer-approved parts, safeguarding your vehicle’s
                  warranty and value.
                </p>

                <h5>We go the extra mile</h5>
                <p className="collision">
                  Being one of the most trusted accident claim management
                  companies, we go the extra mile to provide you with the best
                  solutions to minimize your financial losses after an accident.
                </p>
              </div>
            </div>
          </div>
        </div>
      </search>

      <section className="Accident-claim">
        <div className="container">
          <div className="row">
            <div className="needed">
              <h2 className="trusted-title">
                How you can maximize admiral <br />
                <span className="light"> car insurance claims</span>
              </h2>
              <p className="complexities">
                Want to make a no-fault car accident claim? Here’s how you can
                ensure you <br />
                receive maximum compensation from your accident claim.{" "}
              </p>
            </div>
            <hr />
          </div>
          <div className="row" id="incdes">
            <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-12">
              <div className="Preparing">
                <ul>
                  <li>
                    <div className="seemingly">
                      <h5 className="Seek">Contact emergency services</h5>
                      <p>
                        {" "}
                        Promptly call the emergency services and{" "}
                        <strong>admiral car accident helpline</strong> if
                        required. Also, <strong>report car accident </strong>to
                        the relevant authorities, such as police or highway
                        patrol.{" "}
                      </p>
                    </div>
                  </li>
                  <li>
                    <div className="seemingly">
                      <h5 className="Seek"> Gather information </h5>
                      <p>
                        Collect evidence from the accident scene, including
                        photos of the vehicles, registration numbers, details of
                        individuals involved in the accident and witness
                        statements to determine who is at fault.{" "}
                      </p>
                    </div>
                  </li>
                  <li>
                    <div className="seemingly">
                      <h5 className="Seek">Seek medical attention</h5>
                      <p>
                        Check yourself or any other passengers for the injuries.
                        If you find minor or severe injuries, immediately seek
                        medical help from professionals.
                      </p>
                    </div>
                  </li>
                  <li>
                    <div className="seemingly">
                      <h5 className="Seek">Exchange details</h5>
                      <p>
                        Exchange your details with the third parties involved in
                        the accident. These details must include your car
                        registration no, name and address, contact number and
                        insurance details.
                      </p>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
            <div
              className="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-12"
              id="dualcardiv"
            >
              <img src={dualcar} alt="" className="dualcar" />
            </div>
            <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-12">
              <div className="Preparing">
                <ul>
                  <li>
                    <div className="seemingly">
                      <h5 className="Seek">Avoid settling too quickly</h5>
                      <p>
                        The insurance companies may encourage you to make an
                        early settlement of the claim. Avoid accepting an
                        initial settlement offer without assessing the extent of
                        the damages.{" "}
                      </p>
                    </div>
                  </li>
                  <li>
                    <div className="seemingly">
                      <h5 className="Seek">
                        {" "}
                        Contact admiral car insurance claim number
                      </h5>
                      <p>
                        Call the <strong>admiral claims contact number </strong>
                        as soon as possible after the accident and provide the
                        necessary details related to the accident. We will
                        arrange for the vehicle recovery and courtesy car.
                      </p>
                    </div>
                  </li>
                  <li>
                    <div className="seemingly">
                      <h5 className="Seek">Accidental repairs</h5>
                      <p>
                        When you call the{" "}
                        <strong>admiral claims contact number,</strong> we will
                        ensure quick repairs of your vehicle at an approved
                        repair facility without any additional cost.{" "}
                      </p>
                    </div>
                  </li>
                  <li>
                    <div className="seemingly">
                      <h5 className="Seek">File a no-fault claim</h5>
                      <p>
                        Contact the{" "}
                        <strong>admiral accident claims helpline </strong>and
                        make a no-fault no claim to get maximum compensation for
                        the injuries and damages you’ve suffered as a result of
                        a no-fault car accident.
                      </p>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="whyus">
        <div className="container">
          <div className="row">
            <div className="col-xxl-12 col-md-12">
              <div className="why">
                <h2 className="choose">
                  How can you start{" "}
                  <span className="light">your claim with us?</span>
                </h2>
                <p className="complexities">
                  You can contact the{" "}
                  <strong>admiral accident claims number </strong>to get
                  exceptional accident management services and trusted advice
                  related to your no-fault claim. When making a claim with us,
                  follow these steps. Get in touch with us on the{" "}
                  <strong>admiral accident number </strong>and start your claim
                  in these easy steps.{" "}
                </p>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-xxl-3 col-xl-6 col-lg-6 col-md-6 col-12">
              <div className="box-service">
                <img src={check} className="check" alt="" />
                <h4>Call us </h4>
                <p>
                  Call us on our <strong>admiral accident helpline </strong>to
                  speak to our expert claim handlers and start your claim
                  process after being involved in a no-fault accident. Share the
                  accident details and the amount of compensation you’re seeking
                  from the at-fault party.
                </p>
              </div>
            </div>
            <div className="col-xxl-3 col-xl-6 col-lg-6 col-md-6 col-12">
              <div className="box-service">
                <img src={check} className="check" alt="" />
                <h4>Explain your claim </h4>
                <p>
                  Get in touch with our experts on{" "}
                  <strong>admiral claims contact number</strong> and provide us
                  with the necessary details related to the accident and any
                  minor or severe injuries you’ve sustained. Our solicitors will
                  then discuss your rights and options to pursue a claim.
                </p>
              </div>
            </div>
            <div className="col-xxl-3 col-xl-6 col-lg-6 col-md-6 col-12">
              <div className="box-service">
                <img src={check} className="check" alt="" />
                <h4>Provide documentation </h4>
                <p>
                  We might require certain documents related to your accident
                  such as the police report, medical records, photographs from
                  the accident scene, eyewitness statements or any other
                  evidence that you may have. This will help us make your claim
                  stronger.{" "}
                </p>
              </div>
            </div>
            <div className="col-xxl-3 col-xl-6 col-lg-6 col-md-6 col-12">
              <div className="box-service">
                <img src={check} className="check" alt="" />
                <h4>Wait to get qualified </h4>
                <p>
                  That’s it. Once we have all the required documents, we will
                  initiate your claim and keep you informed about the updates.
                  Within a short period, you’ll receive the deserving
                  compensation for the injuries and damages sustained due to the
                  accident.{" "}
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="faqs">
        <div className="container">
          <div className="row">
            <div className="col-xxl-12 col-xl-12 col-md-12 col-12">
              <div className="fq">
                <h2>
                  {" "}
                  Frequently Asked{" "}
                  <span className="light">Questions (FAQ)</span>
                </h2>
                <p className="complexities">
                  Have questions related to our no-fault car accident management
                  services? We have them answered here.{" "}
                </p>
              </div>
              <div className="allfaq">
                <Accordion defaultActiveKey="0">
                  <Accordion.Item eventKey="0">
                    <Accordion.Header>
                      What accident details are required for admiral insurance
                      accident support services?
                    </Accordion.Header>
                    <Accordion.Body>
                      You need to provide your personal information and accident
                      details to get{" "}
                      <strong>admiral insurance accident support</strong>{" "}
                      services. This includes your name, address and contact
                      number. Additionally, you must provide us with details of
                      vehicle registration numbers, photographs, eyewitnesses'
                      names and statements and CCTV footage from the accident
                      scene. This will help us to establish liability in your
                      favor.
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="1">
                    <Accordion.Header>
                      When should I contact the admiral car accident helpline
                      after having been involved in a no-fault accident?
                    </Accordion.Header>
                    <Accordion.Body>
                      To improve the chances of a successful claim, you must
                      call the admiral car insurance claim number immediately
                      after the accident or as soon as possible and provide us
                      with all the essential details that can help us make your
                      claim stronger.
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="2">
                    <Accordion.Header>
                      What to do if someone hits my car and flees from the
                      scene?
                    </Accordion.Header>
                    <Accordion.Body>
                      You must stop your car at a safe place and inform the
                      police immediately. In addition, check the injuries and
                      gather evidence from the accident scene along with the
                      witness statements. Contact the police and provide them
                      with all the details.
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="3">
                    <Accordion.Header>
                      How long after the no-fault accident should I wait to
                      claim repairs for my damaged vehicle?{" "}
                    </Accordion.Header>
                    <Accordion.Body>
                      If your vehicle has been damaged and you want to claim
                      repairs, you can decide when to initiate a claim for your
                      damaged vehicle after the accident. In case of a no-fault
                      accident, you can contact the admiral car accident
                      helpline to get costly repairs done without incurring any
                      additional charges.
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="4">
                    <Accordion.Header>
                      Can I choose my own repairer for no-fault accidental
                      repairs?
                    </Accordion.Header>
                    <Accordion.Body>
                      Yes. We offer you the freedom to choose your preferred
                      garage for repairs. You can provide us with the details of
                      your repairer, and we will get your vehicle repaired from
                      your chosen repair network.
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="5">
                    <Accordion.Header>
                      Can I get compensation for whiplash injuries sustained in
                      the accident?{" "}
                    </Accordion.Header>
                    <Accordion.Body>
                      You must immediately get medical attention from a
                      professional if you have any symptoms of whiplash
                      injuries. Furthermore, call the admiral accident claim
                      phone number to know your rights and options to secure a
                      fair amount of compensation for your whiplash injuries.
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="6">
                    <Accordion.Header>
                      How much time will it take for my car to get repaired
                      after the accident?{" "}
                    </Accordion.Header>
                    <Accordion.Body>
                      The duration of the repairs of your damaged vehicle
                      depends on several factors, such as the extent of damage,
                      availability of original parts and your vehicle’s
                      condition. Your repairer will provide you with an estimate
                      of the time required for your car repairs after the
                      inspection and assessment of your vehicle.
                    </Accordion.Body>
                  </Accordion.Item>
                </Accordion>
              </div>
            </div>
          </div>
        </div>
      </section>

      <Footer />
    </>
  );
};

export default Admiralaccident;
