import React, { useRef } from "react";
import Accordion from "react-bootstrap/Accordion";
import check from "../assets/images/check.png";
import dualcar from "../../src/assets/images/dualcar.webp";
import carbnr1 from "../../src/assets/images/carbnr1.webp";
import Sidebarinner from "../components/Sidebar-inner";
import phonecall from "../assets/images/phonecall.png";
import Footer from "../components/footer";
import { Helmet } from "react-helmet";

const Lancasterinsurance = () => {
  const sidebarRef = useRef(null);

  const handleScrollToSidebar = () => {
    if (sidebarRef.current) {
      sidebarRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Lancaster Car Insurance Accident Claim Phone Number:</title>
        <meta
          name="description"
          content="Have you been a victim of a no-fault accident? Contact us on the Lancaster Car Insurance accident claim phone number 0800 772 0850 for fast and trusted claim support. "
        />
        <link
          rel="canonical"
          href="https://insurance-accident-claims.co.uk/lancaster-insurance"
        />
      </Helmet>
      <section className="innernav">
        <div className="container">
          <div className="row">
            <div className="col-md-6 col-sm-6">
              <div className="left-logo">
                <h3>
                  Accident<span className="sipo"> Claims</span>
                </h3>
              </div>
            </div>
            <div className="col-md-6 col-sm-6">
              <div className="left-cta">
                <div class="headercnt">
                  <div class="chatmsg">
                    <img
                      src={phonecall}
                      className="phonecall"
                      alt="phonecall "
                    />
                  </div>
                  <div class="box-body">
                    <h3 class="nav-info-box-title">Toll Free Number </h3>
                    <p>
                      <a href="tel:0800 772 0850">0800 772 0850</a>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section
        className="newbnr"
        style={{ backgroundImage: "url(" + carbnr1 + ")" }}
      >
        <div className="container">
          <div className="row">
            <div className="col-xxl-8 col-xl-8 col-md-10 col-12">
              <div className="innerbnr">
                <h1>
                  <span className="onespan">Accident management</span>{" "}
                  <span className="sci">solutions </span> and claims assistance
                </h1>
                <p className="brokerage">
                  We specialise in managing all aspects of a non-fault car
                  accident from vehicle recovery to claims handling and much
                  more.
                </p>
                <div className="bnrbtn">
                  <a class="db-btn-border btn-rollover phone" href="tel:0800 772 0850">
                    Road Accident Claim Help?{" "}
                    <i class="fa-solid fa-arrow-right"></i>
                  </a>
                </div>
                <div className="bnrbtn">
                <a className="db-btn-border btn-rollover desktop"  onClick={handleScrollToSidebar}>
                    Road Accident Claim Help? <i className="fa-solid fa-arrow-right"></i>
                </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="stickysection">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="group-4-8">
                <div className="cl8 tf-tab">
                  <div className="content-tab">
                    <div className="inner-content">
                      <h2>
                        Cost Free Accident <br />
                        <span className="light">Assistance </span>{" "}
                      </h2>
                      <p className="disciplines">
                        Are you a victim of a UK road traffic no-fault accident?
                        Call us on <a href="tel:0800 772 0850">0800 772 0850</a>{" "}
                        for emergency assistance while your vehicle is stranded.
                        We offer elite{" "}
                        <strong> Lancaster insurance accident claims </strong>{" "}
                        assistance with no hidden charges or surprises.
                      </p>
                      <hr />
                      <p className="bold-text">No upfront payments </p>
                      <p className="bottom-te">
                        If you get involved in an unfortunate accident resulting
                        in a car crash, call our{" "}
                        <strong>Lancaster car accident helpline</strong> for
                        instant help and support. There is also no upfront
                        payment required by us because all expenses will be paid
                        by the insurer of the third party at fault.{" "}
                      </p>
                      <hr />
                      <p className="bold-text">No claim bonus stays intact</p>
                      <p className="bottom-te">
                        If you make a claim through your insurer, this may
                        affect your well-earned discounts. Unlike your insurance
                        company, we do not initiate proceedings against your own
                        policy, which maintains your no-claims bonus intact.
                      </p>
                      <hr />
                      <p className="bold-text">Dedicated claim handler</p>
                      <p className="bottom-te">
                        You can reach us through the{" "}
                        <strong>Lancaster accident claim phone number </strong>,
                        and once you do that, someone will be assigned to assist
                        you throughout your claims process.{" "}
                      </p>
                      <hr />
                      <p className="bold-text">
                        Comprehensive claims assistance
                      </p>
                      <p className="bottom-te">
                        Our team of specialist solicitors have vast experience
                        in dealing with no-fault claims. As such, you may expect
                        to receive excellent legal advice and support in
                        relation to your{" "}
                        <strong>Lancaster insurance accident claim. </strong>{" "}
                      </p>
                      <hr />
                      <p className="bold-text">Like-for-like replacement </p>
                      <p className="bottom-te">
                        The moment your car is in the garage, our first-class
                        replacement vehicle will be there to keep you moving and
                        reduce any inconvenience. If you wish to continue with
                        your normal routine, please contact us on accident
                        claims helpline{" "}
                        <strong>
                          <a href="tel:0800 772 0850">0800 772 0850</a>{" "}
                        </strong>{" "}
                        so that we can provide a similar like-for-like
                        replacement vehicle for you.
                      </p>
                      <hr />
                      <p className="bold-text">Superior quality repairs</p>
                      <p className="bottom-te">
                        We have a network of over one thousand approved garages
                        and repair experts in the UK who specialise in offering
                        top-notch repairs.
                      </p>
                      <hr />
                      <p className="bold-text">Continuous repair monitoring</p>
                      <p className="bottom-te">
                        We will organise car repairs for you as well as take
                        care of everything on your behalf.
                      </p>
                      <hr />
                      <p className="bold-text">
                        Fast claims processing & resolution
                      </p>
                      <p className="bottom-te">
                        Our{" "}
                        <strong> Lancaster insurance accident claim </strong>
                        process is simple and straightforward so that we quickly
                        deal with it.{" "}
                      </p>
                      <hr />
                      <p className="bold-text">
                        Experts in no-fault accident claims
                      </p>
                      <p className="bottom-te">
                        When it comes to handling your{" "}
                        <strong> Lancaster insurance accident claim </strong>,
                        we are devoted and professional. We ensure that we get
                        the very best result possible for you based on our
                        knowledge and skills.{" "}
                      </p>
                      <hr />

                      <p className="bold-text">Friendly supportive team</p>
                      <p className="bottom-te">
                        Our fully committed experts will walk with you from one
                        stage to another, providing guidance every step of the
                        way, including helping you put all the necessary papers
                        together.{" "}
                      </p>
                    </div>
                  </div>
                </div>
                <div className="cl4" ref={sidebarRef}>
                  <Sidebarinner />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="photographs">
        <div className="container">
          <div className="row">
            <h2 className="road">
              We quicken your recovery after{" "}
              <span className="light">
                a no-fault <br />
                car accident
              </span>{" "}
            </h2>
            <div className="col-xxl-6 col-xl-6 col-md-12 col-12">
              <div className="fourbox">
                <ul>
                  <li>
                    <img src={check} className="check" alt="" />
                    When you call us through our accident claims helpline{" "}
                    <strong>
                      {" "}
                      <a href="tel:0800 772 0850">0800 772 0850</a>
                    </strong>{" "}
                    , after a no-fault motor car mishap we will send emergency
                    aid to speed up your healing.
                  </li>
                  <hr />
                  <li>
                    <img src={check} className="check" alt="" />
                    Our solicitors' board shall evaluate your{" "}
                    <strong> Lancaster insurance accident claim </strong> and
                    provide advice on how to proceed with it.
                  </li>
                  <hr />
                  <li>
                    <img src={check} className="check" alt="" />
                    Our team is going to look at your vehicle's damages and
                    arrange for repair from a panel of approved garages and
                    repairers.
                  </li>
                  <hr />
                  <li>
                    <img src={check} className="check" alt="" />A replacement
                    vehicle will be brought to you within twenty-four hours so
                    that you can get back on the road very quickly.
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-xxl-6 col-xl-6 col-md-12 col-12">
              <div className="fourbox">
                <ul>
                  <li>
                    <img src={check} className="check" alt="" />
                    From covering excess of your policies as well as
                    out-of-pocket expenses to dealing with personal injury
                    claims, we are there for you in every step of the process.
                  </li>
                  <hr />
                  <li>
                    <img src={check} className="check" alt="" />
                    When you call us on the{" "}
                    <strong> Lancaster claims contact number </strong> for
                    accident management services and claims assistance, we will
                    appoint a dedicated claims handler who will take care of all
                    aspects related to the claim.
                  </li>
                  <hr />
                  <li>
                    <img src={check} className="check" alt="" />
                    We will negotiate with insurers and other parties involved
                    so that your case may be settled promptly.
                  </li>
                  <hr />
                  <li>
                    <img src={check} className="check" alt="" />
                    We’ll protect your rights and ensure you receive fair
                    compensation for your losses and injuries.
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="carprocess">
        <div className="container ">
          <div className="row">
            <div className="col-xxl-12 col-xxl-12 col-md-12 col-12">
              <h2 className="trusted-title">
                {" "}
                Accident management services
                <br />
                <span className="light">we offer</span>{" "}
              </h2>
            </div>
          </div>
          <div className="row" id="possible">
            <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-12">
              <div className="claim">
                <ul>
                  <li>
                    <span className="sleej">Vehicle recovery:</span>Has your car
                    become unroadworthy following a no-fault motor vehicle
                    accident? Call us on our car insurance claim number{" "}
                    <a href="tel:0800 772 0850">0800 772 0850</a> to arrange the
                    recovery of your vehicle from the scene of the accident and
                    take it to a secure location.
                  </li>
                  <li>
                    <span className="sleej">Vehicle repairs:</span>Your car can
                    be damaged during an accident, which could render it
                    useless. We will evaluate your car's damages and have it
                    repaired at an accredited repairer’s shop that you choose
                    for yourself.
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-12">
              <div className="claim" id="blue">
                <ul>
                  <li>
                    <span className="sleej">Vehicle storage:</span> It is not
                    safe to park your vehicle on the road after a non-fault
                    accident. Therefore, once you call{" "}
                    <strong>Lancaster claims contact number </strong> , we will
                    make arrangements for keeping your broken-down vehicle in
                    our well-maintained facilities until it is again examined or
                    until you are paid reparation for repairs made.
                  </li>
                  <li>
                    <span className="sleej">Replacement car :</span>We can
                    assist you with receiving a replacement vehicle of similar
                    quality if you are involved in a not-at-fault collision
                    while driving in the UK. We will supply your credit hire car
                    promptly so that you can get back on the road without any
                    delay as soon as possible. It's easy to obtain another car
                    by calling us through the{" "}
                    <strong> Lancaster accident claim phone number. </strong>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-12">
              <div className="claim">
                <ul>
                  <li>
                    <span className="sleej">Injury compensation:</span>
                    Our seasoned solicitors can help whether you want to recoup
                    your expenditure or sue for damages on account of injury. We
                    will assist in the recovery of compensation for injuries, as
                    well as costs and losses connected with the accident.
                  </li>
                  <li>
                    <span className="sleej">Claims management:</span>Contact us
                    at the{" "}
                    <strong> Lancaster accident claims phone number </strong> to
                    make your claim through us. Our committed solicitors will
                    help you complete the documents before a swift adjudication
                    is made on{" "}
                    <strong> Lancaster insurance accident claim.</strong>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="yellowcta">
        <div className="container">
          <div className="row">
            <div className="cta2">
              <div className="col-xxl-8 col-xl-8 col-lg-8 col-12">
                <div className="legal">
                  <h3 className="experienced">
                    Need further assistance?{" "}
                    <span className="light">Speak to our experts</span>{" "}
                  </h3>
                  <p className="professionals">
                    Get in touch with our no-fault claim specialists to get
                    professional advice for your{" "}
                    <strong> Lancaster insurance accident claim. </strong>
                  </p>
                </div>
              </div>
              <div className="col-xxl-4 col-xl-4 col-lg-4 col-12">
              <div class="process">
                  <a class="tf-button style-1 phone" href="tel:0800 772 0850">
                    Get in touch<span class="icon-keyboard_arrow_right"></span>
                  </a>
                </div>
                <div class="process">
                    <a className="tf-button style-1 desktop"  onClick={handleScrollToSidebar}>
                        Get in touch<span className="icon-keyboard_arrow_right"></span>
                    </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <search className="updatedform">
        <div className="container">
          <div className="row text-center">
            <div className="col-xxl-12 col-xl-12 col-md-12 col-12">
              <h2 className="whiteheading">
                {" "}
                Why are we different from other UK <br />
                <span className="light">accident claims companies? </span>
              </h2>
              <p className="sustained">
                Unlike your insurer, we allow you to settle your accident issues
                promptly by organising top-quality vehicle repairs, providing
                like-for-like replacement and offering professional advice free
                of charge. Reach us through our accident claims helpline{" "}
                <a href="tel:0800 772 0850">0800 772 0850</a> for the best
                methods to deal with your accident management needs.
              </p>
            </div>
          </div>
          <div className="row">
            <div className="col-xxl-4 col-xl-4 col-lg-6 col-md-12 col-12">
              <div className="Claimant">
                <h5>Speedy aid</h5>
                <p className="collision">
                  Whenever there is an emergency, call us at the{" "}
                  <strong> Lancaster accident claims number </strong> , and our
                  team of specialists will attend to your needs, ensuring
                  minimal disruption.
                </p>

                <h5>Professional solicitors</h5>
                <p className="collision">
                  With proficiency and dedication that makes a difference, our
                  experienced group of experienced solicitors will handle
                  everything regarding your{" "}
                  <strong>Lancaster insurance accident claim. </strong>
                </p>

                <h5>Guaranteed repairs</h5>
                <p className="collision">
                  We repair your car as well as give you a guarantee of the
                  quality of repairs, ensuring that any vehicle fixes meet the
                  highest standards.
                </p>
              </div>
            </div>
            <div className="col-xxl-4 col-xl-4 col-lg-6 col-md-12 col-12">
              <div className="Claimant">
                <h5>Manufacturer-approved spare parts and paints</h5>
                <p className="collision">
                  To return it to its original status, our approved repair
                  centres use authentic materials and paint approved by car
                  makers in repairing your vehicle.
                </p>
                <h5>All-time assistance</h5>
                <p className="collision">
                  Our team will be there for you at any moment immediately after
                  a faultless road accident, guiding you on how to make{" "}
                  <strong> Lancaster insurance accident claims </strong> in the
                  UK.
                </p>

                <h5>Identical replacement vehicle</h5>
                <p className="collision">
                  In such cases, our accident management team will provide
                  alternative vehicles having similar features without requiring
                  additional payment.{" "}
                </p>
              </div>
            </div>
            <div className="col-xxl-4 col-xl-4 col-lg-12 col-md-12 col-12">
              <div className="Claimant">
                <h5>All-round claims assistance</h5>
                <p className="collision">
                  We offer all-around claims assistance that includes
                  reimbursement of the cost of hiring an equivalent car as well
                  as compensation for medical expenses, lost income and other
                  relevant damage compensations, which are included in the claim
                  settlement agreement.
                </p>

                <h5>Quick claim settlement</h5>
                <p className="collision">
                  Without unnecessary delays, we go through a straightforward
                  claim process that allows us to settle your claim quickly.
                </p>

                <h5>Storage and safe recovery</h5>
                <p className="collision">
                  Our representatives will quickly recover the wreckage from
                  where it was involved in an accident until it reaches our
                  secured storage facilities.
                </p>
              </div>
            </div>
          </div>
        </div>
      </search>

      <section className="Accident-claim">
        <div className="container">
          <div className="row">
            <div className="needed">
              <h2 className="trusted-title">
                How can you get the most out of your <br />
                <span className="light">
                  {" "}
                  Lancaster insurance accident claim?
                </span>
              </h2>
              <p className="complexities">
                Car accidents are traumatic. Here is what to do after a no-fault
                crash, to get you back driving soon and safely. In case of a
                non-fault collision, give us a call at{" "}
                <a href="tel:0800 772 0850">0800 772 0850</a> on the accident
                claims helpline for immediate help and support.{" "}
              </p>
            </div>
            <hr />
          </div>
          <div className="row" id="incdes">
            <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-12">
              <div className="Preparing">
                <ul>
                  <li>
                    <div className="seemingly">
                      <h5 className="Seek">Stay calm:</h5>
                      <p>
                        {" "}
                        Keep it cool and drive your vehicle towards safer places
                        with fewer traffic jams so as to avoid dangers.{" "}
                      </p>
                    </div>
                  </li>
                  <li>
                    <div className="seemingly">
                      <h5 className="Seek">Record the scene: </h5>
                      <p>
                        Photograph damaged cars or property along roadways. You
                        can also take pictures of objects at accident scenes.
                        Furthermore, identify any witnesses and note down their
                        names and contact details.
                      </p>
                    </div>
                  </li>
                  <li>
                    <div className="seemingly">
                      <h5 className="Seek">
                        Call police for car accident reports:
                      </h5>
                      <p>
                        When you get into an incident with another vehicle,
                        immediately inform the police. Moreover, dial the{" "}
                        <strong> Lancaster accident number </strong> if your
                        automobile has sustained some damage that makes it
                        impossible for you to move around in it.
                      </p>
                    </div>
                  </li>
                  <li>
                    <div className="seemingly">
                      <h5 className="Seek">Exchange information</h5>
                      <p>
                        Passengers’ & drivers’ details, including names,
                        insurance data as well as time & date should be
                        exchanged amongst other things involving all parties in
                        a car wreck.
                      </p>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
            <div
              className="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-12"
              id="dualcardiv"
            >
              <img src={dualcar} alt="" className="dualcar" />
            </div>
            <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-12">
              <div className="Preparing">
                <ul>
                  <li>
                    <div className="seemingly">
                      <h5 className="Seek">Photograph the vehicle harm:</h5>
                      <p>
                        Before repairing your car, take a photograph of your
                        damaged car at the accident scene. This will help you
                        prove the other party’s negligence.{" "}
                      </p>
                    </div>
                  </li>
                  <li>
                    <div className="seemingly">
                      <h5 className="Seek"> Seek medical attention</h5>
                      <p>
                        In case you feel any pain or discomfort, immediately
                        schedule an appointment with your doctor for treatment
                        of your injuries.
                      </p>
                    </div>
                  </li>
                  <li>
                    <div className="seemingly">
                      <h5 className="Seek">Do not go for early settlement:</h5>
                      <p>
                        The insurance companies may force you into settling for
                        an early settlement, which does not compensate you fully
                        for damages and losses. Avoid accepting initial
                        settlements from insurance firms without looking at your
                        damages first.{" "}
                      </p>
                    </div>
                  </li>
                  <li>
                    <div className="seemingly">
                      <h5 className="Seek">
                        Start your{" "}
                        <strong> Lancaster insurance accident claim </strong>
                      </h5>
                      <p>
                        Car accidents can result in damage to property and
                        personal injuries. To claim back for the damage and get
                        injury compensation call us on{" "}
                        <a href="tel:0800 772 0850">0800 772 0850</a> to start
                        the claims process and be able to receive payment for
                        your losses.
                      </p>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="whyus">
        <div className="container">
          <div className="row">
            <div className="col-xxl-12 col-md-12">
              <div className="why">
                <h2 className="choose">
                  Effortlessly make{" "}
                  <span className="light">
                    {" "}
                    Lancaster insurance accident claims
                  </span>
                  using our Streamlined and Hassle-free Process
                </h2>
                <p className="complexities">
                  With our easy-moving and tension-free{" "}
                  <strong> Lancaster insurance accident claim </strong>process,
                  you can begin your claims easily. For assistance with any
                  matter concerning{" "}
                  <strong> Lancaster insurance accident claims </strong> ,
                  contact us on car insurance claim number{" "}
                  <a href="tel:0800 772 0850">0800 772 0850</a> to get prompt
                  service from our team.{" "}
                </p>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-xxl-3 col-xl-6 col-lg-6 col-md-6 col-12">
              <div className="box-service">
                <img src={check} className="check" alt="" />
                {/* <img src={experience} className="experience" alt="" /> */}
                <h4>Get in touch</h4>
                <p>
                  Contact our team via the{" "}
                  <strong>Lancaster car accident helpline </strong>to get
                  immediate support and assistance following a no-fault car
                  accident.
                </p>
              </div>
            </div>
            <div className="col-xxl-3 col-xl-6 col-lg-6 col-md-6 col-12">
              <div className="box-service">
                <img src={check} className="check" alt="" />
                <h4>Share accident details</h4>
                <p>
                  Provide us with relevant information related to the accident,
                  including the time, date, location and the reason behind the
                  accident.
                </p>
              </div>
            </div>
            <div className="col-xxl-3 col-xl-6 col-lg-6 col-md-6 col-12">
              <div className="box-service">
                <img src={check} className="check" alt="" />
                <h4>Supporting documents and evidence</h4>
                <p>
                  Additionally, share any relevant documentation such as photos,
                  police reports or witness statements that may help prove your
                  innocence and get deserving compensation.{" "}
                </p>
              </div>
            </div>
            <div className="col-xxl-3 col-xl-6 col-lg-6 col-md-6 col-12">
              <div className="box-service">
                <img src={check} className="check" alt="" />
                <h4>Claim assessment and resolution</h4>
                <p>
                  Our solicitors will assess your claim and negotiate with the
                  different parties to secure maximum compensation for you.{" "}
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="faqs">
        <div className="container">
          <div className="row">
            <div className="col-xxl-12 col-xl-12 col-md-12 col-12">
              <div className="fq">
                <h2>
                  {" "}
                  Frequently Asked{" "}
                  <span className="light">Questions (FAQ)</span>
                </h2>
                <p className="complexities">
                  Got questions about the{" "}
                  <strong> Lancaster insurance accident claim? </strong>We have
                  them answered here.
                </p>
              </div>
              <div className="allfaq">
                <Accordion defaultActiveKey="0">
                  <Accordion.Item eventKey="0">
                    <Accordion.Header>
                      What type of replacement vehicle will I get?
                    </Accordion.Header>
                    <Accordion.Body>
                      We have an extensive fleet of vehicles and will provide
                      you with a cost-free replacement vehicle of a similar
                      standard to your damaged vehicle to ensure no interruption
                      to your day-to-day activities.
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="1">
                    <Accordion.Header>
                      Who pays for the services offered by you?
                    </Accordion.Header>
                    <Accordion.Body>
                      As a no-fault accident victim, you’re legally entitled to
                      receive the accident management services provided by us.
                      We will recover all costs from third-party insurers.
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="2">
                    <Accordion.Header>
                      Will the Lancaster insurance accident claim affect my
                      premiums?
                    </Accordion.Header>
                    <Accordion.Body>
                      Your own insurance will not be claimed. Therefore, your
                      claims will neither affect the insurance premium nor the
                      no-claim bonus.
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="3">
                    <Accordion.Header>
                      Can I choose my own repair shop for the repairs of my
                      damaged vehicle?{" "}
                    </Accordion.Header>
                    <Accordion.Body>
                      One of our many good points is that we are flexible, which
                      means you can choose where to have your car fixed. You can
                      tell us through the{" "}
                      <strong> Lancaster car accident helpline </strong> to
                      provide details of the repairs from whom you want to get
                      your vehicle repaired.
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="4">
                    <Accordion.Header>
                      What benefits do I get by making Lancaster insurance
                      accident claims?
                    </Accordion.Header>
                    <Accordion.Body>
                      Benefits offered when you select us as an accident
                      management service provider include:
                      <ul className="listfaq">
                        <li className="faqli">No compulsory excess</li>
                        <li className="faqli">
                          Manufacturer sanctioned repairs
                        </li>
                        <li className="faqli">No effect on NCB</li>
                        <li className="faqli">
                          Individualised assistance at all stages
                        </li>
                        <li className="faqli">Prompt settlement of claims</li>
                      </ul>
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="5">
                    <Accordion.Header>
                      What type of assistance do you provide if my vehicle is a
                      write-off?{" "}
                    </Accordion.Header>
                    <Accordion.Body>
                      You will be given a temporary replacement vehicle
                      like-for-like until you receive a cheque amounting to the
                      market value of your written-off vehicle from the insurer
                      responsible for the accident.
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="6">
                    <Accordion.Header>
                      For how long can I keep the courtesy car?{" "}
                    </Accordion.Header>
                    <Accordion.Body>
                      If your car is repairable, you will be allowed to retain
                      the replaceable vehicle until it is repaired. If your
                      vehicle becomes a total loss after an accident, you may
                      hold onto the replacement car until you get a cheque for
                      its full value.
                    </Accordion.Body>
                  </Accordion.Item>
                </Accordion>
              </div>
            </div>
          </div>
        </div>
      </section>

      <Footer />
    </>
  );
};

export default Lancasterinsurance;
