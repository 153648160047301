import React, { useRef } from "react";
import Accordion from "react-bootstrap/Accordion";
import check from "../assets/images/check.png";
import dualcar from "../../src/assets/images/dualcar.webp";
import carbnr1 from "../../src/assets/images/carbnr1.webp";
import Sidebarinner from "../components/Sidebar-inner";
import phonecall from "../assets/images/phonecall.png";
import Footer from "../components/footer";
import { Helmet } from "react-helmet";

const Surethingcar = () => {
  const sidebarRef = useRef(null);

  const handleScrollToSidebar = () => {
    if (sidebarRef.current) {
      sidebarRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Sure Thing Car Insurance Accident Claim Phone Number:</title>
        <meta
          name="description"
          content="Contact our professional team on the Sure Thing Car Insurance accident claim phone number 0800 772 0850 to get prompt and reliable claim management support in the UK. "
        />
        <link
          rel="canonical"
          href="https://insurance-accident-claims.co.uk/sure-thing-car"
        />
      </Helmet>
      <section className="innernav">
        <div className="container">
          <div className="row">
            <div className="col-md-6 col-sm-6">
              <div className="left-logo">
                <h3>
                  Accident<span className="sipo"> Claims</span>
                </h3>
              </div>
            </div>
            <div className="col-md-6 col-sm-6">
              <div className="left-cta">
                <div class="headercnt">
                  <div class="chatmsg">
                    <img
                      src={phonecall}
                      className="phonecall"
                      alt="phonecall "
                    />
                  </div>
                  <div class="box-body">
                    <h3 class="nav-info-box-title">Toll Free Number </h3>
                    <p>
                      <a href="tel:0800 772 0850">0800 772 0850</a>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section
        className="newbnr"
        style={{ backgroundImage: "url(" + carbnr1 + ")" }}
      >
        <div className="container">
          <div className="row">
            <div className="col-xxl-8 col-xl-8 col-md-10 col-12">
              <div className="innerbnr">
                <h1>
                  <span className="onespan">Dedicated accident</span>{" "}
                  <span className="sci">claims management </span> in the UK
                </h1>
                <p className="brokerage">
                  We are with you every step of the way, from arranging for your
                  vehicle to be recovered from the place of the incident and
                  managing your claim.
                </p>
                <div className="bnrbtn">
                  <a class="db-btn-border btn-rollover phone" href="tel:0800 772 0850">
                    Road Accident Claim Help?{" "}
                    <i class="fa-solid fa-arrow-right"></i>
                  </a>
                </div>
                <div className="bnrbtn">
                <a className="db-btn-border btn-rollover desktop"  onClick={handleScrollToSidebar}>
                    Road Accident Claim Help? <i className="fa-solid fa-arrow-right"></i>
                </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="stickysection">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="group-4-8">
                <div className="cl8 tf-tab">
                  <div className="content-tab">
                    <div className="inner-content">
                      <h2>
                        Zero excess no-fault
                        <br />
                        <span className="light">accident support </span>{" "}
                      </h2>
                      <p className="disciplines">
                        We are dedicated partners who aim to make it easy for
                        victims of no-fault car accidents in the United Kingdom
                        to handle the aftermath of their accidents flawlessly.
                        We are here from roadside recovery to on-road return
                        without charging any amount above what we pay out. Start
                        by calling <a href="tel:0800 772 0850">0800 772 0850</a>
                      </p>
                      <hr />
                      <p className="bold-text">No excess</p>
                      <p className="bottom-te">
                        When you file a{" "}
                        <strong> Sure Thing accident claim </strong> through us,
                        we will make sure there are no hidden charges or nasty
                        surprises.{" "}
                      </p>
                      <hr />
                      <p className="bold-text">
                        No effect on the no-claims bonus
                      </p>
                      <p className="bottom-te">
                        We know how valuable those years of having not claimed
                        on insurance have been. Therefore, your no-claims bonus
                        and hard-won discounts will stay as they were before.
                      </p>
                      <hr />
                      <p className="bold-text">
                        Guaranteed high-quality repairs
                      </p>
                      <p className="bottom-te">
                        You can reach us via telephone by dialling the{" "}
                        <strong>
                          Sure Thing Car accident claim phone number{" "}
                        </strong>
                        if you need accidental repair work done on your car
                        after an accident. Our highly recommended garages will
                        give you unmatched excellence because they use genuine
                        parts and paint to repair vehicles.
                      </p>
                      <hr />
                      <p className="bold-text">
                        Like for like replacement car{" "}
                      </p>
                      <p className="bottom-te">
                        No vehicle after a car crash? Reach us on{" "}
                        <strong>
                          Sure Thing Car insurance claim phone number{" "}
                        </strong>
                        and we will organise another similar replacement vehicle
                        to put you back on track.{" "}
                      </p>
                      <hr />
                      <p className="bold-text">Quick claims processing</p>
                      <p className="bottom-te">
                        No more delays in handling your claim if you are using
                        our services. We have an efficient and quick{" "}
                        <strong> Sure Thing insurance claims </strong>process to
                        ensure your claim gets settled in the shortest possible
                        time.
                      </p>
                      <hr />
                      <p className="bold-text">24/7 support</p>
                      <p className="bottom-te">
                        We know how important it is to get timely help after a
                        road accident. That’s why our team is always ready to
                        meet your accident management requirements whenever they
                        arise.
                      </p>
                      <hr />
                      <p className="bold-text">
                        {" "}
                        A panel of experienced solicitors{" "}
                      </p>
                      <p className="bottom-te">
                        Our solicitors are highly experienced in handling
                        no-fault injury cases related to car accidents, which
                        makes them capable of giving you the best results for
                        your claim.
                      </p>
                      <hr />
                      <p className="bold-text">Safe storage for vehicles</p>
                      <p className="bottom-te">
                        Connect with our team through{" "}
                        <strong>
                          {" "}
                          Sure Thing's car insurance claim number{" "}
                        </strong>{" "}
                        for vehicle storage. We provide secure places where cars
                        can be kept until a valuation or legal decision has been
                        made.{" "}
                      </p>
                      <hr />
                      <p className="bold-text">
                        {" "}
                        Quick and efficient services{" "}
                      </p>
                      <p className="bottom-te">
                        We understand that quick response time can minimise the
                        stress and anxiety of being stranded on the road. Call
                        us now on the{" "}
                        <strong> Sure Thing insurance claim number, </strong>{" "}
                        and we will promptly dispatch our team to ensure you get
                        timely assistance without any delay.
                      </p>
                      <hr />

                      <p className="bold-text">Full range of services</p>
                      <p className="bottom-te">
                        From car recovery to certified repairs, accident
                        management, and providing temporary replacement cars, we
                        offer an array of services.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="cl4" ref={sidebarRef}>
                  <Sidebarinner />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="photographs">
        <div className="container">
          <div className="row">
            <h2 className="road">
              We’re committed to getting you{" "}
              <span className="light">
                back on <br />
                the road to recovery
              </span>{" "}
            </h2>
            <div className="col-xxl-6 col-xl-6 col-md-12 col-12">
              <div className="fourbox">
                <ul>
                  <li>
                    <img src={check} className="check" alt="" />
                    If you have a UK non-fault road traffic accident, our team
                    will help you make a successful{" "}
                    <strong> Sure Thing accident claim </strong> for
                    compensation.
                  </li>
                  <hr />
                  <li>
                    <img src={check} className="check" alt="" />
                    Call us at{" "}
                    <strong>
                      {" "}
                      Sure Thing car accident claim phone number{" "}
                    </strong>
                    where our dedicated group will provide top-quality support
                    and help.
                  </li>
                  <hr />
                  <li>
                    <img src={check} className="check" alt="" />
                    We will record all the details about the crash as well as
                    identify the party at fault against whom you may file your{" "}
                    <strong> Sure Thing insurance claim. </strong>
                  </li>
                  <hr />
                  <li>
                    <img src={check} className="check" alt="" />
                    Until your vehicle is fixed we shall give you a
                    like-for-like replacement vehicle.
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-xxl-6 col-xl-6 col-md-12 col-12">
              <div className="fourbox">
                <ul>
                  <li>
                    <img src={check} className="check" alt="" />
                    We will contact a trustworthy repair centre approved by
                    manufacturers to repair your car expeditiously.
                  </li>
                  <hr />
                  <li>
                    <img src={check} className="check" alt="" />
                    The claim for a full recovery of the repair and temporary
                    replacement costs will directly be made against the at-fault
                    party.
                  </li>
                  <hr />
                  <li>
                    <img src={check} className="check" alt="" />
                    We shall carry out the demanding and twisting process of
                    coordination among different parties involved in the
                    accident on your behalf.
                  </li>
                  <hr />
                  <li>
                    <img src={check} className="check" alt="" />
                    We will also keep in constant communication with the vehicle
                    repairer to ensure you receive your repaired vehicle as
                    quickly as possible.
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="carprocess">
        <div className="container ">
          <div className="row">
            <div className="col-xxl-12 col-xxl-12 col-md-12 col-12">
              <h2 className="trusted-title">
                {" "}
                Our wide range of accident <br />
                <span className="light">management services</span>{" "}
              </h2>
            </div>
          </div>
          <div className="row" id="possible">
            <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-12">
              <div className="claim">
                <ul>
                  <li>
                    <span className="sleej">Accident recovery:</span>We are one
                    of the leading car accident recovery companies in the UK.
                    Call us on our car accident claim phone number{" "}
                    <strong>
                      {" "}
                      <a href="tel:0800 772 0850">0800 772 0850</a>{" "}
                    </strong>{" "}
                    , and we’ll promptly recover your vehicle from the accident
                    scene, transporting it safely to our storage yards.
                  </li>
                  <li>
                    <span className="sleej">Approved repairs:</span>Looking
                    around for a car crash repair centre? Reach us through the{" "}
                    <strong>
                      {" "}
                      Sure Thing car insurance claim phone number{" "}
                    </strong>{" "}
                    and have your car fixed with genuine parts and paint by
                    approved repairers so that it can be put back to its
                    pre-accident state.
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-12">
              <div className="claim" id="blue">
                <ul>
                  <li>
                    <span className="sleej">Car storage:</span> Vehicle storage
                    solutions are available for your car immediately following
                    its involvement in an accident. To guarantee your car is
                    well protected, and saved from damages, theft and vandalism,
                    we ensure to park it in a fully secure place within our
                    well-kept yards. Communicate with us through the{" "}
                    <strong> Sure Thing insurance claim number </strong>for safe
                    and reliable vehicle storage services.
                  </li>
                  <li>
                    <span className="sleej">Replacement vehicle:</span>In case
                    of a non-fault accident, we will provide you with the exact
                    replacement vehicle of your choice at no cost to you so that
                    your daily life does not change at all. Thus, you can
                    continue doing your daily chores without any difficulties.
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-12">
              <div className="claim">
                <ul>
                  <li>
                    <span className="sleej">Personal injury advice:</span>
                    Would you like to make a claim for your injury? Contact us
                    on the
                    <strong> Sure Thing car insurance claim number. </strong>We
                    will introduce you to our experienced solicitors, who will
                    guide you throughout the process of making your claim.
                  </li>
                  <li>
                    <span className="sleej">Claim management:</span>Our
                    dedicated solicitors handle anything related to your claims,
                    starting from initiation up to finalisation. You are welcome
                    to communicate with us via our{" "}
                    <strong> Sure Thing insurance claim number </strong>so that
                    we may take things forward.
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="yellowcta">
        <div className="container">
          <div className="row">
            <div className="cta2">
              <div className="col-xxl-8 col-xl-8 col-lg-8 col-12">
                <div className="legal">
                  <h3 className="experienced">
                    Got more questions?{" "}
                    <span className="light">Talk to our specialists </span>{" "}
                  </h3>
                  <p className="professionals">
                    Get in touch with our no-fault claim experts via{" "}
                    <strong>
                      car insurance claims number{" "}
                      <a className="cpt-no" href="tel:0800 772 0850">
                        {" "}
                        0800 772 0850{" "}
                      </a>{" "}
                    </strong>
                    to get the best advice for your{" "}
                    <strong> Sure Thing insurance claim. </strong>
                  </p>
                </div>
              </div>
              <div className="col-xxl-4 col-xl-4 col-lg-4 col-12">
              <div class="process">
                  <a class="tf-button style-1 phone" href="tel:0800 772 0850">
                    Get in touch<span class="icon-keyboard_arrow_right"></span>
                  </a>
                </div>
                <div class="process">
                    <a className="tf-button style-1 desktop"  onClick={handleScrollToSidebar}>
                        Get in touch<span className="icon-keyboard_arrow_right"></span>
                    </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <search className="updatedform">
        <div className="container">
          <div className="row text-center">
            <div className="col-xxl-12 col-xl-12 col-md-12 col-12">
              <h2 className="whiteheading">
                {" "}
                What makes us the best for your accident <br />
                <span className="light">management needs? </span>
              </h2>
              <p className="sustained">
                We know that a road traffic accident can be very distressing.
                Consequently, we provide end-to-end accident management services
                that facilitate your quick rehabilitation following a car crash.
              </p>
            </div>
          </div>
          <div className="row">
            <div className="col-xxl-4 col-xl-4 col-lg-6 col-md-12 col-12">
              <div className="Claimant">
                <h5> Services without charges </h5>
                <p className="collision">
                  You won’t be required to pay any fees for our accident
                  management services. The costs will be recovered from the
                  insurer of the other party at fault.
                </p>

                <h5>Personalised assistance</h5>
                <p className="collision">
                  When you call{" "}
                  <strong> Sure Thing insurance claim number, </strong>you will
                  receive specialised assistance that factors in your individual
                  circumstances.{" "}
                </p>

                <h5> Quick help </h5>
                <p className="collision">
                  Our dedicated team is always available to help with all your
                  accident management requirements. That means if you need
                  roadside assistance, vehicle repair arrangements, or require
                  support dealing with a{" "}
                  <strong> Sure Thing insurance claims </strong> process, we are
                  just one phone call away from providing emergency assistance.
                </p>
              </div>
            </div>
            <div className="col-xxl-4 col-xl-4 col-lg-6 col-md-12 col-12">
              <div className="Claimant">
                <h5> Transparent process </h5>
                <p className="collision">
                  At every stage of your case processing, we will ensure that
                  you are kept informed and updated about your status and any
                  developments in relation to your claim
                </p>
                <h5> Independent engineer </h5>
                <p className="collision">
                  For an impartial damage evaluation on which you may recover
                  legitimate compensation for the harm caused by others to the
                  vehicle, an independent engineer will be provided.
                </p>
                <h5> Nationwide repair network </h5>
                <p className="collision">
                  Our repairers use authentic parts and paints in repairing your
                  vehicle after it has been damaged to ensure that it meets set
                  standards of quality.{" "}
                </p>
              </div>
            </div>
            <div className="col-xxl-4 col-xl-4 col-lg-12 col-md-12 col-12">
              <div className="Claimant">
                <h5> No rise in premiums on insurance</h5>
                <p className="collision">
                  Your insurance premiums are not going to be inflated by an
                  outstanding claim, as we will not claim against your own
                  insurance policy.
                </p>

                <h5> Free and non-compulsory advice </h5>
                <p className="collision">
                  Obtain free, no-obligation advice from our experienced and
                  professional panel of injury solicitors who deal with road
                  traffic accidents via the{" "}
                  <string> Sure Thing Insurance claim number.</string>
                </p>

                <h5>No win, no fee claim</h5>
                <p className="collision">
                  If you fail in your compensation claim, you do not need to pay
                  a legal fee to your solicitor.
                </p>
              </div>
            </div>
          </div>
        </div>
      </search>

      <section className="Accident-claim">
        <div className="container">
          <div className="row">
            <div className="needed">
              <h2 className="trusted-title">
                How can you maximise the settlement value of your <br />
                <span className="light"> Sure Thing insurance claim?</span>
              </h2>
              <p className="complexities">
                When you have suffered loss and damage due to a non-fault road
                accident, it is important to make a{" "}
                <strong> Sure Thing accident claim </strong> for fair
                compensation. Here are some steps you should follow in order to
                increase your settlement value:{" "}
              </p>
            </div>
            <hr />
          </div>
          <div className="row" id="incdes">
            <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-12">
              <div className="Preparing">
                <ul>
                  <li>
                    <div className="seemingly">
                      <h5 className="Seek">Seek medical attention:</h5>
                      <p>
                        {" "}
                        Your priority following the car accident must be
                        checking if there were any injuries sustained by you or
                        any other passengers that may require immediate care
                        from a doctor’s perspective.
                      </p>
                    </div>
                  </li>
                  <li>
                    <div className="seemingly">
                      <h5 className="Seek">Gather evidence:</h5>
                      <p>
                        To increase the chances of securing maximum compensation
                        and provide a solid basis for your{" "}
                        <strong> Sure Thing insurance claim, </strong>it is
                        important that you gather vital evidence from the scene
                        of the accident.
                      </p>
                    </div>
                  </li>
                  <li>
                    <div className="seemingly">
                      <h5 className="Seek">Take eyewitness statements:</h5>
                      <p>
                        For higher compensation, get statements from people who
                        saw accidents happen. This can give details of the
                        accident, which will make your case stronger.
                      </p>
                    </div>
                  </li>
                  <li>
                    <div className="seemingly">
                      <h5 className="Seek">
                        Don’t be too fast in accepting the initial offer
                      </h5>
                      <p>
                        Insurance companies may offer an initial settlement to
                        entice you to settle the claim early. Such an offer is
                        lower than the value of the damages you’ve suffered. So,
                        never accept the first offer without discussing it with
                        your solicitor.
                      </p>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
            <div
              className="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-12"
              id="dualcardiv"
            >
              <img src={dualcar} alt="" className="dualcar" />
            </div>
            <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-12">
              <div className="Preparing">
                <ul>
                  <li>
                    <div className="seemingly">
                      <h5 className="Seek">Stay off social media sites:</h5>
                      <p>
                        It’s in your best interest to avoid using social media
                        while your claim is being processed. Anything you post
                        on social media may damage your claim.
                      </p>
                    </div>
                  </li>
                  <li>
                    <div className="seemingly">
                      <h5 className="Seek"> Record all expenses </h5>
                      <p>
                        Put all expenses you incur, including hospital bills,
                        out-of-pocket expenses, lost wages and costs for
                        travelling, in writing.
                      </p>
                    </div>
                  </li>
                  <li>
                    <div className="seemingly">
                      <h5 className="Seek">Get advice from a solicitor:</h5>
                      <p>
                        Talk to a solicitor who knows what steps need to be
                        taken to help ensure that your application succeeds. Get
                        personalised assistance for yourself by calling our{" "}
                        <strong> Sure Thing insurance claims number. </strong>{" "}
                      </p>
                    </div>
                  </li>
                  <li>
                    <div className="seemingly">
                      <h5 className="Seek">Initiate your claim </h5>
                      <p>
                        Contact our dedicated team through the{" "}
                        <strong>
                          {" "}
                          Sure Thing Car accident claim phone number{" "}
                        </strong>{" "}
                        and provide them with evidence and relevant documents to
                        start your claim.
                      </p>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="whyus">
        <div className="container">
          <div className="row">
            <div className="col-xxl-12 col-md-12">
              <div className="why">
                <h2 className="choose">
                  Begin your claim now with our{" "}
                  <span className="light">simplified process.</span>
                </h2>
                <p className="complexities">
                  When you choose to make your claim with us, there won’t be any
                  complicated paperwork to deal with and long follow-up calls.
                  Begin the process of a{" "}
                  <strong> Sure Thing insurance claim </strong>by calling us on{" "}
                  <a href="tel:0800 772 0850"> 0800 772 0850 </a>
                </p>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-xxl-3 col-xl-6 col-lg-6 col-md-6 col-12">
              <div className="box-service">
                <img src={check} className="check" alt="" />
                {/* <img src={experience} className="experience" alt="" /> */}
                <h4>Get in touch</h4>
                <p>
                  Make a first contact by calling the{" "}
                  <strong> Sure Thing car insurance claim phone number </strong>{" "}
                  and talk to our team.
                </p>
              </div>
            </div>
            <div className="col-xxl-3 col-xl-6 col-lg-6 col-md-6 col-12">
              <div className="box-service">
                <img src={check} className="check" alt="" />
                <h4>Provide details of the accident </h4>
                <p>
                  Let our team record pertinent information about the accident
                  as well as guide you on what you need to do next.
                </p>
              </div>
            </div>
            <div className="col-xxl-3 col-xl-6 col-lg-6 col-md-6 col-12">
              <div className="box-service">
                <img src={check} className="check" alt="" />
                <h4>Submit evidence and documentation</h4>
                <p>
                  Should you have any evidence from the scene of an accident,
                  kindly share it with us so that we can make your claim strong.{" "}
                </p>
              </div>
            </div>
            <div className="col-xxl-3 col-xl-6 col-lg-6 col-md-6 col-12">
              <div className="box-service">
                <img src={check} className="check" alt="" />
                <h4>Follow up</h4>
                <p>
                  After collecting evidence and relevant records, we will start
                  your claim and keep updating you on its progress.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="faqs">
        <div className="container">
          <div className="row">
            <div className="col-xxl-12 col-xl-12 col-md-12 col-12">
              <div className="fq">
                <h2>
                  {" "}
                  Frequently Asked{" "}
                  <span className="light">Questions (FAQ)</span>
                </h2>
                <p className="complexities">
                  Got questions about the{" "}
                  <strong> Sure Thing insurance claim? </strong>We have them
                  answered here.
                </p>
              </div>
              <div className="allfaq">
                <Accordion defaultActiveKey="0">
                  <Accordion.Item eventKey="0">
                    <Accordion.Header>
                      Could you fix my automobile after the vehicle got damaged
                      in an accident?
                    </Accordion.Header>
                    <Accordion.Body>
                      We will direct you to an approved repairer in the UK who
                      can help you with fixing your damaged car. If there is a
                      particular repairer that you prefer, give us their contact
                      details, and we will call them so they can schedule your
                      repair.
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="1">
                    <Accordion.Header>
                      For how long can I have the replacement vehicle?
                    </Accordion.Header>
                    <Accordion.Body>
                      You may keep it until your own car is ready again. In case
                      of a total write-off, use the temporary replacement as
                      long as you receive compensation.
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="2">
                    <Accordion.Header>
                      Will my no-claims bonus be affected if I claim through
                      your company?
                    </Accordion.Header>
                    <Accordion.Body>
                      No. Your existing insurance policy remains intact. Thus,
                      your no-claims bonus remains unchanged.
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="3">
                    <Accordion.Header>
                      How do I get a replacement car from your company?{" "}
                    </Accordion.Header>
                    <Accordion.Body>
                      In order for you to obtain a replacement car, you should
                      be a faultless driver. Just dial{" "}
                      <a href="tel:0800 772 0850">0800 772 0850</a>, and we will
                      have that delivered right at your doorstep.
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="4">
                    <Accordion.Header>
                      How long does repairing the damage on my vehicle take?
                    </Accordion.Header>
                    <Accordion.Body>
                      Repairing time for your damaged vehicle varies depending
                      on various factors, such as the extent of damages and
                      availability of parts. Once we check out how badly your
                      car is messed up we shall tell you more about when it
                      could likely get fixed.
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="5">
                    <Accordion.Header>
                      Are your services available outside business hours?{" "}
                    </Accordion.Header>
                    <Accordion.Body>
                      Certainly. Our team works around the clock to help you
                      manage accidents; therefore, you can reach us anytime
                      using our{" "}
                      <strong>
                        {" "}
                        Sure Thing car Insurance claim phone number{" "}
                      </strong>{" "}
                      when a need arises.
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="6">
                    <Accordion.Header>
                      What type of replacement vehicle will I receive?{" "}
                    </Accordion.Header>
                    <Accordion.Body>
                      Therefore, in order to avoid any disruption whatsoever, we
                      endeavour to provide you with a like-for-like replacement
                      vehicle that closely resembles your own car.
                    </Accordion.Body>
                  </Accordion.Item>
                </Accordion>
              </div>
            </div>
          </div>
        </div>
      </section>

      <Footer />
    </>
  );
};

export default Surethingcar;
