import React, { useRef } from "react";
import Accordion from "react-bootstrap/Accordion";
import check from "../assets/images/check.png";
import dualcar from "../../src/assets/images/dualcar.webp";
import homebnr from "../../src/assets/images/homebnr.webp";
import Sidebarinner from "../components/Sidebar-inner";
import phonecall from "../assets/images/phonecall.png";
import Footer from "../components/footer";
import { Helmet } from "react-helmet";

const Jaguarlandroverinsurance = () => {
    const sidebarRef = useRef(null);

    const handleScrollToSidebar = () => {
        if (sidebarRef.current) {
            sidebarRef.current.scrollIntoView({ behavior: "smooth" });
        }
    };
    return (
        <>
        <Helmet>
        <meta charSet="utf-8" />
        <title>Jaguar Car Insurance Accident Claim Phone Number</title>
        <meta
          name="description"
          content="Connect with our knowledgeable solicitors through the Jaguar Car Insurance Accident Claim Phone Number 0800 772 0850 for reliable claims help and guidance in the UK. "
        />
        <link
          rel="canonical"
          href="https://insurance-accident-claims.co.uk/Jaguar-land-rover-insurance"
        />
      </Helmet>
            <section className="innernav">
                <div className="container">
                    <div className="row">
                        <div className="col-md-6 col-sm-6">
                            <div className="left-logo">
                                <h3>
                                    Accident<span className="sipo"> Claims</span>
                                </h3>
                            </div>
                        </div>
                        <div className="col-md-6 col-sm-6">
                            <div className="left-cta">
                                <div class="headercnt">
                                    <div class="chatmsg">
                                        <img
                                            src={phonecall}
                                            className="phonecall"
                                            alt="phonecall "
                                        />
                                    </div>
                                    <div class="box-body">
                                        <h3 class="nav-info-box-title">Toll Free Number </h3>
                                        <p>
                                            <a href="tel:0800 772 0850">0800 772 0850</a>
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section
                className="newbnr"
                style={{ backgroundImage: "url(" + homebnr + ")" }}
            >
                <div className="container">
                    <div className="row">
                        <div className="col-xxl-7 col-xl-7 col-md-10 col-12">
                            <div className="innerbnr">
                                <h1>
                                    <span className="onespan">No-fault accident </span>{" "}
                                    <span className="sci">   insurance claims management  </span> support 
                                </h1>
                                <p className="brokerage">
                                From arranging vehicle recovery to providing replacement cars and managing your claim from start to end, we will take care of all your accident management needs. 

                                </p>
                                <div className="bnrbtn">
                                    <a class="db-btn-border btn-rollover phone" href="tel:0800 772 0850">
                                        Road Accident Claim Help?{" "}
                                        <i class="fa-solid fa-arrow-right"></i>
                                    </a>
                                </div>
                                <div className="bnrbtn">
                                    <a className="db-btn-border btn-rollover desktop" onClick={handleScrollToSidebar}>
                                        Road Accident Claim Help? <i className="fa-solid fa-arrow-right"></i>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="stickysection">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="group-4-8">
                                <div className="cl8 tf-tab">
                                    <div className="content-tab">
                                        <div className="inner-content">
                                            <h2>
                                            Leading UK insurance 

                                                <span className="light"> claim handlers</span>{" "}
                                            </h2>
                                            <p className="disciplines">
                                            You can call us on the <strong> Jaguar Land Rover car insurance phone number </strong>  if you are in need of assistance making a claim. We will provide dedicated claims assistance to minimise your stress and get you back on the road to recovery. We will deal with your <strong>  Jaguar Land Rover car insurance claim </strong> at no cost. 
                                            </p>
                                            <hr />
                                            <p className="bold-text">No excess payments </p>
                                            <p className="bottom-te">  If the accident was not your fault, you don’t have to pay any upfront charges or any excess payments.                            </p>
                                            <hr />
                                            <p className="bold-text">Unaffected no claims bonus </p>
                                            <p className="bottom-te">
                                            We do not make a claim against your policy. When you claim through us, rest assured that your bonus will remain intact.                                   </p>
                                            <hr />
                                            <p className="bold-text">
                                            Fast recovery
                                            </p>
                                            <p className="bottom-te">
                                            Left stranded by the roadside after an accident? Call us on the Jaguar Land Rover car insurance number to get your vehicle recovered from the accident scene immediately. 
                                            </p>
                                            <hr />
                                            <p className="bold-text">Manufacturer approved repairers</p>
                                            <p className="bottom-te">
                                            We will ensure that your vehicle is repaired at the highest standards by our approved repairers.                                    </p>
                                            <hr />
                                            <p className="bold-text">Like-for-like replacement car</p>
                                            <p className="bottom-te">
                                            In case of an accident or if your vehicle is unroadworthy, we can allow you to use another car of the same specifications as yours.                                        </p>
                                            <hr />
                                            <p className="bold-text">Experienced solicitors</p>
                                            <p className="bottom-te">
                                            Our experienced solicitors have extensive experience in handling <strong> Jaguar Land Rover insurance claims </strong>and have helped victims receive justifiable compensation for accidents not caused by them.

                                            </p>
                                            <hr />
                                            <p className="bold-text">Swift settlement of claims</p>
                                            <p className="bottom-te">
                                            Our claims process does not require much paperwork and helps in ensuring a quick settlement process.                                    </p>
                                            <hr />
                                            <p className="bold-text">Free advice</p>
                                            <p className="bottom-te">
                                            For any inquiries concerning <strong> Jaguar Land Rover insurance claims, </strong>  call 
                                                <strong>
                                                    {" "}
                                                    <a href="tel:0800 772 0850">0800 772 0850</a>{" "}
                                                </strong>.You will be advised on the next steps to ensure a favourable settlement. 
                                            </p>
                                            <hr />
                                            <p className="bold-text">No win-no fee</p>
                                            <p className="bottom-te">
                                            Our solicitors work on a contingency basis. If they cannot secure a favourable outcome for your claim, then there would be nothing for you to pay them.                               </p>
                                            <hr />

                                            <p className="bold-text">24/7 support</p>
                                            <p className="bottom-te">
                                            If you need emergency roadside assistance, immediately reach out to us. In case of an accident, contact our hotline for a car insurance claim on 
                                                <strong>
                                                    {" "}
                                                    <a href="tel:0800 772 0850">0800 772 0850</a>{" "}
                                                </strong> for immediate roadside support and assistance.
                                                </p>
                                        </div>
                                    </div>
                                </div>
                                <div className="cl4" ref={sidebarRef}>
                                    <Sidebarinner />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="photographs">
                <div className="container">
                    <div className="row">
                        <h2 className="road">
                        We are here to ease your stress with our  {" "}
                            <span className="light">

                            quick and reliable <br />  crash management support
                            </span>{" "}
                        </h2>
                        <div className="col-xxl-6 col-xl-6 col-md-12 col-12">
                            <div className="fourbox">
                                <ul>
                                    <li>
                                        <img src={check} className="check" alt="" />
                                        If there is a vehicle emergency after an accident, you can contact us via the <strong> Jaguar Land Rover car insurance contact number.</strong> 

                                    </li>
                                    <hr />
                                    <li>
                                        <img src={check} className="check" alt="" />
                                        Additionally, we will find out who caused the accident and make a claim against that person.                                </li>
                                    <hr />
                                    <li>
                                        <img src={check} className="check" alt="" />
                                        Moreover, we will file your paperwork in order to claim any financial loss and support it with relevant documents. 
                                    </li>
                                    <hr />
                                    <li>
                                        <img src={check} className="check" alt="" />
                                        Our team will take your car away from the place of collision and make arrangements for its repair through our approved repairers.                          </li>
                                </ul>
                            </div>
                        </div>
                        <div className="col-xxl-6 col-xl-6 col-md-12 col-12">
                            <div className="fourbox">
                                <ul>
                                    <li>
                                        <img src={check} className="check" alt="" />
                                        Where it is established that reasonable economic repair cannot be achieved, then an independent engineer will be appointed who will assess the damage incurred on your vehicle.                             </li>
                                    <hr />
                                    <li>
                                        <img src={check} className="check" alt="" />
                                        Until your claim is settled, you’ll be given a replacement vehicle on a temporary basis.                          </li>
                                    <hr />
                                    <li>
                                        <img src={check} className="check" alt="" />
                                        We will follow up on its repairs continuously and keep you informed about the progress.                                 </li>
                                    <hr />
                                    <li>
                                        <img src={check} className="check" alt="" />
                                        Call 
                                        <strong>
                                            {" "}
                                            <a href="tel:0800 772 0850">0800 772 0850</a>{" "}
                                        </strong> and we will connect you with experienced solicitors who will offer you guidance on how to successfully file a <strong> Jaguar Land Rover insurance claim </strong>to recover the damages and losses.
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="carprocess">
                <div className="container ">
                    <div className="row">
                        <div className="col-xxl-12 col-xxl-12 col-md-12 col-12">
                            <h2 className="trusted-title">
                                {" "}
                                Our accident management <br />
                                <span className="light">  services</span>{" "}
                            </h2>
                        </div>
                    </div>
                    <div className="row" id="possible">
                        <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-12">
                            <div className="claim">
                                <ul>
                                    <li>
                                        <span className="sleej">Roadside Recovery:</span>  If you need to have your car towed away from the accident scene, please contact us via the <strong> Jaguar Land Rover car insurance phone number. </strong> Our recovery services are designed to ensure that your vehicle is taken away from where it was involved in an accident and put in secure storage yards as fast as possible.

                                    </li>
                                    <li>
                                        <span className="sleej">Approved Repairs:</span>   Contact our team through the <strong> Jaguar Land Rover insurance claim number </strong> if you need repair services on your car. The repairing of your car will be done by reputable garages across the UK with no extra charges.

                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-12">
                            <div className="claim" id="blue">
                                <ul>
                                    <li>
                                        <span className="sleej">Car Storage:</span>   Want to store your car in a safe place after a no-fault accident? Contact us via<strong> Jaguar Land Rover car insurance number. </strong>  Our secure yard makes it easy for you to store it while you wait for the assessment of the claim.
                                    </li>
                                    <li>
                                        <span className="sleej">Replacement car :</span>If your vehicle can’t be repaired, we can provide a temporary one. When in dire need of another vehicle like yours, justcall our emergency hotline,
                                        <strong>
                                            {" "}
                                            <a href="tel:0800 772 0850">0800 772 0850</a>{" "}
                                        </strong>
                                        , so that you can get a like-for-like replacement vehicle to carry on with your daily activities without any difficulties. You can keep the replacement vehicle until your claim is settled. 


                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-12">
                            <div className="claim">
                                <ul>
                                    <li>
                                        <span className="sleej">Personal injury claims</span>
                                        Do you want to claim for personal injuries as a result of an accident? Our company’s solicitors can provide you with expert advice on how to go about claiming compensation for injuries.
                                    </li>
                                    <li>
                                        <span className="sleej">Claim handling:</span> From paperwork to dealing with insurers, car repairs and the provision of alternative vehicles, we will handle it all. 
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="yellowcta">
                <div className="container">
                    <div className="row">
                        <div className="cta2">
                            <div className="col-xxl-8 col-xl-8 col-lg-8 col-12">
                                <div className="legal">
                                    <h3 className="experienced">
                                        Got more questions? Talk {" "}
                                        <span className="light"> to our specialists
                                        </span>{" "}
                                    </h3>
                                    <p className="professionals">
                                        Get in touch with our no-fault claim experts via
                                        {" "}
                                        <strong> car insurance contact number{" "}
                                            <a className="cpt-no" href="tel:0800 772 0850">
                                                {" "}
                                                0800 772 0850{" "}
                                            </a>{" "}
                                        </strong>
                                        to get the best advice for the
                                        <strong>Jaguar Land Rover insurance claims.  </strong>
                                    </p>
                                </div>
                            </div>
                            <div className="col-xxl-4 col-xl-4 col-lg-4 col-12">
                                <div class="process">
                                    <a class="tf-button style-1 phone" href="tel:0800 772 0850">
                                        Get in touch<span class="icon-keyboard_arrow_right"></span>
                                    </a>
                                </div>
                                <div class="process">
                                    <a className="tf-button style-1 desktop" onClick={handleScrollToSidebar}>
                                        Get in touch<span className="icon-keyboard_arrow_right"></span>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <search className="updatedform">
                <div className="container">
                    <div className="row text-center">
                        <div className="col-xxl-12 col-xl-12 col-md-12 col-12">
                            <h2 className="whiteheading">
                                {" "}
                                Why choose us for your accident 
                                <span className="light">   management needs?
                                </span>
                            </h2>
                            <p className="sustained">
                            We will remove all road traffic-accident-related non-fault stress off your shoulders. Some of the advantages that make us top top-choice handling firm include:                 </p>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-xxl-4 col-xl-4 col-lg-6 col-md-12 col-12">
                            <div className="Claimant">
                                <h5> 24/7 vehicle recovery</h5>
                                <p className="collision">
                                Do you require vehicle recovery services? Call the <strong> Jaguar Land Rover insurance claim number, </strong>  and we will be there at the accident scene to recover your vehicle, no matter the time. 


                                </p>

                                <h5>Assured repairs </h5>
                                <p className="collision">
                                We have multiple manufacturer-standard repair garages that will repair your vehicle to the highest standards of quality.  </p>

                                <h5>No increase in premium charges</h5>
                                <p className="collision">
                                We won't make a claim against your own insurance policy, leaving your insurance premium intact. 

                                </p>
                            </div>
                        </div>
                        <div className="col-xxl-4 col-xl-4 col-lg-6 col-md-12 col-12">
                            <div className="Claimant">
                                <h5>Trustworthy partners
                                </h5>
                                <p className="collision">
                                We strive to offer high-quality service to our customers by partnering with accredited solicitors and repair centres all over the UK.  </p>

                                <h5>Expedited claims settlement </h5>
                                <p className="collision">
                                After our experienced solicitors have had a talk with the other party’s insurer concerning your Jaguar Land Rover car insurance claim, you can expect it to be fairly resolved.                    </p>

                                <h5>Quick response</h5>
                                <p className="collision">
                                Immediate response is something that you can count on in case of emergency after an accident caused by another person.
                                </p>
                            </div>
                        </div>
                        <div className="col-xxl-4 col-xl-4 col-lg-12 col-md-12 col-12">
                            <div className="Claimant">
                                <h5>Dedicated claims handler </h5>
                                <p className="collision">
                                 We put your claim first. In this regard, we will assign a claims handler to directly handle your claim and keep you informed during its progression at every stage.                       </p>

                                <h5>No additional charge</h5>
                                <p className="collision">
                                Since third-party insurers will cater for all our expenses, there will be no additional charge you need to pay to us.                      </p>

                                                         
                            </div>
                        </div>
                    </div>
                </div>
            </search>

            <section className="Accident-claim">
                <div className="container">
                    <div className="row">
                        <div className="needed">
                            <h2 className="trusted-title">
                            How can you boost the chances of securing  <br />
                                <span className="light">  maximum compensation?</span>
                            </h2>
                            <p className="complexities">
                            If you want to increase the likelihood of obtaining substantial compensation for damages, then present solid evidence showing that you were not at fault. Here are the steps you should follow to ensure a deserving compensation is obtained following an accident.                     </p>
                        </div>
                        <hr />
                    </div>
                    <div className="row" id="incdes">
                        <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-12">
                            <div className="Preparing">
                                <ul>
                                    <li>
                                        <div className="seemingly">
                                            <h5 className="Seek">Keep yourself safe.:</h5>
                                            <p>
                                                {" "}
                                                You must make sure to stop and park your car at a safe place and check yourself and passengers for visible or invisible injuries.                                     </p>
                                        </div>
                                    </li>
                                    <li>
                                        <div className="seemingly">
                                            <h5 className="Seek">
                                            Visit a doctor immediately:{" "}
                                            </h5>
                                            <p>
                                            In case somebody gets injured in the course of a road accident, try to find a good doctor as soon as possible who will help with the treatment of the injuries without further delay.                                        </p>
                                        </div>
                                    </li>
                                    <li>
                                        <div className="seemingly">
                                            <h5 className="Seek">Gather evidences:</h5>
                                            <p>   The information required from an accident scene includes pictures of where the accident occurred, the responsible driver, any damages caused, serious physical wounds which can be seen with the naked eye and statements from eyewitnesses.                       </p>
                                        </div>
                                    </li>
                                    <li>
                                        <div className="seemingly">
                                            <h5 className="Seek">Call the Police:</h5>
                                            <p>
                                            Report the accident to the police and ask for a copy of the police report that could support your <strong> Jaguar Land Rover car insurance claim.  </strong>                                       </p>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div
                            className="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-12"
                            id="dualcardiv"
                        >
                            <img src={dualcar} alt="" className="dualcar" />
                        </div>
                        <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-12">
                            <div className="Preparing">
                                <ul>
                                    <li>
                                        <div className="seemingly">
                                            <h5 className="Seek">Exchange information:</h5>
                                            <p>
                                            Exchange details with the other driver, including name, contact number, insurance details and vehicle details for smooth claim processing.                     </p>
                                        </div>
                                    </li>
                                    <li>
                                        <div className="seemingly">
                                            <h5 className="Seek">Contact eyewitnesses: </h5>
                                            <p>
                                            Reach out to eyewitnesses and note down their addresses so that you can contact them later.                                         </p>
                                        </div>
                                    </li>
                                    <li>
                                        <div className="seemingly">
                                            <h5 className="Seek">
                                            Note down costs:
                                            </h5>
                                            <p>
                                            Remember to write down receipts, such as repair bills, medical bills or other costs needing reimbursement.
                                            </p>
                                        </div>
                                    </li>
                                    <li>
                                        <div className="seemingly">
                                            <h5 className="Seek">
                                            Consult with a no-fault solicitor:
                                            </h5>
                                            <p>
                                            Call us on  {" "}
                                                <strong>{" "}
                                                    <a className="cpt-no" href="tel:0800 772 0850">
                                                        {" "}
                                                        0800 772 0850 {" "}
                                                    </a>{" "}
                                                </strong> ,  so that our solicitors guide you about how to make a successful claim for compensation.
                                            </p>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="whyus">
                <div className="container">
                    <div className="row">
                        <div className="col-xxl-12 col-md-12">
                            <div className="why">
                                <h2 className="choose">
                                Get started with our simplified and stress-free{" "}
                                    <span className="light"> approach to claims </span>
                                </h2>
                                <p className="complexities">
                                It may seem like a daunting task to make a <strong> Jaguar Land Rover car insurance claim  </strong> after an accident you didn’t cause. However, it is possible to commence the process of claiming via our streamlined procedure without complications. You can do this by calling us using the <strong> Jaguar Land Rover car insurance phone number.  </strong>                         </p>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-xxl-3 col-xl-6 col-lg-6 col-md-6 col-12">
                            <div className="box-service">
                                <img src={check} className="check" alt="" />
                                {/* <img src={experience} className="experience" alt="" /> */}
                                <h4>Reach us</h4>
                                <p>
                        <strong> Jaguar Land Rover car insurance contact number </strong>   is available for consultations regarding your claim. </p>
                            </div>
                        </div>
                        <div className="col-xxl-3 col-xl-6 col-lg-6 col-md-6 col-12">
                            <div className="box-service">
                                <img src={check} className="check" alt="" />
                                <h4>Provide details</h4>
                                <p>
                                Please give full information about the accident, such as when it happened, where it occurred, how it happened, etc.</p>
                            </div>
                        </div>
                        <div className="col-xxl-3 col-xl-6 col-lg-6 col-md-6 col-12">
                            <div className="box-service">
                                <img src={check} className="check" alt="" />
                                <h4>Submit evidence</h4>
                                <p>
                                Evidence submission can take forms such as photos, video clips, statements from witnesses or even security camera recordings, which will be useful in backing up your cas               </p>
                            </div>
                        </div>
                        <div className="col-xxl-3 col-xl-6 col-lg-6 col-md-6 col-12">
                            <div className="box-service">
                                <img src={check} className="check" alt="" />
                                <h4>Claim initiation</h4>
                                <p>
                                After evaluating your claim, we will initiate your claim and keep you informed of any developments. 

                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="faqs">
                <div className="container">
                    <div className="row">
                        <div className="col-xxl-12 col-xl-12 col-md-12 col-12">
                            <div className="fq">
                                <h2>
                                    {" "}
                                    Frequently Asked{" "}
                                    <span className="light">Questions</span>
                                </h2>
                                <p className="complexities">
                                    Got questions about the <strong> Jaguar Land Rover insurance claims? </strong>  We have them answered here.

                                </p>
                            </div>
                            <div className="allfaq">
                                <Accordion defaultActiveKey="0">
                                    <Accordion.Item eventKey="0">
                                        <Accordion.Header>
                                        Are courtesy cars free of charge?                                </Accordion.Header>
                                        <Accordion.Body>
                                        Yes. But if you are not willing to cooperate with us, engage a solicitor without our consent or provide false information concerning your case, you may be liable to pay the costs.                              </Accordion.Body>
                                    </Accordion.Item>
                                    <Accordion.Item eventKey="1">
                                        <Accordion.Header>
                                        How long does it take for my claim to be settled?
                                        </Accordion.Header>
                                        <Accordion.Body>
                                        We will try as much as possible to hasten your claim, but we cannot say when it will end because each case is different. We will keep you posted once we initiate your claims process.
                                        </Accordion.Body>
                                    </Accordion.Item>
                                    <Accordion.Item eventKey="2">
                                        <Accordion.Header>
                                        Is it true that the insurance company responsible for the accident pays for my car’s recovery fee?
                                        </Accordion.Header>
                                        <Accordion.Body>
                                        Yes, all expenses are met by the fault party’s insurer. You need to have some support documents, such as photos taken at an accident spot or a police report indicating that you qualify for compensation.         </Accordion.Body>
                                    </Accordion.Item>
                                    <Accordion.Item eventKey="3">
                                        <Accordion.Header>
                                        Can I get credit for hire after a non-fault road traffic collision?
                                        </Accordion.Header>
                                        <Accordion.Body>
                                        Yes, in cases where you are not at fault for causing the car crash, we can make arrangements for a credit hire. 
                                        </Accordion.Body>
                                    </Accordion.Item>
                                    <Accordion.Item eventKey="4">
                                        <Accordion.Header>
                                        If I am not responsible for the accident, will I still lose my no-claims bonus? 

                                        </Accordion.Header>
                                        <Accordion.Body>
                                        Your no-claim bonus is safe because we do not file a claim against your policy.                    </Accordion.Body>
                                    </Accordion.Item>
                                    <Accordion.Item eventKey="5">
                                        <Accordion.Header>
                                        Can I select my own garage for repairs?{" "}
                                        </Accordion.Header>
                                        <Accordion.Body>
                                        Certainly. You have an option to have the work done at your preferred workshop. Once you share the details of your preferred garage, we promise fast and high-quality repairs.               </Accordion.Body>
                                    </Accordion.Item>
                                    <Accordion.Item eventKey="6">
                                        <Accordion.Header>
                                        What type of vehicle will be provided as a substitute? {" "}
                                        </Accordion.Header>
                                        <Accordion.Body>
                                        Replacement cars are chosen on the basis of size, value and function similar to your car. All you need to do is call us on our <strong> Jaguar Land Rover insurance claim number </strong>  and let us know what you require. We shall hand over a replacement car that suits you very well.

                                        </Accordion.Body>
                                    </Accordion.Item>
                                </Accordion>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <Footer />
        </>
    );
};

export default Jaguarlandroverinsurance;
