import React, { useRef } from "react";
import Accordion from "react-bootstrap/Accordion";
import check from "../assets/images/check.png";
import dualcar from "../../src/assets/images/dualcar.webp";
import carbnr1 from "../../src/assets/images/carbnr1.webp";
import Sidebarinner from "../components/Sidebar-inner";
import phonecall from "../assets/images/phonecall.png";
import Footer from "../components/footer";
import { Helmet } from "react-helmet";

const Endsleigh = () => {
    const sidebarRef = useRef(null);

    const handleScrollToSidebar = () => {
        if (sidebarRef.current) {
            sidebarRef.current.scrollIntoView({ behavior: "smooth" });
        }
    };
    return (
        <>
        <Helmet>
        <meta charSet="utf-8" />
        <title>Endsleigh Car Insurance Accident Claim Phone Number</title>
        <meta
          name="description"
          content="Need assistance in filing a car insurance claim? Call us on the Endsleigh Car Insurance Accident Claim Phone Number 0800 772 0850 for expert and professional advice. "
        />
        <link
          rel="canonical"
          href="https://insurance-accident-claims.co.uk/Endsleigh"
        />
      </Helmet>
            <section className="innernav">
                <div className="container">
                    <div className="row">
                        <div className="col-md-6 col-sm-6">
                            <div className="left-logo">
                                <h3>
                                    Accident<span className="sipo"> Claims</span>
                                </h3>
                            </div>
                        </div>
                        <div className="col-md-6 col-sm-6">
                            <div className="left-cta">
                                <div class="headercnt">
                                    <div class="chatmsg">
                                        <img
                                            src={phonecall}
                                            className="phonecall"
                                            alt="phonecall "
                                        />
                                    </div>
                                    <div class="box-body">
                                        <h3 class="nav-info-box-title">Toll Free Number </h3>
                                        <p>
                                            <a href="tel:0800 772 0850">0800 772 0850</a>
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section
                className="newbnr"
                style={{ backgroundImage: "url(" + carbnr1 + ")" }}
            >
                <div className="container">
                    <div className="row">
                        <div className="col-xxl-7 col-xl-7 col-md-10 col-12">
                            <div className="innerbnr">
                                <h1>
                                    <span className="onespan">Expedited and dependable  accident </span>{" "}
                                    <span className="sci"> insurance claims  </span> number
                                </h1>
                                <p className="brokerage">
                                From claim initiation to settlement, we will organise everything on your behalf so you can get back on track as quickly as possible.
                                </p>
                                <div className="bnrbtn">
                                    <a class="db-btn-border btn-rollover phone" href="tel:0800 772 0850">
                                        Road Accident Claim Help?{" "}
                                        <i class="fa-solid fa-arrow-right"></i>
                                    </a>
                                </div>
                                <div className="bnrbtn">
                                    <a className="db-btn-border btn-rollover desktop" onClick={handleScrollToSidebar}>
                                        Road Accident Claim Help? <i className="fa-solid fa-arrow-right"></i>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="stickysection">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="group-4-8">
                                <div className="cl8 tf-tab">
                                    <div className="content-tab">
                                        <div className="inner-content">
                                            <h2>
                                            The leading claims management experts

                                                <span className="light">  in the UK</span>{" "}
                                            </h2>
                                            <p className="disciplines">
                                            We are one of the top firms that offer esteemed clients<strong> Endsleigh insurance claims </strong>help in the UK, having successfully handled hundreds of cases. To make a claim, call us using the<strong> Endsleigh car insurance contact number. </strong> We will manage your <strong> Endsleigh car insurance claim </strong> at no cost.   
                                            </p>
                                            <hr />
                                            <p className="bold-text">No excess fees</p>
                                            <p className="bottom-te">
                                            If you are not at fault for an accident, there will be no excesses to burden you, which may lead to increased premiums.                                          </p>
                                            <hr />
                                            <p className="bold-text">No-claims bonus protection</p>
                                            <p className="bottom-te">
                                            When you file your claim with us, you don’t have to fear losing our no-claims bonus. We shall ensure that your no-claims bonus remains intact in our hands.                                            </p>
                                            <hr />
                                            <p className="bold-text">
                                            Fast vehicle recovery
                                            </p>
                                            <p className="bottom-te">
                                            We never want you helpless on the road after a collision or crash. Dial emergency assistance immediately via <strong> Endsleigh car insurance contact number, </strong> and we will see to it that your vehicle is promptly and safely recovered from any location where it may be marooned.                                         </p>
                                            <hr />
                                            <p className="bold-text">Authorised garages </p>
                                            <p className="bottom-te">
                                            We have a wide chain of approved manufacturers’ garages that will restore your car to its original condition.                                       </p>
                                            <hr />
                                            <p className="bold-text">Like for like replacement</p>
                                            <p className="bottom-te">
                                            When your vehicle is being repaired, or it’s off the road, we can provide you with another one of those that matches its make and model through a hire like-for-like.                                            </p>
                                            <hr />
                                            <p className="bold-text">An experienced team of legal experts
                                            </p>
                                            <p className="bottom-te">
                                            Through our seasoned solicitors who understand no-fault accident claims, we have been successful in processing<strong> Endsleigh insurance claims, </strong> resulting in full compensation to claimants.                               </p>
                                            <hr />
                                            <p className="bold-text">Quick claims processing </p>
                                            <p className="bottom-te">
                                            There is little paperwork and long waits with our efficient and prompt claim procedure. You can easily make your claim without paperwork headaches.                                            </p>
                                            <hr />
                                            <p className="bold-text">Free independent advice</p>
                                            <p className="bottom-te">
                                            You could give us a call at any time on <strong>
                                                    {" "}
                                                    <a href="tel:0800 772 0850">0800 772 0850</a>{" "}
                                                </strong>if you have any questions about Endsleigh insurance claims. Our solicitors will advise and help you pursue your case accordingly.
                                            </p>
                                            <hr />
                                            <p className="bold-text">No-win, no-fee</p>
                                            <p className="bottom-te">
                                            We operate on a “no win-no fee” basis when serving our clients’ insurance claims. Therefore, you pay nothing if you lose your case.            </p>
                                            <hr />

                                            <p className="bold-text">24 hours a day, seven days a week support</p>
                                            <p className="bottom-te">
                                            If there is an accident, we are always there to help you by giving emergency assistance and guidance. For immediate assistance following a non-at-fault car accident, please call our car insurance claims line on<strong>
                                                    {" "}
                                                    <a href="tel:0800 772 0850">0800 772 0850 .</a>{" "}
                                                </strong>                                     </p>
                                        </div>
                                    </div>
                                </div>
                                <div className="cl4" ref={sidebarRef}>
                                    <Sidebarinner />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="photographs">
                <div className="container">
                    <div className="row">
                        <h2 className="road">
                        We will help you reduce the load of {" "}
                            <span className="light">
                                accident <br />
                                management through expert assistance
                            </span>{" "}
                        </h2>
                        <div className="col-xxl-6 col-xl-6 col-md-12 col-12">
                            <div className="fourbox">
                                <ul>
                                    <li>
                                        <img src={check} className="check" alt="" />
                                        In case of emergencies that result from car accidents, get in touch with us using the <strong> Endsleigh car insurance phone number. </strong> 

                                    </li>
                                    <hr />
                                    <li>
                                        <img src={check} className="check" alt="" />
                                        We will decide whether there is any other party at fault that can be pursued on your behalf for compensation.                                   </li>
                                    <hr />
                                    <li>
                                        <img src={check} className="check" alt="" />
                                        We provide start-to-end <strong> Endsleigh car insurance claims </strong> assistance to help you recover all losses.                     </li>
                                    <hr />
                                    <li>
                                        <img src={check} className="check" alt="" />
                                        Our team will recover your vehicle from the accident scene and arrange repair work through our network of approved repairers.                           </li>
                                </ul>
                            </div>
                        </div>
                        <div className="col-xxl-6 col-xl-6 col-md-12 col-12">
                            <div className="fourbox">
                                <ul>
                                    <li>
                                        <img src={check} className="check" alt="" />
                                        In cases where your vehicle cannot be repaired, we shall request a professional engineer to assess it.                                 </li>
                                    <hr />
                                    <li>
                                        <img src={check} className="check" alt="" />
                                        While your car is being fixed, you will have a temporary replacement car.                                 </li>
                                    <hr />
                                    <li>
                                        <img src={check} className="check" alt="" />
                                        Henceforth, we will keep track of how far your repairs are going so that we can let you know what’s happening with your vehicle.                                    </li>
                                    <hr />
                                    <li>
                                        <img src={check} className="check" alt="" />
                                        If you give us a call at 
                                        <strong>
                                            {" "}
                                            <a href="tel:0800 772 0850">0800 772 0850</a>{" "}
                                        </strong>, we can discuss with you about making an <strong> Endsleigh insurance claim </strong> so that you get paid for damages and losses.                                   </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="carprocess">
                <div className="container ">
                    <div className="row">
                        <div className="col-xxl-12 col-xxl-12 col-md-12 col-12">
                            <h2 className="trusted-title">
                                {" "}
                                Accident management services <br />
                                <span className="light">  we offer </span>{" "}
                            </h2>
                        </div>
                    </div>
                    <div className="row" id="possible">
                        <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-12">
                            <div className="claim">
                                <ul>
                                    <li>
                                        <span className="sleej">Car recovery:</span> To have your vehicle recovered after an accident, call<strong> Endsleigh car insurance contact number. </strong> We have a vehicle recovery service to ensure that your vehicle is quickly recovered from the scene of the accident into our secure storage yards.
                                    </li>
                                    <li>
                                        <span className="sleej">Approved Repairs:</span> Alternatively, if you wish to have your car repaired, contact our team using the <strong> Endsleigh insurance claim number. </strong> There are no excess charges for repairing your car, which will take place in trusted UK garages.
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-12">
                            <div className="claim" id="blue">
                                <ul>
                                    <li>
                                        <span className="sleej">Car storage:</span> Are you concerned about where to keep your car while awaiting your claim settlement? Kindly reach us through the <strong> Endsleigh insurance claim number. </strong>  We offer stress-free storage services for cars at our well-maintained yards while claims are being finalised.
                                    </li>
                                    <li>
                                        <span className="sleej"> Substitute Vehicles:</span> Whereby any damage incurred on your motor vehicle has made it unrepairable, you will receive a substitute vehicle. Contact us by dialling 
                                        <strong>
                                            {" "}
                                            <a href="tel:0800 772 0850">0800 772 0850</a>{" "}
                                        </strong>   emergency hotline in order that we may provide you with a similar replacement car that will enable you to go on with your normal life without worry. You can retain it until your claim is settled.


                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-12">
                            <div className="claim">
                                <ul>
                                    <li>
                                        <span className="sleej">Personal injury support:</span>
                                        To know if you can claim for personal injury compensation, the best way is to call our company on <strong> Endsleigh insurance claim number. </strong>  Our solicitors will give you invaluable advice towards securing damages arising from physical harm done to oneself.                                  </li>
                                    <li>
                                        <span className="sleej">Claim management :</span> From dealing with documents to arguing for settlement with insurers, arranging car repairs, and organising replacement vehicles – we do it all.

                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="yellowcta">
                <div className="container">
                    <div className="row">
                        <div className="cta2">
                            <div className="col-xxl-8 col-xl-8 col-lg-8 col-12">
                                <div className="legal">
                                    <h3 className="experienced">
                                        Got More questions? Talk to {" "}
                                        <span className="light">  our specialists
                                        </span>{" "}
                                    </h3>
                                    <p className="professionals">
                                    Get in touch with our no-fault claim experts via car insurance contact number 
                                        {" "}
                                        <strong>{" "}
                                            <a className="cpt-no" href="tel:0800 772 0850">
                                                {" "}
                                                0800 772 0850{" "}
                                            </a>{" "}
                                        </strong>
                                        to get the best advice for the
                                        <strong>  Endsleigh insurance claims.  </strong>
                                    </p>
                                </div>
                            </div>
                            <div className="col-xxl-4 col-xl-4 col-lg-4 col-12">
                                <div class="process">
                                    <a class="tf-button style-1 phone" href="tel:0800 772 0850">
                                        Get in touch<span class="icon-keyboard_arrow_right"></span>
                                    </a>
                                </div>
                                <div class="process">
                                    <a className="tf-button style-1 desktop" onClick={handleScrollToSidebar}>
                                        Get in touch<span className="icon-keyboard_arrow_right"></span>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <search className="updatedform">
                <div className="container">
                    <div className="row text-center">
                        <div className="col-xxl-12 col-xl-12 col-md-12 col-12">
                            <h2 className="whiteheading">
                                {" "}
                                What sets us apart 
                                <span className="light">  from others? 
                                </span>
                            </h2>
                            <p className="sustained">
                            Before anything else, we will save you from experiencing a traumatic incident of a “no-fault” road traffic accident. Here are some benefits you get when making us your choice for claims management.                        </p>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-xxl-4 col-xl-4 col-lg-6 col-md-12 col-12">
                            <div className="Claimant">
                                <h5>24/7 vehicle recovery</h5>
                                <p className="collision">
                                Are you looking for vehicle recovery services? Call the <strong> Endsleigh insurance claim number </strong> now, and no matter what time of day it is, we’ll come and take your vehicle from where it’s been after the accident or collision.

                                </p>

                                <h5>Guaranteed repairs</h5>
                                <p className="collision">
                                We have a wide coverage network of repairers who can fix your car excellently and with a guarantee.                              </p>

                                <h5>No increase in insurance premium</h5>
                                <p className="collision">
                                We shall not initiate a claim against your own policy, so your insurance premiums won’t rise.                           </p>
                            </div>
                        </div>
                        <div className="col-xxl-4 col-xl-4 col-lg-6 col-md-12 col-12">
                            <div className="Claimant">
                                <h5> Free replacement car
                                </h5>
                                <p className="collision">
                                We will provide a similar replacement vehicle without any additional charges. </p>
                                <h5>A network of reliable partners</h5>
                                <p className="collision">
                                We have created a strong network of UK-approved repairers and skilled solicitors in order to ensure excellent service delivery on our part.
                                </p>

                                <h5>Fast claims settlement</h5>
                                <p className="collision">
                                We make sure that the claim is made quickly and fairly through discussions with the other party’s insurer by our experienced solicitors.
                                </p>
                            </div>
                        </div>
                        <div className="col-xxl-4 col-xl-4 col-lg-12 col-md-12 col-12">
                            <div className="Claimant">
                                <h5>Immediate support</h5>
                                <p className="collision">
                                Without wasting time, we guarantee quick assistance whenever there are non-fault road traffic accidents leading to emergencies.
                                </p>

                                <h5>Tailored assistance</h5>
                                <p className="collision">
                                Your case will be handled personally by one of our claims managers, who will always keep you informed about developments on it.                        </p>

                                <h5>No excess charges</h5>
                                <p className="collision">
                                This means that you will be refunded any money that you spend since no extra payment or out-of-pocket expenses are required.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </search>

            <section className="Accident-claim">
                <div className="container">
                    <div className="row">
                        <div className="needed">
                            <h2 className="trusted-title">
                            How can you maximise  <br />
                                <span className="light"> your claim?  </span>
                            </h2>
                            <p className="complexities">
                            It is stressful to get involved in an accident, which isn’t your fault, but it is even more frustrating to deal with the complex claims processes. To maximise your chances of obtaining rightful compensation, you need to provide tangible evidence to prove your innocence. Here is what you must do after a car accident to secure maximum compensation. 
                            </p>
                        </div>
                        <hr />
                    </div>
                    <div className="row" id="incdes">
                        <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-12">
                            <div className="Preparing">
                                <ul>
                                    <li>
                                        <div className="seemingly">
                                            <h5 className="Seek">Ensure your safety :</h5>
                                            <p>
                                                {" "}
                                                Stop your car in a safe place and check yourself and your passengers for serious or minor injuries.                                     </p>
                                        </div>
                                    </li>
                                    <li>
                                        <div className="seemingly">
                                            <h5 className="Seek">
                                            Get immediate medical help :{" "}
                                            </h5>
                                            <p>
                                            If there is any injured passenger in your vehicle, make sure that you call an experienced doctor immediately.                                       </p>
                                        </div>
                                    </li>
                                    <li>
                                        <div className="seemingly">
                                            <h5 className="Seek">Gather evidence:</h5>
                                            <p>
                                            Essential pieces of evidence to be collected at the scene of the accident include photos showing where the accidents occurred, information on who caused the accidents, injuries sustained, damages resulting and vehicle information.                                           </p>
                                        </div>
                                    </li>
                                    <li>
                                        <div className="seemingly">
                                            <h5 className="Seek">Call police :</h5>
                                            <p>
                                            Report the accident to the police within 24 hours and also get a copy of the police report to make your claim stronger.                                   </p>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div
                            className="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-12"
                            id="dualcardiv"
                        >
                            <img src={dualcar} alt="" className="dualcar" />
                        </div>
                        <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-12">
                            <div className="Preparing">
                                <ul>
                                    <li>
                                        <div className="seemingly">
                                            <h5 className="Seek">Exchange information:</h5>
                                            <p>
                                            In case possible, provide other drivers with your insurance information as well as ask for theirs. Some of this information may include their complete names, phone numbers, and vehicle information.                                            </p>
                                        </div>
                                    </li>
                                    <li>
                                        <div className="seemingly">
                                            <h5 className="Seek">Collect eyewitness details: </h5>
                                            <p>
                                            Witness’s details, including their phone numbers, can also be captured for future reference purposes.                      </p>
                                        </div>
                                    </li>
                                    <li>
                                        <div className="seemingly">
                                            <h5 className="Seek">
                                            Note down expenses:
                                            </h5>
                                            <p> Receipts for repair services dues in response to claims filed by insurers must be kept in order to recover these costs from the other party. 
                                              

                                            </p>
                                        </div>
                                    </li>
                                    <li>
                                        <div className="seemingly">
                                            <h5 className="Seek">
                                            Consult a no-fault solicitor :
                                            </h5>
                                            <p> For advice on how to claim successfully to recover all damages and losses call our helpline on 
                                            <strong>
                                            {" "}
                                            <a href="tel:0800 772 0850">0800 772 0850</a>{" "}
                                        </strong>

                                            </p>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="whyus">
                <div className="container">
                    <div className="row">
                        <div className="col-xxl-12 col-md-12">
                            <div className="why">
                                <h2 className="choose">
                                Make your no-fault accident claim in  {" "}
                                    <span className="light"> simple steps </span>
                                </h2>
                                <p className="complexities">
                                Making an <strong> Endsleigh motor insurance claim </strong>  after an accident caused by another is often daunting. We will help you with this. Dial <strong> Endsleigh car insurance phone number </strong>  and start it now.                                       </p>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-xxl-3 col-xl-6 col-lg-6 col-md-6 col-12">
                            <div className="box-service">
                                <img src={check} className="check" alt="" />
                                {/* <img src={experience} className="experience" alt="" /> */}
                                <h4>Contact us</h4>
                                <p>
                                Contact our team on the <strong> Endsleigh car insurance contact number </strong>  to discuss your case. </p>
                            </div>
                        </div>
                        <div className="col-xxl-3 col-xl-6 col-lg-6 col-md-6 col-12">
                            <div className="box-service">
                                <img src={check} className="check" alt="" />
                                <h4>Give details
                                </h4>
                                <p>
                                Please give us all the particulars of the accident, including the date, time, location and how it happened.</p>
                            </div>
                        </div>
                        <div className="col-xxl-3 col-xl-6 col-lg-6 col-md-6 col-12">
                            <div className="box-service">
                                <img src={check} className="check" alt="" />
                                <h4>Provide evidence</h4>
                                <p>
                                Provide some proof to support your claim. They may include photos taken at the scene of the accident, eyewitness statements, police reports and CCTV videos.
                                </p>
                            </div>
                        </div>
                        <div className="col-xxl-3 col-xl-6 col-lg-6 col-md-6 col-12">
                            <div className="box-service">
                                <img src={check} className="check" alt="" />
                                <h4>Claim processing </h4>
                                <p>
                                Upon assessment, we will begin your claim. Our team will keep you updated on the progress.

                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="faqs">
                <div className="container">
                    <div className="row">
                        <div className="col-xxl-12 col-xl-12 col-md-12 col-12">
                            <div className="fq">
                                <h2>
                                    {" "}
                                    Frequently Asked{" "}
                                    <span className="light">Questions</span>
                                </h2>
                                <p className="complexities">
                                    Got questions about the <strong>  Endsleigh insurance claims?</strong> We have them answered here.
                                </p>
                            </div>
                            <div className="allfaq">
                                <Accordion defaultActiveKey="0">
                                    <Accordion.Item eventKey="0">
                                        <Accordion.Header>
                                        Do I need to pay additional fees for a replacement vehicle?                               </Accordion.Header>
                                        <Accordion.Body>
                                        No. We will bear the full costs of replacing your car from the guilty party’s insurer. You only get to pay if you fail to cooperate with us, employ a solicitor without our knowledge or provide false information about your case.                             </Accordion.Body>
                                    </Accordion.Item>
                                    <Accordion.Item eventKey="1">
                                        <Accordion.Header>
                                        What is the duration of the claims settlement?
                                        </Accordion.Header>
                                        <Accordion.Body>
                                        Though we will do everything possible to expedite claim settlement for Endsleigh car insurance claim as soon as possible. Nevertheless, when exactly it will be done depends upon the circumstances of your case. After we have initiated your claim with us, you will be updated about its progress                         </Accordion.Body>
                                    </Accordion.Item>
                                    <Accordion.Item eventKey="2">
                                        <Accordion.Header>
                                        Does the fault party’s insurance company cover the costs of my vehicle recovery? 
                                        </Accordion.Header>
                                        <Accordion.Body>
                                        Indeed, all expenses involved in retrieving the vehicle are compensated for by the insurer of the party that was responsible for causing the accident. If you wish to 
                                        receive compensation, you might need evidence such as a police report or images taken at the scene which show that you were entitled to it.                            </Accordion.Body>
                                    </Accordion.Item>
                                    <Accordion.Item eventKey="3">
                                        <Accordion.Header>
                                        Can I have a credit hire after a no-fault car accident?
                                        </Accordion.Header>
                                        <Accordion.Body>
                                        Yes, if you are an innocent driver, you will get a credit hire. Contact us at the <strong> Endsleigh insurance claim number </strong> when your car is destroyed or being fixed, and we will provide you with another similar one to use until you regain your vehicle.                                   </Accordion.Body>
                                    </Accordion.Item>
                                    <Accordion.Item eventKey="4">
                                        <Accordion.Header>
                                        Will I lose all my no-claims bonus if it’s not my fault?

                                        </Accordion.Header>
                                        <Accordion.Body>
                                        If you make your claim through us, then we won’t submit a claim against your own insurance policy, meaning that there is no risk of any loss of your bonuses or discounts that have been accumulated over the years.        </Accordion.Body>
                                    </Accordion.Item>
                                    <Accordion.Item eventKey="5">
                                        <Accordion.Header>
                                        Can my preferred garage be used for repairing my car?{" "}
                                        </Accordion.Header>
                                        <Accordion.Body>
                                        Certainly! We can have your vehicle repaired in your best-loved garage. The repairs will be done fast, and our repairer will be liaising with the repairer to ensure that only top-notch quality standards are met.                 </Accordion.Body>
                                    </Accordion.Item>
                                    <Accordion.Item eventKey="6">
                                        <Accordion.Header>
                                        What type of vehicle do I get in place of mine?{" "}
                                        </Accordion.Header>
                                        <Accordion.Body>
                                        We will organise a replacement vehicle which is equivalent to, as well as similar in size to, and serves the same function as yours. Call us on our <strong> Endsleigh insurance claim number, </strong>  and just let us know what you want. Then we shall give you an appropriate replacement vehicle. 
                                        </Accordion.Body>
                                    </Accordion.Item>
                                </Accordion>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <Footer />
        </>
    );
};

export default Endsleigh;
