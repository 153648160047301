import React, { useRef } from "react";
import insurancelogo from "../../src/assets/images/insurancelogo.png";
import Accordion from "react-bootstrap/Accordion";
import check from "../assets/images/check.png";
import dualcar from "../../src/assets/images/dualcar.webp";
import morethan from "../../src/assets/images/morethan.webp";
import Sidebarinner from "../components/Sidebar-inner";
import phonecall from "../assets/images/phonecall.png";
import Footer from "../components/footer";
import { Helmet } from "react-helmet";

const Sainsburysbank = () => {
  const sidebarRef = useRef(null);

  const handleScrollToSidebar = () => {
    if (sidebarRef.current) {
      sidebarRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Sainsbury's Bank Car Insurance Claims Contact Number</title>
        <meta
          name="description"
          content="Need assistance with your car accident claim? Call the Sainsbury’s Bank Car Insurance Claims Contact Number 0800 772 0850 for professional guidance from claim experts. "
        />
        <link
          rel="canonical"
          href="https://insurance-accident-claims.co.uk/sainsburys-bank-car-insurance"
        />
      </Helmet>
      <section className="innernav">
        <div className="container">
          <div className="row">
            <div className="col-md-6 col-sm-6">
              <div className="left-logo">
                {/* <img src={insurancelogo} className='insurancelogo' alt='Insurance Logo' /> */}
                <h3>Accident Claims</h3>
              </div>
            </div>
            <div className="col-md-6 col-sm-6">
              <div className="left-cta">
                <div class="headercnt">
                  <div class="chatmsg">
                    <img
                      src={phonecall}
                      className="phonecall"
                      alt="phonecall "
                    />
                  </div>
                  <div class="box-body">
                    <h3 class="nav-info-box-title">Toll Free Number </h3>
                    <p>
                      <a href="tel:0800 772 0850">0800 772 0850</a>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section
        className="newbnr"
        style={{ backgroundImage: "url(" + morethan + ")" }}
      >
        <div className="container">
          <div className="row">
            <div className="col-xxl-6 col-xl-6 col-md-10 col-12">
              <div className="innerbnr">
                <h1>
                  <span className="onespan">No-Fault </span> <br />
                  <span className="sci">Accident Management</span> & Claims
                  Experts
                </h1>
                <p className="brokerage">
                  Let us be your first port of call for accident management
                  solutions and claims assistance after a no-fault car accident.
                </p>

                <div className="bnrbtn">
                  <a class="db-btn-border btn-rollover phone" href="tel:0800 772 0850">
                    Start your claim{" "}
                    <i class="fa-solid fa-arrow-right"></i>
                  </a>
                </div>
                <div className="bnrbtn">
                <a className="db-btn-border btn-rollover desktop"  onClick={handleScrollToSidebar}>
                  Start your claim <i className="fa-solid fa-arrow-right"></i>
                </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="stickysection">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="group-4-8">
                <div className="cl8 tf-tab">
                  <div className="content-tab">
                    <div className="inner-content">
                      <h2>
                        We provide the best accident management assistance
                        <span className="light"> with no excess costs </span>
                      </h2>
                      <p className="disciplines">
                        When you find yourself dealing with the consequences of
                        a car accident, we’re here to help. Call us at{" "}
                        <strong>
                          Sainsbury's car insurance claims contact number
                        </strong>{" "}
                        for end-to-end accident management solutions from
                        vehicle recovery to claims management with zero upfront
                        charges.
                      </p>
                      <hr />
                      <p className="bold-text">No insurance excess</p>
                      <p className="bottom-te">
                        We don’t claim against your own insurance policy.
                        Instead, we initiate a{" "}
                        <strong>Sainsbury’s car insurance claim</strong>{" "}
                        directly against the at-fault party, saving you from
                        paying any policy excess.
                      </p>
                      <hr />
                      <p className="bold-text">No impact on no-claims bonus</p>
                      <p className="bottom-te">
                        Since we won’t initiate a claim against your own
                        insurance policy, your discounts and no-claims bonus
                        will stay protected when you make a{" "}
                        <strong>Sainsbury's Bank car insurance claim </strong>
                        with us.
                      </p>
                      <hr />
                      <p className="bold-text">Dedicated claims handler </p>
                      <p className="bottom-te">
                        We will appoint a dedicated claims handler to oversee{" "}
                        <strong>Sainsbury's Bank car accident claims</strong>{" "}
                        and protect your interests.
                      </p>
                      <hr />
                      <p className="bold-text">24/7 support </p>
                      <p className="bottom-te">
                        We offer round-the-clock vehicle recovery and safe
                        storage assistance across the UK. So, when you call us
                        at{" "}
                        <strong>
                          Sainsbury's car insurance claims contact number
                        </strong>
                        , you’re sure to receive immediate assistance no matter
                        the time.
                      </p>
                      <hr />
                      <p className="bold-text">Manufacturer approved repairs</p>
                      <p className="bottom-te">
                        We ensure superior standards of repairs through our
                        extensive network of reliable repairers in the UK who
                        only use manufacturer-approved parts and paints.
                      </p>
                      <hr />
                      <p className="bold-text">
                        Comparable vehicle replacement{" "}
                      </p>
                      <p className="bottom-te">
                        When you make the{" "}
                        <strong>Sainsbury’s car insurance claim</strong> with
                        us, we guarantee to provide you with a replacement
                        vehicle comparable to your own vehicle in terms of size,
                        make and model.
                      </p>
                      <hr />
                      <p className="bold-text">24/7 recovery and storage </p>
                      <p className="bottom-te">
                        We offer round-the-clock vehicle recovery and safe
                        storage assistance across the UK. So, when you call us
                        at{" "}
                        <strong>
                          Sainsbury's car insurance claims contact number
                        </strong>
                        , you’re sure to receive immediate assistance no matter
                        the time.
                      </p>
                      <hr />
                      <p className="bold-text">Rapid response </p>
                      <p className="bottom-te">
                        We will handle all the paperwork and documentation for
                        your <strong>Sainsbury’s car insurance claim</strong> so
                        that you can focus on your recovery.
                      </p>
                      <hr />
                      <p className="bold-text">Expedited claims settlement </p>
                      <p className="bottom-te">
                        Our streamlined process ensures quick resolution of your{" "}
                        <strong>Sainsbury’s car insurance claim</strong>,
                        allowing you to get deserving compensation without
                        delay.
                      </p>
                      <hr />
                      <p className="bold-text">Best legal assistance </p>
                      <p className="bottom-te">
                        Our panel of solicitors specialise in handling no-fault
                        car accident claims in the UK. So, you can be assured of
                        getting the best advice and guidance for your{" "}
                        <strong>Sainsbury's Bank car insurance claim</strong>.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="cl4" ref={sidebarRef}>
                  <Sidebarinner />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="photographs">
        <div className="container">
          <div className="row">
            <h2 className="road">
              We help you get back on the road to <br />
              <span className="light"> recovery after a car accident</span>{" "}
            </h2>
            <div className="col-xxl-6 col-xl-6 col-md-12 col-12">
              <div className="fourbox">
                <ul>
                  <li>
                    <img src={check} className="check" alt="" />
                    We’re your one-stop shop for the best assistance and advice
                    after a no-fault car accident.
                  </li>
                  <hr />
                  <li>
                    <img src={check} className="check" alt="" />
                    We will manage your{" "}
                    <strong>Sainsbury's Bank car insurance claim</strong> from
                    start to finish, offering your guidance at every step.
                  </li>
                  <hr />
                  <li>
                    <img src={check} className="check" alt="" />
                    We will liaise with all the parties involved with the
                    accident to ensure quick settlement of your{" "}
                    <strong>Sainsbury's Bank car accident claim</strong>.
                  </li>
                  <hr />
                  <li>
                    <img src={check} className="check" alt="" />
                    Our dedicated team will do their best to restore your
                    vehicle to its pre-accident condition and bring a smile to
                    your face.
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-xxl-6 col-xl-6 col-md-12 col-12">
              <div className="fourbox">
                <ul>
                  <li>
                    <img src={check} className="check" alt="" />
                    We only work with expert and manufacturer-approved
                    repairers, so you can expect the highest standards of
                    repairs for your vehicle.
                  </li>
                  <hr />
                  <li>
                    <img src={check} className="check" alt="" />
                    We will provide you with a like-for-like replacement vehicle
                    to keep you mobile while your vehicle is off the road.
                  </li>
                  <hr />
                  <li>
                    <img src={check} className="check" alt="" />
                    Our services remove the stress and hassle involved in
                    managing{" "}
                    <strong>Sainsbury's Bank car accident claims</strong>{" "}
                    through expert guidance.
                  </li>
                  <hr />
                  <li>
                    <img src={check} className="check" alt="" />
                    We will assess your damages and loss of earnings and help
                    you recover all the costs from the at-fault party.
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="carprocess">
        <div className="container ">
          <div className="row">
            <div className="col-xxl-12 col-xxl-12 col-md-12 col-12">
              <h2 className="trusted-title">
                Our no-fault accident <br />
                <span className="light">management solutions </span>
              </h2>
            </div>
          </div>
          <div className="row" id="possible">
            <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-12">
              <div className="claim">
                <ul>
                  <li>
                    <span className="sleej">Vehicle recovery</span> <br />
                    Want accident recovery for your vehicle? Call us at{" "}
                    <strong>
                      Sainsbury's car insurance claims contact number
                    </strong>
                    . Our qualified and experienced recovery team will move your
                    vehicle to a safe and secure place.
                  </li>
                  <li>
                    <span className="sleej">Vehicle repairs </span>
                    <br />
                    When you call us at the{" "}
                    <strong>Sainsbury car insurance claim number</strong> for
                    vehicle repairs, we will ensure your vehicle is repaired to
                    the highest industry standards through our trusted and
                    reliable repair network in the UK.
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-12">
              <div className="claim" id="blue">
                <ul>
                  <li>
                    <span className="sleej">Safe storage </span> <br />
                    If your vehicle is not in a driveable state after a no-fault
                    car accident, we will store your vehicle in our safe storage
                    facilities until repairs or claim assessment. Our dedicated
                    team ensures the safe transportation of your car to our
                    facility, so you can rest assured that your vehicle is in
                    safe hands.
                  </li>
                  <li>
                    <span className="sleej">Replacement car </span> <br />
                    We understand that getting back on the road is your first
                    priority after a no-fault car accident. We'll help you get
                    back to normality by providing a like-for-like replacement
                    car. Get in touch with us on{" "}
                    <strong>
                      Sainsbury's car insurance claims contact number
                    </strong>{" "}
                    to secure your replacement vehicle today.
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-12">
              <div className="claim">
                <ul>
                  <li>
                    <span className="sleej"> Injury claims </span>
                    <br />
                    We can help you recover expenses and claim compensation for
                    the injuries sustained in a no-fault car accident. Our
                    experienced solicitors will guide you throughout the process
                    and help you get the deserving compensation.
                  </li>
                  <li>
                    <span className="sleej">Claims management </span> <br />
                    From claim initiation to claims settlement, we will assist
                    you at each and every step of{" "}
                    <strong>Sainsbury's Bank car accident claim</strong> to
                    ensure the best outcome.
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="yellowcta">
        <div className="container">
          <div className="row">
            <div className="cta2">
              <div className="col-xxl-8 col-xl-8 col-lg-8 col-12">
                <div className="legal">
                  <h3 className="experienced">
                    Got more questions?{" "}
                    <span className="light">Talk to our specialists </span>
                  </h3>
                  <p className="professionals">
                    Get in touch with our no-fault claim experts via{" "}
                    <strong>
                      insurance claims contact number{" "}
                      <a href="tel:0800 772 0850">0800 772 0850</a>
                    </strong>{" "}
                    to get the best advice for your{" "}
                    <strong>Sainsbury's Bank car accident claim</strong>.
                  </p>
                </div>
              </div>
              <div className="col-xxl-4 col-xl-4 col-lg-4 col-12">
              <div class="process">
                  <a class="tf-button style-1 phone" href="tel:0800 772 0850">
                    Get in touch<span class="icon-keyboard_arrow_right"></span>
                  </a>
                </div>
                <div class="process">
                    <a className="tf-button style-1 desktop"  onClick={handleScrollToSidebar}>
                        Get in touch<span className="icon-keyboard_arrow_right"></span>
                    </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <search className="updatedform">
        <div className="container">
          <div className="row text-center">
            <div className="col-xxl-12 col-xl-12 col-md-12 col-12">
              <h2 className="whiteheading">
                Why choose us for your
                <span className="light"> accident management needs?</span>
              </h2>
              <p className="sustained">
                As one of the leading accident management companies in the UK,
                we have helped thousands of no-fault drivers obtain compensation
                for their losses and damages. Our accident management solutions
                reduce the stress, complexity and hassle involved in dealing
                with the aftermath of road traffic accidents. Reach out to us on{" "}
                <strong>
                  <a href="tel:0800 772 0850">0800 772 0850</a>
                </strong>
                for emergency assistance after a no-fault accident.
              </p>
            </div>
          </div>
          <div className="row">
            <div className="col-xxl-4 col-xl-4 col-lg-6 col-md-12 col-12">
              <div className="Claimant">
                <h5>Hassle-free replacement vehicle </h5>
                <p className="collision">
                  In the event of a no-fault car accident, if your vehicle is
                  undrivable, we will provide you with an instant like-for-like
                  replacement vehicle.
                </p>
                <h5>24/7 claims handling </h5>
                <p className="collision">
                  Our team is available round the clock to provide immediate
                  assistance, ensuring your{" "}
                  <strong>Sainsbury’s car insurance claim</strong> gets
                  initiated without any delay.
                </p>
                <h5>Quality repairs </h5>
                <p className="collision">
                  Call us at <strong>Sainsbury’s car insurance claim</strong>{" "}
                  for quick repairs of your damaged vehicle from our
                  manufacturer-approved repairers.
                </p>
              </div>
            </div>
            <div className="col-xxl-4 col-xl-4 col-lg-6 col-md-12 col-12">
              <div className="Claimant">
                <h5>End-to-end claims management </h5>
                <p className="collision">
                  From vehicle recovery to claims management and everything in
                  between, we provide a comprehensive range of services.
                </p>
                <h5>No win no fee </h5>
                <p className="collision">
                  With our no-win, no-fee solicitors, you can make your{" "}
                  <strong>Sainsbury's Bank car accident claim</strong> without
                  incurring any fees unless your claim is successful.
                </p>
                <h5>No excess fee</h5>
                <p className="collision">
                  Since you're not at fault, you can make the{" "}
                  <strong>Sainsbury's Bank car insurance claim</strong> without
                  paying any insurance excess.
                </p>
              </div>
            </div>
            <div className="col-xxl-4 col-xl-4 col-lg-12 col-md-12 col-12">
              <div className="Claimant">
                <h5>Prompt recovery assistance </h5>
                <p className="collision">
                  With our round-the-clock availability, we will ensure quick
                  recovery and vehicle repairs to get you back on the road as
                  soon as possible.
                </p>
                <h5>Immediate replacement vehicle </h5>
                <p className="collision">
                  We will provide you with a like-for-like replacement vehicle
                  without delay, allowing you to continue your daily tasks
                  without significant disruption.
                </p>
                <h5>No upfront costs </h5>
                <p className="collision">
                  We won’t charge you a single penny for our services. We will
                  recover all the expenses and costs from the at-fault party’s
                  insurance company.
                </p>
              </div>
            </div>
          </div>
        </div>
      </search>

      <section className="Accident-claim">
        <div className="container">
          <div className="row">
            <div className="needed">
              <h2 className="trusted-title">
                How to get the best compensation for
                <br />
                <span className="light">Sainsbury's car insurance claim? </span>
              </h2>
              <p className="complexities">
                Being involved in a no-fault car accident can lead to thousands
                of pounds in medical bills and vehicle repairs as well as
                significant losses such as loss of income, pain & suffering.
                However, you must take a few steps to build a strong case and
                obtain maximum compensation.
              </p>
            </div>
            <hr />
          </div>
          <div className="row" id="incdes">
            <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-12">
              <div className="Preparing">
                <ul>
                  <li>
                    <div className="seemingly">
                      <h5 className="Seek">Stop and examine yourself </h5>
                      <p>
                        Never flee from the accident scene. Instead, stop your
                        car at a secure place away from the traffic and check
                        yourself and other passengers involved in the accident
                        for any injuries.
                      </p>
                    </div>
                  </li>
                  <li>
                    <div className="seemingly">
                      <h5 className="Seek">Call the police </h5>
                      <p>
                        Immediately call the police and report the car accident.
                        The police report will record all the crucial details of
                        the accident and provide vital evidence to support your{" "}
                        <strong>Sainsbury's Bank car accident claim</strong>.
                      </p>
                    </div>
                  </li>
                  <li>
                    <div className="seemingly">
                      <h5 className="Seek">Gather evidence at the scene</h5>
                      <p>
                        Take photographs and videos of the accident scene, the
                        vehicles involved and your injuries. It will serve as
                        crucial evidence to strengthen your claim.
                      </p>
                    </div>
                  </li>
                  <li>
                    <div className="seemingly">
                      <h5 className="Seek">Seek medical treatment </h5>
                      <p>
                        If you or any of your passengers have sustained any
                        injuries, immediately seek medical treatment to get them
                        treated. The medical report may serve as vital evidence
                        for your injury claim.
                      </p>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
            <div
              className="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-12"
              id="dualcardiv"
            >
              <img src={dualcar} alt="" className="dualcar" />
            </div>
            <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-12">
              <div className="Preparing">
                <ul>
                  <li>
                    <div className="seemingly">
                      <h5 className="Seek">Keep a record of your losses </h5>
                      <p>
                        Maintain a record of all the financial losses incurred
                        due to the accident, such as medical bills, loss of
                        income, travel expenses and legal costs.
                      </p>
                    </div>
                  </li>
                  <li>
                    <div className="seemingly">
                      <h5 className="Seek">Stay away from social media </h5>
                      <p>
                        Don’t post anything on social media until your claim
                        gets settled. The other party’s insurance company and
                        solicitor may use your social media posts against you
                        and try to lower your compensation.
                      </p>
                    </div>
                  </li>
                  <li>
                    <div className="seemingly">
                      <h5 className="Seek">Talk to a no-claim solicitor </h5>
                      <p>
                        Speak to a qualified no-fault claim solicitor to
                        understand your legal options to demand compensation for
                        your losses and injuries.
                      </p>
                    </div>
                  </li>
                  <li>
                    <div className="seemingly">
                      <h5 className="Seek">Make a no-fault claim </h5>
                      <p>
                        Call us on{" "}
                        <strong>
                          Sainsbury's car insurance claims contact number
                        </strong>{" "}
                        to initiate a successful{" "}
                        <strong>Sainsbury’s car insurance claim</strong> to get
                        rightful compensation.
                      </p>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="whyus">
        <div className="container">
          <div className="row">
            <div className="col-xxl-12 col-md-12">
              <div className="why">
                <h2 className="choose">
                  Start your Sainsbury's Bank Car Insurance Claim in
                  <span className="light">
                    <br /> 4 easy steps
                  </span>
                </h2>
                <p className="complexities">
                  Making a no-fault car accident claim is a daunting and
                  stressful process. However, with our streamlined and
                  hassle-free claims process, you can make your{" "}
                  <strong>Sainsbury's Bank car insurance claim</strong> in a few
                  steps. Contact us on the{" "}
                  <strong>Sainsbury car insurance claim number</strong> to get
                  started.
                </p>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-xxl-3 col-xl-6 col-lg-6 col-md-6 col-12">
              <div className="box-service">
                <img src={check} className="check" alt="" />
                <h4>Get in touch</h4>
                <p>
                  Call our dedicated team on{" "}
                  <strong>
                    car insurance claims contact number{" "}
                    <a href="tel:0800 772 0850">0800 772 0850</a>
                  </strong>{" "}
                  for emergency assistance.
                </p>
              </div>
            </div>
            <div className="col-xxl-3 col-xl-6 col-lg-6 col-md-6 col-12">
              <div className="box-service">
                <img src={check} className="check" alt="" />
                <h4>Share accident details </h4>
                <p>
                  Share the car accident details with our team, including the
                  date, time, location and reason behind the collision.
                </p>
              </div>
            </div>
            <div className="col-xxl-3 col-xl-6 col-lg-6 col-md-6 col-12">
              <div className="box-service">
                <img src={check} className="check" alt="" />
                <h4>Claim assessment </h4>
                <p>
                  Our solicitors will assess your{" "}
                  <strong>Sainsbury's Bank car accident claim</strong> to
                  determine the extent of damages.
                </p>
              </div>
            </div>
            <div className="col-xxl-3 col-xl-6 col-lg-6 col-md-6 col-12">
              <div className="box-service">
                <img src={check} className="check" alt="" />
                <h4>Claim resolution </h4>
                <p>
                  Once we've received all the necessary documents and evidence,
                  our team will work towards resolving your claim as soon as
                  possible.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="faqs">
        <div className="container">
          <div className="row">
            <div className="col-xxl-12 col-xl-12 col-md-12 col-12">
              <div className="fq">
                <h2>
                  {" "}
                  Frequently Asked{" "}
                  <span className="light">Questions (FAQ)</span>
                </h2>
                <p className="complexities">
                  Got questions about{" "}
                  <strong>Sainsbury's car insurance claim?</strong> We have them
                  answered here.{" "}
                </p>
              </div>
              <div className="allfaq">
                <Accordion defaultActiveKey="0">
                  <Accordion.Item eventKey="0">
                    <Accordion.Header>
                      Do I get a courtesy car after a no-fault car accident?
                    </Accordion.Header>
                    <Accordion.Body>
                      Yes. We will provide you with a courtesy car as long as
                      your vehicle is undergoing repairs at an approved repair
                      network.
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="1">
                    <Accordion.Header>
                      What happens to my no-claims bonus if I make a Sainsbury's
                      Bank car insurance claim?
                    </Accordion.Header>
                    <Accordion.Body>
                      If you’ve had a no-fault car accident, it’s unlikely to
                      affect your no-claims bonus as the claim is not initiated
                      against your own insurance policy.
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="2">
                    <Accordion.Header>
                      What happens if my car needs to be repaired?
                    </Accordion.Header>
                    <Accordion.Body>
                      When you contact our team on the{" "}
                      <strong>Sainsbury car insurance claim number</strong> to
                      report your accident, we will advise you to get your
                      vehicle repaired by one of our trusted repairers. This
                      will expedite the process and ensure the highest quality
                      repairs for your damaged car.
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="3">
                    <Accordion.Header>
                      Can I have my vehicle repairs carried out by the repairer
                      of my choice?
                    </Accordion.Header>
                    <Accordion.Body>
                      Yes. You can share the details of your repairer with us,
                      and we’ll get in touch with them to schedule your vehicle
                      repairs.
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="4">
                    <Accordion.Header>
                      What kind of replacement vehicle will I be offered?
                    </Accordion.Header>
                    <Accordion.Body>
                      We will provide you with a like-for-like replacement
                      vehicle that matches the size, make and model of your own
                      vehicle.
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="5">
                    <Accordion.Header>
                      Should I accept an out-of-court settlement offer by the
                      other party?
                    </Accordion.Header>
                    <Accordion.Body>
                      Don’t accept any settlement offer from the other party’s
                      insurance company without discussing it with your
                      solicitor, as such a settlement will be lower than what
                      you deserve.
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="6">
                    <Accordion.Header>
                      Will my vehicle be repaired using genuine parts?
                    </Accordion.Header>
                    <Accordion.Body>
                      Yes. Our repair network comprises manufacturer-approved
                      repairers that provide high-quality repairs using genuine
                      and original parts. So, you can rest assured that your
                      vehicle will be repaired using genuine parts and paints.
                    </Accordion.Body>
                  </Accordion.Item>
                </Accordion>
              </div>
            </div>
          </div>
        </div>
      </section>

      <Footer />
    </>
  );
};

export default Sainsburysbank;
