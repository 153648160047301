import React, { useRef } from "react";
import Accordion from "react-bootstrap/Accordion";
import check from "../assets/images/check.png";
import dualcar from "../../src/assets/images/dualcar.webp";
import carbnr1 from "../../src/assets/images/carbnr1.webp";
import Sidebarinner from "../components/Sidebar-inner";
import phonecall from "../assets/images/phonecall.png";
import Footer from "../components/footer";
import { Helmet } from "react-helmet";

const Autosaint = () => {
    const sidebarRef = useRef(null);

    const handleScrollToSidebar = () => {
        if (sidebarRef.current) {
            sidebarRef.current.scrollIntoView({ behavior: "smooth" });
        }
    };
    return (
        <>
        <Helmet>
        <meta charSet="utf-8" />
        <title>Autosaint Car Insurance Accident Claim Phone Number</title>
        <meta
          name="description"
          content="Looking to initiate a car insurance claim? Call us on the Autosaint car insurance claim number 0800 772 0850 for end-to-end claim management support in the UK. "
        />
        <link
          rel="canonical"
          href="https://insurance-accident-claims.co.uk/Autosaint"
        />
      </Helmet>
            <section className="innernav">
                <div className="container">
                    <div className="row">
                        <div className="col-md-6 col-sm-6">
                            <div className="left-logo">
                                <h3>
                                    Accident<span className="sipo"> Claims</span>
                                </h3>
                            </div>
                        </div>
                        <div className="col-md-6 col-sm-6">
                            <div className="left-cta">
                                <div class="headercnt">
                                    <div class="chatmsg">
                                        <img
                                            src={phonecall}
                                            className="phonecall"
                                            alt="phonecall "
                                        />
                                    </div>
                                    <div class="box-body">
                                        <h3 class="nav-info-box-title">Toll Free Number </h3>
                                        <p>
                                            <a href="tel:0800 772 0850">0800 772 0850</a>
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section
                className="newbnr"
                style={{ backgroundImage: "url(" + carbnr1 + ")" }}
            >
                <div className="container">
                    <div className="row">
                        <div className="col-xxl-7 col-xl-7 col-md-10 col-12">
                            <div className="innerbnr">
                                <h1>
                                    <span className="onespan">Rapid and trustworthy </span>{" "}
                                    <span className="sci"> car insurance claims </span> number
                                </h1>
                                <p className="brokerage">
                                    There is nothing to worry about when it comes to claims management. We will take care of everything from the time you call us for assistance until you get back on the road again.
                                </p>
                                <div className="bnrbtn">
                                    <a class="db-btn-border btn-rollover phone" href="tel:0800 772 0850">
                                        Road Accident Claim Help?{" "}
                                        <i class="fa-solid fa-arrow-right"></i>
                                    </a>
                                </div>
                                <div className="bnrbtn">
                                    <a className="db-btn-border btn-rollover desktop" onClick={handleScrollToSidebar}>
                                        Road Accident Claim Help? <i className="fa-solid fa-arrow-right"></i>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="stickysection">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="group-4-8">
                                <div className="cl8 tf-tab">
                                    <div className="content-tab">
                                        <div className="inner-content">
                                            <h2>
                                                Leading insurance claims handlers
                                                <br />
                                                <span className="light"> in the UK </span>{" "}
                                            </h2>
                                            <p className="disciplines">
                                                We rank among the top providers of <strong>
                                                    {" "}
                                                    Autosaint insurance claims
                                                </strong>{" "}
                                                across the UK, having dealt with thousands of cases successfully. You can reach us by using the
                                                <strong>
                                                    {" "}
                                                    Autosaint car insurance contact number
                                                </strong>{" "}
                                                if you need assistance in filing a claim. We will handle your
                                                <strong>
                                                    {" "}
                                                    Autosaint car insurance claim
                                                </strong>{" "}
                                                without charging any fees.
                                            </p>
                                            <hr />
                                            <p className="bold-text">No extra charges for excess</p>
                                            <p className="bottom-te">
                                                This means that if the accident was not caused by you, then there will be no additional costs for an insurance excess so as to upsurge your premiums.                                            </p>
                                            <hr />
                                            <p className="bold-text">No Claims Bonuses unaffected</p>
                                            <p className="bottom-te">
                                                You don’t have to lose your no-claim bonus when you make claims through our company. Your no-claim bonus is guaranteed safe with us.                                            </p>
                                            <hr />
                                            <p className="bold-text">
                                                Fast recovery
                                            </p>
                                            <p className="bottom-te">
                                                You should not stay stranded on the road after a crash or any other incident. Just dial the emergency phone line through the <strong>Autosaint car insurance contact number,</strong>and we shall quickly recover your vehicle from the accident scene.                                            </p>
                                            <hr />
                                            <p className="bold-text">Approved repair garages </p>
                                            <p className="bottom-te">
                                                We will make sure your vehicle is repaired to the highest standards through a large number of garages that are approved by car manufacturers.                                            </p>
                                            <hr />
                                            <p className="bold-text">Like-for-like replacement</p>
                                            <p className="bottom-te">
                                                If you have an accident, or your vehicle is unfit for the road, we can offer you a similar car to drive as yours on a hire basis.                                             </p>
                                            <hr />
                                            <p className="bold-text">Seasoned legal team</p>
                                            <p className="bottom-te">
                                                Our experienced solicitors know how to process<strong> Autosaint insurance claims </strong>and have helped victims of no-fault accidents obtain fair compensation.                                             </p>
                                            <hr />
                                            <p className="bold-text">Speedy claims processing</p>
                                            <p className="bottom-te">
                                                Paperwork shouldn’t be your worry when claiming with us. We have an efficient claim procedure, which includes minimal paperwork.                                            </p>
                                            <hr />
                                            <p className="bold-text"> No-obligation advice </p>
                                            <p className="bottom-te">
                                                For any questions regarding<strong> Autosaint insurance claims,</strong> feel free to call us at <strong>
                                            {" "}
                                            <a href="tel:0800 772 0850">0800 772 0850</a>{" "}
                                        </strong>  Let our solicitors guide you on the way forward concerning your case.                                            </p>
                                            <hr />
                                            <p className="bold-text">No win-no fee</p>
                                            <p className="bottom-te">
                                                In administering our clients’ insurance claims, our solicitors work on a no-win, no-fee policy, which means you won't lose anything in case of an unsuccessful outcome.                                            </p>
                                            <hr />

                                            <p className="bold-text">24/7 assistance</p>
                                            <p className="bottom-te">
                                                In case an accident happens and you need someone to offer you emergency assistance and guidance, please do not hesitate to contact us. For immediate help after a car crash that was not your fault, call our car insurance claims department on <strong>
                                            {" "}
                                            <a href="tel:0800 772 0850">0800 772 0850</a>{" "}
                                        </strong>                                             </p>
                                        </div>
                                    </div>
                                </div>
                                <div className="cl4" ref={sidebarRef}>
                                    <Sidebarinner />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="photographs">
                <div className="container">
                    <div className="row">
                        <h2 className="road">
                            Being experts at managing accidents, we are here to take    {" "}
                            <span className="light">
                                the <br />
                                pressure off your shoulders.
                            </span>{" "}
                        </h2>
                        <div className="col-xxl-6 col-xl-6 col-md-12 col-12">
                            <div className="fourbox">
                                <ul>
                                    <li>
                                        <img src={check} className="check" alt="" />
                                        Our <strong> Autosaint car insurance contact number</strong> should be dialled in cases of emergencies following car crashes.

                                    </li>
                                    <hr />
                                    <li>
                                        <img src={check} className="check" alt="" />
                                        We will look for any party responsible for the accident so that we may seek compensation from them on your behalf.                                    </li>
                                    <hr />
                                    <li>
                                        <img src={check} className="check" alt="" />
                                        In order to help you get compensated for any losses incurred, we will assist you in filing <strong>  Autosaint insurance claims. </strong>                                   </li>
                                    <hr />
                                    <li>
                                        <img src={check} className="check" alt="" />
                                        Our team will recover your vehicle from the accident scene and arrange repair work with our trusted network of manufacturer-approved repairers.                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div className="col-xxl-6 col-xl-6 col-md-12 col-12">
                            <div className="fourbox">
                                <ul>
                                    <li>
                                        <img src={check} className="check" alt="" />
                                        If your car cannot be repaired, we will bring in an independent engineer to assess how much it has been damaged or devalued.                                    </li>
                                    <hr />
                                    <li>
                                        <img src={check} className="check" alt="" />
                                        In the meantime, a temporary replacement vehicle will be provided until your claim is settled.                                     </li>
                                    <hr />
                                    <li>
                                        <img src={check} className="check" alt="" />
                                        We will keep updated about how far your repairs have gone by constantly checking up on your vehicle repairs.                                     </li>
                                    <hr />
                                    <li>
                                        <img src={check} className="check" alt="" />
                                        Call us now on <strong>
                                            {" "}
                                            <a href="tel:0800 772 0850">0800 772 0850</a>{" "}
                                        </strong>  and we can discuss further how to make an<strong> Autosaint insurance claim </strong> for compensation of damages and losses.                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="carprocess">
                <div className="container ">
                    <div className="row">
                        <div className="col-xxl-12 col-xxl-12 col-md-12 col-12">
                            <h2 className="trusted-title">
                                {" "}
                                Accident management services <br />
                                <span className="light"> we offer </span>{" "}
                            </h2>
                        </div>
                    </div>
                    <div className="row" id="possible">
                        <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-12">
                            <div className="claim">
                                <ul>
                                    <li>
                                        <span className="sleej">Vehicle recovery:</span> To have your vehicle recovered after an accident, call the <strong> Autosaint car insurance contact number.</strong>  Our vehicle recovery services are designed to ensure the safety of your vehicle through quick recovery from the accident scene into our safe storage yards.
                                    </li>
                                    <li>
                                        <span className="sleej">Approved repairs:</span> In case you want your vehicle repaired, dial our team via the <strong> Autosaint insurance claim number. </strong> There will be no extra costs for the repair of your car, which will occur at trusted and reliable garages found across the UK.
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-12">
                            <div className="claim" id="blue">
                                <ul>
                                    <li>
                                        <span className="sleej">Vehicle storage:</span> Are you concerned about safekeeping your car after a non-fault accident? Contact us through our <strong>Autosaint insurance claim number.</strong>  We have well-kept yards where we provide stress-free vehicle storage service as your claim is awaiting assessment.
                                    </li>
                                    <li>
                                        <span className="sleej">Replacement vehicles:</span> Should your car be beyond repair, you are entitled to a replacement car, so call us via emergency helpline
                                        <strong>
                                            {" "}
                                            <a href="tel:0800 772 0850">0800 772 0850</a>{" "}
                                        </strong>
                                        for a like-for-like replacement car that helps keep life going as usual without any worries. You can keep it until your claim is sorted out.
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-12">
                            <div className="claim">
                                <ul>
                                    <li>
                                        <span className="sleej">Injury claims assistance:</span>
                                        Calling our company on the <strong>Autosaint insurance claim number </strong>  is how you find out if personal injury compensation is available for you or not. Our solicitors will offer advice on how to get damages for personal injuries incurred.                                    </li>
                                    <li>
                                        <span className="sleej">Insurance claim management:</span> We will handle everything from paperwork to dealing with insurance companies, organising vehicle repairs and providing alternative vehicles.
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="yellowcta">
                <div className="container">
                    <div className="row">
                        <div className="cta2">
                            <div className="col-xxl-8 col-xl-8 col-lg-8 col-12">
                                <div className="legal">
                                    <h3 className="experienced">
                                        Got more questions? Talk to  {" "}
                                        <span className="light">our specialists
                                        </span>{" "}
                                    </h3>
                                    <p className="professionals">
                                        Get in touch with our no-fault claim experts via <strong> car insurance contact number </strong>
                                        {" "}
                                        <strong>{" "}
                                            <a className="cpt-no" href="tel:0800 772 0850">
                                                {" "}
                                                0800 772 0850{" "}
                                            </a>{" "}
                                        </strong>
                                        to get the best advice for the
                                        <strong> Autosaint insurance claims. </strong>
                                    </p>
                                </div>
                            </div>
                            <div className="col-xxl-4 col-xl-4 col-lg-4 col-12">
                                <div class="process">
                                    <a class="tf-button style-1 phone" href="tel:0800 772 0850">
                                        Get in touch<span class="icon-keyboard_arrow_right"></span>
                                    </a>
                                </div>
                                <div class="process">
                                    <a className="tf-button style-1 desktop" onClick={handleScrollToSidebar}>
                                        Get in touch<span className="icon-keyboard_arrow_right"></span>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <search className="updatedform">
                <div className="container">
                    <div className="row text-center">
                        <div className="col-xxl-12 col-xl-12 col-md-12 col-12">
                            <h2 className="whiteheading">
                                {" "}
                                What sets us
                                <span className="light"> apart?
                                </span>
                            </h2>
                            <p className="sustained">
                                We will make it our duty to rescue you from the pressure of a no-fault road mishap. Below are some of the perks you enjoy when you choose us as your preference in claims handling.                            </p>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-xxl-4 col-xl-4 col-lg-6 col-md-12 col-12">
                            <div className="Claimant">
                                <h5> Round-the-clock vehicle recovery</h5>
                                <p className="collision">
                                    Are you searching for a company that offers recovery services for vehicles? Dial the <strong> Autosaint insurance claim number,</strong> and we shall recover you from accident scenes regardless of time.

                                </p>

                                <h5>Guaranteed repairs</h5>
                                <p className="collision">
                                    We have a wide range of repairers who are able to provide top-notch and guaranteed repairs to your vehicle.                                </p>

                                <h5>No increase in your premium</h5>
                                <p className="collision">
                                    <strong>  Autosaint car insurance claims </strong>  will be made against the other party, causing no rise in your insurance premium.                                </p>
                            </div>
                        </div>
                        <div className="col-xxl-4 col-xl-4 col-lg-6 col-md-12 col-12">
                            <div className="Claimant">
                                <h5>Free replacement car
                                </h5>
                                <p className="collision">
                                    To keep you moving, we offer a like-for-like replacement vehicle at no upfront cost.  </p>
                                <h5>Reliable partners network</h5>
                                <p className="collision">
                                    We have therefore created a strong network of solicitors and repairers that are approved across the UK to give you quality services.                                </p>

                                <h5> Fast claim settlement</h5>
                                <p className="collision">
                                    Our experienced solicitors ensure your<strong> Autosaint car insurance claim </strong>will be settled fairly shortly after negotiating with the other party’s insurer.
                                </p>
                            </div>
                        </div>
                        <div className="col-xxl-4 col-xl-4 col-lg-12 col-md-12 col-12">
                            <div className="Claimant">
                                <h5>Instant assistance</h5>
                                <p className="collision">
                                    We promise an immediate emergency response following a non-fault accident without any delay.                                </p>

                                <h5>Personalised advice </h5>
                                <p className="collision">
                                    Your claim is our number one priority. Therefore, we will assign you a claims handle dedicated to personally handling your case and updating you on its progress step by step.                                </p>

                                <h5>No fees in excess</h5>
                                <p className="collision">
                                    You will not pay anything more or out-of-pocket for this service as we will recover all costs from the other party.                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </search>

            <section className="Accident-claim">
                <div className="container">
                    <div className="row">
                        <div className="needed">
                            <h2 className="trusted-title">
                                How do you maximise your chances of obtaining <br />
                                <span className="light"> full compensation?</span>
                            </h2>
                            <p className="complexities">
                                Unfortunately, it is quite distressing to be involved in an accident that was not your fault. However, dealing with the claims process can be even more annoying. To maximise your chances of getting enough compensation, prove beyond doubt that you were innocent by producing tangible evidence. Here is what you should do after a car accident if you want to get fair compensation.
                            </p>
                        </div>
                        <hr />
                    </div>
                    <div className="row" id="incdes">
                        <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-12">
                            <div className="Preparing">
                                <ul>
                                    <li>
                                        <div className="seemingly">
                                            <h5 className="Seek">Make sure you are safe:</h5>
                                            <p>
                                                {" "}
                                                Stop and park your vehicle at a secure place before checking your body as well as that of your passengers for any forms of internal or external injuries.                                            </p>
                                        </div>
                                    </li>
                                    <li>
                                        <div className="seemingly">
                                            <h5 className="Seek">
                                                Seek immediate medical attention:{" "}
                                            </h5>
                                            <p>
                                                It is important to find a good doctor immediately if there is anyone injured while in your car.                                            </p>
                                        </div>
                                    </li>
                                    <li>
                                        <div className="seemingly">
                                            <h5 className="Seek">Gather proof:</h5>
                                            <p>
                                                Evidence needed from the scene of an accident includes photographs of where the accident occurred, people responsible for the accident, damage caused, serious injuries and numbers on all drivers’ number plates.                                            </p>
                                        </div>
                                    </li>
                                    <li>
                                        <div className="seemingly">
                                            <h5 className="Seek">Call police:</h5>
                                            <p>
                                                Contact police authorities regarding what happened and obtain a copy of the police report to support your <strong>Autosaint insurance claim.</strong>                                              </p>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div
                            className="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-12"
                            id="dualcardiv"
                        >
                            <img src={dualcar} alt="" className="dualcar" />
                        </div>
                        <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-12">
                            <div className="Preparing">
                                <ul>
                                    <li>
                                        <div className="seemingly">
                                            <h5 className="Seek">Share details:</h5>
                                            <p>
                                                If you have been involved in such accidents, it would be helpful if you share details with the other driver involved in the accident.                                              </p>
                                        </div>
                                    </li>
                                    <li>
                                        <div className="seemingly">
                                            <h5 className="Seek">Record eyewitnesses address: </h5>
                                            <p>
                                                Record the details of eyewitnesses so that they can be reached out later to testify about what they saw.                                             </p>
                                        </div>
                                    </li>
                                    <li>
                                        <div className="seemingly">
                                            <h5 className="Seek">
                                                Note down expenses :
                                            </h5>
                                            <p>
                                                You should keep receipts for all expenses incurred, such as repair services, hospital bills, or any other costs, to get compensated for such expenses.
                                            </p>
                                        </div>
                                    </li>
                                    <li>
                                        <div className="seemingly">
                                            <h5 className="Seek">
                                                Consult a no-fault solicitor :
                                            </h5>
                                            <p>
                                                Call our helpline number on{" "}
                                                <strong>{" "}
                                                    <a className="cpt-no" href="tel:0800 772 0850">
                                                        {" "}
                                                        0800 772 0850 {" "}
                                                    </a>{" "}
                                                </strong>
                                                for guidance on how to make a successful compensation claim.

                                            </p>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="whyus">
                <div className="container">
                    <div className="row">
                        <div className="col-xxl-12 col-md-12">
                            <div className="why">
                                <h2 className="choose">
                                    Start your claim with  {" "}
                                    <span className="light">us today </span>
                                </h2>
                                <p className="complexities">
                                    It can be daunting to make an<strong>Autosaint motor insurance claim </strong> after an accident when it is not your fault. Therefore, we will assist you in doing this. Get started by calling us on the <strong> Autosaint car insurance phone number. </strong>                             </p>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-xxl-3 col-xl-6 col-lg-6 col-md-6 col-12">
                            <div className="box-service">
                                <img src={check} className="check" alt="" />
                                {/* <img src={experience} className="experience" alt="" /> */}
                                <h4>Get in touch with us </h4>
                                <p>
                                    Discuss your matter with us by giving a call on the <strong> Autosaint car insurance contact number.</strong></p>
                            </div>
                        </div>
                        <div className="col-xxl-3 col-xl-6 col-lg-6 col-md-6 col-12">
                            <div className="box-service">
                                <img src={check} className="check" alt="" />
                                <h4>Give information</h4>
                                <p>
                                    Tell us everything about the accident: when and where it took place, as well as the manner of occurrence. </p>
                            </div>
                        </div>
                        <div className="col-xxl-3 col-xl-6 col-lg-6 col-md-6 col-12">
                            <div className="box-service">
                                <img src={check} className="check" alt="" />
                                <h4> Provide proof</h4>
                                <p>
                                    Make available evidence that supports your claims, such as photos, videos, statements from witnesses, police reports or even CCTV footage.                                </p>
                            </div>
                        </div>
                        <div className="col-xxl-3 col-xl-6 col-lg-6 col-md-6 col-12">
                            <div className="box-service">
                                <img src={check} className="check" alt="" />
                                <h4>Claims processing</h4>
                                <p>
                                    Our team will start processing your claims once the valuation is done. As for updates, we will keep you informed at every step.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="faqs">
                <div className="container">
                    <div className="row">
                        <div className="col-xxl-12 col-xl-12 col-md-12 col-12">
                            <div className="fq">
                                <h2>
                                    {" "}
                                    Frequently Asked{" "}
                                    <span className="light">Questions</span>
                                </h2>
                                <p className="complexities">
                                    Got questions about the <strong> Autosaint insurance claims? </strong> We have them answered here.
                                </p>
                            </div>
                            <div className="allfaq">
                                <Accordion defaultActiveKey="0">
                                    <Accordion.Item eventKey="0">
                                        <Accordion.Header>
                                            Do I have to pay additional fees for replacement vehicles?                                        </Accordion.Header>
                                        <Accordion.Body>
                                            No. The at-fault party's insurer pays for all costs of your vehicle replacement. You just have to pay if you refuse to cooperate with us, engage a solicitor without our consent or give false information concerning your case.                                        </Accordion.Body>
                                    </Accordion.Item>
                                    <Accordion.Item eventKey="1">
                                        <Accordion.Header>
                                            How long will it take to settle my claim?
                                        </Accordion.Header>
                                        <Accordion.Body>
                                            We will try everything we can to reach a settlement on the <strong> Autosaint car insurance claim </strong>  as soon as possible. However, settlement time may vary depending on the circumstances surrounding each case. We will keep you updated after we initiate your claim.                                        </Accordion.Body>
                                    </Accordion.Item>
                                    <Accordion.Item eventKey="2">
                                        <Accordion.Header>
                                        Will the at-fault party’s insurance company pay for vehicle recovery?
                                        </Accordion.Header>
                                        <Accordion.Body>
                                        Yes, all expenses related to the towing of the vehicle are catered for by the at-fault party’s insurer. Some proof, like photographs taken at the scene of an accident or a police report, is needed in order to show that you have got entitlements for compensation.                                        </Accordion.Body>
                                    </Accordion.Item>
                                    <Accordion.Item eventKey="3">
                                        <Accordion.Header>
                                        Can I get a credit hire car following a no-fault road accident?
                                        </Accordion.Header>
                                        <Accordion.Body>
                                        Yes. If you are a non-fault driver, credit hire is available to you. In the event that your car has been written off or is under repair, call us on the <strong>Autosaint insurance claim number,</strong>  and we will offer you another car of the same type, make and model as yours.                                        </Accordion.Body>
                                    </Accordion.Item>
                                    <Accordion.Item eventKey="4">
                                        <Accordion.Header>
                                        Will I lose my no-claims bonus if I’m not at fault?

                                        </Accordion.Header>
                                        <Accordion.Body>
                                        We do not make a claim against your own policy. Thus, your no-claim bonus won’t be affected.                                        </Accordion.Body>
                                    </Accordion.Item>
                                    <Accordion.Item eventKey="5">
                                        <Accordion.Header>
                                        Can I use my own garage for repairs?{" "}
                                        </Accordion.Header>
                                        <Accordion.Body>
                                        Definitely. We may opt for repair work to be undertaken at your chosen garage. We will ensure quick repairs that meet only the highest quality standards by scheduling your vehicle repairs with your chosen garage.                                         </Accordion.Body>
                                    </Accordion.Item>
                                    <Accordion.Item eventKey="6">
                                        <Accordion.Header>
                                        What kind of car will I get as a replacement? {" "}
                                        </Accordion.Header>
                                        <Accordion.Body>
                                        Replacement cars are organised according to size, value and function similar to those possessed by yours. Just contact us on the <strong> Autosaint insurance claim number </strong> and tell us what you need. Afterwards, we shall give you one that suits you well enough.
                                        </Accordion.Body>
                                    </Accordion.Item>
                                </Accordion>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <Footer />
        </>
    );
};

export default Autosaint;
