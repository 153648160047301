import React, { useRef } from "react";
import insurancelogo from "../../src/assets/images/insurancelogo.png";
import Accordion from "react-bootstrap/Accordion";
import check from "../assets/images/check.png";
import dualcar from "../../src/assets/images/dualcar.webp";
import carbnr2 from "../../src/assets/images/carbnr2.webp";
import Sidebarinner from "../components/Sidebar-inner";
import phonecall from "../assets/images/phonecall.png";
import { Helmet } from "react-helmet";
import Footer from "../components/footer";

const Tescocarinsurance = () => {
  const sidebarRef = useRef(null);

  const handleScrollToSidebar = () => {
    if (sidebarRef.current) {
      sidebarRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Tesco Insurance Claim | Tesco Car Insurance Claims</title>
        <meta
          name="description"
          content="Want reliable claims assistance? Contact us on the Tesco Car Insurance Claim Phone Number 0800 772 0850 to maximise your chances of securing maximum compensation.  "
        />
        <link
          rel="canonical"
          href="https://insurance-accident-claims.co.uk/tesco-car-insurance "
        />
      </Helmet>
      <section className="innernav">
        <div className="container">
          <div className="row">
            <div className="col-md-6 col-sm-6">
              <div className="left-logo">
                {/* <img src={insurancelogo} className='insurancelogo' alt='Insurance Logo' /> */}
                <h3>Accident Claims</h3>
              </div>
            </div>
            <div className="col-md-6 col-sm-6">
              <div className="left-cta">
                <div class="headercnt">
                  <div class="chatmsg">
                    <img
                      src={phonecall}
                      className="phonecall"
                      alt="phonecall "
                    />
                  </div>
                  <div class="box-body">
                    <h3 class="nav-info-box-title">Toll Free Number </h3>
                    <p>
                      <a href="tel:0800 772 0850">0800 772 0850</a>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section
        className="newbnr"
        style={{ backgroundImage: "url(" + carbnr2 + ")" }}
      >
        <div className="container">
          <div className="row">
            <div className="col-xxl-8 col-xl-8 col-md-10 col-12">
              <div className="innerbnr">
                <h1>
                  <span className="onespan">Stress-free Car </span>{" "}
                  <span className="sci">Accident</span>  insurance claims Solutions{" "}
                </h1>
                <p className="brokerage">
                  From handling your claim to coordinating vehicle repairs,
                  we’re here to alleviate your burden after a no-fault car
                  accident.{" "}
                </p>
                <div className="bnrbtn">
                  <a class="db-btn-border btn-rollover phone" href="tel:0800 772 0850">
                    Begin your claim{" "}
                    <i class="fa-solid fa-arrow-right"></i>
                  </a>
                </div>
                <div className="bnrbtn">
                <a className="db-btn-border btn-rollover desktop"  onClick={handleScrollToSidebar}>
                  Begin your claim <i className="fa-solid fa-arrow-right"></i>
                </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="stickysection">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="group-4-8">
                <div className="cl8 tf-tab">
                  <div className="content-tab">
                    <div className="inner-content">
                      <h2>
                        Effortless Tesco Car Insurance claim{" "}
                        <span className="light">
                          management with zero excess
                        </span>{" "}
                      </h2>
                      <p className="disciplines">
                        We’re your trusted accident management partners
                        dedicated to providing you with the best accident
                        management solutions, from vehicle recovery to claims
                        management. Our professional team is here to handle the
                        complexities of <strong>tesco insurance claims</strong>{" "}
                        and provide you with immediate assistance after a
                        no-fault car accident without any upfront costs.
                      </p>
                      <hr />
                      <p className="bold-text">No additional costs </p>
                      <p className="bottom-te">
                        By choosing us, you can enjoy peace of mind knowing that
                        you can get top-notch accident management solutions
                        without incurring any additional costs. Contact us at
                        the{" "}
                        <strong>tesco car insurance claim phone number</strong>{" "}
                        to get emergency assistance without any financial
                        burden.
                      </p>
                      <hr />
                      <p className="bold-text">Preservation of NCB </p>
                      <p className="bottom-te">
                        We understand the importance of your hard-earned
                        discounts and no-claims bonus. That’s why our team works
                        diligently to ensure that your no-claims bonus stays
                        intact and doesn't get affected throughout the{" "}
                        <strong>tesco motor claims</strong> process.
                      </p>
                      <hr />
                      <p className="bold-text">Guaranteed repairs </p>
                      <p className="bottom-te">
                        We have an extensive network of industry-leading
                        repairers who provide excellent repairs using original
                        parts that meet manufacturer standards. Speak to our
                        dedicated team at the{" "}
                        <strong>
                          car insurance claims telephone number{" "}
                          <a href="tel:0800 772 0850">0800 772 0850</a>{" "}
                        </strong>
                        for instant vehicle repairs for your damaged vehicle.
                      </p>
                      <hr />
                      <p className="bold-text">Like-for-like replacement </p>
                      <p className="bottom-te">
                        When you call us at the tesco bank{" "}
                        <strong>car insurance claim contact number</strong>, we
                        will provide you with a like-for-like replacement
                        vehicle that matches the make, model and specifications
                        of your own vehicle.
                      </p>
                      <hr />
                      <p className="bold-text">Honest accident evaluation </p>
                      <p className="bottom-te">
                        Our panel of solicitors will diligently assess your
                        claim and offer clear and strategic guidance throughout
                        the <strong>tesco car insurance claims </strong>process.
                      </p>
                      <hr />
                      <p className="bold-text">Fast-paced claim process </p>
                      <p className="bottom-te">
                        We understand the significance of resolving your claim
                        promptly without any delay. From initial consultation to
                        claims settlement, we have an expedited process to
                        resolve your <strong>tesco motor claim</strong>.{" "}
                      </p>
                      <hr />
                      <p className="bold-text">Free expert solicitor advice </p>
                      <p className="bottom-te">
                        We work with experienced solicitors who provide free
                        advice without any obligations to help you pursue a
                        successful claim. To seek advice, contact our expert
                        solicitors at the{" "}
                        <strong>
                          car insurance claim phone number{" "}
                          <a href="tel:0800 772 0850">0800 772 0850</a>
                        </strong>
                        .
                      </p>
                      <hr />
                      <p className="bold-text">24/7 assistance </p>
                      <p className="bottom-te">
                        Car accidents occur unexpectedly. Our professional and
                        friendly team is available round the clock to provide
                        immediate assistance and support after a no-fault car
                        accident.{" "}
                      </p>
                      <hr />
                      <p className="bold-text">
                        Dedicated UK-based claim handlers{" "}
                      </p>
                      <p className="bottom-te">
                        When you get in touch via the{" "}
                        <strong>
                          tesco car insurance claims telephone number
                        </strong>
                        , we will assign you a dedicated UK-based claim handler
                        who will provide tailored assistance from start to
                        finish.
                      </p>
                      <hr />

                      <p className="bold-text">
                        No increase in insurance premiums{" "}
                      </p>
                      <p className="bottom-te">
                        With our streamlined and dedicated approach to accident
                        management, you can rest assured that your insurance
                        premiums won’t be affected.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="cl4" ref={sidebarRef}>
                  <Sidebarinner />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="photographs">
        <div className="container">
          <div className="row">
            <h2 className="road">
              We offer prompt and reliable assistance after a <br />
              <span className="light">no-fault car accident </span>{" "}
            </h2>
            <div className="col-xxl-6 col-xl-6 col-md-12 col-12">
              <div className="fourbox">
                <ul>
                  <li>
                    <img src={check} className="check" alt="" />
                    If you have been a victim of a no-fault car accident, we
                    will help you pursue a successful{" "}
                    <strong>tesco motor claim </strong>to help you recover the
                    losses and damages suffered.
                  </li>
                  <hr />
                  <li>
                    <img src={check} className="check" alt="" />
                    Our trained and experienced team will get in touch with you
                    to record accident details and provide quick assistance.
                  </li>
                  <hr />
                  <li>
                    <img src={check} className="check" alt="" />
                    Our solicitors will diligently assess your accident details
                    to identify the negligent party against whom you can pursue
                    a claim for your losses.
                  </li>
                  <hr />
                  <li>
                    <img src={check} className="check" alt="" />
                    If your vehicle cannot be driven or is a write-off, we will
                    provide a replacement vehicle to help you get back on the
                    road.
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-xxl-6 col-xl-6 col-md-12 col-12">
              <div className="fourbox">
                <ul>
                  <li>
                    <img src={check} className="check" alt="" />
                    We will liaise with all the parties involved in the accident
                    on your behalf, saving you from all the stress and hassles.
                  </li>
                  <hr />
                  <li>
                    <img src={check} className="check" alt="" />
                    We will arrange the repair of your damaged vehicle from a
                    trusted repair shop to ensure the highest-quality repairs.
                  </li>
                  <hr />
                  <li>
                    <img src={check} className="check" alt="" />
                    Our team will maintain constant touch with your repairer to
                    keep you informed about the repair progress.
                  </li>
                  <hr />
                  <li>
                    <img src={check} className="check" alt="" />
                    We will help you recover all the costs and expenses related
                    to repairs and replacement from the third-party driver or
                    their insurers.
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="carprocess">
        <div className="container ">
          <div className="row">
            <div className="col-xxl-12 col-xxl-12 col-md-12 col-12">
              <h2 className="trusted-title">
                {" "}
                Trusted and dependable <br />
                <span className="light">
                  accident management solutions{" "}
                </span>{" "}
              </h2>
            </div>
          </div>
          <div className="row" id="possible">
            <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-12">
              <div className="claim">
                <ul>
                  <li>
                    <span className="sleej">Vehicle recovery </span> <br />
                    When you’re left stranded on the roadside or need immediate
                    assistance to get your vehicle recovered from the accident,
                    contact us at the{" "}
                    <strong>tesco car insurance claim phone number</strong>. Our
                    team will ensure prompt recovery of your vehicle without
                    delay.
                  </li>
                  <li>
                    <span className="sleej">Vehicle repairs </span>
                    <br />
                    Car accidents are traumatic and can cause significant damage
                    to your vehicle, leaving it in an undrivable state. We will
                    arrange quick repairs of your vehicle from your chosen
                    repair garage at no cost to you.
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-12">
              <div className="claim" id="blue">
                <ul>
                  <li>
                    <span className="sleej">Secure storage </span> <br />
                    Don’t have adequate space to store your damaged vehicle? We
                    have you covered. Our vehicle storage services provide safe
                    and secure storage facilities to store your damaged vehicle
                    until repairs or further assessment, preventing it from more
                    deterioration.
                  </li>
                  <li>
                    <span className="sleej">Replacement car </span> <br />
                    After a non-fault car accident, you’re entitled to receive a
                    replacement car. We will provide you with a no-cost
                    replacement car similar to your vehicle in terms of size,
                    make and model to help you perform your daily tasks without
                    any hassle. Contact us at the{" "}
                    <strong>
                      tesco car insurance claims telephone number
                    </strong>{" "}
                    to get a like-for-like replacement vehicle.
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-12">
              <div className="claim">
                <ul>
                  <li>
                    <span className="sleej">Injury claims </span>
                    <br />
                    Want to get compensation for the injuries sustained in the
                    accident? Our experienced solicitors have vast experience in
                    handling no-fault injury claims and can help you get maximum
                    compensation for your injuries.
                  </li>
                  <li>
                    <span className="sleej">Claims management </span> <br />
                    Our expert team offers tailored guidance and helps you
                    manage your <strong>
                      tesco motor insurance claim
                    </strong>{" "}
                    process through completion. Get in touch with us at the{" "}
                    <strong>
                      tesco bank car insurance claim contact number
                    </strong>{" "}
                    to start your claim.
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="yellowcta">
        <div className="container">
          <div className="row">
            <div className="cta2">
              <div className="col-xxl-8 col-xl-8 col-lg-8 col-12">
                <div className="legal">
                  <h3 className="experienced">
                    Got more questions?{" "}
                    <span className="light">Talk to our specialists</span>{" "}
                  </h3>
                  <p className="professionals">
                    Get in touch with our no-fault claim experts via{" "}
                    <strong>
                      car insurance claim phone number
                      <a href="tel:0800 772 0850">0800 772 0850</a>
                    </strong>{" "}
                    to get the best advice for your{" "}
                    <strong>tesco car insurance claims</strong>.
                  </p>
                </div>
              </div>
              <div className="col-xxl-4 col-xl-4 col-lg-4 col-12">
              <div class="process">
                  <a class="tf-button style-1 phone" href="tel:0800 772 0850">
                    Get in touch<span class="icon-keyboard_arrow_right"></span>
                  </a>
                </div>
                <div class="process">
                    <a className="tf-button style-1 desktop"  onClick={handleScrollToSidebar}>
                        Get in touch<span className="icon-keyboard_arrow_right"></span>
                    </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <search className="updatedform">
        <div className="container">
          <div className="row text-center">
            <div className="col-xxl-12 col-xl-12 col-md-12 col-12">
              <h2 className="whiteheading">
                What makes us your ideal choice for{" "}
                <span className="light">accident management needs? </span>
              </h2>
              <p className="sustained">
                We’re your dedicated partners, ready to provide you with a
                helping hand throughout the{" "}
                <strong>tesco motor claims process</strong>. Our reliable team
                and specialised solicitors handle our no-fault claim with utmost
                diligence and professionalism to ensure you get maximum
                compensation.{" "}
              </p>
            </div>
          </div>
          <div className="row">
            <div className="col-xxl-4 col-xl-4 col-lg-6 col-md-12 col-12">
              <div className="Claimant">
                <h5>Round-the-clock support </h5>
                <p className="collision">
                  Our highly trained team works round the clock in the UK to
                  provide emergency support and help you get back on track.
                </p>

                <h5>No upfront costs </h5>
                <p className="collision">
                  With us, there won’t be any upfront cost, like your insurer.
                  So, when you contact us at the{" "}
                  <strong>
                    car insurance claim phone number{" "}
                    <a href="tel:0800 772 0850">0800 772 0850</a>
                  </strong>
                  , you don’t have to pay a single penny from your pocket.
                </p>

                <h5>Transparent approach </h5>
                <p className="collision">
                  We will effectively handle your{" "}
                  <strong>tesco motor insurance claims</strong> process with our
                  transparent, quick and streamlined process.
                </p>
              </div>
            </div>
            <div className="col-xxl-4 col-xl-4 col-lg-6 col-md-12 col-12">
              <div className="Claimant">
                <h5>No win no fee</h5>
                <p className="collision">
                  Our solicitors <strong>work on a no-win no-fee basis</strong>.
                  If your tesco motor claim is unsuccessful, you don’t have to
                  pay any upfront charges for your claim.
                </p>

                <h5>A vast network of trustworthy repairers </h5>
                <p className="collision">
                  Our vast network of repairers across the UK is known for
                  providing the highest quality repairs using genuine parts.
                </p>

                <h5>Prompt and effortless claim process</h5>
                <p className="collision">
                  Our approach to handling your{" "}
                  <strong>tesco motor insurance claims</strong> is simple and
                  easy. With our streamlined process, we ensure your claim gets
                  settled in minimum time.
                </p>
              </div>
            </div>
            <div className="col-xxl-4 col-xl-4 col-lg-12 col-md-12 col-12">
              <div className="Claimant">
                <h5>Comparable replacement </h5>
                <p className="collision">
                  Get in touch with us at the{" "}
                  <strong>tesco bank car insurance claim contact number</strong>{" "}
                  to get a like-for-like replacement vehicle that matches your
                  own vehicle.
                </p>

                <h5>Proficient solicitors </h5>
                <p className="collision">
                  Our solicitors possess extensive experience in handling{" "}
                  <strong>tesco car insurance claims</strong> and can help you
                  get the best possible outcome for your claim.
                </p>

                <h5>End-to-end claims management </h5>
                <p className="collision">
                  From initial documentation to claims resolution, we will
                  handle your <strong>tesco car insurance claim</strong> from
                  start to finish.
                </p>
              </div>
            </div>
          </div>
        </div>
      </search>

      <section className="Accident-claim">
        <div className="container">
          <div className="row">
            <div className="needed">
              <h2 className="trusted-title">
                How you can strengthen your <br />
                <span className="light"> tesco insurance claims</span>
              </h2>
              <p className="complexities">
                Thousands of people in the UK file a claim seeking recovery of
                their losses. However, the amount of compensation you receive
                depends on several factors. Here’s how you can maximise your
                compensation after a no-fault accident.
              </p>
            </div>
            <hr />
          </div>
          <div className="row" id="incdes">
            <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-12">
              <div className="Preparing">
                <ul>
                  <li>
                    <div className="seemingly">
                      <h5 className="Seek">Report accident on time </h5>
                      <p>
                        {" "}
                        The foremost thing to do after a no-fault accident is to
                        report the car accident to the police as soon as
                        possible and provide crucial details related to the
                        location, number of vehicles involved and severity of
                        injuries.{" "}
                      </p>
                    </div>
                  </li>
                  <li>
                    <div className="seemingly">
                      <h5 className="Seek">Seek prompt medical care </h5>
                      <p>
                        Check yourself for any injuries and visit the doctor
                        immediately to get your injuries treated. Any delay in
                        seeking medical care may impact your case.
                      </p>
                    </div>
                  </li>
                  <li>
                    <div className="seemingly">
                      <h5 className="Seek">Collect evidence </h5>
                      <p>
                        You need to prove your innocence to get compensation for
                        your losses. Therefore, you must gather evidence for
                        your case, including photographs and videos of the
                        accident scene, eyewitness statements, and CCTV videos.
                      </p>
                    </div>
                  </li>
                  <li>
                    <div className="seemingly">
                      <h5 className="Seek">Document your losses</h5>
                      <p>
                        Keep an accurate record of all the expenses related to
                        the accident and injury such as bills, statement
                        receipts, and invoices.
                      </p>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
            <div
              className="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-12"
              id="dualcardiv"
            >
              <img src={dualcar} alt="" className="dualcar" />
            </div>
            <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-12">
              <div className="Preparing">
                <ul>
                  <li>
                    <div className="seemingly">
                      <h5 className="Seek">Be patient</h5>
                      <p>
                        The insurance companies may make a quick settlement
                        offer to entice victims to settle your claim. So, be
                        patient and don't accept an early settlement offer
                        because once you accept the offer, you’re not eligible
                        for further compensation.
                      </p>
                    </div>
                  </li>
                  <li>
                    <div className="seemingly">
                      <h5 className="Seek">
                        Exchange information with the other driver{" "}
                      </h5>
                      <p>
                        Contact the other driver involved in the accident and
                        exchange significant information, including name,
                        contact number, address, vehicle details, insurance
                        company names and policy numbers.
                      </p>
                    </div>
                  </li>
                  <li>
                    <div className="seemingly">
                      <h5 className="Seek">Start your claim </h5>
                      <p>
                        When you’ve collected the crucial evidence and want to
                        seek compensation for your losses, contact us at the{" "}
                        <strong>tesco car insurance claim phone number</strong>{" "}
                        to start your claim process.
                      </p>
                    </div>
                  </li>
                  <li></li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="whyus">
        <div className="container">
          <div className="row">
            <div className="col-xxl-12 col-md-12">
              <div className="why">
                <h2 className="choose">
                  Fast and smooth{" "}
                  <span className="light">
                    <br /> claim process{" "}
                  </span>
                </h2>
                <p className="complexities">
                  Making <strong>tesco car insurance claims</strong> with us is
                  simple and straightforward with no complex paperwork or
                  hassle. When you want to start your claim process, you can
                  give us a call at the{" "}
                  <strong>tesco car insurance claim phone number</strong> to
                  speak to our advisors.
                </p>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-xxl-3 col-xl-6 col-lg-6 col-md-6 col-12">
              <div className="box-service">
                <img src={check} className="check" alt="" />
                <h4>Speak to our team</h4>
                <p>
                  To start your claim process with us, call us at our{" "}
                  <strong>
                    car insurance claims telephone number{" "}
                    <a href="tel:0800 772 0850">0800 772 0850</a>
                  </strong>{" "}
                  to get in touch with our advisors.
                </p>
              </div>
            </div>
            <div className="col-xxl-3 col-xl-6 col-lg-6 col-md-6 col-12">
              <div className="box-service">
                <img src={check} className="check" alt="" />
                <h4>Share accident details </h4>
                <p>
                  While initiating your claim with us, provide us with detailed
                  information about your accident, including the date, time,
                  location and other vital information.
                </p>
              </div>
            </div>
            <div className="col-xxl-3 col-xl-6 col-lg-6 col-md-6 col-12">
              <div className="box-service">
                <img src={check} className="check" alt="" />
                <h4>Provide evidence</h4>
                <p>
                  For successful claim processing, we might require vital
                  evidence related to the accident, including photographs of the
                  accident scene, a copy of the police report, witness
                  statements or any other information.
                </p>
              </div>
            </div>
            <div className="col-xxl-3 col-xl-6 col-lg-6 col-md-6 col-12">
              <div className="box-service">
                <img src={check} className="check" alt="" />
                <h4>Follow up </h4>
                <p>
                  We will initiate your claim once we have the required
                  documents. Stay in touch with us for regular updates on your{" "}
                  <strong>tesco insurance claim</strong>.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="faqs">
        <div className="container">
          <div className="row">
            <div className="col-xxl-12 col-xl-12 col-md-12 col-12">
              <div className="fq">
                <h2>
                  {" "}
                  Frequently Asked{" "}
                  <span className="light">Questions (FAQ)</span>
                </h2>
                <p className="complexities">
                  Got questions about the tesco motor insurance claims? We have
                  them answered here.
                </p>
              </div>
              <div className="allfaq">
                <Accordion defaultActiveKey="0">
                  <Accordion.Item eventKey="0">
                    <Accordion.Header>
                      Who will pay for the cost of the replacement vehicle that
                      you provide?
                    </Accordion.Header>
                    <Accordion.Body>
                      We will recover the costs of the replacement vehicle and
                      other expenses from the at-fault party’s insurance
                      company.
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="1">
                    <Accordion.Header>
                      Will using your car accident management services affect my
                      insurance premium?
                    </Accordion.Header>
                    <Accordion.Body>
                      No. Using our car accident management services won’t
                      affect your insurance premium as we won’t initiate a claim
                      against your own insurance policy.
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="2">
                    <Accordion.Header>
                      How long can I keep the replacement vehicle provided by
                      you?
                    </Accordion.Header>
                    <Accordion.Body>
                      If you’ve been a victim of a no-fault car accident, you’re
                      entitled to a replacement vehicle. You can keep the
                      replacement vehicle for the duration of your vehicle
                      repairs. In case, your car is fully written off, you can
                      keep the replacement vehicle until you receive
                      compensation for your vehicle.
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="3">
                    <Accordion.Header>
                      What expenses can I claim for my car accident?
                    </Accordion.Header>
                    <Accordion.Body>
                      If you’ve been a victim of a no-fault car accident, you
                      can claim compensation for vehicle repairs, medical
                      expenses, loss of income, rental car expenses, pain and
                      suffering or any other damage that you’ve suffered as a
                      result of the accident.
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="4">
                    <Accordion.Header>
                      Can I choose my own repairer?
                    </Accordion.Header>
                    <Accordion.Body>
                      Yes, you can choose your own repairer to fix your damaged
                      car. If you wish to get your vehicle repaired from the
                      garage of your choice, you can share the details with us.
                      We will coordinate with your chosen repairer for smooth
                      and fast repairs of your vehicle.
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="5">
                    <Accordion.Header>
                      Will my no-claims bonus be affected if I make a tesco
                      motor claim?
                    </Accordion.Header>
                    <Accordion.Body>
                      No. When you make your claim with us, your no-claims bonus
                      will stay intact.
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="6">
                    <Accordion.Header>
                      How long will it take for my vehicle recovery assistance
                      to arrive?
                    </Accordion.Header>
                    <Accordion.Body>
                      Our team is available round the clock to offer immediate
                      assistance. When you contact us at the{" "}
                      <strong>tesco car insurance claim phone number</strong>,
                      we will dispatch our recovery team as soon as possible.
                    </Accordion.Body>
                  </Accordion.Item>
                </Accordion>
              </div>
            </div>
          </div>
        </div>
      </section>

      <Footer />
    </>
  );
};

export default Tescocarinsurance;
