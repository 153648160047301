import React, { useRef } from "react";
import insurancelogo from "../../src/assets/images/insurancelogo.png";
import Accordion from "react-bootstrap/Accordion";
import check from "../assets/images/check.png";
import dualcar from "../../src/assets/images/dualcar.webp";
import bner from "../../src/assets/images/bner.webp";
import Sidebarinner from "../components/Sidebar-inner";
import phonecall from "../assets/images/phonecall.png";
import Footer from "../components/footer";
import { Helmet } from "react-helmet";

const Johnlewisfinance = () => {
  const sidebarRef = useRef(null);

  const handleScrollToSidebar = () => {
    if (sidebarRef.current) {
      sidebarRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>John Lewis Car Insurance Accident Claim Number</title>
        <meta
          name="description"
          content="Call our dedicated accident claims team on the John Lewis Car Insurance Accident Claim Number 0800 772 0850 for speedy, professional and reliable claims assistance."
        />
        <link
          rel="canonical"
          href="https://insurance-accident-claims.co.uk/john-lewis-finance"
        />
      </Helmet>
      <section className="innernav">
        <div className="container">
          <div className="row">
            <div className="col-md-6 col-sm-6">
              <div className="left-logo">
                {/* <img src={insurancelogo} className='insurancelogo' alt='Insurance Logo' /> */}
                <h3>Accident Claims</h3>
              </div>
            </div>
            <div className="col-md-6 col-sm-6">
              <div className="left-cta">
                <div class="headercnt">
                  <div class="chatmsg">
                    <img
                      src={phonecall}
                      className="phonecall"
                      alt="phonecall "
                    />
                  </div>
                  <div class="box-body">
                    <h3 class="nav-info-box-title">Toll Free Number </h3>
                    <p>
                      <a href="tel:0800 772 0850">0800 772 0850</a>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section
        className="newbnr"
        style={{ backgroundImage: "url(" + bner + ")" }}
      >
        <div className="container">
          <div className="row">
            <div className="col-xxl-6 col-xl-6 col-md-10 col-12">
              <div className="innerbnr">
                <h1>
                  <span className="onespan">Stress-Free Accident</span> <br />
                  <span className="sci">Management & No-Fault</span> Claims
                  Assistance
                </h1>
                <p className="brokerage">
                  From vehicle recovery to claims management, we specialise in
                  handling every aspect of no-fault car accident claims.
                </p>

                <div className="bnrbtn">
                  <a class="db-btn-border btn-rollover phone" href="tel:0800 772 0850">
                    Start your claim{" "}
                    <i class="fa-solid fa-arrow-right"></i>
                  </a>
                </div>
                <div className="bnrbtn">
                <a className="db-btn-border btn-rollover desktop"  onClick={handleScrollToSidebar}>
                  Start your claim <i className="fa-solid fa-arrow-right"></i>
                </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="stickysection">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="group-4-8">
                <div className="cl8 tf-tab">
                  <div className="content-tab">
                    <div className="inner-content">
                      <h2>
                        End-to-end accident management support
                        <span className="light">
                          without any upfront charges{" "}
                        </span>
                      </h2>
                      <p className="disciplines">
                        We understand how road traffic accidents can disrupt
                        your life. That's why we offer comprehensive accident
                        management and{" "}
                        <strong>
                          John Lewis car insurance claim assistance
                        </strong>{" "}
                        to help you get back on the road safely and quickly
                        after a no-fault car accident. Call us on the{" "}
                        <strong>
                          car insurance claim number{" "}
                          <a href="tel:0800 772 0850">0800 772 0850</a>{" "}
                        </strong>
                        for emergency help and claims guidance.
                      </p>
                      <hr />
                      <p className="bold-text">No excess on the policy </p>
                      <p className="bottom-te">
                        Instead of making a claim against your own insurance
                        policy, we make a{" "}
                        <strong>John Lewis insurance claim</strong> directly
                        against the other driver’s insurance, helping you avoid
                        the financial risk of paying the excess on your
                        insurance policy.
                      </p>
                      <hr />
                      <p className="bold-text">Protected no-claims bonus </p>
                      <p className="bottom-te">
                        By making a{" "}
                        <strong>John Lewis compensation claim</strong> directly
                        against the at-fault party, we ensure your own insurance
                        policy does not get affected and your no-claims bonus
                        stays intact.
                      </p>
                      <hr />
                      <p className="bold-text">Dedicated claims handler </p>
                      <p className="bottom-te">
                        You can get in touch with us via the{" "}
                        <strong>John Lewis car insurance claim number</strong>{" "}
                        for immediate assistance. We’ll appoint a dedicated
                        claims handler to manage your{" "}
                        <strong>John Lewis insurance accident claim</strong>,
                        avoiding multiple departments and long waiting times.
                      </p>
                      <hr />
                      <p className="bold-text">Quality repairs </p>
                      <p className="bottom-te">
                        Our repair network comprises manufacturer-approved
                        repairers who specialise in providing high-quality
                        repairs using original parts and paint.
                      </p>
                      <hr />
                      <p className="bold-text">Like-for-like replacement </p>
                      <p className="bottom-te">
                        Call us at{" "}
                        <strong>John Lewis car accident claim number</strong> to
                        get a replacement vehicle after a car accident. We will
                        arrange a like-for-like replacement vehicle so that you
                        can continue with your routine activities.
                      </p>
                      <hr />
                      <p className="bold-text">Quick response </p>
                      <p className="bottom-te">
                        When you contact us on the{" "}
                        <strong>
                          John Lewis insurance accident claim number
                        </strong>
                        , you don’t have to wait for long hours to receive help.
                        Our dedicated team is available round the clock to
                        provide you with emergency support when you need it the
                        most.
                      </p>
                      <hr />
                      <p className="bold-text">Rapid claims settlement </p>
                      <p className="bottom-te">
                        Our straightforward and hassle-free claims process
                        ensures rapid settlement of your{" "}
                        <strong>John Lewis car insurance claim</strong>, helping
                        you get the rightful compensation for your losses and
                        injuries.
                      </p>
                      <hr />
                      <p className="bold-text">Best claims advice </p>
                      <p className="bottom-te">
                        Our experienced panel of solicitors have a proven track
                        record of handling thousands of{" "}
                        <strong>John Lewis insurance claims</strong>. So, you’re
                        guaranteed to receive the best advice and support every
                        step of the way.
                      </p>
                      <hr />
                      <p className="bold-text">24/7 claims assistance </p>
                      <p className="bottom-te">
                        Our team is always on hand to offer assistance and
                        guidance regarding your{" "}
                        <strong>John Lewis insurance claim</strong>. Call us on
                        the{" "}
                        <strong>
                          car accident claim number{" "}
                          <a href="tel:0800 772 0850">0800 772 0850</a>
                        </strong>{" "}
                        to get the best compensation for your claim.
                      </p>
                      <hr />
                      <p className="bold-text">No obligation advice </p>
                      <p className="bottom-te">
                        After the no-fault accident, talk to our solicitors via
                        the{" "}
                        <strong>John Lewis car accident claim number</strong> to
                        get free no-obligation advice related to your claim.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="cl4" ref={sidebarRef}>
                  <Sidebarinner />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="photographs">
        <div className="container">
          <div className="row">
            <h2 className="road">
              We make your post-accident journey <br />
              <span className="light"> smoother and hassle-free</span>{" "}
            </h2>
            <div className="col-xxl-6 col-xl-6 col-md-12 col-12">
              <div className="fourbox">
                <ul>
                  <li>
                    <img src={check} className="check" alt="" />
                    If you've been unfortunate to have been a victim of a
                    no-fault car accident, we will help you recover your losses
                    and damages from the at-fault party.
                  </li>
                  <hr />
                  <li>
                    <img src={check} className="check" alt="" />
                    When you get in touch with us via the{" "}
                    <strong>John Lewis car insurance claim number</strong>, we
                    will offer you the best guidance and support to help you
                    claim compensation.
                  </li>
                  <hr />
                  <li>
                    <img src={check} className="check" alt="" />
                    If you choose us for your accident management needs, we will
                    help you hit the road again by organising immediate
                    replacement vehicles and arranging vehicle repairs.
                  </li>
                  <hr />
                  <li>
                    <img src={check} className="check" alt="" />
                    We will liaise with all the parties on your behalf to ensure
                    your <strong>John Lewis compensation claims</strong> get
                    settled as soon as possible.
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-xxl-6 col-xl-6 col-md-12 col-12">
              <div className="fourbox">
                <ul>
                  <li>
                    <img src={check} className="check" alt="" />
                    We will coordinate with trusted repairers to schedule timely
                    and professional repairs of your vehicle.
                  </li>
                  <hr />
                  <li>
                    <img src={check} className="check" alt="" />
                    From handling paperwork to managing documentation, our
                    solicitors will help you at every stage of your{" "}
                    <strong>John Lewis insurance accident claim</strong>{" "}
                    process.
                  </li>
                  <hr />
                  <li>
                    <img src={check} className="check" alt="" />
                    We will assign you a dedicated claims handler who will be a
                    single point of contact throughout the{" "}
                    <strong>John Lewis car insurance claim</strong> process.
                  </li>
                  <hr />
                  <li>
                    <img src={check} className="check" alt="" />
                    We will determine the extent of the damages you’ve suffered
                    and negotiate with the other party’s insurer to help you
                    secure the best settlement.
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="carprocess">
        <div className="container ">
          <div className="row">
            <div className="col-xxl-12 col-xxl-12 col-md-12 col-12">
              <h2 className="trusted-title">
                Our vast spectrum <br />
                <span className="light">of services </span>
              </h2>
            </div>
          </div>
          <div className="row" id="possible">
            <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-12">
              <div className="claim">
                <ul>
                  <li>
                    <span className="sleej">Vehicle recovery</span> <br />
                    If your vehicle is declared unroadworthy, we will
                    immediately make arrangements to get it recovered from the
                    accident scene and transported to a repair centre or our
                    secured storage facilities.
                  </li>
                  <li>
                    <span className="sleej">Approved repairs </span>
                    <br />
                    Once your vehicle is assessed by an independent engineer for
                    repairs, we will schedule the repairs from our trusted
                    network of manufacturer-approved repairers.
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-12">
              <div className="claim" id="blue">
                <ul>
                  <li>
                    <span className="sleej">Secure storage</span> <br />
                    While your vehicle is awaiting repairs or claim assessment,
                    we will store it in our safe storage facility. Thus, you can
                    have peace of mind knowing that your vehicle is safe from
                    further damage and deterioration. Contact us on the{" "}
                    <strong>John Lewis car accident claim number</strong> for
                    quick recovery and storage of your vehicle.
                  </li>
                  <li>
                    <span className="sleej">Replacement vehicle </span> <br />
                    In case your vehicle is in an undrivable state, you can
                    reach out to us via the{" "}
                    <strong>John Lewis Insurance accident claim number</strong>.
                    Our team will organise a like-for-like replacement vehicle
                    to help you hit the road again and continue with your daily
                    tasks without interruption.
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-12">
              <div className="claim">
                <ul>
                  <li>
                    <span className="sleej"> Injury claims </span>
                    <br />
                    Have you sustained any type of injury due to the car
                    accident? Talk to our solicitors via{" "}
                    <strong>John Lewis car insurance claim number</strong> to
                    receive professional advice on claiming compensation for
                    your injuries and losses.
                  </li>
                  <li>
                    <span className="sleej">Claims management </span> <br />
                    Our dedicated claims handler will help you with every aspect
                    of your <strong>John Lewis car insurance claim</strong>,
                    right from claim initiation to settlement.
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="yellowcta">
        <div className="container">
          <div className="row">
            <div className="cta2">
              <div className="col-xxl-8 col-xl-8 col-lg-8 col-12">
                <div className="legal">
                  <h3 className="experienced">
                    Got more questions?{" "}
                    <span className="light">Talk to our specialists </span>
                  </h3>
                  <p className="professionals">
                    Get in touch with our no-fault claim experts via{" "}
                    <strong>
                      car insurance claim number{" "}
                      <a href="tel:0800 772 0850">0800 772 0850</a>
                    </strong>{" "}
                    to get the best advice regarding the{" "}
                    <strong>John Lewis insurance accident claim</strong>.
                  </p>
                </div>
              </div>
              <div className="col-xxl-4 col-xl-4 col-lg-4 col-12">
              <div class="process">
                  <a class="tf-button style-1 phone" href="tel:0800 772 0850">
                    Get in touch<span class="icon-keyboard_arrow_right"></span>
                  </a>
                </div>
                <div class="process">
                    <a className="tf-button style-1 desktop"  onClick={handleScrollToSidebar}>
                        Get in touch<span className="icon-keyboard_arrow_right"></span>
                    </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <search className="updatedform">
        <div className="container">
          <div className="row text-center">
            <div className="col-xxl-12 col-xl-12 col-md-12 col-12">
              <h2 className="whiteheading">
                What makes us the one-stop shop for the best
                <span className="light">accident management solutions?</span>
              </h2>
              <p className="sustained">
                We’re your trusted partners assisting you get back on the road
                to recovery after a no-fault car accident. Our dedicated claims
                support and wide range of accident management solutions can save
                you from the stress of dealing with the{" "}
                <strong>John Lewis insurance claim</strong> on your own.
              </p>
            </div>
          </div>
          <div className="row">
            <div className="col-xxl-4 col-xl-4 col-lg-6 col-md-12 col-12">
              <div className="Claimant">
                <h5>24/7 availability </h5>
                <p className="collision">
                  Our reliable and friendly team operates round the clock to
                  provide timely support after the accident without any delay.
                </p>
                <h5>High-quality repairs </h5>
                <p className="collision">
                  Our vast network of repairers in the UK will restore your
                  vehicle to pre-accident condition using genuine vehicle parts
                  and paint.
                </p>
                <h5>No cost to you </h5>
                <p className="collision">
                  Call us on{" "}
                  <strong>John Lewis Insurance accident claim number</strong>{" "}
                  for end-to-end accident management assistance without any
                  excess. We will recover all the costs from the at-fault
                  party’s insurer.
                </p>
              </div>
            </div>
            <div className="col-xxl-4 col-xl-4 col-lg-6 col-md-12 col-12">
              <div className="Claimant">
                <h5>Full-fledged support </h5>
                <p className="collision">
                  From vehicle recovery to claims settlement, we offer a
                  comprehensive range of services to help you get on track
                  again.
                </p>
                <h5>No win no fee </h5>
                <p className="collision">
                  In case your claim is not successful, our solicitors won’t
                  charge any legal fees for their services.
                </p>
                <h5>Personalised support and guidance </h5>
                <p className="collision">
                  Our solicitors will diligently handle your{" "}
                  <strong>John Lewis compensation claims</strong> and provide
                  personalised support to ensure the best outcome for your case.
                </p>
              </div>
            </div>
            <div className="col-xxl-4 col-xl-4 col-lg-12 col-md-12 col-12">
              <div className="Claimant">
                <h5>Quick vehicle recovery </h5>
                <p className="collision">
                  With our dedicated team available round the clock, you can
                  trust us for prompt recovery of your vehicle from the accident
                  scene.
                </p>
                <h5>Expedited claims process </h5>
                <p className="collision">
                  We ensure speedy settlement of your{" "}
                  <strong>John Lewis Insurance accident claim</strong> through
                  our streamlined procedures and responsive communication at
                  every step.
                </p>
                <h5>No false promises </h5>
                <p className="collision">
                  We won’t make any unrealistic promises. Instead, we will
                  openly communicate with you throughout the{" "}
                  <strong>John Lewis insurance claim</strong> process and keep
                  you informed about the progress of your claim.
                </p>
              </div>
            </div>
          </div>
        </div>
      </search>

      <section className="Accident-claim">
        <div className="container">
          <div className="row">
            <div className="needed">
              <h2 className="trusted-title">
                How can you maximise your
                <br />
                <span className="light">
                  John Lewis compensation claims payout?{" "}
                </span>
              </h2>
              <p className="complexities">
                Wondering how you can increase the value of your{" "}
                <strong>John Lewis insurance accident claim?</strong> Knowing
                the right steps after a no-fault accident can make a significant
                difference in what you get as compensation for your claim. Here
                are a few things you must do following a car accident to
                maximise the value of your case.
              </p>
            </div>
            <hr />
          </div>
          <div className="row" id="incdes">
            <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-12">
              <div className="Preparing">
                <ul>
                  <li>
                    <div className="seemingly">
                      <h5 className="Seek">Check injuries </h5>
                      <p>
                        Stop your vehicle and check yourself and your passengers
                        for any injuries. In case anyone has suffered injuries,
                        immediately seek medical attention.
                      </p>
                    </div>
                  </li>
                  <li>
                    <div className="seemingly">
                      <h5 className="Seek">Collect evidence </h5>
                      <p>
                        The more evidence you have, the greater the chances of
                        securing maximum compensation. So, make sure to collect
                        enough evidence to support your claim.
                      </p>
                    </div>
                  </li>
                  <li>
                    <div className="seemingly">
                      <h5 className="Seek">Document all expenses</h5>
                      <p>
                        Record all the damages related to the accident and
                        injuries, including medical bills, lost income, property
                        damages, transportation expenses or any other costs
                        you’ve incurred.
                      </p>
                    </div>
                  </li>
                  <li>
                    <div className="seemingly">
                      <h5 className="Seek">Never accept the first offer </h5>
                      <p>
                        The first settlement offer does not cover all the
                        damages and may not provide you with deserving
                        compensation. So, never agree to the first settlement
                        offer given by the insurance companies.
                      </p>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
            <div
              className="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-12"
              id="dualcardiv"
            >
              <img src={dualcar} alt="" className="dualcar" />
            </div>
            <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-12">
              <div className="Preparing">
                <ul>
                  <li>
                    <div className="seemingly">
                      <h5 className="Seek">Avoid using social media </h5>
                      <p>
                        Refrain from posting anything on social media until your{" "}
                        <strong>John Lewis car insurance claim</strong> gets
                        settled. The other party solicitors and insurance
                        companies may use your social media activity against
                        you.
                      </p>
                    </div>
                  </li>
                  <li>
                    <div className="seemingly">
                      <h5 className="Seek">Contact eyewitnesses</h5>
                      <p>
                        Get in touch with eyewitnesses of the accident and
                        record their statements. It can help you gather crucial
                        information and evidence to support your{" "}
                        <strong>John Lewis insurance claim</strong>.
                      </p>
                    </div>
                  </li>
                  <li>
                    <div className="seemingly">
                      <h5 className="Seek">
                        Speak to an experienced solicitor
                      </h5>
                      <p>
                        Talk to an experienced no-fault claim solicitor to know
                        your rights and options after a no-fault car accident.
                        Call us on the{" "}
                        <strong>John Lewis car insurance claim number</strong>,
                        and we’ll connect you with an experienced solicitor in
                        the UK.
                      </p>
                    </div>
                  </li>
                  <li>
                    <div className="seemingly">
                      <h5 className="Seek">Start your claim </h5>
                      <p>
                        Once you’ve decided to file a claim against the other
                        party, reach out to our team via the{" "}
                        <strong>John Lewis car accident claim number</strong>.
                        We will initiate the{" "}
                        <strong>John Lewis compensation claims</strong> process
                        to help you get fair compensation.
                      </p>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="whyus">
        <div className="container">
          <div className="row">
            <div className="col-xxl-12 col-md-12">
              <div className="why">
                <h2 className="choose">
                  Initiate your John Lewis Insurance Accident Claim in
                  <span className="light">
                    <br /> 4 simple steps
                  </span>
                </h2>
                <p className="complexities">
                  Looking forward to making a no-fault claim against the other
                  party? Look no further. Get in touch with us through{" "}
                  <strong>John Lewis insurance accident claim number</strong> to
                  make your claim effortlessly in just four steps.
                </p>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-xxl-3 col-xl-6 col-lg-6 col-md-6 col-12">
              <div className="box-service">
                <img src={check} className="check" alt="" />
                <h4>Contact us</h4>
                <p>
                  Call us on the{" "}
                  <strong>
                    car insurance claim number{" "}
                    <a href="tel:0800 772 0850">0800 772 0850</a>
                  </strong>{" "}
                  to get emergency assistance after a no-fault car accident.
                </p>
              </div>
            </div>
            <div className="col-xxl-3 col-xl-6 col-lg-6 col-md-6 col-12">
              <div className="box-service">
                <img src={check} className="check" alt="" />
                <h4>Discuss accident details </h4>
                <p>
                  Share the accident details, including the date, time, location
                  and reason behind the accident.
                </p>
              </div>
            </div>
            <div className="col-xxl-3 col-xl-6 col-lg-6 col-md-6 col-12">
              <div className="box-service">
                <img src={check} className="check" alt="" />
                <h4>Provide evidence</h4>
                <p>
                  Submit any crucial evidence such as photographs and videos
                  you’ve gathered from the accident scene.
                </p>
              </div>
            </div>
            <div className="col-xxl-3 col-xl-6 col-lg-6 col-md-6 col-12">
              <div className="box-service">
                <img src={check} className="check" alt="" />
                <h4>Claim initiation </h4>
                <p>
                  Once you’ve provided us with the evidence and documents
                  related to the accident, we will initiate your claim and
                  update you about the progress.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="faqs">
        <div className="container">
          <div className="row">
            <div className="col-xxl-12 col-xl-12 col-md-12 col-12">
              <div className="fq">
                <h2>
                  {" "}
                  Frequently Asked{" "}
                  <span className="light">Questions (FAQ)</span>
                </h2>
                <p className="complexities">
                  Got questions about the{" "}
                  <strong>John Lewis compensation claims?</strong> We have them
                  answered here.{" "}
                </p>
              </div>
              <div className="allfaq">
                <Accordion defaultActiveKey="0">
                  <Accordion.Item eventKey="0">
                    <Accordion.Header>
                      Am I entitled to a replacement vehicle after a no-fault
                      car accident?
                    </Accordion.Header>
                    <Accordion.Body>
                      Yes. We will provide you with a like-for-like replacement
                      vehicle after a no-fault car accident so that you can
                      continue with your routine activities after a no-fault car
                      accident.
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="1">
                    <Accordion.Header>
                      Do I have to pay any excess for the accident management
                      services?
                    </Accordion.Header>
                    <Accordion.Body>
                      No. We will recover all the expenses from the at-fault
                      party’s insurer. So you don’t have to pay any excess.
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="2">
                    <Accordion.Header>
                      Can you schedule my vehicle repairs with my preferred
                      repairer?
                    </Accordion.Header>
                    <Accordion.Body>
                      Yes. You can let us know the details of your repairer, and
                      we will schedule the repairs of your damaged vehicle for
                      your preferred repair centre in the UK.
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="3">
                    <Accordion.Header>
                      What type of replacement vehicle will I be provided after
                      the no-fault accident?
                    </Accordion.Header>
                    <Accordion.Body>
                      We will provide you with a like-for-like replacement
                      vehicle similar to your own in terms of make, size and
                      model.
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="4">
                    <Accordion.Header>
                      How long can I keep the replacement vehicle?
                    </Accordion.Header>
                    <Accordion.Body>
                      You can keep the replacement vehicle until your vehicle is
                      repaired. In case your vehicle is written off, you can
                      keep the replacement vehicle until you get compensation
                      amounting to the full value of your vehicle.
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="5">
                    <Accordion.Header>
                      What should I do if the other party contacts me for an
                      out-of-court settlement?
                    </Accordion.Header>
                    <Accordion.Body>
                      Never accept an early settlement offer from the other
                      party's insurer. Instead, discuss the offer with your
                      solicitor because the initial settlement offer may not
                      provide fair compensation for your damages.
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="6">
                    <Accordion.Header>
                      Will my no-claim bonus be affected if I make a no-fault
                      car accident claim?
                    </Accordion.Header>
                    <Accordion.Body>
                      No. Since we won’t initiate a claim against your own
                      insurance policy, your no-claims bonus will stay
                      preserved.
                    </Accordion.Body>
                  </Accordion.Item>
                </Accordion>
              </div>
            </div>
          </div>
        </div>
      </section>

      <Footer />
    </>
  );
};

export default Johnlewisfinance;
