import React, { useRef } from "react";
import Accordion from "react-bootstrap/Accordion";
import check from "../assets/images/check.png";
import dualcar from "../../src/assets/images/dualcar.webp";
import carbnr1 from "../../src/assets/images/carbnr1.webp";
import Sidebarinner from "../components/Sidebar-inner";
import phonecall from "../assets/images/phonecall.png";
import Footer from "../components/footer";
import { Helmet } from "react-helmet";

const Quotelinedirect = () => {
  const sidebarRef = useRef(null);

  const handleScrollToSidebar = () => {
    if (sidebarRef.current) {
      sidebarRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Quoteline Direct Car Insurance Accident Claim Phone Number:</title>
        <meta
          name="description"
          content="Call us on the Quoteline Direct Car Insurance accident claim phone number 0800 772 0850 for quick and dedicated assistance with your claim. Contact us today. "
        />
        <link
          rel="canonical"
          href="https://insurance-accident-claims.co.uk/quoteline-direct"
        />
      </Helmet>
      <section className="innernav">
        <div className="container">
          <div className="row">
            <div className="col-md-6 col-sm-6">
              <div className="left-logo">
                <h3>
                  Accident<span className="sipo"> Claims</span>
                </h3>
              </div>
            </div>
            <div className="col-md-6 col-sm-6">
              <div className="left-cta">
                <div class="headercnt">
                  <div class="chatmsg">
                    <img
                      src={phonecall}
                      className="phonecall"
                      alt="phonecall "
                    />
                  </div>
                  <div class="box-body">
                    <h3 class="nav-info-box-title">Toll Free Number </h3>
                    <p>
                      <a href="tel:0800 772 0850">0800 772 0850</a>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section
        className="newbnr"
        style={{ backgroundImage: "url(" + carbnr1 + ")" }}
      >
        <div className="container">
          <div className="row">
            <div className="col-xxl-8 col-xl-8 col-md-10 col-12">
              <div className="innerbnr">
                <h1>
                  <span className="onespan">No-fault</span>{" "}
                  <span className="sci">Accident insurance claims </span> Support
                </h1>
                <p className="brokerage">
                  We are going to take control of every single detail involved
                  in your accident from car recovery through claim management to
                  anything else you might be thinking about.
                </p>
                <div className="bnrbtn">
                  <a class="db-btn-border btn-rollover phone" href="tel:0800 772 0850">
                    Road Accident Claim Help?{" "}
                    <i class="fa-solid fa-arrow-right"></i>
                  </a>
                </div>
                <div className="bnrbtn">
                <a className="db-btn-border btn-rollover desktop"  onClick={handleScrollToSidebar}>
                    Road Accident Claim Help? <i className="fa-solid fa-arrow-right"></i>
                </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="stickysection">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="group-4-8">
                <div className="cl8 tf-tab">
                  <div className="content-tab">
                    <div className="inner-content">
                      <h2>
                        Expert claims assistance
                        <br />
                        <span className="light">without excess </span>{" "}
                      </h2>
                      <p className="disciplines">
                        At the core, we provide accident management services for
                        those who undergo non-fault car accidents, ensuring a
                        seamless transition and easy recovery process with
                        minimum inconvenience. Our team will manage the accident
                        from start to finish, covering everything from vehicle
                        recovery to claims management and making it easier for
                        you to get back on the road. Dial the{" "}
                        <strong>
                          {" "}
                          Quoteline Direct car insurance claim phone number
                        </strong>{" "}
                        now and begin your journey with us.
                      </p>
                      <hr />
                      <p className="bold-text">No insurance excess </p>
                      <p className="bottom-te">
                        Our charges do not include any money as insurance excess
                        because, unlike others, we deal directly with
                        responsible parties.{" "}
                      </p>
                      <hr />
                      <p className="bold-text">Protected no-claims bonus </p>
                      <p className="bottom-te">
                        Your no-claims bonus will be unaffected if you decide to
                        make a claim through us as we won’t make a claim against
                        your own policy.
                      </p>
                      <hr />
                      <p className="bold-text">
                        Manufacturer-approved accident repair centre
                      </p>
                      <p className="bottom-te">
                        To ensure your vehicle is repaired using genuine parts
                        and paint according to stringent manufacturer
                        guidelines, we work closely with approved repairers.{" "}
                      </p>
                      <hr />
                      <p className="bold-text">Comparable replacement </p>
                      <p className="bottom-te">
                        We provide our customers with premium services that
                        would give you a similar vehicle of the same style, size
                        and quality as your car.{" "}
                      </p>
                      <hr />
                      <p className="bold-text">Fast claims process</p>
                      <p className="bottom-te">
                        A no-fault claim can be quite confusing. However, when
                        phoning us on{" "}
                        <strong>
                          {" "}
                          Quoteline Direct’s accident claim number{" "}
                        </strong>
                        for a car accident claim, we will speed up the claims
                        process and ensure it gets settled as quickly as
                        possible.
                      </p>
                      <hr />
                      <p className="bold-text">Advice without obligation</p>
                      <p className="bottom-te">
                        Call our <strong> Quoteline Direct claims line</strong>{" "}
                        for advice completely free of charge, which does not
                        bind you in any way to seek compensation for the
                        injuries suffered or any other losses you may have
                        incurred.
                      </p>
                      <hr />
                      <p className="bold-text">Dedicated claims handler</p>
                      <p className="bottom-te">
                        When making your claim via{" "}
                        <strong>
                          {" "}
                          Quoteline Direct car accident claim phone number,{" "}
                        </strong>
                        you shall receive a specific claims handler highly
                        skilled in handling various types of claims.
                      </p>
                      <hr />
                      <p className="bold-text">Immediate response</p>
                      <p className="bottom-te">
                        The amount of tension attached to a vehicle accident is
                        well known by us. Therefore, after an accident, we’re
                        always on hand to offer immediate assistance and answer
                        your concerns.{" "}
                      </p>
                      <hr />
                      <p className="bold-text">Total support</p>
                      <p className="bottom-te">
                        Our assistance covers everything from where your car is
                        kept during this period until it is fixed or another car
                        has been issued to drive while yours is being fixed plus
                        all through your claim.{" "}
                      </p>
                      <hr />

                      <p className="bold-text">Post-repairs inspaction</p>
                      <p className="bottom-te">
                        We will inspect your vehicle after repairing it to make
                        sure that it was done with the highest quality and
                        safety standards.{" "}
                      </p>
                    </div>
                  </div>
                </div>
                <div className="cl4" ref={sidebarRef}>
                  <Sidebarinner />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="photographs">
        <div className="container">
          <div className="row">
            <h2 className="road">
              We make your claims journey less{" "}
              <span className="light">
                stressful <br />
                and more convenient.
              </span>{" "}
            </h2>
            <div className="col-xxl-6 col-xl-6 col-md-12 col-12">
              <div className="fourbox">
                <ul>
                  <li>
                    <img src={check} className="check" alt="" />
                    Just call us on{" "}
                    <strong> Quoteline Direct accident claim number </strong> in
                    case of a non-fault car accident. We handle accident
                    management needs professionally.
                  </li>
                  <hr />
                  <li>
                    <img src={check} className="check" alt="" />
                    This will also ensure the quick recovery of your damaged
                    vehicle after an accident and alleviate all inconveniences
                    and worries related to its recovery.
                  </li>
                  <hr />
                  <li>
                    <img src={check} className="check" alt="" />
                    Your vehicle shall be assigned an engineer, who will examine
                    it as well as determine the extent of damage that occurred.
                  </li>
                  <hr />
                  <li>
                    <img src={check} className="check" alt="" />
                    We shall sort out repairs for you at a garage shop of your
                    preference if possible after assessing the extent of the
                    damage.
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-xxl-6 col-xl-6 col-md-12 col-12">
              <div className="fourbox">
                <ul>
                  <li>
                    <img src={check} className="check" alt="" />
                    Get in touch with us now via the{" "}
                    <strong> Quoteline Direct claims line, </strong>and we will
                    get you another like-for-like replacement car within no time
                    so that you can return to normal life once again.
                  </li>
                  <hr />
                  <li>
                    <img src={check} className="check" alt="" />
                    All the negotiations will be done by our solicitors on your
                    behalf with the other party’s insurer as well as other
                    parties involved in this matter.
                  </li>
                  <hr />
                  <li>
                    <img src={check} className="check" alt="" />
                    The progress regarding the repair of your vehicle would be
                    consistently monitored while regularly updating you about
                    such developments.
                  </li>
                  <hr />
                  <li>
                    <img src={check} className="check" alt="" />
                    Recovering from the third party’s insurance company all
                    expenses you have incurred due to the car accident is what
                    our solicitors do best.
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="carprocess">
        <div className="container ">
          <div className="row">
            <div className="col-xxl-12 col-xxl-12 col-md-12 col-12">
              <h2 className="trusted-title">
                {" "}
                Our broad range of accident <br />
                <span className="light"> management services </span>{" "}
              </h2>
            </div>
          </div>
          <div className="row" id="possible">
            <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-12">
              <div className="claim">
                <ul>
                  <li>
                    <span className="sleej">Roadside recovery:</span>If your car
                    is not drivable, contact us on the{" "}
                    <strong> Quoteline Direct insurance claim number </strong>to
                    have it professionally recovered. We will organise for quick
                    and efficient vehicle recovery, saving your car from being
                    stranded on the roadside and getting it transported to a
                    safe place.
                  </li>
                  <li>
                    <span className="sleej">Approved repairers:</span>In case
                    your vehicle is damaged at an accident scene call us using
                    the{" "}
                    <strong>
                      {" "}
                      Quoteline Direct car accident claim phone number.{" "}
                    </strong>{" "}
                    We will book you in for quality guaranteed repairs by one of
                    our manufacturer-approved repair shops.
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-12">
              <div className="claim" id="blue">
                <ul>
                  <li>
                    <span className="sleej">Car storage:</span> Call us on the{" "}
                    <strong> Quoteline Direct claims line </strong>regarding
                    safe parking of your vehicle after an accident. Until your
                    claim is assessed, we will keep your car in our secure
                    storage compounds, protecting it from damage and theft.
                    Until you have decided what to do with your vehicle, this is
                    a good spot to park it.
                  </li>
                  <li>
                    <span className="sleej">Replacement car:</span>We replace
                    your car with an identical one for the period it is not
                    usable. You can retain this vehicle as long as your car is
                    being fixed or your claim is being dealt with. All you need
                    to do in order to get the same kind of substitute auto is to
                    call us on the{" "}
                    <strong>
                      {" "}
                      Quoteline Direct car accident claim phone number,{" "}
                    </strong>
                    and we will bring it right to your place.
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-12">
              <div className="claim">
                <ul>
                  <li>
                    <span className="sleej">Personal injury support:</span>
                    If you have been involved in a road accident where you were
                    injured, then call us on
                    <strong> Quoteline Direct insurance claim number </strong>
                    for help from our solicitors who can help you sue for
                    damages.
                  </li>
                  <li>
                    <span className="sleej">Claims administration:</span>We take
                    care of every aspect of your claims from processing
                    documents to coordinating all parties involved in the
                    accidents so that your claim gets resolved fast.
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="yellowcta">
        <div className="container">
          <div className="row">
            <div className="cta2">
              <div className="col-xxl-8 col-xl-8 col-lg-8 col-12">
                <div className="legal">
                  <h3 className="experienced">
                    Got more questions?{" "}
                    <span className="light">Talk to our specialists</span>{" "}
                  </h3>
                  <p className="professionals">
                    Get in touch with our no-fault claim experts via{" "}
                    <strong>
                      car insurance claim phone number{" "}
                      <a className="cpt-no" href="tel:0800 772 0850">
                        {" "}
                        0800 772 0850{" "}
                      </a>{" "}
                    </strong>
                    to get the best advice for your{" "}
                    <strong> Quoteline Direct car insurance claim. </strong>
                  </p>
                </div>
              </div>
              <div className="col-xxl-4 col-xl-4 col-lg-4 col-12">
              <div class="process">
                  <a class="tf-button style-1 phone" href="tel:0800 772 0850">
                    Get in touch<span class="icon-keyboard_arrow_right"></span>
                  </a>
                </div>
                <div class="process">
                    <a className="tf-button style-1 desktop"  onClick={handleScrollToSidebar}>
                        Get in touch<span className="icon-keyboard_arrow_right"></span>
                    </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <search className="updatedform">
        <div className="container">
          <div className="row text-center">
            <div className="col-xxl-12 col-xl-12 col-md-12 col-12">
              <h2 className="whiteheading">
                {" "}
                What makes us <br />
                <span className="light">different?</span>
              </h2>
              <p className="sustained">
                We have the best UK-based team of experienced solicitors who
                ensure you get the maximum compensation possible after suffering
                from road traffic accidents. Dial{" "}
                <a href="tel:0800 772 0850">0800 772 0850</a> for urgent support
                in case you are involved in a no-fault car accident.
              </p>
            </div>
          </div>
          <div className="row">
            <div className="col-xxl-4 col-xl-4 col-lg-6 col-md-12 col-12">
              <div className="Claimant">
                <h5> Cost-free services </h5>
                <p className="collision">
                  You do not have to pay any out-of-pocket expenses for our
                  claims management services. We recover all costs incurred
                  through the other party’s insurer.
                </p>

                <h5> No increase in insurance premiums </h5>
                <p className="collision">
                  With us, you will have your insurance premium intact as we
                  will not claim from your own policy.{" "}
                </p>

                <h5> 24/7 availability </h5>
                <p className="collision">
                  Our 24/7 availability offers you a piece of mind as it means
                  that you can rely on dedicated assistance and support even
                  when it is most needed.
                </p>
              </div>
            </div>
            <div className="col-xxl-4 col-xl-4 col-lg-6 col-md-12 col-12">
              <div className="Claimant">
                <h5> Complete roadside assistance </h5>
                <p className="collision">
                  Our wide-ranging services for vehicle recovery to replacement
                  make available a broad variety of provisions which one can
                  hire besides other assistance which are important in
                  simplifying the problems and stress associated with car
                  accidents.
                </p>
                <h5> Safe vehicles storage </h5>
                <p className="collision">
                  With our storage yards, your vehicle is temporarily kept safe
                  from any risks or damages.
                </p>

                <h5>Best repairs</h5>
                <p className="collision">
                  We guarantee to give you quality repairs done using original
                  parts and paint. Call{" "}
                  <strong> Quoteline Direct claims line </strong>for damaged
                  vehicle repair arrangements.
                </p>
              </div>
            </div>
            <div className="col-xxl-4 col-xl-4 col-lg-12 col-md-12 col-12">
              <div className="Claimant">
                <h5> Fast replacement car delivery </h5>
                <p className="collision">
                  Thus, our goal is to lessen the trouble created due to the
                  crash by providing an alternative car delivered within
                  twenty-four hours to your doorstep.
                </p>

                <h5> Personalised advice </h5>
                <p className="collision">
                  Our solicitors will provide tailored advice according to the
                  circumstances of your case.
                </p>

                <h5>No win, no fee agreement</h5>
                <p className="collision">
                  If our solicitors fail to win your case or fetch you what you
                  deserve, we will not charge you a penny.
                </p>
              </div>
            </div>
          </div>
        </div>
      </search>

      <section className="Accident-claim">
        <div className="container">
          <div className="row">
            <div className="needed">
              <h2 className="trusted-title">
                How can you maximise your <br />
                <span className="light"> insurance claim? </span>
              </h2>
              <p className="complexities">
                If you are involved in a motor vehicle accident that is not your
                fault, it will become necessary to sue for compensation for the
                damages and losses incurred. Below are some ways your settlement
                value of the claim can be maximised to receive fair
                compensation.{" "}
              </p>
            </div>
            <hr />
          </div>
          <div className="row" id="incdes">
            <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-12">
              <div className="Preparing">
                <ul>
                  <li>
                    <div className="seemingly">
                      <h5 className="Seek">Report the accident:</h5>
                      <p>
                        {" "}
                        The first thing to do after being involved in an auto
                        accident is call the police and report it. Police
                        reports often have vital information which may help you
                        with the case.{" "}
                      </p>
                    </div>
                  </li>
                  <li>
                    <div className="seemingly">
                      <h5 className="Seek">
                        Get immediate emergency services:{" "}
                      </h5>
                      <p>
                        When left stranded on the roadside, ensure that
                        emergency services reach where you are as soon as
                        possible. For immediate roadside assistance, please ring
                        us through our{" "}
                        <strong>
                          {" "}
                          Quoteline Direct car insurance claims number.
                        </strong>
                      </p>
                    </div>
                  </li>
                  <li>
                    <div className="seemingly">
                      <h5 className="Seek">Keep a record of all expenses:</h5>
                      <p>
                        All expenses like medical bills, transportation fees,
                        repair costs, and replacement car expenses must be
                        documented.
                      </p>
                    </div>
                  </li>
                  <li>
                    <div className="seemingly">
                      <h5 className="Seek">Compile proofs of claim</h5>
                      <p>
                        Accumulate proofs from accident scenes which act as
                        testimony for claiming compensation. These may
                        incorporate crime scene photographs, personal injury
                        photos, eyewitness statements, and CCTV footage.
                      </p>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
            <div
              className="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-12"
              id="dualcardiv"
            >
              <img src={dualcar} alt="" className="dualcar" />
            </div>
            <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-12">
              <div className="Preparing">
                <ul>
                  <li>
                    <div className="seemingly">
                      <h5 className="Seek">Do not use social media:</h5>
                      <p>
                        Do not make any posts on social media during the time
                        your claim is ongoing, because the other party’s
                        solicitor may use those posts against you, thereby
                        ruining your case.{" "}
                      </p>
                    </div>
                  </li>
                  <li>
                    <div className="seemingly">
                      <h5 className="Seek"> Share information: </h5>
                      <p>
                        For ease of processing your claim, exchange all relevant
                        information regarding you and the other driver including
                        personal details, insurance details, vehicle details and
                        driving licence details.
                      </p>
                    </div>
                  </li>
                  <li>
                    <div className="seemingly">
                      <h5 className="Seek">
                        Get a no-fault claims specialist:
                      </h5>
                      <p>
                        Connect with us on{" "}
                        <strong>
                          {" "}
                          Quoteline Direct car accident claim phone number{" "}
                        </strong>{" "}
                        to be connected with reputable solicitors in the UK who
                        have handled similar car accident cases before.{" "}
                      </p>
                    </div>
                  </li>
                  <li>
                    <div className="seemingly">
                      <h5 className="Seek">
                        Start making the first steps of filing a claim:
                      </h5>
                      <p>
                        Once you have all the evidence and necessary documents,
                        just give us a call at{" "}
                        <strong>
                          {" "}
                          Quoteline Direct insurance claim number{" "}
                        </strong>{" "}
                        to begin the process of claiming for it.
                      </p>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="whyus">
        <div className="container">
          <div className="row">
            <div className="col-xxl-12 col-md-12">
              <div className="why">
                <h2 className="choose">
                  Simple steps to begin{" "}
                  <span className="light">your claim</span>
                </h2>
                <p className="complexities">
                  Our simple claims procedure will allow you to easily make a
                  claim without worrying about completing any forms or
                  paperwork. Give us a ring through the{" "}
                  <strong> Quoteline Direct accident claim number, </strong>then
                  leave everything else for us to take care of.
                </p>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-xxl-3 col-xl-6 col-lg-6 col-md-6 col-12">
              <div className="box-service">
                <img src={check} className="check" alt="" />
                {/* <img src={experience} className="experience" alt="" /> */}
                <h4>Contact us</h4>
                <p>
                  Call us on{" "}
                  <strong>
                    {" "}
                    <a href="tel:0800 772 0850">0800 772 0850</a>{" "}
                  </strong>
                  for assistance as well as booking an appointment with our
                  claims experts free of charge.
                </p>
              </div>
            </div>
            <div className="col-xxl-3 col-xl-6 col-lg-6 col-md-6 col-12">
              <div className="box-service">
                <img src={check} className="check" alt="" />
                <h4> Give us an account of the accident </h4>
                <p>
                  Tell us when it happened, where it took place, and what
                  circumstances caused the crash.
                </p>
              </div>
            </div>
            <div className="col-xxl-3 col-xl-6 col-lg-6 col-md-6 col-12">
              <div className="box-service">
                <img src={check} className="check" alt="" />
                <h4> Show us what evidence you have </h4>
                <p>
                  If you collected any information to help your case, show it to
                  us, please. Give some relevant papers for claims processing.{" "}
                </p>
              </div>
            </div>
            <div className="col-xxl-3 col-xl-6 col-lg-6 col-md-6 col-12">
              <div className="box-service">
                <img src={check} className="check" alt="" />
                <h4> Follow up </h4>
                <p>
                  Make sure to get in touch with our offices for progress
                  updates on your claim.{" "}
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="faqs">
        <div className="container">
          <div className="row">
            <div className="col-xxl-12 col-xl-12 col-md-12 col-12">
              <div className="fq">
                <h2>
                  {" "}
                  Frequently Asked{" "}
                  <span className="light">Questions (FAQ)</span>
                </h2>
                <p className="complexities">
                  Got questions about the{" "}
                  <strong> Quoteline Direct car insurance claim? </strong> We
                  have them answered here.
                </p>
              </div>
              <div className="allfaq">
                <Accordion defaultActiveKey="0">
                  <Accordion.Item eventKey="0">
                    <Accordion.Header>
                      Are there hidden costs I should know before making a claim
                      through you?
                    </Accordion.Header>
                    <Accordion.Body>
                      Not at all. Don’t expect anything that might surprise you
                      or put you into unnecessary expenses. We will be
                      transparent and honest at all times. Hence, we will make
                      the other party pay for everything spent out of pocket by
                      claiming their insurers.
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="1">
                    <Accordion.Header>
                      Can I count on you to take my vehicle after an accident?
                    </Accordion.Header>
                    <Accordion.Body>
                      Of course. Our team will always pick up your car from road
                      accident spots and send it to the repair centre of your
                      choice.
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="2">
                    <Accordion.Header>
                      Does this affect my no-claims bonus?
                    </Accordion.Header>
                    <Accordion.Body>
                      No! If the accident was not your fault, your no-claims
                      bonus is safe. You can therefore relax knowing that even
                      if a claim is made against your policy, your no-claims
                      bonus won’t be affected.
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="3">
                    <Accordion.Header>
                      What about the guarantee for vehicle repairs?{" "}
                    </Accordion.Header>
                    <Accordion.Body>
                      Certainly, we will organise your repairs through repair
                      centres approved by the manufacturer to ensure
                      high-quality repairs.
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="4">
                    <Accordion.Header>
                      What do I do if my car is written off?
                    </Accordion.Header>
                    <Accordion.Body>
                      If your car has been declared a write-off, we shall
                      provide you with a replacement vehicle of exactly the same
                      specification until compensation is received from the
                      third party.
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="5">
                    <Accordion.Header>
                      Will I be required to pay any excess in case it was not my
                      fault?{" "}
                    </Accordion.Header>
                    <Accordion.Body>
                      No. We won’t take the excess from you when you get
                      involved in an accident that wasn’t your fault. All
                      expenses are obtained from an insurer of another party
                      participating in such an accident.{" "}
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="6">
                    <Accordion.Header>
                      How soon can one obtain a replacement vehicle after being
                      involved in a road traffic accident?{" "}
                    </Accordion.Header>
                    <Accordion.Body>
                      In cases where the accident is not your fault, you will
                      have a second car within 24 hours so as to maintain
                      normality while doing other things.
                    </Accordion.Body>
                  </Accordion.Item>
                </Accordion>
              </div>
            </div>
          </div>
        </div>
      </section>

      <Footer />
    </>
  );
};

export default Quotelinedirect;
