import React, { useRef } from "react";
import Accordion from "react-bootstrap/Accordion";
import check from "../assets/images/check.png";
import dualcar from "../../src/assets/images/dualcar.webp";
import carbnr1 from "../../src/assets/images/carbnr1.webp";
import Sidebarinner from "../components/Sidebar-inner";
import phonecall from "../assets/images/phonecall.png";
import Footer from "../components/footer";
import { Helmet } from "react-helmet";

const Geoffreyinsurance = () => {
  const sidebarRef = useRef(null);

  const handleScrollToSidebar = () => {
    if (sidebarRef.current) {
      sidebarRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Geoffrey Car Insurance Accident Claim Phone Number:</title>
        <meta
          name="description"
          content="Need fast and reliable claim management support? Get in touch with us at the Geoffrey Car Insurance accident claim phone number 0800 772 0850 for immediate assistance. "
        />
        <link
          rel="canonical"
          href="https://insurance-accident-claims.co.uk/geoffrey-insurance"
        />
      </Helmet>
      <section className="innernav">
        <div className="container">
          <div className="row">
            <div className="col-md-6 col-sm-6">
              <div className="left-logo">
                <h3>
                  Accident<span className="sipo"> Claims</span>
                </h3>
              </div>
            </div>
            <div className="col-md-6 col-sm-6">
              <div className="left-cta">
                <div class="headercnt">
                  <div class="chatmsg">
                    <img
                      src={phonecall}
                      className="phonecall"
                      alt="phonecall "
                    />
                  </div>
                  <div class="box-body">
                    <h3 class="nav-info-box-title">Toll Free Number </h3>
                    <p>
                      <a href="tel:0800 772 0850">0800 772 0850</a>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section
        className="newbnr"
        style={{ backgroundImage: "url(" + carbnr1 + ")" }}
      >
        <div className="container">
          <div className="row">
            <div className="col-xxl-8 col-xl-8 col-md-10 col-12">
              <div className="innerbnr">
                <h1>
                  <span className="onespan">Accident Management and </span>{" "}
                  <span className="sci">No-Fault </span> Claims Assistance in
                  the UK
                </h1>
                <p className="brokerage">
                  We provide a wide range of accident management solutions to
                  help you get back on the road after a no-fault car accident.
                </p>
                <div className="bnrbtn">
                  <a class="db-btn-border btn-rollover phone" href="tel:0800 772 0850">
                    Road Accident Claim Help?{" "}
                    <i class="fa-solid fa-arrow-right"></i>
                  </a>
                </div>
                <div className="bnrbtn">
                <a className="db-btn-border btn-rollover desktop"  onClick={handleScrollToSidebar}>
                    Road Accident Claim Help? <i className="fa-solid fa-arrow-right"></i>
                </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="stickysection">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="group-4-8">
                <div className="cl8 tf-tab">
                  <div className="content-tab">
                    <div className="inner-content">
                      <h2>
                        Claims support with
                        <br />
                        <span className="light">zero excess payment </span>{" "}
                      </h2>
                      <p className="disciplines">
                        Every year, several accidents occur in the United
                        Kingdom. That is why we are here to assist people who
                        have been involved in road traffic accidents by offering
                        authentic emergency support as well as professional
                        services even without asking for extra charges. Our
                        dedicated team will be available on the{" "}
                        <strong> Geoffrey claims number </strong> so that we can
                        provide you with excellent claim assistance following a
                        non-fault accident.
                      </p>
                      <hr />
                      <p className="bold-text">No insurance excess</p>
                      <p className="bottom-te">
                        If you choose to submit your{" "}
                        <strong> Geoffrey accident claim </strong> through us,
                        we will save you from being charged insurance excess
                        since we shall not make any claim against your policy of
                        insurance.{" "}
                      </p>
                      <hr />
                      <p className="bold-text">Fast response </p>
                      <p className="bottom-te">
                        When you call us using the{" "}
                        <strong> Geoffrey claim line, </strong>we guarantee
                        instant support.
                      </p>
                      <hr />
                      <p className="bold-text">Manufacturer approved repairs</p>
                      <p className="bottom-te">
                        Our network of approved repairers ensures your vehicle
                        is repaired to the highest standards possible.
                      </p>
                      <hr />
                      <p className="bold-text">
                        Replacement vehicle provided immediately{" "}
                      </p>
                      <p className="bottom-te">
                        At no extra fee, we will supply you with a same-day
                        like-for-like replacement car in case your own is
                        written off.{" "}
                      </p>
                      <hr />
                      <p className="bold-text">No claims bonus protection</p>
                      <p className="bottom-te">
                        Since we are not going to make a claim on your insurance
                        policy, your hard-earned discounts and no-claims bonus
                        will remain intact.
                      </p>
                      <hr />
                      <p className="bold-text">All-time assistance</p>
                      <p className="bottom-te">
                        Just dial <strong> Geoffrey contact number </strong> to
                        get roadside assistance after a non-fault motor vehicle
                        accident. Our team is at hand 24/7 to help you with
                        accident management.
                      </p>
                      <hr />
                      <p className="bold-text">Free initial advice</p>
                      <p className="bottom-te">
                        Looking for free advice on what can be done? Call us
                        through our claims contact number{" "}
                        <strong>
                          <a href="tel:0800 772 0850">0800 772 0850</a>
                        </strong>
                        , to receive no-obligation advice from our experienced
                        panel of solicitors.
                      </p>
                      <hr />
                      <p className="bold-text">Quick claim settlement</p>
                      <p className="bottom-te">
                        Fast resolution of your{" "}
                        <strong>Geoffrey accident claims </strong>
                        is guaranteed by our systematised approach and open
                        lines of communication between all parties involved.{" "}
                      </p>
                      <hr />
                      <p className="bold-text">Experienced claims handler</p>
                      <p className="bottom-te">
                        You will have an assigned claims handler who specialises
                        in handling <strong>Geoffrey claims </strong> and will
                        guide you through each stage so as to make this process
                        easier for you.{" "}
                      </p>
                      <hr />

                      <p className="bold-text">Countrywide recovery</p>
                      <p className="bottom-te">
                        When you phone us using the{" "}
                        <strong> Geoffrey car insurance claim number, </strong>
                        we will organise to collect your damaged vehicle,
                        ensuring that it arrives safely at one of our storage
                        facilities.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="cl4" ref={sidebarRef}>
                  <Sidebarinner />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="photographs">
        <div className="container">
          <div className="row">
            <h2 className="road">
              Sit back and recover while we{" "}
              <span className="light">
                handle <br />
                your Geoffrey claim.
              </span>{" "}
            </h2>
            <div className="col-xxl-6 col-xl-6 col-md-12 col-12">
              <div className="fourbox">
                <ul>
                  <li>
                    <img src={check} className="check" alt="" />
                    We will ease your stress by recovering your vehicle after a
                    no-fault car accident.
                  </li>
                  <hr />
                  <li>
                    <img src={check} className="check" alt="" />
                    Contact us through the{" "}
                    <strong> Geoffrey claims number </strong>for reliable
                    repairs to your vehicle that will be guaranteed, from a
                    garage of your choice.
                  </li>
                  <hr />
                  <li>
                    <img src={check} className="check" alt="" />
                    We will arrange for an independent engineer to assess your
                    vehicle’s damage.
                  </li>
                  <hr />
                  <li>
                    <img src={check} className="check" alt="" />
                    In case of any injury resulting from a road accident, our
                    solicitors will advise you on how to make the{" "}
                    <strong> Geoffrey accident claim </strong> so that you can
                    get a rightful amount for compensation for your injuries.
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-xxl-6 col-xl-6 col-md-12 col-12">
              <div className="fourbox">
                <ul>
                  <li>
                    <img src={check} className="check" alt="" />
                    We can help you calculate any loss of earnings and
                    destructions made on the property and then see if you are
                    refunded by the insurance company of the at-fault party.
                  </li>
                  <hr />
                  <li>
                    <img src={check} className="check" alt="" />
                    Require another car? Reach us using the{" "}
                    <strong> Geoffrey claim line. </strong>Until such time as it
                    takes for repairs to be made on your vehicle, we shall send
                    you a replacement equivalent, which has matching features at
                    your doorway.
                  </li>
                  <hr />
                  <li>
                    <img src={check} className="check" alt="" />
                    Our solicitors have long experience in recovering all
                    uninsured losses from the at-fault party's insurers.
                  </li>
                  <hr />
                  <li>
                    <img src={check} className="check" alt="" />
                    With our no win no fee policy, you will not be burdened with
                    the dangers of meeting any upfront expenses in case your{" "}
                    <strong> Geoffrey claim </strong>does not succeed.
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="carprocess">
        <div className="container ">
          <div className="row">
            <div className="col-xxl-12 col-xxl-12 col-md-12 col-12">
              <h2 className="trusted-title">
                {" "}
                Our accident management <br />
                <span className="light">services</span>{" "}
              </h2>
            </div>
          </div>
          <div className="row" id="possible">
            <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-12">
              <div className="claim">
                <ul>
                  <li>
                    <span className="sleej">Car recovery:</span>In case you were
                    not at fault in the accident, dial up the{" "}
                    <strong> Geoffrey claims contact number </strong> to get
                    efficient and professional help in getting your car moved to
                    a safer location.
                  </li>
                  <li>
                    <span className="sleej">Specialised repairs:</span>If you
                    need top-notch mechanical maintenance for your car, ring our
                    line at <strong>Geoffrey claim line. </strong> We have
                    well-established contacts with manufacturers of vehicles,
                    making it possible for us to repair your car within the
                    shortest time possible and enabling you to get back on track
                    without much hassle.
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-12">
              <div className="claim" id="blue">
                <ul>
                  <li>
                    <span className="sleej">Vehicle storage:</span> For recovery
                    of your vehicle from the site of an accident and its
                    transportation to our safe storage facilities, call us at{" "}
                    <strong>Geoffrey claims contact number.</strong>After
                    picking up your car from the scene of your accident we will
                    store it safely here until repairs are made or its claim is
                    assessed.
                  </li>
                  <li>
                    <span className="sleej">Replacement vehicle:</span>We will
                    help you normalise life after an innocent motor incident.
                    Call us via{" "}
                    <strong> Geoffrey car insurance claims number, </strong> and
                    we will ensure that you are back on track by giving you
                    another car like what was damaged at no cost to yourself
                    This Means until such a time when your own vehicle is
                    undergoing repairs, you will still have another one at your
                    disposal.
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-12">
              <div className="claim">
                <ul>
                  <li>
                    <span className="sleej">Personal injury support:</span>
                    We’ll help you get legal advice and an experienced solicitor
                    appointed to guide you through the process of making
                    <strong> Geoffrey claims,</strong>just in case you’ve
                    suffered personal injuries as a result of a car accident.
                  </li>
                  <li>
                    <span className="sleej">Claims management:</span>Starting
                    from giving you professional advice to organising the
                    repairs of your car and communicating with all the involved
                    parties in an accident, we’ll run your{" "}
                    <strong> Geoffrey accident claim. </strong>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="yellowcta">
        <div className="container">
          <div className="row">
            <div className="cta2">
              <div className="col-xxl-8 col-xl-8 col-lg-8 col-12">
                <div className="legal">
                  <h3 className="experienced">
                    Got more questions?{" "}
                    <span className="light">Talk to our specialists</span>{" "}
                  </h3>
                  <p className="professionals">
                    Get in touch with our no-fault claim experts via{" "}
                    <strong>
                      car insurance claims number{" "}
                      <a className="cpt-no" href="tel:0800 772 0850">
                        {" "}
                        0800 772 0850{" "}
                      </a>{" "}
                    </strong>
                    to get the best advice for your{" "}
                    <strong> Geoffrey claims. </strong>
                  </p>
                </div>
              </div>
              <div className="col-xxl-4 col-xl-4 col-lg-4 col-12">
              <div class="process">
                  <a class="tf-button style-1 phone" href="tel:0800 772 0850">
                    Get in touch<span class="icon-keyboard_arrow_right"></span>
                  </a>
                </div>
                <div class="process">
                    <a className="tf-button style-1 desktop"  onClick={handleScrollToSidebar}>
                        Get in touch<span className="icon-keyboard_arrow_right"></span>
                    </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <search className="updatedform">
        <div className="container">
          <div className="row text-center">
            <div className="col-xxl-12 col-xl-12 col-md-12 col-12">
              <h2 className="whiteheading">
                {" "}
                Why are we the best answer to your <br />
                <span className="light"> accident management needs? </span>
              </h2>
              <p className="sustained">
                We know how road traffic accidents could mess up one’s life, but
                then again we are trusted partners who manage non-fault
                accidents to bring you back on track. We shall be by your side
                as soon as you report an occurrence of a car crash until the
                expenses incurred during repair of damages that resulted from
                the at-fault driver are catered for and paid.{" "}
              </p>
            </div>
          </div>
          <div className="row">
            <div className="col-xxl-4 col-xl-4 col-lg-6 col-md-12 col-12">
              <div className="Claimant">
                <h5> Speedy accident recovery </h5>
                <p className="collision">
                  Though it may sound demanding and tricky, this is possible
                  with us and within a very short period of time. Call us on our{" "}
                  <strong> Geoffrey claims number, </strong>and we will ensure
                  that you have safely moved back on track while taking care of
                  both your safety and that of your vehicle.
                </p>

                <h5> Manufacturer-authorised parts and paints </h5>
                <p className="collision">
                  We can fix your damaged car as quickly as possible, ensuring
                  that only the best hands are used and first-hand parts are
                  applied.{" "}
                </p>

                <h5>Specialised claims handling</h5>
                <p className="collision">
                  Our solicitors will look into your{" "}
                  <strong> Geoffrey accident claim, </strong> appraise the
                  extent of damage on your vehicles and link up with a
                  third-party insurer for faster settlement of your claim.
                </p>
              </div>
            </div>
            <div className="col-xxl-4 col-xl-4 col-lg-6 col-md-12 col-12">
              <div className="Claimant">
                <h5> 24/7 support </h5>
                <p className="collision">
                  We have a dedicated team that is always available round the
                  clock every day of the week. Call us via{" "}
                  <strong> Geoffrey claims contact number </strong>regarding
                  immediate assistance following an accident not caused by
                  yourself.
                </p>
                <h5> No excess charges required </h5>
                <p className="collision">
                  You won’t be charged any excess fees when it’s not at fault
                  over a car accident.
                </p>

                <h5>Full evaluation of damages</h5>
                <p className="collision">
                  Our specialists will do a thorough examination of your vehicle
                  to identify the actual value lost and determine what can be
                  done fast enough to make it work again correctly.{" "}
                </p>
              </div>
            </div>
            <div className="col-xxl-4 col-xl-4 col-lg-12 col-md-12 col-12">
              <div className="Claimant">
                <h5>Same-day replacement car service</h5>
                <p className="collision">
                  We will facilitate your return back on the road with
                  like-for-like replacement, ensuring that it is the exact make,
                  size, model and registration number as yours.
                </p>

                <h5> Inclusive claim handling service </h5>
                <p className="collision">
                  From the beginning of filling out forms to the settlement of
                  your claim, we take care of all that entails the{" "}
                  <strong> Geoffrey claims </strong>process.
                </p>

                <h5>Legal and administrative support</h5>
                <p className="collision">
                  To ensure that matters are addressed fairly, we have put
                  together a team of specialists who make sure that all the
                  paperwork is handled properly during this process.
                </p>
              </div>
            </div>
          </div>
        </div>
      </search>

      <section className="Accident-claim">
        <div className="container">
          <div className="row">
            <div className="needed">
              <h2 className="trusted-title">
                How can you maximise your settlement after a <br />
                <span className="light"> no-fault car accident? </span>
              </h2>
              <p className="complexities">
                It’s necessary to make the correct moves soon after an accident
                that was not your fault to ensure that you receive due
                compensation for injuries and losses. Here are some of the
                things that you must do in order to improve your chances of
                having a successful claim for compensation.{" "}
              </p>
            </div>
            <hr />
          </div>
          <div className="row" id="incdes">
            <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-12">
              <div className="Preparing">
                <ul>
                  <li>
                    <div className="seemingly">
                      <h5 className="Seek">
                        Seek immediate medical attention:
                      </h5>
                      <p>
                        {" "}
                        After a no-fault car accident, your health should be the
                        number one priority. Thus, get prompt medical treatment
                        even if you have only sustained minor injuries.{" "}
                      </p>
                    </div>
                  </li>
                  <li>
                    <div className="seemingly">
                      <h5 className="Seek">Keep track of every detail: </h5>
                      <p>
                        Maintain an extensive record regarding all such costs,
                        lost salaries as well financial losses arising from the
                        car incident.
                      </p>
                    </div>
                  </li>
                  <li>
                    <div className="seemingly">
                      <h5 className="Seek">Don’t talk about the accident:</h5>
                      <p>
                        Never discuss anything related to your accident or{" "}
                        <strong> Geoffrey claims </strong> with anyone else
                        apart from those who are part of your legal team. Any
                        word may undermine your case.
                      </p>
                    </div>
                  </li>
                  <li>
                    <div className="seemingly">
                      <h5 className="Seek"> Collect evidence </h5>
                      <p>
                        Comprehensive evidence including medical records,
                        photographs of injury and accident scenes, statements
                        from witnesses, and financial loss documents should be
                        collected.
                      </p>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
            <div
              className="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-12"
              id="dualcardiv"
            >
              <img src={dualcar} alt="" className="dualcar" />
            </div>
            <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-12">
              <div className="Preparing">
                <ul>
                  <li>
                    <div className="seemingly">
                      <h5 className="Seek">Avoid early settlement offers:</h5>
                      <p>
                        Usually, the initial settlement offer fails to cover all
                        damages faced by victims. Therefore before accepting any
                        such offer talk with your solicitor to ensure fair
                        compensation for all damage is achieved.{" "}
                      </p>
                    </div>
                  </li>
                  <li>
                    <div className="seemingly">
                      <h5 className="Seek">
                        {" "}
                        Monitor your social media presence{" "}
                      </h5>
                      <p>
                        To avoid calamities in the course of bargaining for a
                        rightful payback, it is imperative to tread carefully on
                        social media.
                      </p>
                    </div>
                  </li>
                  <li>
                    <div className="seemingly">
                      <h5 className="Seek">
                        Speak to a no-fault claim expert:
                      </h5>
                      <p>
                        Feel free to contact us via{" "}
                        <strong> Geoffrey claims contact number </strong> for an
                        opportunity to have a conversation with our no-fault
                        claim expert on your rights and choices.{" "}
                      </p>
                    </div>
                  </li>
                  <li>
                    <div className="seemingly">
                      <h5 className="Seek">Make your claim </h5>
                      <p>
                        Contact the Geoffrey claims department once you have
                        gathered all the necessary evidence and documents
                        pertaining to the accident.
                      </p>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="whyus">
        <div className="container">
          <div className="row">
            <div className="col-xxl-12 col-md-12">
              <div className="why">
                <h2 className="choose">
                  How to register your{" "}
                  <span className="light">claim with us</span>
                </h2>
                <p className="complexities">
                  Thinking of starting a{" "}
                  <strong> Geoffrey accident claim </strong>for your loss
                  compensation? With our simplified and fast claims procedure,
                  you can begin it in just four simple steps. Ring us on{" "}
                  <strong>
                    <a href="tel:0800 772 0850">0800 772 0850</a>.
                  </strong>{" "}
                  as soon as possible.
                </p>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-xxl-3 col-xl-6 col-lg-6 col-md-6 col-12">
              <div className="box-service">
                <img src={check} className="check" alt="" />
                {/* <img src={experience} className="experience" alt="" /> */}
                <h4>Initial consultation</h4>
                <p>
                  Ring us through the{" "}
                  <strong> Geoffrey car insurance claims number </strong>to book
                  an appointment with our solicitors.
                </p>
              </div>
            </div>
            <div className="col-xxl-3 col-xl-6 col-lg-6 col-md-6 col-12">
              <div className="box-service">
                <img src={check} className="check" alt="" />
                <h4>Deliverance of evidence and documents</h4>
                <p>
                  Give us all relevant proofs plus documents that were picked by
                  you from where the accident happened.
                </p>
              </div>
            </div>
            <div className="col-xxl-3 col-xl-6 col-lg-6 col-md-6 col-12">
              <div className="box-service">
                <img src={check} className="check" alt="" />
                <h4> Submit a claim </h4>
                <p>
                  Your claim will be prepared and initiated on your behalf after
                  collection of all the relevant documents.{" "}
                </p>
              </div>
            </div>
            <div className="col-xxl-3 col-xl-6 col-lg-6 col-md-6 col-12">
              <div className="box-service">
                <img src={check} className="check" alt="" />
                <h4>Settling a claim</h4>
                <p>
                  We will aggressively work to resolve your claim in order to
                  secure your full compensation.{" "}
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="faqs">
        <div className="container">
          <div className="row">
            <div className="col-xxl-12 col-xl-12 col-md-12 col-12">
              <div className="fq">
                <h2>
                  {" "}
                  Frequently Asked{" "}
                  <span className="light">Questions (FAQ)</span>
                </h2>
                <p className="complexities">
                  Got questions about the{" "}
                  <strong> Geoffrey accident claim? </strong>We have them
                  answered here.
                </p>
              </div>
              <div className="allfaq">
                <Accordion defaultActiveKey="0">
                  <Accordion.Item eventKey="0">
                    <Accordion.Header>
                      Are there any charges that I don’t know about when I file
                      a Geoffrey accident claim?
                    </Accordion.Header>
                    <Accordion.Body>
                      We believe in transparency. As such, there are no hidden
                      charges that we may not tell you about. All the money that
                      you spend out of pocket would be reimbursed by the
                      defendant's insurer if he is at fault. So, you don’t have
                      to pay any fee to us.
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="1">
                    <Accordion.Header>
                      Does claiming through your services mean my no-claims
                      bonus is affected?
                    </Accordion.Header>
                    <Accordion.Body>
                      No, upon making a{" "}
                      <strong> Geoffrey accident claim </strong>with us, there
                      will be no deductibles required and this has nothing to do
                      with loss of benefits for non-fault accidents.
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="2">
                    <Accordion.Header>
                      Who pays for everything including the services rendered
                      and my claim?
                    </Accordion.Header>
                    <Accordion.Body>
                      The other party’s insurance company will pay every dime
                      you incur as a result of a no-fault car accident. From
                      repair expenses to costs of replacement vehicles, the
                      at-fault party’s insurance company is liable to pay all
                      the costs incurred by you due to the accident.
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="3">
                    <Accordion.Header>
                      Can you help me get my car back after an accident that was
                      not my fault?{" "}
                    </Accordion.Header>
                    <Accordion.Body>
                      Sure. We will recover your crashed vehicle from the scene
                      of a car accident and convey it to a repair centre
                      approved by us. Moreover, we will offer you a replacement
                      vehicle so that you can continue with your daily
                      activities normally.
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="4">
                    <Accordion.Header>
                      Can you help if my car is a write-off?
                    </Accordion.Header>
                    <Accordion.Body>
                      Sure. In case your vehicle is declared as beyond repair,
                      we will give you an equivalent substitute for it.
                      Additionally, we negotiate with the other party for the
                      best compensation for your written-off vehicle.
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="5">
                    <Accordion.Header>
                      The insurer of the other party has offered me an
                      out-of-court settlement. Should I agree?{" "}
                    </Accordion.Header>
                    <Accordion.Body>
                      No. There is no need to say yes when someone offers you an
                      out-of-court settlement without prior consultation with
                      your solicitor. Call our solicitors at{" "}
                      <strong> Geoffrey claims number </strong>
                      and let them advise whether or not the offer being made is
                      reasonable enough to fully compensate you for all loss
                      suffered.
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="6">
                    <Accordion.Header>
                      How long can I have the courtesy car?{" "}
                    </Accordion.Header>
                    <Accordion.Body>
                      The period of time in which you will have the courtesy car
                      depends on how bad the accident was and how much damage
                      there is on your vehicle that needs to be repaired. You
                      are able to use it until such time that your own car comes
                      back from repairs or should you receive full salvage value
                      payable amounting to the market value of your damaged car.
                    </Accordion.Body>
                  </Accordion.Item>
                </Accordion>
              </div>
            </div>
          </div>
        </div>
      </section>

      <Footer />
    </>
  );
};

export default Geoffreyinsurance;
