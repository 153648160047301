import React, { useRef } from "react";
import Accordion from "react-bootstrap/Accordion";
import check from "../assets/images/check.png";
import dualcar from "../../src/assets/images/dualcar.webp";
import carbnr1 from "../../src/assets/images/carbnr1.webp";
import Sidebarinner from "../components/Sidebar-inner";
import phonecall from "../assets/images/phonecall.png";
import Footer from "../components/footer";
import { Helmet } from "react-helmet";

const Dayinsure = () => {
  const sidebarRef = useRef(null);

  const handleScrollToSidebar = () => {
    if (sidebarRef.current) {
      sidebarRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };
  return (
    <>
    <Helmet>
        <meta charSet="utf-8" />
        <title>Dayinsure Car Insurance Accident Claim Phone Number</title>
        <meta
          name="description"
          content="Want to file a no-fault car accident claim? Reach out to our dedicated team via the  Dayinsure car insurance claim number for 0800 772 0850 expert claims assistance. "
        />
        <link
          rel="canonical"
          href="https://insurance-accident-claims.co.uk/dayinsure"
        />
      </Helmet>
      <section className="innernav">
        <div className="container">
          <div className="row">
            <div className="col-md-6 col-sm-6">
              <div className="left-logo">
                <h3>
                  Accident<span className="sipo"> Claims</span>
                </h3>
              </div>
            </div>
            <div className="col-md-6 col-sm-6">
              <div className="left-cta">
                <div class="headercnt">
                  <div class="chatmsg">
                    <img
                      src={phonecall}
                      className="phonecall"
                      alt="phonecall "
                    />
                  </div>
                  <div class="box-body">
                    <h3 class="nav-info-box-title">Toll Free Number </h3>
                    <p>
                      <a href="tel:0800 772 0850">0800 772 0850</a>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section
        className="newbnr"
        style={{ backgroundImage: "url(" + carbnr1 + ")" }}
      >
        <div className="container">
          <div className="row">
            <div className="col-xxl-8 col-xl-8 col-md-10 col-12">
              <div className="innerbnr">
                <h1>
                  <span className="onespan">Accident management</span>{" "}
                  <span className="sci">services and </span> expert claims assistance
                </h1>
                <p className="brokerage">
                We have covered all your accident management needs, from vehicle recovery to making a no-fault claim.
                </p>
                <div className="bnrbtn">
                  <a class="db-btn-border btn-rollover phone" href="tel:0800 772 0850">
                    Road Accident Claim Help?{" "}
                    <i class="fa-solid fa-arrow-right"></i>
                  </a>
                </div>
                <div className="bnrbtn">
                <a className="db-btn-border btn-rollover desktop"  onClick={handleScrollToSidebar}>
                    Road Accident Claim Help? <i className="fa-solid fa-arrow-right"></i>
                </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="stickysection">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="group-4-8">
                <div className="cl8 tf-tab">
                  <div className="content-tab">
                    <div className="inner-content">
                      <h2>
                      No additional cost for  
                        <br />
                        <span className="light">
                        dedicated claims assistance{" "}
                        </span>{" "}
                      </h2>
                      <p className="disciplines">
                      We are the UK's leading no-fault-accident specialists who specialise in helping no-fault drivers.  From dealing with the insurers to getting you back on the road, we’ve got it sorted for you. Call us on the <strong> Dayinsure car insurance claim number </strong> and experience a claims journey that is free of stress starting off till the end.
                      </p>
                      <hr />
                      <p className="bold-text"> No extra charge </p>
                      <p className="bottom-te">
                      We manage all aspects of the <strong> Dayinsure car insurance claim </strong>{" "}
                      without any additional costs or upfront charges. When you choose us for accident management and{" "} <strong> Dayinsure insurance claims </strong>assistance, you don’t have to spend thousands of pounds.
                      </p>
                      <hr />
                      <p className="bold-text"> Approved repairers </p>
                      <p className="bottom-te">
                      Through our network of approved vehicle repairers offering genuine parts and quality repairs, we will ensure quick repair of your damaged vehicle.
                      </p>
                      <hr />
                      <p className="bold-text">
                      NCB protection
                      </p>
                      <p className="bottom-te">
                      Our main priority is protecting your no-claims bonus. Our no-fault claim specialists proficiently handle{" "}
                        <strong>
                        Dayinsure insurance claims,{" "}
                        </strong>
                        making sure you do not miss out on any hard-earned discounts or lose your no-claims bonus.{" "}
                      </p>
                      <hr />
                      <p className="bold-text">
                      Comparable replacement vehicle{" "}
                      </p>
                      <p className="bottom-te">
                      Contact us at the{" "}
                        <strong>
                        Dayinsure insurance claims number
                        </strong>
                        to get you back on the road after a traffic accident. We will offer you a replacement car of similar size, make and model as your own vehicle.{" "}
                      </p>
                      <hr />
                      <p className="bold-text">Emergency roadside assistance</p>
                      <p className="bottom-te">
                      We are available 24/7 to offer fast and professional roadside assistance that will enable you to be back on the road as soon as possible. Give us a call on car insurance claim number {" "}<a href="tel:0800 772 0850">0800 772 0850</a> for urgent roadside recovery of your own vehicle.
                      </p>
                      <hr />
                      <p className="bold-text">Expert advice</p>
                      <p className="bottom-te">
                      Our solicitors provide high-quality advice on <strong> Dayinsure insurance claims, </strong> enabling you to receive compensation from the responsible parties for any damages and losses that have been suffered by a no-fault driver.
                      </p>
                      <hr />
                      <p className="bold-text"> Trustworthy and independent </p>
                      <p className="bottom-te">
                      As an independent accident management firm, we assure clients of impartiality throughout the process of making{" "}
                        <strong> Dayinsure car insurance claims.</strong>
                      </p>
                      <hr />
                      <p className="bold-text"> Rapid claim settlement </p>
                      <p className="bottom-te">
                      You can solve your{" "}
                        <strong> Dayinsure car insurance claims </strong>
                        quickly and return back to normal with our simple claims process, which does not cause stress.{" "}
                      </p>
                      <hr />
                      <p className="bold-text">Free Consultation</p>
                      <p className="bottom-te">
                      In order to make informed choices without financial obligations, our solicitors are always willing to give free advice regarding options and rights available in this matter.{" "}
                      </p>
                      <hr />

                      <p className="bold-text">Experienced claim handler</p>
                      <p className="bottom-te">
                      You will be given a distinct claims handler who will communicate everything to you and guide you on the choices for a{" "}<strong> Dayinsure car insurance claim.</strong>
                      </p>
                    </div>
                  </div>
                </div>
                <div className="cl4" ref={sidebarRef}>
                  <Sidebarinner />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="photographs">
        <div className="container">
          <div className="row">
            <h2 className="road">
            We take away the stress of dealing with the {" "}
              <span className="light">
              consequences <br />
                 of a car accident
              </span>{" "}
            </h2>
            <div className="col-xxl-6 col-xl-6 col-md-12 col-12">
              <div className="fourbox">
                <ul>
                  <li>
                    <img src={check} className="check" alt="" />
                    From immediate notification of a claim through the provision of replacement vehicles to arranging for repairs of high quality, we shall assist you with everything.
                  </li>
                  <hr />
                  <li>
                    <img src={check} className="check" alt="" />
                    If your vehicle is not drivable, in case of an accident, please contact us at the {" "}
                    <strong> Dayinsure insurance claim number, </strong>and we will quickly arrange recovery.
                  </li>
                  <hr />
                  <li>
                    <img src={check} className="check" alt="" />
                    We take care of initiating Dayinsure car insurance claims as well as getting you back on track to recovery.
                  </li>
                  <hr />
                  <li>
                    <img src={check} className="check" alt="" />
                    Contact us via our <strong> Dayinsure car insurance claim number, </strong>and we will give you an equivalent replacement vehicle while yours is being fixed.
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-xxl-6 col-xl-6 col-md-12 col-12">
              <div className="fourbox">
                <ul>
                  <li>
                    <img src={check} className="check" alt="" />
                    We shall provide you with experienced solicitors who can handle your case efficiently.
                  </li>
                  <hr />
                  <li>
                    <img src={check} className="check" alt="" />
                    Our team works with all stakeholders involved in your <strong> Dayinsure motor claims, </strong> including insurers, garages and solicitors.
                  </li>
                  <hr />
                  <li>
                    <img src={check} className="check" alt="" />
                    We assist you in recovering all costs and losses from the faulty party’s insurer.
                  </li>
                  <hr />
                  <li>
                    <img src={check} className="check" alt="" />
                    Our dedicated claims handler is available throughout the entire process of making a <strong> Dayinsure car insurance claim </strong>to offer tailor-made support and guidance.
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="carprocess">
        <div className="container ">
          <div className="row">
            <div className="col-xxl-12 col-xxl-12 col-md-12 col-12">
              <h2 className="trusted-title">
                {" "}
                Our wide range of accident  <br />
                <span className="light">management services</span>{" "}
              </h2>
            </div>
          </div>
          <div className="row" id="possible">
            <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-12">
              <div className="claim">
                <ul>
                  <li>
                    <span className="sleej">Vehicle recovery:</span>If you are stuck somewhere by the road with an undrivable car, get in touch with our team using our <strong> Dayinsure car insurance claim number, </strong>and we will send out recovery agents to you immediately for vehicle recovery without any excess on your policy.
                  </li>
                  <li>
                    <span className="sleej">Car repairs:</span>Our accident repair centres are skilled in a variety of vehicle repairs. Thus, we are trustworthy when it comes to genuine parts repairs.
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-12">
              <div className="claim" id="blue">
                <ul>
                  <li>
                    <span className="sleej">Vehicle storage:</span> As soon as you ring us at the{" "}
                    <strong> Dayinsure insurance claim number, </strong>we will take back your car and put it up in our storage yards until more investigation is done. Our CCTV-monitored facilities provide a secure place to store your vehicle to avoid any further harm from taking place. 
                  </li>
                  <li>
                    <span className="sleej">Replacement vehicle:</span>Accidents can interrupt your life due to lack of transport means. We can give you back hope by giving you what is like-for-like replacement car like yours. If you want a replacement car, call us on the<strong> Dayinsure car insurance claim number </strong>so that we may provide you with a comparable replacement vehicle.
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-12">
              <div className="claim">
                <ul>
                  <li>
                    <span className="sleej">Personal injury assistance:</span>
                    Were you injured by the accident? Let our experts help you get compensated properly for injuries and losses suffered. Call us now at the
                    <strong> Dayinsure insurance claim number </strong>to discuss your situation with our solicitors.
                  </li>
                  <li>
                    <span className="sleej">Claims management:</span>We are always there for you, right from the time when your claim starts until it ends. We handle all procedures involved in{" "}
                    <strong> Dayinsure car insurance claims </strong>in order to ensure that you get your money as fast as possible and also to make sure that every step is done correctly and professionally.
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="yellowcta">
        <div className="container">
          <div className="row">
            <div className="cta2">
              <div className="col-xxl-8 col-xl-8 col-lg-8 col-12">
                <div className="legal">
                  <h3 className="experienced">
                  Got more questions?{" "}
                    <span className="light">Talk to our specialists</span>{" "}
                  </h3>
                  <p className="professionals">
                  Get in touch with our no-fault claim experts via{" "}
                    <strong>
                    car insurance claim number{" "}
                      <a className="cpt-no" href="tel:0800 772 0850">
                        {" "}
                        0800 772 0850{" "}
                      </a>{" "}
                    </strong>
                    to get the best advice regarding{" "}
                    <strong> Dayinsure insurance claims. </strong>
                  </p>
                </div>
              </div>
              <div className="col-xxl-4 col-xl-4 col-lg-4 col-12">
                <div class="process">
                  <a class="tf-button style-1 phone" href="tel:0800 772 0850">
                    Get in touch<span class="icon-keyboard_arrow_right"></span>
                  </a>
                </div>
                <div class="process">
                    <a className="tf-button style-1 desktop"  onClick={handleScrollToSidebar}>
                        Get in touch<span className="icon-keyboard_arrow_right"></span>
                    </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <search className="updatedform">
        <div className="container">
          <div className="row text-center">
            <div className="col-xxl-12 col-xl-12 col-md-12 col-12">
              <h2 className="whiteheading">
                {" "}
                Why do we stand out  <br />
                <span className="light"> from others? </span>
              </h2>
              <p className="sustained">
              We’re your trusted partners throughout the{" "}<strong> Dayinsure car insurance claim </strong>process, handling every aspect of the post-accident journey to ensure the best outcome for <strong> Dayinsure motor claims. </strong>Throughout that post-crash journey, we are with you all the way as your reliable partners in <strong> Dayinsure car insurance claims. </strong>
              </p>
            </div>
          </div>
          <div className="row">
            <div className="col-xxl-4 col-xl-4 col-lg-6 col-md-12 col-12">
              <div className="Claimant">
                <h5> Round-the-clock assistance </h5>
                <p className="collision">
                The time when accidents can happen is unforeseen. Hence, we are available 24/7 to offer immediate roadside help after a no-fault accident happens.
                </p>

                <h5>Stress-free claims management</h5>
                <p className="collision">
                If you need assistance, call us at{" "}
                  <strong> <a href="tel:0800 772 0850">0800 772 0850</a> </strong>now. Starting from scratch to your claim settlement, we will handle your claim and take care of its intricacies.{" "}
                </p>

                <h5>No insurance excess</h5>
                <p className="collision">
                With us, you don’t pay any insurance excess because we won't claim against your own policy.
                </p>
              </div>
            </div>
            <div className="col-xxl-4 col-xl-4 col-lg-6 col-md-12 col-12">
              <div className="Claimant">
                <h5> Quality and guaranteed repairs </h5>
                <p className="collision">
                In order to have a real vehicle repair, contact us at <strong> Dayinsure car insurance claim number </strong>for genuine vehicle repairs.
                </p>
                <h5>Expert claim management</h5>
                <p className="collision">
                Instead of trying to deal with calls, paperwork and decision-making, our dedicated claims handler would relieve you from all these responsibilities, saving you precious time to get well soon.
                </p>

                <h5>Top solicitors</h5>
                <p className="collision">
                To ensure professional and quick handling of your claim, we have partnered with skilled solicitors who have extensive experience and a proven record of dealing with no-fault accident claims.{" "}
                </p>
              </div>
            </div>
            <div className="col-xxl-4 col-xl-4 col-lg-12 col-md-12 col-12">
              <div className="Claimant">
                <h5>Safe storage</h5>
                <p className="collision">
                Your car will be kept safe in our secure storage yards until repairs or claim assessment.
                </p>

                <h5>Like-for-like replacement vehicle </h5>
                <p className="collision">
                We recognise the importance of a replacement vehicle after an auto accident. Just call us on the{" "} <strong> Dayinsure insurance claim number,</strong>and we will give you a temporary replacement car for your uninterrupted movement.
                </p>

                <h5>Quick response time</h5>
                <p className="collision">
                When it is most needed, we are there for you. Do not worry; with our quick response time, you can be sure that emergency assistance is just one call away.
                </p>
              </div>
            </div>
          </div>
        </div>
      </search>

      <section className="Accident-claim">
        <div className="container">
          <div className="row">
            <div className="needed">
              <h2 className="trusted-title">
              How can you maximise compensation for your  <br />
                <span className="light"> Dayinsure insurance claims?</span>
              </h2>
              <p className="complexities">
              Road traffic accidents could completely change your life as well as lead to high levels of stress. The first step towards recovery is seeking reasonable compensation for your  <strong> Dayinsure motor claims. </strong>{" "}Below are some steps to take after a no-fault car accident in order to maximise your reimbursement.{" "}
              </p>
            </div>
            <hr />
          </div>
          <div className="row" id="incdes">
            <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-12">
              <div className="Preparing">
                <ul>
                  <li>
                    <div className="seemingly">
                      <h5 className="Seek">Get medical help:</h5>
                      <p>
                        {" "}
                        Immediately check yourself and your passengers after such accidents for injuries and get them treated.{" "}
                      </p>
                    </div>
                  </li>
                  <li>
                    <div className="seemingly">
                      <h5 className="Seek">Document the losses: </h5>
                      <p>
                      Make sure to keep track of all your bills like prescriptions, medical charges, transportation costs and other receipts that came about from the car crash.
                      </p>
                    </div>
                  </li>
                  <li>
                    <div className="seemingly">
                      <h5 className="Seek">Say no to a quick settlement:</h5>
                      <p>
                      The insurance firm may decide to offer low amounts of settlement as soon as talks start. Do not be in a hurry to take the initial settlement offer since it may not fully compensate you for your losses and injuries.
                      </p>
                    </div>
                  </li>
                  <li>
                    <div className="seemingly">
                      <h5 className="Seek">Avoid posting anything on social media</h5>
                      <p>
                      Do not post anything at all on social media. The other party's solicitor can use your social media posts against you when bargaining for compensation.
                      </p>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
            <div
              className="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-12"
              id="dualcardiv"
            >
              <img src={dualcar} alt="" className="dualcar" />
            </div>
            <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-12">
              <div className="Preparing">
                <ul>
                  <li>
                    <div className="seemingly">
                      <h5 className="Seek">Take photos and make videos:</h5>
                      <p>
                      Pictures or videos should be taken of the accident scene, including vehicle positions, road signs, skid marks or any visible damages. These pictures will be useful in proving your claim.{" "}
                      </p>
                    </div>
                  </li>
                  <li>
                    <div className="seemingly">
                      <h5 className="Seek"> Contact eyewitnesses </h5>
                      <p>
                      Meet with eyewitnesses and get their statements. Their statements could help bolster your case.
                      </p>
                    </div>
                  </li>
                  <li>
                    <div className="seemingly">
                      <h5 className="Seek">Seek help from a claims solicitor:</h5>
                      <p>
                      To recover maximum compensation for your{" "} <strong> Dayinsure car insurance claim, </strong> hire our no-fault claims solicitor. Call the <strong> Dayinsure car insurance claim number </strong> for free advice from our qualified solicitors.{" "}
                      </p>
                    </div>
                  </li>
                  <li>
                    <div className="seemingly">
                      <h5 className="Seek"> Begin your claim </h5>
                      <p>
                      Once you have acquired enough evidence and sorted the relevant documents, you can contact us with our{" "} <strong>  Dayinsure car insurance claim number </strong> to make a no-fault claim.
                      </p>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="whyus">
        <div className="container">
          <div className="row">
            <div className="col-xxl-12 col-md-12">
              <div className="why">
                <h2 className="choose">
                Four simple steps to make {" "}
                  <span className="light">Dayinsure motor claims</span>
                </h2>
                <p className="complexities">
                While car accidents could be daunting, making a no-fault road accident claim should not add to your strain. You can file your{" "} <strong> Dayinsure car insurance claim </strong>using our simple process. To start it off, call us on{" "}
                  <strong>
                    <a href="tel:0800 772 0850">0800 772 0850</a>.
                  </strong>{" "}
                </p>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-xxl-3 col-xl-6 col-lg-6 col-md-6 col-12">
              <div className="box-service">
                <img src={check} className="check" alt="" />
                {/* <img src={experience} className="experience" alt="" /> */}
                <h4>Contact us</h4>
                <p>
                Call them now on their{" "} <strong> Dayinsure insurance claims number </strong>after an accident, and we will give you emergency assistance.
                </p>
              </div>
            </div>
            <div className="col-xxl-3 col-xl-6 col-lg-6 col-md-6 col-12">
              <div className="box-service">
                <img src={check} className="check" alt="" />
                <h4>Give details of the accident</h4>
                <p>
                Share the particulars of the mishap while being ready with your policy number when you talk to one of our solicitors.
                </p>
              </div>
            </div>
            <div className="col-xxl-3 col-xl-6 col-lg-6 col-md-6 col-12">
              <div className="box-service">
                <img src={check} className="check" alt="" />
                <h4>Attach supporting documents</h4>
                <p>
                Submit all the necessary information in the claims form and supply evidence and documents that will support your assertions.{" "}
                </p>
              </div>
            </div>
            <div className="col-xxl-3 col-xl-6 col-lg-6 col-md-6 col-12">
              <div className="box-service">
                <img src={check} className="check" alt="" />
                <h4>Wait for compensation</h4>
                <p>
                Upon initiation of your claim,  expect to receive payment for damages soon. {" "}
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="faqs">
        <div className="container">
          <div className="row">
            <div className="col-xxl-12 col-xl-12 col-md-12 col-12">
              <div className="fq">
                <h2>
                  {" "}
                  Frequently Asked{" "}
                  <span className="light">Questions (FAQ)</span>
                </h2>
                <p className="complexities">
                Got questions about the{" "} <strong> Dayinsure car insurance claim? </strong>We have them answered here.
                </p>
              </div>
              <div className="allfaq">
                <Accordion defaultActiveKey="0">
                  <Accordion.Item eventKey="0">
                    <Accordion.Header>
                    Are there any charges involved in your accident management services?
                    </Accordion.Header>
                    <Accordion.Body>
                    No, if it was not your fault, we may reclaim all costs from the other driver’s insurer, and there will be no extra expenses on your part.
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="1">
                    <Accordion.Header>
                    Can you fix my car after it has been involved in an accident?
                    </Accordion.Header>
                    <Accordion.Body>
                    Yes. Call the <strong> Dayinsure car insurance claim number, </strong>and we will arrange for your car to be repaired at our trust repairs network.
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="2">
                    <Accordion.Header>
                    I have been in a car accident. What should I do next?
                    </Accordion.Header>
                    <Accordion.Body>
                    Please call us on the <strong> Dayinsure insurance claims number </strong> for emergency assistance. Our team will be there immediately to assist you at the scene of the accident.
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="3">
                    <Accordion.Header>
                    Can you arrange for my vehicle’s repair from my own chosen repairer?{" "}
                    </Accordion.Header>
                    <Accordion.Body>
                    Yes. Provide us with details of your preferred repairer so that we can establish contact and quickly organise best quality repairs for your vehicle through your chosen garage
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="4">
                    <Accordion.Header>
                    What happens if my vehicle is written off?
                    </Accordion.Header>
                    <Accordion.Body>
                    We shall talk to the other person’s insurer to discuss issues of compensation on a fair-market-value basis plus replacement costs. Additionally, we will give you a replacement vehicle until a settlement is made.
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="5">
                    <Accordion.Header>
                    What should I do if somebody from the other side contacts me after the accident?{" "}
                    </Accordion.Header>
                    <Accordion.Body>
                    If you select us as your claim provider, forward us the calls from the other party, their insurers or solicitors. We will handle them on your behalf.
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="6">
                    <Accordion.Header>
                    Who will deal with my claim?{" "}
                    </Accordion.Header>
                    <Accordion.Body>
                    Our team will take all information about how it happened and then connect you with our competent solicitors who specialise in <strong> Dayinsure insurance claims </strong>and will effectively administer it from start to finish.
                    </Accordion.Body>
                  </Accordion.Item>
                </Accordion>
              </div>
            </div>
          </div>
        </div>
      </section>

      <Footer />
    </>
  );
};

export default Dayinsure;
