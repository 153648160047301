import React, { useRef } from "react";
import Accordion from "react-bootstrap/Accordion";
import check from "../assets/images/check.png";
import dualcar from "../../src/assets/images/dualcar.webp";
import carbnr1 from "../../src/assets/images/carbnr1.webp";
import Sidebarinner from "../components/Sidebar-inner";
import phonecall from "../assets/images/phonecall.png";
import Footer from "../components/footer";
import { Helmet } from "react-helmet";

const Toyotacarinsurance = () => {
    const sidebarRef = useRef(null);

    const handleScrollToSidebar = () => {
        if (sidebarRef.current) {
            sidebarRef.current.scrollIntoView({ behavior: "smooth" });
        }
    };
    return (
        <>
        <Helmet>
        <meta charSet="utf-8" />
        <title>Toyota Car Insurance Accident Claim Phone Number</title>
        <meta
          name="description"
          content="Contact the Toyota Car Insurance Accident Claim Phone Number 0800 772 0850 to understand your rights and options for making a successful no-fault accident claim."
        />
        <link
          rel="canonical"
          href="https://insurance-accident-claims.co.uk/Toyota-car-insurance"
        />
      </Helmet>
            <section className="innernav">
                <div className="container">
                    <div className="row">
                        <div className="col-md-6 col-sm-6">
                            <div className="left-logo">
                                <h3>
                                    Accident<span className="sipo"> Claims</span>
                                </h3>
                            </div>
                        </div>
                        <div className="col-md-6 col-sm-6">
                            <div className="left-cta">
                                <div class="headercnt">
                                    <div class="chatmsg">
                                        <img
                                            src={phonecall}
                                            className="phonecall"
                                            alt="phonecall "
                                        />
                                    </div>
                                    <div class="box-body">
                                        <h3 class="nav-info-box-title">Toll Free Number </h3>
                                        <p>
                                            <a href="tel:0800 772 0850">0800 772 0850</a>
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section
                className="newbnr"
                style={{ backgroundImage: "url(" + carbnr1 + ")" }}
            >
                <div className="container">
                    <div className="row">
                        <div className="col-xxl-7 col-xl-7 col-md-10 col-12">
                            <div className="innerbnr">
                                <h1>
                                    <span className="onespan"> No-fault accident</span>{" "}
                                    <span className="sci"> claims   </span> management
                                </h1>
                                <p className="brokerage">
                                    From the initial assessment to the finalisation of your claim, we will manage everything so that you can be back on the road as soon as possible.
                                </p>
                                <div className="bnrbtn">
                                    <a class="db-btn-border btn-rollover phone" href="tel:0800 772 0850">
                                        Road Accident Claim Help?{" "}
                                        <i class="fa-solid fa-arrow-right"></i>
                                    </a>
                                </div>
                                <div className="bnrbtn">
                                    <a className="db-btn-border btn-rollover desktop" onClick={handleScrollToSidebar}>
                                        Road Accident Claim Help? <i className="fa-solid fa-arrow-right"></i>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="stickysection">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="group-4-8">
                                <div className="cl8 tf-tab">
                                    <div className="content-tab">
                                        <div className="inner-content">
                                            <h2>
                                                UK’s Leading Insurance Claims Management

                                                <span className="light"> Experts</span>{" "}
                                            </h2>
                                            <p className="disciplines">
                                                We are among the leading companies that offer assistance in regard to <strong> Toyota insurance claims </strong>  in the UK, having been able to handle thousands of cases. Call us through the <strong> Toyota car insurance contact number </strong> for claims. We will handle your <strong> Toyota car insurance claim </strong> free of charge.
                                            </p>
                                            <hr />
                                            <p className="bold-text">Nil Excess</p>
                                            <p className="bottom-te">
                                                To prevent any increase in premiums, if you did not cause the accident, we won't charge any excess.                                       </p>
                                            <hr />
                                            <p className="bold-text">NCB protection</p>
                                            <p className="bottom-te">
                                                You don’t have to worry about losing your NCB when claiming with us. We assure you that our customers’ no-claims bonus is unaffected when you make a claim with us.                                  </p>
                                            <hr />
                                            <p className="bold-text">
                                                Rapid vehicle recovery
                                            </p>
                                            <p className="bottom-te">
                                                We don’t wish you to be stranded helpless after an accident or crash. Therefore, immediately dial the<strong> Toyota car insurance contact number, </strong> and we will quickly and safely recover your car wherever it might have become stuck.
                                            </p>
                                            <hr />
                                            <p className="bold-text">Manufacturer-approved repairs</p>
                                            <p className="bottom-te">
                                                Our manufacturers’ approved garages ensure your car is repaired to the highest standards of excellence.
                                            </p>
                                            <hr />
                                            <p className="bold-text">Like-for-like hire</p>
                                            <p className="bottom-te">
                                                Our experienced solicitors have extensive experience in processing <strong> Toyota insurance claims, </strong> resulting in securing deserving compensation.                                          </p>
                                            <hr />
                                            <p className="bold-text">Fast processing of claims
                                            </p>
                                            <p className="bottom-te">
                                                Little paperwork is required, and there are short waiting periods when you choose to make your claim with us. Therefore, you will not encounter any paperwork troubles while filing your claim.                                </p>
                                            <hr />
                                            <p className="bold-text">Free impartial advice</p>
                                            <p className="bottom-te">
                                                For all concerns about <strong> Toyota insurance claims, </strong>you can call our free number,
                                                <strong>
                                                    {" "}
                                                    <a href="tel:0800 772 0850">0800 772 0850</a>{" "}
                                                </strong> ,  without commitment. Oursolicitors will give guidance and support your case on its merits.
                                            </p>
                                            <hr />
                                            <p className="bold-text">No-win, no-fee</p>
                                            <p className="bottom-te">
                                                Our solicitors operate on a no-win-no-fee basis when they handle the insurance
                                                claims for our clients. In other words, if you lose the case, it won’t cost you anything.

                                            </p>
                                            <hr />
                                            <p className="bold-text">24/7 assistance</p>
                                            <p className="bottom-te">
                                            After an accident, we are available around the clock to provide emergency assistance and support. If your car has been involved in a non-fault road traffic accident, please call us for immediate help on 
                                            <strong>
                                            {" "}
                                            <a href="tel:0800 772 0850">0800 772 0850</a>{" "}
                                        </strong>        </p>
                                            <hr />

                                            <p className="bold-text">Professional services</p>
                                            <p className="bottom-te">
                                            We will be glad to help you handle the accident aftermath by offering professional accident management services. In Emergency cases after a car crash, you should get in touch with us through the<strong>  Toyota car insurance phone number.   </strong>                              </p>
                                        </div>
                                    </div>
                                </div>
                                <div className="cl4" ref={sidebarRef}>
                                    <Sidebarinner />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="photographs">
                <div className="container">
                    <div className="row">
                        <h2 className="road">
                        We will minimise your stress and burden {" "}
                            <span className="light">
                            with our <br />
                            dedicated assistance 
                            </span>{" "}
                        </h2>
                        <div className="col-xxl-6 col-xl-6 col-md-12 col-12">
                            <div className="fourbox">
                                <ul>
                                    <li>
                                        <img src={check} className="check" alt="" />
                                        We will determine if there is any party at fault who may be pursued for damages on your behalf.

                                    </li>
                                    <hr />
                                    <li>
                                        <img src={check} className="check" alt="" />
                                        We will support you in filling <strong> Toyota car insurance claims </strong> so that you can seek indemnification for the losses suffered.                                </li>
                                    <hr />
                                    <li>
                                        <img src={check} className="check" alt="" />
                                        Our dedicated team shall take back your vehicle from the scene where it got wrecked and engage with approved repairers to get it fixed.                   </li>
                                    <hr />
                                    <li>
                                        <img src={check} className="check" alt="" />
                                        Whereby your vehicle cannot be repaired, we would ask for a valuation of the damages to be done by an independent engineer.            </li>
                                </ul>
                            </div>
                        </div>
                        <div className="col-xxl-6 col-xl-6 col-md-12 col-12">
                            <div className="fourbox">
                                <ul>
                                    <li>
                                        <img src={check} className="check" alt="" />
                                        A replacement vehicle will be given as your damaged car is being repaired.                           </li>
                                    <hr />
                                    <li>
                                        <img src={check} className="check" alt="" />
                                        Our team monitors your vehicle repairs and keeps you updated about the progress. Call us on
                                        <strong>
                                            {" "}
                                            <a href="tel:0800 772 0850">0800 772 0850</a> {" "}
                                        </strong> if you want to know more about making a<strong> Toyota insurance claim </strong> to receive compensation for damages and losses.
                                                                  </li>
                                    <hr />
                                    <li>
                                        <img src={check} className="check" alt="" />
                                        If you are injured and want to claim, call the <strong> Toyota insurance claims number. </strong> We have a panel of specialist solicitors who can help you with your claim and guide you through the process.                               </li>
                                    <hr />
                                     
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="carprocess">
                <div className="container ">
                    <div className="row">
                        <div className="col-xxl-12 col-xxl-12 col-md-12 col-12">
                            <h2 className="trusted-title">
                                {" "}
                                Our accident management  <br />
                                <span className="light">    services </span>{" "}
                            </h2>
                        </div>
                    </div>
                    <div className="row" id="possible">
                        <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-12">
                            <div className="claim">
                                <ul>
                                    <li>
                                        <span className="sleej">Vehicle recovery  :</span> For an accident-related car haul, call the <strong> Toyota car insurance contact number. </strong>  We will offer rapid removal of your vehicle from the accident scene and placement in a safe place.    

                                    </li>
                                    <li>
                                        <span className="sleej">Approved repairs:</span>If you want your car to be fixed, just contact us on the <strong> Toyota car insurance phone number. </strong>  We will organise fast repairs to be done on your car through our network of approved repairers.
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-12">
                            <div className="claim" id="blue">
                                <ul>
                                    <li>
                                        <span className="sleej">Vehicle storage:</span> Do you worry about where to keep your crashed vehicle? Just talk to our team using the <strong> Toyota car insurance phone number. </strong> We shall park it temporarily at well-kept yards until the settlement of your claim. 
                                    </li>
                                    <li>
                                        <span className="sleej">Replacement vehicle:</span> In case your vehicle cannot be repaired, a substitute car will be provided to you. Call the emergency helpline
                                        <strong>
                                            {" "}
                                            <a href="tel:0800 772 0850">0800 772 0850</a>{" "}
                                        </strong> , and we will give you another vehicle like yours that you can use for everyday purposes without any worries.


                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-12">
                            <div className="claim">
                                <ul>
                                    <li>
                                        <span className="sleej">Personal injury support :</span>
                                        To find out whether you are able to get personal injury compensation, it is best to call the <strong> Toyota insurance claim number. </strong> Our solicitors will give you invaluable tips on how to recover damages for a personal injury             </li>
                                    <li>
                                        <span className="sleej">Claim management :</span> We will be responsible for paperwork, haggling with insurers, organising car repairs and providing replacements.


                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="yellowcta">
                <div className="container">
                    <div className="row">
                        <div className="cta2">
                            <div className="col-xxl-8 col-xl-8 col-lg-8 col-12">
                                <div className="legal">
                                    <h3 className="experienced">
                                        Got More questions? Talk to {" "}
                                        <span className="light">  our specialists
                                        </span>{" "}
                                    </h3>
                                    <p className="professionals">
                                        Get in touch with our no-fault claim experts via <strong> car insurance claim number</strong>
                                        {" "}
                                        <strong>{" "}
                                            <a className="cpt-no" href="tel:0800 772 0850">
                                                {" "}
                                                0800 772 0850 {" "}
                                            </a>{" "}
                                        </strong>
                                        to get the best advice for your
                                        <strong> Toyota car insurance claim.   </strong>
                                    </p>
                                </div>
                            </div>
                            <div className="col-xxl-4 col-xl-4 col-lg-4 col-12">
                                <div class="process">
                                    <a class="tf-button style-1 phone" href="tel:0800 772 0850">
                                        Get in touch<span class="icon-keyboard_arrow_right"></span>
                                    </a>
                                </div>
                                <div class="process">
                                    <a className="tf-button style-1 desktop" onClick={handleScrollToSidebar}>
                                        Get in touch<span className="icon-keyboard_arrow_right"></span>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <search className="updatedform">
                <div className="container">
                    <div className="row text-center">
                        <div className="col-xxl-12 col-xl-12 col-md-12 col-12">
                            <h2 className="whiteheading">
                                {" "}
                                What sets us apart from other
                                <span className="light">  companies?
                                </span>
                            </h2>
                            <p className="sustained">
                            Our main priority is saving you from the stress that comes with a no-fault road accident. Below are some of the advantages that come along when you choose us to handle your claims.                      </p>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-xxl-4 col-xl-4 col-lg-6 col-md-12 col-12">
                            <div className="Claimant">
                                <h5>24/7 vehicle recovery</h5>
                                <p className="collision">
                                Do you need vehicle recovery services? Call the<strong> Toyota insurance claim number,</strong> and we will remove your car from the accident scene at any time.

                                </p>

                                <h5>Guaranteed repairs</h5>
                                <p className="collision">
                                We have partnered with several garages where we can arrange quality repairs for your car.                        </p>

                                <h5>No increase in insurance premium</h5>
                                <p className="collision">
                                The <strong> Toyota car insurance claim </strong> will not be made against your own policy. This means that your insurance premium won't go up.                     </p>
                            </div>
                        </div>
                        <div className="col-xxl-4 col-xl-4 col-lg-6 col-md-12 col-12">
                            <div className="Claimant">
                                <h5>Free replacement car
                                </h5>
                                <p className="collision">
                                We can provide you with a similar car in order to keep you mobile until your own vehicle is repaired. </p>
                                <h5>Reliable partners</h5>
                                <p className="collision">
                                Our firm, therefore, has an extensive network of accredited repairers and competent solicitors across the UK who will serve you well.
                                </p>

                                <h5>Quick claims processing </h5>
                                <p className="collision">
                                Our experienced solicitors will negotiate with the insurer of the other driver responsible for the accident so that your <strong>Toyota motor insurance claim </strong> is resolved amicably and quickly.
                                </p>
                            </div>
                        </div>
                        <div className="col-xxl-4 col-xl-4 col-lg-12 col-md-12 col-12">
                            <div className="Claimant">
                                <h5>Immediate assistance</h5>
                                <p className="collision">
                                In case of any non-fault road traffic accident situation, we guarantee immediate emergency support without delay.
                                </p>

                                <h5>Bespoke help offered</h5>
                                <p className="collision">
                                Your claim is our priority. Thus, we will assign an exclusive claim manager who will personally oversee your matter all through and keep you updated at each stage.
                                </p>

                                <h5>No excess</h5>
                                <p className="collision">
                                There are no additional amounts to be paid or expenses incurred by our services since we shall seek full reimbursement from the at-fault party.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </search>

            <section className="Accident-claim">
                <div className="container">
                    <div className="row">
                        <div className="needed">
                            <h2 className="trusted-title">
                            How can you enhance your chances of getting <br />
                                <span className="light">maximum compensation?  </span>
                            </h2>
                            <p className="complexities">
                            To increase the chances of securing substantial compensation, prove beyond any reasonable doubt that you are innocent by presenting physical evidence. Here are the steps you should take after the accident to increase the chances of securing rightful compensation. 
                            </p>
                        </div>
                        <hr />
                    </div>
                    <div className="row" id="incdes">
                        <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-12">
                            <div className="Preparing">
                                <ul>
                                    <li>
                                        <div className="seemingly">
                                            <h5 className="Seek">Ensure your safety :</h5>
                                            <p>
                                                {" "}
                                                Stop your car at a safe area and check yourself and your fellow passengers for major or minor injuries.                              </p>
                                        </div>
                                    </li>
                                    <li>
                                        <div className="seemingly">
                                            <h5 className="Seek">
                                            Obtain immediate medical attention:{" "}
                                            </h5>
                                            <p>
                                            If anyone gets hurt while inside your vehicle, it is vital to call an experienced doctor as quickly as possible.                             </p>
                                        </div>
                                    </li>
                                    <li>
                                        <div className="seemingly">
                                            <h5 className="Seek">Gather proof:</h5>
                                            <p>
                                            Some of the most important things to be gathered from the accident scene include pictures showing where accidents took place, the party responsible for the accident, damages sustained, serious injuries and number plates of all drivers involved.                                          </p>
                                        </div>
                                    </li>
                                    <li>
                                        <div className="seemingly">
                                            <h5 className="Seek">Report the incident to the police:</h5>
                                            <p>
                                            Call the police and report the accident. The police report may contain vital information to support your<strong>Toyota insurance claim. </strong>                                </p>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div
                            className="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-12"
                            id="dualcardiv"
                        >
                            <img src={dualcar} alt="" className="dualcar" />
                        </div>
                        <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-12">
                            <div className="Preparing">
                                <ul>
                                    <li>
                                        <div className="seemingly">
                                            <h5 className="Seek">Swap information:</h5>
                                            <p>
                                            If possible, exchange details with the other driver, including their full names, phone numbers, physical addresses, insurance details and car registration numbers.                                        </p>
                                        </div>
                                    </li>
                                    <li>
                                        <div className="seemingly">
                                            <h5 className="Seek">Contact  eyewitnesses: </h5>
                                            <p>
                                            It may also be useful to get witnesses’ contact information for future reference purposes.                      </p>
                                        </div>
                                    </li>
                                    <li>
                                        <div className="seemingly">
                                            <h5 className="Seek">
                                            Record expenses:
                                            </h5>
                                            <p> You should keep receipts of all expenses incurred, including repairs and medical treatment, among other costs, to be fully compensated for your losses. 
                                            </p>
                                        </div>
                                    </li>
                                    <li>
                                        <div className="seemingly">
                                            <h5 className="Seek">
                                            Seek advice from a no-fault solicitor  :
                                            </h5>
                                            <p>Reach out to a claim expert to get guidance on how to secure maximum compensation for your damages.  
                                    
                                            </p>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="whyus">
                <div className="container">
                    <div className="row">
                        <div className="col-xxl-12 col-md-12">
                            <div className="why">
                                <h2 className="choose">
                                Initiate your claim with simple and{" "}
                                    <span className="light"> straightforward steps </span>
                                </h2>
                                <p className="complexities">
                                It can be quite scary to make a <strong> Toyota motor insurance claim </strong> after being involved in an accident that was not their fault.This is why we are here to help with this process. Call us on the <strong> Toyota car insurance phone number </strong> to start your claim process.                                   </p>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-xxl-3 col-xl-6 col-lg-6 col-md-6 col-12">
                            <div className="box-service">
                                <img src={check} className="check" alt="" />
                                {/* <img src={experience} className="experience" alt="" /> */}
                                <h4>Reach out to us</h4>
                                <p>
                                Please give our team a call on the <strong> Toyota car insurance contact number </strong>  regarding your claim. </p>
                            </div>
                        </div>
                        <div className="col-xxl-3 col-xl-6 col-lg-6 col-md-6 col-12">
                            <div className="box-service">
                                <img src={check} className="check" alt="" />
                                <h4>Give information
                                </h4>
                                <p>
                                The details of the accident, such as date, time, location and how it happened should be given.</p>
                            </div>
                        </div>
                        <div className="col-xxl-3 col-xl-6 col-lg-6 col-md-6 col-12">
                            <div className="box-service">
                                <img src={check} className="check" alt="" />
                                <h4>Show proof</h4>
                                <p>
                                Submit some evidence which will back up your case, such as photographs of the incident scene, statements made by witnesses, police reports and CCTV footage.
                                </p>
                            </div>
                        </div>
                        <div className="col-xxl-3 col-xl-6 col-lg-6 col-md-6 col-12">
                            <div className="box-service">
                                <img src={check} className="check" alt="" />
                                <h4>Claim processing </h4>
                                <p>
                                We will start processing your claims after evaluation. We will frequently update you on what is occurring behind the scenes.

                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="faqs">
                <div className="container">
                    <div className="row">
                        <div className="col-xxl-12 col-xl-12 col-md-12 col-12">
                            <div className="fq">
                                <h2>
                                    {" "}
                                    Frequently Asked{" "}
                                    <span className="light">Questions</span>
                                </h2>
                                <p className="complexities">
                                    Got questions about the <strong> Toyota car insurance claim? </strong> We have them answered here.
                                </p>
                            </div>
                            <div className="allfaq">
                                <Accordion defaultActiveKey="0">
                                    <Accordion.Item eventKey="0">
                                        <Accordion.Header>
                                        Do I have to pay extra fees for a courtesy car?                         </Accordion.Header>
                                        <Accordion.Body>
                                        No, all expenses incurred in replacing your vehicle shall be charged to the insurer of the offending party. You just need to pay if you do not cooperate with us, use solicitors without our knowledge or give false information regarding your case.
                                        </Accordion.Body>
                                    </Accordion.Item>
                                    <Accordion.Item eventKey="1">
                                        <Accordion.Header>
                                        What is the waiting period for my claim?
                                        </Accordion.Header>
                                        <Accordion.Body>
                                        We will try our best to resolve your <strong> Toyota car insurance claim </strong> as fast as possible, but the time it takes to settle a claim will depend on the specifics of your case. We will keep you posted on any developments once we initiate your claim.            </Accordion.Body>
                                    </Accordion.Item>
                                    <Accordion.Item eventKey="2">
                                        <Accordion.Header>
                                        Does the at-fault party’s insurance company cover vehicle recovery costs?
                                        </Accordion.Header>
                                        <Accordion.Body>
                                        Yes, recovery costs are paid by an at-fault party’s insurance company. You may need evidence, such as an occurrence report or photos taken from the place showing that you should be compensated.                 </Accordion.Body>
                                    </Accordion.Item>
                                    <Accordion.Item eventKey="3">
                                        <Accordion.Header>
                                        Can I have a credit hire following an accident that isn’t my fault?
                                        </Accordion.Header>
                                        <Accordion.Body>
                                        Yes. You qualify for credit hire as long as you are not at fault for the collision. Whenever you require a replacement vehicle because yours has been written off or is under repair, kindly call us on the <strong> Toyota car insurance contact number.</strong>  We will give you another car of your type until yours gets back on the road.                              </Accordion.Body>
                                    </Accordion.Item>
                                    <Accordion.Item eventKey="4">
                                        <Accordion.Header>
                                        Would I lose my no-claims bonus if the accident wasn’t my fault?

                                        </Accordion.Header>
                                        <Accordion.Body>
                                        If you file your claim with us, we won’t put in a claim against your policy, meaning there is no danger of losing any year's worth of bonuses or discounts which had already accumulated.   </Accordion.Body>
                                    </Accordion.Item>
                                    <Accordion.Item eventKey="5">
                                        <Accordion.Header>
                                        Can I use my own garage to fix the car?{" "}
                                        </Accordion.Header>
                                        <Accordion.Body>
                                        Certainly! We can arrange to have your car fixed at a garage of your choice. We will coordinate with the repairer so that your vehicle repairs are done swiftly.
                                        </Accordion.Body>
                                    </Accordion.Item>
                                    <Accordion.Item eventKey="6">
                                        <Accordion.Header>
                                        What car will I get as a replacement for mine?{" "}
                                        </Accordion.Header>
                                        <Accordion.Body>
                                        We shall find an alternative vehicle corresponding to the size, cost and functionality of your own car. Get in touch with us using our <strong> Toyota insurance claim number </strong>  and tell us what you need so that we can provide you with a suitable vehicle. 
                                        </Accordion.Body>
                                    </Accordion.Item>
                                </Accordion>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <Footer />
        </>
    );
};

export default Toyotacarinsurance;
