import React, { useRef } from "react";
import Accordion from "react-bootstrap/Accordion";
import check from "../assets/images/check.png";
import dualcar from "../../src/assets/images/dualcar.webp";
import carbnr1 from "../../src/assets/images/carbnr1.webp";
import Sidebarinner from "../components/Sidebar-inner";
import phonecall from "../assets/images/phonecall.png";
import Footer from "../components/footer";
import { Helmet } from "react-helmet";

const Acorninsure = () => {
  const sidebarRef = useRef(null);

  const handleScrollToSidebar = () => {
    if (sidebarRef.current) {
      sidebarRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Acorn Car Insurance Accident Claim Phone Number</title>
        <meta
          name="description"
          content="Need professional assistance in initiating a no-fault car accident claim? Give us a call at the Acorn Car Insurance Accident Claim Phone Number  0800 772 0850. "
        />
        <link
          rel="canonical"
          href="https://insurance-accident-claims.co.uk/acorn-car-insurance"
        />
      </Helmet>
      <section className="innernav">
        <div className="container">
          <div className="row">
            <div className="col-md-6 col-sm-6">
              <div className="left-logo">
                <h3>
                  Accident<span className="sipo"> Claims</span>
                </h3>
              </div>
            </div>
            <div className="col-md-6 col-sm-6">
              <div className="left-cta">
                <div class="headercnt">
                  <div class="chatmsg">
                    <img
                      src={phonecall}
                      className="phonecall"
                      alt="phonecall "
                    />
                  </div>
                  <div class="box-body">
                    <h3 class="nav-info-box-title">Toll Free Number </h3>
                    <p>
                      <a href="tel:0800 772 0850">0800 772 0850</a>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section
        className="newbnr"
        style={{ backgroundImage: "url(" + carbnr1 + ")" }}
      >
        <div className="container">
          <div className="row">
            <div className="col-xxl-8 col-xl-8 col-md-10 col-12">
              <div className="innerbnr">
                <h1>
                  <span className="onespan">Expert car</span>{" "}
                  <span className="sci">accident insurance claim </span> services in
                  the UK
                </h1>
                <p className="brokerage">
                  For a stress-free life after a non-fault car accident, we
                  offer accident management and claims support.
                </p>
                <div className="bnrbtn">
                  <a
                    class="db-btn-border btn-rollover phone"
                    href="tel:0800 772 0850"
                  >
                    Road Accident Claim Help?{" "}
                    <i class="fa-solid fa-arrow-right"></i>
                  </a>
                </div>
                <div className="bnrbtn">
                  <a
                    className="db-btn-border btn-rollover desktop"
                    onClick={handleScrollToSidebar}
                  >
                    Road Accident Claim Help?{" "}
                    <i className="fa-solid fa-arrow-right"></i>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="stickysection">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="group-4-8">
                <div className="cl8 tf-tab">
                  <div className="content-tab">
                    <div className="inner-content">
                      <h2>
                        Excess-Free claims
                        <br />
                        <span className="light">handling </span>{" "}
                      </h2>
                      <p className="disciplines">
                        We are specialists at helping no-fault drivers get back
                        on track with our all-inclusive accident management
                        service and claims assistance. We will save you time and
                        manage your Acorn car insurance claim from start to
                        finish. For professional advice on a no-win no-fee
                        basis, contact us at{" "}
                        <a href="tel:0800 772 0850">0800 772 0850</a>
                      </p>
                      <hr />
                      <p className="bold-text">No excess</p>
                      <p className="bottom-te">
                        The expenses for our services will be met by the fault
                        party’s insurers. So, you do not need to worry about
                        paying any extra amount.{" "}
                      </p>
                      <hr />
                      <p className="bold-text">No change in NCB</p>
                      <p className="bottom-te">
                        Your NCB (no claims bonus) remains intact as the claim
                        is not made against your own insurance policy.
                      </p>
                      <hr />
                      <p className="bold-text">In-depth expertise</p>
                      <p className="bottom-te">
                        We are focused on no-win, no-fee claims, unlike most
                        insurers. Our solicitors work hard so that you may have
                        maximum compensation for your case.
                      </p>
                      <hr />
                      <p className="bold-text">End-to-end services </p>
                      <p className="bottom-te">
                        We have you covered in each and every stage, from
                        helping you deal with complications associated with your{" "}
                        <strong>Acorn insurance claim </strong>
                        to providing emergency assistance to get you back on the
                        road.{" "}
                      </p>
                      <hr />
                      <p className="bold-text">No win, No fee services</p>
                      <p className="bottom-te">
                        If your <strong> Acorn car insurance claim </strong>{" "}
                        fails, you are under no obligation to pay any legal
                        costs.
                      </p>
                      <hr />
                      <p className="bold-text">Friendly and supportive team</p>
                      <p className="bottom-te">
                        When you call us on the{" "}
                        <strong> Acorn car insurance claim number, </strong>our
                        friendly team will guide you through the steps of making
                        a successful <strong> Acorn insurance claim. </strong>
                      </p>
                      <hr />
                      <p className="bold-text"> Expert claim handler </p>
                      <p className="bottom-te">
                        We shall allocate an experienced claims handler who will
                        endeavour to offer an excellent claims solution for you
                        and seek full recovery of all costs from the at-fault
                        driver without any struggle.
                      </p>
                      <hr />
                      <p className="bold-text">Professional advice</p>
                      <p className="bottom-te">
                        We can help make an{" "}
                        <strong>Acorn car insurance claim </strong>
                        by giving free no-obligation advice from solicitors to
                        help inform you about what your options are and what
                        rights are available to you.{" "}
                      </p>
                      <hr />
                      <p className="bold-text">Quality vehicle repairs</p>
                      <p className="bottom-te">
                        Call us on the{" "}
                        <strong> Acorn insurance claim phone number </strong>and
                        schedule quality repairs for your vehicle. Our wide
                        network of authorised repairers specialises in
                        guaranteed fix-up using original paints and parts.
                      </p>
                      <hr />

                      <p className="bold-text">
                        Like-for-like replacement vehicle
                      </p>
                      <p className="bottom-te">
                        A like-for-like replacement vehicle will be provided
                        during the time your car is being repaired. It’ll be in
                        the same class as yours, of the same model and brand.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="cl4" ref={sidebarRef}>
                  <Sidebarinner />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="photographs">
        <div className="container">
          <div className="row">
            <h2 className="road">
              We will handle all the complexities of{" "}
              <span className="light">
                your <br />
                claim while you recover
              </span>{" "}
            </h2>
            <div className="col-xxl-6 col-xl-6 col-md-12 col-12">
              <div className="fourbox">
                <ul>
                  <li>
                    <img src={check} className="check" alt="" />
                    From assessing damages to bargaining with insurers and
                    running your claim, from its inception to conclusion, we’ve
                    got your back.
                  </li>
                  <hr />
                  <li>
                    <img src={check} className="check" alt="" />
                    When you dial{" "}
                    <strong> the Acorn insurance claim phone number, </strong>
                    our dedicated team will understand your needs and find a
                    top-quality replacement vehicle to hit the road again.
                  </li>
                  <hr />
                  <li>
                    <img src={check} className="check" alt="" />
                    We are able to organise high-quality repairs for your car
                    from manufacturer-approved repairers.
                  </li>
                  <hr />
                  <li>
                    <img src={check} className="check" alt="" />
                    If necessary, we may communicate with multiple parties
                    involved in an accident so that you focus on other important
                    things.
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-xxl-6 col-xl-6 col-md-12 col-12">
              <div className="fourbox">
                <ul>
                  <li>
                    <img src={check} className="check" alt="" />
                    We work with a pool of specialist solicitors who specialise
                    in non-fault claims relating to car accidents. So whenever
                    you use the{" "}
                    <strong>
                      {" "}
                      Acorn car insurance claim phone number,{" "}
                    </strong>{" "}
                    feel confident of getting additional advice as well as
                    guidance regarding your claim.
                  </li>
                  <hr />
                  <li>
                    <img src={check} className="check" alt="" />
                    An independent engineer will be arranged for vehicle
                    examination.
                  </li>
                  <hr />
                  <li>
                    <img src={check} className="check" alt="" />
                    Call the <strong>
                      {" "}
                      Acorn insurance claim number,{" "}
                    </strong>{" "}
                    and we will make arrangements to collect your damaged
                    vehicle and have it delivered at a later time.
                  </li>
                  <hr />
                  <li>
                    <img src={check} className="check" alt="" />
                    We shall give you full support to help retrieve all your
                    expenses which were incurred from the insurer of the
                    at-fault party.
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="carprocess">
        <div className="container ">
          <div className="row">
            <div className="col-xxl-12 col-xxl-12 col-md-12 col-12">
              <h2 className="trusted-title">
                {" "}
                Our wide range of accident <br />
                <span className="light">management solutions</span>{" "}
              </h2>
            </div>
          </div>
          <div className="row" id="possible">
            <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-12">
              <div className="claim">
                <ul>
                  <li>
                    <span className="sleej">Vehicle recovery :</span>Has your
                    car become immobilised as a result of an accident? Just get
                    in touch with us through the{" "}
                    <strong> Acorn insurance claim phone number, </strong> and
                    we will help you recover it quickly. Our recovery agents
                    will take your car from where it was involved in an accident
                    and bring it to a protected location.
                  </li>
                  <li>
                    <span className="sleej">Approved repairs:</span>Contact our
                    team on <a href="tel:0800 772 0850">0800 772 0850</a> for
                    immediate repairs. We have manufacturer-approved repairers
                    who will repair your vehicle and take it back to its
                    original appearance before the crash.
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-12">
              <div className="claim" id="blue">
                <ul>
                  <li>
                    <span className="sleej">Secured storage:</span> If your car
                    cannot be driven, leaving it on the side of the road or
                    outside your residence may not be secure. Your vehicle can
                    be put into our storage yards so that you do not worry about
                    further damage to your vehicle. Call us through our
                    toll-free line anytime on{" "}
                    <a href="tel:0800 772 0850">0800 772 0850 </a> for safe
                    storage assistance for damaged vehicles.
                  </li>
                  <li>
                    <span className="sleej">Replacement vehicle:</span>We know
                    how it feels to be without a car. Our dedicated team will
                    get you moving again with a like-for-like replacement
                    vehicle on the same day. This way, your vehicle will be
                    replaced until it is repaired or you get reimbursed through{" "}
                    <strong> Acorn car insurance claim. </strong>All costs and
                    expenses associated with the replacement vehicle will be
                    recovered from the insurer of the at-fault party.
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-12">
              <div className="claim">
                <ul>
                  <li>
                    <span className="sleej">Personal injury claims :</span>
                    Personal injury claims
                    <strong> Acorn insurance claims </strong>in such a way that
                    you receive maximum compensation depending on the severity
                    of injuries you’ve suffered due to the car accident.
                  </li>
                  <li>
                    <span className="sleej">Claims management:</span>From
                    initiation of your{" "}
                    <strong> Acorn car insurance claim </strong>through handling
                    paperwork, and settlement, we offer all-inclusive end-to-end
                    claims management services.
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="yellowcta">
        <div className="container">
          <div className="row">
            <div className="cta2">
              <div className="col-xxl-8 col-xl-8 col-lg-8 col-12">
                <div className="legal">
                  <h3 className="experienced">
                    Got more questions?{" "}
                    <span className="light">Talk to our specialists </span>{" "}
                  </h3>
                  <p className="professionals">
                    Get in touch with our no-fault claim experts via{" "}
                    <strong>
                      car insurance claim number{" "}
                      <a className="cpt-no" href="tel:0800 772 0850">
                        {" "}
                        0800 772 0850{" "}
                      </a>{" "}
                    </strong>
                    to get the best advice for your{" "}
                    <strong> Acorn car insurance claim. </strong>
                  </p>
                </div>
              </div>
              <div className="col-xxl-4 col-xl-4 col-lg-4 col-12">
                <div class="process">
                  <a class="tf-button style-1 phone" href="tel:0800 772 0850">
                    Get in touch<span class="icon-keyboard_arrow_right"></span>
                  </a>
                </div>
                <div class="process">
                  <a
                    className="tf-button style-1 desktop"
                    onClick={handleScrollToSidebar}
                  >
                    Get in touch
                    <span className="icon-keyboard_arrow_right"></span>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <search className="updatedform">
        <div className="container">
          <div className="row text-center">
            <div className="col-xxl-12 col-xl-12 col-md-12 col-12">
              <h2 className="whiteheading">
                {" "}
                What makes us different <br />
                <span className="light">from others?</span>
              </h2>
              <p className="sustained">
                We are your dedicated partners whom you should consult if you
                have been involved in a no-fault car accident in the United
                Kingdom and require complete accident management solutions and
                claims assistance. Should you need help with vehicle recovery,
                replacement vehicles or legal advice to let you know about your
                rights, we will offer you the most suitable options.
              </p>
            </div>
          </div>
          <div className="row">
            <div className="col-xxl-4 col-xl-4 col-lg-6 col-md-12 col-12">
              <div className="Claimant">
                <h5> Zero upfront charges </h5>
                <p className="collision">
                  You don’t pay any costs in advance when using our services. We
                  allow you to receive quality emergency support at no cost.
                </p>

                <h5>Immediate assistance</h5>
                <p className="collision">
                  Our team is always available for twenty hours a day to provide
                  prompt help and resolve any issues concerned with accident
                  management.
                </p>

                <h5> Approved repairers </h5>
                <p className="collision">
                  Our UK repairers are approved by manufacturers, which means
                  they operate professionally on your vehicle while using
                  original parts as well as original paintwork after all the
                  specifications of a manufacturer.
                </p>
              </div>
            </div>
            <div className="col-xxl-4 col-xl-4 col-lg-6 col-md-12 col-12">
              <div className="Claimant">
                <h5> Replacement car in less than 24 hours </h5>
                <p className="collision">
                  Call our{" "}
                  <strong> Acorn insurance claims phone number </strong> to be
                  provided with another vehicle within 24 hours so that little
                  disruption happens in your life today.
                </p>
                <h5> Quick settlement of claims </h5>
                <p className="collision">
                  Our process works smoothly and openly, making it simple for
                  people to sort out their insurance claims within a reasonable
                  period of time.
                </p>

                <h5> 24/7 Recovery </h5>
                <p className="collision">
                  Regardless of the time, our recovery agents are always
                  available for your vehicle retrieval assistance.{" "}
                </p>
              </div>
            </div>
            <div className="col-xxl-4 col-xl-4 col-lg-12 col-md-12 col-12">
              <div className="Claimant">
                <h5> Advice without obligation </h5>
                <p className="collision">
                  Call our team on the{" "}
                  <strong> Acorn insurance claims phone number </strong> to
                  arrange a no-obligation discussion that will help you
                  understand what rights and actions you can take in relation to
                  making your claim.
                </p>

                <h5> Experienced solicitors </h5>
                <p className="collision">
                  Our solicitors have several cases of dealing with innocent
                  accident support after being involved in a car crash. They
                  will provide you with the best counsel and will be there to
                  back you up.
                </p>

                <h5>Personal advice</h5>
                <p className="collision">
                  The details of your{" "}
                  <strong> Acorn car insurance claim </strong> will be reviewed
                  by our professional and knowledgeable solicitors, who will
                  offer personalised suggestions on how to proceed for the best
                  possible outcome.
                </p>
              </div>
            </div>
          </div>
        </div>
      </search>

      <section className="Accident-claim">
        <div className="container">
          <div className="row">
            <div className="needed">
              <h2 className="trusted-title">
                How can I get maximum compensation for <br />
                <span className="light"> my Acorn insurance claim? </span>
              </h2>
              <p className="complexities">
                A crash may change one’s life and bring about too much pressure.
                Obtaining compensation for the losses and injuries is the
                primary step toward recovering from a car accident impact.
                However, follow these steps to increase the settlement amount
                and maximise your compensation.
              </p>
            </div>
            <hr />
          </div>
          <div className="row" id="incdes">
            <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-12">
              <div className="Preparing">
                <ul>
                  <li>
                    <div className="seemingly">
                      <h5 className="Seek">See a doctor:</h5>
                      <p>
                        {" "}
                        You must receive immediate medical attention to ensure
                        that your injuries are accurately diagnosed and treated,
                        thereby establishing a vital record of your condition to
                        strengthen your{" "}
                        <strong> Acorn insurance claim. </strong>
                      </p>
                    </div>
                  </li>
                  <li>
                    <div className="seemingly">
                      <h5 className="Seek">Record expenses: </h5>
                      <p>
                        Keep a record of all expenditures resulting from your
                        claim, including medical bills, prescription charges,
                        travelling expenses and legal fees.
                      </p>
                    </div>
                  </li>
                  <li>
                    <div className="seemingly">
                      <h5 className="Seek">Accumulate proof:</h5>
                      <p>
                        Take pictures and videos of the accident site, gather
                        eyewitness testimonies, try accessing CCTV footage and
                        get a copy of the police report to make your claims
                        stronger.
                      </p>
                    </div>
                  </li>
                  <li>
                    <div className="seemingly">
                      <h5 className="Seek">
                        Don’t accept a fast settlement offer
                      </h5>
                      <p>
                        Avoid instantly accepting the first offer made for
                        settlement but rather negotiate it with your solicitor
                        so that it is able to adequately compensate you for any
                        losses or injuries sustained in the process.
                      </p>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
            <div
              className="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-12"
              id="dualcardiv"
            >
              <img src={dualcar} alt="" className="dualcar" />
            </div>
            <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-12">
              <div className="Preparing">
                <ul>
                  <li>
                    <div className="seemingly">
                      <h5 className="Seek">Stay off social media:</h5>
                      <p>
                        Cease using social media while awaiting the resolution
                        of your Acorn insurance claim. Your comments on these
                        platforms can be used by the other party to deny or
                        reduce compensation.
                      </p>
                    </div>
                  </li>
                  <li>
                    <div className="seemingly">
                      <h5 className="Seek"> Share contact information </h5>
                      <p>
                        Share important details with other drivers involved such
                        as name, contacts, car particulars and insurance cover
                        information.
                      </p>
                    </div>
                  </li>
                  <li>
                    <div className="seemingly">
                      <h5 className="Seek">Engage an at-fault solicitor:</h5>
                      <p>
                        Call us on the{" "}
                        <strong> Acorn car insurance claim number </strong> to
                        speak with an experienced no-fault specialist who will
                        help you obtain maximum compensation for your loss.{" "}
                      </p>
                    </div>
                  </li>
                  <li>
                    <div className="seemingly">
                      <h5 className="Seek"> Notify us about the claim </h5>
                      <p>
                        Give us a call on the{" "}
                        <strong> Acorn insurance claim number </strong> when you
                        have gathered enough evidence and vital information
                        concerning the accident to initiate your claim.
                      </p>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="whyus">
        <div className="container">
          <div className="row">
            <div className="col-xxl-12 col-md-12">
              <div className="why">
                <h2 className="choose">
                  Our streamlined and hassle-free{" "}
                  <span className="light">claims process </span>
                </h2>
                <p className="complexities">
                  You don’t have to go through long lines or complicated
                  processes when you make an{" "}
                  <strong> Acorn car insurance claim </strong>with us. You can
                  make your claim in four easy steps with our effortless and
                  fast procedure!{" "}
                </p>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-xxl-3 col-xl-6 col-lg-6 col-md-6 col-12">
              <div className="box-service">
                <img src={check} className="check" alt="" />
                {/* <img src={experience} className="experience" alt="" /> */}
                <h4>Contact us </h4>
                <p>
                  Please, dial our helpline{" "}
                  <strong>
                    {" "}
                    <a href="tel:0800 772 0850"> 0800 772 0850</a>{" "}
                  </strong>{" "}
                  for a free initial appointment with one of our experienced
                  solicitors.
                </p>
              </div>
            </div>
            <div className="col-xxl-3 col-xl-6 col-lg-6 col-md-6 col-12">
              <div className="box-service">
                <img src={check} className="check" alt="" />
                <h4>Share accident details</h4>
                <p>
                  Narrate all the details concerning the car crash, like time,
                  place and reasons leading to it.
                </p>
              </div>
            </div>
            <div className="col-xxl-3 col-xl-6 col-lg-6 col-md-6 col-12">
              <div className="box-service">
                <img src={check} className="check" alt="" />
                <h4>Submit evidence</h4>
                <p>
                  Give us all crucial evidence that you have collected from an
                  accident scene, such as pictures, videos, a copy of the police
                  report, eyewitness testimonies or any other information which
                  would help strengthen your{" "}
                  <strong> Acorn insurance claim. </strong>
                </p>
              </div>
            </div>
            <div className="col-xxl-3 col-xl-6 col-lg-6 col-md-6 col-12">
              <div className="box-service">
                <img src={check} className="check" alt="" />
                <h4>Claim submission</h4>
                <p>
                  Once we have all supporting evidence and required documents
                  ready, your <strong> Acorn car insurance claim </strong>will
                  be submitted, and we will endeavour to settle it at the
                  earliest opportunity.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="faqs">
        <div className="container">
          <div className="row">
            <div className="col-xxl-12 col-xl-12 col-md-12 col-12">
              <div className="fq">
                <h2>
                  {" "}
                  Frequently Asked{" "}
                  <span className="light">Questions (FAQ)</span>
                </h2>
                <p className="complexities">
                  Got questions about the{" "}
                  <strong> Acorn car insurance claim? </strong>We have them
                  answered here.
                </p>
              </div>
              <div className="allfaq">
                <Accordion defaultActiveKey="0">
                  <Accordion.Item eventKey="0">
                    <Accordion.Header>
                      Do I need to pay any excess?
                    </Accordion.Header>
                    <Accordion.Body>
                      No, but you won’t be charged anything because they are
                      fully recoverable from the other party's insurer.
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="1">
                    <Accordion.Header>
                      Will genuine repair and replacement parts be used to
                      repair my damaged vehicle?
                    </Accordion.Header>
                    <Accordion.Body>
                      Yes. You are free to choose your own repairer. If you
                      prefer, we will book your vehicle repairs through the
                      repair centre of your choice.
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="2">
                    <Accordion.Header>
                      If I use your services, will it affect my no-claims bonus?
                    </Accordion.Header>
                    <Accordion.Body>
                      No. Your claim will be filed by our professional claim
                      handlers directly against the other party without any
                      effects on your insurance premiums as well as a no-claims
                      bonus.
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="3">
                    <Accordion.Header>
                      How long can I keep the replacement vehicle?{" "}
                    </Accordion.Header>
                    <Accordion.Body>
                      If your car is unfit to drive and requires fixing after a
                      road accident, you may retain the substitute until such
                      time when repair work has been completed.
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="4">
                    <Accordion.Header>
                      Will the replacement vehicle be similar to my car?
                    </Accordion.Header>
                    <Accordion.Body>
                      Irrespective of what sort of car you have, our goal is to
                      provide you with a suitable like-for-like replacement
                      vehicle. We will offer an equivalent one once you get in
                      touch with us through the{" "}
                      <strong> Acorn car insurance claim number. </strong>
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="5">
                    <Accordion.Header>
                      Are repairs for vehicles guaranteed?{" "}
                    </Accordion.Header>
                    <Accordion.Body>
                      Yes. Our manufacturer-approved repairs come with
                      guarantees that last for as long as you possess the
                      vehicle.
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="6">
                    <Accordion.Header>
                      Can you assist me in recovering all the losses I’ve
                      suffered as a result of this accident?{" "}
                    </Accordion.Header>
                    <Accordion.Body>
                      We can help recover costs such as medical bills,
                      transportation expenses, costs of replacement vehicles,
                      repair expenses, compensation for pain and suffering
                      caused due to the accident and any other out-of-pocket
                      expenses.
                    </Accordion.Body>
                  </Accordion.Item>
                </Accordion>
              </div>
            </div>
          </div>
        </div>
      </section>

      <Footer />
    </>
  );
};

export default Acorninsure;
