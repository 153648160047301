import React, { useRef } from "react";
import Accordion from "react-bootstrap/Accordion";
import check from "../assets/images/check.png";
import dualcar from "../../src/assets/images/dualcar.webp";
import carbnr1 from "../../src/assets/images/carbnr1.webp";
import Sidebarinner from "../components/Sidebar-inner";
import phonecall from "../assets/images/phonecall.png";
import Footer from "../components/footer";
import { Helmet } from "react-helmet";

const Halifax = () => {
  const sidebarRef = useRef(null);

  const handleScrollToSidebar = () => {
    if (sidebarRef.current) {
      sidebarRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Halifax Car Insurance Accident Claim Phone Number</title>
        <meta
          name="description"
          content="Want to initiate a no-fault accident claim? Call the Halifax Car Insurance accident claim phone number 0800 772 0850 for immediate assistance and claim support. "
        />
        <link
          rel="canonical"
          href="https://insurance-accident-claims.co.uk/halifax"
        />
      </Helmet>
      <section className="innernav">
        <div className="container">
          <div className="row">
            <div className="col-md-6 col-sm-6">
              <div className="left-logo">
                <h3>
                  Accident<span className="sipo"> Claims</span>
                </h3>
              </div>
            </div>
            <div className="col-md-6 col-sm-6">
              <div className="left-cta">
                <div class="headercnt">
                  <div class="chatmsg">
                    <img
                      src={phonecall}
                      className="phonecall"
                      alt="phonecall "
                    />
                  </div>
                  <div class="box-body">
                    <h3 class="nav-info-box-title">Toll Free Number </h3>
                    <p>
                      <a href="tel:0800 772 0850">0800 772 0850</a>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section
        className="newbnr"
        style={{ backgroundImage: "url(" + carbnr1 + ")" }}
      >
        <div className="container">
          <div className="row">
            <div className="col-xxl-8 col-xl-8 col-md-10 col-12">
              <div className="innerbnr">
                <h1>
                  <span className="onespan">No-fault</span>{" "}
                  <span className="sci">accident claims </span> management
                </h1>
                <p className="brokerage">
                  From arranging for your car to be recovered from the accident
                  scene to overseeing the entire process of your compensation
                  claim, we’re reliable partners ready to assist you in every
                  stage.
                </p>
                <div className="bnrbtn">
                  <a class="db-btn-border btn-rollover phone" href="tel:0800 772 0850">
                    Road Accident Claim Help?{" "}
                    <i class="fa-solid fa-arrow-right"></i>
                  </a>
                </div>
                <div className="bnrbtn">
                <a className="db-btn-border btn-rollover desktop"  onClick={handleScrollToSidebar}>
                    Road Accident Claim Help? <i className="fa-solid fa-arrow-right"></i>
                </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="stickysection">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="group-4-8">
                <div className="cl8 tf-tab">
                  <div className="content-tab">
                    <div className="inner-content">
                      <h2>
                        Support for a faultless
                        <br />
                        <span className="light">
                          accident without excess{" "}
                        </span>{" "}
                      </h2>
                      <p className="disciplines">
                        We are a team of dependable experts in the UK who deal
                        with post-incident aftermath of car accidents that were
                        not your fault. Taking your vehicle from where it broke
                        down all the way back to it being roadworthy again, we
                        will help you through it by taking care of everything
                        else without excess. Dial{" "}
                        <a href="tel:0800 772 0850">0800 772 0850</a> to get
                        started with us.
                      </p>
                      <hr />
                      <p className="bold-text">No excess </p>
                      <p className="bottom-te">
                        When you make a <strong>Halifax accident claim</strong>{" "}
                        with us, there will be no nasty surprises or hidden
                        charges.{" "}
                      </p>
                      <hr />
                      <p className="bold-text">No loss of no claims bonus </p>
                      <p className="bottom-te">
                        We value the years spent earning those hard-earned no
                        claims and hence we will leave them intact.
                      </p>
                      <hr />
                      <p className="bold-text">
                        Assured and high-quality repairs
                      </p>
                      <p className="bottom-te">
                        If your vehicle has been damaged as a result of an
                        accident, get in touch with us on the{" "}
                        <strong>
                          Halifax car accident claim phone number{" "}
                        </strong>
                        for accidental repairs. We shall direct you to an
                        approved garage where your car can be thoroughly fixed
                        with unparalleled quality and excellence levels.{" "}
                      </p>
                      <hr />
                      <p className="bold-text">
                        A like-for-like replacement car{" "}
                      </p>
                      <p className="bottom-te">
                        No ride after a motor wreck? Call us on the{" "}
                        <strong>
                          Halifax car insurance claim phone number
                        </strong>
                        . We will organise for another car identical to yours so
                        that you may resume driving again.{" "}
                      </p>
                      <hr />
                      <p className="bold-text">Expedited claim processing</p>
                      <p className="bottom-te">
                        You will not have to wait for your claim to be processed
                        with us. Our streamlined insurance claims process leads
                        to a smooth and quick processing of your{" "}
                        <strong>Halifax car insurance claim </strong>, thus
                        allowing you the opportunity to get compensated in the
                        shortest time possible.
                      </p>
                      <hr />
                      <p className="bold-text">24/7 support</p>
                      <p className="bottom-te">
                        We know how important it is to receive prompt assistance
                        after a car accident. Therefore, our team meets your
                        accident management needs in good time because we work
                        around the clock.
                      </p>
                      <hr />
                      <p className="bold-text">Expert panel of solicitors</p>
                      <p className="bottom-te">
                        Our solicitors are experienced in this field. They are
                        veterans in dealing with “no-fault” road traffic
                        accident claims and have expertise on what would be the
                        best result for the{" "}
                        <strong>Halifax insurance claim.</strong>
                      </p>
                      <hr />
                      <p className="bold-text">Safe car storage</p>
                      <p className="bottom-te">
                        Contact our team via{" "}
                        <strong>Halifax car insurance claim number </strong>
                        if you need a secure place for your vehicle. Our
                        services provide safe storage for cars when their owners
                        are waiting for their insurance claims to be assessed.{" "}
                      </p>
                      <hr />
                      <p className="bold-text">Swift and effective services</p>
                      <p className="bottom-te">
                        We understand that swift responses can reduce the strain
                        of being stuck on a roadside. Once you call us on the{" "}
                        <strong>Halifax insurance claim number </strong>, we
                        shall send help through without any delay issues.{" "}
                      </p>
                      <hr />

                      <p className="bold-text">A wide range of services</p>
                      <p className="bottom-te">
                        We offer an extensive range of accident management
                        services including vehicle recovery, approved repairs,
                        the provision of alternative vehicles on a temporary
                        basis and controlling your claim from commencement to
                        conclusion.{" "}
                      </p>
                    </div>
                  </div>
                </div>
                <div className="cl4" ref={sidebarRef}>
                  <Sidebarinner />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="photographs">
        <div className="container">
          <div className="row">
            <h2 className="road">
              We will make sure you are back on track{" "}
              <span className="light">
                with <br />
                the least possible inconvenience
              </span>{" "}
            </h2>
            <div className="col-xxl-6 col-xl-6 col-md-12 col-12">
              <div className="fourbox">
                <ul>
                  <li>
                    <img src={check} className="check" alt="" />
                    If you have been involved in a no-fault road traffic
                    accident in the UK, our team can assist you in making a
                    successful <strong> Halifax accident claim</strong> for
                    compensation.
                  </li>
                  <hr />
                  <li>
                    <img src={check} className="check" alt="" />
                    Just give us a call at{" "}
                    <strong> Halifax insurance claim </strong>and our dedicated
                    team will be there to help you out.
                  </li>
                  <hr />
                  <li>
                    <img src={check} className="check" alt="" />
                    The accident details will be documented so as to file a{" "}
                    <strong>Halifax insurance claim </strong> against the party
                    responsible for the accident.
                  </li>
                  <hr />
                  <li>
                    <img src={check} className="check" alt="" />
                    We shall also provide you with another vehicle similar to
                    your own until your car has been repaired.
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-xxl-6 col-xl-6 col-md-12 col-12">
              <div className="fourbox">
                <ul>
                  <li>
                    <img src={check} className="check" alt="" />
                    To ensure prompt repairs of your vehicle, we will contact an
                    approved repair centre in the UK.
                  </li>
                  <hr />
                  <li>
                    <img src={check} className="check" alt="" />
                    The claim will be directed at the person who caused it. All
                    the expenses related to repairs and the replacement car will
                    be recovered from the at-fault party.
                  </li>
                  <hr />
                  <li>
                    <img src={check} className="check" alt="" />
                    The processes of managing the complications and difficulties
                    associated with accident-related communications between the
                    different parties involved in it will be run by us.
                  </li>
                  <hr />
                  <li>
                    <img src={check} className="check" alt="" />
                    We will also make sure that your vehicle repairs are done
                    promptly so that you get your car back in minimum time.
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="carprocess">
        <div className="container ">
          <div className="row">
            <div className="col-xxl-12 col-xxl-12 col-md-12 col-12">
              <h2 className="trusted-title">
                {" "}
                Accident management services <br />
                <span className="light">we offer</span>{" "}
              </h2>
            </div>
          </div>
          <div className="row" id="possible">
            <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-12">
              <div className="claim">
                <ul>
                  <li>
                    <span className="sleej">Vehicle recovery:</span>Our aim is
                    to meet all your needs regarding vehicle towing during a car
                    accident in the UK. For example, we will get to where you
                    are after an accident when you call our car accident claim
                    phone number <a href="tel:0800 772 0850">0800 772 0850</a>{" "}
                    and quickly remove your vehicle from there, taking it to our
                    storage yards without causing any further damage.
                  </li>
                  <li>
                    <span className="sleej">Approved repairs:</span>Looking for
                    a place to get your damaged car fixed? Get in touch with us
                    through the{" "}
                    <strong>Halifax car insurance claim phone number </strong>{" "}
                    and your vehicle will undergo repair from
                    manufacturer-approved experts who will ensure it gets back
                    to its initial form.
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-12">
              <div className="claim" id="blue">
                <ul>
                  <li>
                    <span className="sleej">Vehicle storage:</span> Consider
                    storing your vehicle at our secure car storage location
                    after an accident. We protect your car against theft,
                    vandalism and damage while providing you with peace of mind
                    through safekeeping it in our well-maintained storage yard.
                    Contact us on the{" "}
                    <strong>Halifax car insurance claim number </strong>for
                    guaranteed safe vehicle storage facilities.
                  </li>
                  <li>
                    <span className="sleej">Replacement car:</span>If you’ve
                    been involved in a no-fault car accident, we’ll provide you
                    with a like-for-like replacement vehicle at no cost to you,
                    ensuring your daily routine remains uninterrupted. This way,
                    you can continue doing your daily tasks without any worries.
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-12">
              <div className="claim">
                <ul>
                  <li>
                    <span className="sleej">Personal injury support:</span>
                    Want to make a claim for your injuries? Contact us on the
                    <strong> Halifax car insurance claim number</strong>. We
                    will put you in touch with an experienced no-fault claim
                    expert who will guide you through the process of making your
                    <strong> Halifax insurance claim. </strong>
                  </li>
                  <li>
                    <span className="sleej">Claim management:</span>Our
                    dedicated solicitors will handle every aspect of your claim,
                    right from initiation to settlement and everything in
                    between. Get in touch with us through our{" "}
                    <strong> Halifax insurance claim number </strong>to get
                    started.
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="yellowcta">
        <div className="container">
          <div className="row">
            <div className="cta2">
              <div className="col-xxl-8 col-xl-8 col-lg-8 col-12">
                <div className="legal">
                  <h3 className="experienced">
                    Got more questions?{" "}
                    <span className="light">Talk to our specialists</span>{" "}
                  </h3>
                  <p className="professionals">
                    Get in touch with our no-fault claim experts via{" "}
                    <strong>
                      car insurance claims number{" "}
                      <a className="cpt-no" href="tel:0800 772 0850">
                        {" "}
                        0800 772 0850{" "}
                      </a>{" "}
                    </strong>
                    to get the best advice for your{" "}
                    <strong> Halifax insurance claim. </strong>
                  </p>
                </div>
              </div>
              <div className="col-xxl-4 col-xl-4 col-lg-4 col-12">
              <div class="process">
                  <a class="tf-button style-1 phone" href="tel:0800 772 0850">
                    Get in touch<span class="icon-keyboard_arrow_right"></span>
                  </a>
                </div>
                <div class="process">
                    <a className="tf-button style-1 desktop"  onClick={handleScrollToSidebar}>
                        Get in touch<span className="icon-keyboard_arrow_right"></span>
                    </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <search className="updatedform">
        <div className="container">
          <div className="row text-center">
            <div className="col-xxl-12 col-xl-12 col-md-12 col-12">
              <h2 className="whiteheading">
                {" "}
                Why choose us for your accident <br />
                <span className="light">management needs? </span>
              </h2>
              <p className="sustained">
                We know how nerve-racking and traumatic road accidents can be,
                and that is why we have a package of comprehensive accident
                management services meant to help you recover quickly after a
                car accident.{" "}
              </p>
            </div>
          </div>
          <div className="row">
            <div className="col-xxl-4 col-xl-4 col-lg-6 col-md-12 col-12">
              <div className="Claimant">
                <h5>Free-of-charge services </h5>
                <p className="collision">
                  We will not ask you to pay anything when offering our accident
                  management services. All the expenses will be claimed from the
                  insurance company of the at-fault party.
                </p>

                <h5>Customer-focused support</h5>
                <p className="collision">
                  When you call our{" "}
                  <strong>Halifax insurance claim number </strong>, you are
                  guaranteed to get customised assistance from our team that
                  considers all factors particular to your case.{" "}
                </p>

                <h5>Timely help</h5>
                <p className="collision">
                  Our dedicated team is always at hand to provide any assistance
                  required in managing your accident. Whether it is roadside
                  assistance, arranging for repairs on your vehicle or managing
                  a <strong> Halifax insurance claim </strong> for an accident,
                  emergency help can always be accessed by dialling a number
                  only.
                </p>
              </div>
            </div>
            <div className="col-xxl-4 col-xl-4 col-lg-6 col-md-12 col-12">
              <div className="Claimant">
                <h5>Transparent process</h5>
                <p className="collision">
                  You shall be informed about every step taken in your claims
                  process making sure that you are involved all along.
                </p>
                <h5>Independent engineer</h5>
                <p className="collision">
                  To ensure that you are compensated fairly for such damages, we
                  would make arrangements for an independent engineer who would
                  give an accurate and unbiased assessment of the damages.
                </p>

                <h5>Nationwide repair network</h5>
                <p className="collision">
                  Our manufacturer-approved repair network uses genuine parts
                  and paints to repair your damaged vehicle, hence guaranteeing
                  the highest standards of quality.{" "}
                </p>
              </div>
            </div>
            <div className="col-xxl-4 col-xl-4 col-lg-12 col-md-12 col-12">
              <div className="Claimant">
                <h5>No increase in insurance premiums</h5>
                <p className="collision">
                  There will be no pending claims against your own insurance
                  policy which would cause an increase in the insurance premium.
                </p>

                <h5>No obligation advice </h5>
                <p className="collision">
                  Call us on our{" "}
                  <strong> Halifax insurance claim number </strong>for free,
                  unbiased advice from our team of experienced UK no-fault
                  compensation claim specialists.
                </p>

                <h5>No-win, no-fee</h5>
                <p className="collision">
                  If your claim for compensation does not succeed then you are
                  not liable to pay your solicitor’s legal fees.
                </p>
              </div>
            </div>
          </div>
        </div>
      </search>

      <section className="Accident-claim">
        <div className="container">
          <div className="row">
            <div className="needed">
              <h2 className="trusted-title">
                How to maximise the settlement value of <br />
                <span className="light"> your Halifax insurance claim?</span>
              </h2>
              <p className="complexities">
                If you get involved in a non-fault car accident, make sure that
                you promptly file a <strong> Halifax accident claim </strong>{" "}
                <br /> so as to be rewarded fairly for the losses and damages
                caused. These steps will assist you gain more out of your payout
                settlement.{" "}
              </p>
            </div>
            <hr />
          </div>
          <div className="row" id="incdes">
            <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-12">
              <div className="Preparing">
                <ul>
                  <li>
                    <div className="seemingly">
                      <h5 className="Seek">Get medical treatment:</h5>
                      <p>
                        {" "}
                        After the motor vehicle accident, concentrate on getting
                        treated first. Check if anyone has been hurt including
                        yourself. Speak to a doctor immediately so that they can
                        treat your injuries on time.{" "}
                      </p>
                    </div>
                  </li>
                  <li>
                    <div className="seemingly">
                      <h5 className="Seek">Collect evidence: </h5>
                      <p>
                        To build a strong base for your{" "}
                        <strong> Halifax insurance claim </strong>and increase
                        the chances of maximum compensation, gather evidence
                        from the spot where the accident occurred.
                      </p>
                    </div>
                  </li>
                  <li>
                    <div className="seemingly">
                      <h5 className="Seek">Gather eyewitness statements:</h5>
                      <p>
                        For your compensation to be maximised, secure eyewitness
                        accounts from those who viewed the accident as it
                        happened. Their statements can offer essential
                        information about the mishap that will add credibility
                        to your case.
                      </p>
                    </div>
                  </li>
                  <li>
                    <div className="seemingly">
                      <h5 className="Seek">Don’t jump at the initial offer</h5>
                      <p>
                        At times, insurance companies may make an initial
                        payment in order for you to settle the claim early.
                        However, this first amount is always lower than what
                        you’ve actually lost in terms of damages. Therefore, do
                        not accept it prior to consulting your solicitor.
                      </p>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
            <div
              className="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-12"
              id="dualcardiv"
            >
              <img src={dualcar} alt="" className="dualcar" />
            </div>
            <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-12">
              <div className="Preparing">
                <ul>
                  <li>
                    <div className="seemingly">
                      <h5 className="Seek">Avoid social media:</h5>
                      <p>
                        When processing your claim, it is recommended that you
                        keep off social networks. Everything posted on social
                        media could undermine your claim.{" "}
                      </p>
                    </div>
                  </li>
                  <li>
                    <div className="seemingly">
                      <h5 className="Seek"> Document your damages</h5>
                      <p>
                        Keep records of all expenses incurred, including medical
                        bills, other costs out of pocket, lost earnings and
                        travelling expenditures.
                      </p>
                    </div>
                  </li>
                  <li>
                    <div className="seemingly">
                      <h5 className="Seek">Speak to a solicitor:</h5>
                      <p>
                        Take a chance and approach one experienced in this field
                        who would give you steps leading to a successful claims
                        process. Call us on our{" "}
                        <strong> Halifax insurance claim number </strong> today
                        and we will give you advice specifically tailored to
                        your case.{" "}
                      </p>
                    </div>
                  </li>
                  <li>
                    <div className="seemingly">
                      <h5 className="Seek">File your claim </h5>
                      <p>
                        If you have evidence of the accident, please contact our
                        professional team on the{" "}
                        <strong> Halifax car accident phone number </strong> and
                        register your claim with us.
                      </p>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="whyus">
        <div className="container">
          <div className="row">
            <div className="col-xxl-12 col-md-12">
              <div className="why">
                <h2 className="choose">
                  Begin your claim with a{" "}
                  <span className="light">simplified process</span>
                </h2>
                <p className="complexities">
                  You will not need to fill in complicated forms or make lengthy
                  follow-up calls once you commence your claims process with us.
                  For the start of the{" "}
                  <strong> Halifax insurance claim </strong>procedure, call us
                  at{" "}
                  <strong>
                    <a href="tel:0800 772 0850">0800 772 0850</a>.
                  </strong>{" "}
                </p>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-xxl-3 col-xl-6 col-lg-6 col-md-6 col-12">
              <div className="box-service">
                <img src={check} className="check" alt="" />
                {/* <img src={experience} className="experience" alt="" /> */}
                <h4>Get in touch</h4>
                <p>
                  To discuss your case with our solicitors, use our{" "}
                  <strong>Halifax car insurance claim phone number. </strong>
                </p>
              </div>
            </div>
            <div className="col-xxl-3 col-xl-6 col-lg-6 col-md-6 col-12">
              <div className="box-service">
                <img src={check} className="check" alt="" />
                <h4>Give the details of the accident</h4>
                <p>
                  Share accident details to enable our team to capture all
                  necessary details about it and provide guidance on what you
                  need to do next.
                </p>
              </div>
            </div>
            <div className="col-xxl-3 col-xl-6 col-lg-6 col-md-6 col-12">
              <div className="box-service">
                <img src={check} className="check" alt="" />
                <h4>Provide any documentary evidence</h4>
                <p>
                  In case there was any evidence recovered from where the
                  accident took place, give it to us so we can make your claim
                  stronger.{" "}
                </p>
              </div>
            </div>
            <div className="col-xxl-3 col-xl-6 col-lg-6 col-md-6 col-12">
              <div className="box-service">
                <img src={check} className="check" alt="" />
                <h4>Follow up</h4>
                <p>
                  Once we have obtained proof or documents supporting your
                  claim, we will initiate your claim and keep you posted about
                  its progress.{" "}
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="faqs">
        <div className="container">
          <div className="row">
            <div className="col-xxl-12 col-xl-12 col-md-12 col-12">
              <div className="fq">
                <h2>
                  {" "}
                  Frequently Asked{" "}
                  <span className="light">Questions (FAQ)</span>
                </h2>
                <p className="complexities">
                  Got questions about the{" "}
                  <strong> Halifax Insurance claim? </strong>We have them
                  answered here.
                </p>
              </div>
              <div className="allfaq">
                <Accordion defaultActiveKey="0">
                  <Accordion.Item eventKey="0">
                    <Accordion.Header>
                      Can you assist me in repairing my vehicle after a car
                      crash?
                    </Accordion.Header>
                    <Accordion.Body>
                      In order to fix your damaged vehicle, we will send you an
                      authorised UK repairer. If you want your vehicle repaired
                      from your chosen repair centre, tell us if there is any
                      specific repairer that you have in mind so that we can
                      then arrange for repairs on your vehicle.
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="1">
                    <Accordion.Header>
                      For how long can I keep the replacement vehicle?
                    </Accordion.Header>
                    <Accordion.Body>
                      You are allowed to keep this until your own car has been
                      repaired. If it is written off, you may continue using the
                      temporary substitute car until you receive compensation.
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="2">
                    <Accordion.Header>
                      Will claiming through your company affect my no-claims
                      bonus?
                    </Accordion.Header>
                    <Accordion.Body>
                      No. Our services have nothing to do with your personal
                      insurance coverage and therefore you will not suffer any
                      losses related to it including a no-claims bonus.
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="3">
                    <Accordion.Header>
                      How do I get a replacement car through your company?{" "}
                    </Accordion.Header>
                    <Accordion.Body>
                      To entitle yourself to a replacement car, you should not
                      be at fault while driving. Just phone{" "}
                      <a href="tel:0800 772 0850">0800 772 0850</a> and we will
                      have another one brought right to your house.
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="4">
                    <Accordion.Header>
                      How much time will it take to repair my car that has been
                      damaged?
                    </Accordion.Header>
                    <Accordion.Body>
                      The period it takes for repairs to be made depends on
                      several factors, including the extent of damage and
                      availability of parts. We will give you an idea as soon as
                      we have had a look at your damaged vehicle.
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="5">
                    <Accordion.Header>
                      Do you work outside office hours?{" "}
                    </Accordion.Header>
                    <Accordion.Body>
                      Yes, our team is available 24/7 to handle all your
                      accident management needs. For emergency aid, you can
                      therefore reach us anytime using the{" "}
                      <strong>
                        {" "}
                        Halifax car accident claims phone number.{" "}
                      </strong>
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="6">
                    <Accordion.Header>
                      What type of replacement vehicle will I get?{" "}
                    </Accordion.Header>
                    <Accordion.Body>
                      To avoid any trouble, we try as much as possible to offer
                      a like-for-like replacement vehicle that closely matches
                      your own car.
                    </Accordion.Body>
                  </Accordion.Item>
                </Accordion>
              </div>
            </div>
          </div>
        </div>
      </section>

      <Footer />
    </>
  );
};

export default Halifax;
