import React, { useRef } from "react";
import insurancelogo from "../../src/assets/images/insurancelogo.png";
import Accordion from "react-bootstrap/Accordion";
import check from "../assets/images/check.png";
import dualcar from "../../src/assets/images/dualcar.webp";
import carbnr1 from "../../src/assets/images/carbnr1.webp";
import Sidebarinner from "../components/Sidebar-inner";
import phonecall from "../assets/images/phonecall.png";
import Footer from "../components/footer";
import { Helmet } from "react-helmet";

const AXA = () => {
  const sidebarRef = useRef(null);

  const handleScrollToSidebar = () => {
    if (sidebarRef.current) {
      sidebarRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>AXA Car Accident Claims Contact Number UK</title>
        <meta
          name="description"
          content=" We’re available 24/7 to help you with your claims assistance needs. Call us on the AXA Car Insurance Accident Claim Phone Number 0800 772 0850 for the best claim advice. "
        />
        <link
          rel="canonical"
          href="https://insurance-accident-claims.co.uk/AXA "
        />
      </Helmet>
      <section className="innernav">
        <div className="container">
          <div className="row">
            <div className="col-md-6 col-sm-6">
              <div className="left-logo">
                {/* <img src={insurancelogo} className='insurancelogo' alt='Insurance Logo' /> */}
                <h3>Accident Claims</h3>
              </div>
            </div>
            <div className="col-md-6 col-sm-6">
              <div className="left-cta">
                <div class="headercnt">
                  <div class="chatmsg">
                    <img
                      src={phonecall}
                      className="phonecall"
                      alt="phonecall "
                    />
                  </div>
                  <div class="box-body">
                    <h3 class="nav-info-box-title">Toll Free Number </h3>
                    <p>
                      <a href="tel:0800 772 0850">0800 772 0850</a>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section
        className="newbnr"
        style={{ backgroundImage: "url(" + carbnr1 + ")" }}
      >
        <div className="container">
          <div className="row">
            <div className="col-xxl-8 col-xl-8 col-md-10 col-12">
              <div className="innerbnr">
                <h1>
                  <span className="onespan">Car Accident claims </span>{" "}
                  <span className="sci"> Management</span> Services In The UK{" "}
                </h1>
                <p className="brokerage">
                  We’re the UK’s leading accident management experts providing
                  the best no-fault accident management solutions. From vehicle
                  recovery to comprehensive claims settlement, we have you
                  covered.{" "}
                </p>
                <div className="bnrbtn">
                  <a
                    class="db-btn-border btn-rollover phone"
                    href="tel:0800 772 0850"
                  >
                    Make a claim <i class="fa-solid fa-arrow-right"></i>
                  </a>
                </div>
                <div className="bnrbtn">
                  <a
                    className="db-btn-border btn-rollover desktop"
                    onClick={handleScrollToSidebar}
                  >
                    Make a claim <i className="fa-solid fa-arrow-right"></i>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="stickysection">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="group-4-8">
                <div className="cl8 tf-tab">
                  <div className="content-tab">
                    <div className="inner-content">
                      <h2>
                        Trusted and reliable AXA <br />
                        <span className="light">
                          insurance accident support{" "}
                        </span>{" "}
                      </h2>
                      <p className="disciplines">
                        We understand that being involved in a car accident is a
                        stressful experience. However, we will relieve you from
                        all the stress and anxiety with our comprehensive range
                        of <strong> insurance accident support </strong>
                        services. Contact us on our{" "}
                        <strong>
                          {" "}
                          claims contact number{" "}
                          <a href="tel:0800 772 0850">0800 772 0850</a>{" "}
                        </strong>
                        to get immediate assistance following a no-fault
                        accident at no additional charges or hidden costs.{" "}
                      </p>
                      <hr />
                      <p className="bold-text">No upfront costs </p>
                      <p className="bottom-te">
                        When you reach out to us on our{" "}
                        <strong>AXA accident claims number</strong> for{" "}
                        <strong>AXA insurance accident support</strong>, you can
                        rest assured that you don’t have to pay any upfront
                        charges or additional costs. We will recover all the
                        costs from the at-fault driver’s insurance company.{" "}
                      </p>
                      <hr />
                      <p className="bold-text"> No effect on NCB </p>
                      <p className="bottom-te">
                        With us, you can avoid losing your earned discounts. We
                        will claim all the costs from the at-fault party’s
                        insurer. As a result, your earned discounts will remain
                        protected. You can get in touch with us on our
                        <strong>AXA accident number</strong> to ensure that your
                        no-claims bonus remains untouched.{" "}
                      </p>
                      <hr />
                      <p className="bold-text">
                        Manufacturer approved repairs{" "}
                      </p>
                      <p className="bottom-te">
                        Contact us on{" "}
                        <strong>the AXA car insurance claim number </strong>to
                        get high-quality repairs for your damaged vehicle.
                        Through our vast network of approved repair centres, we
                        will ensure quick and efficient repairs of your damaged
                        vehicle according to the highest industry standards.
                      </p>
                      <hr />
                      <p className="bold-text">
                        Comparable replacement vehicle{" "}
                      </p>
                      <p className="bottom-te">
                        If you’re involved in a no-fault car accident, you can
                        contact us at{" "}
                        <strong>AXA claims contact number.</strong> We will
                        provide you with a no-cost courtesy car that suits your
                        needs.
                      </p>
                      <hr />
                      <p className="bold-text">Specialist claim handlers </p>
                      <p className="bottom-te">
                        After the no-fault accident, you can call us on the
                        <strong> AXA car insurance claim number.</strong> We
                        will put you in touch with a dedicated claims handler
                        who will manage your claim from start to finish and
                        ensure you get deserving compensation.
                      </p>
                      <hr />
                      <p className="bold-text">Hassle-free claim management </p>
                      <p className="bottom-te">
                        You can trust us for a quick settlement of your no-fault
                        claim. When you contact us through the{" "}
                        <strong>AXA car accident helpline</strong>, we will
                        kickstart the processing of your claim without wasting
                        any time to help you get rightful compensation for your
                        injuries.
                      </p>
                      <hr />
                      <p className="bold-text">Trusted advice </p>
                      <p className="bottom-te">
                        We partner with experienced and trusted no-fault
                        solicitors in the UK. When you call us on the{" "}
                        <strong>AXA accident claim phone number</strong>, you’re
                        sure to get the best advice from experienced solicitors
                        for your no-fault accident claim.{" "}
                      </p>
                      <hr />
                      <p className="bold-text">Honest and genuine approach </p>
                      <p className="bottom-te">
                        We follow an open and transparent approach. From the
                        moment we file the claim to its final settlement, we
                        keep our{" "}
                        <strong>AXA report car accident customers </strong>
                        updated about the progress of their claim. Get in touch
                        with us on the{" "}
                        <strong>AXA accident claims helpline </strong>to get
                        maximum compensation for your claim.
                      </p>
                      <hr />
                      <p className="bold-text">No-fault accident experts </p>
                      <p className="bottom-te">
                        From initial assessment to final settlement, we have the
                        expertise to handle every aspect of your no-fault claim.
                        This in-depth expertise helps us to manage your claim
                        strategically and secure the best possible outcome.
                      </p>
                      <hr />

                      <p className="bold-text"> Quick response </p>
                      <p className="bottom-te">
                        We understand that immediate assistance is essential
                        after a no-fault accident. Therefore, when you contact
                        us on the{" "}
                        <strong>AXA accident claim phone number</strong> to
                        receive <strong>AXA insurance accident support</strong>,
                        our dedicated team will offer immediate assistance for
                        your no-fault car accident management needs.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="cl4" ref={sidebarRef}>
                  <Sidebarinner />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="photographs">
        <div className="container">
          <div className="row">
            <h2 className="road">
              We help you get maximum{" "}
              <span className="light">
                compensation <br />
                for your no-fault claim{" "}
              </span>{" "}
            </h2>
            <div className="col-xxl-6 col-xl-6 col-md-12 col-12">
              <div className="fourbox">
                <ul>
                  <li>
                    <img src={check} className="check" alt="" />
                    After being involved in a no-fault accident, when you
                    contact us on the <strong>AXA accident number</strong>, our
                    team will record your accident details and ensure you get
                    the best advice for your concerns.
                  </li>
                  <hr />
                  <li>
                    <img src={check} className="check" alt="" />
                    We will assess your no-fault{" "}
                    <strong>AXA insurance accident claim</strong>, and if there
                    are strong chances of securing compensation, we will move
                    ahead to pursue your claim. Our experienced solicitors will
                    assist you with the necessary documentation to support your
                    claim.
                  </li>
                  <hr />
                  <li>
                    <img src={check} className="check" alt="" />
                    Also, when you contact us on the{" "}
                    <strong>AXA accident claim phone number</strong>, we will
                    arrange a like-for-like replacement car so that you can hit
                    the road again and carry on your daily tasks without
                    disruption.
                  </li>
                  <hr />
                  <li>
                    <img src={check} className="check" alt="" />
                    We will facilitate the assessment of your vehicle to
                    determine the extent of damages and coordinate the repairs
                    from a trusted repair network of your choice. Moreover, we
                    will keep you informed about the progress of your vehicle
                    repairs.
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-xxl-6 col-xl-6 col-md-12 col-12">
              <div className="fourbox">
                <ul>
                  <li>
                    <img src={check} className="check" alt="" />
                    Once your vehicle repairs have been completed, we will
                    thoroughly inspect your vehicle to ensure that it is
                    restored to its pre-accident condition.
                  </li>
                  <hr />
                  <li>
                    <img src={check} className="check" alt="" />
                    You can reach out to us on{" "}
                    <strong>
                      the car insurance claim number{" "}
                      <a href="tel:0800 772 0850">0800 772 0850</a>{" "}
                    </strong>
                    to get started with your AXA insurance accident claim. We
                    will handle all the complexities and documentation of your
                    claim to ensure your peace of mind.
                  </li>
                  <hr />
                  <li>
                    <img src={check} className="check" alt="" />
                    Throughout your{" "}
                    <strong>AXA insurance accident claim process</strong>, we
                    will maintain open and transparent communication with the
                    insurance company to ensure you get the best outcome for
                    your claim.
                  </li>
                  <hr />
                  <li>
                    <img src={check} className="check" alt="" />
                    When your claim is settled, you will get your repaired
                    vehicle or a cheque amounting to full value if your vehicle
                    is entirely written off.
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="carprocess">
        <div className="container ">
          <div className="row">
            <div className="col-xxl-12 col-xxl-12 col-md-12 col-12">
              <h2 className="trusted-title">
                {" "}
                End-to-end AXA insurance <br />
                <span className="light">accident support services </span>{" "}
              </h2>
            </div>
          </div>
          <div className="row" id="possible">
            <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-12">
              <div className="claim">
                <ul>
                  <li>
                    <span className="sleej">Vehicle Recovery:</span> <br />
                    Want your vehicle to be recovered from the accident scene?
                    Contact us on the{" "}
                    <strong>AXA accident claim phone number.</strong> We have a
                    24/7 recovery team who will recover your vehicle from the
                    roadside and place it in our secured yards to prevent
                    further damage.
                  </li>
                  <li>
                    <span className="sleej">Accidental repairs :</span>
                    <br />
                    Car accidents can damage vehicles to a great extent. Get in
                    touch with us on
                    <strong>the AXA car accident helpline </strong>to get
                    high-quality repairs for your vehicle. We partner with
                    approved manufacturer repairers to ensure that your vehicle
                    is repaired according to the highest standards.
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-12">
              <div className="claim" id="blue">
                <ul>
                  <li>
                    <span className="sleej">Vehicle Storage:</span> <br />
                    It’s important that your vehicle must be stored in a safe
                    place throughout the process. You can contact us on the{" "}
                    <strong>AXA accident number </strong>to get your vehicle
                    recovered from the accident scene and stored securely in our
                    storage yards. Our comprehensive{" "}
                    <strong>AXA insurance accident support </strong>ensures safe
                    storage of your vehicle to prevent further damage to your
                    vehicle from dirt or pollution.
                  </li>
                  <li>
                    <span className="sleej">Replacement Vehicle: </span> <br />
                    You can get in touch with us on the{" "}
                    <strong>AXA accident number </strong>if you want to get a
                    replacement vehicle after a no-fault accident. Our accident
                    management experts will provide you with a like-for-like
                    replacement vehicle matching your needs at no additional
                    cost. You can keep the replacement vehicle with you until
                    your vehicle gets repaired or you receive compensation for
                    the damages to your vehicle.
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-12">
              <div className="claim">
                <ul>
                  <li>
                    <span className="sleej">Injury Claim:</span>
                    <br />
                    We have a team of experienced solicitors who have vast
                    experience in securing maximum compensation for{" "}
                    <strong> report accident customers </strong>without any
                    hidden charges. Call us at the{" "}
                    <strong>
                      {" "}
                      claims contact number{" "}
                      <a href="tel:0800 772 0850">0800 772 0850 </a>
                    </strong>
                    to get the best assistance and support for your no-fault
                    insurance accident claim.
                  </li>
                  <li>
                    <span className="sleej">
                      Start to finish claims management:
                    </span>{" "}
                    <br />
                    We specialize in handling no-fault{" "}
                    <strong>AXA insurance accident claims </strong>in the UK.
                    When you contact us on the{" "}
                    <strong>AXA car accident helpline</strong>, you’ll receive
                    comprehensive support from our professional solicitors
                    throughout the claim process and get maximum compensation
                    for your injuries and damages.
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="yellowcta">
        <div className="container">
          <div className="row">
            <div className="cta2">
              <div className="col-xxl-8 col-xl-8 col-lg-8 col-12">
                <div className="legal">
                  <h3 className="experienced">
                    Still have questions?{" "}
                    <span className="light">Talk to our no-fault experts </span>{" "}
                  </h3>
                  <p className="professionals">
                    Contact us on the{" "}
                    <strong>AXA claims contact number </strong>to discuss your
                    claim with us. We'd be more than happy to help you pursue
                    your claim and get rightful compensation.
                  </p>
                </div>
              </div>
              <div className="col-xxl-4 col-xl-4 col-lg-4 col-12">
                <div class="process">
                  <a class="tf-button style-1 phone" href="tel:0800 772 0850">
                    Get in touch<span class="icon-keyboard_arrow_right"></span>
                  </a>
                </div>
                <div class="process">
                  <a
                    className="tf-button style-1 desktop"
                    onClick={handleScrollToSidebar}
                  >
                    Get in touch
                    <span className="icon-keyboard_arrow_right"></span>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <search className="updatedform">
        <div className="container">
          <div className="row text-center">
            <div className="col-xxl-12 col-xl-12 col-md-12 col-12">
              <h2 className="whiteheading">
                {" "}
                What makes us your trusted partner <br />
                <span className="light">for no-fault accident claims?</span>
              </h2>
              <p className="sustained">
                Prior to contacting your insurer, call us on the{" "}
                <strong>AXA accident claims number</strong>. We can provide you
                with
                <br /> more benefits compared to our own insurance company.{" "}
              </p>
            </div>
          </div>
          <div className="row">
            <div className="col-xxl-4 col-xl-4 col-lg-6 col-md-12 col-12">
              <div className="Claimant">
                <h5>No hidden costs </h5>
                <p className="collision">
                  With us, you can be relieved from the stress of paying
                  additional costs or upfront charges for{" "}
                  <strong>AXA insurance accident support </strong>services. We
                  will collect all the payments for the at-fault driver’s
                  insurance company.
                </p>

                <h5>Fast claim processing </h5>
                <p className="collision">
                  We follow a straightforward and streamlined approach to manage
                  no-fault<strong> insurance accident claims</strong>. Contact
                  us on{" "}
                  <strong>the car insurance claim number 0800 772 0850</strong>{" "}
                  and we will take care of every aspect of your claim.
                </p>

                <h5>24/7 assistance </h5>
                <p className="collision">
                  Accidents can happen at any time. Therefore, our
                  round-the-clock accident management team is always on hand to
                  offer instant support. Contact us on the
                  <strong> AXA car accident helpline</strong> to get immediate
                  assistance for your needs.
                </p>
              </div>
            </div>
            <div className="col-xxl-4 col-xl-4 col-lg-6 col-md-12 col-12">
              <div className="Claimant">
                <h5>Experienced solicitors </h5>
                <p className="collision">
                  Our panel of dedicated solicitors have vast experience in
                  handling no-fault claims. You can get in touch with us on{" "}
                  <strong>AXA claims contact number</strong> to get maximum
                  compensation for injuries and damages after a no-fault
                  accident.
                </p>

                <h5>Quality repairs guaranteed </h5>
                <p className="collision">
                  We partner with reliable and trusted repair centres across the
                  UK that are audited for the best quality and highest standards
                  of service. With us, you’re guaranteed to receive top-notch
                  repairs using original parts.
                </p>

                <h5>Like-for-like credit hire </h5>
                <p className="collision">
                  We’re aware that <strong>AXA road traffic accidents </strong>
                  can leave you stranded on the road. When you contact us on{" "}
                  <strong>the AXA accident claims helpline,</strong> we will
                  provide you with a like-for-like replacement vehicle without
                  extra cost.
                </p>
              </div>
            </div>
            <div className="col-xxl-4 col-xl-4 col-lg-12 col-md-12 col-12">
              <div className="Claimant">
                <h5>Free, no-obligation advice </h5>
                <p className="collision">
                  We provide free initial discussion via our{" "}
                  <strong> accident claims helpline 0800 772 0850</strong>.
                  Contact us on<strong> the AXA claims contact number </strong>
                  to talk to our experienced panel of solicitors and get free
                  no-obligation advice for{" "}
                  <strong> insurance accident claims. </strong>
                </p>

                <h5>Efficient claim monitoring </h5>
                <p className="collision">
                  We will closely track and monitor the progress of your{" "}
                  <strong>AXA insurance accident claims.</strong> As an{" "}
                  <strong> AXA report car accident customer</strong>, you’ll
                  receive timely updates related to the status of your no-fault
                  claim.
                </p>

                <h5>No-fuss services </h5>
                <p className="collision">
                  From vehicle recovery to claims settlement, our reliable and
                  friendly will take care of the complexities of your no-fault
                  insurance accident claims and restore your peace of mind.
                  Contact us on the <strong>AXA claims contact number </strong>
                  to start your claim with us.
                </p>
              </div>
            </div>
          </div>
        </div>
      </search>

      <section className="Accident-claim">
        <div className="container">
          <div className="row">
            <div className="needed">
              <h2 className="trusted-title">
                How can you secure maximum compensation for <br />
                <span className="light">
                  {" "}
                  your no-fault insurance accident claims?
                </span>
              </h2>
              <p className="complexities">
                Taking the correct steps after a no-fault accident is essential
                to process a successful{" "}
                <strong>AXA insurance accident claim. </strong>
              </p>
            </div>
            <hr />
          </div>
          <div className="row" id="incdes">
            <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-12">
              <div className="Preparing">
                <ul>
                  <li>
                    <div className="seemingly">
                      <h5 className="Seek">
                        Don’t flee from the accident scene{" "}
                      </h5>
                      <p>
                        {" "}
                        Avoid fleeing from the accident scene. Instead, find a
                        safe place and stop your car. Check yourself and your
                        passengers for minor or severe injuries.{" "}
                      </p>
                    </div>
                  </li>
                  <li>
                    <div className="seemingly">
                      <h5 className="Seek">
                        Contact emergency AXA insurance support services
                      </h5>
                      <p>
                        Call us on the{" "}
                        <strong>AXA claims contact number</strong> to share your
                        accident details. Also, you must{" "}
                        <strong>report car accident </strong>to the police as
                        soon as possible within 24 hours of the accident.
                      </p>
                    </div>
                  </li>
                  <li>
                    <div className="seemingly">
                      <h5 className="Seek">Get medical help </h5>
                      <p>
                        If you or any other passenger have sustained any major
                        or minor injuries, immediately get medical treatment and
                        get your injuries treated.
                      </p>
                    </div>
                  </li>
                  <li>
                    <div className="seemingly">
                      <h5 className="Seek">
                        Exchange details with the other driver
                      </h5>
                      <p>
                        To pursue a successful claim, exchange the details with
                        all the parties involved in the accident. This includes
                        your name, address, contact number, vehicle registration
                        number and insurance information.
                      </p>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
            <div
              className="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-12"
              id="dualcardiv"
            >
              <img src={dualcar} alt="" className="dualcar" />
            </div>
            <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-12">
              <div className="Preparing">
                <ul>
                  <li>
                    <div className="seemingly">
                      <h5 className="Seek">
                        Collect information and evidence{" "}
                      </h5>
                      <p>
                        Gather detailed information related to the accident such
                        as vehicle registration numbers, photographs of the
                        accident scene and damaged vehicles, eyewitness
                        statements, and CCTV footage.
                      </p>
                    </div>
                  </li>
                  <li>
                    <div className="seemingly">
                      <h5 className="Seek">Don’t agree to early settlement </h5>
                      <p>
                        The insurance companies may force you to settle quickly.
                        Avoid settling too early as it could result in you
                        receiving less compensation than you deserve.
                      </p>
                    </div>
                  </li>
                  <li>
                    <div className="seemingly">
                      <h5 className="Seek">Get vehicle recovery</h5>
                      <p>
                        Check your vehicle for damages and contact{" "}
                        <strong>
                          {" "}
                          accident claims number{" "}
                          <a href="tel:0800 772 0850">0800 772 0850</a>{" "}
                        </strong>
                        to get your unroadworthy vehicle recovered from the
                        accident scene.
                      </p>
                    </div>
                  </li>
                  <li>
                    <div className="seemingly">
                      <h5 className="Seek">Make a no-fault claim</h5>
                      <p>
                        If the<strong> claims department AXA insurance </strong>
                        is experiencing high inquiries, you can call{" "}
                        <strong>the AXA car accident helpline </strong>to make
                        your no-fault
                        <strong> AXA insurance accident claim </strong>and get
                        compensation for the damages.{" "}
                      </p>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="whyus">
        <div className="container">
          <div className="row">
            <div className="col-xxl-12 col-md-12">
              <div className="why">
                <h2 className="choose">
                  How can you make your no-fault insurance{" "}
                  <span className="light">
                    <br /> accident claim with us?
                  </span>
                </h2>
                <p className="complexities">
                  You can get in touch with us on{" "}
                  <strong>the AXA accident claims number </strong>to get the
                  best accident management solutions tailored to your needs. We
                  follow a simple and hassle-free claim process. Call on the{" "}
                  <strong>AXA car helpline </strong>to make your{" "}
                  <strong>no-fault AXA insurance accident claim. </strong>
                </p>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-xxl-3 col-xl-6 col-lg-6 col-md-6 col-12">
              <div className="box-service">
                <img src={check} className="check" alt="" />
                <h4>Get in touch </h4>
                <p>
                  Contact us through the{" "}
                  <strong>AXA accident claims number </strong> to get in touch
                  with our experienced claim handlers and initiate your claim
                  process. Also, provide us with relevant details related to the
                  accident and the compensation you want from an at-fault party.
                </p>
              </div>
            </div>
            <div className="col-xxl-3 col-xl-6 col-lg-6 col-md-6 col-12">
              <div className="box-service">
                <img src={check} className="check" alt="" />
                <h4>Discuss your claim </h4>
                <p>
                  Call us on{" "}
                  <strong>
                    the car insurance claim number{" "}
                    <a href="tel:0800 772 0850">0800 772 0850</a>
                  </strong>{" "}
                  and share all the necessary details with our no-fault claim
                  handlers. This includes the essential details from the
                  accident scene and any minor or major injuries you've
                  sustained.
                </p>
              </div>
            </div>
            <div className="col-xxl-3 col-xl-6 col-lg-6 col-md-6 col-12">
              <div className="box-service">
                <img src={check} className="check" alt="" />
                <h4>Share evidence </h4>
                <p>
                  We may require certain documents and information, including
                  the police report, medical reports, photographs of the
                  accident scene and vehicle, eyewitness comments and statements
                  or any other evidence you may possess.
                </p>
              </div>
            </div>
            <div className="col-xxl-3 col-xl-6 col-lg-6 col-md-6 col-12">
              <div className="box-service">
                <img src={check} className="check" alt="" />
                <h4>Get compensation </h4>
                <p>
                  Once we have received all the required documents, we will
                  initiate your claim and keep you updated about its progress.
                  After the successful processing of your{" "}
                  <strong>AXA insurance accident claim</strong>, you’ll get the
                  rightful compensation for your injuries.{" "}
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="faqs">
        <div className="container">
          <div className="row">
            <div className="col-xxl-12 col-xl-12 col-md-12 col-12">
              <div className="fq">
                <h2>
                  {" "}
                  Frequently Asked{" "}
                  <span className="light">Questions (FAQ)</span>
                </h2>
                <p className="complexities">
                  Got more questions about our no-fault AXA insurance accident
                  support services. We have them answered below.{" "}
                </p>
              </div>
              <div className="allfaq">
                <Accordion defaultActiveKey="0">
                  <Accordion.Item eventKey="0">
                    <Accordion.Header>
                      Can you help me get compensation without insurance?
                    </Accordion.Header>
                    <Accordion.Body>
                      Yes. By pursuing your no-fault insurance accident claim
                      with us, you can settle your dispute with the guidance and
                      expertise of our experienced solicitors. This will help
                      you secure compensation without affecting your insurance
                      rights and help you safeguard your no-claim bonus. To
                      start your no-fault claim with us, call us on the AXA
                      accident claim phone number.
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="1">
                    <Accordion.Header>
                      How long will it take to get my damaged car repaired after
                      the no-fault accident?
                    </Accordion.Header>
                    <Accordion.Body>
                      With us, you’ll get accidental repairs for your vehicle
                      without any additional charges. In case of minor scratches
                      or dents, your vehicle may get repaired in a day or two.
                      However, for severe damages, it may take a few weeks to
                      get your vehicle to its pre-accident condition.
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="2">
                    <Accordion.Header>
                      How quickly will my replacement vehicle be arranged?
                    </Accordion.Header>
                    <Accordion.Body>
                      We understand that being involved in a fault accident is
                      traumatic. Therefore, we will provide you with a
                      like-for-like replacement vehicle as soon as possible
                      after the accident. We will arrange the vehicle shortly
                      after the accident, within 24 hours.
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="3">
                    <Accordion.Header>
                      For how long can I keep the replacement vehicle?{" "}
                    </Accordion.Header>
                    <Accordion.Body>
                      When you call us on the AXA accident claims helpline, we
                      will provide you with the highest standard quality vehicle
                      that you can keep until your damaged vehicle is repaired.
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="4">
                    <Accordion.Header>
                      Do I need to pay any excess costs if I'm not at fault?
                    </Accordion.Header>
                    <Accordion.Body>
                      When you make your claim with us, you don’t have to pay
                      any upfront or excess charges. However, if you make a
                      claim to your insurance company, you might have to pay an
                      extra premium.
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="5">
                    <Accordion.Header>
                      What information do I need to make a claim after a
                      no-fault accident?{" "}
                    </Accordion.Header>
                    <Accordion.Body>
                      When making your no-fault claim with us you may have to
                      send us certain details, such as your driving licence,
                      written statement, damages pictures of the vehicle,
                      national insurance number, details of all the parties
                      involved, accident location, date, time, weather
                      conditions, road conditions, insurance certificate and any
                      other information that we request.
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="6">
                    <Accordion.Header>
                      What expenses can I claim after a no-fault accident?
                    </Accordion.Header>
                    <Accordion.Body>
                      You’re eligible to claim various types of expenses,
                      including vehicle repair or replacement costs, medical
                      expenses, lost wages, compensation for mental, physical
                      and psychological injuries, and transportation costs.
                    </Accordion.Body>
                  </Accordion.Item>
                </Accordion>
              </div>
            </div>
          </div>
        </div>
      </section>

      <Footer />
    </>
  );
};

export default AXA;
