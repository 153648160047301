import React, { useRef } from "react";
import insurancelogo from "../../src/assets/images/insurancelogo.png";
import Accordion from "react-bootstrap/Accordion";
import check from "../assets/images/check.png";
import dualcar from "../../src/assets/images/dualcar.webp";
import morethan from "../../src/assets/images/morethan.webp";
import Sidebarinner from "../components/Sidebar-inner";
import phonecall from "../assets/images/phonecall.png";
import { Helmet } from "react-helmet";
import Footer from "../components/footer";

const MoreThan = () => {
  const sidebarRef = useRef(null);

  const handleScrollToSidebar = () => {
    if (sidebarRef.current) {
      sidebarRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>More Than Claims Phone Number | More Than Insurance Claims Number</title>
        <meta
          name="description"
          content="Contact our dedicated team on the MORE THAN Insurance Accident Claim Contact Number 0800 772 0850 to receive prompt and reliable claim assistance. Get in touch today."
        />
        <link
          rel="canonical"
          href="https://insurance-accident-claims.co.uk/more-than "
        />
      </Helmet>
      <section className="innernav">
        <div className="container">
          <div className="row">
            <div className="col-md-6 col-sm-6">
              <div className="left-logo">
                {/* <img src={insurancelogo} className='insurancelogo' alt='Insurance Logo' /> */}
                <h3>Accident Claims</h3>
              </div>
            </div>
            <div className="col-md-6 col-sm-6">
              <div className="left-cta">
                <div class="headercnt">
                  <div class="chatmsg">
                    <img
                      src={phonecall}
                      className="phonecall"
                      alt="phonecall "
                    />
                  </div>
                  <div class="box-body">
                    <h3 class="nav-info-box-title">Toll Free Number </h3>
                    <p>
                      <a href="tel:0800 772 0850">0800 772 0850</a>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section
        className="newbnr"
        style={{ backgroundImage: "url(" + morethan + ")" }}
      >
        <div className="container">
          <div className="row">
            <div className="col-xxl-7 col-xl-7 col-md-10 col-12">
              <div className="innerbnr">
                <h1>
                  <span className="onespan">One Stop For The Best </span>{" "}
                  <span className="sci">Accident</span> insurance claims Solutions
                </h1>
                <p className="brokerage">
                  We specialise in managing every aspect of the no-fault car
                  accident, from vehicle recovery to claims assistance and much
                  more.
                </p>
                <div className="bnrbtn">
                  <a class="db-btn-border btn-rollover phone" href="tel:0800 772 0850">
                    Begin your claim{" "}
                    <i class="fa-solid fa-arrow-right"></i>
                  </a>
                </div>
                <div className="bnrbtn">
                <a className="db-btn-border btn-rollover desktop"  onClick={handleScrollToSidebar}>
                    Begin your claim <i className="fa-solid fa-arrow-right"></i>
                </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="stickysection">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="group-4-8">
                <div className="cl8 tf-tab">
                  <div className="content-tab">
                    <div className="inner-content">
                      <h2>
                        More than insurance accident support with{" "}
                        <span className="light">zero excess to pay</span>{" "}
                      </h2>
                      <p className="disciplines">
                        Have you been a victim of a no-fault car accident in the
                        UK? Get in touch with us at{" "}
                        <strong>
                          accident number{" "}
                          <a href="tel:0800 772 0850">0800 772 0850</a>{" "}
                        </strong>
                        to get emergency help while you’re left stranded on the
                        road. We provide top-notch{" "}
                        <strong>More Than insurance accident support</strong>{" "}
                        without any hidden fees or surprises.
                      </p>
                      <hr />
                      <p className="bold-text">No upfront payment </p>
                      <p className="bottom-te">
                        When you contact us at the{" "}
                        <strong>More Than car accident helpline</strong> to get
                        immediate assistance and{" "}
                        <strong>MoreThan insurance support</strong>, you don’t
                        have to make any excess payments. We will recover all
                        the costs from the at-fault driver's insurance company.
                      </p>
                      <hr />
                      <p className="bold-text">
                        No-claim bonus is left unaffected
                      </p>
                      <p className="bottom-te">
                        Making a claim through your own insurer can impact your
                        hard-earned discounts. Unlike your insurer, we strive to
                        safeguard your no-claims bonus by not initiating a claim
                        against your own insurance policy.
                      </p>
                      <hr />
                      <p className="bold-text">Comparable replacement </p>
                      <p className="bottom-te">
                        Our top-notch replacement vehicle will keep you mobile
                        and minimise disruption while your car is off the road.
                        You can call us at{" "}
                        <strong>
                          accident claims helpline{" "}
                          <a href="tel:0800 772 0850">0800 772 0850</a>
                        </strong>{" "}
                        to get a like-for-like replacement vehicle to carry on
                        with your routine activities.
                      </p>
                      <hr />
                      <p className="bold-text">High-grade repairs </p>
                      <p className="bottom-te">
                        We have a network of more than 1000 approved garages and
                        repair experts in the UK who provide industry-leading
                        repairs.
                      </p>
                      <hr />
                      <p className="bold-text">Dedicated claim handler</p>
                      <p className="bottom-te">
                        When you contact us via the{" "}
                        <strong>More Than accident claim phone number</strong>,
                        we will assign a dedicated claim handler who will guide
                        you throughout the process from start to end.
                      </p>
                      <hr />
                      <p className="bold-text">
                        Comprehensive claims assistance{" "}
                      </p>
                      <p className="bottom-te">
                        Our panel of experienced solicitors specialise in
                        no-fault claims. So, you’re guaranteed to receive the
                        best possible advice and assistance for your{" "}
                        <strong>More Than insurance accident claim</strong>.
                      </p>
                      <hr />
                      <p className="bold-text">Repairs monitoring</p>
                      <p className="bottom-te">
                        We will arrange for your vehicle repairs and oversee the
                        entire process, providing you with regular updates about
                        the progress.
                      </p>
                      <hr />
                      <p className="bold-text">
                        Swift claim processing and resolution{" "}
                      </p>
                      <p className="bottom-te">
                        We have a hassle-free and straightforward claim process
                        to ensure quick processing and resolution of your{" "}
                        <strong>More Than insurance accident claim</strong>.
                      </p>
                      <hr />
                      <p className="bold-text">No-fault claim experts </p>
                      <p className="bottom-te">
                        We are dedicated to handling your{" "}
                        <strong>More Than insurance accident claim</strong> with
                        dedication and professionalism. With our specialised
                        knowledge and expertise, we will secure the best
                        possible outcome for you.
                      </p>
                      <hr />

                      <p className="bold-text">Friendly and supportive team</p>
                      <p className="bottom-te">
                        Our dedicated and professional team will guide you
                        through every aspect of your claim and will also help
                        you arrange all the necessary paperwork and documents.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="cl4" ref={sidebarRef}>
                  <Sidebarinner />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="photographs">
        <div className="container">
          <div className="row">
            <h2 className="road">
              We fasten your recovery after a <br />
              <span className="light">no-fault accident </span>{" "}
            </h2>
            <div className="col-xxl-6 col-xl-6 col-md-12 col-12">
              <div className="fourbox">
                <ul>
                  <li>
                    <img src={check} className="check" alt="" />
                    After being involved in a no-fault car accident, when you
                    contact us via the{" "}
                    <strong>
                      accident claims helpline{" "}
                      <a href="tel:0800 772 0850">0800 772 0850</a>
                    </strong>
                    , we will dispatch emergency help to fast-track your
                    recovery.
                  </li>
                  <hr />
                  <li>
                    <img src={check} className="check" alt="" />
                    Our panel of solicitors will assess your{" "}
                    <strong> More Than insurance accident claim</strong> and
                    guide you with further steps to pursue your claim.
                  </li>
                  <hr />
                  <li>
                    <img src={check} className="check" alt="" />
                    Our team will assess your vehicle damages and arrange for
                    repairs from an approved network of garages and repairers.
                  </li>
                  <hr />
                  <li>
                    <img src={check} className="check" alt="" />
                    We will deliver you a replacement vehicle within 24 hours so
                    that you can hit the road again in a flash.
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-xxl-6 col-xl-6 col-md-12 col-12">
              <div className="fourbox">
                <ul>
                  <li>
                    <img src={check} className="check" alt="" />
                    From recovering your policy excess and out-of-pocket
                    expenses to handling your personal injury claims, we will
                    assist you at every step of the way.
                  </li>
                  <hr />
                  <li>
                    <img src={check} className="check" alt="" />
                    When you contact us at{" "}
                    <strong>More Than accident claims number</strong>, we will
                    appoint a dedicated claims handler to oversee every aspect
                    of your claim.
                  </li>
                  <hr />
                  <li>
                    <img src={check} className="check" alt="" />
                    We will communicate with insurance companies and other
                    parties on your behalf to ensure quick settlement of your
                    claim.
                  </li>
                  <hr />
                  <li>
                    <img src={check} className="check" alt="" />
                    We will protect your insurance rights and ensure that you’re
                    treated in a fair manner by the different parties involved
                    in the accident.
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="carprocess">
        <div className="container ">
          <div className="row">
            <div className="col-xxl-12 col-xxl-12 col-md-12 col-12">
              <h2 className="trusted-title">
                {" "}
                Our wide array of More Than <br />
                <span className="light">
                  insurance accident support services
                </span>{" "}
              </h2>
            </div>
          </div>
          <div className="row" id="possible">
            <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-12">
              <div className="claim">
                <ul>
                  <li>
                    <span className="sleej">Vehicle recovery </span> <br />
                    Is your car unroadworthy after a no-fault car accident?
                    Contact us at{" "}
                    <strong>
                      accident number{" "}
                      <a href="tel:0800 772 0850">0800 772 0850</a>
                    </strong>{" "}
                    to get your vehicle recovered from the accident scene and
                    transported to a safe place.
                  </li>
                  <li>
                    <span className="sleej">Vehicle repairs </span>
                    <br />
                    Car accidents can damage your vehicle and leave them in an
                    undrivable state. We will assess the damages to your vehicle
                    and get it repaired by an approved garage or repairer of
                    your choice.
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-12">
              <div className="claim" id="blue">
                <ul>
                  <li>
                    <span className="sleej">Vehicle storage</span> <br />
                    After the no-fault accident, it’s unsafe to leave your
                    vehicle parked on the road. So, when you call us at{" "}
                    <strong>More Than claims contact number</strong>, we will
                    make arrangements to store your damaged vehicle in our
                    well-maintained facilities until further assessment or till
                    you receive compensation for repairs.
                  </li>
                  <li>
                    <span className="sleej">Replacement car </span> <br />
                    If you have been involved in a no-fault car accident in the
                    UK, we can help you get back on the road by providing a
                    like-for-like replacement vehicle. We will deliver your
                    credit hire vehicle promptly so that you can hit the road
                    again with minimum fuss. Reach out to us at the{" "}
                    <strong>
                      car accident helpline{" "}
                      <a href="tel:0800 772 0850">0800 772 0850</a>
                    </strong>{" "}
                    to get a replacement vehicle quickly after the accident.
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-12">
              <div className="claim">
                <ul>
                  <li>
                    <span className="sleej">Injury claim </span>
                    <br />
                    Whether you want to recover your expenses or claim
                    compensation for your injury, our experienced solicitors can
                    help. We will help you recover compensation for your
                    injuries, costs and losses associated with the accident.
                  </li>
                  <li>
                    <span className="sleej">Claims management </span> <br />
                    Get in touch with us at{" "}
                    <strong>More Than accident claim phone number</strong> to
                    make your claim with us. Our dedicated solicitors will guide
                    you through the paperwork and ensure a quick settlement of
                    your <strong>More Than insurance accident claim</strong>.
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="yellowcta">
        <div className="container">
          <div className="row">
            <div className="cta2">
              <div className="col-xxl-8 col-xl-8 col-lg-8 col-12">
                <div className="legal">
                  <h3 className="experienced">
                    Need further assistance?{" "}
                    <span className="light"> Speak to our experts </span>{" "}
                  </h3>
                  <p className="professionals">
                    Get in touch with our no-fault claim specialists to get
                    professional advice for your More Than insurance accident
                    claim.
                  </p>
                </div>
              </div>
              <div className="col-xxl-4 col-xl-4 col-lg-4 col-12">
              <div class="process">
                  <a class="tf-button style-1 phone" href="tel:0800 772 0850">
                    Get in touch<span class="icon-keyboard_arrow_right"></span>
                  </a>
                </div>
                <div class="process">
                    <a className="tf-button style-1 desktop"  onClick={handleScrollToSidebar}>
                        Get in touch<span className="icon-keyboard_arrow_right"></span>
                    </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <search className="updatedform">
        <div className="container">
          <div className="row text-center">
            <div className="col-xxl-12 col-xl-12 col-md-12 col-12">
              <h2 className="whiteheading">
                Why do we stand apart from other <br />{" "}
                <span className="light">
                  accident management companies in the UK?{" "}
                </span>
              </h2>
              <p className="sustained">
                Unlike your insurer, we allow you to settle your accident issues
                promptly by organising high-quality vehicle repairs, providing
                comparable replacement and offering professional advice at no
                cost to you. Reach out to us at{" "}
                <strong>
                  accident claims helpline{" "}
                  <a href="tel:0800 772 0850">0800 772 0850</a>
                </strong>{" "}
                to get the best solutions for your accident management needs.{" "}
              </p>
            </div>
          </div>
          <div className="row">
            <div className="col-xxl-4 col-xl-4 col-lg-6 col-md-12 col-12">
              <div className="Claimant">
                <h5>Quick assistance </h5>
                <p className="collision">
                  When you contact us at{" "}
                  <strong>More Than accident claims number</strong> for
                  emergency help, our dedicated team will promptly address your
                  needs to minimise inconvenience.
                </p>

                <h5>Expert solicitors</h5>
                <p className="collision">
                  Our experienced panel of solicitors will handle your More Than
                  insurance accident claim with expert efficiency and
                  dedication.
                </p>

                <h5>Guarantee on repairs</h5>
                <p className="collision">
                  In addition to arranging the repairs of your vehicle, we
                  guarantee the quality of repairs, ensuring your vehicle
                  repairs meet the highest standards.
                </p>
              </div>
            </div>
            <div className="col-xxl-4 col-xl-4 col-lg-6 col-md-12 col-12">
              <div className="Claimant">
                <h5>Manufacturer-approved parts and paints </h5>
                <p className="collision">
                  We ensure genuine repairs of your vehicle using
                  manufacturer-approved parts and paints so that your vehicle is
                  restored to its original condition.
                </p>

                <h5>Round-the-clock assistance </h5>
                <p className="collision">
                  Our dedicated team is available round the clock to provide
                  immediate assistance after a no-fault car accident and guide
                  you in filing{" "}
                  <strong>More Than insurance accident claims</strong> in the
                  UK.
                </p>

                <h5>Like-for-like replacement </h5>
                <p className="collision">
                  Our accident management experts will provide you with a
                  comparable replacement vehicle with no excess to pay.
                </p>
              </div>
            </div>
            <div className="col-xxl-4 col-xl-4 col-lg-12 col-md-12 col-12">
              <div className="Claimant">
                <h5>Assistance with all aspects of the claim </h5>
                <p className="collision">
                  We provide comprehensive claim support by assisting you with
                  several aspects of your claim, including recovery of the cost
                  of the replacement vehicle, repair expenses, loss of earnings
                  and injury compensation.
                </p>

                <h5>Swift claim resolution</h5>
                <p className="collision">
                  We follow a streamlined and hassle-free claim process to
                  settle your claim quickly without any unwanted delays.
                </p>

                <h5>Safe storage and recovery </h5>
                <p className="collision">
                  Our recovery team is trained to handle the recovery of your
                  vehicle quickly and efficiently from the accident scene to our
                  safe and secure yards.
                </p>
              </div>
            </div>
          </div>
        </div>
      </search>

      <section className="Accident-claim">
        <div className="container">
          <div className="row">
            <div className="needed">
              <h2 className="trusted-title">
                How to get the most from your More Than <br />
                <span className="light">insurance accident claim</span>
              </h2>
              <p className="complexities">
                Car accidents are traumatic. Here are the steps you can take
                after a no-fault car accident to get back on the road swiftly
                and safely. If you’re involved in a no-fault accident, call us
                at{" "}
                <strong>
                  accident claims helpline{" "}
                  <a href="tel:0800 772 0850">0800 772 0850</a>
                </strong>{" "}
                to get emergency support and assistance.
              </p>
            </div>
            <hr />
          </div>
          <div className="row" id="incdes">
            <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-12">
              <div className="Preparing">
                <ul>
                  <li>
                    <div className="seemingly">
                      <h5 className="Seek">Stay calm </h5>
                      <p>
                        Keep your cool and move your vehicle to a safe place to
                        avoid traffic jams and ensure your safety.{" "}
                      </p>
                    </div>
                  </li>
                  <li>
                    <div className="seemingly">
                      <h5 className="Seek">Document the scene </h5>
                      <p>
                        Assess the accident scene and take photographs of the
                        damaged vehicles, property or objects at the accident
                        scene. Also, locate the witnesses and record their names
                        and contact information.
                      </p>
                    </div>
                  </li>
                  <li>
                    <div className="seemingly">
                      <h5 className="Seek">Report car accident </h5>
                      <p>
                        If you‘re involved in a car accident, immediately call
                        the police to report the car accident. Also, if your
                        vehicle is damaged and cannot be driven, get emergency
                        help by giving us a call at the{" "}
                        <strong>More Than accident number</strong>.
                      </p>
                    </div>
                  </li>
                  <li>
                    <div className="seemingly">
                      <h5 className="Seek">Exchange information</h5>
                      <p>
                        Exchange crucial details with the parties involved in
                        the accident, including name and details of drivers and
                        passengers, insurance information, and date & time of
                        the accident.
                      </p>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
            <div
              className="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-12"
              id="dualcardiv"
            >
              <img src={dualcar} alt="" className="dualcar" />
            </div>
            <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-12">
              <div className="Preparing">
                <ul>
                  <li>
                    <div className="seemingly">
                      <h5 className="Seek">Document vehicle damages </h5>
                      <p>
                        Prior to getting your vehicle repaired, take pictures of
                        your damaged vehicle at the accident scene. This will
                        help you prove the fault of the other party.
                      </p>
                    </div>
                  </li>
                  <li>
                    <div className="seemingly">
                      <h5 className="Seek">Get medical treatment </h5>
                      <p>
                        If you experience any pain or discomfort, immediately
                        book an appointment with your doctor and get your
                        injuries treated.
                      </p>
                    </div>
                  </li>
                  <li>
                    <div className="seemingly">
                      <h5 className="Seek">Don’t agree to early settlement </h5>
                      <p>
                        The insurance companies may pressurise you to accept an
                        early settlement offer without fully compensating you
                        for damages and losses. Avoid accepting the initial
                        settlement offer from insurance companies without
                        assessing your damages.
                      </p>
                    </div>
                  </li>
                  <li>
                    <div className="seemingly">
                      <h5 className="Seek">
                        Start your More Than insurance accident claim{" "}
                      </h5>
                      <p>
                        Car accidents can lead to damages and injuries. To
                        recover the damages and receive injury compensation,
                        call us at{" "}
                        <strong> More Than claims contact number</strong> to
                        start your claim process and get compensation for your
                        losses.{" "}
                      </p>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="whyus">
        <div className="container">
          <div className="row">
            <div className="col-xxl-12 col-md-12">
              <div className="why">
                <h2 className="choose">
                  Make your More Than insurance accident claim effortlessly with
                  our Streamlined and{" "}
                  <span className="light">
                    <br /> Hassle-free process{" "}
                  </span>
                </h2>
                <p className="complexities">
                  With our smooth and stress-free{" "}
                  <strong>More Than insurance accident claim process</strong>,
                  you can start your claim without hassle. Get in touch with us
                  at{" "}
                  <strong>
                    car insurance claim number{" "}
                    <a href="tel:0800 772 0850">0800 772 0850</a>
                  </strong>{" "}
                  to receive quick assistance from our team for your More Than
                  insurance accident claim.
                </p>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-xxl-3 col-xl-6 col-lg-6 col-md-6 col-12">
              <div className="box-service">
                <img src={check} className="check" alt="" />
                <h4>Get in touch </h4>
                <p>
                  Contact our team via the{" "}
                  <strong>More Than car accident helpline</strong> to get
                  immediate support and assistance following a no-fault car
                  accident.
                </p>
              </div>
            </div>
            <div className="col-xxl-3 col-xl-6 col-lg-6 col-md-6 col-12">
              <div className="box-service">
                <img src={check} className="check" alt="" />
                <h4>Share accident details </h4>
                <p>
                  Provide us with relevant information related to the accident,
                  including the time, date, location and the reason behind the
                  accident.
                </p>
              </div>
            </div>
            <div className="col-xxl-3 col-xl-6 col-lg-6 col-md-6 col-12">
              <div className="box-service">
                <img src={check} className="check" alt="" />
                <h4>Supporting documents and evidence</h4>
                <p>
                  Additionally, share any relevant documentation such as photos,
                  police reports or witness statements that may help prove your
                  innocence and get deserving compensation.
                </p>
              </div>
            </div>
            <div className="col-xxl-3 col-xl-6 col-lg-6 col-md-6 col-12">
              <div className="box-service">
                <img src={check} className="check" alt="" />
                <h4>Claim assessment and resolution </h4>
                <p>
                  Our solicitors will assess your claim and negotiate with the
                  different parties to secure maximum compensation for you.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="faqs">
        <div className="container">
          <div className="row">
            <div className="col-xxl-12 col-xl-12 col-md-12 col-12">
              <div className="fq">
                <h2>
                  {" "}
                  Frequently Asked{" "}
                  <span className="light">Questions (FAQ)</span>
                </h2>
                <p className="complexities">
                  Got questions about the{" "}
                  <strong>More Than insurance accident claim?</strong> We have
                  them answered here.{" "}
                </p>
              </div>
              <div className="allfaq">
                <Accordion defaultActiveKey="0">
                  <Accordion.Item eventKey="0">
                    <Accordion.Header>
                      What type of replacement vehicle will I get?
                    </Accordion.Header>
                    <Accordion.Body>
                      We have an extensive fleet of vehicles and will provide
                      you with a cost-free replacement vehicle of a similar
                      standard to your damaged vehicle to ensure no interruption
                      to your day-to-day activities.
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="1">
                    <Accordion.Header>
                      Who pays for the services offered by you?
                    </Accordion.Header>
                    <Accordion.Body>
                      As a no-fault accident victim, you’re legally entitled to
                      receive the services we offer. We will recover the costs
                      of the replacement vehicle and repairs from the insurance
                      company of the faulty driver.
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="2">
                    <Accordion.Header>
                      Will More Than insurance accident claim affect my
                      premiums?
                    </Accordion.Header>
                    <Accordion.Body>
                      We won’t initiate a claim against your own insurance
                      policy. Therefore, your insurance premium and no claims
                      bonus won’t be affected.
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="3">
                    <Accordion.Header>
                      Can I choose my own repair shop for the repairs of my
                      damaged vehicle?
                    </Accordion.Header>
                    <Accordion.Body>
                      We offer you the flexibility to get your vehicle repaired
                      from your preferred garage. You can share the details of
                      your preferred repair shop via{" "}
                      <strong>More Than car accident helpline</strong> and we
                      will get in touch with them to schedule repairs for your
                      damaged vehicle.
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="4">
                    <Accordion.Header>
                      What benefits do I get by making More Than insurance
                      accident claim?
                    </Accordion.Header>
                    <Accordion.Body>
                      By choosing us as your accident management partner, you
                      enjoy the following benefits:
                      <ul>
                        <li>1. Zero excess.</li>
                        <li>2. Manufacturer-approved repairs.</li>
                        <li>3. No effect on no-claims bonus.</li>
                        <li>
                          4. Personalised support and guidance at every step.
                        </li>
                        <li>5. Quick claim settlement.</li>
                      </ul>
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="5">
                    <Accordion.Header>
                      What type of assistance do you provide if my vehicle is a
                      write-off?
                    </Accordion.Header>
                    <Accordion.Body>
                      If your car is written off due to the accident, we will
                      provide you with a like-for-like replacement vehicle that
                      you can keep until you receive a bank cheque from the
                      at-fault party’s insurance company.
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="6">
                    <Accordion.Header>
                      How long am I allowed to keep the replacement vehicle?
                    </Accordion.Header>
                    <Accordion.Body>
                      If your car is in a repairable condition, you can keep the
                      replacement vehicle until repairs. In case your car is
                      entirely written off, you can keep the courtesy car until
                      you receive a cheque amounting to the full value of the
                      vehicle.
                    </Accordion.Body>
                  </Accordion.Item>
                </Accordion>
              </div>
            </div>
          </div>
        </div>
      </section>

      <Footer />
    </>
  );
};

export default MoreThan;
