import React, { useRef } from "react";
import Accordion from "react-bootstrap/Accordion";
import check from "../assets/images/check.png";
import dualcar from "../../src/assets/images/dualcar.webp";
import hasting from "../../src/assets/images/hasting.webp";
import Sidebarinner from "../components/Sidebar-inner";
import phonecall from "../assets/images/phonecall.png";
import Footer from "../components/footer";
import { Helmet } from "react-helmet";

const Mercedesbenzinsurnace = () => {
    const sidebarRef = useRef(null);

    const handleScrollToSidebar = () => {
        if (sidebarRef.current) {
            sidebarRef.current.scrollIntoView({ behavior: "smooth" });
        }
    };
    return (
        <>
        <Helmet>
        <meta charSet="utf-8" />
        <title>Mercedes-Benz Car Insurance Accident Claim Phone Number</title>
        <meta
          name="description"
          content="Contact the Mercedes-Benz Car Insurance Accident Claim Phone Number 0800 772 0850 for swift claim assistance and reliable support after a car accident in the UK. "
        />
        <link
          rel="canonical"
          href="https://insurance-accident-claims.co.uk/Mercedes-benz-insurance"
        />
      </Helmet>
            <section className="innernav">
                <div className="container">
                    <div className="row">
                        <div className="col-md-6 col-sm-6">
                            <div className="left-logo">
                                <h3>
                                    Accident<span className="sipo"> Claims</span>
                                </h3>
                            </div>
                        </div>
                        <div className="col-md-6 col-sm-6">
                            <div className="left-cta">
                                <div class="headercnt">
                                    <div class="chatmsg">
                                        <img
                                            src={phonecall}
                                            className="phonecall"
                                            alt="phonecall "
                                        />
                                    </div>
                                    <div class="box-body">
                                        <h3 class="nav-info-box-title">Toll Free Number </h3>
                                        <p>
                                            <a href="tel:0800 772 0850">0800 772 0850</a>
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section
                className="newbnr"
                style={{ backgroundImage: "url(" + hasting + ")" }}
            >
                <div className="container">
                    <div className="row">
                        <div className="col-xxl-7 col-xl-7 col-md-10 col-12">
                            <div className="innerbnr">
                                <h1>
                                    <span className="onespan">Dedicated and trusted no-fault</span>{" "}
                                    <span className="sci">claims   </span> help
                                </h1>
                                <p className="brokerage">
                                Claims management should not be a concern. We will handle everything from the moment you call us for assistance up to when you are back on the road.                             </p>
                                <div className="bnrbtn">
                                    <a class="db-btn-border btn-rollover phone" href="tel:0800 772 0850">
                                        Road Accident Claim Help?{" "}
                                        <i class="fa-solid fa-arrow-right"></i>
                                    </a>
                                </div>
                                <div className="bnrbtn">
                                    <a className="db-btn-border btn-rollover desktop" onClick={handleScrollToSidebar}>
                                        Road Accident Claim Help? <i className="fa-solid fa-arrow-right"></i>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="stickysection">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="group-4-8">
                                <div className="cl8 tf-tab">
                                    <div className="content-tab">
                                        <div className="inner-content">
                                            <h2>
                                            Top insurance claims handlers
                                                <br />
                                                <span className="light"> in the UK</span>{" "}
                                            </h2>
                                            <p className="disciplines">
                                            We are one of the leading providers of <strong>  Mercedes-Benz insurance claims </strong> all over the UK, with several thousand positive outcomes. In case you need help making a claim, you can get in touch with us through the <strong> Mercedes-Benz car insurance contact number.</strong> Our team will process your <strong> Mercedes-Benz car insurance claim </strong> at absolutely zero cost.
                                            </p>
                                            <hr />
                                            <p className="bold-text">No additional charge for excess</p>
                                            <p className="bottom-te"> This is to say that if the accident was not your fault, then there will be no extra charge for an insurance excess just to hike your premiums.                                        </p>
                                            <hr />
                                            <p className="bold-text">Unaffected No Claims Bonus</p>
                                            <p className="bottom-te">
                                            You do not have to lose your no-claim bonus since we won’t make a claim against your own insurance policy. Your no-claim bonus is safe when you make your claim with us.                                           </p>
                                            <hr />
                                            <p className="bold-text">
                                            Quick recovery 
                                            </p>
                                            <p className="bottom-te">
                                            There is no reason for you to stay stranded on the road after an accident. Simply use the <strong> Mercedes-Benz car insurance number </strong>  to dial the emergency telephone line, and we shall immediately recover your vehicle from the accident scene.                                           </p>
                                            <hr />
                                            <p className="bold-text">Manufacturer-approved repair centre </p>
                                            <p className="bottom-te">
                                            We will make sure your car is repaired in accordance with the highest standards in our manufacturer-approved garages.                                       </p>
                                            <hr />
                                            <p className="bold-text">Replacement vehicle on a like-for-like basis</p>
                                            <p className="bottom-te">
                                            In case you have an accident or your car is unroadworthy, we can provide you with another replacement vehicle with similar features to the one you had.                                         </p>
                                            <hr />
                                            <p className="bold-text">Experienced solicitors </p>
                                            <p className="bottom-te">
                                            Our experienced solicitors know how to handle <strong> Mercedes-Benz insurance claims </strong>and have assisted victims in getting equitable compensation for no-fault accidents.
                                            </p>
                                            <hr />
                                            <p className="bold-text">Speedy processing of claims</p>
                                            <p className="bottom-te">
                                            When you claim from us, don’t worry about paperwork. We have a streamlined claiming process that minimises paperwork.                                           </p>
                                            <hr />
                                            <p className="bold-text">Unrestricted advice </p>
                                            <p className="bottom-te">
                                            Feel free to call <strong>
                                                    {" "}
                                                    <a href="tel:0800 772 0850">0800 772 0850</a>{" "}
                                                </strong>  if you have any questions about <strong> Mercedes-Benz insurance claims .</strong> Our solicitors will advise on what next in relation to your situation.                                             </p>
                                            <hr />
                                            <p className="bold-text">No win-no fee</p>
                                            <p className="bottom-te">
                                            Our solicitors operate a no-win-no-fee policy when handling our clients’ insurance claims, meaning that if they don't succeed in securing a favourable outcome for your claim, you don’t have to pay anything.                                      </p>
                                            <hr />

                                            <p className="bold-text">Round-the-clock support </p>
                                            <p className="bottom-te">
                                            Don't hesitate to get in touch with us if an accident happens and you need someone who can give emergency help and guidance. In order to get immediate assistance after having been involved in an accident, please ring our car insurance claim hotline at 
                                           <strong>
                                                    {" "}
                                                    <a href="tel:0800 772 0850">0800 772 0850</a>{" "}
                                                </strong>   
                                                </p>
                                        </div>
                                    </div>
                                </div>
                                <div className="cl4" ref={sidebarRef}>
                                    <Sidebarinner />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="photographs">
                <div className="container">
                    <div className="row">
                        <h2 className="road">
                        We are here to relieve you from stress by providing comprehensive   {" "}
                            <span className="light">
                            accident <br />
                                management support
                            </span>{" "}
                        </h2>
                        <div className="col-xxl-6 col-xl-6 col-md-12 col-12">
                            <div className="fourbox">
                                <ul>
                                    <li>
                                        <img src={check} className="check" alt="" />
                                        In case of an emergency following a car crash, you can contact our <strong> Mercedes-Benz car insurance contact number.</strong>

                                    </li>
                                    <hr />
                                    <li>
                                        <img src={check} className="check" alt="" />
                                        We shall endeavour to trace any responsible party against whom the claim shall be pursued on your behalf for damages.                                </li>
                                    <hr />
                                    <li>
                                        <img src={check} className="check" alt="" />
                                        To aid in claiming any financial loss suffered by you, we will help you in handling paperwork and documentation for your <strong> Mercedes-Benz insurance claims.</strong> 
                                    </li>
                                    <hr />
                                    <li>
                                        <img src={check} className="check" alt="" />
                                        Our team will take your vehicle from the accident scene and organise its repair with our reliable network of approved manufacturers’ repairers.                            </li>
                                </ul>
                            </div>
                        </div>
                        <div className="col-xxl-6 col-xl-6 col-md-12 col-12">
                            <div className="fourbox">
                                <ul>
                                    <li>
                                        <img src={check} className="check" alt="" />
                                        If it turns out that your vehicle has sustained damages that are beyond reasonable economic repair, an independent engineer will be arranged to assess the damages to your vehicle.                                </li>
                                    <hr />
                                    <li>
                                        <img src={check} className="check" alt="" />
                                        A replacement vehicle will be provided on a temporary basis to you until your claim is settled.                             </li>
                                    <hr />
                                    <li>
                                        <img src={check} className="check" alt="" />
                                        We will keep you informed about the progress made with respect to repairs by continuously monitoring your vehicle repairs. 
                                        </li>
                                    <hr />
                                    <li>
                                        <img src={check} className="check" alt="" />
                                        Dial<strong>
                                            {" "}
                                            <a href="tel:0800 772 0850">0800 772 0850</a>{" "}
                                        </strong> now and let us guide you to make a successful <strong> Mercedes-Benz insurance claim  </strong>  to claim compensation for damages and losses.                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="carprocess">
                <div className="container ">
                    <div className="row">
                        <div className="col-xxl-12 col-xxl-12 col-md-12 col-12">
                            <h2 className="trusted-title">
                                {" "}
                                Our accident management   <br />
                                <span className="light"> services  </span>{" "}
                            </h2>
                        </div>
                    </div>
                    <div className="row" id="possible">
                        <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-12">
                            <div className="claim">
                                <ul>
                                    <li>
                                        <span className="sleej">Vehicle recovery:</span> To have your vehicle rescued after an accident, please phone the <strong> Mercedes-Benz car insurance contact number. </strong> Our recovery services are specifically designed to ensure that your car is protected through prompt retrieval from the scene of the accident into our secure storage yards.   
                                    </li>
                                    <li>
                                        <span className="sleej">Authorised repairs:</span>If you want your car to be repaired, get in touch with us using the <strong> Mercedes-Benz insurance claim number. </strong>  The repair of your vehicle will be carried out at trusted and reliable garages across the UK without any extra charges.  

                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-12">
                            <div className="claim" id="blue">
                                <ul>
                                    <li>
                                        <span className="sleej">Vehicle storage:</span> Do you want to keep your car safe after a not-your-fault accident? Dial our  <strong> Mercedes-Benz insurance claim number.</strong>  We have well-maintained yards where we offer hassle-free storage services as you await assessment for your claim.
                                    </li>
                                    <li>
                                        <span className="sleej">Replacement car :</span>If it is impossible to fix your car, we will provide a replacement vehicle on request. Make use of our emergency helpline
                                        <strong>
                                            {" "}
                                            <a href="tel:0800 772 0850">0800 772 0850</a>{" "}
                                        </strong>
                                        if you require a temporary replacement vehicle similar to yours that ensures normal life flow with no fuss. You may retain it until the final settlement of your claim.

                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-12">
                            <div className="claim">
                                <ul>
                                    <li>
                                        <span className="sleej">Personal injury support </span>
                                        Would you like to claim for personal injuries suffered in the accident? Call our company on the <strong> Mercedes-Benz insurance claim number </strong> to get expert advice from our solicitors regarding claiming compensation for injuries sustained.                                 </li>
                                    <li>
                                        <span className="sleej">Claim management :</span>  From paperwork to dealing with insurers, car repairs and the provision of alternative vehicles, we will handle everything. 
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="yellowcta">
                <div className="container">
                    <div className="row">
                        <div className="cta2">
                            <div className="col-xxl-8 col-xl-8 col-lg-8 col-12">
                                <div className="legal">
                                    <h3 className="experienced">
                                    Got more questions? Talk to {" "}
                                        <span className="light">our specialists 
                                        </span>{" "}
                                    </h3>
                                    <p className="professionals">
                                    Get in touch with our no-fault claim experts via <strong> car insurance contact number</strong>
                                        {" "}
                                        <strong>{" "}
                                            <a className="cpt-no" href="tel:0800 772 0850">
                                                {" "}
                                                0800 772 0850{" "}
                                            </a>{" "}
                                        </strong>
                                        to get the best advice for the 
                                        <strong> Mercedes-Benz insurance claims.  </strong>
                                    </p>
                                </div>
                            </div>
                            <div className="col-xxl-4 col-xl-4 col-lg-4 col-12">
                                <div class="process">
                                    <a class="tf-button style-1 phone" href="tel:0800 772 0850">
                                        Get in touch<span class="icon-keyboard_arrow_right"></span>
                                    </a>
                                </div>
                                <div class="process">
                                    <a className="tf-button style-1 desktop" onClick={handleScrollToSidebar}>
                                        Get in touch<span className="icon-keyboard_arrow_right"></span>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <search className="updatedform">
                <div className="container">
                    <div className="row text-center">
                        <div className="col-xxl-12 col-xl-12 col-md-12 col-12">
                            <h2 className="whiteheading">
                                {" "}
                                What makes us the best choice for handling your
                                <span className="light"> accident management needs?
                                </span>
                            </h2>
                            <p className="sustained">
                            It will be our responsibility to relieve you from the stress related to non-fault road accidents. Some of the benefits you are entitled to whenever you choose us as your preferred handling firm are listed below.                 </p>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-xxl-4 col-xl-4 col-lg-6 col-md-12 col-12">
                            <div className="Claimant">
                                <h5>24/7 vehicle recovery</h5>
                                <p className="collision">
                                Are you in need of a company that offers vehicle recovery services? Call the <strong> Mercedes-Benz insurance claim number, </strong>and we’ll come for you at accident scenes, irrespective of time.

                                </p>

                                <h5>Guaranteed repairs</h5>
                                <p className="collision">
                                We have a number of garages that offer high-quality repairs that match the manufacturer's specifications.                           </p>

                                <h5>No increase in premiums</h5>
                                <p className="collision">
                                The<strong> Mercedes-Benz car insurance claims </strong> are made against other drivers’ policies, meaning that no rise in premiums occurs on your policy.
                                </p>
                            </div>
                        </div>
                        <div className="col-xxl-4 col-xl-4 col-lg-6 col-md-12 col-12">
                            <div className="Claimant">
                                <h5>Free replacement cars
                                </h5>
                                <p className="collision">
                                For uninterrupted movement, we will give you a like-to-like replacement vehicle without any upfront payment. </p>
                                <h5>Dependable network of partners </h5>
                                <p className="collision">
                                We have built a strong link with accredited solicitors and garages throughout the UK with the aim of giving quality service provision to all our customers.                          </p>

                                <h5>Immediate claim settlement</h5>
                                <p className="collision">
                                Once negotiations with the other party’s insurer have been concluded by our experienced solicitors, expect a fair settlement of your <strong> Mercedes-Benz car insurance claim </strong> 
                                </p>
                            </div>
                        </div>
                        <div className="col-xxl-4 col-xl-4 col-lg-12 col-md-12 col-12">
                            <div className="Claimant">
                                <h5>Fast help</h5>
                                <p className="collision">
                                We assure a quick emergency response after a non-fault accident with no delay.                               </p>

                                <h5>One-on-one advice</h5>
                                <p className="collision">
                                Your claim is our top priority. For this reason, we will provide you with a claims handler who will handle your case directly and inform you about the progress at every step.                           </p>

                                <h5>No excess payment </h5>
                                <p className="collision">
                                We shall not charge you extra or additional costs as we will recover all costs from the other party’s insurer.                     </p>
                            </div>
                        </div>
                    </div>
                </div>
            </search>

            <section className="Accident-claim">
                <div className="container">
                    <div className="row">
                        <div className="needed">
                            <h2 className="trusted-title">
                            How can you maximise my chances of <br />
                                <span className="light">getting full compensation?
                                </span>
                            </h2>
                            <p className="complexities">
                            Nevertheless, being involved in an accident that is not caused by you is quite painful. However, the process of making claims can prove worse. To enhance your likelihood of recovering enough amounts as damages, demonstrate that you were not guilty by presenting concrete proof. Here are things to do after a car accident if you want to be compensated fairly.                      </p>
                        </div>
                        <hr />
                    </div>
                    <div className="row" id="incdes">
                        <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-12">
                            <div className="Preparing">
                                <ul>
                                    <li>
                                        <div className="seemingly">
                                            <h5 className="Seek">Ensure your own safety:</h5>
                                            <p>
                                                {" "}
                                                Ensure that you stop and park in a safe spot and examine yourself and your passengers for any visible or invisible wounds.                                   </p>
                                        </div>
                                    </li>
                                    <li>
                                        <div className="seemingly">
                                            <h5 className="Seek">
                                            Get immediate medical attention :{" "}
                                            </h5>
                                            <p>
                                            If someone has suffered any injuries in a car accident, quickly find a good physician to get the injuries treated.                                          </p>
                                        </div>
                                    </li>
                                    <li>
                                        <div className="seemingly">
                                            <h5 className="Seek">Collect evidence:</h5>
                                            <p> The information from the scene of an accident that is needed consists of photographs of the location of the accident, responsible parties, damages inflicted, serious injuries and their visible signs, and eyewitness statements.                                        </p>
                                        </div>
                                    </li>
                                    <li>
                                        <div className="seemingly">
                                            <h5 className="Seek">Report the accident to the police :</h5>
                                            <p>
                                            Contact police authorities with regard to what happened and obtain a copy of the police report to support your <strong> Mercedes-Benz insurance claim. </strong>                                              </p>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div
                            className="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-12"
                            id="dualcardiv"
                        >
                            <img src={dualcar} alt="" className="dualcar" />
                        </div>
                        <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-12">
                            <div className="Preparing">
                                <ul>
                                    <li>
                                        <div className="seemingly">
                                            <h5 className="Seek">Share details :</h5>
                                            <p>
                                            If you have had such accidents before, it would be great if you could provide some information to another driver involved in them. 
                                   </p>
                                        </div>
                                    </li>
                                    <li>
                                        <div className="seemingly">
                                            <h5 className="Seek">Record the address of eyewitnesses : </h5>
                                            <p>
                                            Note down eyewitnesses’ personal information so that they can be reached out in future about testifying what they saw. 
                                            </p>
                                        </div>
                                    </li>
                                    <li>
                                        <div className="seemingly">
                                            <h5 className="Seek">
                                            Write down expenses :
                                            </h5>
                                            <p>
                                            You should retain all receipts for any expenditures made, such as repair expenses, hospital bills or any other cost for reimbursement purposes. 
                                            </p>
                                        </div>
                                    </li>
                                    <li>
                                        <div className="seemingly">
                                            <h5 className="Seek">
                                            Talk to a no-fault solicitor:
                                            </h5>
                                            <p>
                                            Call our helpline now at {" "}
                                                <strong>{" "}
                                                    <a className="cpt-no" href="tel:0800 772 0850">
                                                        {" "}
                                                        0800 772 0850  {" "}
                                                    </a>{" "}
                                                </strong>
                                               , and our team will guide you through making a successful compensation claim. 


                                            </p>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="whyus">
                <div className="container">
                    <div className="row">
                        <div className="col-xxl-12 col-md-12">
                            <div className="why">
                                <h2 className="choose">
                                Begin your claim with our simplified and {" "}
                                    <span className="light">  hassle-free process</span>
                                </h2>
                                <p className="complexities">
                                Submitting a Mercedes-Benz motor insurance claim after an accident for which you were not responsible can be a daunting task. However, with our simplified claims process, you can easily start your claim without any hassle. Get started on calling us through the <strong> Mercedes-Benz car insurance contact number. </strong>                    </p>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-xxl-3 col-xl-6 col-lg-6 col-md-6 col-12">
                            <div className="box-service">
                                <img src={check} className="check" alt="" />
                                {/* <img src={experience} className="experience" alt="" /> */}
                                <h4>Contact us </h4>
                                <p>
                                You can discuss your claim with us via the<strong> Mercedes-Benz car insurance contact number. </strong>  </p>
                            </div>
                        </div>
                        <div className="col-xxl-3 col-xl-6 col-lg-6 col-md-6 col-12">
                            <div className="box-service">
                                <img src={check} className="check" alt="" />
                                <h4>Provide details  </h4>
                                <p>
                                Give us full details of the accident, including when, where and how it happened.</p>
                            </div>
                        </div>
                        <div className="col-xxl-3 col-xl-6 col-lg-6 col-md-6 col-12">
                            <div className="box-service">
                                <img src={check} className="check" alt="" />
                                <h4>Evidence submission </h4>
                                <p>
                                Submit photographs, videos, witness statements, police reports or even CCTV footage to support your claim. 
                                </p>
                            </div>
                        </div>
                        <div className="col-xxl-3 col-xl-6 col-lg-6 col-md-6 col-12">
                            <div className="box-service">
                                <img src={check} className="check" alt="" />
                                <h4>Claim initiation </h4>
                                <p>
                                Claims will be attended to immediately after valuation by our team. For any developments, we will get in touch with you every step of the way. 
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="faqs">
                <div className="container">
                    <div className="row">
                        <div className="col-xxl-12 col-xl-12 col-md-12 col-12">
                            <div className="fq">
                                <h2>
                                    {" "}
                                    Frequently Asked{" "}
                                    <span className="light">Questions</span>
                                </h2>
                                <p className="complexities">
                                Got questions about the <strong> Mercedes-Benz insurance claims?</strong> We have them answered here. 
                                </p>
                            </div>
                            <div className="allfaq">
                                <Accordion defaultActiveKey="0">
                                    <Accordion.Item eventKey="0">
                                        <Accordion.Header>
                                        Are there additional costs for courtesy cars?                               </Accordion.Header>
                                        <Accordion.Body>
                                        No, everything about your vehicle replacement will be taken care of by the guilty party’s insurer unless you refuse to cooperate with us, involve a solicitor without our consent or give wrong information concerning your case.                                      </Accordion.Body>
                                    </Accordion.Item>
                                    <Accordion.Item eventKey="1">
                                        <Accordion.Header>
                                        How long does it take to settle my claim?
                                        </Accordion.Header>
                                        <Accordion.Body>
                                        We would do all within our capabilities to reach an agreement on your claim as quickly as possible, but there is no time frame as each case is unique. We will keep you updated after we initiate your claim process.                                   </Accordion.Body>
                                    </Accordion.Item>
                                    <Accordion.Item eventKey="2">
                                        <Accordion.Header>
                                        Will the insurance company of the person responsible for a car accident pay for the recovery of my vehicle?
                                        </Accordion.Header>
                                        <Accordion.Body>
                                        Yes, all towing costs are met by the at-fault party’s insurer. You must provide some evidence, like photographs taken at an accident scene or a police report that shows you have entitlements to compensation.                     </Accordion.Body>
                                    </Accordion.Item>
                                    <Accordion.Item eventKey="3">
                                        <Accordion.Header>
                                        Can I get a credit hire car following a no-fault road accident?
                                        </Accordion.Header>
                                        <Accordion.Body>
                                        Yes. If you did not cause the car crash, we can offer you a credit hire. Call us on our <strong> Mercedes-Benz insurance claim number </strong>  when your car is written off or in need of repair, and we will give you another vehicle similar to your own car.                                    </Accordion.Body>
                                    </Accordion.Item>
                                    <Accordion.Item eventKey="4">
                                        <Accordion.Header>
                                        Will I lose my no-claims bonus if I’m not at fault?

                                        </Accordion.Header>
                                        <Accordion.Body>
                                        We do not make claims against your policy, so your no-claim bonus won’t be affected. 
                                        </Accordion.Body>
                                    </Accordion.Item>
                                    <Accordion.Item eventKey="5">
                                        <Accordion.Header>
                                        Can I use my own garage for repairs?{" "}
                                        </Accordion.Header>
                                        <Accordion.Body>
                                        Certainly. You may choose to have work done at any workshop of your choice. By booking vehicle repairs with your selected garage, we will guarantee swift repairs that meet only the highest standards possible                </Accordion.Body>
                                    </Accordion.Item>
                                    <Accordion.Item eventKey="6">
                                        <Accordion.Header>
                                        What kind of car will I get as a replacement? {" "}
                                        </Accordion.Header>
                                        <Accordion.Body>
                                        Replacement cars are organised according to size, value and function, similar to your own vehicle.  Just contact us on the <strong> Mercedes-Benz insurance claim number </strong>  and tell us what you need. We shall give you the replacement vehicle that suits you well enough.

                                        </Accordion.Body>
                                    </Accordion.Item>
                                </Accordion>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <Footer />
        </>
    );
};

export default Mercedesbenzinsurnace;
