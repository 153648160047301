import React from "react";
import insurancelogo from "../../src/assets/images/insurancelogo.png";
import phonecall from "../assets/images/phonecall.png";
import Footer from "../components/footer";

const Privacypolicy = () => {
  return (
    <>
      <section className="innernav">
        <div className="container">
          <div className="row">
            <div className="col-md-6 col-sm-6">
              <div className="left-logo">
                {/* <img src={insurancelogo} className='insurancelogo' alt='Insurance Logo' /> */}
                <h3>Accident Claims</h3>
              </div>
            </div>
            <div className="col-md-6 col-sm-6">
              <div className="left-cta">
                <div class="headercnt">
                  <div class="chatmsg">
                    <img
                      src={phonecall}
                      className="phonecall"
                      alt="phonecall "
                    />
                  </div>
                  <div class="box-body">
                    <h3 class="nav-info-box-title">Toll Free Number </h3>
                    <p>
                      <a href="tel:0800 772 0850">0800 772 0850</a>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="privacybnr">
        <div className="container">
          <div className="row">
            <div className="col-xxl-12 col-12">
              <h2>Privacy Policy</h2>
            </div>
          </div>
        </div>
      </section>

      <section className="privacy-content">
        <div className="container">
          <div className="row">
            <div className="col-xxl-12 col-12">
              <div className="consent">
                <h2 className="prolu">
                  Insurance accident claims
                  <br />
                  <span className="light">Privacy policy</span>{" "}
                </h2>
                <p className="safeguarding">
                  At Insurance Accident Claims, safeguarding your personal
                  information is our top priority. We are dedicated to treating
                  your data with the utmost respect and ensuring the
                  confidentiality of your information.
                </p>

                <p className="safeguarding">
                  This privacy policy outlines how we collect, utilize, and
                  safeguard your personal data. By entrusting us with your
                  personal information, you acknowledge and consent to its
                  processing in accordance with this privacy policy.
                  Additionally, when submitting personal data on behalf of
                  others, you confirm that you have obtained their consent to
                  disclose their information. You’re advised to read this
                  privacy policy before providing your personal information to
                  us.
                </p>
                <p className="bold-t">Who we are </p>
                <p className="safeguarding">
                  In this policy, whenever you see the words ‘We’, ‘Us’ or ‘Our’
                  it refers to Insurance Accident Claims. Under the General Data
                  Protection Regulation, we are the Data Controller responsible
                  for the personal information collected and processed as
                  outlined in this privacy policy. We specialize in accident
                  claim management, focusing on no-fault road traffic accident
                  claims and providing accident management services to
                  individuals involved in non-fault accidents.
                </p>
                <p className="bold-t">Information we collect </p>
                <p className="safeguarding">
                  As part of our service provision, we collect personal data and
                  sensitive personal information. Additionally, we adhere to UK
                  laws by monitoring or recording calls, emails, SMS messages,
                  or other communications.
                </p>
                <p className="safeguarding">
                  The personal information we collect may include your name,
                  postal address, phone number, email address, driving license
                  details, insurance details or any other confidential documents
                  related to your car accident claim. We may also collect
                  sensitive personal information about your medical history,
                  claims history and criminal convictions.
                </p>
                <p className="bold-t">When we collect your information</p>
                <p className="safeguarding">
                  We collect your personal data when:
                </p>
                <ul className="prilisting">
                  <li>You inquire about our services as a customer</li>
                  <li>You register for information or other services</li>
                  <li>You file a claim</li>
                  <li>You respond to communications or surveys</li>
                </ul>

                <p className="bold-t">Use of your information</p>
                <p className="safeguarding">
                  We use your personal information for the following purposes:
                </p>
                <ul className="prilisting">
                  <li>Preventing and detecting fraud</li>
                  <li>Managing and processing your claim</li>
                  <li>
                    Communicating with you regarding the progress of your claim
                  </li>
                  <li>Verifying your identity as needed</li>
                  <li>
                    Assessing and analyzing data to enhance our services and
                    provide you with relevant information
                  </li>
                  <li>Pursue our legitimate business interests</li>
                </ul>

                <p className="bold-t">Information Sharing </p>
                <p className="safeguarding">
                  We believe in transparency regarding the sharing of your
                  information with our partners, as we have a legitimate
                  interest in doing so. To provide our comprehensive services,
                  we engage with various service providers and we may share your
                  personal information with them. Besides ourselves, other
                  entities that may have access to your data include:
                </p>

                <ul className="prilisting">
                  <li>
                    Service providers engaged to facilitate the progression of
                    your claim. These may include:
                  </li>
                  <li>
                    Third parties engaged in the claim process, such as
                    insurers, solicitors, or representatives
                  </li>
                  <li>
                    Medical professionals, law enforcement, or investigators
                  </li>
                  <li>Designated engineers</li>
                  <li>Our solicitor panel</li>
                  <li>Approved repair garages that would complete repairs</li>
                </ul>
                <p className="bold-t">
                  Confidentiality and disclosure of your data
                </p>
                <p className="safeguarding">
                  We are committed to treating your personal data with the
                  utmost privacy and confidentiality. However, there are
                  instances where disclosure may be necessary as mandated by law
                  or to protect our interests. These exceptional cases include:
                </p>
                <ul className="prilisting">
                  <li>Legal compulsion to disclose your information</li>
                  <li>Duty to disclose for public interest</li>
                  <li>Disclosure required to safeguard our interests</li>
                  <li>Disclosure made at your request or with your consent</li>
                </ul>
                <p className="safeguarding">
                  If you complain about our service, we may be obligated to
                  share details, including your personal data, with the relevant
                  ombudsman. Rest assured, they are bound by the Data Protection
                  Act and are required to maintain strict confidentiality of
                  your personal data.
                </p>
                <p className="bold-t">Data security </p>
                <p className="safeguarding">
                  Ensuring the security of your data is paramount to us. We
                  implement robust measures to safeguard your personal
                  information against unauthorized access, alteration,
                  disclosure, or destruction. Our security protocols adhere to
                  industry best practices and regulatory standards to provide
                  you with peace of mind regarding the protection of your data.
                  However, no transmission of information over the Internet is
                  100% secure. We cannot guarantee the security of information
                  transmitted via the Internet.
                </p>
                <p className="bold-t">Data protection rights </p>
                <p className="safeguarding">
                  You have the following rights regarding the protection of
                  personal information we have about you.
                </p>
                <ul className="prilisting">
                  <li>
                    Access: You have the right to access the personal data we
                    hold about you.
                  </li>
                  <li>
                    Rectification: You can request the correction of any
                    inaccuracies in your personal data.
                  </li>
                  <li>
                    Erasure: You have the right to request the deletion of your
                    personal data under certain circumstances.
                  </li>
                  <li>
                    Objection: You can object to the processing of your personal
                    data for certain purposes, such as direct marketing.
                  </li>
                  <li>
                    Restriction: You have the right to request the restriction
                    of processing of your personal data in certain situations.
                  </li>
                  <li>
                    Data portability: You can request a copy of your personal
                    data in a structured, machine-readable format for transfer
                    to another organization.
                  </li>
                  <li>
                    Right to be informed: You have the right to be informed
                    about how your personal data is being used.
                  </li>
                  <li>
                    Right to withdraw consent: If we rely on your consent to
                    process your personal data, you have the right to withdraw
                    that consent at any time.
                  </li>
                  <li>
                    Right to complain: You have the right to complain to a
                    supervisory authority if you believe your data protection
                    rights have been violated.
                  </li>
                </ul>
                <p className="bold-t">Changes to privacy policy </p>
                <p className="safeguarding">
                  We reserve the right to update this privacy policy
                  periodically. We recommend that you check this page regularly
                  to remain informed about any changes to our privacy practices.
                </p>
                <p className="bold-t">Contact us </p>
                <p className="safeguarding">
                  If you have any questions or queries about our privacy policy,
                  feel free to contact us at{" "}
                  <strong>
                    <a href="mailto:info@insurance-accident-claims.co.uk">
                      info@insurance-accident-claims.co.uk
                    </a>
                  </strong>
                  .
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <Footer />
    </>
  );
};

export default Privacypolicy;
