import React from "react";

import {
  BrowserRouter as Router,
  Routes,
  Route,
  useRoutes,
} from "react-router-dom";

import { useEffect } from "react";
import ScrollToTop from "./ScrollToTop";
import { useState } from "react";
import Preloader from "./components/preloader";
import Home from "./pages/home";
import Thankyou from "./pages/thank-you";
import Admiralaccident from "./pages/admiral-accident-claims"
import AXA from "./pages/axa";
import Privacypolicy from "./pages/privacy-policy";
import Termsandconditions from "./pages/termsandconditions";
import Aviva from "./pages/aviva";
import Churchill from "./pages/churchill";
import DirectLine from "./pages/direct-line";
import Esure from "./pages/esure";
import HastingsDirect from "./pages/hastings-direct";
import LiverpoolVictoria from "./pages/lv-insurance-claims";
import MoreThan from "./pages/more-than";
import Nationwide from "./pages/nationwide";
import Sagacarinsurance from "./pages/saga-car-insurance";
import Tescocarinsurance from "./pages/tesco-car-insurance";
import Zurich from "./pages/zurich";
import Coopinsurance from "./pages/co-op-insurance";
import Swintoninsurance from "./pages/swinton-insurance";
import RACcarinsurance from "./pages/rac-car-insurance";
import Sainsburysbank from "./pages/sainsburys-bank-car-insurance";
import Johnlewisfinance from "./pages/john-lewis-finance";
import Aacarinsurance from "./pages/aa-car-insurance";
import Sheilaswheels from "./pages/sheilas-wheels";
import Diamond from "./pages/diamond";
import Elephant from "./pages/elephant";
import Bellinsurance from "./pages/bell-insurance";
import Postoffice from "./pages/postoffice";
import Ageas from "./pages/ageas";
import Allianzcarinsurance from "./pages/allianz-car-insurance";
import NFUmutual from "./pages/nfu-mutual";
import Hiscox from "./pages/hiscox";
import Legalandgeneral from "./pages/legal-and-general";
import Privilegecarinsuranceclaims from "./pages/privilege-car-insurance-accident-claim";
import Quotemehappy from "./pages/quote-me-happy";
import Marksandspencercarinsurance from "./pages/marksandspencer-car-insurance";
import Goskippy from "./pages/goskippy";
import Stcentralinsurance from "./pages/1stcentralinsurance";
import Halifax from "./pages/halifax";
import Lloydsbank from "./pages/lloydsbank";
import Bewiser from "./pages/bewiser";
import Onecallinsurance from "./pages/onecallinsurance";
import Octagoninsurance from "./pages/octagon-insurance";
import Ingenie from "./pages/ingenie";
import Marmalade from "./pages/marmalade-car-insurance";
import Fluxdirect from "./pages/flux-direct";
import Adrianflux from "./pages/adrian-flux";
import Footmanjames from "./pages/footmanjames";
import Lancasterinsurance from "./pages/lancasterinsurance";
import Chaucergroup from "./pages/chaucergroup";
import Autonetinsurance from "./pages/autonetinsurance";
import Greenflag from "./pages/greenflag";
import Coverbox from "./pages/coverbox";
import Insure2drive from "./pages/insure2drive";
import Ladybirdinsurance from "./pages/ladybirdinsurance";
import Acorninsure from "./pages/acorninsure";
import Insurancefactorycar from "./pages/insurancefactorycar";
import Performancedirect from "./pages/performancedirect";
import Surethingcar from "./pages/surethingcar";
import Skyinsurance from "./pages/sky-insurance";
import Geoffreyinsurance from "./pages/geoffreyinsurance";
import Quotelinedirect from "./pages/quotelinedirect";
import ECarinsurance from "./pages/e-car-insurance";
import Collingwood from "./pages/collingwood";
import Policywise from "./pages/policywise";
import Staveleyhead from "./pages/staveleyhead";
import Tempcover from "./pages/tempcover";
import Dayinsure from "./pages/dayinsure";
import Ikubeinsurance from "./pages/ikube-insurance";
import Drivelikeagirl from "./pages/drivelikeagirl";
import Mypolicy from "./pages/mypolicy";
import Gogirl from "./pages/gogirl";
import InsuretheBox from "./pages/insurethebox";
import Autosaint from "./pages/autosaint";
import Carrotinsurance from "./pages/carrotinsurance";
import Wiserchoice from "./pages/wiserchoice";
import Oinkinsurance from "./pages/oink-insurance";
import Thinkinsurance from "./pages/thinkinsurance";
import Veygo from "./pages/veygo";
import Bymiles from "./pages/bymiles";
import Marshmallow from "./pages/marshmallow";
import Cuvva from "./pages/cuvva";
import Zego from "./pages/zego";
import Mceinsurance from "./pages/mceinsurance";
import Sosure from "./pages/so-sure";
import APlaninsurance from "./pages/a-plan-insurance";
import Easycarclub from "./pages/easycar-club";
import Huddleinsurance from "./pages/huddleinsurance";
import Wisedriving from "./pages/wise-driving";
import Budgetinsurance from "./pages/budgetinsurance";
import Hughesinsurance from "./pages/hughesinsurance";
import Endsleigh from "./pages/endsleigh";
import RiasCarInsurance from "./pages/rias-car-insurance ";
import Peopleschoice from "./pages/peopleschoice";
import Toyotacarinsurance from "./pages/toyota-car-insurance";
import Bmwinsurance from "./pages/bmw-insurance";
import Fordinsurance from "./pages/ford-insurance";
import Mercedesbenzinsurnace from "./pages/mercedes-benz-insurnace";
import Kiacarinsurance from "./pages/kia-car-insurance";
import Nissaninsuranceservices from "./pages/nissan-insurance-services";
import Hondacarinsurance from "./pages/honda-car-insurance";
import Jaguarlandroverinsurance from "./pages/jaguar-land-rover-insurance";
import Volvocarinsurance from "./pages/volvo-car-insurance";
import Lexusinsurance from "./pages/lexus-insurance";
import Mitsubishimotorinsurance from "./pages/mitsubishi-motor-insurance";
import Firstinsurance from "./pages/first-insurance";
import Brightsideinsurance from "./pages/bright-side-insurance";
import Blog from "./pages/blog";
import Top5carinsurance from "./pages/top-5-car-insurance-claim-companies-in-the-uk";


const App = () => {
  let routes = useRoutes([
    { path: "/", element: <Home /> },
    { path: "/privilege-car-insurance-accident-claim", element: <Privilegecarinsuranceclaims /> },
    { path: "/quote-me-happy", element: <Quotemehappy /> },
    { path: "/thank-you", element: <Thankyou /> },
    { path: "/thank-you", element: <Thankyou /> },
    { path: "/nationwide", element: <Nationwide /> },
    { path: "/termsandconditions", element: <Termsandconditions /> },
    { path: "/privacy-policy", element: <Privacypolicy /> },
    { path: "/hiscox", element: <Hiscox /> },
    { path: "/esure", element: <Esure /> },
    { path: "/direct-line", element: <DirectLine /> },
    { path: "/aviva", element: <Aviva /> },
    { path: "/churchill", element: <Churchill /> },
    { path: "/axa", element: <AXA /> },
    { path: "/admiral-accident-claims", element: <Admiralaccident /> },
    { path: "/hastings-direct", element: <HastingsDirect /> },
    { path: "/lv-insurance-claims", element: <LiverpoolVictoria /> },
    { path: "/more-than", element: <MoreThan /> },
    { path: "/saga-car-insurance", element: <Sagacarinsurance /> },
    { path: "/tesco-car-insurance", element: <Tescocarinsurance /> },
    { path: "/zurich", element: <Zurich /> },
    { path: "/swinton-insurance", element: <Swintoninsurance /> },
    { path: "/co-op-insurance", element: <Coopinsurance /> },
    { path: "/rac-car-insurance", element: <RACcarinsurance /> },
    { path: "/sainsburys-bank-car-insurance", element: <Sainsburysbank /> },
    { path: "/john-lewis-finance", element: <Johnlewisfinance /> },
    { path: "/aa-car-insurance", element: <Aacarinsurance /> },
    { path: "/sheilas-wheels", element: <Sheilaswheels /> },
    { path: "/diamond", element: <Diamond /> },
    { path: "/elephant", element: <Elephant /> },
    { path: "/bell-insurance", element: <Bellinsurance /> },
    { path: "/post-office", element: <Postoffice /> },
    { path: "/ageas", element: <Ageas /> },
    { path: "/allianz-car-insurance", element: <Allianzcarinsurance /> },
    { path: "/nfu-mutual", element: <NFUmutual /> },
    { path: "/marks-and-spencer", element: <Marksandspencercarinsurance /> },
    { path: "/goskippy", element: <Goskippy /> },
    { path: "/1st-central-insurance", element: <Stcentralinsurance /> },
    { path: "/halifax", element: <Halifax /> },
    { path: "/lloyds", element: <Lloydsbank /> },
    { path: "/bewiser", element: <Bewiser /> },
    { path: "/one-call-insurance", element: <Onecallinsurance /> },
    { path: "/octagon-insurance", element: <Octagoninsurance /> },
    { path: "/ingenie", element: <Ingenie /> },
    { path: "/marmalade-car-insurance", element: <Marmalade /> },
    { path: "/flux-direct", element: <Fluxdirect /> },
    { path: "/adrian-flux", element: <Adrianflux /> },
    { path: "/footman-james", element: <Footmanjames /> },
    { path: "/lancaster-insurance", element: <Lancasterinsurance /> },
    { path: "/chaucer-direct-insurance", element: <Chaucergroup /> },
    { path: "/autonet-insurance", element: <Autonetinsurance /> },
    { path: "/green-flag", element: <Greenflag /> },
    { path: "/coverbox", element: <Coverbox /> },
    { path: "/insure2drive", element: <Insure2drive /> },
    { path: "/ladybird-insurance", element: <Ladybirdinsurance /> },
    { path: "/acorn-car-insurance", element: <Acorninsure /> },
    { path: "/insurance-factory-car", element: <Insurancefactorycar /> },
    { path: "/performance-direct", element: <Performancedirect /> },
    { path: "/sure-thing-car", element: <Surethingcar /> },
    { path: "/sky-insurance", element: <Skyinsurance /> },
    { path: "/geoffrey-insurance", element: <Geoffreyinsurance /> },
    { path: "/quoteline-direct", element: <Quotelinedirect /> },
    { path: "/ecar-insurance", element: <ECarinsurance /> },
    { path: "/collingwood", element: <Collingwood /> },
    { path: "/policywise", element: <Policywise /> },
    { path: "/staveley-head", element: <Staveleyhead /> },
    { path: "/tempcover", element: <Tempcover /> },
    { path: "/dayinsure", element: <Dayinsure /> },
    { path: "/ikube-insurance", element: <Ikubeinsurance /> },
    { path: "/drive-like-a-girl", element: <Drivelikeagirl /> },
    { path: "/insure-the-box", element: <InsuretheBox /> },
    { path: "/my-policy", element: <Mypolicy /> },
    { path: "/go-girl", element: <Gogirl /> },
    { path: "/autosaint", element: <Autosaint /> },
    { path: "/carrot-insurance", element: <Carrotinsurance /> },
    { path: "/wiserchoice", element: <Wiserchoice /> },
    { path: "/first-insurance", element: <Firstinsurance /> },
    { path: "/Oink-insurance", element: <Oinkinsurance /> },
    { path: "/think-insurance", element: <Thinkinsurance /> },
    { path: "/veygo", element: <Veygo /> },
    { path: "/bymiles", element: <Bymiles /> },
    { path: "/marshmallow", element: <Marshmallow /> },
    { path: "/cuvva", element: <Cuvva /> },
    { path: "/zego", element: <Zego /> },
    { path: "/mce-insurance", element: <Mceinsurance /> },
    { path: "/so-sure", element: <Sosure /> },
    { path: "/a-plan-insurance", element: <APlaninsurance /> },
    { path: "/easycar-club", element: <Easycarclub /> },
    { path: "/huddle-insurance", element: <Huddleinsurance /> },
    { path: "/wise-driving", element: <Wisedriving /> },
    { path: "/budget-insurance", element: <Budgetinsurance /> },
    { path: "/hughes-insurance", element: <Hughesinsurance /> },
    { path: "/endsleigh", element: <Endsleigh /> },
    { path: "/rias-car-insurance", element: <RiasCarInsurance /> },
    { path: "/peopleschoice", element: <Peopleschoice /> },
    { path: "/toyota-car-insurance", element: <Toyotacarinsurance /> },
    { path: "/bmw-insurance", element: <Bmwinsurance /> },
    { path: "/ford-insurance", element: <Fordinsurance /> },
    { path: "/mercedes-benz-insurance", element: <Mercedesbenzinsurnace /> },
    { path: "/kia-car-insurance", element: <Kiacarinsurance /> },
    { path: "/nissan-insurance-services", element: <Nissaninsuranceservices /> },
    { path: "/honda-car-insurance", element: <Hondacarinsurance /> },
    { path: "/jaguar-land-rover-insurance", element: <Jaguarlandroverinsurance /> },
    { path: "/volvo-car-insurance", element: <Volvocarinsurance /> },
    { path: "/lexus-insurance", element: <Lexusinsurance /> },
    { path: "/mitsubishi-motor-insurance", element: <Mitsubishimotorinsurance /> },
    { path: "/bright-side-insurance", element: <Brightsideinsurance /> },
    { path: "/blog", element: <Blog /> },
    { path: "/top-5-car-insurance-claim-companies-in-the-uk", element: <Top5carinsurance /> },


  ]);
  return routes;
};

const AppWrapper = () => {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 1000);
  }, []);

  return (
    <>
      {!loading ? (
        <Router>
          <ScrollToTop />
          <App />
        </Router>
      ) : (
        <Preloader />
      )}
    </>
  );
};

export default AppWrapper;
