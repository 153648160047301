import React, { useState } from "react";

const Mynewform = () => {
  const [currentStep, setCurrentStep] = useState(1);

  const handleNext = () => {
    setCurrentStep((prevStep) => prevStep + 1);
    
  };

  const handlePrevious = () => {
    setCurrentStep((prevStep) => prevStep - 1);
  };

  const calculateProgress = () => {
    return (currentStep / totalSteps) * 100;
  };

  const totalSteps = 3; // Replace with the total number of steps

  return (

    <div className="mainform">
    <h2 className="based">We're a well-established brokerage <span className="light">firm based in the UK.</span></h2>  
    <form action="action.php"
       method="post"
       encType="multipart/form-data"
        id="jobdetil">

      <fieldset style={{ display: currentStep === 1 ? "block" : "none" }}>
      <fieldset>
      <div className="form-card">
         <div className="row" id="forw" >

            <div className="col-xxl-6 col-xl-6 col-md-6 col-lg-6 col-12" id='formdiv'>
            <label >Date of Accident</label>
          <input type="date" id="date" name="accidentdate" required />
             </div>

             <div className="col-xxl-6 col-xl-6 col-md-6 col-lg-6 col-12" id='formdiv'>
             <label >Location of Accident</label>
          <input type="text" name="accidentlocation"  className="reg-input" required />
             </div>

              </div>
              <div className="row" id="forw">
              <div className="col-xxl-12 col-xl-12 col-md-12 col-lg-12 col-12" id='formdiv'>
                 <label >Vehicle Identification No.</label>
          <input type="text" name="vehiclenumber"  className="reg-input" required />
              </div>
              </div>
              </div>
            
          </fieldset>
           
        <button className="next" onClick={handleNext}>
          Next
        </button>
      </fieldset>
      <fieldset style={{ display: currentStep === 2 ? "block" : "none" }}>
      <fieldset>
            <div className="form-card">
             <div className="row" id="forw">
              <div className="col-xxl-12 col-xl-12 col-md-12 col-lg-12 col-12" id='formdiv'>
             <h4>Personal Information</h4>
             </div>
              <div className="form-card">
              <div className="row" id="forw">
              <div className="col-xxl-12 col-xl-12 col-md-12 col-lg-12 col-12" id='formdiv'>
              <input type="text" name="name"  placeholder="Name" className="reg-input" required />
              </div>
              </div>
             </div>
           <div className="form-card">
            <div className="row" id="forw">
            <div className="col-xxl-6 col-xl-6 col-md-6 col-lg-6 col-12" id='formdiv'>
             <input type="email" name="email" placeholder="Email" className="reg-input" required />
              </div>
            <div className="col-xxl-6 col-xl-6 col-md-6 col-lg-6 col-12" id='formdiv'>
             <input type="tel" name="phone"  placeholder="Phone"  className="reg-input" required />
            </div>
            </div>
            </div>
           </div>
             </div>
                            
                     </fieldset>
    
          <ul className="formlisted">
            <li>   <button className="previous" onClick={handlePrevious}>
          Previous
        </button></li>
        <li>
        <button className="next" onClick={handleNext}>
          Next
        </button></li>
          </ul>
           </fieldset>
      <fieldset style={{ display: currentStep === 3 ? "block" : "none" }}>
      <fieldset>
            <div className="form-card">
            <div className="row">
        <div className="col-xxl-12 col-xl-12 col-md-12 col-lg-12 col-12" id='formdiv'>
        <label>Choose A Date For Us to Call You</label>
          <input type="datetime-local" id="date" name="calldate" required  />
                  </div>
              </div>
            </div>
                            
          </fieldset>
          <ul className="formlisted">
          <li> <button className="previous" onClick={handlePrevious}>
          Previous
        </button></li>
          <li> <input type="submit" className='submit' value="Submit" /> </li>
           </ul>
         
       
      
      </fieldset>
      </form>
    
    </div>
  );
};

export default Mynewform;
