import React, { useRef } from "react";
import Accordion from "react-bootstrap/Accordion";
import check from "../assets/images/check.png";
import dualcar from "../../src/assets/images/dualcar.webp";
import carbnr1 from "../../src/assets/images/carbnr1.webp";
import Sidebarinner from "../components/Sidebar-inner";
import phonecall from "../assets/images/phonecall.png";
import Footer from "../components/footer";
import { Helmet } from "react-helmet";

const Skyinsurance = () => {
  const sidebarRef = useRef(null);

  const handleScrollToSidebar = () => {
    if (sidebarRef.current) {
      sidebarRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Sky Car Insurance Accident Claim Phone Number:</title>
        <meta
          name="description"
          content="Reach out to our team via the Sky Car Insurance accident claim phone number 0800 772 0850 for quick, efficient and reliable no-fault claim handling and support. Contact us today. "
        />
        <link
          rel="canonical"
          href="https://insurance-accident-claims.co.uk/sky-insurance"
        />
      </Helmet>
      <section className="innernav">
        <div className="container">
          <div className="row">
            <div className="col-md-6 col-sm-6">
              <div className="left-logo">
                <h3>
                  Accident<span className="sipo"> Claims</span>
                </h3>
              </div>
            </div>
            <div className="col-md-6 col-sm-6">
              <div className="left-cta">
                <div class="headercnt">
                  <div class="chatmsg">
                    <img
                      src={phonecall}
                      className="phonecall"
                      alt="phonecall "
                    />
                  </div>
                  <div class="box-body">
                    <h3 class="nav-info-box-title">Toll Free Number </h3>
                    <p>
                      <a href="tel:0800 772 0850">0800 772 0850</a>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section
        className="newbnr"
        style={{ backgroundImage: "url(" + carbnr1 + ")" }}
      >
        <div className="container">
          <div className="row">
            <div className="col-xxl-8 col-xl-8 col-md-10 col-12">
              <div className="innerbnr">
                <h1>
                  <span className="onespan">No-fault</span>{" "}
                  <span className="sci">car accident </span> Insurance claims number
                </h1>
                <p className="brokerage">
                  If you have ever been involved in a non-fault road accident,
                  dial up the Sky motor claims number for emergency support and
                  claims advice following the accident.
                </p>
                <div className="bnrbtn">
                  <a class="db-btn-border btn-rollover phone" href="tel:0800 772 0850">
                    Road Accident Claim Help?{" "}
                    <i class="fa-solid fa-arrow-right"></i>
                  </a>
                </div>
                <div className="bnrbtn">
                <a className="db-btn-border btn-rollover desktop"  onClick={handleScrollToSidebar}>
                    Road Accident Claim Help? <i className="fa-solid fa-arrow-right"></i>
                </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="stickysection">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="group-4-8">
                <div className="cl8 tf-tab">
                  <div className="content-tab">
                    <div className="inner-content">
                      <h2>
                        Claims assistance without
                        <br />
                        <span className="light">any excess </span>{" "}
                      </h2>
                      <p className="disciplines">
                        When it comes to handling the consequences of car
                        accidents, we know how challenging this period is. In
                        line with this, all our efforts are devoted to providing
                        comprehensive accident management solutions without any
                        upfront costs or excess payments. After a road traffic
                        crash, talk to our agents at{" "}
                        <strong>
                          {" "}
                          Sky car insurance claims contact number,{" "}
                        </strong>{" "}
                        who will instantaneously respond to your needs.
                      </p>
                      <hr />
                      <p className="bold-text">Free of charge</p>
                      <p className="bottom-te">
                        We provide accident management services that ease your
                        burden completely, with no payment required for seeking
                        assistance. You can make a{" "}
                        <strong> Sky accident claim </strong> without paying an
                        ounce.{" "}
                      </p>
                      <hr />
                      <p className="bold-text"> No loss of NCB </p>
                      <p className="bottom-te">
                        By selecting us as your preferred company for making{" "}
                        <strong> Sky car claims, </strong>not only do you
                        benefit from extensive accident management solutions,
                        but also secure your no-claims bonus. Our guarantee is
                        that there will be no damage inflicted on your
                        accumulated no-claims discount and its value during any
                        given period of time.
                      </p>
                      <hr />
                      <p className="bold-text">Suitable courtesy car</p>
                      <p className="bottom-te">
                        We will provide you with an appropriate substitute
                        vehicle according to your preferences and needs
                        delivered to your doorstep. We will make sure that you
                        continue moving while your car is being repaired.
                      </p>
                      <hr />
                      <p className="bold-text">Friendly and supportive team </p>
                      <p className="bottom-te">
                        The{" "}
                        <strong>
                          motor claims contact number{" "}
                          <a href="tel:0800 772 0850">0800 772 0850</a>
                        </strong>
                        is available for you to call in case of a road accident,
                        where our friendly supportive team will take away the
                        worry from you making it as stress-free as possible.{" "}
                      </p>
                      <hr />
                      <p className="bold-text">24/7 assistance</p>
                      <p className="bottom-te">
                        Our team works speedily day and night to have you back
                        on the road within the shortest time possible after a
                        vehicle accident.
                      </p>
                      <hr />
                      <p className="bold-text">Full nationwide coverage</p>
                      <p className="bottom-te">
                        When one of those incidents happens, all it takes to get
                        immediate roadside support by contacting us at{" "}
                        <strong>
                          {" "}
                          Sky car insurance claims contact number{" "}
                        </strong>
                        so that we see to it that no one is left stranded.
                        Contact us at the{" "}
                        <strong>
                          {" "}
                          Sky car insurance claims contact number,{" "}
                        </strong>{" "}
                        and we will make sure that you’re never left stranded.
                      </p>
                      <hr />
                      <p className="bold-text"> Continuous claims tracking </p>
                      <p className="bottom-te">
                        We would like to let you know that once we initiate your{" "}
                        <strong> Sky accident claim, </strong> we are going to
                        follow up with your{" "}
                        <strong> Sky auto insurance claim </strong>updating you
                        regularly.
                      </p>
                      <hr />
                      <p className="bold-text">Expert claims advice</p>
                      <p className="bottom-te">
                        Our group of solicitors have vast experience in handling{" "}
                        <strong> Sky car claims. </strong>
                        When you call us at the{" "}
                        <strong> Sky motor claims contact number </strong>to
                        initiate your claim, our solicitors will provide the
                        best guidance to help you secure the best outcome.
                      </p>
                      <hr />
                      <p className="bold-text">Expedited Claim Settlement </p>
                      <p className="bottom-te">
                        We aim to settle your Sky accident claim in minimum time
                        without complex paperwork or lengthy follow-ups.
                      </p>
                      <hr />

                      <p className="bold-text">Quality repairs guaranteed</p>
                      <p className="bottom-te">
                        We see to it that the image of your car in future is
                        protected by making arrangements for its repair with a
                        trusted repairer using original parts and materials of
                        high quality.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="cl4" ref={sidebarRef}>
                  <Sidebarinner />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="photographs">
        <div className="container">
          <div className="row">
            <h2 className="road">
              From beginning to end, we’ll handle all{" "}
              <span className="light">
                your <br />
                accident management needs
              </span>{" "}
            </h2>
            <div className="col-xxl-6 col-xl-6 col-md-12 col-12">
              <div className="fourbox">
                <ul>
                  <li>
                    <img src={check} className="check" alt="" />
                    If you’re unfortunate to have been involved in a no-fault
                    car accident, contact us at the{" "}
                    <strong> Sky car insurance accident helpline </strong> to
                    make a successful claim.
                  </li>
                  <hr />
                  <li>
                    <img src={check} className="check" alt="" />
                    We will relieve you of any anxiety, problems and
                    complications that may arise when making a{" "}
                    <strong> Sky auto insurance claim </strong>through our
                    specialist advice and continuous support.
                  </li>
                  <hr />
                  <li>
                    <img src={check} className="check" alt="" />
                    From dealing with the insurers to keeping you mobile by
                    providing a replacement car, we will manage everything for
                    you.
                  </li>
                  <hr />
                  <li>
                    <img src={check} className="check" alt="" />
                    We will arrange for an approved repairer recommended by top
                    vehicle manufacturers who are reliable enough to bring about
                    the highest quality repairs for your damaged vehicle.
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-xxl-6 col-xl-6 col-md-12 col-12">
              <div className="fourbox">
                <ul>
                  <li>
                    <img src={check} className="check" alt="" />
                    In case you need to get a replacement car of the same type
                    during your claims, contact us through our car insurance
                    claims contact number,{" "}
                    <strong>
                      {" "}
                      <a href="tel:0800 772 0850">0800 772 0850</a>
                    </strong>
                  </li>
                  <hr />
                  <li>
                    <img src={check} className="check" alt="" />
                    We will assign a dedicated claim handler to advise and lead
                    you in the claim process.
                  </li>
                  <hr />
                  <li>
                    <img src={check} className="check" alt="" />
                    We shall be in touch with your repairer and update you on
                    how your vehicle is progressing through its repair stages.
                  </li>
                  <hr />
                  <li>
                    <img src={check} className="check" alt="" />
                    After the repairs are done on your vehicle, we will arrange
                    its delivery to your doorstep.
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="carprocess">
        <div className="container ">
          <div className="row">
            <div className="col-xxl-12 col-xxl-12 col-md-12 col-12">
              <h2 className="trusted-title">
                {" "}
                Our extensive range of accident <br />
                <span className="light">management services</span>{" "}
              </h2>
            </div>
          </div>
          <div className="row" id="possible">
            <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-12">
              <div className="claim">
                <ul>
                  <li>
                    <span className="sleej">Vehicle recovery:</span>Are you
                    struggling to find someone to recover your vehicle after a
                    non-fault accident? Call us immediately at{" "}
                    <strong> Sky car insurance claims contact number </strong>{" "}
                    for instant help. We will send our rescue team quickly to
                    ensure prompt recovery of your vehicle within no time.
                  </li>
                  <li>
                    <span className="sleej">Approved repairs:</span>Why go
                    through the stress of arranging your car’s repairs when all
                    you need is just one call? Make use of{" "}
                    <strong> the Sky car insurance accident helpline </strong>to
                    arrange high-quality repairs of your vehicle with genuine
                    parts and paint.
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-12">
              <div className="claim" id="blue">
                <ul>
                  <li>
                    <span className="sleej">Vehicle storage:</span> We will
                    store your damaged vehicle at our secure storage yards in
                    case it is no longer drivable. It is possible to safely keep
                    your broken car in our storage yards until more examination
                    is carried out, thus preventing further damage and decay.
                  </li>
                  <li>
                    <span className="sleej">Replacement car :</span>We are aware
                    that not having a vehicle can be an inconvenience and cause
                    stress. Our accident management services include providing
                    you with another car which would suit your everyday life so
                    that you can return to the road without any risk. The
                    replacement vehicle costs will be recovered from the
                    responsible party’s insurer.
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-12">
              <div className="claim">
                <ul>
                  <li>
                    <span className="sleej">Personal injury assistance:</span>
                    Do you have any injuries from a non-fault car accident?
                    Please contact us at
                    <strong> Sky motor claims contact number </strong>to have a
                    victorious personal injury claim to get fair compensation
                    for your injuries.
                  </li>
                  <li>
                    <span className="sleej">Claims management:</span>Our
                    experienced solicitors handle all{" "}
                    <strong> Sky accident claims </strong> with professionalism,
                    saving you from all the hassles and stress.
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="yellowcta">
        <div className="container">
          <div className="row">
            <div className="cta2">
              <div className="col-xxl-8 col-xl-8 col-lg-8 col-12">
                <div className="legal">
                  <h3 className="experienced">
                    Got more questions?{" "}
                    <span className="light">Talk to our specialists </span>{" "}
                  </h3>
                  <p className="professionals">
                    Get in touch with our no-fault claim experts via{" "}
                    <strong>
                      car insurance claims contact number{" "}
                      <a className="cpt-no" href="tel:0800 772 0850">
                        {" "}
                        0800 772 0850{" "}
                      </a>{" "}
                    </strong>
                    to get the best advice to pursue a successful{" "}
                    <strong> Sky accident claim. </strong>
                  </p>
                </div>
              </div>
              <div className="col-xxl-4 col-xl-4 col-lg-4 col-12">
              <div class="process">
                  <a class="tf-button style-1 phone" href="tel:0800 772 0850">
                    Get in touch<span class="icon-keyboard_arrow_right"></span>
                  </a>
                </div>
                <div class="process">
                    <a className="tf-button style-1 desktop"  onClick={handleScrollToSidebar}>
                        Get in touch<span className="icon-keyboard_arrow_right"></span>
                    </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <search className="updatedform">
        <div className="container">
          <div className="row text-center">
            <div className="col-xxl-12 col-xl-12 col-md-12 col-12">
              <h2 className="whiteheading">
                {" "}
                Advantages of Choosing Us for Accident <br />
                <span className="light">Management Assistance </span>
              </h2>
              <p className="sustained">
                As a leading provider of accident management assistance, we take
                away a lot of the complexity, stress and hassle out of your
                hands by providing a wide range of services. We’ve got you
                covered, from vehicle recovery to claims management.
              </p>
            </div>
          </div>
          <div className="row">
            <div className="col-xxl-4 col-xl-4 col-lg-6 col-md-12 col-12">
              <div className="Claimant">
                <h5> 24/7 support </h5>
                <p className="collision">
                  Call us any time to arrange your vehicle repairs, get a
                  replacement car or receive guidance on{" "}
                  <strong> Sky auto insurance claims. </strong>
                </p>

                <h5> No excess </h5>
                <p className="collision">
                  With our accident management service, you never have to pay
                  additional fees or upfront charges.
                </p>

                <h5> Reliable partnerships </h5>
                <p className="collision">
                  We have established networks with trusted legal practitioners
                  and well-known repairers who can provide premium services.
                </p>
              </div>
            </div>
            <div className="col-xxl-4 col-xl-4 col-lg-6 col-md-12 col-12">
              <div className="Claimant">
                <h5> Hassle-free car replacement </h5>
                <p className="collision">
                  While your damaged car is being repaired, we will quickly
                  organise a substitute vehicle that matches your specifications
                  so that you remain mobile at all times.
                </p>
                <h5> Comprehensive repair network </h5>
                <p className="collision">
                  Our UK-wide network consists of highly trained repair
                  providers who deliver an exceptional standard of workmanship
                  that has been guaranteed beyond satisfying expectations.
                </p>

                <h5> Experienced team of solicitors </h5>
                <p className="collision">
                  We have a strong network of experienced solicitors, who can
                  handle <strong> Sky auto insurance claims </strong> with the
                  greatest efficiency possible.
                </p>
              </div>
            </div>
            <div className="col-xxl-4 col-xl-4 col-lg-12 col-md-12 col-12">
              <div className="Claimant">
                <h5> Total assistance </h5>
                <p className="collision">
                  From the assessment of claims to settlement, we will be with
                  you every step of the way.
                </p>

                <h5> Manufacturer-approved repairs </h5>
                <p className="collision">
                  Our repair partners are reputed for providing quality work and
                  high-quality repairs using only genuine parts.
                </p>

                <h5>Quick Claim Settlement</h5>
                <p className="collision">
                  With our efficient claim management process, we are able to
                  file your claim in time and maximise your chances of obtaining
                  a higher compensation.
                </p>
              </div>
            </div>
          </div>
        </div>
      </search>

      <section className="Accident-claim">
        <div className="container">
          <div className="row">
            <div className="needed">
              <h2 className="trusted-title">
                How can you maximise Sky accident <br />
                <span className="light"> claim compensation?</span>
              </h2>
              <p className="complexities">
                A no-fault road accident can be a stressful experience, and
                proving your innocence can be even more difficult. Hence, it is
                important that you know what steps should be taken in order to
                prove your innocence and increase your chances of getting fair
                compensation.{" "}
              </p>
            </div>
            <hr />
          </div>
          <div className="row" id="incdes">
            <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-12">
              <div className="Preparing">
                <ul>
                  <li>
                    <div className="seemingly">
                      <h5 className="Seek">Call emergency services:</h5>
                      <p>
                        {" "}
                        Try getting as much information about an accident as
                        possible and give us a call at Sky motor claims contact
                        number to arrange recovery for your damaged vehicle.
                      </p>
                    </div>
                  </li>
                  <li>
                    <div className="seemingly">
                      <h5 className="Seek">Report the accident: </h5>
                      <p>
                        Contact the police so that they can register a report
                        that could be crucial evidence to strengthen your case.
                      </p>
                    </div>
                  </li>
                  <li>
                    <div className="seemingly">
                      <h5 className="Seek">Examine injuries:</h5>
                      <p>
                        After the accident, your well-being should be your
                        priority. Look at yourself and any of the people you
                        were travelling with for any serious or minor injuries.
                      </p>
                    </div>
                  </li>
                  <li>
                    <div className="seemingly">
                      <h5 className="Seek">Seek medical attention</h5>
                      <p>
                        If it is a serious injury that requires immediate
                        attention, go to the hospital for appropriate treatment.
                      </p>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
            <div
              className="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-12"
              id="dualcardiv"
            >
              <img src={dualcar} alt="" className="dualcar" />
            </div>
            <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-12">
              <div className="Preparing">
                <ul>
                  <li>
                    <div className="seemingly">
                      <h5 className="Seek">Arrange repairs:</h5>
                      <p>
                        In case you have your own repairer, we will get in touch
                        with them to obtain estimates for repairs and schedule a
                        suitable date of service. If you don’t have one in mind,
                        we can suggest some names from our network.
                      </p>
                    </div>
                  </li>
                  <li>
                    <div className="seemingly">
                      <h5 className="Seek"> Gather information </h5>
                      <p>
                        Where possible, take evidence and other vital details
                        from the scene, such as names and addresses of all
                        drivers involved or witness statements etc., which may
                        support your claim.
                      </p>
                    </div>
                  </li>
                  <li>
                    <div className="seemingly">
                      <h5 className="Seek">Note down damages:</h5>
                      <p>
                        Before your vehicle is sent for repairs, take pictures
                        and videos of car damage. These images will prove how
                        much damage has been done to your car.{" "}
                      </p>
                    </div>
                  </li>
                  <li>
                    <div className="seemingly">
                      <h5 className="Seek">Contact an experienced solicitor</h5>
                      <p>
                        To make a successful claim for damages and injuries,
                        hire a skilled solicitor, who can help you throughout
                        the process. Call us on{" "}
                        <strong> Sky motor claims contact number </strong> and
                        speak with one of our expert solicitors.
                      </p>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="whyus">
        <div className="container">
          <div className="row">
            <div className="col-xxl-12 col-md-12">
              <div className="why">
                <h2 className="choose">
                  Fast and stress-free car insurance{" "}
                  <span className="light">claims process </span>
                </h2>
                <p className="complexities">
                  Want to make a Sky accident claim? The quickest way of making
                  a claim is to contact us on the{" "}
                  <strong> Sky car insurance claims contact number </strong>so
                  that we can assist you in seeking compensation for injuries
                  and property damage sustained due to the car crash.
                </p>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-xxl-3 col-xl-6 col-lg-6 col-md-6 col-12">
              <div className="box-service">
                <img src={check} className="check" alt="" />
                {/* <img src={experience} className="experience" alt="" /> */}
                <h4>Contact our team</h4>
                <p>
                  To get advice on your claim, call our car insurance accident
                  helpline at <a href="tel:0800 772 0850">0800 772 0850 </a>
                </p>
              </div>
            </div>
            <div className="col-xxl-3 col-xl-6 col-lg-6 col-md-6 col-12">
              <div className="box-service">
                <img src={check} className="check" alt="" />
                <h4>Provide details</h4>
                <p>
                  Share important information about your accident, such as date,
                  time, place and how it happened.
                </p>
              </div>
            </div>
            <div className="col-xxl-3 col-xl-6 col-lg-6 col-md-6 col-12">
              <div className="box-service">
                <img src={check} className="check" alt="" />
                <h4>Share evidence</h4>
                <p>
                  Include all crucial pieces of evidence, such as pictures from
                  the scene of the accident, eyewitness accounts and videos from
                  CCTVs, if any.{" "}
                </p>
              </div>
            </div>
            <div className="col-xxl-3 col-xl-6 col-lg-6 col-md-6 col-12">
              <div className="box-service">
                <img src={check} className="check" alt="" />
                <h4>Claim review and resolution</h4>
                <p>
                  Our team will go through your claim carefully and try to
                  resolve it within a very short duration of time.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="faqs">
        <div className="container">
          <div className="row">
            <div className="col-xxl-12 col-xl-12 col-md-12 col-12">
              <div className="fq">
                <h2>
                  {" "}
                  Frequently Asked{" "}
                  <span className="light">Questions (FAQ)</span>
                </h2>
                <p className="complexities">
                  Got questions about <strong> Sky car claims? </strong>We have
                  them answered here.
                </p>
              </div>
              <div className="allfaq">
                <Accordion defaultActiveKey="0">
                  <Accordion.Item eventKey="0">
                    <Accordion.Header>
                      Should I file a claim with my own insurance company?
                    </Accordion.Header>
                    <Accordion.Body>
                      No. To avoid paying any excess on your policy and to
                      safeguard your no-claims bonus, avoid making a claim with
                      your insurer. Instead, contact us on{" "}
                      <strong>
                        {" "}
                        the Sky car insurance claims contact number{" "}
                      </strong>
                      to get guidance on the next steps.
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="1">
                    <Accordion.Header>
                      What kind of vehicle will be given to me as a replacement?
                    </Accordion.Header>
                    <Accordion.Body>
                      We are going to coordinate a similar replacement vehicle
                      in terms of the size and quality as your own. It would be
                      best if you inform us about your requirements or
                      preferences by calling the{" "}
                      <strong> Sky car insurance accident helpline </strong> so
                      that we can give you an appropriate alternative vehicle.
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="2">
                    <Accordion.Header>
                      Who covers the costs of your accident management services?
                    </Accordion.Header>
                    <Accordion.Body>
                      All repair costs, replacement vehicles, or other expenses
                      incurred shall henceforth be borne by the insurer of the
                      party responsible for the accident.
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="3">
                    <Accordion.Header>
                      Can I take my car to any garage for repair?{" "}
                    </Accordion.Header>
                    <Accordion.Body>
                      Yes, just provide us with details about your preferred
                      garage, and we will liaise with them to ensure proper
                      repairs.
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="4">
                    <Accordion.Header>
                      Will I lose my no-claims bonus if I make a claim?
                    </Accordion.Header>
                    <Accordion.Body>
                      No. When you make a <strong> Sky accident claim </strong>{" "}
                      with us, we will ensure that your no-claims bonus stays
                      intact.
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="5">
                    <Accordion.Header>
                      What should I do if the third-party insurers contact me
                      directly?{" "}
                    </Accordion.Header>
                    <Accordion.Body>
                      If the third-party insurers contact you directly, you can
                      refer them to us if you choose to make your claim with us.
                      We will deal with them and handle all the communications
                      on your behalf.
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="6">
                    <Accordion.Header>
                      What if my car is not repairable?{" "}
                    </Accordion.Header>
                    <Accordion.Body>
                      We will certainly give you a car in place of one that has
                      been written off and negotiate with the responsible party
                      so that they can provide you with appropriate
                      compensation.
                    </Accordion.Body>
                  </Accordion.Item>
                </Accordion>
              </div>
            </div>
          </div>
        </div>
      </section>

      <Footer />
    </>
  );
};

export default Skyinsurance;
