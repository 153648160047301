import React, { useRef } from "react";
import Accordion from "react-bootstrap/Accordion";
import check from "../assets/images/check.png";
import dualcar from "../../src/assets/images/dualcar.webp";
import carbnr1 from "../../src/assets/images/carbnr1.webp";
import Sidebarinner from "../components/Sidebar-inner";
import phonecall from "../assets/images/phonecall.png";
import Footer from "../components/footer";
import { Helmet } from "react-helmet";

const Marksandspencercarinsurance = () => {
  const sidebarRef = useRef(null);

  const handleScrollToSidebar = () => {
    if (sidebarRef.current) {
      sidebarRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>M&S Car Insurance Accident Claim Phone Number</title>
        <meta
          name="description"
          content="Our expert solicitors are always on hand to offer no-obligation advice for your claim. Call us on the M&S Car Insurance Accident Claim Phone Number, 0800 772 0850. "
        />
        <link
          rel="canonical"
          href="https://insurance-accident-claims.co.uk/marks-and-spencer-car-insurance"
        />
      </Helmet>
      <section className="innernav">
        <div className="container">
          <div className="row">
            <div className="col-md-6 col-sm-6">
              <div className="left-logo">
                <h3>
                  Accident<span className="sipo"> Claims</span>
                </h3>
              </div>
            </div>
            <div className="col-md-6 col-sm-6">
              <div className="left-cta">
                <div class="headercnt">
                  <div class="chatmsg">
                    <img
                      src={phonecall}
                      className="phonecall"
                      alt="phonecall "
                    />
                  </div>
                  <div class="box-body">
                    <h3 class="nav-info-box-title">Toll Free Number </h3>
                    <p>
                      <a href="tel:0800 772 0850">0800 772 0850</a>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section
        className="newbnr"
        style={{ backgroundImage: "url(" + carbnr1 + ")" }}
      >
        <div className="container">
          <div className="row">
            <div className="col-xxl-8 col-xl-8 col-md-10 col-12">
              <div className="innerbnr">
                <h1>
                  <span className="onespan">Expert accident </span>{" "}
                  <span className="sci"> insurance claims </span>in the UK
                </h1>
                <p className="brokerage">
                  Let us take care
                  <span className="yellow"> of your accident management </span>
                  needs and claim aspects while you focus on your recovery.{" "}
                </p>
                <div className="bnrbtn">
                  <a class="db-btn-border btn-rollover phone" href="tel:0800 772 0850">
                    Road Accident Claim Help?{" "}
                    <i class="fa-solid fa-arrow-right"></i>
                  </a>
                </div>
                <div className="bnrbtn">
                <a className="db-btn-border btn-rollover desktop"  onClick={handleScrollToSidebar}>
                    Road Accident Claim Help? <i className="fa-solid fa-arrow-right"></i>
                </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="stickysection">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="group-4-8">
                <div className="cl8 tf-tab">
                  <div className="content-tab">
                    <div className="inner-content">
                      <h2>
                        Dedicated claims assistance <br />
                        <span className="light">
                          to minimise your hassles{" "}
                        </span>{" "}
                      </h2>
                      <p className="disciplines">
                        Car accidents happen unexpectedly, and when they do,
                        we’re here to support you throughout the{" "}
                        <strong> Marks and Spencer car insurance claim </strong>
                        process by leveraging our vast experience and expertise
                        to ensure your peace of mind. Call us on the{" "}
                        <strong> M&S car insurance phone number </strong> to get
                        back on the road and start your{" "}
                        <strong> M&S car insurance claims </strong>process with
                        us.
                      </p>
                      <hr />
                      <p className="bold-text">No insurance excess </p>
                      <p className="bottom-te">
                        If you’re not at fault, you don’t have to stress about
                        paying any policy excess, as we will initiate a claim
                        directly against the other party’s insurer.
                      </p>
                      <hr />
                      <p className="bold-text">No effect on no-claims bonus</p>
                      <p className="bottom-te">
                        Since we won’t make an{" "}
                        <strong> M&S car accident claim </strong> against your
                        own insurance policy, your insurance premiums won’t
                        increase, and you won’t lose your no-claims bonus.{" "}
                      </p>
                      <hr />
                      <p className="bold-text">Same-day replacement vehicle </p>
                      <p className="bottom-te">
                        In case your vehicle is in an undrivable state, we will
                        provide you with a same-day like-for-like replacement
                        vehicle that will be delivered either to the accident
                        location or to your doorstep.
                      </p>
                      <hr />
                      <p className="bold-text">Total loss claims </p>
                      <p className="bottom-te">
                        If your vehicle is declared a total loss, our solicitors
                        will negotiate with the other party for maximum
                        compensation amounting to the market value of your
                        vehicle.
                      </p>
                      <hr />
                      <p className="bold-text">High-quality repairers</p>
                      <p className="bottom-te">
                        We have a managed network of top-notch repairers in the
                        UK, specialising in providing quality and guaranteed
                        repairs to your vehicle.
                      </p>
                      <hr />
                      <p className="bold-text">Third-party claims handling</p>
                      <p className="bottom-te">
                        From initial claim intake to final settlement of your{" "}
                        <strong> Marks and Spencer car insurance claim </strong>
                        , we will handle each and every step of the process.
                      </p>
                      <hr />
                      <p className="bold-text">
                        24/7 roadside recovery assistance{" "}
                      </p>
                      <p className="bottom-te">
                        Get in touch with us via the{" "}
                        <strong> M&S car insurance claims number </strong> to
                        receive immediate roadside recovery assistance without
                        any delay.{" "}
                      </p>
                      <hr />
                      <p className="bold-text"> UK-based claims handlers </p>
                      <p className="bottom-te">
                        Our dedicated claims handlers go over and beyond to
                        guide you throughout the{" "}
                        <strong> M&S car insurance claims </strong> process.
                      </p>
                      <hr />
                      <p className="bold-text">Friendly & supportive team </p>
                      <p className="bottom-te">
                        We have a professional and friendly team that works
                        tirelessly to ensure our customers get timely assistance
                        and support when they need it.
                      </p>
                      <hr />

                      <p className="bold-text"> Quick claims settlement </p>
                      <p className="bottom-te">
                        With our straightforward process, we make sure that your{" "}
                        <strong> M&S car accident claims </strong> get settled
                        quickly and that you get deserving compensation without
                        long waiting periods.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="cl4" ref={sidebarRef}>
                  <Sidebarinner />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="photographs">
        <div className="container">
          <div className="row">
            <h2 className="road">
              We’re dedicated to making your{" "}
              <span className="light">
                {" "}
                post-accident <br /> experience stress-free
              </span>{" "}
            </h2>
            <div className="col-xxl-6 col-xl-6 col-md-12 col-12">
              <div className="fourbox">
                <ul>
                  <li>
                    <img src={check} className="check" alt="" />
                    If you’ve been a victim of a no-fault car accident, we can
                    help you get back on the road.
                  </li>
                  <hr />
                  <li>
                    <img src={check} className="check" alt="" />
                    We will get you mobile by providing a same-day like-for-like
                    replacement vehicle.
                  </li>
                  <hr />
                  <li>
                    <img src={check} className="check" alt="" />
                    We will take the stress out of dealing with an accident by
                    liaising with all the parties involved in the car accident
                    on your behalf.
                  </li>
                  <hr />
                  <li>
                    <img src={check} className="check" alt="" />
                    We will arrange a quick recovery of your vehicle through our
                    trusted recovery network.
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-xxl-6 col-xl-6 col-md-12 col-12">
              <div className="fourbox">
                <ul>
                  <li>
                    <img src={check} className="check" alt="" />
                    When you call us on the{" "}
                    <strong> M&S car insurance phone number </strong>, we will
                    organise quality repairs of your damaged vehicle from our
                    trusted repair network.
                  </li>
                  <hr />
                  <li>
                    <img src={check} className="check" alt="" />
                    We will deal directly with the at-fault party’s insurer and
                    negotiate with them for a fair settlement.
                  </li>
                  <hr />
                  <li>
                    <img src={check} className="check" alt="" />
                    Our <strong> M&S car accident claims </strong> solicitors
                    will help you recover uninsured losses such as policy excess
                    or loss of earnings from the other party.
                  </li>
                  <hr />
                  <li>
                    <img src={check} className="check" alt="" />
                    Our panel of experienced solicitors in the UK will guide you
                    at every step of the{" "}
                    <strong>
                      {" "}
                      Marks and Spencer car insurance claim{" "}
                    </strong>{" "}
                    process and provide professional advice if required.
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="carprocess">
        <div className="container ">
          <div className="row">
            <div className="col-xxl-12 col-xxl-12 col-md-12 col-12">
              <h2 className="trusted-title">
                {" "}
                Accident management services <br />
                <span className="light">we offer</span>{" "}
              </h2>
            </div>
          </div>
          <div className="row" id="possible">
            <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-12">
              <div className="claim">
                <ul>
                  <li>
                    <span className="sleej">Vehicle Recovery:</span> Need
                    emergency roadside recovery for your vehicle? Call us on the{" "}
                    <strong>M&S car insurance claims number. </strong>We will
                    quickly arrange the recovery of your vehicle from the
                    accident scene to the repair centre or our storage
                    facilities.
                  </li>
                  <li>
                    <span className="sleej">Approved repairs:</span>We have a
                    wide network of trusted and manufacturer-approved repairers
                    who provide cost-effective and high-quality repairs. Contact
                    us on the <strong> M&S car insurance phone number </strong>{" "}
                    to schedule your vehicle repairs from our repair network.
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-12">
              <div className="claim" id="blue">
                <ul>
                  <li>
                    <span className="sleej">Safe storage:</span> Worried about
                    the safety of your vehicle after the car accident? We have
                    you covered. Until your vehicle is assessed for repairs or
                    your claim gets settled, we will store your vehicle in our
                    secured facilities to prevent further damage. Reach out to
                    our team via the{" "}
                    <span> M&S car insurance claims number </span> for a safe
                    storage solution for your vehicle.
                  </li>
                  <li>
                    <span className="sleej">Replacement vehicle: </span> If the
                    accident wasn’t your fault, we will arrange a like-for-like
                    replacement vehicle to help you hit the road again and carry
                    on your daily routine without disruption. Get in touch with
                    us via{" "}
                    <strong>
                      {" "}
                      car insurance phone number{" "}
                      <a href="tel:0800 772 0850">0800 772 0850</a>{" "}
                    </strong>{" "}
                    to get a same-day comparable replacement vehicle delivered
                    to your doorstep.
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-12">
              <div className="claim">
                <ul>
                  <li>
                    <span className="sleej">Injury claims:</span>
                    Car accidents can leave you with long-lasting injuries.
                    Therefore, it’s important to get the best advice and
                    assistance to receive rightful compensation. Our solicitors
                    will provide the best legal advice and support to claim
                    compensation for injuries.
                  </li>
                  <li>
                    <span className="sleej">Claims management:</span> It can be
                    challenging to handle your claim on your own. We can help
                    you deal with the complexities of your{" "}
                    <strong> M&S car insurance claims </strong> with our expert
                    guidance and professional claims management from start to
                    finish.
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="yellowcta">
        <div className="container">
          <div className="row">
            <div className="cta2">
              <div className="col-xxl-8 col-xl-8 col-lg-8 col-12">
                <div className="legal">
                  <h3 className="experienced">
                    {" "}
                    Got more questions?{" "}
                    <span className="light">
                      {" "}
                      Talk to our specialists{" "}
                    </span>{" "}
                  </h3>
                  <p className="professionals">
                    Get in touch with our no-fault claim experts via{" "}
                    <strong>
                      car insurance phone number{" "}
                      <a className="cpt-no" href="tel:0800 772 0850">
                        {" "}
                        0800 772 0850{" "}
                      </a>{" "}
                    </strong>{" "}
                    to get the best advice regarding the{" "}
                    <strong> M&S car insurance claims.</strong>
                  </p>
                </div>
              </div>
              <div className="col-xxl-4 col-xl-4 col-lg-4 col-12">
              <div class="process">
                  <a class="tf-button style-1 phone" href="tel:0800 772 0850">
                    Get in touch<span class="icon-keyboard_arrow_right"></span>
                  </a>
                </div>
                <div class="process">
                    <a className="tf-button style-1 desktop"  onClick={handleScrollToSidebar}>
                        Get in touch<span className="icon-keyboard_arrow_right"></span>
                    </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <search className="updatedform">
        <div className="container">
          <div className="row text-center">
            <div className="col-xxl-12 col-xl-12 col-md-12 col-12">
              <h2 className="whiteheading">
                {" "}
                Why do we stand <span className="light">out from others? </span>
              </h2>
              <p className="sustained">
                We specialise in getting people back on the road after a car
                accident in the UK. With our wide range of accident management
                solutions, we aim to minimise the stress and hassle involved in
                managing <strong> M&S car accident claims </strong> and provide
                you with the highest level of service you’re entitled to
                receive.{" "}
              </p>
            </div>
          </div>
          <div className="row">
            <div className="col-xxl-4 col-xl-4 col-lg-6 col-md-12 col-12">
              <div className="Claimant">
                <h5> Guaranteed professional repairs </h5>
                <p className="collision">
                  Our nationwide repair network in the UK specialises in
                  providing high-quality and guaranteed repairs using
                  manufacturer-approved parts.
                </p>

                <h5> Uninsured loss recovery </h5>
                <p className="collision">
                  Our solicitors will help you recover all the losses from the
                  other party, including loss of earnings and policy excess.{" "}
                </p>

                <h5>Comparable replacement vehicle</h5>
                <p className="collision">
                  We will provide you with a comparable replacement vehicle of a
                  similar style, size and overall quality to your own vehicle.
                </p>
              </div>
            </div>
            <div className="col-xxl-4 col-xl-4 col-lg-6 col-md-12 col-12">
              <div className="Claimant">
                <h5>Legal assistance </h5>
                <p className="collision">
                  Our solicitors will provide dedicated legal assistance for
                  your <strong> M&S car insurance claims </strong>to ensure the
                  best outcome.
                </p>

                <h5>No win-no fee basis </h5>
                <p className="collision">
                  Our solicitors work on a no-win no-fee basis. You’ll only pay
                  if your <strong> M&S car accident claim </strong> is
                  successful.
                </p>

                <h5> Start to finish claims management </h5>
                <p className="collision">
                  From vehicle recovery to claim initiation and settlement, we
                  will handle each and every aspect of your{" "}
                  <strong>Marks and Spencer car insurance claim. </strong>{" "}
                </p>
              </div>
            </div>
            <div className="col-xxl-4 col-xl-4 col-lg-12 col-md-12 col-12">
              <div className="Claimant">
                <h5> Quick accident reporting </h5>
                <p className="collision">
                  With us, you don’t have to go through lengthy procedures to
                  report the accident. Call us on the{" "}
                  <strong> M&S car insurance phone number </strong>, and we’ll
                  immediately dispatch emergency help.
                </p>

                <h5> Expert negotiation </h5>
                <p className="collision">
                  If your vehicle is beyond repair, we will negotiate with the
                  other party’s insurer on your behalf.
                </p>

                <h5> Speedy vehicle recovery </h5>
                <p className="collision">
                  Get in touch with us on the{" "}
                  <strong> M&S car insurance claims number </strong> for
                  immediate recovery of your damaged vehicle.
                </p>
              </div>
            </div>
          </div>
        </div>
      </search>

      <section className="Accident-claim">
        <div className="container">
          <div className="row">
            <div className="needed">
              <h2 className="trusted-title">
                How can you increase the settlement <br />
                <span className="light">
                  {" "}
                  value of your M&S car insurance claim?
                </span>
              </h2>
              <p className="complexities">
                Being involved in a no-fault car accident could result in
                thousands of pounds in expenses. However, being aware of the
                right steps to follow after a no-fault car accident can increase
                the amount of compensation you’ll receive from the at-fault
                party’s insurer. Here are a few things you must follow after a
                no-fault car accident to maximise your claim value.{" "}
              </p>
            </div>
            <hr />
          </div>
          <div className="row" id="incdes">
            <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-12">
              <div className="Preparing">
                <ul>
                  <li>
                    <div className="seemingly">
                      <h5 className="Seek">Get emergency assistance :</h5>
                      <p>
                        {" "}
                        Contact us on the{" "}
                        <strong> M&S car insurance claims number </strong> to
                        receive emergency roadside assistance for your vehicle
                        recovery from the accident scene.{" "}
                      </p>
                    </div>
                  </li>
                  <li>
                    <div className="seemingly">
                      <h5 className="Seek"> Seek medical care : </h5>
                      <p>
                        One of the best ways to increase your compensation
                        amount is to immediately seek medical attention from a
                        qualified professional to get your injuries treated.{" "}
                      </p>
                    </div>
                  </li>
                  <li>
                    <div className="seemingly">
                      <h5 className="Seek">Take photographs:</h5>
                      <p>
                        Collect as much evidence as possible from the accident
                        scene, including photographs, videos and CCTV videos.
                        The more evidence you have, the stronger your case will
                        be.
                      </p>
                    </div>
                  </li>
                  <li>
                    <div className="seemingly">
                      <h5 className="Seek">Calculate all damages</h5>
                      <p>
                        Record all the damages you’ve suffered as a result of a
                        car accident to propose a fair settlement that
                        compensates you for your injuries.
                      </p>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
            <div
              className="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-12"
              id="dualcardiv"
            >
              <img src={dualcar} alt="" className="dualcar" />
            </div>
            <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-12">
              <div className="Preparing">
                <ul>
                  <li>
                    <div className="seemingly">
                      <h5 className="Seek">Don’t settle too soon :</h5>
                      <p>
                        The insurance companies may offer an early settlement to
                        the victims of no-fault accidents. Never accept the
                        first settlement offer as it may not provide fair
                        compensation.{" "}
                      </p>
                    </div>
                  </li>
                  <li>
                    <div className="seemingly">
                      <h5 className="Seek"> Be careful on social media</h5>
                      <p>
                        Stay away from social media while your{" "}
                        <strong>Marks and Spencer car insurance claim </strong>{" "}
                        is ongoing. The other party's solicitor may use your
                        social media activity to hurt your case and reduce
                        compensation.{" "}
                      </p>
                    </div>
                  </li>
                  <li>
                    <div className="seemingly">
                      <h5 className="Seek">
                        Seek guidance from an experienced solicitor:
                      </h5>
                      <p>
                        Call us on the{" "}
                        <strong>M&S car insurance phone number</strong> to speak
                        to an experienced no-fault claim solicitor to know your
                        rights and options.{" "}
                      </p>
                    </div>
                  </li>
                  <li>
                    <div className="seemingly">
                      <h5 className="Seek">Make your claim</h5>
                      <p>
                        Once you’ve gathered crucial information and evidence,
                        get in touch with us via{" "}
                        <strong> M&S car insurance claims number </strong> to
                        initiate your claim.
                      </p>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="whyus">
        <div className="container">
          <div className="row">
            <div className="col-xxl-12 col-md-12">
              <div className="why">
                <h2 className="choose">
                  Register your claim in{" "}
                  <span className="light"> 4 easy steps </span>
                </h2>
                <p className="complexities">
                  Initiating an <strong> M&S car insurance </strong> claim with
                  us is no longer a daunting process. No long forms, long
                  waiting times and being passed around. Simply, call us on{" "}
                  <strong> M&S car insurance </strong> to initiate your claim,
                  and we will handle the rest.{" "}
                </p>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-xxl-3 col-xl-6 col-lg-6 col-md-6 col-12">
              <div className="box-service">
                <img src={check} className="check" alt="" />
                {/* <img src={experience} className="experience" alt="" /> */}
                <h4>Get in touch</h4>
                <p>
                  Call our dedicated team on the 24/7 claims helpline at{" "}
                  <a href="tel:0800 772 0850">0800 772 0850</a> to report the
                  accident and discuss your claim.
                </p>
              </div>
            </div>
            <div className="col-xxl-3 col-xl-6 col-lg-6 col-md-6 col-12">
              <div className="box-service">
                <img src={check} className="check" alt="" />
                <h4>Share accident details </h4>
                <p>
                  If you have any crucial information and evidence related to
                  the accident, share it with our solicitors.
                </p>
              </div>
            </div>
            <div className="col-xxl-3 col-xl-6 col-lg-6 col-md-6 col-12">
              <div className="box-service">
                <img src={check} className="check" alt="" />
                <h4>Claim assessment </h4>
                <p>
                  Our solicitors will examine your{" "}
                  <strong> Marks and Spencer car insurance claim </strong> to
                  evaluate the severity of damages.
                </p>
              </div>
            </div>
            <div className="col-xxl-3 col-xl-6 col-lg-6 col-md-6 col-12">
              <div className="box-service">
                <img src={check} className="check" alt="" />
                <h4> Claim initiation & resolution</h4>
                <p>
                  Once you’ve provided us with all the relevant documents, we’ll
                  initiate your claim and keep you informed about the progress.{" "}
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="faqs">
        <div className="container">
          <div className="row">
            <div className="col-xxl-12 col-xl-12 col-md-12 col-12">
              <div className="fq">
                <h2>
                  {" "}
                  Frequently Asked{" "}
                  <span className="light">Questions (FAQ)</span>
                </h2>
                <p className="complexities">
                  Got questions about the{" "}
                  <strong> M&S car insurance claims? </strong> We have them
                  answered here.{" "}
                </p>
              </div>
              <div className="allfaq">
                <Accordion defaultActiveKey="0">
                  <Accordion.Item eventKey="0">
                    <Accordion.Header>
                      Am I eligible to get a courtesy car after a no-fault car
                      accident?
                    </Accordion.Header>
                    <Accordion.Body>
                      Yes. You’re entitled to receive a courtesy car after a
                      no-fault car accident. When you contact us on the{" "}
                      <strong> M&S car insurance phone number </strong> , we
                      will provide you with a courtesy car without upfront
                      costs.
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="1">
                    <Accordion.Header>
                      Do I have to pay anything for the accident management
                      services?
                    </Accordion.Header>
                    <Accordion.Body>
                      No. We won’t charge any upfront charges for our accident
                      management services. We’ll recover all the costs from the
                      at-fault party’s insurer.
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="2">
                    <Accordion.Header>
                      Can I choose my own repairer for the repairs of my damaged
                      vehicle?
                    </Accordion.Header>
                    <Accordion.Body>
                      Yes. With us, you enjoy the freedom to choose your own
                      repairer. If you want to get your vehicle repaired from
                      your chosen repair centre, you can share the details with
                      us so that we can organise your repairs from the garage of
                      your choice.
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="3">
                    <Accordion.Header>
                      Will my NCB be safe if I make a no-fault claim?{" "}
                    </Accordion.Header>
                    <Accordion.Body>
                      If the accident is not your fault, your no-claims bonus
                      and insurance premium will stay intact.
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="4">
                    <Accordion.Header>
                      {" "}
                      What will happen if my vehicle is a write-off?{" "}
                    </Accordion.Header>
                    <Accordion.Body>
                      If your car is a total loss as a result of the car
                      accident, we will provide you with a like-for-like
                      replacement vehicle until you receive a cheque from the
                      other party’s insurance company.
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="5">
                    <Accordion.Header>
                      Will the repairers from your approved network repair my
                      vehicle using genuine parts?{" "}
                    </Accordion.Header>
                    <Accordion.Body>
                      Yes. Our repair network comprises manufacturer-approved
                      repairers who provide high-quality repairs using genuine
                      parts to ensure the highest standards of excellence.
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="6">
                    <Accordion.Header>
                      What losses can I recover by making an M&S car accident
                      claim?
                    </Accordion.Header>
                    <Accordion.Body>
                      You can recover all the expenses you’ve incurred as a
                      result of a car accident, including loss of earnings,
                      medical expenses, travelling expenses, damaged property,
                      costs of vehicle repairs and replacement vehicle or any
                      other expenses that you’ve incurred due to the accident.
                    </Accordion.Body>
                  </Accordion.Item>
                </Accordion>
              </div>
            </div>
          </div>
        </div>
      </section>

      <Footer />
    </>
  );
};

export default Marksandspencercarinsurance;
