import React, { useRef } from "react";
import Accordion from "react-bootstrap/Accordion";
import check from "../assets/images/check.png";
import dualcar from "../../src/assets/images/dualcar.webp";
import hasting from "../../src/assets/images/hasting.webp";
import Sidebarinner from "../components/Sidebar-inner";
import phonecall from "../assets/images/phonecall.png";
import Footer from "../components/footer";
import { Helmet } from "react-helmet";

const Fordinsurance = () => {
    const sidebarRef = useRef(null);

    const handleScrollToSidebar = () => {
        if (sidebarRef.current) {
            sidebarRef.current.scrollIntoView({ behavior: "smooth" });
        }
    };
    return (
        <>
        <Helmet>
        <meta charSet="utf-8" />
        <title>Ford Insure Car Insurance Accident Claim Phone Number</title>
        <meta
          name="description"
          content="Need guidance to proceed with the no-fault car accident claim in the UK? Contact our team on the Ford Insure Car Insurance Accident Claim Phone Number 0800 772 0850. "
        />
        <link
          rel="canonical"
          href="https://insurance-accident-claims.co.uk/Ford-insurance"
        />
      </Helmet>
            <section className="innernav">
                <div className="container">
                    <div className="row">
                        <div className="col-md-6 col-sm-6">
                            <div className="left-logo">
                                <h3>
                                    Accident<span className="sipo"> Claims</span>
                                </h3>
                            </div>
                        </div>
                        <div className="col-md-6 col-sm-6">
                            <div className="left-cta">
                                <div class="headercnt">
                                    <div class="chatmsg">
                                        <img
                                            src={phonecall}
                                            className="phonecall"
                                            alt="phonecall "
                                        />
                                    </div>
                                    <div class="box-body">
                                        <h3 class="nav-info-box-title">Toll Free Number </h3>
                                        <p>
                                            <a href="tel:0800 772 0850">0800 772 0850</a>
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section
                className="newbnr"
                style={{ backgroundImage: "url(" + hasting + ")" }}
            >
                <div className="container">
                    <div className="row">
                        <div className="col-xxl-7 col-xl-7 col-md-10 col-12">
                            <div className="innerbnr">
                                <h1>
                                    <span className="onespan"> Car Accident  insurance claims </span> {" "}
                                    <span className="sci">from start  </span>to end 
                                </h1>
                                <p className="brokerage">
                                We ensure you get back on the road as soon as possible with our dedicated assistance that includes arranging replacement vehicles, organising repairs and providing comprehensive claims assistance.                             </p>
                                <div className="bnrbtn">
                                    <a class="db-btn-border btn-rollover phone" href="tel:0800 772 0850">
                                        Road Accident Claim Help?{" "}
                                        <i class="fa-solid fa-arrow-right"></i>
                                    </a>
                                </div>
                                <div className="bnrbtn">
                                    <a className="db-btn-border btn-rollover desktop" onClick={handleScrollToSidebar}>
                                        Road Accident Claim Help? <i className="fa-solid fa-arrow-right"></i>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="stickysection">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="group-4-8">
                                <div className="cl8 tf-tab">
                                    <div className="content-tab">
                                        <div className="inner-content">
                                            <h2>
                                            Trusted claims support without any
                                                <br />
                                                <span className="light">  excess payment</span>{" "}
                                            </h2>
                                            <p className="disciplines">
                                            Our job is to help you with accident management in case of a non-fault car crash. Call the <strong> Ford Insure motor claims number </strong>  for emergency assistance and claims handling without excess payment.
                                            </p>
                                            <hr />
                                            <p className="bold-text">No upfront payments   </p>
                                            <p className="bottom-te"> We won’t charge any excess as we will recover all costs from the other party's insurer. 
                                            
                                        </p>
                                            <hr />
                                            <p className="bold-text">Retained no-claims bonus </p>
                                            <p className="bottom-te">
                                            Since we won’t go against your own policy, your discounts and no-claims bonuses are all protected.                                       </p>
                                            <hr />
                                            <p className="bold-text">
                                            Individually managed claims
                                            </p>
                                            <p className="bottom-te">
                                            To lodge a claim, just call us via the <strong> Ford Insure motor claims contact number.</strong>  We will assign one of our experienced claims handlers who will assist with everything related to your <strong> Ford Insure motor claim </strong> right from its inception till its settlement                                            </p>
                                            <hr />
                                            <p className="bold-text">Like-for-like replacement</p>
                                            <p className="bottom-te">
                                            We shall provide a similar car to you during the repair period similar to the make, size or model of your own vehicle.                                      </p>
                                            <hr />
                                            <p className="bold-text">Manufacturer-approved repairs </p>
                                            <p className="bottom-te">
                                            Authorised repairers will fix your car using genuine materials and paint to get it back to its original condition.                                         </p>
                                            <hr />
                                            <p className="bold-text">Fast-track claims process</p>
                                            <p className="bottom-te">
                                            Our claim process is expedited and simplified so that you can have a quick solution to the<strong>  Ford Insure auto insurance claim </strong> without long waiting times.  </p>
                                            <hr />
                                            <p className="bold-text">Experienced solicitors</p>
                                            <p className="bottom-te">
                                            When you call us on our car insurance claim phone number, 
                                            <strong>
                                            {" "}
                                            <a href="tel:0800 772 0850">0800 772 0850</a>{" "}
                                        </strong> we put you in touch with solicitors having enough expertise to provide the best legal counsel and assistance for your case.
                                        </p>
                                            <hr />
                                            <p className="bold-text"> No-win no-fee agreement</p>
                                            <p className="bottom-te">
                                            With our no-win, no-fee offer, we do not charge any upfront fees if your case does not go in your favour.                                     </p>
                                            <hr />
                                            <p className="bold-text">Extensive network of repairs </p>
                                            <p className="bottom-te">
                                            In order to meet set quality standards during the repair of your car in the UK, we are associated with approved repairers.                </p>
                                            <hr />

                                            <p className="bold-text">Administrative support</p>
                                            <p className="bottom-te">
                                            We will take care of all the paperwork and documentation to ensure a quick settlement of your claim without disruption. 
                                                     
                                                 </p>
                                        </div>
                                    </div>
                                </div>
                                <div className="cl4" ref={sidebarRef}>
                                    <Sidebarinner />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="photographs">
                <div className="container">
                    <div className="row">
                        <h2 className="road">
                        We provide comprehensive accident support to {" "}
                            <span className="light">
                             alleviate <br />
                             your stress
                            </span>{" "}
                        </h2>
                        <div className="col-xxl-6 col-xl-6 col-md-12 col-12">
                            <div className="fourbox">
                                <ul>
                                    <li>
                                        <img src={check} className="check" alt="" />
                                        We provide reliable and dedicated post-crash support. Call now on the <strong> Ford Insure accident claim </strong>  phone number to get started with us. 

                                    </li>
                                    <hr />
                                    <li>
                                        <img src={check} className="check" alt="" />
                                        Our main aim is to offer you an alternative car that will be the same as yours so that you can continue using it for your daily tasks.                               </li>
                                    <hr />
                                    <li>
                                        <img src={check} className="check" alt="" />
                                        Within a short period of time, we shall fix your car in the best possible condition.
                                    </li>
                                    <hr />
                                    <li>
                                        <img src={check} className="check" alt="" />
                                        We have solicitors who will help you recover all the losses and damages from the insurance company of the other party.                            </li>
                                </ul>
                            </div>
                        </div>
                        <div className="col-xxl-6 col-xl-6 col-md-12 col-12">
                            <div className="fourbox">
                                <ul>
                                    <li>
                                        <img src={check} className="check" alt="" />
                                        We will organise for a separate engineer to come and inspect your vehicle's damages.
                                        </li>
                                    <hr />
                                    <li>
                                        <img src={check} className="check" alt="" />
                                        When you call us on 
                                        <strong>
                                            {" "}
                                            <a href="tel:0800 772 0850">0800 772 0850</a>{" "}
                                        </strong> , our experienced solicitors will provide professional legal support. 
                                        </li>
                                    <hr />
                                    <li>
                                        <img src={check} className="check" alt="" />
                                        Moreover, we will plan on how your vehicle will be collected and delivered following its repair.                                </li>
                                    <hr />
                                    <li>
                                        <img src={check} className="check" alt="" />
                                        We shall engage all parties involved in the accident for quick settlement                          </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="carprocess">
                <div className="container ">
                    <div className="row">
                        <div className="col-xxl-12 col-xxl-12 col-md-12 col-12">
                            <h2 className="trusted-title">
                                {" "}
                                Accident management services <br />
                                <span className="light">we offer </span>{" "}
                            </h2>
                        </div>
                    </div>
                    <div className="row" id="possible">
                        <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-12">
                            <div className="claim">
                                <ul>
                                    <li>
                                        <span className="sleej">Vehicle recovery:</span>    If any severe damage has occurred, rendering your vehicle totally undrivable, contact the <strong> Ford Insure car insurance claim phone number, </strong> and we’ll dispatch our well-trained recovery team members immediately to collect your vehicle from the accident spot. 
                                    </li>
                                    <li>
                                        <span className="sleej">Approved repairs :</span>    Call us on the <strong> Ford Insure car accident phone number </strong>  if you need assistance to repair your vehicle after a no-fault road traffic accident. We will organise quick repairs using genuine parts and paint. 

                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-12">
                            <div className="claim" id="blue">
                                <ul>
                                    <li>
                                        <span className="sleej">Vehicle storage:</span>Would you like to have your car stored safely after an accident? Ring us at the <strong> Ford Insure motor claims contact number. </strong> We will go and pick up your car for you and store it in our well-protected compounds to prevent further damage.      
                                    </li>
                                    <li>
                                        <span className="sleej">Replacement vehicle :</span> You’re entitled to a replacement car if the accident was not your fault. Call us using the <strong> Ford Insure car accident claim phone number, </strong> and we will provide you with a similar car matching your own until yours is undergoing repairs.    
                                         
                                        
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-12">
                            <div className="claim">
                                <ul>
                                    <li>
                                        <span className="sleej">Personal injury assistance</span>
                                        We offer the best quality advice from our solicitors, which will ensure that you receive rightful compensation for injuries incurred during the accident.                                       </li>
                                    <li>
                                        <span className="sleej">Claim management:</span>  We deal with everything from the submission of a claim up to its final settlement, inclusive of paperwork, evidence and discussions, all geared towards having your claim settled as smoothly as possible.
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="yellowcta">
                <div className="container">
                    <div className="row">
                        <div className="cta2">
                            <div className="col-xxl-8 col-xl-8 col-lg-8 col-12">
                                <div className="legal">
                                    <h3 className="experienced">
                                    Got more questions? Talk to {" "}
                                        <span className="light">  our specialists 
                                        </span>{" "}
                                    </h3>
                                    <p className="professionals">
                                    Get in touch with our no-fault claim experts via <strong> car insurance claim phone number</strong>
                                    <strong>
                                            {" "}
                                            <a href="tel:0800 772 0850">0800 772 0850</a>{" "}
                                        </strong>  to get the best advice for your <strong>Ford Insure car insurance claim. </strong> 
                                    </p>
                                </div>
                            </div>
                            <div className="col-xxl-4 col-xl-4 col-lg-4 col-12">
                                <div class="process">
                                    <a class="tf-button style-1 phone" href="tel:0800 772 0850">
                                        Get in touch<span class="icon-keyboard_arrow_right"></span>
                                    </a>
                                </div>
                                <div class="process">
                                    <a className="tf-button style-1 desktop" onClick={handleScrollToSidebar}>
                                        Get in touch<span className="icon-keyboard_arrow_right"></span>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <search className="updatedform">
                <div className="container">
                    <div className="row text-center">
                        <div className="col-xxl-12 col-xl-12 col-md-12 col-12">
                            <h2 className="whiteheading">
                                {" "}
                                What sets us apart from other accident  
                                <span className="light">  management companies?
                                </span>
                            </h2>
                            <p className="sustained">
                            We are a team of professionals who are experts in helping accident victims get back on track after a no-fault car accident. Our services have been streamlined to ensure that the process of managing <strong> Ford Insure motor claims </strong> is stress-free, uncomplicated as well as not time-consuming. Contact us today via the <strong> Ford Insure car accident claim phone number </strong> for immediate help and expert advice.                              </p>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-xxl-4 col-xl-4 col-lg-6 col-md-12 col-12">
                            <div className="Claimant">
                                <h5>No claim excess</h5>
                                <p className="collision">
                                We are not going to charge you because all our fees will be collected from the wrongdoer’s insurer.With our independent accident management services, you will be freed from the requirement of paying out any money since we shall put forward<strong> Ford Insure car insurance claims </strong> directly against the other party.

                                </p>

                                <h5>Stress-free vehicle repairs</h5>
                                <p className="collision">
                                Manufacturer-approved repairers would repair your car using genuine parts and paintwork within no time.                 </p>

                                <h5>Same-day replacement car</h5>
                                <p className="collision">
                                If the accident was not your fault, we can make arrangements for an alternative car, which will be delivered to your doorstep within 24 hours.
                                </p>
                            </div>
                        </div>
                        <div className="col-xxl-4 col-xl-4 col-lg-6 col-md-12 col-12">
                            <div className="Claimant">
                                <h5>Free advice without any obligation
                                </h5>
                                <p className="collision">
                                Just dial the <strong> Ford Insure motor claim number, </strong>  and we will promptly advise and guide you so that you can obtain maximum benefits with respect to <strong> Ford Insure car insurance claims. </strong> </p>
                                <h5>Expertise in handling claims</h5>
                                <p className="collision">
                                When the <strong> Ford Insure car insurance claim </strong> is made, our dedicated claims handler will effectively deal with it, and develop strategies that are appropriate for the situation, engage all parties involved and ensure prompt settlement.                          </p>

                                <h5>Uninsured loss recovery</h5>
                                <p className="collision">
                                Our solicitors will make sure that you recover all financial costs from the other party’s insurance company. 
                                </p>
                            </div>
                        </div>
                        <div className="col-xxl-4 col-xl-4 col-lg-12 col-md-12 col-12">
                            <div className="Claimant">
                                <h5>24/7 Availability </h5>
                                <p className="collision">
                                For accident management purposes, we are available around the clock. To get emergency assistance after an accident, call us on the <strong> Ford Insure car accident claim number </strong> immediately.                              </p>

                                <h5>A complete range of services </h5>
                                <p className="collision">
                                We provide complete accident management support,  including recovering vehicles, arranging for repairs, providing replacement vehicles and managing <strong> Ford Insure car insurance claims. </strong>                          </p>

                                <h5>Immediate recovery</h5>
                                <p className="collision">
                                Our recovery team will immediately retrieve your vehicle from the accident and transport it to a safer place to avoid further deterioration.                         </p>
                            </div>
                        </div>
                    </div>
                </div>
            </search>

            <section className="Accident-claim">
                <div className="container">
                    <div className="row">
                        <div className="needed">
                            <h2 className="trusted-title">
                            How can you enhance your claim <br />
                                <span className="light"> value?</span>
                            </h2>
                            <p className="complexities">
                            The amount one receives as compensation in case of a no-fault auto collision varies depending on several factors. Therefore, post a no-fault car accident, steps must be taken to make sure that the value paid out in respect of Ford Insure motor claims is enhanced.                      </p>
                        </div>
                        <hr />
                    </div>
                    <div className="row" id="incdes">
                        <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-12">
                            <div className="Preparing">
                                <ul>
                                    <li>
                                        <div className="seemingly">
                                            <h5 className="Seek">Get immediate medical attention :</h5>
                                            <p>
                                                {" "}
                                                You must immediately seek medical assistance from an authorised physician. The medical report indicates the severity of your injuries and can help you support your claim.                                      </p>
                                        </div>
                                    </li>
                                    <li>
                                        <div className="seemingly">
                                            <h5 className="Seek">
                                            Don’t agree to a fast settlement :{" "}
                                            </h5>
                                            <p>
                                            The insurance could give you a low offer in order to minimise payout. Before accepting this kind of offer, consult with your solicitor, who will ensure that it fairly compensates you for any loss or damage.                                            </p>
                                        </div>
                                    </li>
                                    <li>
                                        <div className="seemingly">
                                            <h5 className="Seek">Keep off social media :</h5>
                                            <p>  Avoid speaking or posting anything on social media since it may reduce the value of your settlement. Don’t use social media until your Insure car insurance claim is resolved.                                        </p>
                                        </div>
                                    </li>
                                    <li>
                                        <div className="seemingly">
                                            <h5 className="Seek">Gather proof  :</h5>
                                            <p>
                                            Take important evidence from the scene of the accident, such as pictures of the location, vehicles involved and injuries sustained by yourself.                                            </p>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div
                            className="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-12"
                            id="dualcardiv"
                        >
                            <img src={dualcar} alt="" className="dualcar" />
                        </div>
                        <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-12">
                            <div className="Preparing">
                                <ul>
                                    <li>
                                        <div className="seemingly">
                                            <h5 className="Seek">Share information with other party :</h5>
                                            <p>
                                            Exchange details with other drivers involved in car accidents like contact details,  licence information and insurance details so that there are no hitches in the claiming process.                                        </p>
                                        </div>
                                    </li>
                                    <li>
                                        <div className="seemingly">
                                            <h5 className="Seek">Talk to a no-fault solicitor: </h5>
                                            <p>
                                            Communicate with an experienced solicitor who can negotiate with the other driver’s insurance company and secure adequate compensation for your losses. For professional advice on your specific case, call the<strong> Ford Insure car accident claim phone number    </strong>                                          </p>
                                        </div>
                                    </li>
                                    <li>
                                        <div className="seemingly">
                                            <h5 className="Seek">
                                            Commence your claim:
                                            </h5>
                                            <p>
                                            Once you have all the paperwork and evidence ready, get in touch with us through the Ford Insure car accident claim contact number so that you can register your claim.
                                            </p>
                                        </div>
                                    </li>
                                    
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="whyus">
                <div className="container">
                    <div className="row">
                        <div className="col-xxl-12 col-md-12">
                            <div className="why">
                                <h2 className="choose">
                               How can you start your claim {" "}
                                    <span className="light">with us?</span>
                                </h2>
                                <p className="complexities">
                                Please give us a call on
                                <strong>
                                            {" "}
                                            <a href="tel:0800 772 0850">0800 772 0850</a>{" "}
                                        </strong>  if you think you may be eligible for compensation following an accident that was not your fault. Our simplified claims process will enable you to easily make your claim without any concerns about paperwork.                      </p>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-xxl-3 col-xl-6 col-lg-6 col-md-6 col-12">
                            <div className="box-service">
                                <img src={check} className="check" alt="" />
                                {/* <img src={experience} className="experience" alt="" /> */}
                                <h4>Call us</h4>
                                <p>
                                To arrange a free initial consultation for your case, ring our team on the <strong> Ford Insure motor claims contact number.</strong> 
                                        </p>
                            </div>
                        </div>
                        <div className="col-xxl-3 col-xl-6 col-lg-6 col-md-6 col-12">
                            <div className="box-service">
                                <img src={check} className="check" alt="" />
                                <h4>Give us details</h4>
                                <p>
                                Our professionals will inquire about all related issues of an accident and its proof against the guilty party.</p>
                            </div>
                        </div>
                        <div className="col-xxl-3 col-xl-6 col-lg-6 col-md-6 col-12">
                            <div className="box-service">
                                <img src={check} className="check" alt="" />
                                <h4>Claim initiation </h4>
                                <p>
                                Our solicitors will institute legal action after they receive relevant papers and evidence.                              </p>
                            </div>
                        </div>
                        <div className="col-xxl-3 col-xl-6 col-lg-6 col-md-6 col-12">
                            <div className="box-service">
                                <img src={check} className="check" alt="" />
                                <h4>Claim settlement </h4>
                                <p>
                                A fair negotiation result would be reached where you will be compensated for all the damages and losses suffered. 

                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="faqs">
                <div className="container">
                    <div className="row">
                        <div className="col-xxl-12 col-xl-12 col-md-12 col-12">
                            <div className="fq">
                                <h2>
                                    {" "}
                                    Frequently Asked{" "}
                                    <span className="light">Questions</span>
                                </h2>
                                <p className="complexities">
                                Got questions about the <strong>  Ford Insure car insurance claim?  </strong>  We have them answered here.

                                </p>
                            </div>
                            <div className="allfaq">
                                <Accordion defaultActiveKey="0">
                                    <Accordion.Item eventKey="0">
                                        <Accordion.Header>
                                        Could you support me if my car is immovable when it is hit by another vehicle?                                 </Accordion.Header>
                                        <Accordion.Body>
                                        In the scenario that your vehicle is rendered immovable by an accident, we shall get it recovered from the accident scene and provide you with another one so that you keep doing your daily tasks without interruption.                               </Accordion.Body>
                                    </Accordion.Item>
                                    <Accordion.Item eventKey="1">
                                        <Accordion.Header>
                                        What will happen to my car after a road accident?
                                        </Accordion.Header>
                                        <Accordion.Body>
                                        If your car is repairable, we will arrange for repairs at a reliable body shop in the UK. Please contact us on the <strong> Ford Insure motor claims contact number </strong>  if you wish to use your own repairer.                                     </Accordion.Body>
                                    </Accordion.Item>
                                    <Accordion.Item eventKey="2">
                                        <Accordion.Header>
                                        How long can I store my car after an accident?
                                        </Accordion.Header>
                                        <Accordion.Body>
                                        We will store your car in our storage yards until your car is fixed or your claim is settled.                          </Accordion.Body>
                                    </Accordion.Item>
                                    <Accordion.Item eventKey="3">
                                        <Accordion.Header>
                                        Will initiating a claim affect my insurance premiums?
                                        </Accordion.Header>
                                        <Accordion.Body>
                                        No effect will be had on coverage rates, as well as no claims bonus since we won’t claim against your own policy.                              </Accordion.Body>
                                    </Accordion.Item>
                                    <Accordion.Item eventKey="4">
                                        <Accordion.Header>
                                        Can you help me in finding a qualified repairer who can fix my car?

                                        </Accordion.Header>
                                        <Accordion.Body>
                                        Certainly! We still arrange for manufacturer-approved repairers to repair your broken-down vehicle utilising genuine spares and superior paint.                         </Accordion.Body>
                                    </Accordion.Item>
                                    <Accordion.Item eventKey="5">
                                        <Accordion.Header>
                                        How long do I have to wait before driving again after having a non-fault accident?{" "}
                                        </Accordion.Header>
                                        <Accordion.Body>
                                        We are committed to making sure that you are back on the road as soon as possible. Just dial the<strong> Ford Insure car insurance claim phone number, </strong> and we will get you another vehicle within 24 hours.                           </Accordion.Body>
                                    </Accordion.Item>
                                    <Accordion.Item eventKey="6">
                                        <Accordion.Header>
                                        Do I need to pay any policy excess on my insurance?{" "}
                                        </Accordion.Header>
                                        <Accordion.Body>
                                        No, there isn’t any excess for your insurance policy when you claim, as we won’t make the claim against your own insurance policy.
                                        </Accordion.Body>
                                    </Accordion.Item>
                                </Accordion>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <Footer />
        </>
    );
};

export default Fordinsurance;
