import React, { useRef } from "react";
import Accordion from "react-bootstrap/Accordion";
import check from "../assets/images/check.png";
import dualcar from "../../src/assets/images/dualcar.webp";
import bner from "../../src/assets/images/bner.webp";
import Sidebarinner from "../components/Sidebar-inner";
import phonecall from "../assets/images/phonecall.png";
import Footer from "../components/footer";
import { Helmet } from "react-helmet";

const Oinkinsurance = () => {
    const sidebarRef = useRef(null);

    const handleScrollToSidebar = () => {
        if (sidebarRef.current) {
            sidebarRef.current.scrollIntoView({ behavior: "smooth" });
        }
    };
    return (
        <>
        <Helmet>
        <meta charSet="utf-8" />
        <title>Oink Insurance Car Insurance Claim Phone Number</title>
        <meta
          name="description"
          content="Have you experienced a no-fault car accident? Call us on the Oink Insurance Car Insurance Claim Phone Number 0800 772 0850 to get prompt no-fault claims assistance. "
        />
        <link
          rel="canonical"
          href="https://insurance-accident-claims.co.uk/Oink-insurance"
        />
      </Helmet>
            <section className="innernav">
                <div className="container">
                    <div className="row">
                        <div className="col-md-6 col-sm-6">
                            <div className="left-logo">
                                <h3>
                                    Accident<span className="sipo"> Claims</span>
                                </h3>
                            </div>
                        </div>
                        <div className="col-md-6 col-sm-6">
                            <div className="left-cta">
                                <div class="headercnt">
                                    <div class="chatmsg">
                                        <img
                                            src={phonecall}
                                            className="phonecall"
                                            alt="phonecall "
                                        />
                                    </div>
                                    <div class="box-body">
                                        <h3 class="nav-info-box-title">Toll Free Number </h3>
                                        <p>
                                            <a href="tel:0800 772 0850">0800 772 0850</a>
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section
                className="newbnr"
                style={{ backgroundImage: "url(" + bner + ")" }}
            >
                <div className="container">
                    <div className="row">
                        <div className="col-xxl-7 col-xl-7 col-md-10 col-12">
                            <div className="innerbnr">
                                <h1>
                                    <span className="onespan">Expedited and hassle-free accident  </span>{" "}
                                    <span className="sci"> insurance claims  </span>services
                                </h1>
                                <p className="brokerage">
                                    We do everything from initial appraisal to settling your claim so that you can get back on the road as soon as possible.

                                </p>
                                <div className="bnrbtn">
                                    <a class="db-btn-border btn-rollover phone" href="tel:0800 772 0850">
                                        Road Accident Claim Help?{" "}
                                        <i class="fa-solid fa-arrow-right"></i>
                                    </a>
                                </div>
                                <div className="bnrbtn">
                                    <a className="db-btn-border btn-rollover desktop" onClick={handleScrollToSidebar}>
                                        Road Accident Claim Help? <i className="fa-solid fa-arrow-right"></i>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="stickysection">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="group-4-8">
                                <div className="cl8 tf-tab">
                                    <div className="content-tab">
                                        <div className="inner-content">
                                            <h2>
                                                We’re the UK’s top
                                                <br />
                                                <span className="light"> claim handlers </span>{" "}
                                            </h2>
                                            <p className="disciplines">
                                                Among the main UK providers of <strong>
                                                    {" "}
                                                    Oink insurance claims
                                                </strong>{" "}
                                                help, having handled many with success. You can complain by getting in touch with us via the
                                                <strong>
                                                    {" "}
                                                    Oink car insurance contact number.
                                                </strong>{" "}
                                                We will assist you in making an
                                                <strong>
                                                    {" "}
                                                    Oink car insurance
                                                </strong>{" "}
                                                claim at no cost.
                                            </p>
                                            <hr />
                                            <p className="bold-text">No Deductible</p>
                                            <p className="bottom-te">
                                                If it is not your fault for the accident, then no extra charges are necessary for your premium payment.                                        </p>
                                            <hr />
                                            <p className="bold-text">No-claims bonus protection</p>
                                            <p className="bottom-te">
                                                You don’t need to worry about the safety of your no-claims bonus. We won’t make a claim against your own insurance policy. So your hard-earned discounts and no-claims bonus will stay intact.                                          </p>
                                            <hr />
                                            <p className="bold-text">
                                                Rapid vehicle recovery
                                            </p>
                                            <p className="bottom-te">
                                                We don’t want to leave you stranded on the roadside after an accident or a collision. Make a call immediately to our helpline through the <strong>Oinkcar insurance phone number,</strong>  and we will ensure that wherever you have left it, your motor vehicle will be recovered efficiently and promptly at all times.                                          </p>
                                            <hr />
                                            <p className="bold-text">Approved repair  </p>
                                            <p className="bottom-te">
                                                Since we work with manufacturer-approved repairers, your vehicle repairs will be of the highest quality.                                            </p>
                                            <hr />
                                            <p className="bold-text">Like-for-like replacement car </p>
                                            <p className="bottom-te">
                                                We can provide you with one similar through like-for-like hire when your car is being serviced or has been rendered unfit to drive.                                            </p>
                                            <hr />
                                            <p className="bold-text">Specialist legal professionals</p>
                                            <p className="bottom-te">
                                                The involvement of our proficient solicitors in processing <strong>Oink car insurance claims </strong> has seen victims get their full compensation due to their expertise in no-fault accident injury cases.                                     </p>
                                            <hr />
                                            <p className="bold-text">Quick claim process</p>
                                            <p className="bottom-te">
                                                Our claim procedure is fast and efficient. That's why we do not require much paperwork and long waits. You can claim without having a lot of trouble with paperwork.                                             </p>
                                            <hr />
                                            <p className="bold-text">Free independent advice</p>
                                            <p className="bottom-te">
                                                You may call us on  <strong>
                                                    {" "}
                                                    <a href="tel:0800 772 0850">0800 772 0850</a>{" "}
                                                </strong>  if you want to get free advice regarding the <strong>Oink insurance claims.</strong>  Our solicitors will provide free, no-obligation advice without any pressure to proceed ahead.                                            </p>
                                            <hr />
                                            <p className="bold-text">No-win-no-fee basis</p>
                                            <p className="bottom-te">
                                                Our solicitors operate under a no-win, no-fee policy for handling our clients’ insurance claims. Therefore, you will not pay anything if you lose your case.                                         </p>
                                            <hr />

                                            <p className="bold-text">24/7 accident recovery</p>
                                            <p className="bottom-te">
                                                No matter what time it happens, call us on the <strong> Oink insurance accident claim phone number </strong> to get emergency assistance to get your car recovered from the accident scene.                                           </p>
                                        </div>
                                    </div>
                                </div>
                                <div className="cl4" ref={sidebarRef}>
                                    <Sidebarinner />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="photographs">
                <div className="container">
                    <div className="row">
                        <h2 className="road">
                            Insurance accident support right   {" "}
                            <span className="light">
                                from <br />
                                collision to recovery
                            </span>{" "}
                        </h2>
                        <div className="col-xxl-6 col-xl-6 col-md-12 col-12">
                            <div className="fourbox">
                                <ul>
                                    <li>
                                        <img src={check} className="check" alt="" />
                                        Talk to us through the <strong>  Oink insurance accident claim number. </strong> Our professional team will be able to find a solution that will recover your car and take it safely away.

                                    </li>
                                    <hr />
                                    <li>
                                        <img src={check} className="check" alt="" />
                                        From vehicle recovery to handing back your car keys after collection, we’ve got you covered at every post-accident stage.                                  </li>
                                    <hr />
                                    <li>
                                        <img src={check} className="check" alt="" />
                                        From vehicle recovery to handing back your car keys after collection, we’ve got you covered at every post-accident stage.                                  </li>
                                    <hr />
                                    <li>
                                        <img src={check} className="check" alt="" />
                                        We would arrange for repairs at a manufacturer-approved repairer that will restore the condition of your vehicle before the accident takes place.                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div className="col-xxl-6 col-xl-6 col-md-12 col-12">
                            <div className="fourbox">
                                <ul>
                                    <li>
                                        <img src={check} className="check" alt="" />
                                        Our storage facilities are available throughout the process, so you’re not worried about costs anymore.                                  </li>
                                    <hr />
                                    <li>
                                        <img src={check} className="check" alt="" />
                                        We shall ensure you receive the best legal representation when reporting an accident via the <strong> Oink insurance accident claim number. </strong>                                </li>
                                    <hr />
                                    <li>
                                        <img src={check} className="check" alt="" />
                                        It’s our duty to communicate with all parties involved in the accident, thereby saving you unnecessary stress.                                     </li>
                                    <hr />
                                    <li>
                                        <img src={check} className="check" alt="" />
                                        Your car repairs will be completed and delivered to your doorstep within an acceptable time period.                       </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="carprocess">
                <div className="container ">
                    <div className="row">
                        <div className="col-xxl-12 col-xxl-12 col-md-12 col-12">
                            <h2 className="trusted-title">
                                {" "}
                                Accident management services <br />
                                <span className="light"> we offer </span>{" "}
                            </h2>
                        </div>
                    </div>
                    <div className="row" id="possible">
                        <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-12">
                            <div className="claim">
                                <ul>
                                    <li>
                                        <span className="sleej">Car recovery :</span>Contact the  <strong> Oinkcar insurance phone number</strong>   to recover your car after an accident. We will ensure fast removal of your vehicle from the scene of the accident and its safe storage in our secure yards.
                                    </li>
                                    <li>
                                        <span className="sleej">Accidental repairs:</span> In case you need to fix your vehicle, please call us on the  <strong> Oink insurance claim number. </strong> We will arrange high-quality repairs from manufacturer-approved repairers.
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-12">
                            <div className="claim" id="blue">
                                <ul>
                                    <li>
                                        <span className="sleej">Vehicle storage:</span>    Do you wonder where to park your car after another driver causes an accident? Call us via the <strong> Oink insurance claim number. </strong>We provide secured yards to store your vehicle until your claim is settled.
                                    </li>
                                    <li>
                                        <span className="sleej">Replacement car :</span> You will be given another car if yours cannot be repaired. Call us on the emergency helpline
                                        <strong>
                                            {" "}
                                            <a href="tel:0800 772 0850">0800 772 0850</a>{" "}
                                        </strong>
                                        to get a like-for-like replacement vehicle and carry on your vehicle repairs without interruption.
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-12">
                            <div className="claim">
                                <ul>
                                    <li>
                                        <span className="sleej">Injury claim assistance :</span>
                                        Want to receive compensation for personal injuries? Dial the <strong> Oink insurance claim number </strong> to get expert advice from our solicitors to make a successful claim to get compensation for your injuries.                    </li>
                                    <li>
                                        <span className="sleej">Claims management :</span>  From taking care of paperwork to negotiating with insurers, arranging for repairs in garages and providing substitute cars, we have taken care of everything.

                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="yellowcta">
                <div className="container">
                    <div className="row">
                        <div className="cta2">
                            <div className="col-xxl-8 col-xl-8 col-lg-8 col-12">
                                <div className="legal">
                                    <h3 className="experienced">
                                        More queries? Call  {" "}
                                        <span className="light">    our professionals
                                        </span>{" "}
                                    </h3>
                                    <p className="professionals">
                                        Contacting the <strong>Oink car insurance claims </strong>  team is never a challenge through that line anytime for no-blame accident advice on automobile coverage at<strong>
                                            {" "}
                                            <a href="tel:0800 772 0850">0800 772 0850</a>{" "}
                                        </strong>
                                        to get the best advice for the
                                        <strong> Autosaint insurance claims. </strong>
                                    </p>
                                </div>
                            </div>
                            <div className="col-xxl-4 col-xl-4 col-lg-4 col-12">
                                <div class="process">
                                    <a class="tf-button style-1 phone" href="tel:0800 772 0850">
                                        Get in touch<span class="icon-keyboard_arrow_right"></span>
                                    </a>
                                </div>
                                <div class="process">
                                    <a className="tf-button style-1 desktop" onClick={handleScrollToSidebar}>
                                        Get in touch<span className="icon-keyboard_arrow_right"></span>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <search className="updatedform">
                <div className="container">
                    <div className="row text-center">
                        <div className="col-xxl-12 col-xl-12 col-md-12 col-12">
                            <h2 className="whiteheading">
                                {" "}
                                What makes us
                                <span className="light"> unique?

                                </span>
                            </h2>
                            <p className="sustained">
                                We focus on making sure you don’t have to worry about a non-fault road traffic accident. Here are some reasons why you should consider us as your preferred claims handler.                         </p>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-xxl-4 col-xl-4 col-lg-6 col-md-12 col-12">
                            <div className="Claimant">
                                <h5> Recovery service available round the clock</h5>
                                <p className="collision">
                                    Looking for vehicle recovery services? Just call the  <strong> Oink insurance claim number,</strong>  and we will come pick you up from wherever you are, irrespective of the time it is.


                                </p>

                                <h5>Guaranteed repairs</h5>
                                <p className="collision">
                                    We have partnered with high-quality and guaranteed repairers in the UK who can help restore your car to its original state as before.                              </p>

                                <h5>No rise in premium rates</h5>
                                <p className="collision">
                                    The   <strong>Oink car insurance claim</strong> will not be taken out of your own policy, saving your insurance premiums from going up.                                 </p>
                            </div>
                        </div>
                        <div className="col-xxl-4 col-xl-4 col-lg-6 col-md-12 col-12">
                            <div className="Claimant">
                                <h5>Secure vehicle storage
                                </h5>
                                <p className="collision">
                                    Following a no-fault car accident, our vehicle storage solutions offer secure protection for your vehicle. </p>
                                <h5>Customer-centric approach</h5>
                                <p className="collision">
                                    Call the <strong> Oink insurance accident claim number </strong> for free advice from our experienced solicitors, who will take into consideration the circumstances of your case.                             </p>

                                <h5>Efficient claims processing</h5>
                                <p className="collision">
                                    Our team efficiently processes <strong> Oink car insurance claims </strong> in order to achieve quick results. Besides this, we will keep you updated regarding how far your claim has gone.
                                </p>
                            </div>
                        </div>
                        <div className="col-xxl-4 col-xl-4 col-lg-12 col-md-12 col-12">
                            <div className="Claimant">
                                <h5>Uninsured loss recovery</h5>
                                <p className="collision">
                                    Did you bear any costs out of pocket because of the crash? We will help you to recover all those expenses from the insurer of the other party.                              </p>

                                <h5>Hassle-free vehicle replacement</h5>
                                <p className="collision">
                                    We provide services that allow you to get a suitable replacement vehicle with minimal disturbance, allowing you to continue with your everyday activities.                      </p>

                                <h5>End-to-end claims service </h5>
                                <p className="collision">
                                    From arranging for vehicle recovery to overseeing the entire lifecycle of a claim, we give round-the-clock support throughout every stage in the course of filing for compensation.                              </p>
                            </div>
                        </div>
                    </div>
                </div>
            </search>

            <section className="Accident-claim">
                <div className="container">
                    <div className="row">
                        <div className="needed">
                            <h2 className="trusted-title">
                                How to Increase the<br />
                                <span className="light">  Claim Value? </span>
                            </h2>
                            <p className="complexities">
                                Do you want to increase the claim value of your <strong>Oink car insurance claim? </strong> Here are the steps you should take to maximise your chances of a bigger payout on the <strong>Oink car insurance claim. </strong>
                            </p>
                        </div>
                        <hr />
                    </div>
                    <div className="row" id="incdes">
                        <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-12">
                            <div className="Preparing">
                                <ul>
                                    <li>
                                        <div className="seemingly">
                                            <h5 className="Seek">Report the accident:</h5>
                                            <p>
                                                {" "}
                                                Phone the police and report the accident within 24 hours of the crash. Moreover, remember to get a copy of the police report, which contains detailed information about what happened in the accident.                                         </p>
                                        </div>
                                    </li>
                                    <li>
                                        <div className="seemingly">
                                            <h5 className="Seek">
                                                Seek medical help immediately:{" "}
                                            </h5>
                                            <p>
                                                To ensure that injuries are treated promptly, it is vital for one to seek immediate medical attention from professionals. The solicitor may rely on such reports as crucial evidence when fighting for your fair compensation.                                            </p>
                                        </div>
                                    </li>
                                    <li>
                                        <div className="seemingly">
                                            <h5 className="Seek">Keep records of all financial losses:</h5>
                                            <p>
                                                For you to be compensated for your losses, ensure you keep receipts and write down all expenses resulting from the mishap.                                          </p>
                                        </div>
                                    </li>
                                    <li>
                                        <div className="seemingly">
                                            <h5 className="Seek">Don’t accept the first offer.:</h5>
                                            <p>
                                                It is advisable not to just agree with whatever is being offered without having talks with a solicitor. In most cases, initial settlement offers do not cover completely for damages suffered by individuals following accidents.                                 </p>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div
                            className="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-12"
                            id="dualcardiv"
                        >
                            <img src={dualcar} alt="" className="dualcar" />
                        </div>
                        <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-12">
                            <div className="Preparing">
                                <ul>
                                    <li>
                                        <div className="seemingly">
                                            <h5 className="Seek">Gather evidence:</h5>
                                            <p>
                                                Some important pieces of evidence from accident scenes include photographs, videos, eyewitness statements, as well as pictures showing damage to vehicles and personal injury marks.                            </p>
                                        </div>
                                    </li>
                                    <li>
                                        <div className="seemingly">
                                            <h5 className="Seek">Record eyewitnesses address: </h5>
                                            <p>
                                                Record the details of eyewitnesses so that they can be reached out later to testify about what they saw.                                             </p>
                                        </div>
                                    </li>
                                    <li>
                                        <div className="seemingly">
                                            <h5 className="Seek">
                                                Note down expenses :
                                            </h5>
                                            <p>
                                                You should keep receipts for all expenses incurred, such as repair services, hospital bills, or any other costs, to get compensated for such expenses.
                                            </p>
                                        </div>
                                    </li>
                                    <li>
                                        <div className="seemingly">
                                            <h5 className="Seek">
                                                Consult a no-fault solicitor :
                                            </h5>
                                            <p>
                                                Call our helpline number on{" "}
                                                <strong>{" "}
                                                    <a className="cpt-no" href="tel:0800 772 0850">
                                                        {" "}
                                                        0800 772 0850 {" "}
                                                    </a>{" "}
                                                </strong>
                                                for guidance on how to make a successful compensation claim.

                                            </p>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="whyus">
                <div className="container">
                    <div className="row">
                        <div className="col-xxl-12 col-md-12">
                            <div className="why">
                                <h2 className="choose">
                                    Starting your claim   {" "}
                                    <span className="light"> with us </span>
                                </h2>
                                <p className="complexities">
                                    If you choose to make personal injury claims in the UK with us, don’t worry about complex paperwork, long follow-up calls and unrequired waiting periods. To start a compensation claim for an accident, call
                                    <strong>
                                            {" "}
                                            <a href="tel:0800 772 0850">0800 772 0850</a>{" "}
                                        </strong> 
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-xxl-3 col-xl-6 col-lg-6 col-md-6 col-12">
                            <div className="box-service">
                                <img src={check} className="check" alt="" />
                                {/* <img src={experience} className="experience" alt="" /> */}
                                <h4>Stay connected to us </h4>
                                <p>
                                Call our dedicated team through the<strong> Oink insurance accident claim phone number </strong> for free advice that is without obligation to proceed. </p>
                            </div>
                        </div>
                        <div className="col-xxl-3 col-xl-6 col-lg-6 col-md-6 col-12">
                            <div className="box-service">
                                <img src={check} className="check" alt="" />
                                <h4>Provide details </h4>
                                <p>
                                Give details of the events leading to the accident, including the time, date and location of the motor vehicle mishap. </p>
                            </div>
                        </div>
                        <div className="col-xxl-3 col-xl-6 col-lg-6 col-md-6 col-12">
                            <div className="box-service">
                                <img src={check} className="check" alt="" />
                                <h4>Submit evidence</h4>
                                <p>
                                To ensure everything runs smoothly regarding the processing of your case, you will be requested to submit relevant documents or any crucial evidence, if available.                  </p>
                            </div>
                        </div>
                        <div className="col-xxl-3 col-xl-6 col-lg-6 col-md-6 col-12">
                            <div className="box-service">
                                <img src={check} className="check" alt="" />
                                <h4>Check progress </h4>
                                <p>
                                We will initiate the claims on your behalf and keep you informed about its progression. 
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="faqs">
                <div className="container">
                    <div className="row">
                        <div className="col-xxl-12 col-xl-12 col-md-12 col-12">
                            <div className="fq">
                                <h2>
                                    {" "}
                                    Frequently Asked{" "}
                                    <span className="light">Questions</span>
                                </h2>
                                <p className="complexities">
                                    Got questions about the <strong>  Oink car insurance claim? </strong> We have them answered here.
                                </p>
                            </div>
                            <div className="allfaq">
                                <Accordion defaultActiveKey="0">
                                    <Accordion.Item eventKey="0">
                                        <Accordion.Header>
                                        Should I accept the settlement offer made by the insurance company?                                     </Accordion.Header>
                                        <Accordion.Body>
                                        No, you should consult your solicitor before accepting the initial settlement offer. The first offer might only partly offset all accident-related costs and lowball settlements done by insurance companies to avoid prolonged claim proceedings. That’s why it is important to get legal advice from a reputable solicitor before accepting any such offers so as to know how much money you should be paid.                                   </Accordion.Body>
                                    </Accordion.Item>
                                    <Accordion.Item eventKey="1">
                                        <Accordion.Header>
                                        Can I get another car after my car accident?
                                        </Accordion.Header>
                                        <Accordion.Body>
                                        Certainly, you will be entitled to a temporary replacement vehicle in case of an accident involving your motor vehicle. Call our <strong>Oink insurance accident claim phone number </strong>  for a like-for-like replacement vehicle and return to the road again following this car accident.                                    </Accordion.Body>
                                    </Accordion.Item>
                                    <Accordion.Item eventKey="2">
                                        <Accordion.Header>
                                        Am I allowed to select where my car is repaired subsequent to my car collision?
                                        </Accordion.Header>
                                        <Accordion.Body>
                                        Sure. You are at liberty to choose where your vehicle will be repaired. Just give us the contacts of your preferred repair shop, and we shall organise repairs for your vehicle right from that facility that you have chosen.                                 </Accordion.Body>
                                    </Accordion.Item>
                                    <Accordion.Item eventKey="3">
                                        <Accordion.Header>
                                        How do I ensure my vehicle's repairs are guaranteed?
                                        </Accordion.Header>
                                        <Accordion.Body>
                                        Choose a repairer from our manufacturer-approved repair network to ensure that your vehicle is repaired to the highest quality standards. Our approved repairers provide high-quality and guaranteed vehicle repairs using genuine parts.                                 </Accordion.Body>
                                    </Accordion.Item>
                                    <Accordion.Item eventKey="4">
                                        <Accordion.Header>
                                        For how long can I keep the replacement car?

                                        </Accordion.Header>
                                        <Accordion.Body>
                                        It depends on how long it will take to fix your car. You should be able to retain the rental as long as your car is repaired, or you receive fair compensation for total loss.                                       </Accordion.Body>
                                    </Accordion.Item>
                                    <Accordion.Item eventKey="5">
                                        <Accordion.Header>
                                        Does it cost anything for me to use your accident management services? {" "}
                                        </Accordion.Header>
                                        <Accordion.Body>
                                        No, we won’t make you spend money after an accident. All costs associated with our crash management services shall be recouped from the insurer of another party involved in the incident.                                 </Accordion.Body>
                                    </Accordion.Item>
                                    <Accordion.Item eventKey="6">
                                        <Accordion.Header>
                                        Is there any chance that my insurance premium will go up if I make a no-fault claim? {" "}
                                        </Accordion.Header>
                                        <Accordion.Body>
                                        No, if you were not at fault during the accident, your insurance premium will remain constant. The no-fault claim against the guilty party will be filed.
                                        </Accordion.Body>
                                    </Accordion.Item>
                                </Accordion>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <Footer />
        </>
    );
};

export default Oinkinsurance;
