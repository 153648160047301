import React, { useRef } from "react";
import Accordion from "react-bootstrap/Accordion";
import check from "../assets/images/check.png";
import dualcar from "../../src/assets/images/dualcar.webp";
import homebnr from "../../src/assets/images/homebnr.webp";
import Sidebarinner from "../components/Sidebar-inner";
import phonecall from "../assets/images/phonecall.png";
import Footer from "../components/footer";
import { Helmet } from "react-helmet";

const Wiserchoice = () => {
    const sidebarRef = useRef(null);

    const handleScrollToSidebar = () => {
        if (sidebarRef.current) {
            sidebarRef.current.scrollIntoView({ behavior: "smooth" });
        }
    };
    return (
        <>
        <Helmet>
        <meta charSet="utf-8" />
        <title>Wiser Choice Car Insurance Accident Claim Phone Number</title>
        <meta
          name="description"
          content="Call us on the Wiser Choice Car Insurance Accident Claim Phone Number  0800 772 0850 to make a successful car accident claim and get maximum compensation for your losses. "
        />
        <link
          rel="canonical"
          href="https://insurance-accident-claims.co.uk/Wiserchoice"
        />
      </Helmet>
            <section className="innernav">
                <div className="container">
                    <div className="row">
                        <div className="col-md-6 col-sm-6">
                            <div className="left-logo">
                                <h3>
                                    Accident<span className="sipo"> Claims</span>
                                </h3>
                            </div>
                        </div>
                        <div className="col-md-6 col-sm-6">
                            <div className="left-cta">
                                <div class="headercnt">
                                    <div class="chatmsg">
                                        <img
                                            src={phonecall}
                                            className="phonecall"
                                            alt="phonecall "
                                        />
                                    </div>
                                    <div class="box-body">
                                        <h3 class="nav-info-box-title">Toll Free Number </h3>
                                        <p>
                                            <a href="tel:0800 772 0850">0800 772 0850</a>
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section
                className="newbnr"
                style={{ backgroundImage: "url(" + homebnr + ")" }}
            >
                <div className="container">
                    <div className="row">
                        <div className="col-xxl-7 col-xl-7 col-md-10 col-12">
                            <div className="innerbnr">
                                <h1>
                                    <span className="onespan">Fast and dependable accident  </span>{" "}
                                    <span className="sci">claims  </span>  assistance
                                </h1>
                                <p className="brokerage">
                                    We handle everything from initial assessment to finalising your claim so that you can
                                    be driving again as soon as possible.
                                </p>
                                <div className="bnrbtn">
                                    <a class="db-btn-border btn-rollover phone" href="tel:0800 772 0850">
                                        Road Accident Claim Help?{" "}
                                        <i class="fa-solid fa-arrow-right"></i>
                                    </a>
                                </div>
                                <div className="bnrbtn">
                                    <a className="db-btn-border btn-rollover desktop" onClick={handleScrollToSidebar}>
                                        Road Accident Claim Help? <i className="fa-solid fa-arrow-right"></i>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="stickysection">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="group-4-8">
                                <div className="cl8 tf-tab">
                                    <div className="content-tab">
                                        <div className="inner-content">
                                            <h2>
                                            The best claims management professionals
                                                
                                                <span className="light"> in the UK </span>{" "}
                                            </h2>
                                            <p className="disciplines">
                                            We are one of the United Kingdom’s main providers of <strong>
                                                    {" "}
                                                    Wiser Choice insurance claims 
                                                </strong>{" "}
                                                assistance, having dealt with thousands of cases successfully. To register a claim, contact us through the 
                                                <strong>
                                                    {" "}
                                                    Wiser Choice car insurance contact number.
                                                </strong>{" "}
                                                We will look after your <strong> Wiser Choice car insurance claim </strong>  at zero cost.
                                            </p>
                                            <hr />
                                            <p className="bold-text">Zero Excess</p>
                                            <p className="bottom-te">  You won’t have to pay any excesses on your insurance if you are not at fault for the accident, as we won’t initiate a claim against your own insurance policy.                             </p>
                                            <hr />
                                            <p className="bold-text">No-claims bonus protection</p>
                                            <p className="bottom-te">
                                            When it comes to claiming, you don’t worry about loss of non-claims bonus. We guarantee that your no-claims bonus will remain untouched when you claim with us.                                             </p>
                                            <hr />
                                            <p className="bold-text">
                                            Fast vehicle recovery
                                            </p>
                                            <p className="bottom-te">
                                            After a crash or collision, we don’t want you left helpless by the side of the road. Call right away for urgent help at <strong> Wiser Choice car insurance phone number, </strong>  and we will make sure that wherever your vehicle is stuck, it will be recovered safely and promptly every time.
                                            </p>
                                            <hr />
                                            <p className="bold-text">High-quality repairs  </p>
                                            <p className="bottom-te">
                                            Given the variety of car manufacturers with whom we have partnered, you can be sure that your vehicle will be repaired by us at the highest quality level.                                           </p>
                                            <hr />
                                            <p className="bold-text">Like-for-like replacement</p>
                                            <p className="bottom-te">
                                            While your car is being repaired or it has been rendered unfit for driving, we can provide you with a similar one through like-for-like hire.                                          </p>
                                            <hr />
                                            <p className="bold-text">Expert legal solicitors</p>
                                            <p className="bottom-te">
                                            Involving our competent solicitors in processing <strong> Wiser Choice claims  </strong> has led to awarding victims their full compensation due to their expertise in no-fault accident injury cases.

                                            </p>
                                            <hr />
                                            <p className="bold-text">Fast claim processing</p>
                                            <p className="bottom-te">
                                            We do not require much paperwork and long waits because our claim procedure is efficient and prompt. You can make a claim without having to deal with paperwork troubles.                                        </p>
                                            <hr />
                                            <p className="bold-text">Free independent advice</p>
                                            <p className="bottom-te">
                                            If you have any queries on <strong>Wiser Choice insurance claims,</strong> please feel free to call us on 
                                            <strong>
                                            {" "}
                                            <a href="tel:0800 772 0850">0800 772 0850</a>{" "}
                                        </strong> with no obligation attached to this. This way, our solicitors will advise and guide you throughout your case.
                                        </p>
                                            <hr />
                                            <p className="bold-text">No win-no fee</p>
                                            <p className="bottom-te">
                                            To handle our client’s insurance claims, our solicitors operate under a policy of “no-win-no-fee”. Therefore, if you lose your case, you will not pay anything.                               </p>
                                            <hr />

                                            <p className="bold-text">24/7 support</p>
                                            <p className="bottom-te">
                                            If anything occurs, we always remain around to help in emergencies. If you have been involved in a non-fault car accident and need immediate assistance, please call our car insurance claims line on
                                            <strong>
                                            {" "}
                                            <a href="tel:0800 772 0850">0800 772 0850</a>{" "}
                                        </strong>                                     </p>
                                        </div>
                                    </div>
                                </div>
                                <div className="cl4" ref={sidebarRef}>
                                    <Sidebarinner />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="photographs">
                <div className="container">
                    <div className="row">
                        <h2 className="road">
                        We shall assist with expert support to get   {" "}
                            <span className="light">
                              
                                you   <br />back on the road
                            </span>{" "}
                        </h2>
                        <div className="col-xxl-6 col-xl-6 col-md-12 col-12">
                            <div className="fourbox">
                                <ul>
                                    <li>
                                        <img src={check} className="check" alt="" />
                                        In case of an emergency following a car accident, reach out to us through the  <strong>Wiser Choice car insurance phone number. </strong> 

                                    </li>
                                    <hr />
                                    <li>
                                        <img src={check} className="check" alt="" />
                                        We will find out whether there is a third party at fault who can be pursued for compensation                                 </li>
                                    <hr />
                                    <li>
                                        <img src={check} className="check" alt="" />
                                        We will be of help to you when it comes to filling <strong> Wiser Choice insurance claims </strong> for you to get compensated for the losses. 
                                    </li>
                                    <hr />
                                    <li>
                                        <img src={check} className="check" alt="" />
                                        Our team will make the arrangements for the recovery of your vehicle from the scene of an accident and liaise with our network of approved repairers.                                </li>
                                </ul>
                            </div>
                        </div>
                        <div className="col-xxl-6 col-xl-6 col-md-12 col-12">
                            <div className="fourbox">
                                <ul>
                                    <li>
                                        <img src={check} className="check" alt="" />
                                        We will ask an independent engineer to assess the damage.                               </li>
                                    <hr />
                                    <li>
                                        <img src={check} className="check" alt="" />
                                        While your vehicle is being repaired, you will be provided with a hire car until your own one is ready to drive.                              </li>
                                    <hr />
                                    <li>
                                        <img src={check} className="check" alt="" />
                                        To let you know about what has been done so far, we regularly check on how the repair process is progressing.                                  </li>
                                    <hr />
                                    <li>
                                        <img src={check} className="check" alt="" />
                                        To find out more about claiming on your <strong>Wiser Choice insurance, </strong>  call us on
                                        <br></br>
                                        <strong>
                                            {" "}
                                            <a href="tel:0800 772 0850">0800 772 0850</a>{" "}
                                        </strong>                             </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="carprocess">
                <div className="container ">
                    <div className="row">
                        <div className="col-xxl-12 col-xxl-12 col-md-12 col-12">
                            <h2 className="trusted-title">
                                {" "}
                                Accident management service <br />
                                <span className="light"> we offer  </span>{" "}
                            </h2>
                        </div>
                    </div>
                    <div className="row" id="possible">
                        <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-12">
                            <div className="claim">
                                <ul>
                                    <li>
                                        <span className="sleej">Car recovery:</span> Call the <strong >Wiser Choice car insurance contact number </strong>  to have your vehicle recovered after an accident. Our vehicle recovery services ensure your vehicle is quickly taken from the scene of the accident and secured in our secure storage yards.

                                    </li>
                                    <li>
                                        <span className="sleej">Approved repairs:</span>If you need to repair your car, please reach our team through the <strong>  Wiser Choice insurance claim number </strong>With us, repairs for your car take place under a no excess cost policy at any garage within the UK that can be relied upon.

                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-12">
                            <div className="claim" id="blue">
                                <ul>
                                    <li>
                                        <span className="sleej">Vehicle storage:</span> Do you fear where to keep your vehicle following an accident caused by a different driver? Reach us via the <strong> Wiser Choice insurance claim number. </strong>  We have well-kept yards where we will store your car while claims are being managed.
                                    </li>
                                    <li>
                                        <span className="sleej">Replacement vehicles:</span>Your car will be replaced if it cannot be repaired. Dial the emergency helpline at 
                                        <strong>
                                            {" "}
                                            <a href="tel:0800 772 0850">0800 772 0850</a>{" "}
                                        </strong>
                                        and obtain a replacement vehicle the same as the one you had so that your day-to-day activities will run smoothly. It is possible to keep it until your claim is settled.
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-12">
                            <div className="claim">
                                <ul>
                                    <li>
                                        <span className="sleej">Injury claims assistance </span>
                                        The best way to determine if you should be receiving personal injury compensation or not is by calling the  <strong>  Wiser Choice insurance claim number. </strong>  Our solicitors will offer you priceless guidance on getting reparation for physical damage suffered by an individual.
                                        </li>
                                    <li>
                                        <span className="sleej">Claim management:</span>   This includes taking care of paperwork, negotiating with insurers, arranging car mending, and providing hire cars.
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="yellowcta">
                <div className="container">
                    <div className="row">
                        <div className="cta2">
                            <div className="col-xxl-8 col-xl-8 col-lg-8 col-12">
                                <div className="legal">
                                    <h3 className="experienced">
                                    Got more questions? Talk {" "}
                                        <span className="light"> to our specialists
                                        </span>{" "}
                                    </h3>
                                    <p className="professionals">
                                    Get in touch with our no-fault claim experts via 
                                        {" "}
                                        <strong> car insurance contact number{" "}
                                            <a className="cpt-no" href="tel:0800 772 0850">
                                                {" "}
                                                0800 772 0850{" "}
                                            </a>{" "}
                                        </strong>
                                        to get the best advice for the 
                                        <strong>Wiser Choice insurance claims.</strong>
                                    </p>
                                </div>
                            </div>
                            <div className="col-xxl-4 col-xl-4 col-lg-4 col-12">
                                <div class="process">
                                    <a class="tf-button style-1 phone" href="tel:0800 772 0850">
                                        Get in touch<span class="icon-keyboard_arrow_right"></span>
                                    </a>
                                </div>
                                <div class="process">
                                    <a className="tf-button style-1 desktop" onClick={handleScrollToSidebar}>
                                        Get in touch<span className="icon-keyboard_arrow_right"></span>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <search className="updatedform">
                <div className="container">
                    <div className="row text-center">
                        <div className="col-xxl-12 col-xl-12 col-md-12 col-12">
                            <h2 className="whiteheading">
                                {" "}
                                What sets us
                                <span className="light">  apart?
                                </span>
                            </h2>
                            <p className="sustained">
                            We prioritise ensuring that a non-fault road accident doesn’t become stressful for you. The following are some benefits of choosing us as your preferred claims handler.                   </p>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-xxl-4 col-xl-4 col-lg-6 col-md-12 col-12">
                            <div className="Claimant">
                                <h5> 24/7 vehicle recovery</h5>
                                <p className="collision">
                                Looking for vehicle recovery services? Just dial the  <strong> Wiser Choice insurance claim number, </strong>and we shall retrieve you from the scene, irrespective of what hour it may be.


                                </p>

                                <h5>Guaranteed repairs</h5>
                                <p className="collision">
                                We can facilitate the repair of your car through a wide range of high-quality and guaranteed repairs all over.                         </p>

                                <h5>No increase in insurance premium</h5>
                                <p className="collision">
                                    The  <strong>   Wiser Choice car insurance claim  </strong>   will not be made against your own insurance policy. It means that the rates you pay for your coverage will not go up.

                                </p>
                            </div>
                        </div>
                        <div className="col-xxl-4 col-xl-4 col-lg-6 col-md-12 col-12">
                            <div className="Claimant">
                                <h5>Free replacement car
                                </h5>
                                <p className="collision">
                                We would arrange for a like-for-like hire vehicle so that you can continue using your car while it is being repaired at no cost to you upfront. </p>
                                <h5>A network of reliable partners</h5>
                                <p className="collision">
                                To offer quality services, we have established an extensive network of approved repairers and competent solicitors across the United Kingdom.                        </p>

                                <h5>Fast claims settlement</h5>
                                <p className="collision">
                                As a result, our experienced solicitors negotiate with the other party’s insurer to ensure a fair and quick resolution of your  <strong>Wiser Choice motor insurance claim. </strong>   
                                </p>
                            </div>
                        </div>
                        <div className="col-xxl-4 col-xl-4 col-lg-12 col-md-12 col-12">
                            <div className="Claimant">
                                <h5>Immediate help</h5>
                                <p className="collision">
                                Our emergency response service guarantees immediate assistance after road accidents that were not caused by you without any delays whatsoever.                            </p>

                                <h5>Tailored assistance </h5>
                                <p className="collision">
                                Because we deal with such cases on a daily basis, we understand what it takes to provide personal attention. Therefore, our dedicated claims handler is responsible for handling your case directly and keeping you updated on every development along the way until completion.                    </p>

                                <h5>No excess charges</h5>
                                <p className="collision">
                                You don’t need to make extra payments or bear out-of-pocket expenses for our services. We will recover all costs from the other party.                           </p>
                            </div>
                        </div>
                    </div>
                </div>
            </search>

            <section className="Accident-claim">
                <div className="container">
                    <div className="row">
                        <div className="needed">
                            <h2 className="trusted-title">
                            What increases the likelihood of getting <br />
                                <span className="light"> enough compensation?</span>
                            </h2>
                            <p className="complexities">
                            It is upsetting to be in an accident that is not your fault, but it is even more exasperating dealing with claims. If you want a good chance of getting proper compensation, you will have to prove that it was not your fault by providing physical proof. Here are a few steps you should take after a no-fault car accident to maximise your compensation.                       </p>
                        </div>
                        <hr />
                    </div>
                    <div className="row" id="incdes">
                        <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-12">
                            <div className="Preparing">
                                <ul>
                                    <li>
                                        <div className="seemingly">
                                            <h5 className="Seek">Ensure your safety:</h5>
                                            <p>
                                                {" "}
                                                Park your vehicle in a safe place and check yourself and the other passengers for any serious or minor injurie.                                       </p>
                                        </div>
                                    </li>
                                    <li>
                                        <div className="seemingly">
                                            <h5 className="Seek">
                                            Get immediate medical attention:{" "}
                                            </h5>
                                            <p>
                                            Immediately call a professional doctor if there are any injured persons on board.                                         </p>
                                        </div>
                                    </li>
                                    <li>
                                        <div className="seemingly">
                                            <h5 className="Seek">Gather evidence:</h5>
                                            <p> Crucial evidence such as photos of where the accidents happened, who caused them, damages made, severe injuries and all drivers’ licence plate numbers should be collected from the accident scene.                                 </p>
                                        </div>
                                    </li>
                                    <li>
                                        <div className="seemingly">
                                            <h5 className="Seek">Notify police:</h5>
                                            <p>
                                            Notify the authorities about what just happened. You must also obtain a copy of the police report to make your claim stronger.                                            </p>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div
                            className="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-12"
                            id="dualcardiv"
                        >
                            <img src={dualcar} alt="" className="dualcar" />
                        </div>
                        <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-12">
                            <div className="Preparing">
                                <ul>
                                    <li>
                                        <div className="seemingly">
                                            <h5 className="Seek">Exchange information:</h5>
                                            <p>
                                            In case possible, swap details with the other parties involved in the accident, including full names, phone number addresses, vehicle details and insurance information                                 </p>
                                        </div>
                                    </li>
                                    <li>
                                        <div className="seemingly">
                                            <h5 className="Seek">Collect the witness’s information: </h5>
                                            <p>
                                            Capturing the details of the witnesses for future reference can also help you reach out to them to get vital details of the accident.                                           </p>
                                        </div>
                                    </li>
                                    <li>
                                        <div className="seemingly">
                                            <h5 className="Seek">
                                            List down the costs:
                                            </h5>
                                            <p>
                                            Retain all receipts for repair, medical and other expenses to ensure you get reimbursed to recover all fees spent.
                                            </p>
                                        </div>
                                    </li>
                                    <li>
                                        <div className="seemingly">
                                            <h5 className="Seek">
                                            Contact a no-fault solicitor  :
                                            </h5>
                                            <p>
                                            To find out what to do about a claim of compensation under our no-fault car accident, call us on {" "}
                                                <strong>{" "}
                                                    <a className="cpt-no" href="tel:0800 772 0850">
                                                        {" "}
                                                        0800 772 0850 {" "}
                                                    </a>{" "}
                                                </strong>
                                            </p>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="whyus">
                <div className="container">
                    <div className="row">
                        <div className="col-xxl-12 col-md-12">
                            <div className="why">
                                <h2 className="choose">
                                Would you like to submit a no-fault claim? {" "}
                                    <span className="light">Start immediately </span>
                                </h2>
                                <p className="complexities">
                                It can be scary when you have a road traffic accident. However, with our simplified and streamlined claims process, you can make the  <strong> Wiser Choice car insurance claim</strong>   easily and without hassle.                            </p>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-xxl-3 col-xl-6 col-lg-6 col-md-6 col-12">
                            <div className="box-service">
                                <img src={check} className="check" alt="" />
                                {/* <img src={experience} className="experience" alt="" /> */}
                                <h4>Get in touch with us </h4>
                                <p>
                                Call our team on the Wiser Choice car insurance contact number to discuss your claim. </p>
                            </div>
                        </div>
                        <div className="col-xxl-3 col-xl-6 col-lg-6 col-md-6 col-12">
                            <div className="box-service">
                                <img src={check} className="check" alt="" />
                                <h4>Provide accident details </h4>
                                <p>
                                Please inform us of all that happened in the accident, including its date, location, time and manner of occurrence.</p>
                            </div>
                        </div>
                        <div className="col-xxl-3 col-xl-6 col-lg-6 col-md-6 col-12">
                            <div className="box-service">
                                <img src={check} className="check" alt="" />
                                <h4>Give supporting documents</h4>
                                <p>
                                Provide evidence to support your claim, such as pictures of where it occurred, eyewitness statements, police reports, and CCTV videos.                              </p>
                            </div>
                        </div>
                        <div className="col-xxl-3 col-xl-6 col-lg-6 col-md-6 col-12">
                            <div className="box-service">
                                <img src={check} className="check" alt="" />
                                <h4>Claims processing</h4>
                                <p>
                                The claim will be initiated by us after assessment. Moving forward, our team will keep you posted about the progress.

                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="faqs">
                <div className="container">
                    <div className="row">
                        <div className="col-xxl-12 col-xl-12 col-md-12 col-12">
                            <div className="fq">
                                <h2>
                                    {" "}
                                    Frequently Asked{" "}
                                    <span className="light">Questions</span>
                                </h2>
                                <p className="complexities">
                                Got questions about the <strong> Wiser Choice insurance claims?</strong>  We have them answered here.

                                </p>
                            </div>
                            <div className="allfaq">
                                <Accordion defaultActiveKey="0">
                                    <Accordion.Item eventKey="0">
                                        <Accordion.Header>
                                        Will I be charged extra for a replacement vehicle?                                    </Accordion.Header>
                                        <Accordion.Body>
                                        No, all costs of replacing your vehicle are billed to the at-fault party’s insurer. You’ll only have to pay if you do not cooperate with us, use a solicitor without our knowledge or provide misleading information about your case.                      </Accordion.Body>
                                    </Accordion.Item>
                                    <Accordion.Item eventKey="1">
                                        <Accordion.Header>
                                        How long does it take a claim to come to fruition?
                                        </Accordion.Header>
                                        <Accordion.Body>
                                        We will try our best to conclude your <strong>  Wiser Choice car insurance claim </strong>    in the shortest time possible. However, the length of time it takes to settle varies depending on the particulars of your case. As soon as we have made a claim on your behalf, we shall notify you of any changes that occur.
                                        </Accordion.Body>
                                    </Accordion.Item>
                                    <Accordion.Item eventKey="2">
                                        <Accordion.Header>
                                        Does the at-fault party’s insurance pay for the recovery of vehicles?
                                        </Accordion.Header>
                                        <Accordion.Body>
                                        Yes. The other party’s insurance company is liable to cater for all expenses, car recovery, vehicle repairs and replacement cars. You must show that you deserve compensation by presenting evidence such as a police report or photographs taken at the scene and surrounding areas where the incident occurred              </Accordion.Body>
                                    </Accordion.Item>
                                    <Accordion.Item eventKey="3">
                                        <Accordion.Header>
                                        Can I get a replacement car after a no-fault accident involving my vehicle?
                                        </Accordion.Header>
                                        <Accordion.Body>
                                        Yes, if you are not at fault, you will be entitled to a replacement car. In case your car is declared unroadworthy or under repair, please contact us through the <strong> Wiser Choice insurance claim number. </strong>  We’ll give you another car of your own until yours gets back.
                                        </Accordion.Body>
                                    </Accordion.Item>
                                    <Accordion.Item eventKey="4">
                                        <Accordion.Header>
                                        Will I lose any of my no-claim bonuses if I am not at fault?

                                        </Accordion.Header>
                                        <Accordion.Body>
                                        If you submit your claim through us, we will not make a claim against your own insurance policy, and you will not risk losing any years’ worth of bonuses or discounts that have been accumulated.                            </Accordion.Body>
                                    </Accordion.Item>
                                    <Accordion.Item eventKey="5">
                                        <Accordion.Header>
                                        Can I use my own garage to fix my car?{" "}
                                        </Accordion.Header>
                                        <Accordion.Body>
                                        Definitely! We may arrange for your car to be fixed at the place that you choose. We’ll arrange for a repairer who will meet only the highest quality standards and do fast repairs.                     </Accordion.Body>
                                    </Accordion.Item>
                                    <Accordion.Item eventKey="6">
                                        <Accordion.Header>
                                        What type of vehicle can replace mine?{" "}
                                        </Accordion.Header>
                                        <Accordion.Body>
                                        We can provide a replacement car similar in size, value as well as function to your own vehicle. Simply contact us on our  <strong> Wiser Choice insurance claim number </strong> and inform us about your requirements. Thereafter we will avail one suitable for you.

                                        </Accordion.Body>
                                    </Accordion.Item>
                                </Accordion>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <Footer />
        </>
    );
};

export default Wiserchoice;
