import React, { useEffect, useState } from "react";
import phonecall from "../../../src/assets/images/phonecall.png";

Header2.propTypes = {};

function Header2({ clname = "", handleMobile }) {
  const [activeIndex, setActiveIndex] = useState(null);
  const handleDropdown = (index) => {
    setActiveIndex(index);
  };

  const [scroll, setScroll] = useState(0);

  useEffect(() => {
    document.addEventListener("scroll", () => {
      const scrollCheck = window.scrollY > 100;
      if (scrollCheck !== scroll) {
        setScroll(scrollCheck);
      }
    });
  }, []);

  return (
    <header
      id="header"
      className="header header-default headercnt"
    >
      <div className="container">
        <div className="row">
          <div className="col-md-12">
            <div className="sticky-area-wrap">
              <div className="header-ct-left">
                <div id="logo" className="logo">
                {/* <img src={insurancelogo} className='insurancelogo' alt='Insurance Logo' /> */}
                <h3>Accident Claims</h3>
                </div>
            
              </div>
              <div className="header-ct-center">
                <div className="nav-wrap">
                  <nav id="main-nav" className="main-nav">
                    <ul id="menu-primary-menu" className={`menu ${clname}`}>
                    <li>
                    <a href="/">Home</a>
                             </li>
                  <li>
                <a href="#about">About</a>
                   </li>
                         
                <li>
                <a href="#contact">Contact</a>
                 
                </li>
                <li>
                <a href="/Blog">Blog</a> 
                </li>
        
                     
                    </ul>
                  </nav>
                </div>
              </div>
              <div className="header-ct-right">
              <div class="headercnt">
          <div class="chatmsg">
          <img src={phonecall} className="phonecall" alt="phonecall " />
          </div>
          <div class="box-body">
          <h3 class="nav-info-box-title">24/7 Helpline</h3>
          <p><a href="tel:0800 772 0850">0800 772 0850</a>
          </p>
          </div>
          </div>
              
              </div>
              <div className="nav-filter" onClick={handleMobile}>
                <div className="nav-mobile">
                  <span></span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </header>
  );
}

export default Header2;
