import React, { useRef } from "react";
import Accordion from "react-bootstrap/Accordion";
import check from "../assets/images/check.png";
import dualcar from "../../src/assets/images/dualcar.webp";
import bner from "../../src/assets/images/bner.webp";
import Sidebarinner from "../components/Sidebar-inner";
import phonecall from "../assets/images/phonecall.png";
import Footer from "../components/footer";
import { Helmet } from "react-helmet";

const Budgetinsurance = () => {
    const sidebarRef = useRef(null);

    const handleScrollToSidebar = () => {
        if (sidebarRef.current) {
            sidebarRef.current.scrollIntoView({ behavior: "smooth" });
        }
    };
    return (
        <>
         <Helmet>
        <meta charSet="utf-8" />
        <title>Budget Car Insurance Accident Claim Phone Number</title>
        <meta
          name="description"
          content="Do you want quick assistance and support for your no-fault claim? Call the Budget Car Insurance Accident Claim Phone Number 0800 772 0850 for expert claim management. "
        />
        <link
          rel="canonical"
          href="https://insurance-accident-claims.co.uk/Budget-insurance "
        />
      </Helmet>
            <section className="innernav">
                <div className="container">
                    <div className="row">
                        <div className="col-md-6 col-sm-6">
                            <div className="left-logo">
                                <h3>
                                    Accident<span className="sipo"> Claims</span>
                                </h3>
                            </div>
                        </div>
                        <div className="col-md-6 col-sm-6">
                            <div className="left-cta">
                                <div class="headercnt">
                                    <div class="chatmsg">
                                        <img
                                            src={phonecall}
                                            className="phonecall"
                                            alt="phonecall "
                                        />
                                    </div>
                                    <div class="box-body">
                                        <h3 class="nav-info-box-title">Toll Free Number </h3>
                                        <p>
                                            <a href="tel:0800 772 0850">0800 772 0850</a>
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section
                className="newbnr"
                style={{ backgroundImage: "url(" + bner + ")" }}
            >
                <div className="container">
                    <div className="row">
                        <div className="col-xxl-7 col-xl-7 col-md-10 col-12">
                            <div className="innerbnr">
                                <h1>
                                    <span className="onespan">No-fault insurance   </span>{" "}
                                    <span className="sci">claims </span> management 
                                </h1>
                                <p className="brokerage">
                                From arranging vehicle recovery and repairs to organising temporary replacement vehicles and providing professional claims assistance, we’re your trusted partners on your journey to recovery. 

                                </p>
                                <div className="bnrbtn">
                                    <a class="db-btn-border btn-rollover phone" href="tel:0800 772 0850">
                                        Road Accident Claim Help?{" "}
                                        <i class="fa-solid fa-arrow-right"></i>
                                    </a>
                                </div>
                                <div className="bnrbtn">
                                    <a className="db-btn-border btn-rollover desktop" onClick={handleScrollToSidebar}>
                                        Road Accident Claim Help? <i className="fa-solid fa-arrow-right"></i>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="stickysection">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="group-4-8">
                                <div className="cl8 tf-tab">
                                    <div className="content-tab">
                                        <div className="inner-content">
                                            <h2>
                                            Your reliable partner in road
                                                <br />
                                                <span className="light"> traffic accidents</span>{" "}
                                            </h2>
                                            <p className="disciplines">
                                            We’re professional no-fault claim experts in the UK, committed to helping the victims involved in a car accident. Our dedicated team is always on hand to provide comprehensive accident support services to ensure a smooth and hassle-free experience for the victims following a car accident. Call us on our  insurance claims number  <strong>
                                            {" "}
                                            <a href="tel:0800 772 0850">0800 772 0850</a>{" "}
                                        </strong>to make a successful accident claim.      
                                            </p>
                                            <hr />
                                            <p className="bold-text">Avoid paying any insurance excess</p>
                                            <p className="bottom-te">
                                            We will ensure your own insurance policy is left untouched, saving you from paying insurance fees.                                        </p>
                                            <hr />
                                            <p className="bold-text">Manufacturer-approved repairs </p>
                                            <p className="bottom-te">
                                            When you contact us on <strong> Budget insurance Accident claim number </strong>  for vehicle repairs after the accident, we’ll ensure your vehicle repairs adhere to the manufacturer’s standards and specifications.                                           </p>
                                            <hr />
                                            <p className="bold-text">
                                            Comparable replacement vehicle 
                                            </p>
                                            <p className="bottom-te">
                                            If your car is damaged due to the accident, we’ll replace it with an equivalent car of similar size, make, model and specifications.                                       </p>
                                            <hr />
                                            <p className="bold-text">Fast-track claim processing </p>
                                            <p className="bottom-te">
                                            With our smooth and simplified claim process, you can rest assured your <strong> Budget car insurance claim </strong>  will get settled in minimum time.                                          </p>
                                            <hr />
                                            <p className="bold-text">Professional claims handler  </p>
                                            <p className="bottom-te">
                                            Once you make your <strong> Budget car insurance claim </strong>  with us, we’ll allocate you a dedicated and experienced claims handler so that you have a single point of contact at every stage of the claims process.                                           </p>
                                            <hr />
                                            <p className="bold-text">Experienced and qualified claims team </p>
                                            <p className="bottom-te">
                                            If you need to make a claim, please call our claims team on the <strong>  Budget insurance claim phone number.</strong> Our team will work tirelessly to achieve the best outcome for your claim.                                   </p>
                                            <hr />
                                            <p className="bold-text">No change to your no claims bonus</p>
                                            <p className="bottom-te">
                                            We avoid making a claim against your own insurance policy, safeguarding your hard-earned discounts and no-claims bonus.                                             </p>
                                            <hr />
                                            <p className="bold-text">24/7 accident recovery </p>
                                            <p className="bottom-te">
                                            No matter what time the accident happens, call us on the <strong> Budget insurance accident claim phone number, </strong> and we’ll dispatch our team right away to recover your vehicle from the accident scene.                                           </p>
                                            <hr />
                                            <p className="bold-text">Expert support  basis</p>
                                            <p className="bottom-te">
                                               Our experienced panel of solicitors in the UK will provide professional advice and guidance throughout your claim process to ensure you get fair compensation. 
                                         </p>
                                            <hr />

                                            <p className="bold-text">No obligation advice </p>
                                            <p className="bottom-te">
                                            We provide access to expert advice and a free initial consultation to help you understand your rights and options to pursue a claim against the at-fault party.                               </p>
                                        </div>
                                    </div>
                                </div>
                                <div className="cl4" ref={sidebarRef}>
                                    <Sidebarinner />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="photographs">
                <div className="container">
                    <div className="row">
                        <h2 className="road">
                        Dedicated insurance accident support   {" "}
                            <span className="light">
                                from <br />
                                collision to recovery
                            </span>{" "}
                        </h2>
                        <div className="col-xxl-6 col-xl-6 col-md-12 col-12">
                            <div className="fourbox">
                                <ul>
                                    <li>
                                        <img src={check} className="check" alt="" />
                                        Get in touch with our team via <strong> Budget insurance accident claim number. </strong>  Our professional team will find the best solution to recover your vehicle and move it to safety. 

                                    </li>
                                    <hr />
                                    <li>
                                        <img src={check} className="check" alt="" />
                                        From vehicle recovery to handing your car keys back to you, we have you covered throughout every step post your car accident.                                </li>
                                    <hr />
                                    <li>
                                        <img src={check} className="check" alt="" />
                                        In case your vehicle is undrivable, we’ll get you back on the road with a like-for-like replacement vehicle.                                  </li>
                                    <hr />
                                    <li>
                                        <img src={check} className="check" alt="" />
                                        We’ll ensure your vehicle is restored to its pre-accident condition by arranging repairs from a manufacturer-approved repairer.                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div className="col-xxl-6 col-xl-6 col-md-12 col-12">
                            <div className="fourbox">
                                <ul>
                                    <li>
                                        <img src={check} className="check" alt="" />
                                        Our storage facilities are available to store your vehicle throughout the process with no worries about costs.                               </li>
                                    <hr />
                                    <li>
                                        <img src={check} className="check" alt="" />
                                        When you report the accident via <strong> Budget insurance accident claim number, </strong>  we’ll ensure you get the best legal representation for your case.                                 </li>
                                    <hr />
                                    <li>
                                        <img src={check} className="check" alt="" />
                                        We will handle the complicated process of communicating with the parties involved in the accident, saving you from unwanted stress.                                      </li>
                                    <hr />
                                    <li>
                                        <img src={check} className="check" alt="" />
                                        Once your vehicle repairs are complete, we'll deliver your car to your doorstep at an appropriate time.                     </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="carprocess">
                <div className="container ">
                    <div className="row">
                        <div className="col-xxl-12 col-xxl-12 col-md-12 col-12">
                            <h2 className="trusted-title">
                                {" "}
                                Our wide array of  <br />
                                <span className="light">  services </span>{" "}
                            </h2>
                        </div>
                    </div>
                    <div className="row" id="possible">
                        <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-12">
                            <div className="claim">
                                <ul>
                                    <li>
                                        <span className="sleej">Vehicle recovery :</span>  Using our expert recovery agents, we’ll ensure quick recovery of your vehicle from the accident scene to our safe storage facilities. Contact us on the <strong> Budget insurance claims number </strong>  for your vehicle recovery needs. 
                                    </li>
                                    <li>
                                        <span className="sleej">Accidental repairs :</span>   With a vast network of manufacturer-approved repairers in the UK, we’ll arrange for your vehicle repairs to be carried out at the nearest garage or at the repair centre of your choice.
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-12">
                            <div className="claim" id="blue">
                                <ul>
                                    <li>
                                        <span className="sleej">Safe storage :</span>  Leaving your vehicle on the roadside after a car accident is not safe. So, until your claim is assessed, we’ll store your vehicle in our well-maintained storage yards, ensuring its protection from theft, vandalism and damage. 
                                    </li>
                                    <li>
                                        <span className="sleej">Replacement car :</span>  Contact our team via the Budget insurance claim phone number if your vehicle is a write-off or is inoperable due to the accident. We’ll provide you with a like-for-like replacement vehicle for the duration of your vehicle repairs or until your claim is settled. 
                                        
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-12">
                            <div className="claim">
                                <ul>
                                    <li>
                                        <span className="sleej">Personal injury assistance  :</span>
                                        Seeking compensation for the injuries sustained due to the accident? Get in touch with us on the <strong> Budget insurance accident claim phone number </strong> to receive expert guidance from our solicitors to maximise your compensation.               </li>
                                    <li>
                                        <span className="sleej">Claims management  :</span>   From the initiation of your claim to its final settlement, we’ll provide dedicated support and assistance from start to finish. Contact us on the <strong> Budget insurance claims number </strong>  to begin your claim process. 


                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="yellowcta">
                <div className="container">
                    <div className="row">
                        <div className="cta2">
                            <div className="col-xxl-8 col-xl-8 col-lg-8 col-12">
                                <div className="legal">
                                    <h3 className="experienced">
                                    Got more questions? Talk to  {" "}
                                        <span className="light">   our specialists 
                                        </span>{" "}
                                    </h3>
                                    <p className="professionals">
                                    Get in touch with our no-fault claim experts via <strong> insurance claims number </strong> <strong>
                                            {" "}
                                            <a href="tel:0800 772 0850">0800 772 0850</a>{" "}
                                        </strong> to get the best advice for your <strong>Budget car insurance claim. </strong>
                                    </p>
                                </div>
                            </div>
                            <div className="col-xxl-4 col-xl-4 col-lg-4 col-12">
                                <div class="process">
                                    <a class="tf-button style-1 phone" href="tel:0800 772 0850">
                                        Get in touch<span class="icon-keyboard_arrow_right"></span>
                                    </a>
                                </div>
                                <div class="process">
                                    <a className="tf-button style-1 desktop" onClick={handleScrollToSidebar}>
                                        Get in touch<span className="icon-keyboard_arrow_right"></span>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <search className="updatedform">
                <div className="container">
                    <div className="row text-center">
                        <div className="col-xxl-12 col-xl-12 col-md-12 col-12">
                            <h2 className="whiteheading">
                                {" "}
                                Why choose 
                                <span className="light">  us?

                                </span>
                            </h2>
                            <p className="sustained">
                            Car accidents can be traumatic, causing stress and financial burden. However, if the accident is not your fault, we’ll protect you from its consequences. From recovery to claim management, we’ll provide comprehensive support without costing you a single penny. Call us on the <strong> Budget insurance accident claim number </strong>  to assess our accident support services.                         </p>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-xxl-4 col-xl-4 col-lg-6 col-md-12 col-12">
                            <div className="Claimant">
                                <h5>No cost </h5>
                                <p className="collision">
                                Our services won’t cost you thousands of pounds. We’ll recover all the costs from the other party’s insurer. 


                                </p>

                                <h5>Vast repair network </h5>
                                <p className="collision">
                                With us, you’re guaranteed to receive high-quality repairs for your vehicle that exceed expectations.                              </p>

                                <h5>Reliable assistance </h5>
                                <p className="collision">
                                By resolving your <strong> Budget car insurance claim </strong>  in a timely and professional manner, we provide dedicated and reliable assistance.                                 </p>
                            </div>
                        </div>
                        <div className="col-xxl-4 col-xl-4 col-lg-6 col-md-12 col-12">
                            <div className="Claimant">
                                <h5>Secure vehicle storage 
                                </h5>
                                <p className="collision">
                                Our vehicle storage solutions provide safety and security to your vehicle following a no-fault car accident.  </p>
                                <h5>Customer-centric approach </h5>
                                <p className="collision">
                                Contact us on the <strong>  Budget insurance accident Claim number </strong> to get free, personalised advice from our experienced solicitors based on the circumstances of your case                           </p>

                                <h5>Efficient claims processing</h5>
                                <p className="collision">
                                Our claims team efficiently processes your <strong> Budget car insurance claim </strong> to ensure on-time resolution. Moreover, we’ll keep you informed about your claims progress every step of the way
                                </p>
                            </div>
                        </div>
                        <div className="col-xxl-4 col-xl-4 col-lg-12 col-md-12 col-12">
                            <div className="Claimant">
                                <h5>Uninsured loss recovery </h5>
                                <p className="collision">
                                Have you incurred out-of-pocket expenses due to the accident? We’ll help you recover all the costs from the other party’s insurer.             </p>

                                <h5>Hassle-free vehicle replacement </h5>
                                <p className="collision">
                                Our services provide you with a suitable replacement vehicle to minimise inconvenience and help you get back to your daily routine.                     </p>

                                <h5>End-to-end claims service  </h5>
                                <p className="collision">
                                From arranging vehicle recovery to managing your claim from start to finish, we provide dedicated support and assistance at every stage of your claims journey.                            </p>
                            </div>
                        </div>
                    </div>
                </div>
            </search>

            <section className="Accident-claim">
                <div className="container">
                    <div className="row">
                        <div className="needed">
                            <h2 className="trusted-title">
                            How to increase the value of<br />
                                <span className="light">  your claim? </span>
                            </h2>
                            <p className="complexities">
                            Wondering how you can maximise the settlement value of your <strong> Budget car insurance claim? </strong>  By taking the right steps after the car accident, you can increase the chances of getting maximum compensation for your <strong> Budget car insurance claim. </strong> 
                            </p>
                        </div>
                        <hr />
                    </div>
                    <div className="row" id="incdes">
                        <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-12">
                            <div className="Preparing">
                                <ul>
                                    <li>
                                        <div className="seemingly">
                                            <h5 className="Seek">Report car accident :</h5>
                                            <p>
                                                {" "}
                                                Call the police and report the car accident within 24 hours. Also, be sure to collect a copy of the police report containing detailed information about the accident.                                      </p>
                                        </div>
                                    </li>
                                    <li>
                                        <div className="seemingly">
                                            <h5 className="Seek">
                                            Seek medical attention :{" "}
                                            </h5>
                                            <p>
                                            It’s important to get immediate medical attention from a professional to get your injuries treated on time. Your solicitor may use the medical reports as crucial evidence to secure fair compensation for you.                                          </p>
                                        </div>
                                    </li>
                                    <li>
                                        <div className="seemingly">
                                            <h5 className="Seek">Record all financial losses :</h5>
                                            <p>
                                            If you want compensation for all your losses, you must file all receipts and write down all expenses incurred due to the accident.                                           </p>
                                        </div>
                                    </li>
                                    <li>
                                        <div className="seemingly">
                                            <h5 className="Seek">Don’t accept the first offer :</h5>
                                            <p>
                                            Never accept the initial settlement offer without consulting with your solicitor. The first settlement offer may not compensate you completely for damages and losses you’ve suffered.                              </p>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div
                            className="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-12"
                            id="dualcardiv"
                        >
                            <img src={dualcar} alt="" className="dualcar" />
                        </div>
                        <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-12">
                            <div className="Preparing">
                                <ul>
                                    <li>
                                        <div className="seemingly">
                                            <h5 className="Seek">Collect evidence:</h5>
                                            <p>
                                            Gather crucial evidence from the accident scene, including photographs, videos, eyewitness statements, and pictures of the damaged vehicles and your injury marks.                        </p>
                                        </div>
                                    </li>
                                    <li>
                                        <div className="seemingly">
                                            <h5 className="Seek">Exchange details : </h5>
                                            <p>
                                            To ensure smooth processing of your <strong> Budget car insurance claim,</strong> exchange details with the other driver involved in the accident, such as your name, contact information, vehicle details and insurance information.                                           </p>
                                        </div>
                                    </li>
                                    <li>
                                        <div className="seemingly">
                                            <h5 className="Seek">
                                            Talk to a no-fault claim expert  :
                                            </h5>
                                            <p>
                                            Get in touch with us via the<strong> Budget insurance claim phone number </strong>  to talk to our accident claim specialists to discuss your claim. 
                                            </p>
                                        </div>
                                    </li>
                                    <li>
                                        <div className="seemingly">
                                            <h5 className="Seek">
                                            File your claim as soon as possible :
                                            </h5>
                                            <p>
                                            Don’t wait for too long to file your claim. Call us on the Budget insurance accident claim phone number to start your claim process as soon as possible. 

                                            </p>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="whyus">
                <div className="container">
                    <div className="row">
                        <div className="col-xxl-12 col-md-12">
                            <div className="why">
                                <h2 className="choose">
                                How to start your claim {" "}
                                    <span className="light"> with us </span>
                                </h2>
                                <p className="complexities">
                                When you choose to make your claim with us, you don’t have to go through complicated paperwork procedures, lengthy follow-up calls or unnecessary waiting periods. Call us on  
                                    <strong>
                                            {" "}
                                            <a href="tel:0800 772 0850">0800 772 0850</a>{" "}
                                        </strong>  to initiate your accident claim. 
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-xxl-3 col-xl-6 col-lg-6 col-md-6 col-12">
                            <div className="box-service">
                                <img src={check} className="check" alt="" />
                                {/* <img src={experience} className="experience" alt="" /> */}
                                <h4>Get in touch </h4>
                                <p>
                                Contact our dedicated team via the <strong> Budget insurance accident claim phone number </strong>  for free no-obligation advice.  </p>
                            </div>
                        </div>
                        <div className="col-xxl-3 col-xl-6 col-lg-6 col-md-6 col-12">
                            <div className="box-service">
                                <img src={check} className="check" alt="" />
                                <h4>Discuss accident details  </h4>
                                <p>
                                Describe the sequence of events leading to the accident and provide information about the date, time and location of the car accident. </p>
                            </div>
                        </div>
                        <div className="col-xxl-3 col-xl-6 col-lg-6 col-md-6 col-12">
                            <div className="box-service">
                                <img src={check} className="check" alt="" />
                                <h4>Share documents </h4>
                                <p>
                                Hand over essential documents and any crucial evidence you’ve collected to ensure the smooth processing of your claim.      </p>
                            </div>
                        </div>
                        <div className="col-xxl-3 col-xl-6 col-lg-6 col-md-6 col-12">
                            <div className="box-service">
                                <img src={check} className="check" alt="" />
                                <h4>Follow up  </h4>
                                <p>
                                Once we have initiated your claim, we’ll stay in touch with you to keep you informed about its status. 
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="faqs">
                <div className="container">
                    <div className="row">
                        <div className="col-xxl-12 col-xl-12 col-md-12 col-12">
                            <div className="fq">
                                <h2>
                                    {" "}
                                    Frequently Asked{" "}
                                    <span className="light">Questions</span>
                                </h2>
                                <p className="complexities">
                                    Got questions about the <strong>  Budget car insurance claim?  </strong> We have them answered here.
                                </p>
                            </div>
                            <div className="allfaq">
                                <Accordion defaultActiveKey="0">
                                    <Accordion.Item eventKey="0">
                                        <Accordion.Header>
                                        Should I accept the insurance company’s settlement offer?                                  </Accordion.Header>
                                        <Accordion.Body>
                                        No, you should never accept the initial settlement offer without consulting your solicitor. An initial offer may not fully cover all of your accident-related costs, and insurance companies tend to give lowball settlement offers to settle the claim early. So, it’s advisable to speak to an experienced solicitor before accepting any such offer and to determine the fair amount of compensation you deserve.                                </Accordion.Body>
                                    </Accordion.Item>
                                    <Accordion.Item eventKey="1">
                                        <Accordion.Header>
                                        Am I entitled to a replacement car after the car accident?
                                        </Accordion.Header>
                                        <Accordion.Body>
                                        Yes. You’re entitled to a temporary replacement vehicle following a car accident. Call us on the <strong> Budget insurance Accident claim Phone number </strong>  for a like-for-like replacement vehicle to hit the road again post-car accident.                                    </Accordion.Body>
                                    </Accordion.Item>
                                    <Accordion.Item eventKey="2">
                                        <Accordion.Header>
                                        Can I choose where to get my vehicle repaired after the car accident?
                                        </Accordion.Header>
                                        <Accordion.Body>
                                        Yes. We offer you the freedom of choice to select your preferred garage or repair centre for your vehicle repairs. You can provide us with the contact information of your preferred repair centre, and we’ll promptly arrange your vehicle repairs from your chosen body shop.                                </Accordion.Body>
                                    </Accordion.Item>
                                    <Accordion.Item eventKey="3">
                                        <Accordion.Header>
                                        How can I ensure my vehicle repairs are guaranteed?
                                        </Accordion.Header>
                                        <Accordion.Body>
                                        If you want to ensure your vehicle gets repaired to the highest quality standards, we advise you to choose the repairer from our manufacturer-approved repair network.  Our approved repairers provide high-quality and guaranteed vehicle repairs using genuine parts                             </Accordion.Body>
                                    </Accordion.Item>
                                    <Accordion.Item eventKey="4">
                                        <Accordion.Header>
                                        How long can I keep the replacement car?

                                        </Accordion.Header>
                                        <Accordion.Body>
                                        The duration of the replacement vehicle depends on your repair timeline. You can keep the replacement vehicle until your car is repaired, or you receive fair compensation for total loss.                                  </Accordion.Body>
                                    </Accordion.Item>
                                    <Accordion.Item eventKey="5">
                                        <Accordion.Header>
                                        Is there a cost for using your accident management services?{" "}
                                        </Accordion.Header>
                                        <Accordion.Body>
                                        No. We won’t put you into financial risk after the car accident. We’ll recover all the costs related to our accident management services from the other party’s insurer.                        </Accordion.Body>
                                    </Accordion.Item>
                                    <Accordion.Item eventKey="6">
                                        <Accordion.Header>
                                        Will my insurance premium increase if I make a no-fault accident claim?  {" "}
                                        </Accordion.Header>
                                        <Accordion.Body>
                                        No. Your insurance premium won’t increase if the accident is not your fault. The no-fault accident claim will be made against the at-fault party. 
                                        </Accordion.Body>
                                    </Accordion.Item>
                                </Accordion>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <Footer />
        </>
    );
};

export default Budgetinsurance;
