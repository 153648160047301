import React from 'react';
import PropTypes from 'prop-types';
import insurancelogowhite from "../../assets/images/insurancelogowhite.png";
import { Link } from 'react-router-dom';



function Footer(props) {
    return (
        <footer className="footer" id='contact'>
              <div className="inner-footer">
                <div className="tf-container">
                <div className='row' id='logoRow1'>
                    <div className='col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-12'>
                {/* <img src={insurancelogo} className='insurancelogo' alt='Insurance Logo' /> */}
                <h3 class="wh">Accident Claims</h3>
                 <p className='handle'>We’re your all-time-trusted partner for the best no-fault accident management solutions. </p>
                    </div>
                    </div>
                    <div className='row'>
                  <div className='col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-12'>
                  <div className='assistance'>
                     <h3>Location</h3>
                     <div className="textarea"> 71-75 Shelton Street, London UK WC2H 9JQ</div>
                        </div>
                    </div>
                    <div className='col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-12'>
                  <div className='assistance'>
                     <h3>Contact Us</h3>
                     <div className="textarea"><a href='tel:0800 772 0850'>0800 772 0850</a> </div>
                        </div>
                    </div>
                    <div className='col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-12'>
                  <div className='assistance'>
                     <h3>Email Us</h3>
                     <div className="textarea"><a href='mailto:info@insurance-accident-claims.co.uk'>info@insurance-accident-claims.co.uk</a> </div>
                        </div>
                    </div>
                    </div>
                    <div className='row text-center' id='bottom-row'>
                      <div className='col-xxl-6 col-xl-6 col-lg-6 col-md-7 col-12'>
                      <div className="copyright">©2024 <span className='copytext'>insurance-accident-claims</span>. All Rights Reserved.</div>
                         </div>
                         <div className='col-xxl-6 col-xl-6 col-lg-6 col-md-5 col-12'>
                      <ul className='privac'>
                        <li><a href='/Privacy-policy'>Privacy Policy</a></li>
                        <li><a href='/Termsandconditions'>Terms & Conditions</a></li>
                      </ul>
                         </div>
                      </div>        
                </div>
                </div>
                
            </footer>
           
    );
}

export default Footer;