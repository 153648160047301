import React, { useRef } from "react";
import Accordion from "react-bootstrap/Accordion";
import check from "../assets/images/check.png";
import dualcar from "../../src/assets/images/dualcar.webp";
import carbnr1 from "../../src/assets/images/carbnr1.webp";
import Sidebarinner from "../components/Sidebar-inner";
import phonecall from "../assets/images/phonecall.png";
import Footer from "../components/footer";
import { Helmet } from "react-helmet";

const Insure2drive = () => {
  const sidebarRef = useRef(null);

  const handleScrollToSidebar = () => {
    if (sidebarRef.current) {
      sidebarRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Insure 2 Drive Car Insurance Accident Claim Phone Number:</title>
        <meta
          name="description"
          content="Seeking reliable claims assistance and support after a car accident in the UK? Contact the Insure 2 Drive Car Insurance accident claim phone number  0800 772 0850. "
        />
        <link
          rel="canonical"
          href="https://insurance-accident-claims.co.uk/Insure2drive"
        />
      </Helmet>
      <section className="innernav">
        <div className="container">
          <div className="row">
            <div className="col-md-6 col-sm-6">
              <div className="left-logo">
                <h3>
                  Accident<span className="sipo"> Claims</span>
                </h3>
              </div>
            </div>
            <div className="col-md-6 col-sm-6">
              <div className="left-cta">
                <div class="headercnt">
                  <div class="chatmsg">
                    <img
                      src={phonecall}
                      className="phonecall"
                      alt="phonecall "
                    />
                  </div>
                  <div class="box-body">
                    <h3 class="nav-info-box-title">Toll Free Number </h3>
                    <p>
                      <a href="tel:0800 772 0850">0800 772 0850</a>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section
        className="newbnr"
        style={{ backgroundImage: "url(" + carbnr1 + ")" }}
      >
        <div className="container">
          <div className="row">
            <div className="col-xxl-8 col-xl-8 col-md-10 col-12">
              <div className="innerbnr">
                <h1>
                  <span className="onespan">No-fault</span>{" "}
                  <span className="sci">car accident </span>claims experts
                </h1>
                <p className="brokerage">
                  From organising the car’s recovery to arranging its repairs
                  and seeing you through your claim as a liaison officer, we
                  relieve you of the headache that comes with being responsible
                  for a non-fault car accident.
                </p>
                <div className="bnrbtn">
                  <a
                    class="db-btn-border btn-rollover phone"
                    href="tel:0800 772 0850"
                  >
                    Road Accident Claim Help?{" "}
                    <i class="fa-solid fa-arrow-right"></i>
                  </a>
                </div>
                <div className="bnrbtn">
                  <a
                    className="db-btn-border btn-rollover desktop"
                    onClick={handleScrollToSidebar}
                  >
                    Road Accident Claim Help?{" "}
                    <i className="fa-solid fa-arrow-right"></i>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="stickysection">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="group-4-8">
                <div className="cl8 tf-tab">
                  <div className="content-tab">
                    <div className="inner-content">
                      <h2>
                        Comprehensive claims management
                        <br />
                        <span className="light">with no excess </span>{" "}
                      </h2>
                      <p className="disciplines">
                        Getting involved in a no-fault car accident can be quite
                        overwhelming and stressful. Nevertheless, we’re here to
                        reduce the stress and uncertainty associated with going
                        it alone on an{" "}
                        <strong> Insure 2 Drive car insurance claim. </strong>{" "}
                        We provide a vast range of accident management services,
                        from fixing damages to getting temporary vehicles
                        without putting extra charges on your shoulders.
                      </p>
                      <hr />
                      <p className="bold-text">Excess free services</p>
                      <p className="bottom-te">
                        When you make your{" "}
                        <strong> Insure 2 Drive auto insurance claim </strong>{" "}
                        with us, there is no excess to pay. We will protect you
                        from excess payments as we will claim directly against
                        the other party’s insurer.{" "}
                      </p>
                      <hr />
                      <p className="bold-text">Approved repairs</p>
                      <p className="bottom-te">
                        Only approved by manufacturers, and repairers who use
                        genuine parts and repaints are allowed to fix your
                        vehicle.
                      </p>
                      <hr />
                      <p className="bold-text">Unparalleled expertise</p>
                      <p className="bottom-te">
                        We specialise in protecting the victims of a no-fault
                        road traffic accident. Our solicitors work tirelessly to
                        represent your best interests and help you secure
                        maximum compensation for your{" "}
                        <strong>Insure 2 Drive car insurance claim. </strong>
                      </p>
                      <hr />
                      <p className="bold-text">No-win, no-fee basis </p>
                      <p className="bottom-te">
                        Solicitors of ours operate in terms of no-win, no-fee.
                        If you do not get through with your{" "}
                        <strong>Insure 2 Drive car accident claim,</strong>
                        you will not be responsible for any legal expenses.{" "}
                      </p>
                      <hr />
                      <p className="bold-text">Equivalence replacement</p>
                      <p className="bottom-te">
                        As your vehicle is being repaired, we shall bring you an
                        equivalent replacement vehicle so that you can get back
                        on the road as quickly as possible.
                      </p>
                      <hr />
                      <p className="bold-text">No effect on no-claim bonus</p>
                      <p className="bottom-te">
                        Your premiums will never rise and your no-claims bonus
                        will not be lost since we will make a claim directly
                        against the other party. To make a claim with us, dial{" "}
                        <strong>
                          {" "}
                          Insure 2 Drive car insurance claim number{" "}
                        </strong>
                        now.
                      </p>
                      <hr />
                      <p className="bold-text">
                        {" "}
                        An experienced claims handler{" "}
                      </p>
                      <p className="bottom-te">
                        When making a claim, using the{" "}
                        <strong>
                          {" "}
                          Insure 2 Drive auto insurance claims phone number,{" "}
                        </strong>
                        you will have an dedicated and experienced claims
                        handler assigned to handle your claim.
                      </p>
                      <hr />
                      <p className="bold-text">Expert claims management</p>
                      <p className="bottom-te">
                        We would direct you to our team of qualified solicitors
                        who will walk side by side with you during all the
                        stages of the{" "}
                        <strong>Insure 2 Drive auto insurance claims </strong>
                        process.{" "}
                      </p>
                      <hr />
                      <p className="bold-text">Tailored advice</p>
                      <p className="bottom-te">
                        You’ll get tailored advice according to your case
                        circumstances from our panel of solicitors.{" "}
                      </p>
                      <hr />

                      <p className="bold-text">Advice without any obligation</p>
                      <p className="bottom-te">
                        Call us on{" "}
                        <strong>
                          {" "}
                          the Insure 2 Drive auto insurance claims phone number{" "}
                        </strong>{" "}
                        for free legal advice where you will be guided by one of
                        our solicitors who will also tell you about your rights
                        and the avenues available to pursue your claim.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="cl4" ref={sidebarRef}>
                  <Sidebarinner />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="photographs">
        <div className="container">
          <div className="row">
            <h2 className="road">
              We take away the troubles associated{" "}
              <span className="light">
                with <br />
                post-accident issues.
              </span>{" "}
            </h2>
            <div className="col-xxl-6 col-xl-6 col-md-12 col-12">
              <div className="fourbox">
                <ul>
                  <li>
                    <img src={check} className="check" alt="" />
                    Our car insurance claim number is{" "}
                    <strong>
                      {" "}
                      <a href="tel:0800 772 0850">0800 772 0850</a>.
                    </strong>{" "}
                    When you talk to us about a car accident we shall ask for
                    information concerning it and give you quick assistance to
                    enable you to go back behind the wheel once more. for
                    compensation.
                  </li>
                  <hr />
                  <li>
                    <img src={check} className="check" alt="" />
                    In case it is impossible to drive your vehicle, we will
                    substitute it with a vehicle of its kind so that you can
                    carry on with your day-to-day activities without any
                    problem.
                  </li>
                  <hr />
                  <li>
                    <img src={check} className="check" alt="" />
                    An independent engineer will be arranged for us to do an
                    evaluation of the damage to your vehicle.
                  </li>
                  <hr />
                  <li>
                    <img src={check} className="check" alt="" />
                    Your chosen auto repair centre will be contacted by us in
                    order to plan when and how your car can be fixed back to its
                    initial state after an accident.
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-xxl-6 col-xl-6 col-md-12 col-12">
              <div className="fourbox">
                <ul>
                  <li>
                    <img src={check} className="check" alt="" />
                    We would mediate between all parties involved and bargain
                    with the at-fault party’s insurer for a reasonable
                    settlement amount in relation to the{" "}
                    <strong> Insure 2 Drive car accident claim.</strong>
                  </li>
                  <hr />
                  <li>
                    <img src={check} className="check" alt="" />
                    Repairs need constant communication until they are done.
                    That's why we will always keep in touch with the repairer
                    and update you about what is taking place until the
                    completion of repairs.
                  </li>
                  <hr />
                  <li>
                    <img src={check} className="check" alt="" />
                    Once completed, a repaired vehicle will be brought while
                    taking back another one which was used during the
                    replacement period.
                  </li>
                  <hr />
                  <li>
                    <img src={check} className="check" alt="" />A specialist
                    claim manager, a professional who specifically handles the
                    intricacies of your{" "}
                    <strong> Insure 2 Drive auto insurance claim, </strong> will
                    be assigned to manage your claim.
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="carprocess">
        <div className="container ">
          <div className="row">
            <div className="col-xxl-12 col-xxl-12 col-md-12 col-12">
              <h2 className="trusted-title">
                {" "}
                Accident management services
                <br />
                <span className="light">we offer</span>{" "}
              </h2>
            </div>
          </div>
          <div className="row" id="possible">
            <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-12">
              <div className="claim">
                <ul>
                  <li>
                    <span className="sleej">Vehicle recovery:</span>Call us on
                    the{" "}
                    <strong> Insure 2 Drive car insurance claim number </strong>{" "}
                    for recovery of your damaged car after being involved in an
                    accident. The accident recovery team will be there
                    immediately at the accident site and secure it in a safe
                    place.
                  </li>
                  <li>
                    <span className="sleej">Accidental repairs:</span>Our
                    vehicle repair services mainly focus on fixing your vehicle
                    within the shortest time possible. We shall make sure that
                    you are repaired by manufacturer-approved repairers who will
                    guarantee quality work so that you can get back on the road
                    quickly.
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-12">
              <div className="claim" id="blue">
                <ul>
                  <li>
                    <span className="sleej">Safe storage:</span> Want to keep
                    your vehicle safe after a car crash? We have storage yards
                    where you can park your damaged vehicle. When your car
                    cannot move or if you think it is not safe to leave it
                    parked along the roadside, simply just contact our team
                    through{" "}
                    <strong>
                      {" "}
                      insurance claims phone number{" "}
                      <a href="tel:0800 772 0850">0800 772 0850 </a>{" "}
                    </strong>
                    where we shall take possession of the car and store it for
                    you absolutely free of charge at any one of our storage
                    yards nationwide.
                  </li>
                  <li>
                    <span className="sleej">
                      Like-for-like replacement vehicle:
                    </span>
                    In case of a no-fault accident, UK drivers can avail
                    themselves of temporary substitute vehicles to facilitate
                    their return to the road. We will supply you with a
                    replacement vehicle that is identical in every way to your
                    own vehicle.
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-12">
              <div className="claim">
                <ul>
                  <li>
                    <span className="sleej">
                      Personal injury claim support :
                    </span>
                    Did you suffer any physical injuries following the car
                    accident? Contact us via
                    <strong>
                      {" "}
                      the Insure 2 Drive car insurance claims phone number.{" "}
                    </strong>
                    Our solicitors will take you through the process for the
                    best result on the
                    <strong> Insure 2 Drive car insurance claim. </strong>
                  </li>
                  <li>
                    <span className="sleej">Claim management:</span>We will
                    handle all aspects of an{" "}
                    <strong> Insure 2 Drive auto insurance claim </strong>right
                    from when you report the accident until your claim is
                    completely sorted out.
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="yellowcta">
        <div className="container">
          <div className="row">
            <div className="cta2">
              <div className="col-xxl-8 col-xl-8 col-lg-8 col-12">
                <div className="legal">
                  <h3 className="experienced">
                    Got more questions?{" "}
                    <span className="light">Talk to our specialists </span>{" "}
                  </h3>
                  <p className="professionals">
                    Get in touch with our no-fault claim experts via{" "}
                    <strong>
                      car insurance claims number{" "}
                      <a className="cpt-no" href="tel:0800 772 0850">
                        {" "}
                        0800 772 0850{" "}
                      </a>{" "}
                    </strong>
                    to get the best advice for your{" "}
                    <strong> Insure 2 Drive auto claim. </strong>
                  </p>
                </div>
              </div>
              <div className="col-xxl-4 col-xl-4 col-lg-4 col-12">
                <div class="process">
                  <a class="tf-button style-1 phone" href="tel:0800 772 0850">
                    Get in touch<span class="icon-keyboard_arrow_right"></span>
                  </a>
                </div>
                <div class="process">
                  <a
                    className="tf-button style-1 desktop"
                    onClick={handleScrollToSidebar}
                  >
                    Get in touch
                    <span className="icon-keyboard_arrow_right"></span>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <search className="updatedform">
        <div className="container">
          <div className="row text-center">
            <div className="col-xxl-12 col-xl-12 col-md-12 col-12">
              <h2 className="whiteheading">
                {" "}
                Why are we your one-stop destination for the <br />
                <span className="light">
                  best accident management solutions?{" "}
                </span>
              </h2>
              <p className="sustained">
                We are professionals in no-fault claims who offer resolute
                support to reduce stress levels and worries when managing{" "}
                <strong> Insure 2 Drive auto insurance claims. </strong>From
                navigating through the intricacies of a claim to providing
                inclusive assistance and guidance, our purpose is to render
                holistic support at each stage.
              </p>
            </div>
          </div>
          <div className="row">
            <div className="col-xxl-4 col-xl-4 col-lg-6 col-md-12 col-12">
              <div className="Claimant">
                <h5> Guaranteed repairs </h5>
                <p className="collision">
                  We will begin your repair process as soon as possible from
                  manufacturer-approved repairers who specialise in guaranteed
                  repairs that ensure quality.
                </p>

                <h5>Skillful handling of claims</h5>
                <p className="collision">
                  Our solicitors will evaluate{" "}
                  <strong> Insure 2 Drive auto insurance claims </strong>while
                  coordinating with multiple participants in an accident to
                  facilitate fast determination.{" "}
                </p>

                <h5> Immediate roadside help </h5>
                <p className="collision">
                  When you contact us through the{" "}
                  <strong> Insure 2 Drive car insurance claim number, </strong>{" "}
                  we will send our rescue team to assist you promptly so that
                  both you and your vehicle can be taken out of harm’s way.
                </p>
              </div>
            </div>
            <div className="col-xxl-4 col-xl-4 col-lg-6 col-md-12 col-12">
              <div className="Claimant">
                <h5> Free legal consultation </h5>
                <p className="collision">
                  You can receive free legal advice from experienced solicitors
                  specialised in non-fault road accident compensation claims,
                  who will guide you on how to make an{" "}
                  <strong> Insure 2 Drive car accident claim </strong> and what
                  rights or options you have.
                </p>
                <h5> Extensive experience </h5>
                <p className="collision">
                  No matter how entangled your case may appear, our solicitors
                  boast vast experience advising and guiding clients towards
                  securing the best possible outcome for their{" "}
                  <strong> Insure 2 Drive auto claim. </strong>
                </p>

                <h5> Same-day replacement vehicle </h5>
                <p className="collision">
                  On the same day, we will give you an alternative vehicle that
                  suits your needs and preferences so as to save you from any
                  inconvenience.{" "}
                </p>
              </div>
            </div>
            <div className="col-xxl-4 col-xl-4 col-lg-12 col-md-12 col-12">
              <div className="Claimant">
                <h5> All-inclusive services </h5>
                <p className="collision">
                  From car towing all through to fixing and handling{" "}
                  <strong> Insure 2 Drive car insurance claims, </strong> we
                  have got everything covered for you.
                </p>

                <h5> No payment upfront </h5>
                <p className="collision">
                  The at-fault insurer will reimburse all costs related to
                  repairs, replacement vehicle or any other expenses incurred.
                  This means that no initial payments are required from you.
                </p>

                <h5>Round-the-clock support</h5>
                <p className="collision">
                  Our team is always there for emergency roadside assistance.
                  Reach us on the{" "}
                  <strong> Insure 2 Drive car insurance claim number </strong>{" "}
                  to get undiluted care when it is required.
                </p>
              </div>
            </div>
          </div>
        </div>
      </search>

      <section className="Accident-claim">
        <div className="container">
          <div className="row">
            <div className="needed">
              <h2 className="trusted-title">
                How can one maximise compensation on an <br />
                <span className="light">
                  {" "}
                  Insure 2 Drive auto insurance claim?
                </span>
              </h2>
              <p className="complexities">
                Should you suffer a non-fault traffic accident, then you can be
                entitled to damages and injuries awarded by a third party.
                However, it’s up to the victim of a no-fault car accident to
                maximise the amount of compensation for your{" "}
                <strong> Insure 2 Drive auto insurance </strong> claim. Here are
                the steps you should take to get the most out of your claim.{" "}
              </p>
            </div>
            <hr />
          </div>
          <div className="row" id="incdes">
            <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-12">
              <div className="Preparing">
                <ul>
                  <li>
                    <div className="seemingly">
                      <h5 className="Seek">Seek medical assistance:</h5>
                      <p>
                        {" "}
                        You should visit the nearest doctor immediately to have
                        your injuries treated. The{" "}
                        <strong> Insure 2 Drive car accident claim </strong>will
                        be bolstered by a strong medical report.
                      </p>
                    </div>
                  </li>
                  <li>
                    <div className="seemingly">
                      <h5 className="Seek">Report the incident: </h5>
                      <p>
                        To begin with, call the police as soon as possible and
                        inform them about the vehicle accident you were involved
                        in. In connection with this, the police report can be
                        used to give credence to your case.
                      </p>
                    </div>
                  </li>
                  <li>
                    <div className="seemingly">
                      <h5 className="Seek">Gather evidence:</h5>
                      <p>
                        Pictures of the accident scene, vehicles involved,
                        injuries sustained and CCTV video clips will serve as
                        evidence that support{" "}
                        <strong> Insure 2 Drive car insurance claims.</strong>
                      </p>
                    </div>
                  </li>
                  <li>
                    <div className="seemingly">
                      <h5 className="Seek">Record financial losses</h5>
                      <p>
                        This involves keeping records of all expenses incurred
                        in conjunction with the accident, such as medical bills,
                        transport costs, court fees as well as loss on earnings,
                        including benefits or any compensation whatsoever.
                      </p>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
            <div
              className="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-12"
              id="dualcardiv"
            >
              <img src={dualcar} alt="" className="dualcar" />
            </div>
            <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-12">
              <div className="Preparing">
                <ul>
                  <li>
                    <div className="seemingly">
                      <h5 className="Seek">Don’t use social media sites:</h5>
                      <p>
                        During the course of your{" "}
                        <strong> Insure 2 Drive auto insurance claim </strong>
                        period don’t share anything on social media because the
                        other party’s solicitors may try to distort your
                        statements and postings, reducing the amount you are
                        going to receive from them.
                      </p>
                    </div>
                  </li>
                  <li>
                    <div className="seemingly">
                      <h5 className="Seek"> Call witnesses </h5>
                      <p>
                        Take their contacts and record what they say concerning
                        the car crash so that you can contact them later if the
                        need arises in relation to helping make an{" "}
                        <strong> Insure 2 Drive car insurance claim </strong>{" "}
                        stronger.
                      </p>
                    </div>
                  </li>
                  <li>
                    <div className="seemingly">
                      <h5 className="Seek">Find a no-fault solicitor:</h5>
                      <p>
                        Please contact us using our{" "}
                        <strong>
                          {" "}
                          Insure 2 Drive auto insurance claims number{" "}
                        </strong>{" "}
                        so we can provide you with an experienced solicitor who
                        will look into your accident closely besides help get
                        high settlements.{" "}
                      </p>
                    </div>
                  </li>
                  <li>
                    <div className="seemingly">
                      <h5 className="Seek">Start your claim </h5>
                      <p>
                        Contact{" "}
                        <strong>
                          {" "}
                          Insure 2 Drive auto insurance claims phone number{" "}
                        </strong>{" "}
                        when you are ready to file your claim as soon as you
                        have all the documents and evidence at hand.
                      </p>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="whyus">
        <div className="container">
          <div className="row">
            <div className="col-xxl-12 col-md-12">
              <div className="why">
                <h2 className="choose">
                  Making your Insure 2 Drive auto insurance claim{" "}
                  <span className="light">with us takes just 4 steps </span>
                </h2>
                <p className="complexities">
                  Our claims process is easy and straightforward. It's noiseless
                  with no huge paperwork headaches that result in{" "}
                  <strong> Insure 2 Drive auto insurance claim </strong>frauds.
                  In order to begin your claim process, reach us through{" "}
                  <strong>Insure 2 Drive car insurance claim number.</strong>{" "}
                </p>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-xxl-3 col-xl-6 col-lg-6 col-md-6 col-12">
              <div className="box-service">
                <img src={check} className="check" alt="" />
                {/* <img src={experience} className="experience" alt="" /> */}
                <h4>Get connected </h4>
                <p>
                  Call our office at the{" "}
                  <strong>
                    {" "}
                    Insure 2 Drive auto insurance claims phone number{" "}
                  </strong>{" "}
                  for a free initial consultation appointment about your case.
                </p>
              </div>
            </div>
            <div className="col-xxl-3 col-xl-6 col-lg-6 col-md-6 col-12">
              <div className="box-service">
                <img src={check} className="check" alt="" />
                <h4>Give details</h4>
                <p>
                  Share as much information on the accident as possible,
                  including when it happened, who was involved, and any injuries
                  sustained.
                </p>
              </div>
            </div>
            <div className="col-xxl-3 col-xl-6 col-lg-6 col-md-6 col-12">
              <div className="box-service">
                <img src={check} className="check" alt="" />
                <h4>Documentation readiness</h4>
                <p>
                  Submit the evidence you’ve collected from the scene of the
                  accident together with other relevant papers on which to base
                  your claim.{" "}
                </p>
              </div>
            </div>
            <div className="col-xxl-3 col-xl-6 col-lg-6 col-md-6 col-12">
              <div className="box-service">
                <img src={check} className="check" alt="" />
                <h4>Initiate a Claim</h4>
                <p>
                  We will register your{" "}
                  <strong> Insure 2 Drive car accident claim </strong>and inform
                  you periodically about how far we’ve gone with it.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="faqs">
        <div className="container">
          <div className="row">
            <div className="col-xxl-12 col-xl-12 col-md-12 col-12">
              <div className="fq">
                <h2>
                  {" "}
                  Frequently Asked{" "}
                  <span className="light">Questions (FAQ)</span>
                </h2>
                <p className="complexities">
                  Got questions about the{" "}
                  <strong> Insure 2 Drive car insurance claim? </strong>We have
                  them answered here.
                </p>
              </div>
              <div className="allfaq">
                <Accordion defaultActiveKey="0">
                  <Accordion.Item eventKey="0">
                    <Accordion.Header>
                      How soon will my claim for the Insure 2 Drive car accident
                      be handled?
                    </Accordion.Header>
                    <Accordion.Body>
                      All claims are unique. This means that some cases may take
                      longer to process than others. It could take a long time
                      to handle complex claims, but simple ones can be worked
                      out in a few days. For that reason, we have our dedicated
                      team who will keep you posted on the progress of your{" "}
                      <strong> Insure 2 Drive car accident claim. </strong>
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="1">
                    <Accordion.Header>
                      Will genuine repair and replacement parts be used to
                      repair my damaged vehicle?
                    </Accordion.Header>
                    <Accordion.Body>
                      We work with an extensive UK-wide network of approved
                      repairers who use genuine parts and paint. Therefore, it
                      is guaranteed that your car will be mended using original
                      parts authorised by the manufacturer.
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="2">
                    <Accordion.Header>
                      How long can I drive the replacement vehicle if mine is a
                      total loss?
                    </Accordion.Header>
                    <Accordion.Body>
                      In the event your vehicle gets written off, we shall give
                      you another one and you can retain it until the full
                      valuation of your claim.
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="3">
                    <Accordion.Header>
                      Is it possible for my selected garage to fix my car back
                      into shape?{" "}
                    </Accordion.Header>
                    <Accordion.Body>
                      Of course! Simply provide us with information about your
                      repairs, and we shall reach out regarding repairs for your
                      car.
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="4">
                    <Accordion.Header>
                      Is it necessary to report the accident to the police?
                    </Accordion.Header>
                    <Accordion.Body>
                      Yes. You must immediately call the police and report the
                      car accident following a no-fault car accident. The police
                      report may contain important details about the accident
                      that will make your claim stronger.
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="5">
                    <Accordion.Header>
                      Who will pay for your accident management services?{" "}
                    </Accordion.Header>
                    <Accordion.Body>
                      The at-fault party’s insurance company will pay back every
                      cent you spend on a no-fault car accident. They are
                      required by law to take care of all your costs arising
                      from an accident.
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="6">
                    <Accordion.Header>
                      Will I lose my no-claims bonus?{" "}
                    </Accordion.Header>
                    <Accordion.Body>
                      In case of an unproven allegation, if your insurer did not
                      pay any money out of pocket, you should be glad because
                      your no-claims bonus is intact. Therefore, you will not
                      suffer from losing premiums or forfeiting discounts earned
                      through hard work.
                    </Accordion.Body>
                  </Accordion.Item>
                </Accordion>
              </div>
            </div>
          </div>
        </div>
      </section>

      <Footer />
    </>
  );
};

export default Insure2drive;
