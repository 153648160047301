import React, { useRef } from "react";
import insurancelogo from "../../src/assets/images/insurancelogo.png";
import Accordion from 'react-bootstrap/Accordion';
import check from "../assets/images/check.png";
import dualcar from "../../src/assets/images/dualcar.webp";
import carbnr2 from "../../src/assets/images/carbnr2.webp";
import Sidebarinner from "../components/Sidebar-inner";
import phonecall from "../assets/images/phonecall.png";
import { Helmet } from "react-helmet";
import Footer from "../components/footer";

const Hiscox = () => {
  const sidebarRef = useRef(null);

  const handleScrollToSidebar = () => {
    if (sidebarRef.current) {
      sidebarRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };
  return (
    <>
      <Helmet>

        <meta charSet="utf-8" />
        <title>Hiscox Car Insurance Accident Claim Phone Number </title>
        <meta name="description" content=" Need a free initial consultation for your no-fault accident claim? Call us on the Hiscox Car Insurance Accident Claim Phone Number 0800 772 0850 for free, no-obligation advice. " />
        <link rel="canonical" href="https://insurance-accident-claims.co.uk/Hiscox " />
      </Helmet>
      <section className="innernav">
        <div className="container">
          <div className="row">
            <div className="col-md-6 col-sm-6">
              <div className="left-logo">
                {/* <img src={insurancelogo} className='insurancelogo' alt='Insurance Logo' /> */}
                <h3>Accident Claims</h3>
              </div>
            </div>
            <div className="col-md-6 col-sm-6">
              <div className="left-cta">
                <div class="headercnt">
                  <div class="chatmsg">
                    <img src={phonecall} className="phonecall" alt="phonecall " />
                  </div>
                  <div class="box-body">
                    <h3 class="nav-info-box-title">Toll Free Number </h3>
                    <p><a href="tel:0800 772 0850">0800 772 0850</a>
                    </p>
                  </div>
                </div>
              </div>


            </div>
          </div>
        </div>
      </section>
      <section className="newbnr" style={{ backgroundImage: "url(" + carbnr2 + ")" }}>
        <div className="container">
          <div className="row">
            <div className="col-xxl-8 col-xl-8 col-md-10 col-12">
              <div className="innerbnr">
                <h1><span className="onespan">Dedicated no-fault </span> 
                <span className="sci">accident</span> Insurance claims number  </h1>
                <p className="brokerage">From vehicle recovery to comprehensive claims assistance, we have you covered for all your accident management needs. </p>
                <div className="bnrbtn">
                  <a class="db-btn-border btn-rollover phone" href="tel:0800 772 0850">
                    Begin your claim{" "}
                    <i class="fa-solid fa-arrow-right"></i>
                  </a>
                </div>
                <div className="bnrbtn">
                  <a className="db-btn-border btn-rollover desktop" onClick={handleScrollToSidebar}>
                    Begin your claim <i className="fa-solid fa-arrow-right"></i>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="stickysection">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="group-4-8">

                <div className="cl8 tf-tab">
                  <div className="content-tab">
                    <div className="inner-content">

                      <h2>Expert claims management <br /><span className="light">without any excess</span> </h2>
                      <p className="disciplines">
                        We are a team of professionals in the UK, specialising in helping the victims of no-fault car accidents to get back on the road. From arranging vehicle recovery to scheduling repairs, providing a replacement car and managing your <strong>Hiscox car insurance claim </strong>from start to end, you can count on us for dedicated support without worrying about excess payment.
                      </p>
                      <hr />
                      <p className="bold-text">No insurance excess  </p>
                      <p className="bottom-te">
                        We will make the <strong>Hiscox car insurance claim </strong>against the negligent party. So, your insurance premiums won’t be affected, and you’ll not have to pay any insurance excess.
                      </p>
                      <hr />
                      <p className="bold-text">No effect on no-claims bonus </p>
                      <p className="bottom-te">
                        When you make your claim with us, your no-claims bonus that you’ve accumulated over time won’t be affected.
                      </p>
                      <hr />
                      <p className="bold-text">High-quality vehicle repairs</p>
                      <p className="bottom-te">
                        We will schedule your vehicle repairs with a trusted repairer from our manufacturer-approved network.
                      </p>
                      <hr />
                      <p className="bold-text">Comparable replacement  </p>
                      <p className="bottom-te">
                        Want to get back on the road as soon as possible after the car accident? Call us on the car insurance claim phone number<a href="tel:0800 772 0850"> 0800 772 0850</a>. We will arrange a like-for-like replacement vehicle to help you carry on your routine activities.
                      </p>
                      <hr />
                      <p className="bold-text">Fast-paced claim process </p>
                      <p className="bottom-te">
                        We have streamlined our claims process to ensure your <strong>Hiscox car insurance claim </strong>gets settled in minimal time. Contact us on the <strong>Hiscox car insurance claim </strong>number to make your claim with us.
                      </p>
                      <hr />
                      <p className="bold-text">24/7 availability </p>
                      <p className="bottom-te">
                        Our professional team is available round the clock to provide emergency roadside assistance, helping you smoothen your path to recovery.
                      </p>
                      <hr />
                      <p className="bold-text">Uninsured loss recovery  </p>
                      <p className="bottom-te">
                        Our experienced panel of solicitors have extensive experience in securing maximum compensation for no-fault claimants. Get in touch with us via <strong>Hiscox insurance claim Number </strong>to ensure quick recovery of all the expenses incurred by you due to the accident.
                      </p>
                      <hr />
                      <p className="bold-text">Free legal assistance</p>
                      <p className="bottom-te">
                        Our solicitors specialise in no-fault claims. So, you’re assured of getting the best advice and assistance based on your personalised circumstances. </p>
                      <hr />
                      <p className="bold-text">Damage assessment  </p>
                      <p className="bottom-te">
                        We will arrange for an independent engineer to determine the extent of damages you’ve suffered.
                      </p>
                      <hr />

                      <p className="bold-text">No obligation advice </p>
                      <p className="bottom-te">
                        Get in touch with us via <strong>the Hiscox car accident claim phone</strong> number to get free no-obligation advice to understand your rights and options to make a successful claim.
                      </p>
                    </div>

                  </div>
                </div>
                <div className="cl4" ref={sidebarRef}>
                  <Sidebarinner />
                </div>
              </div>

            </div>
          </div>
        </div>
      </section>
      <section className="photographs">
        <div className="container">
          <div className="row">
            <h2 className="road">We make your post-accident  <br /><span className="light">recovery quick and effortless </span> </h2>
            <div className="col-xxl-6 col-xl-6 col-md-12 col-12">
              <div className="fourbox">

                <ul>
                  <li>
                    <img src={check} className="check" alt="" />
                    Call us on the<strong> Hiscox accident claim </strong>number for emergency assistance after the car accident.
                  </li>
                  <hr />
                  <li>
                    <img src={check} className="check" alt="" />
                    We will arrange instant recovery of your vehicle if it is not drivable, moving it to a safe place.
                  </li>
                  <hr />
                  <li>
                    <img src={check} className="check" alt="" />
                    Our team will assist you with the arrangements for a temporary replacement vehicle to help you continue with your routine.
                  </li>
                  <hr />
                  <li>
                    <img src={check} className="check" alt="" />
                    We will assist you with the<strong> Hiscox car insurance claim </strong>process and liaison with all the parties on your behalf.
                  </li>

                </ul>
              </div>
            </div>
            <div className="col-xxl-6 col-xl-6 col-md-12 col-12">
              <div className="fourbox">
                <ul>
                  <li>
                    <img src={check} className="check" alt="" />
                    Get in touch with us via the <strong>Hiscox car insurance claim </strong>phone number, and we’ll connect you with an experienced solicitor specialising in no-fault accident claims.
                  </li>
                  <hr />
                  <li>
                    <img src={check} className="check" alt="" />
                    Until your claim is assessed, we will keep your vehicle secured in our storage facility, ensuring its protection from potential risk.
                  </li>
                  <hr />
                  <li>
                    <img src={check} className="check" alt="" />
                    We will help you get no-obligation advice to know your rights and options to make a claim.
                  </li>
                  <hr />
                  <li>
                    <img src={check} className="check" alt="" />
                    Our team will update you about the repairs to your vehicle and schedule its delivery upon completion.
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="carprocess">
        <div className="container ">
          <div className="row">
            <div className="col-xxl-12 col-xxl-12 col-md-12 col-12">
              <h2 className="trusted-title"> Accident management services <br /><span className="light"> we offer   </span> </h2>
            </div>
          </div>
          <div className="row" id="possible">
            <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-12">
              <div className="claim">
                <ul>
                  <li><span className="sleej">Vehicle recovery  </span> <br />
                    Has your vehicle been damaged due to the car accident? Call us on <strong>car insurance claim number <a href="tel:0800 772 0850">0800 772 0850</a>
                      .</strong>We will arrange immediate recovery of your vehicle with the help of our experienced recovery agents, moving it to a safe and secure place.
                  </li>
                  <li><span className="sleej">Vehicle repairs  </span><br />
                    Get in touch with us via the <strong>Hiscox car insurance claim </strong>number for quick and efficient repairs of your damaged vehicle. Our approved repairers specialise in providing high-quality repairs that exceed manufacturer specifications.
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-12">
              <div className="claim" id="blue">
                <ul>
                  <li><span className="sleej">Safe storage</span> <br />
                    Until your claim is examined, we will store your vehicle in our safe and secure storage yards to safeguard it from further damage. Our vehicle storage services offer a safe, secure and convenient location to store your damaged vehicle without the burden of any excess payments or additional costs.
                  </li>
                  <li><span className="sleej">Replacement car  </span> <br />
                    You’re entitled to a free replacement vehicle after a no-fault car accident. Contact us at the <strong>Hiscox insurance claim number</strong> to get back on the road again with a like-for-like replacement vehicle that matches the specifications of your own vehicle.
                  </li>

                </ul>
              </div>
            </div>
            <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-12">
              <div className="claim">
                <ul>
                  <li><span className="sleej">Injury support </span><br />
                    Have you sustained injuries due to the car accident? Our solicitors have a proven track record of securing maximum compensation for the injuries suffered by the victims of no-fault car accidents.

                  </li>
                  <li><span className="sleej">Claim management </span> <br />
                    From the moment you call us to initiate your claim to its final settlement, we will stand by your side every step of the way to ensure the best possible outcome.

                  </li>
                </ul>
              </div>
            </div>
          </div>

        </div>
      </section>
      <section className="yellowcta">
        <div className="container">
          <div className="row">
            <div className="cta2">
              <div className="col-xxl-8 col-xl-8 col-lg-8 col-12">

                <div className="legal">
                  <h3 className="experienced">Got questions?<span className="light">Talk to our specialists  </span> </h3>
                  <p className="professionals">Get in touch with our no-fault claim experts via <strong>car insurance claim phone number <a href="tel:0800 772 0850">0800 772 0850</a> </strong>to get the best advice for your <strong>Hiscox car insurance claim. </strong>
                  </p>
                </div>

              </div>
              <div className="col-xxl-4 col-xl-4 col-lg-4 col-12">
                <div class="process">
                  <a class="tf-button style-1 phone" href="tel:0800 772 0850">
                    Get in touch<span class="icon-keyboard_arrow_right"></span>
                  </a>
                </div>
                <div class="process">
                  <a className="tf-button style-1 desktop" onClick={handleScrollToSidebar}>
                    Get in touch<span className="icon-keyboard_arrow_right"></span>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <search className="updatedform">
        <div className="container">
          <div className="row text-center">
            <div className="col-xxl-12 col-xl-12 col-md-12 col-12">
              <h2 className="whiteheading"> Why make us your reliable partner for <span className="light">the best claims assistance? </span></h2>
              <p className="sustained">
                Our team of experts can help you deal with the consequences of a car accident smoothly and effortlessly. With our wide array of accident management services, ranging from vehicle recovery to claims management, we will ease your road to recovery and help you get deserving compensation for the losses and injuries you’ve sustained due to the car accident.
              </p>
            </div>
          </div>
          <div className="row">
            <div className="col-xxl-4 col-xl-4 col-lg-6 col-md-12 col-12">
              <div className="Claimant">
                <h5>No cost to you </h5>
                <p className="collision">
                  We won’t burden you with additional costs or upfront charges, as we will recover all the costs of our services from the negligent party's insurer.
                </p>

                <h5>Same-day replacement vehicle </h5>
                <p className="collision">
                  To ensure you get back on the road the same day, we will provide you with a like-for-like replacement vehicle within 24 hours.
                </p>

                <h5>Dedicated claims handler  </h5>
                <p className="collision">
                  When you call our <strong>Hiscox insurance claim number </strong>to initiate your claim, we will assign a dedicated claims handler, who will manage your claim from initiation to settlement and offer advice throughout the process.
                </p>

              </div>
            </div>
            <div className="col-xxl-4 col-xl-4 col-lg-6 col-md-12 col-12">
              <div className="Claimant">
                <h5>Comprehensive legal support </h5>
                <p className="collision">
                  From helping you recover all out-of-pocket expenses to claiming compensation for your damages and injuries, we will offer you dedicated support and assistance.
                </p>

                <h5>Hassle-free claim </h5>
                <p className="collision">
                  Our solicitors will take care of all aspects of your claim, from documentation preparation to claim monitoring, negotiation support and settlement, saving you from hassles and stress.

                </p>

                <h5>Manufacturer-approved vehicle repairs </h5>
                <p className="collision">
                  We have a UK-wide network of manufacturer-approved repairers to ensure top-notch repairs to your vehicle. Call us on the <strong>Hiscox insurance claim number</strong>, and we’ll ensure your car gets repaired to the highest standards of excellence.

                </p>

              </div>
            </div>
            <div className="col-xxl-4 col-xl-4 col-lg-12 col-md-12 col-12">
              <div className="Claimant">
                <h5>Experienced solicitors </h5>
                <p className="collision">
                  Our solicitors have a successful record of handling thousands of no-fault accident claims and securing the best compensation for the victims. Get in touch with us via the <strong>Hiscox car accident claim phone number </strong>to get professional advice tailored to your circumstances.

                </p>

                <h5>Clear communication </h5>
                <p className="collision">
                  We believe in transparency. So, you can rest assured that we will keep you informed every step of the way with regular updates about the <strong>Hiscox car insurance claim. </strong>
                </p>

                <h5>Quick response time </h5>
                <p className="collision">
                  Our streamlined and hassle-free process ensures your claim is handled quickly without any unwanted surprises or delays.

                </p>

              </div>
            </div>
          </div>
        </div>
      </search>


      <section className="Accident-claim">
        <div className="container">
          <div className="row">
            <div className="needed">
              <h2 className="trusted-title"> How to get the best settlement for<br /><span className="light">  the Hiscox car insurance claim? </span></h2>
              <p className="complexities">
                Want to get more money for your no-fault accident claim? To ensure maximum compensation for your claim, here are a few steps you must follow after a life-changing car accident.
              </p>
            </div>
            <hr />
          </div>
          <div className="row" id="incdes">

            <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-12">
              <div className="Preparing">
                <ul>
                  <li>
                    <div className="seemingly">
                      <h5 className="Seek">Report the accident </h5>
                      <p> Call the nearby police station and report the car accident within 24 hours. Also, get a copy of the police report to support your claim. </p>
                    </div>
                  </li>
                  <li>
                    <div className="seemingly">
                      <h5 className="Seek">Check injuries </h5>
                      <p>Examine yourself or your passengers to check whether anyone has sustained any injuries and immediately make arrangements to get the injuries treated.

                      </p>
                    </div>
                  </li>
                  <li>
                    <div className="seemingly">
                      <h5 className="Seek">Collect evidence </h5>
                      <p>It might be challenging to prove your innocence. So, gather multiple pieces of evidence from the accident scene, including eyewitness statements, photos of injuries, CCTV videos, injury-related reports and a copy of the accident report.
                      </p>
                    </div>
                  </li>
                  <li>
                    <div className="seemingly">
                      <h5 className="Seek">Record all expenses </h5>
                      <p>
                        Record all the expenses you’ve incurred due to the accident, such as accidental loss, car repair or replacement costs, and any other out-of-pocket expenses.
                      </p>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-12" id="dualcardiv">
              <img src={dualcar} alt="" className="dualcar" />
            </div>
            <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-12">
              <div className="Preparing">
                <ul>
                  <li>
                    <div className="seemingly">
                      <h5 className="Seek">Don’t settle for less</h5>
                      <p>
                        The other party’s insurer may reach out to you for an early settlement offer. Don’t settle for less than what you deserve. Instead, contact an experienced solicitor to determine the rightful compensation for your losses.
                      </p>
                    </div>
                  </li>
                  <li>
                    <div className="seemingly">
                      <h5 className="Seek">Exchange details </h5>
                      <p>
                        Swap details with the other driver, including the name, contact number, vehicle registration number and insurance policy details.
                      </p>
                    </div>
                  </li>
                  <li>
                    <div className="seemingly">
                      <h5 className="Seek">Talk to an expert solicitor </h5>
                      <p>
                        Get in touch with us via the <strong>Hiscox car insurance claim number</strong> to get professional and legal assistance from our panel of solicitors.
                      </p>
                    </div>
                  </li>
                  <li>
                    <div className="seemingly">
                      <h5 className="Seek">Initiate your claim </h5>
                      <p>After collecting evidence and gathering relevant documents, call us on the <strong>Hiscox insurance claim number </strong> to initiate your claim.  </p>
                    </div>
                  </li>
                </ul>
              </div>
            </div>

          </div>
        </div>
      </section>
      <section className="whyus">
        <div className="container">
          <div className="row">
            <div className="col-xxl-12 col-md-12">
              <div className="why">
                <h2 className="choose">Make your claim in <span className="light"><br /> just 4 steps  </span></h2>
                <p className="complexities">
                  Making a car accident claim may seem a daunting and frustrating task. However, with our streamlined process, you can make your claim quickly and efficiently without any paperwork hassles. Contact us on <a href="tel:0800 772 0850">0800 772 0850</a>
                  to file your claim.
                </p>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-xxl-3 col-xl-6 col-lg-6 col-md-6 col-12">
              <div className="box-service">
                <img src={check} className="check" alt="" />
                <h4>Get in touch</h4>
                <p>
                  Call us on the<strong> Hiscox car accident claim </strong>phone number to book a free consultation with our solicitors to discuss your claim.

                </p>
              </div>
            </div>
            <div className="col-xxl-3 col-xl-6 col-lg-6 col-md-6 col-12">
              <div className="box-service">
                <img src={check} className="check" alt="" />
                <h4>Share accident details  </h4>
                <p>
                  Provide accident details, including the date, time, location and cause, so we can proceed with your claim.

                </p>
              </div>
            </div>
            <div className="col-xxl-3 col-xl-6 col-lg-6 col-md-6 col-12">
              <div className="box-service">
                <img src={check} className="check" alt="" />
                <h4>Submit evidence </h4>
                <p>
                  Hand over the evidence you’ve collected from the accident scene to allow us to build a solid foundation for your claim.
                </p>
              </div>
            </div>
            <div className="col-xxl-3 col-xl-6 col-lg-6 col-md-6 col-12">
              <div className="box-service">
                <img src={check} className="check" alt="" />
                <h4>Claim initiation </h4>
                <p>
                  We will initiate your <strong>Hiscox car insurance claim </strong>and negotiate with the other party’s insurer to resolve it at the earliest.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="faqs">
        <div className="container">
          <div className="row">
            <div className="col-xxl-12 col-xl-12 col-md-12 col-12">
              <div className="fq">
                <h2> Frequently Asked <span className="light">Questions (FAQ)</span></h2>
                <p className="complexities">
                  Got questions about the Hiscox car insurance claim? We have them answered here.
                </p>
              </div>
              <div className="allfaq">
                <Accordion defaultActiveKey="0" >
                  <Accordion.Item eventKey="0">
                    <Accordion.Header>How long can I keep the replacement vehicle?
                    </Accordion.Header>
                    <Accordion.Body>
                      The duration of the replacement vehicle depends on the circumstances of the accident and the time required to repair or replace the damaged vehicle. Generally, you can keep the replacement vehicle until your damaged vehicle is repaired or replaced.
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="1">
                    <Accordion.Header>What if I want my vehicle repaired from my chosen repair shop?
                    </Accordion.Header>
                    <Accordion.Body>
                      Call us on the Hiscox car insurance claim phone number to share the details of the repair shop from whom you would like to get your vehicle repaired. We will contact your chosen repairer to schedule the vehicle repairs.

                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="2">
                    <Accordion.Header>What happens if my vehicle is written off?
                    </Accordion.Header>
                    <Accordion.Body>
                      In case your vehicle is written off, we will store it in our storage yards and provide you with a replacement vehicle for the duration of your claim.

                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="3">
                    <Accordion.Header>Do I need to notify my insurer?</Accordion.Header>
                    <Accordion.Body>
                      No. If you choose to claim with us, we will inform your insurer and keep you updated about the progress.

                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="4">
                    <Accordion.Header>Will the replacement vehicle be similar to my own vehicle?
                    </Accordion.Header>
                    <Accordion.Body>
                      Yes. We will provide you with a like-for-like replacement vehicle that will be similar to your vehicle.

                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="5">
                    <Accordion.Header>Will the services cost me anything?
                    </Accordion.Header>
                    <Accordion.Body>
                      No. We won’t charge you for our accident management services. We will claim all the costs from the other party’s insurer.

                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="6">
                    <Accordion.Header>Will my no-claims bonus be affected if I make a claim?
                    </Accordion.Header>
                    <Accordion.Body>
                      No. We won’t initiate a claim against your own insurance policy. As a result, your no-claims bonus will stay safe.

                    </Accordion.Body>
                  </Accordion.Item>
                </Accordion>

              </div>
            </div>
          </div>
        </div>
      </section>


      <Footer />


    </>
  );
};

export default Hiscox;
