import React, { useRef } from "react";
import Accordion from "react-bootstrap/Accordion";
import check from "../assets/images/check.png";
import dualcar from "../../src/assets/images/dualcar.webp";
import hasting from "../../src/assets/images/hasting.webp";
import Sidebarinner from "../components/Sidebar-inner";
import phonecall from "../assets/images/phonecall.png";
import Footer from "../components/footer";
import { Helmet } from "react-helmet";

const Lexusinsurance = () => {
    const sidebarRef = useRef(null);

    const handleScrollToSidebar = () => {
        if (sidebarRef.current) {
            sidebarRef.current.scrollIntoView({ behavior: "smooth" });
        }
    };
    return (
        <>
        <Helmet>
        <meta charSet="utf-8" />
        <title>Lexus Car Insurance Accident Claim Phone Number</title>
        <meta
          name="description"
          content="Searching for immediate claims assistance? Call us on the Lexus Car Insurance Accident Claim Phone Number 0800 772 0850 for timely support and smooth claim settlement. "
        />
        <link
          rel="canonical"
          href=">https://insurance-accident-claims.co.uk/Lexus-insurance"
        />
      </Helmet>
            <section className="innernav">
                <div className="container">
                    <div className="row">
                        <div className="col-md-6 col-sm-6">
                            <div className="left-logo">
                                <h3>
                                    Accident<span className="sipo"> Claims</span>
                                </h3>
                            </div>
                        </div>
                        <div className="col-md-6 col-sm-6">
                            <div className="left-cta">
                                <div class="headercnt">
                                    <div class="chatmsg">
                                        <img
                                            src={phonecall}
                                            className="phonecall"
                                            alt="phonecall "
                                        />
                                    </div>
                                    <div class="box-body">
                                        <h3 class="nav-info-box-title">Toll Free Number </h3>
                                        <p>
                                            <a href="tel:0800 772 0850">0800 772 0850</a>
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section
                className="newbnr"
                style={{ backgroundImage: "url(" + hasting + ")" }}
            >
                <div className="container">
                    <div className="row">
                        <div className="col-xxl-7 col-xl-7 col-md-10 col-12">
                            <div className="innerbnr">
                                <h1>
                                    <span className="onespan">Dedicated no-fault car accident</span>{" "}
                                    <span className="sci">claims   </span>assistance
                                </h1>
                                <p className="brokerage">
                                From vehicle recovery to claim management, we'll provide reliable and trusted support to get you back on the road again.                             </p>
                                <div className="bnrbtn">
                                    <a class="db-btn-border btn-rollover phone" href="tel:0800 772 0850">
                                        Road Accident Claim Help?{" "}
                                        <i class="fa-solid fa-arrow-right"></i>
                                    </a>
                                </div>
                                <div className="bnrbtn">
                                    <a className="db-btn-border btn-rollover desktop" onClick={handleScrollToSidebar}>
                                        Road Accident Claim Help? <i className="fa-solid fa-arrow-right"></i>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="stickysection">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="group-4-8">
                                <div className="cl8 tf-tab">
                                    <div className="content-tab">
                                        <div className="inner-content">
                                            <h2>
                                            The best insurance claims handling company
                                           
                                                <span className="light"> in the UK</span>{" "}
                                            </h2>
                                            <p className="disciplines">
                                            If you’re confused about how to make a <strong>  Lexus car insurance claim </strong> or any other assistance after a car accident, contact our hotline number and get immediate help. Do not care about payment, as we offer cost-free <strong>  Lexus car insurance claims </strong> assistance. We will recover all the charges for the<strong> Lexus car insurance claim </strong>  from the at-fault party. 
                                            </p>
                                            <hr />
                                            <p className="bold-text">No excess  </p>
                                            <p className="bottom-te">If the accident is not your fault, you don’t have to pay any excess charges. 
                                            
                                        </p>
                                            <hr />
                                            <p className="bold-text">No claims bonus unaffected </p>
                                            <p className="bottom-te">
                                            We don’t initiate a claim against your own policy. When you make a claim through us, remember that you retain the bonus awarded.                                     </p>
                                            <hr />
                                            <p className="bold-text">
                                            Quick recovery 
                                            </p>
                                            <p className="bottom-te">
                                            If you’re left stranded on the roadside after the road accident, call us on the<strong> Lexus car insurance number </strong>for emergency assistance.                                        </p>
                                            <hr />
                                            <p className="bold-text">Authorised repairs by manufacturer-approved repairers</p>
                                            <p className="bottom-te">
                                            Our approved repairers shall take care of your car so that it remains in its original condition.                                    </p>
                                            <hr />
                                            <p className="bold-text">Like-for-like replacement car  </p>
                                            <p className="bottom-te">
                                            In the event of an accident or if your vehicle is not fit to be driven on roads, we can provide you with a replacement car similar to your own.                                       </p>
                                            <hr />
                                            <p className="bold-text">Experienced solicitors </p>
                                            <p className="bottom-te">
                                            Our experienced<strong> Lexus insurance claims </strong>  solicitors have been able to help those people who were innocent victims of accidents caused by others and have helped them get the compensation that they deserve. </p>
                                            <hr />
                                            <p className="bold-text">Quick settlement</p>
                                            <p className="bottom-te">
                                            Our claim processes are straightforward, meaning little paperwork is required to start your claim, helping you save time. 
                                          
                                        </p>
                                            <hr />
                                            <p className="bold-text">Free initial consultation</p>
                                            <p className="bottom-te">
                                            For all questions concerning <strong> Lexus car insurance claims, </strong>  call 
                                            <strong>
                                            {" "}
                                            <a href="tel:0800 772 0850">0800 772 0850</a>{" "}
                                        </strong> We will advise you on the steps you should take for a successful outcome. 
                                        </p>
                                            <hr />
                                            <p className="bold-text">No win, no pay</p>
                                            <p className="bottom-te">
                                            Our solicitors operate under a contingency fee arrangement. So, if there is no positive outcome for you, then there will be no need to pay them anything.          </p>
                                            <hr />

                                            <p className="bold-text">24/7 support</p>
                                            <p className="bottom-te">
                                            Please contact us immediately if you need any assistance in case of an accident. In the event of any accident, please call us on 
                                            <strong>
                                            {" "}
                                            <a href="tel:0800 772 0850">0800 772 0850</a>{" "}
                                        </strong> to get quick roadside assistance.  

                                                     
                                                 </p>
                                        </div>
                                    </div>
                                </div>
                                <div className="cl4" ref={sidebarRef}>
                                    <Sidebarinner />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="photographs">
                <div className="container">
                    <div className="row">
                        <h2 className="road">
                        We aim to make your post-accident {" "}
                            <span className="light">
                            journey  <br />
                            stress-free 
                            </span>{" "}
                        </h2>
                        <div className="col-xxl-6 col-xl-6 col-md-12 col-12">
                            <div className="fourbox">
                                <ul>
                                    <li>
                                        <img src={check} className="check" alt="" />
                                     <strong>  Lexus car insurance contact number </strong>   can be reached in case of a vehicle emergency after an accident. 

                                    </li>
                                    <hr />
                                    <li>
                                        <img src={check} className="check" alt="" />
                                        We will establish who was responsible for the accident and file your claim accordingly.                    </li>
                                    <hr />
                                    <li>
                                        <img src={check} className="check" alt="" />
                                        Apart from that, we will handle all the paperwork and documentation required to make your claim. 
                                    </li>
                                    <hr />
                                    <li>
                                        <img src={check} className="check" alt="" />
                                        We will collect your car from the scene of the accident and fix it through one of our approved repairers.                            </li>
                                </ul>
                            </div>
                        </div>
                        <div className="col-xxl-6 col-xl-6 col-md-12 col-12">
                            <div className="fourbox">
                                <ul>
                                    <li>
                                        <img src={check} className="check" alt="" />
                                        Where there can be no reasonable economic repair, then an independent engineer may be engaged to value damages on your car. 
                                        </li>
                                    <hr />
                                    <li>
                                        <img src={check} className="check" alt="" />
                                        You will have a temporary replacement car placed at your disposal until your claim is resolved. 
                                        
                                        </li>
                                    <hr />
                                    <li>
                                        <img src={check} className="check" alt="" />
                                        Additionally, your vehicle repairs too shall be inspected periodically so as to monitor the progress of car repairs.                             </li>
                                    <hr />
                                    <li>
                                        <img src={check} className="check" alt="" />
                                        Call  <strong>
                                            {" "}
                                            <a href="tel:0800 772 0850">0800 772 0850</a>{" "}
                                        </strong>  now, and we will advise you how to initiate a successful claim for damages and losses.                         </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="carprocess">
                <div className="container ">
                    <div className="row">
                        <div className="col-xxl-12 col-xxl-12 col-md-12 col-12">
                            <h2 className="trusted-title">
                                {" "}
                                Accident management services <br />
                                <span className="light">we  provide  </span>{" "}
                            </h2>
                        </div>
                    </div>
                    <div className="row" id="possible">
                        <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-12">
                            <div className="claim">
                                <ul>
                                    <li>
                                        <span className="sleej">Roadside Assistance:</span> If you need to move your car from the accident scene, please contact us on the <strong> Lexus insurance claim phone number. </strong>  As soon as possible, our recovery services are available to ensure that your car is taken from where the accident happened and secured in the storage yard.     
                                    </li>
                                    <li>
                                        <span className="sleej">Manufacturer-approved repairs:</span> For repairs to your car, call our <strong> Lexus car insurance claim number.</strong> You will not incur any upfront expenses as you have your vehicle fixed at authorised garages in different parts of the UK.  

                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-12">
                            <div className="claim" id="blue">
                                <ul>
                                    <li>
                                        <span className="sleej">Car Storage:</span>  Want your car to be stored in a safe place until your claim is underway?  Call us through the following <strong> Lexus insurance claim phone number. </strong> While your claim assessment is taking place, we will store your car in our safe compound.     
                                    </li>
                                    <li>
                                        <span className="sleej">Replacement vehicle :</span> Maybe we can help by providing an alternative car if your vehicle is not fixable. In cases of urgency where you need a replacement vehicle, ring our emergency 
                                        number  <strong>
                                            {" "}
                                            <a href="tel:0800 772 0850">0800 772 0850</a>{" "}
                                        </strong>  to get a temporary replacement vehicle to continue doing your daily tasks. 
      
                                         
                                        
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-12">
                            <div className="claim">
                                <ul>
                                    <li>
                                        <span className="sleej">Personal injury claim assistance </span>
                                        Are you interested in making a claim for injuries emanating from an accident? Our solicitors can be of assistance in advising you on how to claim compensation for losses or injury.                                     </li>
                                    <li>
                                        <span className="sleej">Claims management:</span>  We will manage everything, including paperwork management, negotiating with insurers, arranging repairs and organising car hire.
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="yellowcta">
                <div className="container">
                    <div className="row">
                        <div className="cta2">
                            <div className="col-xxl-8 col-xl-8 col-lg-8 col-12">
                                <div className="legal">
                                    <h3 className="experienced">
                                    Got more questions? Talk to {" "}
                                        <span className="light">  our specialists 
                                        </span>{" "}
                                    </h3>
                                    <p className="professionals">
                                    Get in touch with our no-fault claim experts via <strong> car insurance claim phone number</strong>
                                    <strong>
                                            {" "}
                                            <a href="tel:0800 772 0850">0800 772 0850</a>{" "}
                                        </strong>  to get the best advice for your <strong> Lexus car insurance claim. </strong> 
                                    </p>
                                </div>
                            </div>
                            <div className="col-xxl-4 col-xl-4 col-lg-4 col-12">
                                <div class="process">
                                    <a class="tf-button style-1 phone" href="tel:0800 772 0850">
                                        Get in touch<span class="icon-keyboard_arrow_right"></span>
                                    </a>
                                </div>
                                <div class="process">
                                    <a className="tf-button style-1 desktop" onClick={handleScrollToSidebar}>
                                        Get in touch<span className="icon-keyboard_arrow_right"></span>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <search className="updatedform">
                <div className="container">
                    <div className="row text-center">
                        <div className="col-xxl-12 col-xl-12 col-md-12 col-12">
                            <h2 className="whiteheading">
                                {" "}
                                Why should we be your choice for meeting your 
                                <span className="light">   accident management requirements?
                                </span>
                            </h2>
                            <p className="sustained">
                            Let us handle any non-fault road traffic accident issues. Here are some of the advantages that make us one of the best claim handlers in the UK.                              </p>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-xxl-4 col-xl-4 col-lg-6 col-md-12 col-12">
                            <div className="Claimant">
                                <h5>Round-the-clock vehicle recovery</h5>
                                <p className="collision">
                                Do you need someone to recover your vehicle? If so, please dial the <strong> Lexus insurance claim number, </strong> and we will recover your vehicle from the accident scene as soon as possible. 

                                </p>

                                <h5>Assured Repairs</h5>
                                <p className="collision">
                                We have partnered with manufacturer-approved repairers in the UK to ensure quality repairs for your vehicle.              </p>

                                <h5>No increase in premiums</h5>
                                <p className="collision">
                                The claim won’t be made against your own insurance policy, leaving your insurance premiums intact. 
                                </p>
                            </div>
                        </div>
                        <div className="col-xxl-4 col-xl-4 col-lg-6 col-md-12 col-12">
                            <div className="Claimant">
                                <h5>Dependable partners
                                </h5>
                                <p className="collision">
                                We strive to provide you with competent services by partnering with experienced solicitors and garages all over the United Kingdom.</p>
                                <h5>Instant Claim Settlements</h5>
                                <p className="collision">
                                After our seasoned solicitors have talked to the insurance firm of another driver concerning your <strong> Lexus car insurance claim, </strong>  you can be assured of a fair settlement.                          </p>

                                <h5>Prompt Aid</h5>
                                <p className="collision">
                                Call us on the<strong> Lexus insurance claim phone number, </strong> and rest assured that you'll receive emergency support without any delay.  
                                </p>
                            </div>
                        </div>
                        <div className="col-xxl-4 col-xl-4 col-lg-12 col-md-12 col-12">
                            <div className="Claimant">
                                <h5>Experienced claim handler </h5>
                                <p className="collision">
                                We will allocate a claims handler who will handle your claim from its start to finish, informing you at every stage of its progress.                            </p>

                                <h5>No excess payments </h5>
                                <p className="collision">
                                You will not be required to make any excess payments since third-party insurers will cater for our expenses.                         </p>
                            </div>
                        </div>
                    </div>
                </div>
            </search>

            <section className="Accident-claim">
                <div className="container">
                    <div className="row">
                        <div className="needed">
                            <h2 className="trusted-title">
                            How to maximise the settlement value  <br />
                                <span className="light">of your claim </span>
                            </h2>
                            <p className="complexities">
                            If you want a better possibility of getting high compensation for injuries caused, then produce strong evidence to prove your innocence. Here are the steps you should follow after the accident to prove your innocence.                      </p>
                        </div>
                        <hr />
                    </div>
                    <div className="row" id="incdes">
                        <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-12">
                            <div className="Preparing">
                                <ul>
                                    <li>
                                        <div className="seemingly">
                                            <h5 className="Seek">Ensure your safety :</h5>
                                            <p>
                                                {" "}
                                                Find a safe place away from the traffic and park your car. Then, check yourself and the passengers for any external or internal injuries.                                    </p>
                                        </div>
                                    </li>
                                    <li>
                                        <div className="seemingly">
                                            <h5 className="Seek">
                                            Get to a doctor immediately :{" "}
                                            </h5>
                                            <p>
                                            In case someone is injured in a road accident, try and find a good doctor right away who will help treat the injuries.                                          </p>
                                        </div>
                                    </li>
                                    <li>
                                        <div className="seemingly">
                                            <h5 className="Seek">Obtain proof:</h5>
                                            <p> Collect evidence from the accident scene, such as photographs, CCTV videos and eyewitness statements, to support your claim.                                       </p>
                                        </div>
                                    </li>
                                    <li>
                                        <div className="seemingly">
                                            <h5 className="Seek">Report the accident to the Police :</h5>
                                            <p>
                                            Call the Police and report the accident within 24 hours. The police report may contain crucial information to support your claim.                                 </p>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div
                            className="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-12"
                            id="dualcardiv"
                        >
                            <img src={dualcar} alt="" className="dualcar" />
                        </div>
                        <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-12">
                            <div className="Preparing">
                                <ul>
                                    <li>
                                        <div className="seemingly">
                                            <h5 className="Seek">Provide information:</h5>
                                            <p>
                                            For the smooth resolution of your claim, swap details and insurance information with the other driver.                                        </p>
                                        </div>
                                    </li>
                                    <li>
                                        <div className="seemingly">
                                            <h5 className="Seek">Contact eyewitnesses: </h5>
                                            <p>
                                            Reach out to the eyewitnesses and collect their details for future reference purposes.                               </p>
                                        </div>
                                    </li>
                                    <li>
                                        <div className="seemingly">
                                            <h5 className="Seek">
                                            Note down expenses :
                                            </h5>
                                            <p>
                                            Record all the expenses you’ve incurred as a result of the car accident so that you can claim compensation for all costs. 
                                            </p>
                                        </div>
                                    </li>
                                    <li>
                                        <div className="seemingly">
                                            <h5 className="Seek">
                                            Consult with a no-fault solicitor :
                                            </h5>
                                            <p>
                                            Ring us on  <strong>
                                            {" "}
                                            <a href="tel:0800 772 0850">0800 772 0850</a>{" "}
                                        </strong> , and we will connect you with an experienced solicitor who can help you make a successful claim.
                                            </p>
                                        </div>
                                    </li>
                                    
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="whyus">
                <div className="container">
                    <div className="row">
                        <div className="col-xxl-12 col-md-12">
                            <div className="why">
                                <h2 className="choose">
                                Make your claim effortlessly with our {" "}
                                    <span className="light"> simplified process </span>
                                </h2>
                                <p className="complexities">
                                With our simplified and streamlined claims process, you can easily make your claim in just 4 steps. Here’s how you can make a <strong>  Lexus car insurance claim </strong> with us.             </p>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-xxl-3 col-xl-6 col-lg-6 col-md-6 col-12">
                            <div className="box-service">
                                <img src={check} className="check" alt="" />
                                {/* <img src={experience} className="experience" alt="" /> */}
                                <h4>Call us</h4>
                                <p>
                                Contact us via the <strong>  Lexus car insurance claim number </strong>to schedule a consultation with our solicitors.  
                                        </p>
                            </div>
                        </div>
                        <div className="col-xxl-3 col-xl-6 col-lg-6 col-md-6 col-12">
                            <div className="box-service">
                                <img src={check} className="check" alt="" />
                                <h4>Share accident details </h4>
                                <p>
                                Provide a detailed account of when, where and how the accident occurred.</p>
                            </div>
                        </div>
                        <div className="col-xxl-3 col-xl-6 col-lg-6 col-md-6 col-12">
                            <div className="box-service">
                                <img src={check} className="check" alt="" />
                                <h4>Give evidence </h4>
                                <p>
                                Submit evidence such as photographs, videos, statements or even CCTV videos that can support your claim.                              </p>
                            </div>
                        </div>
                        <div className="col-xxl-3 col-xl-6 col-lg-6 col-md-6 col-12">
                            <div className="box-service">
                                <img src={check} className="check" alt="" />
                                <h4>Claim initiation</h4>
                                <p>
                                Once we’ve gathered all the paperwork and documents, we will work towards initiating your claim. 

                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="faqs">
                <div className="container">
                    <div className="row">
                        <div className="col-xxl-12 col-xl-12 col-md-12 col-12">
                            <div className="fq">
                                <h2>
                                    {" "}
                                    Frequently Asked{" "}
                                    <span className="light">Questions</span>
                                </h2>
                                <p className="complexities">
                                Got questions about the <strong>  Lexus car insurance claim?   </strong>  We have them answered here.

                                </p>
                            </div>
                            <div className="allfaq">
                                <Accordion defaultActiveKey="0">
                                    <Accordion.Item eventKey="0">
                                        <Accordion.Header>
                                        Do I have to pay any excess for courtesy cars?                                 </Accordion.Header>
                                        <Accordion.Body>
                                        No. You don't have to make any payments to avail of a courtesy car as all costs will be recovered from the at-fault party's insurer.                               </Accordion.Body>
                                    </Accordion.Item>
                                    <Accordion.Item eventKey="1">
                                        <Accordion.Header>
                                        How long would it take before my claim gets settled?
                                        </Accordion.Header>
                                        <Accordion.Body>
                                        We will try to settle your claim as quickly as possible, but we won’t know how long it will take because each claim is different. Once we initiate your claim, we’ll keep you updated about its progress.                                 </Accordion.Body>
                                    </Accordion.Item>
                                    <Accordion.Item eventKey="2">
                                        <Accordion.Header>
                                        Is it right that the other party’s insurer pays for my vehicle recovery costs?
                                        </Accordion.Header>
                                        <Accordion.Body>
                                        Absolutely, it is the liability of the party at fault in any particular incident. Documents such as photographs taken at the place where an injury occurs or a police report that shows you are entitled to compensation should be gathered.                        </Accordion.Body>
                                    </Accordion.Item>
                                    <Accordion.Item eventKey="3">
                                        <Accordion.Header>
                                        Can I get credit for hire after a non-fault road traffic collision?
                                        </Accordion.Header>
                                        <Accordion.Body>
                                        Yes. If you were not responsible for causing the car crash, we’ll provide you with a courtesy car.                               </Accordion.Body>
                                    </Accordion.Item>
                                    <Accordion.Item eventKey="4">
                                        <Accordion.Header>
                                        If I am not at fault, will I lose my no-claims bonus?

                                        </Accordion.Header>
                                        <Accordion.Body>
                                        Your no-claim bonus won’t be affected as we don’t raise claims on your policy.                         </Accordion.Body>
                                    </Accordion.Item>
                                    <Accordion.Item eventKey="5">
                                        <Accordion.Header>
                                        Can I repair my car in my preferred repair centre?{" "}
                                        </Accordion.Header>
                                        <Accordion.Body>
                                        Yes. We give you the choice to choose any workshop where you can have repairs done. We will book your vehicle repairs at your preferred garage and ensure quick and superior repairs.                          </Accordion.Body>
                                    </Accordion.Item>
                                    <Accordion.Item eventKey="6">
                                        <Accordion.Header>
                                        What make of car will be offered to me?{" "}
                                        </Accordion.Header>
                                        <Accordion.Body>
                                        We will provide you with a like-for-like replacement car. Just get in touch with us through <strong> Lexus insurance claim number </strong>  and let us know what you require. We shall provide a replacement vehicle that satisfies you best.
                                        </Accordion.Body>
                                    </Accordion.Item>
                                </Accordion>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <Footer />
        </>
    );
};

export default Lexusinsurance;
