import React from "react";
import top1 from "../assets/images/top1.png";
import bottom1 from "../assets/images/bottom1.png";

function Sidebarinner(props) {
  return (
    <div className="widget-filter st2  style-scroll po-sticky">
    <form action="action.php"
       method="post"
       encType="multipart/form-data"
        id="jobdetil">

      <h3 className="touch text-center">Start Your Claim With Us </h3>
        <div className="group-form">
           <label>Name</label>
          <input type="text" name="name"  className="reg-input" required />

          <label>Phone Number</label>
          <input type="tel" name="phone"  className="reg-input" required />

          <label >Email</label>
          <input type="email" name="email"  className="reg-input" required />

          <label >Date of Accident</label>
          <input type="date" id="date" name="accidentdate" required />

           <label >Location of Accident</label>
          <input type="text" name="accidentlocation"  className="reg-input" required />

          <label >Vehicle Identification No.</label>
          <input type="text" name="vehiclenumber"  className="reg-input" required />

          <label>Choose A Date For Us to Call You</label>
          <input type="datetime-local" id="date" name="calldate" required  />
           </div> 
           <button type="submit">Submit</button>
      </form>
      </div>
  );
}

export default Sidebarinner;
