import React, { useRef } from "react";
import Accordion from "react-bootstrap/Accordion";
import check from "../assets/images/check.png";
import dualcar from "../../src/assets/images/dualcar.webp";
import hasting from "../../src/assets/images/hasting.webp";
import Sidebarinner from "../components/Sidebar-inner";
import phonecall from "../assets/images/phonecall.png";
import Footer from "../components/footer";
import { Helmet } from "react-helmet";

const Kiacarinsurance = () => {
    const sidebarRef = useRef(null);

    const handleScrollToSidebar = () => {
        if (sidebarRef.current) {
            sidebarRef.current.scrollIntoView({ behavior: "smooth" });
        }
    };
    return (
        <>
        <Helmet>
        <meta charSet="utf-8" />
        <title>Kia Car Insurance Accident Claim Phone Number</title>
        <meta
          name="description"
          content="Thinking of pursuing a no-fault car accident claim? Call us on the Kia Car Insurance Accident Claim Phone Number 0800 772 0850 for efficient claims assistance. "
        />
        <link
          rel="canonical"
          href="https://insurance-accident-claims.co.uk/Kia-car-insurance"
        />
      </Helmet>
            <section className="innernav">
                <div className="container">
                    <div className="row">
                        <div className="col-md-6 col-sm-6">
                            <div className="left-logo">
                                <h3>
                                    Accident<span className="sipo"> Claims</span>
                                </h3>
                            </div>
                        </div>
                        <div className="col-md-6 col-sm-6">
                            <div className="left-cta">
                                <div class="headercnt">
                                    <div class="chatmsg">
                                        <img
                                            src={phonecall}
                                            className="phonecall"
                                            alt="phonecall "
                                        />
                                    </div>
                                    <div class="box-body">
                                        <h3 class="nav-info-box-title">Toll Free Number </h3>
                                        <p>
                                            <a href="tel:0800 772 0850">0800 772 0850</a>
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section
                className="newbnr"
                style={{ backgroundImage: "url(" + hasting + ")" }}
            >
                <div className="container">
                    <div className="row">
                        <div className="col-xxl-7 col-xl-7 col-md-10 col-12">
                            <div className="innerbnr">
                                <h1>
                                    <span className="onespan">Car crash </span>{" "}
                                    <span className="sci">Insurance claims   </span>  number
                                </h1>
                                <p className="brokerage">
                                When you are in a road accident that was not your fault, we will be there for you to give emergency support and claims assistance.                         </p>
                                <div className="bnrbtn">
                                    <a class="db-btn-border btn-rollover phone" href="tel:0800 772 0850">
                                        Road Accident Claim Help?{" "}
                                        <i class="fa-solid fa-arrow-right"></i>
                                    </a>
                                </div>
                                <div className="bnrbtn">
                                    <a className="db-btn-border btn-rollover desktop" onClick={handleScrollToSidebar}>
                                        Road Accident Claim Help? <i className="fa-solid fa-arrow-right"></i>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="stickysection">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="group-4-8">
                                <div className="cl8 tf-tab">
                                    <div className="content-tab">
                                        <div className="inner-content">
                                            <h2>
                                            Quick and efficient claim resolution 
                                                <br />
                                                <span className="light">without any excess </span>{" "}
                                            </h2>
                                            <p className="disciplines">
                                            Our services support no-fault drivers involved in car accidents in the UK. In this regard, our team of experienced solicitors is ready to help as well as manage everything about Kia car insurance claims from start to end. For free claim assistance, call us on 
                                            <strong>
                                            {" "}
                                            <a href="tel:0800 772 0850">0800 772 0850</a>{" "}
                                        </strong>
                                            </p>
                                            <hr />
                                            <p className="bold-text">Zero deductible </p>
                                            <p className="bottom-te"> This implies that our costs will be met by the other party’s insurer, and you won’t have to bear any upfront expenses.                                        </p>
                                            <hr />
                                            <p className="bold-text">No effect on no-claims discounts</p>
                                            <p className="bottom-te">
                                            The <strong> Kia insurance accident claim </strong>  will not affect your own insurance policy. This means that you will not lose out on any of your no-claims bonuses or discount entitlements.                                           </p>
                                            <hr />
                                            <p className="bold-text">
                                            Dedicated experts 
                                            </p>
                                            <p className="bottom-te">
                                            Managing non-fault crashes is our speciality, as we have solicitors who work around the clock to get the best outcome possible for you.                                           </p>
                                            <hr />
                                            <p className="bold-text">Comprehensive services </p>
                                            <p className="bottom-te">
                                            We’ve taken care of everything – from handling complex <strong> Kia insurance claims </strong>  to providing emergency assistance that helps get you back on the road again.                                      </p>
                                            <hr />
                                            <p className="bold-text">No win, no fee</p>
                                            <p className="bottom-te">
                                            There is no obligation to pay any legal costs if your <strong> Kia car insurance claim </strong>fails.                                         </p>
                                            <hr />
                                            <p className="bold-text">Supportive and friendly team </p>
                                            <p className="bottom-te">
                                            When you call us using the<strong> Kia car insurance claim number, </strong> our team members will be there for you in a supportive way to assist you with the process of making a successful <strong> Kia insurance claim.</strong> 
                                            </p>
                                            <hr />
                                            <p className="bold-text">Experienced claims handler </p>
                                            <p className="bottom-te">
                                            We will assign an efficient claims officer who will strive to make sure that your case wins and that all the expenses incurred by you are reimbursed from the guilty driver.                                          </p>
                                            <hr />
                                            <p className="bold-text">Professional advice</p>
                                            <p className="bottom-te">
                                            Consult our solicitors, who are experts in handling no-fault claims. They can give you free advice on what to do depending on your situation when filing for a <strong> Kia car insurance claim. </strong>                                             </p>
                                            <hr />
                                            <p className="bold-text">Superior repairs service </p>
                                            <p className="bottom-te">
                                            Our huge network of manufacturer-approved repairers uses only authentic parts and paint, meaning your vehicle will be repaired to the highest standards of quality.                                      </p>
                                            <hr />

                                            <p className="bold-text">Comparable replacement vehicle </p>
                                            <p className="bottom-te">
                                            During the period when your car is undergoing repair work, we will provide you with a replacement vehicle similar to your own.
                                            
                                                </p>
                                        </div>
                                    </div>
                                </div>
                                <div className="cl4" ref={sidebarRef}>
                                    <Sidebarinner />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="photographs">
                <div className="container">
                    <div className="row">
                        <h2 className="road">
                        Sit back and relax while we sort out the   {" "}
                            <span className="light">
                            intricacies of<br />
                            your claim.
                            </span>{" "}
                        </h2>
                        <div className="col-xxl-6 col-xl-6 col-md-12 col-12">
                            <div className="fourbox">
                                <ul>
                                    <li>
                                        <img src={check} className="check" alt="" />
                                        From the assessment of damages to negotiation with insurers and managing the entire process from beginning to resolution, we have you covered at every stage. 

                                    </li>
                                    <hr />
                                    <li>
                                        <img src={check} className="check" alt="" />
                                        Just call us on our<strong> Kia Insurance claims number, </strong>  and our dedicated team will understand what you require and arrange an alternative car that can get you back on track again.                               </li>
                                    <hr />
                                    <li>
                                        <img src={check} className="check" alt="" />
                                        We will liaise with repairers in the UK to book repairs for your vehicle.
                                    </li>
                                    <hr />
                                    <li>
                                        <img src={check} className="check" alt="" />
                                        We will talk to all parties involved in the crash so that you can focus on other important things.                           </li>
                                </ul>
                            </div>
                        </div>
                        <div className="col-xxl-6 col-xl-6 col-md-12 col-12">
                            <div className="fourbox">
                                <ul>
                                    <li>
                                        <img src={check} className="check" alt="" />
                                        We have a no-fault accident solicitors panel who are experts at handling no-fault accidents. Call us on the <strong> Kia car insurance claims contact number </strong>  to get expert advice for your claim.                                 </li>
                                    <hr />
                                    <li>
                                        <img src={check} className="check" alt="" />
                                        We will make sure that your vehicle gets inspected by our authorised engineer.                           </li>
                                    <hr />
                                    <li>
                                        <img src={check} className="check" alt="" />
                                        If you want us to recover your damaged vehicle and return it after repairs, please dial the <strong> Kia car insurance claim number. </strong> 
                                        </li>
                                    <hr />
                                    <li>
                                        <img src={check} className="check" alt="" />
                                        Moreover, we will help you recover all costs paid for by you which are associated with an accident caused by someone else.                 </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="carprocess">
                <div className="container ">
                    <div className="row">
                        <div className="col-xxl-12 col-xxl-12 col-md-12 col-12">
                            <h2 className="trusted-title">
                                {" "}
                                Our accident management   <br />
                                <span className="light"> services  </span>{" "}
                            </h2>
                        </div>
                    </div>
                    <div className="row" id="possible">
                        <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-12">
                            <div className="claim">
                                <ul>
                                    <li>
                                        <span className="sleej">Car recovery:</span> Has your car been immobilised by an accident? If it has, contact us through the <strong> Kia insurance claims contact number </strong> for swift recovery of your vehicle. Our recovery team will recover your vehicle from the accident scene and transport it to a safe place.       
                                    </li>
                                    <li>
                                        <span className="sleej">Approved repairs:</span> Call us at the<strong> Kia car insurance claim number </strong>  so we can organise immediate repairs at any approved garage of your choice. This will ensure that our qualified repairers will restore your vehicle to its pre-accident condition, increasing its resale value.    

                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-12">
                            <div className="claim" id="blue">
                                <ul>
                                    <li>
                                        <span className="sleej">Vehicle storage:</span> If your car is not drivable, you may be unable to leave it on the roadside or outside your home. We will help you reduce this stress by keeping the car in our storage yards, which means no more damage or deterioration. Contact us on our insurance claim phone number
                                        <strong>
                                            {" "}
                                            <a href="tel:0800 772 0850">0800 772 0850</a>{" "}
                                        </strong> for secure storage of your vehicle.   
                                    </li>
                                    <li>
                                        <span className="sleej">Vehicle replacement :</span> We know what it feels like when you have no transport left with you. Our team will give you another vehicle similar to yours within a day to get you back on track again. You can keep the substitute car until yours is fixed or <strong> Kia car insurance claims </strong>  get compensated for. All fees and charges involved in obtaining a replacement vehicle would be recovered from the insurer of the guilty party's side.
                                          
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-12">
                            <div className="claim">
                                <ul>
                                    <li>
                                        <span className="sleej">Personal injury compensation claim  </span>
                                        Have you suffered injuries in a road traffic accident? Call us on the Kia car Insurance claim number. Our solicitors will guide you on the steps you should take to secure a deserving compensation for injuries.                               </li>
                                    <li>
                                        <span className="sleej">Claim management :</span>From the beginning of your <strong> Kia car insurance claim </strong>  until settlement, we offer a comprehensive range of claim management services such as vehicle recovery, replacement car arrangement, document management and claims assistance. 
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="yellowcta">
                <div className="container">
                    <div className="row">
                        <div className="cta2">
                            <div className="col-xxl-8 col-xl-8 col-lg-8 col-12">
                                <div className="legal">
                                    <h3 className="experienced">
                                    Got more questions? Talk to {" "}
                                        <span className="light">our specialists 
                                        </span>{" "}
                                    </h3>
                                    <p className="professionals">
                                    To get expert advice for your <strong>  Kia car insurance claim,</strong>
                                    contact our no-fault claim experts through the car insurance claim number 
                                        {" "}
                                        <strong>{" "}
                                            <a className="cpt-no" href="tel:0800 772 0850">
                                                {" "}
                                                0800 772 0850{" "}
                                            </a>{" "}
                                        </strong>
                                         
                                    </p>
                                </div>
                            </div>
                            <div className="col-xxl-4 col-xl-4 col-lg-4 col-12">
                                <div class="process">
                                    <a class="tf-button style-1 phone" href="tel:0800 772 0850">
                                        Get in touch<span class="icon-keyboard_arrow_right"></span>
                                    </a>
                                </div>
                                <div class="process">
                                    <a className="tf-button style-1 desktop" onClick={handleScrollToSidebar}>
                                        Get in touch<span className="icon-keyboard_arrow_right"></span>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <search className="updatedform">
                <div className="container">
                    <div className="row text-center">
                        <div className="col-xxl-12 col-xl-12 col-md-12 col-12">
                            <h2 className="whiteheading">
                                {" "}
                                What makes us different
                                <span className="light">  from others?
                                </span>
                            </h2>
                            <p className="sustained">
                            In case you were injured in a no-fault road accident in the UK, we are here to provide complete assistance with our wide array of services that include vehicle recovery, replacement car and claims assistance.                  </p>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-xxl-4 col-xl-4 col-lg-6 col-md-12 col-12">
                            <div className="Claimant">
                                <h5>Quick response</h5>
                                <p className="collision">
                                Our team remains on standby at all times to offer quick assistance and solutions on car accident issues.

                                </p>

                                <h5>Manufacturer-approved garages</h5>
                                <p className="collision">
                                We can ensure that your vehicle is repaired to the highest standards of excellence through our partnerships with different repair centres in the UK that follow manufacturer specifications and use genuine parts as well as paint to restore your vehicle regardless of its condition.                           </p>

                                <h5>Same-day replacement car</h5>
                                <p className="collision">
                                You can reach us via the <strong> Kia car insurance claims contact number </strong> to get a replacement car without any major disruptions.
                                </p>
                            </div>
                        </div>
                        <div className="col-xxl-4 col-xl-4 col-lg-6 col-md-12 col-12">
                            <div className="Claimant">
                                <h5>Claims handling
                                </h5>
                                <p className="collision">
                                Starting from initiating a <strong> Kia car insurance claim </strong>until its settlement, we provide the full range of services in connection with claim handling.</p>
                                <h5>No upfront costs</h5>
                                <p className="collision">
                                We have zero charges upfront for our accident management services. So, during times of emergencies, you can access dedicated support without financial obligations.                     </p>

                                <h5>24/7 breakdown assistance </h5>
                                <p className="collision">
                                Our vehicle recovery agents are always available to assist you with anything related to recovering your vehicle.  
                                </p>
                            </div>
                        </div>
                        <div className="col-xxl-4 col-xl-4 col-lg-12 col-md-12 col-12">
                            <div className="Claimant">
                                <h5>Free advice ‘’without obligation’’</h5>
                                <p className="collision">
                                Call the <strong> Kia insurance claim phone number </strong>  and receive no-obligation advice about your rights and options for seeking compensation.                            </p>

                                <h5>Specialist legal experts </h5>
                                <p className="collision">
                                For cases of no-fault road traffic accidents, our solicitors have great experience. They will provide professional advice regarding your individual situation.                           </p>

                                <h5>Personal advice  </h5>
                                <p className="collision">
                                After reviewing the details related to your Kia car insurance claim, we will provide specific directions concerning what could have been done to achieve the best result.                      </p>
                            </div>
                        </div>
                    </div>
                </div>
            </search>

            <section className="Accident-claim">
                <div className="container">
                    <div className="row">
                        <div className="needed">
                            <h2 className="trusted-title">
                            How can you get maximum compensation for <br />
                                <span className="light"> your Kia insurance claim?
                                </span>
                            </h2>
                            <p className="complexities">
                            Car accidents disturb daily routine and cause great concern. The first step forward in overcoming this is by receiving compensations, which are meant to cover the losses and injuries sustained through an accident. However, there are a few steps you should follow in order to augment the settlement amount, raising the value of your compensation.                     </p>
                        </div>
                        <hr />
                    </div>
                    <div className="row" id="incdes">
                        <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-12">
                            <div className="Preparing">
                                <ul>
                                    <li>
                                        <div className="seemingly">
                                            <h5 className="Seek">Go for medical help:</h5>
                                            <p>
                                                {" "}
                                                Get urgent medical attention for the treatment of your injuries and build up important records that would make your <strong> Kia insurance claim </strong>  stronger.                                    </p>
                                        </div>
                                    </li>
                                    <li>
                                        <div className="seemingly">
                                            <h5 className="Seek">
                                            Take account of damages :{" "}
                                            </h5>
                                            <p>
                                            Make sure you record all expenses associated with claims, such as medical bills, prescription costs, fare charges, and legal fees, among others.                                          </p>
                                        </div>
                                    </li>
                                    <li>
                                        <div className="seemingly">
                                            <h5 className="Seek">Collect evidence:</h5>
                                            <p> To strengthen your claim, take photos or videos of what exactly happened at the scene, acquire eyewitness statements, try accessing any CCTV footage from the scene and finally get a copy of the police report.                                      </p>
                                        </div>
                                    </li>
                                    <li>
                                        <div className="seemingly">
                                            <h5 className="Seek">Don't be too quick to settle:</h5>
                                            <p>
                                            It's advisable not to accept the initial offer of settlement immediately until you have consulted your legal representative, who will ensure that you get the correct amount of compensation in relation to loss or injury sustained by you.                                           </p>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div
                            className="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-12"
                            id="dualcardiv"
                        >
                            <img src={dualcar} alt="" className="dualcar" />
                        </div>
                        <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-12">
                            <div className="Preparing">
                                <ul>
                                    <li>
                                        <div className="seemingly">
                                            <h5 className="Seek">Do not use social media :</h5>
                                            <p>
                                            It is better to refrain from using social media while still waiting for <strong> Kia insurance claims. </strong>  Your social media posts can be used by other parties as a means of lowering your compensation.
                                   </p>
                                        </div>
                                    </li>
                                    <li>
                                        <div className="seemingly">
                                            <h5 className="Seek">Exchange information: </h5>
                                            <p>
                                            This would include exchanging names, phone numbers, car number plates and registration numbers, as well as insurance details with other people who were involved in the accident.
                                            </p>
                                        </div>
                                    </li>
                                    <li>
                                        <div className="seemingly">
                                            <h5 className="Seek">
                                            Hire a no-fault compensation solicitor:
                                            </h5>
                                            <p>
                                            To discuss your case and speak to a seasoned non-negligence claim expert, call us on the <strong> Kia car insurance claim number </strong>  so that our solicitors can help you make your maximum claim for compensation. 
                                            </p>
                                        </div>
                                    </li>
                                    <li>
                                        <div className="seemingly">
                                            <h5 className="Seek">
                                            Start your claim:
                                            </h5>
                                            <p>
                                            Once you’ve got everything ready, just call our <strong> Kia insurance claims number </strong>  to kickstart your claim. 


                                            </p>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="whyus">
                <div className="container">
                    <div className="row">
                        <div className="col-xxl-12 col-md-12">
                            <div className="why">
                                <h2 className="choose">
                                Make your claim with our easy and hassle-free {" "}
                                    <span className="light">  claims process </span>
                                </h2>
                                <p className="complexities">
                                When making a  <strong> Kia car insurance claim </strong> through us, there are no long waits or complicated processes. You can start your claim in just 4 simple steps with our fast-paced claims process.                 </p>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-xxl-3 col-xl-6 col-lg-6 col-md-6 col-12">
                            <div className="box-service">
                                <img src={check} className="check" alt="" />
                                {/* <img src={experience} className="experience" alt="" /> */}
                                <h4>Contact us </h4>
                                <p>
                                For free consultation, please call our solicitors anytime through our phone number for insurance claims
                                <br></br>
                                <strong>
                                            {" "}
                                            <a href="tel:0800 772 0850">0800 772 0850</a>{" "}
                                        </strong>  </p>
                            </div>
                        </div>
                        <div className="col-xxl-3 col-xl-6 col-lg-6 col-md-6 col-12">
                            <div className="box-service">
                                <img src={check} className="check" alt="" />
                                <h4>Share accident details </h4>
                                <p>
                                Tell us everything related to the accident, including the accident time, place, and cause of the accident.</p>
                            </div>
                        </div>
                        <div className="col-xxl-3 col-xl-6 col-lg-6 col-md-6 col-12">
                            <div className="box-service">
                                <img src={check} className="check" alt="" />
                                <h4>Submit evidence</h4>
                                <p>
                                Attach any relevant photos or videos taken at the scene of the accident and include receipts for other important documents, such as police reports and testimony from witnesses, in order to support your claims. 
                                </p>
                            </div>
                        </div>
                        <div className="col-xxl-3 col-xl-6 col-lg-6 col-md-6 col-12">
                            <div className="box-service">
                                <img src={check} className="check" alt="" />
                                <h4>Claim processing  </h4>
                                <p>
                                After we have received all supporting documentary evidence to substantiate your claim, we will initiate your claim. 
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="faqs">
                <div className="container">
                    <div className="row">
                        <div className="col-xxl-12 col-xl-12 col-md-12 col-12">
                            <div className="fq">
                                <h2>
                                    {" "}
                                    Frequently Asked{" "}
                                    <span className="light">Questions</span>
                                </h2>
                                <p className="complexities">
                                Got questions about the <strong>Kia car insurance claim? </strong> We have them answered here. 
                                </p>
                            </div>
                            <div className="allfaq">
                                <Accordion defaultActiveKey="0">
                                    <Accordion.Item eventKey="0">
                                        <Accordion.Header>
                                        Do I need to pay extra costs?                             </Accordion.Header>
                                        <Accordion.Body>
                                        You do not need to pay more because all costs will be recovered from the other party’s insurer.                                      </Accordion.Body>
                                    </Accordion.Item>
                                    <Accordion.Item eventKey="1">
                                        <Accordion.Header>
                                        Am I at liberty to choose my own repairer?
                                        </Accordion.Header>
                                        <Accordion.Body>
                                        Of course, yes. Feel free to select your preferred repairer. We will arrange the restoration of your vehicle from the garage of your choice.
                                        </Accordion.Body>
                                    </Accordion.Item>
                                    <Accordion.Item eventKey="2">
                                        <Accordion.Header>
                                        Will my no-claims bonus be affected if I use your service?
                                        </Accordion.Header>
                                        <Accordion.Body>
                                        No, by having one of our expert claim handlers make a direct claim against the third party, your no-claims bonus and insurance premiums will stay intact.                    </Accordion.Body>
                                    </Accordion.Item>
                                    <Accordion.Item eventKey="3">
                                        <Accordion.Header>
                                        How long can I keep the replacement vehicle?
                                        </Accordion.Header>
                                        <Accordion.Body>
                                        In the event that after a road accident, your car becomes unroadworthy and goes for repairs, you will be provided a substitute vehicle until your vehicle repairs are finished.                               </Accordion.Body>
                                    </Accordion.Item>
                                    <Accordion.Item eventKey="4">
                                        <Accordion.Header>
                                        Will the replacement vehicle be identical to my vehicle? 

                                        </Accordion.Header>
                                        <Accordion.Body>
                                        We always try our best to provide replacement vehicles regardless of the kind of car you have. If you phone us at the <strong> Kia car insurance claim number, </strong> we will deliver you a replacement vehicle as soon as possible.  
                                        </Accordion.Body>
                                    </Accordion.Item>
                                    <Accordion.Item eventKey="5">
                                        <Accordion.Header>
                                        Are the repairs guaranteed?{" "}
                                        </Accordion.Header>
                                        <Accordion.Body>
                                        Yes. Our manufacturer’s approved repairers aim to offer guarantees and high-quality repair works that go above and beyond manufacturer specifications.            </Accordion.Body>
                                    </Accordion.Item>
                                    <Accordion.Item eventKey="6">
                                        <Accordion.Header>
                                        Can I recover all the costs I incurred due to the accident?{" "}
                                        </Accordion.Header>
                                        <Accordion.Body>
                                        We can help you get back any expenses or losses incurred as a result of the car crash, such as medical bills, transport fares, rental charges for cars, service charges on cars, wages and salaries loss, pain compensation and damage to properties, among other expenses out of pocket.

                                        </Accordion.Body>
                                    </Accordion.Item>
                                </Accordion>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <Footer />
        </>
    );
};

export default Kiacarinsurance;
