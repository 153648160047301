import React, { useRef } from "react";
import Accordion from "react-bootstrap/Accordion";
import check from "../assets/images/check.png";
import dualcar from "../../src/assets/images/dualcar.webp";
import carbnr1 from "../../src/assets/images/carbnr1.webp";
import Sidebarinner from "../components/Sidebar-inner";
import phonecall from "../assets/images/phonecall.png";
import Footer from "../components/footer";
import { Helmet } from "react-helmet";

const Octagoninsurance = () => {
  const sidebarRef = useRef(null);

  const handleScrollToSidebar = () => {
    if (sidebarRef.current) {
      sidebarRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Octagon Car Insurance Accident Claim Phone Number:</title>
        <meta
          name="description"
          content="Contact the Octagon Car Insurance accident claim phone number 0800 772 0850 for emergency roadside assistance and expedited claims processing of your no-fault claim. "
        />
        <link
          rel="canonical"
          href="https://insurance-accident-claims.co.uk/octagon-insurance"
        />
      </Helmet>
      <section className="innernav">
        <div className="container">
          <div className="row">
            <div className="col-md-6 col-sm-6">
              <div className="left-logo">
                <h3>
                  Accident<span className="sipo"> Claims</span>
                </h3>
              </div>
            </div>
            <div className="col-md-6 col-sm-6">
              <div className="left-cta">
                <div class="headercnt">
                  <div class="chatmsg">
                    <img
                      src={phonecall}
                      className="phonecall"
                      alt="phonecall "
                    />
                  </div>
                  <div class="box-body">
                    <h3 class="nav-info-box-title">Toll Free Number </h3>
                    <p>
                      <a href="tel:0800 772 0850">0800 772 0850</a>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section
        className="newbnr"
        style={{ backgroundImage: "url(" + carbnr1 + ")" }}
      >
        <div className="container">
          <div className="row">
            <div className="col-xxl-8 col-xl-8 col-md-10 col-12">
              <div className="innerbnr">
                <h1>
                  <span className="onespan">Accident management </span>{" "}
                  <span className="sci">and claims </span> assistance for no-fault
                  car accidents
                </h1>
                <p className="brokerage">
                  Every aspect of your aftermath of the accident will be taken
                  care of by our team. We’ll help you with everything from
                  retrieving your vehicle to handling your claim until the end.{" "}
                </p>
                <div className="bnrbtn">
                  <a class="db-btn-border btn-rollover phone" href="tel:0800 772 0850">
                    Road Accident Claim Help?{" "}
                    <i class="fa-solid fa-arrow-right"></i>
                  </a>
                </div>
                <div className="bnrbtn">
                <a className="db-btn-border btn-rollover desktop"  onClick={handleScrollToSidebar}>
                    Road Accident Claim Help? <i className="fa-solid fa-arrow-right"></i>
                </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="stickysection">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="group-4-8">
                <div className="cl8 tf-tab">
                  <div className="content-tab">
                    <div className="inner-content">
                      <h2>
                        Claim compensation
                        <br />
                        <span className="light">without any excess</span>{" "}
                      </h2>
                      <p className="disciplines">
                        We have designed our accident management services mainly
                        to assist individuals who have been involved in
                        incidents that were not their fault and want to move on
                        quickly without much hassle. Our team will handle the
                        entire process right from vehicle collection through
                        claims management, making it less stressful for you as
                        an individual. Reach us via the{" "}
                        <strong>
                          {" "}
                          Octagon car insurance claims phone number{" "}
                        </strong>{" "}
                        to commence your claims process with us.
                      </p>
                      <hr />
                      <p className="bold-text">No excess charges</p>
                      <p className="bottom-te">
                        As opposed to your insurer, we do not require any
                        insurance excess because we make a direct claim against
                        the party responsible for causing the accident.
                      </p>
                      <hr />
                      <p className="bold-text">Maintained no-claims discount</p>
                      <p className="bottom-te">
                        When you select us for filing your case, you do not lose
                        years of safe driving that have contributed towards
                        acquiring a no-claims bonus over time.
                      </p>
                      <hr />
                      <p className="bold-text">Manufacturer-approved repairs</p>
                      <p className="bottom-te">
                        We work hand in hand with manufacturer-approved
                        repairers so as to guarantee that only genuine parts and
                        paint are used when carrying out repairs, thus ensuring
                        that repairs are done according to the manufacturer’s
                        strict standards.
                      </p>
                      <hr />
                      <p className="bold-text">Like-for-like replacement</p>
                      <p className="bottom-te">
                        We have premium services that make it possible for us to
                        provide you with a similar vehicle in terms of style,
                        size and quality.
                      </p>
                      <hr />
                      <p className="bold-text">Fast-paced claims process</p>
                      <p className="bottom-te">
                        Filing a fault claim can be quite intimidating. However,
                        once you call us at the{" "}
                        <strong>
                          {" "}
                          Octagon car accident claim phone number{" "}
                        </strong>{" "}
                        , we will speed up the claims process to ensure fast
                        resolution.
                      </p>
                      <hr />
                      <p className="bold-text">Free advice</p>
                      <p className="bottom-te">
                        Call our free <strong> Octagon claims line </strong>for
                        any advice without being obligated to anything and find
                        out whether we can assist you in getting compensation
                        for damages and losses you have incurred.
                      </p>
                      <hr />
                      <p className="bold-text">A dedicated manager</p>
                      <p className="bottom-te">
                        If you call us on the L & G UK claims line a dedicated
                        handler will be appointed who is skilled in professional
                        handling of no-fault claims.
                      </p>
                      <hr />
                      <p className="bold-text">Immediate response</p>
                      <p className="bottom-te">
                        As you go through stress during an accident, we are here
                        round the clock to quickly answer all your queries as
                        well as respond to various requests after an accident
                        has happened.
                      </p>
                      <hr />
                      <p className="bold-text">All-inclusive support</p>
                      <p className="bottom-te">
                        In addition, from recovering your vehicle through
                        managing repairs, arranging for a replacement vehicle
                        and handling your claim, we offer extensive support
                        throughout the whole process of claiming.
                      </p>
                      <hr />

                      <p className="bold-text">Post Inspection after repairs</p>
                      <p className="bottom-te">
                        Following repairs to your vehicle, we will conduct a
                        thorough examination of it to ensure that the repair
                        work is completed in accordance with the highest quality
                        and safety standards.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="cl4" ref={sidebarRef}>
                  <Sidebarinner />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="photographs">
        <div className="container">
          <div className="row">
            <h2 className="road">
              Make your claims journey much{" "}
              <span className="light">
                easier <br />
                and less stressful with us.
              </span>{" "}
            </h2>
            <div className="col-xxl-6 col-xl-6 col-md-12 col-12">
              <div className="fourbox">
                <ul>
                  <li>
                    <img src={check} className="check" alt="" />
                    WDial the <strong>
                      Octagon accident claim number
                    </strong>{" "}
                    after a no-fault car accident. We will control your accident
                    management requirements efficiently.
                  </li>
                  <hr />
                  <li>
                    <img src={check} className="check" alt="" />
                    We will ensure that your damaged vehicle is promptly
                    recovered from an accident site. This way you can become
                    nervous-less.
                  </li>
                  <hr />
                  <li>
                    <img src={check} className="check" alt="" />
                    An engineer shall be assigned to do the examination of the
                    car and carry out a valuation of the damages.
                  </li>
                  <hr />
                  <li>
                    <img src={check} className="check" alt="" />
                    In case your vehicle can be fixed, we are going to arrange a
                    manufacturer-approved repairer for the repairs on your
                    vehicle.
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-xxl-6 col-xl-6 col-md-12 col-12">
              <div className="fourbox">
                <ul>
                  <li>
                    <img src={check} className="check" alt="" />
                    Dial <a href="tel:0800 772 0850">0800 772 0850</a> through
                    our <strong> Octagon claims line </strong>, and we will
                    provide you with a replacement car of equal value in hours
                    so that you do not have to face any disruption in your daily
                    chores.
                  </li>
                  <hr />
                  <li>
                    <img src={check} className="check" alt="" />
                    Our solicitors will negotiate with the insurer for the other
                    party on your behalf and communicate with other parties.
                  </li>
                  <hr />
                  <li>
                    <img src={check} className="check" alt="" />
                    Through every stage of repair, we shall continually check on
                    it for you and keep informing you about its progress.
                  </li>
                  <hr />
                  <li>
                    <img src={check} className="check" alt="" />
                    Our solicitors are going to claim back all expenditure
                    incurred by you due to an accident from an insurer belonging
                    to another party.
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="carprocess">
        <div className="container ">
          <div className="row">
            <div className="col-xxl-12 col-xxl-12 col-md-12 col-12">
              <h2 className="trusted-title">
                {" "}
                Our accident management <br />
                <span className="light">services</span>{" "}
              </h2>
            </div>
          </div>
          <div className="row" id="possible">
            <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-12">
              <div className="claim">
                <ul>
                  <li>
                    <span className="sleej">Accident recovery:</span> Call us
                    via our <strong>Octagon car insurance claim number </strong>
                    to arrange for professional vehicle recovery in case your
                    car is not drivable anymore. We ensure quick and efficient
                    recovery of your vehicle, saving it from the condition it is
                    in by bringing it to a convenient place.
                  </li>
                  <li>
                    <span className="sleej">Approved repairs:</span>Contact us
                    at our <strong>Octagon insurance claim number</strong> if
                    you have a damaged motor vehicle which was caused by the
                    accident. We will organise quality, guaranteed repairs
                    through an approved repairer in our extensive network.
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-12">
              <div className="claim" id="blue">
                <ul>
                  <li>
                    <span className="sleej">Vehicle storage:</span> To ensure
                    secure vehicle storage after the accident, dial the{" "}
                    <strong>Octagon insurance claim number</strong> . While
                    waiting for your claims to be settled, we shall keep your
                    car within our safe yards where it cannot be damaged further
                    or stolen. Our storage facilities are always safe places
                    where you can take your car until the time your claim is
                    assessed.
                  </li>
                  <li>
                    <span className="sleej">Replacement vehicle: </span> We will
                    offer you a similar replacement car while yours is being
                    repaired. You can use the replacement car until your car is
                    fixed or your case is settled. Kindly, call us on{" "}
                    <a href="tel:0800 772 0850">0800 772 0850</a> to request for
                    an equivalent replacement car delivered right at your door.
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-12">
              <div className="claim">
                <ul>
                  <li>
                    <span className="sleej">Personal injury support:</span>
                    To claim compensation for injuries resulting from a road
                    accident, contact us on our helpline number -{" "}
                    <a href="tel:0800 772 0850">0800 772 0850</a> for advice
                    from our solicitors.
                  </li>
                  <li>
                    <span className="sleej">Claim administration:</span> From
                    documentation and paperwork processing to liaising with all
                    parties and ensuring that matters are resolved quickly, we
                    will take care of everything.
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="yellowcta">
        <div className="container">
          <div className="row">
            <div className="cta2">
              <div className="col-xxl-8 col-xl-8 col-lg-8 col-12">
                <div className="legal">
                  <h3 className="experienced">
                    Got more questions?{" "}
                    <span className="light">Talk to our specialists</span>{" "}
                  </h3>
                  <p className="professionals">
                    Get in touch with our no-fault claim experts via{" "}
                    <strong>
                      {" "}
                      car insurance claim phone number{" "}
                      <a className="cpt-no" href="tel:0800 772 0850">
                        0800 772 0850
                      </a>
                    </strong>{" "}
                    to get the best advice for your{" "}
                    <strong> Octagon car insurance claim. </strong>
                  </p>
                </div>
              </div>
              <div className="col-xxl-4 col-xl-4 col-lg-4 col-12">
              <div class="process">
                  <a class="tf-button style-1 phone" href="tel:0800 772 0850">
                    Get in touch<span class="icon-keyboard_arrow_right"></span>
                  </a>
                </div>
                <div class="process">
                    <a className="tf-button style-1 desktop"  onClick={handleScrollToSidebar}>
                        Get in touch<span className="icon-keyboard_arrow_right"></span>
                    </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <search className="updatedform">
        <div className="container">
          <div className="row text-center">
            <div className="col-xxl-12 col-xl-12 col-md-12 col-12">
              <h2 className="whiteheading">
                {" "}
                What makes us <br />
                <span className="light">different? </span>
              </h2>
              <p className="sustained">
                We have the best legal professionals in the UK who have
                successfully ensured the highest claims ever for victims of
                motor accidents. If you’ve been involved in an accident, please
                don’t hesitate to dial our emergency number{" "}
                <strong>
                  {" "}
                  <a href="tel:0800 772 0850">0800 772 0850</a>
                </strong>{" "}
                at any time.
              </p>
            </div>
          </div>
          <div className="row">
            <div className="col-xxl-4 col-xl-4 col-lg-6 col-md-12 col-12">
              <div className="Claimant">
                <h5>No fee for you</h5>
                <p className="collision">
                  Certainly, we have to eliminate your costs in what would be
                  described as out-of-pocket expenses which are associated with
                  our services that relate to accident management when we
                  recover these from the other party’s insurance company.
                </p>

                <h5>Insurance does not go higher in renewal</h5>
                <p className="collision">
                  If you decide to make your claim through us, your insurance
                  premiums will stay the same since you won’t submit a claim
                  under your own policy.
                </p>

                <h5>24/7 availability</h5>
                <p className="collision">
                  Our service is continuously available round the clock. You can
                  have peace of mind knowing that you have a standby helpline at
                  all times.
                </p>
              </div>
            </div>
            <div className="col-xxl-4 col-xl-4 col-lg-6 col-md-12 col-12">
              <div className="Claimant">
                <h5>Full support </h5>
                <p className="collision">
                  One of our objectives is to help drivers with the aftermath of
                  a car crash without having too much trouble. This can be
                  achieved through our roadside assistance services such as
                  recovering vehicles, arranging temporary substitute cars and
                  many more.
                </p>

                <h5>Safe vehicle storage</h5>
                <p className="collision">
                  We can provide secure areas where your damaged car can be
                  temporarily kept thereby eliminating any potential risk or
                  damage it may face.
                </p>

                <h5> Quality repairs performed </h5>
                <p className="collision">
                  When we repair your car using its genuine paint and
                  replacement parts we promise to deliver quality workmanship.
                  To schedule repairs for your damaged car, phone{" "}
                  <strong> Octagon claims line </strong>today.
                </p>
              </div>
            </div>
            <div className="col-xxl-4 col-xl-4 col-lg-12 col-md-12 col-12">
              <div className="Claimant">
                <h5>Immediate replacement car</h5>
                <p className="collision">
                  To minimise the inconvenience due to a vehicle accident, we
                  can send you an alternative car within 24 hours.
                </p>

                <h5>Personalised advice </h5>
                <p className="collision">
                  Our solicitors will offer assistance which helps you secure
                  maximum compensation for your losses.
                </p>

                <h5>No win no fee basis</h5>
                <p className="collision">
                  Whether or not solicitors are able to secure your case or
                  receive proper compensation, you do not have to pay any legal
                  fees.
                </p>
              </div>
            </div>
          </div>
        </div>
      </search>

      <section className="Accident-claim">
        <div className="container">
          <div className="row">
            <div className="needed">
              <h2 className="trusted-title">
                How does one maximise <br />
                <span className="light"> an insurance claim?</span>
              </h2>
              <p className="complexities">
                After involvement in a car accident through no fault of yours,
                filing claims becomes important as this helps you seek
                compensation for the damages and losses suffered. Here’s how to
                get the most out of your claim settlement value.
              </p>
            </div>
            <hr />
          </div>
          <div className="row" id="incdes">
            <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-12">
              <div className="Preparing">
                <ul>
                  <li>
                    <div className="seemingly">
                      <h5 className="Seek">Report the claim:</h5>
                      <p>
                        {" "}
                        Call police officers after a car accident to report the
                        matter officially. The police report may contain crucial
                        information that can be helpful in winning your case.{" "}
                      </p>
                    </div>
                  </li>
                  <li>
                    <div className="seemingly">
                      <h5 className="Seek"> Get emergency services: </h5>
                      <p>
                        In case you’re stuck on the road, call us immediately
                        for emergency services. Dial the{" "}
                        <strong> Octagon car insurance claim number </strong>{" "}
                        and we shall provide immediate roadside help so that you
                        can continue with your journey.
                      </p>
                    </div>
                  </li>
                  <li>
                    <div className="seemingly">
                      <h5 className="Seek">Record all expenses:</h5>
                      <p>
                        Keep a record of the expenditures you have made, such as
                        medical costs, travel expenses, legal fees or any other
                        out-of-pocket payments.
                      </p>
                    </div>
                  </li>
                  <li>
                    <div className="seemingly">
                      <h5 className="Seek">Collect evidence</h5>
                      <p>
                        Get evidence at the accident scene that will support
                        your claim like photos of the accident and injuries,
                        witness statements and CCTV videos.
                      </p>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
            <div
              className="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-12"
              id="dualcardiv"
            >
              <img src={dualcar} alt="" className="dualcar" />
            </div>
            <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-12">
              <div className="Preparing">
                <ul>
                  <li>
                    <div className="seemingly">
                      <h5 className="Seek">Avoid social media:</h5>
                      <p>
                        Refrain from posting on social media until your case has
                        started. Posts you make on social media could be used by
                        the opposing solicitor to destroy your claim.
                      </p>
                    </div>
                  </li>
                  <li>
                    <div className="seemingly">
                      <h5 className="Seek"> Exchange details </h5>
                      <p>
                        Exchange details with other drivers involved in the
                        accident. You should indicate personal info as well as
                        insurance details and car particulars.
                      </p>
                    </div>
                  </li>
                  <li>
                    <div className="seemingly">
                      <h5 className="Seek">
                        Consult an experienced claim expert:
                      </h5>
                      <p>
                        If you call us on the{" "}
                        <strong> Octagon insurance claim number. </strong> We
                        shall connect you with experienced UK solicitors who
                        have been dealing with complex scenarios related to car
                        accidents in practice.{" "}
                      </p>
                    </div>
                  </li>
                  <li>
                    <div className="seemingly">
                      <h5 className="Seek">Make your claim</h5>
                      <p>
                        When you have all relevant documents and evidence ready,
                        just call our{" "}
                        <strong>
                          {" "}
                          Octagon car insurance claims phone number{" "}
                        </strong>
                        to start your claim process.
                      </p>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="whyus">
        <div className="container">
          <div className="row">
            <div className="col-xxl-12 col-md-12">
              <div className="why">
                <h2 className="choose">
                  Simplified steps for{" "}
                  <span className="light">commencing your claim</span>
                </h2>
                <p className="complexities">
                  We have simplified our process so that you can easily make a
                  claim without going through the paperwork troubles. Get in
                  touch with us by calling the{" "}
                  <strong> Octagon insurance claim number </strong>, and leave
                  the rest to us.{" "}
                </p>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-xxl-3 col-xl-6 col-lg-6 col-md-6 col-12">
              <div className="box-service">
                <img src={check} className="check" alt="" />
                <h4>Talk to us</h4>
                <p>
                  Contact us on the <strong> Octagon claim line </strong>for
                  emergency assistance and book your free consultation with one
                  of our claims specialists.
                </p>
              </div>
            </div>
            <div className="col-xxl-3 col-xl-6 col-lg-6 col-md-6 col-12">
              <div className="box-service">
                <img src={check} className="check" alt="" />
                <h4>Provide accident information</h4>
                <p>
                  Share details about the accident, including when it took
                  place, where it occurred and how it happened.
                </p>
              </div>
            </div>
            <div className="col-xxl-3 col-xl-6 col-lg-6 col-md-6 col-12">
              <div className="box-service">
                <img src={check} className="check" alt="" />
                <h4>Share proof </h4>
                <p>
                  Please provide any evidence you have obtained in support of
                  your case. Also, pass on any relevant documents that will
                  enable us to progress your claim further.
                </p>
              </div>
            </div>
            <div className="col-xxl-3 col-xl-6 col-lg-6 col-md-6 col-12">
              <div className="box-service">
                <img src={check} className="check" alt="" />
                <h4>Follow up</h4>
                <p>
                  We are always keen on updating clients regarding the status of
                  their claims. Therefore, please keep in touch to know the
                  progress of your{" "}
                  <strong> Octagon car insurance claim. </strong>{" "}
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="faqs">
        <div className="container">
          <div className="row">
            <div className="col-xxl-12 col-xl-12 col-md-12 col-12">
              <div className="fq">
                <h2>
                  {" "}
                  Frequently Asked{" "}
                  <span className="light">Questions (FAQ)</span>
                </h2>
                <p className="complexities">
                  Got questions about the{" "}
                  <strong> Octagon car insurance claim? </strong> We have them
                  answered here.
                </p>
              </div>
              <div className="allfaq">
                <Accordion defaultActiveKey="0">
                  <Accordion.Item eventKey="0">
                    <Accordion.Header>
                      Are there hidden charges involved once I file a claim with
                      you?
                    </Accordion.Header>
                    <Accordion.Body>
                      We are transparent as we never charge any unexpected costs
                      or other hidden fees. We will take care of everything
                      while keeping only honesty and transparency in mind thus
                      recovering all the costs from the insurer of the third
                      party involved.
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="1">
                    <Accordion.Header>
                      Can you recover my car from the scene of an accident?
                    </Accordion.Header>
                    <Accordion.Body>
                      Yes. We’ll get your vehicle out of the accident scene and
                      send it to a certified body shop for repair.
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="2">
                    <Accordion.Header>
                      Will my NCB be affected?
                    </Accordion.Header>
                    <Accordion.Body>
                      No. Your hard-earned no-claims bonus will not be lost, so
                      you can rest assured that your NCB will be intact.
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="3">
                    <Accordion.Header>
                      Are the repairs on the car going to be under guarantee?{" "}
                    </Accordion.Header>
                    <Accordion.Body>
                      Yes. Your car repairs will be done by UK repairers
                      approved by the manufacturer and you can have peace of
                      mind that your car repairs are guaranteed.
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="4">
                    <Accordion.Header>
                      My vehicle is written off. What can you do for me?
                    </Accordion.Header>
                    <Accordion.Body>
                      Should your vehicle be deemed a total write-off, we will
                      ensure that you get a like-for-like replacement to keep
                      you mobile during this period until you obtain
                      compensation from third-party insurers.
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="5">
                    <Accordion.Header>
                      Will I have to pay extra if it wasn’t my fault?{" "}
                    </Accordion.Header>
                    <Accordion.Body>
                      No. When not at fault in such accidents, we never impose
                      additional charges on our clients as all expenses related
                      to it are usually claimed against the insurer of the other
                      party involved.
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="6">
                    <Accordion.Header>
                      After getting injured in an accident, how soon can I get
                      another car?
                    </Accordion.Header>
                    <Accordion.Body>
                      We will give you a replacement car in 24 hours if the
                      accident is not caused by you so that it doesn’t interfere
                      with your daily routine.
                    </Accordion.Body>
                  </Accordion.Item>
                </Accordion>
              </div>
            </div>
          </div>
        </div>
      </section>

      <Footer />
    </>
  );
};

export default Octagoninsurance;
