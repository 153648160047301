import React, { useRef } from "react";
import Accordion from "react-bootstrap/Accordion";
import check from "../assets/images/check.png";
import dualcar from "../../src/assets/images/dualcar.webp";
import hasting from "../../src/assets/images/hasting.webp";
import Sidebarinner from "../components/Sidebar-inner";
import phonecall from "../assets/images/phonecall.png";
import Footer from "../components/footer";
import { Helmet } from "react-helmet";

const Carrotinsurance = () => {
    const sidebarRef = useRef(null);

    const handleScrollToSidebar = () => {
        if (sidebarRef.current) {
            sidebarRef.current.scrollIntoView({ behavior: "smooth" });
        }
    };
    return (
        <>
        <Helmet>
        <meta charSet="utf-8" />
        <title>Carrot Car Insurance Accident Claim Phone Number</title>
        <meta
          name="description"
          content="Contact the Carrot Car Insurance Accident Claim Phone Number  0800 772 0850 for quick and stress-free claim assistance after a no-fault car accident in the UK. Get in touch with us today. "
        />
        <link
          rel="canonical"
          href="https://insurance-accident-claims.co.uk/Carrot-insurance"
        />
      </Helmet>
            <section className="innernav">
                <div className="container">
                    <div className="row">
                        <div className="col-md-6 col-sm-6">
                            <div className="left-logo">
                                <h3>
                                    Accident<span className="sipo"> Claims</span>
                                </h3>
                            </div>
                        </div>
                        <div className="col-md-6 col-sm-6">
                            <div className="left-cta">
                                <div class="headercnt">
                                    <div class="chatmsg">
                                        <img
                                            src={phonecall}
                                            className="phonecall"
                                            alt="phonecall "
                                        />
                                    </div>
                                    <div class="box-body">
                                        <h3 class="nav-info-box-title">Toll Free Number </h3>
                                        <p>
                                            <a href="tel:0800 772 0850">0800 772 0850</a>
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section
                className="newbnr"
                style={{ backgroundImage: "url(" + hasting + ")" }}
            >
                <div className="container">
                    <div className="row">
                        <div className="col-xxl-7 col-xl-7 col-md-10 col-12">
                            <div className="innerbnr">
                                <h1>
                                    <span className="onespan">Quick and reliable accident </span>{" "}
                                    <span className="sci">insurance  </span>  claims number
                                </h1>
                                <p className="brokerage">
                                    From the initial assessment to finalising your claim, we handle everything so that you can get back on the road as soon as possible.                                </p>
                                <div className="bnrbtn">
                                    <a class="db-btn-border btn-rollover phone" href="tel:0800 772 0850">
                                        Road Accident Claim Help?{" "}
                                        <i class="fa-solid fa-arrow-right"></i>
                                    </a>
                                </div>
                                <div className="bnrbtn">
                                    <a className="db-btn-border btn-rollover desktop" onClick={handleScrollToSidebar}>
                                        Road Accident Claim Help? <i className="fa-solid fa-arrow-right"></i>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="stickysection">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="group-4-8">
                                <div className="cl8 tf-tab">
                                    <div className="content-tab">
                                        <div className="inner-content">
                                            <h2>
                                                Dedicated claims handling experts
                                                <br />
                                                <span className="light"> in the UK </span>{" "}
                                            </h2>
                                            <p className="disciplines">
                                                We are one of the leading firms providing  <strong>
                                                    {" "}
                                                    Carrot insurance claims
                                                </strong>{" "}
                                                assistance in the UK and have successfully managed thousands of cases. In order to make a claim with us, contact us using the
                                                <strong>
                                                    {" "}
                                                    Carrot car insurance number.
                                                </strong>{" "}
                                                We will process your claim at no extra cost
                                            </p>
                                            <hr />
                                            <p className="bold-text">No excess</p>
                                            <p className="bottom-te">  If you did not cause an accident, you will not be required to pay any insurance excess, which eventually saves you from increased premiums.                                          </p>
                                            <hr />
                                            <p className="bold-text">Protected NCB</p>
                                            <p className="bottom-te">
                                                Your no-claim bonus won’t be affected when you make a claim through us. We guarantee its safety.                                             </p>
                                            <hr />
                                            <p className="bold-text">
                                                Fast recovery
                                            </p>
                                            <p className="bottom-te">
                                                We do not want you stranded by the roadside after an accident or collision. Dial emergency help on the<strong>Carrot car insurance contact number</strong>right away, and we shall ensure fast and secure vehicle recovery from the accident scene anytime.                                             </p>
                                            <hr />
                                            <p className="bold-text">High-quality repairs  </p>
                                            <p className="bottom-te">
                                                A network of approved garages by car manufacturers will ensure your vehicle gets repaired to the required standard.                                           </p>
                                            <hr />
                                            <p className="bold-text">Like-for-like replacement</p>
                                            <p className="bottom-te">
                                                When your car is under repair or not roadworthy, a like-for-like hire service can be arranged for you whereby you have another vehicle of the same make and model as yours.                                             </p>
                                            <hr />
                                            <p className="bold-text">Legal experts</p>
                                            <p className="bottom-te">
                                                Successful processing of <strong>Carrot insurance claims</strong> will be carried out by our learned solicitors who understand no-fault accident law and ensure full compensation for victims.
                                            </p>
                                            <hr />
                                            <p className="bold-text">Rapid settlement of claims</p>
                                            <p className="bottom-te">
                                                We have an effective and efficient claim process. This means that there is less paperwork and waiting time for our clients, making it possible to make your claim without having to get involved in paperwork.                                           </p>
                                            <hr />
                                            <p className="bold-text"> Free independent advice</p>
                                            <p className="bottom-te">
                                                You may call us free on  <strong>
                                                    {" "}
                                                    <a href="tel:0800 772 0850">0800 772 0850</a>{" "}
                                                </strong>  at any time with any questions regarding <strong> Carrot insurance claims </strong> without obligation. Our solicitors will advise and assist you on how best to go ahead with your case.                                          </p>
                                            <hr />
                                            <p className="bold-text">No win-no fee</p>
                                            <p className="bottom-te">
                                                Our solicitors work on a “no-win-no-fee” basis when handling clients’ insurance claims. This way, if you lose your claim, you don’t have to pay anything.                                          </p>
                                            <hr />

                                            <p className="bold-text">Around-the-clock coverage</p>
                                            <p className="bottom-te">
                                                We are always available in case of an accident to provide immediate emergency help and guidance. For immediate assistance after a non-fault car accident, call our car insurance claims line on <strong>
                                                    {" "}
                                                    <a href="tel:0800 772 0850">0800 772 0850</a>{" "}
                                                </strong>                                           </p>
                                        </div>
                                    </div>
                                </div>
                                <div className="cl4" ref={sidebarRef}>
                                    <Sidebarinner />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="photographs">
                <div className="container">
                    <div className="row">
                        <h2 className="road">
                            We will support you in relieving the pressure of managing   {" "}
                            <span className="light">
                                the <br />
                                aftermath of accidents.
                            </span>{" "}
                        </h2>
                        <div className="col-xxl-6 col-xl-6 col-md-12 col-12">
                            <div className="fourbox">
                                <ul>
                                    <li>
                                        <img src={check} className="check" alt="" />
                                        In case of emergencies following road traffic accidents, use the <strong> Carrot car insurance phone number </strong> to contact us.

                                    </li>
                                    <hr />
                                    <li>
                                        <img src={check} className="check" alt="" />
                                        We will find out whether there is someone at fault to sue for damages on your behalf.                                  </li>
                                    <hr />
                                    <li>
                                        <img src={check} className="check" alt="" />
                                        We will assist you in making  <strong>  Carrot insurance claims </strong> to get compensation for any losses suffered.
                                    </li>
                                    <hr />
                                    <li>
                                        <img src={check} className="check" alt="" />
                                        Our team will recover your vehicle from the accident scene and organise repairs via a network of manufacturer-approved repairers that we trust.                                </li>
                                </ul>
                            </div>
                        </div>
                        <div className="col-xxl-6 col-xl-6 col-md-12 col-12">
                            <div className="fourbox">
                                <ul>
                                    <li>
                                        <img src={check} className="check" alt="" />
                                        If it is not possible to have the vehicle repaired, we will arrange an independent engineer’s assessment of the damage caused.                                   </li>
                                    <hr />
                                    <li>
                                        <img src={check} className="check" alt="" />
                                        A substitute car will be given until your own has been repaired.                                    </li>
                                    <hr />
                                    <li>
                                        <img src={check} className="check" alt="" />
                                        We will keep you updated on how far your vehicle repairs have gone with your repairs.                                   </li>
                                    <hr />
                                    <li>
                                        <img src={check} className="check" alt="" />
                                        You need to contact us on  <strong>
                                            {" "}
                                            <a href="tel:0800 772 0850">0800 772 0850</a>{" "}
                                        </strong>  so that we can discuss further how you can make a <strong>Carrot insurance claim </strong> to get compensation for damages and losses.                               </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="carprocess">
                <div className="container ">
                    <div className="row">
                        <div className="col-xxl-12 col-xxl-12 col-md-12 col-12">
                            <h2 className="trusted-title">
                                {" "}
                                Our broad range of accident  <br />
                                <span className="light">management services  </span>{" "}
                            </h2>
                        </div>
                    </div>
                    <div className="row" id="possible">
                        <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-12">
                            <div className="claim">
                                <ul>
                                    <li>
                                        <span className="sleej">Vehicle recovery:</span> Should an accident occur, call the <strong >Carrot car insurance contact number </strong>  so that your vehicle will be recovered. Our vehicle recovery services are designed in such a manner that they guarantee you quick removal of your car from an accident scene and transport it safely into our storage yards.
                                    </li>
                                    <li>
                                        <span className="sleej">Approved repairs:</span>Contact us by dialling the <strong> Carrot insurance claim number  </strong>if you would like to have your vehicle repaired. We use reliable and trustworthy UK-based garages to carry out repairs without excess charges.

                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-12">
                            <div className="claim" id="blue">
                                <ul>
                                    <li>
                                        <span className="sleej">Vehicle storage:</span> Stressed over the safety of your vehicle after a car accident? Reach us through our  <strong>Carrot insurance claim number.</strong>  We have secured yards where we offer stress-free parking for several cars as claims are being sorted out.
                                    </li>
                                    <li>
                                        <span className="sleej">Replacement vehicles:</span>If Your car is beyond repair, you are eligible for a replacement vehicle. Do not hesitate to contact us through the hotline number
                                        <strong>
                                            {" "}
                                            <a href="tel:0800 772 0850">0800 772 0850</a>{" "}
                                        </strong>
                                        and get a like-for-like replacement vehicle that will enable you to continue your daily routine with no hassle. You can keep the replacement vehicle until your claim is settled.
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-12">
                            <div className="claim">
                                <ul>
                                    <li>
                                        <span className="sleej">Injury support </span>
                                        The best way to find out if you can be compensated for personal injuries is by calling our solicitors using the <strong>  Carrot insurance claim number. </strong>  Our solicitors shall advise on how you can get compensation for physical damages.                                     </li>
                                    <li>
                                        <span className="sleej">Claim handling:</span> We will handle everything–from paperwork to negotiating with insurers, organising repairs for your car and providing a replacement vehicle.
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="yellowcta">
                <div className="container">
                    <div className="row">
                        <div className="cta2">
                            <div className="col-xxl-8 col-xl-8 col-lg-8 col-12">
                                <div className="legal">
                                    <h3 className="experienced">
                                        Do you have more questions? Consult {" "}
                                        <span className="light"> our experts.
                                        </span>{" "}
                                    </h3>
                                    <p className="professionals">
                                        Call the car insurance contact number
                                        {" "}
                                        <strong>{" "}
                                            <a className="cpt-no" href="tel:0800 772 0850">
                                                {" "}
                                                0800 772 0850{" "}
                                            </a>{" "}
                                        </strong>
                                        and get professional advice regarding the
                                        <strong>Carrot insurance claims </strong>
                                    </p>
                                </div>
                            </div>
                            <div className="col-xxl-4 col-xl-4 col-lg-4 col-12">
                                <div class="process">
                                    <a class="tf-button style-1 phone" href="tel:0800 772 0850">
                                        Get in touch<span class="icon-keyboard_arrow_right"></span>
                                    </a>
                                </div>
                                <div class="process">
                                    <a className="tf-button style-1 desktop" onClick={handleScrollToSidebar}>
                                        Get in touch<span className="icon-keyboard_arrow_right"></span>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <search className="updatedform">
                <div className="container">
                    <div className="row text-center">
                        <div className="col-xxl-12 col-xl-12 col-md-12 col-12">
                            <h2 className="whiteheading">
                                {" "}
                                What makes us stand
                                <span className="light"> out?
                                </span>
                            </h2>
                            <p className="sustained">
                                We save you from the stress and burden that comes with being involved in a road traffic accident. Here are some benefits of choosing us as your dedicated partner for accident management needs.                    </p>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-xxl-4 col-xl-4 col-lg-6 col-md-12 col-12">
                            <div className="Claimant">
                                <h5> 24/7 vehicle recovery</h5>
                                <p className="collision">
                                    Do you need vehicle recovery services? Dial the <strong>  Carrot insurance claim number. </strong> No matter what time it is, we will get your vehicle out of the accident site.

                                </p>

                                <h5>Manufacturer-approved repairs</h5>
                                <p className="collision">
                                    Your car will be repaired by our manufacturer-approved repairers in a manner that is both excellent and guaranteed.                           </p>

                                <h5> No extra cost on your premium</h5>
                                <p className="collision">
                                    The  <strong>  Carrot car insurance claim  </strong>  will not be made against your own policy. This means that making a claim won’t lead to a raised premium in your insurance rates.
                                </p>
                            </div>
                        </div>
                        <div className="col-xxl-4 col-xl-4 col-lg-6 col-md-12 col-12">
                            <div className="Claimant">
                                <h5>Free comparable courtesy cars
                                </h5>
                                <p className="collision">
                                    We prefer to make you mobile by giving you a similar car without changing any excess from you.  </p>
                                <h5>A network of trusted partners</h5>
                                <p className="collision">
                                    We have created an extensive network consisting of preferred repairers across the UK as well as competent solicitors to ensure the best outcome for your claim.                                </p>

                                <h5> Quick settling of claims</h5>
                                <p className="collision">
                                    Our experienced solicitors will negotiate with the other driver’s insurer to ensure your <strong>Carrot motor insurance claim </strong>   is resolved fairly and quickly.
                                </p>
                            </div>
                        </div>
                        <div className="col-xxl-4 col-xl-4 col-lg-12 col-md-12 col-12">
                            <div className="Claimant">
                                <h5>Immediate assistance</h5>
                                <p className="collision">
                                    We guarantee immediate emergency support after a non-fault road traffic accident without any delay at all.                               </p>

                                <h5>Personalised guidance </h5>
                                <p className="collision">
                                    Our only job right now is to address your complaint. That’s why we’ll appoint a dedicated claims handler to take care of your claim alone throughout its duration and keep you updated with everything about its progress every step along the wa                             </p>

                                <h5>Zero surcharges/fees</h5>
                                <p className="collision">
                                    You are not expected to pay us directly, as we will recover all costs from the other party’s insurer.                            </p>
                            </div>
                        </div>
                    </div>
                </div>
            </search>

            <section className="Accident-claim">
                <div className="container">
                    <div className="row">
                        <div className="needed">
                            <h2 className="trusted-title">
                                How can you maximise the chances of getting  <br />
                                <span className="light"> enough compensation?</span>
                            </h2>
                            <p className="complexities">
                                It is a sad experience if you are involved in an accident that was not your fault, and it is even worse when someone has to go through the claims process. The best way to secure adequate compensation is by proving beyond any reasonable doubt that you were not at all responsible for the accident. If you want fair compensation following a car crash, then be sure to follow these steps.                             </p>
                        </div>
                        <hr />
                    </div>
                    <div className="row" id="incdes">
                        <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-12">
                            <div className="Preparing">
                                <ul>
                                    <li>
                                        <div className="seemingly">
                                            <h5 className="Seek">Ensure your safety:</h5>
                                            <p>
                                                {" "}
                                                Park the car somewhere safe, and check yourself and your passengers for serious injuries.                                       </p>
                                        </div>
                                    </li>
                                    <li>
                                        <div className="seemingly">
                                            <h5 className="Seek">
                                                Get help immediately from medical professionals.:{" "}
                                            </h5>
                                            <p>
                                                If any occupants of your vehicle are injured, phone an expert doctor right away.                                            </p>
                                        </div>
                                    </li>
                                    <li>
                                        <div className="seemingly">
                                            <h5 className="Seek">Collect evidence:</h5>
                                            <p> Gather evidence, including pictures or videos of the accident scene, individuals involved, damages caused to your vehicle, injury marks sustained during accidents and dashcam footage.                                         </p>
                                        </div>
                                    </li>
                                    <li>
                                        <div className="seemingly">
                                            <h5 className="Seek">Call police:</h5>
                                            <p>
                                                Report the incident to the police. The involvement of law enforcers will help prove your innocence, and the police report may serve as solid evidence to support your                                              </p>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div
                            className="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-12"
                            id="dualcardiv"
                        >
                            <img src={dualcar} alt="" className="dualcar" />
                        </div>
                        <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-12">
                            <div className="Preparing">
                                <ul>
                                    <li>
                                        <div className="seemingly">
                                            <h5 className="Seek">Exchange information:</h5>
                                            <p>
                                                Swap details with the other parties involved in the accident, including their full names, telephone numbers, addresses and plate numbers.                                              </p>
                                        </div>
                                    </li>
                                    <li>
                                        <div className="seemingly">
                                            <h5 className="Seek">Gather eyewitness data: </h5>
                                            <p>
                                                Contact details of f potential witnesses should be taken for future reference.                                             </p>
                                        </div>
                                    </li>
                                    <li>
                                        <div className="seemingly">
                                            <h5 className="Seek">
                                                Take note of expenditure :
                                            </h5>
                                            <p>
                                                It’s important to remember that the receipts for expenses incurred can be reimbursed in total. These may include costs for repair services, medical treatment or any other fees related to accidents.
                                            </p>
                                        </div>
                                    </li>
                                    <li>
                                        <div className="seemingly">
                                            <h5 className="Seek">
                                                Consult a no-fault solicitor :
                                            </h5>
                                            <p>
                                                Call our helpline number on{" "}
                                                <strong>{" "}
                                                    <a className="cpt-no" href="tel:0800 772 0850">
                                                        {" "}
                                                        0800 772 0850 {" "}
                                                    </a>{" "}
                                                </strong>
                                                and we can give you advice on how to make a successful claim to get deserving compensation.

                                            </p>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="whyus">
                <div className="container">
                    <div className="row">
                        <div className="col-xxl-12 col-md-12">
                            <div className="why">
                                <h2 className="choose">
                                    Begin your claim in four  {" "}
                                    <span className="light"> simple steps </span>
                                </h2>
                                <p className="complexities">
                                    It can be very daunting to make a <strong>Carrot car insurance claim </strong> following an accident which was not your fault.  Therefore, we will help you in this regard. Get started by calling us via the  <strong>Carrot car insurance phone number. </strong>                             </p>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-xxl-3 col-xl-6 col-lg-6 col-md-6 col-12">
                            <div className="box-service">
                                <img src={check} className="check" alt="" />
                                {/* <img src={experience} className="experience" alt="" /> */}
                                <h4>Contact Us </h4>
                                <p>
                                    Do not hesitate to get in touch with our team through the  <strong> Carrot car insurance contact number</strong>  to discuss your claim. </p>
                            </div>
                        </div>
                        <div className="col-xxl-3 col-xl-6 col-lg-6 col-md-6 col-12">
                            <div className="box-service">
                                <img src={check} className="check" alt="" />
                                <h4>Give details</h4>
                                <p>
                                    Provide us with all the details regarding the accident, such as date, time, location and what exactly happened.</p>
                            </div>
                        </div>
                        <div className="col-xxl-3 col-xl-6 col-lg-6 col-md-6 col-12">
                            <div className="box-service">
                                <img src={check} className="check" alt="" />
                                <h4>Share evidence</h4>
                                <p>
                                    Hand over evidence, such as crash photos, statements from eyewitnesses, police reports or videos from CCTV cameras, could support your case.                                </p>
                            </div>
                        </div>
                        <div className="col-xxl-3 col-xl-6 col-lg-6 col-md-6 col-12">
                            <div className="box-service">
                                <img src={check} className="check" alt="" />
                                <h4>Claims processing</h4>
                                <p>
                                    Your claim will be commenced after an assessment. Our team will let you know about the progress

                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="faqs">
                <div className="container">
                    <div className="row">
                        <div className="col-xxl-12 col-xl-12 col-md-12 col-12">
                            <div className="fq">
                                <h2>
                                    {" "}
                                    Frequently Asked{" "}
                                    <span className="light">Questions</span>
                                </h2>
                                <p className="complexities">
                                    Do you have questions about the <strong> Carrot insurance claims?</strong>  We have them answered here.

                                </p>
                            </div>
                            <div className="allfaq">
                                <Accordion defaultActiveKey="0">
                                    <Accordion.Item eventKey="0">
                                        <Accordion.Header>
                                            Should I pay an additional fee for the hire vehicle?                                      </Accordion.Header>
                                        <Accordion.Body>
                                            No, we take from the third-party insurer all expenses related to the replacement vehicle. You’re liable for excess charges only when you fail to cooperate with us, use a solicitor without our authority or provide wrong information.                                        </Accordion.Body>
                                    </Accordion.Item>
                                    <Accordion.Item eventKey="1">
                                        <Accordion.Header>
                                            How long does it take for my claim to settle?
                                        </Accordion.Header>
                                        <Accordion.Body>
                                            We will try our best to finalise your <strong>  Carrot motor insurance claim  </strong>    as soon as we can.  However, this will depend on the specifics of your case. We shall keep you posted once we initiate your application.                                      </Accordion.Body>
                                    </Accordion.Item>
                                    <Accordion.Item eventKey="2">
                                        <Accordion.Header>
                                            Does the at-fault party’s insurance cover the cost of vehicle recovery?
                                        </Accordion.Header>
                                        <Accordion.Body>
                                            Yes, everything involved in getting back the vehicle is funded by the guilty driver’s insurer. You may be required to prove that you deserve compensation with a police report or photos taken at the scene                              </Accordion.Body>
                                    </Accordion.Item>
                                    <Accordion.Item eventKey="3">
                                        <Accordion.Header>
                                            Can I get a substitute vehicle on a credit hire basis after a non-fault road traffic accident?
                                        </Accordion.Header>
                                        <Accordion.Body>
                                            Yes. The instant you report the loss to us via the <strong >Carrot car insurance phone number </strong> , we will let you use another vehicle similar to your own until your vehicle is back in operation.                                     </Accordion.Body>
                                    </Accordion.Item>
                                    <Accordion.Item eventKey="4">
                                        <Accordion.Header>
                                            Do I lose my no-claims bonus if it’s not my fault

                                        </Accordion.Header>
                                        <Accordion.Body>
                                            If you put a claim through us, we shall never make a claim against your own policy, and as such, we shall never forgo any of the years’ worth of bonuses or discounts that you have accumulated                               </Accordion.Body>
                                    </Accordion.Item>
                                    <Accordion.Item eventKey="5">
                                        <Accordion.Header>
                                            May I use my own garage when fixing up my car?{" "}
                                        </Accordion.Header>
                                        <Accordion.Body>
                                            Certainly! We can organise your vehicle to be repaired at your chosen garage. We will cooperate with your repairer to schedule high-quality and quick repairs.                                   </Accordion.Body>
                                    </Accordion.Item>
                                    <Accordion.Item eventKey="6">
                                        <Accordion.Header>
                                            What sort of car will I receive in exchange for mine? {" "}
                                        </Accordion.Header>
                                        <Accordion.Body>
                                            We shall organise a replacement vehicle similar in size, value and function to your own. Just dial us through our <strong> Carrot insurance claim number </strong>and tell us what you need. We will help you accordingly.

                                        </Accordion.Body>
                                    </Accordion.Item>
                                </Accordion>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <Footer />
        </>
    );
};

export default Carrotinsurance;
