import React, { useRef } from "react";
import Accordion from "react-bootstrap/Accordion";
import check from "../assets/images/check.png";
import dualcar from "../../src/assets/images/dualcar.webp";
import carbnr1 from "../../src/assets/images/carbnr1.webp";
import Sidebarinner from "../components/Sidebar-inner";
import phonecall from "../assets/images/phonecall.png";
import Footer from "../components/footer";
import { Helmet } from "react-helmet";

const Footmanjames = () => {
  const sidebarRef = useRef(null);

  const handleScrollToSidebar = () => {
    if (sidebarRef.current) {
      sidebarRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Footman James Car Insurance Accident Claim Phone Number:</title>
        <meta
          name="description"
          content="Get in touch with our team via the Footman James Car Insurance accident claim phone number 0800 772 0850 for quick, reliable and professional claim assistance. "
        />
        <link
          rel="canonical"
          href="https://insurance-accident-claims.co.uk/footman-james"
        />
      </Helmet>
      <section className="innernav">
        <div className="container">
          <div className="row">
            <div className="col-md-6 col-sm-6">
              <div className="left-logo">
                <h3>
                  Accident<span className="sipo"> Claims</span>
                </h3>
              </div>
            </div>
            <div className="col-md-6 col-sm-6">
              <div className="left-cta">
                <div class="headercnt">
                  <div class="chatmsg">
                    <img
                      src={phonecall}
                      className="phonecall"
                      alt="phonecall "
                    />
                  </div>
                  <div class="box-body">
                    <h3 class="nav-info-box-title">Toll Free Number </h3>
                    <p>
                      <a href="tel:0800 772 0850">0800 772 0850</a>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section
        className="newbnr"
        style={{ backgroundImage: "url(" + carbnr1 + ")" }}
      >
        <div className="container">
          <div className="row">
            <div className="col-xxl-8 col-xl-8 col-md-10 col-12">
              <div className="innerbnr">
                <h1>
                  <span className="onespan">Accident management</span>{" "}
                  <span className="sci">and claims assistance</span> in the UK
                </h1>
                <p className="brokerage">
                  We specialise in no-fault car accident claims management,
                  providing advice and help to claim compensation for no-fault
                  car accidents.
                </p>
                <div className="bnrbtn">
                  <a
                    class="db-btn-border btn-rollover phone"
                    href="tel:0800 772 0850"
                  >
                    Road Accident Claim Help?{" "}
                    <i class="fa-solid fa-arrow-right"></i>
                  </a>
                </div>
                <div className="bnrbtn">
                  <a
                    className="db-btn-border btn-rollover desktop"
                    onClick={handleScrollToSidebar}
                  >
                    Road Accident Claim Help?{" "}
                    <i className="fa-solid fa-arrow-right"></i>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="stickysection">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="group-4-8">
                <div className="cl8 tf-tab">
                  <div className="content-tab">
                    <div className="inner-content">
                      <h2>
                        We provide comprehensive
                        <br />
                        <span className="light">
                          accident support from start to finish{" "}
                        </span>{" "}
                      </h2>
                      <p className="disciplines">
                        We are a team of professionals and knowledgeable experts
                        who offer full support for those who have been injured
                        in a non-fault car accident. You should not pay anything
                        for our solicitors’ services, as they will guide you
                        through the entire process of handling the claim, and
                        show you possible alternatives to achieve success in
                        your claim, absolutely without any payment required.
                      </p>
                      <hr />
                      <p className="bold-text">No excess fee payable</p>
                      <p className="bottom-te">
                        When you make{" "}
                        <strong> Footman James car insurance claims </strong>{" "}
                        against third parties with us, we save you from having
                        to pay any additional charges if it was not your fault.{" "}
                      </p>
                      <hr />
                      <p className="bold-text">
                        Repairs approved by manufacturers
                      </p>
                      <p className="bottom-te">
                        Our carefully vetted network of professional UK
                        repairers ensures manufacturer-specified repairs are
                        carried out to high standards. Call us on{" "}
                        <strong> Footman James claims number </strong> and we
                        will arrange for repairs either through one of our
                        network repairers or at your chosen repair centre.
                      </p>
                      <hr />
                      <p className="bold-text">Comparable replacement</p>
                      <p className="bottom-te">
                        We will offer you a similar car, which is like-for-like,
                        in case your vehicle is no longer fit to drive. Call us
                        now on{" "}
                        <strong>Footman James' claims contact number </strong>
                        for a corresponding replacement vehicle.{" "}
                      </p>
                      <hr />
                      <p className="bold-text">Extensive experience </p>
                      <p className="bottom-te">
                        Our solicitors have wide-ranging experience in handling{" "}
                        <strong>Footman James car insurance claims</strong>
                        and securing the highest possible compensation to
                        non-fault victims. If you want to get maximum
                        compensation for your claim call us on the claims phone
                        number <a href="tel:0800 772 0850">
                          0800 772 0850
                        </a>.{" "}
                      </p>
                      <hr />
                      <p className="bold-text">Simplified claims process</p>
                      <p className="bottom-te">
                        Our simplified{" "}
                        <strong> Footman James car insurance claims </strong>
                        procedure does not involve any complex documents or
                        lengthy follow-ups. Just call our office through the{" "}
                        <strong>
                          {" "}
                          Footman James insurance claims contact number{" "}
                        </strong>{" "}
                        and state your allegations.
                      </p>
                      <hr />
                      <p className="bold-text">NCB remains unaltered </p>
                      <p className="bottom-te">
                        Filing a complaint with our company will not reduce your
                        years of NCB accumulation. Therefore, even after making
                        a <strong> Footman James car insurance claim </strong> ,
                        you can enjoy your hard-earned discounts.
                      </p>
                      <hr />
                      <p className="bold-text">
                        Free advice without obligations
                      </p>
                      <p className="bottom-te">
                        Contact us through the{" "}
                        <strong>
                          Footman James insurance claims contact number{" "}
                        </strong>{" "}
                        to discuss accident details and obtain free
                        no-obligation advice tailored specifically to you.
                      </p>
                      <hr />
                      <p className="bold-text">Recovering uninsured losses</p>
                      <p className="bottom-te">
                        With our dedicated support and guidance, we will help
                        you recover all the expenses you’ve incurred due to the
                        car accident from the negligent party’s insurer.{" "}
                      </p>
                      <hr />
                      <p className="bold-text">Secure vehicle storage</p>
                      <p className="bottom-te">
                        Call us on{" "}
                        <strong>Footman James insurance claims number </strong>
                        or safe storage of your vehicle post-accident. We will
                        store your car in our secured yards to safeguard it from
                        further damage.{" "}
                      </p>
                      <hr />

                      <p className="bold-text">Dedicated claims handler</p>
                      <p className="bottom-te">
                        To ensure personalised attention to your claim, we will
                        assign a dedicated claims handler who will always be on
                        hand to guide you with the{" "}
                        <strong> Footman James car insurance claims </strong>{" "}
                        process from start to finish.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="cl4" ref={sidebarRef}>
                  <Sidebarinner />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="photographs">
        <div className="container">
          <div className="row">
            <h2 className="road">
              We will assist you in recovering from{" "}
              <span className="light">
                no-fault <br />
                motor vehicle crash losses
              </span>{" "}
            </h2>
            <div className="col-xxl-6 col-xl-6 col-md-12 col-12">
              <div className="fourbox">
                <ul>
                  <li>
                    <img src={check} className="check" alt="" />
                    Call us at <strong> Footman James claims number </strong> in
                    case of an emergency as early as possible. We will make sure
                    that you and your vehicle are safe.
                  </li>
                  <hr />
                  <li>
                    <img src={check} className="check" alt="" />
                    We will arrange for you to be repaired by a trusted repairer
                    so that you can start using it again.
                  </li>
                  <hr />
                  <li>
                    <img src={check} className="check" alt="" />
                    Our solicitors will guide you on the best way forward to get
                    compensation for your injuries and losses.
                  </li>
                  <hr />
                  <li>
                    <img src={check} className="check" alt="" />
                    No-win no-fee is our approach where you do not have to pay
                    solicitors upfront fees if your claim does not succeed.
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-xxl-6 col-xl-6 col-md-12 col-12">
              <div className="fourbox">
                <ul>
                  <li>
                    <img src={check} className="check" alt="" />
                    Our team works together with all parties involved in
                    accidents so as to settle{" "}
                    <strong> Footman James car insurance claims </strong> in a
                    smooth and stress-free manner.
                  </li>
                  <hr />
                  <li>
                    <img src={check} className="check" alt="" />
                    Our solicitors simply make everything easy through their
                    experience without any issues.
                  </li>
                  <hr />
                  <li>
                    <img src={check} className="check" alt="" />
                    During the period when your car is being repaired, we can
                    organise another one on similar terms so that you will not
                    even worry about your daily tasks.
                  </li>
                  <hr />
                  <li>
                    <img src={check} className="check" alt="" />
                    The claimants whom we represent are often compensated for
                    other costs they endure because of being knocked down by a
                    negligent driver.
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="carprocess">
        <div className="container ">
          <div className="row">
            <div className="col-xxl-12 col-xxl-12 col-md-12 col-12">
              <h2 className="trusted-title">
                {" "}
                Our extensive range of accident <br />
                <span className="light">management services</span>{" "}
              </h2>
            </div>
          </div>
          <div className="row" id="possible">
            <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-12">
              <div className="claim">
                <ul>
                  <li>
                    <span className="sleej">Vehicle retrieval:</span>Do you need
                    a service to move your car after an accident? Call{" "}
                    <strong> Footman James claims phone number </strong>, and we
                    will quickly remove it from the place of the accident and
                    take it to a safe storage site.
                  </li>
                  <li>
                    <span className="sleej">Approved repairs:</span>If the
                    vehicle can be repaired, we will arrange for immediate and
                    guaranteed repairs at any garage of your choice. To arrange
                    repairs with a manufacturer-approved repairer, call us on
                    the insurance claims helpline{" "}
                    <a href="tel:0800 772 0850">0800 772 0850</a>.
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-12">
              <div className="claim" id="blue">
                <ul>
                  <li>
                    <span className="sleej">Vehicle storage:</span> We protect
                    your car from further damage by storing it in our secure
                    facilities. Call{" "}
                    <strong>
                      Footman James insurance claims contact number{" "}
                    </strong>{" "}
                    now to store your vehicle in our storage facilities
                    throughout the entire process without paying upfront fees.
                  </li>
                  <li>
                    <span className="sleej">Replacement car:</span>Whatever you
                    drive, we have a like-for-like replacement vehicle that
                    would best meet your daily needs. While awaiting repairs on
                    your car, retain the alternative vehicle to continue with
                    normal activities.
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-12">
              <div className="claim">
                <ul>
                  <li>
                    <span className="sleej">Personal injury support:</span>
                    We have panel solicitors who are experienced and available
                    across the UK to give quick advice as well as legal
                    representation that will assist you in achieving maximum
                    compensation for your personal injuries.
                  </li>
                  <li>
                    <span className="sleej">Claim management:</span>
                    When you report the occurrence of a car accident, we begin
                    working relentlessly in order to get your claim started and
                    resolved as quickly as possible. We have got you covered
                    from filing your claim to final settlement.
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="yellowcta">
        <div className="container">
          <div className="row">
            <div className="cta2">
              <div className="col-xxl-8 col-xl-8 col-lg-8 col-12">
                <div className="legal">
                  <h3 className="experienced">
                    Got more questions?{" "}
                    <span className="light">Talk to our specialists</span>{" "}
                  </h3>
                  <p className="professionals">
                    Get in touch with our no-fault claim experts via{" "}
                    <strong>
                      car insurance claim contact number{" "}
                      <a className="cpt-no" href="tel:0800 772 0850">
                        {" "}
                        0800 772 0850{" "}
                      </a>{" "}
                    </strong>
                    to get the best advice for your{" "}
                    <strong> Footman James car insurance claim. </strong>
                  </p>
                </div>
              </div>
              <div className="col-xxl-4 col-xl-4 col-lg-4 col-12">
                <div class="process">
                  <a class="tf-button style-1 phone" href="tel:0800 772 0850">
                    Get in touch<span class="icon-keyboard_arrow_right"></span>
                  </a>
                </div>
                <div class="process">
                  <a
                    className="tf-button style-1 desktop"
                    onClick={handleScrollToSidebar}
                  >
                    Get in touch
                    <span className="icon-keyboard_arrow_right"></span>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <search className="updatedform">
        <div className="container">
          <div className="row text-center">
            <div className="col-xxl-12 col-xl-12 col-md-12 col-12">
              <h2 className="whiteheading">
                {" "}
                What makes us different <br />
                <span className="light">from others?</span>
              </h2>
              <p className="sustained">
                Our stress-free accident management and assistance services for
                claims ensure that you do not have to deal with any form of
                trouble after road traffic accidents. Save your precious time,
                money and effort by calling us at{" "}
                <strong>
                  {" "}
                  Footman James car insurance claims contact number.{" "}
                </strong>
              </p>
            </div>
          </div>
          <div className="row">
            <div className="col-xxl-4 col-xl-4 col-lg-6 col-md-12 col-12">
              <div className="Claimant">
                <h5>No additional charges</h5>
                <p className="collision">
                  If it wasn’t your fault, we will not demand excesses from you,
                  but all the expenses incurred will be recovered from the
                  guilty party.
                </p>

                <h5>Comparable replacement </h5>
                <p className="collision">
                  We will get you back on track with a substitute vehicle
                  similar to yours which means that you drive a vehicle
                  identical to your own in terms of size, make and model.{" "}
                </p>

                <h5>Quality and guaranteed repairs</h5>
                <p className="collision">
                  Our manufacturer-approved repairers specialise in offering
                  quality repairs with genuine parts so that your car gets back
                  to its best possible condition.
                </p>
              </div>
            </div>
            <div className="col-xxl-4 col-xl-4 col-lg-6 col-md-12 col-12">
              <div className="Claimant">
                <h5>Free initial consultation </h5>
                <p className="collision">
                  Call our solicitors via{" "}
                  <strong>
                    {" "}
                    Footman James car insurance claims contact number{" "}
                  </strong>
                  to arrange for a free initial consultation. Find out what
                  options are available to you or which way is most appropriate
                  as advised by our legal experts.
                </p>
                <h5>Zero hold time</h5>
                <p className="collision">
                  Once dialled at{" "}
                  <strong>
                    {" "}
                    Footman James insurance claims contact number{" "}
                  </strong>
                  , there would be no waiting time involved when speaking with
                  one of our representatives. No matter the situation, our
                  solicitors are always there offering instant advice and
                  guidance.
                </p>

                <h5>Customised advice</h5>
                <p className="collision">
                  Consequently, our seasoned solicitors will offer
                  individualised advice that suits your situation and
                  circumstances.{" "}
                </p>
              </div>
            </div>
            <div className="col-xxl-4 col-xl-4 col-lg-12 col-md-12 col-12">
              <div className="Claimant">
                <h5>24/7 claims handling</h5>
                <p className="collision">
                  Car accidents can occur at any time. As such, we have a
                  dedicated team on standby throughout the day to provide
                  emergency assistance and address your concerns.
                </p>

                <h5>Expert claim management</h5>
                <p className="collision">
                  Through claiming initiation until finalisation, our dedicated
                  solicitors shall expertly handle your claim for prompt
                  settlement with no stress involved.
                </p>

                <h5>Proven record </h5>
                <p className="collision">
                  Our solicitors have successfully dealt with thousands of{" "}
                  <strong> Footman James car insurance claims </strong> before.
                  This means you can be confident of getting personalised
                  guidance that helps you make the most out of a settlement
                  offer.
                </p>
              </div>
            </div>
          </div>
        </div>
      </search>

      <section className="Accident-claim">
        <div className="container">
          <div className="row">
            <div className="needed">
              <h2 className="trusted-title">
                How do you improve the value of <br />
                <span className="light">
                  {" "}
                  Footman James Car Insurance Claim?
                </span>
              </h2>
              <p className="complexities">
                If you are injured in a car accident where the other party is at
                fault then you are entitled to receive compensation for your
                damages and financial losses. Here are a few ways to increase
                your claims settlement amount and ensure the success of your
                claim.{" "}
              </p>
            </div>
            <hr />
          </div>
          <div className="row" id="incdes">
            <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-12">
              <div className="Preparing">
                <ul>
                  <li>
                    <div className="seemingly">
                      <h5 className="Seek">Consult healthcare providers:</h5>
                      <p>
                        {" "}
                        When it comes to injuries resulting from faultless
                        traffic collisions, seeking medical attention should top
                        your list of priorities. In case you get injured as a
                        result of an accident, see the physician and keep your
                        medical record safe.{" "}
                      </p>
                    </div>
                  </li>
                  <li>
                    <div className="seemingly">
                      <h5 className="Seek">Report accident : </h5>
                      <p>
                        Contact the police to report the accident as soon as
                        possible. Additionally, make sure you get a copy of the
                        police report since it may contain important information
                        connected with your case.
                      </p>
                    </div>
                  </li>
                  <li>
                    <div className="seemingly">
                      <h5 className="Seek">Gather evidence:</h5>
                      <p>
                        If you have more evidence from the accident scene, it
                        will be very simple to put up a strong case. Collect as
                        much evidence as possible for{" "}
                        <strong> Footman James' car insurance claim </strong> in
                        order to have an advantage.
                      </p>
                    </div>
                  </li>
                  <li>
                    <div className="seemingly">
                      <h5 className="Seek">Stay cool</h5>
                      <p>
                        Don’t hurry over the settlement. Instead, talk through
                        the proposed settlement with your solicitor so that you
                        will be adequately compensated for all injuries and
                        losses.
                      </p>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
            <div
              className="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-12"
              id="dualcardiv"
            >
              <img src={dualcar} alt="" className="dualcar" />
            </div>
            <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-12">
              <div className="Preparing">
                <ul>
                  <li>
                    <div className="seemingly">
                      <h5 className="Seek">Quantify your losses :</h5>
                      <p>
                        Find out what money you can claim after adding up
                        medical expenses, repair quotes, loss of earnings and
                        other expenses that came about due to the incident.{" "}
                      </p>
                    </div>
                  </li>
                  <li>
                    <div className="seemingly">
                      <h5 className="Seek"> Exchange information</h5>
                      <p>
                        When making a{" "}
                        <strong> Footman James car insurance claim </strong>
                        against another driver, ensure that you exchange crucial
                        data such as contact details, vehicle registration
                        numbers and insurance policy details with them.
                      </p>
                    </div>
                  </li>
                  <li>
                    <div className="seemingly">
                      <h5 className="Seek">Talk to a no-fault claim expert:</h5>
                      <p>
                        You can reach us via{" "}
                        <strong>
                          {" "}
                          Footman James car insurance claims contact number{" "}
                        </strong>{" "}
                        and speak to one of our seasoned solicitors concerning
                        your claim.{" "}
                      </p>
                    </div>
                  </li>
                  <li>
                    <div className="seemingly">
                      <h5 className="Seek">Start your claim</h5>
                      <p>
                        Connect us via the{" "}
                        <strong>
                          {" "}
                          Footman James car insurance claims number{" "}
                        </strong>{" "}
                        after you have compiled all the necessary documents and
                        evidence required for a stronger claim.
                      </p>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="whyus">
        <div className="container">
          <div className="row">
            <div className="col-xxl-12 col-md-12">
              <div className="why">
                <h2 className="choose">
                  Our hassle-free and simplified{" "}
                  <span className="light">claim process</span>
                </h2>
                <p className="complexities">
                  Making a claim can be really daunting, especially if it is
                  your first time. Luckily, we will take you through a
                  simplified and streamlined claims process through which we can
                  make a <strong> Footman James car insurance claim </strong>
                  without any trouble. Dial{" "}
                  <a href="tel:0800 772 0850">0800 772 0850</a> to start your
                  claims process now.
                </p>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-xxl-3 col-xl-6 col-lg-6 col-md-6 col-12">
              <div className="box-service">
                <img src={check} className="check" alt="" />
                {/* <img src={experience} className="experience" alt="" /> */}
                <h4>Claim reporting</h4>
                <p>
                  Call us on the <strong>Footman James claims number </strong>so
                  that we can discuss your case with you in relation to our
                  solicitors.
                </p>
              </div>
            </div>
            <div className="col-xxl-3 col-xl-6 col-lg-6 col-md-6 col-12">
              <div className="box-service">
                <img src={check} className="check" alt="" />
                <h4>Share accident details and proofs</h4>
                <p>
                  Give out explanations concerning the accident and send us what
                  has made up your important evidence from the occurrence site
                  of accidents.
                </p>
              </div>
            </div>
            <div className="col-xxl-3 col-xl-6 col-lg-6 col-md-6 col-12">
              <div className="box-service">
                <img src={check} className="check" alt="" />
                <h4>Claim assessment</h4>
                <p>
                  Our solicitors will review your application and determine the
                  damages that have resulted from the road traffic accident
                  leading to your injuries.{" "}
                </p>
              </div>
            </div>
            <div className="col-xxl-3 col-xl-6 col-lg-6 col-md-6 col-12">
              <div className="box-service">
                <img src={check} className="check" alt="" />
                <h4>Claim resolution</h4>
                <p>
                  We shall initiate a fast-moving process for resolving your
                  claim so as to ensure that it does not escalate into something
                  more serious.{" "}
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="faqs">
        <div className="container">
          <div className="row">
            <div className="col-xxl-12 col-xl-12 col-md-12 col-12">
              <div className="fq">
                <h2>
                  {" "}
                  Frequently Asked{" "}
                  <span className="light">Questions (FAQ)</span>
                </h2>
                <p className="complexities">
                  Got questions about the{" "}
                  <strong> Footman James car insurance claim? </strong>We have
                  them answered here.
                </p>
              </div>
              <div className="allfaq">
                <Accordion defaultActiveKey="0">
                  <Accordion.Item eventKey="0">
                    <Accordion.Header>
                      Should I agree to the other party’s insurance company’s
                      first offer of payment?
                    </Accordion.Header>
                    <Accordion.Body>
                      By accepting any out-of-court settlement offered by the
                      insurer representing the other side, you forfeit your
                      rights to file a{" "}
                      <strong> Footman James car insurance claim </strong> in
                      future. That is why it is important for you to discuss
                      this matter with your solicitor before accepting their
                      proposal.
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="1">
                    <Accordion.Header>
                      How long should I expect my claim to take?
                    </Accordion.Header>
                    <Accordion.Body>
                      Settlement times can vary depending on the complexity of
                      your claim and any supporting information you may supply.
                      If you reach us at{" "}
                      <strong> Footman James claims contact number </strong> ,
                      we will work diligently towards giving it priority so that
                      it can be solved within no time.
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="2">
                    <Accordion.Header>
                      Can I select my own garage?
                    </Accordion.Header>
                    <Accordion.Body>
                      Yeah absolutely. You are free to choose a repairer who
                      will fix your vehicle back into its condition before the
                      accident happens. Nonetheless, we also have an extensive
                      network of manufacturer-approved repairers across the UK
                      who would ensure that your vehicle gets repaired up to the
                      highest quality standards.
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="3">
                    <Accordion.Header>
                      Do I get a replacement vehicle when mine is being fixed?{" "}
                    </Accordion.Header>
                    <Accordion.Body>
                      Yes certainly. We will give you another car similar to
                      yours in order not to keep you stationary during repairs.
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="4">
                    <Accordion.Header>
                      When filing a claim, what kind of information should I
                      provide?
                    </Accordion.Header>
                    <Accordion.Body>
                      For you to make your claim through the{" "}
                      <strong> Footman James claims phone number </strong> , we
                      will require you to provide details that pertain to the
                      accident. Some of these include the date, time and
                      location of the accident, description of damages, other
                      persons involved in the accident, and their contacts among
                      others. This information could make our{" "}
                      <strong> Footman James car insurance claims </strong> more
                      effective.
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="5">
                    <Accordion.Header>
                      What would happen if my vehicle was written off
                      completely?{" "}
                    </Accordion.Header>
                    <Accordion.Body>
                      In this case, where your car is termed as a total loss our
                      solicitors will negotiate with the insurer of the other
                      party for a fair settlement value based on the market
                      value of your car. We shall also give you another car
                      while we settle your claim at hand.{" "}
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="6">
                    <Accordion.Header>
                      Should I get a police report?{" "}
                    </Accordion.Header>
                    <Accordion.Body>
                      The police report may have important information regarding
                      injuries sustained during accidents and other specifics
                      relating to such events. Therefore it can be an essential
                      tool for making a strong insurance assertion.
                    </Accordion.Body>
                  </Accordion.Item>
                </Accordion>
              </div>
            </div>
          </div>
        </div>
      </section>

      <Footer />
    </>
  );
};

export default Footmanjames;
