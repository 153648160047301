import React, { useRef } from "react";
import Accordion from "react-bootstrap/Accordion";
import check from "../assets/images/check.png";
import dualcar from "../../src/assets/images/dualcar.webp";
import carbnr2 from "../../src/assets/images/carbnr2.webp";
import Sidebarinner from "../components/Sidebar-inner";
import phonecall from "../assets/images/phonecall.png";
import Footer from "../components/footer";
import { Helmet } from "react-helmet";

const Thinkinsurance = () => {
    const sidebarRef = useRef(null);

    const handleScrollToSidebar = () => {
        if (sidebarRef.current) {
            sidebarRef.current.scrollIntoView({ behavior: "smooth" });
        }
    };
    return (
        <>
         <Helmet>
        <meta charSet="utf-8" />
        <title>Think Insurance Car Insurance Claim Phone Number</title>
        <meta
          name="description"
          content="Have you been through a no-fault car accident? Contact our team via the Think Insurance Car Insurance Claim Phone Number 0800 772 0850 for reliable and fast support."
        />
        <link
          rel="canonical"
          href="https://insurance-accident-claims.co.uk/Think-insurance"
        />
      </Helmet>
            <section className="innernav">
                <div className="container">
                    <div className="row">
                        <div className="col-md-6 col-sm-6">
                            <div className="left-logo">
                                <h3>
                                    Accident<span className="sipo"> Claims</span>
                                </h3>
                            </div>
                        </div>
                        <div className="col-md-6 col-sm-6">
                            <div className="left-cta">
                                <div class="headercnt">
                                    <div class="chatmsg">
                                        <img
                                            src={phonecall}
                                            className="phonecall"
                                            alt="phonecall "
                                        />
                                    </div>
                                    <div class="box-body">
                                        <h3 class="nav-info-box-title">Toll Free Number </h3>
                                        <p>
                                            <a href="tel:0800 772 0850">0800 772 0850</a>
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section
                className="newbnr"
                style={{ backgroundImage: "url(" + carbnr2 + ")" }}
            >
                <div className="container">
                    <div className="row">
                        <div className="col-xxl-7 col-xl-7 col-md-10 col-12">
                            <div className="innerbnr">
                                <h1>
                                    <span className="onespan">No-Fault Accident and Claims</span>{" "}
                                    <span className="sci"> Management in </span> the UK
                                </h1>
                                <p className="brokerage">
                                We offer a wide range of accident management solutions to help you get back on the road after a no-fault car accident.
                                </p>
                                <div className="bnrbtn">
                                    <a class="db-btn-border btn-rollover phone" href="tel:0800 772 0850">
                                        Road Accident Claim Help?{" "}
                                        <i class="fa-solid fa-arrow-right"></i>
                                    </a>
                                </div>
                                <div className="bnrbtn">
                                    <a className="db-btn-border btn-rollover desktop" onClick={handleScrollToSidebar}>
                                        Road Accident Claim Help? <i className="fa-solid fa-arrow-right"></i>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="stickysection">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="group-4-8">
                                <div className="cl8 tf-tab">
                                    <div className="content-tab">
                                        <div className="inner-content">
                                            <h2>
                                            Expert claim assistance with zero 
                                                <br />
                                                <span className="light"> excess payment </span>{" "}
                                            </h2>
                                            <p className="disciplines">
                                            We work for victims of no-fault car accident incidents to ensure they receive emergency aid without extra expenses and claim assistance from professionals. For top-notch support following a non-fault motor vehicle crash, contact our dedicated team through the <strong>Think claims number.</strong> 
                                            </p>
                                            <hr />
                                            <p className="bold-text">No hidden charges </p>
                                            <p className="bottom-te">
                                            We do not charge an insurance excess on your own policy upon making your <strong> Think accident claim </strong> through us                                           </p>
                                            <hr />
                                            <p className="bold-text"> Fast response</p>
                                            <p className="bottom-te">
                                            Once you dial the <strong> Think claim line, </strong> be confident that help will be made available immediately.                                    </p>
                                            <hr />
                                            <p className="bold-text">
                                            Manufacturer-backed repairs
                                            </p>
                                            <p className="bottom-te">
                                            Our manufacturer-approved repair network guarantees your vehicle is restored to its highest potential standards.                                       </p>
                                            <hr />
                                            <p className="bold-text">Instant replacement vehicle </p>
                                            <p className="bottom-te">
                                            In case your car is written off, we can give you a same-day like-for-like courtesy car at absolutely no cost.                                     </p>
                                            <hr />
                                            <p className="bold-text">No-claims bonus protection</p>
                                            <p className="bottom-te">
                                            Your no-claims discount and various other bonuses will not be affected because we are not going to make any claim against your insurance.                                </p>
                                            <hr />
                                            <p className="bold-text">24-hour assistance</p>
                                            <p className="bottom-te">
                                            Dial the <strong>Think claims number </strong> in order to get roadside recovery immediately if you have an accident. Our traffic accident management team is available throughout the day and night.                                            </p>
                                            <hr />
                                            <p className="bold-text">Free independent guidance</p>
                                            <p className="bottom-te">
                                            If you would like to know your options and rights, get a free legal consultation today. Speak with one of our experienced solicitors 24/7 on
                                            <strong>
                                            {" "}
                                            <a href="tel:0800 772 0850">0800 772 0850</a>{" "}
                                        </strong> without any obligation.                                    </p>
                                            <hr />
                                            <p className="bold-text">Quick claim resolution  </p>
                                            <p className="bottom-te">
                                            With our simplified and streamlined claims process, your <strong> Think car insurance claim </strong>can be sorted out faster than ever before                                            </p>
                                            <hr />
                                            <p className="bold-text">Experienced claims handler </p>
                                            <p className="bottom-te">
                                            You will have an experienced claims handler who will take you through the process of making <strong>Think car insurance claims </strong>  for accidents, making it easier for you.                                            </p>
                                            <hr />

                                            <p className="bold-text">Nationwide recovery service </p>
                                            <p className="bottom-te">
                                            Just give us a call using the <strong> Think car insurance contact number </strong> provided above, and we can send our agents to retrieve your vehicle after it has been damaged by an accident and drive it safely to one of our storage yards.                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div className="cl4" ref={sidebarRef}>
                                    <Sidebarinner />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="photographs">
                <div className="container">
                    <div className="row">
                        <h2 className="road">
                        We deal with every step of the claim while you recover   {" "}
                            <span className="light">
                            from <br />
                              the accident 
                            </span>{" "}
                        </h2>
                        <div className="col-xxl-6 col-xl-6 col-md-12 col-12">
                            <div className="fourbox">
                                <ul>
                                    <li>
                                        <img src={check} className="check" alt="" />
                                        In case of a non-fault road traffic accident, we shall retrieve your car while handling all the aspects related to your Think accident claim.

                                    </li>
                                    <hr />
                                    <li>
                                        <img src={check} className="check" alt="" />
                                        To get your car repaired properly, please contact us at <strong>Think claims number.</strong>  We will arrange for it to be done quickly at a garage of your choice.                                   </li>
                                    <hr />
                                    <li>
                                        <img src={check} className="check" alt="" />
                                        We will send a separate engineer to assess the damage that was caused to your vehicle.                                 </li>
                                    <hr />
                                    <li>
                                        <img src={check} className="check" alt="" />
                                        If you have been hurt in an accident and are looking for guidance on making a <strong> Think accident claim </strong>so that you can be compensated fairly for your injuries, our solicitors will help you with this.                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div className="col-xxl-6 col-xl-6 col-md-12 col-12">
                            <div className="fourbox">
                                <ul>
                                    <li>
                                        <img src={check} className="check" alt="" />
                                        Assistance will be provided in assessing loss of earnings and property damage as well as recovering these from the insurance company of the responsible party.                                 </li>
                                    <hr />
                                    <li>
                                        <img src={check} className="check" alt="" />
                                        Need a courtesy car? Call us now using the <strong>Think claim line.</strong>  For all the time when your car is being fixed, we will bring you another ride having similar specifications as your own vehicle.                                 </li>
                                    <hr />
                                    <li>
                                        <img src={check} className="check" alt="" />
                                        Our personal injury team is experienced enough to enable you to recover any uninsured losses that were due to their fault.                                    </li>
                                    <hr />
                                    <li>
                                        <img src={check} className="check" alt="" />
                                        No win, no fee guarantee implies that if your claim fails, then there is nothing to lose since upfront payments are not necessary with respect to <strong>Think accident claim </strong>                                  </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="carprocess">
                <div className="container ">
                    <div className="row">
                        <div className="col-xxl-12 col-xxl-12 col-md-12 col-12">
                            <h2 className="trusted-title">
                                {" "}
                                Our accident management  <br />
                                <span className="light">services</span>{" "}
                            </h2>
                        </div>
                    </div>
                    <div className="row" id="possible">
                        <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-12">
                            <div className="claim">
                                <ul>
                                    <li>
                                        <span className="sleej">Car recovery :</span>In case you have been involved in a non-fault accident, feel free to call us at the <strong>  Think claims contact number</strong>    so that we may offer quick and professional assistance in order for your car to be taken away safely.
                                    </li>
                                    <li>
                                        <span className="sleej">Accident repairs :</span>    If you want top-quality and cost-effective car repairs, contact the <strong> Think claim line. </strong>We are connected to many manufacturers and authorised repairers across the UK who will speedily fix your car so that you can get back on the road as soon as possible. 
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-12">
                            <div className="claim" id="blue">
                                <ul>
                                    <li>
                                        <span className="sleej">Vehicle storage :</span>    Call us using the <strong>Think claims contact number </strong>  to have your vehicle towed away from the scene of an accident and kept in a safe place with us. Your motorcar will be removed from where it was after being involved in an accident and stored safely until after repairs or evaluation for compensation purposes. 
                                    </li>
                                    <li>
                                        <span className="sleej">Replacement vehicles:</span> For no-fault road traffic accident recovery, call us on the <stron>Think claim line.</stron>  When you dial this number, we take responsibility for providing you with another similar borrowed vehicle at no cost to you whatsoever before getting yours fixed.
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-12">
                            <div className="claim">
                                <ul>
                                    <li>
                                        <span className="sleej">Personal injury support :</span>
                                        We can provide legal advice if you have been injured in a car accident and also instruct a competent solicitor to assist you through the process of making a <strong>Think car insurance claim.</strong>                                    </li>
                                    <li>
                                        <span className="sleej">Claim management:</span> We will take care of your <strong>Think car insurance claim </strong>  from start to finish, including providing legal advice, arranging repairs on your motor vehicle and working with everyone involved in the accident.
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="yellowcta">
                <div className="container">
                    <div className="row">
                        <div className="cta2">
                            <div className="col-xxl-8 col-xl-8 col-lg-8 col-12">
                                <div className="legal">
                                    <h3 className="experienced">
                                    Have more questions? Speak to  {" "}
                                        <span className="light"> our experts.
                                        </span>{" "}
                                    </h3>
                                    <p className="professionals">
                                    Call us on the car insurance claims number
                                        {" "}
                                        <strong>{" "}
                                             <a className="cpt-no" href="tel:0800 772 0850">
                                                {" "}
                                                 0800 772 0850{" "}
                                            </a>{" "}
                                        </strong>
                                        for exceptional advice on
                                        <strong>Think accident claims.  </strong>
                                    </p>
                                </div>
                            </div>
                            <div className="col-xxl-4 col-xl-4 col-lg-4 col-12">
                                <div class="process">
                                    <a class="tf-button style-1 phone" href="tel:0800 772 0850">
                                        Get in touch<span class="icon-keyboard_arrow_right"></span>
                                    </a>
                                </div>
                                <div class="process">
                                    <a className="tf-button style-1 desktop" onClick={handleScrollToSidebar}>
                                        Get in touch<span className="icon-keyboard_arrow_right"></span>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <search className="updatedform">
                <div className="container">
                    <div className="row text-center">
                        <div className="col-xxl-12 col-xl-12 col-md-12 col-12">
                            <h2 className="whiteheading">
                                {" "}
                                Why are we your best choice 
                                <span className="light">  for accident management services?
                                </span>
                            </h2>
                            <p className="sustained">
                            We understand how road traffic accidents can flip your life upside down. Nonetheless, when it comes to no-fault car accidents, we are your reliable partners who can help you get back on track. From the time you report an accident till all repair costs and replacements are claimed from the other party, we have you covered for all your needs.                             </p>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-xxl-4 col-xl-4 col-lg-6 col-md-12 col-12">
                            <div className="Claimant">
                                <h5>Fast recovery after a collision</h5>
                                <p className="collision">
                                To restore your safety and that of your vehicle, call us through our <strong> Think claims contact number.</strong> 

                                </p>

                                <h5>Genuine manufacturer parts and paints </h5>
                                <p className="collision">
                                Contact our team via the <strong>Think claim car insurance claims number, </strong> and we will organise swift repairs through well-trained repairers who will replace using authentic spares.                               </p>

                                <h5>Expert claim management</h5>
                                <p className="collision">
                                Our solicitors will assess your car for damage and negotiate with the other party’s insurer so that you get prompt compensation.                               </p>
                            </div>
                        </div>
                        <div className="col-xxl-4 col-xl-4 col-lg-6 col-md-12 col-12">
                            <div className="Claimant">
                                <h5>24/7 support
                                </h5>
                                <p className="collision">
                                Call <strong>Think claims contact number </strong> to get emergency roadside assistance if you are involved in an emergency situation following a non-fault road traffic accident. 
                                </p>
                                <h5>No upfront charges</h5>
                                <p className="collision">
                                We won’t let you face any hidden charges or pay anything upfront if you’ve become a victim of someone else’s mistake on the road.                                 </p>

                                <h5> Comprehensive damage assessment</h5>
                                <p className="collision">
                                In order to repair it properly, our specialists will have to make a thorough evaluation of your car and determine the damage extent as well as propose ways of quick and effective repairs
                                </p>
                            </div>
                        </div>
                        <div className="col-xxl-4 col-xl-4 col-lg-12 col-md-12 col-12">
                            <div className="Claimant">
                                <h5>Immediate replacement car</h5>
                                <p className="collision">
                                We will help you find another similar vehicle within like-for-like terms and conditions so that you can continue your routine without any hassle.                                </p>

                                <h5>Full-fledged claims assistance </h5>
                                <p className="collision">
                                We provide full care, starting from reporting up to settlement of all <strong> Think car insurance claims. </strong>                           </p>

                                <h5>Legal and administrative assistance </h5>
                                <p className="collision">
                                Our team will handle paperwork and documentation to ensure your claim gets settled in minimum time.                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </search>

            <section className="Accident-claim">
                <div className="container">
                    <div className="row">
                        <div className="needed">
                            <h2 className="trusted-title">
                            How can you ensure the maximum settlement amount after <br />
                                <span className="light"> a no-fault car accident?</span>
                            </h2>
                            <p className="complexities">
                            It's important to act prudently following a no-fault car accident if one desires to be compensated for injuries and losses. Therefore, there are some things that need to be done in order to improve your chances of getting successful compensation claims.
                            </p>
                        </div>
                        <hr />
                    </div>
                    <div className="row" id="incdes">
                        <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-12">
                            <div className="Preparing">
                                <ul>
                                    <li>
                                        <div className="seemingly">
                                            <h5 className="Seek">Seek medical help:</h5>
                                            <p>
                                                {" "}
                                                When there has been an accident due to someone else’s fault while driving his/her vehicle with you in it, health should be prioritised. Thus, seek immediate medical attention even when the injury sustained is not serious.                                         </p>
                                        </div>
                                    </li>
                                    <li>
                                        <div className="seemingly">
                                            <h5 className="Seek">
                                            Record everything:{" "}
                                            </h5>
                                            <p>
                                            You should document all expenses, lost wages and financial impacts caused by vehicle accidents.                                         </p>
                                        </div>
                                    </li>
                                    <li>
                                        <div className="seemingly">
                                            <h5 className="Seek">Don’t talk about your accidents:</h5>
                                            <p>
                                            Do not discuss the incident except with personal injury solicitors. Any statement made by you can weaken your case.                                        </p>
                                        </div>
                                    </li>
                                    <li>
                                        <div className="seemingly">
                                            <h5 className="Seek"> Collect evidence :</h5>
                                            <p>
                                            This will involve detailed records such as photographs taken at the location of injury or accident scene, witness statements, official documents concerning medical treatment and other financial damages.                                            </p>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div
                            className="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-12"
                            id="dualcardiv"
                        >
                            <img src={dualcar} alt="" className="dualcar" />
                        </div>
                        <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-12">
                            <div className="Preparing">
                                <ul>
                                    <li>
                                        <div className="seemingly">
                                            <h5 className="Seek">Avoid early settlements :</h5>
                                            <p>
                                            The first offer for settlement may not cover all damages which have resulted from the incident. As such, go over it with your solicitor before agreeing so that it compensates all your damages fully.                                         </p>
                                        </div>
                                    </li>
                                    <li>
                                        <div className="seemingly">
                                            <h5 className="Seek">Monitor your social media presence.: </h5>
                                            <p>
                                            It is better to avoid using these platforms as it may prejudice your chance of getting the rightful compensation due to you.                                           </p>
                                        </div>
                                    </li>
                                    <li>
                                        <div className="seemingly">
                                            <h5 className="Seek">
                                            Talk to a no-fault claim solicitor  :
                                            </h5>
                                            <p>
                                            You can use our <strong>Think claims contact number </strong>  to speak with our specialist in non-fault claims, who will educate you on your rights and choices.
                                            </p>
                                        </div>
                                    </li>
                                    <li>
                                        <div className="seemingly">
                                            <h5 className="Seek">
                                            Make a claim:
                                            </h5>
                                            <p>
                                                 
                                            Do not hesitate to contact the <strong>Think claims department </strong>  once you have gathered all necessary evidence and documents regarding the accident and commence your claim process.
                                            </p>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="whyus">
                <div className="container">
                    <div className="row">
                        <div className="col-xxl-12 col-md-12">
                            <div className="why">
                                <h2 className="choose">
                                Make a claim in four  {" "}
                                    <span className="light">easy steps</span>
                                </h2>
                                <p className="complexities">
                                Would you like to make a <strong>Think car insurance claim? </strong>  Begin your claim in 4 simple steps with our easily manageable and stress-free claims process. Call us now at <strong> <a href="tel:0800 772 0850">0800 772 0850</a> </strong> to get started. </p>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-xxl-3 col-xl-6 col-lg-6 col-md-6 col-12">
                            <div className="box-service">
                                <img src={check} className="check" alt="" />
                                {/* <img src={experience} className="experience" alt="" /> */}
                                <h4>First consultation</h4>
                                <p>
                                Book an appointment with one of our solicitors by dialling the number through which you can reach <strong>Think car insurance claims </strong>  service providers</p>
                            </div>
                        </div>
                        <div className="col-xxl-3 col-xl-6 col-lg-6 col-md-6 col-12">
                            <div className="box-service">
                                <img src={check} className="check" alt="" />
                                <h4>Submit evidence and documents</h4>
                                <p>
                                Provide us with any material proof that was collected from the accident scene. </p>
                            </div>
                        </div>
                        <div className="col-xxl-3 col-xl-6 col-lg-6 col-md-6 col-12">
                            <div className="box-service">
                                <img src={check} className="check" alt="" />
                                <h4>Claim initiation</h4>
                                <p>
                                We will appraise and present your case after we receive every element of evidentiary information relevant thereto.                              </p>
                            </div>
                        </div>
                        <div className="col-xxl-3 col-xl-6 col-lg-6 col-md-6 col-12">
                            <div className="box-service">
                                <img src={check} className="check" alt="" />
                                <h4>Claim settlement </h4>
                                <p>
                                Our dedication is towards ensuring that everything turns out favourably in your case, ensuring fair compensation.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="faqs">
                <div className="container">
                    <div className="row">
                        <div className="col-xxl-12 col-xl-12 col-md-12 col-12">
                            <div className="fq">
                                <h2>
                                    {" "}
                                    Frequently Asked{" "}
                                    <span className="light">Questions</span>
                                </h2>
                                <p className="complexities">
                                    Got questions about the <strong>Think accident claim? </strong> We have them answered here.
                                </p>
                            </div>
                            <div className="allfaq">
                                <Accordion defaultActiveKey="0">
                                    <Accordion.Item eventKey="0">
                                        <Accordion.Header>
                                        Is there an extra charge for making a claim in the event of a car accident?                                      </Accordion.Header>
                                        <Accordion.Body>
                                        We are transparent with our customers. Therefore, any unpredicted charges will not apply. Indeed, we don't expect you to pay any upfront fees since it was the fault of others. All expenses would be claimed back from the insurance company of the negligent driver                                        </Accordion.Body>
                                    </Accordion.Item>
                                    <Accordion.Item eventKey="1">
                                        <Accordion.Header>
                                        Will my no-claims bonus be affected by using your service?
                                        </Accordion.Header>
                                        <Accordion.Body>
                                        No. There will be no excesses charged on making a<strong>Think car accident claim </strong> through us, and your no-claims will remain intact.                           </Accordion.Body>
                                    </Accordion.Item>
                                    <Accordion.Item eventKey="2">
                                        <Accordion.Header>
                                        Who is responsible for paying for services that I access through your company?
                                        </Accordion.Header>
                                        <Accordion.Body>
                                        When it comes to non-fault car accidents, all charges involving repairs and alternative vehicle hiring costs shall be borne by the insurer of the other driver. They will cover your bills resulting from the accident.                                     </Accordion.Body>
                                    </Accordion.Item>
                                    <Accordion.Item eventKey="3">
                                        <Accordion.Header>
                                        Can you help me get my car back after a no-fault accident?
                                        </Accordion.Header>
                                        <Accordion.Body>
                                        Certainly. Your car will be brought back to the repair shop after being picked up where it has been hit. Besides this, we will give you another vehicle without cost interruption so that your day-to-day life continues normally.                                      </Accordion.Body>
                                    </Accordion.Item>
                                    <Accordion.Item eventKey="4">
                                        <Accordion.Header>
                                        Could you assist me if the car is a write-off?

                                        </Accordion.Header>
                                        <Accordion.Body>
                                        Certainly. In case your vehicle has been declared a complete loss, we will give you another one exactly like your own. Additionally, we shall talk to the other driver’s insurer in order to get the best deal for your written-off vehicle.                                       </Accordion.Body>
                                    </Accordion.Item>
                                    <Accordion.Item eventKey="5">
                                        <Accordion.Header>
                                        I have been offered an out-of-court settlement by the third-party’s insurers. Should I take it?{" "}
                                        </Accordion.Header>
                                        <Accordion.Body>
                                        No. Do not accept an offer of out-of-court settlement until you have talked to your solicitor. For advice about your claim, please phone us on the <strong>Think claims line. </strong>                                         </Accordion.Body>
                                    </Accordion.Item>
                                    <Accordion.Item eventKey="6">
                                        <Accordion.Header>
                                        For how long can I access a replacement car? {" "}
                                        </Accordion.Header>
                                        <Accordion.Body>
                                        The duration for using the replacement vehicle will be provided depending on the circumstances related to the accident and when your vehicle will be fixed by the repairers or if your claim is settled through the insurance company. You may keep the vehicle as long as it takes for repairs or resolution of your claim.
                                        </Accordion.Body>
                                    </Accordion.Item>
                                </Accordion>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <Footer />
        </>
    );
};

export default Thinkinsurance;
