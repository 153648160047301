import React, { useRef } from "react";
import insurancelogo from "../../src/assets/images/insurancelogo.png";
import Accordion from "react-bootstrap/Accordion";
import check from "../assets/images/check.png";
import dualcar from "../../src/assets/images/dualcar.webp";
import carbnr2 from "../../src/assets/images/carbnr2.webp";
import Sidebarinner from "../components/Sidebar-inner";
import phonecall from "../assets/images/phonecall.png";
import { Helmet } from "react-helmet";
import Footer from "../components/footer";

const NFUmutual = () => {
  const sidebarRef = useRef(null);

  const handleScrollToSidebar = () => {
    if (sidebarRef.current) {
      sidebarRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>NFU Mutual Car Insurance Claims Number | NFU Mutual Insurance Claim</title>
        <meta
          name="description"
          content="Get quick and professional no-fault claims assistance to know your rights and options by dialling the NFU Mutual Car Insurance Claims Phone Number 0800 772 0850. "
        />
        <link
          rel="canonical"
          href="https://insurance-accident-claims.co.uk/NFU-mutual"
        />
      </Helmet>
      <section className="innernav">
        <div className="container">
          <div className="row">
            <div className="col-md-6 col-sm-6">
              <div className="left-logo">
                {/* <img src={insurancelogo} className='insurancelogo' alt='Insurance Logo' /> */}
                <h3>Accident Claims</h3>
              </div>
            </div>
            <div className="col-md-6 col-sm-6">
              <div className="left-cta">
                <div class="headercnt">
                  <div class="chatmsg">
                    <img
                      src={phonecall}
                      className="phonecall"
                      alt="phonecall "
                    />
                  </div>
                  <div class="box-body">
                    <h3 class="nav-info-box-title">Toll Free Number </h3>
                    <p>
                      <a href="tel:0800 772 0850">0800 772 0850</a>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section
        className="newbnr"
        style={{ backgroundImage: "url(" + carbnr2 + ")" }}
      >
        <div className="container">
          <div className="row">
            <div className="col-xxl-8 col-xl-8 col-md-10 col-12">
              <div className="innerbnr">
                <h1>
                  <span className="onespan">No-fault Car </span>{" "}
                  <span className="sci">Accident</span> insurance claims Services{" "}
                </h1>
                <p className="brokerage">
                  From arranging vehicle recovery to organising repairs,
                  providing a replacement vehicle and offering full-fledged
                  claim assistance, we’ve got you covered for all your accident
                  management needs.{" "}
                </p>
                <div className="bnrbtn">
                  <a class="db-btn-border btn-rollover phone" href="tel:0800 772 0850">
                    Begin your claim{" "}
                    <i class="fa-solid fa-arrow-right"></i>
                  </a>
                </div>
                <div className="bnrbtn">
                <a className="db-btn-border btn-rollover desktop"  onClick={handleScrollToSidebar}>
                    Begin your claim <i className="fa-solid fa-arrow-right"></i>
                </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="stickysection">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="group-4-8">
                <div className="cl8 tf-tab">
                  <div className="content-tab">
                    <div className="inner-content">
                      <h2>
                        {" "}
                        We manage your NFU mutual <br />
                        <span className="light">
                          claims without any excess
                        </span>{" "}
                      </h2>
                      <p className="disciplines">
                        After the no-fault car accident, making an NFU mutual
                        car insurance claim can seem to be a daunting task. But
                        we’re here as your trusted partners, helping you deal
                        with the consequences of the no-fault car accident with
                        our start-to-finish claims assistance. Contact us on the{" "}
                        <strong>
                          car insurance claim phone number{" "}
                          <a href="tel:0800 772 0850">0800 772 0850</a>{" "}
                        </strong>
                        for emergency support and claims assistance after the
                        car accident.
                      </p>
                      <hr />
                      <p className="bold-text">No excess </p>
                      <p className="bottom-te">
                        If the accident is not your fault, we won’t burden you
                        with excess payments. We will recover all the costs from
                        the other party’s insurance company.
                      </p>
                      <hr />
                      <p className="bold-text">No impact on no claims bonus </p>
                      <p className="bottom-te">
                        If you make the{" "}
                        <strong>NFU mutual insurance claims</strong> with us,
                        you won’t lose your no-claims bonus and hard-earned
                        discounts that you’ve earned through safe driving.
                      </p>
                      <hr />
                      <p className="bold-text">Personal claims manager </p>
                      <p className="bottom-te">
                        Call us on the{" "}
                        <strong>NFU car insurance claim number</strong>, and
                        we’ll appoint a dedicated claims handler who will
                        investigate the accident circumstances and develop an
                        appropriate strategy to help you secure maximum
                        compensation.
                      </p>
                      <hr />
                      <p className="bold-text">Quality vehicle repairs </p>
                      <p className="bottom-te">
                        We will ensure your vehicle gets repaired to the highest
                        possible standards through one of our
                        manufacturer-approved repair centres.
                      </p>
                      <hr />
                      <p className="bold-text">Quick claims settlement </p>
                      <p className="bottom-te">
                        Our solicitors work with utmost dedication and
                        professionalism to ensure quick settlement of your{" "}
                        <strong>NFU mutual insurance claims</strong>. With our
                        streamlined claims process, you can make your{" "}
                        <strong>NFU insurance claim </strong>effortlessly.
                      </p>
                      <hr />
                      <p className="bold-text">Comparable replacement </p>
                      <p className="bottom-te">
                        If your vehicle is undrivable, we will help you stay
                        mobile by providing a like-for-like replacement vehicle
                        at no cost to you.{" "}
                      </p>
                      <hr />
                      <p className="bold-text">Legal assistance </p>
                      <p className="bottom-te">
                        When you call us on the{" "}
                        <strong>NFU car insurance claim phone number</strong>,
                        we will put you in touch with an experienced solicitor
                        who will represent your claim and negotiate for the best
                        settlement value.
                      </p>
                      <hr />
                      <p className="bold-text">No obligation advice </p>
                      <p className="bottom-te">
                        Contact us on the{" "}
                        <strong>NFU car insurance claim number </strong>to
                        schedule a free initial consultation with our solicitors
                        and understand how we can help recover your losses from
                        the other party.{" "}
                      </p>
                      <hr />
                      <p className="bold-text">Personal injury support </p>
                      <p className="bottom-te">
                        In case you’ve suffered any injuries as a result of the
                        accident, we’ll guide you through the necessary process
                        to claim compensation.
                      </p>
                      <hr />

                      <p className="bold-text">Dedicated claims management </p>
                      <p className="bottom-te">
                        Our experienced claim handlers will evaluate your NFU
                        car insurance claim, determine the extent of damages
                        you’ve suffered, and liaise with all parties to ensure a
                        smooth resolution of your claim.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="cl4" ref={sidebarRef}>
                  <Sidebarinner />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="photographs">
        <div className="container">
          <div className="row">
            <h2 className="road">
              {" "}
              We ensure your safety and peace
              <br />
              <span className="light">
                of mind after the car accident{" "}
              </span>{" "}
            </h2>
            <div className="col-xxl-6 col-xl-6 col-md-12 col-12">
              <div className="fourbox">
                <ul>
                  <li>
                    <img src={check} className="check" alt="" />
                    From recovery to repair, we will take care of all your
                    accident management needs and help you get back on track.
                  </li>
                  <hr />
                  <li>
                    <img src={check} className="check" alt="" />
                    We will assess your NFU mutual insurance claim and guide you
                    on the next steps to get maximum compensation.
                  </li>
                  <hr />
                  <li>
                    <img src={check} className="check" alt="" />
                    We will arrange high-quality repairs of your vehicle from a
                    manufacturer-approved repair centre.
                  </li>
                  <hr />
                  <li>
                    <img src={check} className="check" alt="" />
                    Call us on the{" "}
                    <strong>NFU car insurance claim phone number</strong> to hit
                    the road again with a like-for-like replacement vehicle.
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-xxl-6 col-xl-6 col-md-12 col-12">
              <div className="fourbox">
                <ul>
                  <li>
                    <img src={check} className="check" alt="" />
                    Our solicitors will help make your{" "}
                    <strong>NFU mutual car insurance claim </strong>quick,
                    simple and easy.
                  </li>
                  <hr />
                  <li>
                    <img src={check} className="check" alt="" />
                    We ensure you receive the best possible advice and
                    assistance regarding the{" "}
                    <strong>NFU mutual insurance claim</strong>.
                  </li>
                  <hr />
                  <li>
                    <img src={check} className="check" alt="" />
                    Our dedicated claims handler will manage your{" "}
                    <strong>NFU insurance claim </strong>from start to finish
                    with utmost diligence.
                  </li>
                  <hr />
                  <li>
                    <img src={check} className="check" alt="" />
                    We will make arrangements for the delivery of your repaired
                    vehicle after the completion of repairs.
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="carprocess">
        <div className="container ">
          <div className="row">
            <div className="col-xxl-12 col-xxl-12 col-md-12 col-12">
              <h2 className="trusted-title">
                {" "}
                Our wide range <span className="light">of services </span>{" "}
              </h2>
            </div>
          </div>
          <div className="row" id="possible">
            <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-12">
              <div className="claim">
                <ul>
                  <li>
                    <span className="sleej">Vehicle recovery </span> <br />
                    If you’re stuck at a roadside following a car accident,
                    contact us on the{" "}
                    <strong>NFU car insurance claim number. </strong>We will
                    arrange for the recovery to safely secure your vehicle and
                    tow it to our safe storage facility.
                  </li>
                  <li>
                    <span className="sleej">Approved repairs </span>
                    <br />
                    Call us on the{" "}
                    <strong>NFU car insurance claim number</strong> to arrange
                    quick repairs for your damaged vehicle. Our repair network
                    specialises in providing high-quality repairs using
                    manufacturer-approved parts and paint.
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-12">
              <div className="claim" id="blue">
                <ul>
                  <li>
                    <span className="sleej">Storage </span> <br />
                    Following a car accident, leaving your vehicle at the
                    roadside can pose significant safety risks. To ensure the
                    safety and security of your vehicle, we will temporarily
                    store it in our storage yards to ensure it stays protected
                    from potential hazards until your claim is settled.
                  </li>
                  <li>
                    <span className="sleej">Replacement car </span> <br />
                    If you’ve been a victim of a no-fault car accident, we will
                    arrange for a comparable replacement vehicle to be delivered
                    to your doorstep. You can keep the replacement vehicle for
                    the duration of your claim. Once your{" "}
                    <strong>NFU car insurance claim gets </strong>settled, we
                    will collect the replacement vehicle from you.
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-12">
              <div className="claim">
                <ul>
                  <li>
                    <span className="sleej">Personal injury assistance </span>
                    <br />
                    In case you’ve sustained any injuries due to the accident,
                    we will connect you with our experienced panel of
                    solicitors, who will guide you on the steps to make an{" "}
                    <strong>NFU mutual claim </strong> to get compensation for
                    your injuries.
                  </li>
                  <li>
                    <span className="sleej">Claim management </span> <br />
                    From preparing paperwork to negotiating with insurance
                    companies, handling communication with the different parties
                    and ensuring quick resolution of your{" "}
                    <strong>NFU mutual car insurance claim,</strong> we have got
                    you covered.
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="yellowcta">
        <div className="container">
          <div className="row">
            <div className="cta2">
              <div className="col-xxl-8 col-xl-8 col-lg-8 col-12">
                <div className="legal">
                  <h3 className="experienced">
                    Got more questions?
                    <span className="light"> Talk to our specialists </span>{" "}
                  </h3>
                  <p className="professionals">
                    Get in touch with our no-fault claim experts via{" "}
                    <strong>car insurance claim phone number</strong>
                    <a href="tel:0800 772 0850">0800 772 0850</a>to get the best
                    advice for your <strong>NFU insurance claim. </strong>
                  </p>
                </div>
              </div>
              <div className="col-xxl-4 col-xl-4 col-lg-4 col-12">
              <div class="process">
                  <a class="tf-button style-1 phone" href="tel:0800 772 0850">
                    Get in touch<span class="icon-keyboard_arrow_right"></span>
                  </a>
                </div>
                <div class="process">
                    <a className="tf-button style-1 desktop"  onClick={handleScrollToSidebar}>
                        Get in touch<span className="icon-keyboard_arrow_right"></span>
                    </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <search className="updatedform">
        <div className="container">
          <div className="row text-center">
            <div className="col-xxl-12 col-xl-12 col-md-12 col-12">
              <h2 className="whiteheading">
                Why choose us for NFU mutual
                <span className="light"> insurance claims assistance?</span>
              </h2>
              <p className="sustained">
                When you choose us for your accident management needs and NFU
                mutual claims assistance, you’re choosing a dedicated partner
                who will help minimise the hassle and inconvenience associated
                with the accidents. Discover the reasons why choosing our
                accident management services can be your best bet.
              </p>
            </div>
          </div>
          <div className="row">
            <div className="col-xxl-4 col-xl-4 col-lg-6 col-md-12 col-12">
              <div className="Claimant">
                <h5>No cost to you </h5>
                <p className="collision">
                  Our accident management solutions do not add to your financial
                  burden as we recover all the costs from the other party or
                  their insurer.
                </p>

                <h5>Manufacturer-approved repairers </h5>
                <p className="collision">
                  We have a vast network of manufacturer-approved repairers in
                  the UK, who possess the technical knowledge to restore your
                  damaged vehicle to the highest manufacturer safety and quality
                  standards.
                </p>

                <h5>Same day replacement </h5>
                <p className="collision">
                  By providing you with a like-for-like replacement vehicle, we
                  will help you get back on the road the same day.
                </p>
              </div>
            </div>
            <div className="col-xxl-4 col-xl-4 col-lg-6 col-md-12 col-12">
              <div className="Claimant">
                <h5>Customer-centric focus </h5>
                <p className="collision">
                  Contact us on the{" "}
                  <strong>NFU car insurance claim phone number </strong>to get
                  tailored advice from our experienced solicitor based on the
                  circumstances of your accident.
                </p>

                <h5>Timely claim resolution </h5>
                <p className="collision">
                  By leveraging their legal expertise and negotiation skills,
                  our solicitors strive to settle your{" "}
                  <strong>NFU mutual car insurance claims</strong> as quickly as
                  possible.
                </p>

                <h5>Expert claim management </h5>
                <p className="collision">
                  Your dedicated claims manager will efficiently process your
                  claim, claiming all the costs from the other party’s insurer.
                </p>
              </div>
            </div>
            <div className="col-xxl-4 col-xl-4 col-lg-12 col-md-12 col-12">
              <div className="Claimant">
                <h5>No-win no-fee basis </h5>
                <p className="collision">
                  If your claim is unsuccessful, our solicitors won’t charge any
                  legal fees from you.
                </p>

                <h5>24/7 roadside assistance </h5>
                <p className="collision">
                  Our team of professionals is ready to assist you with your
                  accident management needs 24 hours a day, 365 days a year.
                </p>

                <h5>Efficient claim monitoring </h5>
                <p className="collision">
                  We will consistently track and monitor your NFU mutual car
                  insurance claim and keep you informed about its status.
                </p>
              </div>
            </div>
          </div>
        </div>
      </search>

      <section className="Accident-claim">
        <div className="container">
          <div className="row">
            <div className="needed">
              <h2 className="trusted-title">
                How can you maximise compensation
                <br />
                <span className="light"> in an NFU insurance claim?</span>
              </h2>
              <p className="complexities">
                The consequences of a car accident can be traumatic. Besides
                dealing with the pain and suffering, you also have to deal with
                the hassles of managing{" "}
                <strong>NFU mutual car insurance claims.</strong> Therefore, you
                need to be aware of the right steps to maximise your settlement
                value.
              </p>
            </div>
            <hr />
          </div>
          <div className="row" id="incdes">
            <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-12">
              <div className="Preparing">
                <ul>
                  <li>
                    <div className="seemingly">
                      <h5 className="Seek">Get medical attention </h5>
                      <p>
                        The more time you take to get medical attention, the
                        higher the chances of your claim being undervalued. So,
                        make sure to seek immediate medical treatment for your
                        injuries to maximise your claim.{" "}
                      </p>
                    </div>
                  </li>
                  <li>
                    <div className="seemingly">
                      <h5 className="Seek">Document your injuries </h5>
                      <p>
                        Keep track of all the documents related to your injuries
                        to claim compensation for all injuries you’ve suffered.
                      </p>
                    </div>
                  </li>
                  <li>
                    <div className="seemingly">
                      <h5 className="Seek">Avoid using social media </h5>
                      <p>
                        Refrain from using social media until your claim is
                        settled. The other party’s solicitor may use your social
                        media posts to cast doubt on your{" "}
                        <strong>NFU insurance claim. </strong>
                      </p>
                    </div>
                  </li>
                  <li>
                    <div className="seemingly">
                      <h5 className="Seek">Don’t accept the first offer </h5>
                      <p>
                        The insurance company may offer you an initial
                        settlement offer that may not provide fair compensation
                        for your losses and damages. Don’t rush to accept such
                        an initial settlement without discussing it with your
                        solicitor.
                      </p>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
            <div
              className="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-12"
              id="dualcardiv"
            >
              <img src={dualcar} alt="" className="dualcar" />
            </div>
            <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-12">
              <div className="Preparing">
                <ul>
                  <li>
                    <div className="seemingly">
                      <h5 className="Seek">Gather evidence </h5>
                      <p>
                        To build a strong foundation for your NFU mutual claim,
                        gather as much evidence as possible from the accident
                        scene, including photographs, videos, third-party
                        vehicle details, CCTV cameras and dashcams.
                      </p>
                    </div>
                  </li>
                  <li>
                    <div className="seemingly">
                      <h5 className="Seek">Contact eyewitness </h5>
                      <p>
                        Reach out to the eyewitnesses of the car accident and
                        collect their details, such as contact details and their
                        statements to support your claim.
                      </p>
                    </div>
                  </li>
                  <li>
                    <div className="seemingly">
                      <h5 className="Seek">
                        Talk to a no-fault claim solicitor{" "}
                      </h5>
                      <p>
                        A no-fault claim expert can help maximise your claim
                        settlement value. Contact us via the
                        <strong> NFU car insurance claim phone number </strong>
                        to discuss your claim with our experienced solicitors
                        and understand your options.
                      </p>
                    </div>
                  </li>
                  <li>
                    <div className="seemingly">
                      <h5 className="Seek">Start your claim </h5>
                      <p>
                        Once you’ve decided to pursue a claim against the other
                        party, get in touch with us via the NFU car insurance
                        claim number to initiate your claim.{" "}
                      </p>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="whyus">
        <div className="container">
          <div className="row">
            <div className="col-xxl-12 col-md-12">
              <div className="why">
                <h2 className="choose">
                  How to make your{" "}
                  <span className="light">
                    <br /> NFU mutual claim with us{" "}
                  </span>
                </h2>
                <p className="complexities">
                  We have streamlined it to ensure that you can make the NFU
                  mutual car insurance claim with us. Our claims process
                  involves no tedious paperwork or lengthy follow-ups, allowing
                  you to initiate your claim effortlessly. Get in touch with us
                  on <a href="tel:0800 772 0850">0800 772 0850</a> to begin your
                  claim.
                </p>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-xxl-3 col-xl-6 col-lg-6 col-md-6 col-12">
              <div className="box-service">
                <img src={check} className="check" alt="" />
                <h4>Get in touch </h4>
                <p>
                  Call us on the{" "}
                  <strong>NFU car insurance claim phone number </strong>for a
                  free initial consultation to discuss your claim.
                </p>
              </div>
            </div>
            <div className="col-xxl-3 col-xl-6 col-lg-6 col-md-6 col-12">
              <div className="box-service">
                <img src={check} className="check" alt="" />
                <h4>Share accident details </h4>
                <p>
                  Provide all the crucial details related to the accident. Our
                  team will guide you through the next steps.
                </p>
              </div>
            </div>
            <div className="col-xxl-3 col-xl-6 col-lg-6 col-md-6 col-12">
              <div className="box-service">
                <img src={check} className="check" alt="" />
                <h4>Submit evidence </h4>
                <p>
                  Hand over the evidence you’ve collected from the accident
                  scene to make your{" "}
                  <strong>NFU mutual insurance claim </strong>stronger.
                </p>
              </div>
            </div>
            <div className="col-xxl-3 col-xl-6 col-lg-6 col-md-6 col-12">
              <div className="box-service">
                <img src={check} className="check" alt="" />
                <h4>Claim initiation </h4>
                <p>
                  After collecting all the details and evidence, we will
                  initiate the claim and work consistently to resolve it
                  quickly.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="faqs">
        <div className="container">
          <div className="row">
            <div className="col-xxl-12 col-xl-12 col-md-12 col-12">
              <div className="fq">
                <h2>
                  {" "}
                  Frequently Asked{" "}
                  <span className="light">Questions (FAQ)</span>
                </h2>
                <p className="complexities">
                  Got questions about the NFU mutual car insurance claim? We
                  have them answered here.
                </p>
              </div>
              <div className="allfaq">
                <Accordion defaultActiveKey="0">
                  <Accordion.Item eventKey="0">
                    <Accordion.Header>
                      Will my vehicle repairs be genuine?
                    </Accordion.Header>
                    <Accordion.Body>
                      Yes. Our manufacturer-approved repairers specialise in
                      providing high-quality repairs to your vehicle using
                      genuine and original parts and paint.
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="1">
                    <Accordion.Header>
                      Will I lose my no-claims bonus if I make a claim?
                    </Accordion.Header>
                    <Accordion.Body>
                      No. If you’ve been involved in a no-fault car accident,
                      your no-claims bonus won’t be affected. Get in touch with
                      us via the{" "}
                      <strong>NFU car insurance claim phone number </strong> to
                      register your claim and safeguard your hard-earned
                      discounts and bonuses.
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="2">
                    <Accordion.Header>
                      What should I do if the other party’s insurers contact me?
                    </Accordion.Header>
                    <Accordion.Body>
                      If the third-party insurers contact you, refer them to us
                      if we’re handling your claim. We will deal with them on
                      your behalf.
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="3">
                    <Accordion.Header>
                      Will you arrange my vehicle repairs from my chosen repair
                      centre?
                    </Accordion.Header>
                    <Accordion.Body>
                      Yes. Call us on the{" "}
                      <strong>NFU car insurance claim number </strong>to share
                      the details of your preferred repair centre so that we can
                      contact them to schedule the repairs.
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="4">
                    <Accordion.Header>
                      My vehicle has been written off. Can you provide a
                      replacement vehicle?
                    </Accordion.Header>
                    <Accordion.Body>
                      Yes. No matter what you drive, we’ll arrange a
                      like-for-like replacement vehicle to allow you to continue
                      with your routine without hassle.
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="5">
                    <Accordion.Header>
                      How long can I keep the replacement car?
                    </Accordion.Header>
                    <Accordion.Body>
                      You can keep the replacement car until your vehicle is
                      repaired. If your car is a total loss, you can keep the
                      replacement car until your claim is settled.
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="6">
                    <Accordion.Header>
                      Should I report the accident to my insurance company?
                    </Accordion.Header>
                    <Accordion.Body>
                      If you choose to make the NFU insurance claim with us, you
                      don’t need to inform your insurance company. We will take
                      care of everything on your behalf.
                    </Accordion.Body>
                  </Accordion.Item>
                </Accordion>
              </div>
            </div>
          </div>
        </div>
      </section>

      <Footer />
    </>
  );
};

export default NFUmutual;
