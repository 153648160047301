import React, { useRef } from "react";
import Accordion from "react-bootstrap/Accordion";
import check from "../assets/images/check.png";
import dualcar from "../../src/assets/images/dualcar.webp";
import morethan from "../../src/assets/images/morethan.webp";
import Sidebarinner from "../components/Sidebar-inner";
import phonecall from "../assets/images/phonecall.png";
import Footer from "../components/footer";
import { Helmet } from "react-helmet";

const Sosure = () => {
    const sidebarRef = useRef(null);

    const handleScrollToSidebar = () => {
        if (sidebarRef.current) {
            sidebarRef.current.scrollIntoView({ behavior: "smooth" });
        }
    };
    return (
        <>
        <Helmet>
        <meta charSet="utf-8" />
        <title>So Sure Car Insurance Accident Claim Phone Number</title>
        <meta
          name="description"
          content="Call the So Sure Car Insurance Accident Claim Phone Number 0800 772 0850 for prompt and start-to-finish no-fault claims assistance. Contact our friendly team today.  "
        />
        <link
          rel="canonical"
          href="https://insurance-accident-claims.co.uk/So-sure"
        />
      </Helmet>
            <section className="innernav">
                <div className="container">
                    <div className="row">
                        <div className="col-md-6 col-sm-6">
                            <div className="left-logo">
                                <h3>
                                    Accident<span className="sipo"> Claims</span>
                                </h3>
                            </div>
                        </div>
                        <div className="col-md-6 col-sm-6">
                            <div className="left-cta">
                                <div class="headercnt">
                                    <div class="chatmsg">
                                        <img
                                            src={phonecall}
                                            className="phonecall"
                                            alt="phonecall "
                                        />
                                    </div>
                                    <div class="box-body">
                                        <h3 class="nav-info-box-title">Toll Free Number </h3>
                                        <p>
                                            <a href="tel:0800 772 0850">0800 772 0850</a>
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section
                className="newbnr"
                style={{ backgroundImage: "url(" + morethan + ")" }}
            >
                <div className="container">
                    <div className="row">
                        <div className="col-xxl-7 col-xl-7 col-md-10 col-12">
                            <div className="innerbnr">
                                <h1>
                                    <span className="onespan">Expert car accident  insurance claims </span>{" "}
                                    <span className="sci"> services   </span> in the UK
                                </h1>
                                <p className="brokerage">
                                    We offer accident management and claims support to ensure a stress-free recovery after having a non-fault car accident.
                                </p>
                                <div className="bnrbtn">
                                    <a class="db-btn-border btn-rollover phone" href="tel:0800 772 0850">
                                        Road Accident Claim Help?{" "}
                                        <i class="fa-solid fa-arrow-right"></i>
                                    </a>
                                </div>
                                <div className="bnrbtn">
                                    <a className="db-btn-border btn-rollover desktop" onClick={handleScrollToSidebar}>
                                        Road Accident Claim Help? <i className="fa-solid fa-arrow-right"></i>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="stickysection">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="group-4-8">
                                <div className="cl8 tf-tab">
                                    <div className="content-tab">
                                        <div className="inner-content">
                                            <h2>
                                                Claims handling  without

                                                <span className="light">  excess </span>{" "}
                                            </h2>
                                            <p className="disciplines">
                                                We specialise in helping out drivers who are not at fault with our accident management and claims assistance services. With<strong> So Sure car insurance claims, </strong>  we will save time and manage it from beginning to end. Call us on
                                                <strong>
                                                    {" "}
                                                    <a href="tel:0800 772 0850">0800 772 0850</a>{" "}
                                                </strong> for professional advice free of charge.

                                            </p>
                                            <hr />
                                            <p className="bold-text">No extra charges</p>
                                            <p className="bottom-te">  The fees for our services are paid by the negligent party’s insurer. Hence, you do not have to bear any additional costs.                           </p>
                                            <hr />
                                            <p className="bold-text">Risk-free NCB (no change in NCB)</p>
                                            <p className="bottom-te">
                                                The claim is not against your personal policy, so your no-claims bonus or discount remains intact.                                   </p>
                                            <hr />
                                            <p className="bold-text">
                                                Unmatched level of experience
                                            </p>
                                            <p className="bottom-te">
                                                Our focus is on no-win, no-fee cases only. You will get maximum compensation through our solicitors working tirelessly on your case.
                                            </p>
                                            <hr />
                                            <p className="bold-text">Full range of services</p>
                                            <p className="bottom-te">
                                                From addressing problems with your <strong> So Sure insurance claim </strong> to offering emergency help when you need it most, we've got everything covered along the way.                                   </p>
                                            <hr />
                                            <p className="bold-text">No-win, No-fee basis</p>
                                            <p className="bottom-te">
                                                If you lose your<strong> So Sure car insurance claim,  </strong>you won’t be liable for any legal costs at all.            </p>
                                            <hr />
                                            <p className="bold-text">Supportive team </p>
                                            <p className="bottom-te">
                                                When you dial the<strong>So Sure car insurance claim  number,</strong> our supportive team will assist you with steps on how to make a successful
                                                <strong> So Sure insurance claim. </strong>

                                            </p>
                                            <hr />
                                            <p className="bold-text">Skilled claims handler</p>
                                            <p className="bottom-te">
                                                We shall assign an expert in handling claims whose main objective is to ensure that your claims are settled amicably and all costs are fully recovered from the guilty driver.                   </p>
                                            <hr />
                                            <p className="bold-text">Free consultation </p>
                                            <p className="bottom-te">
                                                No-cost legal advice can be sought from solicitors who can explain the various options and rights which can be pursued after making a <strong>So Sure car insurance claim. </strong>
                                            </p>
                                            <hr />
                                            <p className="bold-text">Quality repairs </p>
                                            <p className="bottom-te">
                                                Dial us at the<strong> So Sure insurance claim phone number </strong>  so as to arrange for quality repair of your vehicle. This will be done by our wide network of authorised repairers who specialise in using genuine parts and paints.
                                            </p>
                                            <hr />

                                            <p className="bold-text"> Vehicle replacement on a like-for-like basis</p>
                                            <p className="bottom-te">
                                                During the time your vehicle is repaired, we offer you a like-for-like replacement car within the same class as well as model and brand as your own vehicle.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div className="cl4" ref={sidebarRef}>
                                    <Sidebarinner />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="photographs">
                <div className="container">
                    <div className="row">
                        <h2 className="road">
                            While you recuperate, we can deal with all the  {" "}
                            <span className="light">

                                intricacies  <br />  of your claim.
                            </span>{" "}
                        </h2>
                        <div className="col-xxl-6 col-xl-6 col-md-12 col-12">
                            <div className="fourbox">
                                <ul>
                                    <li>
                                        <img src={check} className="check" alt="" />
                                        We’ve got your back from evaluating damages to haggling with insurers and running the process of your claim from beginning to end.

                                    </li>
                                    <hr />
                                    <li>
                                        <img src={check} className="check" alt="" />
                                        Dial <strong> So Sure insurance claims phone number, </strong>  and our team will appreciate what you need and replace a vehicle for you that matches your needs.                                </li>
                                    <hr />
                                    <li>
                                        <img src={check} className="check" alt="" />
                                        We’ll organise high-quality repairs for your car from manufacturer-approved repairers.
                                    </li>
                                    <hr />
                                    <li>
                                        <img src={check} className="check" alt="" />
                                        In case need be, we may engage many parties involved in an accident so that they do not distract you from focusing on other important issues.                           </li>
                                </ul>
                            </div>
                        </div>
                        <div className="col-xxl-6 col-xl-6 col-md-12 col-12">
                            <div className="fourbox">
                                <ul>
                                    <li>
                                        <img src={check} className="check" alt="" />
                                        Our solicitors specialise in non-fault claims relating to car accidents. Whenever you call us on the <strong> So Sure insurance claim number, </strong>be confident that additional pieces of advice, as well as guidance regarding the claim, will be given to you.                             </li>
                                    <hr />
                                    <li>
                                        <img src={check} className="check" alt="" />
                                        There will be arrangements made for independent engineer’s examination of vehicles.                                </li>
                                    <hr />
                                    <li>
                                        <img src={check} className="check" alt="" />
                                        We will schedule the delivery of your repaired vehicle at your doorstep at a suitable time.                            </li>
                                    <hr />
                                    <li>
                                        <img src={check} className="check" alt="" />
                                        We will help you recover all expenses incurred due to the accident from the other party and shall provide complete assistance to get back all your expenditures. </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="carprocess">
                <div className="container ">
                    <div className="row">
                        <div className="col-xxl-12 col-xxl-12 col-md-12 col-12">
                            <h2 className="trusted-title">
                                {" "}
                                Our accident management  <br />
                                <span className="light"> services </span>{" "}
                            </h2>
                        </div>
                    </div>
                    <div className="row" id="possible">
                        <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-12">
                            <div className="claim">
                                <ul>
                                    <li>
                                        <span className="sleej">Vehicle recovery :</span>    Has your vehicle stopped on the road because of an accident? Contact us via the <strong>  So Sure insurance claim contact number </strong> to take it back in time. Our recovery team will, therefore, move your car from the scene of an accident and bring it to safe storage.

                                    </li>
                                    <li>
                                        <span className="sleej">Authorised repairs:</span> Call our team immediately on  <strong>
                                            {" "}
                                            <a href="tel:0800 772 0850">0800 772 0850</a>{" "}
                                        </strong> if you need repairs. Our repairers will fix your car as per the manufacturer's standards and return it to its state before the crash.

                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-12">
                            <div className="claim" id="blue">
                                <ul>
                                    <li>
                                        <span className="sleej">Vehicle storage:</span>To leave a non-working car on the roadside or outside your home is not always safe. Consequently, your car can be placed at one of our storage yards in order to protect it from additional harm. In case you would like secure storage facilities for wrecked vehicles, please contact us on     {" "}
                                        <a href="tel:0800 772 0850">0800 772 0850</a>{" "}
                                    </li>
                                    <li>
                                        <span className="sleej">Replacement car:</span>   We know that being without a car is something you don’t want to experience. Our devoted team will get you back on the road with a like-for-like replacement vehicle on the very same day. All expenses related to such a new vehicle will be claimed back from the defaulting party’s insurer.
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-12">
                            <div className="claim">
                                <ul>
                                    <li>
                                        <span className="sleej">Personal injury claim support </span>
                                        Our solicitors handle <strong> So Sure insurance claims </strong>  to ensure that victims of road accidents receive maximum compensation for their injuries
                                    </li>
                                    <li>
                                        <span className="sleej">Claims management:</span>  All-inclusive end-to-end claims management services are provided starting from the beginning of a <strong>  So Sure care insurance claim, </strong> completing paperwork and ending settlement.

                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="yellowcta">
                <div className="container">
                    <div className="row">
                        <div className="cta2">
                            <div className="col-xxl-8 col-xl-8 col-lg-8 col-12">
                                <div className="legal">
                                    <h3 className="experienced">
                                        Got more questions? Talk {" "}
                                        <span className="light"> to our specialists
                                        </span>{" "}
                                    </h3>
                                    <p className="professionals">
                                        Get in touch with our no-fault claim experts via
                                        {" "}
                                        <strong> car insurance claim number{" "}
                                            <a className="cpt-no" href="tel:0800 772 0850">
                                                {" "}
                                                0800 772 0850{" "}
                                            </a>{" "}
                                        </strong>
                                        to get the best advice for your
                                        <strong>So Sure car insurance claim. </strong>
                                    </p>
                                </div>
                            </div>
                            <div className="col-xxl-4 col-xl-4 col-lg-4 col-12">
                                <div class="process">
                                    <a class="tf-button style-1 phone" href="tel:0800 772 0850">
                                        Get in touch<span class="icon-keyboard_arrow_right"></span>
                                    </a>
                                </div>
                                <div class="process">
                                    <a className="tf-button style-1 desktop" onClick={handleScrollToSidebar}>
                                        Get in touch<span className="icon-keyboard_arrow_right"></span>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <search className="updatedform">
                <div className="container">
                    <div className="row text-center">
                        <div className="col-xxl-12 col-xl-12 col-md-12 col-12">
                            <h2 className="whiteheading">
                                {" "}
                                What sets us apart from
                                <span className="light">  others?
                                </span>
                            </h2>
                            <p className="sustained">
                                We are there for you as dedicated partners if you have had a non-fault road accident in the UK, providing complete accident management solutions and claiming assistance services. From vehicle recovery to replacement cars and legal assistance, we’ve got you covered every step of the way.        </p>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-xxl-4 col-xl-4 col-lg-6 col-md-12 col-12">
                            <div className="Claimant">
                                <h5>Zero upfront charges</h5>
                                <p className="collision">
                                    No costs are payable beforehand upon accessing our services. It allows one to enjoy quality emergency support at no cost whatsoever.


                                </p>

                                <h5>Immediate support</h5>
                                <p className="collision">
                                    Our team is available 24/7 to provide instant assistance and resolve any issues associated with accident management.                        </p>

                                <h5>Authorised garages  </h5>
                                <p className="collision">
                                    Our repair centres are authorised by manufacturers. So you can rest assured only original parts & paintwork will be used on your car to adhere to the manufacturers’ specifications.

                                </p>
                            </div>
                        </div>
                        <div className="col-xxl-4 col-xl-4 col-lg-6 col-md-12 col-12">
                            <div className="Claimant">
                                <h5>Same-day replacement car
                                </h5>
                                <p className="collision">
                                    Need another vehicle? Call our<strong> So Sure insurance claims phone number, </strong> and we will provide a like-for-like replacement vehicle to ensure that life carries on without much interruption.  </p>
                                <h5>Speedy settlement of claims  </h5>
                                <p className="collision">
                                    Our procedures run smoothly & transparently, enabling you to easily sort out your claims within a reasonable time frame.                      </p>

                                <h5>24/7 Recovery</h5>
                                <p className="collision">
                                    We are available at all times for any type of vehicle recovery needs you might have.
                                </p>
                            </div>
                        </div>
                        <div className="col-xxl-4 col-xl-4 col-lg-12 col-md-12 col-12">
                            <div className="Claimant">
                                <h5>No-obligation advice</h5>
                                <p className="collision">
                                    Contact our team through the <strong>  So Sure insurance claims phone number </strong> and arrange a discussion without obligation where you can learn about your rights and things you can do when making your claim.                            </p>

                                <h5>Experienced solicitors  </h5>
                                <p className="collision">
                                    Our solicitors have extensive experience in no-fault accident claims. They will offer some legal advice and stand by your side every step of the way.              </p>

                                <h5>Personal advice</h5>
                                <p className="collision">
                                    Our professional and knowledgeable solicitors will review the specifics of your <strong> So Sure car insurance claim </strong> and advise you on what to do for a better outcome.  </p>
                            </div>
                        </div>
                    </div>
                </div>
            </search>

            <section className="Accident-claim">
                <div className="container">
                    <div className="row">
                        <div className="needed">
                            <h2 className="trusted-title">
                                How can I get maximum compensation for my <br />
                                <span className="light"> So Sure insurance claim? </span>
                            </h2>
                            <p className="complexities">
                                Crash changes life completely, bringing too much pressure. The first step towards recovering from a motor vehicle accident is getting reimbursed for losses suffered as a result of the crash. Follow these steps to increase the settlement amount and maximise your compensation.            </p>
                        </div>
                        <hr />
                    </div>
                    <div className="row" id="incdes">
                        <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-12">
                            <div className="Preparing">
                                <ul>
                                    <li>
                                        <div className="seemingly">
                                            <h5 className="Seek">See a doctor:</h5>
                                            <p>
                                                {" "}
                                                In order to have an appropriate record that will be used in strengthening your <strong> So Sure insurance claim, </strong>  it is necessary to seek medical attention immediately so that the injuries can be diagnosed accurately as well as treated.                                    </p>
                                        </div>
                                    </li>
                                    <li>
                                        <div className="seemingly">
                                            <h5 className="Seek">
                                                Record expenses:{" "}
                                            </h5>
                                            <p>
                                                Keep a log of every expense related to your case, such as any medical fees paid by you, prescription costs incurred, travel expenditures, and solicitors’ charges.                                   </p>
                                        </div>
                                    </li>
                                    <li>
                                        <div className="seemingly">
                                            <h5 className="Seek">Accumulate proof :</h5>
                                            <p> To strengthen your claim, take photos or video clips at the scene of an accident; collect witnesses’ statements, attempt to get CCTV footage and obtain a copy of a police report.                                  </p>
                                        </div>
                                    </li>
                                    <li>
                                        <div className="seemingly">
                                            <h5 className="Seek">Don’t accept a fast settlement offer :</h5>
                                            <p>
                                                Do not accept initial offers made for settlements but rather discuss with your solicitor to ensure that it adequately compensates you for all damages or injuries.
                                                
                                            </p>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div
                            className="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-12"
                            id="dualcardiv"
                        >
                            <img src={dualcar} alt="" className="dualcar" />
                        </div>
                        <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-12">
                            <div className="Preparing">
                                <ul>
                                    <li>
                                        <div className="seemingly">
                                            <h5 className="Seek">Refrain from using social media :</h5>
                                            <p>
                                            Avoid using social media while your claim is in progress. What you say on social networks can be used to deny or reduce your compensation by the other party           </p>
                                        </div>
                                    </li>
                                    <li>
                                        <div className="seemingly">
                                            <h5 className="Seek">Exchange contacts: </h5>
                                            <p>
                                            Share name, phone number, vehicle information and coverage details with the other drivers involved in the accident.                                           </p>
                                        </div>
                                    </li>
                                    <li>
                                        <div className="seemingly">
                                            <h5 className="Seek">
                                            Hire fault solicitor :
                                            </h5>
                                            <p>
                                            Talk to a no-fault specialist who will help you get maximum compensation for your <strong> So Sure car insurance claim. </strong> 
                                            </p>
                                        </div>
                                    </li>
                                    <li>
                                        <div className="seemingly">
                                            <h5 className="Seek">
                                            Inform us about your claim :
                                            </h5>
                                            <p>
                                            Call us through the <strong>  So Sure insurance claim number </strong> once you are ready to initiate your claim after having collected enough evidence and important facts regarding the accident.
                                            </p>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="whyus">
                <div className="container">
                    <div className="row">
                        <div className="col-xxl-12 col-md-12">
                            <div className="why">
                                <h2 className="choose">
                                Our quick and easy claims  {" "}
                                    <span className="light"> process</span>
                                </h2>
                                <p className="complexities">
                                There are no queues or tiring procedures that you will have to go through when making a<strong> So Sure car accident claim. </strong> Our fast and simple process allows you to make a claim in just four easy steps!      </p>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-xxl-3 col-xl-6 col-lg-6 col-md-6 col-12">
                            <div className="box-service">
                                <img src={check} className="check" alt="" />
                                {/* <img src={experience} className="experience" alt="" /> */}
                                <h4>Reach out to us </h4>
                                <p>
                                For a free first consultation with one of our experienced solicitors, please dial our helpline at
                                <strong>
                                            {" "}
                                            <a href="tel:0800 772 0850">0800 772 0850</a>{" "}
                                        </strong>  </p>
                            </div>
                        </div>
                        <div className="col-xxl-3 col-xl-6 col-lg-6 col-md-6 col-12">
                            <div className="box-service">
                                <img src={check} className="check" alt="" />
                                <h4>Share details </h4>
                                <p>
                                Reveal all aspects that pertain to road accidents, such as date, time, location and cause.</p>
                            </div>
                        </div>
                        <div className="col-xxl-3 col-xl-6 col-lg-6 col-md-6 col-12">
                            <div className="box-service">
                                <img src={check} className="check" alt="" />
                                <h4>Evidence submission </h4>
                                <p>
                                Provide us with any vital evidence you have gathered from an accident scene, such as photos, videos, police report copies, witnesses’ statements or any other information that can make your <strong>So Sure insurance claim stronger.  </strong>                            </p>
                            </div>
                        </div>
                        <div className="col-xxl-3 col-xl-6 col-lg-6 col-md-6 col-12">
                            <div className="box-service">
                                <img src={check} className="check" alt="" />
                                <h4>Claim initiation  </h4>
                                <p>
                                Once all the supporting evidence and necessary documents are in place, we will submit your <strong> So Sure car insurance claim </strong> and aim to settle it as soon as possible.

                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="faqs">
                <div className="container">
                    <div className="row">
                        <div className="col-xxl-12 col-xl-12 col-md-12 col-12">
                            <div className="fq">
                                <h2>
                                    {" "}
                                    Frequently Asked{" "}
                                    <span className="light">Questions</span>
                                </h2>
                                <p className="complexities">
                                    Got questions about the <strong> So Sure car insurance claim? </strong>  We have them answered here.

                                </p>
                            </div>
                            <div className="allfaq">
                                <Accordion defaultActiveKey="0">
                                    <Accordion.Item eventKey="0">
                                        <Accordion.Header>
                                        Do I need to pay an excess for accident management services?                         </Accordion.Header>
                                        <Accordion.Body>
                                        No, you won’t be charged anything because they are fully recoverable from the other party's insurer.           </Accordion.Body>
                                    </Accordion.Item>
                                    <Accordion.Item eventKey="1">
                                        <Accordion.Header>
                                        Can I select my own repairer?
                                        </Accordion.Header>
                                        <Accordion.Body>
                                        Definitely, you are more than welcome to choose your preferred repairer. Alternatively, if you wish, we can organise repairs on your vehicle through a garage of your choice.
                                        </Accordion.Body>
                                    </Accordion.Item>
                                    <Accordion.Item eventKey="2">
                                        <Accordion.Header>
                                        Will using your services affect my no-claims bonus?
                                        </Accordion.Header>
                                        <Accordion.Body>
                                        Not at all. Our professional claims handlers will directly initiate the claim against the third party without affecting either your premiums or your no-claims discounts.         </Accordion.Body>
                                    </Accordion.Item>
                                    <Accordion.Item eventKey="3">
                                        <Accordion.Header>
                                        How long can I keep the replacement vehicle?
                                        </Accordion.Header>
                                        <Accordion.Body>
                                        When your car is not roadworthy and requires fixing after an accident, you will keep the replacement vehicle until your vehicle gets repaired.
                                        </Accordion.Body>
                                    </Accordion.Item>
                                    <Accordion.Item eventKey="4">
                                        <Accordion.Header>
                                        Will the replacement vehicle be similar to my car?

                                        </Accordion.Header>
                                        <Accordion.Body>
                                        No matter what type of car you have, we aim to offer you a like-for-like replacement vehicle that suits your needs. Call us via the <strong> So Sure car insurance claim number, </strong> and we will provide a replacement vehicle that matches your own vehicle.                        </Accordion.Body>
                                    </Accordion.Item>
                                    <Accordion.Item eventKey="5">
                                        <Accordion.Header>
                                        Are your car repairs guaranteed?  {" "}
                                        </Accordion.Header>
                                        <Accordion.Body>
                                        Yes. Our manufacturer-approved repairers provide high-quality and guaranteed repairs that exceed manufacturer specifications.     </Accordion.Body>
                                    </Accordion.Item>
                                    <Accordion.Item eventKey="6">
                                        <Accordion.Header>
                                        Can you help me recover all of the damages resulting from the accident?{" "}
                                        </Accordion.Header>
                                        <Accordion.Body>
                                        Yes. We will help you recover all costs from the other party’s insurer, including medical bills, transportation costs, repair expenses, replacement car expenses or any other out-of-pocket expenses you've incurred. 

                                        </Accordion.Body>
                                    </Accordion.Item>
                                </Accordion>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <Footer />
        </>
    );
};

export default Sosure;
