import React, { useRef } from "react";
import Accordion from "react-bootstrap/Accordion";
import check from "../assets/images/check.png";
import dualcar from "../../src/assets/images/dualcar.webp";
import carbnr1 from "../../src/assets/images/carbnr1.webp";
import Sidebarinner from "../components/Sidebar-inner";
import phonecall from "../assets/images/phonecall.png";
import Footer from "../components/footer";
import { Helmet } from "react-helmet";

const Collingwood = () => {
  const sidebarRef = useRef(null);

  const handleScrollToSidebar = () => {
    if (sidebarRef.current) {
      sidebarRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };
  return (
    <>
    <Helmet>
        <meta charSet="utf-8" />
        <title>Collingwood Car Insurance Accident Claim Phone Number</title>
        <meta
          name="description"
          content="Call us on the Collingwood car insurance claim number 0800 772 0850 for quick roadside assistance and expedited processing of your no-fault car accident claim."
        />
        <link
          rel="canonical"
          href="https://insurance-accident-claims.co.uk/Collingwood"
        />
      </Helmet>
      <section className="innernav">
        <div className="container">
          <div className="row">
            <div className="col-md-6 col-sm-6">
              <div className="left-logo">
                <h3>
                  Accident<span className="sipo"> Claims</span>
                </h3>
              </div>
            </div>
            <div className="col-md-6 col-sm-6">
              <div className="left-cta">
                <div class="headercnt">
                  <div class="chatmsg">
                    <img
                      src={phonecall}
                      className="phonecall"
                      alt="phonecall "
                    />
                  </div>
                  <div class="box-body">
                    <h3 class="nav-info-box-title">Toll Free Number </h3>
                    <p>
                      <a href="tel:0800 772 0850">0800 772 0850</a>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section
        className="newbnr"
        style={{ backgroundImage: "url(" + carbnr1 + ")" }}
      >
        <div className="container">
          <div className="row">
            <div className="col-xxl-8 col-xl-8 col-md-10 col-12">
              <div className="innerbnr">
                <h1>
                  <span className="onespan">Fast and</span>{" "}
                  <span className="sci">Reliable car insurance </span> claims phone number
               
                </h1>
                <p className="brokerage">
                  We will handle everything from the initial assessment until
                  the final claim resolution to get you back on the road as
                  quickly and smoothly as possible.
                </p>
                <div className="bnrbtn">
                  <a
                    class="db-btn-border btn-rollover phone"
                    href="tel:0800 772 0850"
                  >
                    Road Accident Claim Help?{" "}
                    <i class="fa-solid fa-arrow-right"></i>
                  </a>
                </div>
                <div className="bnrbtn">
                  <a
                    className="db-btn-border btn-rollover desktop"
                    onClick={handleScrollToSidebar}
                  >
                    Road Accident Claim Help?{" "}
                    <i className="fa-solid fa-arrow-right"></i>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="stickysection">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="group-4-8">
                <div className="cl8 tf-tab">
                  <div className="content-tab">
                    <div className="inner-content">
                      <h2>
                        Dedicated claim management
                        <br />
                        <span className="light">experts in the UK </span>{" "}
                      </h2>
                      <p className="disciplines">
                        We have a proven track record of dealing with thousands
                        of claims, making us one of the UK’s leading{" "}
                        <strong> Collingwood insurance claim </strong>{" "}
                        providers. Call us on the{" "}
                        <strong>
                          {" "}
                          Collingwood car insurance contact number{" "}
                        </strong>
                        to start your claim process with us. We will initiate
                        your <strong> Collingwood car insurance claim </strong>
                        without charge.
                      </p>
                      <hr />
                      <p className="bold-text">Zero excess </p>
                      <p className="bottom-te">
                        If it is not your fault, you do not pay any excess
                        charges, and your premiums remain unaltered.{" "}
                      </p>
                      <hr />
                      <p className="bold-text">No claims bonus safety </p>
                      <p className="bottom-te">
                        Using our services to make a{" "}
                        <strong> Collingwood insurance claim </strong>means that
                        you do not have to worry about losing your no-claims
                        bonus. We will keep all those hard-earned reductions
                        safe for you.
                      </p>
                      <hr />
                      <p className="bold-text">Rapid vehicle recovery</p>
                      <p className="bottom-te">
                        We ensure that after an accident, you are never left
                        stranded on the road. You can reach us via the{" "}
                        <strong>Collingwood insurance claim number </strong>
                        if you need emergency support. We will arrange for swift
                        and secure recovery of your vehicle right away.{" "}
                      </p>
                      <hr />
                      <p className="bold-text">Authorised repair shops </p>
                      <p className="bottom-te">
                        An approved manufacturer network of repairers will
                        guarantee that your car never sees second-rate repairs.{" "}
                      </p>
                      <hr />
                      <p className="bold-text">
                        Comparable replacement vehicle
                      </p>
                      <p className="bottom-te">
                        During the period when your car is being repaired or is
                        not running, we will arrange for a hire vehicle with a
                        similar make and model to yours.
                      </p>
                      <hr />
                      <p className="bold-text">A panel of expert solicitors</p>
                      <p className="bottom-te">
                        <strong>
                          {" "}
                          Our well-established solicitors have shown themselves
                          to be highly reliable in handling Collingwood car
                          insurance claims{" "}
                        </strong>{" "}
                        and have managed to get the maximum amount of
                        compensation payable by law for innocent drivers
                        involved in non-fault road accidents.
                      </p>
                      <hr />
                      <p className="bold-text"> Prompt claim settlement </p>
                      <p className="bottom-te">
                        A quick settling process of your{" "}
                        <strong> Collingwood motor insurance claim </strong>{" "}
                        enabled by our fast track system means less paperwork
                        and no long follow-ups.
                      </p>
                      <hr />
                      <p className="bold-text">
                        {" "}
                        Free advice without any obligation{" "}
                      </p>
                      <p className="bottom-te">
                        Are you looking for information about how to make a{" "}
                        <strong> Collingwood car insurance claim? </strong>
                        Call our helpline{" "}
                        <a href="tel:0800 772 0850">0800 772 0850</a> , and get
                        free no-obligation guidance on{" "}
                        <strong> Collingwood insurance claims.</strong> Our
                        solicitors will provide expert guidance to help you
                        proceed with your claim.{" "}
                      </p>
                      <hr />
                      <p className="bold-text">
                        {" "}
                        Conditional fee agreement (CFA){" "}
                      </p>
                      <p className="bottom-te">
                        If you lose the case, our solicitors handling{" "}
                        <strong> Collingwood insurance accident claims </strong>
                        will not charge you legal fees as they operate under
                        conditional fee agreements.{" "}
                      </p>
                      <hr />

                      <p className="bold-text"> Tailored support </p>
                      <p className="bottom-te">
                        Your case is given utmost priority. Our claims handler,
                        who will be personally working on your case, shall keep
                        updating you on the progress.{" "}
                      </p>
                    </div>
                  </div>
                </div>
                <div className="cl4" ref={sidebarRef}>
                  <Sidebarinner />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="photographs">
        <div className="container">
          <div className="row">
            <h2 className="road">
              We are here to take away the headache of{" "}
              <span className="light">
                dealing <br />
                with a car accident
              </span>{" "}
            </h2>
            <div className="col-xxl-6 col-xl-6 col-md-12 col-12">
              <div className="fourbox">
                <ul>
                  <li>
                    <img src={check} className="check" alt="" />
                    To deal with the emergency, contact our team when you
                    experience a crash by dialling{" "}
                    <strong> Collingwood insurance claim number. </strong>
                  </li>
                  <hr />
                  <li>
                    <img src={check} className="check" alt="" />
                    In order to pursue your losses, we will conduct an in-depth
                    investigation to ascertain whether any party negligently
                    acted.
                  </li>
                  <hr />
                  <li>
                    <img src={check} className="check" alt="" />
                    We will lead you through the process of{" "}
                    <strong> Collingwood insurance claims </strong> to get
                    compensation for the damages.
                  </li>
                  <hr />
                  <li>
                    <img src={check} className="check" alt="" />
                    We shall retrieve your car from where it got involved in an
                    accident and arrange for repairs at any
                    manufacturer-approved garage out of our network.
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-xxl-6 col-xl-6 col-md-12 col-12">
              <div className="fourbox">
                <ul>
                  <li>
                    <img src={check} className="check" alt="" />
                    If the vehicle is not repairable, we can assist you in
                    arranging for an independent engineer who will value your
                    written-off vehicle.
                  </li>
                  <hr />
                  <li>
                    <img src={check} className="check" alt="" />
                    Until the duration of your vehicle repairs, we will deliver
                    a replacement car to drive.
                  </li>
                  <hr />
                  <li>
                    <img src={check} className="check" alt="" />
                    The procedure of repair progress will constantly be checked
                    on and communicated back to you.
                  </li>
                  <hr />
                  <li>
                    <img src={check} className="check" alt="" />
                    Our solicitors’ help would guarantee you a justifiable{" "}
                    <strong> Collingwood motor insurance claim </strong>that
                    would result in deserving compensation for your damage. Just
                    make a call at <a href="tel:0800 772 0850">0800 772 0850</a>{" "}
                    and begin your claim with us.
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="carprocess">
        <div className="container ">
          <div className="row">
            <div className="col-xxl-12 col-xxl-12 col-md-12 col-12">
              <h2 className="trusted-title">
                {" "}
                Our accident management <br />
                <span className="light">solutions</span>{" "}
              </h2>
            </div>
          </div>
          <div className="row" id="possible">
            <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-12">
              <div className="claim">
                <ul>
                  <li>
                    <span className="sleej">Vehicle recovery:</span>If you have
                    been involved in an accident and need vehicle recovery, just
                    contact us at{" "}
                    <strong> Collingwood car insurance contact number. </strong>{" "}
                    Our roadside assistance will help move your damaged car from
                    the accident area to a safe location with minimal
                    disturbances.
                  </li>
                  <li>
                    <span className="sleej">Cost-free repairs:</span>For free
                    vehicle repair after a non-fault road traffic accident, call
                    our team using the{" "}
                    <strong> Collingwood insurance claim number. </strong> We
                    will sort out your vehicle repair from a reliable and
                    credible repairer without any excess.
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-12">
              <div className="claim" id="blue">
                <ul>
                  <li>
                    <span className="sleej">Safe storage:</span> Are you
                    concerned about where to park your car following a non-fault
                    motor accident? Give us a call on the{" "}
                    <strong> Collingwood insurance claim number. </strong>We
                    provide convenient vehicle storing services in
                    well-maintained compounds that protect your car until your
                    claim is assessed.
                  </li>
                  <li>
                    <span className="sleej">Replacement vehicle:</span>Should
                    your car be deemed unroadworthy, then you can be given
                    another one as a replacement. Get in touch with us through
                    our emergency hotline{" "}
                    <a href="tel:0800 772 0850">0800 772 0850</a> for a
                    like-for-like replacement vehicle so that you can go on with
                    your daily activities without stress. You are allowed to
                    keep the same until settlement of claims is reached.
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-12">
              <div className="claim">
                <ul>
                  <li>
                    <span className="sleej">Personal injury support:</span>
                    The{" "}
                    <strong> Collingwood car insurance contact number </strong>
                    is your best shot at knowing whether you qualify for a
                    personal injury claim. Our solicitors will advise you on how
                    to get compensated for your injuries.
                  </li>
                  <li>
                    <span className="sleej">Claims management:</span>We provide
                    all-around support throughout the entire process of{" "}
                    <strong> Collingwood insurance claims, </strong>right from
                    handling paperwork to negotiating with insurers, arranging
                    repairs and providing substitute cars.
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="yellowcta">
        <div className="container">
          <div className="row">
            <div className="cta2">
              <div className="col-xxl-8 col-xl-8 col-lg-8 col-12">
                <div className="legal">
                  <h3 className="experienced">
                    Got more questions?{" "}
                    <span className="light">Talk to our specialists</span>{" "}
                  </h3>
                  <p className="professionals">
                    Get in touch with our no-fault claim experts via{" "}
                    <strong>
                      car insurance contact number{" "}
                      <a className="cpt-no" href="tel:0800 772 0850">
                        {" "}
                        0800 772 0850{" "}
                      </a>{" "}
                    </strong>
                    to get the best advice for the{" "}
                    <strong> Collingwood insurance claims. </strong>
                  </p>
                </div>
              </div>
              <div className="col-xxl-4 col-xl-4 col-lg-4 col-12">
                <div class="process">
                  <a class="tf-button style-1 phone" href="tel:0800 772 0850">
                    Get in touch<span class="icon-keyboard_arrow_right"></span>
                  </a>
                </div>
                <div class="process">
                  <a
                    className="tf-button style-1 desktop"
                    onClick={handleScrollToSidebar}
                  >
                    Get in touch
                    <span className="icon-keyboard_arrow_right"></span>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <search className="updatedform">
        <div className="container">
          <div className="row text-center">
            <div className="col-xxl-12 col-xl-12 col-md-12 col-12">
              <h2 className="whiteheading">
                {" "}
                What sets us <br />
                <span className="light">apart? </span>
              </h2>
              <p className="sustained">
                In case you are unfortunate enough to be involved in a non-fault
                car accident, we make it our top priority to save you from any
                stress and inconvenience. Here are some reasons why we are the
                best choice for your claims management.{" "}
              </p>
            </div>
          </div>
          <div className="row">
            <div className="col-xxl-4 col-xl-4 col-lg-6 col-md-12 col-12">
              <div className="Claimant">
                <h5> Round-the-clock recovery services </h5>
                <p className="collision">
                  Looking for vehicle recovery services? Dial the{" "}
                  <strong> Collingwood car insurance phone number </strong> so
                  that we may reach out to you as fast as possible, regardless
                  of time.
                </p>

                <h5>Guaranteed repairs</h5>
                <p className="collision">
                  Your car will be repaired by our huge network of repairers who
                  will ensure its repairs are of high quality and guaranteed.{" "}
                </p>

                <h5> No insurance excess </h5>
                <p className="collision">
                  On your own policy, we will not register a{" "}
                  <strong> Collingwood car insurance claim. </strong> Hence,
                  there won’t be any impact on your premium rates.
                </p>
              </div>
            </div>
            <div className="col-xxl-4 col-xl-4 col-lg-6 col-md-12 col-12">
              <div className="Claimant">
                <h5> Free replacement vehicles </h5>
                <p className="collision">
                  To keep you mobile, we offer free like-for-like vehicle
                  replacements without any upfront payments.
                </p>
                <h5> Replacement vehicle at no cost </h5>
                <p className="collision">
                  We will maintain your mobility by providing you with a
                  replacement vehicle similar to the damaged vehicle without
                  requiring you to make any upfront payments.
                </p>

                <h5>Trusted network of partners</h5>
                <p className="collision">
                  To deliver the best in class service, we have established a
                  strong group of associate repairers and competent solicitors
                  across the country.{" "}
                </p>
              </div>
            </div>
            <div className="col-xxl-4 col-xl-4 col-lg-12 col-md-12 col-12">
              <div className="Claimant">
                <h5>Fast processing of claims</h5>
                <p className="collision">
                  Our experienced solicitors are skilled in negotiating with
                  other party’s insurers for fair and quick settlement of{" "}
                  <strong> Collingwood motor insurance claims. </strong>
                </p>

                <h5> Immediate assistance </h5>
                <p className="collision">
                  We provide immediate emergency assistance at the accident
                  scene after non-fault road traffic accidents without any
                  delay.
                </p>

                <h5> No excess </h5>
                <p className="collision">
                  You will not have to pay a single penny from your pocket or
                  make any additional payments for our services.
                </p>
              </div>
            </div>
          </div>
        </div>
      </search>

      <section className="Accident-claim">
        <div className="container">
          <div className="row">
            <div className="needed">
              <h2 className="trusted-title">
                Tips on how to increase the chances of <br />
                <span className="light"> receiving fair compensation</span>
              </h2>
              <p className="complexities">
                Being involved in a no-fault car accident is stressful enough,
                but dealing with the process of filing claims can be even more
                frustrating. To enhance your likelihood of receiving equitable
                compensation, establish that it was not your mistake. Here are
                critical aspects to consider after being involved in an accident
                so that you may get something worth it.{" "}
              </p>
            </div>
            <hr />
          </div>
          <div className="row" id="incdes">
            <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-12">
              <div className="Preparing">
                <ul>
                  <li>
                    <div className="seemingly">
                      <h5 className="Seek">Check for your own safety:</h5>
                      <p>
                        {" "}
                        Bring your car to rest in a safe place and check
                        yourself and other occupants of the vehicle for severe
                        or minor injuries.{" "}
                      </p>
                    </div>
                  </li>
                  <li>
                    <div className="seemingly">
                      <h5 className="Seek">Consult with the doctor: </h5>
                      <p>
                        Where there are injured persons in your vehicle, it is
                        important that you seek immediate medical attention from
                        a qualified person.
                      </p>
                    </div>
                  </li>
                  <li>
                    <div className="seemingly">
                      <h5 className="Seek">Get information:</h5>
                      <p>
                        Gather essential details from the accident scene,
                        including photographs of the vehicles involved, damage
                        caused, serious injuries and licence plates of all
                        drivers.
                      </p>
                    </div>
                  </li>
                  <li>
                    <div className="seemingly">
                      <h5 className="Seek">Notify the police</h5>
                      <p>
                        Call police officers to attend to the road accident
                        immediately. A police report will be used as substantial
                        evidence when making the{" "}
                        <strong> Collingwood motor insurance claim. </strong>
                      </p>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
            <div
              className="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-12"
              id="dualcardiv"
            >
              <img src={dualcar} alt="" className="dualcar" />
            </div>
            <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-12">
              <div className="Preparing">
                <ul>
                  <li>
                    <div className="seemingly">
                      <h5 className="Seek">Exchange information:</h5>
                      <p>
                        If possible, share insurance and contact details with
                        other drivers, such as names, telephone numbers,
                        addresses or insurance policy numbers and particulars
                        about the vehicles.{" "}
                      </p>
                    </div>
                  </li>
                  <li>
                    <div className="seemingly">
                      <h5 className="Seek"> Contact eyewitnesses </h5>
                      <p>
                        Collect data relating to eyewitnesses by noting down
                        their names along with phone numbers so that you can get
                        back to them afterwards.
                      </p>
                    </div>
                  </li>
                  <li>
                    <div className="seemingly">
                      <h5 className="Seek">Keep records of expenditure:</h5>
                      <p>
                        This is obtained by maintaining receipts used for fixing
                        costs, treatment expenses and any other kind of cost
                        that was incurred in order to make sure all your costs
                        are compensated for at once.{" "}
                      </p>
                    </div>
                  </li>
                  <li>
                    <div className="seemingly">
                      <h5 className="Seek">
                        {" "}
                        Speak with a no-fault claim specialist{" "}
                      </h5>
                      <p>
                        To file a no-fault claim, call us on{" "}
                        <a href="tel:0800 772 0850">0800 772 0850</a> now for
                        professional advice on how to obtain rightful
                        compensation for your damages.
                      </p>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="whyus">
        <div className="container">
          <div className="row">
            <div className="col-xxl-12 col-md-12">
              <div className="why">
                <h2 className="choose">
                  Interested in filing a no-fault claim?{" "}
                  <span className="light">Start the process</span>
                </h2>
                <p className="complexities">
                  We know that submitting a mutual car insurance compensation
                  request following an accident is difficult. Therefore, we made
                  it easy and simple for you. Contact us through the{" "}
                  <strong> Collingwood car insurance phone number. </strong>
                </p>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-xxl-3 col-xl-6 col-lg-6 col-md-6 col-12">
              <div className="box-service">
                <img src={check} className="check" alt="" />
                {/* <img src={experience} className="experience" alt="" /> */}
                <h4>Get in touch</h4>
                <p>
                  Please give us a call via our{" "}
                  <strong> Collingwood insurance claim number </strong>
                  Collingwood insurance claim number
                </p>
              </div>
            </div>
            <div className="col-xxl-3 col-xl-6 col-lg-6 col-md-6 col-12">
              <div className="box-service">
                <img src={check} className="check" alt="" />
                <h4>Share details</h4>
                <p>
                  Provide us with the date, time, location and what led to the
                  accident.
                </p>
              </div>
            </div>
            <div className="col-xxl-3 col-xl-6 col-lg-6 col-md-6 col-12">
              <div className="box-service">
                <img src={check} className="check" alt="" />
                <h4>Submit evidence</h4>
                <p>
                  Share any form of documents that can back up your complaint,
                  including pictures from the accident scene, eyewitness
                  accounts, police reports or CCTV videos.{" "}
                </p>
              </div>
            </div>
            <div className="col-xxl-3 col-xl-6 col-lg-6 col-md-6 col-12">
              <div className="box-service">
                <img src={check} className="check" alt="" />
                <h4>Claim processing</h4>
                <p>
                  We will initiate your claim and keep you updated on its
                  progress.{" "}
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="faqs">
        <div className="container">
          <div className="row">
            <div className="col-xxl-12 col-xl-12 col-md-12 col-12">
              <div className="fq">
                <h2>
                  {" "}
                  Frequently Asked{" "}
                  <span className="light">Questions (FAQ)</span>
                </h2>
                <p className="complexities">
                  Got questions about the{" "}
                  <strong> Collingwood insurance claims? </strong> We have them
                  answered here.
                </p>
              </div>
              <div className="allfaq">
                <Accordion defaultActiveKey="0">
                  <Accordion.Item eventKey="0">
                    <Accordion.Header>
                      Will I have to pay any excess for the replacement vehicle?
                    </Accordion.Header>
                    <Accordion.Body>
                      No. We are going to take all costs of the substitute car
                      from the wrongdoer’s insurer who caused this event.
                      However, if you refuse to cooperate with us, retain a
                      solicitor’s services without notifying us or give false
                      information when reporting, you may be liable to pay
                      excess charges.
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="1">
                    <Accordion.Header>
                      How long will it take for my claim to be settled?
                    </Accordion.Header>
                    <Accordion.Body>
                      We will strive to settle your claim for{" "}
                      <strong> Collingwood car insurance claim </strong>
                      expeditiously, though the duration of settlement would
                      depend on the peculiarities of your particular case. When
                      we have initiated your claim, we will keep updating you
                      about our progress.
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="2">
                    <Accordion.Header>
                      Will the cost of vehicle recovery be covered by the
                      at-fault party’s insurance?
                    </Accordion.Header>
                    <Accordion.Body>
                      Yes, the expenses relating to vehicle recovery are met by
                      the at-fault party’s insurance company. You may need to
                      produce some evidence, like an accident report or
                      photographs from the scene of the accident, that proves
                      that you deserve reimbursement for your costs in
                      recovering your vehicle.
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="3">
                    <Accordion.Header>
                      Can I get a credit hire after a non-fault road accident?{" "}
                    </Accordion.Header>
                    <Accordion.Body>
                      Yes. You would qualify for a credit hire if you are an
                      innocent driver. Whenever your car gets written off or is
                      under repair, and you need another one, please make
                      contact with us using the{" "}
                      <strong> Collingwood insurance claim number </strong>
                      provided below. We will give you the same model vehicle
                      until yours is out of order.
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="4">
                    <Accordion.Header>
                      Will not claiming at fault lead me to lose my no-claims
                      bonus?
                    </Accordion.Header>
                    <Accordion.Body>
                      Claiming directly with your own insurer may make you lose
                      your no-claims bonus. But if you file a claim here with
                      us, we do not make a claim against your policy, which
                      saves your hard-earned bonuses and discounts.
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="5">
                    <Accordion.Header>
                      Can I select my preferred repair shop for vehicle repairs?{" "}
                    </Accordion.Header>
                    <Accordion.Body>
                      Yes! We can organise that so that you have your car
                      repaired by your preferred repairer. We will Coordinate
                      with your repairer to guarantee good quality work and
                      promptness in repairing it.{" "}
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="6">
                    <Accordion.Header>
                      What kind of replacement vehicle will I get?{" "}
                    </Accordion.Header>
                    <Accordion.Body>
                      We shall provide a like-for-like substitute vehicle which
                      is of the same size, quality as well as usefulness as your
                      own car.Just call our{" "}
                      <strong> Collingwood insurance claims number </strong> and
                      let us know what you are looking for in terms of vehicles,
                      and we will give you exactly what suits you.
                    </Accordion.Body>
                  </Accordion.Item>
                </Accordion>
              </div>
            </div>
          </div>
        </div>
      </section>

      <Footer />
    </>
  );
};

export default Collingwood;
