import React, { useRef } from "react";
import Accordion from "react-bootstrap/Accordion";
import check from "../assets/images/check.png";
import dualcar from "../../src/assets/images/dualcar.webp";
import carbnr1 from "../../src/assets/images/carbnr1.webp";
import Sidebarinner from "../components/Sidebar-inner";
import phonecall from "../assets/images/phonecall.png";
import Footer from "../components/footer";
import { Helmet } from "react-helmet";

const Hughesinsurance = () => {
    const sidebarRef = useRef(null);

    const handleScrollToSidebar = () => {
        if (sidebarRef.current) {
            sidebarRef.current.scrollIntoView({ behavior: "smooth" });
        }
    };
    return (
        <>
        <Helmet>
        <meta charSet="utf-8" />
        <title>Hughes Insurance Car Insurance Accident Claim Number</title>
        <meta
          name="description"
          content="Considering filing a no-fault car accident claim? Reach out to us via the Hughes Insurance Car Insurance Accident Claim Number 0800 772 0850 for expedited claim settlement."
        />
        <link
          rel="canonical"
          href="https://insurance-accident-claims.co.uk/Hughes-insurance"
        />
      </Helmet>
            <section className="innernav">
                <div className="container">
                    <div className="row">
                        <div className="col-md-6 col-sm-6">
                            <div className="left-logo">
                                <h3>
                                    Accident<span className="sipo"> Claims</span>
                                </h3>
                            </div>
                        </div>
                        <div className="col-md-6 col-sm-6">
                            <div className="left-cta">
                                <div class="headercnt">
                                    <div class="chatmsg">
                                        <img
                                            src={phonecall}
                                            className="phonecall"
                                            alt="phonecall "
                                        />
                                    </div>
                                    <div class="box-body">
                                        <h3 class="nav-info-box-title">Toll Free Number </h3>
                                        <p>
                                            <a href="tel:0800 772 0850">0800 772 0850</a>
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section
                className="newbnr"
                style={{ backgroundImage: "url(" + carbnr1 + ")" }}
            >
                <div className="container">
                    <div className="row">
                        <div className="col-xxl-7 col-xl-7 col-md-10 col-12">
                            <div className="innerbnr">
                                <h1>
                                    <span className="onespan">Quick and trustworthy accident </span>{" "}
                                    <span className="sci"> insurance claims   </span> support
                                </h1>
                                <p className="brokerage">
                                    We will do everything from initial assessment to finalising your claim so you can be back on the road as soon as possible.
                                </p>
                                <div className="bnrbtn">
                                    <a class="db-btn-border btn-rollover phone" href="tel:0800 772 0850">
                                        Road Accident Claim Help?{" "}
                                        <i class="fa-solid fa-arrow-right"></i>
                                    </a>
                                </div>
                                <div className="bnrbtn">
                                    <a className="db-btn-border btn-rollover desktop" onClick={handleScrollToSidebar}>
                                        Road Accident Claim Help? <i className="fa-solid fa-arrow-right"></i>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="stickysection">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="group-4-8">
                                <div className="cl8 tf-tab">
                                    <div className="content-tab">
                                        <div className="inner-content">
                                            <h2>
                                                UK’s Top Claims Management

                                                <span className="light">  Experts </span>{" "}
                                            </h2>
                                            <p className="disciplines">
                                                We are leading accident management experts, offering UK Hughes insurance claims assistance with a proven record of handling thousands of successful cases. Call us on the <strong> Hughes car insurance contact number </strong>to make a claim. We will handle your<strong> Hughes car insurance claim </strong> for free.
                                            </p>
                                            <hr />
                                            <p className="bold-text">No excess charges</p>
                                            <p className="bottom-te">
                                                If you were not at fault in an accident, then you don’t have to worry about paying any excesses which could increase your premiums.                                           </p>
                                            <hr />
                                            <p className="bold-text">No-claims bonus protection </p>
                                            <p className="bottom-te">
                                                Whenever you make a claim with us, your no-claim bonus will not be lost. We ensure that we leave your no-claims discount intact.                                            </p>
                                            <hr />
                                            <p className="bold-text">
                                                Fast vehicle recovery
                                            </p>
                                            <p className="bottom-te">
                                                In case of an accident or collision, we would never like to see you stranded beside the road. Contact us immediately whenever you need emergency help at the <strong> Hughes car insurance contact number, </strong>and we shall always ensure that wherever it is stuck, your vehicle returns promptly and safely.                                          </p>
                                            <hr />
                                            <p className="bold-text">Approved garages </p>
                                            <p className="bottom-te">
                                                We will repair your car to the best standard at one of our approved workshops located in the UK.                                          </p>
                                            <hr />
                                            <p className="bold-text">Equivalent hire </p>
                                            <p className="bottom-te">
                                                When your vehicle is being repaired, or it is un-roadworthy, we can replace it with one identical to yours for temporary use under like-for-like hire.                                            </p>
                                            <hr />
                                            <p className="bold-text">Professional legal team
                                            </p>
                                            <p className="bottom-te">
                                                Our seasoned solicitors have successfully handled thousands of claims, leading to full compensation for victims.                                </p>
                                            <hr />
                                            <p className="bold-text">Quick claim settlement </p>
                                            <p className="bottom-te">
                                                There is little paperwork and no long waits due to our fast claim process. This means that you can make a claim easily without worrying about paperwork.                                            </p>
                                            <hr />
                                            <p className="bold-text">Free independent guidance  </p>
                                            <p className="bottom-te">
                                                For any questions on Hughes insurance claims, please call us on  <strong>
                                                    {" "}
                                                    <a href="tel:0800 772 0850">0800 772 0850</a>{" "}
                                                </strong> Our solicitors will give advice and assist you in proceeding with your case accordingly.
                                            </p>
                                            <hr />
                                            <p className="bold-text">No-win no-fee policy </p>
                                            <p className="bottom-te">
                                                Our solicitors handle our customer’s insurance claims on a no-win-no-fee basis. That is why if you lose the case, you won’t pay anything for the services rendered by them.                                      </p>
                                            <hr />

                                            <p className="bold-text">24/7 support</p>
                                            <p className="bottom-te">
                                                If an accident happens, our emergency assistance and guidance will always be provided. For immediate help after another driver causes a car accident, please dial <strong>
                                                    {" "}
                                                    <a href="tel:0800 772 0850">0800 772 0850</a>{" "}
                                                </strong>  , which is the hotline for our motor insurance claims.                                     </p>
                                        </div>
                                    </div>
                                </div>
                                <div className="cl4" ref={sidebarRef}>
                                    <Sidebarinner />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="photographs">
                <div className="container">
                    <div className="row">
                        <h2 className="road">
                            We will provide a shoulder to lean on with expert  {" "}
                            <span className="light">
                                accident <br />
                                management support
                            </span>{" "}
                        </h2>
                        <div className="col-xxl-6 col-xl-6 col-md-12 col-12">
                            <div className="fourbox">
                                <ul>
                                    <li>
                                        <img src={check} className="check" alt="" />
                                        Contact us via <strong> Hughes car insurance phone number </strong> in case of emergencies that arise from road accidents. now.

                                    </li>
                                    <hr />
                                    <li>
                                        <img src={check} className="check" alt="" />
                                        We will find out whether there was anyone else responsible whose actions caused your injuries that you can sue for money.                                     </li>
                                    <hr />
                                    <li>
                                        <img src={check} className="check" alt="" />
                                        We will assist you in filling forms for <strong> Hughes insurance claims </strong>so as to facilitate payment of compensation for losses and damages.                                </li>
                                    <hr />
                                    <li>
                                        <img src={check} className="check" alt="" />
                                        Our team shall retrieve your car from where it was wrecked and initiate repairs through our network of approved repairers.                            </li>
                                </ul>
                            </div>
                        </div>
                        <div className="col-xxl-6 col-xl-6 col-md-12 col-12">
                            <div className="fourbox">
                                <ul>
                                    <li>
                                        <img src={check} className="check" alt="" />
                                        Where the vehicle cannot be repaired, we would instruct an independent engineer to assess the damages.                                   </li>
                                    <hr />
                                    <li>
                                        <img src={check} className="check" alt="" />
                                        Your car may be undrivable during repairs. Therefore, we will provide a temporary replacement car.                                 </li>
                                    <hr />
                                    <li>
                                        <img src={check} className="check" alt="" />
                                        We will monitor how your repairs are going so as to keep you posted on the progress.                                    </li>
                                    <hr />
                                    <li>
                                        <img src={check} className="check" alt="" />
                                        To discuss further how you can make a <strong> Hughes insurance claim </strong> for your damages and losses, call us on
                                        <strong>
                                            {" "}
                                            <a href="tel:0800 772 0850">0800 772 0850</a>{" "}
                                        </strong> .                                  </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="carprocess">
                <div className="container ">
                    <div className="row">
                        <div className="col-xxl-12 col-xxl-12 col-md-12 col-12">
                            <h2 className="trusted-title">
                                {" "}
                                Our accident management  <br />
                                <span className="light">  services</span>{" "}
                            </h2>
                        </div>
                    </div>
                    <div className="row" id="possible">
                        <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-12">
                            <div className="claim">
                                <ul>
                                    <li>
                                        <span className="sleej">Car recovery:</span>     If you need the car to be recovered after involvement in an accident, dial the <strong> Hughes car insurance contact number. </strong> The vehicle recovery service is aimed at guaranteeing that your car is safely returned to our storage yards within the minimum time possible.
                                    </li>
                                    <li>
                                        <span className="sleej">Authorised repairs:</span>  Call our team using the<strong>  Hughes insurance claim number </strong> when you want your vehicle to be repaired. No additional charges will be billed for repairing a vehicle that will be done only in recognised garages located across the UK.
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-12">
                            <div className="claim" id="blue">
                                <ul>
                                    <li>
                                        <span className="sleej">Car storage:</span>    Where should you keep your car if it has been involved in an accident which was not even your own fault? Reach us through our <strong> Hughes insurance claim number. </strong> We have well-maintained compounds where we provide secure storage facilities for cars until your claim is finalised.
                                    </li>
                                    <li>
                                        <span className="sleej">Replacement vehicles:</span> When your car is not economically viable to be repaired, you can qualify for a replacement vehicle. Contact us on our helpline
                                        <strong>
                                            {" "}
                                            <a href="tel:0800 772 0850">0800 772 0850</a>{" "}
                                        </strong>  and get yourself a like-for-like replacement vehicle so that you can continue with your daily routine. You also have an option to hold it until the finalisation of your claim.


                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-12">
                            <div className="claim">
                                <ul>
                                    <li>
                                        <span className="sleej">Personal injury claim support:</span>
                                        The best way of knowing whether or not you are eligible to receive compensation for personal injuries is by calling us on the <strong> Hughes insurance claim number. </strong> Our solicitors will advise you accordingly about how to obtain compensation for personal injuries.                                  </li>
                                    <li>
                                        <span className="sleej">Claim management :</span>   We handle all paperwork, negotiate with insurers, organise repairs of vehicles and provide alternative cars.

                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="yellowcta">
                <div className="container">
                    <div className="row">
                        <div className="cta2">
                            <div className="col-xxl-8 col-xl-8 col-lg-8 col-12">
                                <div className="legal">
                                    <h3 className="experienced">
                                        Got More questions? Talk to {" "}
                                        <span className="light">  our specialists
                                        </span>{" "}
                                    </h3>
                                    <p className="professionals">
                                        Inquiry through car insurance contact number
                                        {" "}
                                        <strong>{" "}
                                            <a className="cpt-no" href="tel:0800 772 0850">
                                                {" "}
                                                0800 772 0850{" "}
                                            </a>{" "}
                                        </strong>
                                        from no fault claim experts to establish whether we can help you in making
                                        <strong>  Hughes insurance claims.  </strong>
                                    </p>
                                </div>
                            </div>
                            <div className="col-xxl-4 col-xl-4 col-lg-4 col-12">
                                <div class="process">
                                    <a class="tf-button style-1 phone" href="tel:0800 772 0850">
                                        Get in touch<span class="icon-keyboard_arrow_right"></span>
                                    </a>
                                </div>
                                <div class="process">
                                    <a className="tf-button style-1 desktop" onClick={handleScrollToSidebar}>
                                        Get in touch<span className="icon-keyboard_arrow_right"></span>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <search className="updatedform">
                <div className="container">
                    <div className="row text-center">
                        <div className="col-xxl-12 col-xl-12 col-md-12 col-12">
                            <h2 className="whiteheading">
                                {" "}
                                What makes us
                                <span className="light"> different?
                                </span>
                            </h2>
                            <p className="sustained">
                                Our focus is on helping you avoid the pressure that comes after being involved in an accident where you're not liable. These are just some advantages that come when choosing us as your claims partner                         </p>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-xxl-4 col-xl-4 col-lg-6 col-md-12 col-12">
                            <div className="Claimant">
                                <h5>Round-the-clock recovery</h5>
                                <p className="collision">
                                    Are you in need of car recovery services? Call the <strong> Hughes insurance claim number, </strong>  and we will get you up from the scene of a crash, no matter the time.

                                </p>

                                <h5>Guaranteed repairs</h5>
                                <p className="collision">
                                    We can organise high-quality and guaranteed repairs for your car through our wide network of repairers.                               </p>

                                <h5>No increase in insurance premium</h5>
                                <p className="collision">
                                    The <strong> Hughes motor insurance claim </strong> will not be made against your own policy. Therefore, premiums on your car will not go up.                            </p>
                            </div>
                        </div>
                        <div className="col-xxl-4 col-xl-4 col-lg-6 col-md-12 col-12">
                            <div className="Claimant">
                                <h5>Free replacement car
                                </h5>
                                <p className="collision">
                                    We shall offer you a like-for-like replacement vehicle at no cost to keep you mobile.</p>
                                <h5>A network of trusted partners</h5>
                                <p className="collision">
                                    We have built a solid network of approved repairers and experienced solicitors across the UK to provide you with reliable assistance.
                                </p>

                                <h5>Fast claims settlement</h5>
                                <p className="collision">
                                    To ensure that your<strong> Hughes motor insurance claim </strong>  is sorted out fairly and promptly, our experienced solicitors negotiate with the other driver’s insurer.
                                </p>
                            </div>
                        </div>
                        <div className="col-xxl-4 col-xl-4 col-lg-12 col-md-12 col-12">
                            <div className="Claimant">
                                <h5>Immediate help</h5>
                                <p className="collision">
                                    We assure you of immediate emergency aid after a no-fault road accident.
                                </p>

                                <h5>Tailored assistance</h5>
                                <p className="collision">
                                    Since we value your case as much as possible, we will assign a dedicated handler to handle your claim who will guide you at every step.                        </p>

                                <h5>No excess charges</h5>
                                <p className="collision">
                                    You don’t have to worry about extra payments or additional costs associated with our services because all expenses will be recovered from the other party’s insurer.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </search>

            <section className="Accident-claim">
                <div className="container">
                    <div className="row">
                        <div className="needed">
                            <h2 className="trusted-title">
                                How can you ensure maximum compensation for <br />
                                <span className="light"> your claim? </span>
                            </h2>
                            <p className="complexities">
                                It is an awful experience when you are in a mishap that wasn't your fault, but it's even worse to navigate through the claims process. To maximise the chances of receiving fair compensation, you need to provide irrefutable evidence of innocence. Here’s what you need to do once you have had a car accident and you want to get enough money as compensation.
                            </p>
                        </div>
                        <hr />
                    </div>
                    <div className="row" id="incdes">
                        <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-12">
                            <div className="Preparing">
                                <ul>
                                    <li>
                                        <div className="seemingly">
                                            <h5 className="Seek">Ensure your safety. :</h5>
                                            <p>
                                                {" "}
                                                Move your vehicle to a secure place and conduct an examination for any injuries on yourself and your passengers.                                     </p>
                                        </div>
                                    </li>
                                    <li>
                                        <div className="seemingly">
                                            <h5 className="Seek">
                                                Get immediate medical help.:{" "}
                                            </h5>
                                            <p>
                                                Immediately call for the services of professional doctors if any of those who were in your vehicle, including you, got injured in the accident.                                         </p>
                                        </div>
                                    </li>
                                    <li>
                                        <div className="seemingly">
                                            <h5 className="Seek">Collect evidence :</h5>
                                            <p>
                                                Gather evidence like photos, videos, eyewitness statements, vehicle damages and a copy of the police report.                                           </p>
                                        </div>
                                    </li>
                                    <li>
                                        <div className="seemingly">
                                            <h5 className="Seek">Call police :</h5>
                                            <p>
                                                Contacting the Police is another step to take. You will get sufficient evidence to support your <strong> Hughes insurance claim </strong> by calling law enforcement agents.                                       </p>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div
                            className="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-12"
                            id="dualcardiv"
                        >
                            <img src={dualcar} alt="" className="dualcar" />
                        </div>
                        <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-12">
                            <div className="Preparing">
                                <ul>
                                    <li>
                                        <div className="seemingly">
                                            <h5 className="Seek">Exchange information :</h5>
                                            <p>
                                                In case possible, swap details with the other driver involved in the accident. Some of this information may include their full names, phone numbers, home addresses as well as registration number plates owned by each party involved.                                           </p>
                                        </div>
                                    </li>
                                    <li>
                                        <div className="seemingly">
                                            <h5 className="Seek">Write down what you spend: </h5>
                                            <p>
                                                It is important that you keep the receipts of all costs even if you will be fully refunded because it is a basis for indemnification of all the charges.                                       </p>
                                        </div>
                                    </li>
                                    <li>
                                        <div className="seemingly">
                                            <h5 className="Seek">
                                                Contact  a no-fault solicitor:
                                            </h5>
                                            <p>
                                                For assistance on how to make a successful claim for compensation, call us using helpline number
                                                <strong>
                                                    {" "}
                                                    <a href="tel:0800 772 0850">0800 772 0850</a>{" "}
                                                </strong>

                                            </p>
                                        </div>
                                    </li>
                                    <li>
                                        <div className="seemingly">
                                            <h5 className="Seek">
                                                Make a claim  :
                                            </h5>
                                            <p> Contact us on <strong> Hughes car insurance claim number </strong> to initiate your claim process to get deserving compensation for your losses.

                                            </p>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="whyus">
                <div className="container">
                    <div className="row">
                        <div className="col-xxl-12 col-md-12">
                            <div className="why">
                                <h2 className="choose">
                                    Start your claim in just 4 {" "}
                                    <span className="light"> easy steps</span>
                                </h2>
                                <p className="complexities">
                                    Making a <strong> Hughes motor insurance claim </strong> after being involved in an accident in which you are not at fault can be scary. Therefore, we will help you through this. Call us on <strong> Hughes car insurance contact number </strong>  and begin it now.                               </p>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-xxl-3 col-xl-6 col-lg-6 col-md-6 col-12">
                            <div className="box-service">
                                <img src={check} className="check" alt="" />
                                {/* <img src={experience} className="experience" alt="" /> */}
                                <h4>Get in touch</h4>
                                <p>
                                    Communicate with our team about your claim through the <strong>  Hughes car insurance contact number.</strong></p>
                            </div>
                        </div>
                        <div className="col-xxl-3 col-xl-6 col-lg-6 col-md-6 col-12">
                            <div className="box-service">
                                <img src={check} className="check" alt="" />
                                <h4>Provide details
                                </h4>
                                <p>
                                    Tell us everything about the accident, including when, where and how it occurred.</p>
                            </div>
                        </div>
                        <div className="col-xxl-3 col-xl-6 col-lg-6 col-md-6 col-12">
                            <div className="box-service">
                                <img src={check} className="check" alt="" />
                                <h4>Give proof</h4>
                                <p>
                                    You need to offer some evidence that supports your claim. These may include photos of the scene, statements from witnesses, police records and videos recorded by CCTV cameras.
                                </p>
                            </div>
                        </div>
                        <div className="col-xxl-3 col-xl-6 col-lg-6 col-md-6 col-12">
                            <div className="box-service">
                                <img src={check} className="check" alt="" />
                                <h4>Claim initiation </h4>
                                <p>
                                    On evaluation, we will embark on your application. Our team shall be updating you all along about the progress of your claim.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="faqs">
                <div className="container">
                    <div className="row">
                        <div className="col-xxl-12 col-xl-12 col-md-12 col-12">
                            <div className="fq">
                                <h2>
                                    {" "}
                                    Frequently Asked{" "}
                                    <span className="light">Questions</span>
                                </h2>
                                <p className="complexities">
                                    Got questions about the <strong> Hughes car insurance claim?  </strong> We have them answered here.
                                </p>
                            </div>
                            <div className="allfaq">
                                <Accordion defaultActiveKey="0">
                                    <Accordion.Item eventKey="0">
                                        <Accordion.Header>
                                            Do I have to pay any extra fee for a replacement vehicle?                                  </Accordion.Header>
                                        <Accordion.Body>
                                            No, we charge the insurer of the party at fault with all prices of replacing your car. You'll be liable for the excess payments if you do not cooperate with us, use a solicitor that we do not know about or give us wrong details about the accident                                       </Accordion.Body>
                                    </Accordion.Item>
                                    <Accordion.Item eventKey="1">
                                        <Accordion.Header>
                                            How long does it take to get my claim settled?
                                        </Accordion.Header>
                                        <Accordion.Body>
                                            Our <strong>  Hughes car insurance claims </strong> are completed as quickly as possible, but settlement times will vary depending on the circumstances surrounding each case. If we file your claim with us, we’ll keep you updated about the progress.                          </Accordion.Body>
                                    </Accordion.Item>
                                    <Accordion.Item eventKey="2">
                                        <Accordion.Header>
                                            Does the other party’s insurance company cover recovery fees?
                                        </Accordion.Header>
                                        <Accordion.Body>
                                            Surely, all costs involving recovery of your vehicle are provided by the other driver’s insurer.                                 </Accordion.Body>
                                    </Accordion.Item>
                                    <Accordion.Item eventKey="3">
                                        <Accordion.Header>
                                            Can I have a credit hire after a no-fault road accident?
                                        </Accordion.Header>
                                        <Accordion.Body>
                                            Yes. A non-fault driver is entitled to credit hire. Dial the<strong> Hughes insurance claim number </strong> anytime you need another car like yours as long as yours is written off or being repaired.                                  </Accordion.Body>
                                    </Accordion.Item>
                                    <Accordion.Item eventKey="4">
                                        <Accordion.Header>
                                            Will my no-claims bonus be lost if it is not my fault?

                                        </Accordion.Header>
                                        <Accordion.Body>
                                            If your claim is passed through us, we will not make any claims against your own policy of insurance, and there would be no danger of losing even one year’s worth of accumulated bonuses or discounts.           </Accordion.Body>
                                    </Accordion.Item>
                                    <Accordion.Item eventKey="5">
                                        <Accordion.Header>
                                            Is it possible for me to use my own garage for car repairs?{" "}
                                        </Accordion.Header>
                                        <Accordion.Body>
                                            Certainly! We can organise for your vehicle to be fixed at a place that you prefer. Share the details of the repairer with us, and we will facilitate quality repairs and ensure they are done quickly enough.                 </Accordion.Body>
                                    </Accordion.Item>
                                    <Accordion.Item eventKey="6">
                                        <Accordion.Header>
                                            What type of car replaces mine?{" "}
                                        </Accordion.Header>
                                        <Accordion.Body>
                                            We shall arrange for an alternative vehicle equivalent in size, value and function to your own. Simply reach us through our <strong> Hughes insurance claim number </strong>explaining what you require. After that, we'll provide you with an appropriate replacement vehicle.
                                        </Accordion.Body>
                                    </Accordion.Item>
                                </Accordion>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <Footer />
        </>
    );
};

export default Hughesinsurance;
