import React, { useRef } from "react";
import insurancelogo from "../../src/assets/images/insurancelogo.png";
import Accordion from "react-bootstrap/Accordion";
import check from "../assets/images/check.png";
import dualcar from "../../src/assets/images/dualcar.webp";
import carbnr2 from "../../src/assets/images/carbnr2.webp";
import Sidebarinner from "../components/Sidebar-inner";
import phonecall from "../assets/images/phonecall.png";
import { Helmet } from "react-helmet";
import Footer from "../components/footer";

const Aviva = () => {
  const sidebarRef = useRef(null);

  const handleScrollToSidebar = () => {
    if (sidebarRef.current) {
      sidebarRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Aviva Car Insurance Claims | Aviva Claims Contact Number</title>
        <meta
          name="description"
          content=" Need help in making a successful car accident claim? Contact the Aviva Car Accident Claims Contact Number 0800 772 0850 for dedicated support every step of the way. "
        />
        <link
          rel="canonical"
          href="https://insurance-accident-claims.co.uk/Aviva "
        />
      </Helmet>
      <section className="innernav">
        <div className="container">
          <div className="row">
            <div className="col-md-6 col-sm-6">
              <div className="left-logo">
                {/* <img src={insurancelogo} className='insurancelogo' alt='Insurance Logo' /> */}
                <h3>Accident Claims</h3>
              </div>
            </div>
            <div className="col-md-6 col-sm-6">
              <div className="left-cta">
                <div class="headercnt">
                  <div class="chatmsg">
                    <img
                      src={phonecall}
                      className="phonecall"
                      alt="phonecall "
                    />
                  </div>
                  <div class="box-body">
                    <h3 class="nav-info-box-title">Toll Free Number </h3>
                    <p>
                      <a href="tel:0800 772 0850">0800 772 0850</a>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section
        className="newbnr"
        style={{ backgroundImage: "url(" + carbnr2 + ")" }}
      >
        <div className="container">
          <div className="row">
            <div className="col-xxl-8 col-xl-8 col-md-10 col-12">
              <div className="innerbnr">
                <h1>
                  <span className="onespan">Car Accident </span>{" "}
                  <span className="sci">Claims</span> Management Assistance{" "}
                </h1>
                <p className="brokerage">
                  We are your one-stop destination for end-to-end accident
                  management solutions after a no-fault car accident.{" "}
                </p>
                <div className="bnrbtn">
                  <a class="db-btn-border btn-rollover phone" href="tel:0800 772 0850">
                    Begin your claim {" "}
                    <i class="fa-solid fa-arrow-right"></i>
                  </a>
                </div>
                <div className="bnrbtn">
                <a className="db-btn-border btn-rollover desktop"  onClick={handleScrollToSidebar}>
                  Begin your claim  <i className="fa-solid fa-arrow-right"></i>
                </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="stickysection">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="group-4-8">
                <div className="cl8 tf-tab">
                  <div className="content-tab">
                    <div className="inner-content">
                      <h2>
                        Cost-free Aviva insurance <br />
                        <span className="light">accident support</span>{" "}
                      </h2>
                      <p className="disciplines">
                        If you are involved in a no-fault car accident, call{" "}
                        <strong>
                          {" "}
                          accident claims number{" "}
                          <a href="tel:0800 772 0850">0800 772 0850</a>.
                        </strong>{" "}
                        As one of the most experienced accident management
                        experts, we specialize in handling every aspect of
                        no-fault claims without putting any financial burden on
                        you. Get in touch with us on
                        <strong> Aviva accident number </strong>to get emergency
                        assistance for your no-fault accident management needs
                        at no upfront charges.
                      </p>
                      <hr />
                      <p className="bold-text">Zero excess fees </p>
                      <p className="bottom-te">
                        We provide no-fault car accident management assistance
                        without excess costs or additional charges. Unlike your
                        insurance company, when you contact us on the
                        <strong> Aviva accident number</strong>, you don’t have
                        to pay hundreds of pounds in excess fees.
                      </p>
                      <hr />
                      <p className="bold-text">Top-notch accidental repairs </p>
                      <p className="bottom-te">
                        Our dedicated team of no-fault claim specialists
                        strategically manage the claims process to safeguard
                        your accumulated discounts. Our claim handlers don’t
                        initiate a claim against your own insurance policy,
                        ensuring that your no-claim bonus stays intact. Call us
                        on Aviva claims contact number to make a hassle-free
                        claim while protecting your earned discounts.
                      </p>
                      <hr />
                      <p className="bold-text">
                        Manufacturer approved repairs{" "}
                      </p>
                      <p className="bottom-te">
                        Our dedicated team is always on hand to help you with
                        your vehicle repairs when you contact us via{" "}
                        <strong> car accident helpline 0800 772 0850.</strong>{" "}
                        We have a dedicated network of approved repair garages
                        that provide professional accident repairs using
                        manufacturer-approved parts.
                      </p>
                      <hr />
                      <p className="bold-text">No-cost replacement vehicle </p>
                      <p className="bottom-te">
                        Contact us on
                        <strong>Aviva accident claim phone number </strong> to
                        claim a replacement vehicle after the no-fault car
                        accident. We will arrange a cost-free like-for-like
                        replacement vehicle to get you back on the road.
                      </p>
                      <hr />
                      <p className="bold-text">Specialized support </p>
                      <p className="bottom-te">
                        We are experts in handling no-fault claims with utmost
                        dedication and professionalism. When you contact us on{" "}
                        <strong>Aviva accident claims helpline</strong>, you're
                        guaranteed to get dedicated support and assistance for
                        all your requirements.
                      </p>
                      <hr />
                      <p className="bold-text">Expert legal advice </p>
                      <p className="bottom-te">
                        We have a panel of top solicitors in the UK who possess
                        extensive experience in handling no-fault claims. When
                        you reach out to us on{" "}
                        <strong>the Aviva accident claim phone number</strong>,
                        we will connect you with an experienced solicitor who
                        will help you know your options and rights to pursue
                        your claim.{" "}
                      </p>
                      <hr />
                      <p className="bold-text">Secured vehicle storage </p>
                      <p className="bottom-te">
                        After the no-fault accident, we provide you with safe
                        storage of your damaged car in our secured yards.
                        Contact us on the
                        <strong> Aviva accident claims helpline </strong>to get
                        your vehicle transported to our well-maintained
                        facilities.
                      </p>
                      <hr />
                      <p className="bold-text">
                        Transparent and honest approach{" "}
                      </p>
                      <p className="bottom-te">
                        We provide clear and honest information to the claimants
                        about the claim process, ensuring that you have complete
                        knowledge of your options and rights to move ahead with
                        your claim. Call on{" "}
                        <strong>Aviva accident claims number </strong>to start
                        your claim with us.{" "}
                      </p>
                      <hr />
                      <p className="bold-text">Maximum compensation </p>
                      <p className="bottom-te">
                        When you reach out to us on{" "}
                        <strong>Aviva accident number </strong>to make your
                        claim, we immediately start assessing your case to
                        ensure that you get the maximum compensation for your
                        no-fault{" "}
                        <strong>Aviva insurance accident claim. </strong>
                      </p>
                      <hr />

                      <p className="bold-text">Fast claim resolution </p>
                      <p className="bottom-te">
                        We strive to settle your claim as soon as possible
                        without lengthy follow-up calls or paperwork. So, when
                        you call us on
                        <strong> Aviva claims contact number </strong>to discuss
                        your claim, you can rest assured that your no-fault{" "}
                        <strong>Aviva insurance accident claim</strong> will be
                        handled swiftly.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="cl4" ref={sidebarRef}>
                  <Sidebarinner />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="photographs">
        <div className="container">
          <div className="row">
            <h2 className="road">
              We keep you moving after the <br />
              <span className="light">no-fault car accident </span>{" "}
            </h2>
            <div className="col-xxl-6 col-xl-6 col-md-12 col-12">
              <div className="fourbox">
                <ul>
                  <li>
                    <img src={check} className="check" alt="" />
                    When you call us on{" "}
                    <strong>
                      the car accident helpline{" "}
                      <a href="tel:0800 772 0850">0800 772 0850</a>
                    </strong>{" "}
                    to get <strong> insurance accident support</strong>, we will
                    provide you with trusted advice and ensure you get back on
                    the road as soon as possible.
                  </li>
                  <hr />
                  <li>
                    <img src={check} className="check" alt="" />
                    Then, we will evaluate your{" "}
                    <strong>Aviva insurance accident claim.</strong> If we think
                    there are favourable chances of securing compensation, we
                    will gather the required documents and initiate your claim.
                  </li>
                  <hr />
                  <li>
                    <img src={check} className="check" alt="" />
                    We will help you hit the road again by arranging for a
                    like-for-like replacement vehicle free of cost. You can
                    contact us on{" "}
                    <strong>the Aviva accident claim phone number</strong> to
                    request a replacement vehicle.
                  </li>
                  <hr />
                  <li>
                    <img src={check} className="check" alt="" />
                    Also, we will arrange for your vehicle repairs from your
                    preferred repairer. Call us on{" "}
                    <strong>
                      the accident claims helpline{" "}
                      <a href="tel:0800 772 0850">0800 772 0850</a>{" "}
                    </strong>
                    to get your vehicle repaired after the no-fault accident
                    without any cost.
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-xxl-6 col-xl-6 col-md-12 col-12">
              <div className="fourbox">
                <ul>
                  <li>
                    <img src={check} className="check" alt="" />
                    After your vehicle repairs are finished, we’ll assess the
                    quality of repairs to ensure that original parts are used
                    during the repair.
                  </li>
                  <hr />
                  <li>
                    <img src={check} className="check" alt="" />
                    When you contact us on{" "}
                    <strong>the Aviva Accident Claims Number </strong>to make a
                    no-fault <strong>Aviva insurance accident claim</strong>, we
                    will collect details of the losses suffered by you and send
                    them to the at-fault driver’s insurance company with
                    supporting documents.
                  </li>
                  <hr />
                  <li>
                    <img src={check} className="check" alt="" />
                    We will handle your entire claim and liaise with all the
                    parties, including eyewitnesses, insurers, and repairers.
                    Also, we will appoint a solicitor who will guide you
                    throughout
                    <strong> the Aviva insurance accident claim </strong>
                    process.
                  </li>
                  <hr />
                  <li>
                    <img src={check} className="check" alt="" />
                    Once your vehicle gets repaired, we will get in touch to
                    schedule the collection of your repaired vehicle at a
                    convenient time.
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="carprocess">
        <div className="container ">
          <div className="row">
            <div className="col-xxl-12 col-xxl-12 col-md-12 col-12">
              <h2 className="trusted-title">
                {" "}
                Fast and effortless Aviva <br />
                <span className="light">insurance accident support </span>{" "}
              </h2>
            </div>
          </div>
          <div className="row" id="possible">
            <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-12">
              <div className="claim">
                <ul>
                  <li>
                    <span className="sleej">Vehicle recovery </span> <br />
                    Get in touch with us through{" "}
                    <strong>
                      {" "}
                      accident number{" "}
                      <a href="tel:0800 772 0850">0800 772 0850</a>{" "}
                    </strong>
                    immediately after the no-fault accident to get your vehicle
                    recovered from the accident scene. Our dedicated recovery
                    team will swiftly get into action and get your vehicle moved
                    to our secured storage facilities in minimal time.
                  </li>
                  <li>
                    <span className="sleej">Vehicle repairs </span>
                    <br />
                    Contact us on{" "}
                    <strong>the Aviva claims contact number </strong>to get a
                    comprehensive solution for your vehicle repairs after the
                    accident. We will organize instant repairs of your car from
                    the garage of your choice. We have a vast network of
                    reliable repair partners who provide high-quality repairs
                    using original parts.
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-12">
              <div className="claim" id="blue">
                <ul>
                  <li>
                    <span className="sleej">Secure storage </span> <br />
                    After <strong>the report accident</strong>, it's important
                    to move your vehicle to a safe place to prevent further
                    damage. When you call us on
                    <strong>
                      {" "}
                      the car insurance claim number{" "}
                      <a href="tel:0800 772 0850">0800 772 0850</a>
                    </strong>
                    , our team will arrive at the accident scene to collect your
                    vehicle from the roadside and secure it in our storage
                    facilities with any excess fees. In our secured yards, your
                    vehicle will stay protected from damage and theft until
                    you’re ready to reclaim it.
                  </li>
                  <li>
                    <span className="sleej">Courtesy car </span> <br />
                    When you’re involved in a no-fault Aviva report accident, we
                    will ensure you get the right replacement vehicle. You can
                    get in touch with us through the Aviva car accident helpline
                    to get a like-for-like credit hire vehicle without incurring
                    any additional expenses. You can keep the replacement
                    vehicle with you until your car gets repaired or you receive
                    the cheque for the damages.
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-12">
              <div className="claim">
                <ul>
                  <li>
                    <span className="sleej">Personal injury claim </span>
                    <br />
                    We work with experienced and professional solicitors who
                    have a track record of securing maximum compensation for
                    no-fault accident claimants. When you make your claim with
                    us, we will appoint the best solicitor who will help you
                    assess the paperwork and documents required for the claim.
                  </li>
                  <li>
                    <span className="sleej">
                      Comprehensive claims management{" "}
                    </span>{" "}
                    <br />
                    Our experienced solicitors have the expertise to handle all
                    the aspects of a no-fault accident claim with compassion and
                    professionalism. With us, you’ll receive the best assistance
                    and support at every step. You can call us on the
                    <strong>
                      {" "}
                      accident claim phone number{" "}
                      <a href="tel:0800 772 0850">0800 772 0850</a>
                    </strong>{" "}
                    to pursue a successful claim and get maximum compensation.
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="yellowcta">
        <div className="container">
          <div className="row">
            <div className="cta2">
              <div className="col-xxl-8 col-xl-8 col-lg-8 col-12">
                <div className="legal">
                  <h3 className="experienced">
                    Got questions?
                    <span className="light">
                      Talk to our no-fault claim specialists{" "}
                    </span>{" "}
                  </h3>
                  <p className="professionals">
                    Get in touch with us on the{" "}
                    <strong>Aviva accident claims helpline </strong>to receive
                    expert advice regarding your no-fault{" "}
                    <strong>Aviva insurance accident claim. </strong>
                  </p>
                </div>
              </div>
              <div className="col-xxl-4 col-xl-4 col-lg-4 col-12">
              <div class="process">
                  <a class="tf-button style-1 phone" href="tel:0800 772 0850">
                    Get in touch<span class="icon-keyboard_arrow_right"></span>
                  </a>
                </div>
                <div class="process">
                    <a className="tf-button style-1 desktop"  onClick={handleScrollToSidebar}>
                        Get in touch<span className="icon-keyboard_arrow_right"></span>
                    </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <search className="updatedform">
        <div className="container">
          <div className="row text-center">
            <div className="col-xxl-12 col-xl-12 col-md-12 col-12">
              <h2 className="whiteheading">
                {" "}
                Reasons to Choose<span className="light">Us </span>
              </h2>
              <p className="sustained">
                As one of the most experienced accident management experts in
                the UK, we have vast experience in helping individuals get back
                on the road after the accident and help them secure rightful
                compensation. Here are a few reasons why you must choose us for
                your accident management needs.{" "}
              </p>
            </div>
          </div>
          <div className="row">
            <div className="col-xxl-4 col-xl-4 col-lg-6 col-md-12 col-12">
              <div className="Claimant">
                <h5>No out-of-pocket expenses </h5>
                <p className="collision">
                  Unlike your insurer, we won’t charge any additional expenses
                  from you. So, when you choose us for your accident and claims
                  management needs, you don’t have to deal with unexpected
                  outlays.
                </p>

                <h5>24/7 support </h5>
                <p className="collision">
                  Car accidents can occur at any moment. That’s why we are
                  available round the clock to offer immediate assistance to
                  individuals following an accident. Contact us on{" "}
                  <strong>
                    the accident claims helpline{" "}
                    <a href="tel:0800 772 0850">0800 772 0850</a>{" "}
                  </strong>
                  to get emergency help from us.
                </p>

                <h5>Safe vehicle storage </h5>
                <p className="collision">
                  We offer safe and secure storage facilities to store your
                  damaged vehicle after a no-fault accident. Get in touch with
                  us on the <strong>Aviva accident claims number</strong> to get
                  your damaged car moved to our secured yards.
                </p>
              </div>
            </div>
            <div className="col-xxl-4 col-xl-4 col-lg-6 col-md-12 col-12">
              <div className="Claimant">
                <h5>Authorized repairs</h5>
                <p className="collision">
                  We have a vast network of carefully vetted repairers who
                  provide professional and high-quality repairs for damaged
                  vehicles using manufacturer-approved parts.
                </p>

                <h5>Complete solutions </h5>
                <p className="collision">
                  We provide a comprehensive range of Aviva insurance accident
                  support services. From vehicle recovery to accidental repairs,
                  replacement vehicle and claims management, we offer complete
                  solutions.
                </p>

                <h5>Friendly and supportive team </h5>
                <p className="collision">
                  Our supportive and friendly team will provide you with the
                  best assistance during this stressful time. From initial
                  consultations to claim settlement, we will offer the best
                  guidance and support at every step.
                </p>
              </div>
            </div>
            <div className="col-xxl-4 col-xl-4 col-lg-12 col-md-12 col-12">
              <div className="Claimant">
                <h5>Expert claim handling </h5>
                <p className="collision">
                  We will manage your no-fault{" "}
                  <strong>Aviva insurance accident claim</strong> professionally
                  and effectively. From paperwork to negotiations, we will
                  handle all the complexities of your claim.
                </p>

                <h5>Enhanced credit hire solutions</h5>
                <p className="collision">
                  We ensure quick delivery of a credit hire vehicle to your
                  doorstep so that you can continue doing your routine
                  activities without interruption.
                </p>

                <h5>Expedited services </h5>
                <p className="collision">
                  Through our well-established collaboration with reliable
                  repair networks and an experienced panel of solicitors in the
                  UK, we ensure that you receive prompt services without any
                  delay.
                </p>
              </div>
            </div>
          </div>
        </div>
      </search>

      <section className="Accident-claim">
        <div className="container">
          <div className="row">
            <div className="needed">
              <h2 className="trusted-title">
                What to do after the no-fault
                <br />
                <span className="light"> car accident</span>
              </h2>
              <p className="complexities">
                Knowing the right steps to take post a car accident that was
                someone else’s fault can help you avoid expensive losses and
                legal complexities. Here’s how you can increase your chances of
                getting compensation after a no-fault accident.
              </p>
            </div>
            <hr />
          </div>
          <div className="row" id="incdes">
            <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-12">
              <div className="Preparing">
                <ul>
                  <li>
                    <div className="seemingly">
                      <h5 className="Seek">
                        Stop your vehicle at a safe place{" "}
                      </h5>
                      <p>
                        {" "}
                        The first thing you need to do is park your car in a
                        safe place. Avoid panicking and let yourself calm down.{" "}
                      </p>
                    </div>
                  </li>
                  <li>
                    <div className="seemingly">
                      <h5 className="Seek">Ensure safety</h5>
                      <p>
                        Check whether the parties involved in the accident are
                        safe and secure. If you or anyone else have sustained
                        injuries, seek medical help immediately.
                      </p>
                    </div>
                  </li>
                  <li>
                    <div className="seemingly">
                      <h5 className="Seek">Report car accident to police</h5>
                      <p>
                        Informing the police about the no-fault car accident is
                        crucial. Try to{" "}
                        <strong>Aviva report car accident</strong> to the police
                        within 24 hours. Also, call us on the
                        <strong> Aviva accident number</strong> to get emergency
                        help after the accident.
                      </p>
                    </div>
                  </li>
                  <li>
                    <div className="seemingly">
                      <h5 className="Seek">Gather evidence</h5>
                      <p>
                        If you think you’re not at fault, collect evidence to
                        prove your innocence and make a successful{" "}
                        <strong>Aviva insurance accident claim</strong>. This
                        includes photographs and videos from the accident scene,
                        witness details and statements and CCTV footage.
                      </p>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
            <div
              className="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-12"
              id="dualcardiv"
            >
              <img src={dualcar} alt="" className="dualcar" />
            </div>
            <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-12">
              <div className="Preparing">
                <ul>
                  <li>
                    <div className="seemingly">
                      <h5 className="Seek">Collect essential information </h5>
                      <p>
                        Even if you were not at fault, gather the required
                        information related to the accident. This includes
                        details of the vehicles, driver information, the reason
                        behind the car accident and a copy of the police report.
                      </p>
                    </div>
                  </li>
                  <li>
                    <div className="seemingly">
                      <h5 className="Seek">Check your vehicle for recovery </h5>
                      <p>
                        Your vehicle may get damaged after a road accident.
                        Sometimes the damages may not be apparent, but you might
                        have to get your vehicle recovered from the accident
                        scene. If your vehicle needs to be recovered from the
                        accident scene, you can contact us through our{" "}
                        <strong>
                          {" "}
                          car insurance claim number{" "}
                          <a href="tel:0800 772 0850">0800 772 0850</a>{" "}
                        </strong>
                        to get your damaged vehicle recovered from the accident
                        scene.
                      </p>
                    </div>
                  </li>
                  <li>
                    <div className="seemingly">
                      <h5 className="Seek">Start your claim </h5>
                      <p>
                        After understanding all aspects, make a no-fault Aviva
                        insurance accident claim by giving a call on{" "}
                        <strong>
                          the car accident helpline{" "}
                          <a href="tel:0800 772 0850">0800 772 0850</a>{" "}
                        </strong>
                        to recover the deserving compensation. Our expert claim
                        handlers will discuss your claim and provide the best
                        solutions.
                      </p>
                    </div>
                  </li>
                  <li>
                    <div className="seemingly">
                      <h5 className="Seek">Don’t settle too early </h5>
                      <p>
                        When dealing with a car accident claim, don't rush into
                        an early settlement. By agreeing to settle your claim
                        early, you may miss out on the opportunity to get
                        deserving compensation for your injuries and damages.{" "}
                      </p>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="whyus">
        <div className="container">
          <div className="row">
            <div className="col-xxl-12 col-md-12">
              <div className="why">
                <h2 className="choose">
                  How can you make your no-fault Aviva{" "}
                  <span className="light">
                    <br /> insurance accident claim with us?{" "}
                  </span>
                </h2>
                <p className="complexities">
                  You can reach out to us through the{" "}
                  <strong>
                    {" "}
                    accident claim phone number{" "}
                    <a href="tel:0800 772 0850">0800 772 0850</a>{" "}
                  </strong>
                  to make a successful claim with us. We have a simple and
                  straightforward claim process without any hassles or complex
                  paperwork. You can get started with us via the Aviva car
                  accident helpline for an efficient and smooth claim
                  experience.
                </p>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-xxl-3 col-xl-6 col-lg-6 col-md-6 col-12">
              <div className="box-service">
                <img src={check} className="check" alt="" />
                <h4>Contact us </h4>
                <p>
                  Get in touch with our <strong>Aviva claims department</strong>{" "}
                  by giving us a call on the{" "}
                  <steong>Aviva accident claims number</steong> to report car
                  accident and start your claim journey.
                </p>
              </div>
            </div>
            <div className="col-xxl-3 col-xl-6 col-lg-6 col-md-6 col-12">
              <div className="box-service">
                <img src={check} className="check" alt="" />
                <h4>Share relevant information </h4>
                <p>
                  Provide us with all the relevant details related to the
                  accident, such as time, location, date, the reason behind the
                  accident and any other crucial information that may help us
                  establish the claim in your favor.
                </p>
              </div>
            </div>
            <div className="col-xxl-3 col-xl-6 col-lg-6 col-md-6 col-12">
              <div className="box-service">
                <img src={check} className="check" alt="" />
                <h4>Submit the required documents and evidence </h4>
                <p>
                  We may ask you to provide us with essential documents,
                  including photos or videos of the accident scene, police
                  report, witness statements, medical records, and repair
                  estimates to strengthen your case.
                </p>
              </div>
            </div>
            <div className="col-xxl-3 col-xl-6 col-lg-6 col-md-6 col-12">
              <div className="box-service">
                <img src={check} className="check" alt="" />
                <h4>Claim settlement </h4>
                <p>
                  Once we have gathered all the necessary information, we will
                  initiate your claim and provide you with regular updates.
                  After your <strong>Aviva insurance accident claim </strong>is
                  resolved, you’ll get deserving compensation for your losses.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="faqs">
        <div className="container">
          <div className="row">
            <div className="col-xxl-12 col-xl-12 col-md-12 col-12">
              <div className="fq">
                <h2>
                  {" "}
                  Frequently Asked{" "}
                  <span className="light">Questions (FAQ)</span>
                </h2>
                <p className="complexities">
                  Got more questions about our no-fault Aviva insurance accident
                  support services. We have them answered below.{" "}
                </p>
              </div>
              <div className="allfaq">
                <Accordion defaultActiveKey="0">
                  <Accordion.Item eventKey="0">
                    <Accordion.Header>
                      How long can I keep the credit hire vehicle provided by
                      you?
                    </Accordion.Header>
                    <Accordion.Body>
                      When you call our Insurance Accident Number 0800 772 0850,
                      we will provide you with a like-for-like replacement
                      vehicle for a certain period of time. You can keep the
                      vehicle until your damaged vehicle is fully repaired.
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="1">
                    <Accordion.Header>
                      How much compensation will I receive for my vehicle
                      repairs after an accident?
                    </Accordion.Header>
                    <Accordion.Body>
                      When you make a no-fault Aviva insurance accident claim
                      with us, you will either receive compensation to repair
                      your damaged vehicle or get money to buy a brand-new car.
                      The amount of compensation you receive will depend on the
                      extent of the damages.
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="2">
                    <Accordion.Header>
                      How do you maintain high levels of customer satisfaction
                      throughout the car repair process?
                    </Accordion.Header>
                    <Accordion.Body>
                      We maintain open and honest communication with our
                      customers throughout the repair process, from initial
                      assessment to completion. In addition, we have a vast
                      network of best garages for accidental repairs and we
                      provide our clients with the freedom to choose their
                      desired garage for car repairs.
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="3">
                    <Accordion.Header>
                      What documents do I require for a personal injury claim?
                    </Accordion.Header>
                    <Accordion.Body>
                      Our solicitor may require certain documents to make your
                      claim stronger. These may include your medical record
                      history, medical bills and images of the vehicles and
                      accident scene. You must provide us with these documents
                      for better claim processing.
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="4">
                    <Accordion.Header>
                      How fast can you arrange a replacement vehicle after a
                      no-fault car accident?
                    </Accordion.Header>
                    <Accordion.Body>
                      When you call us on the Aviva claims contact number, we
                      provide you with a free courtesy car immediately after the
                      accident within a span of 24 hours.
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="5">
                    <Accordion.Header>
                      Will I have to pay any excess fee?
                    </Accordion.Header>
                    <Accordion.Body>
                      With us, you don’t have to pay any excess fees. However,
                      before we take up your case, we will make sure that we
                      will be able to successfully complete your claim.
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="6">
                    <Accordion.Header>
                      What type of replacement vehicle will I get?
                    </Accordion.Header>
                    <Accordion.Body>
                      We have a large fleet of vehicles, and we will provide you
                      with a replacement vehicle that matches the standard of
                      your own vehicle so that you can continue doing your daily
                      activities without any hassle.
                    </Accordion.Body>
                  </Accordion.Item>
                </Accordion>
              </div>
            </div>
          </div>
        </div>
      </section>

      <Footer />
    </>
  );
};

export default Aviva;
