import React, { useRef } from "react";
import Accordion from "react-bootstrap/Accordion";
import check from "../assets/images/check.png";
import dualcar from "../../src/assets/images/dualcar.webp";
import carbnr1 from "../../src/assets/images/carbnr1.webp";
import Sidebarinner from "../components/Sidebar-inner";
import phonecall from "../assets/images/phonecall.png";
import Footer from "../components/footer";
import { Helmet } from "react-helmet";

const Bmwinsurance = () => {
    const sidebarRef = useRef(null);

    const handleScrollToSidebar = () => {
        if (sidebarRef.current) {
            sidebarRef.current.scrollIntoView({ behavior: "smooth" });
        }
    };
    return (
        <>
            <Helmet>
                <meta charSet="utf-8" />
                <title> BMW Car Insurance Accident Claim Phone Number </title>
                <meta
                    name="description"
                    content="Need assistance with filing a no-fault car accident claim? Reach out to us on the BMW Car Insurance Accident Claim Phone Number 0800 772 0850 for quick assistance."
                />
                <link
                    rel="canonical"
                    href="https://insurance-accident-claims.co.uk/Bmw-insurance"
                />
            </Helmet>
            <section className="innernav">
                <div className="container">
                    <div className="row">
                        <div className="col-md-6 col-sm-6">
                            <div className="left-logo">
                                <h3>
                                    Accident<span className="sipo"> Claims</span>
                                </h3>
                            </div>
                        </div>
                        <div className="col-md-6 col-sm-6">
                            <div className="left-cta">
                                <div class="headercnt">
                                    <div class="chatmsg">
                                        <img
                                            src={phonecall}
                                            className="phonecall"
                                            alt="phonecall "
                                        />
                                    </div>
                                    <div class="box-body">
                                        <h3 class="nav-info-box-title">Toll Free Number </h3>
                                        <p>
                                            <a href="tel:0800 772 0850">0800 772 0850</a>
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section
                className="newbnr"
                style={{ backgroundImage: "url(" + carbnr1 + ")" }}
            >
                <div className="container">
                    <div className="row">
                        <div className="col-xxl-7 col-xl-7 col-md-10 col-12">
                            <div className="innerbnr">
                                <h1>
                                    <span className="onespan"> Professional accident</span>{" "}
                                    <span className="sci">  Insurance  </span> claims number
                                </h1>
                                <p className="brokerage">
                                    In the scenario of road accidents, we take care of everything from recovering vehicles to arranging replacement cars and handling your claim.
                                </p>
                                <div className="bnrbtn">
                                    <a class="db-btn-border btn-rollover phone" href="tel:0800 772 0850">
                                        Road Accident Claim Help?{" "}
                                        <i class="fa-solid fa-arrow-right"></i>
                                    </a>
                                </div>
                                <div className="bnrbtn">
                                    <a className="db-btn-border btn-rollover desktop" onClick={handleScrollToSidebar}>
                                        Road Accident Claim Help? <i className="fa-solid fa-arrow-right"></i>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="stickysection">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="group-4-8">
                                <div className="cl8 tf-tab">
                                    <div className="content-tab">
                                        <div className="inner-content">
                                            <h2>
                                                BMW accident claim support

                                                <span className="light">  without excess </span>{" "}
                                            </h2>
                                            <p className="disciplines">
                                                If you have ever been involved in a car accident at any time, get in touch with the <strong> BMW claims number, </strong> and our immediate assistance will always be available to you, but without charging you a single cent. Our objective is the provision of <strong> BMW insurance accident support </strong> services that are professional and friendly and reduce the stress and strain associated with your <strong> BMW accident claim. </strong>
                                            </p>
                                            <hr />
                                            <p className="bold-text">No Hidden Charges</p>
                                            <p className="bottom-te">
                                                No upfront or excess charges are required. By using our services, you don’t have to spend thousands of pounds before going back on the road. Call us via the BMW claims number for car crash management solutions.                                     </p>
                                            <hr />
                                            <p className="bold-text">Safeguarding your no-claims discounts</p>
                                            <p className="bottom-te">
                                                We acknowledge how crucial it is to maintain your precious savings intact. Henceforth, we strive towards ensuring a smooth procedure of filing car insurance accident claims so that it does not affect the no-claims bonus at all.                                </p>
                                            <hr />
                                            <p className="bold-text">
                                                Quality repairs
                                            </p>
                                            <p className="bottom-te">
                                                In the UK, we have a reliable network of authorised repairers known for specialising in quality repairs. Dialling the<strong> BMW claims contact number </strong>  will lead us to organise your car’s repairs through our trusted repair partners, who use only genuine parts sourced from the manufacturers.
                                            </p>
                                            <hr />
                                            <p className="bold-text">Costless vehicle replacement </p>
                                            <p className="bottom-te">
                                                Just dial the <strong> BMW car insurance claim number </strong> and let us know what type of vehicle you want. We shall provide you with a substitute car which matches your own.
                                            </p>
                                            <hr />
                                            <p className="bold-text">Expert claims handling</p>
                                            <p className="bottom-te">
                                                Our team of professionals handles all aspects related to processing <strong> BMW insurance accident claims, </strong>  including uninsured losses and claims management, ensuring that you are not troubled by such matters.                                          </p>
                                            <hr />
                                            <p className="bold-text">Repair tracking and progress communication
                                            </p>
                                            <p className="bottom-te">
                                                From scratch till the end, we will manage the entire repair process so that we are going to inform you about how far repairs have gone on your vehicle.                             </p>
                                            <hr />
                                            <p className="bold-text">Customised assistance </p>
                                            <p className="bottom-te">
                                                When you call us on the <strong> BMW car accident helpline, </strong> a dedicated handler for claims will be available at your service, who will be your single point of contact throughout dealing with <strong> BMW insurance accident claims. </strong>


                                            </p>
                                            <hr />
                                            <p className="bold-text">Safe vehicle storage </p>
                                            <p className="bottom-te">
                                                In the event of a no-fault accident, we have secure storage yards where we can store your damaged vehicle to prevent it from further deterioration and theft.

                                            </p>
                                            <hr />
                                            <p className="bold-text">Professional help</p>
                                            <p className="bottom-te">
                                                We possess a group of highly skilled solicitors as well as experts who have been closely involved in handling <strong> BMW insurance accident claims. </strong> You can rely on us to deal with your claim appropriately.
                                            </p>
                                            <hr />

                                            <p className="bold-text">Fast claim processing </p>
                                            <p className="bottom-te">
                                                Our claims procedures are made easy so that your claim can be settled as soon as possible without unnecessary delays.                         </p>
                                        </div>
                                    </div>
                                </div>
                                <div className="cl4" ref={sidebarRef}>
                                    <Sidebarinner />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="photographs">
                <div className="container">
                    <div className="row">
                        <h2 className="road">
                            We’ll offer maximum support to get{" "}
                            <span className="light">
                                you back  <br />
                                on track
                            </span>{" "}
                        </h2>
                        <div className="col-xxl-6 col-xl-6 col-md-12 col-12">
                            <div className="fourbox">
                                <ul>
                                    <li>
                                        <img src={check} className="check" alt="" />
                                        If you suffer a non-fault road accident, call our car accident helpline on
                                        <strong>
                                            {" "}
                                            <a href="tel:0800 772 0850">0800 772 0850</a>{" "}
                                        </strong> and we will instantly give you all the necessary assistance to get you on the road again.

                                    </li>
                                    <hr />
                                    <li>
                                        <img src={check} className="check" alt="" />
                                        Once an independent engineer has evaluated your <strong> BMW insurance accident claim, </strong> our team of experienced solicitors will explain the options available for enforcing your rights.                              </li>
                                    <hr />
                                    <li>
                                        <img src={check} className="check" alt="" />
                                        We also offer a replacement vehicle at no cost so that your normal schedules continue uninterrupted.                  </li>
                                    <hr />
                                    <li>
                                        <img src={check} className="check" alt="" />
                                        From negotiating accidental repairs with insurers to recovering uninsured losses or corresponding with other relevant parties, our dedicated team handles everything about your claim.         </li>
                                </ul>
                            </div>
                        </div>
                        <div className="col-xxl-6 col-xl-6 col-md-12 col-12">
                            <div className="fourbox">
                                <ul>
                                    <li>
                                        <img src={check} className="check" alt="" />
                                        When you phone our <strong> BMW claims contact number, </strong>  we will evaluate your car’s damage degree and arrange for its repair by a trusted repairer.                        </li>
                                    <hr />
                                    <li>
                                        <img src={check} className="check" alt="" />
                                        We will introduce you to a UK dedicated claims expert who can help you recover the highest possible amount of compensation for all your losses.
                                    </li>
                                    <hr />
                                    <li>
                                        <img src={check} className="check" alt="" />
                                        In case of a no-fault road accident, we’ll ensure that your rights are not infringed upon and everyone treats you fairly.
                                    </li>
                                    <hr />
                                    <li>
                                        <img src={check} className="check" alt="" />
                                        After the completion of repairs on your vehicle, we shall get in touch with you to arrange its delivery.
                                    </li>

                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="carprocess">
                <div className="container ">
                    <div className="row">
                        <div className="col-xxl-12 col-xxl-12 col-md-12 col-12">
                            <h2 className="trusted-title">
                                {" "}
                                Wide range of services  <br />
                                <span className="light"> we offer  </span>{" "}
                            </h2>
                        </div>
                    </div>
                    <div className="row" id="possible">
                        <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-12">
                            <div className="claim">
                                <ul>
                                    <li>
                                        <span className="sleej">Vehicle recovery  :</span>We understand the pain and frustrations which come along after a non-fault car accident. Therefore, we will arrange for qualified recovery agents to recover it safely from the accident scene.

                                    </li>
                                    <li>
                                        <span className="sleej">Vehicle repairs:</span> Through our vast network of approved garages where high-quality work is done within record time, we provide excellent car repair services for damaged cars.
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-12">
                            <div className="claim" id="blue">
                                <ul>
                                    <li>
                                        <span className="sleej">Vehicle storage:</span>  In case you are involved in an unprovoked accident that was not your fault, then there is no need to worry about the safety of your car. We shall lock it away in one of our secure compounds. If you would like to keep your vehicle safe until your claim is assessed without paying any more, then please call us directly on the BMW car accident helpline.
                                    </li>
                                    <li>
                                        <span className="sleej">Replacement vehicle:</span>
                                        Your car will be repaired, and a replacement vehicle with the same specifications as yours will be provided for you to continue with other engagements without any setbacks. We will provide you with another one similar to your own and suits your daily needs. You just need to dial a BMW accident claims number for a free replacement vehicle.

                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-12">
                            <div className="claim">
                                <ul>
                                    <li>
                                        <span className="sleej"> Personal injury assistance :</span>
                                        You should be compensated for damages and injuries suffered. Our team of claim solicitors is highly experienced in personal injury cases, and they will scrutinise your case thoroughly so that you get fair compensation for damages and injuries sustained. Start by calling the <strong> BMW claims contact number </strong>  in order to make your claim.
                                    </li>
                                    <li>
                                        <span className="sleej">Claim management :</span> We handle all the related intricacies from paperwork filing up to the conclusion of the <strong> BMW insurance accident claims.</strong>


                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="yellowcta">
                <div className="container">
                    <div className="row">
                        <div className="cta2">
                            <div className="col-xxl-8 col-xl-8 col-lg-8 col-12">
                                <div className="legal">
                                    <h3 className="experienced">
                                        Got More questions? Talk to {" "}
                                        <span className="light">  our specialists
                                        </span>{" "}
                                    </h3>
                                    <p className="professionals">
                                        Get in touch with our no-fault claim experts via <strong> car insurance claim contact number </strong>
                                        {" "}
                                        <strong>{" "}
                                            <a className="cpt-no" href="tel:0800 772 0850">
                                                {" "}
                                                0800 772 0850 {" "}
                                            </a>{" "}
                                        </strong>
                                        to get the best advice to pursue a successful
                                        <strong>  BMW accident claim.  </strong>
                                    </p>
                                </div>
                            </div>
                            <div className="col-xxl-4 col-xl-4 col-lg-4 col-12">
                                <div class="process">
                                    <a class="tf-button style-1 phone" href="tel:0800 772 0850">
                                        Get in touch<span class="icon-keyboard_arrow_right"></span>
                                    </a>
                                </div>
                                <div class="process">
                                    <a className="tf-button style-1 desktop" onClick={handleScrollToSidebar}>
                                        Get in touch<span className="icon-keyboard_arrow_right"></span>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <search className="updatedform">
                <div className="container">
                    <div className="row text-center">
                        <div className="col-xxl-12 col-xl-12 col-md-12 col-12">
                            <h2 className="whiteheading">
                                {" "}
                                Why choose us as your accident management
                                <span className="light">   company?
                                </span>
                            </h2>
                            <p className="sustained">
                                We are specialists in non-fault car accident management, providing excellent support services. Through the<strong> BMW insurance accident support services,</strong> we remove the stress and burden associated with managing your claim.                      </p>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-xxl-4 col-xl-4 col-lg-6 col-md-12 col-12">
                            <div className="Claimant">
                                <h5>Timely assistance </h5>
                                <p className="collision">
                                    Reach us through the <strong> BMW car accident helpline </strong> for quick remedies to get back on track. When you call, we will immediately send assistance to the scene of the accident.

                                </p>

                                <h5>Repair monitoring </h5>
                                <p className="collision">
                                    We will be constantly on the lookout for your vehicle repair, and we will keep updating you about how far it has reached                      </p>

                                <h5>Efficient vehicles collection</h5>
                                <p className="collision">
                                    Once your car is fixed, it is picked up. Either contact us through our <strong> BMW accident claim phone number </strong>  or call us on this line to organise a convenient day and location for the pickup of the car.                 </p>
                            </div>
                        </div>
                        <div className="col-xxl-4 col-xl-4 col-lg-6 col-md-12 col-12">
                            <div className="Claimant">
                                <h5>Quality repairs assured
                                </h5>
                                <p className="collision">
                                    After reporting the accident to us through the<strong> BMW car insurance claim number, </strong>  we ensure that all repairs done on your vehicle conform to the manufacturer’s specifications, keeping its authenticity and preserving any existing guarantees. </p>
                                <h5>Personal support and guidance</h5>
                                <p className="collision">
                                    On filing a<strong> BMW insurance accident claim </strong>  with us, you are allocated a dedicated claims handler who will keep in touch with you throughout the process and update you at every point.
                                </p>

                                <h5>Choice of repairer at owner’s discretion</h5>
                                <p className="collision">
                                    This means that you can get your car repaired from the garage of your choice so that your vehicle gets repaired according to your own standards.
                                </p>
                            </div>
                        </div>
                        <div className="col-xxl-4 col-xl-4 col-lg-12 col-md-12 col-12">
                            <div className="Claimant">
                                <h5>Experienced legal advisors</h5>
                                <p className="collision">
                                    For all aspects of your <strong> BMW insurance accident claim, </strong>  there is a vast network of skilled solicitors who work as quickly as possible.
                                </p>

                                <h5>24/7 support</h5>
                                <p className="collision">
                                    No matter what time it is we are always available for our clients in cases where they need urgent help.
                                </p>

                                <h5>Rapid claims settlement</h5>
                                <p className="collision">
                                    With us, you can make your claim without loads of paperwork and follow-up phone calls.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </search>

            <section className="Accident-claim">
                <div className="container">
                    <div className="row">
                        <div className="needed">
                            <h2 className="trusted-title">
                                How do you maximise your compensation for <br />
                                <span className="light">a BMW accident claim? </span>
                            </h2>
                            <p className="complexities">
                                It is quite stressful going through accident claims. However, taking just a few simple steps can help you get compensated fairly for injuries sustained in a car wreck.
                            </p>
                        </div>
                        <hr />
                    </div>
                    <div className="row" id="incdes">
                        <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-12">
                            <div className="Preparing">
                                <ul>
                                    <li>
                                        <div className="seemingly">
                                            <h5 className="Seek">Check for injuries and keep safe:</h5>
                                            <p>
                                                {" "}
                                                First and foremost, once an accident that was absolutely not your fault has happened, go to a safe place and check yourself and your passengers for any signs of injury.                              </p>
                                        </div>
                                    </li>
                                    <li>
                                        <div className="seemingly">
                                            <h5 className="Seek">
                                                Gather evidence :{" "}
                                            </h5>
                                            <p>
                                                When an accident occurs, it’s good to take pictures of the damaged vehicles, including those involved in the collision, as well as the scenes where they happened and also injured people who were there at that time.                           </p>
                                        </div>
                                    </li>
                                    <li>
                                        <div className="seemingly">
                                            <h5 className="Seek">Get medical help :</h5>
                                            <p>
                                                Even if it’s not very serious, seek medical attention after being injured and recount all the happenings so that there are no loopholes in your case.                                       </p>
                                        </div>
                                    </li>
                                    <li>
                                        <div className="seemingly">
                                            <h5 className="Seek">Notify authorities about the accident :</h5>
                                            <p>
                                                Contact the police concerning the car accident and demand a copy of the police report.                            </p>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div
                            className="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-12"
                            id="dualcardiv"
                        >
                            <img src={dualcar} alt="" className="dualcar" />
                        </div>
                        <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-12">
                            <div className="Preparing">
                                <ul>
                                    <li>
                                        <div className="seemingly">
                                            <h5 className="Seek">Maintain detailed notes :</h5>
                                            <p>
                                                Maintain proper records that demonstrate subsequent expenses such as medical bills, transportation costs, solicitors’ fees, reduced earnings, or miscellaneous expenses.                                        </p>
                                        </div>
                                    </li>
                                    <li>
                                        <div className="seemingly">
                                            <h5 className="Seek">Know your rights and options : </h5>
                                            <p>
                                                Call the <strong> BMW insurance claim number </strong>  to learn about your rights and what types of damages can be awarded to you                 </p>
                                        </div>
                                    </li>
                                    <li>
                                        <div className="seemingly">
                                            <h5 className="Seek">
                                                Don’t accept an initial offer :
                                            </h5>
                                            <p> An insurance firm may make an upfront payment in order to cut costs. Do not take that kind of resolution with the insurance company, as it will deny you any further claims in future.
                                            </p>
                                        </div>
                                    </li>
                                    <li>
                                        <div className="seemingly">
                                            <h5 className="Seek">
                                                Commence with your claim  :
                                            </h5>
                                            <p>Get in touch with our team of experienced solicitors who handle <strong> BMW insurance accident claims </strong> today. We have no-win, no-fee solicitors who are always ready to assist you to get back all your losses legally.

                                            </p>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="whyus">
                <div className="container">
                    <div className="row">
                        <div className="col-xxl-12 col-md-12">
                            <div className="why">
                                <h2 className="choose">
                                    Simplified and straightforward BMW insurance{" "}
                                    <span className="light"> accident claims process </span>
                                </h2>
                                <p className="complexities">
                                    With our company, it could never be easier to make BMW insurance accident claims. Furthermore, with our quick, easy and plain-sailing manner of dealing with claims, you can easily get started on your non-fault claim without any difficulty. Call us at
                                    <strong>
                                        {" "}
                                        <a href="tel:0800 772 0850">0800 772 0850</a>{" "}
                                    </strong> and let our team guide you through your claim.                                   </p>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-xxl-3 col-xl-6 col-lg-6 col-md-6 col-12">
                            <div className="box-service">
                                <img src={check} className="check" alt="" />
                                {/* <img src={experience} className="experience" alt="" /> */}
                                <h4>Get in touch</h4>
                                <p>
                                    Call the <strong> BMW accident number </strong> as soon as possible after a non-fault accident. </p>
                            </div>
                        </div>
                        <div className="col-xxl-3 col-xl-6 col-lg-6 col-md-6 col-12">
                            <div className="box-service">
                                <img src={check} className="check" alt="" />
                                <h4>Provide us with more information
                                </h4>
                                <p>
                                    The details we need include the date, time, place and detailed account of how the event took place.</p>
                            </div>
                        </div>
                        <div className="col-xxl-3 col-xl-6 col-lg-6 col-md-6 col-12">
                            <div className="box-service">
                                <img src={check} className="check" alt="" />
                                <h4>Submit evidence </h4>
                                <p>
                                    If there is any major evidence related to the incident, such as photographs of the accident scene or eyewitness statements and other CCTV videos, they should be provided to us.
                                </p>
                            </div>
                        </div>
                        <div className="col-xxl-3 col-xl-6 col-lg-6 col-md-6 col-12">
                            <div className="box-service">
                                <img src={check} className="check" alt="" />
                                <h4>Claim review and resolution </h4>
                                <p>
                                    After getting all the necessary information and documents that are required by law, we shall review your claim within a reasonable timeframe as well as resolve this matter quickly and efficiently.

                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="faqs">
                <div className="container">
                    <div className="row">
                        <div className="col-xxl-12 col-xl-12 col-md-12 col-12">
                            <div className="fq">
                                <h2>
                                    {" "}
                                    Frequently Asked{" "}
                                    <span className="light">Questions</span>
                                </h2>
                                <p className="complexities">
                                    Got questions about  <strong> BMW insurance accident claims?</strong> We have them answered here.
                                </p>
                            </div>
                            <div className="allfaq">
                                <Accordion defaultActiveKey="0">
                                    <Accordion.Item eventKey="0">
                                        <Accordion.Header>
                                            What evidence must be provided for a successful <strong> BMW insurance accident claim? </strong>                        </Accordion.Header>
                                        <Accordion.Body>
                                            For us to prove your innocence and get you a fair amount of compensation, we need enough proof. In strengthening your claim, some things that may help are pictures of the place where the accident took place, contact addresses or phone numbers of eyewitnesses, and receipts showing expenses paid by you after the car crash.
                                        </Accordion.Body>
                                    </Accordion.Item>
                                    <Accordion.Item eventKey="1">
                                        <Accordion.Header>
                                            Whose responsibility is it to foot the bill for BMW insurance accident support services?
                                        </Accordion.Header>
                                        <Accordion.Body>
                                            If you have a no-fault road collision, you qualify for <strong> BMW insurance accident support </strong>  services. The at-fault party’s insurer will pay the cost of replacing the car and repairing it.            </Accordion.Body>
                                    </Accordion.Item>
                                    <Accordion.Item eventKey="2">
                                        <Accordion.Header>
                                            Will I get credit after a road traffic accident?
                                        </Accordion.Header>
                                        <Accordion.Body>
                                            If your vehicle is unroadworthy and you’re in need of replacement, call us on our<strong> BMW car accident helpline, </strong>where we shall provide you with another vehicle as yours is no longer able to be driven.                 </Accordion.Body>
                                    </Accordion.Item>
                                    <Accordion.Item eventKey="3">
                                        <Accordion.Header>
                                            What do I gain from filing a BMW insurance accident claim?
                                        </Accordion.Header>
                                        <Accordion.Body>
                                            That is to say, there are numerous advantages of making a claim through us, like:
                                            <p><li>There is no fee</li></p>
                                            <p><li>Excess premiums don’t have to be paid to your insurers</li></p>
                                            <p><li>It helps you keep your no-claims bonus</li></p>
                                            <p><li>It ensures that you keep all the rights attached to the policy.</li></p>                  </Accordion.Body>
                                    </Accordion.Item>
                                    <Accordion.Item eventKey="4">
                                        <Accordion.Header>
                                            Can I get advice from car crash management firms?

                                        </Accordion.Header>
                                        <Accordion.Body>
                                            The incidence of road traffic accidents always brings with it mixed feelings. Unlike insurance companies, accident management firms make it simple to deal with the aftermath of the accident. Furthermore, submitting any kind of claim with firms similar will enable you to get deserving compensation for all manner of losses, including personal injury and so on.   </Accordion.Body>
                                    </Accordion.Item>
                                    <Accordion.Item eventKey="5">
                                        <Accordion.Header>
                                            Could my vehicle be repaired by my preferred garage?{" "}
                                        </Accordion.Header>
                                        <Accordion.Body>
                                            Yes, we would be able to repair your car in a garage of your choice if you tell us where it is.
                                        </Accordion.Body>
                                    </Accordion.Item>
                                    <Accordion.Item eventKey="6">
                                        <Accordion.Header>
                                            What do I do if my car is beyond fixing?{" "}
                                        </Accordion.Header>
                                        <Accordion.Body>
                                            If your vehicle is completely written off we shall provide you with another one which will be just the same as the damaged one.
                                        </Accordion.Body>
                                    </Accordion.Item>
                                </Accordion>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <Footer />
        </>
    );
};

export default Bmwinsurance;
