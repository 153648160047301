import React, { useRef } from "react";
import Accordion from "react-bootstrap/Accordion";
import check from "../assets/images/check.png";
import dualcar from "../../src/assets/images/dualcar.webp";
import carbnr1 from "../../src/assets/images/carbnr1.webp";
import Sidebarinner from "../components/Sidebar-inner";
import phonecall from "../assets/images/phonecall.png";
import Footer from "../components/footer";
import { Helmet } from "react-helmet";

const InsuretheBox = () => {
  const sidebarRef = useRef(null);

  const handleScrollToSidebar = () => {
    if (sidebarRef.current) {
      sidebarRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Insurethebox Car Insurance Accident Claim Phone Number</title>
        <meta
          name="description"
          content="Make a successful claim with our expert assistance. Contact our team via the Insurethebox Car Insurance Accident Claim Phone Number 0800 772 0850 to get started. "
        />
        <link
          rel="canonical"
          href="https://insurance-accident-claims.co.uk/insure-the-box"
        />
      </Helmet>
      <section className="innernav">
        <div className="container">
          <div className="row">
            <div className="col-md-6 col-sm-6">
              <div className="left-logo">
                <h3>
                  Accident<span className="sipo"> Claims</span>
                </h3>
              </div>
            </div>
            <div className="col-md-6 col-sm-6">
              <div className="left-cta">
                <div class="headercnt">
                  <div class="chatmsg">
                    <img
                      src={phonecall}
                      className="phonecall"
                      alt="phonecall "
                    />
                  </div>
                  <div class="box-body">
                    <h3 class="nav-info-box-title">Toll Free Number </h3>
                    <p>
                      <a href="tel:0800 772 0850">0800 772 0850</a>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section
        className="newbnr"
        style={{ backgroundImage: "url(" + carbnr1 + ")" }}
      >
        <div className="container">
          <div className="row">
            <div className="col-xxl-8 col-xl-8 col-md-10 col-12">
              <div className="innerbnr">
                <h1>
                  <span className="onespan">Quick and</span>{" "}
                  <span className="sci">hassle-free </span> accident claims management
                </h1>
                <p className="brokerage">
                  For peace of mind after a no-fault traffic accident, we provide a comprehensive range of services, from vehicle recovery to claims management.
                </p>
                <div className="bnrbtn">
                  <a class="db-btn-border btn-rollover phone" href="tel:0800 772 0850">
                    Road Accident Claim Help?{" "}
                    <i class="fa-solid fa-arrow-right"></i>
                  </a>
                </div>
                <div className="bnrbtn">
                  <a className="db-btn-border btn-rollover desktop" onClick={handleScrollToSidebar}>
                    Road Accident Claim Help? <i className="fa-solid fa-arrow-right"></i>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="stickysection">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="group-4-8">
                <div className="cl8 tf-tab">
                  <div className="content-tab">
                    <div className="inner-content">
                      <h2>
                        Ensuring effortless & smooth recovery after
                        <br />
                        <span className="light">a road traffic accident </span>{" "}
                      </h2>
                      <p className="disciplines">
                        We have the know-how and experience to simplify the process of dealing with a no-fault road accident through our full accident management offering without any hidden fees. Call {" "}
                        <strong>
                          {" "}
                          Insure the Box claims number
                        </strong>{" "}
                        for assistance to begin your journey with us.
                      </p>
                      <hr />
                      <p className="bold-text">No excess </p>
                      <p className="bottom-te">
                        We assist you in getting back on track following motor accidents by making an Insure the Box car insurance claim against someone else’s policy, saving you from having extra charges.
                      </p>
                      <hr />
                      <p className="bold-text">No-claims bonus unaffected </p>
                      <p className="bottom-te">
                        During the course of settling your <strong>  Insure the Box car insurance </strong> claims, we make sure your earned bonuses and accumulated discounts remain intact.
                      </p>
                      <hr />
                      <p className="bold-text">
                        First-rate car repairs
                      </p>
                      <p className="bottom-te">
                        According to industry standards, our network of trusted repairers works according to manufacturer-approved specifications to repair your vehicle as per the highest standards of excellence.
                      </p>
                      <hr />
                      <p className="bold-text">Support around the clock </p>
                      <p className="bottom-te">
                        Any time an accident happens, we are available all day and night long to offer emergency support as well as direction. For immediate help after a non-fault car accident, please contact us on car insurance claims number{" "}<a href="tel:0800 772 0850">0800 772 0850</a>.
                      </p>
                      <hr />
                      <p className="bold-text">Like-for-like replacement car</p>
                      <p className="bottom-te">
                        In order to keep you going with your daily routines with minimal disturbance in case of a non-fault accident, we shall provide you with a like-for-like replacement vehicle throughout the period of repairing your vehicle or the settlement of <strong> Insure the Box accident claims. </strong>
                      </p>
                      <hr />
                      <p className="bold-text">Repair monitoring</p>
                      <p className="bottom-te">
                        We will perpetually check on the progress of your vehicle repair process in order to reinstate it back to its original state.
                      </p>
                      <hr />
                      <p className="bold-text">No increase in insurance premium</p>
                      <p className="bottom-te">
                        Your insurance premium will not be increased because making your <strong> Insure the Box car accident claim </strong> through us is not designed to trigger it under your own policy.
                      </p>
                      <hr />
                      <p className="bold-text"> Dedicated claims handler </p>
                      <p className="bottom-te">
                        Your claims handler will handle your{" "} <strong> Insure the Box car accident claim </strong> from beginning to end while updating you regularly.
                      </p>
                      <hr />
                      <p className="bold-text"> Quick claim settlement</p>
                      <p className="bottom-te">
                        The compensation claiming process for a road traffic accident is scary, but our efficient handling of cases means that there will be no delays in your settlement.{" "}
                      </p>
                      <hr />

                      <p className="bold-text">Clearly open approach</p>
                      <p className="bottom-te">
                        We believe in being transparent as well as honest. Therefore, we will make sure that you are involved throughout your{" "}<strong> Insure the Box insurance accident claim </strong> with clear communication about its progress from the day of initiation to settlement.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="cl4" ref={sidebarRef}>
                  <Sidebarinner />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="photographs">
        <div className="container">
          <div className="row">
            <h2 className="road">
              Our best claims management assistance aids   {" "}
              <span className="light">
                you <br />
                in recovering faster
              </span>{" "}
            </h2>
            <div className="col-xxl-6 col-xl-6 col-md-12 col-12">
              <div className="fourbox">
                <ul>
                  <li>
                    <img src={check} className="check" alt="" />
                    After an accident that was not your fault, we assist you in getting back on the road quickly and effectively.
                  </li>
                  <hr />
                  <li>
                    <img src={check} className="check" alt="" />
                    Just dial <strong> <a href="tel:0800 772 0850">0800 772 0850</a> </strong>on our claims line, and our team will record all the details of your accident plus give you detailed advice on how you can qualify for our services.
                  </li>
                  <hr />
                  <li>
                    <img src={check} className="check" alt="" />
                    We’ll provide you with a temporary replacement vehicle to get you back to your usual routines.
                  </li>
                  <hr />
                  <li>
                    <img src={check} className="check" alt="" />
                    Our solicitors will advise how best to handle this situation so as not to prejudice any prospects of compensation.
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-xxl-6 col-xl-6 col-md-12 col-12">
              <div className="fourbox">
                <ul>
                  <li>
                    <img src={check} className="check" alt="" />
                    We ensure that your <strong> Insure the Box car insurance claim </strong>is made without complexity but with maximum benefits to the victims involved.
                  </li>
                  <hr />
                  <li>
                    <img src={check} className="check" alt="" />
                    Dial us at <strong> Insure the Box claims phone number</strong> now for instant car repair service. We will organise repairs for your car by manufacturer-approved repairers if needed.
                  </li>
                  <hr />
                  <li>
                    <img src={check} className="check" alt="" />
                    You do not have to deal with drivers, witnesses, insurers or solicitors. We will communicate with them on your behalf.
                  </li>
                  <hr />
                  <li>
                    <img src={check} className="check" alt="" />
                    Every single detail of your <strong>  Insure the Box accident claim </strong> is taken care of by our solicitors, who pursue the right indemnity for each client’s case.
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="carprocess">
        <div className="container ">
          <div className="row">
            <div className="col-xxl-12 col-xxl-12 col-md-12 col-12">
              <h2 className="trusted-title">
                {" "}
                Accident management services <br />
                <span className="light"> we offer </span>{" "}
              </h2>
            </div>
          </div>
          <div className="row" id="possible">
            <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-12">
              <div className="claim">
                <ul>
                  <li>
                    <span className="sleej">Car recovery:</span>Need help moving your immovable car to the repairer or another safe zone? We are at your service. Call our team on the{" "}
                    <strong> Insure the Box claims number, </strong>and we will organise the quick recovery of your car from where it had been involved in a crash.
                  </li>
                  <li>
                    <span className="sleej">Vehicle repairs:</span>Our committed group has established an extensive network of authorised repairers across the United Kingdom. Just get us through the  <strong> Insure the Box claims phone number </strong>so that we can connect you with someone who fixes every part of your car and brings it back to its original state.
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-12">
              <div className="claim" id="blue">
                <ul>
                  <li>
                    <span className="sleej">Vehicle storage:</span> Concerned about the security of your wrecked car? Don’t worry. Our vehicle storage facilities are safe for damaged vehicles such as yours until the end of repairs or settlement of claims without matters such as excess charges or payments made in advance. Expedited services are available upon calling the{" "} <strong> Insure the Box claims number. </strong>
                  </li>
                  <li>
                    <span className="sleej">Replacement vehicle:</span>It would be unjust after an accident, which was not his fault, to give him a replacement car that does not suit his particular requirements. Contact us on{" "}
                    <strong>
                      {" "}
                      <a href="tel:0800 772 0850">0800 772 0850</a>{" "}
                    </strong>
                    via car insurance claims number, and during the time when yours is being repaired, you will have a replacement vehicle that suits your routine well enough.
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-12">
              <div className="claim">
                <ul>
                  <li>
                    <span className="sleej">Personal injury claim:</span>
                    Our panel has many personal accident compensation solicitors who have dealt with thousands of cases. We will ensure that you get the best legal representation in order to be awarded damages for your injuries and loss.
                  </li>
                  <li>
                    <span className="sleej">Claims administration:</span>Are you frustrated with the complexities in the claims process? Call now on <strong> Insure the Box car insurance claims number </strong> to get help throughout the process of your claim.
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="yellowcta">
        <div className="container">
          <div className="row">
            <div className="cta2">
              <div className="col-xxl-8 col-xl-8 col-lg-8 col-12">
                <div className="legal">
                  <h3 className="experienced">
                    Got more questions? Contact our  {" "}
                    <span className="light">no-fault claim specialists</span>{" "}
                  </h3>
                  <p className="professionals">
                    Contact us today at{" "}
                    <strong>{" "}
                      <a className="cpt-no" href="tel:0800 772 0850">
                        {" "}
                        0800 772 0850{" "}
                      </a>{" "}
                    </strong>
                    to discuss your accident management needs and get the best guidance for your
                    <strong> Insure the Box accident claim.  </strong>
                  </p>
                </div>
              </div>
              <div className="col-xxl-4 col-xl-4 col-lg-4 col-12">
                <div class="process">
                  <a class="tf-button style-1 phone" href="tel:0800 772 0850">
                    Get in touch<span class="icon-keyboard_arrow_right"></span>
                  </a>
                </div>
                <div class="process">
                  <a className="tf-button style-1 desktop" onClick={handleScrollToSidebar}>
                    Get in touch<span className="icon-keyboard_arrow_right"></span>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <search className="updatedform">
        <div className="container">
          <div className="row text-center">
            <div className="col-xxl-12 col-xl-12 col-md-12 col-12">
              <h2 className="whiteheading">
                {" "}
                What makes us your trusted <br />
                <span className="light">claims handler? </span>
              </h2>
              <p className="sustained">
                When one is involved in a road traffic accident, it becomes difficult to handle it alone. In addition, as your trusted partners, we offer tailored no-fault vehicle accident claims solutions.
              </p>
            </div>
          </div>
          <div className="row">
            <div className="col-xxl-4 col-xl-4 col-lg-6 col-md-12 col-12">
              <div className="Claimant">
                <h5> Instant replacement vehicle service </h5>
                <p className="collision">
                  We will always make sure you do not go without a car after a car collision. Therefore, contact us using <strong> the Insure the Box claims phone number </strong> so that we can give you an exchange vehicle of a similar type until such a time when yours is repaired.
                </p>

                <h5> Professional solicitors </h5>
                <p className="collision">
                  We also have experienced legal solicitors who help victims remain calm after having been in road accidents and manage their{" "}<strong> Insure the Box car insurance claims </strong>tactfully to safeguard their coverage rights.
                </p>

                <h5> No cost </h5>
                <p className="collision">
                  Our accident management company also allows you to add other extras, such as vehicle repair, recovery and storage services to your claims bucket at no extra cost.
                </p>
              </div>
            </div>
            <div className="col-xxl-4 col-xl-4 col-lg-6 col-md-12 col-12">
              <div className="Claimant">
                <h5> Courtesy car at no fee </h5>
                <p className="collision">
                  Call our team on the <strong> Insure the Box claims phone number </strong>for a quick replacement vehicle after a non-fault crash. A new vehicle will be brought to you without making you wait for long.
                </p>
                <h5> Fast car repairs </h5>
                <p className="collision">
                  When your car is damaged, we will ensure that it gets back in shape within a very short time with quick repairs.
                </p>

                <h5> No-win, no-fee </h5>
                <p className="collision">
                  We work on a no-win, no-fee basis. You can make your claim with us without fearing any monetary commitment.
                </p>
              </div>
            </div>
            <div className="col-xxl-4 col-xl-4 col-lg-12 col-md-12 col-12">
              <div className="Claimant">
                <h5> Manufacturer-recognised parts & paints </h5>
                <p className="collision">
                  The list of our technicians includes only approved manufacturers’ service providers that can restore your vehicle using original parts and paints.
                </p>

                <h5>Nationwide coverage  </h5>
                <p className="collision">
                  Operating throughout the UK, our team provides an exhaustive array of accident management solutions exclusively for no-fault drivers.
                </p>

                <h5>No waiting time</h5>
                <p className="collision">
                  With our quick response time, you can always expect urgent help to arrive.
                </p>
              </div>
            </div>
          </div>
        </div>
      </search>

      <section className="Accident-claim">
        <div className="container">
          <div className="row">
            <div className="needed">
              <h2 className="trusted-title">
                How to optimise the value of your Insure the <br />
                <span className="light"> Box accident claim </span>
              </h2>
              <p className="complexities">
                Have you been involved in a road traffic accident and are now wondering what to do for a fair settlement? Do not worry. Here’s how to deal with the consequences of a road traffic accident and make a successful
                {" "} <strong> Insure the Box car accident claim </strong>by following the right procedures.
              </p>
            </div>
            <hr />
          </div>
          <div className="row" id="incdes">
            <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-12">
              <div className="Preparing">
                <ul>
                  <li>
                    <div className="seemingly">
                      <h5 className="Seek">Halt the car and check yourself over:</h5>
                      <p>
                        {" "}
                        Park your vehicle at a safe place and turn off its engine. Determine if any injuries have occurred to yourself or your passengers.{" "}
                      </p>
                    </div>
                  </li>
                  <li>
                    <div className="seemingly">
                      <h5 className="Seek">
                        Inform the police about the accident:{" "}
                      </h5>
                      <p>
                        Report the incident immediately to the police as it may help support your  <strong> Insure the Box insurance accident claim </strong> efficiently.
                      </p>
                    </div>
                  </li>
                  <li>
                    <div className="seemingly">
                      <h5 className="Seek">Visit a medical doctor:</h5>
                      <p>
                        Even when you haven’t been injured, see a physician who will conduct an internal examination on you for any internal damages. A doctor’s prognosis is very important in claiming damages for injuries sustained during accidents.
                      </p>
                    </div>
                  </li>
                  <li>
                    <div className="seemingly">
                      <h5 className="Seek">Take pictures and videos of the accident</h5>
                      <p>
                        The more evidence you have, the stronger your claim.  Consequently, it will be wise for you to take photographs as well as video clips of the accident scene to improve the chances of having a successful claim.
                      </p>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
            <div
              className="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-12"
              id="dualcardiv"
            >
              <img src={dualcar} alt="" className="dualcar" />
            </div>
            <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-12">
              <div className="Preparing">
                <ul>
                  <li>
                    <div className="seemingly">
                      <h5 className="Seek">Get details from others involved in the crash:</h5>
                      <p>
                        Details should be exchanged with those concerned. These include other driver(s), passengers and any possible eyewitnesses.{" "}
                      </p>
                    </div>
                  </li>
                  <li>
                    <div className="seemingly">
                      <h5 className="Seek"> Reject an early settlement offer: </h5>
                      <p>
                        Don’t just jump at an early settlement offer by the other parties’ insurer might push you into accepting their first settlement proposal, which does not cover all that you are entitled to.
                      </p>
                    </div>
                  </li>
                  <li>
                    <div className="seemingly">
                      <h5 className="Seek">
                        Consult no-fault claims specialists:
                      </h5>
                      <p>
                        Please talk to our no-fault claims experts via{" "} <strong> Insure the Box car insurance claims number, </strong> where we can advise you about your rights and what your claim options are.

                      </p>
                    </div>
                  </li>
                  <li>
                    <div className="seemingly">
                      <h5 className="Seek">
                        Start your claim:
                      </h5>
                      <p>
                        Contact us using the{" "}
                        <strong>
                          {" "}
                          Insure the Box claims phone number{" "}
                        </strong>{" "}
                        so that we can start guiding you through an easy process of lodging a complaint. We will step in on your behalf and pursue a quick resolution.
                      </p>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="whyus">
        <div className="container">
          <div className="row">
            <div className="col-xxl-12 col-md-12">
              <div className="why">
                <h2 className="choose">
                  Kickstart your claims process in  {" "}
                  <span className="light">just four steps</span>
                </h2>
                <p className="complexities">
                  A simple, easy and quick process for resolving non-fault car accidents is what our transparent claims process endeavours to provide. To be started right away, follow these simple steps to initiate your <strong> Insure the Box car insurance claims. </strong>
                </p>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-xxl-3 col-xl-6 col-lg-6 col-md-6 col-12">
              <div className="box-service">
                <img src={check} className="check" alt="" />
                {/* <img src={experience} className="experience" alt="" /> */}
                <h4> Contact us </h4>
                <p>
                  Call the claims helpline <a href="tel:0800 772 0850">0800 772 0850</a> to get started on your claim by conversing with our dedicated team.
                </p>
              </div>
            </div>
            <div className="col-xxl-3 col-xl-6 col-lg-6 col-md-6 col-12">
              <div className="box-service">
                <img src={check} className="check" alt="" />
                <h4> Give details </h4>
                <p>
                  The accident information like date, time, location, weather conditions and cause of the collision should be provided.
                </p>
              </div>
            </div>
            <div className="col-xxl-3 col-xl-6 col-lg-6 col-md-6 col-12">
              <div className="box-service">
                <img src={check} className="check" alt="" />
                <h4> Examine a claim </h4>
                <p>
                  Whether or not your claim is valid will be verified by our team before initiating your claim.{" "}
                </p>
              </div>
            </div>
            <div className="col-xxl-3 col-xl-6 col-lg-6 col-md-6 col-12">
              <div className="box-service">
                <img src={check} className="check" alt="" />
                <h4> Get rewarded </h4>
                <p>
                  You will receive the compensation you deserve for your losses and injuries after the settlement of your claim. {" "}
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="faqs">
        <div className="container">
          <div className="row">
            <div className="col-xxl-12 col-xl-12 col-md-12 col-12">
              <div className="fq">
                <h2>
                  {" "}
                  Frequently Asked{" "}
                  <span className="light">Questions (FAQ)</span>
                </h2>
                <p className="complexities">
                  Got questions about the {" "}
                  <strong> Insure the Box accident claim? </strong> We have them answered here.
                </p>
              </div>
              <div className="allfaq">
                <Accordion defaultActiveKey="0">
                  <Accordion.Item eventKey="0">
                    <Accordion.Header>
                      If I’m involved in a no-fault car accident, can I still get a replacement car?
                    </Accordion.Header>
                    <Accordion.Body>
                      Yes. Call us on our <strong> Insure the Box car insurance claims number, </strong>and we’ll lend you a replacement car of a similar kind so that you can continue driving while yours undergoes repair work.
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="1">
                    <Accordion.Header>
                      Do I need to pay my excess in case I claim through your firm?
                    </Accordion.Header>
                    <Accordion.Body>
                      No. This is just one of the ways that you benefit from making a claim via us. We claim directly from the insurer of the at-fault driver. Therefore, your own insurance policy will not be affected, and there is no need for you to pay any excess.
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="2">
                    <Accordion.Header>
                      How long does it take for my Insure the Box car accident claim to be settled?
                    </Accordion.Header>
                    <Accordion.Body>
                      The time limit within which your claim needs to be completed is determined by the complexity of your case. Simple claims can take a few weeks, while complicated ones may be slightly delayed.
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="3">
                    <Accordion.Header>
                      Can I select any accident repairer of my own choice?{" "}
                    </Accordion.Header>
                    <Accordion.Body>
                      Yes, you can choose where to get your vehicle repaired, yet we advise selecting an approved manufacturer’s repair centre that guarantees repairs on a quality basis only. This is up to you to decide where you want your vehicle to be repaired.
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="4">
                    <Accordion.Header>
                      How long do I have to use the replacement vehicle?
                    </Accordion.Header>
                    <Accordion.Body>
                      You can keep it until your car is fixed, or you receive compensation for your <strong> Insure the Box insurance claim. </strong>
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="5">
                    <Accordion.Header>
                      Are vehicle repairs guaranteed?{" "}
                    </Accordion.Header>
                    <Accordion.Body>
                      Select a manufacturer-approved repairer for your car repairs. This will ensure that the repair of your vehicle is done in line with the highest standards using authentic parts.{" "}
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="6">
                    <Accordion.Header>
                      The other party’s insurer has contacted me about resolving the claim. What should I do?{" "}
                    </Accordion.Header>
                    <Accordion.Body>
                      If you make a claim with us, direct their questions to us, and we will deal with their insurance company on your behalf.

                    </Accordion.Body>
                  </Accordion.Item>
                </Accordion>
              </div>
            </div>
          </div>
        </div>
      </section>

      <Footer />
    </>
  );
};

export default InsuretheBox;
