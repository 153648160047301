import React, { useRef } from "react";
import Accordion from "react-bootstrap/Accordion";
import check from "../assets/images/check.png";
import dualcar from "../../src/assets/images/dualcar.webp";
import carbnr1 from "../../src/assets/images/carbnr1.webp";
import Sidebarinner from "../components/Sidebar-inner";
import phonecall from "../assets/images/phonecall.png";
import Footer from "../components/footer";
import { Helmet } from "react-helmet";

const Fluxdirect = () => {
  const sidebarRef = useRef(null);

  const handleScrollToSidebar = () => {
    if (sidebarRef.current) {
      sidebarRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Flux Direct Car Insurance Accident Claim Phone Number:</title>
        <meta
          name="description"
          content=" Need reliable support in making a car insurance claim? Call us on Flux Direct's car insurance claim number 0800 772 0850 for prompt and efficient claim support. "
        />
        <link
          rel="canonical"
          href="https://insurance-accident-claims.co.uk/flux-direct"
        />
      </Helmet>
      <section className="innernav">
        <div className="container">
          <div className="row">
            <div className="col-md-6 col-sm-6">
              <div className="left-logo">
                <h3>
                  Accident<span className="sipo"> Claims</span>
                </h3>
              </div>
            </div>
            <div className="col-md-6 col-sm-6">
              <div className="left-cta">
                <div class="headercnt">
                  <div class="chatmsg">
                    <img
                      src={phonecall}
                      className="phonecall"
                      alt="phonecall "
                    />
                  </div>
                  <div class="box-body">
                    <h3 class="nav-info-box-title">Toll Free Number </h3>
                    <p>
                      <a href="tel:0800 772 0850">0800 772 0850</a>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section
        className="newbnr"
        style={{ backgroundImage: "url(" + carbnr1 + ")" }}
      >
        <div className="container">
          <div className="row">
            <div className="col-xxl-8 col-xl-8 col-md-10 col-12">
              <div className="innerbnr">
                <h1>
                  <span className="onespan">Car accident </span>{" "}
                  <span className="sci">  insurance claims </span> experts in
                  the UK
                </h1>
                <p className="brokerage">
                  From assessing the damage to arranging for repairs/replacement
                  vehicles and managing your Flux Direct insurance claim, we
                  will ensure you do not have any stress after your no-fault car
                  accident.
                </p>
                <div className="bnrbtn">
                  <a
                    class="db-btn-border btn-rollover phone"
                    href="tel:0800 772 0850"
                  >
                    Road Accident Claim Help?{" "}
                    <i class="fa-solid fa-arrow-right"></i>
                  </a>
                </div>
                <div className="bnrbtn">
                  <a
                    className="db-btn-border btn-rollover desktop"
                    onClick={handleScrollToSidebar}
                  >
                    Road Accident Claim Help?{" "}
                    <i className="fa-solid fa-arrow-right"></i>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="stickysection">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="group-4-8">
                <div className="cl8 tf-tab">
                  <div className="content-tab">
                    <div className="inner-content">
                      <h2>
                        UK’s Most committed accident
                        <br />
                        <span className="light">
                          management professionals{" "}
                        </span>{" "}
                      </h2>
                      <p className="disciplines">
                        We are a group of reliable and respected experts who
                        help individuals involved in accidents that were not
                        their fault recover from the aftermath and get the best
                        compensation for their injuries and losses. Call our
                        team at{" "}
                        <strong>
                          {" "}
                          Flux Direct insurance claims phone number{" "}
                        </strong>{" "}
                        today to speak to an expert about making a{" "}
                        <strong> Flux Direct insurance claims </strong> for
                        compensation against the responsible party.
                      </p>
                      <hr />
                      <p className="bold-text">Zero-commitment</p>
                      <p className="bottom-te">
                        We will start a{" "}
                        <strong> Flux Direct car insurance claim </strong>
                        directly with the other person’s insurer, which means
                        you don’t have to be concerned with meeting any excess
                        costs pertaining to it.{" "}
                      </p>
                      <hr />
                      <p className="bold-text">Expert negotiations</p>
                      <p className="bottom-te">
                        Our solicitors will skillfully negotiate on behalf of
                        your <strong> Flux Direct car insurance claim </strong>
                        to reach a better settlement value. Call now on{" "}
                        <strong>
                          {" "}
                          Flux Direct insurance claims phone number{" "}
                        </strong>{" "}
                        to make a claim.
                      </p>
                      <hr />
                      <p className="bold-text">Customised guidance</p>
                      <p className="bottom-te">
                        All claims are unique. For this reason, our solicitors
                        will evaluate your case and give you personalised advice
                        depending on the individual circumstances surrounding
                        it. Dial the{" "}
                        <strong> Flux Direct insurance claim number </strong>to
                        have a no-obligation chat with any of our solicitors.{" "}
                      </p>
                      <hr />
                      <p className="bold-text">Transparent process</p>
                      <p className="bottom-te">
                        Our <strong> Flux Directcar insurance claim </strong>
                        procedures have been remodelled and simplified so that
                        we don’t experience any unnecessary delays and
                        complications. Consequently, there will be no heavy
                        paperwork or prolonged waiting period when you call us
                        using the{" "}
                        <strong>
                          {" "}
                          claims contact number{" "}
                          <a href="tel:0800 772 0850">0800 772 0850</a>.
                        </strong>{" "}
                      </p>
                      <hr />
                      <p className="bold-text">Open approach</p>
                      <p className="bottom-te">
                        At all stages of their{" "}
                        <strong> Flux Direct insurance claims </strong>process,
                        customers are kept up-to-date.
                      </p>
                      <hr />
                      <p className="bold-text">Realistic repairs</p>
                      <p className="bottom-te">
                        The key repairs will be carried out by our approved
                        repair centre using genuine parts and paint. So, your
                        vehicle is guaranteed to be repaired to the highest
                        level of quality standards.
                      </p>
                      <hr />
                      <p className="bold-text">
                        Your no-claims bonus is not in danger
                      </p>
                      <p className="bottom-te">
                        Since the{" "}
                        <strong>Flux Direct car insurance claim </strong>will be
                        made against the other driver directly who in turn will
                        pay all expenses. Hence, your no-claims bonus won’t be
                        unaffected.
                      </p>
                      <hr />
                      <p className="bold-text">Fast claim processing</p>
                      <p className="bottom-te">
                        We will not make the claim process difficult, unlike
                        other insurance firms. We can settle your{" "}
                        <strong>Flux Direct insurance claim </strong>
                        in as little time as possible with our simple direct
                        approach.{" "}
                      </p>
                      <hr />
                      <p className="bold-text">
                        Comparable replacement vehicle{" "}
                      </p>
                      <p className="bottom-te">
                        You will receive a replacement car immediately after an
                        accident that was not your mistake. Call us through our{" "}
                        <strong>Flux Direct accident claims number </strong>and
                        get a like-for-like replacement vehicle at no extra cost
                        to you.{" "}
                      </p>
                      <hr />

                      <p className="bold-text">
                        Experienced claim representative
                      </p>
                      <p className="bottom-te">
                        If you call us using the{" "}
                        <strong>
                          {" "}
                          Flux Direct insurance claims phone number{" "}
                        </strong>{" "}
                        , we will connect you with a dedicated claims handler
                        who is going to act as the focal point of contact for
                        your needs and take care of everything including
                        resolving your{" "}
                        <strong> Flux Direct insurance claim </strong>and
                        handling communications for this purpose efficiently.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="cl4" ref={sidebarRef}>
                  <Sidebarinner />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="photographs">
        <div className="container">
          <div className="row">
            <h2 className="road">
              We are here to help ease your{" "}
              <span className="light">
                concerns <br />
                after an accident
              </span>{" "}
            </h2>
            <div className="col-xxl-6 col-xl-6 col-md-12 col-12">
              <div className="fourbox">
                <ul>
                  <li>
                    <img src={check} className="check" alt="" />
                    We are there for you when you need someone most after a
                    terrible car crash.
                  </li>
                  <hr />
                  <li>
                    <img src={check} className="check" alt="" />
                    We save you from trouble by taking care of every single
                    thing from recovering your vehicle right up to settling your{" "}
                    <strong> Flux Direct car insurance claim. </strong>
                  </li>
                  <hr />
                  <li>
                    <img src={check} className="check" alt="" />
                    Feel free to contact us on{" "}
                    <strong>
                      {" "}
                      the Flux Direct insurance claim number{" "}
                    </strong>{" "}
                    and we will go the extra mile so as to arrange a similar
                    replacement vehicle easily.
                  </li>
                  <hr />
                  <li>
                    <img src={check} className="check" alt="" />
                    We will give you contact to experienced and professional
                    solicitors in the UK who can help you handle the{" "}
                    <strong> Flux Direct car insurance claim </strong>until its
                    conclusion.
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-xxl-6 col-xl-6 col-md-12 col-12">
              <div className="fourbox">
                <ul>
                  <li>
                    <img src={check} className="check" alt="" />
                    Through our manufacturer-approved garages, we supply vehicle
                    repair services with timely follow-ups to provide an update
                    on how it’s going.
                  </li>
                  <hr />
                  <li>
                    <img src={check} className="check" alt="" />
                    We will help save you time as our team communicates with all
                    parties involved in the accident, relieving your stress.
                  </li>
                  <hr />
                  <li>
                    <img src={check} className="check" alt="" />
                    When repairs are completed on your vehicle, we will arrange
                    for its collection.
                  </li>
                  <hr />
                  <li>
                    <img src={check} className="check" alt="" />
                    On a no-win no-fee basis, we will diligently manage your{" "}
                    <strong> Flux Direct insurance claim </strong> and lead you
                    to a successful outcome
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="carprocess">
        <div className="container ">
          <div className="row">
            <div className="col-xxl-12 col-xxl-12 col-md-12 col-12">
              <h2 className="trusted-title">
                {" "}
                Different types of accident management
                <br />
                <span className="light"> services we offer </span>{" "}
              </h2>
            </div>
          </div>
          <div className="row" id="possible">
            <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-12">
              <div className="claim">
                <ul>
                  <li>
                    <span className="sleej">Vehicle recovery:</span>We shall
                    retrieve your vehicle from where it was involved in an
                    accident without any unnecessary delays or disruptions. Just
                    call the{" "}
                    <strong> Flux Direct insurance phone number </strong> once
                    for immediate roadside assistance that will be there at the
                    scene when you need it the most.
                  </li>
                  <li>
                    <span className="sleej">Car Repairs:</span>We will make
                    arrangements with approved repairers if they are available
                    near you to do quality repairs to your car at an approved
                    garage of your choice. For excellent repairs on any damaged
                    car call us using{" "}
                    <a href="tel:0800 772 0850">0800 772 0850</a>.
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-12">
              <div className="claim" id="blue">
                <ul>
                  <li>
                    <span className="sleej">Secured storage:</span> If you
                    cannot drive your vehicle, do not leave it next to the road.
                    If there is an accident, please call our company on{" "}
                    <strong> Flux Direct's claims contact number </strong> and
                    we will put your car into safe hands for further damage and
                    rusting.facilities.
                  </li>
                  <li>
                    <span className="sleej">Vehicle replacement:</span>Were you
                    involved in a motor vehicle crash that was not your fault?
                    Ring us up on the{" "}
                    <strong> Flux Direct insurance claim number </strong> , and
                    we will offer you premium support, including placing you in
                    a replacement with the same specification while allowing you
                    to get back behind the wheel and resume normal activities
                    promptly.
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-12">
              <div className="claim">
                <ul>
                  <li>
                    <span className="sleej">Personal injuries support:</span>
                    Contact our team today so that they can start your
                    <strong> Flux Direct insurance claim </strong>. now. We have
                    a group of experienced solicitors who will take care of your
                    compensation claim effectively depending on how serious your
                    injuries are.
                  </li>
                  <li>
                    <span className="sleej">
                      Accident claims management services:
                    </span>
                    We offer tailor-made accident claims management services
                    that handle everything starting from first reports to
                    insurers through arranging repairs.
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="yellowcta">
        <div className="container">
          <div className="row">
            <div className="cta2">
              <div className="col-xxl-8 col-xl-8 col-lg-8 col-12">
                <div className="legal">
                  <h3 className="experienced">
                    Got more questions?{" "}
                    <span className="light">Talk to our specialists</span>{" "}
                  </h3>
                  <p className="professionals">
                    Get in touch with our no-fault claim experts via{" "}
                    <strong>
                      car insurance claims number{" "}
                      <a className="cpt-no" href="tel:0800 772 0850">
                        {" "}
                        0800 772 0850{" "}
                      </a>{" "}
                    </strong>
                    to get the best advice for your{" "}
                    <strong> Flux Direct car insurance claim. </strong>
                  </p>
                </div>
              </div>
              <div className="col-xxl-4 col-xl-4 col-lg-4 col-12">
                <div class="process">
                  <a class="tf-button style-1 phone" href="tel:0800 772 0850">
                    Get in touch<span class="icon-keyboard_arrow_right"></span>
                  </a>
                </div>
                <div class="process">
                  <a
                    className="tf-button style-1 desktop"
                    onClick={handleScrollToSidebar}
                  >
                    Get in touch
                    <span className="icon-keyboard_arrow_right"></span>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <search className="updatedform">
        <div className="container">
          <div className="row text-center">
            <div className="col-xxl-12 col-xl-12 col-md-12 col-12">
              <h2 className="whiteheading">
                {" "}
                Why should you trust us with your accident <br />
                <span className="light">management requirements?</span>
              </h2>
              <p className="sustained">
                We acknowledge the burden a non-fault car accident brings.
                Nevertheless, we are here to give all-around help and guidance
                after an accident where one is not at fault. We will take care
                of your <strong> Flux Direct car insurance claim </strong> at
                zero cost to you, acting as your dedicated partner and
                tirelessly fighting to protect your rights.
              </p>
            </div>
          </div>
          <div className="row">
            <div className="col-xxl-4 col-xl-4 col-lg-6 col-md-12 col-12">
              <div className="Claimant">
                <h5>No win, No fee</h5>
                <p className="collision">
                  The solicitors we work with operate on a no-win no-fee basis.
                  In case you don’t get what you want from{" "}
                  <strong> Flux Direct insurance claims </strong> then there
                  won’t be any financial risk.
                </p>
                <h5>No obligation advice</h5>
                <p className="collision">
                  With us, claiming has no legal binding. Any free initial
                  consultation or general guidance regarding your claim can be
                  obtained by contacting us through the{" "}
                  <strong> Flux Direct insurance claims number.</strong>
                </p>

                <h5>No increase in insurance premium</h5>
                <p className="collision">
                  We don't make a claim against our own policy thereby leaving
                  your premiums undisturbed.
                </p>
              </div>
            </div>
            <div className="col-xxl-4 col-xl-4 col-lg-6 col-md-12 col-12">
              <div className="Claimant">
                <h5>Comprehensive services</h5>
                <p className="collision">
                  Our services span the gamut from helping clients recover their
                  vehicles to handling claims from start to end.
                </p>
                <h5>Immediate replacement vehicle </h5>
                <p className="collision">
                  Call us at the accident claims helpline on{" "}
                  <a href="tel:0800 772 0850">0800 772 0850</a> and you will get
                  a free temporary replacement vehicle to help you maintain your
                  daily activities with minimum interruption.
                </p>

                <h5>Safe storage</h5>
                <p className="collision">
                  We have our safe storage yards where we can keep your damaged
                  car free of cost until your claim is assessed.{" "}
                </p>
              </div>
            </div>
            <div className="col-xxl-4 col-xl-4 col-lg-12 col-md-12 col-12">
              <div className="Claimant">
                <h5>Expert negotiation</h5>
                <p className="collision">
                  Call{" "}
                  <strong> Flux Direct insurance claims phone number </strong>{" "}
                  and get advice from the experts who will negotiate with the
                  other party’s insurer on your behalf.
                </p>

                <h5>Quality repair Services </h5>
                <p className="collision">
                  We shall arrange top-quality repair services that are beyond
                  par in our wide network of accredited UK repairers.
                </p>

                <h5>Bespoke guidance</h5>
                <p className="collision">
                  The <strong> Flux Direct car insurance claim </strong> is
                  unique for each one. Therefore, we will offer personalised
                  advice according to the particular facts of your case.
                </p>
              </div>
            </div>
          </div>
        </div>
      </search>

      <section className="Accident-claim">
        <div className="container">
          <div className="row">
            <div className="needed">
              <h2 className="trusted-title">
                What should you do to maximise settlement out <br />
                <span className="light">
                  of a Flux Direct insurance claim?{" "}
                </span>
              </h2>
              <p className="complexities">
                Car accidents occur very swiftly and can completely change an
                individual’s life thus making it necessary to file a claim in
                order to receive rightful compensation for losses incurred as
                well as injuries sustained. Below are actionable steps that you
                can take towards increasing your{" "}
                <strong> Flux Direct insurance claim </strong> <br />{" "}
                compensation.{" "}
              </p>
            </div>
            <hr />
          </div>
          <div className="row" id="incdes">
            <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-12">
              <div className="Preparing">
                <ul>
                  <li>
                    <div className="seemingly">
                      <h5 className="Seek">Report the accident:</h5>
                      <p>
                        {" "}
                        If you're involved in a no-fault accident, call the
                        police right away and report it. A police report acts as
                        evidence of Flux Direct's car insurance claim.{" "}
                      </p>
                    </div>
                  </li>
                  <li>
                    <div className="seemingly">
                      <h5 className="Seek">Go for medical attention: </h5>
                      <p>
                        Seek medical attention from an authorised medical
                        practitioner even if your injuries are not that severe.
                        The medical report may be crucial to support your claim.
                      </p>
                    </div>
                  </li>
                  <li>
                    <div className="seemingly">
                      <h5 className="Seek">Gather the evidence:</h5>
                      <p>
                        The strength of your case will depend on the kind of
                        evidence you gather from the scene. Therefore, collect
                        as much evidence as possible for a stronger case.
                      </p>
                    </div>
                  </li>
                  <li>
                    <div className="seemingly">
                      <h5 className="Seek">Keep everything on the records</h5>
                      <p>
                        You should maintain a comprehensive record of all the
                        expenses incurred due to accidents, including medical
                        bills, therapy costs, transportation costs and lost
                        income.
                      </p>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
            <div
              className="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-12"
              id="dualcardiv"
            >
              <img src={dualcar} alt="" className="dualcar" />
            </div>
            <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-12">
              <div className="Preparing">
                <ul>
                  <li>
                    <div className="seemingly">
                      <h5 className="Seek">Avoid social media:</h5>
                      <p>
                        Keep away from social media until after you have settled
                        your claim. This way, you will avoid losing money
                        because solicitors from the other side may rely on them
                        to reduce their client’s compensation.{" "}
                      </p>
                    </div>
                  </li>
                  <li>
                    <div className="seemingly">
                      <h5 className="Seek">
                        {" "}
                        Do not accept the first offer made
                      </h5>
                      <p>
                        The insurance companies may offer a quick settlement
                        which is far below what you deserve. Therefore, talk to
                        your solicitor before agreeing to any such offers.
                      </p>
                    </div>
                  </li>
                  <li>
                    <div className="seemingly">
                      <h5 className="Seek">
                        Contact a no-fault claim expert :
                      </h5>
                      <p>
                        Never endure the torturous ordeal of a car accident’s
                        aftermath by yourself if you have been injured and
                        suffered damages because of it. Call us on the{" "}
                        <strong> Flux Direct insurance claims number </strong>{" "}
                        for advice from our experienced solicitors that will
                        increase your chances of being awarded compensatory
                        damages.{" "}
                      </p>
                    </div>
                  </li>
                  <li>
                    <div className="seemingly">
                      <h5 className="Seek">Make a claim </h5>
                      <p>
                        Don’t wait too long before making a{" "}
                        <strong> Flux Direct insurance claim. </strong> Call our
                        team via the{" "}
                        <strong>
                          {" "}
                          Flux Direct insurance claims phone number{" "}
                        </strong>{" "}
                        and we will start your claim immediately.
                      </p>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="whyus">
        <div className="container">
          <div className="row">
            <div className="col-xxl-12 col-md-12">
              <div className="why">
                <h2 className="choose">
                  Register Your Claim In{" "}
                  <span className="light">Just 4 Easy Steps</span>
                </h2>
                <p className="complexities">
                  You can easily register your claim under our simplified and
                  streamlined system without having to wait or fill out any
                  paperwork. Start with us today by calling the{" "}
                  <strong>
                    {" "}
                    Flux Direct car insurance claims phone number.{" "}
                  </strong>
                </p>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-xxl-3 col-xl-6 col-lg-6 col-md-6 col-12">
              <div className="box-service">
                <img src={check} className="check" alt="" />
                {/* <img src={experience} className="experience" alt="" /> */}
                <h4>Contact Us</h4>
                <p>
                  Book an appointment for an initial consultation with our
                  solicitors through{" "}
                  <strong> Flux Direct insurance claims contact number.</strong>
                </p>
              </div>
            </div>
            <div className="col-xxl-3 col-xl-6 col-lg-6 col-md-6 col-12">
              <div className="box-service">
                <img src={check} className="check" alt="" />
                <h4>Give Details Of The Accident</h4>
                <p>
                  Let us know all about the accident, including the date, time,
                  exact place and what actually happened leading to the crash.
                </p>
              </div>
            </div>
            <div className="col-xxl-3 col-xl-6 col-lg-6 col-md-6 col-12">
              <div className="box-service">
                <img src={check} className="check" alt="" />
                <h4>Provide evidence</h4>
                <p>
                  Share evidence you’ve collected from the accident scene or any
                  other vital information you’ve collected from the accident
                  scene.{" "}
                </p>
              </div>
            </div>
            <div className="col-xxl-3 col-xl-6 col-lg-6 col-md-6 col-12">
              <div className="box-service">
                <img src={check} className="check" alt="" />
                <h4>Submission of Claim</h4>
                <p>
                  We will submit your{" "}
                  <strong> Flux Direct car insurance claim </strong> after we
                  gather the necessary evidence and documents for the claim and
                  keep you informed of its progress.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="faqs">
        <div className="container">
          <div className="row">
            <div className="col-xxl-12 col-xl-12 col-md-12 col-12">
              <div className="fq">
                <h2>
                  {" "}
                  Frequently Asked{" "}
                  <span className="light">Questions (FAQ)</span>
                </h2>
                <p className="complexities">
                  Got questions about the{" "}
                  <strong> Flux Direct car insurance claim? </strong>We have
                  them answered here.
                </p>
              </div>
              <div className="allfaq">
                <Accordion defaultActiveKey="0">
                  <Accordion.Item eventKey="0">
                    <Accordion.Header>
                      Can I have a replacement car while mine is being repaired?
                    </Accordion.Header>
                    <Accordion.Body>
                      Yes. We can give you a vehicle with an identical size,
                      make, and model to use as a spare in order that your day
                      goes on uninterrupted.
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="1">
                    <Accordion.Header>
                      How soon can I get a replacement car from you?
                    </Accordion.Header>
                    <Accordion.Body>
                      We appreciate how stressed you will be when your car gets
                      damaged. So we will replace it within 24 hours to enable
                      you to continue doing your daily tasks.
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="2">
                    <Accordion.Header>
                      Should I pay an excess if someone else crashes into me?
                    </Accordion.Header>
                    <Accordion.Body>
                      You do not need to pay any excess fees when filing for{" "}
                      <strong> a Flux Direct car insurance claim </strong>
                      through us. All these expenses will be covered by the
                      insurer of one who is at fault.
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="3">
                    <Accordion.Header>
                      How long does it take to fix my car that has been crashed?{" "}
                    </Accordion.Header>
                    <Accordion.Body>
                      The duration of repairs depends on the extent of damage to
                      your vehicle. Minor dents or scratches can be repaired in
                      a day or two, whereas severe damages can take a few weeks.
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="4">
                    <Accordion.Header>
                      Can I use my own garage for motor vehicle repairs?
                    </Accordion.Header>
                    <Accordion.Body>
                      Sure! Let us know which garage you wish us to avail of so
                      as to get in touch with them for your car repairs.
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="5">
                    <Accordion.Header>
                      What are some of the costs I can recover from the at-fault
                      party?{" "}
                    </Accordion.Header>
                    <Accordion.Body>
                      You will claim all substantiated costs incurred due to the
                      vehicle motor accident, including hospital bills, legal
                      fees, transport expenses and lost wages.
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="6">
                    <Accordion.Header>
                      How long before my Flux Direct insurance claim is settled?{" "}
                    </Accordion.Header>
                    <Accordion.Body>
                      The duration of your claim depends on various factors,
                      including the complexity of your case, how extensive
                      documentation is and how well different parties involved
                      cooperate. We will do our best to settle this matter
                      quickly when you give us a call at{" "}
                      <strong> Flux Direct claims number </strong> and we will
                      reach an agreement within no time.
                    </Accordion.Body>
                  </Accordion.Item>
                </Accordion>
              </div>
            </div>
          </div>
        </div>
      </section>

      <Footer />
    </>
  );
};

export default Fluxdirect;
