import React, { useRef } from "react";
import insurancelogo from "../../src/assets/images/insurancelogo.png";
import Accordion from "react-bootstrap/Accordion";
import check from "../assets/images/check.png";
import dualcar from "../../src/assets/images/dualcar.webp";
import hasting from "../../src/assets/images/hasting.webp";
import Sidebarinner from "../components/Sidebar-inner";
import phonecall from "../assets/images/phonecall.png";
import { Helmet } from "react-helmet";
import Footer from "../components/footer";

const HastingsDirect = () => {
  const sidebarRef = useRef(null);

  const handleScrollToSidebar = () => {
    if (sidebarRef.current) {
      sidebarRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Hastings Direct Claims Number | Hastings Direct Car Insurance Claim</title>
        <meta
          name="description"
          content="Contact us on the Hastings Direct Insurance Accident Claims Contact Number 0800 772 0850 for prompt claims support and expert guidance. Get in touch with us now. "
        />
        <link
          rel="canonical"
          href=">https://insurance-accident-claims.co.uk/hastings-direct "
        />
      </Helmet>
      <section className="innernav">
        <div className="container">
          <div className="row">
            <div className="col-md-6 col-sm-6">
              <div className="left-logo">
                {/* <img src={insurancelogo} className='insurancelogo' alt='Insurance Logo' /> */}
                <h3>Accident Claims</h3>
              </div>
            </div>
            <div className="col-md-6 col-sm-6">
              <div className="left-cta">
                <div class="headercnt">
                  <div class="chatmsg">
                    <img
                      src={phonecall}
                      className="phonecall"
                      alt="phonecall "
                    />
                  </div>
                  <div class="box-body">
                    <h3 class="nav-info-box-title">Toll Free Number </h3>
                    <p>
                      <a href="tel:0800 772 0850">0800 772 0850</a>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section
        className="newbnr"
        style={{ backgroundImage: "url(" + hasting + ")" }}
      >
        <div className="container">
          <div className="row">
            <div className="col-xxl-7 col-xl-7 col-md-10 col-12">
              <div className="innerbnr">
                <h1>
                  <span className="onespan">Experts In No-Fault </span>{" "}
                  <span className="sci">Claims</span> Management{" "}
                </h1>
                <p className="brokerage">
                  From immediate vehicle recovery to handling your no-fault
                  claim, we offer a wide array of accident management solutions.
                </p>
                <div className="bnrbtn">
                  <a class="db-btn-border btn-rollover phone" href="tel:0800 772 0850">
                    Begin your claim{" "}
                    <i class="fa-solid fa-arrow-right"></i>
                  </a>
                </div>
                <div className="bnrbtn">
                <a className="db-btn-border btn-rollover desktop"  onClick={handleScrollToSidebar}>
                  Begin your claim <i className="fa-solid fa-arrow-right"></i>
                </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="stickysection">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="group-4-8">
                <div className="cl8 tf-tab">
                  <div className="content-tab">
                    <div className="inner-content">
                      <h2>
                        Hastings Direct insurance support <br />
                        <span className="light">
                          services without any excess
                        </span>{" "}
                      </h2>
                      <p className="disciplines">
                        We understand that dealing with the consequences of a
                        no-fault car accident can be stressful and traumatic. We
                        strive to relieve you from this stress and anxiety with
                        our no-cost{" "}
                        <strong>
                          Hastings Direct insurance support services
                        </strong>{" "}
                        that provide you with peace of mind after a no-fault
                        accident.
                      </p>
                      <hr />
                      <p className="bold-text">No additional costs </p>
                      <p className="bottom-te">
                        We offer a wide array of{" "}
                        <strong> insurance accident support services</strong> at
                        no expense to you. When you contact us via{" "}
                        <strong>
                          accident claims number{" "}
                          <a href="tel:0800 772 0850">0800 772 0850</a>
                        </strong>{" "}
                        for emergency support after a no-fault accident, you
                        don’t have to shell out thousands of pounds for
                        emergency assistance following an accident.
                      </p>
                      <hr />
                      <p className="bold-text">NCB unaffected </p>
                      <p className="bottom-te">
                        While offering{" "}
                        <strong>
                          Hastings Direct insurance accident support
                        </strong>{" "}
                        we go over and beyond to ensure that your no-claims
                        bonus remains intact. You can rest assured that your
                        hard-earned discounts will stay protected when you
                        choose us for accident management solutions.{" "}
                      </p>
                      <hr />
                      <p className="bold-text">
                        High-quality accidental repairs{" "}
                      </p>
                      <p className="bottom-te">
                        We have a network of trusted repairers across the UK who
                        provide top-notch repairs using genuine parts. When you
                        get in touch with us via{" "}
                        <strong>Hastings Direct accident number</strong>, we
                        will coordinate the repairs of your vehicle from our
                        trusted network or your preferred garage to restore your
                        vehicle to pre-accident condition.
                      </p>
                      <hr />
                      <p className="bold-text">Round-the-clock assistance</p>
                      <p className="bottom-te">
                        Our dedicated team is available 24/7 to provide you with
                        immediate assistance and support to get back on the road
                        after a no-fault accident.
                      </p>
                      <hr />
                      <p className="bold-text">Comparable replacement </p>
                      <p className="bottom-te">
                        To help you carry on with your daily routine without
                        disruption, we will supply you with a like-for-like
                        replacement vehicle without additional costs. Contact us
                        via{" "}
                        <strong>
                          {" "}
                          contact number{" "}
                          <a href="tel:0800 772 0850">0800 772 0850</a>
                        </strong>{" "}
                        to get a like-for-like replacement for free and at no
                        cost to you.
                      </p>
                      <hr />
                      <p className="bold-text">Transparent communication </p>
                      <p className="bottom-te">
                        We maintain utmost transparency and honesty by keeping
                        our clients informed about the updates and progress of
                        their{" "}
                        <strong>
                          Hastings Direct insurance accident claims.
                        </strong>
                      </p>
                      <hr />
                      <p className="bold-text">Post-repair assistance</p>
                      <p className="bottom-te">
                        When your vehicle repairs are complete, we will conduct
                        a post-repair inspection to assess the quality of
                        repairs and ensure that your vehicle is repaired using
                        genuine parts.
                      </p>
                      <hr />
                      <p className="bold-text">Expert negotiation</p>
                      <p className="bottom-te">
                        Our dedicated team will negotiate with the insurance
                        company on your behalf to help you secure deserving
                        compensation for your damages and losses suffered as a
                        result of the no-fault car accident.
                      </p>
                      <hr />
                      <p className="bold-text">Maximised compensation</p>
                      <p className="bottom-te">
                        Our solicitors have vast experience in dealing with
                        no-fault{" "}
                        <strong>
                          Hastings Direct insurance accident claims
                        </strong>
                        . So, when you reach out to us via{" "}
                        <strong>
                          the Hastings Direct car insurance claim number
                        </strong>
                        , we will work diligently to help you get the best
                        outcome for your claim.
                      </p>
                      <hr />

                      <p className="bold-text">Swift claim management</p>
                      <p className="bottom-te">
                        Our experienced team and streamlined claims process
                        ensure quick and hassle-free processing of your claim.
                        We proactively track every step of the claim process to
                        ensure timely resolution.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="cl4" ref={sidebarRef}>
                  <Sidebarinner />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="photographs">
        <div className="container">
          <div className="row">
            <h2 className="road">
              We ease your stress and build a smooth path <br />
              <span className="light">towards your claim resolution </span>{" "}
            </h2>
            <div className="col-xxl-6 col-xl-6 col-md-12 col-12">
              <div className="fourbox">
                <ul>
                  <li>
                    <img src={check} className="check" alt="" />
                    When you contact us via{" "}
                    <strong>
                      the accident number{" "}
                      <a href="tel:0800 772 0850">0800 772 0850</a>
                    </strong>
                    , we will record the details of your accident and provide
                    immediate support to help you get back on track.
                  </li>
                  <hr />
                  <li>
                    <img src={check} className="check" alt="" />
                    Our solicitors will assess your case and provide you with
                    the most professional guidance to handle your claim
                    smoothly.
                  </li>
                  <hr />
                  <li>
                    <img src={check} className="check" alt="" />
                    If your vehicle is unroadworthy and undrivable, we will
                    arrange a suitable replacement vehicle for you.
                  </li>
                  <hr />
                  <li>
                    <img src={check} className="check" alt="" />
                    We will also connect you with reliable and trustworthy
                    medical professionals to support your physical and mental
                    recovery.
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-xxl-6 col-xl-6 col-md-12 col-12">
              <div className="fourbox">
                <ul>
                  <li>
                    <img src={check} className="check" alt="" />
                    From initial documentation to final claims settlement, we
                    will handle each and every aspect of{" "}
                    <strong>Hastings Direct insurance accident claims</strong>.
                  </li>
                  <hr />
                  <li>
                    <img src={check} className="check" alt="" />
                    Our reliable and professional team will evaluate the
                    condition of your vehicle and coordinate vehicle repairs
                    with trusted and approved repairers.
                  </li>
                  <hr />
                  <li>
                    <img src={check} className="check" alt="" />
                    We will communicate with all the parties involved in the
                    accident, including other drivers, witnesses and insurance
                    companies to relieve you from the stress.
                  </li>
                  <hr />
                  <li>
                    <img src={check} className="check" alt="" />
                    Even when your claim is settled, we will provide you with
                    support and guidance to address any issues that may arise.
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="carprocess">
        <div className="container ">
          <div className="row">
            <div className="col-xxl-12 col-xxl-12 col-md-12 col-12">
              <h2 className="trusted-title">
                {" "}
                Discover our wide array of Hastings Direct <br />
                <span className="light">insurance support services </span>{" "}
              </h2>
            </div>
          </div>
          <div className="row" id="possible">
            <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-12">
              <div className="claim">
                <ul>
                  <li>
                    <span className="sleej">Vehicle recovery </span> <br />
                    From minor collisions to major accidents, we can provide
                    immediate vehicle recovery services. You can give us a call
                    at{" "}
                    <strong>
                      {" "}
                      accident number{" "}
                      <a href="tel:0800 772 0850">0800 772 0850</a>
                    </strong>
                    , and our recovery agents will instantly arrive at the
                    accident scene to recover your vehicle.
                  </li>
                  <li>
                    <span className="sleej">Vehicle repairs </span>
                    <br />
                    We offer a comprehensive repair solution to restore your
                    vehicle to pre-accident condition. When you contact us via{" "}
                    <strong>Hastings Direct claims contact number</strong>, we
                    will examine your vehicle and transport it to the best
                    garage for high-quality repairs.
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-12">
              <div className="claim" id="blue">
                <ul>
                  <li>
                    <span className="sleej">Vehicle storage</span> <br />
                    Our vehicle storage services provide a safe place to store
                    your vehicle safely following a no-fault accident. We have a
                    well-maintained and highly secured storage facility to keep
                    your unroadworthy vehicle until you don’t receive
                    compensation for repairs or until a thorough assessment of
                    your vehicle is conducted.
                  </li>
                  <li>
                    <span className="sleej">Replacement vehicle </span> <br />
                    If your vehicle is damaged to a great extent, we will
                    provide you with a like-for-like replacement vehicle from
                    our extensive range of fleets to get you back on the road
                    with minimum hassle. You can get in touch with us at{" "}
                    <strong>
                      {" "}
                      car insurance claim number{" "}
                      <a href="tel:0800 772 0850">0800 772 0850</a>
                    </strong>{" "}
                    to get a replacement vehicle similar to your own.
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-12">
              <div className="claim">
                <ul>
                  <li>
                    <span className="sleej">Injury claim </span>
                    <br />
                    Car accidents can lead to severe injuries and financial
                    loss. If you’ve been involved in a no-fault car accident,
                    you can contact us on the{" "}
                    <strong>Hastings Direct car accident helpline</strong> to
                    begin your claims process right away and get compensation
                    for your injuries.
                  </li>
                  <li>
                    <span className="sleej">Claims management </span> <br />
                    With extensive experience specialising in no-fault car
                    accidents, we have the expertise to handle your claim
                    efficiently and professionally. From documentation
                    assistance to claims settlement, we will manage your entire
                    claim.
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="yellowcta">
        <div className="container">
          <div className="row">
            <div className="cta2">
              <div className="col-xxl-8 col-xl-8 col-lg-8 col-12">
                <div className="legal">
                  <h3 className="experienced">
                    Got more questions?
                    <span className="light">
                      {" "}
                      Contact our no-fault claim specialists{" "}
                    </span>{" "}
                  </h3>
                  <p className="professionals">
                    Contact us today to discuss your accident management needs
                    and get the best guidance for your{" "}
                    <strong>Hastings Direct insurance accident claim. </strong>
                  </p>
                </div>
              </div>
              <div className="col-xxl-4 col-xl-4 col-lg-4 col-12">
              <div class="process">
                  <a class="tf-button style-1 phone" href="tel:0800 772 0850">
                    Get in touch<span class="icon-keyboard_arrow_right"></span>
                  </a>
                </div>
                <div class="process">
                    <a className="tf-button style-1 desktop"  onClick={handleScrollToSidebar}>
                        Get in touch<span className="icon-keyboard_arrow_right"></span>
                    </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <search className="updatedform">
        <div className="container">
          <div className="row text-center">
            <div className="col-xxl-12 col-xl-12 col-md-12 col-12">
              <h2 className="whiteheading">
                Why you should entrust us with your <br />{" "}
                <span className="light">accident management needs </span>
              </h2>
              <p className="sustained">
                We are your one-stop destination for cost-free claim management
                solutions following a no-fault accident. So, before contacting
                your insurer, call us at the{" "}
                <strong>
                  {" "}
                  Insurance accident claims helpline{" "}
                  <a href="tel:0800 772 0850">0800 772 0850</a>
                </strong>{" "}
                to get <strong> insurance support services</strong> without
                paying thousands of pounds.{" "}
              </p>
            </div>
          </div>
          <div className="row">
            <div className="col-xxl-4 col-xl-4 col-lg-6 col-md-12 col-12">
              <div className="Claimant">
                <h5>Best claim management </h5>
                <p className="collision">
                  We help you get back to normal with our best claims management
                  solutions that provide immediate support and assistance,
                  relieving you of stress.
                </p>

                <h5>24/7 helpline</h5>
                <p className="collision">
                  Our dedicated team is available round the clock to provide you
                  with emergency assistance. Whether you need roadside
                  assistance or want a temporary replacement vehicle, you can
                  call us via{" "}
                  <strong>
                    the accident helpline{" "}
                    <a href="tel:0800 772 0850">0800 772 0850</a>
                  </strong>{" "}
                  for prompt and reliable assistance.
                </p>

                <h5>Professional claims process</h5>
                <p className="collision">
                  From getting your vehicle repaired to securing maximum
                  compensation for you, we will ease your worries and remove all
                  the hassles involved in the claims process.
                </p>
              </div>
            </div>
            <div className="col-xxl-4 col-xl-4 col-lg-6 col-md-12 col-12">
              <div className="Claimant">
                <h5>Stress-free recovery</h5>
                <p className="collision">
                  We will recover all the expenses incurred on your behalf from
                  the at-fault party’s insurer, leaving you stress-free.
                </p>

                <h5>Expert advisors</h5>
                <p className="collision">
                  When you contact us via{" "}
                  <strong>Hastings Direct accident claims number</strong>, our
                  experts will guide you to stay calm and will diligently handle
                  your claim to safeguard your insurance rights.
                </p>

                <h5>Quick and prompt service</h5>
                <p className="collision">
                  Whether it’s coordinating repairs, arranging replacement
                  vehicles or processing your{" "}
                  <strong>Hastings Direct insurance accident claim</strong>, our
                  dedicated team provides prompt services without any delay.
                </p>
              </div>
            </div>
            <div className="col-xxl-4 col-xl-4 col-lg-12 col-md-12 col-12">
              <div className="Claimant">
                <h5>Instant repairs</h5>
                <p className="collision">
                  We provide you with the highest-quality repairs from
                  industry-accredited repairers who meet strict industry
                  standards and provide top-notch repairs that exceed
                  manufacturer specifications.
                </p>

                <h5>Immediate replacement vehicle </h5>
                <p className="collision">
                  When you retain our services, we will provide you with an
                  immediate replacement vehicle to ensure minimum disruption to
                  your everyday routine.
                </p>

                <h5>No-obligation advice </h5>
                <p className="collision">
                  When you call us at{" "}
                  <strong>Hastings Direct accident claim phone number</strong>,
                  we provide no-obligation advice to help you know your rights
                  and options after an accident.
                </p>
              </div>
            </div>
          </div>
        </div>
      </search>

      <section className="Accident-claim">
        <div className="container">
          <div className="row">
            <div className="needed">
              <h2 className="trusted-title">
                How can you boost your claim value after a <br />
                <span className="light"> no-fault accident</span>
              </h2>
              <p className="complexities">
                From managing injuries to dealing with vehicle repairs and
                handling complex paperwork, the{" "}
                <strong>Hastings Direct insurance accident claims</strong>{" "}
                process can be stressful. However, knowing the right steps to
                deal with a no-fault accident can help you maximise the value of
                your claim.
              </p>
            </div>
            <hr />
          </div>
          <div className="row" id="incdes">
            <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-12">
              <div className="Preparing">
                <ul>
                  <li>
                    <div className="seemingly">
                      <h5 className="Seek">Don’t panic</h5>
                      <p>
                        Avoid panicking after a no-fault car accident. Instead,
                        find a safe place to stop your car and assess the
                        situation.
                      </p>
                    </div>
                  </li>
                  <li>
                    <div className="seemingly">
                      <h5 className="Seek">Get emergency assistance</h5>
                      <p>
                        <strong>Report car accident</strong> to the police and
                        seek emergency help by giving us a call on{" "}
                        <strong>Hasting Direct claims contact number</strong> to
                        get immediate roadside assistance.
                      </p>
                    </div>
                  </li>
                  <li>
                    <div className="seemingly">
                      <h5 className="Seek">
                        Gather as much evidence as possible
                      </h5>
                      <p>
                        Collect as much evidence as possible from the accident
                        scene to prove your innocence and make your claim
                        stronger. The evidence may include photographs from the
                        accident scene, damaged vehicle, eyewitness statements
                        and CCTV videos.
                      </p>
                    </div>
                  </li>
                  <li>
                    <div className="seemingly">
                      <h5 className="Seek">Arrange vehicle repairs</h5>
                      <p>
                        Assess the condition of your vehicle and make
                        arrangements for your vehicle repairs. If you have your
                        own repairer, you can provide us with the details and we
                        will coordinate your vehicle repairs.
                      </p>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
            <div
              className="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-12"
              id="dualcardiv"
            >
              <img src={dualcar} alt="" className="dualcar" />
            </div>
            <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-12">
              <div className="Preparing">
                <ul>
                  <li>
                    <div className="seemingly">
                      <h5 className="Seek">Organise vehicle replacement </h5>
                      <p>
                        Whilst your vehicle is getting repaired, you might need
                        a temporary vehicle to continue your routine activities.
                        You can call us at{" "}
                        <strong>
                          {" "}
                          accident number{" "}
                          <a href="tel:0800 772 0850">0800 772 0850</a>
                        </strong>
                        , and we will provide you with a cost-free replacement
                        vehicle.
                      </p>
                    </div>
                  </li>
                  <li>
                    <div className="seemingly">
                      <h5 className="Seek">Ensure safety</h5>
                      <p>
                        Check whether you or any of your passengers have
                        sustained any injuries. If you require medical
                        assistance, immediately seek help from a professional
                        and get your injuries treated.
                      </p>
                    </div>
                  </li>
                  <li>
                    <div className="seemingly">
                      <h5 className="Seek">Record your expenses and losses </h5>
                      <p>
                        Maintain a proper record of all the expenses incurred as
                        a result of the car accident, such as your medical
                        bills, repair costs, travelling expenses etc.
                      </p>
                    </div>
                  </li>
                  <li>
                    <div className="seemingly">
                      <h5 className="Seek">Get claim management assistance </h5>
                      <p>
                        Reach out to us via{" "}
                        <strong>Hastings Direct accident claims number</strong>{" "}
                        to kickstart the process of your{" "}
                        <strong>
                          Hastings Direct Insurance accident claim
                        </strong>{" "}
                        and get deserving compensation for your damages.{" "}
                      </p>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="whyus">
        <div className="container">
          <div className="row">
            <div className="col-xxl-12 col-md-12">
              <div className="why">
                <h2 className="choose">
                  Make your claim through an easy and{" "}
                  <span className="light">
                    <br /> effortless process
                  </span>
                </h2>
                <p className="complexities">
                  Making a no-fault car accident claim can be a daunting task.
                  However, with our streamlined and stress-free claims process,
                  you can file your <strong> insurance accident claim</strong>{" "}
                  without any hassle. Start your claim with us by giving us a
                  call at{" "}
                  <strong>
                    car insurance claim number{" "}
                    <a href="tel:0800 772 0850">0800 772 0850</a>
                  </strong>
                  .
                </p>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-xxl-3 col-xl-6 col-lg-6 col-md-6 col-12">
              <div className="box-service">
                <img src={check} className="check" alt="" />
                <h4>Get in touch </h4>
                <p>
                  Reach out to our dedicated team via{" "}
                  <strong>Hastings Direct accident claims number</strong> and
                  share your accident details with us.
                </p>
              </div>
            </div>
            <div className="col-xxl-3 col-xl-6 col-lg-6 col-md-6 col-12">
              <div className="box-service">
                <img src={check} className="check" alt="" />
                <h4>Share evidence </h4>
                <p>
                  To strengthen your no-fault claim, provide us with
                  comprehensive evidence, including police reports, witness
                  statements, photographs of the accident scene, and other
                  relevant documents.
                </p>
              </div>
            </div>
            <div className="col-xxl-3 col-xl-6 col-lg-6 col-md-6 col-12">
              <div className="box-service">
                <img src={check} className="check" alt="" />
                <h4>Claim validation</h4>
                <p>
                  Once we have all the required documents, our dedicated team
                  will assess your claim thoroughly and decide whether it’s
                  valid or not.
                </p>
              </div>
            </div>
            <div className="col-xxl-3 col-xl-6 col-lg-6 col-md-6 col-12">
              <div className="box-service">
                <img src={check} className="check" alt="" />
                <h4>Submission of claim </h4>
                <p>
                  Next, we will submit your claim and keep you updated on the
                  progress. After successful processing of your claim, you’ll
                  get rightful compensation for your losses.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="faqs">
        <div className="container">
          <div className="row">
            <div className="col-xxl-12 col-xl-12 col-md-12 col-12">
              <div className="fq">
                <h2>
                  {" "}
                  Frequently Asked{" "}
                  <span className="light">Questions (FAQ)</span>
                </h2>
                <p className="complexities">
                  Got questions about Hastings Direct insurance accident claim?
                  We have them answered here.{" "}
                </p>
              </div>
              <div className="allfaq">
                <Accordion defaultActiveKey="0">
                  <Accordion.Item eventKey="0">
                    <Accordion.Header>
                      What details do I need to provide to get Hastings Direct
                      insurance accident support?
                    </Accordion.Header>
                    <Accordion.Body>
                      You must share your personal information as well as
                      accident details to get Hastings Direct insurance accident
                      support services. This includes your name, address,
                      vehicle registration numbers, witness statements,
                      photographs and videos of the accident scene and any other
                      vital information you have.
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="1">
                    <Accordion.Header>
                      Do I need to pay for Hastings Direct insurance accident
                      support services?
                    </Accordion.Header>
                    <Accordion.Body>
                      No. We provide our services at no upfront cost to you. All
                      the expenses for our services will be recovered from the
                      at-fault party’s insurance company.
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="2">
                    <Accordion.Header>
                      Can I choose my own repair shop to get my damaged vehicle
                      repaired?
                    </Accordion.Header>
                    <Accordion.Body>
                      Yes. We offer you the flexibility to select your preferred
                      repairer for your vehicle repairs. You can share the
                      details of your repairer with our team and we will arrange
                      your vehicle repairs from the garage of your own choice.
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="3">
                    <Accordion.Header>
                      Will my insurance premiums increase when I avail of
                      Hastings Direct insurance support services?
                    </Accordion.Header>
                    <Accordion.Body>
                      No. When you contact us at the Hastings Direct car
                      accident helpline for accident management solutions, we
                      won’t initiate a claim against your own insurance policy.
                      As a result, your insurance premium won’t increase.
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="4">
                    <Accordion.Header>
                      How long will it take to repair my car after a no-fault
                      car accident?
                    </Accordion.Header>
                    <Accordion.Body>
                      There are various factors that affect the duration of
                      vehicle repairs. While minor scratches or dents can be
                      repaired in a day or two, major damages may take a few
                      weeks to get repaired.
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="5">
                    <Accordion.Header>
                      What will happen if my car is written off?
                    </Accordion.Header>
                    <Accordion.Body>
                      We understand the inconvenience that you may face when
                      your vehicle is written off. Therefore, we will provide
                      you with a like-for-like replacement vehicle that you can
                      keep until and unless you receive the desired compensation
                      amounting to the full value of the written-off vehicle.
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="6">
                    <Accordion.Header>
                      Will the replacement vehicle you provide be similar to my
                      own vehicle?
                    </Accordion.Header>
                    <Accordion.Body>
                      We strive to provide you with a comparable replacement
                      vehicle similar to your own vehicle as possible. You can
                      let us know your preferences and we will arrange a
                      suitable replacement vehicle for you.
                    </Accordion.Body>
                  </Accordion.Item>
                </Accordion>
              </div>
            </div>
          </div>
        </div>
      </section>

      <Footer />
    </>
  );
};

export default HastingsDirect;
