import React, { useRef } from "react";
import Accordion from "react-bootstrap/Accordion";
import check from "../assets/images/check.png";
import dualcar from "../../src/assets/images/dualcar.webp";
import hasting from "../../src/assets/images/hasting.webp";
import Sidebarinner from "../components/Sidebar-inner";
import phonecall from "../assets/images/phonecall.png";
import Footer from "../components/footer";
import { Helmet } from "react-helmet";

const Zego = () => {
    const sidebarRef = useRef(null);

    const handleScrollToSidebar = () => {
        if (sidebarRef.current) {
            sidebarRef.current.scrollIntoView({ behavior: "smooth" });
        }
    };
    return (
        <>
        <Helmet>
        <meta charSet="utf-8" />
        <title>Zego Car Insurance Accident Claim Phone Number</title>
        <meta
          name="description"
          content="Searching for quick and end-to-end claims assistance? Call us on the Zego Car Insurance Accident Claim Phone Number 0800 772 0850 for quick and efficient assistance."
        />
        <link
          rel="canonical"
          href="https://insurance-accident-claims.co.uk/Zego"
        />
      </Helmet>
            <section className="innernav">
                <div className="container">
                    <div className="row">
                        <div className="col-md-6 col-sm-6">
                            <div className="left-logo">
                                <h3>
                                    Accident<span className="sipo"> Claims</span>
                                </h3>
                            </div>
                        </div>
                        <div className="col-md-6 col-sm-6">
                            <div className="left-cta">
                                <div class="headercnt">
                                    <div class="chatmsg">
                                        <img
                                            src={phonecall}
                                            className="phonecall"
                                            alt="phonecall "
                                        />
                                    </div>
                                    <div class="box-body">
                                        <h3 class="nav-info-box-title">Toll Free Number </h3>
                                        <p>
                                            <a href="tel:0800 772 0850">0800 772 0850</a>
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section
                className="newbnr"
                style={{ backgroundImage: "url(" + hasting + ")" }}
            >
                <div className="container">
                    <div className="row">
                        <div className="col-xxl-7 col-xl-7 col-md-10 col-12">
                            <div className="innerbnr">
                                <h1>
                                    <span className="onespan">No-fault accident  </span>{" "}
                                    <span className="sci">insurance claims </span> support
                                </h1>
                                <p className="brokerage">
                                We provide complete accident management support services, ranging from car recovery to claims handling.                               </p>
                                <div className="bnrbtn">
                                    <a class="db-btn-border btn-rollover phone" href="tel:0800 772 0850">
                                        Road Accident Claim Help?{" "}
                                        <i class="fa-solid fa-arrow-right"></i>
                                    </a>
                                </div>
                                <div className="bnrbtn">
                                    <a className="db-btn-border btn-rollover desktop" onClick={handleScrollToSidebar}>
                                        Road Accident Claim Help? <i className="fa-solid fa-arrow-right"></i>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="stickysection">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="group-4-8">
                                <div className="cl8 tf-tab">
                                    <div className="content-tab">
                                        <div className="inner-content">
                                            <h2>
                                            Expert claim assistance with
                                                <br />
                                                <span className="light">  zero excess </span>{" "}
                                            </h2>
                                            <p className="disciplines">
                                            We know how annoying and time-consuming it may be to deal with a non-fault car collision. Therefore, we offer <strong> Zego insurance accident claim  </strong> services at no financial cost to no-fault drivers in the UK. 
                                            </p>
                                            <hr />
                                            <p className="bold-text">No hidden charges </p>
                                            <p className="bottom-te">  When You dial our accident line at <strong>
                                            {" "}
                                            <a href="tel:0800 772 0850">0800 772 0850</a>{" "}
                                        </strong> you will have no extra charges or concealed costs added to you by us. 
                                        </p>
                                            <hr />
                                            <p className="bold-text">Your policy unaffected </p>
                                            <p className="bottom-te">
                                            When you make your <strong>Zego car insurance claim </strong>  with us, we promise you that your insurance premiums won’t increase as we will make a claim against the other party.                                             </p>
                                            <hr />
                                            <p className="bold-text">
                                            No claims bonus stays intact
                                            </p>
                                            <p className="bottom-te">
                                            You can avoid unexpected charges and anxiety about your NCB by calling us at the <strong> Zego car insurance claims number.</strong>                                            </p>
                                            <hr />
                                            <p className="bold-text">Genuine repairs </p>
                                            <p className="bottom-te">
                                            We give you full accident repair service to make your car look the way it did before the accident. Just call us on the <strong> Zego claims contact number </strong> and get top-notch vehicle repair services.                                         </p>
                                            <hr />
                                            <p className="bold-text">Immediate vehicle recovery</p>
                                            <p className="bottom-te">
                                            Your car will be picked up by our team within no time after a non-fault accident. Anytime you experience such an incident, our emergency team works around the clock to offer quick assistance and recover your vehicle from the accident scene.                                            </p>
                                            <hr />
                                            <p className="bold-text">No fault experts </p>
                                            <p className="bottom-te">
                                            Call us now on the <strong>  Zego accident claim number </strong> in case you have been involved in a no-fault accident, and we will connect you with someone who deals exclusively with no-fault claims and will help you secure rightful compensation. 
                                            </p>
                                            <hr />
                                            <p className="bold-text">Professional advice </p>
                                            <p className="bottom-te">
                                            Our panel of experienced no-fault solicitors includes professionals who have dealt with numerous no-fault claims. That’s why, when you call us at the <strong> Zego insurance claims contact number, </strong> we will connect you with a specialist solicitor who will guide you at every step of your claim process.                                       </p>
                                            <hr />
                                            <p className="bold-text"> Dedicated team</p>
                                            <p className="bottom-te">
                                            We’re here to take all the discomforts that come after an accident, and you’re not responsible for it. You can reach us through the <strong> Zego accident claims number, </strong> and our dedicated team will respond quickly to bring you back on the road as soon as possible.                                        </p>
                                            <hr />
                                            <p className="bold-text">Free claim consultation </p>
                                            <p className="bottom-te">
                                            We’ll help you get well faster after no-fault insurance. All you need is a call through our<strong>Zego accident claims number, </strong>  and we’ll give you free support regarding your <strong> Zego insurance claim. </strong>                                       </p>
                                            <hr />

                                            <p className="bold-text">Fast-paced claim process </p>
                                            <p className="bottom-te">
                                            When making your <strong> Zego insurance accident claim </strong> with us, be assured of swift indemnification. Our team works hard at every stage of the claim process to ensure quick resolution. 
                                                     
                                                 </p>
                                        </div>
                                    </div>
                                </div>
                                <div className="cl4" ref={sidebarRef}>
                                    <Sidebarinner />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="photographs">
                <div className="container">
                    <div className="row">
                        <h2 className="road">
                        We leave no cause for anxiety after a  {" "}
                            <span className="light">
                            non-fault <br />
                               road accident
                            </span>{" "}
                        </h2>
                        <div className="col-xxl-6 col-xl-6 col-md-12 col-12">
                            <div className="fourbox">
                                <ul>
                                    <li>
                                        <img src={check} className="check" alt="" />
                                        We are specialists in providing <strong> Zego insurance accident claim </strong>  assistance, having supported thousands of UK drivers in getting back on track after the accident. 

                                    </li>
                                    <hr />
                                    <li>
                                        <img src={check} className="check" alt="" />
                                        When calling us on our car accident hotline <strong>
                                            {" "}
                                            <a href="tel:0800 772 0850">0800 772 0850</a>{" "}
                                        </strong> we shall grant you another vehicle so that your daily activities are not interrupted.                                 </li>
                                    <hr />
                                    <li>
                                        <img src={check} className="check" alt="" />
                                        We will also investigate the truth of your <strong>Zego insurance accident claim </strong>  as well as collect the necessary information and documentation for processing it.
                                    </li>
                                    <hr />
                                    <li>
                                        <img src={check} className="check" alt="" />
                                        Again, we could help arrange to fix your damaged vehicle at a garage using original parts. You need to call us through our <strong> Zego accident claims </strong>  helpline for the best accident repairs.                                </li>
                                </ul>
                            </div>
                        </div>
                        <div className="col-xxl-6 col-xl-6 col-md-12 col-12">
                            <div className="fourbox">
                                <ul>
                                    <li>
                                        <img src={check} className="check" alt="" />
                                        If you have been injured in a no-fault accident, please contact our solicitors on <strong>
                                            {" "}
                                            <a href="tel:0800 772 0850">0800 772 0850</a>{" "}
                                        </strong> so that they can advise you on how to proceed ahead with your claim. 
                                        </li>
                                    <hr />
                                    <li>
                                        <img src={check} className="check" alt="" />
                                        Once we receive your call through the <strong> Zego claims number,</strong> a list of all losses incurred in that occurrence shall be prepared and sent to either the insurer or directly affected party’s company responsible for paying damages.                                   </li>
                                    <hr />
                                    <li>
                                        <img src={check} className="check" alt="" />
                                       We shall take up your claim from beginning to end and communicate with various parties involved in the collision.                                  </li>
                                    <hr />
                                    <li>
                                        <img src={check} className="check" alt="" />
                                        After your vehicle is repaired completely, we should schedule its delivery to your home at an appropriate time.                             </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="carprocess">
                <div className="container ">
                    <div className="row">
                        <div className="col-xxl-12 col-xxl-12 col-md-12 col-12">
                            <h2 className="trusted-title">
                                {" "}
                                Accident management services <br />
                                <span className="light">we offer </span>{" "}
                            </h2>
                        </div>
                    </div>
                    <div className="row" id="possible">
                        <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-12">
                            <div className="claim">
                                <ul>
                                    <li>
                                        <span className="sleej">Vehicle recovery:</span> If you’re in search of a company dealing with accident recovery to haul your car to a safe place after a non-fault road crash, call us at the<strong> Zego accident claim phone number. </strong>  Our team will arrive within moments for car removal from the scene of the collision.     
                                    </li>
                                    <li>
                                        <span className="sleej">Vehicle repairs :</span>  Your badly damaged vehicle will be repaired by our trusted repairer free of charge using original parts. In case you need to repair your damaged car via our efficient and rapid repair network, contact us on the <strong>Zego accident claims helpline. </strong> 

                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-12">
                            <div className="claim" id="blue">
                                <ul>
                                    <li>
                                        <span className="sleej">Vehicle storage:</span> We have an all-inclusive vehicle storage facility specially designed for your convenience after an accident that was not your fault. Your damaged car can be placed in our facilities while waiting for either repairs or further evaluation carried out by a specialist. Please feel free to call us via the <strong> Zego claims line  </strong> so that we can move your car into our well-kept compounds without additional charges.
                                    </li>
                                    <li>
                                        <span className="sleej">Replacement car:</span> Our main objective is to offer a courtesy vehicle of the same type as yours that will enable you to continue with your day-to-day activities while your car is being repaired or is immobile after an accident that was not your fault. When you phone us by dialling the <strong> Zego accident claims helpline,</strong>  we will ensure that a suitable courtesy car is delivered for you. 
                                         
                                        
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-12">
                            <div className="claim">
                                <ul>
                                    <li>
                                        <span className="sleej">Personal injury claim </span>
                                        Contact the <strong> Zego accident claims phone number </strong>  when you need advice on how to make a claim, and we will assign you an experienced solicitor who will assist and advise you until every issue relating to your case is resolved.                                            </li>
                                    <li>
                                        <span className="sleej">Claim management:</span> We specialise in dealing with non-fault accidents within the UK. Call the <strong> Zego accident claims helpline </strong>  whenever you have queries concerning your case, and we will simplify everything so as to ensure full compensation for all injuries caused.
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="yellowcta">
                <div className="container">
                    <div className="row">
                        <div className="cta2">
                            <div className="col-xxl-8 col-xl-8 col-lg-8 col-12">
                                <div className="legal">
                                    <h3 className="experienced">
                                    Need further assistance? Speak{" "}
                                        <span className="light"> to our no-fault claim experts. 
                                        </span>{" "}
                                    </h3>
                                    <p className="professionals">
                                    Get in touch with us via the <strong> Zego accident claims number </strong> to get professional advice for your no-fault claim from our expert solicitors. 
                                    </p>
                                </div>
                            </div>
                            <div className="col-xxl-4 col-xl-4 col-lg-4 col-12">
                                <div class="process">
                                    <a class="tf-button style-1 phone" href="tel:0800 772 0850">
                                        Get in touch<span class="icon-keyboard_arrow_right"></span>
                                    </a>
                                </div>
                                <div class="process">
                                    <a className="tf-button style-1 desktop" onClick={handleScrollToSidebar}>
                                        Get in touch<span className="icon-keyboard_arrow_right"></span>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <search className="updatedform">
                <div className="container">
                    <div className="row text-center">
                        <div className="col-xxl-12 col-xl-12 col-md-12 col-12">
                            <h2 className="whiteheading">
                                {" "}
                                Why are we the best option for your accident
                                <span className="light">  management needs? 
                                </span>
                            </h2>
                            <p className="sustained">
                            Before making any contact with your insurance company, get in touch with us first by calling the  <strong> Zego claims contact number.</strong> We offer several advantages over what may be offered by insurers.                 </p>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-xxl-4 col-xl-4 col-lg-6 col-md-12 col-12">
                            <div className="Claimant">
                                <h5>No excess charges or unwanted surprises </h5>
                                <p className="collision">
                                We are not going to charge you because all our fees will be collected from the wrongdoer’s insurer.

                                </p>

                                <h5>Fast claims settlement</h5>
                                <p className="collision">
                                For quick settlement of your <strong> Zego insurance accident claim, </strong>  we have an easy and straightforward process that enables you to receive your compensation fa                        </p>

                                <h5>Round-the-clock assistance</h5>
                                <p className="collision">
                                Our team is always ready with immediate response after a non-fault car accident. 
                                </p>
                            </div>
                        </div>
                        <div className="col-xxl-4 col-xl-4 col-lg-6 col-md-12 col-12">
                            <div className="Claimant">
                                <h5>Manufacturer-approved repairs 
                                </h5>
                                <p className="collision">
                                In the UK, we have well-scrutinised repairers whose repairs are of high quality. Call us on the <strong> Zego accident number </strong> for quality and guaranteed repairs for your damaged vehicle.  </p>
                                <h5>Free Courtesy Car </h5>
                                <p className="collision">
                                If you get in touch with us through the <strong> Zego claims number,</strong>  we will provide you with another one similar to yours without any excess.                           </p>

                                <h5>Free initial consultation</h5>
                                <p className="collision">
                                To guide you on the next steps after the accident, our adept solicitors can give free advice based on your accident facts. 
                                </p>
                            </div>
                        </div>
                        <div className="col-xxl-4 col-xl-4 col-lg-12 col-md-12 col-12">
                            <div className="Claimant">
                                <h5>Ongoing claims follow-up</h5>
                                <p className="collision">
                                At each stage of your<strong>  Zego car insurance claim, </strong> we shall keep updating you about its progress.                               </p>

                                <h5>Expert solicitors </h5>
                                <p className="collision">
                                Our solicitors have a lot of experience recovering the highest possible compensation for victims who have suffered from accidents that were not their fault.                        </p>

                                <h5>No fuss services </h5>
                                <p className="collision">
                                When you make contact with us on the<strong>Zego accident claim number, </strong>  we will oversee all elements of your claim with utmost accuracy so that you do not suffer any stress at all.                          </p>
                            </div>
                        </div>
                    </div>
                </div>
            </search>

            <section className="Accident-claim">
                <div className="container">
                    <div className="row">
                        <div className="needed">
                            <h2 className="trusted-title">
                            How to get the most out of your no-fault car accident <br />
                                <span className="light"> injury compensation</span>
                            </h2>
                            <p className="complexities">
                            There is a lot one needs to do right away after a no-fault collision in order to prove innocence and enhance the case. Here are some steps which you need to take if you want your<strong> Zego insurance accident claim </strong> to result in full cover or anything close by.                          </p>
                        </div>
                        <hr />
                    </div>
                    <div className="row" id="incdes">
                        <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-12">
                            <div className="Preparing">
                                <ul>
                                    <li>
                                        <div className="seemingly">
                                            <h5 className="Seek">Do not leave the accident scene:</h5>
                                            <p>
                                                {" "}
                                                Remember, do not run away from an accident scene following a non-fault road traffic incident. Instead, leave your vehicle safely parked nearby.                                    </p>
                                        </div>
                                    </li>
                                    <li>
                                        <div className="seemingly">
                                            <h5 className="Seek">
                                            Call the police:{" "}
                                            </h5>
                                            <p>
                                            Call the police immediately within 24 hours and get a copy of the police report.                                             </p>
                                        </div>
                                    </li>
                                    <li>
                                        <div className="seemingly">
                                            <h5 className="Seek">Collect evidence :</h5>
                                            <p> After a road accident, ensure that you collect as much information from the site of the crash, such as photos showing how the event occurred, eyewitness accounts and any other important piece of evidence that will help support your claim.                                        </p>
                                        </div>
                                    </li>
                                    <li>
                                        <div className="seemingly">
                                            <h5 className="Seek">Seek medical attention :</h5>
                                            <p>
                                            Immediately after an accident that is not your fault, get medical treatment for your injuries. The longer you wait before seeking medical help for the injuries, the harder it will be to prove a case against the other party.                                            </p>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div
                            className="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-12"
                            id="dualcardiv"
                        >
                            <img src={dualcar} alt="" className="dualcar" />
                        </div>
                        <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-12">
                            <div className="Preparing">
                                <ul>
                                    <li>
                                        <div className="seemingly">
                                            <h5 className="Seek">Stay away from first offer settlements :</h5>
                                            <p>
                                            Do not go for the first compensation offers presented by insurance companies since they are fast and cheap payouts. Once you accept this settlement, it becomes impossible to further pursue the claim.                                            </p>
                                        </div>
                                    </li>
                                    <li>
                                        <div className="seemingly">
                                            <h5 className="Seek">Communicate with the other driver : </h5>
                                            <p>
                                            Swap sensitive information about yourself and the car with the other driver, like his name, vehicle details such as registration number and make, insurance policy details and driver’s licence number.                                             </p>
                                        </div>
                                    </li>
                                    <li>
                                        <div className="seemingly">
                                            <h5 className="Seek">
                                            Organise for recovery of vehicle :
                                            </h5>
                                            <p>
                                            If your car cannot move on its own, organise its recovery from the accident scene by calling the <strong> Zego accident number.</strong> 
                                            </p>
                                        </div>
                                    </li>
                                    <li>
                                        <div className="seemingly">
                                            <h5 className="Seek">
                                            Make your claim  :
                                            </h5>
                                            <p>
                                            In addition to availing all relevant documents, call our claims department using the <strong> Zego claims contact number </strong>  after collecting all evidence.

                                            </p>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="whyus">
                <div className="container">
                    <div className="row">
                        <div className="col-xxl-12 col-md-12">
                            <div className="why">
                                <h2 className="choose">
                                Easy and hassle-free  {" "}
                                    <span className="light">claims process.</span>
                                </h2>
                                <p className="complexities">
                                To make a successful claim with us, you can reach us on the <strong> Zego car insurance claim number. </strong> With our simple and straightforward claims process, you can make your claim in the shortest possible time.                      </p>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-xxl-3 col-xl-6 col-lg-6 col-md-6 col-12">
                            <div className="box-service">
                                <img src={check} className="check" alt="" />
                                {/* <img src={experience} className="experience" alt="" /> */}
                                <h4>Contact Us </h4>
                                <p>
                                Contact us on <strong>
                                            {" "}
                                            <a href="tel:0800 772 0850">0800 772 0850</a>{" "}
                                        </strong> for a free initial consultation with our solicitors to discuss your claim. 
                                        </p>
                            </div>
                        </div>
                        <div className="col-xxl-3 col-xl-6 col-lg-6 col-md-6 col-12">
                            <div className="box-service">
                                <img src={check} className="check" alt="" />
                                <h4>Exchange accident information </h4>
                                <p>
                                You should notify us with details regarding your no-fault accident, such as date, time, location and others.</p>
                            </div>
                        </div>
                        <div className="col-xxl-3 col-xl-6 col-lg-6 col-md-6 col-12">
                            <div className="box-service">
                                <img src={check} className="check" alt="" />
                                <h4>Provide evidence </h4>
                                <p>
                                Submit any important evidence that you may have to make your claim stronger.                              </p>
                            </div>
                        </div>
                        <div className="col-xxl-3 col-xl-6 col-lg-6 col-md-6 col-12">
                            <div className="box-service">
                                <img src={check} className="check" alt="" />
                                <h4>Claims initiation</h4>
                                <p>
                                We guarantee that once we have all the necessary information concerning your matter, we will take up the matter on your behalf so that it results in fair compensation for injuries sustained by you. 

                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="faqs">
                <div className="container">
                    <div className="row">
                        <div className="col-xxl-12 col-xl-12 col-md-12 col-12">
                            <div className="fq">
                                <h2>
                                    {" "}
                                    Frequently Asked{" "}
                                    <span className="light">Questions</span>
                                </h2>
                                <p className="complexities">
                                Got more questions about our no-fault <strong> Zego insurance accident claim. </strong>  We have them answered below. 

                                </p>
                            </div>
                            <div className="allfaq">
                                <Accordion defaultActiveKey="0">
                                    <Accordion.Item eventKey="0">
                                        <Accordion.Header>
                                        Would my premiums go up if I make a <strong> Zego car insurance claim?</strong>                                  </Accordion.Header>
                                        <Accordion.Body>
                                        No. Our service remains uncompromised in relation to your own policy. So, there is no way it would affect insurance rates or cost extra money.                                  </Accordion.Body>
                                    </Accordion.Item>
                                    <Accordion.Item eventKey="1">
                                        <Accordion.Header>
                                        In case of a no-fault accident, how long will it take you to get me another car?
                                        </Accordion.Header>
                                        <Accordion.Body>
                                        We recognise that dealing with accidents not caused by the driver can be very distressing. Consequently, we will replace your private or motor vehicle model within 24 hours at no cost to you. While your vehicle is being completely restored to its former condition before the accident, you may continue using it.                                     </Accordion.Body>
                                    </Accordion.Item>
                                    <Accordion.Item eventKey="2">
                                        <Accordion.Header>
                                        Can I have my car repaired by my preferred repairer?
                                        </Accordion.Header>
                                        <Accordion.Body>
                                        You choose which garage repairs your car for you. Just let us know who they are, and we will take care of everything else in collaboration with them.                          </Accordion.Body>
                                    </Accordion.Item>
                                    <Accordion.Item eventKey="3">
                                        <Accordion.Header>
                                        How much time will it take for my damaged car to be fixed?
                                        </Accordion.Header>
                                        <Accordion.Body>
                                        Several factors influence how quickly a damaged car gets repaired, including the extent of destruction and availability of authorised parts. The repairer shall provide us with a quotation and inform you about the duration it will take for the repairs to be finished.                                 </Accordion.Body>
                                    </Accordion.Item>
                                    <Accordion.Item eventKey="4">
                                        <Accordion.Header>
                                        What are the other types of damages suffered by the other side that I can sue for in a case?

                                        </Accordion.Header>
                                        <Accordion.Body>
                                        These include the cost of repairing the vehicle, medical bills payment, loss of earnings and both emotional, physical and psychological pain which was caused as a result of the accident. You will get professional advice on what to claim when you call the<strong> Zego claims number.  </strong>                           </Accordion.Body>
                                    </Accordion.Item>
                                    <Accordion.Item eventKey="5">
                                        <Accordion.Header>
                                        How long does it take for my<strong>  Zego insurance accident claim </strong>to be settled?{" "}
                                        </Accordion.Header>
                                        <Accordion.Body>
                                        The duration within which your case settles may be influenced by a number of factors. However, once you call us via our <strong> Zego accident claims helpline,  </strong>we shall expedite your claim so that it is resolved within a very short period.                                  </Accordion.Body>
                                    </Accordion.Item>
                                    <Accordion.Item eventKey="6">
                                        <Accordion.Header>
                                        Should I accept an out-of-court settlement from an insurance firm representing someone who caused a collision?{" "}
                                        </Accordion.Header>
                                        <Accordion.Body>
                                        No. You ought to avoid being tempted to accept out-of-court settlements from insurers. Once you agree with their terms of payment, you will lose your right to seek legal redress against them.
                                        </Accordion.Body>
                                    </Accordion.Item>
                                </Accordion>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <Footer />
        </>
    );
};

export default Zego;
