import React, { useRef } from "react";
import Accordion from "react-bootstrap/Accordion";
import check from "../assets/images/check.png";
import dualcar from "../../src/assets/images/dualcar.webp";
import carbnr1 from "../../src/assets/images/carbnr1.webp";
import Sidebarinner from "../components/Sidebar-inner";
import phonecall from "../assets/images/phonecall.png";
import Footer from "../components/footer";
import { Helmet } from "react-helmet";

const Policywise = () => {
  const sidebarRef = useRef(null);

  const handleScrollToSidebar = () => {
    if (sidebarRef.current) {
      sidebarRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Policywise Car Insurance Accident Claim Phone Number</title>
        <meta
          name="description"
          content="Reach out to our team via the Policywise Car Insurance Accident Claim Phone Number 0800 772 0850 for efficient claims assistance to secure maximum compensation."
        />
        <link
          rel="canonical"
          href="https://insurance-accident-claims.co.uk/Policywise"
        />
      </Helmet>
      <section className="innernav">
        <div className="container">
          <div className="row">
            <div className="col-md-6 col-sm-6">
              <div className="left-logo">
                <h3>
                  Accident<span className="sipo"> Claims</span>
                </h3>
              </div>
            </div>
            <div className="col-md-6 col-sm-6">
              <div className="left-cta">
                <div class="headercnt">
                  <div class="chatmsg">
                    <img
                      src={phonecall}
                      className="phonecall"
                      alt="phonecall "
                    />
                  </div>
                  <div class="box-body">
                    <h3 class="nav-info-box-title">Toll Free Number </h3>
                    <p>
                      <a href="tel:0800 772 0850">0800 772 0850</a>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section
        className="newbnr"
        style={{ backgroundImage: "url(" + carbnr1 + ")" }}
      >
        <div className="container">
          <div className="row">
            <div className="col-xxl-8 col-xl-8 col-md-10 col-12">
              <div className="innerbnr">
                <h1>
                  <span className="onespan">The Trusted</span>{" "}
                  <span className="sci"> accident insurance claims </span> specialist in the UK
                </h1>
                <p className="brokerage">
                  We are the leading accident management experts in the UK, specialising in providing the best solutions for quick recovery after no-fault car accidents.
                </p>
                <div className="bnrbtn">
                  <a class="db-btn-border btn-rollover phone" href="tel:0800 772 0850">
                    Road Accident Claim Help?{" "}
                    <i class="fa-solid fa-arrow-right"></i>
                  </a>
                </div>
                <div className="bnrbtn">
                  <a className="db-btn-border btn-rollover desktop" onClick={handleScrollToSidebar}>
                    Road Accident Claim Help? <i className="fa-solid fa-arrow-right"></i>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="stickysection">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="group-4-8">
                <div className="cl8 tf-tab">
                  <div className="content-tab">
                    <div className="inner-content">
                      <h2>
                        Exceptional Policywise Insurance Accident
                        <br />
                        <span className="light">
                          Support with Zero Excess.{" "}
                        </span>{" "}
                      </h2>
                      <p className="disciplines">
                        Being involved in a no-fault car accident can be a stressful experience, but this is just one of the issues you have to deal with. In addition to coping with injuries and damages, there are also complexities involved in insurance claims handling. To this end, we offer you when it comes to support through your{" "}
                        <strong> Policywise insurance accident claim </strong>process without charging any extra money.
                      </p>
                      <hr />
                      <p className="bold-text">No upfront costs</p>
                      <p className="bottom-te">
                        <strong>Policywise insurance accident support</strong>{" "}
                        covers everything from vehicle recovery to claim assistance so you can move on with your life without paying out any additional costs, as all our services are tailored to meet such requirements.{" "}
                      </p>
                      <hr />
                      <p className="bold-text">No claims bonus remains protected</p>
                      <p className="bottom-te">
                        When you dial our claims line at <a href="tel:0800 772 0850">0800 772 0850</a> for emergency assistance, you can rest assured your hard-earned discounts remain intact.
                      </p>
                      <hr />
                      <p className="bold-text">
                        Professional standards
                      </p>
                      <p className="bottom-te">
                        Our professional standards are executed through efficient{" "}
                        <strong>
                          Policywise insurance accident support{" "}
                        </strong>
                        services, which guarantee maximum compensation on behalf of clients with an existing policy’s claim arising out of an insurable event.{" "}
                      </p>
                      <hr />
                      <p className="bold-text">
                        Experienced solicitors{" "}
                      </p>
                      <p className="bottom-te">
                        Inefficiently handling your claim is not our style, as we have enlisted highly experienced and professional solicitors throughout the country who will manage the case within the shortest time possible.{" "}
                      </p>
                      <hr />
                      <p className="bold-text">Free initial consultation</p>
                      <p className="bottom-te">
                        Our solicitors will assess your claim, offer professional advice and guide you throughout the process.
                      </p>
                      <hr />
                      <p className="bold-text">A strong network of approved repairers and credit hire companies</p>
                      <p className="bottom-te">
                        Our wide range of highly reputed repairers, renowned credit hire firms, as well as skilled solicitors offer a very supportive network to ensure quality service provision.
                      </p>
                      <hr />
                      <p className="bold-text">Quick claim settlement</p>
                      <p className="bottom-te">
                        You can start your claim with us by calling the{" "}
                        <strong>Policywise accident claims helpline.</strong> We will make sure that your <strong> Policywise insurance accident claim </strong> is settled fast and easily through our expedited claims process.
                      </p>
                      <hr />
                      <p className="bold-text">Immediate vehicle replacement</p>
                      <p className="bottom-te">
                        We will supply like-for-like replacement vehicles on the same day to allow you to continue your normal day-to-day activities without disturbance.{" "}
                      </p>
                      <hr />
                      <p className="bold-text">Easy vehicle storage without stress </p>
                      <p className="bottom-te">
                        We have very safe and secure storage yards which keep damaged cars, preventing any further deterioration or damage.{" "}
                      </p>
                      <hr />

                      <p className="bold-text">Deep expertise and unmatched experience.</p>
                      <p className="bottom-te">
                        We have vast expertise in handling {" "}<strong> Policywise insurance accident claims, </strong> and we are now top-class experts in this field.We certainly will get you more money when you reach out to us through the <strong> Policywise accident claims number </strong>  than any other company that is dealing with accidents at present.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="cl4" ref={sidebarRef}>
                  <Sidebarinner />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="photographs">
        <div className="container">
          <div className="row">
            <h2 className="road">
              Let us handle your claim  {" "}
              <span className="light">
                while <br />
                you recover
              </span>{" "}
            </h2>
            <div className="col-xxl-6 col-xl-6 col-md-12 col-12">
              <div className="fourbox">
                <ul>
                  <li>
                    <img src={check} className="check" alt="" />
                    Following a non-fault road traffic accident, when you get in touch with us on our car accident claims helpline <strong> <a href="tel:0800 772 0850">0800 772 0850</a></strong> , we will take down the details of the accident and help instantly.
                  </li>
                  <hr />
                  <li>
                    <img src={check} className="check" alt="" />
                    Our qualified solicitors will investigate and evaluate your{" "}
                    <strong> Policywise insurance accident claim </strong> and guide you through assessing how to go about making a successful claim.
                  </li>
                  <hr />
                  <li>
                    <img src={check} className="check" alt="" />
                    Call us at{" "}
                    <strong> Policywise accident claims number. </strong> We shall give you a car for another one while yours is being repaired.
                  </li>
                  <hr />
                  <li>
                    <img src={check} className="check" alt="" />
                    We are experts in handling <strong> Policywise insurance accident claims </strong>from start to finish. From managing paperwork to ensuring quick settlement, we are your trusted partners along the way.
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-xxl-6 col-xl-6 col-md-12 col-12">
              <div className="fourbox">
                <ul>
                  <li>
                    <img src={check} className="check" alt="" />
                    We take over all aspects of the claim process so that you can bounce back without any delay.
                  </li>
                  <hr />
                  <li>
                    <img src={check} className="check" alt="" />
                    We negotiate fair settlement offers to secure maximum compensation for your injuries and losses.
                  </li>
                  <hr />
                  <li>
                    <img src={check} className="check" alt="" />
                    Our accident management team works closely with reputable repairers and garages for high-quality repairs.
                  </li>
                  <hr />
                  <li>
                    <img src={check} className="check" alt="" />
                    Throughout the course of your <strong> Policywise insurance accident claim, </strong>we will protect your rights as far as possible in relation to it and ensure that you have peace of mind all through.
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="carprocess">
        <div className="container ">
          <div className="row">
            <div className="col-xxl-12 col-xxl-12 col-md-12 col-12">
              <h2 className="trusted-title">
                {" "}
                Accident management services <br />
                <span className="light">we offer</span>{" "}
              </h2>
            </div>
          </div>
          <div className="row" id="possible">
            <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-12">
              <div className="claim">
                <ul>
                  <li>
                    <span className="sleej">Vehicle recovery:</span>By contacting the <strong> Policywise accident claims number, </strong>  you will get access to fast and professional services of vehicle recovery that will help you move your crashed car to a safer place.
                  </li>
                  <li>
                    <span className="sleej"> Vehicle repairs :</span>Do you want high-quality repairs for your damaged vehicle? Call us on the{" "}
                    <strong> Policywise accident claims number, </strong>{" "}
                    and we will arrange repairs for your damaged vehicle. In the UK, our approved repairers’ network is well-connected, and they are all accredited companies with high standards of workmanship.
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-12">
              <div className="claim" id="blue">
                <ul>
                  <li>
                    <span className="sleej">Vehicle Storage:</span> After an accident that was not your fault, it is essential that one keeps his or her car in a safe place where no further damage can occur. We collect your wrecked car from where the incident occurred as soon as you call us via the{" "}
                    <strong> Policywise accident contact number </strong>and keep it in our storage yards until such a time when there is a need for assessment purposes.
                  </li>
                  <li>
                    <span className="sleej">Replacement car:</span>A courtesy car can be given to you after a non-fault road traffic accident. What we do is provide you with an alternative temporary means of transport so that you don’t have to suffer from work inconvenience. All one needs to do is dial the number <strong> Policywise accident number, </strong>whereby within twenty-four hours, they will have accessed another vehicle similar to their previous one.
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-12">
              <div className="claim">
                <ul>
                  <li>
                    <span className="sleej">Injury compensation:</span>
                    Should you have been injured in an accident, we will assist you with making a successful claim. Our team will guide you through the entire process to make sure that you get what is meant for you as compensation.
                  </li>
                  <li>
                    <span className="sleej">Claims management:</span>
                    We shall help you to make a successful{" "}
                    <strong> Policywise insurance accident claim, </strong>starting from documentation to claims settlement,  making your life easier during this difficult time.
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="yellowcta">
        <div className="container">
          <div className="row">
            <div className="cta2">
              <div className="col-xxl-8 col-xl-8 col-lg-8 col-12">
                <div className="legal">
                  <h3 className="experienced">
                    Need further guidance?  {" "}
                    <span className="light">Contact our experts</span>{" "}
                  </h3>
                  <p className="professionals">
                    Reach out to our dedicated team through our{" "}
                    <strong>
                      accident claims number{" "}
                      <a className="cpt-no" href="tel:0800 772 0850">
                        {" "}
                        0800 772 0850{" "}
                      </a>{" "}
                    </strong>
                    to discuss your claim and know your options to get the maximum compensation.
                  </p>
                </div>
              </div>
              <div className="col-xxl-4 col-xl-4 col-lg-4 col-12">
                <div class="process">
                  <a class="tf-button style-1 phone" href="tel:0800 772 0850">
                    Get in touch<span class="icon-keyboard_arrow_right"></span>
                  </a>
                </div>
                <div class="process">
                  <a className="tf-button style-1 desktop" onClick={handleScrollToSidebar}>
                    Get in touch<span className="icon-keyboard_arrow_right"></span>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <search className="updatedform">
        <div className="container">
          <div className="row text-center">
            <div className="col-xxl-12 col-xl-12 col-md-12 col-12">
              <h2 className="whiteheading">
                {" "}
                Why choose us for your accident<br />
                <span className="light">management needs? </span>
              </h2>
              <p className="sustained">
                To get us started on your case, there is no cost charged for getting the best accident management solutions possible, and you can get back on track as soon as possible. Once we know about the road traffic accident, our committed specialists take over all dealings and handle everything until the end. To start a claim, call the{" "}<strong> Policywise accident claims number. </strong>
              </p>
            </div>
          </div>
          <div className="row">
            <div className="col-xxl-4 col-xl-4 col-lg-6 col-md-12 col-12">
              <div className="Claimant">
                <h5> Comprehensive services </h5>
                <p className="collision">
                  These include vehicle recovery, courtesy car provision and full claims management.
                </p>
                <h5>No excess to pay</h5>
                <p className="collision">
                  The expenses will be paid by the insurer of the driver who caused it. There is never any excess payable by you.{" "}
                </p>

                <h5>Rapid vehicle retrieval</h5>
                <p className="collision">
                  We are available 24/7 to assist with your needs regarding vehicle recovery immediately upon contacting us.
                </p>
              </div>
            </div>
            <div className="col-xxl-4 col-xl-4 col-lg-6 col-md-12 col-12">
              <div className="Claimant">
                <h5>High-Quality repairs</h5>
                <p className="collision">
                  We will ensure that your vehicle receives repairs of the highest standards using original parts.
                </p>
                <h5>Comparable  replacement vehicle</h5>
                <p className="collision">
                  We are going to provide an alternative vehicle close to your own in terms of make, model and size.
                </p>

                <h5>Quick claims handling</h5>
                <p className="collision">
                  Our process is designed to handle claims quickly without unnecessary delays.{" "}
                </p>
              </div>
            </div>
            <div className="col-xxl-4 col-xl-4 col-lg-12 col-md-12 col-12">
              <div className="Claimant">
                <h5>Experienced claims handler</h5>
                <p className="collision">
                  We shall appoint you a dedicated claims negotiator who will be your single point of contact during the claim process.
                </p>

                <h5>Expert negotiation</h5>
                <p className="collision">
                  We will speak with all insurers on your behalf so that you don’t have to deal with them yourselves.
                </p>

                <h5>Post repair assessment</h5>
                <p className="collision">
                  Once we have completed the repair of your car, we will conduct a post-repair inspection for quality assurance purposes.
                </p>
              </div>
            </div>
          </div>
        </div>
      </search>

      <section className="Accident-claim">
        <div className="container">
          <div className="row">
            <div className="needed">
              <h2 className="trusted-title">
                How to enhance the likelihood of <br />
                <span className="light"> getting fair compensation? </span>
              </h2>
              <p className="complexities">
                Road traffic accidents can be stressful and might leave you wondering how to handle unexpected costs. Here are a few steps you must follow to increase the chances of fair compensation.{" "}
              </p>
            </div>
            <hr />
          </div>
          <div className="row" id="incdes">
            <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-12">
              <div className="Preparing">
                <ul>
                  <li>
                    <div className="seemingly">
                      <h5 className="Seek">Examine the injuries:</h5>
                      <p>
                        {" "}
                        After moving away from the road, observe oneself and other travellers for any minor or significant injuries.{" "}
                      </p>
                    </div>
                  </li>
                  <li>
                    <div className="seemingly">
                      <h5 className="Seek">Get medical help: </h5>
                      <p>
                        Go to a medical professional immediately to attend to your injuries.
                      </p>
                    </div>
                  </li>
                  <li>
                    <div className="seemingly">
                      <h5 className="Seek">Seek emergency assistance:</h5>
                      <p>
                        Get in touch with us through our <strong> Policywise accident claim helpline </strong>for car accident claims so that we can help you get back on track as soon as possible.
                      </p>
                    </div>
                  </li>
                  <li>
                    <div className="seemingly">
                      <h5 className="Seek">Report the case to the police</h5>
                      <p>
                        Immediately report this case of a road traffic accident to the police, who will properly document it and take appropriate measures.
                      </p>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
            <div
              className="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-12"
              id="dualcardiv"
            >
              <img src={dualcar} alt="" className="dualcar" />
            </div>
            <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-12">
              <div className="Preparing">
                <ul>
                  <li>
                    <div className="seemingly">
                      <h5 className="Seek">Gather evidence:</h5>
                      <p>
                        Ensure that you gather vital evidence at the accident scene that will help prove your innocence. As you await for police’s arrival, collect essential pieces of evidence, including taking photos of the area in which the accident took place as well as motor vehicles involved and seek witnesses’ statements, if any.{" "}
                      </p>
                    </div>
                  </li>
                  <li>
                    <div className="seemingly">
                      <h5 className="Seek"> Photograph vehicle damages </h5>
                      <p>
                        Have photographs taken of your damaged vehicle before sending it for repair. This will be crucial in making sure that another party is liable for the accident.
                      </p>
                    </div>
                  </li>
                  <li>
                    <div className="seemingly">
                      <h5 className="Seek">Exchange details:</h5>
                      <p>
                        This includes exchanging names, contacts, insurance information and vehicle details with other drivers involved in the accident.{" "}
                      </p>
                    </div>
                  </li>
                  <li>
                    <div className="seemingly">
                      <h5 className="Seek">Make your claim</h5>
                      <p>
                        To get your claim started, give us a call on{" "}
                        <strong> <a href="tel:0800 772 0850">0800 772 0850</a> </strong>  You will be allocated a personal accident handler who will manage your entire claim from start to finish.
                      </p>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="whyus">
        <div className="container">
          <div className="row">
            <div className="col-xxl-12 col-md-12">
              <div className="why">
                <h2 className="choose">
                  Start your claim in {" "}
                  <span className="light">four easy steps</span>
                </h2>
                <p className="complexities">
                  A straightforward claims process for no-fault car accidents is designed to provide a quick resolution. Follow these simple steps to kick-start your Policywise motor insurance claims now. {" "}
                </p>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-xxl-3 col-xl-6 col-lg-6 col-md-6 col-12">
              <div className="box-service">
                <img src={check} className="check" alt="" />
                {/* <img src={experience} className="experience" alt="" /> */}
                <h4>Reach out</h4>
                <p>
                  Your claim starts by speaking with our team on the{" "}
                  <strong> claims phone number </strong><a href="tel:0800 772 0850">0800 772 0850</a>
                </p>
              </div>
            </div>
            <div className="col-xxl-3 col-xl-6 col-lg-6 col-md-6 col-12">
              <div className="box-service">
                <img src={check} className="check" alt="" />
                <h4>Provide  information </h4>
                <p>
                  Share information, including the accident date, time, location, weather conditions, plus the reason behind the collision.
                </p>
              </div>
            </div>
            <div className="col-xxl-3 col-xl-6 col-lg-6 col-md-6 col-12">
              <div className="box-service">
                <img src={check} className="check" alt="" />
                <h4>Claim evaluation</h4>
                <p>
                  We shall review the details of your {" "}<strong> Policywise car insurance claim </strong>and determine its validity.
                </p>
              </div>
            </div>
            <div className="col-xxl-3 col-xl-6 col-lg-6 col-md-6 col-12">
              <div className="box-service">
                <img src={check} className="check" alt="" />
                <h4>Wait for compensation</h4>
                <p>
                  You will be compensated accordingly after the examination and settlement of your claim.{" "}
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="faqs">
        <div className="container">
          <div className="row">
            <div className="col-xxl-12 col-xl-12 col-md-12 col-12">
              <div className="fq">
                <h2>
                  {" "}
                  Frequently Asked{" "}
                  <span className="light">Questions (FAQ)</span>
                </h2>
                <p className="complexities">
                  Got questions about the {" "}
                  <strong> Policywise insurance accident claim? </strong> We have them answered here.
                </p>
              </div>
              <div className="allfaq">
                <Accordion defaultActiveKey="0">
                  <Accordion.Item eventKey="0">
                    <Accordion.Header>
                      Can I make a claim for minor injuries that occurred as a result of the accident?
                    </Accordion.Header>
                    <Accordion.Body>
                      Absolutely. Minor injuries like bruises and scrapes, minor fractures or dislocations can enable you to take legal action. Our personal injury experts will evaluate your condition and inform you if it is eligible for compensation claims.
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="1">
                    <Accordion.Header>
                      Is there an option to use my preferred garage for vehicle repairs?
                    </Accordion.Header>
                    <Accordion.Body>
                      Certainly. You may provide us with information about your chosen garage so that the repair of your car can be scheduled at the desired place.
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="2">
                    <Accordion.Header>
                      What if my car cannot move after an accident not caused by me?
                    </Accordion.Header>
                    <Accordion.Body>
                      We shall look into arranging the vehicle recovery in cases where your car is no longer mobile after an accident not caused by you. Call us via the <strong> Policywise car accident claims helpline </strong> right away, and they will get it collected quickly from the accident scene.
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="3">
                    <Accordion.Header>
                      How long can you park my vehicle in your secure yards?{" "}
                    </Accordion.Header>
                    <Accordion.Body>
                      We will keep your vehicles for you until yours is fixed or until your claim gets settled.
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="4">
                    <Accordion.Header>
                      Will my no-fault claim affect my accident premium?
                    </Accordion.Header>
                    <Accordion.Body>
                      No, we don’t make a claim against your own insurance policy. Therefore, your insurance premium and no claim bonus cannot be affected.
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="5">
                    <Accordion.Header>
                      What if my car is written off?{" "}
                    </Accordion.Header>
                    <Accordion.Body>
                      If your car is totally written off, we will give you another one that you will have until the time you get full payment for the damaged one.{" "}
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="6">
                    <Accordion.Header>
                      Do I need to tell my insurer?{" "}
                    </Accordion.Header>
                    <Accordion.Body>
                      No, we shall do that for you. We shall contact your insurer and keep in touch with both of you throughout this process.
                    </Accordion.Body>
                  </Accordion.Item>
                </Accordion>
              </div>
            </div>
          </div>
        </div>
      </section>

      <Footer />
    </>
  );
};

export default Policywise;
