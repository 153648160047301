import React, { useRef } from "react";
import Accordion from "react-bootstrap/Accordion";
import check from "../assets/images/check.png";
import dualcar from "../../src/assets/images/dualcar.webp";
import liverpool from "../../src/assets/images/liverpool.webp";
import Sidebarinner from "../components/Sidebar-inner";
import phonecall from "../assets/images/phonecall.png";
import Footer from "../components/footer";
import { Helmet } from "react-helmet";

const Marshmallow = () => {
    const sidebarRef = useRef(null);

    const handleScrollToSidebar = () => {
        if (sidebarRef.current) {
            sidebarRef.current.scrollIntoView({ behavior: "smooth" });
        }
    };
    return (
        <>
        <Helmet>
        <meta charSet="utf-8" />
        <title>Marshmallow Car Insurance Accident Claim Phone Number</title>
        <meta
          name="description"
          content="Want to file a car insurance claim in the UK? Call us on the Marshmallow's car insurance claim number 0800 772 0850 for prompt and effective support. Contact us today. "
        />
        <link
          rel="canonical"
          href="https://insurance-accident-claims.co.uk/Marshmallow"
        />
      </Helmet>
            <section className="innernav">
                <div className="container">
                    <div className="row">
                        <div className="col-md-6 col-sm-6">
                            <div className="left-logo">
                                <h3>
                                    Accident<span className="sipo"> Claims</span>
                                </h3>
                            </div>
                        </div>
                        <div className="col-md-6 col-sm-6">
                            <div className="left-cta">
                                <div class="headercnt">
                                    <div class="chatmsg">
                                        <img
                                            src={phonecall}
                                            className="phonecall"
                                            alt="phonecall "
                                        />
                                    </div>
                                    <div class="box-body">
                                        <h3 class="nav-info-box-title">Toll Free Number </h3>
                                        <p>
                                            <a href="tel:0800 772 0850">0800 772 0850</a>
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section
                className="newbnr"
                style={{ backgroundImage: "url(" + liverpool + ")" }}
            >
                <div className="container">
                    <div className="row">
                        <div className="col-xxl-7 col-xl-7 col-md-10 col-12">
                            <div className="innerbnr">
                                <h1>
                                    <span className="onespan">No-fault accident </span>{" "}
                                    <span className="sci"> insurance claims </span> services
                                </h1>
                                <p className="brokerage">
                                    Are you in need of assistance after a no-fault road accident? Our Marshmallow insurance accident support services will take care of all your needs.
                                </p>
                                <div className="bnrbtn">
                                    <a class="db-btn-border btn-rollover phone" href="tel:0800 772 0850">
                                        Road Accident Claim Help?{" "}
                                        <i class="fa-solid fa-arrow-right"></i>
                                    </a>
                                </div>
                                <div className="bnrbtn">
                                    <a className="db-btn-border btn-rollover desktop" onClick={handleScrollToSidebar}>
                                        Road Accident Claim Help? <i className="fa-solid fa-arrow-right"></i>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="stickysection">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="group-4-8">
                                <div className="cl8 tf-tab">
                                    <div className="content-tab">
                                        <div className="inner-content">
                                            <h2>
                                                Your ultimate destination for inner peace following
                                                <br />
                                                <span className="light"> a car crash </span>{" "}
                                            </h2>
                                            <p className="disciplines">
                                                We are a top provider of vehicle collision management solutions whose aim is to minimise the complexity, stress and worries associated with car accidents. By dialling the number for <strong> Marshmallow accident claims, </strong> you can get back on track without any additional costs.
                                            </p>
                                            <hr />
                                            <p className="bold-text">No hidden costs</p>
                                            <p className="bottom-te">
                                                Calling our accident Number <strong>
                                                    {" "}
                                                    <a href="tel:0800 772 0850">0800 772 0850</a>{" "}
                                                </strong> for <strong> Marshmallow insurance accident support </strong>  means that you won’t pay anything at all. This implies that there is no need to worry about any unexpected or hidden charges as soon as you choose our
                                                <strong>Marshmallow insurance support </strong> services.                                        </p>
                                            <hr />
                                            <p className="bold-text">Zero insurance excess </p>
                                            <p className="bottom-te">
                                                When making a claim with your insurer, there may be an insurance excess charge. However, if partnering with us, you don’t have to pay any insurance excess, as we won’t make a claim against your own insurance policy.                                            </p>
                                            <hr />
                                            <p className="bold-text">
                                                No-claims discount remains unaffected
                                            </p>
                                            <p className="bottom-te">
                                                For any loss of your no-claim bonus, ring us via the <strong> Marshmallow claims contact number. </strong> Unlike your insurance company, we will ensure you don’t lose your hard-earned discount.                                       </p>
                                            <hr />
                                            <p className="bold-text">Reliable car repairs </p>
                                            <p className="bottom-te">
                                                We have joined hands with a vast network of pre-approved repairers who carry out dependable repairs involving genuine parts. You can call our car accident helpline
                                                <strong>
                                                    {" "}
                                                    <a href="tel:0800 772 0850">0800 772 0850</a>{" "}
                                                </strong>to access superior quality services in fixing your damaged vehicle.                                            </p>
                                            <hr />
                                            <p className="bold-text">Courtesy car at no extra cost</p>
                                            <p className="bottom-te">
                                                Place a call to the  <strong>Marshmallow accident claim phone number,</strong>
                                                and you will get a free courtesy vehicle for your journey back on the road. We will provide an alternate car with minimal delay.                                          </p>
                                            <hr />
                                            <p className="bold-text">Unstinting support</p>
                                            <p className="bottom-te">
                                                We shall walk alongside you throughout your claims process, ensuring you get the rightful compensation.                                             </p>
                                            <hr />
                                            <p className="bold-text">Professional advice</p>
                                            <p className="bottom-te">
                                                We are committed to ensuring the best possible guidance on your claim. When you contact us on our <strong> Marshmallow accident claims number, </strong>
                                                we will give you the right advice to enable you to get a higher compensation.
                                            </p>
                                            <hr />
                                            <p className="bold-text">Total transparency </p>
                                            <p className="bottom-te">
                                            We will let you know whatever is happening with your <strong>Marshmallow insurance accident claim </strong> throughout, keeping you updated about the progress at every step.                                          </p>
                                            <hr />
                                            <p className="bold-text">Quick settlement of claims </p>
                                            <p className="bottom-te">
                                            We always ensure that we speed up the process of settling claims for our clients. When you call us using the <strong>Marshmallow accident number,</strong>  we shall not take long before solving your case.                                          </p>
                                            <hr />

                                            <p className="bold-text">No fault specialist</p>
                                            <p className="bottom-te">
                                            One claims handler will be allocated to handle your case from inception until successful completion     </p>
                                        </div>
                                    </div>
                                </div>
                                <div className="cl4" ref={sidebarRef}>
                                    <Sidebarinner />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="photographs">
                <div className="container">
                    <div className="row">
                        <h2 className="road">
                        All-round claims assistance and   {" "}
                            <span className="light">
                                the <br />
                                accident management 
                            </span>{" "}
                        </h2>
                        <div className="col-xxl-6 col-xl-6 col-md-12 col-12">
                            <div className="fourbox">
                                <ul>
                                    <li>
                                        <img src={check} className="check" alt="" />
                                        Following a non-fault motor vehicle collision, we offer end-to-end assistance. Therefore, contacting us via this emergency line at 
                                        <strong>
                                            {" "}
                                            <a href="tel:0800 772 0850">0800 772 0850</a>{" "}
                                        </strong>is the first step for immediate help when an accident happens.


                                    </li>
                                    <hr />
                                    <li>
                                        <img src={check} className="check" alt="" />
                                        That being the case, we will make sure that we help you as much as possible and see to it that your vehicle is back on the road within a short period of time.                                    </li>
                                    <hr />
                                    <li>
                                        <img src={check} className="check" alt="" />
                                        Our dedicated team will consider your<strong>Marshmallow insurance claim  </strong>  if there are high chances of recovering any amount.                                   </li>
                                    <hr />
                                    <li>
                                        <img src={check} className="check" alt="" />
                                        Moreover, we shall provide you with a like-for-like replacement car to help you hit the road again.                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div className="col-xxl-6 col-xl-6 col-md-12 col-12">
                            <div className="fourbox">
                                <ul>
                                    <li>
                                        <img src={check} className="check" alt="" />
                                        We use manufacturer-approved parts for fixing damaged vehicles in our reputable and authorised repair centres, meaning your car will be repaired as per the manufacturers’ specifications.                                 </li>
                                    <hr />
                                    <li>
                                        <img src={check} className="check" alt="" />
                                        After repairing your vehicle successfully, we carry out quality checks on the repairs made to ensure they meet the highest standards.                                  </li>
                                    <hr />
                                    <li>
                                        <img src={check} className="check" alt="" />
                                        Call us about our accident support services, and we will compile all the necessary documents needed in order to start your claim process.                                   </li>
                                    <hr />
                                    <li>
                                        <img src={check} className="check" alt="" />
                                        Once your car is repaired, our team shall get in touch with you to arrange how it will safely be brought back home.                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="carprocess">
                <div className="container ">
                    <div className="row">
                        <div className="col-xxl-12 col-xxl-12 col-md-12 col-12">
                            <h2 className="trusted-title">
                                {" "}
                                Accident management services <br />
                                <span className="light"> we offer </span>{" "}
                            </h2>
                        </div>
                    </div>
                    <div className="row" id="possible">
                        <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-12">
                            <div className="claim">
                                <ul>
                                    <li>
                                        <span className="sleej">Car Recovery:</span>    When you call the car accident helpline on 
                                        <strong>
                                            {" "}
                                            <a href="tel:0800 772 0850">0800 772 0850</a>{" "}
                                        </strong> we will rapidly find ways of picking up your damaged vehicle from the scene of the accident. Our approved recovery agents take your car from an accident site to keep it safe in our yards.
                                    </li>
                                    <li>
                                        <span className="sleej">Vehicle repairs :</span>      We can arrange for prompt, flexible and high-quality repairs through our extensive network of reputable repairers. If you contact us through the <strong>Marshmallow accident claim phone number, </strong>  we will make sure that your vehicle is repaired properly, either at the nearest approved garage or as preferred by you.
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-12">
                            <div className="claim" id="blue">
                                <ul>
                                    <li>
                                        <span className="sleej">Vehicle storage:</span>    In case your motor vehicle has been damaged in a road traffic accident, it is important that it be moved to a secure place where no further damage can occur. To ensure safe storage of your vehicle, please phone our car accidents helpline on 
                                        <strong>
                                            {" "}
                                            <a href="tel:0800 772 0850">0800 772 0850</a>{" "}
                                        </strong>and ask about vehicle storage in our secure storage yards. Our recovery team would then go to the accident scene to collect your damaged vehicle.
                                    </li>
                                    <li>
                                        <span className="sleej">Replacement car:</span>  
                                        We offer a like-for-like replacement vehicle if you have an unroadworthy car. Call us on the <strong> Marshmallow claims contact number, </strong>  and we will provide you with a courtesy car until your car is road-worthy again. There’s no extra cost involved in giving you a credit hire car. Keep it till yours is completely fixed.
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-12">
                            <div className="claim">
                                <ul>
                                    <li>
                                        <span className="sleej">Personal injury claim support:</span>
                                        Our experienced solicitors have worked for years to maximise compensation awarded to victims of accidents that were not their fault. So, when you call us through the <strong> Marshmallow car insurance claim number, </strong>  we will assist you with your personal injury claim to ensure that you get maximum compensation.                                 </li>
                                    <li>
                                        <span className="sleej"> Claims management :</span>   We’ll take care of every aspect of your <strong> Marshmallow insurance accident claim, </strong>  from handling paperwork to comprehensive claims service provision.
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="yellowcta">
                <div className="container">
                    <div className="row">
                        <div className="cta2">
                            <div className="col-xxl-8 col-xl-8 col-lg-8 col-12">
                                <div className="legal">
                                    <h3 className="experienced">
                                    Have more questions? Speak  {" "}
                                        <span className="light">to our experts. 
                                        </span>{" "}
                                    </h3>
                                    <p className="professionals">
                                    We’re here to help you at every step of the claim process. Get in touch with our experts through the<strong>  Marshmallow  insurance accident claim number </strong> to get professional advice for your claim
                                    </p>
                                </div>
                            </div>
                            <div className="col-xxl-4 col-xl-4 col-lg-4 col-12">
                                <div class="process">
                                    <a class="tf-button style-1 phone" href="tel:0800 772 0850">
                                        Get in touch<span class="icon-keyboard_arrow_right"></span>
                                    </a>
                                </div>
                                <div class="process">
                                    <a className="tf-button style-1 desktop" onClick={handleScrollToSidebar}>
                                        Get in touch<span className="icon-keyboard_arrow_right"></span>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <search className="updatedform">
                <div className="container">
                    <div className="row text-center">
                        <div className="col-xxl-12 col-xl-12 col-md-12 col-12">
                            <h2 className="whiteheading">
                                {" "}
                                Advantages of Choosing Us as Your Accident
                                <span className="light"> Management Partner 
                                </span>
                            </h2>
                            <p className="sustained">
                            Instead of getting in touch with your insurer for settlement of undisputed claims, get to us via our claims contact line
                            <strong>
                                            {" "}
                                            <a href="tel:0800 772 0850">0800 772 0850</a>{" "}
                                        </strong>     We will give you better options and benefits than your own insurer. Additionally, selecting us will ensure smooth accident management without monetary constraints.
                                        </p>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-xxl-4 col-xl-4 col-lg-6 col-md-12 col-12">
                            <div className="Claimant">
                                <h5>Reduced costs </h5>
                                <p className="collision">
                                Our immediate assistance and effective claims process will allow you to settle your claim in minimum time, helping you save costs and time.

                                </p>

                                <h5>24/7 recovery </h5>
                                <p className="collision">
                                Our dedicated team is here at an instant notification to aid you urgently when a non-fault traffic collision happens. In case there is an accident, call us through the <strong> Marshmallow accident number </strong> to update us and acquire the necessary help.                                </p>

                                <h5>Top-notch repairs</h5>
                                <p className="collision">
                                We render service for optimal repairs available through our wide array of approved UK repairers.                              </p>
                            </div>
                        </div>
                        <div className="col-xxl-4 col-xl-4 col-lg-6 col-md-12 col-12">
                            <div className="Claimant">
                                <h5>Comprehensive accident management solutions
                                </h5>
                                <p className="collision">
                                Our services range from car repairs and storage facilities to leasing replacement cars and handling the entire process of making a claim. Thus, we are able to provide total accident management solutions. </p>
                                <h5>No obligation advice </h5>
                                <p className="collision">
                                We offer advice on claims for non-fault accidents without any pressure or requirement that you take legal action. Call our <strong> Marshmallow accident claim contact number </strong> if you want free advice.                             </p>

                                <h5>Efficient claims handling </h5>
                                <p className="collision">
                                Your no-fault compensation will be managed by us quickly and with little fuss. By working with us, you shall be updated frequently on the progress of your claim.
                                </p>
                            </div>
                        </div>
                        <div className="col-xxl-4 col-xl-4 col-lg-12 col-md-12 col-12">
                            <div className="Claimant">
                                <h5>Superfast claim settlement</h5>
                                <p className="collision">
                                We strive to speed up the <strong> Marshmallow insurance accident claims </strong> process. Reach our department fast through the <strong>  Marshmallow accident helpline </strong> so that your claim can be processed smoothly.                               </p>

                                <h5>Secure vehicle storage </h5>
                                <p className="collision">
                                We have secure yards where we keep your vehicle safe after an accident, minimising further damage. You may reach us anytime via 
                                <strong>
                                            {" "}
                                            <a href="tel:0800 772 0850">0800 772 0850</a>{" "}
                                        </strong>in order for your car to be stored in our well-maintained, safe yards.                           </p>

                                <h5>Experienced solicitors panel </h5>
                                <p className="collision">
                                We have a team of solicitors who are experienced in handling <strong> Marshmallow insurance accident claims.</strong> They will make sure you get the most out of your claim.                               </p>
                            </div>
                        </div>
                    </div>
                </div>
            </search>

            <section className="Accident-claim">
                <div className="container">
                    <div className="row">
                        <div className="needed">
                            <h2 className="trusted-title">
                            How to win Maximum Compensation for<br />
                                <span className="light"> Your No-Fault Claim </span>
                            </h2>
                            <p className="complexities">
                            Do you want maximum compensation for no fault? Do you want help on how to increase payout on no-fault road traffic accidents? Here is what needs to be done if one has been involved in a non-fault car accident and wants maximum compensation. 
                            </p>
                        </div>
                        <hr />
                    </div>
                    <div className="row" id="incdes">
                        <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-12">
                            <div className="Preparing">
                                <ul>
                                    <li>
                                        <div className="seemingly">
                                            <h5 className="Seek">Never leave the scene of the crash:</h5>
                                            <p>
                                                {" "}
                                                Running away from an accident scene post-car collision might lead to severe consequences. Therefore, park your car in a safe place.                                        </p>
                                        </div>
                                    </li>
                                    <li>
                                        <div className="seemingly">
                                            <h5 className="Seek">
                                            Report car crash:{" "}
                                            </h5>
                                            <p>
                                            Report the accident immediately to the police. Call us on accident claims helpline 
                                            <strong>
                                            {" "}
                                            <a href="tel:0800 772 0850">0800 772 0850</a>{" "}
                                        </strong>  and we will provide <strong> Marshmallow insurance accident </strong> support services                                          </p>
                                        </div>
                                    </li>
                                    <li>
                                        <div className="seemingly">
                                            <h5 className="Seek">Gather all evidence related to the car accident:</h5>
                                            <p>
                                            If you do not have enough evidence, proving innocence can become very difficult. So collect all necessary things like photos or videos of the accident scene as well as injuries caused by it, eyewitness statements, CCTV tapes and a copy of the police report too.                                           </p>
                                        </div>
                                    </li>
                                    <li>
                                        <div className="seemingly">
                                            <h5 className="Seek">See a doctor.:</h5>
                                            <p>
                                            If there are any injuries that you or your passengers have suffered, you should see a doctor to treat the wounds immediately.                                          </p>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div
                            className="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-12"
                            id="dualcardiv"
                        >
                            <img src={dualcar} alt="" className="dualcar" />
                        </div>
                        <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-12">
                            <div className="Preparing">
                                <ul>
                                    <li>
                                        <div className="seemingly">
                                            <h5 className="Seek">Appraise car damages:</h5>
                                            <p>
                                            Just start by checking if anything has been damaged around your vehicle and call us on our helpline number 
                                            <strong>
                                            {" "}
                                            <a href="tel:0800 772 0850">0800 772 0850</a>{" "}
                                        </strong> for your car to be recovered at a safe place.                                              </p>
                                        </div>
                                    </li>
                                    <li>
                                        <div className="seemingly">
                                            <h5 className="Seek">Record all losses: </h5>
                                            <p>
                                            Record all expenses that you make after an accident, including medical bills, transportation costs, repairing the car or replacement of its parts.                                             </p>
                                        </div>
                                    </li>
                                    <li>
                                        <div className="seemingly">
                                            <h5 className="Seek">
                                            Don’t take their initial offer.:
                                            </h5>
                                            <p>
                                            The insurer might approach you for early settlement. Do not entertain such an idea as it will lead to surrendering further rights to claim compensation.
                                            </p>
                                        </div>
                                    </li>
                                    <li>
                                        <div className="seemingly">
                                            <h5 className="Seek">
                                            File your claim:
                                            </h5>
                                            <p>
                                            Please call us on the <strong>Marshmallow claims number </strong>  to make a <strong>  Marshmallow insurance accident claim </strong>and get professional advice from our solicitors. 

                                            </p>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="whyus">
                <div className="container">
                    <div className="row">
                        <div className="col-xxl-12 col-md-12">
                            <div className="why">
                                <h2 className="choose">
                                Effortless process of making a  {" "}
                                    <span className="light">Marshmallow insurance accident claim </span>
                                </h2>
                                <p className="complexities">
                                To begin initiating the Marshmallow insurance accident claim process with our help, please contact us via our claims phone line <strong>
                                            {" "}
                                            <a href="tel:0800 772 0850">0800 772 0850</a>{" "}
                                        </strong>in order to start off your claim.
                                        </p>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-xxl-3 col-xl-6 col-lg-6 col-md-6 col-12">
                            <div className="box-service">
                                <img src={check} className="check" alt="" />
                                {/* <img src={experience} className="experience" alt="" /> */}
                                <h4>Make a call to us.</h4>
                                <p>
                                Call the <strong> Marshmallow accident helpline </strong>  to report a road traffic accident and make your claim for compensation for damages you have suffered. </p>
                            </div>
                        </div>
                        <div className="col-xxl-3 col-xl-6 col-lg-6 col-md-6 col-12">
                            <div className="box-service">
                                <img src={check} className="check" alt="" />
                                <h4>Furnish details of the accident</h4>
                                <p>
                                Tell our experts about everything concerning the collision, including when it occurred, where it happened, what caused it or any other crucial detail that will help your case.</p>
                            </div>
                        </div>
                        <div className="col-xxl-3 col-xl-6 col-lg-6 col-md-6 col-12">
                            <div className="box-service">
                                <img src={check} className="check" alt="" />
                                <h4>Submit evidence and documents</h4>
                                <p>
                                Provide all required evidence from the scene of an accident, such as videos and photos, vehicle details of those involved in the crash, statements from witnesses etc.                               </p>
                            </div>
                        </div>
                        <div className="col-xxl-3 col-xl-6 col-lg-6 col-md-6 col-12">
                            <div className="box-service">
                                <img src={check} className="check" alt="" />
                                <h4>Claim initiation </h4>
                                <p>
                                Once we gather all relevant documents, we will initiate your <strong> Marshmallow insurance accident claim </strong>  and keep you updated about the progres
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="faqs">
                <div className="container">
                    <div className="row">
                        <div className="col-xxl-12 col-xl-12 col-md-12 col-12">
                            <div className="fq">
                                <h2>
                                    {" "}
                                    Frequently Asked{" "}
                                    <span className="light">Questions</span>
                                </h2>
                                <p className="complexities">
                                    Got questions about the <strong> Marshmallow car insurance claims?  </strong> We have them answered here.
                                </p>
                            </div>
                            <div className="allfaq">
                                <Accordion defaultActiveKey="0">
                                    <Accordion.Item eventKey="0">
                                        <Accordion.Header>
                                        Will using your services for accident management affect my no-claims bonus?                                     </Accordion.Header>
                                        <Accordion.Body>
                                        No. We shall reinstate your no-claim bonus because we will be recovering all our expenses from the insurer of the at-fault party. This will enable you to safeguard your earned discounts.                                       </Accordion.Body>
                                    </Accordion.Item>
                                    <Accordion.Item eventKey="1">
                                        <Accordion.Header>
                                        Why should I select your company for my accident management needs?
                                        </Accordion.Header>
                                        <Accordion.Body>
                                        Dealing with road accidents on one’s own can be a harrowing experience. We can save you from unwanted stress by taking away all the pressure and burdens that come along with it. This entails everything, starting from vehicle recovery to replacement repairs, as well as claims management, without any additional costs or hidden charges.For immediate help in cases of motor accidents, call our claims helpline
                                        <strong>
                                            {" "}
                                            <a href="tel:0800 772 0850">0800 772 0850</a>{" "}
                                        </strong>                                </Accordion.Body>
                                    </Accordion.Item>
                                    <Accordion.Item eventKey="2">
                                        <Accordion.Header>
                                        Do I have to pay extra fees for the accident management support?
                                        </Accordion.Header>
                                        <Accordion.Body>
                                        No, nothing will be charged since we will recover all such expenses from the insurer of the at-fault driver.                                      </Accordion.Body>
                                    </Accordion.Item>
                                    <Accordion.Item eventKey="3">
                                        <Accordion.Header>
                                        How long does it take to have my car recovered after an accident scene?
                                        </Accordion.Header>
                                        <Accordion.Body>
                                        The time in which your damaged vehicle is taken back depends on several factors, like how severe the crash was, where it took place or some legal formalities which must be undertaken before your car is retrieved. We will try our level best to ensure that we get your car recovered as quickly as possible.                              </Accordion.Body>
                                    </Accordion.Item>
                                    <Accordion.Item eventKey="4">
                                        <Accordion.Header>
                                        Can I choose my own repairer?

                                        </Accordion.Header>
                                        <Accordion.Body>
                                        Absolutely! We offer you total autonomy when it comes to selecting who repairs your automobile. Just tell us which garage is likely going to satisfy all your desires, and we shall coordinate everything for you so that your vehicle gets quick repairs at your preferred repair centre.                                     </Accordion.Body>
                                    </Accordion.Item>
                                    <Accordion.Item eventKey="5">
                                        <Accordion.Header>
                                        Will the replacement car be similar to mine?{" "}
                                        </Accordion.Header>
                                        <Accordion.Body>
                                        We will make sure that you get a car which is like yours in case it becomes unroadworthy. You can retain that vehicle until your own gets repaired.                                 </Accordion.Body>
                                    </Accordion.Item>
                                    <Accordion.Item eventKey="6">
                                        <Accordion.Header>
                                        What types of losses and damages may be claimed following a non-at-fault accident?{" "}
                                        </Accordion.Header>
                                        <Accordion.Body>
                                        All losses and damages caused by the non-at-fault accident are compensable. These include medical costs, transportation expenses, compensation for both mental and emotional suffering, as well as vehicle repair or replacement bills, lost wages or other sundry charges.
                                        </Accordion.Body>
                                    </Accordion.Item>
                                </Accordion>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <Footer />
        </>
    );
};

export default Marshmallow;
