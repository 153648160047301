import React, { useRef } from "react";
import insurancelogo from "../../src/assets/images/insurancelogo.png";
import Accordion from "react-bootstrap/Accordion";
import check from "../assets/images/check.png";
import dualcar from "../../src/assets/images/dualcar.webp";
import carbnr1 from "../../src/assets/images/carbnr1.webp";
import Sidebarinner from "../components/Sidebar-inner";
import phonecall from "../assets/images/phonecall.png";
import Footer from "../components/footer";
import { Helmet } from "react-helmet";

const DirectLine = () => {
  const sidebarRef = useRef(null);

  const handleScrollToSidebar = () => {
    if (sidebarRef.current) {
      sidebarRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Direct Line Claims Number | Direct Line Car Insurance Claims</title>
        <meta
          name="description"
          content="Want to get no-obligation claim advice? Contact us on the Direct Line Car Insurance Accident Claim Phone Number 0800 772 0850 to discuss your claim with our solicitors. "
        />
        <link
          rel="canonical"
          href="https://insurance-accident-claims.co.uk/Direct-Line "
        />
      </Helmet>
      <section className="innernav">
        <div className="container">
          <div className="row">
            <div className="col-md-6 col-sm-6">
              <div className="left-logo">
                {/* <img src={insurancelogo} className='insurancelogo' alt='Insurance Logo' /> */}
                <h3>Accident Claims</h3>
              </div>
            </div>
            <div className="col-md-6 col-sm-6">
              <div className="left-cta">
                <div class="headercnt">
                  <div class="chatmsg">
                    <img
                      src={phonecall}
                      className="phonecall"
                      alt="phonecall "
                    />
                  </div>
                  <div class="box-body">
                    <h3 class="nav-info-box-title">Toll Free Number </h3>
                    <p>
                      <a href="tel:0800 772 0850">0800 772 0850</a>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section
        className="newbnr"
        style={{ backgroundImage: "url(" + carbnr1 + ")" }}
      >
        <div className="container">
          <div className="row">
            <div className="col-xxl-8 col-xl-8 col-md-10 col-12">
              <div className="innerbnr">
                <h1>
                  <span className="onespan"> Your Trusted Accident</span>{" "}
                  <span className="sci"> insurance claims </span> Partner In The UK
                </h1>
                <p className="brokerage">
                  From vehicle recovery to claims management, we specialise in a
                  complete spectrum of accident management support services in
                  the UK.
                </p>

                <div className="bnrbtn">
                  <a
                    class="db-btn-border btn-rollover phone"
                    href="tel:0800 772 0850"
                  >
                    Start your claim <i class="fa-solid fa-arrow-right"></i>
                  </a>
                </div>
                <div className="bnrbtn">
                  <a
                    className="db-btn-border btn-rollover desktop"
                    onClick={handleScrollToSidebar}
                  >
                    Start your claim <i className="fa-solid fa-arrow-right"></i>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="stickysection">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="group-4-8">
                <div className="cl8 tf-tab">
                  <div className="content-tab">
                    <div className="inner-content">
                      <h2>
                        {" "}
                        Hassle-free claim <br />
                        <span className="light">without any excess </span>
                      </h2>
                      <p className="disciplines">
                        Have you been involved in a no-fault car accident? Fret
                        not. We're here to offer comprehensive
                        <strong> insurance support services</strong> that take
                        the hassle out of the process and keep your costs down.
                        Reach out to us on the
                        <strong>
                          {" "}
                          number <a href="tel:0800 772 0850">0800 772 0850</a>,
                        </strong>{" "}
                        and we will work in your best interest to secure maximum
                        compensation for you.
                      </p>
                      <hr />
                      <p className="bold-text">Policy unaffected </p>
                      <p className="bottom-te">
                        When you contact us on the{" "}
                        <strong>Direct Line claims contact number</strong>,
                        you’re not just getting accident management services but
                        also the invaluable benefit of an unaffected insurance
                        policy. Your insurance premiums won’t be affected as we
                        won’t initiate any claim against your insurance policy.
                      </p>
                      <hr />
                      <p className="bold-text">No excess</p>
                      <p className="bottom-te">
                        When you make your claim with us, you don’t have to pay
                        any excess costs out of your pocket. We will recover all
                        the expenses from the fault party’s insurance company,
                        leaving you with no financial burden. Contact us on the
                        car insurance claim number{" "}
                        <a href="tel:0800 772 0850">0800 772 0850</a> to start
                        your claim with us.
                      </p>
                      <hr />
                      <p className="bold-text">
                        No claims bonus stays protected
                      </p>
                      <p className="bottom-te">
                        We will safeguard your hard-earned discounts and
                        insurance premium rewards. So, when you call us at the{" "}
                        <strong>Direct Line car accident helpline </strong>to
                        get{" "}
                        <strong>Direct Line insurance accident support</strong>,
                        you can have complete peace of mind knowing that your
                        financial rewards will stay intact.
                      </p>
                      <hr />
                      <p className="bold-text">Instant accidental repairs </p>
                      <p className="bottom-te">
                        We provide you with a full-fledged solution for no-fault
                        accident repairs while saving your insurance rights.
                        Call us on the{" "}
                        <strong>
                          accident claim phone number{" "}
                          <a href="tel:0800 772 0850">0800 772 0850</a>{" "}
                        </strong>
                        to get instant repairs of your damaged vehicle at no
                        cost.
                      </p>
                      <hr />
                      <p className="bold-text">Quick recovery </p>
                      <p className="bottom-te">
                        We’re always on hand to provide you with top-notch
                        vehicle assistance. Our dedicated team is available
                        round the clock to ensure instant recovery of your
                        vehicle from the accident scene, preventing further
                        damage.
                      </p>
                      <hr />
                      <p className="bold-text">
                        Comparable replacement vehicle{" "}
                      </p>
                      <p className="bottom-te">
                        Our premium assistance will provide you with a
                        like-for-like replacement vehicle at no cost. You can
                        reach out to us via{" "}
                        <strong>
                          the Direct Line accident claims helpline{" "}
                        </strong>
                        to get a temporary vehicle to continue performing your
                        daily tasks.
                      </p>
                      <hr />
                      <p className="bold-text">Quick resolution</p>
                      <p className="bottom-te">
                        We strive to settle your claim in the minimum possible
                        time. So, when you call the Direct Line accident claims
                        number to start your claim, you don’t have to deal with
                        lengthy follow-ups or tedious paperwork. We will help
                        you get back on the road as soon as possible.
                      </p>
                      <hr />
                      <p className="bold-text">Friendly and supportive team </p>
                      <p className="bottom-te">
                        When you call us on the{" "}
                        <strong>Direct Line accident number </strong>to begin
                        your claim with us, we will remove the stress and
                        anxiety out of the claims process. Our dedicated and
                        supportive team will provide you with a well-informed
                        and transparent service to ensure that your claim is
                        settled in a timely manner.
                      </p>
                      <hr />
                      <p className="bold-text">Professional advice</p>
                      <p className="bottom-te">
                        Call us at{" "}
                        <strong>the Direct Line claims contact number</strong>{" "}
                        to get expert advice from our experienced solicitors for
                        your{" "}
                        <strong>Direct Line insurance accident claim</strong>.
                        Our solicitors will provide the best advice and
                        understanding of the options available to pursue your
                        claim.
                      </p>
                      <hr />
                      <p className="bold-text">Dedicated claims handler </p>
                      <p className="bottom-te">
                        Reach out to us via{" "}
                        <strong>
                          the Direct Line car insurance claim number{" "}
                        </strong>
                        to start your claim with us. We will provide you with a
                        dedicated claims handler who will oversee each and every
                        aspect of your claim and offer personalised guidance at
                        every step.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="cl4" ref={sidebarRef}>
                  <Sidebarinner />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="photographs">
        <div className="container">
          <div className="row">
            <h2 className="road">
              Quick roadside assistance to <br />
              <span className="light">
                {" "}
                get you back on the road safely
              </span>{" "}
            </h2>
            <div className="col-xxl-6 col-xl-6 col-md-12 col-12">
              <div className="fourbox">
                <ul>
                  <li>
                    <img src={check} className="check" alt="" />
                    At <strong>Direct Line insurance accident claims</strong>,
                    we restore your peace of mind after a no-fault accident by
                    providing full-fledged
                    <strong>
                      Direct Line insurance accident support services.{" "}
                    </strong>
                  </li>
                  <hr />
                  <li>
                    <img src={check} className="check" alt="" />
                    When you reach out to us via the{" "}
                    <strong>
                      car accident helpline at{" "}
                      <a href="tel:0800 772 0850">0800 772 0850</a>
                    </strong>
                    , we will provide a courtesy car to keep you mobile while
                    your vehicle is getting repaired.
                  </li>
                  <hr />
                  <li>
                    <img src={check} className="check" alt="" />
                    Contact us via{" "}
                    <strong>
                      the Direct Line accident claim phone number{" "}
                    </strong>
                    for transparent accident evaluation and expert guidance
                    throughout the claim process.
                  </li>
                  <hr />
                  <li>
                    <img src={check} className="check" alt="" />
                    From recovering your policy excess to managing complex
                    personal injury claims, we will support you at every step of
                    the way.
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-xxl-6 col-xl-6 col-md-12 col-12">
              <div className="fourbox">
                <ul>
                  <li>
                    <img src={check} className="check" alt="" />
                    We will ensure quick repairs of your vehicle through a
                    network of manufacturer-approved body shops.
                  </li>
                  <hr />
                  <li>
                    <img src={check} className="check" alt="" />
                    When you contact us via{" "}
                    <strong>the Direct Line accident claims helpline</strong>,
                    we will manage your{" "}
                    <strong>Direct Line insurance accident claim </strong>from
                    start to finish and serve as a liaison between all the
                    parties involved to ensure a hassle-free process.
                  </li>
                  <hr />
                  <li>
                    <img src={check} className="check" alt="" />
                    We proactively recover uninsured losses, including insurance
                    costs and courtesy car related expenses from the at-fault
                    party’s insurance company.
                  </li>
                  <hr />
                  <li>
                    <img src={check} className="check" alt="" />
                    Upon successful completion of your vehicle repairs, we will
                    reach out to you to arrange the collection of your vehicle.
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="carprocess">
        <div className="container ">
          <div className="row">
            <div className="col-xxl-12 col-xxl-12 col-md-12 col-12">
              <h2 className="trusted-title">
                Full-fledged insurance accident support <br />
                <span className="light">from collision to recovery </span>{" "}
              </h2>
            </div>
          </div>
          <div className="row" id="possible">
            <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-12">
              <div className="claim">
                <ul>
                  <li>
                    <span className="sleej">Vehicle recovery</span> <br />
                    Get in touch with us at accident claims number{" "}
                    <a href="tel:0800 772 0850">0800 772 0850</a> to get your
                    vehicle recovered from the accident scene without a claim on
                    your insurance. Our dedicated team will send reliable and
                    trusted recovery agents to pick up the crashed car from the
                    accident scene.
                  </li>
                  <li>
                    <span className="sleej">Accident repairs</span>
                    <br />
                    Our friendly team is always by your side to ensure prompt
                    repairs of your damaged vehicle from a trusted repair
                    network. We work with a vast network of carefully vetted
                    repairers who offer high-quality vehicle repairs in the UK.
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-12">
              <div className="claim" id="blue">
                <ul>
                  <li>
                    <span className="sleej">Safe vehicle storage</span> <br />
                    We have a well-maintained storage facility to store your
                    unroadworthy vehicle until it is being repaired or you
                    receive compensation for your vehicle repairs. You can get
                    in touch with us via the{" "}
                    <strong>
                      accident number{" "}
                      <a href="tel:0800 772 0850">0800 772 0850</a>{" "}
                    </strong>
                    to ensure hassle-free recovery and storage of your damaged
                    vehicle in our storage yards.
                  </li>
                  <li>
                    <span className="sleej">
                      Like-for-like replacement vehicle{" "}
                    </span>{" "}
                    <br />
                    We will help you get back on the road after the no-fault
                    accident by providing a like-for-like replacement vehicle at
                    no extra cost. You can keep the replacement vehicle with you
                    until your car is fully repaired or receive full payment for
                    the written-off value of your damaged vehicle. You can call
                    us at the <strong>
                      Direct Line claims contact number
                    </strong>{" "}
                    to get a like-for-like replacement vehicle that suits your
                    needs.
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-12">
              <div className="claim">
                <ul>
                  <li>
                    <span className="sleej">Personal injury claim </span>
                    <br />
                    Reach out to us via the{" "}
                    <strong>Direct Line car accident helpline </strong>to get
                    compensation for injuries sustained in a no-fault car
                    accident. Our experienced solicitors will provide you with
                    the best options and outcomes for your{" "}
                    <strong>Direct Line insurance accident claims</strong> based
                    on the severity of your injuries.
                  </li>
                  <li>
                    <span className="sleej">Accident claim management </span>{" "}
                    <br />
                    We will provide you with the best accident management
                    solutions to secure maximum compensation for your injuries
                    and losses. Get in touch with us at the Direct Line accident
                    claims helpline to get expert and strategic guidance
                    throughout your claim process.
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="yellowcta">
        <div className="container">
          <div className="row">
            <div className="cta2">
              <div className="col-xxl-8 col-xl-8 col-lg-8 col-12">
                <div className="legal">
                  <h3 className="experienced">
                    {" "}
                    Have more questions?
                    <span className="light"> Contact our experts</span>{" "}
                  </h3>
                  <p className="professionals">
                    Call us at the{" "}
                    <strong>
                      claims contact number{" "}
                      <a href="tel:0800 772 0850">0800 772 0850</a>{" "}
                    </strong>
                    to get free no-obligation advice for your Direct Line
                    insurance accident claim from our experienced panel of
                    solicitors.
                  </p>
                </div>
              </div>
              <div className="col-xxl-4 col-xl-4 col-lg-4 col-12">
                <div class="process">
                  <a class="tf-button style-1 phone" href="tel:0800 772 0850">
                    Get in touch<span class="icon-keyboard_arrow_right"></span>
                  </a>
                </div>
                <div class="process">
                  <a
                    className="tf-button style-1 desktop"
                    onClick={handleScrollToSidebar}
                  >
                    Get in touch
                    <span className="icon-keyboard_arrow_right"></span>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <search className="updatedform">
        <div className="container">
          <div className="row text-center">
            <div className="col-xxl-12 col-xl-12 col-md-12 col-12">
              <h2 className="whiteheading">
                {" "}
                What makes us your go-to-solutione
                <span className="light"> for accident management</span>
              </h2>
              <p className="sustained">
                We’re here to save you from the hassles of dealing with the
                insurance company through a comprehensive range of{" "}
                <strong>Direct Line insurance accident support services</strong>
                . Also, we ensure you don't get ripped off by dishonest insurers
                who may leave you out of pocket. You can contact us at the
                accident number <a href="tel:0800 772 0850">0800 772 0850</a> to
                start your claim with us.
              </p>
            </div>
          </div>
          <div className="row">
            <div className="col-xxl-4 col-xl-4 col-lg-6 col-md-12 col-12">
              <div className="Claimant">
                <h5>No cost to you</h5>
                <p className="collision">
                  We don’t charge any upfront charges for our accident
                  management services. We will recover all the costs from the
                  at-fault party’s insurer, relieving you of the financial
                  stress.
                </p>
                <h5>24/7 support </h5>
                <p className="collision">
                  We have a dedicated team working round the clock to provide
                  you with emergency roadside assistance. Call us at the{" "}
                  <strong>Direct Line accident claims helpline </strong>to get
                  immediate help after a no-fault car acciden
                </p>
                <h5>End-to-end services</h5>
                <p className="collision">
                  We offer comprehensive{" "}
                  <strong>Direct Line insurance accident support </strong>
                  services from the moment the accident occurs until the claim
                  gets resolved.
                </p>
              </div>
            </div>
            <div className="col-xxl-4 col-xl-4 col-lg-6 col-md-12 col-12">
              <div className="Claimant">
                <h5>Same-day replacement vehicle</h5>
                <p className="collision">
                  We provide you with a same-day like-for-like replacement
                  vehicle so that you can continue with your daily routine
                  without disruptions.
                </p>
                <h5>Fully guaranteed repairs</h5>
                <p className="collision">
                  When you reach out to us via the{" "}
                  <strong>
                    accident claim phone number{" "}
                    <a href="tel:0800 772 0850">0800 772 0850</a>
                  </strong>{" "}
                  for vehicle repairs, we will ensure quick repairs using
                  genuine parts.
                </p>
                <h5>Professional advice and assistance </h5>
                <p className="collision">
                  We will arrange an experienced solicitor who will support you
                  throughout your claim process for recovery of expenses, lost
                  earnings and compensation.
                </p>
              </div>
            </div>
            <div className="col-xxl-4 col-xl-4 col-lg-12 col-md-12 col-12">
              <div className="Claimant">
                <h5>Experienced claim-handling experts </h5>
                <p className="collision">
                  When you contact us via the{" "}
                  <strong>Direct Line accident number </strong>to make a{" "}
                  <strong>Direct Line insurance accident claim</strong>, our
                  experienced handlers will give the best independent advice to
                  help you get maximum compensation.
                </p>
                <h5>No insurance excess</h5>
                <p className="collision">
                  Unlike your insurers, with us, you don’t have to pay any
                  insurance excess as we won’t make a claim on your own
                  insurance policy.
                </p>
                <h5>Speedy claim resolution</h5>
                <p className="collision">
                  We will ensure that your claim is managed in a diligent and
                  professional manner, resulting in a quick settlement of your
                  claim.
                </p>
              </div>
            </div>
          </div>
        </div>
      </search>

      <section className="Accident-claim">
        <div className="container">
          <div className="row">
            <div className="needed">
              <h2 className="trusted-title">
                How to increase your compensation
                <br />
                <span className="light">
                  {" "}
                  chances after a no-fault car accident{" "}
                </span>
              </h2>
              <p className="complexities">
                When you’re involved in a no-fault car accident, there are
                several steps that you must take to prove your innocence and
                maximise the chances of securing maximum compensation for your
                losses and injuries.
              </p>
            </div>
            <hr />
          </div>
          <div className="row" id="incdes">
            <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-12">
              <div className="Preparing">
                <ul>
                  <li>
                    <div className="seemingly">
                      <h5 className="Seek">Check injuries</h5>
                      <p>
                        {" "}
                        Stop your car at a safe place. Check yourself and your
                        passengers for any minor or major injuries and request
                        immediate medical attention if someone has sustained
                        injuries.
                      </p>
                    </div>
                  </li>
                  <li>
                    <div className="seemingly">
                      <h5 className="Seek">Report car accident </h5>
                      <p>
                        Whether you’re involved in a minor fender bender or a
                        major collision, you must immediately{" "}
                        <strong>report car accident </strong>to the police and
                        get a copy of the police report.
                      </p>
                    </div>
                  </li>
                  <li>
                    <div className="seemingly">
                      <h5 className="Seek">Document everything </h5>
                      <p>
                        {" "}
                        Write down all the essential information related to the
                        accident, such as the date, time, location and the
                        reason behind the accident. Also, make sure to take
                        photographs of the accident scene and damaged vehicle
                      </p>
                    </div>
                  </li>
                  <li>
                    <div className="seemingly">
                      <h5 className="Seek">Get medical treatment </h5>
                      <p>
                        Sometimes car accident symptoms may appear lately.
                        Therefore, make sure to visit the doctor shortly after
                        the accident to ensure prompt treatment and diagnosis of
                        the injury.
                      </p>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
            <div
              className="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-12"
              id="dualcardiv"
            >
              <img src={dualcar} alt="" className="dualcar" />
            </div>
            <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-12">
              <div className="Preparing">
                <ul>
                  <li>
                    <div className="seemingly">
                      <h5 className="Seek">Exchange information</h5>
                      <p>
                        After checking yourself for the injuries, exchange
                        contact and insurance information with the other driver.
                        This information must include name, contact information,
                        insurance policy number, driver’s licence number,
                        vehicle details and location of the accident.
                      </p>
                    </div>
                  </li>
                  <li>
                    <div className="seemingly">
                      <h5 className="Seek">Request vehicle recovery</h5>
                      <p>
                        Check the condition of your vehicle, and if it’s
                        unroadworthy, seek emergency help through the accident
                        claims helpline{" "}
                        <a href="tel:0800 772 0850">0800 772 0850</a>to get your
                        vehicle recovered from the accident spot.
                      </p>
                    </div>
                  </li>
                  <li>
                    <div className="seemingly">
                      <h5 className="Seek">Start your claim</h5>
                      <p>
                        A no-fault car accident can leave you with thousands of
                        pounds in vehicle damages, lost wages and mounting
                        medical bills. Therefore, you must immediately call the{" "}
                        <strong>Direct Line accident claims number </strong>to
                        reach out to the <strong>Direct Line claims </strong>
                        department and start your claims process for quick
                        recovery of your damages.
                      </p>
                    </div>
                  </li>
                  <li>
                    <div className="seemingly">
                      <h5 className="Seek">Don’t settle too early </h5>
                      <p>
                        A no-fault car accident can leave you with thousands of
                        pounds in vehicle damages, lost wages and mounting
                        medical bills. Therefore, you must immediately call the{" "}
                        <strong>Direct Line accident claims number</strong> to
                        reach out to the <strong>Direct Line claims </strong>
                        department and start your claims process for quick
                        recovery of your damages.
                      </p>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="whyus">
        <div className="container">
          <div className="row">
            <div className="col-xxl-12 col-md-12">
              <div className="why">
                <h2 className="choose">
                  How to start your Direct{" "}
                  <span className="light">
                    <br /> Line insurance accident claim?{" "}
                  </span>
                </h2>
                <p className="complexities">
                  We have a quick, hassle-free and straightforward claim process
                  to help you get compensation for your losses. To start your
                  claim with us, you can simply give us a call at{" "}
                  <strong>
                    claims contact number{" "}
                    <a href="tel:0800 772 0850">0800 772 0850</a>.
                  </strong>
                </p>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-xxl-3 col-xl-6 col-lg-6 col-md-6 col-12">
              <div className="box-service">
                <img src={check} className="check" alt="" />
                <h4>Contact us via the Direct line accident claims helpline</h4>
                <p>
                  Speak to our experts by giving a call on the{" "}
                  <strong>Direct Line accident claims </strong>number to report
                  a car accident and begin a stress-free claim process with us.
                </p>
              </div>
            </div>
            <div className="col-xxl-3 col-xl-6 col-lg-6 col-md-6 col-12">
              <div className="box-service">
                <img src={check} className="check" alt="" />
                <h4>Discuss your claim</h4>
                <p>
                  Share the accident details with us, including the date, time,
                  location and the reason for the accident. Our solicitors will
                  guide you about your rights and the options available to you.
                </p>
              </div>
            </div>
            <div className="col-xxl-3 col-xl-6 col-lg-6 col-md-6 col-12">
              <div className="box-service">
                <img src={check} className="check" alt="" />
                <h4>Share crucial evidence </h4>
                <p>
                  Provide us with all the vital information and evidence related
                  to the no-fault car accident, such as photographs, CCTV
                  videos, eyewitness statements, repair estimates and any other
                  information that may help us strengthen your case.
                </p>
              </div>
            </div>
            <div className="col-xxl-3 col-xl-6 col-lg-6 col-md-6 col-12">
              <div className="box-service">
                <img src={check} className="check" alt="" />
                <h4>Wait for your claim settlement </h4>
                <p>
                  After collecting all the required information, we will process
                  and initiate your{" "}
                  <strong>Direct Line insurance accident claim.</strong> After
                  your claim gets settled, you’ll get compensation for your
                  losses.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="faqs">
        <div className="container">
          <div className="row">
            <div className="col-xxl-12 col-xl-12 col-md-12 col-12">
              <div className="fq">
                <h2>
                  {" "}
                  Frequently Asked{" "}
                  <span className="light">Questions (FAQ)</span>
                </h2>
                <p className="complexities">
                  Got more questions about our no-fault Direct-Line insurance
                  accident support services. We have them answered below.{" "}
                </p>
              </div>
              <div className="allfaq">
                <Accordion defaultActiveKey="0">
                  <Accordion.Item eventKey="0">
                    <Accordion.Header>
                      Do I have to pay any excess for Direct Line insurance
                      accident claims?
                    </Accordion.Header>
                    <Accordion.Body>
                      No. When you choose to make your Direct Line insurance
                      accident claim with us, you don’t have to pay any excess
                      on your insurance policy. We will liaise with your
                      insurance company and recover all the expenses from the
                      third-party insurer.
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="1">
                    <Accordion.Header>
                      Can I get a credit-hire car if my car is completely
                      written off?
                    </Accordion.Header>
                    <Accordion.Body>
                      Yes. We will provide you with a like-for-like replacement
                      vehicle in the minimum possible time after the no-fault
                      car accident. Whether your car has minor damages or is
                      completely written off, you can call the Direct Line
                      accident number to get a free courtesy car.
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="2">
                    <Accordion.Header>
                      Can I get my vehicle repaired from the garage of my own
                      choice?
                    </Accordion.Header>
                    <Accordion.Body>
                      If you want your vehicle to be repaired by your preferred
                      repairer, we will arrange it for you. You can provide us
                      with the details of your repairer, and we will ensure that
                      your car is restored to top-notch condition as soon as
                      possible.
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="3">
                    <Accordion.Header>
                      Will your services affect my No-claims bonus?{" "}
                    </Accordion.Header>
                    <Accordion.Body>
                      No. With us, your no-fault bonus and insurance will stay
                      intact. We will recover all the costs from the at-fault
                      party’s insurer.
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="4">
                    <Accordion.Header>
                      Will the replacement vehicle you provide match my own
                      vehicle?
                    </Accordion.Header>
                    <Accordion.Body>
                      We will put in our best efforts to provide a courtesy car
                      that suits your needs. While your vehicle is getting
                      repaired, we will provide you with a comparable
                      replacement.
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="5">
                    <Accordion.Header>
                      How much compensation can I receive after a no-fault car
                      accident?
                    </Accordion.Header>
                    <Accordion.Body>
                      The amount of compensation you’re entitled to receive
                      after a no-fault accident depends on various factors, such
                      as the injuries or damages you’ve suffered, lost earnings,
                      and medical expenses. Our experienced solicitors will
                      assess your case and inform you of how much compensation
                      you’re entitled to receive.
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="6">
                    <Accordion.Header>
                      How long will it take for my Direct Line insurance
                      accident claim to get settled?
                    </Accordion.Header>
                    <Accordion.Body>
                      The timeframe for the settlement of your claim entirely
                      depends on the specifics of your claim. Straightforward
                      claims may get settled quickly, but complex claims may
                      take longer to settle. Our solicitors will support you
                      throughout the claim process to ensure that your claim is
                      settled quickly.
                    </Accordion.Body>
                  </Accordion.Item>
                </Accordion>
              </div>
            </div>
          </div>
        </div>
      </section>

      <Footer />
    </>
  );
};

export default DirectLine;
