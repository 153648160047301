import React, { useRef } from "react";
import Accordion from "react-bootstrap/Accordion";
import check from "../assets/images/check.png";
import dualcar from "../../src/assets/images/dualcar.webp";
import bner from "../../src/assets/images/bner.webp";
import Sidebarinner from "../components/Sidebar-inner";
import phonecall from "../assets/images/phonecall.png";
import Footer from "../components/footer";
import { Helmet } from "react-helmet";

const APlaninsurance = () => {
    const sidebarRef = useRef(null);

    const handleScrollToSidebar = () => {
        if (sidebarRef.current) {
            sidebarRef.current.scrollIntoView({ behavior: "smooth" });
        }
    };
    return (
        <>
        <Helmet>
        <meta charSet="utf-8" />
        <title>A-Plan Insurance Car Insurance Claim Phone Number</title>
        <meta
          name="description"
          content="Thinking of making a no-fault car accident claim? Call us on the A-Plan Insurance Car Insurance Claim Phone Number 0800 772 0850 for efficient and fast assistance."
        />
        <link
          rel="canonical"
          href="https://insurance-accident-claims.co.uk/A-Plan-insurance"
        />
      </Helmet>
            <section className="innernav">
                <div className="container">
                    <div className="row">
                        <div className="col-md-6 col-sm-6">
                            <div className="left-logo">
                                <h3>
                                    Accident<span className="sipo"> Claims</span>
                                </h3>
                            </div>
                        </div>
                        <div className="col-md-6 col-sm-6">
                            <div className="left-cta">
                                <div class="headercnt">
                                    <div class="chatmsg">
                                        <img
                                            src={phonecall}
                                            className="phonecall"
                                            alt="phonecall "
                                        />
                                    </div>
                                    <div class="box-body">
                                        <h3 class="nav-info-box-title">Toll Free Number </h3>
                                        <p>
                                            <a href="tel:0800 772 0850">0800 772 0850</a>
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section
                className="newbnr"
                style={{ backgroundImage: "url(" + bner + ")" }}
            >
                <div className="container">
                    <div className="row">
                        <div className="col-xxl-7 col-xl-7 col-md-10 col-12">
                            <div className="innerbnr">
                                <h1>
                                    <span className="onespan">Car Accident  </span>{" "}
                                    <span className="sci">management  </span>Experts 
                                </h1>
                                <p className="brokerage">
                                When you have an accident on the road that was not your fault, we will be there to provide emergency support and help with claims.

                                </p>
                                <div className="bnrbtn">
                                    <a class="db-btn-border btn-rollover phone" href="tel:0800 772 0850">
                                        Road Accident Claim Help?{" "}
                                        <i class="fa-solid fa-arrow-right"></i>
                                    </a>
                                </div>
                                <div className="bnrbtn">
                                    <a className="db-btn-border btn-rollover desktop" onClick={handleScrollToSidebar}>
                                        Road Accident Claim Help? <i className="fa-solid fa-arrow-right"></i>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="stickysection">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="group-4-8">
                                <div className="cl8 tf-tab">
                                    <div className="content-tab">
                                        <div className="inner-content">
                                            <h2>
                                            No-hassle claim handling 
                                                <br />
                                                <span className="light"> at no cost</span>{" "}
                                            </h2>
                                            <p className="disciplines">
                                            Our accident management services fully assist all victims involved in non-fault car accidents in the UK. To this end, our team of highly experienced solicitors is always ready to assist you as well as manage everything regarding 
                                             <strong> A-Plan car insurance claims </strong>   from initiation to settlement. If you need assistance with a claim on a free-of-charge basis, call us at  <strong>
                                            {" "}
                                            <a href="tel:0800 772 0850">0800 772 0850</a>{" "}
                                        </strong>   
                                            </p>
                                            <hr />
                                            <p className="bold-text">No excess</p>
                                            <p className="bottom-te">
                                            That means the other side’s insurer is footing the bill for our services, and you do not have to pay anything upfront.                                       </p>
                                            <hr />
                                            <p className="bold-text">No impact on no-claims bonus </p>
                                            <p className="bottom-te">
                                            The <strong> A-Plan insurance accident claim  </strong> won’t be made against your own insurance policy. Hence, there won't be any loss of your no-claims bonus and discount entitlements.                                         </p>
                                            <hr />
                                            <p className="bold-text">
                                            Dedicated professionals
                                            </p>
                                            <p className="bottom-te">
                                            We are experts in managing no-fault car accidents. Our solicitors work non-stop so that you can win the best possible result.                                          </p>
                                            <hr />
                                            <p className="bold-text">Full package  </p>
                                            <p className="bottom-te">
                                            From managing the complexities of your <strong>  A-Plan insurance claim </strong> to providing emergency help that will enable you to get back on the street, we have taken care of everything for you.                                             </p>
                                            <hr />
                                            <p className="bold-text">No win, no fee</p>
                                            <p className="bottom-te">
                                            If your <strong> A-Plan car insurance claim </strong>  fails, there is no obligation on your part to meet any legal costs.                                           </p>
                                            <hr />
                                            <p className="bold-text">Supportive and friendly team </p>
                                            <p className="bottom-te">
                                            Our friendly team guides you through the steps to make a successful <strong> A-Plan insurance claim </strong>  once you call us on the <strong> A-Plan car insurance claim number. </strong>                                  </p>
                                            <hr />
                                            <p className="bold-text">Dedicated claims handler </p>
                                            <p className="bottom-te">
                                            We will appoint an experienced claims manager who will work hard to provide you with an exceptional claims solution and recover all your costs from the guilty motorist within no time                                    </p>
                                            <hr />
                                            <p className="bold-text">Professional advice</p>
                                            <p className="bottom-te">
                                            We can assist you in obtaining no-obligation advice from experienced solicitors if you want to make an <strong> A-Plan car insurance claim </strong> so that they can inform you about your rights and choices.                                             </p>
                                            <hr />
                                            <p className="bold-text">Superior repairs service </p>
                                            <p className="bottom-te">
                                            Feel free to contact us on the <strong> A-Plan insurance claim  phone number  </strong>  so that we may organise quality repairs for your vehicle through our vast network of manufacturer-approved repairers using original parts and paint.                                      </p>
                                            <hr />

                                            <p className="bold-text">Comparable replacement vehicle </p>
                                            <p className="bottom-te">
                                            While your car is being repaired, we will offer you a similar replacement vehicle of the same size, brand and model.                                        </p>
                                        </div>
                                    </div>
                                </div>
                                <div className="cl4" ref={sidebarRef}>
                                    <Sidebarinner />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="photographs">
                <div className="container">
                    <div className="row">
                        <h2 className="road">
                        Sit back and recover while we handle  {" "}
                            <span className="light">
                            your<br />
                                claim’s intricacies
                            </span>{" "}
                        </h2>
                        <div className="col-xxl-6 col-xl-6 col-md-12 col-12">
                            <div className="fourbox">
                                <ul>
                                    <li>
                                        <img src={check} className="check" alt="" />
                                        From assessing damages to negotiating with insurers and handling the whole process from inception to settlement, we have got you covered every step of the way.  

                                    </li>
                                    <hr />
                                    <li>
                                        <img src={check} className="check" alt="" />
                                        When you phone us on the <strong> A-Plan insurance claims number, </strong>  our dedicated team will appreciate your needs and arrange for the best alternative car that can get you back on track again.                                 </li>
                                    <hr />
                                    <li>
                                        <img src={check} className="check" alt="" />
                                        We would liaise with repairers in the UK to book your vehicle at an agreed garage.                                 </li>
                                    <hr />
                                    <li>
                                        <img src={check} className="check" alt="" />
                                        If necessary, we will talk with all parties involved in the crash, thereby enabling you to concentrate on other important tasks.                                     </li>
                                </ul>
                            </div>
                        </div>
                        <div className="col-xxl-6 col-xl-6 col-md-12 col-12">
                            <div className="fourbox">
                                <ul>
                                    <li>
                                        <img src={check} className="check" alt="" />
                                        We have a panel of no-fault accident solicitors who are specialists in managing no-fault accidents. Therefore, you can be sure of getting good advice when you call us using the <strong>A-Plan car insurance claims contact number. </strong>                               </li>
                                    <hr />
                                    <li>
                                        <img src={check} className="check" alt="" />
                                        We’ll make sure that a private engineer inspects your car.                              </li>
                                    <hr />
                                    <li>
                                        <img src={check} className="check" alt="" />
                                        Please dial the<strong>A-Plan car insurance claim number  </strong> if you need us to arrange for the recovery of your damaged vehicle and deliver it back to you after its repair.                                  </li>
                                    <hr />
                                    <li>
                                        <img src={check} className="check" alt="" />
                                        We will support you in getting back all the costs paid by you which are related to the accident caused by another driver.                      </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="carprocess">
                <div className="container ">
                    <div className="row">
                        <div className="col-xxl-12 col-xxl-12 col-md-12 col-12">
                            <h2 className="trusted-title">
                                {" "}
                                Accident management services <br />
                                <span className="light"> we offer </span>{" "}
                            </h2>
                        </div>
                    </div>
                    <div className="row" id="possible">
                        <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-12">
                            <div className="claim">
                                <ul>
                                    <li>
                                        <span className="sleej">Car recovery :</span>  Has your vehicle become immobilised as a result of an accident? Contact us through the <strong>  A-Plan insurance claims contact number </strong> for prompt vehicle recovery. Our recovery personnel can collect your car from where it was involved in an accident and take it away safely. 
                                    </li>
                                    <li>
                                        <span className="sleej">Accidental repairs:</span>   Call us at the <strong> A-Plan car insurance claim number </strong> so we can arrange instant repairs in any approved garage of your choice. Our certified repairers will bring your car back to what it was before the incident, thereby enhancing its resale value.
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-12">
                            <div className="claim" id="blue">
                                <ul>
                                    <li>
                                        <span className="sleej">Vehicle storage:</span> If your vehicle isn’t drivable, it might not be safe to leave it at the roadside or outside your house. We will lessen your stress by storing the car in our storage yards, preventing any more damage or deterioration. Call us on our insurance claim phone number 
                                        <strong>
                                            {" "}
                                            <a href="tel:0800 772 0850">0800 772 0850</a>{" "}
                                        </strong>  for safe storage of your vehicle. 
                                    </li>
                                    <li>
                                        <span className="sleej">Vehicle replacement :</span> We know what it feels like when you are left without transport. Our team will get you back on track by giving you another vehicle like yours on the same day. You can keep the substitute car until your car gets fixed or your A-Plan car insurance claims have been compensated. All charges and expenses related to the replacement vehicle will be recouped from the culpable party's insurer.
                                         
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-12">
                            <div className="claim">
                                <ul>
                                    <li>
                                        <span className="sleej">Personal injury compensation claim  :</span>
                                        Receive expert advice and support on making a claim against the person responsible for your injuries and losses. Our solicitors would efficiently handle <strong> A-Plan Insurance claims, </strong>  ensuring you receive the best possible outcome for your claim.        </li>
                                    <li>
                                        <span className="sleej">Claim management  :</span>    We specialise in handling documents, evaluation of your claims and final settlement by providing a full range of the claim’s management services from the initiation of your A-Plan car insurance claim to settlement. 

                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="yellowcta">
                <div className="container">
                    <div className="row">
                        <div className="cta2">
                            <div className="col-xxl-8 col-xl-8 col-lg-8 col-12">
                                <div className="legal">
                                    <h3 className="experienced">
                                    Got more questions? Talk to  {" "}
                                        <span className="light">  our specialists 
                                        </span>{" "}
                                    </h3>
                                    <p className="professionals">
                                    Get in touch with our no-fault claim experts via <strong> car insurance claim number </strong>    
                                    <strong>
                                            {" "}
                                            <a href="tel:0800 772 0850">0800 772 0850</a>{" "}
                                        </strong> to get the best advice for your<strong>A-Plan car insurance claim. </strong>
                                    </p>
                                </div>
                            </div>
                            <div className="col-xxl-4 col-xl-4 col-lg-4 col-12">
                                <div class="process">
                                    <a class="tf-button style-1 phone" href="tel:0800 772 0850">
                                        Get in touch<span class="icon-keyboard_arrow_right"></span>
                                    </a>
                                </div>
                                <div class="process">
                                    <a className="tf-button style-1 desktop" onClick={handleScrollToSidebar}>
                                        Get in touch<span className="icon-keyboard_arrow_right"></span>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <search className="updatedform">
                <div className="container">
                    <div className="row text-center">
                        <div className="col-xxl-12 col-xl-12 col-md-12 col-12">
                            <h2 className="whiteheading">
                                {" "}
                                What makes us different
                                <span className="light"> from others?

                                </span>
                            </h2>
                            <p className="sustained">
                            We’re your one-stop shop for top-notch accident management solutions and claims assistance if you are victimised by a no-fault car accident in the United Kingdom. In case you need help with vehicle recovery, whether to rely on replacement cars or to go for legal advice on your rights and options, we will work out something good depending on what you want.                       </p>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-xxl-4 col-xl-4 col-lg-6 col-md-12 col-12">
                            <div className="Claimant">
                                <h5> No upfront costs</h5>
                                <p className="collision">
                                We do not charge any upfront fees when offering our services so that you can access emergency support at your convenience without any financial obligations.


                                </p>

                                <h5>Rapid response</h5>
                                <p className="collision">
                                Our team is always available 24/7 in order to provide timely support and resolutions regarding the issues of car accidents.                             </p>

                                <h5>Manufacturer-approved repairers</h5>
                                <p className="collision">
                                Through our partnerships with different UK-based repair centres, we can make sure that your vehicle gets repaired through professionals who comply with manufacturer specifications and use genuine parts as well as paint to restore your vehicle regardless of its condition.                                 </p>
                            </div>
                        </div>
                        <div className="col-xxl-4 col-xl-4 col-lg-6 col-md-12 col-12">
                            <div className="Claimant">
                                <h5>Same-day replacement vehicle 
                                </h5>
                                <p className="collision">
                                Call us via the <strong> A-Plan car insurance claim number </strong>  to get a same-day replacement so that you can continue with your activities without much interruption. </p>
                                <h5>Claim management</h5>
                                <p className="collision">
                                We provide a full range of claims management services, from opening an <strong> A-Plan car insurance claim </strong>  to the end part of document handling, assessment and final settlement. 
                                </p>

                                <h5>24/7 recovery </h5>
                                <p className="collision">
                                There is no time you can’t find our vehicle recovery agents to help you with all your vehicle recovery needs. 
                                </p>
                            </div>
                        </div>
                        <div className="col-xxl-4 col-xl-4 col-lg-12 col-md-12 col-12">
                            <div className="Claimant">
                                <h5>No obligation advice </h5>
                                <p className="collision">
                                Call the <strong> A-Plan insurance claims number </strong>  for free consultation on your rights and choices to pursue a claim without obligation.                       </p>

                                <h5>Experienced solicitors </h5>
                                <p className="collision">
                                Our solicitors have enormous experience in handling no-fault car accident claims. They will give the best direction needed for this matter.                     </p>

                                <h5> Personalised advice </h5>
                                <p className="collision">
                                Following an evaluation of details surrounding your <strong> A-Plan car insurance claim, </strong>  we will provide personalised guidance as to what would be the most suitable way forward for a more favourable outcome.                              </p>
                            </div>
                        </div>
                    </div>
                </div>
            </search>

            <section className="Accident-claim">
                <div className="container">
                    <div className="row">
                        <div className="needed">
                            <h2 className="trusted-title">
                            How can maximum compensation be obtained<br />
                                <span className="light">for your A-Plan insurance claim? 
                                </span>
                            </h2>
                            <p className="complexities">
                            Car accidents disrupt one’s life and cause immense distress. The major step towards recovering from its effects is obtaining compensation that addresses the losses and injuries suffered due to such an accident. However, there are procedures which must be followed to enhance the settlement amount, thereby increasing one’s compensation value.
                            </p>
                        </div>
                        <hr />
                    </div>
                    <div className="row" id="incdes">
                        <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-12">
                            <div className="Preparing">
                                <ul>
                                    <li>
                                        <div className="seemingly">
                                            <h5 className="Seek">Seek medical attention:</h5>
                                            <p>
                                                {" "}
                                                Seek immediate medical attention to ensure you get a proper diagnosis and treatment for your injuries, as well as build up important records that will make your <strong> A-Plan insurance claim </strong> stronger.                                          </p>
                                        </div>
                                    </li>
                                    <li>
                                        <div className="seemingly">
                                            <h5 className="Seek">
                                            Take note of the damage:{" "}
                                            </h5>
                                            <p>
                                            Record all expenses related to your claims like medical bills, prescription costs, fare costs, legal fees and other costs which have been incurred.                                        </p>
                                        </div>
                                    </li>
                                    <li>
                                        <div className="seemingly">
                                            <h5 className="Seek">Gather proof:</h5>
                                            <p>
                                            To strengthen your claim, take photographs and videos of what happened at the scene, gather eyewitnesses’ statements, if any are available, try accessing any CCTV footage from the scene and finally obtain a copy of the police report.                                          </p>
                                        </div>
                                    </li>
                                    <li>
                                        <div className="seemingly">
                                            <h5 className="Seek">Avoid quick settlement:</h5>
                                            <p>
                                            Do not accept the initial settlement offer quickly but rather consult with your solicitor so that it can compensate you fairly based on losses incurred by you or even injuries sustained.                          </p>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div
                            className="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-12"
                            id="dualcardiv"
                        >
                            <img src={dualcar} alt="" className="dualcar" />
                        </div>
                        <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-12">
                            <div className="Preparing">
                                <ul>
                                    <li>
                                        <div className="seemingly">
                                            <h5 className="Seek">Do not use social media :</h5>
                                            <p>
                                            While waiting for the resolution of the <strong> A-Plan insurance claim </strong> it is better to stop using social media. The posts contain information which can be used by another party to bring down what they should pay for compensation.                    </p>
                                        </div>
                                    </li>
                                    <li>
                                        <div className="seemingly">
                                            <h5 className="Seek">Exchange information: </h5>
                                            <p>
                                            Exchange relevant information with other parties involved in an accident, such as names, contacts, car identity numbers and particulars, plus insurance information.                                            </p>
                                        </div>
                                    </li>
                                    <li>
                                        <div className="seemingly">
                                            <h5 className="Seek">
                                            Hire a no-fault compensation solicitor:
                                            </h5>
                                            <p>
                                            You can get expert help by contacting us on this number regarding your claim as well as to avail of the professional services of a seasoned non-negligence claim specialist who will ensure that you receive maximum compensation.
                                            </p>
                                        </div>
                                    </li>
                                    <li>
                                        <div className="seemingly">
                                            <h5 className="Seek">
                                            Start your claim :
                                            </h5>
                                            <p>
                                            Dial our number after making sure you have gathered everything, and call on<strong> A-Plan car insurance claim number </strong>  to process your claim. 

                                            </p>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="whyus">
                <div className="container">
                    <div className="row">
                        <div className="col-xxl-12 col-md-12">
                            <div className="why">
                                <h2 className="choose">
                                Our easy and hassle-free   {" "}
                                    <span className="light"> claims process  </span>
                                </h2>
                                <p className="complexities">
                                When you make an A-Plan car insurance claim with us, there is no long wait or complicated process. It’s just about filling in 4 simple steps with our smooth and swift approach.
                                    
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-xxl-3 col-xl-6 col-lg-6 col-md-6 col-12">
                            <div className="box-service">
                                <img src={check} className="check" alt="" />
                                {/* <img src={experience} className="experience" alt="" /> */}
                                <h4>Contact us </h4>
                                <p>
                                Please phone our solicitors for free consultation at any one time through our insurance claim phone number 
                                <strong>
                                            {" "}
                                            <a href="tel:0800 772 0850">0800 772 0850</a>{" "}
                                        </strong> </p>
                            </div>
                        </div>
                        <div className="col-xxl-3 col-xl-6 col-lg-6 col-md-6 col-12">
                            <div className="box-service">
                                <img src={check} className="check" alt="" />
                                <h4>Tell us about the accident details </h4>
                                <p>
                                Right from when the accident happened to where it happened and how it occurred, share every single bit of information with us. </p>
                            </div>
                        </div>
                        <div className="col-xxl-3 col-xl-6 col-lg-6 col-md-6 col-12">
                            <div className="box-service">
                                <img src={check} className="check" alt="" />
                                <h4>Submit proofs</h4>
                                <p>
                                Share any relevant photos or videos shot on the scene, and attach copies of police reports and witness statements, among other important documents, to support your case.         </p>
                            </div>
                        </div>
                        <div className="col-xxl-3 col-xl-6 col-lg-6 col-md-6 col-12">
                            <div className="box-service">
                                <img src={check} className="check" alt="" />
                                <h4>Claim submission </h4>
                                <p>
                                Your <strong> A-Plan car insurance claim </strong>  will be submitted as soon as we have all the supporting evidence and documentation necessary to resolve it.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="faqs">
                <div className="container">
                    <div className="row">
                        <div className="col-xxl-12 col-xl-12 col-md-12 col-12">
                            <div className="fq">
                                <h2>
                                    {" "}
                                    Frequently Asked{" "}
                                    <span className="light">Questions</span>
                                </h2>
                                <p className="complexities">
                                    Got questions about the <strong>A-Plan car insurance claim?</strong> We have them answered here.
                                </p>
                            </div>
                            <div className="allfaq">
                                <Accordion defaultActiveKey="0">
                                    <Accordion.Item eventKey="0">
                                        <Accordion.Header>
                                        Do I have to pay extra charges?                                   </Accordion.Header>
                                        <Accordion.Body>
                                        No, you don't need to pay additional costs for the services since we will recover everything from the other driver's insurer.                                  </Accordion.Body>
                                    </Accordion.Item>
                                    <Accordion.Item eventKey="1">
                                        <Accordion.Header>
                                        Can I select my own repairer?
                                        </Accordion.Header>
                                        <Accordion.Body>
                                        Yes, certainly. You can choose your own repairer. If you prefer that option, we will arrange for your vehicle repairs at the centre of your choice.                           </Accordion.Body>
                                    </Accordion.Item>
                                    <Accordion.Item eventKey="2">
                                        <Accordion.Header>
                                        Will my no-claims bonus be affected if I use your services?
                                        </Accordion.Header>
                                        <Accordion.Body>
                                        No. Our expert claim handlers will make a direct claim against the third party, leaving your no-claims bonus intact along with unchanged insurance premiums.                                </Accordion.Body>
                                    </Accordion.Item>
                                    <Accordion.Item eventKey="3">
                                        <Accordion.Header>
                                        How long can I keep the replacement vehicle? 
                                        </Accordion.Header>
                                        <Accordion.Body>
                                        If, after being involved in an accident on the road, your vehicle is unroadworthy and undergoing repairs, you may retain the replacement vehicle until your vehicle repairs are done.             </Accordion.Body>
                                    </Accordion.Item>
                                    <Accordion.Item eventKey="4">
                                        <Accordion.Header>
                                        Will the replacement vehicle be identical to my vehicle? 

                                        </Accordion.Header>
                                        <Accordion.Body>
                                        No matter what kind of car you have, we strive to provide a like-for-like replacement vehicle that will work for you. Once you’ve phoned us on the <strong>  A-Plan car insurance claim number, </strong> we shall supply an alternative car in the shortest possible time.                                      </Accordion.Body>
                                    </Accordion.Item>
                                    <Accordion.Item eventKey="5">
                                        <Accordion.Header>
                                        Are the repairs guaranteed?{" "}
                                        </Accordion.Header>
                                        <Accordion.Body>
                                        Yes. Our manufacturer-approved repairers strive to provide guaranteed and quality repairs that exceed manufacturer specifications.                                 </Accordion.Body>
                                    </Accordion.Item>
                                    <Accordion.Item eventKey="6">
                                        <Accordion.Header>
                                        Can I recover all the costs I incurred due to the accident?{" "}
                                        </Accordion.Header>
                                        <Accordion.Body>
                                        We can help you recover any expenses or losses incurred as a result of a car accident, such as medical bills, transportation fees, rental car charges, car repair charges, lost salaries and wages, compensation for suffering, and property damage, among other out-of-pocket costs.
                                        </Accordion.Body>
                                    </Accordion.Item>
                                </Accordion>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <Footer />
        </>
    );
};

export default APlaninsurance;
