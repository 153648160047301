import React, { useRef } from "react";
import insurancelogo from "../../src/assets/images/insurancelogo.png";
import Accordion from "react-bootstrap/Accordion";
import check from "../assets/images/check.png";
import dualcar from "../../src/assets/images/dualcar.webp";
import carbnr1 from "../../src/assets/images/carbnr1.webp";
import Sidebarinner from "../components/Sidebar-inner";
import phonecall from "../assets/images/phonecall.png";
import Footer from "../components/footer";
import { Helmet } from "react-helmet";

const Nissaninsuranceservices = () => {
    const sidebarRef = useRef(null);

    const handleScrollToSidebar = () => {
        if (sidebarRef.current) {
            sidebarRef.current.scrollIntoView({ behavior: "smooth" });
        }
    };
    return (
        <>
            <Helmet>
                <meta charSet="utf-8" />
                <title>Nissan Car Insurance Accident Claim Phone Number</title>
                <meta
                    name="description"
                    content="Our dedicated team is available 24/7 to provide full-fledged claims assistance. Contact us on the Nissan Car Insurance Accident Claim Phone Number 0800 772 0850 today. "
                />
                <link
                    rel="canonical"
                    href="https://insurance-accident-claims.co.uk/Nissan-insurance-services"
                />
            </Helmet>
            <section className="innernav">
                <div className="container">
                    <div className="row">
                        <div className="col-md-6 col-sm-6">
                            <div className="left-logo">
                                {/* <img src={insurancelogo} className='insurancelogo' alt='Insurance Logo' /> */}
                                <h3>Accident Claims</h3>
                            </div>
                        </div>
                        <div className="col-md-6 col-sm-6">
                            <div className="left-cta">
                                <div class="headercnt">
                                    <div class="chatmsg">
                                        <img
                                            src={phonecall}
                                            className="phonecall"
                                            alt="phonecall "
                                        />
                                    </div>
                                    <div class="box-body">
                                        <h3 class="nav-info-box-title">Toll Free Number </h3>
                                        <p>
                                            <a href="tel:0800 772 0850">0800 772 0850</a>
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section
                className="newbnr"
                style={{ backgroundImage: "url(" + carbnr1 + ")" }}
            >
                <div className="container">
                    <div className="row">
                        <div className="col-xxl-8 col-xl-8 col-md-10 col-12">
                            <div className="innerbnr">
                                <h1>
                                    <span className="onespan">We’re experts in managing </span>{" "}
                                    <span className="sci">road traffic </span> accident claims.
                                </h1>
                                <p className="brokerage">
                                    In the event that you are involved in a road accident which is not your fault, we will be there to provide emergency assistance and assistance with claims.
                                </p>
                                <div className="bnrbtn">
                                    <a
                                        class="db-btn-border btn-rollover phone"
                                        href="tel:0800 772 0850"
                                    >
                                        Road Accident Claim Help?{" "}
                                        <i class="fa-solid fa-arrow-right"></i>
                                    </a>
                                </div>
                                <div className="bnrbtn">
                                    <a
                                        className="db-btn-border btn-rollover desktop"
                                        onClick={handleScrollToSidebar}
                                    >
                                        Road Accident Claim Help?{" "}
                                        <i className="fa-solid fa-arrow-right"></i>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="stickysection">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="group-4-8">
                                <div className="cl8 tf-tab">
                                    <div className="content-tab">
                                        <div className="inner-content">
                                            <h2>
                                                {" "}
                                                Hassle-free claim management
                                                <span className="light"> at no cost </span>
                                            </h2>
                                            <p className="disciplines">
                                                We fully support non-fault accident drivers in the UK through our accident management services. Consequently, our team of highly qualified solicitors can provide you with expert advice on the <strong> Nissan car insurance claims. </strong>  For no-cost claim assistance, call us at
                                                <strong>
                                                    {" "}
                                                    <a href="tel:0800 772 0850">0800 772 0850</a>{" "}
                                                </strong>
                                            </p>
                                            <hr />
                                            <p className="bold-text">No excess liability</p>
                                            <p className="bottom-te">
                                                This means that the at-fault party will pay for our services, and you will not be required to make any upfront payments.
                                            </p>
                                            <hr />
                                            <p className="bold-text">Personal bonus undamaged </p>
                                            <p className="bottom-te">
                                                We won’t make a claim against your own insurance policy. So your no-claim bonus won’t be affected.
                                            </p>
                                            <hr />
                                            <p className="bold-text">Experienced solicitors   </p>
                                            <p className="bottom-te">
                                                We have mastered how to handle accidents without faults involved. Our solicitors work around the clock so that you can emerge with nothing less than the best outcome possible.
                                            </p>
                                            <hr />
                                            <p className="bold-text">Complete solution </p>
                                            <p className="bottom-te">
                                                Everything has been taken care of for you – from handling complex issues surrounding your <strong> Nissan insurance claim </strong>  to providing immediate support that would allow you to drive out again.
                                            </p>
                                            <hr />
                                            <p className="bold-text">No win, no fee.</p>
                                            <p className="bottom-te">
                                                If your <strong>  Nissan car insurance claim </strong>  is unsuccessful, you do not have to pay any legal costs.
                                            </p>
                                            <hr />
                                            <p className="bold-text">Supportive and friendly team</p>
                                            <p className="bottom-te">
                                                Once you call us on the <strong> Nissan car insurance claim number, </strong> our team will assist you through the process of making a successful Nissan insurance claim.
                                            </p>
                                            <hr />
                                            <p className="bold-text">Dedicated claims handler</p>
                                            <p className="bottom-te">
                                                We will hire an experienced claims manager who will work to give you an excellent claims solution and recover all your costs from the guilty party.
                                            </p>
                                            <hr />
                                            <p className="bold-text">Professional advice</p>
                                            <p className="bottom-te">
                                                If you want to make a <strong> Nissan car insurance claim, </strong>we can help you find competent solicitors who can offer free advice that will acquaint you with your rights and options.
                                            </p>
                                            <hr />
                                            <p className="bold-text">Superior repairs service</p>
                                            <p className="bottom-te">
                                                Just dial the <strong> Nissan insurance claim phone number </strong>  so that we can arrange quality repairs on your vehicle using original spare parts and paintwork sourced from our broad base of manufacturer-approved repairers.
                                            </p>
                                            <hr />
                                            <p className="bold-text">Comparable replacement vehicle</p>
                                            <p className="bottom-te">
                                                While your vehicle is being repaired, we shall provide you with another one in the same size, brand and model.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div className="cl4" ref={sidebarRef}>
                                    <Sidebarinner />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="photographs">
                <div className="container">
                    <div className="row">
                        <h2 className="road">
                            While we handle all the intricacies of your claim,<br />
                            <span className="light">
                                {" "}
                                you can focus on your recovery {" "}
                            </span>{" "}
                        </h2>
                        <div className="col-xxl-6 col-xl-6 col-md-12 col-12">
                            <div className="fourbox">
                                <ul>
                                    <li>
                                        <img src={check} className="check" alt="" />
                                        From appraising damages to bargaining with insurers and taking care of everything from beginning to finishing, we’ve got all grounds covered for a smooth settlement of your claim.
                                    </li>
                                    <hr />
                                    <li>
                                        <img src={check} className="check" alt="" />
                                        Call us at the <strong>  Nissan insurance claims number, </strong> and our dedicated team will understand your requirements and provide a substitute vehicle that can take you back to the road again.
                                    </li>
                                    <hr />
                                    <li>
                                        <img src={check} className="check" alt="" />
                                        We will make arrangements with repairers across the UK to book your car in a manufacturer-approved garage.
                                    </li>
                                    <hr />
                                    <li>
                                        <img src={check} className="check" alt="" />
                                        We will negotiate directly on your behalf with all the parties so that you can focus on other important duties.
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div className="col-xxl-6 col-xl-6 col-md-12 col-12">
                            <div className="fourbox">
                                <ul>
                                    <li>
                                        <img src={check} className="check" alt="" />
                                        Besides having experts who specialise in no-fault accidents, we have a list of no-fault accident solicitors to choose from. Therefore, calling us via the <strong> Nissan car insurance claims contact number </strong>  will guarantee that you receive sound advice.
                                    </li>
                                    <hr />
                                    <li>
                                        <img src={check} className="check" alt="" />
                                        We will arrange for an independent engineer to assess your vehicle's damages.
                                    </li>
                                    <hr />
                                    <li>
                                        <img src={check} className="check" alt="" />
                                        If you require assistance in arranging the collection of your repaired vehicle, please dial the <strong> Nissan car insurance claim number.</strong>
                                    </li>
                                    <hr />
                                    <li>
                                        <img src={check} className="check" alt="" />
                                        We will help you recover all costs incurred by you due to an accident from the at-fault party’s insurer.

                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="carprocess">
                <div className="container ">
                    <div className="row">
                        <div className="col-xxl-12 col-xxl-12 col-md-12 col-12">
                            <h2 className="trusted-title">
                                Our accident management  <br />
                                <span className="light">services  </span>
                            </h2>
                        </div>
                    </div>
                    <div className="row" id="possible">
                        <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-12">
                            <div className="claim">
                                <ul>
                                    <li>
                                        <span className="sleej">Car recovery </span> <br />
                                        In case your car has been rendered immobile as a result of any accident, you can get it recovered by calling us on the <strong> Nissan insurance claims contact number.</strong>  Our recovery team will pick up your vehicle from the accident scene into our storage yards.
                                    </li>
                                    <li>
                                        <span className="sleej">Approved repairs </span>
                                        <br />
                                        Once you call us using the <strong> Nissan car insurance claim number, </strong>  we will help in setting quick repairs with an approved garage of your own choice. This will make your vehicle resale price go up because our certified repairers will restore its initial condition before the accident.
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-12">
                            <div className="claim" id="blue">
                                <ul>
                                    <li>
                                        <span className="sleej">Vehicle storage</span> <br />
                                        If you are not able to drive your vehicle, it may be unsafe to leave it at the side of the road or outside of your home. We will place the car in our storage yards so that no further harm or deterioration occurs. For safekeeping of your car, give us a call on our phone number
                                        <br></br>
                                        <strong>
                                            {" "}
                                            <a href="tel:0800 772 0850">0800 772 0850</a>{" "}
                                        </strong>
                                    </li>
                                    <li>
                                        <span className="sleej">Vehicle replacement </span> <br />
                                        We understand how one feels when left without a means of transport. Our team will provide an identical vehicle to yours within 24 hours to get you back into gear. You can keep the other car until yours is fixed or until your claim is settled. All costs associated with renting a replacement vehicle are recoverable from the responsible party’s insurance company.

                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-12">
                            <div className="claim">
                                <ul>
                                    <li>
                                        <span className="sleej">Personal injury compensation claim</span>
                                        <br />
                                        Receive expert advice and support on making a claim against the person responsible for your injuries and losses. Our solicitors would efficiently handle the <strong> Nissan car insurance claim, </strong> ensuring that you get the best possible result in your claim.
                                    </li>
                                    <li>
                                        <span className="sleej">
                                            Claim management{" "}
                                        </span>{" "}
                                        <br />
                                        We specialise in handling paperwork, evaluating your claims and ensuring its quick settlement. Contact us on the <strong> Nissan car insurance claim number </strong> to initiate your claim.
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="yellowcta">
                <div className="container">
                    <div className="row">
                        <div className="cta2">
                            <div className="col-xxl-8 col-xl-8 col-lg-8 col-12">
                                <div className="legal">
                                    <h3 className="experienced">
                                        Got more questions?{" "}
                                        <span className="light">Talk to our specialists </span>
                                    </h3>
                                    <p className="professionals">
                                        Get in touch with our no-fault claim experts via <strong>car
                                            insurance contact number </strong>   <strong>
                                            {" "}
                                            <a href="tel:0800 772 0850">0800 772 0850</a>{" "}
                                        </strong> to get the best advice for your
                                        <strong> Nissan car insurance claim.  </strong>.

                                    </p>
                                </div>
                            </div>
                            <div className="col-xxl-4 col-xl-4 col-lg-4 col-12">
                                <div class="process">
                                    <a class="tf-button style-1 phone" href="tel:0800 772 0850">
                                        Get in touch<span class="icon-keyboard_arrow_right"></span>
                                    </a>
                                </div>
                                <div class="process">
                                    <a
                                        className="tf-button style-1 desktop"
                                        onClick={handleScrollToSidebar}
                                    >
                                        Get in touch
                                        <span className="icon-keyboard_arrow_right"></span>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <search className="updatedform">
                <div className="container">
                    <div className="row text-center">
                        <div className="col-xxl-12 col-xl-12 col-md-12 col-12">
                            <h2 className="whiteheading">
                                What makes us different from
                                <span className="light"> others</span>
                            </h2>
                            <p className="sustained">
                                We’re your one-stop shop for top-notch accident management solutions and claims assistance if you are victimised by a no-fault car accident in the United Kingdom. If you require any help concerning vehicle recovery, replacement cars or getting legal advice about your rights and options, we have got you covered for all your needs.
                            </p>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-xxl-4 col-xl-4 col-lg-6 col-md-12 col-12">
                            <div className="Claimant">
                                <h5>No upfront costs</h5>
                                <p className="collision">
                                    This means that there is no fee payable for our accident management services. You can access emergency assistance anytime without obligations.
                                </p>
                                <h5>Quick response </h5>
                                <p className="collision">
                                    We are available 24/7 at your service to handle your car accident issues.
                                </p>
                                <h5>Approved Repairers</h5>
                                <p className="collision">
                                    Our association with a variety of UK-based repair centres makes it possible for all cars to be fixed by professionals who adhere strictly to the manufacturer’s requirements and use branded parts as well as paint in order to renovate damaged cars no matter their state.
                                </p>
                            </div>
                        </div>
                        <div className="col-xxl-4 col-xl-4 col-lg-6 col-md-12 col-12">
                            <div className="Claimant">
                                <h5>Same-day replacement car</h5>
                                <p className="collision">
                                    Just call us using the Nissan car insurance claim number, and we will get you another vehicle on the same day so that you can continue with your activities without interruption.
                                </p>
                                <h5>Efficient claim management</h5>
                                <p className="collision">
                                    From vehicle recovery to your Nissan car insurance claim settlement, we offer a comprehensive range of claims management services.
                                </p>
                                <h5>24-hour recovery  </h5>
                                <p className="collision">
                                    There is no moment when our team will not be available for those unfortunate times when you might need help recovering your car.
                                </p>
                            </div>
                        </div>
                        <div className="col-xxl-4 col-xl-4 col-lg-12 col-md-12 col-12">
                            <div className="Claimant">
                                <h5>Free Advice </h5>
                                <p className="collision">
                                    Dial Nissan insurance claims number for experts and to know your rights or choices without any obligation to proceed further.
                                </p>
                                <h5>Experienced accident solicitors</h5>
                                <p className="collision">
                                    Our solicitors have vast experience dealing with clients’ road traffic collisions and can help you secure maximum compensation.
                                </p>
                                <h5>Personal advice</h5>
                                <p className="collision">
                                    Our dedicated solicitors will provide you with customised recommendations on how to achieve a better outcome for the <strong> Nissan car insurance claim. </strong>
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </search>

            <section className="Accident-claim">
                <div className="container">
                    <div className="row">
                        <div className="needed">
                            <h2 className="trusted-title">
                                What is the best way to get maximum compensation for
                                <br />
                                <span className="light"> a Nissan insurance claim? </span>
                            </h2>
                            <p className="complexities">
                                Car accidents disrupt one’s life and cause immense distress. The first step towards mitigating their effects is getting compensated for the losses and injuries resulting from such an accident. Nonetheless, there are steps that can be followed to increase the settlement amount, thus heightening your compensation value.
                            </p>
                        </div>
                        <hr />
                    </div>
                    <div className="row" id="incdes">
                        <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-12">
                            <div className="Preparing">
                                <ul>
                                    <li>
                                        <div className="seemingly">
                                            <h5 className="Seek">Look for medical help</h5>
                                            <p>
                                                Go for immediate medical attention in order to receive proper diagnoses and treatment for your injuries, as well as build up valuable records that will boost the strength of your <strong> Nissan insurance claim. </strong>
                                            </p>
                                        </div>
                                    </li>
                                    <li>
                                        <div className="seemingly">
                                            <h5 className="Seek">Keep track of damage</h5>
                                            <p>
                                                Write down all costs involved in your claims, like doctor’s bills, prescription charges, transportation fares, solicitors fees, plus other expenses incurred during that period.
                                            </p>
                                        </div>
                                    </li>
                                    <li>
                                        <div className="seemingly">
                                            <h5 className="Seek">Collect evidence </h5>
                                            <p>
                                                While at the accident scene, take pictures or videos of the incident, get statements from witnesses, check whether there is any CCTV footage and, finally, secure a copy of the police report.
                                            </p>
                                        </div>
                                    </li>
                                    <li>
                                        <div className="seemingly">
                                            <h5 className="Seek">Avoid settling quickly  </h5>
                                            <p>
                                                Do not take the first offer hastily. Instead, consult your solicitor so that it can compensate you fairly based on the losses incurred by you.
                                            </p>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div
                            className="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-12"
                            id="dualcardiv"
                        >
                            <img src={dualcar} alt="" className="dualcar" />
                        </div>
                        <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-12">
                            <div className="Preparing">
                                <ul>
                                    <li>
                                        <div className="seemingly">
                                            <h5 className="Seek">Avoid using social media </h5>
                                            <p>
                                                While waiting for the resolution of the claim, it is good to refrain from using social media. Social media posts can be used against another party in order to bring down what they should pay as compensation.
                                            </p>
                                        </div>
                                    </li>
                                    <li>
                                        <div className="seemingly">
                                            <h5 className="Seek">Exchange relevant information</h5>
                                            <p>
                                                Swap names, addresses, car registration numbers and details plus insurance details with other parties involved in an accident.
                                            </p>
                                        </div>
                                    </li>
                                    <li>
                                        <div className="seemingly">
                                            <h5 className="Seek">Get a no-fault compensation solicitor </h5>
                                            <p>
                                                You can contact us to receive professional services from a well-trained non-negligence claim expert who will guarantee maximum compensation.
                                            </p>
                                        </div>
                                    </li>
                                    <li>
                                        <div className="seemingly">
                                            <h5 className="Seek">Begin your claim</h5>
                                            <p>
                                                Call our <strong> Nissan car insurance claims number </strong>when you are ready with all documents and evidence.
                                            </p>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="whyus">
                <div className="container">
                    <div className="row">
                        <div className="col-xxl-12 col-md-12">
                            <div className="why">
                                <h2 className="choose">
                                    Our smooth and hassle-free
                                    <span className="light">
                                        <br />  claims process
                                    </span>
                                </h2>
                                <p className="complexities">
                                    Making a <strong>  Nissan  car insurance claim  </strong> with us does not involve any long waits or complex procedures. Just fill in four simple boxes and submit them through our rapid and efficient approach.

                                    <strong>
                                        {" "}
                                        <a href="tel:0800 772 0850">0800 772 0850</a>{" "}
                                    </strong> to begin your claim. Our claims process has been made as simple as possible to enable you to make your claim without being bogged down by tedious paperwork.
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-xxl-3 col-xl-6 col-lg-6 col-md-6 col-12">
                            <div className="box-service">
                                <img src={check} className="check" alt="" />
                                <h4>Contact Us</h4>
                                <p>
                                    A free consultation can be reached through our claims phone number,
                                    <strong>
                                        {" "}
                                        <a href="tel:0800 772 0850">0800 772 0850</a>{" "}
                                    </strong>
                                </p>
                            </div>
                        </div>
                        <div className="col-xxl-3 col-xl-6 col-lg-6 col-md-6 col-12">
                            <div className="box-service">
                                <img src={check} className="check" alt="" />
                                <h4>Report Details of the Accident</h4>
                                <p>
                                Tell everything you know about the accident, starting from the moment it took place to where it took place and how.
                                </p>
                            </div>
                        </div>
                        <div className="col-xxl-3 col-xl-6 col-lg-6 col-md-6 col-12">
                            <div className="box-service">
                                <img src={check} className="check" alt="" />
                                <h4>Provide Proofs </h4>
                                <p>
                                For your claim’s substantiation purpose, attach pictures or videos taken at the scene of the accident and include copies of such documents as police reports and witness statements.
                                </p>
                            </div>
                        </div>
                        <div className="col-xxl-3 col-xl-6 col-lg-6 col-md-6 col-12">
                            <div className="box-service">
                                <img src={check} className="check" alt="" />
                                <h4>Claim submission </h4>
                                <p>
                                Once we receive all the supplementary information and supporting documentation necessary in order for us to initiate your claim, we will submit your <strong> Nissan car insurance claim </strong> right away.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="faqs">
                <div className="container">
                    <div className="row">
                        <div className="col-xxl-12 col-xl-12 col-md-12 col-12">
                            <div className="fq">
                                <h2>
                                    {" "}
                                    Frequently Asked{" "}
                                    <span className="light">Questions  </span>
                                </h2>
                                <p className="complexities">
                                    Got questions about the
                                    <strong>Nissan car insurance claim? </strong>? We have them
                                    answered here.{" "}
                                </p>
                            </div>
                            <div className="allfaq">
                                <Accordion defaultActiveKey="0">
                                    <Accordion.Item eventKey="0">
                                        <Accordion.Header>
                                        Will I be charged extra?
                                        </Accordion.Header>
                                        <Accordion.Body>
                                        No. For our services, you will not incur any extra charges because we shall recover all costs from the other party's insurer.
                                        </Accordion.Body>
                                    </Accordion.Item>
                                    <Accordion.Item eventKey="1">
                                        <Accordion.Header>
                                        Can I choose my own repairer?
                                        </Accordion.Header>
                                        <Accordion.Body>
                                        Yes, definitely. You are free to select your own car repairer. We can arrange for your vehicle repairs from the garage of your choice
                                        </Accordion.Body>
                                    </Accordion.Item>
                                    <Accordion.Item eventKey="2">
                                        <Accordion.Header>
                                        Will my no-claims bonus be affected by using your services?
                                        </Accordion.Header>
                                        <Accordion.Body>
                                        No. Our experienced claims handlers are going to make direct third-party claims, meaning that no-claim bonuses stay intact while premiums remain unaltered.
                                        </Accordion.Body>
                                    </Accordion.Item>
                                    <Accordion.Item eventKey="3">
                                        <Accordion.Header>
                                        How long can I keep the replacement vehicle?
                                        </Accordion.Header>
                                        <Accordion.Body>
                                        Replacement vehicles may be retained by you until such time as your car is repaired and roadworthy again in circumstances where your vehicle has been involved in a road traffic accident and is undergoing repairs which render it unroadworthy.

                                        </Accordion.Body>
                                    </Accordion.Item>
                                    <Accordion.Item eventKey="4">
                                        <Accordion.Header>
                                      Will the replacement vehicle be identical to my vehicle?
                                        </Accordion.Header>
                                        <Accordion.Body>
                                        Our aim is to give you, irrespective of whatever sort of vehicle you drive, a like-for-like replacement car that will meet your needs. We shall send another car when you call us on the<strong> Nissan insurance claim phone number.</strong> 
                                        </Accordion.Body>
                                    </Accordion.Item>
                                    <Accordion.Item eventKey="5">
                                        <Accordion.Header>
                                        Are the repairs guaranteed?
                                        </Accordion.Header>
                                        <Accordion.Body>
                                        Yes. Our repairers, who are approved by manufacturers, strive to exceed the manufacturer’s specifications for guaranteed quality repairs.
                                        </Accordion.Body>
                                    </Accordion.Item>
                                    <Accordion.Item eventKey="6">
                                        <Accordion.Header>
                                        Can I recover all the costs I incurred due to the accident?
                                        </Accordion.Header>
                                        <Accordion.Body>
                                        You can recover all costs, including out-of-pocket medical bills, transportation costs, rental car costs, auto body or collision damages, lost earnings and wages, compensation for pain and suffering, or damage to property. 
                                        </Accordion.Body>
                                    </Accordion.Item>
                                </Accordion>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <Footer />
        </>
    );
};

export default Nissaninsuranceservices;
