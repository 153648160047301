import React, { useRef } from "react";
import Accordion from "react-bootstrap/Accordion";
import check from "../assets/images/check.png";
import dualcar from "../../src/assets/images/dualcar.webp";
import carbnr1 from "../../src/assets/images/carbnr1.webp";
import Sidebarinner from "../components/Sidebar-inner";
import phonecall from "../assets/images/phonecall.png";
import Footer from "../components/footer";
import { Helmet } from "react-helmet";

const Autonetinsurance = () => {
  const sidebarRef = useRef(null);

  const handleScrollToSidebar = () => {
    if (sidebarRef.current) {
      sidebarRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Autonet Car Insurance Accident Claim Phone Number:</title>
        <meta
          name="description"
          content="Want to file a no-fault car accident claim? Contact our team via the Autonet Car Insurance accident claim phone number 0800 772 0850 for dedicated claim support. "
        />
        <link
          rel="canonical"
          href="https://insurance-accident-claims.co.uk/autonet-insurance"
        />
      </Helmet>
      <section className="innernav">
        <div className="container">
          <div className="row">
            <div className="col-md-6 col-sm-6">
              <div className="left-logo">
                <h3>
                  Accident<span className="sipo"> Claims</span>
                </h3>
              </div>
            </div>
            <div className="col-md-6 col-sm-6">
              <div className="left-cta">
                <div class="headercnt">
                  <div class="chatmsg">
                    <img
                      src={phonecall}
                      className="phonecall"
                      alt="phonecall "
                    />
                  </div>
                  <div class="box-body">
                    <h3 class="nav-info-box-title">Toll Free Number </h3>
                    <p>
                      <a href="tel:0800 772 0850">0800 772 0850</a>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section
        className="newbnr"
        style={{ backgroundImage: "url(" + carbnr1 + ")" }}
      >
        <div className="container">
          <div className="row">
            <div className="col-xxl-8 col-xl-8 col-md-10 col-12">
              <div className="innerbnr">
                <h1>
                  <span className="onespan">car insurance </span>{" "}
                  <span className="sci"> claims </span>  assistance
                </h1>
                <p className="brokerage">
                  We guarantee a no-obligation consultation to get you back on
                  track after a no-fault car accident.
                </p>
                <div className="bnrbtn">
                  <a class="db-btn-border btn-rollover phone" href="tel:0800 772 0850">
                    Road Accident Claim Help?{" "}
                    <i class="fa-solid fa-arrow-right"></i>
                  </a>
                </div>
                <div className="bnrbtn">
                <a className="db-btn-border btn-rollover desktop"  onClick={handleScrollToSidebar}>
                    Road Accident Claim Help? <i className="fa-solid fa-arrow-right"></i>
                </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="stickysection">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="group-4-8">
                <div className="cl8 tf-tab">
                  <div className="content-tab">
                    <div className="inner-content">
                      <h2>
                        Professional claims assistance
                        <br />
                        <span className="light">
                          at no additional cost{" "}
                        </span>{" "}
                      </h2>
                      <p className="disciplines">
                        Have you been involved in an incident that was not your
                        fault? Call our experts using the{" "}
                        <strong>
                          {" "}
                          Autonet car insurance claims telephone number
                        </strong>
                        , and we will handle your claim effortlessly, saving you
                        time, stress and heaps of money.
                      </p>
                      <hr />
                      <p className="bold-text">Zero excess</p>
                      <p className="bottom-te">
                        In case you are not liable for any accident, we will
                        ensure that you do not pay any excess or upfront fees.
                        We will recover the entire amount from their insurer
                        without asking anything from you.{" "}
                      </p>
                      <hr />
                      <p className="bold-text">No loss of no-claims bonus</p>
                      <p className="bottom-te">
                        Call our number{" "}
                        <a href="tel:0800 772 0850">0800 772 0850</a> to file
                        your claim with us. We would never take it from your own
                        policy, protecting the NCB and giving respect to your
                        well-deserved concessions.
                      </p>
                      <hr />
                      <p className="bold-text">Approved manufacturer repairs</p>
                      <p className="bottom-te">
                        We will organise repairs for your vehicle through
                        UK-approved repairers who use original parts and
                        paintwork so as to restore it to its pre-accident state.{" "}
                      </p>
                      <hr />
                      <p className="bold-text">Personal injury help </p>
                      <p className="bottom-te">
                        If you have experienced some kind of accident that has
                        caused any level of injury, we will take you through the
                        process of making a successful{" "}
                        <strong>Autonet car insurance claim.</strong>{" "}
                      </p>
                      <hr />
                      <p className="bold-text">Emergency roadside assistance</p>
                      <p className="bottom-te">
                        When you contact us using the{" "}
                        <strong>Autonet car insurance claim number </strong>, we
                        make sure we send our team on time so that you can get
                        emergency support in your difficult moments.
                      </p>
                      <hr />
                      <p className="bold-text">
                        Coordination with repair centres
                      </p>
                      <p className="bottom-te">
                        We work closely with repair centres so as to ensure that
                        your damaged vehicle is repaired seamlessly and quickly
                        as well as reducing downtime.
                      </p>
                      <hr />
                      <p className="bold-text">Replacement on same day</p>
                      <p className="bottom-te">
                        By bringing it to where the incident occurred or
                        delivering it to your doorstep, we aim to get you back
                        on the road smoothly with a replacement vehicle within
                        one working day.
                      </p>
                      <hr />
                      <p className="bold-text">Experienced claims handler</p>
                      <p className="bottom-te">
                        Once you choose us for your{" "}
                        <strong>Autonet car accident claim</strong>, we shall
                        allocate your claims handler who will be there
                        throughout all stages of the process.{" "}
                      </p>
                      <hr />
                      <p className="bold-text">24 hour recover</p>
                      <p className="bottom-te">
                        Call us through the{" "}
                        <strong>Autonet car insurance helpline</strong> whenever
                        necessary to recover a damaged vehicle anytime and store
                        it safely in our compounds.{" "}
                      </p>
                      <hr />

                      <p className="bold-text">Free legal advice</p>
                      <p className="bottom-te">
                        Our solicitors will assist you in many ways, including
                        managing your{" "}
                        <strong> Autonet car insurance claim </strong> and
                        recovering policy excesses and out-of-pocket expenses.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="cl4" ref={sidebarRef}>
                  <Sidebarinner />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="photographs">
        <div className="container">
          <div className="row">
            <h2 className="road">
              We speed up the process of your recovery{" "}
              <span className="light">
                after <br />a no-fault accident
              </span>{" "}
            </h2>
            <div className="col-xxl-6 col-xl-6 col-md-12 col-12">
              <div className="fourbox">
                <ul>
                  <li>
                    <img src={check} className="check" alt="" />
                    Everything you might need after a car accident can be found
                    in our extensive range of services.
                  </li>
                  <hr />
                  <li>
                    <img src={check} className="check" alt="" />
                    We have a team that works round the clock to ensure your
                    vehicle recovery and repair processes are done quickly.
                  </li>
                  <hr />
                  <li>
                    <img src={check} className="check" alt="" />
                    If we believe there is a good chance that you will be able
                    to make a claim, we can put you in touch with an experienced
                    solicitor who will offer free advice about{" "}
                    <strong> Autonet car insurance claims. </strong>
                  </li>
                  <hr />
                  <li>
                    <img src={check} className="check" alt="" />
                    Call us on the{" "}
                    <strong> Autonet car insurance claims number</strong>, if
                    you are looking for a replacement car. We can provide you
                    with another one similar to your own until yours is fixed.
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-xxl-6 col-xl-6 col-md-12 col-12">
              <div className="fourbox">
                <ul>
                  <li>
                    <img src={check} className="check" alt="" />
                    All the parties shall also be involved by us directly so as
                    to expedite the settlement of your{" "}
                    <strong> Autonet car insurance claim. </strong>
                  </li>
                  <hr />
                  <li>
                    <img src={check} className="check" alt="" />
                    We will appoint an independent engineer to carry out an
                    inspection of your vehicle and determine how much it has
                    been destroyed during the accident or in any other way.
                  </li>
                  <hr />
                  <li>
                    <img src={check} className="check" alt="" />
                    Our solicitors will ensure recovery of all financial losses
                    from the other party’s insurer.
                  </li>
                  <hr />
                  <li>
                    <img src={check} className="check" alt="" />
                    Once the repairs to your vehicle have been completed, we
                    will arrange for your vehicle to be picked up and delivered
                    back at convenient times.
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="carprocess">
        <div className="container ">
          <div className="row">
            <div className="col-xxl-12 col-xxl-12 col-md-12 col-12">
              <h2 className="trusted-title">
                {" "}
                Our comprehensive range of accident <br />
                <span className="light">management solutions </span>{" "}
              </h2>
            </div>
          </div>
          <div className="row" id="possible">
            <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-12">
              <div className="claim">
                <ul>
                  <li>
                    <span className="sleej">Vehicle recovery :</span>We have
                    expertise in quick and effective recovery of your car
                    ensuring you experience a seamless and stress-free process
                    starting from the time you call us on the{" "}
                    <strong> Autonet car accident claim number.</strong>
                  </li>
                  <li>
                    <span className="sleej">Accident repairs:</span>Contact our
                    team, through{" "}
                    <strong>Autonet car insurance claim number</strong>, to have
                    your vehicle repaired as quickly as possible after a car
                    accident that was not your fault. We will organise
                    specialised professionals to repair your vehicle from an
                    approved repair centre.
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-12">
              <div className="claim" id="blue">
                <ul>
                  <li>
                    <span className="sleej">Vehicle storage:</span> Do you worry
                    about storing your car even after causing an accident? Worry
                    no more. We are there for you. Reach out to us using the{" "}
                    <strong>Autonet car insurance helpline </strong>so that we
                    arrange for secure storage of it in our facilities. Our
                    storage centres can be used to protect and keep your vehicle
                    without any financial obligations, thus shielding it from
                    any form of damage that may happen.
                  </li>
                  <li>
                    <span className="sleej">Replacement car:</span>When someone
                    else causes the accident, they should provide you with
                    another car legally. Contacting us via the{" "}
                    <strong>
                      {" "}
                      Autonet car insurance claims telephone number{" "}
                    </strong>{" "}
                    means within hours we will help you hit the road by giving
                    you a like-for-like replacement vehicle which is available
                    on day one itself.
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-12">
              <div className="claim">
                <ul>
                  <li>
                    <span className="sleej">Personal injury support :</span>
                    We will provide you with free legal advice and the correct
                    compensation for your injuries if you sustained any major or
                    minor injuries as a result of a car accident using our
                    <strong> Autonet car accident claim </strong>service.
                  </li>
                  <li>
                    <span className="sleej">Claims management :</span>
                    Everything, including arranging alternative vehicles,
                    overseeing repairs, communicating with all parties involved
                    and administering documentation that goes with
                    <strong> Autonet insurance claims </strong> is handled by
                    us. Call us on <a href="tel:0800 772 0850">0800 772 0850</a>{" "}
                    to start your claim now.
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="yellowcta">
        <div className="container">
          <div className="row">
            <div className="cta2">
              <div className="col-xxl-8 col-xl-8 col-lg-8 col-12">
                <div className="legal">
                  <h3 className="experienced">
                    Got more questions?{" "}
                    <span className="light">Talk to our specialists</span>{" "}
                  </h3>
                  <p className="professionals">
                    Get in touch with our no-fault claim experts via{" "}
                    <strong>
                      car insurance claims number
                      <a className="cpt-no" href="tel:0800 772 0850">
                        {" "}
                        0800 772 0850{" "}
                      </a>{" "}
                    </strong>
                    to get the best advice for your{" "}
                    <strong> Autonet car insurance claim. </strong>
                  </p>
                </div>
              </div>
              <div className="col-xxl-4 col-xl-4 col-lg-4 col-12">
              <div class="process">
                  <a class="tf-button style-1 phone" href="tel:0800 772 0850">
                    Get in touch<span class="icon-keyboard_arrow_right"></span>
                  </a>
                </div>
                <div class="process">
                    <a className="tf-button style-1 desktop"  onClick={handleScrollToSidebar}>
                        Get in touch<span className="icon-keyboard_arrow_right"></span>
                    </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <search className="updatedform">
        <div className="container">
          <div className="row text-center">
            <div className="col-xxl-12 col-xl-12 col-md-12 col-12">
              <h2 className="whiteheading">
                {" "}
                What makes us distinct <br />
                <span className="light">from others? </span>
              </h2>
              <p className="sustained">
                Engagement in a car accident is agonising. To make it simple for
                you after experiencing a no-fault car accident, we are here to
                support you as your reliable partners. Our stress-reducing
                accident management solutions will save time and allow you to
                concentrate on healing yourself.{" "}
              </p>
            </div>
          </div>
          <div className="row">
            <div className="col-xxl-4 col-xl-4 col-lg-6 col-md-12 col-12">
              <div className="Claimant">
                <h5>Manufacturer-approved repairs</h5>
                <p className="collision">
                  Contact the{" "}
                  <strong> Autonet car accident claim number </strong>for
                  professional, high-quality repairs of your vehicle through our
                  manufacturer-approved repair network.
                </p>

                <h5>Expert claim management</h5>
                <p className="collision">
                  Our experienced claim handlers will determine the value of
                  your claim, evaluate the damage and act as intermediaries
                  between insurers, repairers and other parties for quick claims
                  settlement.{" "}
                </p>

                <h5>Immediate response</h5>
                <p className="collision">
                  Once you reach out to our{" "}
                  <strong> Autonet car insurance helpline</strong>, we shall
                  send our team straight away to offer assistance so that you
                  have prompt support whenever necessary.
                </p>
              </div>
            </div>
            <div className="col-xxl-4 col-xl-4 col-lg-6 col-md-12 col-12">
              <div className="Claimant">
                <h5>Same-day replacement vehicle</h5>
                <p className="collision">
                  If you want a replacement vehicle without incurring costs,
                  call our{" "}
                  <strong> car insurance claims telephone number </strong>
                  <a href="tel:0800 772 0850"> 0800 772 0850 </a>and get back on
                  the road smoothly with like-for-like replacement vehicles.
                </p>
                <h5>No additional charges</h5>
                <p className="collision">
                  Where an accident is not your fault there would be no upfront
                  fee imposed on you. All the costs will be recovered from the
                  insurer of the opposite party.
                </p>

                <h5>Authentic replacement parts</h5>
                <p className="collision">
                  We ensure the finest possible repair for your damaged vehicle
                  by using approved repairers who use genuine parts and paint in
                  their repairs.{" "}
                </p>
              </div>
            </div>
            <div className="col-xxl-4 col-xl-4 col-lg-12 col-md-12 col-12">
              <div className="Claimant">
                <h5>Transparent process</h5>
                <p className="collision">
                  One of our experienced claims handlers will stay in touch with
                  you to let you know what’s happening with your{" "}
                  <strong> Autonet car accident claim. </strong>
                </p>

                <h5>Secure Storage</h5>
                <p className="collision">
                  With us, you can be sure that your car will be kept at highly
                  secure storage yards.
                </p>

                <h5>Reduced stress</h5>
                <p className="collision">
                  Our services on <strong> Autonet car accident claim </strong>{" "}
                  will help to reduce the torment and hustle brought about by an
                  accident and enable you to concentrate on recovering.
                </p>
              </div>
            </div>
          </div>
        </div>
      </search>

      <section className="Accident-claim">
        <div className="container">
          <div className="row">
            <div className="needed">
              <h2 className="trusted-title">
                How can you get the most from the <br />
                <span className="light"> Autonet car accident claim?</span>
              </h2>
              <p className="complexities">
                Obtaining maximum compensation is crucial if you have sustained
                serious injuries in a non-fault road traffic accident. However,
                the value of the <strong> Autonet car insurance claim </strong>{" "}
                <br /> depends on what steps you take after being involved in a
                road traffic accident. Here are some steps to be followed to
                ensure that you receive fair compensation for your injuries and
                other losses.{" "}
              </p>
            </div>
            <hr />
          </div>
          <div className="row" id="incdes">
            <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-12">
              <div className="Preparing">
                <ul>
                  <li>
                    <div className="seemingly">
                      <h5 className="Seek">Seek medical treatment:</h5>
                      <p>
                        {" "}
                        The first thing that needs to be done is to go for
                        immediate medication and also obtain copies of your
                        medical records showing the extent of your injury.{" "}
                      </p>
                    </div>
                  </li>
                  <li>
                    <div className="seemingly">
                      <h5 className="Seek">Report police about the crash: </h5>
                      <p>
                        Dial emergency numbers and report the accident. This
                        report will provide significant proof as well as
                        information which will support your{" "}
                        <strong> Autonet car insurance claim. </strong>
                      </p>
                    </div>
                  </li>
                  <li>
                    <div className="seemingly">
                      <h5 className="Seek">Collect evidence:</h5>
                      <p>
                        Strengthen your{" "}
                        <strong> Autonet car accident claim </strong> by
                        collecting essential proof like images of the accident
                        scene, damages caused to vehicles and injuries suffered
                        by you.
                      </p>
                    </div>
                  </li>
                  <li>
                    <div className="seemingly">
                      <h5 className="Seek">Exchange contact details</h5>
                      <p>
                        Since it is the other driver you are claiming against,
                        make sure that there is an exchange of important
                        information such as names, addresses, contact details
                        and insurance particulars with them.
                      </p>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
            <div
              className="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-12"
              id="dualcardiv"
            >
              <img src={dualcar} alt="" className="dualcar" />
            </div>
            <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-12">
              <div className="Preparing">
                <ul>
                  <li>
                    <div className="seemingly">
                      <h5 className="Seek">Witnesses’ statements:</h5>
                      <p>
                        In case someone happened to be around when this matter
                        was taking place, reach out to them and get their
                        contact details.{" "}
                      </p>
                    </div>
                  </li>
                  <li>
                    <div className="seemingly">
                      <h5 className="Seek">
                        {" "}
                        Never accept an early settlement offer
                      </h5>
                      <p>
                        If you receive a lowly valued early settlement proposal
                        from the other party’s insurer, do not agree to it
                        without consulting your solicitor.
                      </p>
                    </div>
                  </li>
                  <li>
                    <div className="seemingly">
                      <h5 className="Seek">Dashcam footage:</h5>
                      <p>
                        Try to get hold of dash cam footage because it will
                        provide strong visual evidence as to what exactly
                        transpired leading up to the accident.{" "}
                      </p>
                    </div>
                  </li>
                  <li>
                    <div className="seemingly">
                      <h5 className="Seek">Make a claim with us</h5>
                      <p>
                        Call us on the{" "}
                        <strong> Autonet car accident claim number</strong>, and
                        we shall initiate the claim process for you immediately.
                      </p>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="whyus">
        <div className="container">
          <div className="row">
            <div className="col-xxl-12 col-md-12">
              <div className="why">
                <h2 className="choose">
                  How does it work? –{" "}
                  <span className="light">Our eased claims process</span>
                </h2>
                <p className="complexities">
                  We have designed a very simple procedure for filing claims so
                  that customers can easily make their{" "}
                  <strong> Autonet car insurance claims.</strong> The outlined
                  steps will help you file your complaint with us successfully.
                </p>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-xxl-3 col-xl-6 col-lg-6 col-md-6 col-12">
              <div className="box-service">
                <img src={check} className="check" alt="" />
                {/* <img src={experience} className="experience" alt="" /> */}
                <h4>Contact Us</h4>
                <p>
                  Please use the{" "}
                  <strong>Autonet car insurance claim number</strong> to get in
                  touch with our solicitors for free consultations regarding
                  your case.
                </p>
              </div>
            </div>
            <div className="col-xxl-3 col-xl-6 col-lg-6 col-md-6 col-12">
              <div className="box-service">
                <img src={check} className="check" alt="" />
                <h4>Tell us more about the accident</h4>
                <p>
                  Detail everything that happened during the accident including
                  date, time, place and description of occurrences that led you
                  into the mess involving a collision or two.
                </p>
              </div>
            </div>
            <div className="col-xxl-3 col-xl-6 col-lg-6 col-md-6 col-12">
              <div className="box-service">
                <img src={check} className="check" alt="" />
                <h4>Submit the proof</h4>
                <p>
                  If necessary, bring any substantial evidence or important
                  details that you have collected from the scene of the accident
                  to our solicitors.{" "}
                </p>
              </div>
            </div>
            <div className="col-xxl-3 col-xl-6 col-lg-6 col-md-6 col-12">
              <div className="box-service">
                <img src={check} className="check" alt="" />
                <h4>Submission Of Claims</h4>
                <p>
                  When you have all the relevant documents and information, we
                  will commence and submit your{" "}
                  <strong> Autonet car accident claim. </strong>
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="faqs">
        <div className="container">
          <div className="row">
            <div className="col-xxl-12 col-xl-12 col-md-12 col-12">
              <div className="fq">
                <h2>
                  {" "}
                  Frequently Asked{" "}
                  <span className="light">Questions (FAQ)</span>
                </h2>
                <p className="complexities">
                  Got questions about the{" "}
                  <strong> Autonet car insurance claim? </strong>We have them
                  answered here.
                </p>
              </div>
              <div className="allfaq">
                <Accordion defaultActiveKey="0">
                  <Accordion.Item eventKey="0">
                    <Accordion.Header>
                      Can I get a courtesy car after a no-fault car accident?
                    </Accordion.Header>
                    <Accordion.Body>
                      Yes indeed! If your vehicle is not roadworthy, we will put
                      you in one while yours is being fixed.
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="1">
                    <Accordion.Header>
                      Am I free to choose my own garage for fixing my car?
                    </Accordion.Header>
                    <Accordion.Body>
                      Yes, if you want your vehicle repaired at your preferred
                      garage, then provide us with the repairer’s particulars,
                      and we will arrange for repairs at your chosen repair
                      centre.
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="2">
                    <Accordion.Header>
                      How long does it take for my car to be repaired?
                    </Accordion.Header>
                    <Accordion.Body>
                      It is difficult to tell you exactly how long it’ll take
                      for your vehicle to be fixed. However, we shall liaise
                      with the repairer and keep updating on the progress of
                      repairs.
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="3">
                    <Accordion.Header>
                      What happens after my vehicle is beyond repair?{" "}
                    </Accordion.Header>
                    <Accordion.Body>
                      If your vehicle is a write-off, you can call our{" "}
                      <strong> Autonet car insurance helpline</strong>, and we
                      will instruct an independent assessor to evaluate the
                      value of your car and arrange for a replacement vehicle
                      until your claim is settled.
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="4">
                    <Accordion.Header>
                      Do I need to report my accident to the police?
                    </Accordion.Header>
                    <Accordion.Body>
                      Of course! You should immediately contact the authorities
                      and notify them within twenty-four hours. The police
                      report may come in handy when proving that you were right
                      on your <strong> Autonet car insurance claim. </strong>
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="5">
                    <Accordion.Header>
                      How can I get my car recovered after a non-fault road
                      traffic accident?
                    </Accordion.Header>
                    <Accordion.Body>
                      To obtain emergency roadside assistance following a
                      no-fault road traffic accident, call us on the{" "}
                      <strong> Autonet car insurance helpline. </strong>Our
                      recovery agents will rush to retrieve your vehicle from
                      where it was involved in an accident.
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="6">
                    <Accordion.Header>
                      Can I receive damages for physical injuries sustained due
                      to the incident?{" "}
                    </Accordion.Header>
                    <Accordion.Body>
                      Yeah. If you have any injuries due to the other driver’s
                      negligence, you can claim compensation from the insurer of
                      the third party involved in the crash. Speak with a
                      solicitor experienced with cases like yours when pursuing
                      adequate remuneration for suffering caused by another
                      person’s negligence.
                    </Accordion.Body>
                  </Accordion.Item>
                </Accordion>
              </div>
            </div>
          </div>
        </div>
      </section>

      <Footer />
    </>
  );
};

export default Autonetinsurance;
