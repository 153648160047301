import React, { useRef } from "react";
import Accordion from "react-bootstrap/Accordion";
import check from "../assets/images/check.png";
import dualcar from "../../src/assets/images/dualcar.webp";
import carbnr1 from "../../src/assets/images/carbnr1.webp";
import Sidebarinner from "../components/Sidebar-inner";
import phonecall from "../assets/images/phonecall.png";
import Footer from "../components/footer";
import { Helmet } from "react-helmet";

const Stcentralinsurance = () => {
  const sidebarRef = useRef(null);

  const handleScrollToSidebar = () => {
    if (sidebarRef.current) {
      sidebarRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };
  return (
    <>
    <Helmet>
        <meta charSet="utf-8" />
        <title>1st Central Car Insurance Accident Claim Phone Number:</title>
        <meta
          name="description"
          content="Get emergency roadside assistance and professional claim support by dialling the 1st Central Car Insurance accident claim phone number  0800 772 0850. Contact us today. "
        />
        <link
          rel="canonical"
          href="https://insurance-accident-claims.co.uk/1st-central-insurance"
        />
      </Helmet>
      <section className="innernav">
        <div className="container">
          <div className="row">
            <div className="col-md-6 col-sm-6">
              <div className="left-logo">
                <h3>
                  Accident<span className="sipo"> Claims</span>
                </h3>
              </div>
            </div>
            <div className="col-md-6 col-sm-6">
              <div className="left-cta">
                <div class="headercnt">
                  <div class="chatmsg">
                    <img
                      src={phonecall}
                      className="phonecall"
                      alt="phonecall "
                    />
                  </div>
                  <div class="box-body">
                    <h3 class="nav-info-box-title">Toll Free Number </h3>
                    <p>
                      <a href="tel:+/">0800 772 0850</a>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section
        className="newbnr"
        style={{ backgroundImage: "url(" + carbnr1 + ")" }}
      >
        <div className="container">
          <div className="row">
            <div className="col-xxl-8 col-xl-8 col-md-10 col-12">
              <div className="innerbnr">
                <h1>
                  <span className="onespan">Car insurance </span>{" "}
                  <span className="sci"> claim </span> assistance 
                </h1>
                <p className="brokerage">
                  We are here for you at every step of the way, right from
                  arranging the recovery and repairs of your vehicle, providing
                  professional claims assistance and organising temporary
                  replacement vehicles{" "}
                </p>
                <div className="bnrbtn">
                  <a class="db-btn-border btn-rollover phone" href="tel:0800 772 0850">
                    Road Accident Claim Help?{" "}
                    <i class="fa-solid fa-arrow-right"></i>
                  </a>
                </div>
                <div className="bnrbtn">
                <a className="db-btn-border btn-rollover desktop"  onClick={handleScrollToSidebar}>
                    Road Accident Claim Help? <i className="fa-solid fa-arrow-right"></i>
                </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="stickysection">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="group-4-8">
                <div className="cl8 tf-tab">
                  <div className="content-tab">
                    <div className="inner-content">
                      <h2>
                        We’re your dedicated partners <br />
                        <span className="light">
                          {" "}
                          on your road to recovery after a car accident{" "}
                        </span>{" "}
                      </h2>
                      <p className="disciplines">
                        Our committed UK-based no-fault claim experts are always
                        there to help victims who have been involved in a car
                        accident. We have a team of professionals who provide
                        complete accident support services designed to make the
                        victim’s lives easier following a car accident. To
                        successfully file an accident claim call our{" "}
                        <strong>
                          number <a href="tel:0800 772 0850">0800 772 0850</a>{" "}
                          for insurance claims.{" "}
                        </strong>{" "}
                      </p>
                      <hr />
                      <p className="bold-text">
                        Don’t pay any insurance excess
                      </p>
                      <p className="bottom-te">
                        We’ll make sure you don’t pay any insurance fees and
                        your own insurance policy remains untouched.
                      </p>
                      <hr />
                      <p className="bold-text">
                        Manufacturer’s approved repairs
                      </p>
                      <p className="bottom-te">
                        Just get in touch with us on the{" "}
                        <strong>
                          {" "}
                          1st Central Insurance accident claim number{" "}
                        </strong>{" "}
                        for vehicle repairs after the accident. We will see to
                        it that your vehicle repairs are done according to the
                        manufacturer's standards and specifications.{" "}
                      </p>
                      <hr />
                      <p className="bold-text">Like-for-like replacement car</p>
                      <p className="bottom-te">
                        In case your car is damaged as a result of an accident,
                        we will replace it with another one of the same
                        specification size, make and model.
                      </p>
                      <hr />
                      <p className="bold-text">Accelerated claims process</p>
                      <p className="bottom-te">
                        {" "}
                        With our fast and simplified process of making a claim,
                        be assured that your
                        <strong> 1st Central car insurance claim </strong> will
                        be settled within no time.
                      </p>
                      <hr />
                      <p className="bold-text">Qualified claims handler</p>
                      <p className="bottom-te">
                        Once you have filled in your{" "}
                        <strong> 1st Central car insurance claim </strong> form,
                        we shall assign you a specialist claims handler so that
                        at every step of the claim process, you can deal with
                        one person only.
                      </p>
                      <hr />
                      <p className="bold-text">Skilled solicitor panel</p>
                      <p className="bottom-te">
                        Should you need to file a
                        <strong> 1st Central Insurance claim </strong>, please
                        contact our claims team using the{" "}
                        <strong>
                          {" "}
                          1st Central car insurance claim phone number{" "}
                        </strong>{" "}
                        . They are in constant pursuit of justice regarding your
                        claim.
                      </p>
                      <hr />
                      <p className="bold-text">No change in no claims bonus</p>
                      <p className="bottom-te">
                        We do not make any claims under your own policy so that
                        your no-claims discount and bonuses earned by hard work
                        are preserved.
                      </p>
                      <hr />
                      <p className="bold-text">24/7 accident recovery</p>
                      <p className="bottom-te">
                        Anytime you have an accident, contact us on the{" "}
                        <strong> 1st Central insurance claim number </strong>and
                        we will send our team immediately to collect your
                        vehicle from the scene of the accident.
                      </p>
                      <hr />
                      <p className="bold-text">Expert support</p>
                      <p className="bottom-te">
                        In order for you to be compensated fairly, our
                        experienced panel of UK-based solicitors will guide and
                        provide professional advice throughout your claim
                        process.
                      </p>
                      <hr />

                      <p className="bold-text">No obligation advice</p>
                      <p className="bottom-te">
                        If you have been harmed by someone else due to their
                        negligence, we can help. Access free expert advice and a
                        free initial consultation so that you know your rights
                        as well as options on whether or not to take action
                        against the wrongdoer.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="cl4" ref={sidebarRef}>
                  <Sidebarinner />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="photographs">
        <div className="container">
          <div className="row">
            <h2 className="road">
              Dedicated insurance accident{" "}
              <span className="light">
                support <br /> from collision to recovery
              </span>{" "}
            </h2>
            <div className="col-xxl-6 col-xl-6 col-md-12 col-12">
              <div className="fourbox">
                <ul>
                  <li>
                    <img src={check} className="check" alt="" />
                    Reach out to our team using the{" "}
                    <strong>
                      {" "}
                      1st Central insurance accident claim number.{" "}
                    </strong>{" "}
                    Our expert panel of solicitors will explore ways of
                    recovering your car best before moving it away from danger.
                  </li>
                  <hr />
                  <li>
                    <img src={check} className="check" alt="" />
                    From vehicle recovery until when we hand back your keys, we
                    are with you in every step after a road traffic accident.
                  </li>
                  <hr />
                  <li>
                    <img src={check} className="check" alt="" />
                    Wherever possible we can get you back on the road with a
                    like-for-like courtesy car if yours is undriveable.
                  </li>
                  <hr />
                  <li>
                    <img src={check} className="check" alt="" />
                    Repairs arranged by us using manufacturer-approved repairers
                    ensure that your vehicle is put back into its pre-accident
                    condition.
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-xxl-6 col-xl-6 col-md-12 col-12">
              <div className="fourbox">
                <ul>
                  <li>
                    <img src={check} className="check" alt="" />
                    We have storage facilities that will take care of your car
                    while you go through the process without any costs.
                  </li>
                  <hr />
                  <li>
                    <img src={check} className="check" alt="" />
                    On reporting an accident through{" "}
                    <strong>
                      {" "}
                      1st Central insurance accident claim number{" "}
                    </strong>
                    , we will ensure that you are provided with the best
                    possible legal representation for your case.
                  </li>
                  <hr />
                  <li>
                    <img src={check} className="check" alt="" />
                    We will deal with all issues relating to communication with
                    people involved in the accident on your behalf as this would
                    save you from unwanted stress.
                  </li>
                  <hr />
                  <li>
                    <img src={check} className="check" alt="" />
                    After repairing your vehicle, we shall bring it to your
                    house at a convenient time.
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="carprocess">
        <div className="container ">
          <div className="row">
            <div className="col-xxl-12 col-xxl-12 col-md-12 col-12">
              <h2 className="trusted-title">
                {" "}
                Our extensive range
                <br />
                <span className="light"> of services</span>{" "}
              </h2>
            </div>
          </div>
          <div className="row" id="possible">
            <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-12">
              <div className="claim">
                <ul>
                  <li>
                    <span className="sleej">Car recovery:</span> Through our
                    skilled recovery agents, we shall guarantee fast recovery of
                    your vehicle from the scene of the accident up to our secure
                    storage units. Dial us at{" "}
                    <strong> 1st Central insurance claims number </strong>for
                    any issues related to recovering your car.
                  </li>
                  <li>
                    <span className="sleej">Approved repairs:</span>In case
                    there is a need for repairing your car, we have a large pool
                    of approved repairers based across the UK who can carry out
                    your repairs using genuine parts and paint.
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-12">
              <div className="claim" id="blue">
                <ul>
                  <li>
                    <span className="sleej">Vehicle storage:</span> It is not
                    safe to leave a damaged car on the roadside after an
                    accident. Until your claim is assessed, your vehicle will be
                    stored in our well-guarded yards, where it will be safe from
                    theft, damage and vandalism.
                  </li>
                  <li>
                    <span className="sleej">Replacement car: </span> If your car
                    is completely destroyed or immobilised by an accident,
                    please call our team through the{" "}
                    <strong> 1st Central insurance claim phone number </strong>
                    and we will provide you with a replacement vehicle that’s
                    exactly like the one you lost to use until repairs or until
                    your claim is settled.
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-12">
              <div className="claim">
                <ul>
                  <li>
                    <span className="sleej">Personal injury assistance:</span>
                    Looking for compensation for injuries caused by an accident?
                    Call our solicitors on the{" "}
                    <strong>
                      {" "}
                      1st Central insurance accident claim phone number{" "}
                    </strong>
                    to get advice from experts on injury claims that will help
                    you maximise your payout.
                  </li>
                  <li>
                    <span className="sleej">Claims management:</span> We’ll be
                    there every step of the way, from filing to settlement of
                    your claim. Call us now at{" "}
                    <strong> 1st Central insurance claims number </strong>to
                    start your claim.
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="yellowcta">
        <div className="container">
          <div className="row">
            <div className="cta2">
              <div className="col-xxl-8 col-xl-8 col-lg-8 col-12">
                <div className="legal">
                  <h3 className="experienced">
                    Got more questions?{" "}
                    <span className="light">Talk to our specialists</span>{" "}
                  </h3>
                  <p className="professionals">
                    Get in touch with our no-fault claim experts via{" "}
                    <strong>
                      insurance claims number{" "}
                      <a className="cpt-no" href="tel:0800 772 0850">
                        {" "}
                        0800 772 0850{" "}
                      </a>{" "}
                    </strong>{" "}
                    to get the best advice for your{" "}
                    <strong> 1st Central car insurance claim.</strong>
                  </p>
                </div>
              </div>
              <div className="col-xxl-4 col-xl-4 col-lg-4 col-12">
              <div class="process">
                  <a class="tf-button style-1 phone" href="tel:0800 772 0850">
                    Get in touch<span class="icon-keyboard_arrow_right"></span>
                  </a>
                </div>
                <div class="process">
                    <a className="tf-button style-1 desktop"  onClick={handleScrollToSidebar}>
                        Get in touch<span className="icon-keyboard_arrow_right"></span>
                    </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <search className="updatedform">
        <div className="container">
          <div className="row text-center">
            <div className="col-xxl-12 col-xl-12 col-md-12 col-12">
              <h2 className="whiteheading">
                {" "}
                Why pick us?
                <br />
                <span className="light"> </span>
              </h2>
              <p className="sustained">
                A car accident can be a stressful and burdensome experience.
                However, if it wasn’t your fault, we will ensure you are not
                haunted by the same. We provide holistic assistance from
                recovery to claim management at zero cost. Call the{" "}
                <strong> 1st Central insurance accident claim number </strong>{" "}
                for our accident support services.
              </p>
            </div>
          </div>
          <div className="row">
            <div className="col-xxl-4 col-xl-4 col-lg-6 col-md-12 col-12">
              <div className="Claimant">
                <h5>Free of charge </h5>
                <p className="collision">
                  Our services will not give a debt of thousands of pounds. All
                  costs shall be recovered from the insurer of the other party.
                </p>

                <h5> Wide repair network </h5>
                <p className="collision">
                  We always go beyond expectations in ensuring high-quality
                  repairs for your vehicle.
                </p>

                <h5>Dedicated support</h5>
                <p className="collision">
                  Through timely and professional resolution of your{" "}
                  <strong> 1st Central car insurance claim </strong>, we offer
                  steadfast reliable assistance.
                </p>
              </div>
            </div>
            <div className="col-xxl-4 col-xl-4 col-lg-6 col-md-12 col-12">
              <div className="Claimant">
                <h5>Protected vehicle storage</h5>
                <p className="collision">
                  With our no-fault car accident aftermath vehicle storage
                  options, the safety and security of your car are highly
                  guaranteed.
                </p>

                <h5>Personalised advice</h5>
                <p className="collision">
                  Call us through{" "}
                  <strong> 1st Central insurance accident claim number </strong>{" "}
                  to receive free, personalised advice from our experienced
                  solicitors based on circumstances relating to your case.
                </p>

                <h5>Efficient claims handling </h5>
                <p className="collision">
                  Your <strong> 1st Central car insurance claim </strong> is
                  efficiently handled by our claims team to guarantee its timely
                  conclusion. In addition, we keep updating you on progress made
                  in settling your claims.
                </p>
              </div>
            </div>
            <div className="col-xxl-4 col-xl-4 col-lg-12 col-md-12 col-12">
              <div className="Claimant">
                <h5>Recovering uninsurable losses</h5>
                <p className="collision">
                  Have you incurred expenses as a result of the accident? We
                  will recover for you all the costs from the third party’s
                  insurer.
                </p>

                <h5> Fast and hassle-free replacement vehicle </h5>
                <p className="collision">
                  Our service helps you find an appropriate replacement car to
                  stop the inconvenience and let you get back to your normal
                  routine.
                </p>

                <h5>End to end services</h5>
                <p className="collision">
                  We handle everything from towing your vehicle to completing
                  the claim form on your behalf hence giving you total support
                  from beginning to end throughout your entire claim journey.
                </p>
              </div>
            </div>
          </div>
        </div>
      </search>

      <section className="Accident-claim">
        <div className="container">
          <div className="row">
            <div className="needed">
              <h2 className="trusted-title">
                How can I increase my <br />
                <span className="light"> settlement offer? </span>
              </h2>
              <p className="complexities">
                Are you thinking about how to maximise the recovery amount of{" "}
                <strong> 1st Central car insurance? </strong> <br />
                By taking the right steps after a motor car accident, one can
                increase the chances of receiving the maximum possible
                compensation amount in respect of your claim.
              </p>
            </div>
            <hr />
          </div>
          <div className="row" id="incdes">
            <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-12">
              <div className="Preparing">
                <ul>
                  <li>
                    <div className="seemingly">
                      <h5 className="Seek">Report accident:</h5>
                      <p>
                        {" "}
                        Call the police immediately when involved in a car
                        collision within 24 hours. Take care to further obtain a
                        police report that has full particulars regarding this
                        occurrence.{" "}
                      </p>
                    </div>
                  </li>
                  <li>
                    <div className="seemingly">
                      <h5 className="Seek"> Look for medical attention: </h5>
                      <p>
                        Professional medical help should be sought immediately
                        so as to have timely treatment of your wounds. Your
                        solicitor may use these reports, which are important
                        evidence as far as getting a fair recompense is
                        concerned.{" "}
                      </p>
                    </div>
                  </li>
                  <li>
                    <div className="seemingly">
                      <h5 className="Seek">
                        Keep a Record of all Financial Losses:
                      </h5>
                      <p>
                        If you need compensation for all your negative results,
                        then you must attach all receipts and indicate the
                        expenses incurred as a result of the accident.
                      </p>
                    </div>
                  </li>
                  <li>
                    <div className="seemingly">
                      <h5 className="Seek">Never Accept the First Offer</h5>
                      <p>
                        Don’t ever accept the first settlement offer without
                        talking to your solicitor. The initial settlement offer
                        may not fully compensate you for all damages and losses
                        faced.
                      </p>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
            <div
              className="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-12"
              id="dualcardiv"
            >
              <img src={dualcar} alt="" className="dualcar" />
            </div>
            <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-12">
              <div className="Preparing">
                <ul>
                  <li>
                    <div className="seemingly">
                      <h5 className="Seek">Collect Proof:</h5>
                      <p>
                        You should get crucial evidence from the scene of an
                        accident, like photographs, videos, eyewitness
                        statements, pictures of damaged vehicles as well as
                        marks on your body that indicate injury.{" "}
                      </p>
                    </div>
                  </li>
                  <li>
                    <div className="seemingly">
                      <h5 className="Seek"> Share Information</h5>
                      <p>
                        To be able to process your{" "}
                        <strong> 1st Central car insurance claim </strong>{" "}
                        smoothly, exchange details with other drivers involved
                        in an accident such as the name, contact information,
                        vehicle details and insurance information.
                      </p>
                    </div>
                  </li>
                  <li>
                    <div className="seemingly">
                      <h5 className="Seek">
                        Consult with No-Fault Claim Experts:
                      </h5>
                      <p>
                        Reach out to us through our{" "}
                        <strong>
                          {" "}
                          1st Central Insurance claim phone number{" "}
                        </strong>{" "}
                        to speak to our experts in accident claims about your
                        claim.
                      </p>
                    </div>
                  </li>
                  <li>
                    <div className="seemingly">
                      <h5 className="Seek">
                        The Quicker Your Filing Process Is Done The Better It Is
                        for You
                      </h5>
                      <p>
                        Don’t delay longer to file your claim. Call us today on
                        the{" "}
                        <strong>
                          {" "}
                          1st Central insurance accident claim phone number{" "}
                        </strong>{" "}
                        and start your claim process as soon as possible.
                      </p>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="whyus">
        <div className="container">
          <div className="row">
            <div className="col-xxl-12 col-md-12">
              <div className="why">
                <h2 className="choose">
                  How to start a <span className="light">claim with us</span>
                </h2>
                <p className="complexities">
                  Choosing our company to make a claim means that there will be
                  no need to do any complex paperwork, keep making follow-up
                  calls or wait in vain. Start your accident claim by calling us
                  at <a href="tel:0800 772 0850">0800 772 0850</a>
                </p>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-xxl-3 col-xl-6 col-lg-6 col-md-6 col-12">
              <div className="box-service">
                <img src={check} className="check" alt="" />
                {/* <img src={experience} className="experience" alt="" /> */}
                <h4>Get in touch </h4>
                <p>
                  For free no-obligation advice, kindly call our dedicated team
                  through the{" "}
                  <strong>
                    1st Central insurance accident claim phone number.{" "}
                  </strong>
                </p>
              </div>
            </div>
            <div className="col-xxl-3 col-xl-6 col-lg-6 col-md-6 col-12">
              <div className="box-service">
                <img src={check} className="check" alt="" />
                <h4>Talk about the accident </h4>
                <p>
                  Describe what led to the accident and give information about
                  when and where it happened.
                </p>
              </div>
            </div>
            <div className="col-xxl-3 col-xl-6 col-lg-6 col-md-6 col-12">
              <div className="box-service">
                <img src={check} className="check" alt="" />
                <h4>Share evidence </h4>
                <p>
                  Give out necessary documents together with any vital proofs
                  that you have collected so as to facilitate the smooth
                  processing of your claim.{" "}
                </p>
              </div>
            </div>
            <div className="col-xxl-3 col-xl-6 col-lg-6 col-md-6 col-12">
              <div className="box-service">
                <img src={check} className="check" alt="" />
                <h4>Follow up </h4>
                <p>
                  We shall be updating you on your claim’s progress after we
                  have started it.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="faqs">
        <div className="container">
          <div className="row">
            <div className="col-xxl-12 col-xl-12 col-md-12 col-12">
              <div className="fq">
                <h2>
                  {" "}
                  Frequently Asked{" "}
                  <span className="light">Questions (FAQ)</span>
                </h2>
                <p className="complexities">
                  Do you have some queries regarding{" "}
                  <strong>1st Central car insurance claims? </strong> Here they
                  are resolved.
                </p>
              </div>
              <div className="allfaq">
                <Accordion defaultActiveKey="0">
                  <Accordion.Item eventKey="0">
                    <Accordion.Header>
                      Should I agree to what the insurance company is offering
                      as a settlement?
                    </Accordion.Header>
                    <Accordion.Body>
                      No, you mustn’t accept an initial offer without first
                      obtaining legal advice. Such an early lowball offer
                      usually leaves some of your accident-related costs
                      uncovered fully by the amount offered. Before you consider
                      accepting any such offer, it is vital to seek the guidance
                      of an experienced solicitor, in order to know what would
                      be appropriate compensation for your damages.
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="1">
                    <Accordion.Header>
                      Can I get a replacement car after my car has been in an
                      accident?
                    </Accordion.Header>
                    <Accordion.Body>
                      Yes. You are eligible for a temporary replacement vehicle
                      immediately following a car accident. Contact our{" "}
                      <strong>
                        {" "}
                        1st Central insurance accident claim phone number{" "}
                      </strong>{" "}
                      for like-for-like temporary replacement vehicles
                      post-accident.
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="2">
                    <Accordion.Header>
                      Am I allowed to select where my car will be repaired
                      following the road traffic collision?
                    </Accordion.Header>
                    <Accordion.Body>
                      Yes. We provide you with freedom of choice on which garage
                      or repair centre is preferred by you for repairs on your
                      vehicle. Just give us contact details of your preferred
                      repair shop and we’ll take care of everything else and
                      ensure that your vehicle repairs are done at that body
                      shop you chose.
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="3">
                    <Accordion.Header>
                      How do I get a guarantee for fixing my vehicle?
                    </Accordion.Header>
                    <Accordion.Body>
                      In order to ensure the top quality repairs of your
                      vehicle, you should choose a repairer from our approved
                      manufacturer’s network. Repairers that we approve are
                      those who have well-trained technicians and they are able
                      to perform high-quality repair works using parts from the
                      manufacturer only.
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="4">
                    <Accordion.Header>
                      Is there a charge for taking up your accident management
                      services?
                    </Accordion.Header>
                    <Accordion.Body>
                      No. We will not make you financially vulnerable after the
                      car accident. Instead, we will recoup all costs incurred
                      on our accident management services from the insurer of
                      another party.
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="5">
                    <Accordion.Header>
                      For how long can I keep the replacement car?
                    </Accordion.Header>
                    <Accordion.Body>
                      The period of time you can use the replacement vehicle
                      depends on how soon your car is repaired or until you
                      receive an equitable settlement in case of total loss.
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="6">
                    <Accordion.Header>
                      Does my insurance premium go up if I have an accident that
                      wasn’t my fault?
                    </Accordion.Header>
                    <Accordion.Body>
                      No. You won’t pay more on your insurance premium if you
                      don’t cause an accident. The no-fault claims will be made
                      against the negligent party.
                    </Accordion.Body>
                  </Accordion.Item>
                </Accordion>
              </div>
            </div>
          </div>
        </div>
      </section>

      <Footer />
    </>
  );
};

export default Stcentralinsurance;
