import React from "react";
import phonecall from "../assets/images/phonecall.png";
import Footer from "../components/footer";
import  insurancelogo from "../../src/assets/images/insurancelogo.png";
const Termsandconditions  = () => {
  return (
    <>
 <section className="innernav">
      <div className="container">
        <div className="row">
          <div className="col-md-6 col-sm-6">
           <div className="left-logo">
                {/* <img src={insurancelogo} className='insurancelogo' alt='Insurance Logo' /> */}
                <h3>Accident Claims</h3>           
            </div>
       </div>
       <div className="col-md-6 col-sm-6">
          <div className="left-cta">
            <div class="headercnt">
          <div class="chatmsg">
          <img src={phonecall} className="phonecall" alt="phonecall " />
          </div>
          <div class="box-body">
          <h3 class="nav-info-box-title">Toll Free Number </h3>
          <p><a href="tel:0800 772 0850">0800 772 0850</a>
          </p>
          </div>
          </div>
          </div>
                      
           
          </div>
        </div>
      </div>
     </section>
     <section className="privacybnr" >
        <div className="container">
            <div className="row">
                <div className="col-xxl-12 col-12">
                    <h2>Terms & conditions</h2>
                </div>
            </div>
        </div>
     </section>
     <section className="privacy-content">
    <div className="container">
        <div className="row">
        <div className="col-xxl-12 col-12">
        <div className="consent">
         <h2 className="prolu">Insurance accident claims<br />
         <span className="light">Terms and conditions</span>  </h2>
          <p className="safeguarding">
          This website is owned and operated by Insurance Accident Claims. We are dedicated to providing no-fault accident management services that comply with relevant regulations.
          Please review these terms and conditions carefully before using our website. By registering on or using our website you agree to be bound by these terms and conditions. If you don't agree to these terms and conditions, please stop using our website. 
          </p>

         <p className="bold-t">Use of the website  </p>
          <p className="safeguarding">
          We provide Users with a limited, personal, and non-transferable right to use our website, subject to adherence to these terms and conditions. By using our website, you agree not to:
          </p>
         
          <ul className="prilisting">
            <li>Violate any applicable laws, regulations, or codes of conduct.</li>
            <li>Publish or transmit any content (including links or references to other content) that is:
        <ol>
          <li>Harmful, offensive, or deceptive</li>
          <li>infringes upon the intellectual property or other rights of others, or</li>
          <li>involves phishing, scamming, or similar activities</li>
        </ol>
  </li>
            <li>Utilize the website for commercial purposes or engage in activities such as junk mail, spam, pyramid schemes, or fraudulent schemes</li>
            <li>Engage in behavior that may negatively impact other Users' experience on the website</li>
            <li>Access the website or its equipment without proper authorization</li>
            <li>Engage in any actions that may damage our reputation</li>
            <li>Provide inaccurate or misleading information </li>
          </ul>

           <p className="bold-t">
           Intellectual property rights 
          </p>
          <p className="safeguarding">
          All trademarks, logos, content, graphics, images, photographs, animations, videos, text, and software displayed on the website are our intellectual property. You may view such material on your screen and print a single copy for personal use only. Any other use, including but not limited to sublicensing, retrieval, display, modification, copying, selling, distribution, downloading, hiring, reverse engineering or creation of extracts or derivative works, requires our explicit prior written consent.

          </p>
        

           <p className="bold-t">Third-party website links </p>
           <p className="safeguarding">
           Our website may contain links to other third-party websites. We do not endorse or recommend any third-party sites or services, and we are not legally liable for them. Users utilize such sites or services at their own risk. You’re advised to read the privacy policy and terms of such websites before providing them with your personal information. 
           </p>

            <p className="bold-t">Limitation of liability </p>
           <p className="safeguarding">
           Nothing in this agreement shall limit or exclude our liability for negligence resulting in death or personal injury, fraud, fraudulent misrepresentation, or any liability that cannot be legally excluded or limited. References to 'us' in this section include our employees and agents. You are responsible for any foreseeable loss or damage we incur due to your breach of this agreement or misuse of our website, subject to our duty to mitigate losses.
           </p>
           <p className="safeguarding">
           Before incurring any costs to remedy a matter, you must provide us with a reasonable opportunity to address it. We shall not be liable for loss or damage caused by us, our employees, or agents in circumstances where:
           </p>
           <ul className="prilisting">
           <li>There is no breach of a legal duty of care owed to you by us, our employees, or agents.</li>
           <li>The loss or damage was unforeseeable.</li>
           <li>The loss or damage is caused by your non-compliance with this agreement.</li>
           <li>The loss or damage is business-related.</li>
           </ul>
        
            <p className="bold-t">Disclaimer </p>
           <p className="safeguarding">
           The information provided on this website is for general informational purposes only and does not constitute professional advice. While we strive to ensure the accuracy and completeness of the information presented, we make no representations or warranties of any kind, express or implied, about the reliability, suitability, or availability of the information, products, services, or related graphics contained on the website for any purpose. Any reliance you place on such information is therefore strictly at your own risk.
           </p>
           <p className="safeguarding">
           In no event will we be liable for any loss or damage, including without limitation, indirect or consequential loss or damage, or any loss or damage arising from loss of data or profits arising out of, or in connection with, the use of this website.
           </p>
            <p className="bold-t">Force majeure  </p>
           <p className="safeguarding">In the event that we are unable to perform any obligation under these terms and conditions due to circumstances beyond our reasonable control, we shall not be liable for any failure to perform such obligation. 
            </p>
        
             <p className="bold-t">Waiver  </p>
            <p className="safeguarding">
            We reserve the right to enforce these terms at any time, and failure to enforce any provision of these terms does not waive our right to enforce such provision in the future.
            </p>
            <p className="bold-t">Governing law </p>
            <p className="safeguarding">
            Our policy is governed by the laws of the United Kingdom. Any dispute arising between the parties shall be resolved in accordance with the English Laws. 
            </p>
            <p className="bold-t">Changes to the terms  </p>
            <p className="safeguarding">
            We reserve the right to modify these terms and conditions periodically. You’re advised to visit this page regularly to stay informed about the updated changes. 
            </p>
             <p className="bold-t">Contact us   </p>
            <p className="safeguarding">
            If you have any questions or queries regarding these terms and conditions, feel free to contact us at <strong><a href="mailto:info@insurance-accident-claims.co.uk">info@insurance-accident-claims.co.uk</a></strong>. 
            </p>

          </div>   
          </div>
        </div>
    </div>
 </section>  


 <Footer />

    
    </>
  );
};

export default Termsandconditions;
