import React, { useRef } from "react";
import Accordion from "react-bootstrap/Accordion";
import check from "../assets/images/check.png";
import dualcar from "../../src/assets/images/dualcar.webp";
import hasting from "../../src/assets/images/hasting.webp";
import Sidebarinner from "../components/Sidebar-inner";
import phonecall from "../assets/images/phonecall.png";
import Footer from "../components/footer";
import { Helmet } from "react-helmet";

const Mitsubishimotorinsurance = () => {
    const sidebarRef = useRef(null);

    const handleScrollToSidebar = () => {
        if (sidebarRef.current) {
            sidebarRef.current.scrollIntoView({ behavior: "smooth" });
        }
    };
    return (
        <>
        <Helmet>
        <meta charSet="utf-8" />
        <title>Mitsubishi Car Insurance Accident Claim Phone Number</title>
        <meta
          name="description"
          content="Planning to file a no-fault accident claim? Connect with our solicitors on the Mitsubishi Car Insurance Accident Claim Phone Number 0800 772 0850 for professional advice. "
        />
        <link
          rel="canonical"
          href="https://insurance-accident-claims.co.uk/Mitsubishi-motor-insurance"
        />
      </Helmet>
            <section className="innernav">
                <div className="container">
                    <div className="row">
                        <div className="col-md-6 col-sm-6">
                            <div className="left-logo">
                                <h3>
                                    Accident<span className="sipo"> Claims</span>
                                </h3>
                            </div>
                        </div>
                        <div className="col-md-6 col-sm-6">
                            <div className="left-cta">
                                <div class="headercnt">
                                    <div class="chatmsg">
                                        <img
                                            src={phonecall}
                                            className="phonecall"
                                            alt="phonecall "
                                        />
                                    </div>
                                    <div class="box-body">
                                        <h3 class="nav-info-box-title">Toll Free Number </h3>
                                        <p>
                                            <a href="tel:0800 772 0850">0800 772 0850</a>
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section
                className="newbnr"
                style={{ backgroundImage: "url(" + hasting + ")" }}
            >
                <div className="container">
                    <div className="row">
                        <div className="col-xxl-7 col-xl-7 col-md-10 col-12">
                            <div className="innerbnr">
                                <h1>
                                    <span className="onespan">No-fault motor insurance </span>{" "}
                                    <span className="sci">claims </span> support
                                </h1>
                                <p className="brokerage">
                                From arranging the recovery of your vehicle to ensuring deserving compensation for your claim, we have got your back.                    </p>
                                <div className="bnrbtn">
                                    <a class="db-btn-border btn-rollover phone" href="tel:0800 772 0850">
                                        Road Accident Claim Help?{" "}
                                        <i class="fa-solid fa-arrow-right"></i>
                                    </a>
                                </div>
                                <div className="bnrbtn">
                                    <a className="db-btn-border btn-rollover desktop" onClick={handleScrollToSidebar}>
                                        Road Accident Claim Help? <i className="fa-solid fa-arrow-right"></i>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="stickysection">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="group-4-8">
                                <div className="cl8 tf-tab">
                                    <div className="content-tab">
                                        <div className="inner-content">
                                            <h2>
                                            We’re the UK’s leading accident
                                                <br />
                                                <span className="light">management experts</span>{" "}
                                            </h2>
                                            <p className="disciplines">
                                            If you do not know how to make a<strong> Mitsubishi car insurance claim </strong> or need any other assistance after the accident, feel free to call us on the <strong> Mitsubishi insurance claims phone number, </strong>  and we will help you out immediately without burdening you with any financial obligation.     
                                            </p>
                                            <hr />
                                            <p className="bold-text">No extra charges.</p>
                                            <p className="bottom-te">If the accident is not your mistake, you don’t have to pay any excess or upfront charges.                                         </p>
                                            <hr />
                                            <p className="bold-text">Protected no-claims bonus</p>
                                            <p className="bottom-te">
                                            We do not file a claim against your own policy. As a result, your no-claims bonus will stay protected.                                           </p>
                                            <hr />
                                            <p className="bold-text">
                                            Fast vehicle recovery 
                                            </p>
                                            <p className="bottom-te">
                                            Get in touch with us via the <strong> Mitsubishi insurance claim phone number </strong>  to get your vehicle recovered from the accident scene without any delay.                                             </p>
                                            <hr />
                                            <p className="bold-text">Manufacturer-approved repairs  </p>
                                            <p className="bottom-te">
                                            We have approved repairers who will repair your vehicle according to manufacturer-approved specifications.                                          </p>
                                            <hr />
                                            <p className="bold-text">Comparable replacement </p>
                                            <p className="bottom-te">
                                            When you have an accident or your car is unroadworthy, we will provide you with a similar replacement vehicle that matches your own vehicle.                                          </p>
                                            <hr />
                                            <p className="bold-text">No-fault claim experts </p>
                                            <p className="bottom-te">
                                            Our experienced solicitors have expertly handled Mitsubishi insurance claims and have helped many innocent accident victims to get the deserving compensation. 
                                            </p>
                                            <hr />
                                            <p className="bold-text">Quick claim settlement</p>
                                            <p className="bottom-te">
                                            Our simplified and streamlined claim process requires very minimal paperwork, allowing you to make your claim without any paperwork headaches.                                        </p>
                                            <hr />
                                            <p className="bold-text">No-obligation advice </p>
                                            <p className="bottom-te">
                                            Call  <strong>
                                                    {" "}
                                                    <a href="tel:0800 772 0850">0800 772 0850</a>{" "}
                                                </strong> for all inquiries concerning <strong> Mitsubishi insurance claims. </strong> After assessing your situation, our solicitors shall advise you on how best to go ahead with the proceedings.                                            </p>
                                            <hr />
                                            <p className="bold-text">No win-no fee</p>
                                            <p className="bottom-te">
                                            Our solicitors operate through a contingent fee system. If your claim is not successful, you will not have to pay a single penny.                                      </p>
                                            <hr />

                                            <p className="bold-text">24/7 availability  </p>
                                            <p className="bottom-te">
                                            We’re available round the clock to assist you with your accident management needs. Call us on the <strong> Mitsubishi insurance claim phone number </strong>  for immediate assistance after a road traffic accident.
                                                </p>
                                        </div>
                                    </div>
                                </div>
                                <div className="cl4" ref={sidebarRef}>
                                    <Sidebarinner />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="photographs">
                <div className="container">
                    <div className="row">
                        <h2 className="road">
                        We strive to make your post-accident   {" "}
                            <span className="light">
                            experience <br />
                          as stress-free as possible 
                            </span>{" "}
                        </h2>
                        <div className="col-xxl-6 col-xl-6 col-md-12 col-12">
                            <div className="fourbox">
                                <ul>
                                    <li>
                                        <img src={check} className="check" alt="" />
                                        If an accident occurs, call us on the <strong> Mitsubishi car insurance contact number </strong>  for emergency roadside assistance.

                                    </li>
                                    <hr />
                                    <li>
                                        <img src={check} className="check" alt="" />
                                        We will identify the party who caused the accident and prepare your claims accordingly.                                </li>
                                    <hr />
                                    <li>
                                        <img src={check} className="check" alt="" />
                                        We will manage all paperwork and documents related to your claim and work tirelessly to ensure the best outcome for your <strong> Mitsubishi insurance claim. </strong> 
                                    </li>
                                    <hr />
                                    <li>
                                        <img src={check} className="check" alt="" />
                                        We shall collect the car from the accident scene, and we shall arrange its repairs through a manufacturer-approved repairer.                      </li>
                                </ul>
                            </div>
                        </div>
                        <div className="col-xxl-6 col-xl-6 col-md-12 col-12">
                            <div className="fourbox">
                                <ul>
                                    <li>
                                        <img src={check} className="check" alt="" />
                                        If your vehicle is beyond economic repair, an external engineer will be appointed to calculate damages to your motor vehicle.                                </li>
                                    <hr />
                                    <li>
                                        <img src={check} className="check" alt="" />
                                        We will provide you with a temporary replacement vehicle until your claim is settled. In addition, regular inspections will also be conducted during your vehicle repairs so as to keep track of vehicle repairs progress.                               </li>
                                    <hr />
                                    <li>
                                        <img src={check} className="check" alt="" />
                                        We will connect you with experienced solicitors who will provide professional guidance regarding your claim.                               </li>
                                    <hr />
                                    <li>
                                        <img src={check} className="check" alt="" />
                                        Call us on  <strong>
                                            {" "}
                                            <a href="tel:0800 772 0850">0800 772 0850</a>{" "}
                                        </strong>  , and we will guide you through the process of making a successful claim for damages and losses. 
                                        </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="carprocess">
                <div className="container ">
                    <div className="row">
                        <div className="col-xxl-12 col-xxl-12 col-md-12 col-12">
                            <h2 className="trusted-title">
                                {" "}
                                Accident management services   <br />
                                <span className="light"> we offer </span>{" "}
                            </h2>
                        </div>
                    </div>
                    <div className="row" id="possible">
                        <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-12">
                            <div className="claim">
                                <ul>
                                    <li>
                                        <span className="sleej">Roadside Assistance:</span> In case your vehicle has to be moved from the accident scene, please get in touch with us via the <strong>  Mitsubishi car insurance claims contact number.</strong> We will immediately dispatch our team to arrange a quick recovery of your vehicle from the accident scene into our storage yard.    
                                    </li>
                                    <li>
                                        <span className="sleej">Approved repairs :</span> Get in touch with us via the <strong> Mitsubishi insurance claims number, </strong> and let us organise speedy repairs of your vehicle from our manufacturer-approved repairers.     
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-12">
                            <div className="claim" id="blue">
                                <ul>
                                    <li>
                                        <span className="sleej">Car storage :</span> Want to store your car in a secure place after a road traffic accident? Call us through the <strong> Mitsubishi car insurance claim number.</strong>  We will store your vehicle in our storage yards until your claim is settled, preventing it from further damage and deterioration.    
                                    </li>
                                    <li>
                                        <span className="sleej">Replacement vehicle :</span> We will provide you with a suitable replacement car if your vehicle cannot be repaired. Dial our emergency hotline
                                        <strong>
                                            {" "}
                                            <a href="tel:0800 772 0850">0800 772 0850</a>{" "}
                                        </strong>
                                        to get a comparable replacement vehicle that matches your own in terms of size, make and model. You can keep the replacement vehicle until your
                                        <strong> Mitsubishi car insurance claim </strong> gets resolved. 
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-12">
                            <div className="claim">
                                <ul>
                                    <li>
                                        <span className="sleej">Personal injury claims support  </span>
                                        Do you intend to make a claim for injuries resulting from an accident? We have solicitors who can assist you in getting advice regarding compensation for damages or injuries.                                    </li>
                                    <li>
                                        <span className="sleej">Claims handling :</span> We will handle every aspect of your claim, from managing paperwork to negotiating with repairers, arranging vehicle recovery and start-to-finish claims management.   
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="yellowcta">
                <div className="container">
                    <div className="row">
                        <div className="cta2">
                            <div className="col-xxl-8 col-xl-8 col-lg-8 col-12">
                                <div className="legal">
                                    <h3 className="experienced">
                                    Got more questions? Talk to {" "}
                                        <span className="light">our specialists 
                                        </span>{" "}
                                    </h3>
                                    <p className="professionals">
                                    Get in touch with our no-fault claim experts via <strong> car insurance contact number</strong>
                                        {" "}
                                        <strong>{" "}
                                            <a className="cpt-no" href="tel:0800 772 0850">
                                                {" "}
                                                0800 772 0850{" "}
                                            </a>{" "}
                                        </strong>
                                        to get the best advice for your
                                        <strong>  Mitsubishi car insurance claim. 
                                        </strong>
                                    </p>
                                </div>
                            </div>
                            <div className="col-xxl-4 col-xl-4 col-lg-4 col-12">
                                <div class="process">
                                    <a class="tf-button style-1 phone" href="tel:0800 772 0850">
                                        Get in touch<span class="icon-keyboard_arrow_right"></span>
                                    </a>
                                </div>
                                <div class="process">
                                    <a className="tf-button style-1 desktop" onClick={handleScrollToSidebar}>
                                        Get in touch<span className="icon-keyboard_arrow_right"></span>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <search className="updatedform">
                <div className="container">
                    <div className="row text-center">
                        <div className="col-xxl-12 col-xl-12 col-md-12 col-12">
                            <h2 className="whiteheading">
                                {" "}
                                Why should we be your choice for meeting your accident 
                                <span className="light">management requirements?
                                </span>
                            </h2>
                            <p className="sustained">
                            We will alleviate your burden and help you deal with the aftermath of a road traffic accident without any stress. Here are a few of the benefits you'll experience when you choose us as your accident management partner.                 </p>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-xxl-4 col-xl-4 col-lg-6 col-md-12 col-12">
                            <div className="Claimant">
                                <h5>Round-the-clock vehicle recovery </h5>
                                <p className="collision">
                                Are you looking for someone who will recover your vehicle from the accident spot? If yes, kindly use the <strong>Mitsubishi insurance claim number, </strong>  and we shall take care of your vehicle recovery needs at all times. 

                                </p>

                                <h5>Guaranteed Repairs</h5>
                                <p className="collision">
                                We have a network of manufacturer-approved repairers who provide guaranteed and high-quality repairs for your vehicle.                       </p>

                                <h5>.No Increase in Premiums</h5>
                                <p className="collision">
                                Since the claim won’t be made against your own insurance policy, your insurance premiums won't be affected. 
                                </p>
                            </div>
                        </div>
                        <div className="col-xxl-4 col-xl-4 col-lg-6 col-md-12 col-12">
                            <div className="Claimant">
                                <h5>Trusted partners 
                                </h5>
                                <p className="collision">
                                We have collaborated with experienced solicitors and manufacturer-approved repairers in the UK to provide you with high-quality services. </p>
                                <h5>Expedited claim settlement </h5>
                                <p className="collision">
                                Our dedicated solicitors will negotiate with the other party’s insurer and try to settle your claim as soon as possible, besides ensuring maximum compensation.                              </p>

                                <h5>Speedy settlement of claims</h5>
                                <p className="collision">
                                Our experienced solicitors negotiate with other driver’s insurers to ensure your <strong> WiseDriving motor insurance claim </strong>  is settled fairly and quickly.
                                </p>
                            </div>
                        </div>
                        <div className="col-xxl-4 col-xl-4 col-lg-12 col-md-12 col-12">
                            <div className="Claimant">
                                <h5>Quick assistance </h5>
                                <p className="collision">
                                When you call us on the <strong> Mitsubishi car insurance claim number,</strong> you’re guaranteed to receive immediate support.                               </p>

                                <h5>Experienced claims handler </h5>
                                <p className="collision">
                                We will assign you a dedicated claim handler who will take care of your claim from its inception to its settlement.                          </p>

                                <h5>No hidden charges</h5>
                                <p className="collision">
                                You won’t have to pay a single penny for our services as we will recover all costs from the negligent party.                        </p>
                            </div>
                        </div>
                    </div>
                </div>
            </search>

            <section className="Accident-claim">
                <div className="container">
                    <div className="row">
                        <div className="needed">
                            <h2 className="trusted-title">
                            How can you enhance the settlement value <br />
                                <span className="light">of your claim? </span>
                            </h2>
                            <p className="complexities">
                            Want to get maximum compensation for your claim? If yes, then you need to prove that the accident was not your fault. Here are the few steps you should follow after the car accident to prove your innocence and increase the chances of securing maximum compensation.                    </p>
                        </div>
                        <hr />
                    </div>
                    <div className="row" id="incdes">
                        <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-12">
                            <div className="Preparing">
                                <ul>
                                    <li>
                                        <div className="seemingly">
                                            <h5 className="Seek">Stop your car at a safe place :</h5>
                                            <p>
                                                {" "}
                                                Make sure you locate a safe place to stop and park your car and then examine yourself as well as the passengers for any external/ internal injuries.                                      </p>
                                        </div>
                                    </li>
                                    <li>
                                        <div className="seemingly">
                                            <h5 className="Seek">
                                            Visit a doctor  :{" "}
                                            </h5>
                                            <p>
                                            Whenever someone gets injured in a road mishap, try to get immediate treatment from a qualified medical professional.                                         </p>
                                        </div>
                                    </li>
                                    <li>
                                        <div className="seemingly">
                                            <h5 className="Seek">Find Evidence:</h5>
                                            <p>  The evidence can be in the form of photographs of the accident scene, vehicles involved, eyewitness statements and CCTV videos.                                      </p>
                                        </div>
                                    </li>
                                    <li>
                                        <div className="seemingly">
                                            <h5 className="Seek">Report the accident to the Police:</h5>
                                            <p>
                                            Call the Police to inform them about the accident and request a copy of a police report, which can be used as support in your <strong>  Mitsubishi motor insurance claim.   </strong>                                       </p>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div
                            className="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-12"
                            id="dualcardiv"
                        >
                            <img src={dualcar} alt="" className="dualcar" />
                        </div>
                        <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-12">
                            <div className="Preparing">
                                <ul>
                                    <li>
                                        <div className="seemingly">
                                            <h5 className="Seek">Don’t agree to a quick settlement :</h5>
                                            <p>
                                            Do not settle for the first offer that comes your way. Instead, you should seek your solicitor’s advice on what to do to get fair compensation that can cover the losses incurred.                                    </p>
                                        </div>
                                    </li>
                                    <li>
                                        <div className="seemingly">
                                            <h5 className="Seek">Don’t use social media: </h5>
                                            <p>
                                            It is good to avoid using social media as you wait for the claim to be resolved. The reason behind this is that your social media posts can be used by the other party to reduce the amount of compensation they ought to pay.                                            </p>
                                        </div>
                                    </li>
                                    <li>
                                        <div className="seemingly">
                                            <h5 className="Seek">
                                            Exchange appropriate information:
                                            </h5>
                                            <p>
                                            Share with other drivers involved in the accident information, including names, addresses, car registration numbers and particulars, along with insurance details.
                                            </p>
                                        </div>
                                    </li>
                                    <li>
                                        <div className="seemingly">
                                            <h5 className="Seek">
                                            Contact a no-fault compensation solicitor:
                                            </h5>
                                            <p>
                                            We have competent professionals who are ready to handle your case of non-negligence claims while ensuring the maximum compensation possible towards any damage caused.

                                            </p>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="whyus">
                <div className="container">
                    <div className="row">
                        <div className="col-xxl-12 col-md-12">
                            <div className="why">
                                <h2 className="choose">
                                File your claim without any hassle with our   {" "}
                                    <span className="light"> simplified process</span>
                                </h2>
                                <p className="complexities">
                                Making a Mitsubishi car insurance claim may seem to be a daunting task. However, with our simplified claim process, you can start your claim process effortlessly.                     </p>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-xxl-3 col-xl-6 col-lg-6 col-md-6 col-12">
                            <div className="box-service">
                                <img src={check} className="check" alt="" />
                                {/* <img src={experience} className="experience" alt="" /> */}
                                <h4>Contact us  </h4>
                                <p>
                                Get in touch with us via the <strong> Mitsubishi insurance claims number </strong>  to schedule a free consultation with our claim experts.  </p>
                            </div>
                        </div>
                        <div className="col-xxl-3 col-xl-6 col-lg-6 col-md-6 col-12">
                            <div className="box-service">
                                <img src={check} className="check" alt="" />
                                <h4>Share accident details </h4>
                                <p>
                                Describe in detail the exact time, location and how the accident took place.</p>
                            </div>
                        </div>
                        <div className="col-xxl-3 col-xl-6 col-lg-6 col-md-6 col-12">
                            <div className="box-service">
                                <img src={check} className="check" alt="" />
                                <h4>Provide evidence </h4>
                                <p>
                                Share evidence and documents that can help you make your claim stronger.                               </p>
                            </div>
                        </div>
                        <div className="col-xxl-3 col-xl-6 col-lg-6 col-md-6 col-12">
                            <div className="box-service">
                                <img src={check} className="check" alt="" />
                                <h4>Claim initiation</h4>
                                <p>
                                Once we have evaluated your claim, we’ll initiate it and keep you updated on the progress. 

                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="faqs">
                <div className="container">
                    <div className="row">
                        <div className="col-xxl-12 col-xl-12 col-md-12 col-12">
                            <div className="fq">
                                <h2>
                                    {" "}
                                    Frequently Asked{" "}
                                    <span className="light">Questions</span>
                                </h2>
                                <p className="complexities">
                                Do you have any questions regarding <strong> Mitsubishi car insurance claim? </strong> Find them answered here.

                                </p>
                            </div>
                            <div className="allfaq">
                                <Accordion defaultActiveKey="0">
                                    <Accordion.Item eventKey="0">
                                        <Accordion.Header>
                                        Can I get a replacement car without any excess?                                 </Accordion.Header>
                                        <Accordion.Body>
                                        Yes. All costs related to the replacement vehicle will be recovered from the negligent party’s insurer.                                      </Accordion.Body>
                                    </Accordion.Item>
                                    <Accordion.Item eventKey="1">
                                        <Accordion.Header>
                                        Can I choose my own repair shop?
                                        </Accordion.Header>
                                        <Accordion.Body>
                                        Certainly yes. You are at liberty to select your preferred repairer. We can arrange for a garage of your choice for vehicle repairs.                                   </Accordion.Body>
                                    </Accordion.Item>
                                    <Accordion.Item eventKey="2">
                                        <Accordion.Header>
                                        Will my no-claims bonus be affected by using your services?
                                        </Accordion.Header>
                                        <Accordion.Body>
                                        No. Our skilled claims handlers will file direct third-party claims. This means that you will not lose your no-claim bonuses.
                                        </Accordion.Body>
                                    </Accordion.Item>
                                    <Accordion.Item eventKey="3">
                                        <Accordion.Header>
                                        How long can I keep the replacement vehicle?
                                        </Accordion.Header>
                                        <Accordion.Body>
                                        In case your car has been involved in a road traffic accident and is unroadworthy due to undergoing repairs, you may have the replacement vehicles retained until your vehicle has been repaired and is roadworthy again.                                   </Accordion.Body>
                                    </Accordion.Item>
                                    <Accordion.Item eventKey="4">
                                        <Accordion.Header>
                                        Will the replacement vehicle be identical to my vehicle?

                                        </Accordion.Header>
                                        <Accordion.Body>
                                        Our objective is to offer you a like-for-like substitute car regardless of what kind of car you own. Once you contact us through the<strong>  Mitsubishi insurance claim phone number, </strong>we’ll send you a replacement vehicle.                    </Accordion.Body>
                                    </Accordion.Item>
                                    <Accordion.Item eventKey="5">
                                        <Accordion.Header>
                                        Are the repairs guaranteed?{" "}
                                        </Accordion.Header>
                                        <Accordion.Body>
                                        Yes, they are. Our approved by manufacturer’s service providers endeavour to surpass set guidelines specified by manufacturers in order to guarantee quality repairs.                 </Accordion.Body>
                                    </Accordion.Item>
                                    <Accordion.Item eventKey="6">
                                        <Accordion.Header>
                                        Can I recover all the costs I incurred due to the accident?{" "}
                                        </Accordion.Header>
                                        <Accordion.Body>
                                        All expenses, including hospital bills, transportation fares, replacement car costs, repair expenses, lost wages and earnings, will be recovered from the negligent driver. 

                                        </Accordion.Body>
                                    </Accordion.Item>
                                </Accordion>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <Footer />
        </>
    );
};

export default Mitsubishimotorinsurance;
