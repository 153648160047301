import React, { useRef } from "react";
import Accordion from "react-bootstrap/Accordion";
import check from "../assets/images/check.png";
import dualcar from "../../src/assets/images/dualcar.webp";
import carbnr1 from "../../src/assets/images/carbnr1.webp";
import Sidebarinner from "../components/Sidebar-inner";
import phonecall from "../assets/images/phonecall.png";
import Footer from "../components/footer";
import { Helmet } from "react-helmet";

const Chaucergroup = () => {
  const sidebarRef = useRef(null);

  const handleScrollToSidebar = () => {
    if (sidebarRef.current) {
      sidebarRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Chaucer Direct Car Insurance Accident Claim Phone Number:</title>
        <meta
          name="description"
          content="Contact our dedicated team at the Chaucer Direct Car Insurance Accident Claim Phone Number 0800 772 0850 for prompt claim assistance in the UK. Contact us today.  "
        />
        <link
          rel="canonical"
          href="https://insurance-accident-claims.co.uk/chaucer-direct-insurance"
        />
      </Helmet>
      <section className="innernav">
        <div className="container">
          <div className="row">
            <div className="col-md-6 col-sm-6">
              <div className="left-logo">
                <h3>
                  Accident<span className="sipo"> Claims</span>
                </h3>
              </div>
            </div>
            <div className="col-md-6 col-sm-6">
              <div className="left-cta">
                <div class="headercnt">
                  <div class="chatmsg">
                    <img
                      src={phonecall}
                      className="phonecall"
                      alt="phonecall "
                    />
                  </div>
                  <div class="box-body">
                    <h3 class="nav-info-box-title">Toll Free Number </h3>
                    <p>
                      <a href="tel:0800 772 0850">0800 772 0850</a>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section
        className="newbnr"
        style={{ backgroundImage: "url(" + carbnr1 + ")" }}
      >
        <div className="container">
          <div className="row">
            <div className="col-xxl-8 col-xl-8 col-md-10 col-12">
              <div className="innerbnr">
                <h1>
                  <span className="onespan">Car accident </span>{" "}
                  <span className="sci"> management</span> and claim
                  specialists in the UK
                </h1>
                <p className="brokerage">
                  With every single step covered from the time you report a car
                  accident to the time your no-fault claim is managed, we’ve got
                  you all the way.
                </p>
                <div className="bnrbtn">
                  <a class="db-btn-border btn-rollover phone" href="tel:0800 772 0850">
                    Road Accident Claim Help?{" "}
                    <i class="fa-solid fa-arrow-right"></i>
                  </a>
                </div>
                <div className="bnrbtn">
                <a className="db-btn-border btn-rollover desktop"  onClick={handleScrollToSidebar}>
                    Road Accident Claim Help? <i className="fa-solid fa-arrow-right"></i>
                </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="stickysection">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="group-4-8">
                <div className="cl8 tf-tab">
                  <div className="content-tab">
                    <div className="inner-content">
                      <h2>
                        Comprehensive claims management <br />
                        <span className="light">without any excess </span>{" "}
                      </h2>
                      <p className="disciplines">
                        Being involved in a no-fault car accident can be
                        stressful. We’ll relieve you from such by providing
                        comprehensive claims assistance related to no-fault
                        vehicle accidents without any deductible throughout.
                        Call us at{" "}
                        <strong> Chaucer Direct claim contact number</strong> to
                        start your claim with us.
                      </p>
                      <hr />
                      <p className="bold-text">No excess</p>
                      <p className="bottom-te">
                        We realise that car accidents can be traumatic. Thus, we
                        offer you peace of mind at no extra cost. When you call
                        us on{" "}
                        <strong>
                          the Chaucer Direct car insurance claims number{" "}
                        </strong>{" "}
                        for accident management, there will be zero excess
                        charged by us.{" "}
                      </p>
                      <hr />
                      <p className="bold-text">No impact on no-claims bonus</p>
                      <p className="bottom-te">
                        Call <a href="tel:0800 772 0850">0800 772 0850</a> for
                        claims assistance on car insurance. We shall ensure that
                        all your accumulated discounts are still intact.
                      </p>
                      <hr />
                      <p className="bold-text">
                        Quick and efficient claims processing
                      </p>
                      <p className="bottom-te">
                        By having our streamlined and hassle-free{" "}
                        <strong>
                          Chaucer Direct insurance claims process{" "}
                        </strong>
                        , we guarantee quick handling of your claim so that you
                        get what is rightfully yours as soon as possible.{" "}
                      </p>
                      <hr />
                      <p className="bold-text">Quality repairs </p>
                      <p className="bottom-te">
                        We strive to offer top-quality repairs for your damaged
                        car with our trusted and vetted network of approved
                        repairers in the UK.{" "}
                      </p>
                      <hr />
                      <p className="bold-text">Like-for-like replacement</p>
                      <p className="bottom-te">
                        Had a no-fault accident and want a replacement vehicle?
                        Ring us at{" "}
                        <strong>Chaucer Direct claims contact number. </strong>
                        We will give you a like-for-like loaner car that is the
                        same, make, model and size as yours.
                      </p>
                      <hr />
                      <p className="bold-text">Injury claims </p>
                      <p className="bottom-te">
                        We can help you get professional advice from experienced
                        solicitors in the UK about claiming compensation for
                        injuries caused by no-fault accidents.
                      </p>
                      <hr />
                      <p className="bold-text">Dedicated claims handler </p>
                      <p className="bottom-te">
                        Throughout the process, our dedicated claims handler
                        will update you on the progress of your claim and advise
                        you on how to proceed with your{" "}
                        <strong>Chaucer Direct accident claim. </strong>
                      </p>
                      <hr />
                      <p className="bold-text">24/7 assistance</p>
                      <p className="bottom-te">
                        Our lines are open day and night. To get in touch with
                        us regarding{" "}
                        <strong>Chaucer Direct car insurance claims</strong>,
                        please call us now at{" "}
                        <strong>
                          {" "}
                          the Chaucer Direct claims contact number.
                        </strong>{" "}
                      </p>
                      <hr />
                      <p className="bold-text">Friendly and supportive team</p>
                      <p className="bottom-te">
                        Our friendly team will relieve all the worry and stress
                        in making the claim, ensuring quick settlement as soon
                        as possible.{" "}
                      </p>
                      <hr />

                      <p className="bold-text">Continuous claims tracking</p>
                      <p className="bottom-te">
                        Until it is over, we will always keep you posted about
                        the developments on{" "}
                        <strong> the Chaucer Direct accident claim. </strong>
                      </p>
                    </div>
                  </div>
                </div>
                <div className="cl4" ref={sidebarRef}>
                  <Sidebarinner />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="photographs">
        <div className="container">
          <div className="row">
            <h2 className="road">
              We provide helpful assistance for fast{" "}
              <span className="light">
                recovery <br />
                after a car accident
              </span>{" "}
            </h2>
            <div className="col-xxl-6 col-xl-6 col-md-12 col-12">
              <div className="fourbox">
                <ul>
                  <li>
                    <img src={check} className="check" alt="" />
                    After an accident that was not your fault, we have a team of
                    dedicated claims experts ready to help get you back on
                    track.
                  </li>
                  <hr />
                  <li>
                    <img src={check} className="check" alt="" />
                    To get help in the recovery process, call{" "}
                    <strong>
                      {" "}
                      Chaucer Direct ’s Claims accident claim helpline.{" "}
                    </strong>
                    Our team will collect your car from the accident scene.
                  </li>
                  <hr />
                  <li>
                    <img src={check} className="check" alt="" />
                    We will initiate the{" "}
                    <strong>Chaucer Direct accident claim </strong> Process for
                    you so that you can be compensated for all damages and
                    injuries.
                  </li>
                  <hr />
                  <li>
                    <img src={check} className="check" alt="" />
                    We can also assist you with claiming out-of-pocket expenses
                    resulting from the car accident.
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-xxl-6 col-xl-6 col-md-12 col-12">
              <div className="fourbox">
                <ul>
                  <li>
                    <img src={check} className="check" alt="" />
                    Our main role is to liaise with all other parties involved
                    in the accident on your behalf for quick settlement of the{" "}
                    <strong> Chaucer Direct insurance accident claim.</strong>
                  </li>
                  <hr />
                  <li>
                    <img src={check} className="check" alt="" />
                    Our team will organise repairs through our established
                    repair network for your vehicle if it is damaged.
                  </li>
                  <hr />
                  <li>
                    <img src={check} className="check" alt="" />
                    In case you require a replacement vehicle, give us a call at{" "}
                    <strong>
                      {" "}
                      Chaucer Direct Insurance claims phone number{" "}
                    </strong>{" "}
                    anytime. We will provide you with a similar car as a
                    substitute.
                  </li>
                  <hr />
                  <li>
                    <img src={check} className="check" alt="" />
                    If your vehicle has been written off, we can bargain for an
                    equitable sum as compensation.
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="carprocess">
        <div className="container ">
          <div className="row">
            <div className="col-xxl-12 col-xxl-12 col-md-12 col-12">
              <h2 className="trusted-title">
                {" "}
                Our wide range of accident <br />
                <span className="light">management solutions</span>{" "}
              </h2>
            </div>
          </div>
          <div className="row" id="possible">
            <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-12">
              <div className="claim">
                <ul>
                  <li>
                    <span className="sleej">Vehicle recovery :</span>24 hours a
                    day, 365 days a year, we provide immediate assistance in
                    recovering your damaged vehicle. Get in touch with us
                    through{" "}
                    <strong> Chaucer Direct claims contact number </strong>, and
                    our dedicated team for recovery will bring it from the
                    accident scene to a safe place.
                  </li>
                  <li>
                    <span className="sleej">Vehicle repairs:</span>Through our
                    network of approved repairers across the UK, we are able to
                    guarantee full vehicle repairs on time and professionally.
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-12">
              <div className="claim" id="blue">
                <ul>
                  <li>
                    <span className="sleej">Vehicle storage:</span> If your car
                    is deemed not fit enough for the road, we shall recover it
                    from the scene of the accident and transport it to our
                    secured storage facilities. Our well-run storage facility
                    has enough space where you can keep your vehicle until you
                    receive payment for its repair.
                  </li>
                  <li>
                    <span className="sleej">Replacement vehicle:</span>We will
                    provide you with a like-for-like replacement vehicle free of
                    charge regardless of the type of motor car you drive. We
                    offer non-faulty drivers similar cars to help them get back
                    on the road as soon as possible.
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-12">
              <div className="claim">
                <ul>
                  <li>
                    <span className="sleej">Injury claim support:</span>
                    You will be able to receive damages for your injuries and
                    other losses after a no-fault car accident. Our solicitors
                    can help you in making successful
                    <strong> Chaucer Direct insurance claims </strong>so that
                    you get compensated for your injuries.
                  </li>
                  <li>
                    <span className="sleej">Claims management:</span>It may be
                    tough to deal with your claim by yourself. Our non-fault
                    claim experts will take care of your claim from beginning to
                    end, professionally and conscientiously, drawing on their
                    wealth of experience and expertise.
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="yellowcta">
        <div className="container">
          <div className="row">
            <div className="cta2">
              <div className="col-xxl-8 col-xl-8 col-lg-8 col-12">
                <div className="legal">
                  <h3 className="experienced">
                    Got more questions?{" "}
                    <span className="light">Talk to our specialists</span>{" "}
                  </h3>
                  <p className="professionals">
                    Get in touch with our no-fault claim experts via{" "}
                    <strong>
                      claims contact number{" "}
                      <a className="cpt-no" href="tel:0800 772 0850">
                        {" "}
                        0800 772 0850{" "}
                      </a>{" "}
                    </strong>
                    to get the best advice regarding your{" "}
                    <strong> Chaucer Direct accident claim.</strong>
                  </p>
                </div>
              </div>
              <div className="col-xxl-4 col-xl-4 col-lg-4 col-12">
              <div class="process">
                  <a class="tf-button style-1 phone" href="tel:0800 772 0850">
                    Get in touch<span class="icon-keyboard_arrow_right"></span>
                  </a>
                </div>
                <div class="process">
                    <a className="tf-button style-1 desktop"  onClick={handleScrollToSidebar}>
                        Get in touch<span className="icon-keyboard_arrow_right"></span>
                    </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <search className="updatedform">
        <div className="container">
          <div className="row text-center">
            <div className="col-xxl-12 col-xl-12 col-md-12 col-12">
              <h2 className="whiteheading">
                {" "}
                Why choose us for Chaucer Direct car <br />
                <span className="light">insurance claims management? </span>
              </h2>
              <p className="sustained">
                We are the people you trust to collaborate with you in smoothing
                through all aspects of{" "}
                <strong> Chaucer Direct insurance claims. </strong> With our
                constant guidance and backing, you can avoid the headache of
                self-management of your claims and start driving again without
                much ado. Call us on{" "}
                <strong> Chaucer Direct claims contact number </strong> to get
                started now.
              </p>
            </div>
          </div>
          <div className="row">
            <div className="col-xxl-4 col-xl-4 col-lg-6 col-md-12 col-12">
              <div className="Claimant">
                <h5>Complete accident support</h5>
                <p className="collision">
                  From vehicle recovery through arranging repairs, providing
                  rental vehicles as well and handling all aspects concerning
                  your <strong> Chaucer Direct accident claim </strong> , we
                  offer complete accident aftercare.
                </p>

                <h5>Round-the-clock support</h5>
                <p className="collision">
                  You’ll find us ready and available for your accident
                  management needs 24/7. So, you can call us anytime for
                  immediate help regarding a no-fault road traffic accident.{" "}
                </p>

                <h5>No-win, no fee</h5>
                <p className="collision">
                  If you fail to win your{" "}
                  <strong> Chaucer Direct car insurance claim </strong> , then
                  there will be no cost for the legal fee for your claim.
                </p>
              </div>
            </div>
            <div className="col-xxl-4 col-xl-4 col-lg-6 col-md-12 col-12">
              <div className="Claimant">
                <h5>Fast processing of claims</h5>
                <p className="collision">
                  With our stress-free claims process, we ensure that your{" "}
                  <strong> Chaucer Direct insurance claims </strong> are settled
                  swiftly without any hassles or unnecessary delays.
                </p>
                <h5>Approved repair network</h5>
                <p className="collision">
                  We have an approved repair network which consists of carefully
                  selected manufacturers’ garages that offer excellent repairs
                  to the highest standards.
                </p>

                <h5>Advice from experts in handling claims</h5>
                <p className="collision">
                  Our experienced claim solicitors are outstanding when it comes
                  to offering professional advice on{" "}
                  <strong>Chaucer Direct insurance claims. </strong>
                </p>
              </div>
            </div>
            <div className="col-xxl-4 col-xl-4 col-lg-12 col-md-12 col-12">
              <div className="Claimant">
                <h5>Individualised support</h5>
                <p className="collision">
                  Your dedicated claims handler will hold your hands and support
                  you all through the{" "}
                  <strong> Chaucer Direct accident claim</strong> as well as
                  address all your worry points.
                </p>

                <h5>Similar replacement car</h5>
                <p className="collision">
                  As a driver not at fault, we arrange for a similar vehicle to
                  be delivered without having to pay anything upfront.
                </p>

                <h5>Safe storage</h5>
                <p className="collision">
                  We provide storage facilities where you can leave your car as
                  you settle things after an accident situation.
                </p>
              </div>
            </div>
          </div>
        </div>
      </search>

      <section className="Accident-claim">
        <div className="container">
          <div className="row">
            <div className="needed">
              <h2 className="trusted-title">
                How to get the most out of a Chaucer <br />
                <span className="light"> Direct car accident claim?</span>
              </h2>
              <p className="complexities">
                Taking the right actions after a non-fault motor vehicle
                accident is essential for the safety of everyone involved in the
                accident and for successful insurance claims. Follow these steps
                to ensure you receive every penny you are entitled.{" "}
              </p>
            </div>
            <hr />
          </div>
          <div className="row" id="incdes">
            <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-12">
              <div className="Preparing">
                <ul>
                  <li>
                    <div className="seemingly">
                      <h5 className="Seek">Take your car to a safe place:</h5>
                      <p>
                        {" "}
                        Stop your car at a safe place away from other traffic
                        following an accident that was not your fault. Switch
                        off the engine of your vehicle so that gas cannot spill
                        and cause fire.{" "}
                      </p>
                    </div>
                  </li>
                  <li>
                    <div className="seemingly">
                      <h5 className="Seek">Assess yourself: </h5>
                      <p>
                        Check if there is any pain or injuries on you as well as
                        any other occupants in the vehicle and promptly get
                        medical help from an experienced healthcare provider to
                        heal your wounds before it is too late.
                      </p>
                    </div>
                  </li>
                  <li>
                    <div className="seemingly">
                      <h5 className="Seek">Write everything down:</h5>
                      <p>
                        Document all crucial information about the accident,
                        such as date, time, location, weather condition, nature
                        of road surface or anything else that can form a strong
                        basis for claiming compensation.
                      </p>
                    </div>
                  </li>
                  <li>
                    <div className="seemingly">
                      <h5 className="Seek">
                        Capture pictures as well as record videos
                      </h5>
                      <p>
                        As much as possible capture photographs and videos on
                        how the incident happened and also on vehicles involved
                        in different perspectives. This will serve as key visual
                        evidence to back up your claim.
                      </p>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
            <div
              className="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-12"
              id="dualcardiv"
            >
              <img src={dualcar} alt="" className="dualcar" />
            </div>
            <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-12">
              <div className="Preparing">
                <ul>
                  <li>
                    <div className="seemingly">
                      <h5 className="Seek">Keep records of your health:</h5>
                      <p>
                        Record each visit to the doctor, any prescribed
                        medicines and all treatments performed by a medical
                        specialist.{" "}
                      </p>
                    </div>
                  </li>
                  <li>
                    <div className="seemingly">
                      <h5 className="Seek"> Exchange data</h5>
                      <p>
                        Contact other drivers in an accident and exchange
                        information, such as their names, contact details,
                        insurance policy number and vehicle registration number.
                      </p>
                    </div>
                  </li>
                  <li>
                    <div className="seemingly">
                      <h5 className="Seek">
                        Contact people who witnessed the accident:
                      </h5>
                      <p>
                        Make contact with those who were present at the site of
                        the incident. They might have some valuable facts
                        concerning how the occurrence took place hence making
                        your case stronger.{" "}
                      </p>
                    </div>
                  </li>
                  <li>
                    <div className="seemingly">
                      <h5 className="Seek">File a claim</h5>
                      <p>
                        To start your{" "}
                        <strong> Chaucer Direct accident claim </strong>{" "}
                        process, call us at the{" "}
                        <strong> Chaucer Direct claims contact number </strong>{" "}
                        and get your deserving compensation for the damages
                        caused.
                      </p>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="whyus">
        <div className="container">
          <div className="row">
            <div className="col-xxl-12 col-md-12">
              <div className="why">
                <h2 className="choose">
                  Start your Chaucer Direct accident{" "}
                  <span className="light"> claim in 4 simple steps </span>
                </h2>
                <p className="complexities">
                  Would you like to file a{" "}
                  <strong> Chaucer Direct accident claim? </strong>These are the
                  four simple steps to follow if you want to initiate our claims
                  process without being overwhelmed by paperwork or
                  documentation.
                </p>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-xxl-3 col-xl-6 col-lg-6 col-md-6 col-12">
              <div className="box-service">
                <img src={check} className="check" alt="" />
                {/* <img src={experience} className="experience" alt="" /> */}
                <h4>Get in touch with us</h4>
                <p>
                  Reach us through{" "}
                  <strong> Chaucer Direct claims contact number </strong> , and
                  brief us about the accident.
                </p>
              </div>
            </div>
            <div className="col-xxl-3 col-xl-6 col-lg-6 col-md-6 col-12">
              <div className="box-service">
                <img src={check} className="check" alt="" />
                <h4> Give info </h4>
                <p>
                  Tell me your policy number, vehicle registration numbers, and
                  eyewitness statements, including the crime reference number.
                </p>
              </div>
            </div>
            <div className="col-xxl-3 col-xl-6 col-lg-6 col-md-6 col-12">
              <div className="box-service">
                <img src={check} className="check" alt="" />
                <h4> Share proof </h4>
                <p>
                  Submit evidence you have gathered and relevant papers required
                  for commencing the claims process.{" "}
                </p>
              </div>
            </div>
            <div className="col-xxl-3 col-xl-6 col-lg-6 col-md-6 col-12">
              <div className="box-service">
                <img src={check} className="check" alt="" />
                <h4> Claim verification and submission </h4>
                <p>
                  We will verify your claim, and if the details are found to be
                  correct and true, your claim will be accepted and processed.{" "}
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="faqs">
        <div className="container">
          <div className="row">
            <div className="col-xxl-12 col-xl-12 col-md-12 col-12">
              <div className="fq">
                <h2>
                  {" "}
                  Frequently Asked{" "}
                  <span className="light">Questions (FAQ)</span>
                </h2>
                <p className="complexities">
                  Got questions about the{" "}
                  <strong> Chaucer Direct insurance claims? </strong>We have
                  them answered here.
                </p>
              </div>
              <div className="allfaq">
                <Accordion defaultActiveKey="0">
                  <Accordion.Item eventKey="0">
                    <Accordion.Header>
                      How long will it take to settle my Chaucer Direct accident
                      claim?
                    </Accordion.Header>
                    <Accordion.Body>
                      We will try our best to settle your claim as soon as
                      possible. Once you provide us with the relevant details,
                      we will provide you with an approximate time limit to
                      resolve your claim.
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="1">
                    <Accordion.Header>
                      Can I choose my own repairer to repair my damaged car?
                    </Accordion.Header>
                    <Accordion.Body>
                      Yes. However, you’ll have to obtain an estimate and get
                      approval, which may take some time. So, it’s better you
                      choose one of our repairers as it will save you time by
                      eliminating the need for estimate approvals.
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="2">
                    <Accordion.Header>
                      Am I entitled to a courtesy car after a no-fault car
                      accident?
                    </Accordion.Header>
                    <Accordion.Body>
                      Yes. We will provide you with a like-for-like replacement
                      vehicle until your vehicle is undergoing repairs at one of
                      our repair centres.
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="3">
                    <Accordion.Header>
                      Can I claim my excess back?{" "}
                    </Accordion.Header>
                    <Accordion.Body>
                      You might have to pay the excess to the garage when your
                      vehicle is undergoing repairs. However, we will help you
                      claim the excess from the at-fault party’s insurer.
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="4">
                    <Accordion.Header>
                      What is the time frame to recover my vehicle at the crash
                      site?
                    </Accordion.Header>
                    <Accordion.Body>
                      Our vehicle recovery services are operational 24 hours a
                      day. Rest assured, whenever you dial our{" "}
                      <strong> Chaucer Direct claims number </strong> , we will
                      send a team of recovery experts right away.
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="5">
                    <Accordion.Header>
                      What kind of help can I expect if my car is written off?{" "}
                    </Accordion.Header>
                    <Accordion.Body>
                      We will find another similar car for you to use while we
                      take your crashed vehicle into repairs in case it is
                      considered unsalvageable due to involvement in an accident
                      on the road.
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="6">
                    <Accordion.Header>
                      How long can I keep the temporary replacement vehicle?{" "}
                    </Accordion.Header>
                    <Accordion.Body>
                      You can keep the replacement vehicle until your car
                      undergoes repair by a specialist at any of our centres. In
                      case your car is declared ‘written-off’, you can use the
                      replacement vehicle until your claim with{" "}
                      <strong> Chaucer Direct insurance claim </strong> has been
                      cleared.
                    </Accordion.Body>
                  </Accordion.Item>
                </Accordion>
              </div>
            </div>
          </div>
        </div>
      </section>

      <Footer />
    </>
  );
};

export default Chaucergroup;
