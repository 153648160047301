import React, { useRef } from "react";
import Accordion from "react-bootstrap/Accordion";
import check from "../assets/images/check.png";
import dualcar from "../../src/assets/images/dualcar.webp";
import carbnr1 from "../../src/assets/images/carbnr1.webp";
import Sidebarinner from "../components/Sidebar-inner";
import phonecall from "../assets/images/phonecall.png";
import Footer from "../components/footer";
import { Helmet } from "react-helmet";

const Adrianflux = () => {
  const sidebarRef = useRef(null);

  const handleScrollToSidebar = () => {
    if (sidebarRef.current) {
      sidebarRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Adrian Flux Car Insurance Accident Claim Phone Number:</title>
        <meta
          name="description"
          content="Contact the Adrian Flux Car Insurance accident claim phone number 0800 772 0850 for immediate support and assistance with your no-fault car insurance claim in the UK. "
        />
        <link
          rel="canonical"
          href="https://insurance-accident-claims.co.uk/adrian-flux"
        />
      </Helmet>
      <section className="innernav">
        <div className="container">
          <div className="row">
            <div className="col-md-6 col-sm-6">
              <div className="left-logo">
                <h3>
                  Accident<span className="sipo"> Claims</span>
                </h3>
              </div>
            </div>
            <div className="col-md-6 col-sm-6">
              <div className="left-cta">
                <div class="headercnt">
                  <div class="chatmsg">
                    <img
                      src={phonecall}
                      className="phonecall"
                      alt="phonecall "
                    />
                  </div>
                  <div class="box-body">
                    <h3 class="nav-info-box-title">Toll Free Number </h3>
                    <p>
                      <a href="tel:0800 772 0850">0800 772 0850</a>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section
        className="newbnr"
        style={{ backgroundImage: "url(" + carbnr1 + ")" }}
      >
        <div className="container">
          <div className="row">
            <div className="col-xxl-8 col-xl-8 col-md-10 col-12">
              <div className="innerbnr">
                <h1>
                  <span className="onespan"> car accident </span>{" "}
                  <span className="sci"> insurance </span> claims specialists
                  in the UK
                </h1>
                <p className="brokerage">
                  From assessing compensation to arranging repairs & replacement
                  cars and managing an Adrian Flux insurance claim, we will take
                  care of everything to ensure that you receive the best
                  possible service after an accident.
                </p>
                <div className="bnrbtn">
                  <a
                    class="db-btn-border btn-rollover phone"
                    href="tel:0800 772 0850"
                  >
                    Road Accident Claim Help?{" "}
                    <i class="fa-solid fa-arrow-right"></i>
                  </a>
                </div>
                <div className="bnrbtn">
                  <a
                    className="db-btn-border btn-rollover desktop"
                    onClick={handleScrollToSidebar}
                  >
                    Road Accident Claim Help?{" "}
                    <i className="fa-solid fa-arrow-right"></i>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="stickysection">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="group-4-8">
                <div className="cl8 tf-tab">
                  <div className="content-tab">
                    <div className="inner-content">
                      <h2>
                        The most dedicated accident
                        <br />
                        <span className="light">
                          management experts in the UK{" "}
                        </span>{" "}
                      </h2>
                      <p className="disciplines">
                        We are a team of trustworthy experts who work for
                        victims of no-fault car accidents and help them restore
                        themselves back to normalcy by getting compensated
                        adequately for all their injuries as well as losses.
                        Talk to us using{" "}
                        <strong>
                          {" "}
                          Adrian Flux's insurance claims phone number{" "}
                        </strong>{" "}
                        so that we may guide you expertly when making{" "}
                        <strong> Adrian Flux insurance claims </strong> in order
                        to seek compensation from the negligent party.
                      </p>
                      <hr />
                      <p className="bold-text">No deductible</p>
                      <p className="bottom-te">
                        We will commence the{" "}
                        <strong> Adrian Flux car insurance claim </strong>{" "}
                        directly with another driver’s insurer. Thus, there will
                        be no need for out-of-pocket payments from your side.{" "}
                      </p>
                      <hr />
                      <p className="bold-text">Expert negotiation</p>
                      <p className="bottom-te">
                        Our solicitors will effectively negotiate with the
                        opposing party’s insurer settling your{" "}
                        <strong> Adrian Flux car insurance claim </strong> at
                        its highest value. Just make your claim with us through
                        a call via the{" "}
                        <strong>
                          {" "}
                          Adrian Flux insurance claims phone number{" "}
                        </strong>{" "}
                        , and we'll start working right away to secure the best
                        outcome for your claim.
                      </p>
                      <hr />
                      <p className="bold-text">Personalised advice</p>
                      <p className="bottom-te">
                        Since each claim is different, our solicitors will
                        examine your case and give you individual advice. If you
                        call us through the{" "}
                        <strong> Adrian Flux insurance claim number </strong> ,
                        you can get free advice from our solicitors’ panel.
                      </p>
                      <p className="bold-text">Simplified process </p>
                      <p className="bottom-te">
                        To avoid any unnecessary delays or complexities in the{" "}
                        <strong> Adrian Flux car insurance claim </strong>{" "}
                        process, we have made it simple and straightforward.
                        Therefore, when you need to contact them about this on
                        claims contact number{" "}
                        <a href="tel:0800 772 0850">0800 772 0850</a> , there
                        will be no complicated forms or long periods of waiting
                        before your matter is dealt with.
                      </p>
                      <hr />
                      <p className="bold-text">Honest approach </p>
                      <p className="bottom-te">
                        In an effort to ensure that our customers are
                        continuously posted about the development of their{" "}
                        <strong>Adrian Flux insurance claims.</strong>
                      </p>
                      <hr />
                      <p className="bold-text">Original repairs</p>
                      <p className="bottom-te">
                        Our authorised repairer will carry out necessary repairs
                        for your vehicle using genuine parts and paint, making
                        sure that it gets done to high standards.
                      </p>
                      <hr />
                      <p className="bold-text">
                        No effect on your no-claims bonus
                      </p>
                      <p className="bottom-te">
                        Your no-claims bonus will not be at risk because all
                        costs associated with an{" "}
                        <strong> Adrian Flux car insurance claim </strong> are
                        paid by the other party involved.
                      </p>
                      <hr />
                      <p className="bold-text">Fast claim processing</p>
                      <p className="bottom-te">
                        We are different from other insurance companies that
                        complicate the claims process. Our simple and direct
                        approach enables us to settle your{" "}
                        <strong>Adrian Flux insurance claim</strong> in no time.
                      </p>
                      <hr />
                      <p className="bold-text">Replacement at par</p>
                      <p className="bottom-te">
                        You will not wait a long time after being involved in a
                        no-fault road accident for you to have another car. You
                        can easily reach us on our{" "}
                        <strong>Adrian Flux accident claims number</strong>,
                        which we will provide you with a like-for-like
                        replacement vehicle free of charge.{" "}
                      </p>
                      <hr />
                      <p className="bold-text">Dedicated claim handler</p>
                      <p className="bottom-te">
                        If you call us on the{" "}
                        <strong>
                          Adrian Flux insurance claims phone number{" "}
                        </strong>
                        , we will connect you with a dedicated claims handler
                        who will be the only person dealing with everything
                        related to your case thus making it easy for all
                        communication regarding your{" "}
                        <strong> Adrian Flux insurance claim </strong>{" "}
                        resolution.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="cl4" ref={sidebarRef}>
                  <Sidebarinner />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="photographs">
        <div className="container">
          <div className="row">
            <h2 className="road">
              We provide dedicated support to take{" "}
              <span className="light">
                away <br />
                post-accident worries
              </span>{" "}
            </h2>
            <div className="col-xxl-6 col-xl-6 col-md-12 col-12">
              <div className="fourbox">
                <ul>
                  <li>
                    <img src={check} className="check" alt="" />
                    We offer unwavering support and emergency aid after an awful
                    car crash.
                  </li>
                  <hr />
                  <li>
                    <img src={check} className="check" alt="" />
                    From recovering your vehicle to finalising your{" "}
                    <strong> Adrian Flux car insurance claim </strong>, we
                    handle each aspect so as to ease the burden off your
                    shoulders.
                  </li>
                  <hr />
                  <li>
                    <img src={check} className="check" alt="" />
                    Contact our team via the{" "}
                    <strong>Adrian Flux insurance claim number </strong> and
                    we'll go above and beyond to arrange a comparable
                    replacement car for you without any inconvenience.
                  </li>
                  <hr />
                  <li>
                    <img src={check} className="check" alt="" />
                    We will give you access to experienced and professional
                    solicitors who are based in the UK and who will be able to
                    help you manage the{" "}
                    <strong> Adrian Flux car insurance claim </strong> until it
                    is closed.
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-xxl-6 col-xl-6 col-md-12 col-12">
              <div className="fourbox">
                <ul>
                  <li>
                    <img src={check} className="check" alt="" />
                    We offer vehicle repairs through manufacturers’ approved
                    garages along with keeping you posted on the progress of
                    your car repair.
                  </li>
                  <hr />
                  <li>
                    <img src={check} className="check" alt="" />
                    Our team will get in touch with all participants of the
                    accident, thus saving your time as well as minimising your
                    burden.
                  </li>
                  <hr />
                  <li>
                    <img src={check} className="check" alt="" />
                    After the completion of repairs on your vehicle, we shall
                    contact you to schedule its collection.
                  </li>
                  <hr />
                  <li>
                    <img src={check} className="check" alt="" />
                    We will take care of everything regarding your{" "}
                    <strong> Adrian Flux insurance claim </strong> , and we do
                    this on a no-win no-fee basis so that you can achieve
                    positive results.
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="carprocess">
        <div className="container ">
          <div className="row">
            <div className="col-xxl-12 col-xxl-12 col-md-12 col-12">
              <h2 className="trusted-title">
                {" "}
                Our assortment of accident <br />
                <span className="light">management solutions</span>{" "}
              </h2>
            </div>
          </div>
          <div className="row" id="possible">
            <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-12">
              <div className="claim">
                <ul>
                  <li>
                    <span className="sleej">Vehicle recovery:</span>Your vehicle
                    would be recovered from the scene without any unnecessary
                    delay or disturbance. With just one call on
                    <strong> Adrian Flux insurance claim number </strong> , our
                    immediate roadside assistance will reach at site for
                    emergency support when it is most required.
                  </li>
                  <li>
                    <span className="sleej">Vehicle repairs:</span>We work with
                    manufacturer-approved repairers to arrange top-quality
                    repairs for your car in an accredited garage of your choice.
                    Call us on <a href="tel:0800 772 0850">0800 772 0850</a> for
                    premium quality repair services for your damaged car.
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-12">
              <div className="claim" id="blue">
                <ul>
                  <li>
                    <span className="sleej">Safe storage:</span> Stranded
                    vehicles aren’t safe. If your vehicle can’t be driven, then
                    it’s not safe to leave it stuck on the roadside. Call our
                    team on <strong> Adrian Flux claims contact number </strong>
                    for secure storage of your vehicle in our yards. We will put
                    your car into our secured facilities and this will help save
                    it from any further destruction or depreciation.
                  </li>
                  <li>
                    <span className="sleej">Replacement vehicle:</span>Involved
                    in a non-fault car accident? Dial us on the{" "}
                    <strong> Adrian Flux accident claims number </strong> , and
                    we'll offer you premium assistance that provides you with an
                    equivalent replacement vehicle ready to hit your road again
                    and keep up with your daily routine without interruption.
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-12">
              <div className="claim">
                <ul>
                  <li>
                    <span className="sleej">Personal injury support:</span>
                    Our personal injury claim experts are professional
                    panellists of qualified solicitors who will ensure that you
                    achieve the best possible outcome, depending on the severity
                    of your injuries. Contact us today and kick-start your
                    <strong> Adrian Flux insurance claim.</strong>
                  </li>
                  <li>
                    <span className="sleej">Accident claim management:</span>Get
                    bespoke accident claim management services tailored to
                    manage every aspect of your claim ranging from initial
                    documentation, liaising with insurers as well as repair
                    arrangements.
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="yellowcta">
        <div className="container">
          <div className="row">
            <div className="cta2">
              <div className="col-xxl-8 col-xl-8 col-lg-8 col-12">
                <div className="legal">
                  <h3 className="experienced">
                    Got more questions?{" "}
                    <span className="light">Talk to our specialists</span>{" "}
                  </h3>
                  <p className="professionals">
                    Get in touch with our no-fault claim experts via{" "}
                    <strong>
                      car insurance claims number{" "}
                      <a className="cpt-no" href="tel:0800 772 0850">
                        {" "}
                        0800 772 0850{" "}
                      </a>{" "}
                    </strong>
                    to get the best advice for your{" "}
                    <strong> Adrian Flux car insurance claim. </strong>
                  </p>
                </div>
              </div>
              <div className="col-xxl-4 col-xl-4 col-lg-4 col-12">
                <div class="process">
                  <a class="tf-button style-1 phone" href="tel:0800 772 0850">
                    Get in touch<span class="icon-keyboard_arrow_right"></span>
                  </a>
                </div>
                <div class="process">
                  <a
                    className="tf-button style-1 desktop"
                    onClick={handleScrollToSidebar}
                  >
                    Get in touch
                    <span className="icon-keyboard_arrow_right"></span>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <search className="updatedform">
        <div className="container">
          <div className="row text-center">
            <div className="col-xxl-12 col-xl-12 col-md-12 col-12">
              <h2 className="whiteheading">
                {" "}
                Why choose us for all your accident <br />
                <span className="light">management needs? </span>
              </h2>
              <p className="sustained">
                We know that a non-fault road traffic accident can be very
                distressing. Nevertheless, we are here to give you full support
                and direction after a no-fault car accident. As your committed
                partner, we will take control of your{" "}
                <strong> Adrian Flux car insurance claim </strong>
                without charging you anything and tirelessly fight for the
                protection of your rights in insurance.
              </p>
            </div>
          </div>
          <div className="row">
            <div className="col-xxl-4 col-xl-4 col-lg-6 col-md-12 col-12">
              <div className="Claimant">
                <h5>No win, No fee </h5>
                <p className="collision">
                  The solicitors that we work with operate on a ‘no win no fee’
                  basis. If your case isn’t successful you won’t have to carry
                  any financial risks connected with{" "}
                  <strong> Adrian Flux car insurance claim.</strong>
                </p>

                <h5>No obligation advice</h5>
                <p className="collision">
                  We don’t hold you legally liable to bring forward a claim.
                  Call <strong> Adrian Flux claims contact number </strong>for
                  general guidance on what you need for your claim.{" "}
                </p>

                <h5>No increase in insurance premium</h5>
                <p className="collision">
                  The claim is not initiated against your own insurance policy.
                  Hence, there is no impact on the cost of such premiums at any
                  rate.
                </p>
              </div>
            </div>
            <div className="col-xxl-4 col-xl-4 col-lg-6 col-md-12 col-12">
              <div className="Claimant">
                <h5>Full range services</h5>
                <p className="collision">
                  Our services span from towing away vehicles as well as
                  processing the entire process from beginning to end within
                  which we represent clients who have suffered road accidents by
                  being behind their wheels.
                </p>
                <h5>Immediate replacement car</h5>
                <p className="collision">
                  Call us on{" "}
                  <strong>
                    {" "}
                    <a href="tel:0800 772 0850">0800 772 0850</a>{" "}
                  </strong>
                  to get a free replacement car to help keep your life running
                  smoothly.
                </p>
                <h5>Secured storage</h5>
                <p className="collision">
                  We can arrange for safe storage of your damaged vehicle in our
                  secure storage yards at no extra cost.{" "}
                </p>
              </div>
            </div>
            <div className="col-xxl-4 col-xl-4 col-lg-12 col-md-12 col-12">
              <div className="Claimant">
                <h5>Clear assessment </h5>
                <p className="collision">
                  Contact our solicitors through{" "}
                  <strong> Adrian Flux insurance claims phone number </strong>
                  so that they may advise you and help negotiate with the other
                  party’s insurer.
                </p>

                <h5>Quality repairs</h5>
                <p className="collision">
                  From our nationwide repair centre, we will organise
                  top-quality repairs that are above expectations.
                </p>

                <h5>Customised advice</h5>
                <p className="collision">
                  Each <strong> Adrian Flux car insurance claim </strong> is
                  unique. We make sure that you receive specific advice based on
                  the exact nature of your claim.
                </p>
              </div>
            </div>
          </div>
        </div>
      </search>

      <section className="Accident-claim">
        <div className="container">
          <div className="row">
            <div className="needed">
              <h2 className="trusted-title">
                What are the ways to ensure you maximise <br />
                <span className="light">
                  {" "}
                  your Adrian Flux insurance claim?
                </span>
              </h2>
              <p className="complexities">
                Car wrecks could happen in an instant, bringing great disruption
                into one’s life. Hence, it becomes imperative to seek
                compensation for such damages. Here are some ways through which
                you can maximise your{" "}
                <strong> Adrian Flux insurance claim </strong> compensation.{" "}
              </p>
            </div>
            <hr />
          </div>
          <div className="row" id="incdes">
            <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-12">
              <div className="Preparing">
                <ul>
                  <li>
                    <div className="seemingly">
                      <h5 className="Seek">Report the accident:</h5>
                      <p>
                        {" "}
                        Whenever there is a no-fault car accident, call the
                        police immediately and report the car wreck. The police
                        report is evidence that can be used when making an{" "}
                        <strong> Adrian Flux car insurance claim. </strong>
                      </p>
                    </div>
                  </li>
                  <li>
                    <div className="seemingly">
                      <h5 className="Seek">Get medical help: </h5>
                      <p>
                        Seek for medical assistance from trained healthcare
                        professionals though your injuries might not be severe.
                        For example, if you have a medical certification for
                        treatment purposes, such a document could provide some
                        support to back up your argument.
                      </p>
                    </div>
                  </li>
                  <li>
                    <div className="seemingly">
                      <h5 className="Seek">Take evidence:</h5>
                      <p>
                        How strong your compensation case is would depend on
                        what kind of proof you are able to obtain. Consequently,
                        gather as much material as possible in order to
                        strengthen your claim.
                      </p>
                    </div>
                  </li>
                  <li>
                    <div className="seemingly">
                      <h5 className="Seek">Write down everything</h5>
                      <p>
                        Record all expenses you incurred due to this car crash,
                        which include medical bills, therapy fees, transport
                        fares and lost wages.
                      </p>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
            <div
              className="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-12"
              id="dualcardiv"
            >
              <img src={dualcar} alt="" className="dualcar" />
            </div>
            <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-12">
              <div className="Preparing">
                <ul>
                  <li>
                    <div className="seemingly">
                      <h5 className="Seek">Keep off social media:</h5>
                      <p>
                        Abstain from using social media sites until after your
                        claim has been settled. By using social media, you may
                        destroy your claim and reduce your compensation.{" "}
                      </p>
                    </div>
                  </li>
                  <li>
                    <div className="seemingly">
                      <h5 className="Seek">
                        Never accept the initial settlement offer
                      </h5>
                      <p>
                        It is important that you do not just take up any
                        monetary assistance proposed by insurers since you may
                        end up getting less than what you actually deserve. So
                        before saying yes to any such proposal talk with your
                        solicitor first.
                      </p>
                    </div>
                  </li>
                  <li>
                    <div className="seemingly">
                      <h5 className="Seek">
                        Contact a no-fault claim specialist:
                      </h5>
                      <p>
                        Don’t face your injuries and damages alone in case of an
                        accident. Dial the{" "}
                        <strong> Adrian Flux insurance claim number </strong> to
                        come into contact with our experienced solicitors to
                        have the highest chances of being awarded fair
                        compensation.{" "}
                      </p>
                    </div>
                  </li>
                  <li>
                    <div className="seemingly">
                      <h5 className="Seek">File your claim </h5>
                      <p>
                        Do not hesitate too much to file an{" "}
                        <strong> Adrian Flux car insurance claim </strong> .
                        Just use the{" "}
                        <strong> Adrian Flux Insurance claim number </strong> to
                        call us and we will start your claim immediately.
                      </p>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="whyus">
        <div className="container">
          <div className="row">
            <div className="col-xxl-12 col-md-12">
              <div className="why">
                <h2 className="choose">
                  Register your claim in{" "}
                  <span className="light">4 simple steps</span>
                </h2>
                <p className="complexities">
                  Our streamlined and hassle-free claims process allows you to
                  register a claim without any waiting period or paperwork
                  hassles. If you want it done faster then reach us through the{" "}
                  <strong> Adrian Flux car insurance claims </strong>contact
                  details as shown above.
                </p>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-xxl-3 col-xl-6 col-lg-6 col-md-6 col-12">
              <div className="box-service">
                <img src={check} className="check" alt="" />
                {/* <img src={experience} className="experience" alt="" /> */}
                <h4>Get in touch</h4>
                <p>
                  Book a first meeting with our solicitors by calling them
                  directly on{" "}
                  <strong> Adrian Flux’s insurance claims number. </strong>
                </p>
              </div>
            </div>
            <div className="col-xxl-3 col-xl-6 col-lg-6 col-md-6 col-12">
              <div className="box-service">
                <img src={check} className="check" alt="" />
                <h4>Provide information about the accident</h4>
                <p>
                  These details include but are not limited to, date, time,
                  exact location, etc., about what happened during the accident.
                </p>
              </div>
            </div>
            <div className="col-xxl-3 col-xl-6 col-lg-6 col-md-6 col-12">
              <div className="box-service">
                <img src={check} className="check" alt="" />
                <h4>Give evidence</h4>
                <p>
                  What evidence or other important facts did you collect from
                  the scene?{" "}
                </p>
              </div>
            </div>
            <div className="col-xxl-3 col-xl-6 col-lg-6 col-md-6 col-12">
              <div className="box-service">
                <img src={check} className="check" alt="" />
                <h4>Claim submission</h4>
                <p>
                  We will submit your{" "}
                  <strong> Adrian Flux car insurance claim</strong> and update
                  you on the status after gathering evidence and important
                  documents.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="faqs">
        <div className="container">
          <div className="row">
            <div className="col-xxl-12 col-xl-12 col-md-12 col-12">
              <div className="fq">
                <h2>
                  {" "}
                  Frequently Asked{" "}
                  <span className="light">Questions (FAQ)</span>
                </h2>
                <p className="complexities">
                  Got questions about the{" "}
                  <strong> Adrian Flux car insurance claim? </strong>We have
                  them answered here.
                </p>
              </div>
              <div className="allfaq">
                <Accordion defaultActiveKey="0">
                  <Accordion.Item eventKey="0">
                    <Accordion.Header>
                      Am I entitled to a replacement vehicle while my car is
                      under repairs?
                    </Accordion.Header>
                    <Accordion.Body>
                      Yes, we shall provide you with a similar replacement car
                      that matches your size, make as well as model, so that you
                      can still carry out your daily activities without any
                      hindrance.
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="1">
                    <Accordion.Header>
                      How quickly can you arrange a replacement vehicle for me?
                    </Accordion.Header>
                    <Accordion.Body>
                      We understand how much trouble it is when your vehicle
                      gets damaged. Therefore, on the same day, we will let you
                      go with another one in order to get back on the road as
                      soon as possible.
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="2">
                    <Accordion.Header>
                      Do I need to pay any excess if the accident is not my
                      fault?
                    </Accordion.Header>
                    <Accordion.Body>
                      As long as you are making the{" "}
                      <strong> Adrian Flux car insurance claim </strong>
                      through us, there is no excess payment that would be
                      required of you. The costs incurred will be recovered from
                      the insurer of the at-fault party.
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="3">
                    <Accordion.Header>
                      How much time is needed to repair a damaged car?{" "}
                    </Accordion.Header>
                    <Accordion.Body>
                      The period of repairs depends on how much your car has
                      been destroyed. Small dents or scratches might take only
                      one or two days while major damages may go on for a few
                      weeks.
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="4">
                    <Accordion.Header>
                      Can I use my own garage for vehicle repairs?
                    </Accordion.Header>
                    <Accordion.Body>
                      Yes, you can. You can give us the details of your own
                      garage so that we arrange for your vehicle’s repair from
                      that specific repair centre.
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="5">
                    <Accordion.Header>
                      What expenses can I claim from the negligent party?{" "}
                    </Accordion.Header>
                    <Accordion.Body>
                      You are allowed to claim any costs associated with your
                      injury, including medical bills, solicitor fees,
                      transportation costs and loss of earnings.
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="6">
                    <Accordion.Header>
                      How long will it take for my Adrian Flux insurance claim
                      to get settled?{" "}
                    </Accordion.Header>
                    <Accordion.Body>
                      Various factors determine how long a settlement in a case
                      like this can last, such as the intricacy of your
                      situation, how well-documented your case is and whether
                      different parties cooperate with each other. We will leave
                      no stone unturned when resolving your claim in the
                      shortest possible time, once you contact us via our{" "}
                      <strong>
                        {" "}
                        Adrian Flux insurance claims phone number.{" "}
                      </strong>
                    </Accordion.Body>
                  </Accordion.Item>
                </Accordion>
              </div>
            </div>
          </div>
        </div>
      </section>

      <Footer />
    </>
  );
};

export default Adrianflux;
