import React, { useRef } from "react";
import Accordion from "react-bootstrap/Accordion";
import check from "../assets/images/check.png";
import dualcar from "../../src/assets/images/dualcar.webp";
import carbnr1 from "../../src/assets/images/carbnr1.webp";
import Sidebarinner from "../components/Sidebar-inner";
import phonecall from "../assets/images/phonecall.png";
import Footer from "../components/footer";
import { Helmet } from "react-helmet";

const Performancedirect = () => {
  const sidebarRef = useRef(null);

  const handleScrollToSidebar = () => {
    if (sidebarRef.current) {
      sidebarRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Performance Direct Car Insurance Accident Claim Phone Number:</title>
        <meta
          name="description"
          content="Want a quick settlement of your claim? Contact us at the Performance Direct Car Insurance accident claim phone number 0800 772 0850 for expedited claim processing. "
        />
        <link
          rel="canonical"
          href="https://insurance-accident-claims.co.uk/performance-direct"
        />
      </Helmet>
      <section className="innernav">
        <div className="container">
          <div className="row">
            <div className="col-md-6 col-sm-6">
              <div className="left-logo">
                <h3>
                  Accident<span className="sipo"> Claims</span>
                </h3>
              </div>
            </div>
            <div className="col-md-6 col-sm-6">
              <div className="left-cta">
                <div class="headercnt">
                  <div class="chatmsg">
                    <img
                      src={phonecall}
                      className="phonecall"
                      alt="phonecall "
                    />
                  </div>
                  <div class="box-body">
                    <h3 class="nav-info-box-title">Toll Free Number </h3>
                    <p>
                      <a href="tel:0800 772 0850">0800 772 0850</a>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section
        className="newbnr"
        style={{ backgroundImage: "url(" + carbnr1 + ")" }}
      >
        <div className="container">
          <div className="row">
            <div className="col-xxl-8 col-xl-8 col-md-10 col-12">
              <div className="innerbnr">
                <h1>
                  <span className="onespan"> Car Accident </span>{" "}
                  <span className="sci">insurance claims </span> professionals in the
                  UK
                </h1>
                <p className="brokerage">
                  We take over the whole process of car accident management,
                  including recovery of the vehicle, provision of replacement
                  cars and handling the entire claim.
                </p>
                <div className="bnrbtn">
                  <a class="db-btn-border btn-rollover phone" href="tel:0800 772 0850">
                    Road Accident Claim Help?{" "}
                    <i class="fa-solid fa-arrow-right"></i>
                  </a>
                </div>
                <div className="bnrbtn">
                <a className="db-btn-border btn-rollover desktop"  onClick={handleScrollToSidebar}>
                    Road Accident Claim Help? <i className="fa-solid fa-arrow-right"></i>
                </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="stickysection">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="group-4-8">
                <div className="cl8 tf-tab">
                  <div className="content-tab">
                    <div className="inner-content">
                      <h2>
                        Performance Direct insurance accident
                        <br />
                        <span className="light">
                          support without upfront costs{" "}
                        </span>{" "}
                      </h2>
                      <p className="disciplines">
                        In case you have been involved in an accident call us on
                        the Performance Direct claims number, and we will offer
                        prompt help at no extra charges. Our goal is to provide
                        you with{" "}
                        <strong>
                          {" "}
                          Performance Direct insurance accident support{" "}
                        </strong>{" "}
                        that is professional yet friendly and reduce your stress
                        levels by avoiding all the problems associated with{" "}
                        <strong>
                          {" "}
                          Performance Direct insurance accident claims.{" "}
                        </strong>{" "}
                      </p>
                      <hr />
                      <p className="bold-text">Zero hidden fees</p>
                      <p className="bottom-te">
                        Our services are free of charge. No advance or
                        additional payments are required for using our{" "}
                        <strong>
                          {" "}
                          Performance Direct insurance accident support{" "}
                        </strong>{" "}
                        services. We will recover all costs from the other
                        party’s insurance company.{" "}
                      </p>
                      <hr />
                      <p className="bold-text">
                        {" "}
                        No impact on no-claims bonus{" "}
                      </p>
                      <p className="bottom-te">
                        We know that it’s important not to lose your
                        well-deserved discounts and privileges earned by
                        yourself entirely. So, we put in our best efforts to
                        handle{" "}
                        <strong> Performance Direct Insurance claims </strong>{" "}
                        as closely as possible, without impacting negatively on
                        your no-claims bonus.
                      </p>
                      <hr />
                      <p className="bold-text"> High-quality repairs </p>
                      <p className="bottom-te">
                        A dependable group of skilled repairers in the UK is
                        known for offering quality repairs. This means that when
                        you call us on the{" "}
                        <strong>
                          {" "}
                          Performance Direct claims contact number,{" "}
                        </strong>{" "}
                        your vehicle repairs will be managed by our trusted
                        repair partners, who are supplied with
                        manufacturer-sourced genuine parts.
                      </p>
                      <hr />
                      <p className="bold-text"> Free replacement car </p>
                      <p className="bottom-te">
                        Should you choose to call us through the car insurance
                        claim number{" "}
                        <strong>
                          {" "}
                          <a href="tel:0800 772 0850">0800 772 0850</a>{" "}
                        </strong>
                        we will offer you a similar replacement car to enable
                        you to continue your everyday activities without any
                        inconvenience.{" "}
                      </p>
                      <hr />
                      <p className="bold-text">Comprehensive services</p>
                      <p className="bottom-te">
                        We cover all aspects of managing a{" "}
                        <strong>
                          {" "}
                          Performance Direct insurance accident claim{" "}
                        </strong>{" "}
                        from having to deal with uninsured losses or claims
                        management that results in stressful conditions for
                        one’s mind and body.
                      </p>
                      <hr />
                      <p className="bold-text">
                        {" "}
                        Repair monitoring and updates{" "}
                      </p>
                      <p className="bottom-te">
                        Throughout the process, we make it a point to keep an
                        eye on how far along your vehicle repairs have come.
                      </p>
                      <hr />
                      <p className="bold-text"> Personalised support </p>
                      <p className="bottom-te">
                        By calling us on the{" "}
                        <strong>
                          {" "}
                          Performance Direct car accident helpline,{" "}
                        </strong>
                        we will allocate a dedicated claims handler to serve as
                        your single point of contact throughout the entire
                        <strong>
                          {" "}
                          Performance Direct insurance accident claim process.
                        </strong>
                      </p>
                      <hr />
                      <p className="bold-text"> Safe vehicle storage </p>
                      <p className="bottom-te">
                        If you should ever be engaged in a no-fault car
                        accident, we will give you the most secure and safe
                        place to keep your car as we prevent it from being
                        damaged further.{" "}
                      </p>
                      <hr />
                      <p className="bold-text"> Expert claim handling </p>
                      <p className="bottom-te">
                        Our group contains experts and legal practitioners with
                        great levels of experience in handling{" "}
                        <strong>
                          {" "}
                          Performance Direct insurance accident claims{" "}
                        </strong>{" "}
                        Performance Direct insurance accident claims
                      </p>
                      <hr />

                      <p className="bold-text"> Quick settlement process </p>
                      <p className="bottom-te">
                        For us, we have an uncomplexed path of having processes
                        that could take place at shorter times without any
                        unnecessary postponements.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="cl4" ref={sidebarRef}>
                  <Sidebarinner />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="photographs">
        <div className="container">
          <div className="row">
            <h2 className="road">
              We will alleviate your burden and help you{" "}
              <span className="light">
                recover <br />
                fast after a car accident
              </span>{" "}
            </h2>
            <div className="col-xxl-6 col-xl-6 col-md-12 col-12">
              <div className="fourbox">
                <ul>
                  <li>
                    <img src={check} className="check" alt="" />
                    In case of a non-fault incident, dial the{" "}
                    <strong>
                      {" "}
                      car accident helpline{" "}
                      <a href="tel:0800 772 0850">0800 772 0850</a>.
                    </strong>{" "}
                    for immediate support to ensure swift recovery.
                  </li>
                  <hr />
                  <li>
                    <img src={check} className="check" alt="" />
                    Our panel of solicitors with vast experience will review
                    your <strong>
                      {" "}
                      Performance Direct accident claim{" "}
                    </strong>{" "}
                    and advise on the rights available to you.
                  </li>
                  <hr />
                  <li>
                    <img src={check} className="check" alt="" />
                    We will make sure that all your day-to-day activities are
                    not interrupted by providing you with a replacement vehicle.
                  </li>
                  <hr />
                  <li>
                    <img src={check} className="check" alt="" />
                    Our team specialises in different areas, such as accidental
                    repairs, negotiations and uninsured losses. We will manage
                    every aspect required for your compensation resulting from
                    the road accident.
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-xxl-6 col-xl-6 col-md-12 col-12">
              <div className="fourbox">
                <ul>
                  <li>
                    <img src={check} className="check" alt="" />
                    When you call us at the{" "}
                    <strong>
                      {" "}
                      Performance Direct accident claim phone number,
                    </strong>{" "}
                    we will assess the extent of damage to your car and arrange
                    for repair services from our preferred garage.
                  </li>
                  <hr />
                  <li>
                    <img src={check} className="check" alt="" />
                    We will assign a dedicated UK claims handler to make sure
                    you receive maximum compensation for your losses.
                  </li>
                  <hr />
                  <li>
                    <img src={check} className="check" alt="" />
                    We provide legal protection and ensure that all parties
                    treat you fairly after a non-fault road traffic collision
                    has occurred.
                  </li>
                  <hr />
                  <li>
                    <img src={check} className="check" alt="" />
                    After the repairs on your vehicle are finished, we will
                    schedule when it can be collected back as fixed.
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="carprocess">
        <div className="container ">
          <div className="row">
            <div className="col-xxl-12 col-xxl-12 col-md-12 col-12">
              <h2 className="trusted-title">
                {" "}
                Our wide range of accident
                <br />
                <span className="light">management services</span>{" "}
              </h2>
            </div>
          </div>
          <div className="row" id="possible">
            <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-12">
              <div className="claim">
                <ul>
                  <li>
                    <span className="sleej">Vehicle recovery:</span>We offer
                    professional car recovery services 24 hours a day every day
                    in order to get your car transported safely after a no-fault
                    road traffic accident.
                  </li>
                  <li>
                    <span className="sleej">Vehicle repairs:</span>Through our
                    extensive network of manufacturer-approved repairers who do
                    quality work fast and efficiently, we provide a complete
                    solution to fix damaged vehicles quickly.
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-12">
              <div className="claim" id="blue">
                <ul>
                  <li>
                    <span className="sleej">Vehicle storage:</span> Want to keep
                    Worried about further damages to your car after a
                    non-negligent accident? Just leave it with us. We will store
                    your vehicle in our storage yards through the entire process
                    without any additional costs incurred. Please call us at the
                    car accident helpline on{" "}
                    <strong>
                      {" "}
                      <a href="tel:0800 772 0850">0800 772 0850 </a>{" "}
                    </strong>
                    for free storage of your vehicle on our premises.
                  </li>
                  <li>
                    <span className="sleej">Courtesy car:</span>
                    We can provide you with a replacement vehicle of the same
                    value as yours while your car remains in the garage. To get
                    a free replacement car, give us a call on the{" "}
                    <strong>
                      {" "}
                      Performance Direct accident claims number.{" "}
                    </strong>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-12">
              <div className="claim">
                <ul>
                  <li>
                    <span className="sleej">Personal injury support:</span>
                    You are supposed to be paid for injuries and losses. Our
                    highly skilled team of solicitors who specialise in personal
                    injury claims will assess your case and secure significant
                    indemnity awards for all such damages you may have suffered.
                  </li>
                  <li>
                    <span className="sleej">Claims management:</span>We will
                    manage all the complexities of{" "}
                    <strong>
                      {" "}
                      Performance Direct insurance accident claims,{" "}
                    </strong>
                    right from initial documentation to claims settlement.
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="yellowcta">
        <div className="container">
          <div className="row">
            <div className="cta2">
              <div className="col-xxl-8 col-xl-8 col-lg-8 col-12">
                <div className="legal">
                  <h3 className="experienced">
                    Have more questions?{" "}
                    <span className="light">Contact our experts </span>{" "}
                  </h3>
                  <p className="professionals">
                    Speak to our knowledgeable no-fault claim experts for
                    tailored assistance for the Performance Direct insurance
                    accident claim.{" "}
                  </p>
                </div>
              </div>
              <div className="col-xxl-4 col-xl-4 col-lg-4 col-12">
              <div class="process">
                  <a class="tf-button style-1 phone" href="tel:0800 772 0850">
                    Get in touch<span class="icon-keyboard_arrow_right"></span>
                  </a>
                </div>
                <div class="process">
                    <a className="tf-button style-1 desktop"  onClick={handleScrollToSidebar}>
                        Get in touch<span className="icon-keyboard_arrow_right"></span>
                    </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <search className="updatedform">
        <div className="container">
          <div className="row text-center">
            <div className="col-xxl-12 col-xl-12 col-md-12 col-12">
              <h2 className="whiteheading">
                {" "}
                Why choose us for your accident <br />
                <span className="light">management needs? </span>
              </h2>
              <p className="sustained">
                As professionals in accident management, we aim to give the most
                appropriate assistance following a non-fault vehicle crash. Pick
                your phone and dial{" "}
                <a href="tel:0800 772 0850">0800 772 0850 </a> and we will
                provide you with no-cost{" "}
                <strong> Performance Direct insurance accident support </strong>{" "}
                services to relieve your anxiety.
              </p>
            </div>
          </div>
          <div className="row">
            <div className="col-xxl-4 col-xl-4 col-lg-6 col-md-12 col-12">
              <div className="Claimant">
                <h5> Immediate assistance </h5>
                <p className="collision">
                  Simply, get in touch with our courteous team through the{" "}
                  <strong> Performance Direct car accident helpline </strong> so
                  that they can assist you in regaining control over your life.
                  We shall dispatch help to where the mishap has happened
                  immediately after receiving such a distress call.
                </p>

                <h5>Repair monitoring</h5>
                <p className="collision">
                  We will always be on top of things when it comes to getting
                  your vehicle back into pre-accident condition as fast as
                  possible.{" "}
                </p>

                <h5> Convenient vehicle collection </h5>
                <p className="collision">
                  Upon completing repairs on your motorcar, we will arrange for
                  its collection. Call{" "}
                  <strong> Performance Direct claims contact number </strong>{" "}
                  today and set a convenient date and place for collecting the
                  car.
                </p>
              </div>
            </div>
            <div className="col-xxl-4 col-xl-4 col-lg-6 col-md-12 col-12">
              <div className="Claimant">
                <h5> Quality repairs guaranteed </h5>
                <p className="collision">
                  When you get in touch with us at the{" "}
                  <strong>
                    {" "}
                    Performance Direct car insurance claim number,{" "}
                  </strong>{" "}
                  we make sure that your vehicle repairs fit within the
                  manufacturer’s specifications, thus preserving its originality
                  and warranty.
                </p>
                <h5> Dedicated support </h5>
                <p className="collision">
                  Once you’ve made your claim, a dedicated claim handler will be
                  appointed to take care of every step of your journey through
                  the{" "}
                  <strong> Performance Direct insurance accident claim </strong>{" "}
                  process.
                </p>

                <h5> Freedom to choose any repairer </h5>
                <p className="collision">
                  To guarantee that fixing your car is absolutely satisfactory,
                  we give you the freedom to select the repairer of your choice.{" "}
                </p>
              </div>
            </div>
            <div className="col-xxl-4 col-xl-4 col-lg-12 col-md-12 col-12">
              <div className="Claimant">
                <h5> Professional Solicitors </h5>
                <p className="collision">
                  We have a vast network of experienced solicitors who will
                  handle your{" "}
                  <strong>
                    {" "}
                    Performance Direct insurance accident claims{" "}
                  </strong>{" "}
                  as quickly as possible.
                </p>
                <h5> 24/7 customer support </h5>
                <p className="collision">
                  Car accidents can happen anytime unexpectedly. As such, no
                  matter what time it is we are always available 24/7 to offer
                  emergency assistance.
                </p>

                <h5> Quick settlement of claims </h5>
                <p className="collision">
                  Our aim is to ensure a quick resolution to your{" "}
                  <strong> Performance Direct insurance accident claim </strong>{" "}
                  without the burden of paperwork and follow-up calls.
                </p>
              </div>
            </div>
          </div>
        </div>
      </search>

      <section className="Accident-claim">
        <div className="container">
          <div className="row">
            <div className="needed">
              <h2 className="trusted-title">
                How can you make the most of a Performance
                <br />
                <span className="light"> Direct insurance accident claim?</span>
              </h2>
              <p className="complexities">
                It is hard to deal with injury claims following an accident.
                However, there are necessary steps that can be taken after being
                involved in a no-fault vehicle crash to ensure fair compensation
                for damages suffered.{" "}
              </p>
            </div>
            <hr />
          </div>
          <div className="row" id="incdes">
            <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-12">
              <div className="Preparing">
                <ul>
                  <li>
                    <div className="seemingly">
                      <h5 className="Seek">
                        Check for injuries and stay safe:
                      </h5>
                      <p>
                        {" "}
                        Immediately after the no-fault car accident, move to a
                        secure location away from the traffic and check yourself
                        and other passengers for injuries.
                      </p>
                    </div>
                  </li>
                  <li>
                    <div className="seemingly">
                      <h5 className="Seek">Collect evidence: </h5>
                      <p>
                        Gather important evidence, such as photographs of
                        damaged vehicles, the scene of an accident and body
                        wounds caused. Furthermore, gather contact details of
                        eyewitnesses as well as those of other drivers involved
                        in the crash.
                      </p>
                    </div>
                  </li>
                  <li>
                    <div className="seemingly">
                      <h5 className="Seek">Get medical help:</h5>
                      <p>
                        Even if it’s just minor injuries you have sustained,
                        please consult medical personnel and record your bodily
                        injuries suffered from the incident along with treatment
                        received to strengthen your claim.
                      </p>
                    </div>
                  </li>
                  <li>
                    <div className="seemingly">
                      <h5 className="Seek">Report car accident </h5>
                      <p>
                        Get emergency vehicle recovery assistance service
                        alongside dedicated support for accidents when you
                        contact us at{" "}
                        <a href="tel:0800 772 0850">0800 772 0850 </a> on the{" "}
                        <strong>
                          {" "}
                          Performance Direct car accident helpline.{" "}
                        </strong>
                      </p>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
            <div
              className="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-12"
              id="dualcardiv"
            >
              <img src={dualcar} alt="" className="dualcar" />
            </div>
            <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-12">
              <div className="Preparing">
                <ul>
                  <li>
                    <div className="seemingly">
                      <h5 className="Seek">Maintain detailed records:</h5>
                      <p>
                        Make sure to keep an accurate account of all the
                        expenses spent due to the mishap, including medical
                        fees, travelling fares, and repair bills amongst others.
                      </p>
                    </div>
                  </li>
                  <li>
                    <div className="seemingly">
                      <h5 className="Seek">
                        {" "}
                        Understand your rights and choices{" "}
                      </h5>
                      <p>
                        Ring us on the{" "}
                        <strong>
                          {" "}
                          Performance Direct car insurance claim number{" "}
                        </strong>{" "}
                        to speak to our experienced solicitors, who can explain
                        your rights and the types of damages that you are
                        entitled to.
                      </p>
                    </div>
                  </li>
                  <li>
                    <div className="seemingly">
                      <h5 className="Seek">
                        Never agree to early settlements:
                      </h5>
                      <p>
                        Insurance firms find it easy to minimise expenses by
                        making a first settlement offer but do not accept any
                        offer from the insurers as they might deny you the right
                        for further compensation for your loss and injuries.
                      </p>
                    </div>
                  </li>
                  <li>
                    <div className="seemingly">
                      <h5 className="Seek">Initiate your Claim</h5>
                      <p>
                        Contact our experienced solicitors for{" "}
                        <strong>
                          {" "}
                          Performance Direct insurance accident claims{" "}
                        </strong>{" "}
                        now. You will be helped in receiving equitable
                        compensation for losses by our solicitors.
                      </p>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="whyus">
        <div className="container">
          <div className="row">
            <div className="col-xxl-12 col-md-12">
              <div className="why">
                <h2 className="choose">
                  Performance Direct insurance accident{" "}
                  <span className="light">claims process</span>
                </h2>
                <p className="complexities">
                  With quick, simple, and straightforward steps, you can start
                  your no-fault claim fast and easily. To find out how we can
                  assist with your claim please call our helpful and
                  professional team on{" "}
                  <a href="tel:0800 772 0850">0800 772 0850 </a>
                </p>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-xxl-3 col-xl-6 col-lg-6 col-md-6 col-12">
              <div className="box-service">
                <img src={check} className="check" alt="" />
                {/* <img src={experience} className="experience" alt="" /> */}
                <h4>Reach us</h4>
                <p>
                  Call us immediately through the{" "}
                  <strong> Performance Direct accident number </strong>{" "}
                  following a no-fault accident.
                </p>
              </div>
            </div>
            <div className="col-xxl-3 col-xl-6 col-lg-6 col-md-6 col-12">
              <div className="box-service">
                <img src={check} className="check" alt="" />
                <h4>Supply details</h4>
                <p>
                  To make your claim, outline the date, time and location of the
                  collision as well as the manner in which it occurred.
                </p>
              </div>
            </div>
            <div className="col-xxl-3 col-xl-6 col-lg-6 col-md-6 col-12">
              <div className="box-service">
                <img src={check} className="check" alt="" />
                <h4>Avail proof</h4>
                <p>
                  If you have useful material such as photos depicting the
                  accident area, witness statements and footage from security
                  cameras, provide them when commencing your claim.{" "}
                </p>
              </div>
            </div>
            <div className="col-xxl-3 col-xl-6 col-lg-6 col-md-6 col-12">
              <div className="box-service">
                <img src={check} className="check" alt="" />
                <h4>Initiation and settlement</h4>
                <p>
                  After obtaining all necessary information and documents, our
                  team will assess your claim and strive to settle it promptly.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="faqs">
        <div className="container">
          <div className="row">
            <div className="col-xxl-12 col-xl-12 col-md-12 col-12">
              <div className="fq">
                <h2>
                  {" "}
                  Frequently Asked{" "}
                  <span className="light">Questions (FAQ)</span>
                </h2>
                <p className="complexities">
                  Got questions about{" "}
                  <strong>
                    {" "}
                    Performance Direct insurance accident claims?{" "}
                  </strong>
                  We have them answered here.
                </p>
              </div>
              <div className="allfaq">
                <Accordion defaultActiveKey="0">
                  <Accordion.Item eventKey="0">
                    <Accordion.Header>
                      What evidence do I need to provide for a successful
                      Performance Direct insurance accident claim?
                    </Accordion.Header>
                    <Accordion.Body>
                      You can help us demonstrate your innocence for high
                      compensation by collecting much evidence, including
                      pictures of where the accident happened, contact
                      information of witnesses and even receipts showing how
                      much money you spent after that crash.
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="1">
                    <Accordion.Header>
                      What if my car is no longer usable?
                    </Accordion.Header>
                    <Accordion.Body>
                      If your car is a write-off, we will give you a similar
                      one. Our experienced solicitors will negotiate on your
                      behalf and ensure you get the best settlement.
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="2">
                    <Accordion.Header>
                      Who settles claims for Performance Direct insurance
                      accident support services?
                    </Accordion.Header>
                    <Accordion.Body>
                      <strong>
                        {" "}
                        Performance Direct insurance accident support{" "}
                      </strong>
                      services are provided free of charge to any driver
                      involved in an accident that was not their fault. The cost
                      of the replacement vehicle and vehicle repairs will be
                      claimed by the insurance company of the person who caused
                      the accident.
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="3">
                    <Accordion.Header>
                      Am I eligible for a replacement vehicle after a non-fault
                      road traffic accident?{" "}
                    </Accordion.Header>
                    <Accordion.Body>
                      Yes. If the crash did not result from your negligence,
                      you’re eligible for a replacement vehicle. If your car is
                      deemed un-drivable and you are seeking an alternative
                      means of transport you can contact us at{" "}
                      <strong>
                        {" "}
                        Performance Direct car accident helpline.{" "}
                      </strong>{" "}
                      We offer replacement cars until such time as yours is
                      repaired.
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="4">
                    <Accordion.Header>
                      How does making a Performance Direct insurance claim help
                      me out?
                    </Accordion.Header>
                    <Accordion.Body>
                      Some of the benefits you experience with our accident
                      management services include:
                      <ul className="listfaq">
                        <li className="faqli">Nothing to pay</li>
                        <li className="faqli">No policy excess</li>
                        <li className="faqli">Preserved no-claims bonus</li>
                        <li className="faqli">
                          No prejudice to future insurance rights
                        </li>
                      </ul>
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="5">
                    <Accordion.Header>
                      Should I seek help from an accident management company?{" "}
                    </Accordion.Header>
                    <Accordion.Body>
                      Car accidents can be very scary and annoying. Unlike an
                      insurance company, accident management firms provide you
                      with assurance of tranquillity. Moreover, making a claim
                      to an accident management firm such as ours will ensure
                      that adequate compensation is given for your losses and
                      injuries.
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="6">
                    <Accordion.Header>
                      Can I take my car for repair to a garage of my choice?{" "}
                    </Accordion.Header>
                    <Accordion.Body>
                      Yes. Just tell us about it, and we shall have your vehicle
                      repaired at the garage of your preference.
                    </Accordion.Body>
                  </Accordion.Item>
                </Accordion>
              </div>
            </div>
          </div>
        </div>
      </section>

      <Footer />
    </>
  );
};

export default Performancedirect;
