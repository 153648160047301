import React, { useRef } from "react";
import insurancelogo from "../../src/assets/images/insurancelogo.png";
import Accordion from "react-bootstrap/Accordion";
import check from "../assets/images/check.png";
import dualcar from "../../src/assets/images/dualcar.webp";
import bner from "../../src/assets/images/bner.webp";
import Sidebarinner from "../components/Sidebar-inner";
import phonecall from "../assets/images/phonecall.png";
import Footer from "../components/footer";
import { Helmet } from "react-helmet";

const Diamond = () => {
  const sidebarRef = useRef(null);

  const handleScrollToSidebar = () => {
    if (sidebarRef.current) {
      sidebarRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Diamond Car Insurance Accident Claim Phone Number</title>
        <meta
          name="description"
          content="Searching for hassle-free car insurance claim assistance? Reach out to our dedicated team on the Diamond Car Insurance Accident Claim Phone Number 0800 772 0850. "
        />
        <link
          rel="canonical"
          href="https://insurance-accident-claims.co.uk/diamond"
        />
      </Helmet>
      <section className="innernav">
        <div className="container">
          <div className="row">
            <div className="col-md-6 col-sm-6">
              <div className="left-logo">
                {/* <img src={insurancelogo} className='insurancelogo' alt='Insurance Logo' /> */}
                <h3>Accident Claims</h3>
              </div>
            </div>
            <div className="col-md-6 col-sm-6">
              <div className="left-cta">
                <div class="headercnt">
                  <div class="chatmsg">
                    <img
                      src={phonecall}
                      className="phonecall"
                      alt="phonecall "
                    />
                  </div>
                  <div class="box-body">
                    <h3 class="nav-info-box-title">Toll Free Number </h3>
                    <p>
                      <a href="tel:0800 772 0850">0800 772 0850</a>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section
        className="newbnr"
        style={{ backgroundImage: "url(" + bner + ")" }}
      >
        <div className="container">
          <div className="row">
            <div className="col-xxl-6 col-xl-6 col-md-10 col-12">
              <div className="innerbnr">
                <h1>
                  <span className="onespan">No win,</span> <br />
                  <span className="sci">No Fee claims</span> management
                </h1>
                <p className="brokerage">
                  From providing no-obligation advice to helping you get back on
                  the road after a no-fault car accident, we have got your back.
                </p>

                <div className="bnrbtn">
                  <a class="db-btn-border btn-rollover phone" href="tel:0800 772 0850">
                    Start your claim{" "}
                    <i class="fa-solid fa-arrow-right"></i>
                  </a>
                </div>
                <div className="bnrbtn">
                <a className="db-btn-border btn-rollover desktop"  onClick={handleScrollToSidebar}>
                  Start your claim <i className="fa-solid fa-arrow-right"></i>
                </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="stickysection">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="group-4-8">
                <div className="cl8 tf-tab">
                  <div className="content-tab">
                    <div className="inner-content">
                      <h2>
                        Expert claims assistance without
                        <span className="light"> any extra costs</span>
                      </h2>
                      <p className="disciplines">
                        Have you been involved in a no-fault car accident? Call
                        us on the{" "}
                        <strong>
                          Diamond car insurance claims telephone number.
                        </strong>{" "}
                        We will expertly manage your claim from start to finish
                        in a way that saves you time, inconvenience and a
                        significant amount of money.{" "}
                        {/* <strong>
                          car insurance claim number{" "}
                          <a href="tel:0800 772 0850">0800 772 0850</a>{" "}
                        </strong>
                        for emergency help and claims guidance. */}
                      </p>
                      <hr />
                      <p className="bold-text">No insurance excess </p>
                      <p className="bottom-te">
                        If you’re not at fault, we will protect you from paying
                        any excess or upfront charges. We will recover all the
                        expenses from the at-fault party’s insurer, incurring no
                        charges to you.{" "}
                        {/* <strong>John Lewis insurance claim</strong> directly
                        against the other driver’s insurance, helping you avoid
                        the financial risk of paying the excess on your
                        insurance policy. */}
                      </p>
                      <hr />
                      <p className="bold-text">No impact on no-claims bonus </p>
                      <p className="bottom-te">
                        Call us on the{" "}
                        <strong>
                          car accident claim number{" "}
                          <a href="tel:0800 772 0850">0800 772 0850</a>
                        </strong>{" "}
                        to make your claim with us. We won’t process your claim
                        through your own insurance policy, protecting your
                        no-claims bonus and hard-earned discounts.
                      </p>
                      <hr />
                      <p className="bold-text">
                        Manufacturer-approved repairs{" "}
                      </p>
                      <p className="bottom-te">
                        We will organise your vehicle repairs from
                        manufacturer-approved repairers in the UK using original
                        parts and paint to restore your vehicle to its
                        pre-accident condition.{" "}
                      </p>
                      <hr />
                      <p className="bold-text">Personal injury support</p>
                      <p className="bottom-te">
                        In case you’ve suffered any major or minor injuries due
                        to the collision, we will walk you through the process
                        of making a successful{" "}
                        <strong> Diamond car insurance claim. </strong>
                      </p>
                      <hr />
                      <p className="bold-text">
                        Immediate roadside assistance{" "}
                      </p>
                      <p className="bottom-te">
                        When you call us on the{" "}
                        <strong>Diamond car insurance claim number</strong> , we
                        will instantly dispatch our team to ensure you have
                        immediate support when you need it the most.
                      </p>
                      <hr />
                      <p className="bold-text">
                        Coordination with repair centres{" "}
                      </p>
                      <p className="bottom-te">
                        We will coordinate with the repair centres to ensure
                        smooth and seamless repairs of your damaged vehicle to
                        minimise downtime.{" "}
                      </p>
                      <hr />
                      <p className="bold-text">Same day replacement </p>
                      <p className="bottom-te">
                        We strive to get you back on the road smoothly with a
                        same-day replacement vehicle by delivering it to your
                        doorstep or the accident location.{" "}
                      </p>
                      <hr />
                      <p className="bold-text">Dedicated claims handler </p>
                      <p className="bottom-te">
                        When you make your{" "}
                        <strong>Diamond car accident claim</strong> with us,
                        you’ll have your own dedicated claims handler who will
                        be your primary point of contact throughout the process.
                      </p>
                      <hr />
                      <p className="bold-text">24/7 recovery and storage </p>
                      <p className="bottom-te">
                        Get in touch with us via the{" "}
                        <strong>Diamond car insurance helpline </strong>for
                        round-the-clock recovery of your damaged vehicle and
                        safe storage in our yards.{" "}
                      </p>
                      <hr />
                      <p className="bold-text">Free legal support </p>
                      <p className="bottom-te">
                        From helping you recover your policy excess and
                        out-of-pocket expenses to managing your{" "}
                        <strong>Diamond car insurance claim</strong>, our
                        solicitors will guide you every step of the way.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="cl4" ref={sidebarRef}>
                  <Sidebarinner />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="photographs">
        <div className="container">
          <div className="row">
            <h2 className="road">
              We streamline your path to recovery after <br />
              <span className="light"> a no-fault car accident</span>{" "}
            </h2>
            <div className="col-xxl-6 col-xl-6 col-md-12 col-12">
              <div className="fourbox">
                <ul>
                  <li>
                    <img src={check} className="check" alt="" />
                    Our comprehensive range of services is designed to address
                    every aspect of your needs following a car accident.
                  </li>
                  <hr />
                  <li>
                    <img src={check} className="check" alt="" />
                    Our team works tirelessly to ensure your vehicle recovery
                    and repair process is as quick as possible.
                  </li>
                  <hr />
                  <li>
                    <img src={check} className="check" alt="" />
                    If your claim feels viable, we’ll connect you with an
                    experienced solicitor who will provide free legal advice for
                    your <strong> Diamond car accident claim. </strong>
                  </li>
                  <hr />
                  <li>
                    <img src={check} className="check" alt="" />
                    If you’re looking for a replacement vehicle, call us on the{" "}
                    <strong>
                      Diamond car insurance claims telephone number.
                    </strong>{" "}
                    We will provide you with a like-for-like replacement
                    vehicle.
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-xxl-6 col-xl-6 col-md-12 col-12">
              <div className="fourbox">
                <ul>
                  <li>
                    <img src={check} className="check" alt="" />
                    We will liaise directly with all the parties to ensure
                    expedited settlement of the{" "}
                    <strong>Diamond car insurance claim.</strong>
                  </li>
                  <hr />
                  <li>
                    <img src={check} className="check" alt="" />
                    We will arrange for an independent engineer to evaluate your
                    vehicle and assess damages.
                  </li>
                  <hr />
                  <li>
                    <img src={check} className="check" alt="" />
                    Our solicitors will help recover all the financial losses
                    you’ve suffered due to the accident from the other party’s
                    insurer.
                  </li>
                  <hr />
                  <li>
                    <img src={check} className="check" alt="" />
                    When your vehicle repairs are finished, we will arrange
                    convenient vehicle collection and return.
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="carprocess">
        <div className="container ">
          <div className="row">
            <div className="col-xxl-12 col-xxl-12 col-md-12 col-12">
              <h2 className="trusted-title">
                Our comprehensive range of accident <br />
                <span className="light">management solutions </span>
              </h2>
            </div>
          </div>
          <div className="row" id="possible">
            <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-12">
              <div className="claim">
                <ul>
                  <li>
                    <span className="sleej">Vehicle recovery </span> <br />
                    We specialise in providing speedy and efficient recovery of
                    your vehicle to ensure a smooth and stress-free experience
                    from the moment you call us on the{" "}
                    <strong> Diamond car accident claim number. </strong>
                  </li>
                  <li>
                    <span className="sleej">Accidental repairs </span>
                    <br />
                    Contact our team on the{" "}
                    <strong> Diamond car insurance claim number </strong> to get
                    your vehicle repairs started quickly after a no-fault car
                    accident. We will arrange professional repairs of your
                    vehicle from a trusted repair centre.
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-12">
              <div className="claim" id="blue">
                <ul>
                  <li>
                    <span className="sleej">Vehicle storage</span> <br />
                    Worried about the storage of your vehicle after the car
                    accident? We have you covered. Get in touch with us via the{" "}
                    <strong>Diamond car insurance helpline</strong> to arrange
                    safe storage of your vehicle in our facilities. Our storage
                    facilities can be used to safeguard and store your vehicle
                    with no upfront costs, saving it from potential damages.
                  </li>
                  <li>
                    <span className="sleej">Replacement car </span> <br />
                    If you’re not at fault, you’re legally entitled to a
                    replacement vehicle. Call us on our{" "}
                    <strong>
                      Diamond car insurance claims telephone number
                    </strong>
                    , and we’ll help you hit the road again by providing a
                    like-for-like replacement vehicle on the same day.
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-12">
              <div className="claim">
                <ul>
                  <li>
                    <span className="sleej">Personal injury support </span>
                    <br />
                    If you’ve suffered any major or minor injuries due to the
                    car accident, we will help you get free legal advice and
                    rightful compensation for your injuries with our{" "}
                    <strong>Diamond car accident claim</strong> service.
                  </li>
                  <li>
                    <span className="sleej">Accident claim management </span>{" "}
                    <br />
                    From arranging replacement vehicles to managing repairs,
                    liaising with all parties and handling paperwork and
                    documentation related to the{" "}
                    <strong>Diamond car insurance claim</strong>, we will take
                    care of everything. Reach out to us on{" "}
                    <a href="tel:0800 772 0850">0800 772 0850</a> to begin your
                    claim.
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="yellowcta">
        <div className="container">
          <div className="row">
            <div className="cta2">
              <div className="col-xxl-8 col-xl-8 col-lg-8 col-12">
                <div className="legal">
                  <h3 className="experienced">
                    Got more questions?{" "}
                    <span className="light">Talk to our specialists </span>
                  </h3>
                  <p className="professionals">
                    Get in touch with our no-fault claim experts via{" "}
                    <strong>
                      car insurance claims number{" "}
                      <a className="cpt-no" href="tel:0800 772 0850">
                        0800 772 0850
                      </a>
                    </strong>{" "}
                    to get the best advice for your{" "}
                    <strong> Diamond car insurance claim.</strong>.
                  </p>
                </div>
              </div>
              <div className="col-xxl-4 col-xl-4 col-lg-4 col-12">
              <div class="process">
                  <a class="tf-button style-1 phone" href="tel:0800 772 0850">
                    Get in touch<span class="icon-keyboard_arrow_right"></span>
                  </a>
                </div>
                <div class="process">
                    <a className="tf-button style-1 desktop"  onClick={handleScrollToSidebar}>
                        Get in touch<span className="icon-keyboard_arrow_right"></span>
                    </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <search className="updatedform">
        <div className="container">
          <div className="row text-center">
            <div className="col-xxl-12 col-xl-12 col-md-12 col-12">
              <h2 className="whiteheading">
                What makes us stand out
                <span className="light"> from others? </span>
              </h2>
              <p className="sustained">
                Being involved in a car accident is traumatic. We’re your
                trusted partners committed to making things easy for you
                following a no-fault car accident. Our accident management
                solutions minimise stress and anxiety, saving you valuable time
                and helping you focus on your well-being.
              </p>
            </div>
          </div>
          <div className="row">
            <div className="col-xxl-4 col-xl-4 col-lg-6 col-md-12 col-12">
              <div className="Claimant">
                <h5>Manufacturer-approved repairs </h5>
                <p className="collision">
                  Reach out to us via the{" "}
                  <strong> Diamond car accident claim number </strong>for
                  professional and high-quality repairs of your vehicle from our
                  manufacturer-approved repair network.
                </p>
                <h5>Dedicated claim management </h5>
                <p className="collision">
                  Our experienced claim handlers will evaluate your claim,
                  assess the damage and liaise with insurers, repairers and all
                  other parties for fast claim resolution.
                </p>
                <h5>Quick support </h5>
                <p className="collision">
                  As soon as you call the{" "}
                  <strong>Diamond car insurance helpline</strong> , we will
                  immediately dispatch our team to assist you, ensuring you have
                  prompt support in your time of need.
                </p>
              </div>
            </div>
            <div className="col-xxl-4 col-xl-4 col-lg-6 col-md-12 col-12">
              <div className="Claimant">
                <h5>Same-day replacement vehicle </h5>
                <p className="collision">
                  Get in touch with us via our{" "}
                  <strong>
                    {" "}
                    car insurance claims telephone number{" "}
                    <a href="tel:0800 772 0850">0800 772 0850</a>{" "}
                  </strong>
                  to get back on the road smoothly with a like-for-like
                  replacement vehicle without incurring additional expenses.
                </p>
                <h5>No excess </h5>
                <p className="collision">
                  If the accident is not your fault, we won’t charge you any
                  upfront fees. We will recover all the expenses from the other
                  party’s insurer.
                </p>
                <h5>Genuine repair parts </h5>
                <p className="collision">
                  Our approved repairers use genuine repair parts and paint to
                  ensure the best possible repairs for your damaged vehicle.{" "}
                </p>
              </div>
            </div>
            <div className="col-xxl-4 col-xl-4 col-lg-12 col-md-12 col-12">
              <div className="Claimant">
                <h5>Transparent Process</h5>
                <p className="collision">
                  We will assign you an experienced claim handler who will keep
                  you informed about the progress of your{" "}
                  <strong>Diamond car accident claim. </strong>
                </p>
                <h5>Protected storage </h5>
                <p className="collision">
                  You can have peace of mind knowing that your vehicle will be
                  stored in highly secured storage facilities while awaiting
                  repairs.
                </p>
                <h5>Relaxation guaranteed </h5>
                <p className="collision">
                  Our <strong>Diamond car accident claim </strong> services will
                  relieve you from the stress and hassle caused due to the car
                  accident and help you focus on your recovery.
                </p>
              </div>
            </div>
          </div>
        </div>
      </search>

      <section className="Accident-claim">
        <div className="container">
          <div className="row">
            <div className="needed">
              <h2 className="trusted-title">
                How can you get the most from your
                <br />
                <span className="light">Diamond car accident claim? </span>
              </h2>
              <p className="complexities">
                Obtaining maximum compensation is crucial if you’ve suffered
                serious injuries in a no-fault car accident. However, the value
                of the <strong>Diamond car insurance claim</strong> depends on
                the actions you take following the road traffic accident. Here
                are the steps you must take to obtain fair compensation for your
                injuries and losses.
              </p>
            </div>
            <hr />
          </div>
          <div className="row" id="incdes">
            <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-12">
              <div className="Preparing">
                <ul>
                  <li>
                    <div className="seemingly">
                      <h5 className="Seek">Seek medical treatment </h5>
                      <p>
                        Immediately seek medical treatment for the injuries
                        sustained and get a copy of your medical records to
                        prove the severity of your injuries.
                      </p>
                    </div>
                  </li>
                  <li>
                    <div className="seemingly">
                      <h5 className="Seek">
                        Report the accident to the police{" "}
                      </h5>
                      <p>
                        Call the police and report the accident. The police
                        report can provide crucial evidence and information to
                        support the{" "}
                        <strong>Diamond car insurance claim. </strong>
                      </p>
                    </div>
                  </li>
                  <li>
                    <div className="seemingly">
                      <h5 className="Seek">Gather evidence </h5>
                      <p>
                        Make your <strong>Diamond car accident claim</strong>{" "}
                        stronger by gathering crucial evidence such as photos of
                        the accident scene, vehicle damages and the injuries
                        you’ve suffered.
                      </p>
                    </div>
                  </li>
                  <li>
                    <div className="seemingly">
                      <h5 className="Seek">Exchange information </h5>
                      <p>
                        Since you’re claiming against the other driver, exchange
                        crucial details with them, including name, address,
                        contact information, and insurance details.
                      </p>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
            <div
              className="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-12"
              id="dualcardiv"
            >
              <img src={dualcar} alt="" className="dualcar" />
            </div>
            <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-12">
              <div className="Preparing">
                <ul>
                  <li>
                    <div className="seemingly">
                      <h5 className="Seek">Witness statements </h5>
                      <p>
                        If someone has witnessed the accident, reach out to them
                        and get their contact details for future reference.
                      </p>
                    </div>
                  </li>
                  <li>
                    <div className="seemingly">
                      <h5 className="Seek">
                        Don’t accept an early settlement offer{" "}
                      </h5>
                      <p>
                        The other party’s insurer may pressurise you to accept
                        an early settlement offer far below what you deserve.
                        Don’t agree to such a settlement offer without
                        discussing it with your solicitor.
                      </p>
                    </div>
                  </li>
                  <li>
                    <div className="seemingly">
                      <h5 className="Seek">Obtain dash cam footage</h5>
                      <p>
                        Try to obtain dash cam footage. It will provide robust
                        visual evidence of the events leading to the accident.
                      </p>
                    </div>
                  </li>
                  <li>
                    <div className="seemingly">
                      <h5 className="Seek">File your claim </h5>
                      <p>
                        Making the <strong>Diamond car accident claim</strong>.
                        as soon as possible after the no-fault car accident will
                        reflect on the severity of the damages you’ve
                        experienced. Immediately call us on the{" "}
                        <strong>Diamond car accident claim number</strong> to
                        initiate the process of your claim.
                      </p>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="whyus">
        <div className="container">
          <div className="row">
            <div className="col-xxl-12 col-md-12">
              <div className="why">
                <h2 className="choose">
                  How it works-Our simplified and
                  <span className="light">
                    <br /> hassle-free claims process
                  </span>
                </h2>
                <p className="complexities">
                  Our straightforward and simple claim process allows you to
                  register your <strong>Diamond car insurance claim</strong>{" "}
                  without hassle. Here’s how you can register your claim with
                  us.
                </p>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-xxl-3 col-xl-6 col-lg-6 col-md-6 col-12">
              <div className="box-service">
                <img src={check} className="check" alt="" />
                <h4>Get in touch</h4>
                <p>
                  Contact our dedicated team via the{" "}
                  <strong>
                    Diamond car insurance claim number{" "}
                    <a href="tel:0800 772 0850">0800 772 0850</a>
                  </strong>{" "}
                  to schedule a free consultation with our panel of solicitors.
                </p>
              </div>
            </div>
            <div className="col-xxl-3 col-xl-6 col-lg-6 col-md-6 col-12">
              <div className="box-service">
                <img src={check} className="check" alt="" />
                <h4>Share accident details </h4>
                <p>
                  Provide the details of the accident, including the date, time,
                  location and description of the events leading to the
                  accident.
                </p>
              </div>
            </div>
            <div className="col-xxl-3 col-xl-6 col-lg-6 col-md-6 col-12">
              <div className="box-service">
                <img src={check} className="check" alt="" />
                <h4>Submit evidence</h4>
                <p>
                  If you’ve collected any vital evidence or crucial information
                  from the accident scene, share it with our solicitors.
                </p>
              </div>
            </div>
            <div className="col-xxl-3 col-xl-6 col-lg-6 col-md-6 col-12">
              <div className="box-service">
                <img src={check} className="check" alt="" />
                <h4>Claim submission </h4>
                <p>
                  After obtaining all the necessary documents and information,
                  we will initiate and submit your
                  <strong> Diamond car accident claim.</strong>{" "}
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="faqs">
        <div className="container">
          <div className="row">
            <div className="col-xxl-12 col-xl-12 col-md-12 col-12">
              <div className="fq">
                <h2>
                  {" "}
                  Frequently Asked{" "}
                  <span className="light">Questions (FAQ)</span>
                </h2>
                <p className="complexities">
                  Got questions about the{" "}
                  <strong>Diamond car insurance claim?</strong> We have them
                  answered here.{" "}
                </p>
              </div>
              <div className="allfaq">
                <Accordion defaultActiveKey="0">
                  <Accordion.Item eventKey="0">
                    <Accordion.Header>
                      Am I entitled to a courtesy car after the no-fault car
                      accident?
                    </Accordion.Header>
                    <Accordion.Body>
                      Yes. If your vehicle isn’t driveable, we will provide you
                      with a courtesy car while your vehicle repairs are
                      underway.
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="1">
                    <Accordion.Header>
                      Can I use my own preferred garage for vehicle repairs?
                    </Accordion.Header>
                    <Accordion.Body>
                      Yes. If you want to get your vehicle repaired from your
                      own garage, share the details of the repairer with us, and
                      we will schedule your vehicle repairs from your chosen
                      repair centre.
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="2">
                    <Accordion.Header>
                      How long will it take for my vehicle to be repaired?
                    </Accordion.Header>
                    <Accordion.Body>
                      Unfortunately, we won’t be able to provide you with the
                      exact timeline for the duration of your vehicle repairs.
                      However, we will coordinate with the repairer and keep you
                      informed about the progress of the repairs.
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="3">
                    <Accordion.Header>
                      My vehicle is beyond repair. What happens next?
                    </Accordion.Header>
                    <Accordion.Body>
                      If your vehicle is a total loss, you can contact us at the{" "}
                      <strong>
                        Diamond car insurance claims telephone number
                      </strong>{" "}
                      , we will arrange for an independent engineer to assess
                      the value of your vehicle and arrange a replacement
                      vehicle until your claim gets resolved.
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="4">
                    <Accordion.Header>
                      Do I need to report the car accident to the police?
                    </Accordion.Header>
                    <Accordion.Body>
                      Yes. You must call the police as soon as possible and
                      report the accident within 24 hours. The police report may
                      prove to be vital evidence in support of your{" "}
                      <strong>Diamond car insurance claim.</strong>
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="5">
                    <Accordion.Header>
                      How can I get my vehicle recovered after a no-fault car
                      accident?
                    </Accordion.Header>
                    <Accordion.Body>
                      If you want to get emergency roadside assistance after a
                      no-fault car accident, contact us at the{" "}
                      <strong>Diamond car accident claim number</strong> . We
                      will immediately dispatch our recovery agents to get your
                      vehicle recovered from the accident location.
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="6">
                    <Accordion.Header>
                      Can I claim compensation for personal injuries sustained
                      due to the accident?
                    </Accordion.Header>
                    <Accordion.Body>
                      Yes. If you’ve sustained any injuries due to the
                      negligence of the other driver, you can claim compensation
                      from the other party’s insurer. You can reach out to an
                      experienced solicitor for advice and guidance regarding
                      your claim so that you receive fair compensation for your
                      injuries.
                    </Accordion.Body>
                  </Accordion.Item>
                </Accordion>
              </div>
            </div>
          </div>
        </div>
      </section>

      <Footer />
    </>
  );
};

export default Diamond;
