import React, { useRef } from "react";
import insurancelogo from "../../src/assets/images/insurancelogo.png";
import Accordion from "react-bootstrap/Accordion";
import check from "../assets/images/check.png";
import dualcar from "../../src/assets/images/dualcar.webp";
import carbnr2 from "../../src/assets/images/carbnr2.webp";
import Sidebarinner from "../components/Sidebar-inner";
import phonecall from "../assets/images/phonecall.png";
import { Helmet } from "react-helmet";
import Footer from "../components/footer";

const Bellinsurance = () => {
  const sidebarRef = useRef(null);

  const handleScrollToSidebar = () => {
    if (sidebarRef.current) {
      sidebarRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Bell Insurance Accident Claims Phone Number</title>
        <meta
          name="description"
          content="Want maximum compensation for your claim? Contact us at the Bell Insurance Accident Claims Phone Number 0800 772 0850 to get deserving compensation for your losses.  "
        />
        <link
          rel="canonical"
          href="https://insurance-accident-claims.co.uk/bell-insurance "
        />
      </Helmet>
      <section className="innernav">
        <div className="container">
          <div className="row">
            <div className="col-md-6 col-sm-6">
              <div className="left-logo">
                {/* <img src={insurancelogo} className='insurancelogo' alt='Insurance Logo' /> */}
                <h3>Accident Claims</h3>
              </div>
            </div>
            <div className="col-md-6 col-sm-6">
              <div className="left-cta">
                <div class="headercnt">
                  <div class="chatmsg">
                    <img
                      src={phonecall}
                      className="phonecall"
                      alt="phonecall "
                    />
                  </div>
                  <div class="box-body">
                    <h3 class="nav-info-box-title">Toll Free Number </h3>
                    <p>
                      <a href="tel:0800 772 0850">0800 772 0850</a>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section
        className="newbnr"
        style={{ backgroundImage: "url(" + carbnr2 + ")" }}
      >
        <div className="container">
          <div className="row">
            <div className="col-xxl-8 col-xl-8 col-md-10 col-12">
              <div className="innerbnr">
                <h1>
                  <span className="onespan">No-fault car </span>{" "}
                  <span className="sci"> accident insurance  </span> claims
                  specialists{" "}
                </h1>
                <p className="brokerage">
                  From assessing damages to arranging repairs & replacement
                  vehicles and managing your{" "}
                  <strong className="bell-bold"> Bell insurance claim </strong>{" "}
                  , we will handle everything to provide you with a stress-free
                  experience after a no-fault car accident.
                </p>
                <div className="bnrbtn">
                  <a
                    class="db-btn-border btn-rollover phone"
                    href="tel:0800 772 0850"
                  >
                    Begin your claim <i class="fa-solid fa-arrow-right"></i>
                  </a>
                </div>
                <div className="bnrbtn">
                  <a
                    className="db-btn-border btn-rollover desktop"
                    onClick={handleScrollToSidebar}
                  >
                    Begin your claim <i className="fa-solid fa-arrow-right"></i>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="stickysection">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="group-4-8">
                <div className="cl8 tf-tab">
                  <div className="content-tab">
                    <div className="inner-content">
                      <h2>
                        Most dedicated accident management <br />
                        <span className="light">experts in the UK </span>{" "}
                      </h2>
                      <p className="disciplines">
                        We’re a team of reliable and trusted professionals
                        specialising in helping the victims of no-fault car
                        accidents recover from the aftermath and get the best
                        compensation for their injuries and losses. Speak to our
                        team via{" "}
                        <strong>
                          {" "}
                          Bell insurance claims phone number{" "}
                          <a href="tel:0800 772 0850">0800 772 0850</a>.
                        </strong>{" "}
                        to get expert help and guidance to make
                        <strong> Bell insurance claims </strong>for obtaining
                        compensation from the negligent party.
                      </p>
                      <hr />
                      <p className="bold-text">No excess </p>
                      <p className="bottom-te">
                        We will initiate the
                        <strong> Bell car insurance claim </strong>directly
                        against the other party’s insurer. So, you don’t have to
                        worry about making any excess payments associated with
                        the claim.
                      </p>
                      <hr />
                      <p className="bold-text">Expert negotiation </p>
                      <p className="bottom-te">
                        Our solicitors will expertly negotiate with the other
                        party’s insurer for the best settlement value for your{" "}
                        <strong> Bell car insurance claim</strong>. Contact us
                        at the{" "}
                        <strong> Bell insurance claims phone number</strong> to
                        make your claim with us.
                      </p>
                      <hr />
                      <p className="bold-text">Tailored advice </p>
                      <p className="bottom-te">
                        Every claim is different. Therefore, our solicitors will
                        assess your situation and provide personalised advice
                        based on the circumstances of your individual case. Get
                        in touch with us via the{" "}
                        <strong> Bell insurance claim number</strong> to get
                        no-obligation advice from our panel of solicitors.
                      </p>
                      <hr />
                      <p className="bold-text">Streamlined process </p>
                      <p className="bottom-te">
                        We have simplified and streamlined our
                        <strong> Bell car insurance claim process </strong> to
                        avoid unwanted delays and complexities. So, when you
                        call us on the{" "}
                        <strong>
                          claims contact number{" "}
                          <a href="tel:0800 772 0850">0800 772 0850</a>{" "}
                        </strong>
                        , you won’t have to deal with complex paperwork and long
                        waiting times.
                      </p>
                      <hr />
                      <p className="bold-text">Transparent approach </p>
                      <p className="bottom-te">
                        We strive to keep our customers informed about the
                        status of their <strong>Bell insurance claims </strong>
                        at every step of the way. .
                      </p>
                      <hr />
                      <p className="bold-text">Genuine repairs </p>
                      <p className="bottom-te">
                        Our manufacturer-approved repair centre will complete
                        essential repairs using genuine parts and paint.
                        Therefore, you can trust that your vehicle will be
                        repaired to the highest quality standards.
                      </p>
                      <hr />
                      <p className="bold-text">
                        No risk to your no claims bonus{" "}
                      </p>
                      <p className="bottom-te">
                        Since the
                        <strong> Bell car insurance claim </strong> will be made
                        directly against the other party and all the expenses
                        will be paid by them, your no-claims bonus will not be
                        affected.
                      </p>
                      <hr />
                      <p className="bold-text">Quick claim processing </p>
                      <p className="bottom-te">
                        Unlike the insurance companies, we won’t complicate the
                        claim process. With our easy and straightforward
                        approach, your <strong> Bell insurance claim </strong>
                        can get settled in minimum time.{" "}
                      </p>
                      <hr />
                      <p className="bold-text">Comparable replacement </p>
                      <p className="bottom-te">
                        With us, there won’t be any delay in getting a
                        replacement vehicle after a no-fault car accident. Reach
                        out to us via{" "}
                        <strong>Bell accident claims number </strong>to get a
                        like-for-like replacement vehicle spot-on without any
                        upfront charges.{" "}
                      </p>
                      <hr />

                      <p className="bold-text">Experienced claim handler </p>
                      <p className="bottom-te">
                        Contact us on the
                        <strong> Bell insurance claims phone number </strong>,
                        and we will connect you with a dedicated claims handler
                        who will be your single point of contact and will
                        oversee the entire process and handle communications to
                        resolve your <strong>Bell insurance claim</strong>{" "}
                        efficiently.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="cl4" ref={sidebarRef}>
                  <Sidebarinner />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="photographs">
        <div className="container">
          <div className="row">
            <h2 className="road">
              We ease your post-accident worries with
              <br />
              <span className="light">our dedicated support </span>{" "}
            </h2>
            <div className="col-xxl-6 col-xl-6 col-md-12 col-12">
              <div className="fourbox">
                <ul>
                  <li>
                    <img src={check} className="check" alt="" />
                    After a stressful accident, you can trust us for dedicated
                    support and emergency assistance.{" "}
                  </li>
                  <hr />
                  <li>
                    <img src={check} className="check" alt="" />
                    From the recovery of your vehicle to the settlement of your{" "}
                    <strong>Bell car insurance claim</strong> , we deal with
                    everything to alleviate your stress.
                  </li>
                  <hr />
                  <li>
                    <img src={check} className="check" alt="" />
                    Get in touch with us via{" "}
                    <strong> Bell insurance claim number </strong> , and our
                    team will go the extra mile to arrange a comparable
                    replacement vehicle without the hassle.
                  </li>
                  <hr />
                  <li>
                    <img src={check} className="check" alt="" />
                    We will put you in touch with experienced and professional
                    solicitors in the UK who will help you manage the{" "}
                    <strong>Bell car insurance claim </strong>
                    through completion.
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-xxl-6 col-xl-6 col-md-12 col-12">
              <div className="fourbox">
                <ul>
                  <li>
                    <img src={check} className="check" alt="" />
                    We provide vehicle repair services through
                    manufacturer-approved garages and ensure timely follow-ups
                    to keep you updated about the progress.
                  </li>
                  <hr />
                  <li>
                    <img src={check} className="check" alt="" />
                    Our team will communicate with all the parties involved in
                    the accident, saving you time and reducing your burden.
                  </li>
                  <hr />
                  <li>
                    <img src={check} className="check" alt="" />
                    Once the vehicle repairs are complete, we will contact you
                    to schedule its collection.
                  </li>
                  <hr />
                  <li>
                    <img src={check} className="check" alt="" />
                    We will diligently handle your{" "}
                    <strong>Bell insurance claim </strong>and guide you towards
                    a successful outcome on a no-win no-fee basis.
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="carprocess">
        <div className="container ">
          <div className="row">
            <div className="col-xxl-12 col-xxl-12 col-md-12 col-12">
              <h2 className="trusted-title">
                {" "}
                Our array of accident <br />
                <span className="light">management solutions </span>{" "}
              </h2>
            </div>
          </div>
          <div className="row" id="possible">
            <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-12">
              <div className="claim">
                <ul>
                  <li>
                    <span className="sleej">Vehicle recovery </span> <br />
                    Without any unwanted delays or interruptions, we will
                    recover your vehicle from the accident location. With just
                    one call on the{" "}
                    <strong> Bell insurance claim number </strong>, our
                    immediate roadside assistance will be at the location,
                    providing emergency support when you need it the most.
                  </li>
                  <li>
                    <span className="sleej">Vehicle repairs </span>
                    <br />
                    We will coordinate with manufacturer-approved repairers to
                    schedule quality repairs of your vehicle in an approved
                    garage of your choice. Contact us on{" "}
                    <strong>
                      <a href="tel:0800 772 0850">0800 772 0850</a>{" "}
                    </strong>{" "}
                    for top-notch repairs of your damaged car.
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-12">
              <div className="claim" id="blue">
                <ul>
                  <li>
                    <span className="sleej">Safe storage </span> <br />
                    If your vehicle is not drivable, it’s not safe to leave it
                    stranded on the roadside. Contact our team on{" "}
                    <strong>Bell claims contact number </strong>for safe storage
                    of your vehicle in our yards. We will store your car in our
                    secured facilities, saving it from more damage and
                    deterioration.
                  </li>
                  <li>
                    <span className="sleej">Replacement vehicle </span> <br />
                    Experienced a car accident that was not your fault? Call us
                    on the <strong>Bell accident claims number</strong> , and
                    our premium assistance will provide you with a like-for-like
                    replacement vehicle to hit the road again and help you carry
                    on with your daily routine without disruption.
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-12">
              <div className="claim">
                <ul>
                  <li>
                    <span className="sleej">Personal injury support </span>
                    <br />
                    We have a panel of experienced solicitors who will expertly
                    handle your personal injury claim and provide you with the
                    best possible outcome according to the extent of your
                    injuries. Get in touch with our team to start your{" "}
                    <strong> Bell insurance claim </strong>
                    today.
                  </li>
                  <li>
                    <span className="sleej">Accident claim management </span>{" "}
                    <br />
                    Get our bespoke accident claim management services that will
                    oversee every aspect of your claim, from initial
                    documentation to liaising with insurers and arranging
                    repairs.
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="yellowcta">
        <div className="container">
          <div className="row">
            <div className="cta2">
              <div className="col-xxl-8 col-xl-8 col-lg-8 col-12">
                <div className="legal">
                  <h3 className="experienced">
                    Got more questions?{" "}
                    <span className="light">Talk to our specialists </span>{" "}
                  </h3>
                  <p className="professionals">
                    Get in touch with our no-fault claim experts via{" "}
                    <strong>
                      insurance claims phone number{" "}
                      <a className="cpt-no" href="tel:0800 772 0850">
                        0800 772 0850
                      </a>{" "}
                    </strong>
                    to get the best advice for your{" "}
                    <strong>Bell car insurance claim.</strong>
                  </p>
                </div>
              </div>
              <div className="col-xxl-4 col-xl-4 col-lg-4 col-12">
                <div class="process">
                  <a class="tf-button style-1 phone" href="tel:0800 772 0850">
                    Get in touch<span class="icon-keyboard_arrow_right"></span>
                  </a>
                </div>
                <div class="process">
                  <a
                    className="tf-button style-1 desktop"
                    onClick={handleScrollToSidebar}
                  >
                    Get in touch
                    <span className="icon-keyboard_arrow_right"></span>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <search className="updatedform">
        <div className="container">
          <div className="row text-center">
            <div className="col-xxl-12 col-xl-12 col-md-12 col-12">
              <h2 className="whiteheading">
                {" "}
                What makes us your trusted choice for
                <span className="light"> your accident management needs? </span>
              </h2>
              <p className="sustained">
                We understand that dealing with a no-fault car accident can be
                overwhelming. However, we are here to provide comprehensive
                support and guidance after a no-fault car accident. As your
                dedicated partner, we will manage your{" "}
                <strong>Bell car insurance claim </strong>
                at no cost and work tirelessly to protect your insurance rights.
              </p>
            </div>
          </div>
          <div className="row">
            <div className="col-xxl-4 col-xl-4 col-lg-6 col-md-12 col-12">
              <div className="Claimant">
                <h5>No win, No fee </h5>
                <p className="collision">
                  The solicitors we work with operate on a no-win no-fee basis.
                  You won’t be burdened with financial risk if your{" "}
                  <strong> Bell insurance claims </strong> do not yield a
                  favourable outcome.
                </p>
                <h5>No obligation advice </h5>
                <p className="collision">
                  With us, there is no legal obligation to pursue a claim.
                  Contact us at <strong>Bell claims contact number </strong>
                  for free initial consultation or general guidance regarding
                  your claim.
                </p>

                <h5>No increase in insurance premium </h5>
                <p className="collision">
                  We don't claim against your own insurance policy, so your
                  insurance premiums won’t be affected at any cost.
                </p>
              </div>
            </div>
            <div className="col-xxl-4 col-xl-4 col-lg-6 col-md-12 col-12">
              <div className="Claimant">
                <h5>Comprehensive services </h5>
                <p className="collision">
                  From vehicle recovery to handling your claim from start to
                  end, our services cover a broad spectrum.
                </p>

                <h5>Immediate replacement vehicle </h5>
                <p className="collision">
                  Call us on the{" "}
                  <strong>
                    {" "}
                    accident claims number{" "}
                    <a href="tel:0800 772 0850">0800 772 0850</a>{" "}
                  </strong>{" "}
                  to access a cost-free replacement vehicle to continue your
                  daily activities without significant disruption.
                </p>
                <h5>Secure storage </h5>
                <p className="collision">
                  We will make arrangements to store your damaged vehicle safely
                  in our secured storage yards without additional charges.
                </p>
              </div>
            </div>
            <div className="col-xxl-4 col-xl-4 col-lg-12 col-md-12 col-12">
              <div className="Claimant">
                <h5>Transparent accident evaluation </h5>
                <p className="collision">
                  Get in touch with us via the{" "}
                  <strong>Bell insurance claims phone number</strong> to seek
                  expert guidance from our solicitors and leverage their
                  negotiation skills to negotiate with the other party’s
                  insurer.
                </p>
                <h5>High-quality repairs </h5>
                <p className="collision">
                  We will arrange the highest quality repairs for your vehicle
                  that exceed expectations from our vast network of approved
                  repairers in the UK.
                </p>
                <h5>Tailored advice </h5>
                <p className="collision">
                  We understand that each{" "}
                  <strong> Bell car insurance claim </strong>
                  is one-of-a-kind. So, we will ensure you receive personalised
                  advice based on the individual circumstances of your claim.
                </p>
              </div>
            </div>
          </div>
        </div>
      </search>

      <section className="Accident-claim">
        <div className="container">
          <div className="row">
            <div className="needed">
              <h2 className="trusted-title">
                How can you get the maximum settlement
                <br />
                <span className="light">for your Bell insurance claim?</span>
              </h2>
              <p className="complexities">
                Car accidents happen in a flash and can turn your life upside
                down. Therefore, pursuing a claim becomes crucial to seek
                deserving compensation for your losses and injuries. Here are
                some actionable steps to increase your{" "}
                <strong> Bell insurance claim </strong> compensation.
              </p>
            </div>
            <hr />
          </div>
          <div className="row" id="incdes">
            <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-12">
              <div className="Preparing">
                <ul>
                  <li>
                    <div className="seemingly">
                      <h5 className="Seek">Report the accident </h5>
                      <p>
                        {" "}
                        Following a no-fault car accident, immediately call the
                        police and report the car accident. The police report
                        serves as vital evidence in your{" "}
                        <strong> Bell car insurance claim. </strong>
                      </p>
                    </div>
                  </li>
                  <li>
                    <div className="seemingly">
                      <h5 className="Seek">Seek medical attention </h5>
                      <p>
                        Get medical attention from a qualified medical
                        professional, even if your injuries are not severe. The
                        medical report can become crucial evidence in support of
                        your claim.
                      </p>
                    </div>
                  </li>
                  <li>
                    <div className="seemingly">
                      <h5 className="Seek">Collect evidence </h5>
                      <p>
                        The strength of your claim depends on the evidence you
                        gather from the accident scene. So, collect as much
                        evidence as possible to strengthen your claim.{" "}
                      </p>
                    </div>
                  </li>
                  <li>
                    <div className="seemingly">
                      <h5 className="Seek">Document everything </h5>
                      <p>
                        Keep a detailed record of all the expenses you’ve
                        incurred due to the accident, including medical bills,
                        therapy costs, transportation expenses, and lost
                        earnings.
                      </p>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
            <div
              className="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-12"
              id="dualcardiv"
            >
              <img src={dualcar} alt="" className="dualcar" />
            </div>
            <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-12">
              <div className="Preparing">
                <ul>
                  <li>
                    <div className="seemingly">
                      <h5 className="Seek">Avoid social media </h5>
                      <p>
                        Stay away from social media until your claim is
                        resolved. The other party’s solicitors may use your
                        social media posts to reduce compensation.
                      </p>
                    </div>
                  </li>
                  <li>
                    <div className="seemingly">
                      <h5 className="Seek">
                        Don’t accept the first settlement offer{" "}
                      </h5>
                      <p>
                        The insurance companies may propose a quick settlement
                        that is far below what you deserve. Before accepting any
                        such offer, reach out to your solicitor and discuss your
                        options.
                      </p>
                    </div>
                  </li>
                  <li>
                    <div className="seemingly">
                      <h5 className="Seek">
                        Hire a no-fault claim specialist{" "}
                      </h5>
                      <p>
                        If you’ve suffered damages and injuries in a car
                        accident, do not go through it alone. Contact us on the{" "}
                        <strong>Bell insurance claim number </strong>
                        to speak to our experienced solicitors to maximise your
                        chances of securing fair compensation.
                      </p>
                    </div>
                  </li>
                  <li>
                    <div className="seemingly">
                      <h5 className="Seek">File your claim </h5>
                      <p>
                        Don’t wait for too long to make a{" "}
                        <strong>bell car insurance claim </strong>. Get in touch
                        with our team via the{" "}
                        <strong>Bell Insurance claim number </strong>, and we’ll
                        initiate your claim as soon as possible.
                      </p>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="whyus">
        <div className="container">
          <div className="row">
            <div className="col-xxl-12 col-md-12">
              <div className="why">
                <h2 className="choose">
                  Register your claim in just{" "}
                  <span className="light">
                    <br />4 simple steps{" "}
                  </span>
                </h2>
                <p className="complexities">
                  Our streamlined and hassle-free claim process allows you to
                  register your claim without any waiting period or paperwork
                  hassles. Contact us on the{" "}
                  <strong> Bell car insurance claim </strong>
                  to get started with us.
                </p>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-xxl-3 col-xl-6 col-lg-6 col-md-6 col-12">
              <div className="box-service">
                <img src={check} className="check" alt="" />
                <h4>Get in touch </h4>
                <p>
                  Reach out to our dedicated team via the{" "}
                  <strong>Bell insurance claim number</strong> to book an
                  initial consultation with our solicitors.
                </p>
              </div>
            </div>
            <div className="col-xxl-3 col-xl-6 col-lg-6 col-md-6 col-12">
              <div className="box-service">
                <img src={check} className="check" alt="" />
                <h4>Share accident details</h4>
                <p>
                  Provide us with the details of the accident, such as the date
                  and time, exact location, and description of the events that
                  led to the accident.
                </p>
              </div>
            </div>
            <div className="col-xxl-3 col-xl-6 col-lg-6 col-md-6 col-12">
              <div className="box-service">
                <img src={check} className="check" alt="" />
                <h4>Submit evidence</h4>
                <p>
                  Share the evidence or any other crucial information you’ve
                  gathered from the accident scene.
                </p>
              </div>
            </div>
            <div className="col-xxl-3 col-xl-6 col-lg-6 col-md-6 col-12">
              <div className="box-service">
                <img src={check} className="check" alt="" />
                <h4>Claim submission </h4>
                <p>
                  After gathering evidence and essential documents, we will
                  submit your <strong>Bell car insurance claim </strong> and
                  update you on its status.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="faqs">
        <div className="container">
          <div className="row">
            <div className="col-xxl-12 col-xl-12 col-md-12 col-12">
              <div className="fq">
                <h2>
                  {" "}
                  Frequently Asked{" "}
                  <span className="light">Questions (FAQ)</span>
                </h2>
                <p className="complexities">
                  Got questions about the{" "}
                  <strong>Bell insurance claim? </strong>
                  We have them answered here.
                </p>
              </div>
              <div className="allfaq">
                <Accordion defaultActiveKey="0">
                  <Accordion.Item eventKey="0">
                    <Accordion.Header>
                      Am I entitled to a replacement vehicle while my car is
                      under repairs?
                    </Accordion.Header>
                    <Accordion.Body>
                      Yes. We will provide you with a like-for-like replacement
                      vehicle matching your own in terms of size, make and model
                      to ensure you can maintain your daily routine without
                      disruption.
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="1">
                    <Accordion.Header>
                      How quickly can you arrange a replacement vehicle for me?
                    </Accordion.Header>
                    <Accordion.Body>
                      We understand the inconvenience caused due to your vehicle
                      getting damaged. Therefore, we will provide you with a
                      replacement vehicle on the same day to ensure you can get
                      back on the road as soon as possible.
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="2">
                    <Accordion.Header>
                      Do I need to pay any excess if the accident is not my
                      fault?
                    </Accordion.Header>
                    <Accordion.Body>
                      When you make the{" "}
                      <strong>Bell car insurance claim </strong> with us, you
                      don’t have to pay any excess. We will recover all the
                      costs from the at-fault party’s insurance company.
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="3">
                    <Accordion.Header>
                      How long does it take to repair my damaged car?
                    </Accordion.Header>
                    <Accordion.Body>
                      The duration of repairs depends on the extent of damage to
                      your vehicle. Minor dents or scratches can be repaired in
                      a day or two, whereas severe damages can take a few weeks.
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="4">
                    <Accordion.Header>
                      Can I use my own garage for vehicle repairs?
                    </Accordion.Header>
                    <Accordion.Body>
                      Yes. If you want to use your own garage, you can share the
                      details of your preferred garage so that we can arrange
                      your vehicle repairs from your chosen repair centre.
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="5">
                    <Accordion.Header>
                      What expenses can I claim from the negligent party?
                    </Accordion.Header>
                    <Accordion.Body>
                      You are entitled to claim all the expenses you’ve incurred
                      due to the car accident, including medical expenses, legal
                      costs, travelling expenses and loss of earnings.
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="6">
                    <Accordion.Header>
                      How long will it take for my Bell insurance claim to get
                      settled?
                    </Accordion.Header>
                    <Accordion.Body>
                      The duration of your claim depends on various factors,
                      including the complexity of your case, the extent of
                      documentation and cooperation between different parties
                      involved. When you call us on the{" "}
                      <strong> Bell claims contact number </strong> to file your
                      claim with us, we will work tirelessly to resolve your
                      claim in minimum time.
                    </Accordion.Body>
                  </Accordion.Item>
                </Accordion>
              </div>
            </div>
          </div>
        </div>
      </section>

      <Footer />
    </>
  );
};

export default Bellinsurance;
