import React, { useRef } from "react";
import Accordion from "react-bootstrap/Accordion";
import check from "../assets/images/check.png";
import dualcar from "../../src/assets/images/dualcar.webp";
import carbnr1 from "../../src/assets/images/carbnr1.webp";
import Sidebarinner from "../components/Sidebar-inner";
import phonecall from "../assets/images/phonecall.png";
import Footer from "../components/footer";
import { Helmet } from "react-helmet";

const Ageas = () => {
  const sidebarRef = useRef(null);

  const handleScrollToSidebar = () => {
    if (sidebarRef.current) {
      sidebarRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Ageas Car Insurance Claims Contact Number</title>
        <meta
          name="description"
          content="Are you searching for reliable and dedicated claims assistance in the UK? Get in touch with us on the Ageas Car Insurance Claims Contact Number 0800 772 0850. "
        />
        <link
          rel="canonical"
          href="https://insurance-accident-claims.co.uk/ageas"
        />
      </Helmet>
      <section className="innernav">
        <div className="container">
          <div className="row">
            <div className="col-md-6 col-sm-6">
              <div className="left-logo">
                <h3>
                  Claim<span className="sipo"> Support</span>
                </h3>
              </div>
            </div>
            <div className="col-md-6 col-sm-6">
              <div className="left-cta">
                <div class="headercnt">
                  <div class="chatmsg">
                    <img
                      src={phonecall}
                      className="phonecall"
                      alt="phonecall "
                    />
                  </div>
                  <div class="box-body">
                    <h3 class="nav-info-box-title">Toll Free Number </h3>
                    <p>
                      <a href="tel:+/">+1 123 203-5105</a>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section
        className="newbnr"
        style={{ backgroundImage: "url(" + carbnr1 + ")" }}
      >
        <div className="container">
          <div className="row">
            <div className="col-xxl-8 col-xl-8 col-md-10 col-12">
              <div className="innerbnr">
                <h1>
                  <span className="onespan">car insurance </span>{" "}
                  <span className="sci"> claim assistance </span>in the UK
                </h1>
                <p className="brokerage">
                  We specialise in no-fault car accident claims management,
                  providing guidance and support to claim the compensation you
                  deserve.{" "}
                </p>
                <div className="bnrbtn">
                  <a
                    class="db-btn-border btn-rollover phone"
                    href="tel:0800 772 0850"
                  >
                    Road Accident Claim Help?{" "}
                    <i class="fa-solid fa-arrow-right"></i>
                  </a>
                </div>
                <div className="bnrbtn">
                  <a
                    className="db-btn-border btn-rollover desktop"
                    onClick={handleScrollToSidebar}
                  >
                    Road Accident Claim Help?{" "}
                    <i className="fa-solid fa-arrow-right"></i>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="stickysection">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="group-4-8">
                <div className="cl8 tf-tab">
                  <div className="content-tab">
                    <div className="inner-content">
                      <h2>
                        We provide dedicated support
                        <br />
                        <span className="light">
                          from start to end without any additional cost{" "}
                        </span>{" "}
                      </h2>
                      <p className="disciplines">
                        We’re a team of professionals and knowledgeable experts
                        providing comprehensive support to individuals injured
                        in a no-fault car accident. Our solicitors will guide
                        you through the end-to-end process of managing the claim
                        and help you know your options to make a successful
                        claim without any additional charges.
                      </p>
                      <hr />
                      <p className="bold-text">No excess to pay </p>
                      <p className="bottom-te">
                        If the accident is not your fault, we will safeguard you
                        from paying any excess by making{" "}
                        <strong>Ageas car insurance claims </strong> directly
                        against the other party.{" "}
                      </p>
                      <hr />
                      <p className="bold-text">Manufacturer-approved repairs</p>
                      <p className="bottom-te">
                        We have a carefully vetted network of professional
                        repairers in the UK who provide high-quality repairs
                        according to manufacturer specifications. When you
                        contact us on <strong>Ageas claims number </strong>, we
                        will schedule your repairs either through one of our
                        network repairers or at your chosen repair centre.
                      </p>
                      <hr />
                      <p className="bold-text">
                        Like-for-like replacement vehicle
                      </p>
                      <p className="bottom-te">
                        If your vehicle is unroadworthy, we will provide you
                        with a like-for-like replacement vehicle similar to your
                        own car. Get in touch with us on{" "}
                        <strong>Ageas claims contact number </strong>to get a
                        comparable replacement vehicle.{" "}
                      </p>
                      <hr />
                      <p className="bold-text">Extensive experience</p>
                      <p className="bottom-te">
                        Our solicitors have extensive experience in dealing with{" "}
                        <strong>Ageas car insurance claims </strong> and
                        securing maximum compensation for the no-fault
                        claimants. If you wish to get maximum compensation for
                        your claim, please contact us via{" "}
                        <strong>
                          claims phone number{" "}
                          <a href="tel:0800 772 0850">0800 772 0850</a>{" "}
                        </strong>
                      </p>
                      <hr />
                      <p className="bold-text">Simplified claims process</p>
                      <p className="bottom-te">
                        Our streamlined{" "}
                        <strong>Ageas car insurance claims </strong> involve no
                        complex paperwork or lengthy follow-ups. You can simply
                        call us on{" "}
                        <strong>
                          the Ageas insurance claims contact number{" "}
                        </strong>
                        to make your claim.
                      </p>
                      <hr />
                      <p className="bold-text">NCB stays intact</p>
                      <p className="bottom-te">
                        Filing your claim with us won’t impact your hard-earned
                        discounts and bonuses. So, you can continue enjoying the
                        financial benefits despite making{" "}
                        <strong>Ageas car insurance claims. </strong>
                      </p>
                      <hr />
                      <p className="bold-text">No obligation advice</p>
                      <p className="bottom-te">
                        Get in touch with us via the{" "}
                        <strong>Ageas insurance claims contact number </strong>
                        to discuss the details of the accident and receive free
                        no-obligation advice tailored to your situation.
                      </p>
                      <hr />
                      <p className="bold-text">Uninsured loss recovery</p>
                      <p className="bottom-te">
                        With our dedicated support and guidance, we will help
                        you recover all the expenses you’ve incurred due to the
                        car accident from the negligent party’s insurer.
                      </p>
                      <hr />
                      <p className="bold-text">Secure vehicle storage</p>
                      <p className="bottom-te">
                        Call us on{" "}
                        <strong>Ageas insurance claims number </strong>for safe
                        storage of your vehicle post-accident. We will store
                        your car in our secured yards to safeguard it from
                        further damage.{" "}
                      </p>
                      <hr />
                      <p className="bold-text">Dedicated claims handler</p>
                      <p className="bottom-te">
                        To ensure personalised attention to your claim, we will
                        assign a dedicated claims handler who will always be on
                        hand to guide you with the{" "}
                        <strong>Ageas car insurance claims</strong> process from
                        start to finish.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="cl4" ref={sidebarRef}>
                  <Sidebarinner />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="photographs">
        <div className="container">
          <div className="row">
            <h2 className="road">
              We help you recover from{" "}
              <span className="light">
                the loss <br />
                after a no-fault car accident
              </span>{" "}
            </h2>
            <div className="col-xxl-6 col-xl-6 col-md-12 col-12">
              <div className="fourbox">
                <ul>
                  <li>
                    <img src={check} className="check" alt="" />
                    Call us on <strong>Ageas claims number</strong> to get
                    emergency support. We will ensure you and your vehicle are
                    moved to safety at the earliest.
                  </li>
                  <hr />
                  <li>
                    <img src={check} className="check" alt="" />
                    We will arrange your repairs from a trusted repairer to get
                    you back up and running.
                  </li>
                  <hr />
                  <li>
                    <img src={check} className="check" alt="" />
                    Our solicitors will advise you on the best course of action
                    to get compensation for your injuries and losses.
                  </li>
                  <hr />
                  <li>
                    <img src={check} className="check" alt="" />
                    With our no-win no-fee approach, you don’t have to pay
                    upfront charges to our solicitors if your claim is
                    unsuccessful.
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-xxl-6 col-xl-6 col-md-12 col-12">
              <div className="fourbox">
                <ul>
                  <li>
                    <img src={check} className="check" alt="" />
                    Our team will coordinate and liaise with all the parties
                    involved in the accident to ensure smooth and hassle-free{" "}
                    <strong>Ageas car insurance claims.</strong>
                  </li>
                  <hr />
                  <li>
                    <img src={check} className="check" alt="" />
                    With a proven track record of handling thousands of claims,
                    our solicitors will make your claim simple, quick and easy.
                  </li>
                  <hr />
                  <li>
                    <img src={check} className="check" alt="" />
                    While your car is undergoing repairs, we will organise a
                    like-for-like replacement vehicle to get you back to
                    normality without any stress.
                  </li>
                  <hr />
                  <li>
                    <img src={check} className="check" alt="" />
                    Our solicitors will help you recover all the losses and
                    out-of-pocket expenses you’ve incurred due to the accident.
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="carprocess">
        <div className="container ">
          <div className="row">
            <div className="col-xxl-12 col-xxl-12 col-md-12 col-12">
              <h2 className="trusted-title">
                {" "}
                Our wide range of accident
                <br />
                <span className="light">management services</span>{" "}
              </h2>
            </div>
          </div>
          <div className="row" id="possible">
            <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-12">
              <div className="claim">
                <ul>
                  <li>
                    <span className="sleej">Vehicle recovery</span> You can Need
                    an accident recovery service to move your vehicle to another
                    location? Call us on{" "}
                    <strong>Ageas car claims number</strong>, and we’ll swiftly
                    recover your vehicle from the accident scene and move it to
                    a safe storage facility.
                  </li>
                  <li>
                    <span className="sleej">Approved repairs </span>Our If your
                    vehicle is repairable, we will arrange quick and guaranteed
                    repairs from the garage of your choice. Call us on{" "}
                    <strong>
                      insurance claims contact number{" "}
                      <a href="tel:0800 772 0850">0800 772 0850</a>{" "}
                    </strong>
                    to schedule vehicle repairs from a manufacturer-approved
                    repairer.
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-12">
              <div className="claim" id="blue">
                <ul>
                  <li>
                    <span className="sleej">Storage</span> We strive to protect
                    your vehicle from further damage and risks by storing it in
                    our safe and secure storage facilities. Get in touch with us
                    via<strong> Ageas claims phone number</strong> to store your
                    vehicle in our storage facilities for the entire process
                    without upfront costs.
                  </li>
                  <li>
                    <span className="sleej">Replacement car </span> You can
                    Irrespective of what you drive, we will provide you with a
                    like-for-like replacement vehicle best suited to meet your
                    daily needs. While your car is undergoing repairs, you can
                    keep the replacement vehicle and carry on your routine
                    without hassle.
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-12">
              <div className="claim">
                <ul>
                  <li>
                    <span className="sleej">Personal injury support</span>
                    We have a panel of experienced solicitors across the UK who
                    will offer immediate advice and legal representation to help
                    you secure the best outcome for the injuries you’ve
                    sustained due to the accident.
                  </li>
                  <li>
                    <span className="sleej">Claim management</span> From the
                    moment you report the car accident, we work tirelessly to
                    initiate and resolve your claim as soon as possible. From
                    submitting your claim to final settlement, we’ve got your
                    back.
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="yellowcta">
        <div className="container">
          <div className="row">
            <div className="cta2">
              <div className="col-xxl-8 col-xl-8 col-lg-8 col-12">
                <div className="legal">
                  <h3 className="experienced">
                    Got more questions?{" "}
                    <span className="light">Talk to our specialists</span>{" "}
                  </h3>
                  <p className="professionals">
                    Get in touch with our no-fault claim experts via{" "}
                    <strong>
                      car insurance claim contact number{" "}
                      <a className="cpt-no" href="tel:0800 772 0850">
                        0800 772 0850
                      </a>
                    </strong>{" "}
                    to get the best advice for your{" "}
                    <strong>Ageas car insurance claim.</strong>
                  </p>
                </div>
              </div>
              <div className="col-xxl-4 col-xl-4 col-lg-4 col-12">
                <div class="process">
                  <a class="tf-button style-1 phone" href="tel:0800 772 0850">
                    Get in touch<span class="icon-keyboard_arrow_right"></span>
                  </a>
                </div>
                <div class="process">
                  <a
                    className="tf-button style-1 desktop"
                    onClick={handleScrollToSidebar}
                  >
                    Get in touch
                    <span className="icon-keyboard_arrow_right"></span>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <search className="updatedform">
        <div className="container">
          <div className="row text-center">
            <div className="col-xxl-12 col-xl-12 col-md-12 col-12">
              <h2 className="whiteheading">
                {" "}
                What makes us stand
                <br />
                <span className="light">out from others?</span>
              </h2>
              <p className="sustained">
                Our accident management and claims assistance services eliminate
                your stress of dealing with the consequences of road traffic
                accidents. Call us at{" "}
                <strong>Ageas car insurance claims contact number </strong>to
                save your valuable time, money and effort with our cost-free
                services.{" "}
              </p>
            </div>
          </div>
          <div className="row">
            <div className="col-xxl-4 col-xl-4 col-lg-6 col-md-12 col-12">
              <div className="Claimant">
                <h5>No additional costs</h5>
                <p className="collision">
                  If you’re not at fault, we won’t charge any excess from you.
                  All the costs you incur will be recovered from the negligent
                  party.
                </p>
                <h5>Comparable replacement</h5>
                <p className="collision">
                  We’ll get you back on the road with a replacement vehicle
                  similar to your own, ensuring you’re driving something you’re
                  used to.{" "}
                </p>
                <h5>Quality & guaranteed repairs</h5>
                <p className="collision">
                  Our manufacturer-approved repairers specialise in providing
                  high-quality repairs using genuine parts so that your vehicle
                  is restored to its best possible condition.
                </p>
              </div>
            </div>
            <div className="col-xxl-4 col-xl-4 col-lg-6 col-md-12 col-12">
              <div className="Claimant">
                <h5>Free initial consultation</h5>
                <p className="collision">
                  Get in touch with us via the{" "}
                  <strong>Ageas car insurance claims contact number </strong>to
                  arrange a free initial consultation. Our solicitors will help
                  you explore your options and take the most suitable approach.
                </p>
                <h5>No waiting time</h5>
                <p className="collision">
                  When you call us on the
                  <strong> Ageas insurance claims number</strong> , you won’t
                  experience any waiting time. Our solicitors are always on hand
                  to provide you with immediate guidance and advice.
                </p>
                <h5>Personalised advice </h5>
                <p className="collision">
                  Our experienced solicitors will provide customised advice
                  tailored to your situation and circumstances.{" "}
                </p>
              </div>
            </div>
            <div className="col-xxl-4 col-xl-4 col-lg-12 col-md-12 col-12">
              <div className="Claimant">
                <h5>24/7 claims handling</h5>
                <p className="collision">
                  Car accidents can happen anytime. Therefore, our dedicated
                  team is available round the clock to provide emergency support
                  and answer your concerns.
                </p>

                <h5>Expert claim management</h5>
                <p className="collision">
                  From claim initiation to final settlement, our dedicated
                  solicitors will expertly manage your claim to ensure quick
                  settlement without any stress.
                </p>

                <h5>Proven track record</h5>
                <p className="collision">
                  Our solicitors have a proven track record of handling
                  thousands of <strong>Ageas car insurance claims</strong> . So,
                  you can rest assured you’ll get dedicated support to maximise
                  your settlement.
                </p>
              </div>
            </div>
          </div>
        </div>
      </search>

      <section className="Accident-claim">
        <div className="container">
          <div className="row">
            <div className="needed">
              <h2 className="trusted-title">
                How can you increase the settlement value <br />
                <span className="light">
                  for the Ageas car insurance claim?
                </span>
              </h2>
              <p className="complexities">
                If you’re involved in a no-fault car accident, you need to
                obtain fair compensation for the recovery of your damages and
                financial losses. There are a few steps you can take to maximise
                your claims settlement and ensure your claim is successful.{" "}
              </p>
            </div>
            <hr />
          </div>
          <div className="row" id="incdes">
            <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-12">
              <div className="Preparing">
                <ul>
                  <li>
                    <div className="seemingly">
                      <h5 className="Seek">Get medical treatment </h5>
                      <p>
                        {" "}
                        Following a no-fault car accident, getting medical
                        treatment for the injuries must be your foremost
                        priority. If you've sustained any injuries due to the
                        accident, make sure to see the doctor and keep your
                        medical record organised.{" "}
                      </p>
                    </div>
                  </li>
                  <li>
                    <div className="seemingly">
                      <h5 className="Seek">Report the accident </h5>
                      <p>
                        Call the police as soon as possible to report the car
                        accident. Also, obtain a copy of the police report. It
                        may provide crucial evidence related to your case.{" "}
                      </p>
                    </div>
                  </li>
                  <li>
                    <div className="seemingly">
                      <h5 className="Seek">Collect relevant evidence</h5>
                      <p>
                        The more evidence you collect from the accident scene,
                        the easier it will be to build a strong case. So,
                        collect as much evidence as possible to ensure a
                        favourable outcome for the{" "}
                        <strong>Ageas car insurance claim.</strong>{" "}
                      </p>
                    </div>
                  </li>
                  <li>
                    <div className="seemingly">
                      <h5 className="Seek">Stay patient</h5>
                      <p>
                        Don’t try to rush the settlement. Instead, discuss the
                        settlement offer with your solicitor to ensure it
                        adequately compensates you for all the injuries and
                        losses.
                      </p>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
            <div
              className="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-12"
              id="dualcardiv"
            >
              <img src={dualcar} alt="" className="dualcar" />
            </div>
            <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-12">
              <div className="Preparing">
                <ul>
                  <li>
                    <div className="seemingly">
                      <h5 className="Seek">Calculate your losses</h5>
                      <p>
                        Calculate all the losses, including medical expenses,
                        repair estimates, loss of earnings, or any other
                        out-of-pocket expenses you’ve incurred due to the
                        accident.{" "}
                      </p>
                    </div>
                  </li>
                  <li>
                    <div className="seemingly">
                      <h5 className="Seek"> Exchange details</h5>
                      <p>
                        {" "}
                        Since you're making the
                        <strong> Ageas car insurance claim </strong>
                        against the other driver, make sure to exchange vital
                        information such as contact details, vehicle
                        registration numbers and insurance policy details with
                        them.
                      </p>
                    </div>
                  </li>
                  <li>
                    <div className="seemingly">
                      <h5 className="Seek">Hire a no-fault claim solicitor</h5>
                      <p>
                        Contact us on the{" "}
                        <strong>Ageas car claims number</strong> to talk to one
                        of our experienced solicitors and discuss your options.{" "}
                      </p>
                    </div>
                  </li>
                  <li>
                    <div className="seemingly">
                      <h5 className="Seek">Begin your claim</h5>
                      <p>
                        Once you’ve gathered all the documents and evidence to
                        make your claim stronger, call us on the{" "}
                        <strong>Ageas car insurance claims number </strong>to
                        initiate your claim process.
                      </p>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="whyus">
        <div className="container">
          <div className="row">
            <div className="col-xxl-12 col-md-12">
              <div className="why">
                <h2 className="choose">
                  How our simplified{" "}
                  <span className="light">claim process works</span>
                </h2>
                <p className="complexities">
                  Making a claim can be daunting, especially if you’re making it
                  for the first time. However, with our simplified and
                  streamlined claims process, you can effortlessly make the{" "}
                  <strong>Ageas car insurance </strong>claim with us without any
                  hassle. Contact us on{" "}
                  <strong>
                    <a href="tel:0800 772 0850">0800 772 0850 </a>
                  </strong>
                  to begin your claims process.{" "}
                </p>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-xxl-3 col-xl-6 col-lg-6 col-md-6 col-12">
              <div className="box-service">
                <img src={check} className="check" alt="" />
                {/* <img src={experience} className="experience" alt="" /> */}
                <h4>Report your claim</h4>
                <p>
                  Get in touch with us via the{" "}
                  <strong> Ageas claims number </strong>to discuss your claim
                  with our panel of solicitors.
                </p>
              </div>
            </div>
            <div className="col-xxl-3 col-xl-6 col-lg-6 col-md-6 col-12">
              <div className="box-service">
                <img src={check} className="check" alt="" />
                <h4>Share accident details and evidence</h4>
                <p>
                  Provide the details of the accident and submit the crucial
                  evidence that you’ve gathered from the accident scene.
                </p>
              </div>
            </div>
            <div className="col-xxl-3 col-xl-6 col-lg-6 col-md-6 col-12">
              <div className="box-service">
                <img src={check} className="check" alt="" />
                <h4>Claim assessment</h4>
                <p>
                  Our solicitors will review your claim and assess the damages
                  and losses you’ve suffered due to the car accident.{" "}
                </p>
              </div>
            </div>
            <div className="col-xxl-3 col-xl-6 col-lg-6 col-md-6 col-12">
              <div className="box-service">
                <img src={check} className="check" alt="" />
                <h4>Claim resolution </h4>
                <p>
                  We will initiate your claim and work tirelessly to ensure it
                  gets resolved as quickly as possible.{" "}
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="faqs">
        <div className="container">
          <div className="row">
            <div className="col-xxl-12 col-xl-12 col-md-12 col-12">
              <div className="fq">
                <h2>
                  {" "}
                  Frequently Asked{" "}
                  <span className="light">Questions (FAQ)</span>
                </h2>
                <p className="complexities">
                  Got questions about the{" "}
                  <strong>Ageas car insurance claim? </strong>We have them
                  answered here.
                </p>
              </div>
              <div className="allfaq">
                <Accordion defaultActiveKey="0">
                  <Accordion.Item eventKey="0">
                    <Accordion.Header>
                      Should I accept the initial settlement offered by the
                      other party’s insurance company?
                    </Accordion.Header>
                    <Accordion.Body>
                      If you accept any out-of-court settlement by the other
                      party’s insurer, you lose your rights to file{" "}
                      <strong>Ageas car insurance claims</strong> in future. So,
                      before accepting any offer from the other party’s insurer,
                      discuss it with your solicitor to ensure it covers all the
                      damages.
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="1">
                    <Accordion.Header>
                      How long will it take to settle my claim?
                    </Accordion.Header>
                    <Accordion.Body>
                      The settlement time may depend on the complexity of your
                      claim and the evidence you provide. When you call us on
                      the<strong> Ageas claims contact number</strong>, we will
                      process your claim as quickly as possible to ensure it
                      gets settled without any delay.
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="2">
                    <Accordion.Header>
                      Can I choose my own repair shop?
                    </Accordion.Header>
                    <Accordion.Body>
                      Yes. You can choose your own repairer to restore your
                      vehicle to its pre-accident condition. However, we also
                      have a network of manufacturer-approved repairers in the
                      UK who will repair your vehicle to the highest quality
                      standards.
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="3">
                    <Accordion.Header>
                      Do you provide a replacement vehicle while my car is being
                      repaired?{" "}
                    </Accordion.Header>
                    <Accordion.Body>
                      Yes. We will provide you with a like-for-like replacement
                      vehicle to ensure you remain mobile while your car is
                      undergoing repairs.
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="4">
                    <Accordion.Header>
                      What information do I need to provide when filing a claim?
                    </Accordion.Header>
                    <Accordion.Body>
                      When you contact us via
                      <strong> Ageas claims phone number </strong> to make your
                      claim with us, you must provide details of the accident,
                      such as the date, time, location of the accident,
                      descriptions of the damages, the contact information of
                      the other parties involved in the accident and any other
                      vital evidence or information that can help us make your{" "}
                      <strong>Ageas car insurance claims </strong>stronger.
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="5">
                    <Accordion.Header>
                      What happens if my vehicle is declared a total loss?{" "}
                    </Accordion.Header>
                    <Accordion.Body>
                      If your vehicle is a total loss, our solicitors will
                      negotiate with the other party insurer to settle your
                      claim based on the actual cash value of your car before
                      the accident. We will also provide you with a replacement
                      vehicle until your claim gets settled.
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="6">
                    <Accordion.Header>
                      Do I need to obtain a police report?{" "}
                    </Accordion.Header>
                    <Accordion.Body>
                      The police report may contain crucial information about
                      the injuries, damages and other specifications related to
                      the accident. So, it can provide significant details that
                      can strengthen your claim.
                    </Accordion.Body>
                  </Accordion.Item>
                </Accordion>
              </div>
            </div>
          </div>
        </div>
      </section>

      <Footer />
    </>
  );
};

export default Ageas;
