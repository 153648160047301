import React, { useRef } from "react";
import Accordion from "react-bootstrap/Accordion";
import check from "../assets/images/check.png";
import dualcar from "../../src/assets/images/dualcar.webp";
import carbnr1 from "../../src/assets/images/carbnr1.webp";
import Sidebarinner from "../components/Sidebar-inner";
import phonecall from "../assets/images/phonecall.png";
import Footer from "../components/footer";
import { Helmet } from "react-helmet";

const Goskippy = () => {
  const sidebarRef = useRef(null);

  const handleScrollToSidebar = () => {
    if (sidebarRef.current) {
      sidebarRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };
  return (
    <>
          <Helmet>
        <meta charSet="utf-8" />
        <title>GoSkippy Car Insurance Accident Claim Phone Number</title>
        <meta
          name="description"
          content="Looking to make a no-fault accident claim? Contact our dedicated team at the GoSkippy Car Insurance Accident Claim Phone Number  0800 772 0850 for reliable claim support. "
        />
        <link
          rel="canonical"
          href="https://insurance-accident-claims.co.uk/GoSkippy "
        />
      </Helmet>
      <section className="innernav">
        <div className="container">
          <div className="row">
            <div className="col-md-6 col-sm-6">
              <div className="left-logo">
                <h3>
                  Accident<span className="sipo"> Claims</span>
                </h3>
              </div>
            </div>
            <div className="col-md-6 col-sm-6">
              <div className="left-cta">
                <div class="headercnt">
                  <div class="chatmsg">
                    <img
                      src={phonecall}
                      className="phonecall"
                      alt="phonecall "
                    />
                  </div>
                  <div class="box-body">
                    <h3 class="nav-info-box-title">Toll Free Number </h3>
                    <p>
                      <a href="tel:+/">0800 772 0850</a>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section
        className="newbnr"
        style={{ backgroundImage: "url(" + carbnr1 + ")" }}
      >
        <div className="container">
          <div className="row">
            <div className="col-xxl-8 col-xl-8 col-md-10 col-12">
              <div className="innerbnr">
                <h1>
                  <span className="onespan">No-fault</span>{" "}
                  <span className="sci">accident  insurance claims </span> support services
                </h1>
                <p className="brokerage">
                  Are you in search of help after a no-fault car accident? Our{" "}
                  <span className="yellow">
                    GoSkippy insurance accident support{" "}
                  </span>
                  services provide everything you need.
                </p>
                <div className="bnrbtn">
                  <a
                    class="db-btn-border btn-rollover phone"
                    href="tel:0800 772 0850"
                  >
                    Road Accident Claim Help?{" "}
                    <i class="fa-solid fa-arrow-right"></i>
                  </a>
                </div>
                <div className="bnrbtn">
                  <a
                    className="db-btn-border btn-rollover desktop"
                    onClick={handleScrollToSidebar}
                  >
                    Road Accident Claim Help?{" "}
                    <i className="fa-solid fa-arrow-right"></i>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="stickysection">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="group-4-8">
                <div className="cl8 tf-tab">
                  <div className="content-tab">
                    <div className="inner-content">
                      <h2>
                        Your ultimate destination <br />
                        <span className="light">
                          for inner calm after a car accident{" "}
                        </span>{" "}
                      </h2>
                      <p className="disciplines">
                        We are a leading provider of car accident management
                        solutions and we aim to reduce complications, anxieties
                        and troubles with our extensive range of{" "}
                        <strong> GoSkippy insurance accident support</strong>{" "}
                        services. By calling us on the{" "}
                        <strong> GoSkippy accident claims number</strong> , you
                        will be back on the road quickly and safely at no extra
                        cost.
                      </p>
                      <hr />
                      <p className="bold-text">No hidden charges </p>
                      <p className="bottom-te">
                        If you call our accident number{" "}
                        <a href="tel:0800 772 0850">0800 772 0850</a> to get{" "}
                        <strong> GoSkippy insurance accident support </strong>{" "}
                        from us, we shall not charge you any money whatsoever.
                        This means that there’s no need to agonise about having
                        to make any sudden or disguised payments once you decide
                        to choose our{" "}
                        <strong> GoSkippy insurance support services. </strong>
                      </p>
                      <hr />
                      <p className="bold-text">Zero excess </p>
                      <p className="bottom-te">
                        In discussions with your insurer, you may incur an
                        insurance excess cost. But partnering with us removes
                        this additional fee completely. Hence, while enjoying
                        the service of quality without any financial engagement,
                        it is worth noting that one can be at ease in mind
                        knowing they are well taken care of.
                      </p>
                      <hr />
                      <p className="bold-text">
                        No claim bonus is not affected
                      </p>
                      <p className="bottom-te">
                        You can rely on us for any no-claims bonus loss as long
                        as it is through the{" "}
                        <strong> GoSkippy claims contact number </strong>.
                        Contrary to your insurer, none of your accrued discounts
                        will ever be tampered with by us.{" "}
                      </p>
                      <hr />
                      <p className="bold-text">Trusted auto repairs</p>
                      <p className="bottom-te">
                        We have partnered with an extensive network consisting
                        of approved repairers who carry out reliable repairs
                        that involve using genuine parts. To get high-standard
                        services for fixing your damaged vehicle, you can call
                        our car accident helpline{" "}
                        <a href="tel:0800 772 0850">0800 772 0850</a>.{" "}
                      </p>
                      <hr />
                      <p className="bold-text">Free courtesy cars </p>
                      <p className="bottom-te">
                        Make a point of calling the{" "}
                        <strong> GoSkippy accident claim phone number </strong>{" "}
                        to get a free courtesy car to back on the road again. We
                        will provide you with an alternative car within the
                        shortest time possible.
                      </p>
                      <hr />
                      <p className="bold-text">Dedicated support</p>
                      <p className="bottom-te">
                        We are well conversant in managing non-fault motor
                        vehicle crash compensation claims in the UK. Such are
                        the promises we make when you happen to make such a call
                        through our dedicated helpline at{" "}
                        <strong> GoSkippy accident claims helpline </strong> .
                        We shall hold your hand throughout your difficult
                        moments, every inch of the way.
                      </p>
                      <hr />
                      <p className="bold-text">Professional advice </p>
                      <p className="bottom-te">
                        Our commitment is to give you the best possible guidance
                        on your claim. Our team of experienced solicitors can
                        provide you with the most suitable advice for obtaining
                        the highest payout in relation to your{" "}
                        <strong> GoSkippy insurance accident claim </strong> ,
                        once you contact us through our{" "}
                        <strong>
                          GoSkippy accident claims number{" "}
                          <a href="tel:0800 772 0850">0800 772 0850</a>.
                        </strong>
                      </p>
                      <hr />
                      <p className="bold-text">Complete transparency </p>
                      <p className="bottom-te">
                        We are always open and honest when giving out{" "}
                        <strong>GoSkippy insurance accident support </strong>
                        services. At every point of handling your{" "}
                        <strong>GoSkippy insurance accident claim </strong>, we
                        will keep you posted on what is going on.
                      </p>
                      <hr />
                      <p className="bold-text">Quick claim settlement</p>
                      <p className="bottom-te">
                        We make sure that we settle your claims as quickly as
                        possible. When you call us via the{" "}
                        <strong> GoSkippy accident number </strong>, we won’t
                        waste time in making sure that your case does not take
                        long to be solved.
                      </p>
                      <hr />

                      <p className="bold-text">No-fault claims specialist </p>
                      <p className="bottom-te">
                        You will be handed over to one claims handler who will
                        work with you from the beginning through towards a
                        successful final result.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="cl4" ref={sidebarRef}>
                  <Sidebarinner />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="photographs">
        <div className="container">
          <div className="row">
            <h2 className="road">
              End-to-end assistance{" "}
              <span className="light">
                and claims
                <br /> management support
              </span>{" "}
            </h2>
            <div className="col-xxl-6 col-xl-6 col-md-12 col-12">
              <div className="fourbox">
                <ul>
                  <li>
                    <img src={check} className="check" alt="" />
                    After a no-fault car accident, we will provide you with
                    end-to-end assistance. By dialling our accident number{" "}
                    <a href="tel:0800 772 0850">0800 772 0850</a>, you will get
                    in touch with us for immediate emergency support.
                  </li>
                  <hr />
                  <li>
                    <img src={check} className="check" alt="" />
                    We will therefore ensure that we fully assist you so that
                    you can have your vehicle back on the road within a short
                    period of time.
                  </li>
                  <hr />
                  <li>
                    <img src={check} className="check" alt="" />
                    In case there is a higher likelihood of recovering your
                    losses, our dedicated group will evaluate your{" "}
                    <strong> GoSkippy insurance claim </strong> . Therefore, if
                    it is worth proceeding with your claim, we shall continue to
                    do so.
                  </li>
                  <hr />
                  <li>
                    <img src={check} className="check" alt="" />
                    Furthermore, just to help you go through all these days
                    without any problem and to keep moving or doing other things
                    that are related to your daily activities, we will give you
                    a like-for-like replacement vehicle at hand which would be
                    available for you should you need it at any time.
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-xxl-6 col-xl-6 col-md-12 col-12">
              <div className="fourbox">
                <ul>
                  <li>
                    <img src={check} className="check" alt="" />
                    With manufacturer-approved parts used by our reliable and
                    authorised repair centres for fixing damaged vehicles
                    excellently, you can rest assured your car will be repaired
                    according to the manufacturer's specifications.
                  </li>
                  <hr />
                  <li>
                    <img src={check} className="check" alt="" />
                    Upon successful completion of repairing your car, we perform
                    quality checks on the repairs done to ensure they meet the
                    highest standards.
                  </li>
                  <hr />
                  <li>
                    <img src={check} className="check" alt="" />
                    When you call us regarding our accident support services, we
                    shall gather all pertinent documents in order to commence
                    your claim.
                  </li>
                  <hr />
                  <li>
                    <img src={check} className="check" alt="" />
                    Our team will contact you once the car has been fixed up to
                    help in arranging for it to be delivered back safely.
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="carprocess">
        <div className="container ">
          <div className="row">
            <div className="col-xxl-12 col-xxl-12 col-md-12 col-12">
              <h2 className="trusted-title">
                {" "}
                Complete and comprehensive GoSkippy <br />
                <span className="light">insurance support services</span>{" "}
              </h2>
            </div>
          </div>
          <div className="row" id="possible">
            <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-12">
              <div className="claim">
                <ul>
                  <li>
                    <span className="sleej">Car recovery:</span> If you call us
                    through the number{" "}
                    <a href="tel:0800 772 0850">0800 772 0850</a> at the car
                    accident helpline, we shall find a very quick way of picking
                    up your damaged vehicle from the accident scene. Our
                    approved recovery agents recover your vehicle from the
                    accident scene and secure it in our safe yards.
                  </li>
                  <li>
                    <span className="sleej">Accident repairs:</span>Through our
                    extensive network of accredited and trustworthy repairers,
                    we can organise prompt, flexible and high-quality repairs
                    for vehicles. When you make a claim on the{" "}
                    <strong> GoSkippy car insurance claim number </strong> , we
                    ensure that we fix your vehicle properly either at the
                    nearest approved garage or preferred by you.
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-12">
              <div className="claim" id="blue">
                <ul>
                  <li>
                    <span className="sleej">Vehicle storage:</span> It is
                    essential that your vehicle, which has been damaged in a car
                    accident, be transferred to a secured place where it will
                    not suffer further damage. To arrange for vehicle storage in
                    our secure storage yards call our car accidents helpline on{" "}
                    <a href="tel:0800 772 0850">0800 772 0850</a>. Our recovery
                    team will come to the accident scene and pick up the damaged
                    car from there until its repair is done.
                  </li>
                  <li>
                    <span className="sleej">Vehicle replacement: </span> We are
                    able to provide you with a like-for-like replacement vehicle
                    if your car is unfit for road use. In cases where your
                    automobile can not be driven, find us at{" "}
                    <strong> GoSkippy claims contact number </strong>and ask for
                    help with a courtesy car. We do not charge anything more
                    when we give you a credit hire car. You may keep it until
                    yours gets fully fixed.
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-12">
              <div className="claim">
                <ul>
                  <li>
                    <span className="sleej">
                      Personal injury claim support:
                    </span>
                    Our experienced solicitors have spent years maximising
                    compensation granted to those who have suffered accidents
                    that were not their fault. And so, whenever you communicate
                    via <strong>GoSkippy car insurance claim number</strong>, we
                    will extend total support towards your personal injury claim
                    so as to ensure that you get maximum compensation.
                  </li>
                  <li>
                    <span className="sleej">Claims management:</span> Our
                    solicitors are experienced in handling all the necessary
                    paperwork and intricacies of your claim. From supervising
                    paperwork to comprehensive claims services, we shall handle
                    every area of your{" "}
                    <strong> GoSkippy insurance accident claim </strong>Call us
                    through the <strong> GoSkippy accident number </strong>to
                    start your claim with us.
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="yellowcta">
        <div className="container">
          <div className="row">
            <div className="cta2">
              <div className="col-xxl-8 col-xl-8 col-lg-8 col-12">
                <div className="legal">
                  <h3 className="experienced">
                    Have more questions?{" "}
                    <span className="light">Speak to our experts.</span>{" "}
                  </h3>
                  <p className="professionals">
                    We’re here to help you at every step of the claim process.
                    Get in touch with our experts through the{" "}
                    <strong> GoSkippy insurance accident claim number </strong>{" "}
                    to get professional advice for your claim.
                  </p>
                </div>
              </div>
              <div className="col-xxl-4 col-xl-4 col-lg-4 col-12">
                <div class="process">
                  <a class="tf-button style-1 phone" href="tel:0800 772 0850">
                    Get in touch<span class="icon-keyboard_arrow_right"></span>
                  </a>
                </div>
                <div class="process">
                  <a
                    className="tf-button style-1 desktop"
                    onClick={handleScrollToSidebar}
                  >
                    Get in touch
                    <span className="icon-keyboard_arrow_right"></span>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <search className="updatedform">
        <div className="container">
          <div className="row text-center">
            <div className="col-xxl-12 col-xl-12 col-md-12 col-12">
              <h2 className="whiteheading">
                {" "}
                Benefits of Hiring Us as Your <br />
                <span className="light">Accident Management Partners</span>
              </h2>
              <p className="sustained">
                Instead of contacting your insurer to settle your uncontested
                claims, contact us through our claims contact number{" "}
                <a href="tel:0800 772 0850">0800 772 0850</a>. We will provide
                better options and advantages than those that accrue from
                choosing an insurance company. Moreover, selecting us will
                facilitate efficient accident management without financial
                constraints.
              </p>
            </div>
          </div>
          <div className="row">
            <div className="col-xxl-4 col-xl-4 col-lg-6 col-md-12 col-12">
              <div className="Claimant">
                <h5>Cost Savings</h5>
                <p className="collision">
                  Incidents such as downtime can lead to excessive costs in
                  handling claims thus slowing down their processing and
                  decreasing settlements made towards them. Our immediate
                  assistance and effective administration of claims make this
                  arrangement a cost-effective settlement for you though it is
                  not acknowledged by many people.
                </p>

                <h5>24/7 recovery and helpline</h5>
                <p className="collision">
                  At a moment’s notice, our dedicated team is available to give
                  you urgent help in case of a non-fault road accident. If an
                  accident occurs, you can reach out to us via the{" "}
                  <strong> GoSkippy accident number </strong>to inform us about
                  it and get needed assistance.
                </p>

                <h5>Top quality repairs</h5>
                <p className="collision">
                  We do not have anything except your well-being in mind.
                  Therefore, we offer service for the best possible repairs
                  through our extensive panel of approved repairers within the
                  UK.
                </p>
              </div>
            </div>
            <div className="col-xxl-4 col-xl-4 col-lg-6 col-md-12 col-12">
              <div className="Claimant">
                <h5>Complete accident management solutions </h5>
                <p className="collision">
                  Our services range from vehicle repairs and storage facilities
                  to replacement vehicles and complete claims administration.
                  Thus, we are capable of providing total solutions for all your
                  accident management needs.
                </p>

                <h5>No-obligation advice</h5>
                <p className="collision">
                  We provide no-obligation guidance on no-fault claims without
                  any coercion or obligation to proceed with your claim with us.
                  If you want free advice, call us at the{" "}
                  <strong>GoSkippy accident claim phone number.</strong>
                </p>

                <h5>
                  Efficient Handling of{" "}
                  <strong>GoSkippy Insurance Claims</strong>
                </h5>
                <p className="collision">
                  We will manage your no-blame claim effectively and
                  efficiently. While working with us you will receive regular
                  updates on how far your claim has moved.
                </p>
              </div>
            </div>
            <div className="col-xxl-4 col-xl-4 col-lg-12 col-md-12 col-12">
              <div className="Claimant">
                <h5>Superfast claim settlement</h5>
                <p className="collision">
                  Our objective is to expedite the{" "}
                  <strong> GoSkippy insurance accident claims </strong>process.
                  Get in touch with our department on the{" "}
                  <strong> GoSkippy accident helpline </strong> for seamless
                  processing of your claim.
                </p>

                <h5> Secure vehicle storage </h5>
                <p className="collision">
                  We house your damaged vehicle safely in protected storage
                  yards to keep it safe and prevent further damage. You can
                  always call us through this number,{" "}
                  <a href="tel:0800 772 0850">0800 772 0850</a> to get your car
                  stored in our secured maintained yards.
                </p>

                <h5>Experienced Panel of Solicitors</h5>
                <p className="collision">
                  We have a team of solicitors who are experienced in dealing
                  with claims for{" "}
                  <strong> GoSkippy insurance accident claims </strong>. They
                  will go above and beyond to get you the best possible result
                  from your case.
                </p>
              </div>
            </div>
          </div>
        </div>
      </search>

      <section className="Accident-claim">
        <div className="container">
          <div className="row">
            <div className="needed">
              <h2 className="trusted-title">
                How to win Maximum Compensation <br />
                <span className="light"> for Your No-Fault Claim </span>
              </h2>
              <p className="complexities">
                Do you want maximum compensation after a no-fault situation?
                Wondering what measures should be taken so that you receive the
                highest compensation after a non-fault road traffic accident?
                Here are some things that must be done if you’ve been involved
                in a non-fault car accident to win maximum compensation.
              </p>
            </div>
            <hr />
          </div>
          <div className="row" id="incdes">
            <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-12">
              <div className="Preparing">
                <ul>
                  <li>
                    <div className="seemingly">
                      <h5 className="Seek">
                        Never leave the scene of the crash:
                      </h5>
                      <p>
                        {" "}
                        Running away from an accident scene post-car collision
                        might lead to severe consequences. Thus, find somewhere
                        safe and park there somehow.{" "}
                      </p>
                    </div>
                  </li>
                  <li>
                    <div className="seemingly">
                      <h5 className="Seek"> Report car crash: </h5>
                      <p>
                        After immediately getting involved in a car accident,
                        report the{" "}
                        <strong> GoSkippy road traffic accident </strong> to the
                        police as quickly as possible. Additionally, contact us
                        through our accident claims helpline{" "}
                        <a href="tel:0800 772 0850">0800 772 0850</a> for{" "}
                        <strong> GoSkippy insurance accident support </strong>
                        services.{" "}
                      </p>
                    </div>
                  </li>
                  <li>
                    <div className="seemingly">
                      <h5 className="Seek">
                        Gather all evidence related to the car accident:
                      </h5>
                      <p>
                        Having no sufficient evidence makes it hard for you to
                        prove your innocence. As a result, gather all requisite
                        evidence like photos or videos of the scene and injuries
                        from an accident, eyewitness statements, CCTV tapes and
                        a copy of the police report.
                      </p>
                    </div>
                  </li>
                  <li>
                    <div className="seemingly">
                      <h5 className="Seek">Visit a medical practitioner</h5>
                      <p>
                        Take note of any injuries sustained by yourself or any
                        other passengers in the vehicle and go seek immediate
                        assistance from a physician for treatment of your
                        wounds.
                      </p>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
            <div
              className="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-12"
              id="dualcardiv"
            >
              <img src={dualcar} alt="" className="dualcar" />
            </div>
            <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-12">
              <div className="Preparing">
                <ul>
                  <li>
                    <div className="seemingly">
                      <h5 className="Seek">
                        Evaluate the damage to your vehicle:
                      </h5>
                      <p>
                        Firstly go around your vehicle checking for damages and
                        then call us on our helpline number{" "}
                        <a href="tel:0800 772 0850">0800 772 0850</a> to ask for
                        your car’s recovery at a place of safety.{" "}
                      </p>
                    </div>
                  </li>
                  <li>
                    <div className="seemingly">
                      <h5 className="Seek"> Document your losses:</h5>
                      <p>
                        Keep track of all costs and expenses incurred because of
                        the collision including medical fees, travelling costs,
                        repairing damage done to your car or anything else.
                      </p>
                    </div>
                  </li>
                  <li>
                    <div className="seemingly">
                      <h5 className="Seek">Never accept the first offer:</h5>
                      <p>
                        The insurance company may reach out to you for an early
                        settlement. Do not agree to their proposal because it
                        can result in loss of rights for further compensation.{" "}
                      </p>
                    </div>
                  </li>
                  <li>
                    <div className="seemingly">
                      <h5 className="Seek">Get your claim filed</h5>
                      <p>
                        To make a no-fault
                        <strong> GoSkippy insurance accident claim </strong>with
                        us and get professional consultation on this matter,
                        please call us on the{" "}
                        <strong> GoSkippy car insurance claim number. </strong>
                      </p>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="whyus">
        <div className="container">
          <div className="row">
            <div className="col-xxl-12 col-md-12">
              <div className="why">
                <h2 className="choose">
                  Effortless GoSkippy insurance
                  <span className="light">accident claim process</span>
                </h2>
                <p className="complexities">
                  In case you want to begin your{" "}
                  <strong> GoSkippy insurance accident claim </strong> process
                  with our assistance, please, contact us via our accident
                  number <a href="tel:0800 772 0850">0800 772 0850</a> to start
                  your claim.
                </p>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-xxl-3 col-xl-6 col-lg-6 col-md-6 col-12">
              <div className="box-service">
                <img src={check} className="check" alt="" />
                {/* <img src={experience} className="experience" alt="" /> */}
                <h4>Call us </h4>
                <p>
                  Contact us at{" "}
                  <strong> GoSkippy accident claim phone number </strong>to
                  report a car accident and submit your request to claim
                  compensation for your damages.
                </p>
              </div>
            </div>
            <div className="col-xxl-3 col-xl-6 col-lg-6 col-md-6 col-12">
              <div className="box-service">
                <img src={check} className="check" alt="" />
                <h4>Provide accident details </h4>
                <p>
                  Inform our experts about all aspects relating to the crash
                  such as date, time, location, cause or any other aspect that
                  will support your case.
                </p>
              </div>
            </div>
            <div className="col-xxl-3 col-xl-6 col-lg-6 col-md-6 col-12">
              <div className="box-service">
                <img src={check} className="check" alt="" />
                <h4>Give over evidence and documents</h4>
                <p>
                  Share all necessary pieces of proof from the crash scene like
                  videos and photographs, details of vehicles involved in the
                  accident, witness statements etc.
                </p>
              </div>
            </div>
            <div className="col-xxl-3 col-xl-6 col-lg-6 col-md-6 col-12">
              <div className="box-service">
                <img src={check} className="check" alt="" />
                <h4>Claim initiation</h4>
                <p>
                  We will start your claim after putting together every relevant
                  document and when the{" "}
                  <strong> GoSkippy insurance accident claim </strong> is
                  processed you will receive proper reimbursement for your
                  damages.{" "}
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="faqs">
        <div className="container">
          <div className="row">
            <div className="col-xxl-12 col-xl-12 col-md-12 col-12">
              <div className="fq">
                <h2>
                  {" "}
                  Frequently Asked{" "}
                  <span className="light">Questions (FAQ)</span>
                </h2>
                <p className="complexities">
                  Got questions about the{" "}
                  <strong> GoSkippy car insurance claims? </strong>We have them
                  answered here.{" "}
                </p>
              </div>
              <div className="allfaq">
                <Accordion defaultActiveKey="0">
                  <Accordion.Item eventKey="0">
                    <Accordion.Header>
                      Will my no-claims bonus be affected if I decide to use
                      your services for accident management?
                    </Accordion.Header>
                    <Accordion.Body>
                      No. We shall reinstate your no-claim bonus because we will
                      be recovering all our expenses from the insurer of the
                      at-fault party. This will enable you to safeguard your
                      earned discounts.
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="1">
                    <Accordion.Header>
                      Why should I choose your company for my accident
                      management needs?
                    </Accordion.Header>
                    <Accordion.Body>
                      Tackling road accidents on one’s own can be a
                      nerve-wracking experience but with us around, we can save
                      you from that by taking away all the stress and worries
                      that come with it. This includes everything right from
                      vehicle recovery to replacement repairs as well as claims
                      management without any extra charges or hidden fees. You
                      can dial our claims helpline{" "}
                      <a href="tel:0800 772 0850">0800 772 0850</a> anytime you
                      need urgent assistance in case of motor accidents.
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="2">
                    <Accordion.Header>
                      Will I have to pay any extra charges for the accident
                      management assistance?{" "}
                    </Accordion.Header>
                    <Accordion.Body>
                      No, you will not be charged anything as we will claim all
                      the costs from the wrong driver’s insurer.
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="3">
                    <Accordion.Header>
                      How long will it take to get my damaged vehicle recovered
                      from an accident scene?{" "}
                    </Accordion.Header>
                    <Accordion.Body>
                      The vehicle recovery time depends on various factors such
                      as the severity of the accident, where the accident
                      happened or legal formalities that must be adhered to
                      towards having your car recovered. We shall try our best
                      to have your car retrieved within the least possible time.
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="4">
                    <Accordion.Header>
                      Can I select my own repairer?
                    </Accordion.Header>
                    <Accordion.Body>
                      Yes. We give you total freedom to choose who repairs your
                      vehicle. Just let us know which garage is likely to meet
                      all your needs and we will make arrangements for the
                      repair of your choice.
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="5">
                    <Accordion.Header>
                      {" "}
                      Will the replacement vehicle you provide match my own
                      vehicle?{" "}
                    </Accordion.Header>
                    <Accordion.Body>
                      We assure you with another car like yours when yours is
                      unroadworthy. You can keep the replacement vehicle until
                      your car gets repaired.
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="6">
                    <Accordion.Header>
                      What type of losses or damages can be claimed after a
                      non-fault accident?{" "}
                    </Accordion.Header>
                    <Accordion.Body>
                      The damages and losses you have incurred as a result of
                      the non-fault accident are compensable. These include
                      medical bills, transport costs, payment for both mental
                      and emotional injuries, vehicle repair or replacement
                      expenses, lost earnings or other miscellaneous costs.
                    </Accordion.Body>
                  </Accordion.Item>
                </Accordion>
              </div>
            </div>
          </div>
        </div>
      </section>

      <Footer />
    </>
  );
};

export default Goskippy;
