import React, { useRef } from "react";
import Accordion from "react-bootstrap/Accordion";
import check from "../assets/images/check.png";
import dualcar from "../../src/assets/images/dualcar.webp";
import carbnr1 from "../../src/assets/images/carbnr1.webp";
import Sidebarinner from "../components/Sidebar-inner";
import phonecall from "../assets/images/phonecall.png";
import Footer from "../components/footer";
import { Helmet } from "react-helmet";

const Peopleschoice = () => {
    const sidebarRef = useRef(null);

    const handleScrollToSidebar = () => {
        if (sidebarRef.current) {
            sidebarRef.current.scrollIntoView({ behavior: "smooth" });
        }
    };
    return (
        <>
        <Helmet>
        <meta charSet="utf-8" />
        <title>People's Choice Car Insurance Accident Claim Number</title>
        <meta
          name="description"
          content="Looking to file a car insurance claim? Call us on the People's Choice car insurance claim number 0800 772 0850 to get expert guidance from our no-fault claim solicitors. "
        />
        <link
          rel="canonical"
          href="https://insurance-accident-claims.co.uk/Peopleschoice"
        />
      </Helmet>
            <section className="innernav">
                <div className="container">
                    <div className="row">
                        <div className="col-md-6 col-sm-6">
                            <div className="left-logo">
                                <h3>
                                    Accident<span className="sipo"> Claims</span>
                                </h3>
                            </div>
                        </div>
                        <div className="col-md-6 col-sm-6">
                            <div className="left-cta">
                                <div class="headercnt">
                                    <div class="chatmsg">
                                        <img
                                            src={phonecall}
                                            className="phonecall"
                                            alt="phonecall "
                                        />
                                    </div>
                                    <div class="box-body">
                                        <h3 class="nav-info-box-title">Toll Free Number </h3>
                                        <p>
                                            <a href="tel:0800 772 0850">0800 772 0850</a>
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section
                className="newbnr"
                style={{ backgroundImage: "url(" + carbnr1 + ")" }}
            >
                <div className="container">
                    <div className="row">
                        <div className="col-xxl-7 col-xl-7 col-md-10 col-12">
                            <div className="innerbnr">
                                <h1>
                                    <span className="onespan"> Rapid and reliable accident </span>{" "}
                                    <span className="sci"> insurance claims  </span>  number
                                </h1>
                                <p className="brokerage">
                                    From assessing your car to settling your claim, we will handle everything so that you can get back on track as soon as possible.
                                </p>
                                <div className="bnrbtn">
                                    <a class="db-btn-border btn-rollover phone" href="tel:0800 772 0850">
                                        Road Accident Claim Help?{" "}
                                        <i class="fa-solid fa-arrow-right"></i>
                                    </a>
                                </div>
                                <div className="bnrbtn">
                                    <a className="db-btn-border btn-rollover desktop" onClick={handleScrollToSidebar}>
                                        Road Accident Claim Help? <i className="fa-solid fa-arrow-right"></i>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="stickysection">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="group-4-8">
                                <div className="cl8 tf-tab">
                                    <div className="content-tab">
                                        <div className="inner-content">
                                            <h2>
                                                Leading UK claims management

                                                <span className="light">  professionals</span>{" "}
                                            </h2>
                                            <p className="disciplines">
                                                We are among the leading entities in the UK providing <strong> People's Choice insurance claims help and have successfully handled thousands of cases. Dial us through the People's Choice car insurance contact number </strong> to make a claim. Your <strong> People's Choice car insurance claim </strong> will be managed by us at no extra cost.
                                            </p>
                                            <hr />
                                            <p className="bold-text">No excess fee</p>
                                            <p className="bottom-te">
                                                If you did not cause an accident, you won’t need to pay for any policy excess charges, which would prevent your premiums from going up.                                          </p>
                                            <hr />
                                            <p className="bold-text">No claims bonus protection</p>
                                            <p className="bottom-te">
                                                You don't have to worry about losing your no-claim bonus, as we won’t initiate a claim against your own insurance policy.                                      </p>
                                            <hr />
                                            <p className="bold-text">
                                                Fast vehicle recovery services.
                                            </p>
                                            <p className="bottom-te">
                                                Following an accident or crash, we won’t leave you stranded at the side of the road. Just call our emergency assistance service using the <strong> People's Choice car insurance contact number </strong>  immediately, and we guarantee fast and safe retrieval of your vehicle.
                                            </p>
                                            <hr />
                                            <p className="bold-text">Approved garages </p>
                                            <p className="bottom-te">
                                                Our approved garage network of car manufacturers is vast and will have your vehicle fixed to the highest standard possible.
                                            </p>
                                            <hr />
                                            <p className="bold-text">Replacement cars are on a like-for-like basis</p>
                                            <p className="bottom-te">
                                                If your vehicle is being repaired or rendered unroadworthy, you can get another car of the same makes through like-for-like hire.                                          </p>
                                            <hr />
                                            <p className="bold-text">An experienced team of legal experts
                                            </p>
                                            <p className="bottom-te">
                                                Our competent solicitors have a proven record of securing maximum compensation for <strong> People's Choice insurance claims.</strong>                                </p>
                                            <hr />
                                            <p className="bold-text"> Quick claim processing</p>
                                            <p className="bottom-te">
                                                We have an efficient and prompt claim process, requiring no paperwork hassles or lengthy follow-up calls.                                            </p>
                                            <hr />
                                            <p className="bold-text">Unbiased free advice</p>
                                            <p className="bottom-te">
                                                Talk to us on <strong>
                                                    {" "}
                                                    <a href="tel:0800 772 0850">0800 772 0850 </a>{" "}
                                                </strong>  anytime to get expert advice about <strong> People's Choice insurance claims</strong>  without obligation. Our solicitors will guide you properly on how to proceed with your case.
                                            </p>
                                            <hr />
                                            <p className="bold-text">No-win, no-fee</p>
                                            <p className="bottom-te">
                                                We adopt a no-win, no-fee approach when dealing with our client’s insurance claims through our solicitors. Consequently, if you lose, you pay nothing.         </p>
                                            <hr />

                                            <p className="bold-text">Round-the-clock support</p>
                                            <p className="bottom-te">
                                                Emergency help and advice can always be provided by us in case of an accident. For immediate assistance after a non-fault road traffic accident, please call our car insurance claims line on <strong>
                                                    {" "}
                                                    <a href="tel:0800 772 0850">0800 772 0850 .</a>{" "}
                                                </strong>                                     </p>
                                        </div>
                                    </div>
                                </div>
                                <div className="cl4" ref={sidebarRef}>
                                    <Sidebarinner />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="photographs">
                <div className="container">
                    <div className="row">
                        <h2 className="road">
                            We provide specialised accident management support  {" "}
                            <span className="light">
                                to relieve  <br />
                                you of the burden
                            </span>{" "}
                        </h2>
                        <div className="col-xxl-6 col-xl-6 col-md-12 col-12">
                            <div className="fourbox">
                                <ul>
                                    <li>
                                        <img src={check} className="check" alt="" />
                                        In case of an emergency due to a road accident, contact us through the<strong>People's Choice car insurance phone number.  </strong>

                                    </li>
                                    <hr />
                                    <li>
                                        <img src={check} className="check" alt="" />
                                        We will determine if there is any party at fault who can be claimed for damages on your behalf.                                </li>
                                    <hr />
                                    <li>
                                        <img src={check} className="check" alt="" />
                                        Our duty will include giving guidance when making<strong> People's Choice car insurance claims </strong> so that you get back what was lost.                    </li>
                                    <hr />
                                    <li>
                                        <img src={check} className="check" alt="" />
                                        We will have our experienced team recover your vehicle from where the accident happened.             </li>
                                </ul>
                            </div>
                        </div>
                        <div className="col-xxl-6 col-xl-6 col-md-12 col-12">
                            <div className="fourbox">
                                <ul>
                                    <li>
                                        <img src={check} className="check" alt="" />
                                        We will organise repairs with our network of accredited manufacturers’ repairers.                            </li>
                                    <hr />
                                    <li>
                                        <img src={check} className="check" alt="" />
                                        If it’s impossible for your car to be repaired, we send an independent engineer to assess its value.                            </li>
                                    <hr />
                                    <li>
                                        <img src={check} className="check" alt="" />
                                        To update you on how much work has been done towards fixing your car, we will keep checking the progress of your repairs.                                </li>
                                    <hr />
                                    <li>
                                        <img src={check} className="check" alt="" />
                                        Contact us on
                                        <strong>
                                            {" "}
                                            <a href="tel:0800 772 0850">0800 772 0850</a> {" "}
                                        </strong> so that we can discuss further how you can make a <strong>  People's Choice insurance claim </strong>to get compensation for damages and losses.                                  </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="carprocess">
                <div className="container ">
                    <div className="row">
                        <div className="col-xxl-12 col-xxl-12 col-md-12 col-12">
                            <h2 className="trusted-title">
                                {" "}
                                Accident management services  <br />
                                <span className="light">  we offer </span>{" "}
                            </h2>
                        </div>
                    </div>
                    <div className="row" id="possible">
                        <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-12">
                            <div className="claim">
                                <ul>
                                    <li>
                                        <span className="sleej">Car recovery :</span> To have your vehicle recovered after an incident, dial the <strong>  People's Choice car insurance contact number. </strong> Our vehicle recovery services ensure that your vehicle is quickly removed from the accident scene and stored in a safe place.

                                    </li>
                                    <li>
                                        <span className="sleej">Approved repairs:</span> If you need your car repaired, just call us through the <strong> People's Choice insurance claim number.</strong>  We will arrange quick repairs of your vehicle from our trusted network of manufacturer-approved repairers.
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-12">
                            <div className="claim" id="blue">
                                <ul>
                                    <li>
                                        <span className="sleej">Vehicle storage:</span> Are you concerned about the safe storage of your car after an accident that was not caused by you? Kindly get in touch with our team via the <strong> People's Choice insurance claim number. </strong> We will temporarily store your vehicle in well-maintained yards until your claim is settled.
                                    </li>
                                    <li>
                                        <span className="sleej">Replacement vehicle:</span> In case it is determined that fixing it will be impossible, then a replacement vehicle will be given to you. Give us a call on
                                        <strong>
                                            {" "}
                                            <a href="tel:0800 772 0850">0800 772 0850</a>{" "}
                                        </strong> through the emergency helpline, and we will provide you with a replacement vehicle similar to your own vehicle so that you can continue doing your daily chores without any concerns.


                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-12">
                            <div className="claim">
                                <ul>
                                    <li>
                                        <span className="sleej">Injury claims assistance:</span>
                                        Dialling the <strong> People's Choice insurance claim number </strong> is the best approach to knowing if you are entitled to personal injury compensation or not. Our solicitors will provide you with precious advice on how to get damages for personal injury.                                  </li>
                                    <li>
                                        <span className="sleej">Claim management:</span>  From handling paperwork, bargaining with insurers, arranging vehicle repairs and offering alternative vehicles, we shall take care of everything.


                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="yellowcta">
                <div className="container">
                    <div className="row">
                        <div className="cta2">
                            <div className="col-xxl-8 col-xl-8 col-lg-8 col-12">
                                <div className="legal">
                                    <h3 className="experienced">
                                        Got More questions? Talk to {" "}
                                        <span className="light">  our specialists
                                        </span>{" "}
                                    </h3>
                                    <p className="professionals">
                                        Get in touch with our no-fault claim experts via <strong> car insurance claim number</strong>
                                        {" "}
                                        <strong>{" "}
                                            <a className="cpt-no" href="tel:0800 772 0850">
                                                {" "}
                                                0800 772 0850 {" "}
                                            </a>{" "}
                                        </strong>
                                        to get the best advice for your
                                        <strong> People’s Choice car insurance claim.  </strong>
                                    </p>
                                </div>
                            </div>
                            <div className="col-xxl-4 col-xl-4 col-lg-4 col-12">
                                <div class="process">
                                    <a class="tf-button style-1 phone" href="tel:0800 772 0850">
                                        Get in touch<span class="icon-keyboard_arrow_right"></span>
                                    </a>
                                </div>
                                <div class="process">
                                    <a className="tf-button style-1 desktop" onClick={handleScrollToSidebar}>
                                        Get in touch<span className="icon-keyboard_arrow_right"></span>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <search className="updatedform">
                <div className="container">
                    <div className="row text-center">
                        <div className="col-xxl-12 col-xl-12 col-md-12 col-12">
                            <h2 className="whiteheading">
                                {" "}
                                What sets us
                                <span className="light">  apart?
                                </span>
                            </h2>
                            <p className="sustained">
                                Our main priority is relieving you from non-fault road accident stress. Here are some of the advantages you will enjoy by choosing us as your claims management partner                        </p>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-xxl-4 col-xl-4 col-lg-6 col-md-12 col-12">
                            <div className="Claimant">
                                <h5>24/7 vehicle recovery</h5>
                                <p className="collision">
                                    Need vehicle recovery services? Simply call the <strong> People's Choice insurance claim number, </strong> and regardless of the time, we will recover you from the accident spot.

                                </p>

                                <h5>Guaranteed repairs</h5>
                                <p className="collision">
                                    We can organise high-quality and guaranteed repairs through our wide network of repairers for your car.                           </p>

                                <h5>No increase in insurance premium </h5>
                                <p className="collision">
                                    The <strong> People's Choice car insurance claim </strong> won't be made against your own insurance policy. Therefore, your premium remains unaffected.                      </p>
                            </div>
                        </div>
                        <div className="col-xxl-4 col-xl-4 col-lg-6 col-md-12 col-12">
                            <div className="Claimant">
                                <h5>Free replacement car
                                </h5>
                                <p className="collision">
                                    We will keep you on the move by offering a free replacement vehicle without any excess.  </p>
                                <h5>A network of reliable partners</h5>
                                <p className="collision">
                                    To ensure that you get excellent services, we have established a strong network of approved repairers and experienced solicitors in the UK.
                                </p>

                                <h5>Fast claims settlement </h5>
                                <p className="collision">
                                    Our solicitors work closely with the third party to settle your <strong> People's Choice motor insurance claims </strong> fairly and quickly.
                                </p>
                            </div>
                        </div>
                        <div className="col-xxl-4 col-xl-4 col-lg-12 col-md-12 col-12">
                            <div className="Claimant">
                                <h5>Immediate help</h5>
                                <p className="collision">
                                    We guarantee that we shall immediately provide emergency assistance after a non-fault road traffic accident without any delay.
                                </p>

                                <h5>Tailored assistance</h5>
                                <p className="collision">
                                    Your claim is our priority. We have dedicated handlers who will take charge of your case
                                    personally and keep you informed about the progress of your claim.
                                </p>

                                <h5>No excess charges</h5>
                                <p className="collision">
                                    There are no extra payments or out-of-pocket expenses for which you have to pay. We shall recover everything from the other party.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </search>

            <section className="Accident-claim">
                <div className="container">
                    <div className="row">
                        <div className="needed">
                            <h2 className="trusted-title">
                                How can you increase the chances of getting <br />
                                <span className="light">adequate compensation?  </span>
                            </h2>
                            <p className="complexities">
                                It is extremely disturbing to be involved in an accident, but what’s more annoying is dealing with the claims procedures. In order to maximise your chances of receiving fair compensation, show beyond doubt that you are innocent by providing substantial evidence. Here’s what you should do if you want just enough money as compensation after a car accident.
                            </p>
                        </div>
                        <hr />
                    </div>
                    <div className="row" id="incdes">
                        <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-12">
                            <div className="Preparing">
                                <ul>
                                    <li>
                                        <div className="seemingly">
                                            <h5 className="Seek">Make sure you’re safe  :</h5>
                                            <p>
                                                {" "}
                                                Stop your car at a safe place and check yourself and others for any injuries, major or minor.                                </p>
                                        </div>
                                    </li>
                                    <li>
                                        <div className="seemingly">
                                            <h5 className="Seek">
                                                Get instant medical assistance:{" "}
                                            </h5>
                                            <p>
                                                If any of the passengers in your vehicle have sustained injuries during the accident, contact a specialist physician immediately.                                    </p>
                                        </div>
                                    </li>
                                    <li>
                                        <div className="seemingly">
                                            <h5 className="Seek">Gather proof:</h5>
                                            <p>
                                                The most important pieces of evidence to be collected from the accident scene include photographs, videos, eyewitness statements, and a copy of the police report.                                          </p>
                                        </div>
                                    </li>
                                    <li>
                                        <div className="seemingly">
                                            <h5 className="Seek">Call the police:</h5>
                                            <p>
                                                You must report the accident to the police. Also, make sure to get a copy of the police report to support your claim.                                  </p>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div
                            className="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-12"
                            id="dualcardiv"
                        >
                            <img src={dualcar} alt="" className="dualcar" />
                        </div>
                        <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-12">
                            <div className="Preparing">
                                <ul>
                                    <li>
                                        <div className="seemingly">
                                            <h5 className="Seek">Exchange information:</h5>
                                            <p>
                                                Swap details with the other driver, including name, address, vehicle details and insurance information.                                          </p>
                                        </div>
                                    </li>
                                    <li>
                                        <div className="seemingly">
                                            <h5 className="Seek">Collect eyewitness details: </h5>
                                            <p>
                                                For future reference purposes, collect eyewitness details and their contact information.                       </p>
                                        </div>
                                    </li>
                                    <li>
                                        <div className="seemingly">
                                            <h5 className="Seek">
                                                Record expenses :
                                            </h5>
                                            <p>Receipts of repair charges, hospital fees or any other payments made need to be recorded to get complete compensation.
                                            </p>
                                        </div>
                                    </li>
                                    <li>
                                        <div className="seemingly">
                                            <h5 className="Seek">
                                                Consult a no-win, no-fee solicitor :
                                            </h5>
                                            <p>Call us on the helpline number
                                                <strong>
                                                    {" "}
                                                    <a href="tel:0800 772 0850">0800 772 0850</a>{" "}
                                                </strong> for advice on how to make a successful claim to get deserving compensation.

                                            </p>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="whyus">
                <div className="container">
                    <div className="row">
                        <div className="col-xxl-12 col-md-12">
                            <div className="why">
                                <h2 className="choose">
                                    Make your claim in 4 {" "}
                                    <span className="light"> simple steps  </span>
                                </h2>
                                <p className="complexities">
                                    It could be daunting at first to put in a <strong> People's Choice motor insurance claim </strong> when you have been involved in an accident which was not your fault. Therefore, we will help you do this. Dial <strong> People's Choice car insurance phone number </strong>  and begin your claims process.                                     </p>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-xxl-3 col-xl-6 col-lg-6 col-md-6 col-12">
                            <div className="box-service">
                                <img src={check} className="check" alt="" />
                                {/* <img src={experience} className="experience" alt="" /> */}
                                <h4>Get into touch</h4>
                                <p>
                                    Reach out to the team through the <strong> People's Choice car insurance contact number </strong> to discuss your claim. </p>
                            </div>
                        </div>
                        <div className="col-xxl-3 col-xl-6 col-lg-6 col-md-6 col-12">
                            <div className="box-service">
                                <img src={check} className="check" alt="" />
                                <h4>Provide details
                                </h4>
                                <p>
                                    Please provide all the details of an accident, including its date, time, where it occurred and how it happened. </p>
                            </div>
                        </div>
                        <div className="col-xxl-3 col-xl-6 col-lg-6 col-md-6 col-12">
                            <div className="box-service">
                                <img src={check} className="check" alt="" />
                                <h4>Submit evidence</h4>
                                <p>
                                    Provide enough evidence to uphold your assertion. Such evidence may include photographs of the accident, its place, eyewitness accounts, police records as well as CCTV videos.
                                </p>
                            </div>
                        </div>
                        <div className="col-xxl-3 col-xl-6 col-lg-6 col-md-6 col-12">
                            <div className="box-service">
                                <img src={check} className="check" alt="" />
                                <h4>Claim initiation </h4>
                                <p>
                                    After assessment, we will initiate your claim. Our team will keep updating on its progress.

                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="faqs">
                <div className="container">
                    <div className="row">
                        <div className="col-xxl-12 col-xl-12 col-md-12 col-12">
                            <div className="fq">
                                <h2>
                                    {" "}
                                    Frequently Asked{" "}
                                    <span className="light">Questions</span>
                                </h2>
                                <p className="complexities">
                                    Got questions about the <strong>People’s Choice car insurance claim? </strong> We have them answered here.
                                </p>
                            </div>
                            <div className="allfaq">
                                <Accordion defaultActiveKey="0">
                                    <Accordion.Item eventKey="0">
                                        <Accordion.Header>
                                            Am I supposed to pay extra fees for a replacement vehicle?                          </Accordion.Header>
                                        <Accordion.Body>
                                            Our company does not charge for the replacement of your car by a no-fault driver. Only in case you defy us, use a solicitor that is unknown to us or give fake details, you would be requested to pay.
                                        </Accordion.Body>
                                    </Accordion.Item>
                                    <Accordion.Item eventKey="1">
                                        <Accordion.Header>
                                            How long does it take to settle my claim?
                                        </Accordion.Header>
                                        <Accordion.Body>
                                            We will try our best to complete settling of your<strong>  People's Choice car insurance claim </strong> within the shortest time possible. However, the time it takes to finalise the claim depends on the nature of your case. We shall keep you updated after filing a claim with any progress made.                       </Accordion.Body>
                                    </Accordion.Item>
                                    <Accordion.Item eventKey="2">
                                        <Accordion.Header>
                                            Are vehicle recovery expenses insured under the at-fault party’s insurance?
                                        </Accordion.Header>
                                        <Accordion.Body>
                                            Yes, all costs involved in recovering a vehicle are borne by the at-fault party’s insurer.                      </Accordion.Body>
                                    </Accordion.Item>
                                    <Accordion.Item eventKey="3">
                                        <Accordion.Header>
                                            Can I get a credit hire following a no-fault car accident?
                                        </Accordion.Header>
                                        <Accordion.Body>
                                            Yes. In case you were innocent, you can acquire a credit hire vehicle. Dial the<strong> People's Choice insurance claims number </strong> to get an immediate replacement vehicle.                               </Accordion.Body>
                                    </Accordion.Item>
                                    <Accordion.Item eventKey="4">
                                        <Accordion.Header>
                                            If the accident isn’t my fault, will I lose a no-claims bonus?

                                        </Accordion.Header>
                                        <Accordion.Body>
                                            We will not claim on your own insurance policy if your claim comes through us, and you won’t risk losing even one year’s worth of accumulated bonuses or discounts.     </Accordion.Body>
                                    </Accordion.Item>
                                    <Accordion.Item eventKey="5">
                                        <Accordion.Header>
                                            Can I fix my car in my garage?{" "}
                                        </Accordion.Header>
                                        <Accordion.Body>
                                            Sure! We can arrange for your car to be repaired at the place of your choice. Just let us know about the repairer you have selected, and we’ll make sure that top-quality repair services are rendered within the shortest time possible.
                                        </Accordion.Body>
                                    </Accordion.Item>
                                    <Accordion.Item eventKey="6">
                                        <Accordion.Header>
                                            What kind of vehicle does mine get replaced with?{" "}
                                        </Accordion.Header>
                                        <Accordion.Body>
                                            We will provide a substitute car that is similar to yours in terms of size, value, and purpose. Call our <strong> People’s Choice insurance claim number </strong> and tell us what you need. Then, we’ll organise an appropriate replacement vehicle.
                                        </Accordion.Body>
                                    </Accordion.Item>
                                </Accordion>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <Footer />
        </>
    );
};

export default Peopleschoice;
