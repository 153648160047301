import React, { useRef } from "react";
import insurancelogo from "../../src/assets/images/insurancelogo.png";
import Accordion from "react-bootstrap/Accordion";
import check from "../assets/images/check.png";
import dualcar from "../../src/assets/images/dualcar.webp";
import churchill from "../../src/assets/images/churchill.webp";
import Sidebarinner from "../components/Sidebar-inner";
import phonecall from "../assets/images/phonecall.png";
import { Helmet } from "react-helmet";
import Footer from "../components/footer";

const Elephant = () => {
  const sidebarRef = useRef(null);

  const handleScrollToSidebar = () => {
    if (sidebarRef.current) {
      sidebarRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Elephant Car Insurance Accident Claims Phone Number</title>
        <meta
          name="description"
          content="Call our friendly and professional team on the Accident Claims Phone Number 0800 772 0850 to get the best advice for your Elephant Car Insurance Accident Claim. "
        />
        <link
          rel="canonical"
          href="https://insurance-accident-claims.co.uk/elephant "
        />
      </Helmet>

      <section className="innernav">
        <div className="container">
          <div className="row">
            <div className="col-md-6 col-sm-6">
              <div className="left-logo">
                {/* <img src={insurancelogo} className='insurancelogo' alt='Insurance Logo' /> */}
                <h3>Accident Claims</h3>
              </div>
            </div>
            <div className="col-md-6 col-sm-6">
              <div className="left-cta">
                <div class="headercnt">
                  <div class="chatmsg">
                    <img
                      src={phonecall}
                      className="phonecall"
                      alt="phonecall "
                    />
                  </div>
                  <div class="box-body">
                    <h3 class="nav-info-box-title">Toll Free Number </h3>
                    <p>
                      <a href="tel:0800 772 0850">0800 772 0850</a>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section
        className="newbnr"
        style={{ backgroundImage: "url(" + churchill + ")" }}
      >
        <div className="container">
          <div className="row">
            <div className="col-xxl-6 col-xl-6 col-md-10 col-12">
              <div className="innerbnr">
                <h1>
                  <span className="onespan">No-fault car accident </span>{" "}
                  <span className="sci">  insurance claims </span> in the UK{" "}
                </h1>
                <p className="brokerage">
                  From arranging vehicle recovery to organising repairs and
                  managing your claim from start to finish, we take away the
                  stress of dealing with the consequences of a no-fault car
                  accident.
                </p>
                <div className="bnrbtn">
                  <a
                    class="db-btn-border btn-rollover phone"
                    href="tel:0800 772 0850"
                  >
                    Begin your claim <i class="fa-solid fa-arrow-right"></i>
                  </a>
                </div>
                <div className="bnrbtn">
                  <a
                    className="db-btn-border btn-rollover desktop"
                    onClick={handleScrollToSidebar}
                  >
                    Begin your claim <i className="fa-solid fa-arrow-right"></i>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="stickysection">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="group-4-8">
                <div className="cl8 tf-tab">
                  <div className="content-tab">
                    <div className="inner-content">
                      <h2>
                        Comprehensive claims management
                        <br />
                        <span className="light">with no excess</span>{" "}
                      </h2>
                      <p className="disciplines">
                        Being involved in a no-fault car accident can be a
                        daunting and stressful experience. However, we’re here
                        to minimise the stress and uncertainty associated with
                        managing the{" "}
                        <strong>Elephant car insurance claim </strong>
                        on your own. From arranging repairs to providing a
                        replacement vehicle, we offer a broad spectrum of
                        accident management solutions without burdening you with
                        any excess payments.
                      </p>
                      <hr />
                      <p className="bold-text">Excess free services </p>
                      <p className="bottom-te">
                        When you make your <strong> Elephant auto claim</strong>{" "}
                        with us, there is no excess to pay. We will protect you
                        from excess payments as we will claim directly against
                        the other party’s insurer.
                      </p>
                      <hr />
                      <p className="bold-text">Approved repairs </p>
                      <p className="bottom-te">
                        We only use manufacturer-approved repairers who will
                        repair your vehicle using original and genuine parts and
                        paint.
                      </p>
                      <hr />
                      <p className="bold-text">Unparalleled expertise </p>
                      <p className="bottom-te">
                        We specialise in protecting the victims of a no-fault
                        car accident. Our solicitors work tirelessly to
                        represent your best interests and help you secure
                        maximum compensation for your{" "}
                        <strong> Elephant auto claim. </strong>
                      </p>
                      <hr />
                      <p className="bold-text">No-win No-fee </p>
                      <p className="bottom-te">
                        Our solicitors work on a no-win, no-fee basis. If your{" "}
                        <strong> Elephant car accident claim </strong>
                        is unsuccessful, you don’t have to bear any legal
                        expenses.
                      </p>
                      <hr />
                      <p className="bold-text">Comparable replacement </p>
                      <p className="bottom-te">
                        While your vehicle is getting repaired, we will deliver
                        you a like-for-like replacement vehicle, ensuring you
                        can quickly hit the road again.
                      </p>
                      <hr />
                      <p className="bold-text">No effect on no-claim bonus</p>
                      <p className="bottom-te">
                        Since we handle{" "}
                        <strong>Elephant car insurance claims </strong>directly,
                        your insurance premiums won’t increase, and you won’t
                        lose your no-claims bonus. Call us on the{" "}
                        <strong>Elephant car insurance claim number</strong> to
                        start your claim with us.
                      </p>
                      <hr />
                      <p className="bold-text">Experienced claims handler </p>
                      <p className="bottom-te">
                        When you get in touch with us via the{" "}
                        <strong>
                          Elephant auto insurance claims phone number{" "}
                        </strong>
                        to make your claim, you’ll have an experienced claims
                        handler dedicated to your case.
                      </p>
                      <hr />
                      <p className="bold-text">
                        Full-fledged claims management
                      </p>
                      <p className="bottom-te">
                        We will refer you to our panel of experienced solicitors
                        who will stand by your side throughout the{" "}
                        <strong>Elephant auto insurance claims</strong> process.
                      </p>
                      <hr />
                      <p className="bold-text">Tailored advice </p>
                      <p className="bottom-te">
                        We understand that every{" "}
                        <strong>Elephant auto claim </strong>is different.
                        Therefore, our solicitors provide customised advice
                        according to the circumstances of your case.
                      </p>
                      <hr />
                      <p className="bold-text">No obligation advice</p>
                      <p className="bottom-te">
                        Contact us on the{" "}
                        <strong>
                          Elephant auto insurance claims phone number{" "}
                        </strong>{" "}
                        to get free no-obligation advice from our solicitors and
                        know your rights and options to pursue a claim.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="cl4" ref={sidebarRef}>
                  <Sidebarinner />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="photographs">
        <div className="container">
          <div className="row">
            <h2 className="road">
              {" "}
              We reduce your burden of dealing with the <br />
              <span className="light">aftermath of the car accident </span>{" "}
            </h2>
            <div className="col-xxl-6 col-xl-6 col-md-12 col-12">
              <div className="fourbox">
                <ul>
                  <li>
                    <img src={check} className="check" alt="" />
                    When you call us on the car insurance claim number{" "}
                    <strong>
                      {" "}
                      <a href="tel:0800 772 085">0800 772 085</a>
                    </strong>{" "}
                    , we will collect the details of the accident and provide
                    timely assistance to help you get back on the road.
                  </li>
                  <hr />
                  <li>
                    <img src={check} className="check" alt="" />
                    If your vehicle is undrivable, we will provide you with a
                    like-for-like replacement vehicle so that you can carry on
                    your daily chores without hassle.
                  </li>
                  <hr />
                  <li>
                    <img src={check} className="check" alt="" />
                    We will arrange an independent engineer for the damage
                    assessment of your vehicle.
                  </li>
                  <hr />
                  <li>
                    <img src={check} className="check" alt="" />
                    We will contact your chosen repair centre to schedule your
                    vehicle repairs and restore your vehicle to its pre-accident
                    condition.
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-xxl-6 col-xl-6 col-md-12 col-12">
              <div className="fourbox">
                <ul>
                  <li>
                    <img src={check} className="check" alt="" />
                    We will liaise with all the parties on your behalf and
                    negotiate with the at-fault party’s insurer for a fair
                    settlement of the
                    <strong> Elephant car accident claim.</strong>{" "}
                  </li>
                  <hr />
                  <li>
                    <img src={check} className="check" alt="" />
                    We will coordinate with the repairer and keep you informed
                    of the repairs until completion.
                  </li>
                  <hr />
                  <li>
                    <img src={check} className="check" alt="" />
                    We will make arrangements for the delivery of the repaired
                    vehicle and collection of the replacement vehicle.
                  </li>
                  <hr />
                  <li>
                    <img src={check} className="check" alt="" />
                    We will assign a specialist claim manager to deal with the
                    complexities of your{" "}
                    <strong>Elephant car insurance claim</strong> and ensure a
                    speedy settlement.
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="carprocess">
        <div className="container ">
          <div className="row">
            <div className="col-xxl-12 col-xxl-12 col-md-12 col-12">
              <h2 className="trusted-title">
                Accident management services <br />
                <span className="light">we offer </span>{" "}
              </h2>
            </div>
          </div>
          <div className="row" id="possible">
            <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-12">
              <div className="claim">
                <ul>
                  <li>
                    <span className="sleej">Vehicle recovery </span> <br />
                    Call us on the{" "}
                    <strong> Elephant car insurance claim number </strong>
                    to get your damaged car recovered after the car accident.
                    Our accident recovery team will immediately arrive at the
                    accident location and safely recover your vehicle, moving it
                    to a safe place.
                  </li>
                  <li>
                    <span className="sleej">Accidental repairs</span>
                    <br />
                    Our vehicle repair services focus on getting your vehicle
                    repaired in minimal time. We will ensure high-quality and
                    guaranteed repairs from manufacturer-approved repairers to
                    get you back on the road as soon as possible.
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-12">
              <div className="claim" id="blue">
                <ul>
                  <li>
                    <span className="sleej">Safe storage </span> <br />
                    Want to keep your vehicle in a safe place following a car
                    accident? We provide storage facilities to store your
                    damaged vehicle. If your vehicle is not driveable or you
                    feel it’s not safe to leave your vehicle on the roadside,
                    contact our team via the{" "}
                    <strong>
                      {" "}
                      insurance claims phone number{" "}
                      <a href="tel:0800 772 0850">0800 772 0850</a>
                    </strong>{" "}
                    . We will recover and store your car in our storage yards at
                    no cost to you.
                  </li>
                  <li>
                    <span className="sleej">Replacement vehicle </span> <br />
                    UK drivers involved in a no-fault car accident are entitled
                    to a temporary replacement vehicle to get back on the road.
                    We will provide you with a like-for-like replacement vehicle
                    of the same size, specification and standard as that of your
                    vehicle.
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-12">
              <div className="claim">
                <ul>
                  <li>
                    <span className="sleej">Personal injury claim </span>
                    <br />
                    Have you sustained any injuries due to the car accident? Get
                    in touch with us via the{" "}
                    <strong>
                      Elephant auto insurance claims phone number{" "}
                    </strong>
                    , to make a claim for personal injuries. Our solicitors will
                    guide you through the process to ensure the best outcome for
                    the<strong> Elephant car insurance claim. </strong>
                  </li>
                  <li>
                    <span className="sleej">Claim management </span> <br />
                    From the moment you report the car accident to the
                    initiation and final resolution of your claim, we will
                    manage every aspect of{" "}
                    <strong> Elephant auto insurance claim.</strong>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="yellowcta">
        <div className="container">
          <div className="row">
            <div className="cta2">
              <div className="col-xxl-8 col-xl-8 col-lg-8 col-12">
                <div className="legal">
                  <h3 className="experienced">
                    Got more questions?{" "}
                    <span className="light"> Talk to our specialists </span>{" "}
                  </h3>
                  <p className="professionals">
                    Get in touch with our no-fault claim experts via{" "}
                    <strong>
                      car insurance claims number{" "}
                      <a className="cpt-no" href="tel:0800 772 0850">
                        0800 772 0850
                      </a>
                    </strong>{" "}
                    to get the best advice for your
                    <strong> Elephant auto claim.</strong>
                  </p>
                </div>
              </div>
              <div className="col-xxl-4 col-xl-4 col-lg-4 col-12">
                <div class="process">
                  <a class="tf-button style-1 phone" href="tel:0800 772 0850">
                    Get in touch<span class="icon-keyboard_arrow_right"></span>
                  </a>
                </div>
                <div class="process">
                  <a
                    className="tf-button style-1 desktop"
                    onClick={handleScrollToSidebar}
                  >
                    Get in touch
                    <span className="icon-keyboard_arrow_right"></span>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <search className="updatedform">
        <div className="container">
          <div className="row text-center">
            <div className="col-xxl-12 col-xl-12 col-md-12 col-12">
              <h2 className="whiteheading">
                {" "}
                Why are we your one-stop shop for the best <br />
                <span className="light">accident management solutions?</span>
              </h2>
              <p className="sustained">
                We are no-fault claim experts offering unwavering support to
                minimise the stress and anxiety involved in managing{" "}
                <strong>Elephant auto insurance claims. </strong>
                From handling the complexities of the claim to offering
                comprehensive assistance and guidance, we’re committed to
                providing all-around support at every step of the way.
              </p>
            </div>
          </div>
          <div className="row">
            <div className="col-xxl-4 col-xl-4 col-lg-6 col-md-12 col-12">
              <div className="Claimant">
                <h5>Guaranteed repairs</h5>
                <p className="collision">
                  We will kickstart your repair process as soon as possible from
                  manufacturer-approved repairers, specialising in providing
                  guaranteed and quality repairs.
                </p>
                <h5>Expert claim handling </h5>
                <p className="collision">
                  Our solicitors will assess{" "}
                  <strong> Elephant auto insurance claims </strong>
                  and coordinate with several parties involved in the accident
                  to ensure fast resolution.
                </p>
                <h5>Prompt roadside assistance </h5>
                <p className="collision">
                  We will dispatch our support team immediately when you call us
                  on the
                  <strong> Elephant car insurance claim number </strong>
                  to ensure you and your vehicle are moved to safety.
                </p>
              </div>
            </div>
            <div className="col-xxl-4 col-xl-4 col-lg-6 col-md-12 col-12">
              <div className="Claimant">
                <h5>Free legal advice </h5>
                <p className="collision">
                  We will help you get free legal advice from experienced
                  solicitors specialising in no-fault car accident claims to
                  help you understand your rights and options to make an{" "}
                  <strong>Elephant car accident claim.</strong>
                </p>
                <h5>In-depth expertise </h5>
                <p className="collision">
                  Irrespective of how complex your claim is, our solicitors have
                  extensive experience to advise and guide you to secure the
                  best possible outcome for your{" "}
                  <strong> Elephant auto claim.</strong>{" "}
                </p>
                <h5>Same-day replacement</h5>
                <p className="collision">
                  We will provide a suitable replacement vehicle matching your
                  needs and preferences on the same day to save you from
                  inconvenience.
                </p>
              </div>
            </div>
            <div className="col-xxl-4 col-xl-4 col-lg-12 col-md-12 col-12">
              <div className="Claimant">
                <h5>Comprehensive services </h5>
                <p className="collision">
                  From vehicle recovery to organising repairs and managing your{" "}
                  <strong>Elephant car insurance claim</strong> from start to
                  finish, we have got you covered.
                </p>
                <h5>No upfront costs </h5>
                <p className="collision">
                  All the costs related to repairs, replacement vehicle or any
                  other expenses you’ve incurred will be recovered from the
                  at-fault party’s insurer. So, you don’t have to pay any
                  upfront charges.
                </p>
                <h5>24/7 support </h5>
                <p className="collision">
                  Our team is available round the clock to provide emergency
                  roadside assistance. Call us on the
                  <strong>
                    {" "}
                    car insurance claim number{" "}
                    <a href="tel:0800 772 0850">0800 772 0850</a>{" "}
                  </strong>
                  to get dedicated support at the time of need.
                </p>
              </div>
            </div>
          </div>
        </div>
      </search>

      <section className="Accident-claim">
        <div className="container">
          <div className="row">
            <div className="needed">
              <h2 className="trusted-title">
                How can you win maximum compensation for your <br />
                <span className="light">Elephant auto insurance claim?</span>
              </h2>
              <p className="complexities">
                If you’ve been the victim of a no-fault car accident, you’ll be
                entitled to receive compensation for the injuries and damages
                from the other party. However, it’s up to you to establish the
                level of compensation you’re entitled to for the{" "}
                <strong>Elephant auto claim</strong>. Here are the steps you
                should take after a no-fault car accident to maximise your
                compensation.
              </p>
            </div>
            <hr />
          </div>
          <div className="row" id="incdes">
            <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-12">
              <div className="Preparing">
                <ul>
                  <li>
                    <div className="seemingly">
                      <h5 className="Seek">Get medical help </h5>
                      <p>
                        {" "}
                        Visit the doctor as soon as possible to get your
                        injuries treated. Your medical report will be a piece of
                        powerful evidence to strengthen the{" "}
                        <strong>Elephant car accident claim.</strong>
                      </p>
                    </div>
                  </li>
                  <li>
                    <div className="seemingly">
                      <h5 className="Seek">Report the accident </h5>
                      <p>
                        Immediately call the police to report the car accident.
                        The police report may be used as strong evidence to
                        support your claim.
                      </p>
                    </div>
                  </li>
                  <li>
                    <div className="seemingly">
                      <h5 className="Seek">Collect evidence</h5>
                      <p>
                        Start gathering evidence, such as photos of the accident
                        scene, vehicles involved, injuries and CCTV videos to
                        make your{" "}
                        <strong> Elephant car insurance claim </strong>
                        stronger.
                      </p>
                    </div>
                  </li>
                  <li>
                    <div className="seemingly">
                      <h5 className="Seek">Record financial losses</h5>
                      <p>
                        Keep a record of all the losses related to the accident,
                        including medical bills, travel expenses, legal costs,
                        loss of income, benefits or any other compensation.
                      </p>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
            <div
              className="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-12"
              id="dualcardiv"
            >
              <img src={dualcar} alt="" className="dualcar" />
            </div>
            <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-12">
              <div className="Preparing">
                <ul>
                  <li>
                    <div className="seemingly">
                      <h5 className="Seek">Stay away from social media </h5>
                      <p>
                        Refrain from using social media until your{" "}
                        <strong> Elephant auto insurance claim </strong>
                        is resolved. The other party’s solicitor may twist your
                        statements and posts to reduce the compensation.
                      </p>
                    </div>
                  </li>
                  <li>
                    <div className="seemingly">
                      <h5 className="Seek">Contact eyewitnesses </h5>
                      <p>
                        Ask the eyewitnesses of the car accident for their
                        contact information and record their statements.
                      </p>
                    </div>
                  </li>
                  <li>
                    <div className="seemingly">
                      <h5 className="Seek">Hire a no-fault claim solicitor </h5>
                      <p>
                        Get in touch with us via the{" "}
                        <strong>
                          {" "}
                          Elephant auto insurance claims phone number{" "}
                        </strong>
                        to talk to an experienced solicitor who will investigate
                        your accident and help you get maximum compensation.
                      </p>
                    </div>
                  </li>
                  <li>
                    <div className="seemingly">
                      <h5 className="Seek">Start your claim</h5>
                      <p>
                        After you’ve gathered all the evidence and documents to
                        support the <strong>Elephant auto claim</strong> , call
                        us on the{" "}
                        <strong>
                          Elephant auto insurance claims phone number{" "}
                        </strong>{" "}
                        to initiate your claim.{" "}
                      </p>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="whyus">
        <div className="container">
          <div className="row">
            <div className="col-xxl-12 col-md-12">
              <div className="why">
                <h2 className="choose">
                  Make your elephant auto insurance{" "}
                  <span className="light">
                    <br /> claim in just 4 steps{" "}
                  </span>
                </h2>
                <p className="complexities">
                  With our simple and hassle-free claims process, you can make
                  the <strong> Elephant auto insurance claims </strong>without
                  any complexities and paperwork headaches. Reach out to us via
                  the <strong> Elephant car insurance claim number </strong>
                  to kickstart your claim process.
                </p>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-xxl-3 col-xl-6 col-lg-6 col-md-6 col-12">
              <div className="box-service">
                <img src={check} className="check" alt="" />
                <h4>Get in touch </h4>
                <p>
                  Contact our team on the{" "}
                  <strong>Elephant auto insurance claims phone number</strong>{" "}
                  to schedule a free initial consultation to discuss your claim.
                </p>
              </div>
            </div>
            <div className="col-xxl-3 col-xl-6 col-lg-6 col-md-6 col-12">
              <div className="box-service">
                <img src={check} className="check" alt="" />
                <h4>Provide details </h4>
                <p>
                  Share comprehensive details about the accident, including
                  date, time, parties involved and any injuries sustained.
                </p>
              </div>
            </div>
            <div className="col-xxl-3 col-xl-6 col-lg-6 col-md-6 col-12">
              <div className="box-service">
                <img src={check} className="check" alt="" />
                <h4>Documentation preparation </h4>
                <p>
                  Submit the evidence you’ve gathered from the accident scene
                  and the necessary documents to initiate your claim.
                </p>
              </div>
            </div>
            <div className="col-xxl-3 col-xl-6 col-lg-6 col-md-6 col-12">
              <div className="box-service">
                <img src={check} className="check" alt="" />
                <h4>Claim initiation</h4>
                <p>
                  We will file your{" "}
                  <strong>Elephant auto insurance claim</strong> and keep you
                  updated about its progress.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="faqs">
        <div className="container">
          <div className="row">
            <div className="col-xxl-12 col-xl-12 col-md-12 col-12">
              <div className="fq">
                <h2>
                  {" "}
                  Frequently Asked{" "}
                  <span className="light">Questions (FAQ)</span>
                </h2>
                <p className="complexities">
                  Got questions about the Elephant car insurance claim? We have
                  them answered here.{" "}
                </p>
              </div>
              <div className="allfaq">
                <Accordion defaultActiveKey="0">
                  <Accordion.Item eventKey="0">
                    <Accordion.Header>
                      How quickly can my Elephant car accident claim be
                      processed?
                    </Accordion.Header>
                    <Accordion.Body>
                      Every <strong>Elephant car insurance claim </strong>is
                      unique. Therefore, the time required to process the claim
                      may be different for every case. Complex claims may be
                      time-consuming, but simple claims can get settled in a few
                      days. Our dedicated team will keep you informed about the
                      progress of your{" "}
                      <strong>Elephant car accident claim </strong>so that
                      you’re aware of your claim status.
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="1">
                    <Accordion.Header>
                      What kind of parts will be used to repair my damaged
                      vehicle?
                    </Accordion.Header>
                    <Accordion.Body>
                      We have a trusted network of approved repairers in the UK
                      who will repair your vehicle using genuine parts and
                      paint. So, you can rest assured that your vehicle will be
                      repaired using original manufacturer-approved parts.
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="2">
                    <Accordion.Header>
                      If my vehicle is a total loss, how long can I use the
                      replacement vehicle?
                    </Accordion.Header>
                    <Accordion.Body>
                      In case your vehicle is written off, we will provide you
                      with a replacement vehicle that you can keep until you
                      receive compensation for the full value of your vehicle.
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="3">
                    <Accordion.Header>
                      Can I get my vehicle repaired from my chosen repair
                      centre?
                    </Accordion.Header>
                    <Accordion.Body>
                      Yes. You can share the details of your repairer, and we’ll
                      get in touch with them to schedule your vehicle repairs.
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="4">
                    <Accordion.Header>
                      Is it crucial to report the accident to the police?
                    </Accordion.Header>
                    <Accordion.Body>
                      Yes. Following a no-fault car accident, you must
                      immediately call the police and report the car accident.
                      The police report may contain crucial information
                      regarding the accident that might make your claim
                      stronger.
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="5">
                    <Accordion.Header>
                      Who will pay for my Elephant car accident claim?
                    </Accordion.Header>
                    <Accordion.Body>
                      Every dime you spend as a result of the no-fault car
                      accident will be recovered from the at-fault party’s
                      insurance company. It’s their legal obligation to pay all
                      the expenses incurred by you due to the accident.
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="6">
                    <Accordion.Header>
                      Will I lose my no-claims bonus?
                    </Accordion.Header>
                    <Accordion.Body>
                      If the accident is not your fault and your insurance
                      company does not cover any expenses, your no-claims bonus
                      will stay protected. This means you won’t lose your
                      hard-earned discounts or face any penalty on your
                      insurance premium.
                    </Accordion.Body>
                  </Accordion.Item>
                </Accordion>
              </div>
            </div>
          </div>
        </div>
      </section>

      <Footer />
    </>
  );
};

export default Elephant;
