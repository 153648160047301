import React, { useRef } from "react";
import insurancelogo from "../../src/assets/images/insurancelogo.png";
import Accordion from "react-bootstrap/Accordion";
import check from "../assets/images/check.png";
import dualcar from "../../src/assets/images/dualcar.webp";
import churchill from "../../src/assets/images/churchill.webp";
import Sidebarinner from "../components/Sidebar-inner";
import phonecall from "../assets/images/phonecall.png";
import { Helmet } from "react-helmet";
import Footer from "../components/footer";

const Quotemehappy = () => {
    const sidebarRef = useRef(null);

    const handleScrollToSidebar = () => {
      if (sidebarRef.current) {
        sidebarRef.current.scrollIntoView({ behavior: "smooth" });
      }
    };
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Quote Me Happy Car Insurance Accident Claim Phone Number</title>
        <meta
          name="description"
          content=" Need expert guidance to handle your claim efficiently? Call us on the Quote Me Happy Car Insurance Accident Claim Phone Number 0800 772 0850 to talk to our solicitors. "
        />
        <link
          rel="canonical"
          href="https://insurance-accident-claims.co.uk/Quote-me-happy "
        />
      </Helmet>
      <section className="innernav">
        <div className="container">
          <div className="row">
            <div className="col-md-6 col-sm-6">
              <div className="left-logo">
                {/* <img src={insurancelogo} className='insurancelogo' alt='Insurance Logo' /> */}
                <h3>Accident Claims</h3>
              </div>
            </div>
            <div className="col-md-6 col-sm-6">
              <div className="left-cta">
                <div class="headercnt">
                  <div class="chatmsg">
                    <img
                      src={phonecall}
                      className="phonecall"
                      alt="phonecall "
                    />
                  </div>
                  <div class="box-body">
                    <h3 class="nav-info-box-title">Toll Free Number </h3>
                    <p>
                      <a href="tel:0800 772 0850">0800 772 0850</a>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section
        className="newbnr"
        style={{ backgroundImage: "url(" + churchill + ")" }}
      >
        <div className="container">
          <div className="row">
            <div className="col-xxl-8 col-xl-8 col-md-10 col-12">
              <div className="innerbnr">
                <h1>
                  <span className="onespan"> No-fault accident</span>{" "}
                  <span className="sci"> claims </span> with zero excess{" "}
                </h1>
                <p className="brokerage">
                  From arranging your vehicle recovery to managing your claim
                  from start to finish, we’re your trusted partners, helping you
                  every step of the way.
                </p>
                <div className="bnrbtn">
                  <a class="db-btn-border btn-rollover phone" href="tel:0800 772 0850">
                    Begin your claim{" "}
                    <i class="fa-solid fa-arrow-right"></i>
                  </a>
                </div>
                <div className="bnrbtn">
                <a className="db-btn-border btn-rollover desktop"  onClick={handleScrollToSidebar}>
                    Begin your claim <i className="fa-solid fa-arrow-right"></i>
                </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="stickysection">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="group-4-8">
                <div className="cl8 tf-tab">
                  <div className="content-tab">
                    <div className="inner-content">
                      <h2>
                        {" "}
                        No-fault accident support <br />
                        <span className="light"> with zero excess </span>{" "}
                      </h2>
                      <p className="disciplines">
                        We're a team of reliable professionals committed to
                        helping the victims of no-fault car accidents in the UK
                        to deal with the consequences of the accident smoothly.
                        From recovering your vehicle from the roadside to
                        returning it to the road, we provide end-to-end
                        assistance without any excess. Contact us on 0800 772
                        0850 to get started.
                      </p>
                      <hr />
                      <p className="bold-text">No insurance excess</p>
                      <p className="bottom-te">
                        We won’t initiate a claim against your own insurance
                        policy, ensuring you don’t have to make any excess
                        payments towards your insurance premium.
                      </p>
                      <hr />
                      <p className="bold-text">No impact on no-claims bonus </p>
                      <p className="bottom-te">
                        If the accident is the other party’s fault, we will
                        ensure your hard-earned discounts and premiums
                        accumulated over the years remain intact.
                      </p>
                      <hr />
                      <p className="bold-text">
                        Comparable vehicle replacement{" "}
                      </p>
                      <p className="bottom-te">
                        If you’re left without a vehicle post the car accident,
                        we will provide you with a like-for-like replacement
                        vehicle of similar make, model, features and
                        specifications so that you can continue doing your daily
                        tasks without interruption.
                      </p>
                      <hr />
                      <p className="bold-text">Quality repairs </p>
                      <p className="bottom-te">
                        Our vast network of manufacturer-approved repairers in
                        the UK specialises in repairing vehicles to the highest
                        standard of quality and excellence.
                      </p>
                      <hr />
                      <p className="bold-text">Prompt claims processing </p>
                      <p className="bottom-te">
                        Our dedicated team and experienced solicitors work
                        tirelessly to fasten every aspect of your
                        <strong> Privilege car insurance claim. </strong>
                      </p>
                      <hr />
                      <p className="bold-text">No obligation advice </p>
                      <p className="bottom-te">
                        Get in touch with our team via the{" "}
                        <strong>
                          insurance claims number{" "}
                          <a href="tel:0800 772 0850">0800 772 0850</a>{" "}
                        </strong>
                        to receive a free initial consultation to discuss your
                        rights and options for making a claim.
                      </p>
                      <hr />
                      <p className="bold-text">Reliable roadside assistance </p>
                      <p className="bottom-te">
                        We understand that road accidents can happen anytime.
                        Therefore, our team is available round the clock to
                        provide immediate roadside assistance during stressful
                        times.
                      </p>
                      <hr />
                      <p className="bold-text">Experienced solicitors</p>
                      <p className="bottom-te">
                        With years of experience in handling no-fault accident
                        claims, our solicitors have the knowledge and expertise
                        to manage your claim seamlessly from initiation to
                        settlement.
                      </p>
                      <hr />
                      <p className="bold-text">Quick & efficient services </p>
                      <p className="bottom-te">
                        We know the significance of timely assistance after a
                        car accident. When you call us on the{" "}
                        <strong>Privilege car insurance claim number</strong>,
                        you can rest assured our team will provide instant
                        support and assistance to minimise your hassles.
                      </p>
                      <hr />

                      <p className="bold-text">Nationwide coverage </p>
                      <p className="bottom-te">
                        With our nationwide coverage across the UK, we can
                        ensure a quick response to your accident management
                        needs to get you back on the road without any delay.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="cl4" ref={sidebarRef}>
                  <Sidebarinner />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="photographs">
        <div className="container">
          <div className="row">
            <h2 className="road">
              {" "}
              We ensure your safety and peace <br />
              <span className="light">
                of mind after the car accident{" "}
              </span>{" "}
            </h2>
            <div className="col-xxl-6 col-xl-6 col-md-12 col-12">
              <div className="fourbox">
                <ul>
                  <li>
                    <img src={check} className="check" alt="" />
                    When you call us on the{" "}
                    <strong>
                      Privilege car insurance claim phone number,
                    </strong>{" "}
                    we’ll offer quick and efficient solutions to minimise stress
                    and make your post-accident journey smoother.
                  </li>
                  <hr />
                  <li>
                    <img src={check} className="check" alt="" />
                    If your vehicle is damaged, we will arrange its repairs
                    through a manufacturer-approved repairer.
                  </li>
                  <hr />
                  <li>
                    <img src={check} className="check" alt="" />
                    Contact us on the{" "}
                    <strong>Privilege car accident claim phone number</strong>,
                    and we’ll provide you with a replacement car to keep you on
                    the move while your car is being repaired.
                  </li>
                  <hr />
                  <li>
                    <img src={check} className="check" alt="" />
                    Our experienced accident management team will handle the{" "}
                    <strong>Privilege car insurance claim </strong>from
                    initiation to completion, helping you get deserving
                    compensation for the losses.
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-xxl-6 col-xl-6 col-md-12 col-12">
              <div className="fourbox">
                <ul>
                  <li>
                    <img src={check} className="check" alt="" />
                    We will ensure you get the best advice and legal
                    representation to help you receive compensation for
                    injuries, losses, and damages suffered due to the accident.
                  </li>
                  <hr />
                  <li>
                    <img src={check} className="check" alt="" />
                    Our advisors are on hand 24/7 to guide you through the
                    end-to-end process of managing your claim.
                  </li>
                  <hr />
                  <li>
                    <img src={check} className="check" alt="" />
                    Our solicitors will negotiate with the other party’s insurer
                    to secure maximum compensation for you.
                  </li>
                  <hr />
                  <li>
                    <img src={check} className="check" alt="" />
                    Once your vehicle repairs are completed, we’ll make
                    arrangements for its delivery.
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="carprocess">
        <div className="container ">
          <div className="row">
            <div className="col-xxl-12 col-xxl-12 col-md-12 col-12">
              <h2 className="trusted-title">
                {" "}
                Our <span className="light"> services </span>{" "}
              </h2>
            </div>
          </div>
          <div className="row" id="possible">
            <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-12">
              <div className="claim">
                <ul>
                  <li>
                    <span className="sleej">Vehicle recovery </span> <br />
                    Need expert and quick car recovery services in the UK? Get
                    in touch with us via the{" "}
                    <strong>
                      car accident claim phone number{" "}
                      <a href="tel:0800 772 0850">0800 772 0850</a>
                    </strong>
                    , and we’ll immediately dispatch our car rescue experts to
                    recover your vehicle from the accident location.
                  </li>
                  <li>
                    <span className="sleej">Approved repairs </span>
                    <br />
                    In case your vehicle is damaged, contact our team on the{" "}
                    <strong>Privilege claims number</strong>. We’ll arrange its
                    repairs from a manufacturer-approved repair centre in the
                    UK, specialising in carrying out quick and efficient vehicle
                    repairs.
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-12">
              <div className="claim" id="blue">
                <ul>
                  <li>
                    <span className="sleej">Safe storage </span> <br />
                    Storing your vehicle following a car accident is essential
                    to protect it from further harm and minimise the chances of
                    theft. Our storage facilities offer a safe place to store
                    your vehicle, protecting it from damage, theft and
                    vandalism. Contact us today on the{" "}
                    <strong>Privilege car insurance claim number </strong>to
                    ensure the safety of your vehicle with our dedicated vehicle
                    storage services.
                  </li>
                  <li>
                    <span className="sleej">Replacement car </span> <br />
                    If you’ve been involved in an accident that was not your
                    fault, we’ll provide you with a like-for-like replacement
                    car. You can keep the temporary replacement vehicle while
                    yours is undergoing repairs. If your vehicle is a total
                    loss, you can keep the replacement vehicle until you receive
                    deserving compensation amounting to the market value of your
                    vehicle.
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-12">
              <div className="claim">
                <ul>
                  <li>
                    <span className="sleej">Personal injury support </span>
                    <br />
                    Looking for legal advice to claim compensation for the
                    injuries sustained in the accident? Call us on the{" "}
                    <strong>Privilege insurance claims number</strong>. Our
                    no-fault claim solicitors will provide the best legal advice
                    and representation to get you the compensation you deserve.
                  </li>
                  <li>
                    <span className="sleej">Claim management </span> <br />
                    From initial claim filing to final settlement, our dedicated
                    solicitors will stand by your side throughout the{" "}
                    <strong>Privilege car insurance claims process</strong>.
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="yellowcta">
        <div className="container">
          <div className="row">
            <div className="cta2">
              <div className="col-xxl-8 col-xl-8 col-lg-8 col-12">
                <div className="legal">
                  <h3 className="experienced">
                    Got questions?
                    <span className="light">Talk to our specialists </span>{" "}
                  </h3>
                  <p className="professionals">
                    Get in touch with our no-fault claim experts via{" "}
                    <strong>
                      car insurance claims number{" "}
                      <a href="tel:0800 772 0850">0800 772 0850</a>{" "}
                    </strong>
                    to get the best advice for your Privilege car insurance
                    claim.
                  </p>
                </div>
              </div>
              <div className="col-xxl-4 col-xl-4 col-lg-4 col-12">
              <div class="process">
                  <a class="tf-button style-1 phone" href="tel:0800 772 0850">
                    Get in touch<span class="icon-keyboard_arrow_right"></span>
                  </a>
                </div>
                <div class="process">
                    <a className="tf-button style-1 desktop"  onClick={handleScrollToSidebar}>
                        Get in touch<span className="icon-keyboard_arrow_right"></span>
                    </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <search className="updatedform">
        <div className="container">
          <div className="row text-center">
            <div className="col-xxl-12 col-xl-12 col-md-12 col-12">
              <h2 className="whiteheading">
                {" "}
                Why choose us for your{" "}
                <span className="light">accident management needs?</span>
              </h2>
              <p className="sustained">
                Our accident management services offer the best solution to
                minimise the stress of dealing with a car accident that was
                someone else’s fault. It provides you peace of mind that a
                dedicated team and experienced solicitors are working on your
                behalf to secure maximum compensation for you.
              </p>
            </div>
          </div>
          <div className="row">
            <div className="col-xxl-4 col-xl-4 col-lg-6 col-md-12 col-12">
              <div className="Claimant">
                <h5>No cost to you </h5>
                <p className="collision">
                  Our accident management services won’t cost you anything. All
                  the costs of our services will be recovered from the other
                  party’s insurer.
                </p>

                <h5>Manufacturer-approved repair network </h5>
                <p className="collision">
                  We have a broad network of manufacturer-approved repair
                  centres with expertise in providing high-quality and
                  guaranteed repairs using genuine parts and paint.
                </p>

                <h5>Same-day replacement vehicle </h5>
                <p className="collision">
                  If the accident is not your fault, we will get you back in the
                  driving seat within 24 hours by providing a like-for-like
                  replacement vehicle.
                </p>
              </div>
            </div>
            <div className="col-xxl-4 col-xl-4 col-lg-6 col-md-12 col-12">
              <div className="Claimant">
                <h5>Reliable and trusted network </h5>
                <p className="collision">
                  We work with a trusted network of reliable repairers, an
                  experienced panel of solicitors, recovery agents and
                  replacement car vendors to provide you with top-notch services
                  that exceed expectations.
                </p>

                <h5>Convenient storage </h5>
                <p className="collision">
                  Our accident vehicle storage services provide a safe and
                  convenient place to store your vehicle, protecting it from
                  further damage and theft.
                </p>

                <h5>Emergency roadside assistance </h5>
                <p className="collision">
                  Call us on our Privilege car insurance claim number for
                  emergency assistance following a car accident. Our prompt
                  response ensures you get quick, safe and dependable roadside
                  assistance.
                </p>
              </div>
            </div>
            <div className="col-xxl-4 col-xl-4 col-lg-12 col-md-12 col-12">
              <div className="Claimant">
                <h5>24/7 availability </h5>
                <p className="collision">
                  Our round-the-clock presence guarantees swift assistance
                  whenever you need it the most.
                </p>

                <h5>Dedicated claims handler </h5>
                <p className="collision">
                  With us, you’ll receive a dedicated claims handler who will
                  provide personalised support and regular updates regarding the
                  Privilege car insurance claim.
                </p>

                <h5>Consistent communication </h5>
                <p className="collision">
                  We will consistently monitor your claim and keep you informed
                  at every step so that you’re never left wondering about the
                  progress of your claim.
                </p>
              </div>
            </div>
          </div>
        </div>
      </search>

      <section className="Accident-claim">
        <div className="container">
          <div className="row">
            <div className="needed">
              <h2 className="trusted-title">
                {" "}
                How can you boost <br />
                <span className="light"> your claim value?</span>
              </h2>
              <p className="complexities">
                If you’ve been a victim of a no-fault car accident, you’re
                entitled to compensation. However, mishandling your case may
                lower your compensation and claim value. Here’s how you can
                maximise the compensation for your no-fault accident claim.
              </p>
            </div>
            <hr />
          </div>
          <div className="row" id="incdes">
            <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-12">
              <div className="Preparing">
                <ul>
                  <li>
                    <div className="seemingly">
                      <h5 className="Seek">Seek medical attention </h5>
                      <p>
                        If you’ve sustained injuries due to a car accident,
                        immediately visit a qualified medical practitioner to
                        get your injuries treated.
                      </p>
                    </div>
                  </li>
                  <li>
                    <div className="seemingly">
                      <h5 className="Seek">Report the accident </h5>
                      <p>
                        Call the police and report the car accident within 24
                        hours, and request a copy of the police report that may
                        contain crucial information to support your claim.
                      </p>
                    </div>
                  </li>
                  <li>
                    <div className="seemingly">
                      <h5 className="Seek">Collect evidence </h5>
                      <p>
                        The more evidence you collect from the accident scene,
                        the higher the chances of securing maximum compensation.
                        This evidence may include your photographs of the
                        accident scene and vehicle involved, eyewitness
                        statements, and CCTV videos.
                      </p>
                    </div>
                  </li>
                  <li>
                    <div className="seemingly">
                      <h5 className="Seek">Exchange details </h5>
                      <p>
                        To ensure smooth and streamlined processing of the
                        <strong> Privilege car insurance claim</strong>,
                        exchange crucial details with the other party, including
                        personal information, vehicle registration numbers and
                        insurance policy details.
                      </p>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
            <div
              className="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-12"
              id="dualcardiv"
            >
              <img src={dualcar} alt="" className="dualcar" />
            </div>
            <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-12">
              <div className="Preparing">
                <ul>
                  <li>
                    <div className="seemingly">
                      <h5 className="Seek">
                        Don’t accept the initial settlement offer{" "}
                      </h5>
                      <p>
                        The initial settlement offer provided by the insurance
                        companies may not compensate you fairly for the losses
                        and damages. So, before accepting any such offer,
                        contact a no-fault claim expert to determine the right
                        amount of compensation.
                      </p>
                    </div>
                  </li>
                  <li>
                    <div className="seemingly">
                      <h5 className="Seek">Contact eyewitness </h5>
                      <p>
                        Get in touch with the eyewitnesses of the car accident
                        and collect their details so that you can contact them
                        later.
                      </p>
                    </div>
                  </li>
                  <li>
                    <div className="seemingly">
                      <h5 className="Seek">
                        Talk to a no-fault claim specialist
                      </h5>
                      <p>
                        Seek advice from an experienced no-fault claim expert to
                        understand your rights and options. Call us on the{" "}
                        <strong>
                          Privilege car insurance claim phone number
                        </strong>
                        , and we’ll connect you with an experienced claim
                        specialist who will provide the best support and
                        guidance throughout your claim process.
                      </p>
                    </div>
                  </li>
                  <li>
                    <div className="seemingly">
                      <h5 className="Seek">Initiate your claim </h5>
                      <p>
                        Once you've gathered relevant documents and evidence,
                        reach out to our team via the{" "}
                        <strong>
                          Privilege car insurance claims phone number
                        </strong>{" "}
                        to register your claim with us.
                      </p>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="whyus">
        <div className="container">
          <div className="row">
            <div className="col-xxl-12 col-md-12">
              <div className="why">
                <h2 className="choose">
                  Start your claim in{" "}
                  <span className="light">
                    <br /> four simple steps{" "}
                  </span>
                </h2>
                <p className="complexities">
                  Thinking of making a car accident claim but worried about
                  paperwork hassles? With our streamlined and hassle-free claims
                  process, you can initiate the{" "}
                  <strong>Privilege car insurance claim </strong>in four steps.
                  Call us on <a href="tel:0800 772 0850">0800 772 0850</a> to
                  schedule a consultation with our claim specialists.
                </p>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-xxl-3 col-xl-6 col-lg-6 col-md-6 col-12">
              <div className="box-service">
                <img src={check} className="check" alt="" />
                <h4>Get in touch </h4>
                <p>
                  Contact our team via the{" "}
                  <strong>Privilege car accident claim phone number </strong>for
                  free, no-obligation advice regarding your claim.
                </p>
              </div>
            </div>
            <div className="col-xxl-3 col-xl-6 col-lg-6 col-md-6 col-12">
              <div className="box-service">
                <img src={check} className="check" alt="" />
                <h4>Share accident details </h4>
                <p>
                  Provide us with all the information related to the accident
                  and the parties involved.
                </p>
              </div>
            </div>
            <div className="col-xxl-3 col-xl-6 col-lg-6 col-md-6 col-12">
              <div className="box-service">
                <img src={check} className="check" alt="" />
                <h4>Hand over evidence and documents </h4>
                <p>
                  Submit relevant documents and evidence collected from the
                  accident scene to ensure smooth processing of your claim.
                </p>
              </div>
            </div>
            <div className="col-xxl-3 col-xl-6 col-lg-6 col-md-6 col-12">
              <div className="box-service">
                <img src={check} className="check" alt="" />
                <h4>Claim initiation </h4>
                <p>
                  After gathering the required information, we’ll initiate your
                  claim and stay in touch with you to update you about its
                  progress.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="faqs">
        <div className="container">
          <div className="row">
            <div className="col-xxl-12 col-xl-12 col-md-12 col-12">
              <div className="fq">
                <h2>
                  {" "}
                  Frequently Asked{" "}
                  <span className="light">Questions (FAQ)</span>
                </h2>
                <p className="complexities">
                  Got questions about the Privilege car insurance claim? We have
                  them answered here.
                </p>
              </div>
              <div className="allfaq">
                <Accordion defaultActiveKey="0">
                  <Accordion.Item eventKey="0">
                    <Accordion.Header>
                      My vehicle is immobile due to the car accident. What
                      should I do?
                    </Accordion.Header>
                    <Accordion.Body>
                      If your vehicle is immobile due to a no-fault car
                      accident, call us on the Privilege car insurance claim
                      number. We will recover your vehicle from the accident
                      scene and move it to our safe storage yards.
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="1">
                    <Accordion.Header>
                      Will you provide me with a replacement vehicle if my
                      vehicle is undergoing repairs?
                    </Accordion.Header>
                    <Accordion.Body>
                      Yes. We will provide you with a like-for-like replacement
                      vehicle similar to your own vehicle so that you can
                      continue your routine activities while your vehicle is off
                      the road.
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="2">
                    <Accordion.Header>
                      Can I choose my own repairer for my vehicle repairs?
                    </Accordion.Header>
                    <Accordion.Body>
                      Yes. Contact us via the Privilege claims number to share
                      the details of your repairer so that we can schedule your
                      vehicle repairs from your chosen garage.
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="3">
                    <Accordion.Header>
                      Are your accident management services available 24/7?
                    </Accordion.Header>
                    <Accordion.Body>
                      Yes. Our dedicated team is available round the clock to
                      provide you with timely assistance, ensuring you get
                      emergency support when you need it the most.
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="4">
                    <Accordion.Header>
                      Will using your accident management services affect my
                      insurance premiums?
                    </Accordion.Header>
                    <Accordion.Body>
                      No. Using our accident management services won’t affect
                      your insurance premium, as we won’t initiate a claim
                      against your own insurance policy.
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="5">
                    <Accordion.Header>
                      What happens if my vehicle is unrepairable?
                    </Accordion.Header>
                    <Accordion.Body>
                      If your vehicle is declared unrepairable after the
                      accident, we will provide you with a temporary replacement
                      vehicle. Also, our solicitors will negotiate with the
                      other party’s insurer to help you secure rightful
                      compensation.
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="6">
                    <Accordion.Header>
                      Are there any costs associated with using your accident
                      management services?
                    </Accordion.Header>
                    <Accordion.Body>
                      No. We provide our accident management services at no cost
                      to you. We will recover all the costs associated with
                      using our accident management services from the other
                      party’s insurer.
                    </Accordion.Body>
                  </Accordion.Item>
                </Accordion>
              </div>
            </div>
          </div>
        </div>
      </section>

      <Footer />
    </>
  );
};

export default Quotemehappy;
