import React, { useEffect, useRef, useState } from "react";
import homecar from "../../src/assets/images/homecar.webp";
import homebnr from "../../src/assets/images/homebnr.webp";
import girlcar from "../assets/images/girlcar.jpg";
import Header2 from "../components/header/Header2";
import Footer from "../components/footer";
import Mynewform from "../components/mynewform";
import { Helmet } from "react-helmet";


const Home = () => {
  return (
    <>

      <Header2 />
      <Helmet>
        <meta charSet="utf-8" />
        <title>Report Car Insurance Accident Claims Number</title>
        <meta name="description" content="Need to report a car accident in the UK? Reach out to us through the Car Insurance Accident Claims Number 0800 772 0850 for fast, reliable and skilled claim support. " />
        <link rel="canonical" href="https://insurance-accident-claims.co.uk " />
      </Helmet>

      <section className="hmebnr" style={{ backgroundImage: "url(" + homebnr + ")" }} id="hmebn">
        <div className="container">
          <div className="row">
            <div className="col-xxl-7 col-xl-7 col-md-10 col-12">
              <div className="innerbnr">
                <h1>Your reliable partner for no-fault <span className="sci">accident</span>Insurance claims number </h1>
                <p className="brokerage">From vehicle recovery and repairs to full claims management, we’re your one-stop solution for all your no-fault car accident management needs. </p>
                <div className="bnrbtn">
                  <a class="db-btn-border btn-rollover" href="tel:0800 772 0850">Start your claim with us <i class="fa-solid fa-arrow-right"></i></a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="homr2">
        <div className="container">
          <div className="row">
            <div className="col-xxl-6 col-xl-6 col-lg-6 col-12">
              <div className="Compensation">
                <h2 className="trusted-title">​​We expertly manage no-fault <span className="light">accidents without any hidden charges</span> </h2>

                <p className="cos">We are the UK's leading accident management specialists, providing assistance to individuals involved in no-fault accidents.</p>
                <p className="cos">At our core, we provide the best <strong>insurance accident support </strong>services at no extra cost to you. With us, you won’t be burdened with any insurance policy excess. In addition, we ensure your claims bonus remains unaffected.</p>
                <p className="cos">go the extra mile to provide you with a no-cost replacement vehicle and the highest quality repairs using original parts. </p>
                <p className="cos">With just a quick call on our <strong>accident claim helpline<a href="tel:0800 772 0850"> 0800 772 0850</a></strong>, you can get access to a comprehensive range of <strong>report accident</strong> claim management services.</p>
              </div>
            </div>
            <div className="rol-xxl-6 col-xl-6 col-lg-6 col-md-12 col-12">
              <Mynewform />
            </div>
          </div>

        </div>
      </section>
      <hr />
      <section className="abt" id="about">
        <div className="container">
          <div className="row">
            <div className="col-xxl-6 col-xl-6 col-md-6 col-12">
              <div className="abtinner">
                <img src={homecar} className="homecar" alt="" />
              </div>
            </div>
            <div className="col-xxl-6 col-xl-6 col-md-6 col-12">
              <div className="abt-right">
                <h2 className="courtesy">Trusted accident claim management <span className="light">experts in the UK </span></h2>
                <p className="disciplines">With our reliable and friendly team by your side, you can rest assured that your accident and claim management needs will be dealt with in an efficient and professional way. If you require an expert negotiator who can help you get fair compensation, you can call on our accident claims helpline <a href="tel:0800 772 0850">0800 772 0850</a>. As your trusted claims partner, we will arrange an experienced solicitor who will guide you at every step. </p>
                <hr />
                <p className="bold-text">Fair Evaluation </p>
                <p className="bottom-te"> Contact us on our <strong>claims contact number</strong> to get the services of an expert solicitor for strategic guidance throughout your <strong>insurance accident claims </strong>process and to negotiate with your insurer for better outcomes.
                </p>
                <hr />
                <p className="bold-text">Fast Settlement</p>
                <p className="bottom-te">Our claims process is smooth and stress-free. When the <strong>claims department</strong> is busy, you can reach out to us on our <strong>accident claim phone number <a href="tel:0800 772 0850">0800 772 0850</a></strong> to start your no-fault<strong> insurance accident claims </strong>without any hassle.
                </p>


              </div>

            </div>
          </div>
        </div>
      </section>
      <section className="yellowcta">
        <div className="container">
          <div className="row">
            <div className="cta2">
              <div className="col-xxl-8 col-xl-8 col-lg-8 col-12">

                <div className="legal">
                  <h3 className="experienced">Have more questions? <span className="light">Contact our team. </span> </h3>
                  <p className="professionals">Speak to our experts on <strong>car insurance claim number </strong>if you have any questions or queries related to your no-fault <strong>insurance accident claim.</strong> </p>
                </div>

              </div>
              <div className="col-xxl-4 col-xl-4 col-lg-4 col-12">
                <div class="process">
                  <a class="tf-button style-1" href="tel:0800 772 0850">Contact Us<span class="icon-keyboard_arrow_right"></span></a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="aboutprocess">
        <div className="container ">
          <div className="row">
            <div className="col-xxl-12 col-lg-12 col-xxl-12 col-md-12 col-12">
              <h2 className="trusted-title"> Quick and hassle-free insurance accident <br /><span className="light">support for no-fault accident</span> </h2>
            </div>
          </div>
          <div className="row" id="possible">
            <div className="col-xxl-4 col-lg-4 col-xxl-4 col-md-12 col-12">
              <div className="claim">
                <ul>
                  <li><span className="sleej">Car Recovery:</span> Our dedicated 24/7 accident recovery team will assist you with your vehicle recovery needs. We will promptly arrange the recovery of your damaged vehicle from the accident scene when you contact us through our <strong>car accident helpline.</strong>  </li>

                  <li><span className="sleej">Car Repair:</span>
                    Our team is always on hand to help you with your vehicle repair after the accident. Give us a call on our <strong>claims contact number <a href="tel:0800 772 0850">0800 772 0850</a></strong>, and we will ensure quick repairs of your vehicle from your preferred garage. </li>
                </ul>
              </div>
            </div>
            <div className="col-xxl-4 col-lg-4 col-xxl-4 col-md-12 col-12">
              <div className="claim" id="blue">
                <ul>
                  <li><span className="sleej">Vehicle Storage:</span>
                    After the accident, if you think that your vehicle is unroadworthy, you can call our <strong>car insurance claim number</strong> for vehicle storage services. We will store your vehicle in our safe storage yard until further inspection or repairs using the appropriate tools and equipment. Our vehicle storage services prevent further deterioration of your vehicle from pollution or dirt.

                  </li>

                  <li><span className="sleej">Credit Hire Car: </span>
                    If you need a <strong>courtesy car report accident</strong>,  we will arrange a like-for-like credit hire replacement vehicle as soon as possible. With our credit hire services, you’ll have a temporary vehicle until yours is being repaired. You can contact us at the <strong>car accident helpline <a href="tel:0800 772 0850">0800 772 0850</a></strong> to get a credit hire car according to your requirements without incurring any additional costs.
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-xxl-4 col-lg-4 col-xxl-4 col-md-12 col-12">
              <div className="claim">
                <ul>
                  <li><span className="sleej">Injury Compensation:</span>
                    Our panel of solicitors have extensive experience in personal injury claims. As a <strong>report accident customer</strong>, you’ll get the best compensation for your injuries with the support and guidance of our expert solicitors.
                  </li>

                  <li><span className="sleej">Comprehensive Claims Management:</span>
                    For best guidance on no-fault accident claims, you can contact us through our accident claim phone number. Our experienced solicitors will guide and support you throughout the claim process and ensure you get the maximum compensation.
                  </li>
                </ul>
              </div>
            </div>
          </div>

        </div>
      </section>

      <section className="workinf">
        <div className="container">
          <div className="row">
            <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-12">
              <div className="abt-right">
                <h2 className="courtesy">Let us help you get rightful compensation <span className="light">for your no-fault insurance accident claim </span></h2>
                <p className="cos">As one of the leading accident management companies in the UK, we have vast experience in securing maximum compensation for drivers involved in no-fault accidents. </p>

                <p className="cos">We specialize in every aspect of <strong>report accident claim</strong> management services to ensure prompt processing of your no-fault accident claim.
                </p>

                <p className="cos"> Whether it’s <strong>roadside recovery, accident damage repair, injury compensation </strong>or arranging a <strong>free courtesy car</strong>, you can trust us for the best <strong>insurance accident support.</strong> </p>

                <p className="cos">Let us handle the complexities of your<strong> no-fault insurance accident claim </strong>while you focus on your recovery.  Call on our <strong>accident claim phone number <a href="tel:0800 772 0850">0800 772 0850</a> </strong>to start your claim with us. </p>

                <a class="db-btn-border btn-rollover" href="tel:0800 772 0850">Make a claim <i class="fa-solid fa-arrow-right"></i></a>
              </div>
            </div>
            <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-12">
              <img src={girlcar} className="girlcar" alt="" />
            </div>
          </div>
        </div>
      </section>

      {/* <div className="rs-about-service">
  <div className="container">
    <div className="row">
      <div className="col-lg-4">
        <div className="rs-about-service__item">
          <div className="rs-icon">
          <img src={ab3} className="ab1" alt="" />
          </div>
          <div className="rs-content">
            <h4 className="title">24/7 assistance </h4>
            <p>
            Our friendly team is available round the clock to provide you with immediate assistance. 
            </p>
          </div>
        </div>
      </div>
      <div className="col-lg-4">
        <div className="rs-about-service__item item-2">
          <div className="rs-icon">
          <img src={ab1} className="ab1" alt="" />
          </div>
          <div className="rs-content">
            <h4 className="title">No upfront charges </h4>
            <p>
            You don't have to pay any excess fees or unexpected costs after a no-fault accident. 
            </p>
          </div>
        </div>
      </div>
      <div className="col-lg-4">
        <div className="rs-about-service__item item-3">
          <div className="rs-icon">
          <img src={ab2} className="ab2" alt="" />
          </div>
          <div className="rs-content">
            <h4 className="title">NCB unaffected </h4>
            <p>
            With us, you can rest assured that your no-claim bonus will remain intact. 
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</div> */}

      <Footer />
    </>

  );
};

export default Home;
