import React, { useRef } from "react";
import Accordion from "react-bootstrap/Accordion";
import check from "../assets/images/check.png";
import dualcar from "../../src/assets/images/dualcar.webp";
import carbnr1 from "../../src/assets/images/carbnr1.webp";
import Sidebarinner from "../components/Sidebar-inner";
import phonecall from "../assets/images/phonecall.png";
import Footer from "../components/footer";
import { Helmet } from "react-helmet";

const Drivelikeagirl = () => {
  const sidebarRef = useRef(null);

  const handleScrollToSidebar = () => {
    if (sidebarRef.current) {
      sidebarRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };
  return (
    <>
    <Helmet>
        <meta charSet="utf-8" />
        <title>Drive Like a Girl Car Insurance Claim Phone Number</title>
        <meta
          name="description"
          content="Contact Drive Like a Girl Car Insurance Claim Phone Number 0800 772 0850 for quick and hassle-free accident management support and claims assistance. Call us now.  "
        />
        <link
          rel="canonical"
          href="https://insurance-accident-claims.co.uk/drive-like-a-girl"
        />
      </Helmet>
      <section className="innernav">
        <div className="container">
          <div className="row">
            <div className="col-md-6 col-sm-6">
              <div className="left-logo">
                <h3>
                  Accident<span className="sipo"> Claims</span>
                </h3>
              </div>
            </div>
            <div className="col-md-6 col-sm-6">
              <div className="left-cta">
                <div class="headercnt">
                  <div class="chatmsg">
                    <img
                      src={phonecall}
                      className="phonecall"
                      alt="phonecall "
                    />
                  </div>
                  <div class="box-body">
                    <h3 class="nav-info-box-title">Toll Free Number </h3>
                    <p>
                      <a href="tel:0800 772 0850">0800 772 0850</a>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section
        className="newbnr"
        style={{ backgroundImage: "url(" + carbnr1 + ")" }}
      >
        <div className="container">
          <div className="row">
            <div className="col-xxl-8 col-xl-8 col-md-10 col-12">
              <div className="innerbnr">
                <h1>
                  <span className="onespan">Car Accident</span>{" "}
                  <span className="sci"> insurance claims </span> in The UK
                </h1>
                <p className="brokerage">
                We are the leading accident management experts in the UK who specialise in offering the best solutions for no-fault car accidents.
                </p>
                <div className="bnrbtn">
                  <a class="db-btn-border btn-rollover phone" href="tel:0800 772 0850">
                    Road Accident Claim Help?{" "}
                    <i class="fa-solid fa-arrow-right"></i>
                  </a>
                </div>
                <div className="bnrbtn">
                <a className="db-btn-border btn-rollover desktop"  onClick={handleScrollToSidebar}>
                    Road Accident Claim Help? <i className="fa-solid fa-arrow-right"></i>
                </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="stickysection">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="group-4-8">
                <div className="cl8 tf-tab">
                  <div className="content-tab">
                    <div className="inner-content">
                      <h2>
                      Excellent Drive Like a Girl insurance accident assistance with no excess
                        <br />
                        <span className="light">assistance with no excess </span>{" "}
                      </h2>
                      <p className="disciplines">
                      It can be such a stressful experience to get involved in a no-fault car accident. In addition to injuries and damages, you will also be responsible for handling your insurance claims complexities. That's why we offer the best{" "}
                        <strong>
                          {" "}
                          Drive Like a Girl insurance accident support 
                        </strong>{" "}
                        without any excess.
                      </p>
                      <hr />
                      <p className="bold-text">No cash upfront </p>
                      <p className="bottom-te">
                      We provide{" "} <strong> Drive Like a Girl insurance accident support </strong> services, including vehicle recovery, replacement car and claims management without requiring you to bear additional costs.
                      </p>
                      <hr />
                      <p className="bold-text">Preserved no-claims bonus </p>
                      <p className="bottom-te">
                      When calling our accident claim helpline <a href="tel:0800 772 0850">0800 772 0850</a>, rest assured that all those hard-earned discounts will stay untouched.
                      </p>
                      <hr />
                      <p className="bold-text">
                      Professional assistance 
                      </p>
                      <p className="bottom-te">
                      Our professional{" "} <strong> Drive Like a Girl insurance accident support </strong>services will ensure that maximum compensation is achieved for your <strong> Drive Like a Girl insurance accident claim. </strong>
                      </p>
                      <hr />
                      <p className="bold-text">Experience solicitors </p>
                      <p className="bottom-te">
                      In the UK, we have a huge network of highly experienced and professional solicitors who will handle your case with diligence.{" "}
                      </p>
                      <hr />
                      <p className="bold-text">Free initial consultation</p>
                      <p className="bottom-te">
                      Our solicitors will assess your claim, provide professional advice and be with you throughout the claims process at no cost to you.
                      </p>
                      <hr />
                      <p className="bold-text">Expertise and extensive experience</p>
                      <p className="bottom-te">
                      We are true professionals following years of practice dealing with <strong> Drive Like a Girl accident insurance accident claims. </strong>{" "}
                      When you call us at the <strong> Drive Like a Girl accident claims number, </strong> we can guarantee that you will get higher compensation than any other accident management company.
                      </p>
                      <hr />
                      <p className="bold-text">Wide network of repairers and credit hirers</p>
                      <p className="bottom-te">
                      Our partners comprise leading repairers, well-known credit hire providers, and experienced solicitors who enable us to offer our clients quality services.
                      </p>
                      <hr />
                      <p className="bold-text"> Fast settlement on claims </p>
                      <p className="bottom-te">
                      Just contact us by dialling the{" "} <strong> Drive Like a Girl accident claims helpline </strong> for enquiries related to filing a claim. With our fast-tracked system for making claims, we ensure that your Drive Like a Girl insurance accident claim will be resolved promptly without involving too much trouble or stress.
                      </p>
                      <hr />
                      <p className="bold-text"> Instant replacement vehicle </p>
                      <p className="bottom-te">
                      We will make available an equivalent car on the same day so that your daily activities are not interrupted in any way whatsoever.{" "}
                      </p>
                      <hr />

                      <p className="bold-text">Hassle-free vehicle storage</p>
                      <p className="bottom-te">
                      We have safe and secure yards where we store damaged vehicles to prevent further decay and destruction.{" "}
                      </p>
                    </div>
                  </div>
                </div>
                <div className="cl4" ref={sidebarRef}>
                  <Sidebarinner />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="photographs">
        <div className="container">
          <div className="row">
            <h2 className="road">
            Sit back and relax while we take care of {" "}
              <span className="light">
              your <br />
                accident management needs
              </span>{" "}
            </h2>
            <div className="col-xxl-6 col-xl-6 col-md-12 col-12">
              <div className="fourbox">
                <ul>
                  <li>
                    <img src={check} className="check" alt="" />
                    In case of a no-fault car accident, once you reach us on our car accident claims helpline at{" "}
                    <strong> <a href="tel:0800 772 0850">0800 772 0850</a> </strong>, we register the accident’s particulars, and all help is provided immediately.
                  </li>
                  <hr />
                  <li>
                    <img src={check} className="check" alt="" />
                    Our seasoned solicitors will scrutinise your <strong> Drive Like a Girl insurance accident claim </strong>and guide you on how to go about it for a successful outcome.
                  </li>
                  <hr />
                  <li>
                    <img src={check} className="check" alt="" />
                    Call us on the <strong> Drive Like a Girl accident claims number </strong>to get a like-for-like replacement vehicle until your vehicle is repaired.
                  </li>
                  <hr />
                  <li>
                    <img src={check} className="check" alt="" />
                    We are experts in handling <strong> Drive Like a Girl insurance accident claims </strong> from start to finish. We are there with you every step of the way—from filling out forms to ensuring quick settlement.
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-xxl-6 col-xl-6 col-md-12 col-12">
              <div className="fourbox">
                <ul>
                  <li>
                    <img src={check} className="check" alt="" />
                    To ensure you can bounce back and return to normal at the soonest time possible, we will take care of everything, including the entire claim process.
                  </li>
                  <hr />
                  <li>
                    <img src={check} className="check" alt="" />
                    We negotiate fair settlement offers on your behalf to secure maximum compensation for your injuries and losses.
                  </li>
                  <hr />
                  <li>
                    <img src={check} className="check" alt="" />
                    Our team works with trusted repairers and garages to ensure high-quality repairs for your vehicle
                  </li>
                  <hr />
                  <li>
                    <img src={check} className="check" alt="" />
                    We will ensure your peace of mind because, throughout the <strong>  Drive Like a Girl insurance accident claim </strong> process, we make sure your rights are protected.
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="carprocess">
        <div className="container ">
          <div className="row">
            <div className="col-xxl-12 col-xxl-12 col-md-12 col-12">
              <h2 className="trusted-title">
                {" "}
                Drive Like a Girl insurance  <br />
                <span className="light"> support services </span>{" "}
              </h2>
            </div>
          </div>
          <div className="row" id="possible">
            <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-12">
              <div className="claim">
                <ul>
                  <li>
                    <span className="sleej">Vehicle recovery:</span>When you need to recover a vehicle, you just have to give us a call at the{" "}
                    <strong> Drive Like a Girl accident claims number, </strong>and we will provide a quick service which involves moving your damaged vehicle to a safe place.
                  </li>
                  <li>
                    <span className="sleej">Vehicle repairs:</span>Do you require outstanding repairs for your vehicle? Be sure to ring the Drive Like a Girl accident claims helpline today. Our UK-wide network of approved repairers meets the highest standards when it comes to vehicle repairs. 
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-12">
              <div className="claim" id="blue">
                <ul>
                  <li>
                    <span className="sleej">Vehicle storage:</span> Your car must be stored in a safe place after it has been involved in an accident that was not your fault. Once you dial the{" "}
                    <strong> Drive Like a Girl accident claims number, </strong>we will promptly collect your vehicle from where the accident occurred and keep it in our safe storage facilities until claim assessment.
                  </li>
                  <li>
                    <span className="sleej">Replacement car:</span>You qualify for a replacement vehicle if you have been involved in a non-at-fault road traffic collision. In order to get you back on track, we’ll arrange for a temporary replacement vehicle. So, you can continue with your daily life without any hindrances or disturbances.  Simply get in touch via the{" "}
                    <strong>
                      {" "}
                      Drive Like a Girl accident contact number,{" "}
                    </strong>
                    and within 24 hours, we will make arrangements for another vehicle for you.
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-12">
              <div className="claim">
                <ul>
                  <li>
                    <span className="sleej">Personal injury support:</span>
                    We will assist you in a successful personal injury compensation claim if you have suffered any injuries as a result of the accident. The
                    <strong> Drive Like a Girl claims department </strong>
                    will help you through every stage and ensure you are rightly compensated.
                  </li>
                  <li>
                    <span className="sleej">Claims management:</span>From your initial paperwork to the settlement of claims, we take care of all aspects of your <strong> Drive Like a Girl insurance accident claim </strong> to minimise your stress
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="yellowcta">
        <div className="container">
          <div className="row">
            <div className="cta2">
              <div className="col-xxl-8 col-xl-8 col-lg-8 col-12">
                <div className="legal">
                  <h3 className="experienced">
                  Need further guidance? {" "}
                    <span className="light">Contact our experts</span>{" "}
                  </h3>
                  <p className="professionals">
                  Reach out to our dedicated team through our{" "}
                    <strong>
                    accident claims number{" "}
                      <a className="cpt-no" href="tel:0800 772 0850">
                        {" "}
                        0800 772 0850{" "}
                      </a>{" "}
                    </strong>
                    to discuss your claim and know your options to get the maximum compensation.
                  </p>
                </div>
              </div>
              <div className="col-xxl-4 col-xl-4 col-lg-4 col-12">
              <div class="process">
                  <a class="tf-button style-1 phone" href="tel:0800 772 0850">
                    Get in touch<span class="icon-keyboard_arrow_right"></span>
                  </a>
                </div>
                <div class="process">
                    <a className="tf-button style-1 desktop"  onClick={handleScrollToSidebar}>
                        Get in touch<span className="icon-keyboard_arrow_right"></span>
                    </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <search className="updatedform">
        <div className="container">
          <div className="row text-center">
            <div className="col-xxl-12 col-xl-12 col-md-12 col-12">
              <h2 className="whiteheading">
                {" "}
                Why choose us for claims <br />
                <span className="light">assistance?</span>
              </h2>
              <p className="sustained">
              We provide free-of-charge accident management solutions to get you back on track after an accident. Once you've notified us about the road traffic collision, our dedicated team will <strong> immediately assume responsibility and manage everything from start to finish. Call the Drive Like a Girl accident claims helpline </strong>if you have an interest in starting your claim with us.
              </p>
            </div>
          </div>
          <div className="row">
            <div className="col-xxl-4 col-xl-4 col-lg-6 col-md-12 col-12">
              <div className="Claimant">
                <h5> Extensive range of services </h5>
                <p className="collision">
                Our range of crash management services includes vehicle recovery and replacement cars and claims management from start to end.
                </p>

                <h5> No excess payments </h5>
                <p className="collision">
                All the charges will be claimed back from the insurer of the driver responsible. You don't have to pay any additional payment.{" "}
                </p>

                <h5> Fast vehicle recovery </h5>
                <p className="collision">
                Our team is available around the clock to help you recover your car on time.
                </p>
              </div>
            </div>
            <div className="col-xxl-4 col-xl-4 col-lg-6 col-md-12 col-12">
              <div className="Claimant">
                <h5> Quality repairs </h5>
                <p className="collision">
                We will ensure that your vehicle receives repairs of the highest standards using original parts.
                </p>
                <h5> Like-for-like replacement </h5>
                <p className="collision">
                A replacement car, same as your own in terms of make, model and size, will be provided by us to you.
                </p>

                <h5> Easy and quick claim procedure </h5>
                <p className="collision">
                Our simple claims process aims at getting you a resolution in as little time as possible.
                </p>
              </div>
            </div>
            <div className="col-xxl-4 col-xl-4 col-lg-12 col-md-12 col-12">
              <div className="Claimant">
                <h5> Dedicated claim handler </h5>
                <p className="collision">
                You will be given one dedicated person who will deal with any issues related to your claim until completion.
                </p>

                <h5>Expert negotiation </h5>
                <p className="collision">
                We will talk with all insurance companies so you do not have to worry about anything or take unwanted stress. 
                </p>

                <h5>Post-repair check</h5>
                <p className="collision">
                After carrying out repair work on your car, we always perform a post-repair inspection on it.
                </p>
              </div>
            </div>
          </div>
        </div>
      </search>

      <section className="Accident-claim">
        <div className="container">
          <div className="row">
            <div className="needed">
              <h2 className="trusted-title">
              How can you ensure you get rightful compensation  <br />
                <span className="light"> for your claim? </span>
              </h2>
              <p className="complexities">
              Accidents on the road can be stressful and might leave you wondering how to handle unexpected costs. Here are a few actions which must be taken in order for you to have a better chance of getting full compensation for your losses and injuries.
              {" "}
              </p>
            </div>
            <hr />
          </div>
          <div className="row" id="incdes">
            <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-12">
              <div className="Preparing">
                <ul>
                  <li>
                    <div className="seemingly">
                      <h5 className="Seek">Check injuries:</h5>
                      <p>
                        {" "}
                        Move out of traffic and check all the passengers involved for major or minor injuries.{" "}
                      </p>
                    </div>
                  </li>
                  <li>
                    <div className="seemingly">
                      <h5 className="Seek">
                      Get medical attention:{" "}
                      </h5>
                      <p>
                      Immediately seek medical help from a qualified doctor in order to avoid further damage to your injuries. 
                      </p>
                    </div>
                  </li>
                  <li>
                    <div className="seemingly">
                      <h5 className="Seek">Call emergency services:</h5>
                      <p>
                      Call us via the <strong> Drive Like a Girl car accident claims helpline</strong> for swift emergency assistance.
                      </p>
                    </div>
                  </li>
                  <li>
                    <div className="seemingly">
                      <h5 className="Seek">Inform the police</h5>
                      <p>
                      Ring the police right away and report the accident. They will thoroughly document the accident and take appropriate action.
                      </p>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
            <div
              className="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-12"
              id="dualcardiv"
            >
              <img src={dualcar} alt="" className="dualcar" />
            </div>
            <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-12">
              <div className="Preparing">
                <ul>
                  <li>
                    <div className="seemingly">
                      <h5 className="Seek">Collect evidence:</h5>
                      <p>
                      Ensure that you gather important information from the scene of the incident so that you can prove your innocence. Meanwhile, while waiting for police, collect significant evidence like photos of the accident place, vehicles involved as well as eyewitness statements.{" "}
                      </p>
                    </div>
                  </li>
                  <li>
                    <div className="seemingly">
                      <h5 className="Seek"> Take photographs of your vehicle: </h5>
                      <p>
                      Take photos of your damaged car before taking it for repair in order to determine the extent of damage, which can help you establish other drivers’ liability.
                      </p>
                    </div>
                  </li>
                  <li>
                    <div className="seemingly">
                      <h5 className="Seek">
                      Exchange particulars with others:
                      </h5>
                      <p>
                      Exchange names, addresses and contact details, including phone numbers and email addresses, with the other drivers. Also, get information on the insurance policy of both parties.{" "}
                      </p>
                    </div>
                  </li>
                  <li>
                    <div className="seemingly">
                      <h5 className="Seek">
                      Start your claim:
                      </h5>
                      <p>
                      Contact us on our accident claim number{" "}
                        <strong>
                          {" "}
                          <a href="tel:0800 772 0850">0800 772 0850</a>{" "}
                        </strong>{" "}
                        to start your no-fault claim. We will allocate a dedicated claims handler who will take care of every aspect of your claim.
                      </p>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="whyus">
        <div className="container">
          <div className="row">
            <div className="col-xxl-12 col-md-12">
              <div className="why">
                <h2 className="choose">
                Simple claims  process for {" "}
                  <span className="light">quick resolution</span>
                </h2>
                <p className="complexities">
                Claiming for non-fault accidents is complicated and involves lots of paperwork and long queues at the office. Our procedure has no hassles, and you can submit your claim quickly without paperwork complexities. To begin the claim contact the Drive Like a Girl accident claims number.
                </p>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-xxl-3 col-xl-6 col-lg-6 col-md-6 col-12">
              <div className="box-service">
                <img src={check} className="check" alt="" />
                {/* <img src={experience} className="experience" alt="" /> */}
                <h4> Get in touch with us</h4>
                <p>
                To get started with making a claim, dial the Drive Like a Girl accident number.
                </p>
              </div>
            </div>
            <div className="col-xxl-3 col-xl-6 col-lg-6 col-md-6 col-12">
              <div className="box-service">
                <img src={check} className="check" alt="" />
                <h4> Provide Details </h4>
                <p>
                Tell us about the incident by specifying when, where and how it occurred.
                </p>
              </div>
            </div>
            <div className="col-xxl-3 col-xl-6 col-lg-6 col-md-6 col-12">
              <div className="box-service">
                <img src={check} className="check" alt="" />
                <h4> Hand over documents </h4>
                <p>
                Any relevant details, like photographs, videos, CCTV footage, and eyewitness statements, should be forwarded to us.{" "}
                </p>
              </div>
            </div>
            <div className="col-xxl-3 col-xl-6 col-lg-6 col-md-6 col-12">
              <div className="box-service">
                <img src={check} className="check" alt="" />
                <h4> Claim initiation </h4>
                <p>
                When we have all the documents, we will review your claim and initiate it.{" "}
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="faqs">
        <div className="container">
          <div className="row">
            <div className="col-xxl-12 col-xl-12 col-md-12 col-12">
              <div className="fq">
                <h2>
                  {" "}
                  Frequently Asked{" "}
                  <span className="light">Questions (FAQ)</span>
                </h2>
                <p className="complexities">
                Got questions about the{" "}
                  <strong> Drive Like a Girl insurance accident claim? </strong> We have them answered here.
                </p>
              </div>
              <div className="allfaq">
                <Accordion defaultActiveKey="0">
                  <Accordion.Item eventKey="0">
                    <Accordion.Header>
                    Can I make a claim for injuries suffered in an accident if they are only minor ones?
                    </Accordion.Header>
                    <Accordion.Body>
                    Yes. You can make a claim for things like soft tissue injuries, minor fractures and crush injuries. Our personal injury solicitors will evaluate your case and tell you if they think it’s eligible for compensation or not.
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="1">
                    <Accordion.Header>
                    Is it possible to use my own preferred garage to repair my car?
                    </Accordion.Header>
                    <Accordion.Body>
                    Certainly! Just provide us with the repairer's details whom you prefer most, and we will organise repairs with them.
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="2">
                    <Accordion.Header>
                    What do I do if my vehicle cannot move after a no-fault collision?
                    </Accordion.Header>
                    <Accordion.Body>
                    If you have been involved in an accident which was not your fault, resulting in the immobility of your vehicle, our company arranges its recovery. For a swift recovery process,  call our specialists through the <strong> Drive Like a Girl accident claims number. </strong>
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="3">
                    <Accordion.Header>
                    For how long can you store my vehicle in your secure yards?{" "}
                    </Accordion.Header>
                    <Accordion.Body>
                    We will store your vehicles in our yards until your vehicle gets repaired or your claim gets settled.
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="4">
                    <Accordion.Header>
                    Will my insurance premium be affected as a result of a no-fault claim?
                    </Accordion.Header>
                    <Accordion.Body>
                    No. We won’t initiate a claim against your own insurance policy, which means that your insurance premium and no claim bonus will not be affected.
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="5">
                    <Accordion.Header>
                    What happens if my car is written off?{" "}
                    </Accordion.Header>
                    <Accordion.Body>
                    If your car is entirely written off, we will provide you with a replacement vehicle that you can keep until you receive full payment for your damaged vehicle.{" "}
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="6">
                    <Accordion.Header>
                    Do I need to notify my insurer?{" "}
                    </Accordion.Header>
                    <Accordion.Body>
                    No. We will handle that for you and inform your insurer on your behalf. We will remain in touch with you and your insurer throughout the process.
                    </Accordion.Body>
                  </Accordion.Item>
                </Accordion>
              </div>
            </div>
          </div>
        </div>
      </section>

      <Footer />
    </>
  );
};

export default Drivelikeagirl;
