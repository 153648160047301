import React, { useRef } from "react";
import Accordion from "react-bootstrap/Accordion";
import check from "../assets/images/check.png";
import dualcar from "../../src/assets/images/dualcar.webp";
import carbnr1 from "../../src/assets/images/carbnr1.webp";
import Sidebarinner from "../components/Sidebar-inner";
import phonecall from "../assets/images/phonecall.png";
import Footer from "../components/footer";
import { Helmet } from "react-helmet";

const Lloydsbank = () => {
  const sidebarRef = useRef(null);

  const handleScrollToSidebar = () => {
    if (sidebarRef.current) {
      sidebarRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Lloyds Bank Car Insurance Accident Claim Phone Number:</title>
        <meta
          name="description"
          content="Searching for reliable claim support and assistance? Get in touch with our dedicated team via Lloyds Bank Car Insurance accident claim phone number 0800 772 0850. "
        />
        <link
          rel="canonical"
          href="https://insurance-accident-claims.co.uk/lloyds"
        />
      </Helmet>
      <section className="innernav">
        <div className="container">
          <div className="row">
            <div className="col-md-6 col-sm-6">
              <div className="left-logo">
                <h3>
                  Accident<span className="sipo"> Claims</span>
                </h3>
              </div>
            </div>
            <div className="col-md-6 col-sm-6">
              <div className="left-cta">
                <div class="headercnt">
                  <div class="chatmsg">
                    <img
                      src={phonecall}
                      className="phonecall"
                      alt="phonecall "
                    />
                  </div>
                  <div class="box-body">
                    <h3 class="nav-info-box-title">Toll Free Number </h3>
                    <p>
                      <a href="tel:0800 772 0850">0800 772 0850</a>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section
        className="newbnr"
        style={{ backgroundImage: "url(" + carbnr1 + ")" }}
      >
        <div className="container">
          <div className="row">
            <div className="col-xxl-8 col-xl-8 col-md-10 col-12">
              <div className="innerbnr">
                <h1>
                  <span className="onespan">No-fault</span>{" "}
                  <span className="sci">insurance claims </span> management
                </h1>
                <p className="brokerage">
                  From arranging vehicle recovery and repairs to organising
                  temporary replacement vehicles and providing professional
                  claims assistance, we’re your trusted partners on the road to
                  recovery.{" "}
                </p>
                <div className="bnrbtn">
                  <a class="db-btn-border btn-rollover phone" href="tel:0800 772 0850">
                    Road Accident Claim Help?{" "}
                    <i class="fa-solid fa-arrow-right"></i>
                  </a>
                </div>
                <div className="bnrbtn">
                <a className="db-btn-border btn-rollover desktop"  onClick={handleScrollToSidebar}>
                    Road Accident Claim Help? <i className="fa-solid fa-arrow-right"></i>
                </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="stickysection">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="group-4-8">
                <div className="cl8 tf-tab">
                  <div className="content-tab">
                    <div className="inner-content">
                      <h2>
                        Reliable partner <br />
                        <span className="light">
                          in road traffic accidents{" "}
                        </span>{" "}
                      </h2>
                      <p className="disciplines">
                        WeWe’re a team of professional no-fault claim experts in
                        the UK who are dedicated to helping you as the victims
                        involved in a motor accident. Our committed team is
                        always available for comprehensive accident support
                        services meant to make it easy and stress-free for the
                        victims after a car crash. Make a successful accident
                        claim by simply calling our{" "}
                        <strong>
                          {" "}
                          insurance claims number{" "}
                          <a href="tel:0800 772 0850">0800 772 0850</a>.{" "}
                        </strong>
                      </p>
                      <hr />
                      <p className="bold-text">
                        Avoid paying any insurance excess
                      </p>
                      <p className="bottom-te">
                        We will leave your own insurance policy intact thus
                        saving you from making payments towards insurance excess
                        charges.{" "}
                      </p>
                      <hr />
                      <p className="bold-text">
                        Manufacturer’s authorised repairs
                      </p>
                      <p className="bottom-te">
                        When you call us on the{" "}
                        <strong>
                          {" "}
                          Lloyds Bank insurance accident claim number{" "}
                        </strong>{" "}
                        for your car repair after the accident, we shall ensure
                        that only those repairs which meet the manufacturer’s
                        standards of repair requirements are done on your
                        vehicle.
                      </p>
                      <hr />
                      <p className="bold-text">
                        Like-for-like replacement vehicle
                      </p>
                      <p className="bottom-te">
                        Should your car become destroyed as a result of an
                        accident, we will replace it with one of similar size,
                        make, model and specifications.{" "}
                      </p>
                      <hr />
                      <p className="bold-text">Fast-track claim processing</p>
                      <p className="bottom-te">
                        Be at ease with our efficient streamlined process and be
                        certain that your{" "}
                        <strong> Lloyds Bank car insurance claim </strong> will
                        be settled within minimum time.{" "}
                      </p>
                      <hr />
                      <p className="bold-text">Expert claims handler</p>
                      <p className="bottom-te">
                        We will seat you down with an experienced claims handler
                        who is trained and has experience in handling claims. He
                        or she will be your only contact person at every step of
                        the process.{" "}
                      </p>
                      <hr />
                      <p className="bold-text">
                        Seasoned and proficient claim expert{" "}
                      </p>
                      <p className="bottom-te">
                        To claim, phone our claims team through the{" "}
                        <strong> Lloyds Bank insurance claims number </strong>.
                        Our team will put all their efforts into ensuring that
                        you get the best results for your request.
                      </p>
                      <hr />
                      <p className="bold-text">
                        Your no-claims bonus remains unaffected
                      </p>
                      <p className="bottom-te">
                        To protect your earned discounts and no-claims bonus, we
                        strive not to claim against your own insurance policy.{" "}
                      </p>
                      <hr />
                      <p className="bold-text">
                        All-day-long accident recovery
                      </p>
                      <p className="bottom-te">
                        Call us through the{" "}
                        <strong>
                          {" "}
                          Lloyds Bank insurance accident claim number{" "}
                        </strong>
                        whenever an accident occurs, regardless of the time, so
                        that we can send our team immediately to retrieve your
                        car from where it was involved in an accident scene.{" "}
                      </p>
                      <hr />
                      <p className="bold-text">Professional backup </p>
                      <p className="bottom-te">
                        Our panel of UK-based solicitors have many years of
                        experience and can take you through legal advice for
                        claiming compensation for all damages and losses.
                      </p>
                      <hr />

                      <p className="bold-text">Advice without commitment</p>
                      <p className="bottom-te">
                        We provide free initial consultations as well as access
                        to expert advice that enables our clients to understand
                        their rights as well as available options to make a
                        claim against another party at fault.{" "}
                      </p>
                    </div>
                  </div>
                </div>
                <div className="cl4" ref={sidebarRef}>
                  <Sidebarinner />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="photographs">
        <div className="container">
          <div className="row">
            <h2 className="road">
              From collision to recovery, we offer{" "}
              <span className="light">
                dedicated <br />
                insurance accident assistance
              </span>{" "}
            </h2>
            <div className="col-xxl-6 col-xl-6 col-md-12 col-12">
              <div className="fourbox">
                <ul>
                  <li>
                    <img src={check} className="check" alt="" />
                    You can reach us through the{" "}
                    <strong>
                      {" "}
                      Lloyds Bank insurance accident claim number{" "}
                    </strong>{" "}
                    for more information. Our experienced team will find the
                    best solution to recover your vehicle and move it to a safe
                    location.
                  </li>
                  <hr />
                  <li>
                    <img src={check} className="check" alt="" />
                    From vehicle recovery to claims management, we take care of
                    all that follows after a car crash.
                  </li>
                  <hr />
                  <li>
                    <img src={check} className="check" alt="" />
                    If your vehicle is no longer drivable, we will give you
                    another one that is similar to your own vehicle in every
                    way.
                  </li>
                  <hr />
                  <li>
                    <img src={check} className="check" alt="" />
                    We will ensure that your car returns back to its original
                    state by organising repairs with an authorised manufacturer
                    repairer.
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-xxl-6 col-xl-6 col-md-12 col-12">
              <div className="fourbox">
                <ul>
                  <li>
                    <img src={check} className="check" alt="" />
                    Our storage facilities are available throughout the process
                    at no cost for you to worry about.
                  </li>
                  <hr />
                  <li>
                    <img src={check} className="check" alt="" />
                    In case of any other accidents that might involve our
                    client, we make sure we have the best legal representation
                    for them when reporting the matter through{" "}
                    <strong>
                      {" "}
                      Lloyds Bank insurance accident claim number.
                    </strong>
                  </li>
                  <hr />
                  <li>
                    <img src={check} className="check" alt="" />
                    Save yourself from stress by allowing us to handle
                    communications between parties involved in the accident
                    which are sometimes difficult and full of hassle.
                  </li>
                  <hr />
                  <li>
                    <img src={check} className="check" alt="" />
                    Once the repairs are complete, your car will be delivered at
                    an appropriate time just at your doorstep.
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="carprocess">
        <div className="container ">
          <div className="row">
            <div className="col-xxl-12 col-xxl-12 col-md-12 col-12">
              <h2 className="trusted-title">
                {" "}
                Our varied services <br />
                <span className="light">range</span>{" "}
              </h2>
            </div>
          </div>
          <div className="row" id="possible">
            <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-12">
              <div className="claim">
                <ul>
                  <li>
                    <span className="sleej">Car recovery :</span> We will
                    quickly get your car out of the crash scene and have it
                    placed in our safety facilities by our professional recovery
                    agents. Dial the{" "}
                    <strong> Lloyds Bank insurance claims number </strong>for
                    assistance with retrieving your vehicle.
                  </li>
                  <li>
                    <span className="sleej">Accidental repairs:</span>In case of
                    any accidental damage to your vehicle, we shall organise for
                    it to be repaired in a nearby garage or a repair centre
                    according to your preference through our broad network of
                    manufacturer-approved repairers found throughout the UK.
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-12">
              <div className="claim" id="blue">
                <ul>
                  <li>
                    <span className="sleej">Secure storage:</span> It is not
                    secure to leave your vehicle on its own after a car
                    accident. Thus, during the period of claim assessment, we
                    will place your car in one of the well-kept yards where
                    there is no possibility of theft, vandalism or any other
                    damages.
                  </li>
                  <li>
                    <span className="sleej">Replacement vehicle: </span> If you
                    have been involved in an accident that has left your vehicle
                    written off or undrivable, call our team using the{" "}
                    <strong> Lloyds Bank insurance claim phone number </strong>
                    who will give you another similar car while yours is being
                    repaired.
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-12">
              <div className="claim">
                <ul>
                  <li>
                    <span className="sleej">Personal injury support:</span>
                    Seeking compensation for injuries sustained as a result of
                    an accident? For comprehensive legal advisory from our
                    solicitors that will help maximise settlement claims contact
                    us at{" "}
                    <strong>
                      {" "}
                      Lloyds Bank insurance accident claim phone number.{" "}
                    </strong>
                  </li>
                  <li>
                    <span className="sleej">Claims Handling:</span> Our
                    solicitors will handle your accident car claims with
                    experience and expertise, from the beginning till the end so
                    that you can be relaxed.
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="yellowcta">
        <div className="container">
          <div className="row">
            <div className="cta2">
              <div className="col-xxl-8 col-xl-8 col-lg-8 col-12">
                <div className="legal">
                  <h3 className="experienced">
                    Got more questions?{" "}
                    <span className="light">Talk to our specialists</span>{" "}
                  </h3>
                  <p className="professionals">
                    Get in touch with our no-fault claim experts via{" "}
                    <strong>
                      {" "}
                      insurance claims number{" "}
                      <a className="cpt-no" href="tel:0800 772 0850">
                        {" "}
                        0800 772 0850{" "}
                      </a>
                    </strong>{" "}
                    to get the best advice for your{" "}
                    <strong> Lloyds Bank car insurance claim. </strong>
                  </p>
                </div>
              </div>
              <div className="col-xxl-4 col-xl-4 col-lg-4 col-12">
              <div class="process">
                  <a class="tf-button style-1 phone" href="tel:0800 772 0850">
                    Get in touch<span class="icon-keyboard_arrow_right"></span>
                  </a>
                </div>
                <div class="process">
                    <a className="tf-button style-1 desktop"  onClick={handleScrollToSidebar}>
                        Get in touch<span className="icon-keyboard_arrow_right"></span>
                    </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <search className="updatedform">
        <div className="container">
          <div className="row text-center">
            <div className="col-xxl-12 col-xl-12 col-md-12 col-12">
              <h2 className="whiteheading">
                {" "}
                Why are we the best option for your <br />
                <span className="light">accident management needs? </span>
              </h2>
              <p className="sustained">
                What happens after a car accident can be very traumatic and at
                times cause stress and financial strain. However, under such
                circumstances, if it was not your mistake, we will protect you
                from these effects. From the time of recuperation to claim
                management; our assistance is inclusive and cost-free. Contact
                us today through the{" "}
                <strong> Lloyds Bank insurance accident claim number </strong>{" "}
                for an assessment of the services we provide on accident
                support.{" "}
              </p>
            </div>
          </div>
          <div className="row">
            <div className="col-xxl-4 col-xl-4 col-lg-6 col-md-12 col-12">
              <div className="Claimant">
                <h5>No cost to you</h5>
                <p className="collision">
                  As for the costs of our services, you wouldn’t have to pay
                  thousands of pounds. We will recover all charges from their
                  insurer.
                </p>

                <h5>Large repair network </h5>
                <p className="collision">
                  Rest assured, with us your vehicle will receive superior
                  repairs that exceed manufacturer specifications.{" "}
                </p>

                <h5>Dedicated support </h5>
                <p className="collision">
                  We render dedicated and reliable assistance by settling your
                  claims in a timely and professional manner.
                </p>
              </div>
            </div>
            <div className="col-xxl-4 col-xl-4 col-lg-6 col-md-12 col-12">
              <div className="Claimant">
                <h5>Secure vehicle storage </h5>
                <p className="collision">
                  Following a no-fault car accident, our car storage solutions
                  will keep your vehicle safe.
                </p>

                <h5>Customer-focused approach</h5>
                <p className="collision">
                  Get in touch with us on{" "}
                  <strong> Lloyds Bank insurance accident claim number </strong>{" "}
                  for free advice tailored to your case by experienced
                  solicitors specialising in road traffic accidents.
                </p>

                <h5>Timely claims processing </h5>
                <p className="collision">
                  Our team processes your{" "}
                  <strong> Lloyds Bank car insurance claim </strong>
                  expeditiously so as to ensure prompt closure. In addition, we
                  will keep you posted about how far it has reached in terms of
                  claiming while moving stepwise along with you.{" "}
                </p>
              </div>
            </div>
            <div className="col-xxl-4 col-xl-4 col-lg-12 col-md-12 col-12">
              <div className="Claimant">
                <h5>Recovering Uninsured Losses</h5>
                <p className="collision">
                  Did the accident cost you anything out of pocket? Start your
                  free case review now and we will help you recover all costs
                  from the other party’s insurer.
                </p>

                <h5>Effortless Car Replacement </h5>
                <p className="collision">
                  Our services offer a convenient replacement car to minimise
                  any inconvenience for you and get you back into your daily
                  routine.
                </p>

                <h5>End-to-End Claims Handling</h5>
                <p className="collision">
                  From organising vehicle recovery to handling your claim
                  end-to-end, we provide dedicated support at every stage of the
                  claims journey.
                </p>
              </div>
            </div>
          </div>
        </div>
      </search>

      <section className="Accident-claim">
        <div className="container">
          <div className="row">
            <div className="needed">
              <h2 className="trusted-title">
                How can you maximise the <br />
                <span className="light"> value of your claim?</span>
              </h2>
              <p className="complexities">
                Wondering how to reclaim all the money on{" "}
                <strong> Lloyds Bank car insurance claim? </strong> <br />
                By doing the right things after being involved in an automobile
                accident, there is an increase in the chances of getting full
                compensation for a{" "}
                <strong> Lloyds Bank car insurance claim. </strong>{" "}
              </p>
            </div>
            <hr />
          </div>
          <div className="row" id="incdes">
            <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-12">
              <div className="Preparing">
                <ul>
                  <li>
                    <div className="seemingly">
                      <h5 className="Seek">Car Accident Reporting:</h5>
                      <p>
                        {" "}
                        Inform police within 24 hours of an accident as well as
                        include a copy of such accident provided by the police
                        with diverse details concerning it.{" "}
                      </p>
                    </div>
                  </li>
                  <li>
                    <div className="seemingly">
                      <h5 className="Seek"> Get medical aid: </h5>
                      <p>
                        To have immediate medical attention from a professional
                        who will give timely treatment to your injuries is
                        important. Medical reports may be used by your attorney
                        as vital evidence that may help him or her secure fair
                        compensation for you.{" "}
                      </p>
                    </div>
                  </li>
                  <li>
                    <div className="seemingly">
                      <h5 className="Seek">Record all the financial losses:</h5>
                      <p>
                        For your full compensation of your losses, you need to
                        present receipts and record in a bookkeeping all
                        expenses incurred as a result of the accident.
                      </p>
                    </div>
                  </li>
                  <li>
                    <div className="seemingly">
                      <h5 className="Seek">Don’t take no for an answer</h5>
                      <p>
                        Before accepting the initial offer as a solution, have
                        it discussed with your solicitor. The first settlement
                        offer may not cover you fully for the damages and losses
                        you have encountered.
                      </p>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
            <div
              className="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-12"
              id="dualcardiv"
            >
              <img src={dualcar} alt="" className="dualcar" />
            </div>
            <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-12">
              <div className="Preparing">
                <ul>
                  <li>
                    <div className="seemingly">
                      <h5 className="Seek">Collect evidence:</h5>
                      <p>
                        The evidence you can collect from the accident scene
                        will include scenes of accident shots, eyewitness
                        statements taken while at the incident as well as photos
                        of damaged motor vehicles and injury marks/hurt.{" "}
                      </p>
                    </div>
                  </li>
                  <li>
                    <div className="seemingly">
                      <h5 className="Seek"> Exchange details</h5>
                      <p>
                        To ensure that there is smooth processing of your{" "}
                        <strong>Lloyds Bank car insurance claim</strong>, give
                        your name, contact details, vehicle particulars and
                        insurance details to the driver who hit your car.
                      </p>
                    </div>
                  </li>
                  <li>
                    <div className="seemingly">
                      <h5 className="Seek">Talk to a no-fault claim expert:</h5>
                      <p>
                        Contact us through our{" "}
                        <strong>
                          Lloyds Bank Insurance Claim phone number
                        </strong>{" "}
                        so that we can give you our personal injury experts’
                        advice before filing your claim.{" "}
                      </p>
                    </div>
                  </li>
                  <li>
                    <div className="seemingly">
                      <h5 className="Seek">File your claim immediately</h5>
                      <p>
                        Do not delay in filing your claim. Call us on the{" "}
                        <strong>
                          {" "}
                          Lloyds Bank insurance accident claim phone number{" "}
                        </strong>
                        to initiate the claims process right now.
                      </p>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="whyus">
        <div className="container">
          <div className="row">
            <div className="col-xxl-12 col-md-12">
              <div className="why">
                <h2 className="choose">
                  How to start your <span className="light">claim with us</span>
                </h2>
                <p className="complexities">
                  You can contact the When you choose to make a claim with us,
                  you do not have to endure complicated administrative
                  paperwork, endless follow-up calls or long waiting times. Dial{" "}
                  <a href="tel:0800 772 0850">0800 772 0850</a> to initiate your
                  accident claim.
                </p>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-xxl-3 col-xl-6 col-lg-6 col-md-6 col-12">
              <div className="box-service">
                <img src={check} className="check" alt="" />
                {/* <img src={experience} className="experience" alt="" /> */}
                <h4>Connect with us</h4>
                <p>
                  Call our
                  <strong>
                    {" "}
                    Lloyds Bank insurance accident claim helpline{" "}
                  </strong>
                  for free no-commitment advice.
                </p>
              </div>
            </div>
            <div className="col-xxl-3 col-xl-6 col-lg-6 col-md-6 col-12">
              <div className="box-service">
                <img src={check} className="check" alt="" />
                <h4>Give us accident details</h4>
                <p>
                  Tell us what led to the accident and give us details of when,
                  where and how it occurred.
                </p>
              </div>
            </div>
            <div className="col-xxl-3 col-xl-6 col-lg-6 col-md-6 col-12">
              <div className="box-service">
                <img src={check} className="check" alt="" />
                <h4>Share evidence</h4>
                <p>
                  To facilitate our processing process, hand over all relevant
                  documents and any supporting evidence that you may have
                  collected.{" "}
                </p>
              </div>
            </div>
            <div className="col-xxl-3 col-xl-6 col-lg-6 col-md-6 col-12">
              <div className="box-service">
                <img src={check} className="check" alt="" />
                <h4>Follow up</h4>
                <p>
                  After we have started the legal proceedings involved in your
                  case, we will keep you updated on its progress.{" "}
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="faqs">
        <div className="container">
          <div className="row">
            <div className="col-xxl-12 col-xl-12 col-md-12 col-12">
              <div className="fq">
                <h2>
                  {" "}
                  Frequently Asked{" "}
                  <span className="light">Questions (FAQ)</span>
                </h2>
                <p className="complexities">
                  Got questions about the{" "}
                  <strong> Lloyds Bank car insurance claim? </strong>We have
                  them answered here.
                </p>
              </div>
              <div className="allfaq">
                <Accordion defaultActiveKey="0">
                  <Accordion.Item eventKey="0">
                    <Accordion.Header>
                      Should I accept the offer from the insurance company?
                    </Accordion.Header>
                    <Accordion.Body>
                      Never accept an initial settlement without first
                      consulting with your solicitor. An initial offer may leave
                      out some of your injury-related expenses and insurance
                      companies are known for using lowball settlement offers so
                      as to expedite settlement. Consequently, before conceding
                      acceptance of such an offer, consult experienced
                      solicitors who will help determine a fair amount of
                      compensation.
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="1">
                    <Accordion.Header>
                      Do I have the right to receive a replacement vehicle after
                      my car has been involved in an accident?
                    </Accordion.Header>
                    <Accordion.Body>
                      Certainly, you are obliged to get a temporary substitute
                      car after having your car involved in an accident. Ring
                      our{" "}
                      <strong>
                        {" "}
                        Lloyds Bank insurance accident claim phone number{" "}
                      </strong>{" "}
                      if you want another vehicle of the same size and quality
                      as your own to be delivered before you begin driving
                      again.
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="2">
                    <Accordion.Header>
                      May I decide where my car will be repaired following an
                      accident?
                    </Accordion.Header>
                    <Accordion.Body>
                      Of course. As to repairing your vehicle, we give you the
                      freedom of choice. You can provide us with details of your
                      repair centre, and we will immediately make appointments
                      with the body shop selected by you.
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="3">
                    <Accordion.Header>
                      What is a way of guaranteeing that my car repairs will be
                      done properly?{" "}
                    </Accordion.Header>
                    <Accordion.Body>
                      It is recommended that if you want the best quality
                      service for your vehicle repairs, you should select from
                      our approved repair network. Our approved repairers
                      provide high-quality and guaranteed vehicle repairs using
                      genuine parts.
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="4">
                    <Accordion.Header>
                      How long can I keep the replacement car?
                    </Accordion.Header>
                    <Accordion.Body>
                      The duration of the replacement vehicle depends on how
                      soon your car’s problem will be fixed. You can keep it
                      until your car comes out from the workshop or until you
                      get a fair market value of the wrecked auto in case it was
                      written off totally.
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="5">
                    <Accordion.Header>
                      Do I have to pay for using your services on accident
                      management?{" "}
                    </Accordion.Header>
                    <Accordion.Body>
                      Certainly not. We don’t want to hurt you either
                      financially after the road accident. We’ll retrieve all
                      the expenses that revolve around our accident management
                      service from the other party’s insurance company.
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="6">
                    <Accordion.Header>
                      Will my insurance premiums go up if I file a no-fault car
                      claim today?{" "}
                    </Accordion.Header>
                    <Accordion.Body>
                      No, it will not. If the accident was not your mistake,
                      then your insurance premiums will be maintained at its
                      current level as we will file a no-fault car claim against
                      the person who caused the accident.
                    </Accordion.Body>
                  </Accordion.Item>
                </Accordion>
              </div>
            </div>
          </div>
        </div>
      </section>

      <Footer />
    </>
  );
};

export default Lloydsbank;
