import React, { useRef } from "react";
import insurancelogo from "../../src/assets/images/insurancelogo.png";
import Accordion from "react-bootstrap/Accordion";
import check from "../assets/images/check.png";
import dualcar from "../../src/assets/images/dualcar.webp";
import churchill from "../../src/assets/images/churchill.webp";
import Sidebarinner from "../components/Sidebar-inner";
import phonecall from "../assets/images/phonecall.png";
import { Helmet } from "react-helmet";
import Footer from "../components/footer";

const Churchill = () => {
  const sidebarRef = useRef(null);

  const handleScrollToSidebar = () => {
    if (sidebarRef.current) {
      sidebarRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Churchill Car Insurance Claims Number | Churchill Claims Number</title>
        <meta
          name="description"
          content="Contact us on the Churchill Car Insurance Accident Claims Contact Number 0800 772 0850 to receive reliable claims assistance from our dedicated team. Call us now. "
        />
        <link
          rel="canonical"
          href="https://insurance-accident-claims.co.uk/Churchill "
        />
      </Helmet>

      <section className="innernav">
        <div className="container">
          <div className="row">
            <div className="col-md-6 col-sm-6">
              <div className="left-logo">
                {/* <img src={insurancelogo} className='insurancelogo' alt='Insurance Logo' /> */}
                <h3>Accident Claims</h3>
              </div>
            </div>
            <div className="col-md-6 col-sm-6">
              <div className="left-cta">
                <div class="headercnt">
                  <div class="chatmsg">
                    <img
                      src={phonecall}
                      className="phonecall"
                      alt="phonecall "
                    />
                  </div>
                  <div class="box-body">
                    <h3 class="nav-info-box-title">Toll Free Number </h3>
                    <p>
                      <a href="tel:0800 772 0850">0800 772 0850</a>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section
        className="newbnr"
        style={{ backgroundImage: "url(" + churchill + ")" }}
      >
        <div className="container">
          <div className="row">
            <div className="col-xxl-6 col-xl-6 col-md-10 col-12">
              <div className="innerbnr">
                <h1>
                  <span className="onespan">Dedicated Support For </span>{" "}
                  <span className="sci">No-Fault</span> Car Accident  claims{" "}
                </h1>
                <p className="brokerage">
                  Looking for assistance after a no-fault car accident? We have
                  you covered with our best Churchill insurance accident support
                  services.
                </p>
                <div className="bnrbtn">
                  <a
                    class="db-btn-border btn-rollover phone"
                    href="tel:0800 772 0850"
                  >
                    Begin your claim <i class="fa-solid fa-arrow-right"></i>
                  </a>
                </div>
                <div className="bnrbtn">
                  <a
                    className="db-btn-border btn-rollover desktop"
                    onClick={handleScrollToSidebar}
                  >
                    Begin your claim <i className="fa-solid fa-arrow-right"></i>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="stickysection">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="group-4-8">
                <div className="cl8 tf-tab">
                  <div className="content-tab">
                    <div className="inner-content">
                      <h2>
                        Your one-stop destination for <br />
                        <span className="light">
                          complete peace of mind
                        </span>{" "}
                      </h2>
                      <p className="disciplines">
                        As one of the leading car accident management solutions
                        providers, we strive to minimize the complexities,
                        worries and hassles with our wide range of{" "}
                        <strong>Churchill insurance accident support</strong>
                        services. All you need to do is give us a call on{" "}
                        <strong>Churchill accident claims number</strong> and
                        we’ll get you back on the road swiftly and safely
                        without any upfront costs.
                      </p>
                      <hr />
                      <p className="bold-text">No hidden fee </p>
                      <p className="bottom-te">
                        When you reach out to us on{" "}
                        <strong>
                          {" "}
                          accident number{" "}
                          <a href="tel:0800 772 0850">0800 772 0850</a>
                        </strong>{" "}
                        to get <strong> insurance accident support</strong>, we
                        won’t charge a single penny from you. Therefore, you
                        don't have to stress about paying any unexpected or
                        hidden costs when you choose our{" "}
                        <strong>Churchill insurance support services. </strong>
                      </p>
                      <hr />
                      <p className="bold-text">No insurance excess</p>
                      <p className="bottom-te">
                        When you work with your insurer, you may have to pay
                        insurance excess. However, with us, you don't have to
                        make any insurance excess payments. So, you can have
                        complete peace of mind knowing that you’ll receive the
                        best services without any financial burden.
                      </p>
                      <hr />
                      <p className="bold-text">No claim bonus remains secure</p>
                      <p className="bottom-te">
                        When you reach out to us on{" "}
                        <strong>
                          {" "}
                          claims contact number{" "}
                          <a href="tel:0800 772 0850">0800 772 0850</a>
                        </strong>
                        , you can rest assured that your no-claims bonus won’t
                        get affected in any manner. Unlike your insurer, we will
                        make sure that all your accumulated discounts remain
                        protected.
                      </p>
                      <hr />
                      <p className="bold-text">Reliable vehicle repairs </p>
                      <p className="bottom-te">
                        We have partnered with a vast network of approved repair
                        networks that provide reliable repair services using
                        original parts. You can contact us on{" "}
                        <strong>
                          {" "}
                          car accident helpline{" "}
                          <a href="tel:0800 772 0850">0800 772 0850</a>
                        </strong>
                        to get high-quality repairs for your damaged vehicle.
                      </p>
                      <hr />
                      <p className="bold-text">Free replacement vehicle </p>
                      <p className="bottom-te">
                        Reach out to us on the{" "}
                        <strong>
                          {" "}
                          accident claim number{" "}
                          <a href="tel:0800 772 0850">0800 772 0850</a>
                        </strong>{" "}
                        to get a cost-free replacement vehicle to hit back the
                        road again. We will provide you with a courtesy car in
                        the minimum possible time.
                      </p>
                      <hr />
                      <p className="bold-text">
                        Dedicated and consistent support
                      </p>
                      <p className="bottom-te">
                        We have vast experience in managing no-fault car
                        accident claims in the UK. When you contact us on the{" "}
                        <strong>Churchill accident claims helpline</strong>, we
                        promise to provide you with the best assistance at every
                        step of the way.{" "}
                      </p>
                      <hr />
                      <p className="bold-text">Professional advice </p>
                      <p className="bottom-te">
                        We’re dedicated to providing you with the best legal
                        advice related to your claim. When you reach out to us
                        on{" "}
                        <strong>
                          the accident claims number{" "}
                          <a href="tel:0800 772 0850">0800 772 0850</a>
                        </strong>
                        , our panel of experienced solicitors will provide you
                        with the best advice to secure the highest compensation
                        for your <strong> insurance accident claim.</strong>
                      </p>
                      <hr />
                      <p className="bold-text">Complete transparency </p>
                      <p className="bottom-te">
                        We provide{" "}
                        <strong>
                          Churchill insurance accident support services
                        </strong>{" "}
                        with utmost transparency and honesty. We will keep you
                        informed at every step of the way about the progress of
                        your{" "}
                        <strong>Churchill insurance accident claim.</strong>{" "}
                      </p>
                      <hr />
                      <p className="bold-text">Quick claim settlement </p>
                      <p className="bottom-te">
                        We ensure to settle your claim in the minimum possible
                        time without any paperwork hassles. When you contact us
                        on the <strong>Churchill accident number</strong>, we
                        will put our best efforts into settling your claim
                        quickly.
                      </p>
                      <hr />
                      <p className="bold-text">No-fault claims specialist </p>
                      <p className="bottom-te">
                        We will assign you a dedicated claims handler who will
                        manage your claim effectively from beginning to end and
                        help you get the best outcome.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="cl4" ref={sidebarRef}>
                  <Sidebarinner />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="photographs">
        <div className="container">
          <div className="row">
            <h2 className="road">
              {" "}
              We offer end-to-end assistance after a <br />
              <span className="light">no-fault car accident </span>{" "}
            </h2>
            <div className="col-xxl-6 col-xl-6 col-md-12 col-12">
              <div className="fourbox">
                <ul>
                  <li>
                    <img src={check} className="check" alt="" />
                    When you get in touch with us via{" "}
                    <strong>
                      {" "}
                      accident number{" "}
                      <a href="tel:0800 772 0850">0800 772 0850</a>
                    </strong>{" "}
                    to get emergency support after the no-fault car accident, we
                    will offer you complete assistance so that you can hit the
                    road again in a minimum time.
                  </li>
                  <hr />
                  <li>
                    <img src={check} className="check" alt="" />
                    Our dedicated team will assess your{" "}
                    <strong>Churchill insurance accident claim</strong>. If
                    there are high chances of getting your losses recovered, we
                    will proceed further with your claim.
                  </li>
                  <hr />
                  <li>
                    <img src={check} className="check" alt="" />
                    Moreover, we will coordinate with you and provide you with a
                    like-for-like replacement vehicle to ensure your mobility
                    and help you carry on with your daily activities without any
                    hassles.
                  </li>
                  <hr />
                  <li>
                    <img src={check} className="check" alt="" />
                    With us, you can be confident that your damaged vehicle will
                    be taken care of. Our reliable and approved repair centers
                    provide exceptional repairs for damaged vehicles using
                    manufacturer-approved parts.
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-xxl-6 col-xl-6 col-md-12 col-12">
              <div className="fourbox">
                <ul>
                  <li>
                    <img src={check} className="check" alt="" />
                    On successful completion of your vehicle repairs, we will
                    evaluate the quality of repairs to ensure they are completed
                    to the highest standards.
                  </li>
                  <hr />
                  <li>
                    <img src={check} className="check" alt="" />
                    When you reach out to us on{" "}
                    <strong>
                      the accident number{" "}
                      <a href="tel:0800 772 0850">0800 772 0850</a>
                    </strong>{" "}
                    to begin your no-fault{" "}
                    <strong> insurance accident claim</strong>, we will collect
                    all the required documents and initiate your claim.
                  </li>
                  <hr />
                  <li>
                    <img src={check} className="check" alt="" />
                    In addition, we will liaise with all the parties involved in
                    the accident, helping you save valuable time and minimizing
                    your stress.
                  </li>
                  <hr />
                  <li>
                    <img src={check} className="check" alt="" />
                    Once your vehicle has been successfully repaired, our team
                    will get in touch with you to coordinate the return of your
                    vehicle.
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="carprocess">
        <div className="container ">
          <div className="row">
            <div className="col-xxl-12 col-xxl-12 col-md-12 col-12">
              <h2 className="trusted-title">
                Complete and comprehensive Churchill <br />
                <span className="light">insurance support services </span>{" "}
              </h2>
            </div>
          </div>
          <div className="row" id="possible">
            <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-12">
              <div className="claim">
                <ul>
                  <li>
                    <span className="sleej">Car recovery</span> <br />
                    When you contact us via the{" "}
                    <strong>
                      {" "}
                      car accident helpline{" "}
                      <a href="tel:0800 772 0850">0800 772 0850</a>
                    </strong>
                    , we will arrange for swift and secure recovery of your
                    damaged vehicle from the scene of the accident. Our approved
                    recovery agents will recover your vehicle from the accident
                    spot and secure it in our safe yards.
                  </li>
                  <li>
                    <span className="sleej">Accident repairs</span>
                    <br />
                    With a wide network of approved and reliable repair experts,
                    we will arrange quick, flexible and high-quality repairs to
                    your vehicle. When you contact us on{" "}
                    <strong>the Churchill car insurance claim number</strong>,
                    we will ensure successful repairs of your vehicle at your
                    nearest approved garage or at your preferred garage.
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-12">
              <div className="claim" id="blue">
                <ul>
                  <li>
                    <span className="sleej">Vehicle storage </span> <br />
                    After the car accident, it’s important to get your damaged
                    vehicle transported to a safe and secure place to avoid
                    further damage. You can call us on the{" "}
                    <strong>
                      {" "}
                      car accident helpline{" "}
                      <a href="tel:0800 772 0850">0800 772 0850</a>
                    </strong>{" "}
                    to arrange safe storage of vehicles in your secured storage
                    yards. Our recovery team will arrive at the accident scene
                    to collect your damaged vehicle from the scene and store it
                    in our yards until repairs.
                  </li>
                  <li>
                    <span className="sleej">Replacement vehicle</span> <br />
                    If your vehicle is unroadworthy, we can arrange a
                    like-for-like replacement vehicle for you that matches your
                    needs. You can reach out to us on{" "}
                    <strong>Churchill claims contact number </strong>to request
                    a courtesy car if your vehicle is in an undrivable state. We
                    will provide you with a credit hire car without any extra
                    expenses. You can keep it until your vehicle gets completely
                    repaired.
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-12">
              <div className="claim">
                <ul>
                  <li>
                    <span className="sleej">
                      Personal injury claim assistance{" "}
                    </span>
                    <br />
                    Our experienced solicitors possess years of experience in
                    getting maximum compensation for the victims of no-fault
                    accidents. So, when you contact us via{" "}
                    <strong>the Churchill car insurance claim number</strong>,
                    we will offer you complete assistance for your personal
                    injury claim and help you secure maximum compensation.
                  </li>
                  <li>
                    <span className="sleej">
                      Full-fledged claims management{" "}
                    </span>{" "}
                    <br />
                    Our solicitors are well-versed in handling all the paperwork
                    and complexities of the claim. From paperwork management to
                    entire claims assistance, we will handle each and every
                    aspect of your no-fault{" "}
                    <strong> insurance accident claim</strong>. Contact us via{" "}
                    <strong>
                      {" "}
                      accident number{" "}
                      <a href="tel:0800 772 0850">0800 772 0850</a>{" "}
                    </strong>
                    to start your claim with us.
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="yellowcta">
        <div className="container">
          <div className="row">
            <div className="cta2">
              <div className="col-xxl-8 col-xl-8 col-lg-8 col-12">
                <div className="legal">
                  <h3 className="experienced">
                    Have more questions?{" "}
                    <span className="light">Speak to our experts.</span>{" "}
                  </h3>
                  <p className="professionals">
                    We’re here to help you at every step of the claim process.
                    Get in touch with our experts through the{" "}
                    <strong>Churchill insurance accident claim number</strong>{" "}
                    to get professional advice for your claim.
                  </p>
                </div>
              </div>
              <div className="col-xxl-4 col-xl-4 col-lg-4 col-12">
                <div class="process">
                  <a class="tf-button style-1 phone" href="tel:0800 772 0850">
                    Get in touch<span class="icon-keyboard_arrow_right"></span>
                  </a>
                </div>
                <div class="process">
                  <a
                    className="tf-button style-1 desktop"
                    onClick={handleScrollToSidebar}
                  >
                    Get in touch
                    <span className="icon-keyboard_arrow_right"></span>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <search className="updatedform">
        <div className="container">
          <div className="row text-center">
            <div className="col-xxl-12 col-xl-12 col-md-12 col-12">
              <h2 className="whiteheading">
                {" "}
                Benefits of choosing us as your <br />
                <span className="light">accident management partner</span>
              </h2>
              <p className="sustained">
                Instead of reaching out to your insurer to settle your no-fault
                claim, reach out to us through{" "}
                <strong>
                  the claims contact number{" "}
                  <a href="tel:0800 772 0850">0800 772 0850</a>
                </strong>
                . We will offer you better solutions and benefits compared to
                your insurance company. Moreover, choosing us for{" "}
                <strong>Churchill insurance accident support services</strong>{" "}
                will unlock doors to efficient accident management without any
                financial burden.{" "}
              </p>
            </div>
          </div>
          <div className="row">
            <div className="col-xxl-4 col-xl-4 col-lg-6 col-md-12 col-12">
              <div className="Claimant">
                <h5>Cost savings </h5>
                <p className="collision">
                  Claims management expenses pile up due to downtime, resulting
                  in slow claim processing and reduced settlements. Our instant
                  support and efficient claims handling make your claim
                  settlement cost-effective.
                </p>

                <h5> 24/7 recovery and helpline</h5>
                <p className="collision">
                  Our dedicated team is available round the clock to offer you
                  immediate assistance when you’re involved in a no-fault car
                  accident. You can get in touch with us via the{" "}
                  <strong>
                    {" "}
                    accident claims helpline{" "}
                    <a href="tel:0800 772 0850">0800 772 0850</a>{" "}
                  </strong>
                  to report an accident and get the required help.
                </p>

                <h5>Comprehensive accident management solutions </h5>
                <p className="collision">
                  Right from vehicle repairs and storage to replacement vehicles
                  and full-fledged claims management, we offer start-to-end
                  solutions for your accident management needs.
                </p>
              </div>
            </div>
            <div className="col-xxl-4 col-xl-4 col-lg-6 col-md-12 col-12">
              <div className="Claimant">
                <h5>Expert solicitors panel </h5>
                <p className="collision">
                  Our solicitors possess years of experience in managing{" "}
                  <strong>Churchill insurance accident claims</strong>. They
                  will go the extra mile to get the best possible outcome for
                  your claim.
                </p>

                <h5>No obligation advice</h5>
                <p className="collision">
                  We offer no-obligation advice for no-fault claims, without any
                  pressure or commitment to move ahead with your claim with us.
                  You can call us at the{" "}
                  <strong>
                    {" "}
                    accident number{" "}
                    <a href="tel:0800 772 0850">0800 772 0850</a>
                  </strong>{" "}
                  to get free advice.
                </p>

                <h5>Efficient Churchill insurance accident claims handling</h5>
                <p className="collision">
                  Our no-fault claim experts will handle your{" "}
                  <strong>Churchill no-fault accident claim</strong> in the most
                  effective and efficient manner. With us, you’ll receive
                  consistent updates regarding the progress of your claim.
                </p>
              </div>
            </div>
            <div className="col-xxl-4 col-xl-4 col-lg-12 col-md-12 col-12">
              <div className="Claimant">
                <h5>Fast claim processing </h5>
                <p className="collision">
                  We work tirelessly to ensure that your no-fault{" "}
                  <strong>Churchill insurance accident claim</strong> is settled
                  without delay or disruption. Contact us via{" "}
                  <strong>the Churchill accident claim phone number</strong> for
                  hassle-free settlement of your claim.
                </p>

                <h5>Safe vehicle storage</h5>
                <p className="collision">
                  We offer safe and secure storage of your damaged vehicle in
                  our secured storage yards to ensure your vehicle's safety and
                  prevent further damage. You can get in touch with us on the{" "}
                  <strong>Churchill car insurance claim number</strong> to get
                  your vehicle stored in our well-maintained yards.
                </p>

                <h5>Highest standard repairs</h5>
                <p className="collision">
                  We’ve got your best interests at heart. Therefore, we provide
                  you with the best possible repairs from our extensive network
                  of approved repairers in the UK.
                </p>
              </div>
            </div>
          </div>
        </div>
      </search>

      <section className="Accident-claim">
        <div className="container">
          <div className="row">
            <div className="needed">
              <h2 className="trusted-title">
                How to win maximum compensation for your <br />
                <span className="light"> no-fault claim</span>
              </h2>
              <p className="complexities">
                Wondering how you can increase the chances of getting maximum
                compensation for your losses or injuries following a no-fault
                accident? Here are a few steps that you must follow after a
                no-fault car accident to maximize your compensation.
              </p>
            </div>
            <hr />
          </div>
          <div className="row" id="incdes">
            <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-12">
              <div className="Preparing">
                <ul>
                  <li>
                    <div className="seemingly">
                      <h5 className="Seek">Stay at the accident scene </h5>
                      <p>
                        {" "}
                        Fleeing from the accident scene after the car accident
                        could result in serious repercussions. So, spot a safe
                        place and stop your car.{" "}
                      </p>
                    </div>
                  </li>
                  <li>
                    <div className="seemingly">
                      <h5 className="Seek">Report car accident </h5>
                      <p>
                        Immediately after the car accident, report the{" "}
                        <strong> road traffic accident</strong> to the police as
                        soon as possible. Also, reach out to us via the{" "}
                        <strong>
                          {" "}
                          accident claims helpline{" "}
                          <a href="tel:0800 772 0850">0800 772 0850</a>
                        </strong>{" "}
                        to request <strong> insurance accident support</strong>{" "}
                        services.
                      </p>
                    </div>
                  </li>
                  <li>
                    <div className="seemingly">
                      <h5 className="Seek">
                        Collect every piece of evidence related to the car
                        accident
                      </h5>
                      <p>
                        Proving your innocence can be difficult if you don’t
                        have enough evidence. Therefore, collect all the
                        necessary evidence, such as photographs or videos of the
                        accident scene and injuries, eyewitness statements, CCTV
                        recordings and a copy of the police report.
                      </p>
                    </div>
                  </li>
                  <li>
                    <div className="seemingly">
                      <h5 className="Seek">Consult a doctor</h5>
                      <p>
                        Check yourself or any other passengers for injuries and
                        seek immediate help from a medical professional to get
                        your injuries treated.
                      </p>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
            <div
              className="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-12"
              id="dualcardiv"
            >
              <img src={dualcar} alt="" className="dualcar" />
            </div>
            <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-12">
              <div className="Preparing">
                <ul>
                  <li>
                    <div className="seemingly">
                      <h5 className="Seek">Assess your vehicle damages </h5>
                      <p>
                        Check your vehicle thoroughly to determine the extent of
                        damages, and reach out to us via the{" "}
                        <strong>
                          {" "}
                          car accident helpline{" "}
                          <a href="tel:0800 772 0850">0800 772 0850</a>{" "}
                        </strong>
                        to request your vehicle recovery to a safe place.
                      </p>
                    </div>
                  </li>
                  <li>
                    <div className="seemingly">
                      <h5 className="Seek">Record your losses </h5>
                      <p>
                        Keep a record of all the expenses and losses you have
                        suffered due to the accident, such as medical expenses,
                        cost of travelling, damages to your vehicle or any other
                        loss.
                      </p>
                    </div>
                  </li>
                  <li>
                    <div className="seemingly">
                      <h5 className="Seek">Avoid accepting the first offer </h5>
                      <p>
                        The insurance company may reach out to you for an early
                        settlement. Avoid accepting their offer because once you
                        accept it, you may lose your right to pursue more
                        compensation.
                      </p>
                    </div>
                  </li>
                  <li>
                    <div className="seemingly">
                      <h5 className="Seek">File your no-fault claim </h5>
                      <p>
                        Call us on the{" "}
                        <strong>Churchill car insurance claim number</strong> to
                        get the best advice and kickstart the process of your
                        no-fault{" "}
                        <strong>Churchill insurance accident claim</strong> with
                        us.{" "}
                      </p>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="whyus">
        <div className="container">
          <div className="row">
            <div className="col-xxl-12 col-md-12">
              <div className="why">
                <h2 className="choose">
                  Hassle-free{" "}
                  <span className="light">
                    <br /> insurance accident claim process{" "}
                  </span>
                </h2>
                <p className="complexities">
                  If you wish to make your{" "}
                  <strong> insurance accident claim</strong> process with us,
                  you can contact us via the{" "}
                  <strong>
                    {" "}
                    accident number{" "}
                    <a href="tel:0800 772 0850">0800 772 0850</a>
                  </strong>{" "}
                  to get started.
                </p>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-xxl-3 col-xl-6 col-lg-6 col-md-6 col-12">
              <div className="box-service">
                <img src={check} className="check" alt="" />
                <h4>Give us a call </h4>
                <p>
                  Call us at{" "}
                  <strong>the Churchill claims contact number</strong> to{" "}
                  <strong>report car accident</strong> and make your claim with
                  the <strong>Churchill claims department</strong>.
                </p>
              </div>
            </div>
            <div className="col-xxl-3 col-xl-6 col-lg-6 col-md-6 col-12">
              <div className="box-service">
                <img src={check} className="check" alt="" />
                <h4>Share accident details </h4>
                <p>
                  Talk to our experts and share all the accident-related
                  information with us, including the date, time, location,
                  reason behind the accident or any other information that will
                  strengthen your case.
                </p>
              </div>
            </div>
            <div className="col-xxl-3 col-xl-6 col-lg-6 col-md-6 col-12">
              <div className="box-service">
                <img src={check} className="check" alt="" />
                <h4>Handover evidence and documents </h4>
                <p>
                  Share all the essential evidence collected from the accident
                  scene, including videos and photographs, vehicle details,
                  witness statements, police reports etc.
                </p>
              </div>
            </div>
            <div className="col-xxl-3 col-xl-6 col-lg-6 col-md-6 col-12">
              <div className="box-service">
                <img src={check} className="check" alt="" />
                <h4>Get compensation</h4>
                <p>
                  We will initiate your claim after gathering all the necessary
                  documents and once your{" "}
                  <strong>Churchill insurance accident claim</strong> gets
                  processed, you’ll get rightful compensation for your losses.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="faqs">
        <div className="container">
          <div className="row">
            <div className="col-xxl-12 col-xl-12 col-md-12 col-12">
              <div className="fq">
                <h2>
                  {" "}
                  Frequently Asked{" "}
                  <span className="light">Questions (FAQ)</span>
                </h2>
                <p className="complexities">
                  Got more questions about our no-fault Churchill insurance
                  accident support services. We have them answered below.{" "}
                </p>
              </div>
              <div className="allfaq">
                <Accordion defaultActiveKey="0">
                  <Accordion.Item eventKey="0">
                    <Accordion.Header>
                      Will my no-claims bonus be affected if I choose you for
                      accident management services?
                    </Accordion.Header>
                    <Accordion.Body>
                      No. With us, your no-claim bonus won’t be affected as we
                      will collect all the expenses from the at-fault party’s
                      insurance company, thus helping you safeguard your earned
                      discounts.
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="1">
                    <Accordion.Header>
                      Why should I choose Insurance accident claims for my
                      accident management needs?
                    </Accordion.Header>
                    <Accordion.Body>
                      Dealing with car accidents alone can be a traumatic
                      experience. However, we’re here to save you from all the
                      stress and hassles involved after a no-fault accident.
                      Right from vehicle recovery to repairs, replacement and
                      claims management, we will handle every aspect without any
                      excess fees or hidden charges. You can contact us at{" "}
                      <strong>
                        {" "}
                        claims contact number{" "}
                        <a href="tel:0800 772 0850">0800 772 0850</a>{" "}
                      </strong>
                      to seek emergency assistance following a car accident.
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="2">
                    <Accordion.Header>
                      Do I have to pay any excess fee for accident management
                      assistance?
                    </Accordion.Header>
                    <Accordion.Body>
                      No, we won’t charge you a single penny as we will recover
                      all the costs from the faulty driver’s insurer.
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="3">
                    <Accordion.Header>
                      How much time will it take to recover my damaged vehicle
                      from the accident scene?
                    </Accordion.Header>
                    <Accordion.Body>
                      The vehicle recovery time depends on several factors, such
                      as the severity of the accident, location of the accident
                      or legal formalities that need to be completed to get your
                      vehicle recovered. We will put in our best efforts to get
                      your vehicle recovered in the minimum possible time.
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="4">
                    <Accordion.Header>
                      Can I choose my own repairer?
                    </Accordion.Header>
                    <Accordion.Body>
                      Yes. We offer you complete freedom to choose your own
                      repairer. You can let us know your preferred repairer, and
                      we will arrange your vehicle repairs from the garage of
                      your choice.
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="5">
                    <Accordion.Header>
                      Will the replacement vehicle you provide match my own
                      vehicle?
                    </Accordion.Header>
                    <Accordion.Body>
                      We ensure to provide you with a replacement car similar to
                      your own vehicle. While your vehicle is not roadworthy, we
                      offer comparable replacement until your vehicle gets
                      repaired.
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="6">
                    <Accordion.Header>
                      What losses and damages can I claim after a no-fault
                      accident?
                    </Accordion.Header>
                    <Accordion.Body>
                      You can claim compensation for all the damages and losses
                      you’ve suffered due to the no-fault accident, such as
                      medical expenses, travelling expenses, compensation for
                      physical, psychological and mental injuries, vehicle
                      repair or replacement costs, lost wages or any other
                      out-of-pocket expenses.
                    </Accordion.Body>
                  </Accordion.Item>
                </Accordion>
              </div>
            </div>
          </div>
        </div>
      </section>

      <Footer />
    </>
  );
};

export default Churchill;
