import React, { useRef } from "react";
import Accordion from "react-bootstrap/Accordion";
import check from "../assets/images/check.png";
import dualcar from "../../src/assets/images/dualcar.webp";
import carbnr1 from "../../src/assets/images/carbnr1.webp";
import Sidebarinner from "../components/Sidebar-inner";
import phonecall from "../assets/images/phonecall.png";
import Footer from "../components/footer";
import { Helmet } from "react-helmet";

const Easycarclub = () => {
    const sidebarRef = useRef(null);

    const handleScrollToSidebar = () => {
        if (sidebarRef.current) {
            sidebarRef.current.scrollIntoView({ behavior: "smooth" });
        }
    };
    return (
        <>
        <Helmet>
        <meta charSet="utf-8" />
        <title>EasyCar Club Car Insurance Accident Claim Phone Number</title>
        <meta
          name="description"
          content="Need assistance in initiating a no-fault accident claim? Call us on the EasyCar Club Car Insurance Accident Claim Phone Number 0800 772 0850 for quick support."
        />
        <link
          rel="canonical"
          href="https://insurance-accident-claims.co.uk/Easycar-club"
        />
      </Helmet>
            <section className="innernav">
                <div className="container">
                    <div className="row">
                        <div className="col-md-6 col-sm-6">
                            <div className="left-logo">
                                <h3>
                                    Accident<span className="sipo"> Claims</span>
                                </h3>
                            </div>
                        </div>
                        <div className="col-md-6 col-sm-6">
                            <div className="left-cta">
                                <div class="headercnt">
                                    <div class="chatmsg">
                                        <img
                                            src={phonecall}
                                            className="phonecall"
                                            alt="phonecall "
                                        />
                                    </div>
                                    <div class="box-body">
                                        <h3 class="nav-info-box-title">Toll Free Number </h3>
                                        <p>
                                            <a href="tel:0800 772 0850">0800 772 0850</a>
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section
                className="newbnr"
                style={{ backgroundImage: "url(" + carbnr1 + ")" }}
            >
                <div className="container">
                    <div className="row">
                        <div className="col-xxl-7 col-xl-7 col-md-10 col-12">
                            <div className="innerbnr">
                                <h1>
                                    <span className="onespan">End-to-end accident </span>{" "}
                                    <span className="sci">insurance   </span> claims 
                                </h1>
                                <p className="brokerage">
                                    From replacement vehicle provision, repairs arrangement and comprehensive claims assistance, we take care of everything to get you back on the road as soon as possible.
                                </p>
                                <div className="bnrbtn">
                                    <a class="db-btn-border btn-rollover phone" href="tel:0800 772 0850">
                                        Road Accident Claim Help?{" "}
                                        <i class="fa-solid fa-arrow-right"></i>
                                    </a>
                                </div>
                                <div className="bnrbtn">
                                    <a className="db-btn-border btn-rollover desktop" onClick={handleScrollToSidebar}>
                                        Road Accident Claim Help? <i className="fa-solid fa-arrow-right"></i>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="stickysection">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="group-4-8">
                                <div className="cl8 tf-tab">
                                    <div className="content-tab">
                                        <div className="inner-content">
                                            <h2>
                                                Start-to-finish claims support without any

                                                <span className="light"> excess payments </span>{" "}
                                            </h2>
                                            <p className="disciplines">
                                                Our role is to provide you with the necessary support and guidance in accident management after a non-fault car crash. Just dial the <strong> Easy Car Club motor claims number, </strong>  and we will be there for emergency assistance and claims handling without excess payment.
                                            </p>
                                            <hr />
                                            <p className="bold-text">No upfront costs</p>
                                            <p className="bottom-te">
                                                We will not ask you for any money upfront because we will recover all our expenses from the insurance company of the other party involved.                                            </p>
                                            <hr />
                                            <p className="bold-text">Protected no-claims bonus </p>
                                            <p className="bottom-te">
                                                Since we will not make a claim against your own policy, your discounts plus no-claims bonuses are safe.                                            </p>
                                            <hr />
                                            <p className="bold-text">
                                                Dedicated claims handler
                                            </p>
                                            <p className="bottom-te">
                                                If you would like to start your claim, please contact us through the <strong> Easy Car Club motor claims contact number. </strong>  We shall allocate you an experienced claims handler who can handle everything about your <strong> Easy Car Club motor claim </strong>  from the beginning till its closure.                                           </p>
                                            <hr />
                                            <p className="bold-text">Comparable replacement </p>
                                            <p className="bottom-te">
                                                During the repair period, we shall give you a similar kind of car as yours in terms of make, size or model type.                                           </p>
                                            <hr />
                                            <p className="bold-text">Manufacturer-sanctioned repairs </p>
                                            <p className="bottom-te">
                                                By our manufacturer-approved repairers, your car will be returned to its state before the accident, using authentic materials and paint.                                            </p>
                                            <hr />
                                            <p className="bold-text">Quick settlement of claims
                                            </p>
                                            <p className="bottom-te">
                                                Our fast-tracked and simplified claim process ensures prompt resolution of your <strong> Easy Car Club auto insurance claim </strong>  with no long waiting periods.                                             </p>
                                            <hr />
                                            <p className="bold-text">Legal aid</p>
                                            <p className="bottom-te">
                                                Upon calling us through our car insurance claim phone number, <strong>
                                            {" "}
                                            <a href="tel:0800 772 0850">0800 772 0850</a>{" "}
                                        </strong>, we connect you with solicitors who have enough experience to provide you with the best legal advice and assistance for your case.                                            </p>
                                            <hr />
                                            <p className="bold-text"> No-win no-fee arrangement  </p>
                                            <p className="bottom-te">
                                                We never ask you for any upfront charges if the outcome of your case is not in favour of you using our no-win, no-fee offer.                                          </p>
                                            <hr />
                                            <p className="bold-text">A wide-ranging network of repairs </p>
                                            <p className="bottom-te">
                                                In the UK, we have approved relationships with repairers so that we can effectively repair your vehicle to meet the required quality standards.                                           </p>
                                            <hr />

                                            <p className="bold-text">Administrative help </p>
                                            <p className="bottom-te">
                                                For a fair resolution, all paperwork will be handled by us on behalf of yours, including managing documents as well as legal proceedings.                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div className="cl4" ref={sidebarRef}>
                                    <Sidebarinner />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="photographs">
                <div className="container">
                    <div className="row">
                        <h2 className="road">
                            We will make sure your post-accident journey   {" "}
                            <span className="light">
                                is <br />
                                smooth and worry-free
                            </span>{" "}
                        </h2>
                        <div className="col-xxl-6 col-xl-6 col-md-12 col-12">
                            <div className="fourbox">
                                <ul>
                                    <li>
                                        <img src={check} className="check" alt="" />
                                        As part of our comprehensive initiatives for accidents from beginning to end, we provide extensive post-crash support. Please call the <strong> Easy Car Club car accident claim phone number </strong>  now.

                                    </li>
                                    <hr />
                                    <li>
                                        <img src={check} className="check" alt="" />
                                        Our main focus is on providing an identical alternative vehicle to keep you going through your daily chores.                                     </li>
                                    <hr />
                                    <li>
                                        <img src={check} className="check" alt="" />
                                        We will repair your car to the best possible condition within a short time period.                                  </li>
                                    <hr />
                                    <li>
                                        <img src={check} className="check" alt="" />
                                        We have solicitors who will assist you in claiming all losses and damages from an insurance company of the other party.                            </li>
                                </ul>
                            </div>
                        </div>
                        <div className="col-xxl-6 col-xl-6 col-md-12 col-12">
                            <div className="fourbox">
                                <ul>
                                    <li>
                                        <img src={check} className="check" alt="" />
                                        We will organise for an independent engineer to come and assess the extent of damage to your car.                                    </li>
                                    <hr />
                                    <li>
                                        <img src={check} className="check" alt="" />
                                        When you call us on the <strong>  Easy Car Club motor claim number, </strong> our experienced legal solicitors will make arrangements for professional legal assistance.                                     </li>
                                    <hr />
                                    <li>
                                        <img src={check} className="check" alt="" />
                                        We will also plan for the collection and delivery of your vehicle after it has been fixed.                                     </li>
                                    <hr />
                                    <li>
                                        <img src={check} className="check" alt="" />
                                        We shall be dealing with all parties involved in the accident to ensure quick settlement.                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="carprocess">
                <div className="container ">
                    <div className="row">
                        <div className="col-xxl-12 col-xxl-12 col-md-12 col-12">
                            <h2 className="trusted-title">
                                {" "}
                                Accident management services <br />
                                <span className="light"> we  provide</span>{" "}
                            </h2>
                        </div>
                    </div>
                    <div className="row" id="possible">
                        <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-12">
                            <div className="claim">
                                <ul>
                                    <li>
                                        <span className="sleej">Car recovery:</span>      In case of severe damage to your vehicle rendering it undriveable, kindly contact Easy Car  Club car insurance claim number immediately, and we will come for it promptly using our trained recovery personnel, who shall take it to a safe area as necessary.
                                    </li>
                                    <li>
                                        <span className="sleej">Approved repairs:</span>     Dial the Easy Car Club car accident phone number if you need repairing assistance after a no-fault car accident. We will ensure quality repairs using genuine parts and paint.
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-12">
                            <div className="claim" id="blue">
                                <ul>
                                    <li>
                                        <span className="sleej">Vehicle storage:</span>   Do you need to keep your car safe after an accident? Call us at the Easy Car Club motor claims contact number. We will retrieve and store your car in our well-guarded yards, where it can be free from any risk or damage.
                                    </li>
                                    <li>
                                        <span className="sleej">Replacement vehicle:</span> If you were not at fault, we are obliged by the law to provide you with another vehicle for use before yours gets repaired. You can reach us through the Easy Car Club car insurance claim phone number, and we will organise a replacement of a similar type of vehicle so that your normal routine will not be affected in any way.

                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-12">
                            <div className="claim">
                                <ul>
                                    <li>
                                        <span className="sleej">Personal injury assistance :</span>
                                        Our solicitors are going to offer you top-quality legal advice to guarantee you get the compensation you deserve for the injuries and all other damages suffered due to the accident.                                  </li>
                                    <li>
                                        <span className="sleej">Claims administration:</span> From submission of your claim until its final settlement, we take care of everything, including papers, documents and discussions, towards ensuring that your claim goes as smoothly as possible.

                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="yellowcta">
                <div className="container">
                    <div className="row">
                        <div className="cta2">
                            <div className="col-xxl-8 col-xl-8 col-lg-8 col-12">
                                <div className="legal">
                                    <h3 className="experienced">
                                        Got more questions? Talk to  {" "}
                                        <span className="light">our specialists
                                        </span>{" "}
                                    </h3>
                                    <p className="professionals">
                                        Get in touch with our no-fault claim experts via <strong> car insurance contact number </strong>
                                        {" "}
                                        <strong>{" "}
                                            <a className="cpt-no" href="tel:0800 772 0850">
                                                {" "}
                                                0800 772 0850{" "}
                                            </a>{" "}
                                        </strong>
                                        to get the best advice for your
                                        <strong> Easy Car Club car insurance claim.  </strong>
                                    </p>
                                </div>
                            </div>
                            <div className="col-xxl-4 col-xl-4 col-lg-4 col-12">
                                <div class="process">
                                    <a class="tf-button style-1 phone" href="tel:0800 772 0850">
                                        Get in touch<span class="icon-keyboard_arrow_right"></span>
                                    </a>
                                </div>
                                <div class="process">
                                    <a className="tf-button style-1 desktop" onClick={handleScrollToSidebar}>
                                        Get in touch<span className="icon-keyboard_arrow_right"></span>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <search className="updatedform">
                <div className="container">
                    <div className="row text-center">
                        <div className="col-xxl-12 col-xl-12 col-md-12 col-12">
                            <h2 className="whiteheading">
                                {" "}
                                What makes us different
                                <span className="light"> from others?
                                </span>
                            </h2>
                            <p className="sustained">
                                We are a group of professionals who specialise in getting accident victims back on their feet. Our services have been structured in such a way that it minimises stress, complications and inconveniences during the whole process of managing the <strong> Easy Car  Club motor claims </strong>  process. Get hold of us today via the<strong> Easy Car Club car accident claim phone number </strong> for quick assistance and expert advice.                          </p>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-xxl-4 col-xl-4 col-lg-6 col-md-12 col-12">
                            <div className="Claimant">
                                <h5>No claim excess</h5>
                                <p className="collision">
                                    With our independent accident management services, you will not be required to pay any money to cover the payments because we will present<strong> Easy Car Club car insurance claims </strong>  directly against the other party.

                                </p>

                                <h5>Stress-free vehicle repairs</h5>
                                <p className="collision">
                                    We would arrange for your car to get repaired by manufacturer-approved repairers using genuine parts and paintwork in minimum time.                                </p>

                                <h5>Same-day replacement car</h5>
                                <p className="collision">
                                    If it wasn’t your fault, we can arrange a different car for you which is brought to your doorstep or where the accident occurred on the same day.                              </p>
                            </div>
                        </div>
                        <div className="col-xxl-4 col-xl-4 col-lg-6 col-md-12 col-12">
                            <div className="Claimant">
                                <h5>Free advice without any obligation
                                </h5>
                                <p className="collision">
                                Just call us through <strong> Easy Car Club motor claim number, </strong>  and we shall instantly guide you and give you good advice in order to obtain the best result in relation to <strong>Easy Car Club car insurance claims. </strong>  </p>
                                <h5>Claims handling expertise</h5>
                                <p className="collision">
                                Our dedicated claims handler will effectively deal with your <strong> Easy Car Club car insurance claim, </strong>  working out appropriate strategies while engaging all parties involved and ensuring quick resolution.                                 </p>

                                <h5>Uninsured loss recovery</h5>
                                <p className="collision">
                                When starting a claim by calling us using the <strong> Easy Car Club auto insurance claim, </strong>  our solicitors will ensure that they recover all expenses incurred from an uninsured driver’s insurer.
                                </p>
                            </div>
                        </div>
                        <div className="col-xxl-4 col-xl-4 col-lg-12 col-md-12 col-12">
                            <div className="Claimant">
                                <h5>24/7 availability</h5>
                                <p className="collision">
                                We are here twenty-four hours a day to help you with your accident management needs. So, get in touch with us through the <strong> Easy Car Club car accident claim number </strong>  for emergency help after the accident                             </p>

                                <h5>A comprehensive range of services</h5>
                                <p className="collision">
                                We offer a wide array of services, including recovering vehicles, arranging repairs, providing replacement vehicles and managing <strong> Easy Car  Club car insurance claims.  </strong>                              </p>

                                <h5>Immediate recovery </h5>
                                <p className="collision">
                                These recovery agents speed up the vehicle's recovery from the scene of an incident and take it to a safer place so as not to cause further damage. 
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </search>

            <section className="Accident-claim">
                <div className="container">
                    <div className="row">
                        <div className="needed">
                            <h2 className="trusted-title">
                            How can you boost your <br />
                                <span className="light"> claim value?</span>
                            </h2>
                            <p className="complexities">
                            The amount of compensation you get after a no-fault car accident depends on various factors. Consequently, there are steps that ought to be taken after an un-faulted car accident 
                            in order to make sure that the settlement value of the <strong> Easy Car Club motor claim </strong>  is enhanced. 
                            </p>
                        </div>
                        <hr />
                    </div>
                    <div className="row" id="incdes">
                        <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-12">
                            <div className="Preparing">
                                <ul>
                                    <li>
                                        <div className="seemingly">
                                            <h5 className="Seek">Seek medical attention immediately :</h5>
                                            <p>
                                                {" "}
                                                Immediately seek medical attention from a licensed physician who will treat your wounds accordingly. The medical report indicates how severe your injuries are, thereby maximising your claims values.                                          </p>
                                        </div>
                                    </li>
                                    <li>
                                        <div className="seemingly">
                                            <h5 className="Seek">
                                            Document damages:{" "}
                                            </h5>
                                            <p>
                                            A record must be kept showing all costs incurred and losses suffered as a result of the incident for recompense purposes.                                          </p>
                                        </div>
                                    </li>
                                    <li>
                                        <div className="seemingly">
                                            <h5 className="Seek">Never ever agree to a swift settlement :</h5>
                                            <p>
                                            The insurance firm can give you a low offer in order to minimise payout. Before taking this kind of offer, consult your solicitor to ensure that it pays you back for all the loss and damage fairly.                                            </p>
                                        </div>
                                    </li>
                                    <li>
                                        <div className="seemingly">
                                            <h5 className="Seek">Don’t post anything on social media :</h5>
                                            <p>
                                            Avoid speaking or posting anything on social media, as it may reduce your settlement value. Stay off social media until<strong> Easy Car Club car insurance claims </strong>  are settled.                                        </p>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div
                            className="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-12"
                            id="dualcardiv"
                        >
                            <img src={dualcar} alt="" className="dualcar" />
                        </div>
                        <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-12">
                            <div className="Preparing">
                                <ul>
                                    <li>
                                        <div className="seemingly">
                                            <h5 className="Seek">Collect evidence :</h5>
                                            <p>
                                            Take important evidence from the scene of the accident, such as photos of the place, vehicles involved and injuries you sustained.                                             </p>
                                        </div>
                                    </li>
                                    <li>
                                        <div className="seemingly">
                                            <h5 className="Seek">Exchange information with the other party : </h5>
                                            <p>
                                            Swap details with the other driver involved in the accident, such as personal ones, licence information, and insurance details, to ensure your claim process flows smoothly.                                            </p>
                                        </div>
                                    </li>
                                    <li>
                                        <div className="seemingly">
                                            <h5 className="Seek">
                                            Speak to a no-fault solicitor :
                                            </h5>
                                            <p>
                                            Contact an experienced solicitor who can negotiate with the other side’s insurer and obtain equitable compensation for their client’s rights. Call us at the <strong> Easy Car Club car insurance claims number </strong>  for expert guidance on your own case.
                                            </p>
                                        </div>
                                    </li>
                                    <li>
                                        <div className="seemingly">
                                            <h5 className="Seek">
                                            Begin your claim :
                                            </h5>
                                            <p> When you have all the documents and evidence ready, contact us through the <strong> Easy Car Club car accident claim number </strong>  to register your claim.

                                            </p>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="whyus">
                <div className="container">
                    <div className="row">
                        <div className="col-xxl-12 col-md-12">
                            <div className="why">
                                <h2 className="choose">
                                How can you start your claim {" "}
                                    <span className="light">with us?</span>
                                </h2>
                                <p className="complexities">
                                If you think you are entitled to compensation for an accident that was not your fault, please get in touch with us on
                                <strong>
                                            {" "}
                                            <a href="tel:0800 772 0850">0800 772 0850</a>{" "}
                                        </strong>to make a claim. Our simplified claims procedure allows you to easily file your claim without any problems with paperwork.                                 </p>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-xxl-3 col-xl-6 col-lg-6 col-md-6 col-12">
                            <div className="box-service">
                                <img src={check} className="check" alt="" />
                                {/* <img src={experience} className="experience" alt="" /> */}
                                <h4>Contact us</h4>
                                <p>
                                To arrange a free initial consultation about your case, call our team on the <strong> Easy Car Club motor claims contact number.</strong> </p>
                            </div>
                        </div>
                        <div className="col-xxl-3 col-xl-6 col-lg-6 col-md-6 col-12">
                            <div className="box-service">
                                <img src={check} className="check" alt="" />
                                <h4>Provide information</h4>
                                <p>
                                Our team will ask for details of the accident and proof of it in order to build up a case against the other party.</p>
                            </div>
                        </div>
                        <div className="col-xxl-3 col-xl-6 col-lg-6 col-md-6 col-12">
                            <div className="box-service">
                                <img src={check} className="check" alt="" />
                                <h4>Start the claim</h4>
                                <p>
                                After having relevant documents and evidence in hand, our solicitors will initiate your claim. 
                                </p>
                            </div>
                        </div>
                        <div className="col-xxl-3 col-xl-6 col-lg-6 col-md-6 col-12">
                            <div className="box-service">
                                <img src={check} className="check" alt="" />
                                <h4>Claim settlement</h4>
                                <p>
                                Once the negotiation is done, you will have a fair settlement that will refund you all the money charges and losses suffered.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="faqs">
                <div className="container">
                    <div className="row">
                        <div className="col-xxl-12 col-xl-12 col-md-12 col-12">
                            <div className="fq">
                                <h2>
                                    {" "}
                                    Frequently Asked{" "}
                                    <span className="light">Questions</span>
                                </h2>
                                <p className="complexities">
                                    Got questions about the <strong> Easy Car Club car insurance claim?  </strong> We have them answered here.
                                </p>
                            </div>
                            <div className="allfaq">
                                <Accordion defaultActiveKey="0">
                                    <Accordion.Item eventKey="0">
                                        <Accordion.Header>
                                        What if my vehicle cannot move after being hit by another car?                                    </Accordion.Header>
                                        <Accordion.Body>
                                        In the situation where a no-fault car accident renders your vehicle immobilised, we shall recover it from where it has been crashed and offer another one to ensure that you remain mobile.                                       </Accordion.Body>
                                    </Accordion.Item>
                                    <Accordion.Item eventKey="1">
                                        <Accordion.Header>
                                        What shall become the fate of my car after a road accident?
                                        </Accordion.Header>
                                        <Accordion.Body>
                                        We will organise repairs for your car from a reputable UK body shop if it is repairable. If you want to use your own repairer, kindly call us at the<strong>Easy Car Club motor claims contact number. </strong>                                        </Accordion.Body>
                                    </Accordion.Item>
                                    <Accordion.Item eventKey="2">
                                        <Accordion.Header>
                                        For how long can I store my car after an accident?
                                        </Accordion.Header>
                                        <Accordion.Body>
                                        Your damaged vehicle will be kept by us until your car is repaired or the claim is resolved.                                    </Accordion.Body>
                                    </Accordion.Item>
                                    <Accordion.Item eventKey="3">
                                        <Accordion.Header>
                                        Will making a claim affect my insurance premium?
                                        </Accordion.Header>
                                        <Accordion.Body>
                                        No, it will not. There won’t be any effect on your insurance premium and no claims bonus as we are not making a claim against your own policy.                                  </Accordion.Body>
                                    </Accordion.Item>
                                    <Accordion.Item eventKey="4">
                                        <Accordion.Header>
                                        Can you organise repair work for me?

                                        </Accordion.Header>
                                        <Accordion.Body>
                                        Certainly! We can organise for manufacturer-approved repairers to fix your damaged vehicle using original spare parts and high-quality paint.                                       </Accordion.Body>
                                    </Accordion.Item>
                                    <Accordion.Item eventKey="5">
                                        <Accordion.Header>
                                        How soon before I can get back on the road after having a non-fault accident?{" "}
                                        </Accordion.Header>
                                        <Accordion.Body>
                                        We work hard to ensure that people in such situations are driving again promptly. When you reach us via the <strong> Easy Car Club car insurance claim phone number, </strong>  we will provide a replacement vehicle within 24 hours.                                         </Accordion.Body>
                                    </Accordion.Item>
                                    <Accordion.Item eventKey="6">
                                        <Accordion.Header>
                                        Will I be required to pay any excess on my insurance policy?{" "}
                                        </Accordion.Header>
                                        <Accordion.Body>
                                        No. After accepting your claim, there is no need to pay any insurance excess since we will not file the claim against your own insurance company. 
                                        </Accordion.Body>
                                    </Accordion.Item>
                                </Accordion>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <Footer />
        </>
    );
};

export default Easycarclub;
