import React, { useRef } from "react";
import Accordion from "react-bootstrap/Accordion";
import check from "../assets/images/check.png";
import dualcar from "../../src/assets/images/dualcar.webp";
import homebnr from "../../src/assets/images/homebnr.webp";
import Sidebarinner from "../components/Sidebar-inner";
import phonecall from "../assets/images/phonecall.png";
import Footer from "../components/footer";
import { Helmet } from "react-helmet";

const Hondacarinsurance = () => {
    const sidebarRef = useRef(null);

    const handleScrollToSidebar = () => {
        if (sidebarRef.current) {
            sidebarRef.current.scrollIntoView({ behavior: "smooth" });
        }
    };
    return (
        <>
        <Helmet>
        <meta charSet="utf-8" />
        <title> Honda Car Insurance Accident Claim Phone Number</title>
        <meta
          name="description"
          content="Looking for car insurance claim support? Contact us at the Honda Car Insurance Accident Claim Phone Number 0800 772 0850 for prompt and professional assistance. "
        />
        <link
          rel="canonical"
          href="https://insurance-accident-claims.co.uk/Honda-car-insurance"
        />
      </Helmet>
            <section className="innernav">
                <div className="container">
                    <div className="row">
                        <div className="col-md-6 col-sm-6">
                            <div className="left-logo">
                                <h3>
                                    Accident<span className="sipo"> Claims</span>
                                </h3>
                            </div>
                        </div>
                        <div className="col-md-6 col-sm-6">
                            <div className="left-cta">
                                <div class="headercnt">
                                    <div class="chatmsg">
                                        <img
                                            src={phonecall}
                                            className="phonecall"
                                            alt="phonecall "
                                        />
                                    </div>
                                    <div class="box-body">
                                        <h3 class="nav-info-box-title">Toll Free Number </h3>
                                        <p>
                                            <a href="tel:0800 772 0850">0800 772 0850</a>
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section
                className="newbnr"
                style={{ backgroundImage: "url(" + homebnr + ")" }}
            >
                <div className="container">
                    <div className="row">
                        <div className="col-xxl-7 col-xl-7 col-md-10 col-12">
                            <div className="innerbnr">
                                <h1>
                                    <span className="onespan">Quick and hassle-free no-fault </span>{" "}
                                    <span className="sci"> claims   </span> assistance
                                </h1>
                                <p className="brokerage">
                                    We shall take care of everything, from vehicle recovery to your claim initiation and its settlement

                                </p>
                                <div className="bnrbtn">
                                    <a class="db-btn-border btn-rollover phone" href="tel:0800 772 0850">
                                        Road Accident Claim Help?{" "}
                                        <i class="fa-solid fa-arrow-right"></i>
                                    </a>
                                </div>
                                <div className="bnrbtn">
                                    <a className="db-btn-border btn-rollover desktop" onClick={handleScrollToSidebar}>
                                        Road Accident Claim Help? <i className="fa-solid fa-arrow-right"></i>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="stickysection">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="group-4-8">
                                <div className="cl8 tf-tab">
                                    <div className="content-tab">
                                        <div className="inner-content">
                                            <h2>
                                                Dedicated insurance claims handlers

                                                <span className="light"> in the UK </span>{" "}
                                            </h2>
                                            <p className="disciplines">
                                                Among many others, we are one of the leading providers of <strong>  Honda insurance claims </strong> throughout the UK, with several thousand positive outcomes. You can contact us via the<strong> Honda car insurance contact number </strong>  if you need assistance in making a claim. At no cost, our team will handle your <strong> Honda car insurance claim. </strong>
                                            </p>
                                            <hr />
                                            <p className="bold-text">No additional charges or excess payments</p>
                                            <p className="bottom-te">  If you were not responsible for causing the accident, we won’t charge you any excess payments or upfront costs.                           </p>
                                            <hr />
                                            <p className="bold-text">Unaffected no claims bonus</p>
                                            <p className="bottom-te">
                                                Since we won’t make a claim against your own policy, you do not have to lose your no-claim bonus. Your no-claim bonus is safe when you make your claim with us.                                       </p>
                                            <hr />
                                            <p className="bold-text">
                                                Quick recovery
                                            </p>
                                            <p className="bottom-te">
                                                We won’t leave you stranded on the road after an accident. Call us on the <strong> Honda car insurance claim number </strong>  to recover your vehicle immediately from the scene of the accident.
                                            </p>
                                            <hr />
                                            <p className="bold-text">Manufacturer approved repairers</p>
                                            <p className="bottom-te">
                                                We will ensure that your vehicle is repaired to the highest standards at our approved repair centres.                                    </p>
                                            <hr />
                                            <p className="bold-text">Like-for-like replacement vehicle</p>
                                            <p className="bottom-te">
                                                If you have an accident or your car is unroadworthy, we can let you have another car of similar make and model as yours                                         </p>
                                            <hr />
                                            <p className="bold-text">Experienced solicitors</p>
                                            <p className="bottom-te">
                                                Our experienced solicitors understand how to deal with <strong> Honda insurance claims </strong> and have helped victims receive fair compensation for accidents that were not their fault.

                                            </p>
                                            <hr />
                                            <p className="bold-text">Swift settlement of claims</p>
                                            <p className="bottom-te">
                                                Do not fret about documentation when claiming from us. We have an efficient and streamlined claims process that involves minimum paperwork.                                    </p>
                                            <hr />
                                            <p className="bold-text">Free advice</p>
                                            <p className="bottom-te">
                                                If you have any questions on  <strong> Honda insurance claims,</strong>   call
                                                <strong>
                                                    {" "}
                                                    <a href="tel:0800 772 0850">0800 772 0850</a>{" "}
                                                </strong>. Our legal representatives will guide you through what to do next based on the accident circumstances.
                                            </p>
                                            <hr />
                                            <p className="bold-text">No win-no fee</p>
                                            <p className="bottom-te">
                                                Our solicitors operate under a no-win-no-fee policy in handling clients’ insurance claims. If you don't get a favourable outcome for your claim, there will be nothing for you to pay.                              </p>
                                            <hr />

                                            <p className="bold-text">24/7 support</p>
                                            <p className="bottom-te">
                                                If you’re involved in a no-fault car accident and you need someone to provide first aid, don’t hesitate to contact us. When involved in an accident, call our car insurance claim hotline on
                                                <strong>
                                                    {" "}
                                                    <a href="tel:0800 772 0850">0800 772 0850</a>{" "}
                                                </strong>  for immediate assistance.   </p>
                                        </div>
                                    </div>
                                </div>
                                <div className="cl4" ref={sidebarRef}>
                                    <Sidebarinner />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="photographs">
                <div className="container">
                    <div className="row">
                        <h2 className="road">
                            We are here to minimise the hassle with our   {" "}
                            <span className="light">

                                stress-free <br />  crash management support
                            </span>{" "}
                        </h2>
                        <div className="col-xxl-6 col-xl-6 col-md-12 col-12">
                            <div className="fourbox">
                                <ul>
                                    <li>
                                        <img src={check} className="check" alt="" />
                                        In case of an emergency after a car accident, you can reach us through the<strong> Honda car insurance contact number.</strong>

                                    </li>
                                    <hr />
                                    <li>
                                        <img src={check} className="check" alt="" />
                                        We will try and identify the responsible party who caused the accident so as to sue that person for your claims.                                </li>
                                    <hr />
                                    <li>
                                        <img src={check} className="check" alt="" />
                                        To assist you in claiming any financial loss, we will also help to file your paperwork and arrange documents for your <strong> Honda insurance claims. </strong>
                                    </li>
                                    <hr />
                                    <li>
                                        <img src={check} className="check" alt="" />
                                        Our team will take possession of your vehicle from the site of the collision and arrange for its repair with our approved repairers.                            </li>
                                </ul>
                            </div>
                        </div>
                        <div className="col-xxl-6 col-xl-6 col-md-12 col-12">
                            <div className="fourbox">
                                <ul>
                                    <li>
                                        <img src={check} className="check" alt="" />
                                        If it is established that reasonable economic repair cannot be achieved, then an independent engineer will be appointed to assess the damage incurred by your vehicle.                              </li>
                                    <hr />
                                    <li>
                                        <img src={check} className="check" alt="" />
                                        You will get a temporary replacement car while waiting for the settlement of your claim.                               </li>
                                    <hr />
                                    <li>
                                        <img src={check} className="check" alt="" />
                                        We shall keep you informed about how repairs are progressing by continuously monitoring your vehicle’s repairs.                                  </li>
                                    <hr />
                                    <li>
                                        <img src={check} className="check" alt="" />
                                        Call us on
                                        <strong>
                                            {" "}
                                            <a href="tel:0800 772 0850">0800 772 0850</a>{" "}
                                        </strong>  and we will show you the right way to make a successful <strong>Honda insurance claim </strong>
                                        for compensation for damages and losses.
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="carprocess">
                <div className="container ">
                    <div className="row">
                        <div className="col-xxl-12 col-xxl-12 col-md-12 col-12">
                            <h2 className="trusted-title">
                                {" "}
                                Our accident management <br />
                                <span className="light">  services</span>{" "}
                            </h2>
                        </div>
                    </div>
                    <div className="row" id="possible">
                        <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-12">
                            <div className="claim">
                                <ul>
                                    <li>
                                        <span className="sleej">Roadside Recovery:</span> If you want your car to be removed from an accident scene, please call us on the <strong> Honda car insurance contact number. </strong> Our recovery services are tailored to ensure that your vehicle is quickly taken away from the scene of the accident into safe storage yards.

                                    </li>
                                    <li>
                                        <span className="sleej">Approved Repairs:</span>For repair services on your car, get in touch with our team using the <strong> Honda insurance claim number. </strong> The work on your car will be done by trusted garages spread all over the UK at no extra cost.

                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-12">
                            <div className="claim" id="blue">
                                <ul>
                                    <li>
                                        <span className="sleej">Car Storage:</span> Worried about the safety of your car after a non-fault accident? Call us via the <strong>  Honda car insurance contact number. </strong> Our secured yard facilitates safe storage of your car while waiting for the assessment of the claim.
                                    </li>
                                    <li>
                                        <span className="sleej">Replacement vehicle :</span>We may give out a replacement vehicle if your car cannot be repaired. Dial our emergency hotline number,
                                        <strong>
                                            {" "}
                                            <a href="tel:0800 772 0850">0800 772 0850</a>{" "}
                                        </strong>
                                        , if you need a temporary substitute car similar to yours to continue your normal routine. You can keep the replacement car until your claim is settled.

                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-12">
                            <div className="claim">
                                <ul>
                                    <li>
                                        <span className="sleej">Personal injury support</span>
                                        Would you like to demand compensation for personal harm that you underwent during the accident? Call us on the <strong> Honda insurance claim number </strong> to get expert advice from our solicitors regarding claiming compensation for injuries sustained.
                                    </li>
                                    <li>
                                        <span className="sleej">Claim management:</span> From paperwork to dealing with insurers, car repairs and the provision of alternative vehicles, we will handle everything.
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="yellowcta">
                <div className="container">
                    <div className="row">
                        <div className="cta2">
                            <div className="col-xxl-8 col-xl-8 col-lg-8 col-12">
                                <div className="legal">
                                    <h3 className="experienced">
                                        Got more questions? Talk {" "}
                                        <span className="light"> to our specialists
                                        </span>{" "}
                                    </h3>
                                    <p className="professionals">
                                        Get in touch with our no-fault claim experts via
                                        {" "}
                                        <strong> car insurance contact number{" "}
                                            <a className="cpt-no" href="tel:0800 772 0850">
                                                {" "}
                                                0800 772 0850{" "}
                                            </a>{" "}
                                        </strong>
                                        to get the best advice for the
                                        <strong> Honda car insurance claim. </strong>
                                    </p>
                                </div>
                            </div>
                            <div className="col-xxl-4 col-xl-4 col-lg-4 col-12">
                                <div class="process">
                                    <a class="tf-button style-1 phone" href="tel:0800 772 0850">
                                        Get in touch<span class="icon-keyboard_arrow_right"></span>
                                    </a>
                                </div>
                                <div class="process">
                                    <a className="tf-button style-1 desktop" onClick={handleScrollToSidebar}>
                                        Get in touch<span className="icon-keyboard_arrow_right"></span>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <search className="updatedform">
                <div className="container">
                    <div className="row text-center">
                        <div className="col-xxl-12 col-xl-12 col-md-12 col-12">
                            <h2 className="whiteheading">
                                {" "}
                                What makes us the best choice for handling
                                <span className="light">   your accident management needs?
                                </span>
                            </h2>
                            <p className="sustained">
                                We shall take away all non-fault road accident-related stress from you. Here are some of the benefits you will experience when you choose us for your accident management needs.                   </p>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-xxl-4 col-xl-4 col-lg-6 col-md-12 col-12">
                            <div className="Claimant">
                                <h5> 24/7 vehicle recovery</h5>
                                <p className="collision">
                                    Are you in need of a company that offers vehicle recovery services? Call the<strong> Honda insurance claim number, </strong> and we'll get your vehicle recovered from the accident scene, irrespective of time.


                                </p>

                                <h5>Guaranteed repairs</h5>
                                <p className="collision">
                                    We have several garages that provide high-quality repairs as per the manufacturer’s specifications   </p>

                                <h5>No increase in premiums</h5>
                                <p className="collision">
                                    While making the <strong> Honda car insurance claim  </strong>with us, you won’t experience any increase in your premiums.

                                </p>
                            </div>
                        </div>
                        <div className="col-xxl-4 col-xl-4 col-lg-6 col-md-12 col-12">
                            <div className="Claimant">
                                <h5>Cost-free replacement vehicle
                                </h5>
                                <p className="collision">
                                    You will be provided with a similar replacement vehicle without making any prior payment to continue your routine activities.  </p>
                                <h5>Reliable partners </h5>
                                <p className="collision">
                                    Our objective has been to give quality service delivery to our customers. Therefore, we have established strong working relationships with accredited solicitors and garages throughout the UK.                      </p>

                                <h5>Quick claim settlement</h5>
                                <p className="collision">
                                    It is right to expect your<strong> Honda car insurance claim </strong>  to be fairly settled after our experienced solicitors have concluded their discussions with the insurer of the other party involved in the accident.
                                </p>
                            </div>
                        </div>
                        <div className="col-xxl-4 col-xl-4 col-lg-12 col-md-12 col-12">
                            <div className="Claimant">
                                <h5>Rapid assistance</h5>
                                <p className="collision">
                                    A rapid response in case of an emergency after a non-fault road traffic accident is guaranteed.                          </p>

                                <h5>Personalised advice</h5>
                                <p className="collision">
                                    We will assign you a claims handler who will deal with your case directly and keep you informed on its progress at each stage.                     </p>

                                <h5>No excess fee</h5>
                                <p className="collision">
                                    We shall not charge you any extra amount as all our expenses will be met by the insurer of the third party.                           </p>
                            </div>
                        </div>
                    </div>
                </div>
            </search>

            <section className="Accident-claim">
                <div className="container">
                    <div className="row">
                        <div className="needed">
                            <h2 className="trusted-title">
                                How can you improve the chances of securing <br />
                                <span className="light">  maximum compensation?</span>
                            </h2>
                            <p className="complexities">
                                To increase the chances of obtaining a large amount awarded for damages, you need to prove that you were not at fault by producing hard evidence. Here are some steps to follow after an accident if you want to get fair compensation.                      </p>
                        </div>
                        <hr />
                    </div>
                    <div className="row" id="incdes">
                        <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-12">
                            <div className="Preparing">
                                <ul>
                                    <li>
                                        <div className="seemingly">
                                            <h5 className="Seek">Make sure you’re safe :</h5>
                                            <p>
                                                {" "}
                                                Make sure that you stop and park in a safe place and check yourself and your passengers for visible or invisible injuries.                                      </p>
                                        </div>
                                    </li>
                                    <li>
                                        <div className="seemingly">
                                            <h5 className="Seek">
                                                Seek immediate medical attention.:{" "}
                                            </h5>
                                            <p>
                                                In case someone has sustained any injuries during a car crash, find a good doctor as soon as possible to have the injuries treated.                                        </p>
                                        </div>
                                    </li>
                                    <li>
                                        <div className="seemingly">
                                            <h5 className="Seek">Gather proofs:</h5>
                                            <p> The information required from an accident scene includes pictures of where the accident occurred, responsible parties, any damages caused, serious physical wounds which can be seen with the naked eye and statements from eyewitnesses.                               </p>
                                        </div>
                                    </li>
                                    <li>
                                        <div className="seemingly">
                                            <h5 className="Seek">Report the accident to the police:</h5>
                                            <p>
                                                Approach the police to report the accident and request for a copy of the police report that would support the <strong> Honda car insurance claim made by you.</strong>                                          </p>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div
                            className="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-12"
                            id="dualcardiv"
                        >
                            <img src={dualcar} alt="" className="dualcar" />
                        </div>
                        <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-12">
                            <div className="Preparing">
                                <ul>
                                    <li>
                                        <div className="seemingly">
                                            <h5 className="Seek">Exchange information:</h5>
                                            <p>
                                                Exchange details, including your name, contact number, vehicle details and insurance policy details, with the other driver involved in the accident.                         </p>
                                        </div>
                                    </li>
                                    <li>
                                        <div className="seemingly">
                                            <h5 className="Seek">Write eyewitnesses details : </h5>
                                            <p>
                                                Reach out to the eyewitnesses and record their details so that you can reach out to them later.                                           </p>
                                        </div>
                                    </li>
                                    <li>
                                        <div className="seemingly">
                                            <h5 className="Seek">
                                                Record all expenses  :
                                            </h5>
                                            <p>
                                                Hold onto your receipts for anything you spend, such as maintenance bills, hospitalisation costs or any other expense that needs to be paid back.
                                            </p>
                                        </div>
                                    </li>
                                    <li>
                                        <div className="seemingly">
                                            <h5 className="Seek">
                                                Talk to a no-fault solicitor:
                                            </h5>
                                            <p>
                                                Dial our advice line now on {" "}
                                                <strong>{" "}
                                                    <a className="cpt-no" href="tel:0800 772 0850">
                                                        {" "}
                                                        0800 772 0850 {" "}
                                                    </a>{" "}
                                                </strong> , and we will walk you through the steps of making a successful claim for compensation.

                                            </p>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="whyus">
                <div className="container">
                    <div className="row">
                        <div className="col-xxl-12 col-md-12">
                            <div className="why">
                                <h2 className="choose">
                                Start your claim with our simplified and {" "}
                                    <span className="light"> uncomplicated process </span>
                                </h2>
                                <p className="complexities">
                                Making a<strong> Honda car insurance claim </strong> after an accident that was not your fault can be daunting. But you can begin the claiming process easily via our simplified claims procedure without any hassle.                          </p>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-xxl-3 col-xl-6 col-lg-6 col-md-6 col-12">
                            <div className="box-service">
                                <img src={check} className="check" alt="" />
                                {/* <img src={experience} className="experience" alt="" /> */}
                                <h4>Contact us </h4>
                                <p>
                                The<strong> Honda car insurance contact number </strong>  is available for consultations regarding your claim. </p>
                            </div>
                        </div>
                        <div className="col-xxl-3 col-xl-6 col-lg-6 col-md-6 col-12">
                            <div className="box-service">
                                <img src={check} className="check" alt="" />
                                <h4>Provide details</h4>
                                <p>
                                Please also provide full information about the incident, including when it occurred, where it happened, and how it happened.</p>
                            </div>
                        </div>
                        <div className="col-xxl-3 col-xl-6 col-lg-6 col-md-6 col-12">
                            <div className="box-service">
                                <img src={check} className="check" alt="" />
                                <h4>Evidence submission</h4>
                                <p>
                                Submit images, videos, witness statements, police reports or even CCTV footage which will help you support your case.                  </p>
                            </div>
                        </div>
                        <div className="col-xxl-3 col-xl-6 col-lg-6 col-md-6 col-12">
                            <div className="box-service">
                                <img src={check} className="check" alt="" />
                                <h4>Claim initiation</h4>
                                <p>
                                Your claim will be initiated after valuation by our team. We will keep you informed about any developments in your claim. 

                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="faqs">
                <div className="container">
                    <div className="row">
                        <div className="col-xxl-12 col-xl-12 col-md-12 col-12">
                            <div className="fq">
                                <h2>
                                    {" "}
                                    Frequently Asked{" "}
                                    <span className="light">Questions</span>
                                </h2>
                                <p className="complexities">
                                    Got questions about the <strong>  Honda car insurance claim? </strong>  We have them answered here.

                                </p>
                            </div>
                            <div className="allfaq">
                                <Accordion defaultActiveKey="0">
                                    <Accordion.Item eventKey="0">
                                        <Accordion.Header>
                                        Do courtesy cars attract extra charges?                                   </Accordion.Header>
                                        <Accordion.Body>
                                        Unless you don’t cooperate with us, involve a solicitor without our consent or give wrong information about your case, all of the costs regarding the replacement of your vehicle will be taken care of by the insurer of the at-fault driver.                              </Accordion.Body>
                                    </Accordion.Item>
                                    <Accordion.Item eventKey="1">
                                        <Accordion.Header>
                                        How long does it take to settle my claim?
                                        </Accordion.Header>
                                        <Accordion.Body>
                                           We would do all within our capabilities to reach an agreement on your claim as quickly as possible, but there is no time frame as each case is unique. We will keep you updated after we initiate your claim process.
                                        </Accordion.Body>
                                    </Accordion.Item>
                                    <Accordion.Item eventKey="2">
                                        <Accordion.Header>
                                         Will the insurance company be the person responsible for costs related to the recovery of my vehicle?
                                        </Accordion.Header>
                                        <Accordion.Body>
                                        Yes, all towing costs are met by the at-fault party’s insurer. You must provide some evidence, like photographs taken at an accident scene or a police report that shows you have entitlements to compensation.          </Accordion.Body>
                                    </Accordion.Item>
                                    <Accordion.Item eventKey="3">
                                        <Accordion.Header>
                                        Can I get a credit hire car following a no-fault road accident?
                                        </Accordion.Header>
                                        <Accordion.Body>
                                        Yes. If you did not cause the car crash, we can offer you a credit hire. Call us on our <strong>Honda insurance claim number </strong>  when your car is written off or in need of repair, and we will give you another vehicle similar to your own car.
                                        </Accordion.Body>
                                    </Accordion.Item>
                                    <Accordion.Item eventKey="4">
                                        <Accordion.Header>
                                        Will the replacement vehicle be identical to my vehicle? 

                                        </Accordion.Header>
                                        <Accordion.Body>
                                        We aim to provide a like-for-like replacement vehicle that will suit your needs regardless of what make or model of car you drive. Call us on our <strong> Honda insurance  claim number   </strong>  so that we can supply an alternative set of wheels as soon as possible.                      </Accordion.Body>
                                    </Accordion.Item>
                                    <Accordion.Item eventKey="5">
                                        <Accordion.Header>
                                        Are the repairs guaranteed?{" "}
                                        </Accordion.Header>
                                        <Accordion.Body>
                                        Yes. Our manufacturer-approved repairers try to offer guaranteed high-quality repairs that are above the manufacturer's standards.                     </Accordion.Body>
                                    </Accordion.Item>
                                    <Accordion.Item eventKey="6">
                                        <Accordion.Header>
                                        Can I recover all costs incurred due to the accident?{" "}
                                        </Accordion.Header>
                                        <Accordion.Body>
                                        We may help you recover any expenses or losses arising from a car accident, including medical bills, transportation costs, rental fees for another car, repair services for cars, lost wages and salaries, payment for pain and suffering, out-of-pocket expenses like damage to property and others.

                                        </Accordion.Body>
                                    </Accordion.Item>
                                </Accordion>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <Footer />
        </>
    );
};

export default Hondacarinsurance;
