import React, { useRef } from "react";
import insurancelogo from "../../src/assets/images/insurancelogo.png";
import Accordion from "react-bootstrap/Accordion";
import check from "../assets/images/check.png";
import dualcar from "../../src/assets/images/dualcar.webp";
import carbnr2 from "../../src/assets/images/carbnr2.webp";
import Sidebarinner from "../components/Sidebar-inner";
import phonecall from "../assets/images/phonecall.png";
import { Helmet } from "react-helmet";
import Footer from "../components/footer";

const Brightsideinsurance = () => {
    const sidebarRef = useRef(null);

    const handleScrollToSidebar = () => {
        if (sidebarRef.current) {
            sidebarRef.current.scrollIntoView({ behavior: "smooth" });
        }
    };
    return (
        <>
            <Helmet>
                <meta charSet="utf-8" />
                <title>Brightside Car Insurance Accident Claim Phone Number</title>
                <meta
                    name="description"
                    content="Have you been involved in a no-fault accident? Call us on the Brightside Car Insurance accident claim phone number  0800 772 0850 for trusted claims assistance. "
                />
                <link
                    rel="canonical"
                    href="https://insurance-accident-claims.co.uk/Bright-side-insurance "
                />
            </Helmet>
            <section className="innernav">
                <div className="container">
                    <div className="row">
                        <div className="col-md-6 col-sm-6">
                            <div className="left-logo">
                                {/* <img src={insurancelogo} className='insurancelogo' alt='Insurance Logo' /> */}
                                <h3>Accident Claims</h3>
                            </div>
                        </div>
                        <div className="col-md-6 col-sm-6">
                            <div className="left-cta">
                                <div class="headercnt">
                                    <div class="chatmsg">
                                        <img
                                            src={phonecall}
                                            className="phonecall"
                                            alt="phonecall "
                                        />
                                    </div>
                                    <div class="box-body">
                                        <h3 class="nav-info-box-title">Toll Free Number </h3>
                                        <p>
                                            <a href="tel:0800 772 0850">0800 772 0850</a>
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section
                className="newbnr"
                style={{ backgroundImage: "url(" + carbnr2 + ")" }}
            >
                <div className="container">
                    <div className="row">
                        <div className="col-xxl-8 col-xl-8 col-md-10 col-12">
                            <div className="innerbnr">
                                <h1>
                                    <span className="onespan">Accident management and claims</span>{" "}
                                    <span className="sci">assistance </span> in the UK {" "}
                                </h1>
                                <p className="brokerage">
                                We are here to relieve you of the stress and anxiety that usually follows a no-fault road traffic accident by providing emergency assistance and claims support.
                                </p>
                                <div className="bnrbtn">
                                    <a class="db-btn-border btn-rollover phone" href="tel:0800 772 0850">
                                        Begin your claim {" "}
                                        <i class="fa-solid fa-arrow-right"></i>
                                    </a>
                                </div>
                                <div className="bnrbtn">
                                    <a className="db-btn-border btn-rollover desktop" onClick={handleScrollToSidebar}>
                                        Begin your claim  <i className="fa-solid fa-arrow-right"></i>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="stickysection">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="group-4-8">
                                <div className="cl8 tf-tab">
                                    <div className="content-tab">
                                        <div className="inner-content">
                                            <h2>
                                            Expert claim management with 
                                                <span className="light">  no excess to pay</span>{" "}
                                            </h2>
                                            <p className="disciplines">
                                            We specialise in assisting the injured parties in no-fault car accidents within the United Kingdom, with comprehensive services provided by our experts to get you on the road to recovery by expertly handling your <strong> Brightside car insurance claim.</strong>  Call us now for expert advice on your claim at no extra cost at  
                                            <strong>
                                            {" "}
                                            <a href="tel:0800 772 0850">0800 772 0850</a>{" "}
                                        </strong>         
                                            </p>
                                            <hr />
                                            <p className="bold-text">Zero Excess</p>
                                            <p className="bottom-te">
                                            It will be up to our team to help you recover all the costs associated with the accident from the responsible person’s insurer. That is why there won’t be any burden hanging over your head as far as excess payments go. </p>
                                            <hr />
                                            <p className="bold-text">No Loss of NCD</p>
                                            <p className="bottom-te">
                                            When filing a <strong> Brightside insurance claim, </strong>  it does not touch upon your own policy. So, your no-claims discount and bonus will remain unaltered.
                                            </p>
                                            <hr />
                                            <p className="bold-text">
                                            Unmatched expertise{" "}
                                            </p>
                                            <p className="bottom-te">
                                            We differ from other insurers because we focus on non-fault auto accident claims only. Our solicitors never stop fighting for justice when it comes to your case.
                                            </p>
                                            <hr />
                                            <p className="bold-text">Start-to-finish assistance</p>
                                            <p className="bottom-te">
                                            From handling the complications of your<strong> Brightside insurance claim </strong>to offering emergency help that can get you back on track, we’ve got you covered all along.
                                            </p>
                                            <hr />
                                            <p className="bold-text">No win, No fee services</p>
                                            <p className="bottom-te">
                                            If your <strong> Brightside car insurance claim </strong>  doesn’t yield any results, you will not be accountable for any legal fees.
                                                 
                                            </p>
                                            <hr />
                                            <p className="bold-text">Friendly and supportive team</p>
                                            <p className="bottom-te">
                                            On calling our insurance claim number   <strong>
                                            {" "}
                                            <a href="tel:0800 772 0850">0800 772 0850</a>{" "}
                                        </strong>  to make a <strong> Brightside car insurance claim,</strong> our friendly team will attend to you by guiding you accordingly through the process.
                                            </p>
                                            <hr />
                                            <p className="bold-text">Expert claim handler</p>
                                            <p className="bottom-te">
                                            We will provide an exclusively dedicated claims handler who will work hard towards an excellent claim resolution and obtain full compensation from the at-fault driver easily.
                                            </p>
                                            <hr />
                                            <p className="bold-text">
                                            Professional advice
                                            </p>
                                            <p className="bottom-te">
                                            Our company can assist you in making a <strong> Brightside car insurance claim </strong> by providing free no-obligation advice from experienced solicitors who can help inform you about your rights and options.
                                            </p>
                                            <hr />
                                            <p className="bold-text">Quality vehicle repairs</p>
                                            <p className="bottom-te">
                                            Our extensive network of affected manufacturers’ repairers specialises in delivering guaranteed repairs using original parts and paints. Call us now at the <strong> Brightside insurance claim phone number </strong>  to book quality repairs for your car.
                                            </p>
                                            <hr />

                                            <p className="bold-text">Like-for-like replacement vehicle</p>
                                            <p className="bottom-te">
                                            A like-for-like replacement vehicle will be given to you while your car is at the garage being fixed.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div className="cl4" ref={sidebarRef}>
                                    <Sidebarinner />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="photographs">
                <div className="container">
                    <div className="row">
                        <h2 className="road">
                        Let us handle the complexities of your  <br />
                            <span className="light">claim as you recover</span>{" "}
                        </h2>
                        <div className="col-xxl-6 col-xl-6 col-md-12 col-12">
                            <div className="fourbox">
                                <ul>
                                    <li>
                                        <img src={check} className="check" alt="" />
                                        No matter if it means assessing damages or dealing with insurers, we will see your claim through to completion from start to finish.
                                    </li>
                                    <hr />
                                    <li>
                                        <img src={check} className="check" alt="" />
                                        When calling the <strong> Brightside insurance claim number, </strong> our dedicated team understands what replacement car best suits you for quick recovery and resumption.
                                    </li>
                                    <hr />
                                    <li>
                                        <img src={check} className="check" alt="" />
                                        Through your preferred garages in the UK, we shall coordinate all repairs needed for your car.
                                    </li>
                                    <hr />
                                    <li>
                                        <img src={check} className="check" alt="" />
                                        We will facilitate an interface between several parties implicated in a crash so that there can be less burden on you among other important things.
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div className="col-xxl-6 col-xl-6 col-md-12 col-12">
                            <div className="fourbox">
                                <ul>
                                    <li>
                                        <img src={check} className="check" alt="" />
                                        We have a network of solicitors who are specialists in no-fault accidents. This assures that if you call us via <strong> Brightside car accident number </strong> to discuss your claim, you can expect proper advice towards the success of your case.
                                    </li>
                                    <hr />
                                    <li>
                                        <img src={check} className="check" alt="" />
                                        Additionally, we will arrange for a separate engineer to inspect your damaged vehicle. 
                                    </li>
                                    <hr />
                                    <li>
                                        <img src={check} className="check" alt="" />
                                        To have your damaged car retrieved from the accident scene and delivered after repair, simply ring the <strong> Brightside insurance claim number </strong>  today.
                                    </li>
                                    <hr />
                                    <li>
                                        <img src={check} className="check" alt="" />
                                        We will give you all the necessary assistance in recovering all your expenses incurred as a result of that accident from the other party’s insurer.
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="carprocess">
                <div className="container ">
                    <div className="row">
                        <div className="col-xxl-12 col-xxl-12 col-md-12 col-12">
                            <h2 className="trusted-title">
                                {" "}
                                Our broad range of accident   <br />
                                <span className="light">management services </span>{" "}
                            </h2>
                        </div>
                    </div>
                    <div className="row" id="possible">
                        <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-12">
                            <div className="claim">
                                <ul>
                                    <li>
                                        <span className="sleej">Vehicle recovery </span> <br />
                                        Has your vehicle been made immobile after an accident? Call us via the <strong> Brightside insurance claim phone number </strong> to facilitate quick recovery of your vehicle. Our recovery agents will recover your vehicle from the scene before taking it to a safe place.
                                    </li>
                                    <li>
                                        <span className="sleej">Authorised repairs</span>
                                        <br />
                                        If you want instant repairs done at an authorised repair shop of your choice, contact us through our <strong> Bright sidecar insurance claim number. </strong>  The repairs will be done by our manufacturer-approved repairers who will restore it back to its original pre-crash condition, while also raising its market value.
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-12">
                            <div className="claim" id="blue">
                                <ul>
                                    <li>
                                        <span className="sleej">Safe storage</span> <br />
                                        If you cannot move your car it is probably not safe to leave it in the street or outside your house. To relieve you of some of the stress we will store your vehicle in our yard to prevent further damage or decay. If your vehicle is damaged due to the accident, call us on the <strong> Brightside insurance claim phone number </strong> for safe storage of your vehicle post- accident.
                                    </li>
                                    <li>
                                        <span className="sleej">Replacement vehicle  </span> <br />
                                        We know how hard it can be to cope without a car at all. We have a dedicated team that will give you a car of the same kind when yours gets damaged. You can keep the replacement vehicle until your own vehicle has been repaired, or until you receive compensation for the <strong> Brightside car insurance claim.</strong>  The other party’s insurer shall recover all costs connected with the substitute vehicle.
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-12">
                            <div className="claim">
                                <ul>
                                    <li>
                                        <span className="sleej">Personal injury support </span>
                                        <br />
                                        Get expert advice and support whilst filing a claim against the defaulting party for compensation for personal injuries and losses suffered. Our solicitors will diligently handle 
                                        your <strong> Brightside insurance claim </strong> so as to get the best outcome based on the gravity of the injuries you’ve sustained. 
                                    </li>
                                    <li>
                                        <span className="sleej">
                                            Claims management {" "}
                                        </span>{" "}
                                        <br />
                                        We offer comprehensive end-to-end accident management services, which include documentation handling, claim evaluation and final settlement. 
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="yellowcta">
                <div className="container">
                    <div className="row">
                        <div className="cta2">
                            <div className="col-xxl-8 col-xl-8 col-lg-8 col-12">
                                <div className="legal">
                                    <h3 className="experienced">
                                        Got more questions? Talk to
                                        <span className="light">
                                            our specialists {" "}
                                        </span>{" "}
                                    </h3>
                                    <p className="professionals">
                                        Get in touch with our no-fault claim experts via{" "}
                                        <strong>car insurance claim number </strong>
                                        <strong>
                                            {" "}
                                            <a href="tel:0800 772 0850">0800 772 0850</a>{" "}
                                        </strong>  to get the best advice for your <strong> Brightside car insurance claim</strong>


                                    </p>
                                </div>
                            </div>
                            <div className="col-xxl-4 col-xl-4 col-lg-4 col-12">
                                <div class="process">
                                    <a class="tf-button style-1 phone" href="tel:0800 772 0850">
                                        Get in touch<span class="icon-keyboard_arrow_right"></span>
                                    </a>
                                </div>
                                <div class="process">
                                    <a className="tf-button style-1 desktop" onClick={handleScrollToSidebar}>
                                        Get in touch<span className="icon-keyboard_arrow_right"></span>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <search className="updatedform">
                <div className="container">
                    <div className="row text-center">
                        <div className="col-xxl-12 col-xl-12 col-md-12 col-12">
                            <h2 className="whiteheading">
                                {" "}
                                What is unique about  <span className="light">us?</span>
                            </h2>
                            <p className="sustained">
                            When it comes to accident management solutions and claims assistance in case of no-fault car accidents that have been caused by another party in the UK, we are your dedicated and trusted partner. From vehicle recovery support to replacement vehicles or legal assistance for understanding your rights and alternatives, a solution that suits your requirements best will be offered by us.
                            </p>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-xxl-4 col-xl-4 col-lg-6 col-md-12 col-12">
                            <div className="Claimant">
                                <h5>No upfront costs</h5>
                                <p className="collision">
                                We assist without upfront costs. You can get emergency support without any financial burden. 
                                </p>

                                <h5>Prompt response</h5>
                                <p className="collision">
                                Our team is always available to help you on time with all your accident management issues.
                                </p>

                                <h5>Approved repairers</h5>
                                <p className="collision">
                                We proactively work with manufacturer-approved repairers in the UK to ensure your car is fixed using genuine parts and paint by professionals who follow manufacturer specifications.
                                </p>
                            </div>
                        </div>
                        <div className="col-xxl-4 col-xl-4 col-lg-6 col-md-12 col-12">
                            <div className="Claimant">
                                <h5>Free courtesy vehicle available within 24 hours</h5>
                                <p className="collision">
                                Contact us via our <strong> Brightside car insurance claim number </strong> and we will arrange a car for you immediately to minimise disruptions to your daily life.

                                </p>

                                <h5>Quicker settling of claims</h5>
                                <p className="collision">
                                Our process is designed to make things easier for our claimants as regards settling their <strong> Brightside insurance claim. </strong>  This allows your case to be resolved promptly.
                                </p>

                                <h5>Roadside assistance 24 hours a day, 7 days a week</h5>
                                <p className="collision">
                                Regardless of the time, our recovery agents are always at hand to assist you with your vehicle recovery needs
                                </p>
                            </div>
                        </div>
                        <div className="col-xxl-4 col-xl-4 col-lg-12 col-md-12 col-12">
                            <div className="Claimant">
                                <h5>No-obligation advice</h5>
                                <p className="collision">
                                Dial the <strong> Brightside insurance claims number </strong> for free no-obligation advice that will help you understand what your rights are and how best to proceed with your case.
                                </p>

                                <h5>Expert solicitors</h5>
                                <p className="collision">
                                Our experts have a track record of handling no-fault accident claims. Therefore, you’ll get guidance and support in the best way possible.
                                </p>

                                <h5>Tailored advice</h5>
                                <p className="collision">
                                Once we have looked at the specifics of your case, our solicitors will offer personalised advice based on what is likely to bring about a positive result in relation to your <strong> Brightside car insurance claim.</strong> 
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </search>

            <section className="Accident-claim">
                <div className="container">
                    <div className="row">
                        <div className="needed">
                            <h2 className="trusted-title">
                            What steps can you take to maximise the compensation
                                <br />
                                <span className="light">you receive for your Brightside insurance claim?
                                </span>
                            </h2>
                            <p className="complexities">
                            A road accident can severely disrupt a person’s life and cause massive depression. In order to begin recovering from an accident, one has to get reimbursement for damages and injuries. Nevertheless, there are procedures that must be followed in order to increase the settlement value and get more than enough payment for it.
                            </p>
                        </div>
                        <hr />
                    </div>
                    <div className="row" id="incdes">
                        <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-12">
                            <div className="Preparing">
                                <ul>
                                    <li>
                                        <div className="seemingly">
                                            <h5 className="Seek">
                                            Seek medical care {" "}
                                            </h5>
                                            <p>
                                                {" "}
                                                Make sure that your injuries are diagnosed and treated immediately. The records will also act as strong proof of your case when making a <strong> Brightside insurance claim. </strong> {" "}
                                            </p>
                                        </div>
                                    </li>
                                    <li>
                                        <div className="seemingly">
                                            <h5 className="Seek">Calculate damages </h5>
                                            <p>
                                            Preserve all receipts related to this claim such as medical bills, prescription costs, transportation expenses, legal fees or any other cost you have incurred. 
                                            </p>
                                        </div>
                                    </li>
                                    <li>
                                        <div className="seemingly">
                                            <h5 className="Seek">Gather evidence</h5>
                                            <p>
                                            Take pictures together with videos at the scene of the incident, gather eyewitness statements, try to access the CCTV camera videos as well acquire a copy of the police report so that evidence becomes irrefutable. 
                                            </p>
                                        </div>
                                    </li>
                                    <li>
                                        <div className="seemingly">
                                            <h5 className="Seek">Never hurry for quick settlements</h5>
                                            <p>
                                            Do not rush into accepting the initial offer for settlement but instead talk about it with your solicitor so that it adequately compensates you for the damage caused by the accident.
                                            </p>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div
                            className="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-12"
                            id="dualcardiv"
                        >
                            <img src={dualcar} alt="" className="dualcar" />
                        </div>
                        <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-12">
                            <div className="Preparing">
                                <ul>
                                    <li>
                                        <div className="seemingly">
                                            <h5 className="Seek">Avoid social media </h5>
                                            <p>
                                            Until the settlement of your <strong> Brightside car insurance claim, </strong>  it’s better not to use social networking sites. Anything you post on social media can be used by the other party to reduce the amount of compensation.
                                            </p>
                                        </div>
                                    </li>
                                    <li>
                                        <div className="seemingly">
                                            <h5 className="Seek">Swap information</h5>
                                            <p>
                                            Exchange details with other drivers involved in the accident, including name, phone number, vehicle registration numbers as well as insurance policy details.
                                            </p>
                                        </div>
                                    </li>
                                    <li>
                                        <div className="seemingly">
                                            <h5 className="Seek">Get a no-fault claims solicitor</h5>
                                            <p>
                                            For advice and guidance on no-fault claims from an experienced expert, call the <strong> Brightside car insurance claims phone number.</strong> 

                                            </p>
                                        </div>
                                    </li>
                                    <li>
                                        <div className="seemingly">
                                            <h5 className="Seek">Make a claim</h5>
                                            <p>
                                            When you are through with gathering evidence and essential information, please contact us using the <strong> Brightside insurance claims number </strong>  to begin your claim.
                                            </p>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="whyus">
                <div className="container">
                    <div className="row">
                        <div className="col-xxl-12 col-md-12">
                            <div className="why">
                                <h2 className="choose">
                                Our seamless and straightforward{" "}
                                    <span className="light">
                                        <br />  claim process {" "}
                                    </span>
                                </h2>
                                <p className="complexities">
                                Making a claim for your car insurance with us will exempt you from long waiting lines and complicated procedures. You can make your claim swiftly and smoothly in just 4 steps. 
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-xxl-3 col-xl-6 col-lg-6 col-md-6 col-12">
                            <div className="box-service">
                                <img src={check} className="check" alt="" />
                                <h4>Call us today</h4>
                                <p>
                                Call us on  <strong> car insurance claim number  </strong>
                                <strong>
                                            {" "}
                                            <a href="tel:0800 772 0850">0800 772 0850</a>{" "}
                                        </strong>  to get a free consultation appointment with our experienced panel of solicitors.
    
                                </p>
                            </div>
                        </div>
                        <div className="col-xxl-3 col-xl-6 col-lg-6 col-md-6 col-12">
                            <div className="box-service">
                                <img src={check} className="check" alt="" />
                                <h4>Share details about the accident.</h4>
                                <p>
                                Share with us all details from the time of occurrence of the accident to where it occurred and what led to the accident.
                                </p>
                            </div>
                        </div>
                        <div className="col-xxl-3 col-xl-6 col-lg-6 col-md-6 col-12">
                            <div className="box-service">
                                <img src={check} className="check" alt="" />
                                <h4>Submit evidence  </h4>
                                <p>
                                Give us all evidence you have collected like photographs, a copy of the police report, videos or any other important information from the scene that could solidify your <strong> Brightside insurance claim. </strong> 
                                </p>
                            </div>
                        </div>
                        <div className="col-xxl-3 col-xl-6 col-lg-6 col-md-6 col-12">
                            <div className="box-service">
                                <img src={check} className="check" alt="" />
                                <h4>Claim submission</h4>
                                <p>
                                Your <strong> Brightside car insurance claim </strong>  will be submitted by us once we are ready with all supporting evidence and documentation.

                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="faqs">
                <div className="container">
                    <div className="row">
                        <div className="col-xxl-12 col-xl-12 col-md-12 col-12">
                            <div className="fq">
                                <h2>
                                    {" "}
                                    Frequently Asked{" "}
                                    <span className="light">Questions  </span>
                                </h2>
                                <p className="complexities">
                                Got questions about the <strong> Brightside car insurance claim? </strong>We have them answered here. 
                                </p>
                            </div>
                            <div className="allfaq">
                                <Accordion defaultActiveKey="0">
                                    <Accordion.Item eventKey="0">
                                        <Accordion.Header>
                                        Do I need to pay any excess?
                                        </Accordion.Header>
                                        <Accordion.Body>
                                        No. There is no service excess because we are going to recover everything from the other party’s insurer.
                                        </Accordion.Body>
                                    </Accordion.Item>
                                    <Accordion.Item eventKey="1">
                                        <Accordion.Header>
                                        Can I choose my own repairer?
                                        </Accordion.Header>
                                        <Accordion.Body>
                                      Surely yes. You can have your own repairer for your vehicle repairs. You can share the details of your preferred repair shop with us and your car repairs will be scheduled at your preferred repair centre.
                                        </Accordion.Body>
                                    </Accordion.Item>
                                    <Accordion.Item eventKey="2">
                                        <Accordion.Header>
                                        If I use your services, will my no-claims bonus be affected?
                                        </Accordion.Header>
                                        <Accordion.Body>
                                        No. Our experienced claim handlers will make the claim directly against the third party without affecting your no-claims bonus or insurance premiums.
                                        </Accordion.Body>
                                    </Accordion.Item>
                                    <Accordion.Item eventKey="3">
                                        <Accordion.Header>
                                        How long am I entitled to keep the replacement car?
                                        </Accordion.Header>
                                        <Accordion.Body>
                                        If your car gets damaged as a result of an accident and is being repaired, you can keep the replacement vehicle until it is fixed.
                                        </Accordion.Body>
                                    </Accordion.Item>
                                    <Accordion.Item eventKey="4">
                                        <Accordion.Header>
                                        Will I get a like-for-like replacement car?
                                        </Accordion.Header>
                                        <Accordion.Body>
                                        We always try to offer like-for-like substitute cars regardless of the type of vehicle you drive. We will give you an equivalent substitute vehicle when you contact us at the <strong>Brightside car insurance claim number.</strong> 
                                        </Accordion.Body>
                                    </Accordion.Item>
                                    <Accordion.Item eventKey="5">
                                        <Accordion.Header>
                                        Are there any guarantees on repairs?
                                        </Accordion.Header>
                                        <Accordion.Body>
                                        Definitely. The repairs rendered by our approved repairers are under guarantee which shall endure as long as you possess that vehicle.
                                        </Accordion.Body>
                                    </Accordion.Item>
                                    <Accordion.Item eventKey="6">
                                        <Accordion.Header>
                                        Can you help me recover all the losses I’ve suffered due to the accident?
                                        </Accordion.Header>
                                        <Accordion.Body>
                                        We can help you recover all expenses incurred due to the accident, including medical bills, transportation costs, costs of vehicle repairs and replacement vehicle, lost wages, compensation for physical pain and suffering and mental anguish or any property damages or other out-of-pocket expenses. 
                                        </Accordion.Body>
                                    </Accordion.Item>
                                </Accordion>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <Footer />
        </>
    );
};

export default Brightsideinsurance;
