import React, { useRef } from "react";
import Accordion from "react-bootstrap/Accordion";
import check from "../assets/images/check.png";
import dualcar from "../../src/assets/images/dualcar.webp";
import carbnr1 from "../../src/assets/images/carbnr1.webp";
import Sidebarinner from "../components/Sidebar-inner";
import phonecall from "../assets/images/phonecall.png";
import Footer from "../components/footer";
import { Helmet } from "react-helmet";

const Ikubeinsurance = () => {
  const sidebarRef = useRef(null);

  const handleScrollToSidebar = () => {
    if (sidebarRef.current) {
      sidebarRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };
  return (
    <>
    <Helmet>
        <meta charSet="utf-8" />
        <title>IKube Car Insurance Accident Claim Phone Number</title>
        <meta
          name="description"
          content="Contact our proficient team via the IKube Car Insurance Accident Claim Phone Number 0800 772 0850 for expert claim assistance and quick roadside support after a car accident. "
        />
        <link
          rel="canonical"
          href="https://insurance-accident-claims.co.uk/ikube-insurance"
        />
      </Helmet>
      <section className="innernav">
        <div className="container">
          <div className="row">
            <div className="col-md-6 col-sm-6">
              <div className="left-logo">
                <h3>
                  Accident<span className="sipo"> Claims</span>
                </h3>
              </div>
            </div>
            <div className="col-md-6 col-sm-6">
              <div className="left-cta">
                <div class="headercnt">
                  <div class="chatmsg">
                    <img
                      src={phonecall}
                      className="phonecall"
                      alt="phonecall "
                    />
                  </div>
                  <div class="box-body">
                    <h3 class="nav-info-box-title">Toll Free Number </h3>
                    <p>
                      <a href="tel:0800 772 0850">0800 772 0850</a>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section
        className="newbnr"
        style={{ backgroundImage: "url(" + carbnr1 + ")" }}
      >
        <div className="container">
          <div className="row">
            <div className="col-xxl-8 col-xl-8 col-md-10 col-12">
              <div className="innerbnr">
                <h1>
                  <span className="onespan">No-fault</span>{" "}
                  <span className="sci"> accident and Claims </span>  management in the UK.
                </h1>
                <p className="brokerage">
                If you want to get back on the road after a no-fault car accident, we have a wide range of accident management solutions that can help you.
                </p>
                <div className="bnrbtn">
                  <a class="db-btn-border btn-rollover phone" href="tel:0800 772 0850">
                    Road Accident Claim Help?{" "}
                    <i class="fa-solid fa-arrow-right"></i>
                  </a>
                </div>
                <div className="bnrbtn">
                <a className="db-btn-border btn-rollover desktop"  onClick={handleScrollToSidebar}>
                    Road Accident Claim Help? <i className="fa-solid fa-arrow-right"></i>
                </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="stickysection">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="group-4-8">
                <div className="cl8 tf-tab">
                  <div className="content-tab">
                    <div className="inner-content">
                      <h2>
                      Claims support with zero 
                        <br />
                        <span className="light">excess payment </span>{" "}
                      </h2>
                      <p className="disciplines">
                      Thousands of accidents take place in the UK each year. We work for victims of no-fault car accident incidents to make sure they receive emergency aid and expert claim assistance’ assistance without extra expenses. For the best claims support after a non-fault motor vehicle crash, contact our dedicated team at{" "}
                        <strong>
                          {" "}
                          iKube claims number.
                        </strong>{" "}
                      </p>
                      <hr />
                      <p className="bold-text">No additional costs </p>
                      <p className="bottom-te">
                      When you make your{" "} <strong> iKube accident claim </strong>through us, we will not charge you an insurance excess as we do not make a claim on your own policy.
                      </p>
                      <hr />
                      <p className="bold-text">Quick response </p>
                      <p className="bottom-te">
                      When you call us on the <strong> iKube claim line, </strong> rest assured that emergency assistance will reach out to you immediately.
                      </p>
                      <hr />
                      <p className="bold-text">
                      Manufacturer-approved repairs
                      </p>
                      <p className="bottom-te">
                      Through our manufacturer-approved repair network, we ensure that your vehicle is repaired to the highest possible standard.{" "}
                      </p>
                      <hr />
                      <p className="bold-text">Immediate replacement vehicle </p>
                      <p className="bottom-te">
                      In case your vehicle is written off, we will offer a same-day like-for-like courtesy car at no extra cost.{" "}
                      </p>
                      <hr />
                      <p className="bold-text">No claims bonus secured </p>
                      <p className="bottom-te">
                      As we will not seek to claim against your insurance, your no-claims discount and the hard-won bonuses will remain unaffected.{" "}
                      </p>
                      <hr />
                      <p className="bold-text">24-hour support </p>
                      <p className="bottom-te">
                      Call our <strong> iKube claims contact number </strong>{" "}
                      for roadside assistance after an accident. Our accident management team will be there for you day or night.
                      </p>
                      <hr />
                      <p className="bold-text">Free impartial advice</p>
                      <p className="bottom-te">
                      Would you like a free legal consultation to know your rights and options? Call for no-obligation advice on{" "}
                        <strong>
                          {" "}
                          <a href="tel:0800 772 0850">0800 772 0850</a>{" "}
                        </strong>
                        from our experienced solicitors panel.
                      </p>
                      <hr />
                      <p className="bold-text"> Fast-tracked settlement claim </p>
                      <p className="bottom-te">
                      Our streamlined approach and open communication promote swift resolution of your{" "}<strong> iKube claims. </strong>
                      </p>
                      <hr />
                      <p className="bold-text"> Experienced claim handler </p>
                      <p className="bottom-te">
                      You will get a dedicated claim handler who will guide you through making{" "} <strong> iKube accident claims, </strong>reducing the stress and anxiety on your part.
                      </p>
                      <hr />

                      <p className="bold-text"> UK wide recovery </p>
                      <p className="bottom-te">
                      When you call us on the {" "}<strong> iKube car insurance claims number, </strong> we can arrange for the collection of your damaged vehicle, ensuring safe transit to one of our storage yards.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="cl4" ref={sidebarRef}>
                  <Sidebarinner />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="photographs">
        <div className="container">
          <div className="row">
            <h2 className="road">
            We handle all aspects of an accident while  {" "}
              <span className="light">
              you <br />
                 take time off to recover
              </span>{" "}
            </h2>
            <div className="col-xxl-6 col-xl-6 col-md-12 col-12">
              <div className="fourbox">
                <ul>
                  <li>
                    <img src={check} className="check" alt="" />
                    Should you be involved in any non-fault car accidents, we would not only assist with retrieving your vehicle but also manage your{" "}
                    <strong>  iKube accident claim </strong>  from start to end.
                  </li>
                  <hr />
                  <li>
                    <img src={check} className="check" alt="" />
                    If you want to get your car properly repaired, contact us through the <strong> iKube claims number. </strong>We will organise quick repairs from a garage of your choice.
                  </li>
                  <hr />
                  <li>
                    <img src={check} className="check" alt="" />
                    We will arrange for an independent engineer to assess the damage caused to your vehicle.
                  </li>
                  <hr />
                  <li>
                    <img src={check} className="check" alt="" />
                    When injured in an accident, our solicitors would advise you on how to go about making an <strong> iKube accident claim </strong> so that you can receive fair compensation for your injuries.
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-xxl-6 col-xl-6 col-md-12 col-12">
              <div className="fourbox">
                <ul>
                  <li>
                    <img src={check} className="check" alt="" />
                    We will assist in assessing loss of earnings and damage to your property as well as help you recoup these from the insurance company of the offending party.
                  </li>
                  <hr />
                  <li>
                    <img src={check} className="check" alt="" />
                    Require a replacement vehicle? Kindly call us using the <strong> iKube claim line. </strong>  Until such time as your vehicle is repaired, we will provide a like-for-like replacement vehicle right at your doorstep.
                  </li>
                  <hr />
                  <li>
                    <img src={check} className="check" alt="" />
                    Our team of experienced solicitors will help you recover all your uninsured losses from their insurers who are at fault.
                  </li>
                  <hr />
                  <li>
                    <img src={check} className="check" alt="" />
                    With our no-win, no-fee policy, there are no risks involved in making any upfront payment if your <strong>  iKube claim </strong>does not succeed.
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="carprocess">
        <div className="container ">
          <div className="row">
            <div className="col-xxl-12 col-xxl-12 col-md-12 col-12">
              <h2 className="trusted-title">
                {" "}
                Our wide array of accident <br />
                <span className="light"> management services </span>{" "}
              </h2>
            </div>
          </div>
          <div className="row" id="possible">
            <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-12">
              <div className="claim">
                <ul>
                  <li>
                    <span className="sleej">Vehicle recovery:</span>In the event that you have had a non-fault accident, call us on the{" "}
                    <strong> iKube claims contact number </strong>so that we may give you fast and professional recovery help to ensure your vehicle is moved to a safe place. 
                  </li>
                  <li>
                    <span className="sleej">High-quality repairs:</span>Reach us through the{" "}
                    <strong>{" "}
                    iKube claim line{" "}
                    </strong>{" "}
                    for high-quality and guaranteed car repairs. We are connected to numerous manufacturers and authorised repairers all over the UK who do fast repairs to get you back on the wheel as soon as possible. 
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-12">
              <div className="claim" id="blue">
                <ul>
                  <li>
                    <span className="sleej">Safe storage:</span> Contact us on the{" "}
                    <strong> iKube claims contact number </strong>for your car to be recovered from the scene of an accident and taken into our safe storage facilities. Your car will be recovered from the scene of an accident and safely locked up in our stores until it has been repaired or assessed for claim purposes. 
                  </li>
                  <li>
                    <span className="sleej">Replacement vehicle:</span>Call us on the{" "}
                    <strong>
                      {" "}
                      iKube car insurance claims number{" "}
                    </strong>
                    to get back on track after a no-fault road traffic accident. We’ll get you a similar replacement vehicle at no charge to you when you call us on the<strong>
                    {" "} iKube car insurance claims number.
                    </strong>Such a vehicle will not leave your side until yours gets repaired.
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-12">
              <div className="claim">
                <ul>
                  <li>
                    <span className="sleej">Support for personal injuries:</span>
                    If you have sustained personal injuries because of a car accident, we can give you legal advice and direct an experienced solicitor to help you with the
                    <strong> iKube car insurance claim. </strong>
                  </li>
                  <li>
                    <span className="sleej">Claim management:</span>From legal advice to arranging repairs on your car and liaising with all people who were involved in the accident, we will take care of your <strong> iKube claim </strong> from start to finish.
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="yellowcta">
        <div className="container">
          <div className="row">
            <div className="cta2">
              <div className="col-xxl-8 col-xl-8 col-lg-8 col-12">
                <div className="legal">
                  <h3 className="experienced">
                  Got more questions? {" "}
                    <span className="light">Talk to our specialists</span>{" "}
                  </h3>
                  <p className="professionals">
                  Get in touch with our no-fault claim experts via{" "}
                    <strong>
                    car insurance claims number{" "}
                      <a className="cpt-no" href="tel:0800 772 0850">
                        {" "}
                        0800 772 0850{" "}
                      </a>{" "}
                    </strong>
                    to get the best advice for your{" "}
                    <strong> iKube claims. </strong>
                  </p>
                </div>
              </div>
              <div className="col-xxl-4 col-xl-4 col-lg-4 col-12">
              <div class="process">
                  <a class="tf-button style-1 phone" href="tel:0800 772 0850">
                    Get in touch<span class="icon-keyboard_arrow_right"></span>
                  </a>
                </div>
                <div class="process">
                    <a className="tf-button style-1 desktop"  onClick={handleScrollToSidebar}>
                        Get in touch<span className="icon-keyboard_arrow_right"></span>
                    </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <search className="updatedform">
        <div className="container">
          <div className="row text-center">
            <div className="col-xxl-12 col-xl-12 col-md-12 col-12">
              <h2 className="whiteheading">
                {" "}
                What makes us the best solution for your  <br />
                <span className="light">accident management needs?</span>
              </h2>
              <p className="sustained">
              We know that road traffic accidents can turn around your life. Nevertheless, we are your trusted partners handling no-fault car accidents to get you back on track. We walk with you from the time you report an accident until your repair costs and replacements are claimed from the other party who might be at fault in order to reduce stress related to managing
              <strong> iKube accident claims. </strong>
              </p>
            </div>
          </div>
          <div className="row">
            <div className="col-xxl-4 col-xl-4 col-lg-6 col-md-12 col-12">
              <div className="Claimant">
                <h5> Quick recovery after an accident </h5>
                <p className="collision">
                Call us on our <strong> iKube claims contact number </strong>so that we can help restore your safety and that of your car.
                </p>

                <h5> Manufacturer’s authorised parts and paints </h5>
                <p className="collision">
                You may reach our team through the support line given by the{" "}<strong> iKube claim. </strong>They will arrange fast repairs through our skilled technicians using original parts to restore your damaged car.
                </p>

                <h5> Management by Expert claim </h5>
                <p className="collision">
                Your accident <strong> iKube claim </strong>will be reviewed by our solicitors for damages to your car, and we will work with the other party’s insurer to ensure that your compensation is paid promptly.
                </p>
              </div>
            </div>
            <div className="col-xxl-4 col-xl-4 col-lg-6 col-md-12 col-12">
              <div className="Claimant">
                <h5> Round-the-clock support </h5>
                <p className="collision">
                Contact us on <strong> iKube claims contact number </strong>in case of an emergency after a non-fault road traffic accident.
                </p>
                <h5> No upfront fee </h5>
                <p className="collision">
                When you are involved in a car accident that was not your fault, we are not going to let you go through some unexpected expenses or pay anything beforehand.
                </p>

                <h5> Complete damage assessment  </h5>
                <p className="collision">
                To effectively fix your car, our professionals will have to do a complete evaluation of your vehicle and identify the extent of the damages, as well as suggest solutions for fast and efficient repair works.
                </p>
              </div>
            </div>
            <div className="col-xxl-4 col-xl-4 col-lg-12 col-md-12 col-12">
              <div className="Claimant">
                <h5> Immediate replacement vehicle </h5>
                <p className="collision">
                We shall help you get back on track by offering you a new identical vehicle under like-for-like terms.
                </p>

                <h5> Comprehensive claims management </h5>
                <p className="collision">
                We’ll take care of each step, from reporting to the settlement of your <strong> iKube claims. </strong>
                </p>

                <h5> Legal and administrative aid </h5>
                <p className="collision">
                Our team stays focused on smoothing out the paperwork concerning claims management on behalf of clients to ensure quick settlement.
                </p>
              </div>
            </div>
          </div>
        </div>
      </search>

      <section className="Accident-claim">
        <div className="container">
          <div className="row">
            <div className="needed">
              <h2 className="trusted-title">
              How can you maximise the settlement amount  <br />
                <span className="light"> after a no-fault car accident? </span>
              </h2>
              <p className="complexities">
              It’s crucial to act accordingly in a no-fault car accident if you want rightful compensation for your injuries and losses.Here are a few things to do for better chances of successful compensation claims.{" "}
              </p>
            </div>
            <hr />
          </div>
          <div className="row" id="incdes">
            <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-12">
              <div className="Preparing">
                <ul>
                  <li>
                    <div className="seemingly">
                      <h5 className="Seek">Seek medical help:</h5>
                      <p>
                        {" "}
                        No doubt, health should be the first point of concern in case of a no-fault car accident. Thus, seek immediate medical attention even when the injury sustained is not serious.{" "}
                      </p>
                    </div>
                  </li>
                  <li>
                    <div className="seemingly">
                      <h5 className="Seek">
                      Record everything:{" "}
                      </h5>
                      <p>
                      Detail all expenses, lost wages, and financial losses you have incurred due to a car accident.
                      </p>
                    </div>
                  </li>
                  <li>
                    <div className="seemingly">
                      <h5 className="Seek">Don’t talk about your accidents:</h5>
                      <p>
                      Avoid talking about the accident or <strong> iKube accident claim </strong>with anyone except your solicitors. Any statement made by you can weaken your case.
                      </p>
                    </div>
                  </li>
                  <li>
                    <div className="seemingly">
                      <h5 className="Seek">Collect evidence</h5>
                      <p>
                      This will include comprehensive records such as photos taken at the scene where the injury or accident had occurred, testimonials from witnesses, official documents regarding medical treatments and other financial losses.
                      </p>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
            <div
              className="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-12"
              id="dualcardiv"
            >
              <img src={dualcar} alt="" className="dualcar" />
            </div>
            <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-12">
              <div className="Preparing">
                <ul>
                  <li>
                    <div className="seemingly">
                      <h5 className="Seek">Avoid early settlements:</h5>
                      <p>
                      The first proposal for settlement may not cover all damages which have resulted from the incident. Consequently, discuss it with your solicitor before accepting so that it fully compensates you for all damages.{" "}
                      </p>
                    </div>
                  </li>
                  <li>
                    <div className="seemingly">
                      <h5 className="Seek"> Keep track of your social media activity: </h5>
                      <p>
                      It is advisable to refrain from using social media as it might affect your chances of receiving the compensation you deserve.
                      </p>
                    </div>
                  </li>
                  <li>
                    <div className="seemingly">
                      <h5 className="Seek">
                      Speak with a no-fault claim specialist:
                      </h5>
                      <p>
                        <strong>
                          {" "}
                          IKube claims contact number{" "}
                        </strong>{" "}
                        can be used to speak to our no-fault claim expert to enlighten you on your rights and options.{" "}
                      </p>
                    </div>
                  </li>
                  <li>
                    <div className="seemingly">
                      <h5 className="Seek">
                      Make a claim:
                      </h5>
                      <p>
                      Once you have gathered all the necessary evidence and documents related to the accident, get in touch with our{" "}
                        <strong>
                          {" "}
                          iKube claims department{" "}
                        </strong>{" "}
                        to start your claim process.
                      </p>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="whyus">
        <div className="container">
          <div className="row">
            <div className="col-xxl-12 col-md-12">
              <div className="why">
                <h2 className="choose">
                Make a claim in four {" "}
                  <span className="light">easy steps</span>
                </h2>
                <p className="complexities">
                Want to make a{" "}
                  <strong> iKube claim </strong>for compensation for what you lost? With our manageable and stress-free claims process, you can begin your claim in just 4 easy stages. Call us on <a href="tel:0800 772 0850">0800 772 0850</a> now.
                </p>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-xxl-3 col-xl-6 col-lg-6 col-md-6 col-12">
              <div className="box-service">
                <img src={check} className="check" alt="" />
                {/* <img src={experience} className="experience" alt="" /> */}
                <h4>Initial consultation</h4>
                <p>
                Get an appointment with our solicitors by calling the{" "}
                  <strong>
                    {" "}
                    iKube car insurance claims number.{" "}
                  </strong>
                </p>
              </div>
            </div>
            <div className="col-xxl-3 col-xl-6 col-lg-6 col-md-6 col-12">
              <div className="box-service">
                <img src={check} className="check" alt="" />
                <h4> Submit proof and papers </h4>
                <p>
                Give us all that matter represented by relevant evidence materials that were collected from the scene of accidents.
                </p>
              </div>
            </div>
            <div className="col-xxl-3 col-xl-6 col-lg-6 col-md-6 col-12">
              <div className="box-service">
                <img src={check} className="check" alt="" />
                <h4> Claim initiation </h4>
                <p>
                We will assess and submit your claim after we have received every part of the evidence relating to it.{" "}
                </p>
              </div>
            </div>
            <div className="col-xxl-3 col-xl-6 col-lg-6 col-md-6 col-12">
              <div className="box-service">
                <img src={check} className="check" alt="" />
                <h4> Claim settlement </h4>
                <p>
                We shall work tirelessly towards achieving a positive outcome for your case so that you receive fair compensation.{" "}
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="faqs">
        <div className="container">
          <div className="row">
            <div className="col-xxl-12 col-xl-12 col-md-12 col-12">
              <div className="fq">
                <h2>
                  {" "}
                  Frequently Asked{" "}
                  <span className="light">Questions (FAQ)</span>
                </h2>
                <p className="complexities">
                Got questions about the{" "}
                  <strong> iKube accident claim? </strong> We have them answered here.
                </p>
              </div>
              <div className="allfaq">
                <Accordion defaultActiveKey="0">
                  <Accordion.Item eventKey="0">
                    <Accordion.Header>
                    Does it cost anything extra to make a iKube accident claim?
                    </Accordion.Header>
                    <Accordion.Body>
                    We trust in transparency when we are dealing with our clients. Hence, you will not pay for any hidden costs. If you are not at fault, we shall recover all expenses through the other party’s insurance company and won’t ask for any upfront payment from you.
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="1">
                    <Accordion.Header>
                    Will using your service impact my no-claims bonus?
                    </Accordion.Header>
                    <Accordion.Body>
                    No. If you make an <strong> iKube claim </strong>through us, there won’t be any excess charges involved and your no-claims bonus is unaffected. 
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="2">
                    <Accordion.Header>
                    Who pays for the services I access through your company?
                    </Accordion.Header>
                    <Accordion.Body>
                    For non-fault car accidents, the other driver’s insurer will cover all your expenses from repairs to alternative vehicle costs. They are liable to foot up all bills incurred by you due to the car crash.
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="3">
                    <Accordion.Header>
                    Can you help me recover my car after a no-fault accident?{" "}
                    </Accordion.Header>
                    <Accordion.Body>
                    Yes. We will recover your vehicle from the point of impact and take it to an approved repairer. Furthermore, we will give you a free replacement car so that your normal life continues without disturbances.
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="4">
                    <Accordion.Header>
                    What if I have a written-off vehicle, can you help?
                    </Accordion.Header>
                    <Accordion.Body>
                    Yes. Where the car is declared a total loss, we will provide you with another one just like your own in every aspect. Besides, we will negotiate with the other party on your behalf to ensure that you get the best compensation for your written-off vehicle.
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="5">
                    <Accordion.Header>
                    The other party’s insurer has offered me an out-of-court settlement. Shall I accept it?{" "}
                    </Accordion.Header>
                    <Accordion.Body>
                    No. You should not accept an out-of-court settlement offer without first speaking to your solicitor.  Call us on the{" "} <strong> iKube claims number </strong>to discuss your claim with our solicitors.
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="6">
                    <Accordion.Header>
                    How long do I have access to a courtesy car?{" "}
                    </Accordion.Header>
                    <Accordion.Body>
                    The duration of the replacement vehicle depends on the circumstances surrounding your accident and how much time it takes to repair your car. You can keep the replacement vehicle until your car gets repaired or your claim gets settled. 
                    </Accordion.Body>
                  </Accordion.Item>
                </Accordion>
              </div>
            </div>
          </div>
        </div>
      </section>

      <Footer />
    </>
  );
};

export default Ikubeinsurance;
