import React, { useRef } from "react";
import Accordion from "react-bootstrap/Accordion";
import check from "../assets/images/check.png";
import dualcar from "../../src/assets/images/dualcar.webp";
import carbnr1 from "../../src/assets/images/carbnr1.webp";
import Sidebarinner from "../components/Sidebar-inner";
import phonecall from "../assets/images/phonecall.png";
import Footer from "../components/footer";
import { Helmet } from "react-helmet";

const RiasCarInsurance = () => {
    const sidebarRef = useRef(null);

    const handleScrollToSidebar = () => {
        if (sidebarRef.current) {
            sidebarRef.current.scrollIntoView({ behavior: "smooth" });
        }
    };
    return (
        <>
        <Helmet>
        <meta charSet="utf-8" />
        <title>Rias Car Insurance Accident Claim Phone Number</title>
        <meta
          name="description"
          content="Get in touch with our team via the Rias Car Insurance Accident Claim Phone Number 0800 772 0850 for quick and efficient claim support to obtain maximum compensation. "
        />
        <link
          rel="canonical"
          href="https://insurance-accident-claims.co.uk/Rias-car-insurance"
        />
      </Helmet>
            <section className="innernav">
                <div className="container">
                    <div className="row">
                        <div className="col-md-6 col-sm-6">
                            <div className="left-logo">
                                <h3>
                                    Accident<span className="sipo"> Claims</span>
                                </h3>
                            </div>
                        </div>
                        <div className="col-md-6 col-sm-6">
                            <div className="left-cta">
                                <div class="headercnt">
                                    <div class="chatmsg">
                                        <img
                                            src={phonecall}
                                            className="phonecall"
                                            alt="phonecall "
                                        />
                                    </div>
                                    <div class="box-body">
                                        <h3 class="nav-info-box-title">Toll Free Number </h3>
                                        <p>
                                            <a href="tel:0800 772 0850">0800 772 0850</a>
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section
                className="newbnr"
                style={{ backgroundImage: "url(" + carbnr1 + ")" }}
            >
                <div className="container">
                    <div className="row">
                        <div className="col-xxl-7 col-xl-7 col-md-10 col-12">
                            <div className="innerbnr">
                                <h1>
                                    <span className="onespan">Hassle-free and trusted accident</span>{" "}
                                    <span className="sci"> insurance claims  </span> number
                                </h1>
                                <p className="brokerage">
                                    We will handle everything from the first assessment to completing your claim so that you can return to the road as soon as possible.
                                </p>
                                <div className="bnrbtn">
                                    <a class="db-btn-border btn-rollover phone" href="tel:0800 772 0850">
                                        Road Accident Claim Help?{" "}
                                        <i class="fa-solid fa-arrow-right"></i>
                                    </a>
                                </div>
                                <div className="bnrbtn">
                                    <a className="db-btn-border btn-rollover desktop" onClick={handleScrollToSidebar}>
                                        Road Accident Claim Help? <i className="fa-solid fa-arrow-right"></i>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="stickysection">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="group-4-8">
                                <div className="cl8 tf-tab">
                                    <div className="content-tab">
                                        <div className="inner-content">
                                            <h2>
                                                Leading claims management experts

                                                <span className="light">  in the UK</span>{" "}
                                            </h2>
                                            <p className="disciplines">
                                                We are one of the leading companies in providing <strong> Rias insurance claims </strong>  help in the UK, having a track record of handling thousands of successful cases. Dial us using the <strong> Rias car insurance contact number </strong> if you want to claim. We will look after your <strong> Rias car insurance claim  </strong>  for free.
                                            </p>
                                            <hr />
                                            <p className="bold-text">No excess charge</p>
                                            <p className="bottom-te">
                                                We won’t charge you any excess for our accident management services. All costs will be recovered from the other party's insurer.                                           </p>
                                            <hr />
                                            <p className="bold-text">Secured no-claims bonus</p>
                                            <p className="bottom-te">
                                                Don’t worry about losing your no-claim bonus after making a claim with us. With us, we guarantee that there won’t be any effect on your no-claims bonus.                                      </p>
                                            <hr />
                                            <p className="bold-text">
                                                Fast vehicle recovery
                                            </p>
                                            <p className="bottom-te">
                                                You don’t want to be left helpless on the roadside when an accident or collision occurs. To ensure prompt and safe vehicle retrieval at all times, immediately call for emergency
                                                assistance on our contact number.
                                            </p>
                                            <hr />
                                            <p className="bold-text">Approved garages</p>
                                            <p className="bottom-te">
                                           Because of our huge network of approved body repair shops, we can assure you that your car will be fixed up to the highest standards. 
                                     </p>
                                            <hr />
                                            <p className="bold-text">Like-for-like hire</p>
                                            <p className="bottom-te">
                                            In a situation where your vehicle is being repaired and cannot move on the road, we offer a like-for-like hire, which means you get the same specifications,  make and model as yours.                                           </p>
                                            <hr />
                                            <p className="bold-text">Expert legal team
                                            </p>
                                            <p className="bottom-te">
                                            For successful <strong> Rias car insurance claims </strong> processing, we have an expert team of solicitors who have vast experience in handling claims, leading to full compensation.                               </p>
                                            <hr />
                                            <p className="bold-text">Fast processing of claims</p>
                                            <p className="bottom-te">
                                            With us, you can easily make your claim without any paperwork hassles or lengthy follow-ups.                                           </p>
                                            <hr />
                                            <p className="bold-text">Free impartial advice</p>
                                            <p className="bottom-te">
                                            In case you need any clarification about <strong> Rias insurance claims, </strong>  feel free to call us at<strong>
                                                    {" "}
                                                    <a href="tel:0800 772 0850">0800 772 0850 .</a>{" "}
                                                </strong> Our solicitors will assist you in your case to secure maximum compensation. 
                                            </p>
                                            <hr />
                                            <p className="bold-text">No-win, no-fee</p>
                                            <p className="bottom-te">
                                            Our solicitors operate under the no-win-no-fee basis guideline when dealing with your clients' insurance claims. Hence, if you don’t win the case, then there is nothing to pay.          </p>
                                            <hr />

                                            <p className="bold-text">24/7 assistance</p>
                                            <p className="bottom-te">
                                            A single phone call to our helpline numbers ensures immediate help in an emergency or guidance after an accident has happened. To get emergency assistance soon after a car accident that was not caused by negligence, please call us at <strong>
                                                    {" "}
                                                    <a href="tel:0800 772 0850">0800 772 0850 .</a>{" "}
                                                </strong>                                     </p>
                                        </div>
                                    </div>
                                </div>
                                <div className="cl4" ref={sidebarRef}>
                                    <Sidebarinner />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="photographs">
                <div className="container">
                    <div className="row">
                        <h2 className="road">
                        We provide expert accident management assistance {" "}
                            <span className="light">
                            to help  <br />
                             you cope with the situation
                            </span>{" "}
                        </h2>
                        <div className="col-xxl-6 col-xl-6 col-md-12 col-12">
                            <div className="fourbox">
                                <ul>
                                    <li>
                                        <img src={check} className="check" alt="" />
                                        To get in touch with us if there is an emergency after a road accident, contact us via <strong> Rias car insurance phone number.</strong>  

                                    </li>
                                    <hr />
                                    <li>
                                        <img src={check} className="check" alt="" />
                                        A party that caused the accident will be identified, and a claim will be made against the other driver on your behalf for compensation.                                   </li>
                                    <hr />
                                    <li>
                                        <img src={check} className="check" alt="" />
                                        We are going to assist you in making a <strong> Rias insurance claim, </strong>  which will cater for all your losses.                    </li>
                                    <hr />
                                    <li>
                                        <img src={check} className="check" alt="" />
                                        Once we have collected your vehicle from the accident scene, our team will arrange its repair through our trusted network of manufacturer-approved repairers.                           </li>
                                </ul>
                            </div>
                        </div>
                        <div className="col-xxl-6 col-xl-6 col-md-12 col-12">
                            <div className="fourbox">
                                <ul>
                                    <li>
                                        <img src={check} className="check" alt="" />
                                        In case it is beyond repair, we source an independent engineer to value it.                              </li>
                                    <hr />
                                    <li>
                                        <img src={check} className="check" alt="" />
                                        At times when your motor vehicle is being repaired, another temporary replacement car will be provided to you.                            </li>
                                    <hr />
                                    <li>
                                        <img src={check} className="check" alt="" />
                                        We will keep checking how far the repairs have gone so as to inform you of what has been done.                                   </li>
                                    <hr />
                                    <li>
                                        <img src={check} className="check" alt="" />
                                        Call us on
                                        <strong>
                                            {" "}
                                            <a href="tel:0800 772 0850">0800 772 0850</a> {" "}
                                        </strong> so that we can offer you expert guidance on how to claim compensation for the injuries or losses you’ve suffered.                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="carprocess">
                <div className="container ">
                    <div className="row">
                        <div className="col-xxl-12 col-xxl-12 col-md-12 col-12">
                            <h2 className="trusted-title">
                                {" "}
                                Our accident management  <br />
                                <span className="light">  services  </span>{" "}
                            </h2>
                        </div>
                    </div>
                    <div className="row" id="possible">
                        <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-12">
                            <div className="claim">
                                <ul>
                                    <li>
                                        <span className="sleej">Vehicle Recovery :</span>  For the recovery of your vehicle after an accident, call Rias' car insurance contact number. Our Intention in the provision of vehicle recovery services is to make sure that vehicles are quickly recovered from the scene of road accidents to our own secure storage compounds. 
                                    </li>
                                    <li>
                                        <span className="sleej">Approved repairs:</span>  If you want your vehicle repaired, contact our team using the<strong> Rias insurance claim number. </strong> Since there is no excess payment for vehicle repairs, we often arrange car repairs at trusted and reliable garages based in the UK.
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-12">
                            <div className="claim" id="blue">
                                <ul>
                                    <li>
                                        <span className="sleej">Vehicle storage:</span> Are you concerned about what will happen to your car if it has been involved in an accident that was not your fault? Get in touch with us via our <strong> Rias insurance claim number.</strong>  We have well-kept yards where we provide hassle-free storage facilities for vehicles as claims are being processed.    
                                    </li>
                                    <li>
                                        <span className="sleej">Replacement vehicles:</span>  If it is impossible to repair your motor vehicle, then it qualifies for replacement. Just dial 
                                        <strong>
                                            {" "}
                                            <a href="tel:0800 772 0850">0800 772 0850</a>{" "}
                                        </strong> so you can get a similar replacement vehicle so that you go on with your normal life. You can keep the replacement vehicle until you receive full compensation for your claim.


                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-12">
                            <div className="claim">
                                <ul>
                                    <li>
                                        <span className="sleej">Injury claims support:</span>
                                        To know whether you can get paid damages for injuries, you should call us on the <strong> Rias insurance claim number </strong>  and get advice from our solicitors.                                   </li>
                                    <li>
                                        <span className="sleej">Claim management:</span>  We handle all paperwork, including negotiating with insurers, arranging for vehicle repairers and offering substitute vehicles.

                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="yellowcta">
                <div className="container">
                    <div className="row">
                        <div className="cta2">
                            <div className="col-xxl-8 col-xl-8 col-lg-8 col-12">
                                <div className="legal">
                                    <h3 className="experienced">
                                        Got More questions? Talk to {" "}
                                        <span className="light">  our specialists
                                        </span>{" "}
                                    </h3>
                                    <p className="professionals">
                                    Get in touch with our no-fault claim experts via <strong> car insurance claim number</strong>
                                        {" "}
                                        <strong>{" "}
                                            <a className="cpt-no" href="tel:0800 772 0850">
                                                {" "}
                                                0800 772 0850{" "}
                                            </a>{" "}
                                        </strong>
                                        to get the best advice for your 
                                        <strong>  Rias car insurance claim.   </strong>
                                    </p>
                                </div>
                            </div>
                            <div className="col-xxl-4 col-xl-4 col-lg-4 col-12">
                                <div class="process">
                                    <a class="tf-button style-1 phone" href="tel:0800 772 0850">
                                        Get in touch<span class="icon-keyboard_arrow_right"></span>
                                    </a>
                                </div>
                                <div class="process">
                                    <a className="tf-button style-1 desktop" onClick={handleScrollToSidebar}>
                                        Get in touch<span className="icon-keyboard_arrow_right"></span>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <search className="updatedform">
                <div className="container">
                    <div className="row text-center">
                        <div className="col-xxl-12 col-xl-12 col-md-12 col-12">
                            <h2 className="whiteheading">
                                {" "}
                                What helps us gain an edge 
                                <span className="light">  over others?
                                </span>
                            </h2>
                            <p className="sustained">
                            We will give priority to saving you from the nightmare of a no-fault car accident. The following are some of the advantages that accrue if you decide to employ us as your claims management partner.                         </p>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-xxl-4 col-xl-4 col-lg-6 col-md-12 col-12">
                            <div className="Claimant">
                                <h5>24/7 car recovery services</h5>
                                <p className="collision">
                                Do you need assistance with car recovery services? Call the <strong> Rias insurance claim number, </strong>  and we shall provide emergency assistance to get your car recovered from the accident scene.

                                </p>

                                <h5>Guaranteed repairs</h5>
                                <p className="collision">
                                Through our vast network of repairers, we can organise quality assured and guaranteed workmanship on your vehicle.                            </p>

                                <h5>No increased premium rate </h5>
                                <p className="collision">
                                By choosing us, you are not making a<strong> Rias car insurance claim </strong> against your own policy. Thus, your premiums would not rise.                       </p>
                            </div>
                        </div>
                        <div className="col-xxl-4 col-xl-4 col-lg-6 col-md-12 col-12">
                            <div className="Claimant">
                                <h5>A free courtesy vehicle
                                </h5>
                                <p className="collision">
                                Without any prepayment, we could provide you with a replacement vehicle of the same kind. </p>
                                <h5>Trusted relationships </h5>
                                <p className="collision">
                                In order to give you top services, we have established sound relationships with the best repairers and appropriate solicitors in the UK.
                                </p>

                                <h5>Expedited claims settlement </h5>
                                <p className="collision">
                                Our experienced solicitors negotiate with the other person’s insurer so that your <strong>  Rias motor insurance claim  </strong>is settled fairly and quickly.
                                </p>
                            </div>
                        </div>
                        <div className="col-xxl-4 col-xl-4 col-lg-12 col-md-12 col-12">
                            <div className="Claimant">
                                <h5>Immediate assistance</h5>
                                <p className="collision">
                                We assure instant emergency help after a non-fault road accident without any delays.
                                </p>

                                <h5>End-to-end support</h5>
                                <p className="collision">
                                A dedicated claims handler will be assigned to your case personally, who will manage your claim from start to end and keep you informed about the developments.                         </p>

                                <h5>Zero extra charges</h5>
                                <p className="collision">
                                You don’t have to make extra payments or have out-of-pocket expenses for using our service. All costs will be recoveredfrom another party.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </search>

            <section className="Accident-claim">
                <div className="container">
                    <div className="row">
                        <div className="needed">
                            <h2 className="trusted-title">
                            How can you increase your chances of getting  <br />
                                <span className="light">enough compensation?  </span>
                            </h2>
                            <p className="complexities">
                            It is stressful being involved in an accident that is not caused by your fault, but it is yet more annoying when one has to deal with the process of filing for compensation. To heighten chances of getting adequate compensation, prove innocence beyond reasonable doubt by providing substantial proof. Here’s what you need to do if you want deserving compensation after a car accident.    
                            </p>
                        </div>
                        <hr />
                    </div>
                    <div className="row" id="incdes">
                        <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-12">
                            <div className="Preparing">
                                <ul>
                                    <li>
                                        <div className="seemingly">
                                            <h5 className="Seek">Ensure your own safety. :</h5>
                                            <p>
                                                {" "}
                                                Park your vehicle at a safe point and make an assessment of yourself and your passengers for any serious or minor injuries.                                  </p>
                                        </div>
                                    </li>
                                    <li>
                                        <div className="seemingly">
                                            <h5 className="Seek">
                                            Get medical assistance. :{" "}
                                            </h5>
                                            <p>
                                            If any of the persons in your car are injured, immediately contact a qualified practitioner.                                       </p>
                                        </div>
                                    </li>
                                    <li>
                                        <div className="seemingly">
                                            <h5 className="Seek">Gather proof:</h5>
                                            <p>
                                            Crucial evidence to be gathered from the scene of the accident includes photographs showing the point where it happened, the party responsible for the accident, damage caused, severe injuries and vehicle details.                                           </p>
                                        </div>
                                    </li>
                                    <li>
                                        <div className="seemingly">
                                            <h5 className="Seek">Contact police:</h5>
                                            <p>
                                            Make sure that you call the authorities and report the accident within 24 hours.                                  </p>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div
                            className="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-12"
                            id="dualcardiv"
                        >
                            <img src={dualcar} alt="" className="dualcar" />
                        </div>
                        <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-12">
                            <div className="Preparing">
                                <ul>
                                    <li>
                                        <div className="seemingly">
                                            <h5 className="Seek">Exchange details:</h5>
                                            <p>
                                            If possible, share insurance information with other parties involved. Among such information must include names in full, phone numbers, physical addresses, plus vehicle details.                                            </p>
                                        </div>
                                    </li>
                                    <li>
                                        <div className="seemingly">
                                            <h5 className="Seek">Get eye-witnesses details: </h5>
                                            <p>
                                            Capture witnesses' details, such as telephone numbers, for purposes of future referencing.                        </p>
                                        </div>
                                    </li>
                                    <li>
                                        <div className="seemingly">
                                            <h5 className="Seek">
                                            Record all expenses incurred :
                                            </h5>
                                            <p> Maintain copies of receipts indicating repairing costs, medical care, or any other expenses to get compensated for all charges. 



                                            </p>
                                        </div>
                                    </li>
                                    <li>
                                        <div className="seemingly">
                                            <h5 className="Seek">
                                            Consult no-fault claim experts  :
                                            </h5>
                                            <p>To speak to someone who can tell you how to go about making a successful claim, call us on 
                                                <strong>
                                                    {" "}
                                                    <a href="tel:0800 772 0850">0800 772 0850</a>{" "}
                                                </strong>

                                            </p>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="whyus">
                <div className="container">
                    <div className="row">
                        <div className="col-xxl-12 col-md-12">
                            <div className="why">
                                <h2 className="choose">
                                Start your claim with our hassle- {" "}
                                    <span className="light"> free process </span>
                                </h2>
                                <p className="complexities">
                                After an accident in which you are not at fault, it can be overwhelming when you want to make a <strong> Rias motor insurance claim. </strong> Therefore, we will help you with the process. Contact us through <strong> Rias insurance contact number </strong> and start your process.                                     </p>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-xxl-3 col-xl-6 col-lg-6 col-md-6 col-12">
                            <div className="box-service">
                                <img src={check} className="check" alt="" />
                                {/* <img src={experience} className="experience" alt="" /> */}
                                <h4>Get into touch</h4>
                                <p>
                                Talk to our team about your claim via the <strong>  Rias car insurance contact number.</strong> </p>
                            </div>
                        </div>
                        <div className="col-xxl-3 col-xl-6 col-lg-6 col-md-6 col-12">
                            <div className="box-service">
                                <img src={check} className="check" alt="" />
                                <h4>Give information
                                </h4>
                                <p>
                                You need to give us all the details of the accident, including when it happened, where and why.</p>
                            </div>
                        </div>
                        <div className="col-xxl-3 col-xl-6 col-lg-6 col-md-6 col-12">
                            <div className="box-service">
                                <img src={check} className="check" alt="" />
                                <h4>Show proof</h4>
                                <p>
                                Include some evidence that supports your application, such as photographs taken from the scene of the accident, statements of eyewitnesses, police reports, and CCTV footage.
                                </p>
                            </div>
                        </div>
                        <div className="col-xxl-3 col-xl-6 col-lg-6 col-md-6 col-12">
                            <div className="box-service">
                                <img src={check} className="check" alt="" />
                                <h4>Claim processing</h4>
                                <p>
                                We will commence processing your claims after evaluation. Our team will keep informing you on how things are progressing.

                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="faqs">
                <div className="container">
                    <div className="row">
                        <div className="col-xxl-12 col-xl-12 col-md-12 col-12">
                            <div className="fq">
                                <h2>
                                    {" "}
                                    Frequently Asked{" "}
                                    <span className="light">Questions</span>
                                </h2>
                                <p className="complexities">
                                    Got questions about the <strong> Rias car insurance claim?</strong> We have them answered here.
                                </p>
                            </div>
                            <div className="allfaq">
                                <Accordion defaultActiveKey="0">
                                    <Accordion.Item eventKey="0">
                                        <Accordion.Header>
                                        Should I pay extra fees for another car?                            </Accordion.Header>
                                        <Accordion.Body>
                                        Not, we bear the costs of replacing your car from the at-fault party’s insurance company. You are only required to pay if you fail to cooperate with us, use a solicitor without our knowledge or provide false information in relation to your matter.                             </Accordion.Body>
                                    </Accordion.Item>
                                    <Accordion.Item eventKey="1">
                                        <Accordion.Header>
                                        How long will it take for my claim to be settled?
                                        </Accordion.Header>
                                        <Accordion.Body>
                                        Though we shall do our best to conclude your <strong> Rias motor insurance claim </strong>  as soon as possible, how long it takes to settle claims depends on your case specifics. We will keep you updated once we initiate your claim.                         </Accordion.Body>
                                    </Accordion.Item>
                                    <Accordion.Item eventKey="2">
                                        <Accordion.Header>
                                        Will the at-fault party’s insurer meet the cost of vehicle recovery?
                                        </Accordion.Header>
                                        <Accordion.Body>
                                        Yes, the at-fault party’s insurer meets all expenses relating to vehicle recovery. You may need to show evidence, such as a police report or photos taken at the scene, indicating that you are entitled to receive compensation.                        </Accordion.Body>
                                    </Accordion.Item>
                                    <Accordion.Item eventKey="3">
                                        <Accordion.Header>
                                        Am I eligible for credit hire after a non-fault car accident?
                                        </Accordion.Header>
                                        <Accordion.Body>
                                        Yes. Credit hire is available if you are not responsible for the accident. To get an alternative vehicle because yours has been written off or is being repaired, call us on the <strong> Rias insurance claim number.</strong>  We will give you a replacement car like yours until your car is in the garage.                                 </Accordion.Body>
                                    </Accordion.Item>
                                    <Accordion.Item eventKey="4">
                                        <Accordion.Header>
                                        Will my no-claim bonus be affected if I am not at fault?

                                        </Accordion.Header>
                                        <Accordion.Body>
                                        If you submit your claim via us, we won’t lay an insurance claim against your own policy, and there is no possibility that you could lose any year’s worth of discount or bonus earned.     </Accordion.Body>
                                    </Accordion.Item>
                                    <Accordion.Item eventKey="5">
                                        <Accordion.Header>
                                        Can I use my own garage for repairs?{" "}
                                        </Accordion.Header>
                                        <Accordion.Body>
                                        Certainly! We will make arrangements for your vehicle to be repaired in the garage of your choice. We will coordinate with the repairer to ensure only quick and highest-quality services are rendered.
               </Accordion.Body>
                                    </Accordion.Item>
                                    <Accordion.Item eventKey="6">
                                        <Accordion.Header>
                                        Which type of automobile would substitute mine?{" "}
                                        </Accordion.Header>
                                        <Accordion.Body>
                                          We will organise another vehicle similar in size, value as well as function to your own vehicle. You just have to get in touch with us by calling our <strong> Rias insurance claim number </strong> and tell us what you want. We shall provide you with a suitable replacement car. 
                                        </Accordion.Body>
                                    </Accordion.Item>
                                </Accordion>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <Footer />
        </>
    );
};

export default RiasCarInsurance;
