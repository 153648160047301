import React, { useRef } from "react";
import Accordion from "react-bootstrap/Accordion";
import check from "../assets/images/check.png";
import dualcar from "../../src/assets/images/dualcar.webp";
import bner0 from "../../src/assets/images/bner0.webp";
import Sidebarinner from "../components/Sidebar-inner";
import phonecall from "../assets/images/phonecall.png";
import Footer from "../components/footer";
import { Helmet } from "react-helmet";

const Veygo = () => {
    const sidebarRef = useRef(null);

    const handleScrollToSidebar = () => {
        if (sidebarRef.current) {
            sidebarRef.current.scrollIntoView({ behavior: "smooth" });
        }
    };
    return (
        <>
        <Helmet>
        <meta charSet="utf-8" />
        <title>Veygo Car Insurance Accident Claim Phone Number</title>
        <meta
          name="description"
          content="Want professional claim assistance in the UK? Contact our team via the Veygo Car Insurance Accident Claim Phone Number 0800 772 0850 to get dedicated claim support.  "
        />
        <link
          rel="canonical"
          href="https://insurance-accident-claims.co.uk/Veygo"
        />
      </Helmet>
            <section className="innernav">
                <div className="container">
                    <div className="row">
                        <div className="col-md-6 col-sm-6">
                            <div className="left-logo">
                                <h3>
                                    Accident<span className="sipo"> Claims</span>
                                </h3>
                            </div>
                        </div>
                        <div className="col-md-6 col-sm-6">
                            <div className="left-cta">
                                <div class="headercnt">
                                    <div class="chatmsg">
                                        <img
                                            src={phonecall}
                                            className="phonecall"
                                            alt="phonecall "
                                        />
                                    </div>
                                    <div class="box-body">
                                        <h3 class="nav-info-box-title">Toll Free Number </h3>
                                        <p>
                                            <a href="tel:0800 772 0850">0800 772 0850</a>
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section
                className="newbnr"
                style={{ backgroundImage: "url(" + bner0 + ")" }}
            >
                <div className="container">
                    <div className="row">
                        <div className="col-xxl-7 col-xl-7 col-md-10 col-12">
                            <div className="innerbnr">
                                <h1>
                                    <span className="onespan">Car Crash</span>{" "}
                                    <span className="sci"> insurance claims </span> Management 
                                </h1>
                                <p className="brokerage">
                                We manage everything involving non-fault car crashes, from recovery of the vehicle to claims assistance. 
                                </p>
                                <div className="bnrbtn">
                                    <a class="db-btn-border btn-rollover phone" href="tel:0800 772 0850">
                                        Road Accident Claim Help?{" "}
                                        <i class="fa-solid fa-arrow-right"></i>
                                    </a>
                                </div>
                                <div className="bnrbtn">
                                    <a className="db-btn-border btn-rollover desktop" onClick={handleScrollToSidebar}>
                                        Road Accident Claim Help? <i className="fa-solid fa-arrow-right"></i>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="stickysection">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="group-4-8">
                                <div className="cl8 tf-tab">
                                    <div className="content-tab">
                                        <div className="inner-content">
                                            <h2>
                                            Road accident support without 
                                                <br />
                                                <span className="light">  any excess</span>{" "}
                                            </h2>
                                            <p className="disciplines">
                                            A road accident can mess up your life.  Therefore, we offer comprehensive crash assistance and <strong>
                                                    {" "}
                                                    Veygo car insurance claims 
                                                </strong>{" "}
                                                help so that you can get back on the road safely and in no time following a non-fault road traffic incident. For emergency assistance or claim advice, call us on
                                                <strong>
                                            {" "}
                                            <a href="tel:0800 772 0850">0800 772 0850</a>{" "}
                                        </strong> 
                                            </p>
                                            <hr />
                                            <p className="bold-text">No policy excess</p>
                                            <p className="bottom-te">
                                            Instead of claiming directly against your own policy, we will make a direct <strong>Veygo Insurance claim </strong>  with the other driver's insurer, which means you won’t have to pay a penny over what you should already pay under your own policy,  saving yourself from the financial risk of paying for excesses on your insurance cover.                                            </p>
                                            <hr />
                                            <p className="bold-text">Safety of no-claims bonus </p>
                                            <p className="bottom-te">
                                            Filing an accident claim against the at-fault party ensures that your own policy retains its no-claim bonus status unaffectedly.                                            </p>
                                            <hr />
                                            <p className="bold-text">
                                            Experienced claims handler 
                                            </p>
                                            <p className="bottom-te">
                                            Immediate help is offered by us on the <strong> Veygo car insurance claim number.</strong>  We have a designated claims handler exclusively for your <strong>Veygo insurance accident claim </strong>  to save you time and minimise any hassle.                                               </p>
                                            <hr />
                                            <p className="bold-text">Superior repairs</p>
                                            <p className="bottom-te">
                                            Our repairer network has skilled repairers who perform quality repairs using genuine parts and paint.                                            </p>
                                            <hr />
                                            <p className="bold-text">Like-for-like replacement</p>
                                            <p className="bottom-te">
                                            When you need a replacement vehicle after an accident, please call us at the Veygo car accident claim number. We will arrange for another like-for-like alternative to enable you to continue with your day-to-day activities.                                             </p>
                                            <hr />
                                            <p className="bold-text">Immediate response</p>
                                            <p className="bottom-te">
                                            Our dedicated staff members are ready to be of assistance if you require immediate attention 24/7. Call us on the <strong> Veygo insurance accident claim number </strong>  for immediate support and assistance following a car accident.                                </p>
                                            <hr />
                                            <p className="bold-text">Quick settlement of claims</p>
                                            <p className="bottom-te">
                                            We have a simple and streamlined claims process to ensure your <strong> Veygo car insurance claim </strong>  can happen without stress.                                           </p>
                                            <hr />
                                            <p className="bold-text">Professional advice </p>
                                            <p className="bottom-te">
                                            Our solicitors have dealt with thousands of <strong> Veygo insurance claims. </strong>  So, when you contact us, you can expect nothing less than top-notch help as well as guidance required throughout every stage of filing a claim.                                         </p>
                                            <hr />
                                            <p className="bold-text">24/7 claim handling</p>
                                            <p className="bottom-te">
                                            Any time you need assistance or advice for your <strong>Veygo insurance claims, </strong> our team is always here to assist you. For the fullest reimbursement of your claim, call the car accident claim number 
                                            <strong>
                                            {" "}
                                            <a href="tel:0800 772 0850">0800 772 0850</a>{" "}
                                        </strong>                                           </p>
                                            <hr />

                                            <p className="bold-text">Free advice with no obligation</p>
                                            <p className="bottom-te">
                                            For a non-fault accident, talk to our solicitors on the <strong>Veygo car accident claims number </strong>  and get free advice about your claim without being obliged to do anything.                                           </p>
                                        </div>
                                    </div>
                                </div>
                                <div className="cl4" ref={sidebarRef}>
                                    <Sidebarinner />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="photographs">
                <div className="container">
                    <div className="row">
                        <h2 className="road">
                        We'll help minimise your stress and hassle   {" "}
                            <span className="light">
                            after<br />
                                a no-fault car accident  
                            </span>{" "}
                        </h2>
                        <div className="col-xxl-6 col-xl-6 col-md-12 col-12">
                            <div className="fourbox">
                                <ul>
                                    <li>
                                        <img src={check} className="check" alt="" />
                                        If you suffer from a non-fault car accident, we will help you recover all your losses as well as damages from the negligent driver.

                                    </li>
                                    <hr />
                                    <li>
                                        <img src={check} className="check" alt="" />
                                        Once you reach us through the <strong>Veygo car insurance claim number, </strong> we will guide you and help you in making a compensation claim.                                   </li>
                                    <hr />
                                    <li>
                                        <img src={check} className="check" alt="" />
                                        If you require replacement vehicles, we can provide courtesy cars after just about any kind of breakdown within hours even and arrange vehicle repairs so that you can be back on the road                                  </li>
                                    <hr />
                                    <li>
                                        <img src={check} className="check" alt="" />
                                        To expedite and facilitate an agreeable resolution to your <strong>Veygo compensation claims, </strong>  we shall liaise with other parties involved.                                     </li>
                                </ul>
                            </div>
                        </div>
                        <div className="col-xxl-6 col-xl-6 col-md-12 col-12">
                            <div className="fourbox">
                                <ul>
                                    <li>
                                        <img src={check} className="check" alt="" />
                                        Through coordination with reputable repairers, your vehicle will be repaired on time and in a professional manner.                                   </li>
                                    <hr />
                                    <li>
                                        <img src={check} className="check" alt="" />
                                        You will be assisted by our solicitors in every step of the <strong>Veygo insurance accident claim </strong>  process, from handling paperwork to negotiating with all parties and everything in between.                                      </li>
                                    <hr />
                                    <li>
                                        <img src={check} className="check" alt="" />
                                        A dedicated claims handler will be assigned to you, who will remain the only point of contact throughout the <strong>Veygo car insurance claim </strong>  process.                                   </li>
                                    <hr />
                                    <li>
                                        <img src={check} className="check" alt="" />
                                        We shall assess the extent of your loss and negotiate with the other party’s insurer for the best settlement possible.                               </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="carprocess">
                <div className="container ">
                    <div className="row">
                        <div className="col-xxl-12 col-xxl-12 col-md-12 col-12">
                            <h2 className="trusted-title">
                                {" "}
                                Our accident management  <br />
                                <span className="light"> services.</span>{" "}
                            </h2>
                        </div>
                    </div>
                    <div className="row" id="possible">
                        <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-12">
                            <div className="claim">
                                <ul>
                                    <li>
                                        <span className="sleej">Vehicle recovery:</span>    In case it is declared that your vehicle cannot be driven again, we shall take immediate steps to have it recovered from an accident scene to either a repair centre or one of our safe storage facilities.
                                    </li>
                                    <li>
                                        <span className="sleej">Approved repairs:</span>   After evaluating your vehicle for repair purposes by an independent engineer, we will then arrange repairs at approved garages within our network.
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-12">
                            <div className="claim" id="blue">
                                <ul>
                                    <li>
                                        <span className="sleej">Secure storage:</span>  As you wait for your vehicle to get fixed or assessed for claims, it gets stored in our safe storage area, where it does not experience additional damage or deterioration. Please call us through the <strong> Veygo car accident claim number </strong> so that we can recover and store your car quickly.
                                    </li>
                                    <li>
                                        <span className="sleej">Replacement vehicle:</span>   In case your car is undriveable, you can contact us on the <strong> Veygo insurance accident claim number, </strong> and our team will arrange for a replacement vehicle of the same kind to make sure that you get back to your usual daily activities quickly.
                                      
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-12">
                            <div className="claim">
                                <ul>
                                    <li>
                                        <span className="sleej">Injury compensation help</span>
                                        Did you suffer any injury as a result of the incident? Dial up our solicitors via <strong> Veygo car insurance claim number,</strong>  and they will offer expert advice on how to seek compensation for injuries suffered and other losses.                                   </li>
                                    <li>
                                        <span className="sleej">Claims management  :</span> From notification until settlement of your <strong> Veygo car insurance claims, </strong>we provide one dedicated claims handler to manage your claim from start to end. 
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="yellowcta">
                <div className="container">
                    <div className="row">
                        <div className="cta2">
                            <div className="col-xxl-8 col-xl-8 col-lg-8 col-12">
                                <div className="legal">
                                    <h3 className="experienced">
                                        Got more questions? Talk to  {" "}
                                        <span className="light">our specialists
                                        </span>{" "}
                                    </h3>
                                    <p className="professionals">
                                        Get in touch with our no-fault claim experts via <strong> car insurance contact number </strong>
                                        {" "}
                                        <strong>{" "}
                                            <a className="cpt-no" href="tel:0800 772 0850">
                                                {" "}
                                                0800 772 0850{" "}
                                            </a>{" "}
                                        </strong>
                                        to get the best advice  advice regarding the 
                                        <strong> Veygo insurance accident claim. </strong>
                                    </p>
                                </div>
                            </div>
                            <div className="col-xxl-4 col-xl-4 col-lg-4 col-12">
                                <div class="process">
                                    <a class="tf-button style-1 phone" href="tel:0800 772 0850">
                                        Get in touch<span class="icon-keyboard_arrow_right"></span>
                                    </a>
                                </div>
                                <div class="process">
                                    <a className="tf-button style-1 desktop" onClick={handleScrollToSidebar}>
                                        Get in touch<span className="icon-keyboard_arrow_right"></span>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <search className="updatedform">
                <div className="container">
                    <div className="row text-center">
                        <div className="col-xxl-12 col-xl-12 col-md-12 col-12">
                            <h2 className="whiteheading">
                                {" "}
                                Why are we a one-stop shop for the best solution
                                <span className="light"> in accident management?
                                </span>
                            </h2>
                            <p className="sustained">
                            We are committed to helping you recover from an accident which wasn’t your fault, becoming your trusted partners in this process. Our services include specialist claims support as well as various forms of accident management solutions designed to stop you from having to go through alone with anything <strong>Veygo insurance claim concern.</strong>                           </p>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-xxl-4 col-xl-4 col-lg-6 col-md-12 col-12">
                            <div className="Claimant">
                                <h5>Constant availability</h5>
                                <p className="collision">
                                Our team works 24 hours a day, seven days a week, providing quick response time after road traffic accidents without delays.

                                </p>

                                <h5>High-quality vehicle repairs</h5>
                                <p className="collision">
                                In the UK, there are many repairers who can restore your car to its original condition using genuine parts and paint.                                </p>

                                <h5>Free of charge</h5>
                                <p className="collision">
                                To benefit from end-to-end accident management services without any excess costs, call the <strong> Veygo Insurance accident claim number. </strong> You will not worry about this as all expenses will be refunded by the other driver's insurer.                            </p>
                            </div>
                        </div>
                        <div className="col-xxl-4 col-xl-4 col-lg-6 col-md-12 col-12">
                            <div className="Claimant">
                                <h5>Full assistance
                                </h5>
                                <p className="collision">
                                We can recover your vehicle, assist you in resolving your claims and provide other forms of help to put you back on track. </p>
                                <h5>No-win, no-fee</h5>
                                <p className="collision">
                                If we do not win your case, our solicitors do not need a penny in terms of costs for their work done on your behalf.                               </p>

                                <h5> Individualised advice and support</h5>
                                <p className="collision">
                                Our solicitors handle every single <strong> Veygo compensation claim </strong> with utmost diligence and offer personalised guidance throughout to achieve the best possible result in each particular case.
                                </p>
                            </div>
                        </div>
                        <div className="col-xxl-4 col-xl-4 col-lg-12 col-md-12 col-12">
                            <div className="Claimant">
                                <h5>Quick vehicle recovery</h5>
                                <p className="collision">
                                We ensure quick recovery of cars after an accident scene with the help of our team, which is available anytime during the day or night.                              </p>

                                <h5>Fast-track claims process</h5>
                                <p className="collision">
                                Our hassle-free claims process ensures that communication is maintained throughout the path of your <strong> Veygo insurance accident claim </strong> so it is dealt with promptly.                               </p>

                                <h5>Genuine commitments</h5>
                                <p className="collision">
                                You will not experience any false promises. We shall keep in touch with you all through the procedure of a <strong> Veygo insurance claim </strong> and inform you of its progress.                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </search>

            <section className="Accident-claim">
                <div className="container">
                    <div className="row">
                        <div className="needed">
                            <h2 className="trusted-title">
                            How can you get the most out of your <br />
                                <span className="light"> Veygo car insurance accident claim? </span>
                            </h2>
                            <p className="complexities">
                            Some things must be done as soon as possible after a car accident so that one can get every dime you spend. Here are a few things you should do after a no-fault car accident to ensure maximum compensation for your claim.
                            </p>
                        </div>
                        <hr />
                    </div>
                    <div className="row" id="incdes">
                        <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-12">
                            <div className="Preparing">
                                <ul>
                                    <li>
                                        <div className="seemingly">
                                            <h5 className="Seek">Check for injuries :</h5>
                                            <p>
                                                {" "}
                                                Stop your vehicle, and check yourself and others in it if they were hurt during the collision. In case there has been any injury, medical treatment must be sought right away.                                            </p>
                                        </div>
                                    </li>
                                    <li>
                                        <div className="seemingly">
                                            <h5 className="Seek">
                                            Collect evidence :{" "}
                                            </h5>
                                            <p>
                                            The more evidence, the better chance you stand at getting maximum damages. Therefore, collect enough information that will help support your claim.                                          </p>
                                        </div>
                                    </li>
                                    <li>
                                        <div className="seemingly">
                                            <h5 className="Seek">Record all costs:</h5>
                                            <p>
                                            After an accident, keep a record of all expenses, including medical expenses, lost earnings, property damage costs, transportation charges and any other out-of-pocket expenses you’ve made.                                         </p>
                                        </div>
                                    </li>
                                    <li>
                                        <div className="seemingly">
                                            <h5 className="Seek">Never accept initial offers :</h5>
                                            <p>
                                            The first settlement offer may not cover all damages.  Hence, deserving compensation may not be provided. Never agree with the first offers given by the insurers.                                          </p>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div
                            className="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-12"
                            id="dualcardiv"
                        >
                            <img src={dualcar} alt="" className="dualcar" />
                        </div>
                        <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-12">
                            <div className="Preparing">
                                <ul>
                                    <li>
                                        <div className="seemingly">
                                            <h5 className="Seek">Do not use social media:</h5>
                                            <p>
                                            Don’t post anything on social media until your ,<strong> Veygo car insurance claim </strong> is resolved. This may be used against you by the other party solicitors and insurers.                                         </p>
                                        </div>
                                    </li>
                                    <li>
                                        <div className="seemingly">
                                            <h5 className="Seek">Contact witnesses: </h5>
                                            <p>
                                            Make contact with the eyewitnesses and record their statements. It will help you in gathering crucial information to support your <strong>Veygo insurance claim. </strong>                                              </p>
                                        </div>
                                    </li>
                                    <li>
                                        <div className="seemingly">
                                            <h5 className="Seek">
                                            Speak to a solicitor :
                                            </h5>
                                            <p>
                                            Consult with a fault claims solicitor to know your rights and options after a no-fault car accident. Dial us on the <strong> Veygo car insurance claim number, </strong>  and we shall connect you with an experienced solicitor in the UK. 
                                            </p>
                                        </div>
                                    </li>
                                    <li>
                                        <div className="seemingly">
                                            <h5 className="Seek">
                                            Start your claim  :
                                            </h5>
                                            <p>
                                            Once you’ve made up your mind to sue the other party, please call the <strong> Veygo car accident claims number.</strong>  We shall start the process of the<strong> Veygo compensation claims </strong> so that you get rightful compensation for your losses. 


                                            </p>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="whyus">
                <div className="container">
                    <div className="row">
                        <div className="col-xxl-12 col-md-12">
                            <div className="why">
                                <h2 className="choose">
                                Make a Veygo Insurance Accident Claim  {" "}
                                    <span className="light">in 4 simple steps  </span>
                                </h2>
                                <p className="complexities">
                                Is it worth claiming if it was not your fault? To begin your claims process, just dial the <strong>Veygo insurance accident claim </strong> number and make your claim without any paperwork hassles and a long waiting period.                             </p>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-xxl-3 col-xl-6 col-lg-6 col-md-6 col-12">
                            <div className="box-service">
                                <img src={check} className="check" alt="" />
                                {/* <img src={experience} className="experience" alt="" /> */}
                                <h4>Contact us. </h4>
                                <p>
                                For immediate assistance following a non-fault road traffic accident, you can call the car insurance claims number 
                                <strong>
                                            {" "}
                                            <a href="tel:0800 772 0850">0800 772 0850</a>{" "}
                                        </strong> </p>
                            </div>
                        </div>
                        <div className="col-xxl-3 col-xl-6 col-lg-6 col-md-6 col-12">
                            <div className="box-service">
                                <img src={check} className="check" alt="" />
                                <h4>Talk about the accident circumstances</h4>
                                <p>
                                Give us all information about the accident, including where it occurred, when it occurred and what caused it. </p>
                            </div>
                        </div>
                        <div className="col-xxl-3 col-xl-6 col-lg-6 col-md-6 col-12">
                            <div className="box-service">
                                <img src={check} className="check" alt="" />
                                <h4>Submit evidence</h4>
                                <p>
                                If there is any good evidence, such as photos or videos of the scene, please provide them.                                </p>
                            </div>
                        </div>
                        <div className="col-xxl-3 col-xl-6 col-lg-6 col-md-6 col-12">
                            <div className="box-service">
                                <img src={check} className="check" alt="" />
                                <h4>Claim initiation </h4>
                                <p>
                                Now that we have given everything about the incident, we take our hands off to begin your case while keeping you posted on its advancement
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="faqs">
                <div className="container">
                    <div className="row">
                        <div className="col-xxl-12 col-xl-12 col-md-12 col-12">
                            <div className="fq">
                                <h2>
                                    {" "}
                                    Frequently Asked{" "}
                                    <span className="light">Questions</span>
                                </h2>
                                <p className="complexities">
                                    Got questions about the <strong> Veygo compensation claims?</strong> We have them answered here.
                                </p>
                            </div>
                            <div className="allfaq">
                                <Accordion defaultActiveKey="0">
                                    <Accordion.Item eventKey="0">
                                        <Accordion.Header>
                                        May I get another car if my accident was not my fault?                                       </Accordion.Header>
                                        <Accordion.Body>
                                        Definitely. After an innocent traffic mishap, we will give you another car just like the one you were using so that your life can continue as normal even after a no-fault vehicle collision.                                       </Accordion.Body>
                                    </Accordion.Item>
                                    <Accordion.Item eventKey="1">
                                        <Accordion.Header>
                                        Will I have to pay any excess for the accident management services? 
                                        </Accordion.Header>
                                        <Accordion.Body>
                                        No. All costs are covered by the party at fault’s insurer, meaning that there is no need to make any excess payment.                                </Accordion.Body>
                                    </Accordion.Item>
                                    <Accordion.Item eventKey="2">
                                        <Accordion.Header>
                                        Could you get my car repaired by the repairer of my choice?
                                        </Accordion.Header>
                                        <Accordion.Body>
                                        Of course, tell us about your repairer, and we will make arrangements for fixing your spoilt car in any UK-based repair place you want.                                  </Accordion.Body>
                                    </Accordion.Item>
                                    <Accordion.Item eventKey="3">
                                        <Accordion.Header>
                                        What model of a hire vehicle do I get after a non-fault accident?
                                        </Accordion.Header>
                                        <Accordion.Body>
                                        Should you become a victim of another driver’s negligence in an accident that results in a total loss of your car, we will offer you an identical car with similar build, size and type as your own vehicle.     </Accordion.Body>
                                    </Accordion.Item>
                                    <Accordion.Item eventKey="4">
                                        <Accordion.Header>
                                        For how long can I use the replacement vehicle?

                                        </Accordion.Header>
                                        <Accordion.Body>
                                        You can keep it until your own vehicle is repaired. If your vehicle is damaged beyond repair, you can keep the replacement vehicle until you receive compensation for the full value of your car.                                        </Accordion.Body>
                                    </Accordion.Item>
                                    <Accordion.Item eventKey="5">
                                        <Accordion.Header>
                                        What happens if the other party offers an out-of-court settlement?{" "}
                                        </Accordion.Header>
                                        <Accordion.Body>
                                        Avoid any initial settlement from the insurance company of the other driver involved in your accident until you have discussed this with your solicitor. Initial settlement offers do not give fair compensation for damages caused.                                      </Accordion.Body>
                                    </Accordion.Item>
                                    <Accordion.Item eventKey="6">
                                        <Accordion.Header>
                                        Will my no-claims bonus be affected if I make a claim? {" "}
                                        </Accordion.Header>
                                        <Accordion.Body>
                                        No. Your no-claims bonus will not be affected since we do not intend to make any claim against your policy.
                                        </Accordion.Body>
                                    </Accordion.Item>
                                </Accordion>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <Footer />
        </>
    );
};

export default Veygo;
