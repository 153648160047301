import React, { useRef } from "react";
import insurancelogo from "../../src/assets/images/insurancelogo.png";
import Accordion from "react-bootstrap/Accordion";
import check from "../assets/images/check.png";
import dualcar from "../../src/assets/images/dualcar.webp";
import bner from "../../src/assets/images/bner.webp";
import Sidebarinner from "../components/Sidebar-inner";
import phonecall from "../assets/images/phonecall.png";
import Footer from "../components/footer";
import { Helmet } from "react-helmet";

const Sheilaswheels = () => {
  const sidebarRef = useRef(null);

  const handleScrollToSidebar = () => {
    if (sidebarRef.current) {
      sidebarRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Sheilas Wheels Car Insurance Accident Claims Number</title>
        <meta
          name="description"
          content="Contact the Sheilas Wheels Car Insurance Accident Claims Number 0800 772 0850 for prompt assistance and guidance to ensure expedited settlement of your claim. "
        />
        <link
          rel="canonical"
          href="https://insurance-accident-claims.co.uk/sheilas-wheels"
        />
      </Helmet>
      <section className="innernav">
        <div className="container">
          <div className="row">
            <div className="col-md-6 col-sm-6">
              <div className="left-logo">
                {/* <img src={insurancelogo} className='insurancelogo' alt='Insurance Logo' /> */}
                <h3>Accident Claims</h3>
              </div>
            </div>
            <div className="col-md-6 col-sm-6">
              <div className="left-cta">
                <div class="headercnt">
                  <div class="chatmsg">
                    <img
                      src={phonecall}
                      className="phonecall"
                      alt="phonecall "
                    />
                  </div>
                  <div class="box-body">
                    <h3 class="nav-info-box-title">Toll Free Number </h3>
                    <p>
                      <a href="tel:0800 772 0850">0800 772 0850</a>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section
        className="newbnr"
        style={{ backgroundImage: "url(" + bner + ")" }}
      >
        <div className="container">
          <div className="row">
            <div className="col-xxl-6 col-xl-6 col-md-10 col-12">
              <div className="innerbnr">
                <h1>
                  <span className="onespan">No fault accident</span> <br />
                  <span className="sci">and claims management</span> in the UK
                </h1>
                <p className="brokerage">
                  Let us help you get back on the road after a no-fault car
                  accident with our comprehensive range of accident management
                  solutions.
                </p>

                <div className="bnrbtn">
                  <a class="db-btn-border btn-rollover phone" href="tel:0800 772 0850">
                    Start your claim{" "}
                    <i class="fa-solid fa-arrow-right"></i>
                  </a>
                </div>
                <div className="bnrbtn">
                <a className="db-btn-border btn-rollover desktop"  onClick={handleScrollToSidebar}>
                  Start your claim <i className="fa-solid fa-arrow-right"></i>
                </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="stickysection">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="group-4-8">
                <div className="cl8 tf-tab">
                  <div className="content-tab">
                    <div className="inner-content">
                      <h2>
                        Claims assistance with zero
                        <span className="light"> excess payment</span>
                      </h2>
                      <p className="disciplines">
                        Every year thousands of accidents take place in the UK.
                        That’s why we are here to help the victims of no-fault
                        car accidents to ensure they receive emergency
                        assistance and professional{" "}
                        <strong>Sheilas Wheels claims</strong> services without
                        burdening them with additional costs. Contact our
                        dedicated team on{" "}
                        <strong>
                          Sheilas Wheels claims number{" "}
                          <a href="tel:0800 772 0850">0800 772 0850</a>{" "}
                        </strong>
                        to get the best claims support after a no-fault car
                        accident.
                      </p>
                      <hr />
                      <p className="bold-text">No insurance excess</p>
                      <p className="bottom-te">
                        If you make your{" "}
                        <strong>Sheilas Wheels accident claim</strong> with us,
                        we will save you from paying insurance excess as we
                        won’t make a claim against your own insurance policy.
                      </p>
                      <hr />
                      <p className="bold-text">Quick response</p>
                      <p className="bottom-te">
                        When you call us on the{" "}
                        <strong>Sheilas Wheels claim line,</strong>you can rest
                        assured that you’ll receive emergency support without
                        any delay.
                      </p>
                      <hr />
                      <p className="bold-text">
                        Manufacturer-approved repairs{" "}
                      </p>
                      <p className="bottom-te">
                        We ensure your vehicle is repaired to the highest
                        quality standards through our manufacturer-approved
                        repair network.{" "}
                        {/* <strong>John Lewis car insurance claim number</strong>{" "}
                        for immediate assistance. We’ll appoint a dedicated
                        claims handler to manage your{" "}
                        <strong>John Lewis insurance accident claim</strong>,
                        avoiding multiple departments and long waiting times. */}
                      </p>
                      <hr />
                      <p className="bold-text">Immediate replacement vehicle</p>
                      <p className="bottom-te">
                        In case your vehicle is a total loss, we will provide
                        you with a same-day like-for-like replacement vehicle at
                        no additional cost.
                      </p>
                      <hr />
                      <p className="bold-text">Protected no-claims bonus</p>
                      <p className="bottom-te">
                        As we won’t initiate a claim against your insurance
                        policy, your no-claims bonus and hard-earned discounts
                        will stay intact.{" "}
                        {/* <strong>John Lewis car accident claim number</strong> to
                        get a replacement vehicle after a car accident. We will
                        arrange a like-for-like replacement vehicle so that you
                        can continue with your routine activities. */}
                      </p>
                      <hr />
                      <p className="bold-text">24/7 assistance</p>{" "}
                      <p className="bottom-te">
                        Call us on{" "}
                        <strong>Sheilas Wheels claims contact number </strong>
                        to get roadside assistance after a no-fault car
                        accident. Our team is available round-the-clock to
                        assist you with your accident management needs.
                      </p>
                      <hr />
                      <p className="bold-text">Free no-obligation advice</p>
                      <p className="bottom-te">
                        Want free initial consultation to know your rights and
                        options? Call us on our{" "}
                        <strong>
                          claims contact number{" "}
                          <a href="tel:0800 772 0850">0800 772 0850</a>{" "}
                        </strong>
                        to get no-obligation advice from our experienced panel
                        of solicitors.
                      </p>
                      <hr />
                      <p className="bold-text">Expedited claim settlement </p>
                      <p className="bottom-te">
                        Our streamlined approach and transparent communication
                        ensure speedy resolution of your{" "}
                        <strong>Sheilas' Wheels claims.</strong>
                      </p>
                      <hr />
                      <p className="bold-text">Experienced claim handler</p>
                      <p className="bottom-te">
                        With us, you’ll be provided with a dedicated claims
                        handler who will walk you through the process of making{" "}
                        <strong>Sheilas Wheels accident claims</strong>,
                        reducing your stress and anxiety.{" "}
                        {/* <strong>
                          car accident claim number{" "}
                          <a href="tel:0800 772 0850">0800 772 0850</a>
                        </strong>{" "} */}
                      </p>
                      <hr />
                      <p className="bold-text">Nationwide recovery </p>
                      <p className="bottom-te">
                        When you call us on the{" "}
                        <strong>
                          Sheilas Wheels car insurance claims number
                        </strong>
                        , we will arrange the collection of your damaged
                        vehicle, ensuring its safe transportation into our
                        storage facilities.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="cl4" ref={sidebarRef}>
                  <Sidebarinner />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="photographs">
        <div className="container">
          <div className="row">
            <h2 className="road">
              We will manage your accident while <br />
              <span className="light">you sit back and recover </span>{" "}
            </h2>
            <div className="col-xxl-6 col-xl-6 col-md-12 col-12">
              <div className="fourbox">
                <ul>
                  <li>
                    <img src={check} className="check" alt="" />
                    If you’re involved in a no-fault car accident, we will not
                    only help you with your vehicle recovery but also handle
                    your <strong> Sheilas Wheels claims </strong> from start to
                    finish.
                  </li>
                  <hr />
                  <li>
                    <img src={check} className="check" alt="" />
                    Get in touch with us via{" "}
                    <strong> Sheilas Wheels claims number </strong>for quality
                    repairs of your vehicle. We will arrange guaranteed repairs
                    from the garage of your choice.
                  </li>
                  <hr />
                  <li>
                    <img src={check} className="check" alt="" />
                    We will arrange an independent engineer for a damage
                    assessment of your vehicle.
                  </li>
                  <hr />
                  <li>
                    <img src={check} className="check" alt="" />
                    If you’re injured due to a car accident, our solicitors will
                    advise you to make a{" "}
                    <strong> Sheilas Wheels accident claim </strong>to ensure
                    you get deserving compensation for your injuries.
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-xxl-6 col-xl-6 col-md-12 col-12">
              <div className="fourbox">
                <ul>
                  <li>
                    <img src={check} className="check" alt="" />
                    We will help you assess any loss of earnings and damages to
                    your property and help you recover the losses from the
                    at-fault party’s insurer.
                  </li>
                  <hr />
                  <li>
                    <img src={check} className="check" alt="" />
                    Want a replacement vehicle? Please call us on the{" "}
                    <strong>Sheilas Wheels claim line.</strong> We will provide
                    you with a like-for-like replacement vehicle delivered to
                    your doorstep until the duration of your vehicle repairs.
                  </li>
                  <hr />
                  <li>
                    <img src={check} className="check" alt="" />
                    Our panel of experienced solicitors will help you recover
                    all your uninsured losses from the at-fault party’s insurer.{" "}
                    {/* <strong>John Lewis car insurance claim</strong> process. */}
                  </li>
                  <hr />
                  <li>
                    <img src={check} className="check" alt="" />
                    With our no-win, no-fee approach, you won’t be burdened with
                    the risks of paying any upfront costs if your{" "}
                    <strong> Sheilas Wheels claim </strong>is unsuccessful.
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="carprocess">
        <div className="container ">
          <div className="row">
            <div className="col-xxl-12 col-xxl-12 col-md-12 col-12">
              <h2 className="trusted-title">
                Our broad spectrum of accident <br />
                <span className="light">management services</span>
              </h2>
            </div>
          </div>
          <div className="row" id="possible">
            <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-12">
              <div className="claim">
                <ul>
                  <li>
                    <span className="sleej">Vehicle recovery </span> <br />
                    If you’ve been in a no-fault accident, call us on{" "}
                    <strong> Sheilas Wheels claims contact number. </strong>We
                    will provide you with speedy and professional recovery
                    assistance to get your vehicle moved to a safe place.
                  </li>
                  <li>
                    <span className="sleej">High-quality repairs</span>
                    <br />
                    Get in touch with us via the
                    <strong> Sheilas Wheels claim line </strong>for high-quality
                    and guaranteed repairs of your vehicle. We have a vast
                    network of manufacturer-approved repairers across the UK who
                    provide quick repairs and can get you back on the road as
                    soon as possible.
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-12">
              <div className="claim" id="blue">
                <ul>
                  <li>
                    <span className="sleej">Safe storage</span> <br />
                    To get your vehicle recovered from the accident scene and
                    transported to our safe storage facilities, contact us at{" "}
                    <strong>Sheilas Wheels claims contact number.</strong>We
                    will recover your vehicle from the accident scene and safely
                    secure it in our storage facilities until repairs or claim
                    assessment.
                  </li>
                  <li>
                    <span className="sleej">Replacement vehicle</span> <br />
                    Let us help you get back to routine after a no-fault car
                    accident. Call us on{" "}
                    <strong>Sheilas Wheels car insurance claims number</strong>.
                    , and we'll put you back on the road by providing a
                    like-for-like replacement vehicle at no cost to you. The
                    replacement vehicle will remain with you until your car gets
                    repaired.
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-12">
              <div className="claim">
                <ul>
                  <li>
                    <span className="sleej">Personal injury support</span>
                    <br />
                    In case you’ve sustained personal injuries due to a car
                    accident, we will help you get legal advice and appoint an
                    experienced solicitor to guide you through the process of
                    making <strong> Sheilas Wheels claims. </strong>
                  </li>
                  <li>
                    <span className="sleej">Claims management </span> <br />
                    From providing legal advice to arranging your vehicle
                    repairs to liaising with all the parties involved in the car
                    accident, we will manage your
                    <strong> Sheilas Wheels accident claim </strong>, from start
                    to finish.
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="yellowcta">
        <div className="container">
          <div className="row">
            <div className="cta2">
              <div className="col-xxl-8 col-xl-8 col-lg-8 col-12">
                <div className="legal">
                  <h3 className="experienced">
                    Got more questions?{" "}
                    <span className="light">Talk to our specialists </span>
                  </h3>
                  <p className="professionals">
                    Get in touch with our no-fault claim experts via{" "}
                    <strong>
                      car insurance claims number{" "}
                      <a className="cpt-no" href="tel:0800 772 0850">
                        0800 772 0850
                      </a>
                    </strong>{" "}
                    to get the best advice for your{" "}
                    <strong> Sheilas Wheels claims. </strong>.
                  </p>
                </div>
              </div>
              <div className="col-xxl-4 col-xl-4 col-lg-4 col-12">
              <div class="process">
                  <a class="tf-button style-1 phone" href="tel:0800 772 0850">
                    Get in touch<span class="icon-keyboard_arrow_right"></span>
                  </a>
                </div>
                <div class="process">
                    <a className="tf-button style-1 desktop"  onClick={handleScrollToSidebar}>
                        Get in touch<span className="icon-keyboard_arrow_right"></span>
                    </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <search className="updatedform">
        <div className="container">
          <div className="row text-center">
            <div className="col-xxl-12 col-xl-12 col-md-12 col-12">
              <h2 className="whiteheading">
                What makes us the best solution for your
                <span className="light">accident management needs?</span>
              </h2>
              <p className="sustained">
                We understand that road traffic accidents can turn your life
                upside down. However, we’re your trusted partners, expertly
                managing the no-fault car accident to get you back to normality.
                From the moment you report a car accident to the recovery of
                repair expenses and replacement costs from the at-fault party,
                we stand by your side to minimise the stress of managing{" "}
                <strong> Sheilas Wheels accident claim. </strong>
              </p>
            </div>
          </div>
          <div className="row">
            <div className="col-xxl-4 col-xl-4 col-lg-6 col-md-12 col-12">
              <div className="Claimant">
                <h5>Speedy accident recovery </h5>
                <p className="collision">
                  Contact us on <strong> Sheilas Wheels claims number </strong>,
                  and we’ll help you get back on the track, ensuring your safety
                  and that of your vehicle.
                </p>
                <h5>Manufacturer-approved parts and paints</h5>
                <p className="collision">
                  Get in touch with our team via the
                  <strong> Sheilas Wheels claim line </strong>. We will arrange
                  quick repairs from our trusted technicians, using first-hand
                  parts to restore your damaged vehicle.
                </p>
                <h5>Expert claims management </h5>
                <p className="collision">
                  Our solicitors will review your{" "}
                  <strong>Sheilas Wheels accident claim</strong> , assess the
                  damages to your vehicle and liaison with the other party’s
                  insurer to ensure expedited settlement of your claim.
                </p>
              </div>
            </div>
            <div className="col-xxl-4 col-xl-4 col-lg-6 col-md-12 col-12">
              <div className="Claimant">
                <h5>24/7 support </h5>
                <p className="collision">
                  Our dedicated team is available around the clock, every day of
                  the week. Contact us on
                  <strong> Sheilas Wheels claims contact number </strong>for
                  emergency support after a no-fault car accident.
                </p>
                <h5>Zero excess payment </h5>
                <p className="collision">
                  If you’re not at fault in a car accident, we won’t burden you
                  with unexpected costs or upfront charges.
                </p>
                <h5>Comprehensive damage assessment </h5>
                <p className="collision">
                  Our experts will thoroughly assess your vehicle to determine
                  the extent of damages and the ideal course of action for
                  efficient and speedy repairs of your vehicle.{" "}
                </p>
              </div>
            </div>
            <div className="col-xxl-4 col-xl-4 col-lg-12 col-md-12 col-12">
              <div className="Claimant">
                <h5>Same-day replacement vehicle </h5>
                <p className="collision">
                  We will help you get back on the road smoothly with a
                  like-for-like replacement vehicle, ensuring it's the same
                  make, size and model as your own vehicle.
                </p>
                <h5>Integrated claims service</h5>
                <p className="collision">
                  From initial claim filing to final settlement, we will take
                  care of every aspect of the{" "}
                  <strong>Sheilas Wheels claims.</strong>
                </p>
                <h5>Legal and administrative support </h5>
                <p className="collision">
                  Our dedicated team will streamline the claims process, manage
                  paperwork and handle the proceedings on your behalf to ensure
                  fair resolution.{" "}
                </p>
              </div>
            </div>
          </div>
        </div>
      </search>

      <section className="Accident-claim">
        <div className="container">
          <div className="row">
            <div className="needed">
              <h2 className="trusted-title">
                How to maximise your settlement after
                <br />
                <span className="light">a no-fault car accident? </span>
              </h2>
              <p className="complexities">
                It’s important to make the right moves after a no-fault car
                accident to ensure you get rightful compensation for your
                injuries and losses. Here are a few things you must do to
                improve the chances of a successful compensation claim.
              </p>
            </div>
            <hr />
          </div>
          <div className="row" id="incdes">
            <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-12">
              <div className="Preparing">
                <ul>
                  <li>
                    <div className="seemingly">
                      <h5 className="Seek">Seek prompt medical treatment </h5>
                      <p>
                        Your health should be your foremost priority after a
                        no-fault car accident. So, get immediate medical
                        treatment as soon as possible, even if you’ve sustained
                        minor injuries.
                      </p>
                    </div>
                  </li>
                  <li>
                    <div className="seemingly">
                      <h5 className="Seek">Document everything </h5>
                      <p>
                        Maintain a detailed record of all the expenses, lost
                        wages, and financial losses you’ve suffered due to the
                        car accident.
                      </p>
                    </div>
                  </li>
                  <li>
                    <div className="seemingly">
                      <h5 className="Seek">Don’t discuss your accident</h5>
                      <p>
                        Avoid discussing your accident or
                        <strong> Sheilas Wheels accident claim </strong>with
                        anyone other than your solicitors. Any statement you
                        make may weaken your case.
                      </p>
                    </div>
                  </li>
                  <li>
                    <div className="seemingly">
                      <h5 className="Seek">Gather evidence </h5>
                      <p>
                        Collect comprehensive evidence, including medical
                        records, photos of the injuries and accident scene,
                        witness statements, and documents of financial losses.
                      </p>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
            <div
              className="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-12"
              id="dualcardiv"
            >
              <img src={dualcar} alt="" className="dualcar" />
            </div>
            <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-12">
              <div className="Preparing">
                <ul>
                  <li>
                    <div className="seemingly">
                      <h5 className="Seek">Avoid early settlement offers </h5>
                      <p>
                        An initial settlement offer may not fully cover all the
                        damages you’ve suffered. So, before accepting any such
                        offer, talk to your solicitor to ensure it compensates
                        you fairly for all the damages.
                      </p>
                    </div>
                  </li>
                  <li>
                    <div className="seemingly">
                      <h5 className="Seek">
                        Monitor your social media presence
                      </h5>
                      <p>
                        It’s wise to stay safe from social media as it may harm
                        your chances of obtaining deserving compensation.
                      </p>
                    </div>
                  </li>
                  <li>
                    <div className="seemingly">
                      <h5 className="Seek">Speak to a no-fault claim expert</h5>
                      <p>
                        Get in touch with us via{" "}
                        <strong>Sheilas Wheels claims contact number</strong> to
                        speak to our no-fault claim expert to know your rights
                        and options.
                      </p>
                    </div>
                  </li>
                  <li>
                    <div className="seemingly">
                      <h5 className="Seek">Register your claim </h5>
                      <p>
                        Once you’ve collected all the evidence and documents
                        related to the accident, contact our{" "}
                        <strong>Sheilas Wheels claims department</strong>. to
                        initiate your claim.
                      </p>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="whyus">
        <div className="container">
          <div className="row">
            <div className="col-xxl-12 col-md-12">
              <div className="why">
                <h2 className="choose">
                  Register your claim in
                  <span className="light">
                    <br /> four easy steps
                  </span>
                </h2>
                <p className="complexities">
                  Thinking of making <strong>Sheilas Wheels claim</strong> to to
                  get compensation for your losses? With our streamlined and
                  hassle-free claims process, you can initiate your claim in
                  just 4 simple steps. Contact us on{" "}
                  <a href="tel:0800 772 0850">0800 772 0850 </a>to get started.
                </p>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-xxl-3 col-xl-6 col-lg-6 col-md-6 col-12">
              <div className="box-service">
                <img src={check} className="check" alt="" />
                <h4>Initial consultation</h4>
                <p>
                  Reach out to us via{" "}
                  <strong>
                    Sheilas Wheels car insurance claims number{" "}
                    <a href="tel:0800 772 0850">0800 772 0850</a>
                  </strong>{" "}
                  to schedule a consultation with our solicitors.
                </p>
              </div>
            </div>
            <div className="col-xxl-3 col-xl-6 col-lg-6 col-md-6 col-12">
              <div className="box-service">
                <img src={check} className="check" alt="" />
                <h4>Submit evidence and documents </h4>
                <p>
                  Provide us with all the relevant evidence and documents that
                  you’ve collected from the accident scene.
                </p>
              </div>
            </div>
            <div className="col-xxl-3 col-xl-6 col-lg-6 col-md-6 col-12">
              <div className="box-service">
                <img src={check} className="check" alt="" />
                <h4>Claim submission</h4>
                <p>
                  After obtaining all the evidence and documents, we will
                  prepare and submit your claim on your behalf.
                </p>
              </div>
            </div>
            <div className="col-xxl-3 col-xl-6 col-lg-6 col-md-6 col-12">
              <div className="box-service">
                <img src={check} className="check" alt="" />
                <h4>Claim settlement </h4>
                <p>
                  We will work tirelessly to achieve a favourable resolution to
                  your claim to help you secure rightful compensation.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="faqs">
        <div className="container">
          <div className="row">
            <div className="col-xxl-12 col-xl-12 col-md-12 col-12">
              <div className="fq">
                <h2>
                  {" "}
                  Frequently Asked{" "}
                  <span className="light">Questions (FAQ)</span>
                </h2>
                <p className="complexities">
                  Got questions about the{" "}
                  <strong>Sheilas Wheels accident claim?</strong> We have them
                  answered here.{" "}
                </p>
              </div>
              <div className="allfaq">
                <Accordion defaultActiveKey="0">
                  <Accordion.Item eventKey="0">
                    <Accordion.Header>
                      Are there any hidden costs involved when I make a Sheilas
                      Wheels accident claim?
                    </Accordion.Header>
                    <Accordion.Body>
                      We believe in transparency and open communication.
                      Therefore, we will make sure that you never incur any
                      unexpected costs. If you’re not at fault, we will recover
                      all the expenses from the other party’s insurance company
                      and won’t charge you any upfront costs.
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="1">
                    <Accordion.Header>
                      Will claiming through your services impact my no-claims
                      bonus?
                    </Accordion.Header>
                    <Accordion.Body>
                      No. If you make <strong> Sheilas Wheels claim </strong>
                      through us, we won’t charge you any excess, and your
                      no-claims bonus will stay intact.
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="2">
                    <Accordion.Header>
                      Who pays for the services and my claim?
                    </Accordion.Header>
                    <Accordion.Body>
                      The other party’s insurance company will pay every dime
                      you incur as a result of a no-fault car accident. From
                      repair expenses to costs of replacement vehicles, the
                      at-fault party’s insurance company is liable to pay all
                      the costs incurred by you due to the accident.
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="3">
                    <Accordion.Header>
                      Will you assist with my vehicle recovery after the
                      no-fault car accident?
                    </Accordion.Header>
                    <Accordion.Body>
                      Yes. We will recover your vehicle from the accident scene
                      and transport it to an approved repair centre. Also, we
                      will provide you with a replacement vehicle to ensure you
                      carry on with your routine activities without disruption.
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="4">
                    <Accordion.Header>
                      Could you help me if my vehicle is a write-off?
                    </Accordion.Header>
                    <Accordion.Body>
                      Yes. We will provide you with a like-for-like replacement
                      vehicle if your vehicle is a total loss. Moreover, we will
                      negotiate with the other party on your behalf for the best
                      compensation for your written-off vehicle.
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="5">
                    <Accordion.Header>
                      The other party’s insurer has offered me an out-of-court
                      settlement. Should I agree?
                    </Accordion.Header>
                    <Accordion.Body>
                      No. It’s best not to accept an out-of-court settlement
                      offer without consulting your solicitor. Call us on{" "}
                      <strong> Sheilas Wheels claims number </strong>to talk to
                      our solicitors and understand whether the offer is fair
                      and completely compensates you for your losses.
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="6">
                    <Accordion.Header>
                      How long can I keep the replacement vehicle?
                    </Accordion.Header>
                    <Accordion.Body>
                      The duration of the replacement vehicle depends on your
                      accident circumstances and the extent of repairs needed
                      for your vehicle. You can keep the replacement vehicle
                      until your car gets repaired, or you receive compensation
                      amounting to the full value of your written-off vehicle.
                    </Accordion.Body>
                  </Accordion.Item>
                </Accordion>
              </div>
            </div>
          </div>
        </div>
      </section>

      <Footer />
    </>
  );
};

export default Sheilaswheels;
