import React, { useRef } from "react";
import Accordion from "react-bootstrap/Accordion";
import check from "../assets/images/check.png";
import dualcar from "../../src/assets/images/dualcar.webp";
import carbnr1 from "../../src/assets/images/carbnr1.webp";
import Sidebarinner from "../components/Sidebar-inner";
import phonecall from "../assets/images/phonecall.png";
import Footer from "../components/footer";
import { Helmet } from "react-helmet";

const Insurancefactorycar = () => {
  const sidebarRef = useRef(null);

  const handleScrollToSidebar = () => {
    if (sidebarRef.current) {
      sidebarRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>The Insurance Factory Car Insurance Accident Claim Phone Number</title>
        <meta
          name="description"
          content="Get in touch with our expert team via the Insurance Factory Car Insurance accident claim phone number 0800 772 0850 for car insurance claim assistance in the UK. "
        />
        <link
          rel="canonical"
          href="https://insurance-accident-claims.co.uk/insurance-factory-car"
        />
      </Helmet>
      <section className="innernav">
        <div className="container">
          <div className="row">
            <div className="col-md-6 col-sm-6">
              <div className="left-logo">
                <h3>
                  Accident<span className="sipo"> Claims</span>
                </h3>
              </div>
            </div>
            <div className="col-md-6 col-sm-6">
              <div className="left-cta">
                <div class="headercnt">
                  <div class="chatmsg">
                    <img
                      src={phonecall}
                      className="phonecall"
                      alt="phonecall "
                    />
                  </div>
                  <div class="box-body">
                    <h3 class="nav-info-box-title">Toll Free Number </h3>
                    <p>
                      <a href="tel:0800 772 0850">0800 772 0850</a>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section
        className="newbnr"
        style={{ backgroundImage: "url(" + carbnr1 + ")" }}
      >
        <div className="container">
          <div className="row">
            <div className="col-xxl-8 col-xl-8 col-md-10 col-12">
              <div className="innerbnr">
                <h1>
                  <span className="onespan">No win,</span>{" "}
                  <span className="sci">No Fee claims </span>management
                </h1>
                <p className="brokerage">
                  Our support ranges from vehicle recovery to getting your
                  no-fault car accident compensation as soon as possible.
                </p>
                <div className="bnrbtn">
                  <a
                    class="db-btn-border btn-rollover phone"
                    href="tel:0800 772 0850"
                  >
                    Road Accident Claim Help?{" "}
                    <i class="fa-solid fa-arrow-right"></i>
                  </a>
                </div>
                <div className="bnrbtn">
                  <a
                    className="db-btn-border btn-rollover desktop"
                    onClick={handleScrollToSidebar}
                  >
                    Road Accident Claim Help?{" "}
                    <i className="fa-solid fa-arrow-right"></i>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="stickysection">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="group-4-8">
                <div className="cl8 tf-tab">
                  <div className="content-tab">
                    <div className="inner-content">
                      <h2>
                        Expert claims without
                        <br />
                        <span className="light">additional fees </span>{" "}
                      </h2>
                      <p className="disciplines">
                        Are you involved in an accident where the other person
                        is at fault? Call the{" "}
                        <strong>
                          {" "}
                          Insurance Factory car insurance claims number.{" "}
                        </strong>{" "}
                        We will manage your claim professionally and efficiently
                        to save you time, hassle and a lot of money.
                      </p>
                      <hr />
                      <p className="bold-text">No excess on insurance</p>
                      <p className="bottom-te">
                        We protect you from paying any excess or upfront
                        payments if it was not your mistake. We will recover all
                        costs from the insurer of the guilty party, at no cost
                        to you.
                      </p>
                      <hr />
                      <p className="bold-text">
                        Doesn’t affect no-claims bonus
                      </p>
                      <p className="bottom-te">
                        Call us on car accident claim number{" "}
                        <a href="tel:0800 772 0850">0800 772 0850</a> and
                        register with us right now. This will prevent processing
                        through your own policy, safeguarding your no-claims
                        bonus and valuable discounts.
                      </p>
                      <hr />
                      <p className="bold-text">
                        Repairs as approved by the manufacturer
                      </p>
                      <p className="bottom-te">
                        Repairers who have been approved by manufacturers in the
                        UK will be arranged to repair damaged vehicles using
                        genuine parts and paint to ensure that they are restored
                        back to their original state before the accident
                        happened.
                      </p>
                      <hr />
                      <p className="bold-text">Support for personal injury </p>
                      <p className="bottom-te">
                        If you’ve sustained any big or small injuries as a
                        result of the accident, we will guide you through the
                        process of making a winning{" "}
                        <strong>Insurance Factory car insurance claim.</strong>
                      </p>
                      <hr />
                      <p className="bold-text">Instant roadside help</p>
                      <p className="bottom-te">
                        Whenever you call us on the{" "}
                        <strong>
                          {" "}
                          Insurance Factory car insurance claim number,{" "}
                        </strong>{" "}
                        we shall send our team to provide immediate assistance
                        where it is required most.
                      </p>
                      <hr />
                      <p className="bold-text">Repair centre coordination</p>
                      <p className="bottom-te">
                        To ensure your damaged vehicle undergoes quick, seamless
                        repairs and reduces downtime, we will coordinate with
                        the repair centre.
                      </p>
                      <hr />
                      <p className="bold-text"> Replacement in 24 hours </p>
                      <p className="bottom-te">
                        To ensure minimal disruption to your journey, we aim to
                        return you behind the wheel by bringing a substitute
                        vehicle to your address or site of collision within the
                        same day.
                      </p>
                      <hr />
                      <p className="bold-text">Your own claims handler</p>
                      <p className="bottom-te">
                        Make an{" "}
                        <strong> Insurance Factory car accident claim </strong>
                        with us and have your own dedicated claims handler who
                        will be there all along throughout this process as your
                        main point of contact.{" "}
                      </p>
                      <hr />
                      <p className="bold-text"> 24/7 availability </p>
                      <p className="bottom-te">
                        Inquire through the helpline at{" "}
                        <strong>
                          {" "}
                          Insurance Factory car insurance claims telephone
                          number{" "}
                        </strong>{" "}
                        for recovery services offered 24 hours a day so that you
                        can store them securely in our yards.
                      </p>
                      <hr />

                      <p className="bold-text">Dedicated assistance</p>
                      <p className="bottom-te">
                        From assisting you in recovering your policy excess and
                        out-of-pocket expenses to handling your{" "}
                        <strong>
                          {" "}
                          Insurance Factory car insurance claim,{" "}
                        </strong>{" "}
                        our solicitors will support you at every step.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="cl4" ref={sidebarRef}>
                  <Sidebarinner />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="photographs">
        <div className="container">
          <div className="row">
            <h2 className="road">
              We make recovery after a non-fault car{" "}
              <span className="light">
                accident <br />
                as easy as possible
              </span>{" "}
            </h2>
            <div className="col-xxl-6 col-xl-6 col-md-12 col-12">
              <div className="fourbox">
                <ul>
                  <li>
                    <img src={check} className="check" alt="" />
                    Our comprehensive range of services is designed to address
                    every aspect of your needs following a car accident.
                  </li>
                  <hr />
                  <li>
                    <img src={check} className="check" alt="" />
                    Our team works tirelessly to ensure your vehicle recovery
                    and repair process is as quick as possible.
                  </li>
                  <hr />
                  <li>
                    <img src={check} className="check" alt="" />
                    If we believe there are prospects for success in pursuing
                    claims, we will put you in touch with an experienced
                    solicitor who will advise you on whether or not the claim
                    should be pursued.
                  </li>
                  <hr />
                  <li>
                    <img src={check} className="check" alt="" />
                    In case you need another vehicle, call the{" "}
                    <strong>
                      {" "}
                      Insurance Factory car insurance claims telephone number.{" "}
                    </strong>{" "}
                    You will get the same type of vehicle.
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-xxl-6 col-xl-6 col-md-12 col-12">
              <div className="fourbox">
                <ul>
                  <li>
                    <img src={check} className="check" alt="" />
                    All the parties shall be engaged directly so that quick
                    resolution of the{" "}
                    <strong> Insurance Factory car accident claim </strong>can
                    be achieved.
                  </li>
                  <hr />
                  <li>
                    <img src={check} className="check" alt="" />
                    An independent engineer will be appointed by us, who will
                    inspect your vehicle and assess damages.
                  </li>
                  <hr />
                  <li>
                    <img src={check} className="check" alt="" />
                    Our solicitors will help you recover all the monetary losses
                    incurred due to the accident from the other party’s insurer.
                  </li>
                  <hr />
                  <li>
                    <img src={check} className="check" alt="" />
                    Once repairs are done on your vehicle, pick-up and return
                    will be scheduled at times convenient for you.
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="carprocess">
        <div className="container ">
          <div className="row">
            <div className="col-xxl-12 col-xxl-12 col-md-12 col-12">
              <h2 className="trusted-title">
                {" "}
                Our complete range of accident <br />
                <span className="light">management solutions</span>{" "}
              </h2>
            </div>
          </div>
          <div className="row" id="possible">
            <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-12">
              <div className="claim">
                <ul>
                  <li>
                    <span className="sleej">Car recovery:</span>We exclusively
                    concentrate on delivering swift and efficient vehicle
                    recoveries to guarantee that there are no hassles from the
                    time you phone us at the{" "}
                    <strong>
                      {" "}
                      Insurance Factory car accident claim number.{" "}
                    </strong>{" "}
                  </li>
                  <li>
                    <span className="sleej">Accidental repairs:</span>In order
                    for your non-fault vehicle accident repair work to commence
                    as soon as possible, talk to our team using the{" "}
                    <strong>
                      {" "}
                      Insurance Factory car insurance claim number.{" "}
                    </strong>{" "}
                    We shall organise a reputable repair centre to fix your car.
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-12">
              <div className="claim" id="blue">
                <ul>
                  <li>
                    <span className="sleej">Vehicle storage:</span> Do not worry
                    about where to keep it after the accident. Call the{" "}
                    <strong>
                      {" "}
                      Insurance Factory car insurance claim number,{" "}
                    </strong>
                    and we’ll store your vehicle in our storage yards. Our
                    first-class storage facilities can be used for safeguarding
                    and storing your vehicle with zero upfront fees, protecting
                    it from other harms that can lead to damages.
                  </li>
                  <li>
                    <span className="sleej">Courtesy car:</span>If you are not
                    responsible for the accident, you are legally entitled to a
                    replacement vehicle. To get back on track with a
                    like-for-like replacement vehicle on the same day, call us
                    through our{" "}
                    <strong>
                      {" "}
                      Insurance Factory car accident claims number.{" "}
                    </strong>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-12">
              <div className="claim">
                <ul>
                  <li>
                    <span className="sleej">Personal injury assistance:</span>
                    In case of any severe or minor injuries caused by a car
                    accident, we can provide you with free legal advice and
                    ensure that you get the compensation you deserve through the
                    <strong> Insurance factory car accident claims. </strong>
                  </li>
                  <li>
                    <span className="sleej">Claim management:</span>We make sure
                    everything from arranging replacement vehicles to managing
                    repairs, liaising with all parties and handling paperwork
                    and documentation is done in relation to the{" "}
                    <strong> Insurance Factory car insurance claim. </strong>{" "}
                    Contact us through{" "}
                    <a href="tel:0800 772 0850">0800 772 0850</a> when you want
                    to start your claim.
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="yellowcta">
        <div className="container">
          <div className="row">
            <div className="cta2">
              <div className="col-xxl-8 col-xl-8 col-lg-8 col-12">
                <div className="legal">
                  <h3 className="experienced">
                    Got more questions?{" "}
                    <span className="light">Talk to our specialists </span>{" "}
                  </h3>
                  <p className="professionals">
                    Get in touch with our no-fault claim experts via{" "}
                    <strong>
                      car insurance claims number{" "}
                      <a className="cpt-no" href="tel:0800 772 0850">
                        {" "}
                        0800 772 0850{" "}
                      </a>{" "}
                    </strong>
                    to get the best advice for your{" "}
                    <strong> Insurance Factory car insurance claim. </strong>
                  </p>
                </div>
              </div>
              <div className="col-xxl-4 col-xl-4 col-lg-4 col-12">
                <div class="process">
                  <a class="tf-button style-1 phone" href="tel:0800 772 0850">
                    Get in touch<span class="icon-keyboard_arrow_right"></span>
                  </a>
                </div>
                <div class="process">
                  <a
                    className="tf-button style-1 desktop"
                    onClick={handleScrollToSidebar}
                  >
                    Get in touch
                    <span className="icon-keyboard_arrow_right"></span>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <search className="updatedform">
        <div className="container">
          <div className="row text-center">
            <div className="col-xxl-12 col-xl-12 col-md-12 col-12">
              <h2 className="whiteheading">
                {" "}
                What makes us stand out <br />
                <span className="light">against others? </span>
              </h2>
              <p className="sustained">
                Car accidents can be traumatic. We are your trusted partners who
                will support you following a no-fault car accident. Our accident
                management solutions help minimise stress-related issues as well
                as anxiety, saving time and energy and giving room to focus on
                yourself.
              </p>
            </div>
          </div>
          <div className="row">
            <div className="col-xxl-4 col-xl-4 col-lg-6 col-md-12 col-12">
              <div className="Claimant">
                <h5> Manufacturer recommended repairs </h5>
                <p className="collision">
                  Reach us via the{" "}
                  <strong> Insurance factory car accident claim number </strong>{" "}
                  for professional and high-quality repair of your vehicle at
                  our manufacturer-approved repair network.
                </p>
                <h5>Dedicated claims handling</h5>
                <p className="collision">
                  This includes assessing the damage, reviewing the claim, and
                  speaking to insurers, repairers and other parties involved in
                  order to quickly resolve claims.{" "}
                </p>

                <h5> Fast support </h5>
                <p className="collision">
                  Fast support{" "}
                  <strong> Insurance Factory car insurance helpline, </strong>{" "}
                  our team will be sent immediately to your aid for quick
                  assistance.
                </p>
              </div>
            </div>
            <div className="col-xxl-4 col-xl-4 col-lg-6 col-md-12 col-12">
              <div className="Claimant">
                <h5> Replacement vehicle on the same day </h5>
                <p className="collision">
                  Call us today on <a href="tel:0800 772 0850">0800 772 0850</a>{" "}
                  and we will arrange for a like-for-like replacement vehicle at
                  no extra cost to you.
                </p>
                <h5> Zero excess </h5>
                <p className="collision">
                  In case of a non-fault accident, there is no need to worry
                  about paying any upfront costs as such. We claim this back
                  from the third party’s insurer.
                </p>

                <h5> Authentic repairs </h5>
                <p className="collision">
                  The best repairs will be done on your damaged car using
                  genuine spare parts and paint through authorised repair
                  agents.{" "}
                </p>
              </div>
            </div>
            <div className="col-xxl-4 col-xl-4 col-lg-12 col-md-12 col-12">
              <div className="Claimant">
                <h5> Transparent procedure </h5>
                <p className="collision">
                  We have one of our experienced claim handlers who ensures that
                  you are updated on how your{" "}
                  <strong> Insurance Factory car accident claim </strong> is
                  proceeding.
                </p>

                <h5> Storage protection </h5>
                <p className="collision">
                  Feel secure knowing your vehicle will be kept in highly
                  secured storage facilities until it's fixed.
                </p>

                <h5>Relaxation assured</h5>
                <p className="collision">
                  We are here to handle everything related to{" "}
                  <strong> Insurance Factory-car accident claims </strong> for
                  you, saving you from stress and burden.
                </p>
              </div>
            </div>
          </div>
        </div>
      </search>

      <section className="Accident-claim">
        <div className="container">
          <div className="row">
            <div className="needed">
              <h2 className="trusted-title">
                How can you maximise the potential of your <br />
                <span className="light">
                  {" "}
                  Insurance Factory car accident claims?
                </span>
              </h2>
              <p className="complexities">
                Getting maximum compensation is vital when you’ve suffered
                serious injuries in a no-fault car accident. However, your case
                value depends on what you do next after the collision incident.
                Here are some steps to take in order for you to get fair
                compensation for your losses and injuries.{" "}
              </p>
            </div>
            <hr />
          </div>
          <div className="row" id="incdes">
            <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-12">
              <div className="Preparing">
                <ul>
                  <li>
                    <div className="seemingly">
                      <h5 className="Seek">Seek medical attention:</h5>
                      <p>
                        {" "}
                        Immediately seek medical attention to verify your claims
                        by taking hospital records showing the extent of your
                        injuries.
                      </p>
                    </div>
                  </li>
                  <li>
                    <div className="seemingly">
                      <h5 className="Seek">Report to the police: </h5>
                      <p>
                        Call the police and tell them about the accident. The
                        information contained in a police report may be very
                        helpful if one is seeking justice or wants the insurance
                        company to compensate them fairly.
                      </p>
                    </div>
                  </li>
                  <li>
                    <div className="seemingly">
                      <h5 className="Seek">Gather evidence:</h5>
                      <p>
                        Strengthen your{" "}
                        <strong> Insurance Factory car accident claim </strong>{" "}
                        case by gathering important pieces of evidence like
                        images of where it occurred, damages on vehicles as well
                        as bodily harm caused.
                      </p>
                    </div>
                  </li>
                  <li>
                    <div className="seemingly">
                      <h5 className="Seek">Exchange details</h5>
                      <p>
                        Since the other driver is at fault, swap vital contacts
                        with them such as name, place of residence, phone
                        numbers and insurance information.
                      </p>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
            <div
              className="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-12"
              id="dualcardiv"
            >
              <img src={dualcar} alt="" className="dualcar" />
            </div>
            <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-12">
              <div className="Preparing">
                <ul>
                  <li>
                    <div className="seemingly">
                      <h5 className="Seek">Collect witnesses’ statements:</h5>
                      <p>
                        Inquire from witnesses who were around or any other
                        person concerning that event in anticipation of future
                        calls.
                      </p>
                    </div>
                  </li>
                  <li>
                    <div className="seemingly">
                      <h5 className="Seek">
                        {" "}
                        Refuse any offer for early settlement{" "}
                      </h5>
                      <p>
                        The insurance firm of the other party may force you into
                        accepting an early settlement that is much lower than
                        what you deserve. Do not accept such a settlement
                        without talking to your solicitor about it.
                      </p>
                    </div>
                  </li>
                  <li>
                    <div className="seemingly">
                      <h5 className="Seek">Request dash cam footage:</h5>
                      <p>
                        Seek dash cam footage. It will provide substantial
                        visual evidence of how the accident happened.{" "}
                      </p>
                    </div>
                  </li>
                  <li>
                    <div className="seemingly">
                      <h5 className="Seek">Start claim </h5>
                      <p>
                        Making{" "}
                        <strong> Insurance Factory car accident claims </strong>{" "}
                        immediately after no-fault car accidents will show there
                        are high damages suffered. Immediately call us on the{" "}
                        <strong>
                          {" "}
                          Insurance Factory car accident claim number{" "}
                        </strong>{" "}
                        to initiate your claim process.
                      </p>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="whyus">
        <div className="container">
          <div className="row">
            <div className="col-xxl-12 col-md-12">
              <div className="why">
                <h2 className="choose">
                  It’s simple-How the{" "}
                  <span className="light">Process Works </span>
                </h2>
                <p className="complexities">
                  Our direct and easy claims process enables you to make your{" "}
                  <strong> Insurance Factory car insurance claim </strong>
                  easily. Here’s how you can file a claim with us.
                </p>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-xxl-3 col-xl-6 col-lg-6 col-md-6 col-12">
              <div className="box-service">
                <img src={check} className="check" alt="" />
                {/* <img src={experience} className="experience" alt="" /> */}
                <h4>Get in touch</h4>
                <p>
                  Contact our dedicated team through the{" "}
                  <strong>
                    {" "}
                    Insurance Factory car insurance claims number{" "}
                  </strong>{" "}
                  and set up a free meeting with our solicitors’ panellists.
                </p>
              </div>
            </div>
            <div className="col-xxl-3 col-xl-6 col-lg-6 col-md-6 col-12">
              <div className="box-service">
                <img src={check} className="check" alt="" />
                <h4>Share account details</h4>
                <p>
                  Give out information regarding when, where and how the
                  accident happened.
                </p>
              </div>
            </div>
            <div className="col-xxl-3 col-xl-6 col-lg-6 col-md-6 col-12">
              <div className="box-service">
                <img src={check} className="check" alt="" />
                <h4>Submit proof</h4>
                <p>
                  Share with our solicitors if there is any important
                  information or evidence you have collected at the scene of an
                  accident/in the event of an accident scene.{" "}
                </p>
              </div>
            </div>
            <div className="col-xxl-3 col-xl-6 col-lg-6 col-md-6 col-12">
              <div className="box-service">
                <img src={check} className="check" alt="" />
                <h4>Claim submission</h4>
                <p>
                  We will process and present your{" "}
                  <strong> Insurance Factory car accident claim </strong> upon
                  receiving all required documents and details.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="faqs">
        <div className="container">
          <div className="row">
            <div className="col-xxl-12 col-xl-12 col-md-12 col-12">
              <div className="fq">
                <h2>
                  {" "}
                  Frequently Asked{" "}
                  <span className="light">Questions (FAQ)</span>
                </h2>
                <p className="complexities">
                  Got questions about the{" "}
                  <strong> Insurance Factory car insurance claim? </strong> We
                  have them answered here.
                </p>
              </div>
              <div className="allfaq">
                <Accordion defaultActiveKey="0">
                  <Accordion.Item eventKey="0">
                    <Accordion.Header>
                      Am I eligible for a replacement vehicle following a
                      non-fault road traffic accident?
                    </Accordion.Header>
                    <Accordion.Body>
                      Yes, we can lend you a courtesy car while yours is being
                      repaired if it is not drivable due to the damage suffered
                      in an accident.
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="1">
                    <Accordion.Header>
                      Can I use my own preferred garage for vehicle repairs?
                    </Accordion.Header>
                    <Accordion.Body>
                      Certainly, provide us with contact information for your
                      preferred repairer so that we may arrange for repairs to
                      be done at any local repair centre of your choice.
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="2">
                    <Accordion.Header>
                      How long do vehicle repairs take?
                    </Accordion.Header>
                    <Accordion.Body>
                      Unfortunately, we cannot give an exact time scale on how
                      long your car will be in for repairs. However, we shall
                      liaise with the company doing the repair and keep you
                      informed as work progresses.
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="3">
                    <Accordion.Header>
                      How can you help if my car is a total loss?{" "}
                    </Accordion.Header>
                    <Accordion.Body>
                      If the car is beyond repair, you can call us on{" "}
                      <a href="tel:0800 772 0850">0800 772 0850</a> and we will
                      arrange for an independent engineer to value your car and
                      organise a replacement vehicle until the settlement of
                      your claim.
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="4">
                    <Accordion.Header>
                      Should I report the accident to the police?
                    </Accordion.Header>
                    <Accordion.Body>
                      Yes. You must report the accident within 24 hours by
                      calling the police as soon as possible. The police report
                      might be crucial evidence for supporting the Insurance
                      Factory’s insurance claim.
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="5">
                    <Accordion.Header>
                      How do I get my vehicle recovered following a non-fault
                      auto collision?{" "}
                    </Accordion.Header>
                    <Accordion.Body>
                      Dial the Insurance Factory car insurance helpline if you
                      need emergency roadside recovery following non-fault car
                      accidents. Our agents are always on standby and will send
                      out a team to recover your vehicle.
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="6">
                    <Accordion.Header>
                      Can I seek compensation for personal injuries that occur
                      due to an accident?{" "}
                    </Accordion.Header>
                    <Accordion.Body>
                      Yes. If because of somebody else’s negligence, you have
                      suffered any injury, you may claim compensation against
                      the other party’s insurer. Get in touch with a specialist
                      solicitor who can advise on your claim and ensure that you
                      receive rightful compensation for injuries sustained.
                    </Accordion.Body>
                  </Accordion.Item>
                </Accordion>
              </div>
            </div>
          </div>
        </div>
      </section>

      <Footer />
    </>
  );
};

export default Insurancefactorycar;
