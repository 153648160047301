import React, { useRef } from "react";
import Accordion from "react-bootstrap/Accordion";
import check from "../assets/images/check.png";
import dualcar from "../../src/assets/images/dualcar.webp";
import carbnr1 from "../../src/assets/images/carbnr1.webp";
import Sidebarinner from "../components/Sidebar-inner";
import phonecall from "../assets/images/phonecall.png";
import Footer from "../components/footer";
import { Helmet } from "react-helmet";

const Tempcover = () => {
  const sidebarRef = useRef(null);

  const handleScrollToSidebar = () => {
    if (sidebarRef.current) {
      sidebarRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Tempcover Car Insurance Accident Claim Phone Number</title>
        <meta
          name="description"
          content="Want to receive fair compensation for the losses suffered in a car accident? Call us on the Tempcover Car Insurance Accident Claim Phone Number 0800 772 0850. "
        />
        <link
          rel="canonical"
          href="https://insurance-accident-claims.co.uk/tempcover"
        />
      </Helmet>
      <section className="innernav">
        <div className="container">
          <div className="row">
            <div className="col-md-6 col-sm-6">
              <div className="left-logo">
                <h3>
                  Accident<span className="sipo"> Claims</span>
                </h3>
              </div>
            </div>
            <div className="col-md-6 col-sm-6">
              <div className="left-cta">
                <div class="headercnt">
                  <div class="chatmsg">
                    <img
                      src={phonecall}
                      className="phonecall"
                      alt="phonecall "
                    />
                  </div>
                  <div class="box-body">
                    <h3 class="nav-info-box-title">Toll Free Number </h3>
                    <p>
                      <a href="tel:0800 772 0850">0800 772 0850</a>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section
        className="newbnr"
        style={{ backgroundImage: "url(" + carbnr1 + ")" }}
      >
        <div className="container">
          <div className="row">
            <div className="col-xxl-8 col-xl-8 col-md-10 col-12">
              <div className="innerbnr">
                <h1>
                  <span className="onespan"> Car Accident </span>{" "}
                  <span className="sci">  insurance claims </span> experts
                </h1>
                <p className="brokerage">
                  Let us be your first stop for all your accident management needs and claims assistance after a no-fault car accident.
                </p>
                <div className="bnrbtn">
                  <a class="db-btn-border btn-rollover phone" href="tel:0800 772 0850">
                    Road Accident Claim Help?{" "}
                    <i class="fa-solid fa-arrow-right"></i>
                  </a>
                </div>
                <div className="bnrbtn">
                  <a className="db-btn-border btn-rollover desktop" onClick={handleScrollToSidebar}>
                    Road Accident Claim Help? <i className="fa-solid fa-arrow-right"></i>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="stickysection">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="group-4-8">
                <div className="cl8 tf-tab">
                  <div className="content-tab">
                    <div className="inner-content">
                      <h2>
                        We offer the best accident assistance {" "}
                        <span className="light">
                          at no extra cost{" "}
                        </span>{" "}
                      </h2>
                      <p className="disciplines">
                        When you are grappling with the aftermath of a road accident, don’t hesitate to inform us. Just call us on <strong> Tempcover's car insurance claims contact number </strong> now and receive holistic accident management, from vehicle recovery to claims handling– all at no upfront charges whatsoever.
                      </p>
                      <hr />
                      <p className="bold-text">No insurance excesses</p>
                      <p className="bottom-te">
                        Instead of claiming on your own insurance policy, we act directly against the negligent party by making a <strong> Tempcover car insurance claim, </strong>{" "}
                        saving you from paying policy excess.{" "}
                      </p>
                      <hr />
                      <p className="bold-text">No-claims bonus safety</p>
                      <p className="bottom-te">
                        Since we do not make a claim on your own insurance policy, your no-claims bonus will stay safe.
                      </p>
                      <hr />
                      <p className="bold-text">
                        Specialised claims handler
                      </p>
                      <p className="bottom-te">
                        Additionally, we will assign a dedicated claims handler who will oversee your{" "}
                        <strong>
                          Tempcover car accident claims,{" "}
                        </strong>
                        safeguarding your interests.{" "}
                      </p>
                      <hr />
                      <p className="bold-text">
                        Manufacturer approved repairs{" "}
                      </p>
                      <p className="bottom-te">
                        Our substantial number of approved UK repairers guarantees superior repair quality using only authorised manufacturer parts and paints.{" "}
                      </p>
                      <hr />
                      <p className="bold-text">Like-for-like replacement vehicle</p>
                      <p className="bottom-te">
                        A replacement vehicle that matches your own car in terms of size, make, and model will be provided when you make{" "} <strong> Tempcover’s car insurance claim. </strong>
                      </p>
                      <hr />
                      <p className="bold-text">24/7 recovery and storage</p>
                      <p className="bottom-te">
                        We provide round-the-clock vehicle recovery and safe storage assistance to ensure your safety. So, when you call us at the <strong> Tempcover car insurance claims contact number, </strong> you're guaranteed to receive immediate assistance.
                      </p>
                      <hr />
                      <p className="bold-text">Emergent support</p>
                      <p className="bottom-te">
                        Call us on{" "} <a href="tel:0800 772 0850">0800 772 0850</a> to report your claim. We have a dedicated team answering all calls. You don’t have to wait for hours before assistance arrives due to our quick turnaround time.
                      </p>
                      <hr />
                      <p className="bold-text">Minimised administrative load</p>
                      <p className="bottom-te">
                        We will take care of all the paperwork and documentation so that you can concentrate on recovering after the accident.{" "}
                      </p>
                      <hr />
                      <p className="bold-text"> Speedy claims resolution </p>
                      <p className="bottom-te">
                        As such, our claims process procedure is designed in such a way that ensures promptness when settling your claims, enabling you to get deserving compensation within the shortest time possible.{" "}
                      </p>
                      <hr />

                      <p className="bold-text">Top legal support</p>
                      <p className="bottom-te">
                        Consequently, you are certain of getting professional advice since our solicitors are experts in dealing with non-fault motor accidents in the UK.{" "}
                      </p>
                    </div>
                  </div>
                </div>
                <div className="cl4" ref={sidebarRef}>
                  <Sidebarinner />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="photographs">
        <div className="container">
          <div className="row">
            <h2 className="road">
              We will help you get back on the road to  {" "}
              <span className="light">
                recovery <br />
                after a road traffic accident.
              </span>{" "}
            </h2>
            <div className="col-xxl-6 col-xl-6 col-md-12 col-12">
              <div className="fourbox">
                <ul>
                  <li>
                    <img src={check} className="check" alt="" />
                    We are your ultimate destination for top-notch support and claims advice, following a no-fault car accident.
                  </li>
                  <hr />
                  <li>
                    <img src={check} className="check" alt="" />
                    We’re here to assist you in every way as we take care of your {" "}
                    <strong> Tempcover car insurance claim </strong> from beginning to end.
                  </li>
                  <hr />
                  <li>
                    <img src={check} className="check" alt="" />
                    We’ll communicate with all involved parties to ensure you swiftly receive your compensation for{" "} <strong> Tempcover accident claims. </strong>
                  </li>
                  <hr />
                  <li>
                    <img src={check} className="check" alt="" />
                    Our team is highly committed and responsible. They will try their utmost best just to make sure that you are back in your right state of mind by offering a like-for-like vehicle replacement service.
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-xxl-6 col-xl-6 col-md-12 col-12">
              <div className="fourbox">
                <ul>
                  <li>
                    <img src={check} className="check" alt="" />
                    You can be sure that when it comes to having your car repaired by us, only skilled and manufacturer-approved technicians will handle it.
                  </li>
                  <hr />
                  <li>
                    <img src={check} className="check" alt="" />
                    While your own car is being serviced or fixed, we will provide you with another one resembling it in every respect.
                  </li>
                  <hr />
                  <li>
                    <img src={check} className="check" alt="" />
                    Our services will take away the headache and anxiety associated with handling <strong> Tempcover car accident claims </strong>via professional advice.
                  </li>
                  <hr />
                  <li>
                    <img src={check} className="check" alt="" />
                    We shall evaluate all the compensation which must be paid by the at-fault party for damages as well as loss of income.
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="carprocess">
        <div className="container ">
          <div className="row">
            <div className="col-xxl-12 col-xxl-12 col-md-12 col-12">
              <h2 className="trusted-title">
                {" "}
                Our accident management <br />
                <span className="light">solutions</span>{" "}
              </h2>
            </div>
          </div>
          <div className="row" id="possible">
            <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-12">
              <div className="claim">
                <ul>
                  <li>
                    <span className="sleej">Vehicle recovery:</span>Do you want your car recovered from the accident scene? You can reach us on <strong> Tempcover's car insurance claims contact number. </strong>Our team of experienced recovery experts will take the vehicle to a safe location.
                  </li>
                  <li>
                    <span className="sleej">Vehicle repairs:</span>For vehicle repairs, when you call us on the{" "} <strong> Tempcover car insurance claim number. </strong>{" "}
                    We make sure that your car is repaired to the highest standard of service through our trusted and reputable repairers across the UK.
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-12">
              <div className="claim" id="blue">
                <ul>
                  <li>
                    <span className="sleej">Safe vehicle storage:</span> If your car is not drivable following a no-fault accident, it will be placed in one of our secure storage facilities until it can be fixed or its claim assessed. We have specially trained staff who know how to safely transport your car in our storage yards, where it will be kept safe until your claim is assessed.
                  </li>
                  <li>
                    <span className="sleej">Replacement vehicle:</span>Following a no-fault car accident, we understand that most people want their cars back on the road quickly. To help you return to normal life, we will provide you with a temporary replacement vehicle exactly like yours at no cost. Contact us today on <strong> Tempcover’s car insurance claims contact number </strong>to secure a like-for-like replacement vehicle.
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-12">
              <div className="claim">
                <ul>
                  <li>
                    <span className="sleej">Personal injury support:</span>
                    If you got injured in an accident which was not your fault, we can help you to recover the expenses and claim compensation. Our experienced solicitors will accompany you through the process and ensure that you obtain rightful compensation.
                  </li>
                  <li>
                    <span className="sleej">Claims management:</span>From initiation of claims to settlement, we will guide you on every step of the{" "}
                    <strong> Tempcover car accident claim </strong>so as to ensure the best outcome.
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="yellowcta">
        <div className="container">
          <div className="row">
            <div className="cta2">
              <div className="col-xxl-8 col-xl-8 col-lg-8 col-12">
                <div className="legal">
                  <h3 className="experienced">
                    Got more questions? {" "}
                    <span className="light">Talk to our specialists</span>{" "}
                  </h3>
                  <p className="professionals">
                    Get in touch with our no-fault claim experts via{" "}
                    <strong>
                      insurance claims contact number{" "}
                      <a className="cpt-no" href="tel:0800 772 0850">
                        {" "}
                        0800 772 0850{" "}
                      </a>{" "}
                    </strong>
                    to get the best advice for your{" "}
                    <strong> Tempcover car accident claim. </strong>
                  </p>
                </div>
              </div>
              <div className="col-xxl-4 col-xl-4 col-lg-4 col-12">
                <div class="process">
                  <a class="tf-button style-1 phone" href="tel:0800 772 0850">
                    Get in touch<span class="icon-keyboard_arrow_right"></span>
                  </a>
                </div>
                <div class="process">
                  <a className="tf-button style-1 desktop" onClick={handleScrollToSidebar}>
                    Get in touch<span className="icon-keyboard_arrow_right"></span>
                  </a>
                </div>

              </div>
            </div>
          </div>
        </div>
      </section>
      <search className="updatedform">
        <div className="container">
          <div className="row text-center">
            <div className="col-xxl-12 col-xl-12 col-md-12 col-12">
              <h2 className="whiteheading">
                {" "}
                Why choose us for your accident <br />
                <span className="light">management needs? </span>
              </h2>
              <p className="sustained">
                We are among the top companies dealing with accidents in the UK and have helped many drivers who are not at fault obtain compensation for their losses. Our services for managing accidents cover a wide range of issues after road traffic accidents, relieving your stress and the complexity involved in it. For urgent assistance following a no-fault accident, call {" "}<a href="tel:0800 772 0850">0800 772 0850</a> any time.
              </p>
            </div>
          </div>
          <div className="row">
            <div className="col-xxl-4 col-xl-4 col-lg-6 col-md-12 col-12">
              <div className="Claimant">
                <h5> Hassle-free replacement vehicle </h5>
                <p className="collision">
                  In the event of an undrivable car due to a non-fault accident, we will supply you with an immediate like-for-like replacement vehicle.
                </p>

                <h5>24/7 claims handling</h5>
                <p className="collision">
                  Our team is always available to offer instant help,  ensuring that no delays occur during your{" "} <strong> Tempcover’s car insurance claim </strong>process.{" "}
                </p>

                <h5>Superior repairs</h5>
                <p className="collision">
                  Call <strong> Tempcover’s car insurance claim number </strong> for quick fixes for your damaged car from our manufacturer-approved repairers.
                </p>
              </div>
            </div>
            <div className="col-xxl-4 col-xl-4 col-lg-6 col-md-12 col-12">
              <div className="Claimant">
                <h5> Total claims handling </h5>
                <p className="collision">
                  From vehicle recovery to claims management,  we offer a fully inclusive suite of services.
                </p>
                <h5>No-win, no-fee</h5>
                <p className="collision">
                  You don't pay a penny unless your <strong> Tempcovercar accident claim </strong>wins. We have solicitors who work on a no-win, no-fee basis.
                </p>

                <h5> No excess fee</h5>
                <p className="collision">
                  If you make the {" "} <strong> Tempcover car insurance claim, </strong>you will not be charged any excess amount.
                </p>
              </div>
            </div>
            <div className="col-xxl-4 col-xl-4 col-lg-12 col-md-12 col-12">
              <div className="Claimant">
                <h5> Immediate breakdown help </h5>
                <p className="collision">
                  We’ll ensure swift recovery and repair of the vehicle so that you can get back to the road immediately within 24 hours.
                </p>

                <h5> Instant replacement car </h5>
                <p className="collision">
                  Without wasting time, we will provide you with another similar car that suits all your daily needs till yours is repaired.
                </p>

                <h5> No upfront costs </h5>
                <p className="collision">
                  We do not charge anything for our service. All expenses and costs are recovered from the at-fault party’s insurer.
                </p>
              </div>
            </div>
          </div>
        </div>
      </search>

      <section className="Accident-claim">
        <div className="container">
          <div className="row">
            <div className="needed">
              <h2 className="trusted-title">
                How can you ensure that you get the best Tempcover<br />
                <span className="light">  car insurance claim compensation?</span>
              </h2>
              <p className="complexities">
                Being involved in a no-fault accident can cost thousands of pounds for medical bills and vehicle repairs, as well as other significant economic losses associated with loss of income, pain & suffering. You have to follow some steps to establish a strong case and win the maximum possible compensation.{" "}
              </p>
            </div>
            <hr />
          </div>
          <div className="row" id="incdes">
            <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-12">
              <div className="Preparing">
                <ul>
                  <li>
                    <div className="seemingly">
                      <h5 className="Seek">Stop and assess yourself:</h5>
                      <p>
                        {" "}
                        Do not run away from the scene of an accident. Instead, park your car in a safe place away from traffic and check yourself and your passengers for any injuries.{" "}
                      </p>
                    </div>
                  </li>
                  <li>
                    <div className="seemingly">
                      <h5 className="Seek">Call the police: </h5>
                      <p>
                        Immediately call the police to report a car crash. The police report will contain all critical information about the accident, which will be used later on when filing your {" "} <strong> Tempcover car accident claim. </strong>
                      </p>
                    </div>
                  </li>
                  <li>
                    <div className="seemingly">
                      <h5 className="Seek">Collect evidence:</h5>
                      <p>
                        Take photos and videos at the scene of an accident, such as vehicles involved in it and injuries sustained by you. It will serve as crucial evidence to strengthen your claim.
                      </p>
                    </div>
                  </li>
                  <li>
                    <div className="seemingly">
                      <h5 className="Seek">Seek treatment:</h5>
                      <p>
                        If you or any passengers have been injured, make sure that they receive medical attention quickly so that their conditions do not deteriorate further. The medical reports may serve as vital proof for your injury claims.
                      </p>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
            <div
              className="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-12"
              id="dualcardiv"
            >
              <img src={dualcar} alt="" className="dualcar" />
            </div>
            <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-12">
              <div className="Preparing">
                <ul>
                  <li>
                    <div className="seemingly">
                      <h5 className="Seek">Record your losses well:</h5>
                      <p>
                        These might include medical expenses, loss of earnings, travelling costs, and legal fees incurred due to the accident.{" "}
                      </p>
                    </div>
                  </li>
                  <li>
                    <div className="seemingly">
                      <h5 className="Seek"> Avoid social media.</h5>
                      <p>
                        Until the claim is resolved, it is advised not to make any posts on social media. This can be used against you by the other person’s insurance company as well as their solicitors to reduce your amount of compensation.
                      </p>
                    </div>
                  </li>
                  <li>
                    <div className="seemingly">
                      <h5 className="Seek">Consult a no win no fee solicitor:</h5>
                      <p>
                        Talk to a specialist accident solicitor who deals with claims that are based on no-fault grounds and find out how to claim damages for loss or injury.{" "}
                      </p>
                    </div>
                  </li>
                  <li>
                    <div className="seemingly">
                      <h5 className="Seek"> Make a non-fault claim </h5>
                      <p>
                        Contact{" "} <strong> Tempcover’s car insurance claims number </strong> today to make a claim and get rightful compensation.
                      </p>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="whyus">
        <div className="container">
          <div className="row">
            <div className="col-xxl-12 col-md-12">
              <div className="why">
                <h2 className="choose">
                  Start Your Tempcover car insurance {" "}
                  <span className="light">claim in 4 simple steps!</span>
                </h2>
                <p className="complexities">
                  Making a non-fault car accident claim is a terrifying and stressful process. Nevertheless, as long as you have our well-organised process guiding you through step by step, you can easily file your{" "} <strong> Tempcover car insurance claim. </strong>Contact us now using the{" "}
                  <strong> Tempcover car insurance claim contact number </strong> to begin your claim.{" "}
                </p>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-xxl-3 col-xl-6 col-lg-6 col-md-6 col-12">
              <div className="box-service">
                <img src={check} className="check" alt="" />
                {/* <img src={experience} className="experience" alt="" /> */}
                <h4>Reach out</h4>
                <p>
                  Simply call our dedicated team on car insurance claims hotline{" "}
                  <strong> <a href="tel:0800 772 0850">0800 772 0850</a> </strong>for emergency support.
                </p>
              </div>
            </div>
            <div className="col-xxl-3 col-xl-6 col-lg-6 col-md-6 col-12">
              <div className="box-service">
                <img src={check} className="check" alt="" />
                <h4>Share accident information</h4>
                <p>
                  Tell us about the accident, including the date, time, place and circumstances of it.
                </p>
              </div>
            </div>
            <div className="col-xxl-3 col-xl-6 col-lg-6 col-md-6 col-12">
              <div className="box-service">
                <img src={check} className="check" alt="" />
                <h4>Claim evaluation</h4>
                <p>
                  Our solicitors will evaluate your{" "} <strong> Tempcover car accident claim </strong>to find the cost of damages.
                </p>
              </div>
            </div>
            <div className="col-xxl-3 col-xl-6 col-lg-6 col-md-6 col-12">
              <div className="box-service">
                <img src={check} className="check" alt="" />
                <h4>Claim settlement</h4>
                <p>
                  Once we have received all necessary documents and evidence, we will start resolving your claim as soon as possible.{" "}
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="faqs">
        <div className="container">
          <div className="row">
            <div className="col-xxl-12 col-xl-12 col-md-12 col-12">
              <div className="fq">
                <h2>
                  {" "}
                  Frequently Asked{" "}
                  <span className="light">Questions (FAQ)</span>
                </h2>
                <p className="complexities">
                  Got questions about the{" "}
                  <strong> Tempcover car insurance claim? </strong> We have them answered here.
                </p>
              </div>
              <div className="allfaq">
                <Accordion defaultActiveKey="0">
                  <Accordion.Item eventKey="0">
                    <Accordion.Header>
                      Am I entitled to a courtesy car after having a no-fault car accident?
                    </Accordion.Header>
                    <Accordion.Body>
                      Certainly! A courtesy vehicle will be given to you while repairs are being done through an approved repairer network.
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="1">
                    <Accordion.Header>
                      What is the implication of a Tempcover car insurance claim on my no- claims bonus?
                    </Accordion.Header>
                    <Accordion.Body>
                      If you’ve been involved in a car accident that was not your fault, there are high chances that it won’t affect your NCB since the claim will not be made against your own policy.
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="2">
                    <Accordion.Header>
                      Will you help my vehicle to get repaired?
                    </Accordion.Header>
                    <Accordion.Body>
                      When you call us on the <strong> Tempcover car insurance claim number </strong>to inform us of your collision, we will suggest an arrangement whereby you can take it to one of our repairers. Through this, we can accelerate the process as well as ensure that your damaged vehicle receives top-quality repairs.
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="3">
                    <Accordion.Header>
                      May I choose my preferred repairer?{" "}
                    </Accordion.Header>
                    <Accordion.Body>
                      Yes. Just provide us with your repairer's contact details, and we’ll book an appointment to repair your car.
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="4">
                    <Accordion.Header>
                      What kind of alternative vehicle will I get?
                    </Accordion.Header>
                    <Accordion.Body>
                      We will provide you with a like-for-like replacement vehicle which will match the size, make and model of your ownvehicle.
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="5">
                    <Accordion.Header>
                      Should I accept an outside court settlement from the other party?{" "}
                    </Accordion.Header>
                    <Accordion.Body>
                      Do not agree to any compensation offered by the insurance company representing the other party without talking to your solicitor because such a settlement is below expectation.{" "}
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="6">
                    <Accordion.Header>
                      Will your repairers use genuine parts to fix my car?{" "}
                    </Accordion.Header>
                    <Accordion.Body>
                      Yes, our chosen repair network consists of manufacturer-approved body shops that undertake excellent repairs using original components and spares so you can have faith in receiving high-quality repairs.
                    </Accordion.Body>
                  </Accordion.Item>
                </Accordion>
              </div>
            </div>
          </div>
        </div>
      </section>

      <Footer />
    </>
  );
};

export default Tempcover;
