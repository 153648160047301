import React, { useRef } from "react";
import Accordion from "react-bootstrap/Accordion";
import check from "../assets/images/check.png";
import dualcar from "../../src/assets/images/dualcar.webp";
import carbnr1 from "../../src/assets/images/carbnr1.webp";
import Sidebarinner from "../components/Sidebar-inner";
import phonecall from "../assets/images/phonecall.png";
import Footer from "../components/footer";
import { Helmet } from "react-helmet";

const Mypolicy = () => {
  const sidebarRef = useRef(null);

  const handleScrollToSidebar = () => {
    if (sidebarRef.current) {
      sidebarRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };
  return (
    <>
    <Helmet>
        <meta charSet="utf-8" />
        <title>My Policy Car Insurance Accident Claim Phone Number</title>
        <meta
          name="description"
          content="Need help with a car insurance claim in the UK? Call us on the My Policy Car Insurance Accident Claim Phone Number 0800 772 0850 for trusted claims assistance. "
        />
        <link
          rel="canonical"
          href="https://insurance-accident-claims.co.uk/my-policy"
        />
      </Helmet>
      <section className="innernav">
        <div className="container">
          <div className="row">
            <div className="col-md-6 col-sm-6">
              <div className="left-logo">
                <h3>
                  Accident<span className="sipo"> Claims</span>
                </h3>
              </div>
            </div>
            <div className="col-md-6 col-sm-6">
              <div className="left-cta">
                <div class="headercnt">
                  <div class="chatmsg">
                    <img
                      src={phonecall}
                      className="phonecall"
                      alt="phonecall "
                    />
                  </div>
                  <div class="box-body">
                    <h3 class="nav-info-box-title">Toll Free Number </h3>
                    <p>
                      <a href="tel:0800 772 0850">0800 772 0850</a>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section
        className="newbnr"
        style={{ backgroundImage: "url(" + carbnr1 + ")" }}
      >
        <div className="container">
          <div className="row">
            <div className="col-xxl-8 col-xl-8 col-md-10 col-12">
              <div className="innerbnr">
                <h1>
                  <span className="onespan">Fast and dependable</span>{" "}
                  <span className="sci"> accident </span> insurance claims.
                </h1>
                <p className="brokerage">
                  We will take care of everything from the initial assessment to
                  finalising your claim so that you can be back on the road as
                  soon as possible.
                </p>
                <div className="bnrbtn">
                  <a
                    class="db-btn-border btn-rollover phone"
                    href="tel:0800 772 0850"
                  >
                    Road Accident Claim Help?{" "}
                    <i class="fa-solid fa-arrow-right"></i>
                  </a>
                </div>
                <div className="bnrbtn">
                  <a
                    className="db-btn-border btn-rollover desktop"
                    onClick={handleScrollToSidebar}
                  >
                    Road Accident Claim Help?{" "}
                    <i className="fa-solid fa-arrow-right"></i>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="stickysection">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="group-4-8">
                <div className="cl8 tf-tab">
                  <div className="content-tab">
                    <div className="inner-content">
                      <h2>
                      Leading claims management experts {" "}
                        <span className="light">
                        in the UK {" "}
                        </span>{" "}
                      </h2>
                      <p className="disciplines">
                      We are one of the leading companies offering{" "}
                        <strong>
                          {" "}
                          My Policy insurance claims
                        </strong>{" "}
                        assistance in the UK, having successfully handled thousands of cases. To make a claim, call us using the <strong> My Policy car insurance contact number. </strong>We will manage your <strong> My Policy car insurance claim </strong>at no cost.
                      </p>
                      <hr />
                      <p className="bold-text">Zero excess </p>
                      <p className="bottom-te">
                      You won’t have to foot any insurance excesses if you did not cause the accident, thus preventing your premiums from increasing.
                      </p>
                      <hr />
                      <p className="bold-text">No-claims bonus security </p>
                      <p className="bottom-te">
                      Losing your no-claim bonus is not among our worries when you claim with us. We guarantee that your no-claims bonus remains unharmed with us.
                      </p>
                      <hr />
                      <p className="bold-text">Quick vehicle recovery</p>
                      <p className="bottom-te">
                      After an accident or collision, we do not want you left on the roadside helpless. Call immediately for emergency assistance on the{" "}
                        <strong>
                          {" "}
                          My Policy car insurance contact number,{" "}
                        </strong>
                        and we will ensure prompt and safe recovery of your vehicle at all times from wherever it might be stranded.{" "}
                      </p>
                      <hr />
                      <p className="bold-text"> Approved garages </p>
                      <p className="bottom-te">
                      Our extensive network of car manufacturers’ approved garages means we will get your vehicle repaired to the highest standard.{" "}
                      </p>
                      <hr />
                      <p className="bold-text">Like-for-like hire </p>
                      <p className="bottom-te">
                      When your car is being fixed, or it’s unroadworthy, we can give you a replacement vehicle of the same make and model as yours through the like-for-like hire.
                      </p>
                      <hr />
                      <p className="bold-text">
                      Expert legal team
                      </p>
                      <p className="bottom-te">
                      We have been successful in processing{" "}
                        <strong>
                          {" "}
                          My Policy insurance claims{" "}
                        </strong>{" "}
                        with our experienced solicitors, who are conversant with no-fault accident law, resulting in full compensation to victims.
                      </p>
                      <hr />
                      <p className="bold-text">
                      Fast processing of claims
                      </p>
                      <p className="bottom-te">
                      With our efficient and prompt claim procedure, there is no need for much paperwork and long waits. You can easily make your claim without paperwork headaches. 
                      </p>
                      <hr />
                      <p className="bold-text"> Free impartial advice </p>
                      <p className="bottom-te">
                      You may call us free on{" "}
                        <strong>
                          {" "}
                          <a href="tel:0800 772 0850">0800 772 0850</a>{" "}
                        </strong>{" "}
                        at any time regarding questions on <strong> My Policy insurance claims </strong> without any obligation. Our solicitors will advise and help you pursue your case accordingly.
                      </p>
                      <hr />
                      <p className="bold-text"> No-win, no-fee </p>
                      <p className="bottom-te">
                      Our solicitors work under a policy of operating on a “no-win-no-fee” basis when handling our clients’ insurance claims. So, you won’t pay anything if you lose your case.{" "}
                      </p>
                      <hr />

                      <p className="bold-text">24/7 assistance </p>
                      <p className="bottom-te">
                      In the event of an accident, we are always available to provide emergency assistance and guidance. For immediate assistance following a non-at-fault car accident, please call our car insurance claims line on{" "}<a href="tel:0800 772 0850">0800 772 0850</a>.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="cl4" ref={sidebarRef}>
                  <Sidebarinner />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="photographs">
        <div className="container">
          <div className="row">
            <h2 className="road">
            We shall ease the burden off your shoulders with    {" "}
              <span className="light">
              expert <br />
                accident management support
              </span>{" "}
            </h2>
            <div className="col-xxl-6 col-xl-6 col-md-12 col-12">
              <div className="fourbox">
                <ul>
                  <li>
                    <img src={check} className="check" alt="" />
                    In emergencies following car accidents, you should reach out to us using the{" "}
                    <strong>
                      {" "}
                      My Policy car insurance phone number. {" "}
                    </strong>
                  </li>
                  <hr />
                  <li>
                    <img src={check} className="check" alt="" />
                    We will establish if there is any party at fault who may be pursued for damages on your behalf.
                  </li>
                  <hr />
                  <li>
                    <img src={check} className="check" alt="" />
                    We shall assist you in the process of filing{" "}
                    <strong> My Policy insurance claims </strong>
                    to help you get compensation for the losses incurred.
                  </li>
                  <hr />
                  <li>
                    <img src={check} className="check" alt="" />
                    Our team will recover your vehicle from the accident scene and arrange repair work with our trusted network of manufacturer-approved repairers.
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-xxl-6 col-xl-6 col-md-12 col-12">
              <div className="fourbox">
                <ul>
                  <li>
                    <img src={check} className="check" alt="" />
                    In cases where your vehicle cannot be repaired, we will ask an independent engineer to value the damages.
                  </li>
                  <hr />
                  <li>
                    <img src={check} className="check" alt="" />
                    A temporary replacement vehicle will be provided until such time as your car is getting fixed.
                  </li>
                  <hr />
                  <li>
                    <img src={check} className="check" alt="" />
                    We will keep checking on how your repairs are going so that we can keep you informed about the progress made so far.
                  </li>
                  <hr />
                  <li>
                    <img src={check} className="check" alt="" />
                    Contact us on{" "}
                    <strong>
                      {" "}
                      <a href="tel:0800 772 0850">0800 772 0850</a>{" "}
                    </strong>{" "}
                    so that we can discuss further how you can make a  <strong> My Policy insurance claim </strong>to get compensation for damages and losses. 
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="carprocess">
        <div className="container ">
          <div className="row">
            <div className="col-xxl-12 col-xxl-12 col-md-12 col-12">
              <h2 className="trusted-title">
                {" "}
                Accident management services <br />
                <span className="light"> we offer </span>{" "}
              </h2>
            </div>
          </div>
          <div className="row" id="possible">
            <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-12">
              <div className="claim">
                <ul>
                  <li>
                    <span className="sleej">Vehicle recovery:</span>To have your vehicle recovered after an accident, call the{" "}
                    <strong>  My Policy car insurance contact number. </strong>
                    Our vehicle recovery services are designed to ensure the safety of your vehicle through quick recovery from the accident scene into our safe storage yards. 
                  </li>
                  <li>
                    <span className="sleej">Approved repairs:</span>In case you want your vehicle to be repaired, call our team using the <strong> My Policy insurance claim number. </strong>There are no excess costs for the repairs of your vehicle, which will take place in trusted and reliable garages that are found within the UK.
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-12">
              <div className="claim" id="blue">
                <ul>
                  <li>
                    <span className="sleej">Vehicle storage:</span> Are you worried about keeping your car after an accident that was not your fault? Contact us through our {" "}
                    <strong> My Policy insurance claim number. </strong>
                    We have well-maintained yards where we offer hassle-free storage services for vehicles while claims are being sorted out.
                  </li>
                  <li>
                    <span className="sleej">Replacement vehicles:</span>If your car is beyond repair, you qualify for a replacement vehicle. Call us via the emergency helpline{" "}
                    <strong>
                      {" "}
                      <a href="tel:0800 772 0850">0800 772 0850</a>{" "}
                    </strong>
                    and within 24 hours, we will make arrangements for another
                    vehicle for you.
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-12">
              <div className="claim">
                <ul>
                  <li>
                    <span className="sleej">Injury claims assistance:</span>
                    Calling our company on the <strong> My Policy insurance claim number </strong>
                    is the best way to establish if personal injury compensation is available to you or not. Our solicitors will give you invaluable advice towards securing damages arising from physical harm done to oneself.
                  </li>
                  <li>
                    <span className="sleej">Claim management:</span>From handling paperwork to bargaining with insurers, arranging vehicle repairs and offering alternative vehicles, we will take care of everything.
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="yellowcta">
        <div className="container">
          <div className="row">
            <div className="cta2">
              <div className="col-xxl-8 col-xl-8 col-lg-8 col-12">
                <div className="legal">
                  <h3 className="experienced">
                  Got more questions? {" "}
                    <span className="light">Talk to our specialists</span>{" "}
                  </h3>
                  <p className="professionals">
                  Get in touch with our no-fault claim experts via{" "}
                    <strong>
                    car insurance contact number{" "}
                      <a className="cpt-no" href="tel:0800 772 0850">
                        {" "}
                        0800 772 0850{" "}
                      </a>{" "}
                    </strong>
                    to get the best advice for the <strong> My Policy insurance claims. </strong>
                  </p>
                </div>
              </div>
              <div className="col-xxl-4 col-xl-4 col-lg-4 col-12">
                <div class="process">
                  <a class="tf-button style-1 phone" href="tel:0800 772 0850">
                    Get in touch<span class="icon-keyboard_arrow_right"></span>
                  </a>
                </div>
                <div class="process">
                  <a
                    className="tf-button style-1 desktop"
                    onClick={handleScrollToSidebar}
                  >
                    Get in touch
                    <span className="icon-keyboard_arrow_right"></span>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <search className="updatedform">
        <div className="container">
          <div className="row text-center">
            <div className="col-xxl-12 col-xl-12 col-md-12 col-12">
              <h2 className="whiteheading">
                {" "}
                What makes us  <br />
                <span className="light">different?</span>
              </h2>
              <p className="sustained">
              We will prioritise saving you from the stress of a no-fault car accident. Here are some benefits you get when making us your choice for claims management.
              </p>
            </div>
          </div>
          <div className="row">
            <div className="col-xxl-4 col-xl-4 col-lg-6 col-md-12 col-12">
              <div className="Claimant">
                <h5> 24/7 vehicle recovery </h5>
                <p className="collision">
                Looking for vehicle recovery services? Dial the  <strong> My Policy insurance claim number, </strong> and we’ll recover you from the accident scene regardless of what time it is.
                </p>

                <h5> Guaranteed repairs </h5>
                <p className="collision">
                Through our extensive network of repairers, we can arrange high-quality and guaranteed repairs to your car.{" "}
                </p>

                <h5> No increase in insurance premium </h5>
                <p className="collision">
                The <strong> My Policy car insurance claim </strong>will not be made against your own policy. So your insurance premium won’t be affected.
                </p>
              </div>
            </div>
            <div className="col-xxl-4 col-xl-4 col-lg-6 col-md-12 col-12">
              <div className="Claimant">
                <h5> Free replacement car </h5>
                <p className="collision">
                We will keep you mobile by providing a like-for-like replacement vehicle without any upfront charges.
                </p>
                <h5> A network of reliable partners </h5>
                <p className="collision">
                To provide you with excellent services, we have developed a strong network of approved repairers and competent solicitors in the UK.
                </p>

                <h5> Fast claims settlement </h5>
                <p className="collision">
                Our experienced solicitors negotiate with the other driver’s insurer to ensure your <strong> My Policy motor insurance claim </strong> is settled fairly and quickly.
                </p>
              </div>
            </div>
            <div className="col-xxl-4 col-xl-4 col-lg-12 col-md-12 col-12">
              <div className="Claimant">
                <h5> Immediate help </h5>
                <p className="collision">
                We promise immediate emergency assistance following a non-fault road traffic accident without any delay.
                </p>

                <h5>Tailored assistance </h5>
                <p className="collision">
                Your claim is our top priority. We, therefore, have a dedicated claims handler who will take responsibility for your case personally and keep you informed every step of the way.
                </p>

                <h5> No excess charges </h5>
                <p className="collision">
                You don’t need to make extra payments or bear out-of-pocket expenses for our services. We will recover all costs from the other party.
                </p>
              </div>
            </div>
          </div>
        </div>
      </search>

      <section className="Accident-claim">
        <div className="container">
          <div className="row">
            <div className="needed">
              <h2 className="trusted-title">
              How can you increase the chances of getting   <br />
                <span className="light"> adequate compensation? </span>
              </h2>
              <p className="complexities">
              It is distressing being involved in an accident, which isn’t your fault, but it is even more frustrating dealing with the claims processes. To maximise chances of obtaining adequate compensation, establish innocence beyond doubt by offering tangible evidence. Here is what you must do after a car accident if you want to get just enough money as compensation.{" "}
              </p>
            </div>
            <hr />
          </div>
          <div className="row" id="incdes">
            <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-12">
              <div className="Preparing">
                <ul>
                  <li>
                    <div className="seemingly">
                      <h5 className="Seek">Ensure your safety your safety:</h5>
                      <p>
                        {" "}
                        Stop your car at a safe place and check yourself and your passengers for major or minor injuries.{" "}
                      </p>
                    </div>
                  </li>
                  <li>
                    <div className="seemingly">
                      <h5 className="Seek">Get immediate medical help: </h5>
                      <p>
                      In case any passenger in your vehicle is hurt, call a professional doctor right away.
                      </p>
                    </div>
                  </li>
                  <li>
                    <div className="seemingly">
                      <h5 className="Seek">Collect evidence:</h5>
                      <p>
                      Essential evidence to be collected from the accident point are photos of where accidents have occurred, those responsible for the accident, damages caused, serious injuries and numbers on the number plates of all drivers.
                      </p>
                    </div>
                  </li>
                  <li>
                    <div className="seemingly">
                      <h5 className="Seek">Call police</h5>
                      <p>
                      Report the accident to the police. By calling law enforcement authorities, you will get enough guidance to support your <strong> My Policy insurance claim.  </strong>
                      </p>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
            <div
              className="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-12"
              id="dualcardiv"
            >
              <img src={dualcar} alt="" className="dualcar" />
            </div>
            <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-12">
              <div className="Preparing">
                <ul>
                  <li>
                    <div className="seemingly">
                      <h5 className="Seek">Exchange information:</h5>
                      <p>
                      If it’s possible, provide the other party with your insurance information as well as get theirs. Some of this information includes their full names, phone numbers, physical addresses, and licence plate numbers.{" "}
                      </p>
                    </div>
                  </li>
                  <li>
                    <div className="seemingly">
                      <h5 className="Seek">
                        {" "}
                        Collect eyewitness details:{" "}
                      </h5>
                      <p>
                      Information about witnesses, such as phone numbers can also be captured for future reference purposes.
                      </p>
                    </div>
                  </li>
                  <li>
                    <div className="seemingly">
                      <h5 className="Seek">
                      Note down expenses:
                      </h5>
                      <p>
                      Even if you are paid back entirely, it is still important that you keep the receipts of the costs incurred through repair services, medical attention, or any other expenses to get indemnified over all the charges. 
                      {" "}
                      </p>
                    </div>
                  </li>
                  <li>
                    <div className="seemingly">
                      <h5 className="Seek">Seek advice from a no-fault solicitor:</h5>
                      <p>
                      For advice on how to mount a successful claim for compensation under our no-fault regulation system, call us at the helpline number{" "}
                        <strong>
                          {" "}
                          <a href="tel:0800 772 0850">0800 772 0850</a>.{" "}
                        </strong>{" "}
                      </p>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="whyus">
        <div className="container">
          <div className="row">
            <div className="col-xxl-12 col-md-12">
              <div className="why">
                <h2 className="choose">
                Do you want to make a claim of {" "}
                  <span className="light">no-fault? Start now</span>
                </h2>
                <p className="complexities">
                It can be intimidating to make a<strong> My Policy motor insurance claim </strong>after being involved in an accident, which is not your mistake. Consequently, we will assist you with this process. Call us on <strong> My Policy car insurance phone number </strong>and start it.
                </p>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-xxl-3 col-xl-6 col-lg-6 col-md-6 col-12">
              <div className="box-service">
                <img src={check} className="check" alt="" />
                {/* <img src={experience} className="experience" alt="" /> */}
                <h4> Contact us </h4>
                <p>
                Contact our team on the <strong> My Policy car insurance contact number </strong>about your case.
                </p>
              </div>
            </div>
            <div className="col-xxl-3 col-xl-6 col-lg-6 col-md-6 col-12">
              <div className="box-service">
                <img src={check} className="check" alt="" />
                <h4> Give details </h4>
                <p>
                Please give us all the particulars of the accident, including the date, time, location and how it happened.
                </p>
              </div>
            </div>
            <div className="col-xxl-3 col-xl-6 col-lg-6 col-md-6 col-12">
              <div className="box-service">
                <img src={check} className="check" alt="" />
                <h4> Provide evidence </h4>
                <p>
                Provide some proof to support your claim. They may include photos of the accident scene, eyewitness statements, police reports and CCTV videos.{" "}
                </p>
              </div>
            </div>
            <div className="col-xxl-3 col-xl-6 col-lg-6 col-md-6 col-12">
              <div className="box-service">
                <img src={check} className="check" alt="" />
                <h4> Claims Processing </h4>
                <p>
                We will initiate your claim after assessment. Our team will keep you informed on how things are going.{" "}
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="faqs">
        <div className="container">
          <div className="row">
            <div className="col-xxl-12 col-xl-12 col-md-12 col-12">
              <div className="fq">
                <h2>
                  {" "}
                  Frequently Asked{" "}
                  <span className="light">Questions (FAQ)</span>
                </h2>
                <p className="complexities">
                Got questions about the{" "}
                  <strong> My Policy insurance claims? </strong>{" "}
                  We have them answered here.
                </p>
              </div>
              <div className="allfaq">
                <Accordion defaultActiveKey="0">
                  <Accordion.Item eventKey="0">
                    <Accordion.Header>
                    Do I need to pay extra charges for the replacement vehicle?
                    </Accordion.Header>
                    <Accordion.Body>
                    No, we charge all costs of replacing your vehicle from the insurer of the at-fault party. You only need to pay when you do not cooperate with us, use a solicitor without our knowledge or give false information regarding your case.
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="1">
                    <Accordion.Header>
                      How long does it take for my claim to settle?
                    </Accordion.Header>
                    <Accordion.Body>
                    We will make our best effort to finalise your <strong> My Policy car insurance claim </strong> as soon as possible, but the time to settle the claim will depend on the peculiarities of your case. Once we file your claim, we will keep you informed of any developments.
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="2">
                    <Accordion.Header>
                    Is the cost of vehicle recovery covered by the at-fault party’s insurance?
                    </Accordion.Header>
                    <Accordion.Body>
                    Yes, the insurance company of an at-fault party covers all expenses relating to the recovery of the vehicle. You may have to provide proof in the form of a police report or pictures taken at the scene indicating that you are entitled to receive compensation.{" "}
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="3">
                    <Accordion.Header>
                    Can I get a credit hire following a no-fault car accident?
                    {" "}
                    </Accordion.Header>
                    <Accordion.Body>
                    Yes. If you are a non-fault driver, you are eligible for credit hire. When you need a replacement vehicle because yours is written off or being repaired, call us on the <strong> My Policy insurance claim number. </strong>We’ll give you another car like yours until yours comes back onto the road.
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="4">
                    <Accordion.Header>
                    Will I lose my no-claims bonus if I’m not at fault?
                    </Accordion.Header>
                    <Accordion.Body>
                    If you submit your claim through us, we will not make a claim against your own insurance policy, and there is no risk of losing any of the years’ worth of bonuses or discounts that you have accumulated.
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="5">
                    <Accordion.Header>
                    Is it possible to use my own garage for car repairs?{" "}
                    </Accordion.Header>
                    <Accordion.Body>
                    Certainly! We can arrange for your vehicle to be repaired at your preferred garage. We will liaise with the repairer, ensuring that only the highest quality standards are met and fast repairs are performed.{" "}
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="6">
                    <Accordion.Header>
                    What kind of car will I get in place of mine?{" "}
                    </Accordion.Header>
                    <Accordion.Body>
                    We will arrange for a replacement vehicle similar in size, value and function to your own vehicle. You just need to call us on our <strong> My Policy insurance claim number </strong> and specify what you need. We shall then provide you with a suitable one.
                    </Accordion.Body>
                  </Accordion.Item>
                </Accordion>
              </div>
            </div>
          </div>
        </div>
      </section>

      <Footer />
    </>
  );
};

export default Mypolicy;
