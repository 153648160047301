import React, { useRef } from "react";
import Accordion from "react-bootstrap/Accordion";
import check from "../assets/images/check.png";
import dualcar from "../../src/assets/images/dualcar.webp";
import carbnr1 from "../../src/assets/images/carbnr1.webp";
import Sidebarinner from "../components/Sidebar-inner";
import phonecall from "../assets/images/phonecall.png";
import Footer from "../components/footer";
import { Helmet } from "react-helmet";

const Gogirl = () => {
  const sidebarRef = useRef(null);

  const handleScrollToSidebar = () => {
    if (sidebarRef.current) {
      sidebarRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Go Girl Car Insurance Accident Claim Phone Number</title>
        <meta
          name="description"
          content="Contact our team on the Go Girl Car Insurance Accident Claim Phone Number 0800 772 0850 for dedicated claims support and expert advice from our experienced solicitors. "
        />
        <link
          rel="canonical"
          href="https://insurance-accident-claims.co.uk/go-girl"
        />
      </Helmet>
      <section className="innernav">
        <div className="container">
          <div className="row">
            <div className="col-md-6 col-sm-6">
              <div className="left-logo">
                <h3>
                  Accident<span className="sipo"> Claims</span>
                </h3>
              </div>
            </div>
            <div className="col-md-6 col-sm-6">
              <div className="left-cta">
                <div class="headercnt">
                  <div class="chatmsg">
                    <img
                      src={phonecall}
                      className="phonecall"
                      alt="phonecall "
                    />
                  </div>
                  <div class="box-body">
                    <h3 class="nav-info-box-title">Toll Free Number </h3>
                    <p>
                      <a href="tel:0800 772 0850">0800 772 0850</a>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section
        className="newbnr"
        style={{ backgroundImage: "url(" + carbnr1 + ")" }}
      >
        <div className="container">
          <div className="row">
            <div className="col-xxl-8 col-xl-8 col-md-10 col-12">
              <div className="innerbnr">
                <h1>
                  <span className="onespan">Fast and reliable</span>{" "}
                  <span className="sci"> accident  </span> Insurance claims number.
                </h1>
                <p className="brokerage">
                  As quickly as possible, we will handle all areas of your claim from initial assessment to claim settlement.
                </p>
                <div className="bnrbtn">
                  <a
                    class="db-btn-border btn-rollover phone"
                    href="tel:0800 772 0850"
                  >
                    Road Accident Claim Help?{" "}
                    <i class="fa-solid fa-arrow-right"></i>
                  </a>
                </div>
                <div className="bnrbtn">
                  <a
                    className="db-btn-border btn-rollover desktop"
                    onClick={handleScrollToSidebar}
                  >
                    Road Accident Claim Help?{" "}
                    <i className="fa-solid fa-arrow-right"></i>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="stickysection">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="group-4-8">
                <div className="cl8 tf-tab">
                  <div className="content-tab">
                    <div className="inner-content">
                      <h2>
                        Experienced no-fault claim experts {" "}
                        <span className="light">in the UK </span>{" "}
                      </h2>
                      <p className="disciplines">
                        We are one of the best companies in the UK which provide{" "}
                        <strong> Go Girl car insurance claims </strong> help, having successfully concluded thousands of such cases. Call us through the{" "}
                        <strong>
                          {" "}
                          Go Girl car insurance phone number{" "}
                        </strong>
                        when you want to make a claim. We will manage your{" "}
                        <strong> Go Girl car insurance claim </strong>at no cost to you.
                      </p>
                      <hr />
                      <p className="bold-text">No excess fee required </p>
                      <p className="bottom-te">
                        If the accident is not caused by you, then there is no need for you to pay an extra charge, otherwise known as an excess.
                      </p>
                      <hr />
                      <p className="bold-text">NCB protection </p>
                      <p className="bottom-te">
                        Our main concern is not losing your no-claims bonus. In case of a successful claim with us, we guarantee you won’t lose your no-claims bonus.
                      </p>
                      <hr />
                      <p className="bold-text">Fast recovery service</p>
                      <p className="bottom-te">
                        We do not like victims staying stranded on the roadside. Calling for emergency help through the{" "}
                        <strong>
                          {" "}
                          Go Girl car insurance contact number{" "}
                        </strong>
                        will ensure prompt and safe recovery of your vehicle from wherever it may get stuck. {" "}
                      </p>
                      <hr />
                      <p className="bold-text"> High-quality repairs </p>
                      <p className="bottom-te">
                        We will repair your vehicle to the highest standard because of our extensive network of manufacturers’ approved garages.{" "}
                      </p>
                      <hr />
                      <p className="bold-text"> Like-for-like replacement vehicle </p>
                      <p className="bottom-te">
                        Assuming your car is being repaired or it is not roadworthy, we can provide you with a substitute vehicle that is the same make and model as yours through like-for-like hire.
                      </p>
                      <hr />
                      <p className="bold-text"> An experienced legal team </p>
                      <p className="bottom-te">
                        Our experienced solicitors have knowledge of no-fault accident law and will ensure the smooth processing of your{" "}
                        <strong> Go Girl insurance claims </strong> so that you get fair compensation for your losses.
                      </p>
                      <hr />
                      <p className="bold-text">Quick claims processing</p>
                      <p className="bottom-te">
                        You do not have to deal with complex paperwork and lengthy waits with our efficient and speedy claiming process. Your claim can easily be made without paperwork worries.
                      </p>
                      <hr />
                      <p className="bold-text"> Free impartial advice </p>
                      <p className="bottom-te">
                        For any inquiries concerning{" "}
                        <strong> Go Girl insurance claims, </strong>
                        call us on
                        <strong>
                          {" "}
                          <a href="tel:0800 772 0850">0800 772 0850</a>{" "}
                        </strong>{" "}
                        at no cost. Our solicitors will give guidance and take up your case accordingly.
                      </p>
                      <hr />
                      <p className="bold-text"> No-win, no-fee </p>
                      <p className="bottom-te">
                        When our solicitors are dealing with your insurance claims, they act on a policy of not charging for services unless the case is won. You also do not have to pay anything if you don’t win.{" "}
                      </p>
                      <hr />

                      <p className="bold-text"> Round-the clock support </p>
                      <p className="bottom-te">
                        We offer emergency assistance and advice in the event of an accident. If it was a non-fault car accident, dial our motor insurance helpline at{" "}
                        <a href="tel:0800 772 0850">0800 772 0850</a> for immediate assistance.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="cl4" ref={sidebarRef}>
                  <Sidebarinner />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="photographs">
        <div className="container">
          <div className="row">
            <h2 className="road">
              Expert accident management support that will take  {" "}
              <span className="light">
                away <br />
                the stress from your shoulders
              </span>{" "}
            </h2>
            <div className="col-xxl-6 col-xl-6 col-md-12 col-12">
              <div className="fourbox">
                <ul>
                  <li>
                    <img src={check} className="check" alt="" />
                    In emergencies following car accidents, you should reach out to us using the{" "}
                    <strong> Go Girl car insurance phone number. </strong>
                  </li>
                  <hr />
                  <li>
                    <img src={check} className="check" alt="" />
                    We will establish if there is any party at fault who may be pursued for damages on your behalf.
                  </li>
                  <hr />
                  <li>
                    <img src={check} className="check" alt="" />
                    We will assist you in filing{" "}
                    <strong> Go Girl Insurance claims </strong>
                    so as to enable you to get compensation for all losses incurred.
                  </li>
                  <hr />
                  <li>
                    <img src={check} className="check" alt="" />
                    Our team will recover your vehicle from the accident scene and organise repairs through our network of manufacturers-approved repairers who we trust.
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-xxl-6 col-xl-6 col-md-12 col-12">
              <div className="fourbox">
                <ul>
                  <li>
                    <img src={check} className="check" alt="" />
                    Where it is necessary that your vehicle is taken off-road, we may instruct an independent engineer to make an assessment of its value.
                  </li>
                  <hr />
                  <li>
                    <img src={check} className="check" alt="" />A temporary
                    Temporary provision of a replacement vehicle shall be done until such time when yours is getting fixed.
                  </li>
                  <hr />
                  <li>
                    <img src={check} className="check" alt="" />
                    We will continuously follow-up on the progress of your repairs in order to keep you updated.
                  </li>
                  <hr />
                  <li>
                    <img src={check} className="check" alt="" />
                    Contact us on{" "}
                    <strong>
                      {" "}
                      <a href="tel:0800 772 0850">0800 772 0850</a>{" "}
                    </strong>{" "}
                    so that we can discuss further how you can make a{" "}
                    <strong> Go Girl insurance claim </strong>to get compensation for damages and losses.
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="carprocess">
        <div className="container ">
          <div className="row">
            <div className="col-xxl-12 col-xxl-12 col-md-12 col-12">
              <h2 className="trusted-title">
                {" "}
                Accident management services <br />
                <span className="light"> we offer </span>{" "}
              </h2>
            </div>
          </div>
          <div className="row" id="possible">
            <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-12">
              <div className="claim">
                <ul>
                  <li>
                    <span className="sleej">Vehicle recovery:</span>For vehicle recovery services after an accident, call the{" "}
                    <strong> Go Girl car insurance contact number. </strong>
                    Our vehicle recovery services ensure that your vehicle is recovered from the scene of the accident and quickly returned to secure yards.
                  </li>
                  <li>
                    <span className="sleej">Approved repairs:</span>To have your car repaired, contact our team on the{" "}
                    <strong> Go Girl insurance claim number. </strong>We will arrange for your car to be repaired by trusted and manufacturer-approved repairers in the UK.
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-12">
              <div className="claim" id="blue">
                <ul>
                  <li>
                    <span className="sleej">Vehicle storage:</span> Would you like to store your car after an accident? Reach us through our{" "}
                    <strong> Go Girl insurance claim number.  </strong>
                    We engage in convenient storage of damaged cars during the settlement of claims in well-preserved storage yards.
                  </li>
                  <li>
                    <span className="sleej">Replacement vehicle:</span>If your car is beyond repair, you qualify for a replacement vehicle. Call us through the emergency helpline{" "}
                    <strong>
                      {" "}
                      <a href="tel:0800 772 0850">0800 772 0850</a>{" "}
                    </strong>
                    to get a like-for-like replacement vehicle so that you can continue with your usual life without any worries. You can hold it till when your claim is settled.
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-12">
              <div className="claim">
                <ul>
                  <li>
                    <span className="sleej">Injury claims assistance:</span>
                    For all those individuals who want to know if they are eligible for personal injury compensation or not, call us on the{" "}
                    <strong> Go Girl insurance claim number. </strong>
                    Our solicitors will give you priceless advice towards obtaining compensation for your injuries.
                  </li>
                  <li>
                    <span className="sleej">Claim management:</span>From paperwork handling to negotiation with insurers and arranging car repairs, plus the provision of alternative vehicles, we will handle it all.
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="yellowcta">
        <div className="container">
          <div className="row">
            <div className="cta2">
              <div className="col-xxl-8 col-xl-8 col-lg-8 col-12">
                <div className="legal">
                  <h3 className="experienced">
                    Do you have any further questions? {" "}
                    <span className="light"> Speak to our professionals </span>{" "}
                  </h3>
                  <p className="professionals">
                    Call us on the car insurance contact number{" "}
                    <strong>{" "}
                      <a className="cpt-no" href="tel:0800 772 0850">
                        {" "}
                        0800 772 0850{" "}
                      </a>{" "}
                    </strong>
                    and get the best advice ever concerning{" "}
                    <strong> Go Girl insurance claims. </strong>
                  </p>
                </div>
              </div>
              <div className="col-xxl-4 col-xl-4 col-lg-4 col-12">
                <div class="process">
                  <a class="tf-button style-1 phone" href="tel:0800 772 0850">
                    Get in touch<span class="icon-keyboard_arrow_right"></span>
                  </a>
                </div>
                <div class="process">
                  <a
                    className="tf-button style-1 desktop"
                    onClick={handleScrollToSidebar}
                  >
                    Get in touch
                    <span className="icon-keyboard_arrow_right"></span>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <search className="updatedform">
        <div className="container">
          <div className="row text-center">
            <div className="col-xxl-12 col-xl-12 col-md-12 col-12">
              <h2 className="whiteheading">
                {" "}
                What makes us <br />
                <span className="light">different?</span>
              </h2>
              <p className="sustained">
                Our purpose is to prioritise saving the no-fault accident victims from any stress and burden. Below are some of the advantages you enjoy as far as choosing us for your claim management is concerned.
              </p>
            </div>
          </div>
          <div className="row">
            <div className="col-xxl-4 col-xl-4 col-lg-6 col-md-12 col-12">
              <div className="Claimant">
                <h5> 24/7 vehicle recovery </h5>
                <p className="collision">
                  Need vehicle recovery services? Call the{" "}
                  <strong> Go Girl insurance claim number, </strong> and we will pick you up from the point of the accident.
                </p>

                <h5> Repair guarantee </h5>
                <p className="collision">
                  We have a wide network of repairers who can offer you quality and guaranteed repairs to your car.{" "}
                </p>

                <h5> No hike on premiums </h5>
                <p className="collision">
                  <strong> Go Girl car insurance claim </strong>won’t be initiated against your own policy, meaning that your premium will not rise.
                </p>
              </div>
            </div>
            <div className="col-xxl-4 col-xl-4 col-lg-6 col-md-12 col-12">
              <div className="Claimant">
                <h5> Free courtesy car </h5>
                <p className="collision">
                  We will provide you with another similar type of car without any upfront charges when yours is being repaired.
                </p>
                <h5> A group of trustworthy partners </h5>
                <p className="collision">
                  We have an extensive network of approved repairers as well as solicitors across the UK who are capable of providing top-notch services to our clients.
                </p>

                <h5> Speedy claims settlement </h5>
                <p className="collision">
                  Our experienced solicitors negotiate with the other driver’s insurer so that your{" "}
                  <strong> Go Girl motor insurance claim </strong> can be resolved fairly and swiftly.
                </p>
              </div>
            </div>
            <div className="col-xxl-4 col-xl-4 col-lg-12 col-md-12 col-12">
              <div className="Claimant">
                <h5> Instant help </h5>
                <p className="collision">
                  If it is a no-fault road traffic accident, we provide immediate emergency assistance without delay.
                </p>

                <h5> Dedicated claims handler  </h5>
                <p className="collision">
                  As such, there is a dedicated claims handler for your claim, who shall be responsible for your case personally and give regular updates on its progress through every stage.
                </p>

                <h5> No excess payments </h5>
                <p className="collision">
                  You don't have to make extra payments or incur any costs. We will recover all expenses from the other party.
                </p>
              </div>
            </div>
          </div>
        </div>
      </search>

      <section className="Accident-claim">
        <div className="container">
          <div className="row">
            <div className="needed">
              <h2 className="trusted-title">
                What are some of the ways you can improve your chances of  <br />
                <span className="light"> getting fair compensation? </span>
              </h2>
              <p className="complexities">
                It is difficult being part of an accident that was not your fault, but it is even worse dealing with insurance companies. For higher possibilities of full financial reimbursement, prove beyond every reasonable doubt that you were completely innocent and provide concrete evidence for that. If you really want to get a just amount as a payback, here’s what you should do after a car crash.
                {" "}
              </p>
            </div>
            <hr />
          </div>
          <div className="row" id="incdes">
            <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-12">
              <div className="Preparing">
                <ul>
                  <li>
                    <div className="seemingly">
                      <h5 className="Seek">Ensure your own safety:</h5>
                      <p>
                        {" "}
                        Stop your vehicle at an area where it cannot cause traffic jams and see if you or any passengers have been injured.{" "}
                      </p>
                    </div>
                  </li>
                  <li>
                    <div className="seemingly">
                      <h5 className="Seek">Seek immediate medical attention: </h5>
                      <p>
                        If any passenger in your car has been injured, call a professional medical expert immediately.
                      </p>
                    </div>
                  </li>
                  <li>
                    <div className="seemingly">
                      <h5 className="Seek">Gather proof:</h5>
                      <p>
                        Some key evidence to collect from the scene includes photographs and videos from the accident scene, the extent of damages caused, serious injuries suffered and pictures of vehicles involved.
                      </p>
                    </div>
                  </li>
                  <li>
                    <div className="seemingly">
                      <h5 className="Seek"> Contact law enforcement officers </h5>
                      <p>
                        Inform the police about the accident. The Police report will have enough evidence to back up your{" "}
                        <strong> Go Girl car accident claim. </strong>
                      </p>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
            <div
              className="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-12"
              id="dualcardiv"
            >
              <img src={dualcar} alt="" className="dualcar" />
            </div>
            <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-12">
              <div className="Preparing">
                <ul>
                  <li>
                    <div className="seemingly">
                      <h5 className="Seek">Share details:</h5>
                      <p>
                        In case possible, provide your insurance information to the other party and obtain theirs as well. Such details include names in full, telephone contacts, addresses and registration numbers.{" "}
                      </p>
                    </div>
                  </li>
                  <li>
                    <div className="seemingly">
                      <h5 className="Seek"> Record eyewitness information: </h5>
                      <p>
                        Information about witnesses, including phone numbers, addresses etc can be held for future reference.
                      </p>
                    </div>
                  </li>
                  <li>
                    <div className="seemingly">
                      <h5 className="Seek">Write down all expenses :</h5>
                      <p>
                        Even when you are fully reimbursed, still maintain the receipts of all repair costs incurred, medical bills or any other expenses to recover them from the other party. {" "}
                      </p>
                    </div>
                  </li>
                  <li>
                    <div className="seemingly">
                      <h5 className="Seek">
                        Ask a no-fault solicitor for guidance:
                      </h5>
                      <p>
                        For advice on how to make a successful no-fault claim to receive compensation for your losses, call us at{" "}
                        <strong>
                          {" "}
                          <a href="tel:0800 772 0850">0800 772 0850</a>{" "}
                        </strong>{" "}helpline number.
                      </p>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="whyus">
        <div className="container">
          <div className="row">
            <div className="col-xxl-12 col-md-12">
              <div className="why">
                <h2 className="choose">
                  Want to file a no-fault claim? {" "}
                  <span className="light">Start now</span>
                </h2>
                <p className="complexities">
                  After an accident that was not your fault, it is challenging to file a car insurance claim. With our simplified claims process, you can start your claim in just 4 easy steps. Dial the
                  <strong>  Go Girl car insurance contact number </strong> to initiate your claim.
                </p>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-xxl-3 col-xl-6 col-lg-6 col-md-6 col-12">
              <div className="box-service">
                <img src={check} className="check" alt="" />
                {/* <img src={experience} className="experience" alt="" /> */}
                <h4> Contact us </h4>
                <p>
                  Communicate with our team through the{" "}
                  <strong> Go Girl car insurance contact number </strong> concerning your claim.
                </p>
              </div>
            </div>
            <div className="col-xxl-3 col-xl-6 col-lg-6 col-md-6 col-12">
              <div className="box-service">
                <img src={check} className="check" alt="" />
                <h4> Provide details </h4>
                <p>
                  We want all the particulars of the accident, such as time, date and where it happened.
                </p>
              </div>
            </div>
            <div className="col-xxl-3 col-xl-6 col-lg-6 col-md-6 col-12">
              <div className="box-service">
                <img src={check} className="check" alt="" />
                <h4> Show proof </h4>
                <p>
                  Give some evidence to support your argument. This could include pictures from the scene of the accident, eyewitness accounts, police reports or CCTV videos.{" "}
                </p>
              </div>
            </div>
            <div className="col-xxl-3 col-xl-6 col-lg-6 col-md-6 col-12">
              <div className="box-service">
                <img src={check} className="check" alt="" />
                <h4> Claims Processing </h4>
                <p>
                  We will start processing your claim after assessing it. Our team will keep you posted on how things are developing.{" "}
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="faqs">
        <div className="container">
          <div className="row">
            <div className="col-xxl-12 col-xl-12 col-md-12 col-12">
              <div className="fq">
                <h2>
                  {" "}
                  Frequently Asked{" "}
                  <span className="light">Questions (FAQ)</span>
                </h2>
                <p className="complexities">
                  Have questions about{" "}
                  <strong> Go Girl insurance claims? </strong> Find them here.
                </p>
              </div>
              <div className="allfaq">
                <Accordion defaultActiveKey="0">
                  <Accordion.Item eventKey="0">
                    <Accordion.Header>
                      Do I have to pay extra fees for a replacement vehicle?
                    </Accordion.Header>
                    <Accordion.Body>
                      No, we can charge the insurer of their party at fault for all costs that involve replacing your vehicle. You will only be required to pay when you fail to cooperate with us or use a solicitor without our knowledge or provide us false information relating to your case.
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="1">
                    <Accordion.Header>
                      How much time do we need for the settlement of my claim?
                    </Accordion.Header>
                    <Accordion.Body>
                      We’ll do our best in order to finalise your{" "}
                      <strong> Go Girl car insurance claim </strong> as quickly as possible, but how long it takes depends entirely on what’s specific about your case. We shall notify you if there are any developments once we submit your claim.
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="2">
                    <Accordion.Header>
                      Is the cost of vehicle recovery covered by the at-fault party’s insurer?
                    </Accordion.Header>
                    <Accordion.Body>
                      Yes, the insurer of the at-fault party pays all costs related to recovering a car. Sometimes, you may be required to provide evidence, such as a police report or photographs taken from the scene, that demonstrate you are eligible for compensation.{" "}
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="3">
                    <Accordion.Header>
                      Can I get a credit hire following a no-fault car accident?{" "}
                    </Accordion.Header>
                    <Accordion.Body>
                      Absolutely! As long as you were not in any way responsible for causing the collision, then you can have one on credit. If your vehicle has been written off or is being fixed and it takes longer than usual, please call us on the{" "}
                      <strong> Go Girl insurance claim number, </strong> and we will replace your car with another similar one while yours is getting repaired.
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="4">
                    <Accordion.Header>
                      Will I lose my no-claims bonus if I’m not at fault?
                    </Accordion.Header>
                    <Accordion.Body>
                      When you choose us to make your claim, the claim will be made against the other party’s insurers, and therefore, there is no danger of losing out on any years’ worth of bonuses or discounts that have accrued over time.
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="5">
                    <Accordion.Header>
                      Can I use my own garage for car repair?{" "}
                    </Accordion.Header>
                    <Accordion.Body>
                      Certainly! We can facilitate repairs to your car at your chosen garage and make appointments with the repairer to ensure the use of top-quality parts and paint to fix your vehicle.{" "}
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="6">
                    <Accordion.Header>
                      What type of replacement vehicle will I get?{" "}
                    </Accordion.Header>
                    <Accordion.Body>
                      We will organise for a similar type of car that is equal in size, cost and purpose as yours. Call us on our{" "}
                      <strong> Go Girl insurance claim number </strong> and let us know what you are looking for, and we shall arrange one for you.
                    </Accordion.Body>
                  </Accordion.Item>
                </Accordion>
              </div>
            </div>
          </div>
        </div>
      </section>

      <Footer />
    </>
  );
};

export default Gogirl;
