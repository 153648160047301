import React, { useRef } from "react";
import insurancelogo from "../../src/assets/images/insurancelogo.png";
import Accordion from "react-bootstrap/Accordion";
import check from "../assets/images/check.png";
import dualcar from "../../src/assets/images/dualcar.webp";
import carbnr2 from "../../src/assets/images/carbnr2.webp";
import Sidebarinner from "../components/Sidebar-inner";
import phonecall from "../assets/images/phonecall.png";
import { Helmet } from "react-helmet";
import Footer from "../components/footer";

const Allianzcarinsurance = () => {
  const sidebarRef = useRef(null);

  const handleScrollToSidebar = () => {
    if (sidebarRef.current) {
      sidebarRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Allianz Car Insurance Claims Contact Number</title>
        <meta
          name="description"
          content="Get in touch with our team on the Allianz Car Insurance Accident Claim Phone Number 0800 772 0850 for prompt claim assistance and speedy settlement. Call us now. "
        />
        <link
          rel="canonical"
          href="https://insurance-accident-claims.co.uk/allianz-car-insurance "
        />
      </Helmet>
      <section className="innernav">
        <div className="container">
          <div className="row">
            <div className="col-md-6 col-sm-6">
              <div className="left-logo">
                {/* <img src={insurancelogo} className='insurancelogo' alt='Insurance Logo' /> */}
                <h3>Accident Claims</h3>
              </div>
            </div>
            <div className="col-md-6 col-sm-6">
              <div className="left-cta">
                <div class="headercnt">
                  <div class="chatmsg">
                    <img
                      src={phonecall}
                      className="phonecall"
                      alt="phonecall "
                    />
                  </div>
                  <div class="box-body">
                    <h3 class="nav-info-box-title">Toll Free Number </h3>
                    <p>
                      <a href="tel:0800 772 0850">0800 772 0850</a>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section
        className="newbnr"
        style={{ backgroundImage: "url(" + carbnr2 + ")" }}
      >
        <div className="container">
          <div className="row">
            <div className="col-xxl-8 col-xl-8 col-md-10 col-12">
              <div className="innerbnr">
                <h1>
                  <span className="onespan">End-to-end </span>{" "}
                  <span className="sci"> insurance claim </span> management assistance{" "}
                </h1>
                <p className="brokerage">
                  From securing a replacement vehicle to arranging repairs and
                  providing full-fledged claims assistance, we take away the
                  hassle and help you get back on track after a no-fault car
                  accident.{" "}
                </p>
                <div className="bnrbtn">
                  <a class="db-btn-border btn-rollover phone" href="tel:0800 772 0850">
                    Begin your claim{" "}
                    <i class="fa-solid fa-arrow-right"></i>
                  </a>
                </div>
                <div className="bnrbtn">
                <a className="db-btn-border btn-rollover desktop"  onClick={handleScrollToSidebar}>
                  Begin your claim <i className="fa-solid fa-arrow-right"></i>
                </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="stickysection">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="group-4-8">
                <div className="cl8 tf-tab">
                  <div className="content-tab">
                    <div className="inner-content">
                      <h2>
                        {" "}
                        Start to finish claims support <br />
                        <span className="light">with zero excess</span>{" "}
                      </h2>
                      <p className="disciplines">
                        We’re here to provide dedicated support and assistance
                        for all your accident management needs following a
                        no-fault car accident. Call us on the{" "}
                        <strong>Allianz motor claims number,</strong> and we’ll
                        provide emergency assistance and claims management
                        without any excess.
                      </p>
                      <hr />
                      <p className="bold-text">No upfront costs </p>
                      <p className="bottom-te">
                        You won’t be burdened with any financial risk as we will
                        recover all the costs from the other party’s insurance
                        company.
                      </p>
                      <hr />
                      <p className="bold-text">Protected no-claims bonus </p>
                      <p className="bottom-te">
                        As we won’t initiate the claim against your own
                        insurance policy, your no-claims bonus and discounts
                        will stay protected.
                      </p>
                      <hr />
                      <p className="bold-text">Dedicated claims handler </p>
                      <p className="bottom-te">
                        Get in touch with us via the{" "}
                        <strong>Allianz Motor Claims contact number </strong>to
                        start your claim. We’ll assign you an experienced claims
                        handler who will manage every aspect of your{" "}
                        <strong>Allianz motor claim. </strong>
                      </p>
                      <hr />
                      <p className="bold-text">Comparable replacement </p>
                      <p className="bottom-te">
                        While your vehicle is undergoing repairs, we will
                        provide a like-for-like replacement vehicle matching
                        your own in terms of make, size and model.
                      </p>
                      <hr />
                      <p className="bold-text">
                        Manufacturer-approved repairs{" "}
                      </p>
                      <p className="bottom-te">
                        Your vehicle will be restored to its pre-accident
                        condition by our manufacturer-approved repairers using
                        genuine parts and paint.
                      </p>
                      <hr />
                      <p className="bold-text">Quick claim settlement </p>
                      <p className="bottom-te">
                        Our expedited and streamlined claims process ensures
                        speedy resolution of your Allianz auto insurance claim
                        without a prolonged waiting period.{" "}
                      </p>
                      <hr />
                      <p className="bold-text">Legal assistance </p>
                      <p className="bottom-te">
                        When you call us on our{" "}
                        <strong>
                          car insurance claim phone number{" "}
                          <a href="tel:0800 772 0850">0800 772 0850</a>
                        </strong>
                        , we will connect you with experienced solicitors to
                        ensure you get the best legal advice and assistance for
                        your claim.
                      </p>
                      <hr />
                      <p className="bold-text">No-win no-fee basis </p>
                      <p className="bottom-te">
                        With our no-win, no-fee approach, you won’t have to pay
                        any upfront charges if your case outcome is not
                        favourable.{" "}
                      </p>
                      <hr />
                      <p className="bold-text">Vast repair network </p>
                      <p className="bottom-te">
                        We continue to build trusted relationships with approved
                        repairers in the UK to ensure your vehicle gets repaired
                        to the highest quality standards.
                      </p>
                      <hr />

                      <p className="bold-text">Administrative support </p>
                      <p className="bottom-te">
                        We will take care of all the paperwork, manage
                        documentation and handle legal proceedings on your
                        behalf to ensure a fair resolution.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="cl4" ref={sidebarRef}>
                  <Sidebarinner />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="photographs">
        <div className="container">
          <div className="row">
            <h2 className="road">
              We will make your post-accident journey <br />
              <span className="light">stress-free and effortless </span>{" "}
            </h2>
            <div className="col-xxl-6 col-xl-6 col-md-12 col-12">
              <div className="fourbox">
                <ul>
                  <li>
                    <img src={check} className="check" alt="" />
                    As part of our start-to-finish accident solutions, we will
                    provide comprehensive support after the car accident. Call
                    us on{" "}
                    <strong>Allianz car accident claim phone number</strong> for
                    immediate roadside assistance.
                  </li>
                  <hr />
                  <li>
                    <img src={check} className="check" alt="" />
                    We specialise in providing a like-for-like replacement
                    vehicle so that you can continue with your daily routine.
                  </li>
                  <hr />
                  <li>
                    <img src={check} className="check" alt="" />
                    We will ensure your vehicle is repaired to the highest
                    standards as quickly as possible.
                  </li>
                  <hr />
                  <li>
                    <img src={check} className="check" alt="" />
                    Our solicitors will help you claim all the losses and
                    damages from the other party’s insurance company.
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-xxl-6 col-xl-6 col-md-12 col-12">
              <div className="fourbox">
                <ul>
                  <li>
                    <img src={check} className="check" alt="" />
                    We will arrange for an independent engineer to assess the
                    damages to your vehicle.
                  </li>
                  <hr />
                  <li>
                    <img src={check} className="check" alt="" />
                    When you contact us on Allianz car insurance claim number,
                    we will arrange professional legal advice through one of our
                    experienced solicitors.
                  </li>
                  <hr />
                  <li>
                    <img src={check} className="check" alt="" />
                    We will also schedule the collection and return of your
                    vehicle post repairs.
                  </li>
                  <hr />
                  <li>
                    <img src={check} className="check" alt="" />
                    We will be your point of contact with all the parties
                    involved in the accident.
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="carprocess">
        <div className="container ">
          <div className="row">
            <div className="col-xxl-12 col-xxl-12 col-md-12 col-12">
              <h2 className="trusted-title">
                {" "}
                Accident management <br />
                <span className="light"> services we offer </span>{" "}
              </h2>
            </div>
          </div>
          <div className="row" id="possible">
            <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-12">
              <div className="claim">
                <ul>
                  <li>
                    <span className="sleej">Vehicle recovery </span> <br />
                    If your car is damaged to an extent where it is undrivable,
                    ring us on{" "}
                    <strong>Allianz car insurance claim number</strong>. We will
                    arrange immediate collection of your vehicle through our
                    expert recovery agents to transport it to a safe location.
                  </li>
                  <li>
                    <span className="sleej">Approved repairs </span>
                    <br />
                    Call us on the{" "}
                    <strong>Allianz car accident claim number</strong>for
                    vehicle repair services after the accident. We will make
                    sure your vehicle repairs are carried out using genuine
                    parts and paint.
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-12">
              <div className="claim" id="blue">
                <ul>
                  <li>
                    <span className="sleej">Vehicle storage </span> <br />
                    Want to keep your vehicle secure after an accident? Contact
                    us on<strong> Allianz motor claims contact number</strong>.
                    We will recover and store your vehicle in our safe storage
                    yards, ensuring its protection from risks and damages.
                  </li>
                  <li>
                    <span className="sleej">Replacement car </span> <br />
                    You’re legally entitled to a replacement following a
                    no-fault car accident. Get in touch with us via the
                    <strong> Allianz car insurance claim phone number</strong>,
                    and we’ll arrange a like-for-like replacement vehicle to
                    help you carry on with your routine without disruption.
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-12">
              <div className="claim">
                <ul>
                  <li>
                    <span className="sleej">Injury support </span>
                    <br />
                    Our solicitors will provide you with the best legal support
                    and assistance to ensure you get deserving compensation for
                    the injuries and losses suffered due to the accident.
                  </li>
                  <li>
                    <span className="sleej">Claim management </span> <br />
                    Right from the submission of your claim to its final
                    settlement, we will take care of everything, including
                    paperwork, documentation and negotiation to ensure your
                    claim gets settled with minimum fuss.
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="yellowcta">
        <div className="container">
          <div className="row">
            <div className="cta2">
              <div className="col-xxl-8 col-xl-8 col-lg-8 col-12">
                <div className="legal">
                  <h3 className="experienced">
                    Got questions?
                    <span className="light">
                      Talk to our no-fault claim specialists{" "}
                    </span>{" "}
                  </h3>
                  <p className="professionals">
                    Get in touch with our no-fault claim experts via{" "}
                    <strong>car insurance claim phone number</strong>
                    <a href="tel:0800 772 0850">0800 772 0850</a> to get the
                    best advice for your{" "}
                    <strong>Allianz car insurance claim. </strong>
                  </p>
                </div>
              </div>
              <div className="col-xxl-4 col-xl-4 col-lg-4 col-12">
              <div class="process">
                  <a class="tf-button style-1 phone" href="tel:0800 772 0850">
                    Get in touch<span class="icon-keyboard_arrow_right"></span>
                  </a>
                </div>
                <div class="process">
                    <a className="tf-button style-1 desktop"  onClick={handleScrollToSidebar}>
                        Get in touch<span className="icon-keyboard_arrow_right"></span>
                    </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <search className="updatedform">
        <div className="container">
          <div className="row text-center">
            <div className="col-xxl-12 col-xl-12 col-md-12 col-12">
              <h2 className="whiteheading">
                Distinctive features that
                <span className="light"> set us apart from others </span>
              </h2>
              <p className="sustained">
                We’re a team of professionals committed to getting you back on
                track after a no-fault car accident. Our services are designed
                to remove the stress, complexity and hassle involved in managing
                the <strong>Allianz motor claims</strong> process. Contact us on
                the <strong>Allianz car accident claim phone number</strong>,
                and we’ll smooth out your path to recovery following an
                accident.{" "}
              </p>
            </div>
          </div>
          <div className="row">
            <div className="col-xxl-4 col-xl-4 col-lg-6 col-md-12 col-12">
              <div className="Claimant">
                <h5>No excess to pay </h5>
                <p className="collision">
                  Our independent accident management services won’t burden you
                  with any financial obligation as we’ll file the{" "}
                  <strong>Allianz auto insurance claims </strong>directly
                  against the other party.
                </p>

                <h5>Hassle-free accident repairs </h5>
                <p className="collision">
                  We will organise guaranteed and quick repairs for your vehicle
                  to help you get back on the road as soon as possible.
                </p>

                <h5>Same-day replacement vehicle </h5>
                <p className="collision">
                  If you’re not at fault, we will arrange a replacement vehicle
                  that suits your needs, delivered on the same day at your
                  doorstep or the accident location.
                </p>
              </div>
            </div>
            <div className="col-xxl-4 col-xl-4 col-lg-6 col-md-12 col-12">
              <div className="Claimant">
                <h5>No obligation advice </h5>
                <p className="collision">
                  Contact us via the{" "}
                  <strong>Allianz car insurance claim number</strong>, and we’ll
                  provide you with immediate advice and guidance to ensure the
                  best outcome for the{" "}
                  <strong>Allianz auto insurance claim. </strong>
                </p>

                <h5>Dedicated claims management </h5>
                <p className="collision">
                  Our dedicated claims handler will efficiently carry out your{" "}
                  <strong>Allianz car insurance claim</strong>, developing an
                  appropriate strategy and handling communication with all
                  parties to ensure speedy resolution.
                </p>

                <h5>Uninsured loss recovery </h5>
                <p className="collision">
                  When you call us on the{" "}
                  <strong>Allianz car insurance claim number </strong>to start
                  your claim, our solicitors will help you recover all
                  out-of-pocket you’ve incurred from the other party’s insurer.
                </p>
              </div>
            </div>
            <div className="col-xxl-4 col-xl-4 col-lg-12 col-md-12 col-12">
              <div className="Claimant">
                <h5>24/7 availability</h5>
                <p className="collision">
                  We’re available round the clock to assist you with your
                  accident management needs. Get in touch with us via the{" "}
                  <strong>Allianz car accident claim number </strong>to get
                  emergency assistance after the accident.
                </p>

                <h5>Wide-spectrum services </h5>
                <p className="collision">
                  From vehicle recovery to organising repairs, replacement
                  vehicles and managing{" "}
                  <strong>Allianz car insurance claims</strong>, we provide a
                  wide spectrum of services.
                </p>

                <h5>Immediate recovery </h5>
                <p className="collision">
                  Our recovery agents enable speedy recovery of your vehicle
                  from the accident scene and move it to a safe place to prevent
                  extended damage.
                </p>
              </div>
            </div>
          </div>
        </div>
      </search>

      <section className="Accident-claim">
        <div className="container">
          <div className="row">
            <div className="needed">
              <h2 className="trusted-title">
                How can you increase the
                <br />
                <span className="light"> value of your claim?</span>
              </h2>
              <p className="complexities">
                If you get injured due to the negligence of the other party, you
                must get compensation for your losses and injuries. The amount
                of compensation depends on many factors. Therefore, you must
                take the right steps after a no-fault car accident to improve
                the settlement value of the
                <strong> Allianz motor claim. </strong>
              </p>
            </div>
            <hr />
          </div>
          <div className="row" id="incdes">
            <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-12">
              <div className="Preparing">
                <ul>
                  <li>
                    <div className="seemingly">
                      <h5 className="Seek">Seek prompt medical attention </h5>
                      <p>
                        Immediately get medical attention from a qualified
                        medical practitioner to get your injuries treated. The
                        medical report contains information about the extent of
                        injuries, which can help maximise the value of your
                        claim.{" "}
                      </p>
                    </div>
                  </li>
                  <li>
                    <div className="seemingly">
                      <h5 className="Seek">Document damages </h5>
                      <p>
                        Keep a log of all the expenses you’ve incurred and
                        damages you’ve suffered as a result of the accident to
                        ensure you get compensation for all the losses.
                      </p>
                    </div>
                  </li>
                  <li>
                    <div className="seemingly">
                      <h5 className="Seek">
                        Don’t agree to a quick settlement{" "}
                      </h5>
                      <p>
                        The insurance company may offer a low settlement value
                        to minimise the payout. Before accepting any such offer,
                        discuss it with your solicitor to ensure it fairly
                        compensates you for all the losses and damages.
                      </p>
                    </div>
                  </li>
                  <li>
                    <div className="seemingly">
                      <h5 className="Seek">
                        Don’t post anything on social media{" "}
                      </h5>
                      <p>
                        Avoid discussing or posting anything on social media as
                        it may minimise your settlement value. Until your{" "}
                        <strong>Allianz car insurance claim </strong>is settled,
                        stay away from social media.
                      </p>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
            <div
              className="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-12"
              id="dualcardiv"
            >
              <img src={dualcar} alt="" className="dualcar" />
            </div>
            <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-12">
              <div className="Preparing">
                <ul>
                  <li>
                    <div className="seemingly">
                      <h5 className="Seek">Gather evidence </h5>
                      <p>
                        Collect crucial evidence from the accident scene,
                        including photographs of the location, vehicles
                        involved, and the injuries you’ve suffered.
                      </p>
                    </div>
                  </li>
                  <li>
                    <div className="seemingly">
                      <h5 className="Seek">
                        Exchange details with the other party{" "}
                      </h5>
                      <p>
                        Exchange the details with the other party to ensure your
                        claim proceeds smoothly. This information must include
                        personal details, licence information and insurance
                        details.
                      </p>
                    </div>
                  </li>
                  <li>
                    <div className="seemingly">
                      <h5 className="Seek">
                        Consult an experienced solicitor{" "}
                      </h5>
                      <p>
                        Hire a professional and experienced solicitor who can
                        negotiate with the other party’s insurer on your behalf
                        and fight for full and fair compensation. Call us on the{" "}
                        <strong>Allianz car insurance claim number</strong> to
                        discuss your claim with our experienced solicitors.
                      </p>
                    </div>
                  </li>
                  <li>
                    <div className="seemingly">
                      <h5 className="Seek">Begin your claim </h5>
                      <p>
                        Once you’ve all the evidence and documents in hand,
                        contact us on the{" "}
                        <strong>Allianz car accident claim</strong> number to
                        register your claim.{" "}
                      </p>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="whyus">
        <div className="container">
          <div className="row">
            <div className="col-xxl-12 col-md-12">
              <div className="why">
                <h2 className="choose">
                  Our hassle-free and{" "}
                  <span className="light">
                    <br /> simplified claims process{" "}
                  </span>
                </h2>
                <p className="complexities">
                  If you think you’re entitled to compensation for an accident
                  that wasn’t your fault, contact us on{" "}
                  <a href="tel:0800 772 0850">0800 772 0850</a> to make your
                  claim. Our simplified claims process allows you to make your
                  claim easily without any paperwork hassles.
                </p>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-xxl-3 col-xl-6 col-lg-6 col-md-6 col-12">
              <div className="box-service">
                <img src={check} className="check" alt="" />
                <h4>Get in touch </h4>
                <p>
                  Contact our team via the{" "}
                  <strong>Allianz motor claims number </strong>to schedule a
                  free initial consultation to discuss your claim.
                </p>
              </div>
            </div>
            <div className="col-xxl-3 col-xl-6 col-lg-6 col-md-6 col-12">
              <div className="box-service">
                <img src={check} className="check" alt="" />
                <h4>Provide details </h4>
                <p>
                  Our team will request details of the accident and evidence to
                  formulate the case against the other party.
                </p>
              </div>
            </div>
            <div className="col-xxl-3 col-xl-6 col-lg-6 col-md-6 col-12">
              <div className="box-service">
                <img src={check} className="check" alt="" />
                <h4>Claim initiation </h4>
                <p>
                  We will submit your claim and initiate negotiations with the
                  other party on your behalf.
                </p>
              </div>
            </div>
            <div className="col-xxl-3 col-xl-6 col-lg-6 col-md-6 col-12">
              <div className="box-service">
                <img src={check} className="check" alt="" />
                <h4>Claim settlement </h4>
                <p>
                  Once the negotiation is concluded, you’ll receive a fair
                  settlement that will compensate you for all the expenses and
                  losses you’ve suffered.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="faqs">
        <div className="container">
          <div className="row">
            <div className="col-xxl-12 col-xl-12 col-md-12 col-12">
              <div className="fq">
                <h2>
                  {" "}
                  Frequently Asked{" "}
                  <span className="light">Questions (FAQ)</span>
                </h2>
                <p className="complexities">
                  Got questions about the Allianz car insurance claim? We have
                  them answered here.
                </p>
              </div>
              <div className="allfaq">
                <Accordion defaultActiveKey="0">
                  <Accordion.Item eventKey="0">
                    <Accordion.Header>
                      What if my vehicle is immobile after the car accident?
                    </Accordion.Header>
                    <Accordion.Body>
                      If your vehicle is immobile after the no-fault car
                      accident, we will offer immediate recovery of your vehicle
                      from the accident location and provide you with a
                      replacement vehicle to help you stay mobile.
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="1">
                    <Accordion.Header>
                      What will happen to my vehicle after the car accident?
                    </Accordion.Header>
                    <Accordion.Body>
                      In case your vehicle is repairable, we will arrange the
                      repairs of your vehicle from a reputable body shop in the
                      UK. If you wish to use your own repairer, please call us
                      at{" "}
                      <strong>the Allianz motor claims contact number. </strong>
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="2">
                    <Accordion.Header>
                      How long can you store my vehicle after the car accident?
                    </Accordion.Header>
                    <Accordion.Body>
                      We will provide storage for your damaged vehicle until
                      your vehicle is repaired or your claim is settled.
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="3">
                    <Accordion.Header>
                      Will my insurance premium be affected?
                    </Accordion.Header>
                    <Accordion.Body>
                      No. Since we are not claiming against your own insurance
                      policy, your insurance premium and no claims bonus won’t
                      be affected.
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="4">
                    <Accordion.Header>
                      Can you arrange repairs for my vehicle?
                    </Accordion.Header>
                    <Accordion.Body>
                      Yes. We can arrange for your vehicle to be repaired from
                      manufacturer-approved repairers using original parts and
                      high-quality paint.
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="5">
                    <Accordion.Header>
                      How soon can you get me back on the road after the
                      no-fault car accident?
                    </Accordion.Header>
                    <Accordion.Body>
                      We strive to get you back on the road as soon as possible
                      after the car accident. You can call us on
                      <strong> Allianz car insurance claim Phone number</strong>
                      , and we’ll provide you with a replacement vehicle the
                      same day.
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="6">
                    <Accordion.Header>
                      Will I have to pay my insurance excess?
                    </Accordion.Header>
                    <Accordion.Body>
                      No. Once we accept your claim, you won’t have to pay any
                      insurance excess as we won’t initiate the claim against
                      your own insurance policy.
                    </Accordion.Body>
                  </Accordion.Item>
                </Accordion>
              </div>
            </div>
          </div>
        </div>
      </section>

      <Footer />
    </>
  );
};

export default Allianzcarinsurance;
