import React, { useRef } from "react";
import Accordion from "react-bootstrap/Accordion";
import check from "../assets/images/check.png";
import dualcar from "../../src/assets/images/dualcar.webp";
import carbnr1 from "../../src/assets/images/carbnr1.webp";
import Sidebarinner from "../components/Sidebar-inner";
import phonecall from "../assets/images/phonecall.png";
import Footer from "../components/footer";
import { Helmet } from "react-helmet";

const Ingenie = () => {
  const sidebarRef = useRef(null);

  const handleScrollToSidebar = () => {
    if (sidebarRef.current) {
      sidebarRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Ingenie Car Insurance Accident Claim Phone Number:</title>
        <meta
          name="description"
          content="Our dedicated team provides quick and reliable no-fault claim assistance in the UK. Reach out to us via Ingenie Car Insurance accident claim phone number 0800 772 0850.  "
        />
        <link
          rel="canonical"
          href="https://insurance-accident-claims.co.uk/Ingenie"
        />
      </Helmet>
      <section className="innernav">
        <div className="container">
          <div className="row">
            <div className="col-md-6 col-sm-6">
              <div className="left-logo">
                <h3>
                  Accident<span className="sipo"> Claims</span>
                </h3>
              </div>
            </div>
            <div className="col-md-6 col-sm-6">
              <div className="left-cta">
                <div class="headercnt">
                  <div class="chatmsg">
                    <img
                      src={phonecall}
                      className="phonecall"
                      alt="phonecall "
                    />
                  </div>
                  <div class="box-body">
                    <h3 class="nav-info-box-title">Toll Free Number </h3>
                    <p>
                      <a href="tel:0800 772 0850">0800 772 0850</a>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section
        className="newbnr"
        style={{ backgroundImage: "url(" + carbnr1 + ")" }}
      >
        <div className="container">
          <div className="row">
            <div className="col-xxl-8 col-xl-8 col-md-10 col-12">
              <div className="innerbnr">
                <h1>
                  <span className="onespan">No-fault </span>{" "}
                  <span className="sci"> accident claims </span> management
                </h1>
                <p className="brokerage">
                  From the recovery of your vehicle, through to managing your
                  claim and all that falls in between we will take you over all
                  the different stages from a no-fault car accident.{" "}
                </p>
                <div className="bnrbtn">
                  <a class="db-btn-border btn-rollover phone" href="tel:0800 772 0850">
                    Road Accident Claim Help?{" "}
                    <i class="fa-solid fa-arrow-right"></i>
                  </a>
                </div>
                <div className="bnrbtn">
                <a className="db-btn-border btn-rollover desktop"  onClick={handleScrollToSidebar}>
                    Road Accident Claim Help? <i className="fa-solid fa-arrow-right"></i>
                </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="stickysection">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="group-4-8">
                <div className="cl8 tf-tab">
                  <div className="content-tab">
                    <div className="inner-content">
                      <h2>
                        Professional claims assistance <br />
                        <span className="light">
                          without financial burden
                        </span>{" "}
                      </h2>
                      <p className="disciplines">
                        The major purpose of our accident management services is
                        to aid victims of faultless car accidents to overcome
                        the consequences in a seamless manner and return to the
                        recovery path with fewer difficulties. Our team will
                        cover the whole process from vehicle recovery to claims
                        management thereby taking away the pain out of it. Dial{" "}
                        <strong>
                          {" "}
                          Ingenie car insurance claims phone number{" "}
                        </strong>{" "}
                        as you start your journey towards making claims.
                      </p>
                      <hr />
                      <p className="bold-text">Zero excess</p>
                      <p className="bottom-te">
                        We do not load you with an excess premium like your
                        insurer because we will make the claim directly against
                        the wrongdoer responsible for an accident.
                      </p>
                      <hr />
                      <p className="bold-text">
                        Preserved no-claims bonus (NCB)
                      </p>
                      <p className="bottom-te">
                        By choosing us to make your claim, do not worry about
                        losing that NCB build-up over time with years of safe
                        driving.
                      </p>
                      <hr />
                      <p className="bold-text">
                        Manufacturer approved repairers
                      </p>
                      <p className="bottom-te">
                        To ensure that your car is taken back to its original
                        condition by using genuine parts and paint, we work
                        closely with manufacturer-approved repairers.
                      </p>
                      <hr />
                      <p className="bold-text">
                        Like-for-like replacement vehicle
                      </p>
                      <p className="bottom-te">
                        Like-for-like replacement vehicles are available through
                        our premium services. This means that you will receive a
                        replacement vehicle of the same quality, size and style
                        as your own.
                      </p>
                      <hr />
                      <p className="bold-text">Expedited claims process</p>
                      <p className="bottom-te">
                        Taking care of a non-fault claim can seem overwhelming.
                        However, when you call our{" "}
                        <strong>
                          {" "}
                          Ingenie car accident claim phone number{" "}
                        </strong>{" "}
                        to make a claim, we will speed up the claims process for
                        fast resolution.
                      </p>
                      <hr />
                      <p className="bold-text">No-obligation advice</p>
                      <p className="bottom-te">
                        To receive free, no-obligation advice to obtain
                        compensation for injuries or losses incurred, feel free
                        to contact us via our <strong>claims line</strong>, at{" "}
                        <a href="tel:0800 772 0850">0800 772 0850</a>.
                      </p>
                      <hr />
                      <p className="bold-text">Dedicated claims manager</p>
                      <p className="bottom-te">
                        Calling us via the{" "}
                        <strong> Ingenie Insurance claim number </strong>{" "}
                        ensures that you will have an assigned claims handler
                        who has experience in claims handling.{" "}
                      </p>
                      <hr />
                      <p className="bold-text">Prompt response</p>
                      <p className="bottom-te">
                        We are always there for you immediately after an
                        accident since we understand how stressful it is. Thus
                        do not hesitate to contact us if you need urgent
                        information or service following an accident.
                      </p>
                      <hr />
                      <p className="bold-text">Complete assistance</p>
                      <p className="bottom-te">
                        We provide comprehensive help throughout the claims
                        processes such as recovering your vehicle, managing
                        repairs, arranging replacement cars or trucks, and
                        dealing with your case management needs.{" "}
                      </p>
                      <hr />

                      <p className="bold-text">
                        Post-repair monitoring & inspection
                      </p>
                      <p className="bottom-te">
                        After repairs have been carried out on your vehicle, we
                        will take our time to examine it thoroughly so as to
                        ensure that its repair has been done according to the
                        highest quality and safety standards.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="cl4" ref={sidebarRef}>
                  <Sidebarinner />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="photographs">
        <div className="container">
          <div className="row">
            <h2 className="road">
              Let us do all the running around
              <span className="light">
                for <br /> you after a car accident
              </span>{" "}
            </h2>
            <div className="col-xxl-6 col-xl-6 col-md-12 col-12">
              <div className="fourbox">
                <ul>
                  <li>
                    <img src={check} className="check" alt="" />
                    You can call us on our{" "}
                    <strong> Ingenie insurance claim number </strong> in case of
                    an accident. We are experts in providing immediate
                    assistance to meet your accident management needs on time.
                  </li>
                  <hr />
                  <li>
                    <img src={check} className="check" alt="" />
                    We shall ensure that the damaged car is recovered quickly
                    after being involved in an accident and this will help you
                    reduce stress and save your money.
                  </li>
                  <hr />
                  <li>
                    <img src={check} className="check" alt="" />A damage
                    assessment will be conducted by one of our engineers to
                    determine the extent of damage to your vehicle.
                  </li>
                  <hr />
                  <li>
                    <img src={check} className="check" alt="" />
                    If it is found that your vehicle can be repaired, we will
                    make arrangements for its repair through your preferred
                    repair shop.
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-xxl-6 col-xl-6 col-md-12 col-12">
              <div className="fourbox">
                <ul>
                  <li>
                    <img src={check} className="check" alt="" />
                    Do not hesitate. Contact us via the{" "}
                    <strong> Ingenie claims line </strong>, and we’ll provide
                    you with a like-for-like replacement vehicle in the shortest
                    possible time so that you can get back to normalcy.
                  </li>
                  <hr />
                  <li>
                    <img src={check} className="check" alt="" />
                    Our solicitors shall also speak with the other driver’s
                    insurance firm on your behalf and communicate with various
                    people accordingly.
                  </li>
                  <hr />
                  <li>
                    <img src={check} className="check" alt="" />
                    We do constant monitoring of your vehicle repair progress
                    and keep you informed at every step of the way.
                  </li>
                  <hr />
                  <li>
                    <img src={check} className="check" alt="" />
                    Our solicitors will make sure that all expenses incurred as
                    a result of road accidents are covered by the insurer of the
                    other party involved.
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="carprocess">
        <div className="container ">
          <div className="row">
            <div className="col-xxl-12 col-xxl-12 col-md-12 col-12">
              <h2 className="trusted-title">
                {" "}
                Accident management services <br />
                <span className="light"> we provide</span>{" "}
              </h2>
            </div>
          </div>
          <div className="row" id="possible">
            <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-12">
              <div className="claim">
                <ul>
                  <li>
                    <span className="sleej">Accident recovery:</span> If your
                    vehicle is undrivable, contact us at the{" "}
                    <strong> Ingenie insurance claim number </strong>for
                    professional recovery of your vehicle. We will organise a
                    fast and efficient vehicle retrieval operation to rescue
                    your car and take it to a secure place.
                  </li>
                  <li>
                    <span className="sleej">Approved repairs:</span> Call us on
                    the <strong> Ingenie claim line </strong> if your vehicle
                    has been damaged due to an accident. We will arrange for
                    quality guaranteed repairs with an approved repairer from
                    our extensive network.
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-12">
              <div className="claim" id="blue">
                <ul>
                  <li>
                    <span className="sleej">Vehicle storage:</span> After having
                    an accident, you can call us at our{" "}
                    <span> Ingenie insurance claims number </span> so that we
                    may store your car safely. While waiting for the valuation
                    of your claim, we shall keep it in our safe storage yards,
                    thereby protecting it from any further damage or theft
                    attempts. Our storage yards offer a safe place in which you
                    can leave your vehicle after an accident.
                  </li>
                  <li>
                    <span className="sleej">Temporary car: </span> A
                    like-to-like replacement vehicle will be handed to you while
                    yours is being repaired. You will keep the replacement
                    vehicle for either the period your car undergoes repairs or
                    until the claim settles. Reach us at the{" "}
                    <strong> Ingenie car accident claims phone number </strong>
                    so that we can have an alternative replacement car brought
                    to your home.
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-12">
              <div className="claim">
                <ul>
                  <li>
                    <span className="sleej">Personal injury support:</span>
                    If you are involved in a road traffic collision and
                    sustained injuries, get in touch with our solicitors via the{" "}
                    <strong> Ingenie car insurance claim phone number </strong>
                    for guidance on how you can go about making personal injury
                    claims.
                  </li>
                  <li>
                    <span className="sleej">Claims handling:</span> Everything
                    concerning your claim will be done by us right from taking
                    care of documents to engaging with all interested parties
                    towards ensuring that it takes the shortest time possible to
                    solve your claim.
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="yellowcta">
        <div className="container">
          <div className="row">
            <div className="cta2">
              <div className="col-xxl-8 col-xl-8 col-lg-8 col-12">
                <div className="legal">
                  <h3 className="experienced">
                    Got more questions?{" "}
                    <span className="light">Talk to our specialists</span>{" "}
                  </h3>
                  <p className="professionals">
                    {" "}
                    Get in touch with our no-fault claim experts via{" "}
                    <strong>
                      {" "}
                      car insurance claim phone number{" "}
                      <a className="cpt-no" href="tel:0800 772 0850">
                        {" "}
                        0800 772 0850{" "}
                      </a>{" "}
                    </strong>{" "}
                    to get the best advice for your{" "}
                    <strong> Ingenie car insurance claim. </strong>
                  </p>
                </div>
              </div>
              <div className="col-xxl-4 col-xl-4 col-lg-4 col-12">
              <div class="process">
                    <a class="tf-button style-1 phone" href="tel:0800 772 0850">
                      Get in touch<span class="icon-keyboard_arrow_right"></span>
                    </a>
                  </div>
                  <div class="process">
                      <a className="tf-button style-1 desktop"  onClick={handleScrollToSidebar}>
                          Get in touch<span className="icon-keyboard_arrow_right"></span>
                      </a>
                  </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <search className="updatedform">
        <div className="container">
          <div className="row text-center">
            <div className="col-xxl-12 col-xl-12 col-md-12 col-12">
              <h2 className="whiteheading">
                {" "}
                What sets us <br />
                <span className="light">apart?</span>
              </h2>
              <p className="sustained">
                We possess the finest team of competent solicitors in the United
                Kingdom who have managed to recover more damages for victims of
                car accidents than any other firm within the UK. Please call us
                on <a href="tel:0800 772 0850"> 0800 772 0850 </a> when faced
                with an emergency following a road crash.
              </p>
            </div>
          </div>
          <div className="row">
            <div className="col-xxl-4 col-xl-4 col-lg-6 col-md-12 col-12">
              <div className="Claimant">
                <h5>At no cost to you </h5>
                <p className="collision">
                  There will be no direct payments from your own pocket
                  regarding our accident management services since we will make
                  a reclaim of all expenses from the other party’s insurer.
                </p>

                <h5> No increase in Insurance renewals </h5>
                <p className="collision">
                  When you decide to make your claim with us, you are assured of
                  not losing your insurance premium, because we shall not prefer
                  any claim on your insurance coverage policy.{" "}
                </p>

                <h5>24/7 availability</h5>
                <p className="collision">
                  Through twenty-four-hour-a-day and seven-days-a-week
                  assistance, you can always have peace of mind, knowing that
                  dedicated support and help are available whenever you need it.
                </p>
              </div>
            </div>
            <div className="col-xxl-4 col-xl-4 col-lg-6 col-md-12 col-12">
              <div className="Claimant">
                <h5>Comprehensive roadside assistance</h5>
                <p className="collision">
                  Our wide range of roadside assistance covers everything from
                  vehicle recovery to providing replacement cars and so forth
                  thus reducing stress and trouble associated with being
                  involved in an accident.
                </p>

                <h5>Secure vehicle storage</h5>
                <p className="collision">
                  Our storage yards guarantee temporary accommodation for your
                  damaged car and as such protect it against possible dangers or
                  losses.
                </p>

                <h5>High-quality repairs</h5>
                <p className="collision">
                  We promise that we will provide you with high-quality car
                  repairs using original parts and paintwork. In order to
                  arrange repair for your damaged car contact us through the{" "}
                  <strong>Ingenie claims line.</strong>{" "}
                </p>
              </div>
            </div>
            <div className="col-xxl-4 col-xl-4 col-lg-12 col-md-12 col-12">
              <div className="Claimant">
                <h5>Fast replacement vehicle</h5>
                <p className="collision">
                  We can attempt to decrease the inconvenience that arises from
                  a car accident by sending you a replacement vehicle to your
                  doorstep within twenty-four hours.
                </p>

                <h5> Personalised advice </h5>
                <p className="collision">
                  Our solicitors will understand your situation and provide
                  professional help tailored to it.
                </p>

                <h5>No win no fee basis</h5>
                <p className="collision">
                  You need not pay any legal fees if our solicitors don’t
                  succeed in getting compensation for your case.
                </p>
              </div>
            </div>
          </div>
        </div>
      </search>

      <section className="Accident-claim">
        <div className="container">
          <div className="row">
            <div className="needed">
              <h2 className="trusted-title">
                How to maximise your <br />
                <span className="light"> Ingenie car insurance claim?</span>
              </h2>
              <p className="complexities">
                It is important to file a claim after being involved in a
                faultless motor accident so that you can be compensated for
                various damages and losses. Here are tips on how you can
                increase your claim settlement amount and gain fair
                compensation.{" "}
              </p>
            </div>
            <hr />
          </div>
          <div className="row" id="incdes">
            <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-12">
              <div className="Preparing">
                <ul>
                  <li>
                    <div className="seemingly">
                      <h5 className="Seek">Report the claim:</h5>
                      <p>
                        {" "}
                        The first thing you should do after a road traffic
                        accident is to call the police and report it to them.
                        The police report might contain vital information which
                        may prove invaluable for your case.{" "}
                      </p>
                    </div>
                  </li>
                  <li>
                    <div className="seemingly">
                      <h5 className="Seek"> Get emergency services: </h5>
                      <p>
                        If you get stuck on the road, call emergency services
                        immediately. Dial up the{" "}
                        <strong>
                          {" "}
                          Ingenie car insurance claim phone number{" "}
                        </strong>{" "}
                        , and we’ll give prompt roadside assistance to have you
                        back behind wheels again as soon as possible.
                      </p>
                    </div>
                  </li>
                  <li>
                    <div className="seemingly">
                      <h5 className="Seek"> Keep a record of your expenses:</h5>
                      <p>
                        Keep track of all expenses incurred, such as medical
                        bills, travelling costs, legal fees or any other
                        expenses you have incurred.
                      </p>
                    </div>
                  </li>
                  <li>
                    <div className="seemingly">
                      <h5 className="Seek">Obtain proof</h5>
                      <p>
                        At the accident scene, gather evidence that will support
                        your claim. Such evidence may include pictures showing
                        the accident and injuries, eyewitness statements and
                        CCTV footage.
                      </p>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
            <div
              className="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-12"
              id="dualcardiv"
            >
              <img src={dualcar} alt="" className="dualcar" />
            </div>
            <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-12">
              <div className="Preparing">
                <ul>
                  <li>
                    <div className="seemingly">
                      <h5 className="Seek"> Stay away from social media:</h5>
                      <p>
                        {" "}
                        Refrain from posting anything on social media until your
                        claim starts. They can be used by the opponent’s
                        solicitor to destroy your case.{" "}
                      </p>
                    </div>
                  </li>
                  <li>
                    <div className="seemingly">
                      <h5 className="Seek"> Exchange details</h5>
                      <p>
                        {" "}
                        Exchange details with the other drivers, including names
                        and addresses, insurance particulars, vehicle details
                        and driver’s licence information so that everyone has
                        all the relevant facts needed to quickly process your
                        claim at a later stage.{" "}
                      </p>
                    </div>
                  </li>
                  <li>
                    <div className="seemingly">
                      <h5 className="Seek"> Get in touch with a specialist:</h5>
                      <p>
                        {" "}
                        Contact us through the{" "}
                        <strong>
                          {" "}
                          Ingenie car accident claim phone number{" "}
                        </strong>
                        , and we’ll put you in touch with seasoned solicitors
                        who are conversant with the complexities associated with
                        car collision cases.{" "}
                      </p>
                    </div>
                  </li>
                  <li>
                    <div className="seemingly">
                      <h5 className="Seek"> Initiate a claim </h5>
                      <p>
                        {" "}
                        Once you have gathered all necessary documents and
                        proofs just call us through our{" "}
                        <strong> Ingenie claims line </strong> to commence your
                        claim.
                      </p>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="whyus">
        <div className="container">
          <div className="row">
            <div className="col-xxl-12 col-md-12">
              <div className="why">
                <h2 className="choose">
                  {" "}
                  Simple steps to begin{" "}
                  <span className="light"> your claim </span>
                </h2>
                <p className="complexities">
                  You can initiate your claim quickly and easily without the
                  need for any paperwork with our uncomplicated claims
                  procedure. We just want you to contact our team using the{" "}
                  <strong> Ingenie accident claims number </strong> , and we
                  shall take care of the rest.
                </p>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-xxl-3 col-xl-6 col-lg-6 col-md-6 col-12">
              <div className="box-service">
                <img src={check} className="check" alt="" />
                {/* <img src={experience} className="experience" alt="" /> */}
                <h4>Connect with us</h4>
                <p>
                  {" "}
                  We are available on the <strong> Ingenie claims line </strong>
                  where you can reach us in times of emergencies or when you
                  need a free consultation from our team of claim experts.
                </p>
              </div>
            </div>
            <div className="col-xxl-3 col-xl-6 col-lg-6 col-md-6 col-12">
              <div className="box-service">
                <img src={check} className="check" alt="" />
                <h4> Give details about the accident </h4>
                <p>
                  Inform us about the accident which includes the date, time,
                  place of occurrence, and circumstances leading to the
                  accident.
                </p>
              </div>
            </div>
            <div className="col-xxl-3 col-xl-6 col-lg-6 col-md-6 col-12">
              <div className="box-service">
                <img src={check} className="check" alt="" />
                <h4> Present evidence and documents </h4>
                <p>
                  {" "}
                  If there is any information that relates to your compensation
                  claim that you have got as proof then please give it to
                  us.Again, present relevant papers that would be used in
                  progressing your case.{" "}
                </p>
              </div>
            </div>
            <div className="col-xxl-3 col-xl-6 col-lg-6 col-md-6 col-12">
              <div className="box-service">
                <img src={check} className="check" alt="" />
                <h4>Claim initiation & follow up</h4>
                <p>
                  {" "}
                  Once we have initiated your claim, we’ll provide regular
                  updates concerning how far your claim has gone.{" "}
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="faqs">
        <div className="container">
          <div className="row">
            <div className="col-xxl-12 col-xl-12 col-md-12 col-12">
              <div className="fq">
                <h2>
                  {" "}
                  Frequently Asked{" "}
                  <span className="light">Questions (FAQ)</span>
                </h2>
                <p className="complexities">
                  Got questions about the{" "}
                  <strong> Ingenie car insurance claim? </strong> We have them
                  answered here.{" "}
                </p>
              </div>
              <div className="allfaq">
                <Accordion defaultActiveKey="0">
                  <Accordion.Item eventKey="0">
                    <Accordion.Header>
                      Are there any hidden costs involved in my claim through
                      you?
                    </Accordion.Header>
                    <Accordion.Body>
                      No. You should expect no nasty surprises or additional
                      charges with us. We believe in being open and honest, so
                      we will keep you informed at all times and recover all our
                      expenses from the other party’s insurer.
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="1">
                    <Accordion.Header>
                      Will you collect my car from the accident scene?
                    </Accordion.Header>
                    <Accordion.Body>
                      Yes, we shall retrieve your car from the crash scene and
                      take it to an approved repair shop for repairs.
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="2">
                    <Accordion.Header>
                      Would this affect my no-claims bonus?
                    </Accordion.Header>
                    <Accordion.Body>
                      No. If the accident is not your fault, then your NCB will
                      remain intact.
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="3">
                    <Accordion.Header>
                      Will vehicle repairs be covered under a guarantee?
                    </Accordion.Header>
                    <Accordion.Body>
                      Yes, we will arrange for your repairs to be done by UK
                      manufacturer-approved repairers. Therefore, it is certain
                      that your vehicle repairs will be under guarantee.
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="4">
                    <Accordion.Header>
                      My car has been written off. How can you help me?
                    </Accordion.Header>
                    <Accordion.Body>
                      Should your vehicle become a total loss, we would supply
                      you with a like-for-like replacement until compensation
                      comes from the third party.
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="5">
                    <Accordion.Header>
                      Will I have to pay any extra money if the crash is not my
                      fault?{" "}
                    </Accordion.Header>
                    <Accordion.Body>
                      There is no excess cost that will be charged to you once
                      an accident happens that was not your mistake. All those
                      expenses will be directed towards other insurers.
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="6">
                    <Accordion.Header>
                      How soon can I get another car after having a road traffic
                      accident?
                    </Accordion.Header>
                    <Accordion.Body>
                      We shall ensure that you receive a replacement vehicle in
                      less than one day after the collision has happened,
                      allowing you to maintain your normal daily schedules
                      without any interruption.
                    </Accordion.Body>
                  </Accordion.Item>
                </Accordion>
              </div>
            </div>
          </div>
        </div>
      </section>

      <Footer />
    </>
  );
};

export default Ingenie;
