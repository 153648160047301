import React, { useRef } from "react";
import Accordion from "react-bootstrap/Accordion";
import check from "../assets/images/check.png";
import dualcar from "../../src/assets/images/dualcar.webp";
import carbnr1 from "../../src/assets/images/carbnr1.webp";
import Sidebarinner from "../components/Sidebar-inner";
import phonecall from "../assets/images/phonecall.png";
import Footer from "../components/footer";
import { Helmet } from "react-helmet";

const Onecallinsurance = () => {
  const sidebarRef = useRef(null);

  const handleScrollToSidebar = () => {
    if (sidebarRef.current) {
      sidebarRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>One Call Car Insurance Accident Claim Phone Number:</title>
        <meta
          name="description"
          content="Looking for dedicated accident claim support? Reach out to our team via the One Call Car Insurance accident claim phone number 0800 772 0850 for quick assistance and support. "
        />
        <link
          rel="canonical"
          href="https://insurance-accident-claims.co.uk/one-call-insurance"
        />
      </Helmet>
      <section className="innernav">
        <div className="container">
          <div className="row">
            <div className="col-md-6 col-sm-6">
              <div className="left-logo">
                <h3>
                  Accident<span className="sipo"> Claims</span>
                </h3>
              </div>
            </div>
            <div className="col-md-6 col-sm-6">
              <div className="left-cta">
                <div class="headercnt">
                  <div class="chatmsg">
                    <img
                      src={phonecall}
                      className="phonecall"
                      alt="phonecall "
                    />
                  </div>
                  <div class="box-body">
                    <h3 class="nav-info-box-title">Toll Free Number </h3>
                    <p>
                      <a href="tel:0800 772 0850">0800 772 0850</a>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section
        className="newbnr"
        style={{ backgroundImage: "url(" + carbnr1 + ")" }}
      >
        <div className="container">
          <div className="row">
            <div className="col-xxl-8 col-xl-8 col-md-10 col-12">
              <div className="innerbnr">
                <h1>
                  <span className="onespan">No-fault</span>{" "}
                  <span className="sci">claims management</span> in the UK{" "}
                </h1>
                <p className="brokerage">
                  Have you ever experienced an accident that was not your fault?
                  Contact us right away at the
                  <string> One call motor claims number </string>for immediate
                  assistance and claim support after an accident.{" "}
                </p>
                <div className="bnrbtn">
                  <a class="db-btn-border btn-rollover phone" href="tel:0800 772 0850">
                    Road Accident Claim Help?{" "}
                    <i class="fa-solid fa-arrow-right"></i>
                  </a>
                </div>
                <div className="bnrbtn">
                <a className="db-btn-border btn-rollover desktop"  onClick={handleScrollToSidebar}>
                    Road Accident Claim Help? <i className="fa-solid fa-arrow-right"></i>
                </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="stickysection">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="group-4-8">
                <div className="cl8 tf-tab">
                  <div className="content-tab">
                    <div className="inner-content">
                      <h2>
                        One Call car claims management <br />
                        <span className="light">with no deductible</span>{" "}
                      </h2>
                      <p className="disciplines">
                        We understand how traumatic it can be to deal with the
                        aftermath of a car crash. That is why we are dedicated
                        to providing full accident management services without
                        any excess or upfront fees. Dial the{" "}
                        <string>
                          {" "}
                          One Call car insurance claims contact number{" "}
                        </string>{" "}
                        today and get prompt help after a car accident.
                      </p>
                      <hr />
                      <p className="bold-text">No upfront costs</p>
                      <p className="bottom-te">
                        Our accident management solutions see to it that you
                        receive the best support and help without any monetary
                        implications. You can make a{" "}
                        <strong>One-Call car insurance claim</strong> by making
                        just one call on our{" "}
                        <string>
                          {" "}
                          One Call car insurance accident helpline{" "}
                        </string>{" "}
                        without paying undue costs for doing so.{" "}
                      </p>
                      <hr />
                      <p className="bold-text">No loss of NCB</p>
                      <p className="bottom-te">
                        Using our services for making{" "}
                        <string> One Call car claims </string> will not only
                        enable you to access a range of comprehensive accident
                        management solutions but also offer you benefits in
                        terms of safeguarding your no-claims bonus. We will make
                        sure that nothing happens to your no-claims bonus as we
                        will make a claim directly against the other party.
                      </p>
                      <hr />
                      <p className="bold-text">
                        Quality and guaranteed repairs
                      </p>
                      <p className="bottom-te">
                        We ensure that your car is worth more in the future by
                        making arrangements for your vehicle repair with
                        trustworthy repairers who use original parts.{" "}
                      </p>
                      <hr />
                      <p className="bold-text">Courtesy car service</p>
                      <p className="bottom-te">
                        While your damaged vehicle is getting fixed, we will
                        deliver to your doorstep the right replacement car of
                        your choice so that you can continue to use it whenever
                        you want.
                      </p>
                      <hr />
                      <p className="bold-text">Friendly and supportive team </p>
                      <p className="bottom-te">
                        In case of an accident while driving, call our motor
                        claims contact number{" "}
                        <a href="tel:0800 772 0850">0800 772 0850</a> for
                        emergency help. Our friendly and supportive team will
                        relieve your worries and smoothen out your situation as
                        much as possible.{" "}
                      </p>
                      <hr />
                      <p className="bold-text">24/7 assistance</p>
                      <p className="bottom-te">
                        Our team acts fast even when it’s dark just to get you
                        back on the road as soon as possible after an accident.
                      </p>
                      <hr />
                      <p className="bold-text">Full nationwide coverage</p>
                      <p className="bottom-te">
                        Through our wide network of partner garages across the
                        UK, we are able to provide immediate help at the time of
                        need. Call us through the{" "}
                        <strong>
                          {" "}
                          One Call car insurance claims contact number{" "}
                        </strong>{" "}
                        , so that you do not have to be stuck anywhere.{" "}
                      </p>
                      <hr />
                      <p className="bold-text">Continuous tracking of claims</p>
                      <p className="bottom-te">
                        From the moment you file a claim after an accident with
                        us, we will keep track of your insurance claim for
                        purposes of informing you about any updates.
                      </p>
                      <hr />
                      <p className="bold-text">Specialised advice on claims</p>
                      <p className="bottom-te">
                        Our solicitors are well-versed when it comes to handling{" "}
                        <strong> One Call motor claims </strong>. In case you
                        call our{" "}
                        <strong> One Call motor claims contact number </strong>{" "}
                        to make a claim, our solicitors will offer guidance that
                        is guaranteed to provide you with the best results.
                      </p>
                      <hr />

                      <p className="bold-text">
                        Accelerated settlement of claims
                      </p>
                      <p className="bottom-te">
                        We aim to get your{" "}
                        <strong> One Call accident claim </strong>settled in
                        record time without requiring complex paperwork or long
                        follow-up processes.{" "}
                      </p>
                    </div>
                  </div>
                </div>
                <div className="cl4" ref={sidebarRef}>
                  <Sidebarinner />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="photographs">
        <div className="container">
          <div className="row">
            <h2 className="road">
              From start to finish, we will take care{" "}
              <span className="light">
                of <br /> your accident management requirements
              </span>{" "}
            </h2>
            <div className="col-xxl-6 col-xl-6 col-md-12 col-12">
              <div className="fourbox">
                <ul>
                  <li>
                    <img src={check} className="check" alt="" />
                    If you have been involved in a non-fault car accident,
                    contact us at our helpline to successfully make a{" "}
                    <strong>One Call car Insurance claim.</strong>
                  </li>
                  <hr />
                  <li>
                    <img src={check} className="check" alt="" />
                    We will get rid of all those pressures, burdens and
                    intricacies that come with{" "}
                    <strong> One Call auto insurance claims </strong> through
                    our professional advice and continued support.
                  </li>
                  <hr />
                  <li>
                    <img src={check} className="check" alt="" />
                    From dealing with insurers to keeping you mobile by
                    providing another car until yours is fixed, we will take
                    care of everything.
                  </li>
                  <hr />
                  <li>
                    <img src={check} className="check" alt="" />
                    We also arrange for trustworthy manufacturer-approved
                    repairers who fix cars up to the highest standards.
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-xxl-6 col-xl-6 col-md-12 col-12">
              <div className="fourbox">
                <ul>
                  <li>
                    <img src={check} className="check" alt="" />
                    If you want a replacement car similar to your own vehicle,
                    call us at car insurance claims contact number{" "}
                    <a href="tel:0800 772 0850">0800 772 0850</a>.
                  </li>
                  <hr />
                  <li>
                    <img src={check} className="check" alt="" />
                    We will appoint a dedicated claims handler who will take you
                    through the claims process and resolve it quickly.
                  </li>
                  <hr />
                  <li>
                    <img src={check} className="check" alt="" />
                    We will keep in touch with your repairer and keep you
                    informed about how your repairs are progressing.
                  </li>
                  <hr />
                  <li>
                    <img src={check} className="check" alt="" />
                    Your repaired vehicle will be examined and transport
                    arrangements will be made for the delivery of it after
                    completion of its repairs.
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="carprocess">
        <div className="container ">
          <div className="row">
            <div className="col-xxl-12 col-xxl-12 col-md-12 col-12">
              <h2 className="trusted-title">
                {" "}
                Our vast range <br />
                <span className="light">of services</span>{" "}
              </h2>
            </div>
          </div>
          <div className="row" id="possible">
            <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-12">
              <div className="claim">
                <ul>
                  <li>
                    <span className="sleej">Vehicle recovery:</span> In case of
                    a no-fault road accident, you can request accident recovery
                    support by calling our{" "}
                    <strong>
                      {" "}
                      One Call car insurance claims contact number{" "}
                    </strong>
                    . We have a speedy recovery team that will be immediately
                    dispatched to ensure your car is recovered instantly.
                  </li>
                  <li>
                    <span className="sleej">Approved repairs:</span>Just dial
                    the{" "}
                    <strong> One Call car insurance accidents helpline </strong>
                    and free yourself from worries about arranging for car
                    repair. We fix damaged vehicles through our massive network
                    of trustworthy repairers. We can organise the repair of your
                    vehicle by the best body shops in our extensive repair
                    network.
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-12">
              <div className="claim" id="blue">
                <ul>
                  <li>
                    <span className="sleej">Vehicle Storage:</span> If your car
                    is immobile, it will be kept in one of our secure storage
                    compounds. Our insured storage sites ensure safe custody
                    until the vehicle is assessed further, during which time it
                    won’t suffer any more damage at all.
                  </li>
                  <li>
                    <span className="sleej">Replacement Vehicles : </span> We
                    understand that being without a car can be an inconvenience
                    and stressful situation. To have you back on the road as
                    soon as possible, we shall provide you with a courtesy car
                    tailored to suit your day-to-day requirements through our
                    accident management services. The cost of this replacement
                    will later be claimed from the insurer of the other party
                    who was at fault.
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-12">
              <div className="claim">
                <ul>
                  <li>
                    <span className="sleej">Injury support :</span>
                    Were you injured in an accident that was not your fault?
                    Call us through our
                    <strong> One Call claims contact number </strong>so that we
                    can take care of everything related to personal injury
                    claims successfully. Our skilled solicitors will help you
                    recuperate from this ordeal both financially and mentally.
                  </li>
                  <li>
                    <span className="sleej">Claims Management:</span> Throughout
                    your claim’s journey, right from when it began until when it
                    ends, they are guided by experience and know how to make
                    sure they relieve you of all stress associated with
                    <strong> One Call car claims. </strong>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="yellowcta">
        <div className="container">
          <div className="row">
            <div className="cta2">
              <div className="col-xxl-8 col-xl-8 col-lg-8 col-12">
                <div className="legal">
                  <h3 className="experienced">
                    Got more questions?
                    <span className="light">Talk to our specialists</span>{" "}
                  </h3>
                  <p className="professionals">
                    Get in touch with our no-fault claim experts via <strong>
                       car insurance claims contact number{" "}
                      <a className="cpt-no" href="tel:0800 772 0850">
                        {" "}
                        0800 772 0850{" "}
                      </a>{" "}
                    </strong>{" "}
                    to get the best advice to pursue a successful{" "}
                    <strong> One Call accident claim. </strong>
                  </p>
                </div>
              </div>
              <div className="col-xxl-4 col-xl-4 col-lg-4 col-12">
              <div class="process">
                  <a class="tf-button style-1 phone" href="tel:0800 772 0850">
                    Get in touch<span class="icon-keyboard_arrow_right"></span>
                  </a>
                </div>
                <div class="process">
                    <a className="tf-button style-1 desktop"  onClick={handleScrollToSidebar}>
                        Get in touch<span className="icon-keyboard_arrow_right"></span>
                    </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <search className="updatedform">
        <div className="container">
          <div className="row text-center">
            <div className="col-xxl-12 col-xl-12 col-md-12 col-12">
              <h2 className="whiteheading">
                {" "}
                The benefits of choosing us for <br />
                <span className="light">accident management assistance </span>
              </h2>
              <p className="sustained">
                We are a market leader in this field providing accident
                management assistance services that eliminate complexities,
                burdens and inconveniences. This covers vehicle recovery to
                claims management among others.
              </p>
            </div>
          </div>
          <div className="row">
            <div className="col-xxl-4 col-xl-4 col-lg-6 col-md-12 col-12">
              <div className="Claimant">
                <h5>24/7 help </h5>
                <p className="collision">
                  Our service is available 24/7. So, you can call us for
                  instinct support at any time of the day whenever you want to
                  arrange for repairs on your car, need a replacement vehicle or
                  have a question on{" "}
                  <string> One call auto insurance claims. </string>
                </p>

                <h5>No excess</h5>
                <p className="collision">
                  When you use our services, we do not charge any excesses or
                  upfront fees.{" "}
                </p>

                <h5>Dependable partners</h5>
                <p className="collision">
                  We partner with reliable professionals such as experienced
                  solicitors and reputed repairers who provide top-notch
                  services.
                </p>
              </div>
            </div>
            <div className="col-xxl-4 col-xl-4 col-lg-6 col-md-12 col-12">
              <div className="Claimant">
                <h5>Hassle-free vehicle replacement </h5>
                <p className="collision">
                  We will promptly organise a like-for-like replacement which
                  suits your needs while your damaged car is being repaired.
                </p>

                <h5>Vast repair network</h5>
                <p className="collision">
                  We have an extensive network of high-end repairers who provide
                  exceptional guaranteed repairs exceeding expectations.
                </p>

                <h5>Experienced panel of solicitors</h5>
                <p className="collision">
                  Our reputable team of solicitors includes those with
                  experience in dealing with
                  <strong> auto insurance claims </strong> in the most effective
                  way possible.
                </p>
              </div>
            </div>
            <div className="col-xxl-4 col-xl-4 col-lg-12 col-md-12 col-12">
              <div className="Claimant">
                <h5>Complete legal advice for the claims proces </h5>
                <p className="collision">
                  We are with you through appraisal, negotiations, and claim
                  settlements.
                </p>

                <h5>Manufacturer-backed repairs</h5>
                <p className="collision">
                  Our repair associates are known for their excellent level of
                  service as well as superior vehicle repairs using only
                  original parts.
                </p>

                <h5>Speedy claim resolution</h5>
                <p className="collision">
                  To facilitate a maximum amount of compensation that you could
                  be awarded, our claims process is very effective and it helps
                  us to have your claim prepared and initiated in a short time.
                </p>
              </div>
            </div>
          </div>
        </div>
      </search>

      <section className="Accident-claim">
        <div className="container">
          <div className="row">
            <div className="needed">
              <h2 className="trusted-title">
                How to get the most out of the
                <br />
                <span className="light"> One Call accident claim?</span>
              </h2>
              <p className="complexities">
                It is not easy dealing with a car accident that was caused by
                someone else’s negligence. However, proving your innocence can
                be an uphill struggle. Therefore, there are certain steps that
                should be followed to prove your innocence and increase the
                possibility of receiving fair payment.
              </p>
            </div>
            <hr />
          </div>
          <div className="row" id="incdes">
            <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-12">
              <div className="Preparing">
                <ul>
                  <li>
                    <div className="seemingly">
                      <h5 className="Seek">Contact emergency services:</h5>
                      <p>
                        {" "}
                        Collect as much information as possible about the
                        occurrence and contact us at{" "}
                        <strong>
                          {" "}
                          One Call motor insurance claims number{" "}
                        </strong>{" "}
                        to arrange for recovery of your damaged vehicle.{" "}
                      </p>
                    </div>
                  </li>
                  <li>
                    <div className="seemingly">
                      <h5 className="Seek"> File an accident report: </h5>
                      <p>
                        Make sure you call the police immediately after the
                        accident so that they can file an official report which
                        will be vital in strengthening your claim.
                      </p>
                    </div>
                  </li>
                  <li>
                    <div className="seemingly">
                      <h5 className="Seek">Examine injuries:</h5>
                      <p>
                        Safety first! After a collision check yourself or any
                        passengers for serious or minor injuries.
                      </p>
                    </div>
                  </li>
                  <li>
                    <div className="seemingly">
                      <h5 className="Seek">Seek medical aid</h5>
                      <p>
                        If you sustain a severe injury that needs urgent
                        attention, hurry up to see a doctor who will treat your
                        injuries on time.
                      </p>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
            <div
              className="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-12"
              id="dualcardiv"
            >
              <img src={dualcar} alt="" className="dualcar" />
            </div>
            <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-12">
              <div className="Preparing">
                <ul>
                  <li>
                    <div className="seemingly">
                      <h5 className="Seek">Organise Vehicle Repairs :</h5>
                      <p>
                        In case you have your specific repairer, then we shall
                        liaise with them to obtain quotes and schedule a
                        suitable date for repairs. Whereby you do not have a
                        preferred repairer, we can provide names from our
                        network.
                      </p>
                    </div>
                  </li>
                  <li>
                    <div className="seemingly">
                      <h5 className="Seek">Gather information</h5>
                      <p>
                        From the scene of the accident, if possible, collect
                        evidence as well as other important details such as
                        other drivers’ names, addresses and eyewitness
                        information. This will be helpful in boosting your
                        claims by providing eyewitness statements or any other
                        possible information.
                      </p>
                    </div>
                  </li>
                  <li>
                    <div className="seemingly">
                      <h5 className="Seek">Note down damages:</h5>
                      <p>
                        Take photographs and video recordings of how your
                        vehicle was damaged before being taken to the garage.
                        This will show clearly the extent to which the vehicle
                        got damaged.
                      </p>
                    </div>
                  </li>
                  <li>
                    <div className="seemingly">
                      <h5 className="Seek">Contact a solicitor</h5>
                      <p>
                        In order to make a successful claim for damages and
                        injuries, consult an experienced solicitor who can
                        advise you on how to go about the process. Call us at{" "}
                        <strong> One Call motor claims contact number </strong>to
                        discuss your case with our experienced solicitors
                      </p>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="whyus">
        <div className="container">
          <div className="row">
            <div className="col-xxl-12 col-md-12">
              <div className="why">
                <h2 className="choose">
                  Fast and stress-free One Call{" "}
                  <span className="light">car claims process</span>
                </h2>
                <p className="complexities">
                  Do you wish to make a{" "}
                  <strong>One Call accident claim? </strong>The fastest way is
                  to reach us via our{" "}
                  <strong> One Call car insurance claims phone number </strong>{" "}
                  so that we can assist you in seeking compensation for the
                  injuries and the damage caused by the road mishap.{" "}
                </p>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-xxl-3 col-xl-6 col-lg-6 col-md-6 col-12">
              <div className="box-service">
                <img src={check} className="check" alt="" />
                <h4>Contact Our Team </h4>
                <p>
                  To get professional guidance concerning your claim, please
                  contact our team through our
                  <strong> car insurance accident helpline </strong>at{" "}
                  <a href="tel:0800 772 0850"> 0800 772 0850 </a>.
                </p>
              </div>
            </div>
            <div className="col-xxl-3 col-xl-6 col-lg-6 col-md-6 col-12">
              <div className="box-service">
                <img src={check} className="check" alt="" />
                <h4>Give details</h4>
                <p>
                  Some of the necessary details about the accident should
                  include the date, time, place and what exactly happened.
                </p>
              </div>
            </div>
            <div className="col-xxl-3 col-xl-6 col-lg-6 col-md-6 col-12">
              <div className="box-service">
                <img src={check} className="check" alt="" />
                <h4>Provide evidence</h4>
                <p>
                  Vital evidence such as photos of where it occurred including
                  eyewitness accounts, CCTV footage or any other significant
                  information should be handed in to make your claim stronger.
                </p>
              </div>
            </div>
            <div className="col-xxl-3 col-xl-6 col-lg-6 col-md-6 col-12">
              <div className="box-service">
                <img src={check} className="check" alt="" />
                <h4>Claim Review and Resolution</h4>
                <p>
                  Our team will go through your claim thoroughly and do
                  everything possible so that it can be resolved within the
                  shortest period possible.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="faqs">
        <div className="container">
          <div className="row">
            <div className="col-xxl-12 col-xl-12 col-md-12 col-12">
              <div className="fq">
                <h2>
                  {" "}
                  Frequently Asked{" "}
                  <span className="light">Questions (FAQ)</span>
                </h2>
                <p className="complexities">
                  Got questions about <strong> One Call car claims? </strong> We
                  have them answered here.
                </p>
              </div>
              <div className="allfaq">
                <Accordion defaultActiveKey="0">
                  <Accordion.Item eventKey="0">
                    <Accordion.Header>
                      Should I claim against my own insurance?
                    </Accordion.Header>
                    <Accordion.Body>
                      No, by making contact with us via the{" "}
                      <strong>
                        {" "}
                        One Call car insurance claims contact number{" "}
                      </strong>{" "}
                      , we will advise you accordingly without you paying any
                      policy excess.
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="1">
                    <Accordion.Header>
                      What sort of vehicle will be provided while mine is being
                      repaired?
                    </Accordion.Header>
                    <Accordion.Body>
                      We will organise a like-for-like replacement vehicle based
                      on the size and standard of your own vehicle. You can
                      speak to us about what it is you want through the{" "}
                      <strong> One Call motor claims number </strong> and we
                      will supply a suitable replacement car for you.
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="2">
                    <Accordion.Header>
                      Who covers the costs involved in providing accident
                      management services?
                    </Accordion.Header>
                    <Accordion.Body>
                      Our partners and we shall take back all charges associated
                      with repairs, hire vehicles or other expenditures from the
                      insurer of the negligent party.
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="3">
                    <Accordion.Header>
                      Can you fix my car at my preferred garage?
                    </Accordion.Header>
                    <Accordion.Body>
                      Yes. Just let us know who that would be and we can work
                      out directly with them for a smooth repair.
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="4">
                    <Accordion.Header>
                      My vehicle is not repairable. Can you assist?
                    </Accordion.Header>
                    <Accordion.Body>
                      Sure, we will give you an alternative car when your car
                      has been written off and speak to the person who caused
                      the accident so as to get a good settlement for you.
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="5">
                    <Accordion.Header>
                      Will I lose my no-claims bonus if I make a claim?
                    </Accordion.Header>
                    <Accordion.Body>
                      No. We guarantee that making a{" "}
                      <strong> One call accident claim </strong> with us will
                      not affect your no-claim bonus.
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="6">
                    <Accordion.Header>
                      What should I do if the third-party insurers try to
                      contact me?
                    </Accordion.Header>
                    <Accordion.Body>
                      When a third-party insurer contacts you directly about
                      this matter, in case you decide to have us handle your
                      claim then you can refer them to us. We shall take care of
                      everything and also act as intermediaries in all the
                      dealings on your behalf.
                    </Accordion.Body>
                  </Accordion.Item>
                </Accordion>
              </div>
            </div>
          </div>
        </div>
      </section>

      <Footer />
    </>
  );
};

export default Onecallinsurance;
