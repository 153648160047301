import React, { useRef } from "react";
import Accordion from "react-bootstrap/Accordion";
import check from "../assets/images/check.png";
import dualcar from "../../src/assets/images/dualcar.webp";
import carbnr1 from "../../src/assets/images/carbnr1.webp";
import Sidebarinner from "../components/Sidebar-inner";
import phonecall from "../assets/images/phonecall.png";
import Footer from "../components/footer";
import { Helmet } from "react-helmet";

const Coverbox = () => {
  const sidebarRef = useRef(null);

  const handleScrollToSidebar = () => {
    if (sidebarRef.current) {
      sidebarRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Coverbox Car Insurance Accident Claim Phone Number:</title>
        <meta
          name="description"
          content="Call our friendly team on the Coverbox Car Insurance accident claim phone number 0800 772 0850 for quick claim processing and reliable support. Contact us now. "
        />
        <link
          rel="canonical"
          href="https://insurance-accident-claims.co.uk/coverbox"
        />
      </Helmet>
      <section className="innernav">
        <div className="container">
          <div className="row">
            <div className="col-md-6 col-sm-6">
              <div className="left-logo">
                <h3>
                  Accident<span className="sipo"> Claims</span>
                </h3>
              </div>
            </div>
            <div className="col-md-6 col-sm-6">
              <div className="left-cta">
                <div class="headercnt">
                  <div class="chatmsg">
                    <img
                      src={phonecall}
                      className="phonecall"
                      alt="phonecall "
                    />
                  </div>
                  <div class="box-body">
                    <h3 class="nav-info-box-title">Toll Free Number </h3>
                    <p>
                      <a href="tel:0800 772 0850">0800 772 0850</a>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section
        className="newbnr"
        style={{ backgroundImage: "url(" + carbnr1 + ")" }}
      >
        <div className="container">
          <div className="row">
            <div className="col-xxl-8 col-xl-8 col-md-10 col-12">
              <div className="innerbnr">
                <h1>
                  <span className="onespan">No-fault</span>{" "}
                  <span className="sci">accident insurance claims </span> in the UK
                </h1>
                <p className="brokerage">
                  We offer full accident management services from vehicle
                  recovery to claims handling.
                </p>
                <div className="bnrbtn">
                  <a class="db-btn-border btn-rollover phone" href="tel:0800 772 0850">
                    Road Accident Claim Help?{" "}
                    <i class="fa-solid fa-arrow-right"></i>
                  </a>
                </div>
                <div className="bnrbtn">
                <a className="db-btn-border btn-rollover desktop"  onClick={handleScrollToSidebar}>
                    Road Accident Claim Help? <i className="fa-solid fa-arrow-right"></i>
                </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="stickysection">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="group-4-8">
                <div className="cl8 tf-tab">
                  <div className="content-tab">
                    <div className="inner-content">
                      <h2>
                        No-fault claims assistance
                        <br />
                        <span className="light">at no cost </span>{" "}
                      </h2>
                      <p className="disciplines">
                        We know how frustrating and time-consuming it can be to
                        deal with a no-fault car accident. As such, we provide
                        our clients with the best{" "}
                        <strong> Coverbox insurance accident claim </strong>{" "}
                        support without any financial burden.
                      </p>
                      <hr />
                      <p className="bold-text">No hidden costs</p>
                      <p className="bottom-te">
                        It is after a non-fault car accident that we come to
                        your assistance without necessarily making your life
                        more difficult financially. So when you call us through
                        our{" "}
                        <strong>
                          {" "}
                          accident number{" "}
                          <a href="tel:0800 772 0850">0800 772 0850</a>
                        </strong>
                        , you will not be charged extra by us or any other
                        hidden fees.
                      </p>
                      <hr />
                      <p className="bold-text">No effect on your policy</p>
                      <p className="bottom-te">
                        Protecting your premiums is important to us. Hence, if
                        you dial{" "}
                        <strong> the Coverbox claims contact number </strong>and
                        file your claim, there won’t be any record of claiming
                        on your personal insurance. Hence ensuring my premiums
                        are protected and unaffected.
                      </p>
                      <hr />
                      <p className="bold-text">
                        No claim bonus remains the same
                      </p>
                      <p className="bottom-te">
                        We do not count your no-claims bonus when you make a
                        claim for an accident. You can avoid unexpected charges
                        and anxiety about your NCB by calling us at the{" "}
                        <strong>Coverbox car insurance claims number. </strong>
                      </p>
                      <hr />
                      <p className="bold-text">Genuine parts genuine repair </p>
                      <p className="bottom-te">
                        We offer a full accident repair solution so as to return
                        your accident back to what it was before the incident.
                        Phone us through the Coverbox car accident helpline, and
                        get your car repaired by reputable garages without
                        incurring any extra costs.
                      </p>
                      <hr />
                      <p className="bold-text">Immediate vehicle recovery</p>
                      <p className="bottom-te">
                        We will quickly and safely recover your vehicle
                        immediately after a non-fault accident. We work round
                        the clock to provide emergency assistance and get your
                        vehicle back within moments after a non-fault accident
                        has occurred.
                      </p>
                      <hr />
                      <p className="bold-text">No fault experts</p>
                      <p className="bottom-te">
                        If you have been involved in a no-fault accident, call
                        us now at the{" "}
                        <strong> Coverbox accident claim number, </strong>and we
                        will link you up with a no-fault claims expert who will
                        handle everything concerning your claim until it is
                        resolved.
                      </p>
                      <hr />
                      <p className="bold-text">Professional advice </p>
                      <p className="bottom-te">
                        Our panel of experienced no-fault solicitors comprises
                        professionals, who have handled thousands of no-fault
                        claims. Therefore, when you call us on the{" "}
                        <strong>Coverbox claims contact number,</strong> we will
                        offer you qualified guidance from a specialist
                        solicitor.
                      </p>
                      <hr />
                      <p className="bold-text">Dedicated team</p>
                      <p className="bottom-te">
                        We are here to relieve you of all the inconveniences
                        that come after an accident in which you were not at
                        fault. Contact us through the{" "}
                        <strong> Coverbox accident claims number </strong>
                        for any help following an accident. Our dedicated team
                        will provide immediate support so that you can get back
                        on the road as soon as possible.{" "}
                      </p>
                      <hr />
                      <p className="bold-text">Free claim consultation </p>
                      <p className="bottom-te">
                        This is what we do to ensure your speedy recovery after
                        a no-fault accident. To get free assistance regarding
                        your <strong> Coverbox insurance claim, </strong> just
                        dial{" "}
                        <strong> our Coverbox accident claims number. </strong>
                      </p>
                      <hr />

                      <p className="bold-text">Fast-paced claim process</p>
                      <p className="bottom-te">
                        When making your{" "}
                        <strong> Coverbox insurance accident claim </strong>with
                        us, you can be confident of quick compensation. Our
                        dependable crew work tirelessly to hasten every stage of
                        the claim process and guarantee rapid resolution.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="cl4" ref={sidebarRef}>
                  <Sidebarinner />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="photographs">
        <div className="container">
          <div className="row">
            <h2 className="road">
              We ensure your peace of mind after a{" "}
              <span className="light">
                no-fault <br />
                road accident
              </span>{" "}
            </h2>
            <div className="col-xxl-6 col-xl-6 col-md-12 col-12">
              <div className="fourbox">
                <ul>
                  <li>
                    <img src={check} className="check" alt="" />
                    We are professionals in offering Coverbox insurance accident
                    support with a success story for having assisted thousands
                    of motorists in the United Kingdom to get back to their
                    wheels.
                  </li>
                  <hr />
                  <li>
                    <img src={check} className="check" alt="" />
                    When you call us on our car accident helpline{" "}
                    <a href="tel:0800 772 0850">0800 772 0850</a>, we will
                    provide you with another vehicle free of charge that will
                    enable you to continue your day-to-day activities.
                  </li>
                  <hr />
                  <li>
                    <img src={check} className="check" alt="" />
                    Additionally, we will evaluate the validity of your{" "}
                    <strong> Coverbox insurance accident claim </strong> and
                    gather vital information and documents required for its
                    processing.
                  </li>
                  <hr />
                  <li>
                    <img src={check} className="check" alt="" />
                    In addition, we can arrange for the repairs on your damaged
                    car using original parts from the garage of your preference.
                    Just make sure you reach us on our{" "}
                    <strong> Coverbox car accident helpline </strong>for quality
                    accident repairs.
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-xxl-6 col-xl-6 col-md-12 col-12">
              <div className="fourbox">
                <ul>
                  <li>
                    <img src={check} className="check" alt="" />
                    If you have suffered injuries in a faultless accident,
                    please contact us on the{" "}
                    <strong> Coverbox car accident helpline </strong>and speak
                    with one of our experienced solicitors, who will be able to
                    advise you regarding an injury caused by an insured driver
                    through no fault if it is covered under the policy.
                  </li>
                  <hr />
                  <li>
                    <img src={check} className="check" alt="" />
                    Upon calling our claims department via the{" "}
                    <strong> Coverbox accident number, </strong> we shall
                    prepare a list showing all losses arising due to that
                    incident and submit them to the insurer or directly involved
                    party’s company responsible for compensation.
                  </li>
                  <hr />
                  <li>
                    <img src={check} className="check" alt="" />
                    We will take charge of your claim process from inception to
                    conclusion and correspond with various individuals concerned
                    with the accident.
                  </li>
                  <hr />
                  <li>
                    <img src={check} className="check" alt="" />
                    After the completion of your vehicle repairs, we will get in
                    touch with you to plan for the recovery of the fully
                    restored car.
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="carprocess">
        <div className="container ">
          <div className="row">
            <div className="col-xxl-12 col-xxl-12 col-md-12 col-12">
              <h2 className="trusted-title">
                {" "}
                Coverbox Services <br />
                <span className="light">we offer</span>{" "}
              </h2>
            </div>
          </div>
          <div className="row" id="possible">
            <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-12">
              <div className="claim">
                <ul>
                  <li>
                    <span className="sleej">Vehicle recovery :</span>If you are
                    looking for a company that provides accident recovery
                    service to shift your vehicle to another location after a
                    no-fault car accident, call us at{" "}
                    <strong> Coverbox claims contact number. </strong>Our team
                    will be there within minutes to recover your vehicle from
                    the scene of the accident.
                  </li>
                  <li>
                    <span className="sleej">Vehicle repairs:</span>Our trusted
                    repairer will repair your damaged vehicle at no cost using
                    original parts. Call us on{" "}
                    <strong> the Coverbox car insurance claim number </strong>{" "}
                    if you want to fix your damaged car through our quick and
                    reliable repair network.
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-12">
              <div className="claim" id="blue">
                <ul>
                  <li>
                    <span className="sleej">Vehicle storage:</span> We have an
                    all-inclusive car vehicle storage facility designed to give
                    you peace of mind after a non-at-fault accident. Your
                    damaged vehicle can be stored in our storage facility, where
                    it awaits either repair or further assessment by an expert.
                    Please do not hesitate to reach us through the{" "}
                    <strong> Coverbox accident claims number </strong>to move
                    your vehicle into our well-maintained yards without
                    incurring extra costs.
                  </li>
                  <li>
                    <span className="sleej">Replacement car:</span>If your car
                    is not drivable after a non-fault accident, our aim is to
                    provide you with a replacement vehicle of the same kind so
                    that you can still go on with your routine activities
                    without any inconvenience. We will organise a courtesy car
                    to be delivered to you that best suits your individual needs
                    when you contact us through the{" "}
                    <strong> Coverbox car accident helpline. </strong>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-12">
              <div className="claim">
                <ul>
                  <li>
                    <span className="sleej">Personal injury claim:</span>
                    When an accident happens, and you call the
                    <strong> Coverbox accident claims phone number </strong>for
                    information about how to file a claim, we will appoint a
                    qualified solicitor who will guide and support you until all
                    matters arising from your situation are resolved.
                  </li>
                  <li>
                    <span className="sleej">Claims management:</span>We
                    specialise in handling no-fault accidents in the UK. Thus,
                    call the Coverbox accident claims helpline when making
                    inquiries regarding your case, and we shall simplify
                    everything as well as ensure that you get proper
                    compensation for all damages suffered by you.
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="yellowcta">
        <div className="container">
          <div className="row">
            <div className="cta2">
              <div className="col-xxl-8 col-xl-8 col-lg-8 col-12">
                <div className="legal">
                  <h3 className="experienced">
                    Need further assistance?{" "}
                    <span className="light">
                      Speak to our no-fault claim experts.
                    </span>{" "}
                  </h3>
                  <p className="professionals">
                    Get in touch with us via the{" "}
                    <strong>Coverbox accident claims number </strong>
                    to get professional advice for your no-fault claim from our
                    expert solicitors.
                  </p>
                </div>
              </div>
              <div className="col-xxl-4 col-xl-4 col-lg-4 col-12">
              <div class="process">
                  <a class="tf-button style-1 phone" href="tel:0800 772 0850">
                    Get in touch<span class="icon-keyboard_arrow_right"></span>
                  </a>
                </div>
                <div class="process">
                    <a className="tf-button style-1 desktop"  onClick={handleScrollToSidebar}>
                        Get in touch<span className="icon-keyboard_arrow_right"></span>
                    </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <search className="updatedform">
        <div className="container">
          <div className="row text-center">
            <div className="col-xxl-12 col-xl-12 col-md-12 col-12">
              <h2 className="whiteheading">
                {" "}
                Why do we stand out as your reliable partner for <br />
                <span className="light">accident management solutions?</span>
              </h2>
              <p className="sustained">
                Talk to us before speaking to your insurer on the{" "}
                <string> Coverbox claims contact number. </string> This will
                give you several benefits over and above those offered by your
                insurance company.
              </p>
            </div>
          </div>
          <div className="row">
            <div className="col-xxl-4 col-xl-4 col-lg-6 col-md-12 col-12">
              <div className="Claimant">
                <h5>No hidden extras </h5>
                <p className="collision">
                  We will not put any financial burden on you since we will be
                  collecting all the charges for our services from the insurer
                  of the wrongdoer.
                </p>

                <h5>Rapid claim processing</h5>
                <p className="collision">
                  For a <strong>Coverbox insurance accident claim,</strong>our
                  process is clear, easy and fast, taking less time to settle
                  your claim.{" "}
                </p>

                <h5>Non-stop support</h5>
                <p className="collision">
                  Our team can always provide you with an immediate response
                  after a non-fault car accident.
                </p>
              </div>
            </div>
            <div className="col-xxl-4 col-xl-4 col-lg-6 col-md-12 col-12">
              <div className="Claimant">
                <h5>Top quality repairs</h5>
                <p className="collision">
                  In the UK, we have a solid network of repairers who are
                  scrutinised well to ensure that their repairs are of the
                  highest quality. You may dial the{" "}
                  <strong> Coverbox accident number </strong> for excellent
                  repairs for your damaged vehicle.
                </p>
                <h5>Free replacement vehicle </h5>
                <p className="collision">
                  If you reach us through the{" "}
                  <strong> Coverbox claims contact number, </strong> we shall
                  give you another vehicle which closely matches yours without
                  making any payment.
                </p>

                <h5>Advice without obligation</h5>
                <p className="collision">
                  For legal guidance on what next, our accomplished solicitors
                  can provide free advice based on your accident facts.{" "}
                </p>
              </div>
            </div>
            <div className="col-xxl-4 col-xl-4 col-lg-12 col-md-12 col-12">
              <div className="Claimant">
                <h5>Ongoing claims monitoring</h5>
                <p className="collision">
                  We will monitor the progress of your{" "}
                  <strong> Coverbox Insurance accident claim </strong>till the
                  end and continue to update you more often, particularly at
                  every stage.
                </p>

                <h5>Expert claim solicitors </h5>
                <p className="collision">
                  Our solicitors are specialists for no-fault claims with
                  Coverbox insurance accident claims and have vast expertise on
                  how to get the maximum compensation for victims who suffered
                  in no-fault accidents.
                </p>

                <h5>No Fuss</h5>
                <p className="collision">
                  When you contact us through the{" "}
                  <strong> Coverbox accident claims number, </strong> we will
                  ensure that every aspect of your claim is handled with utmost
                  precision, making the process as stress-free as possible for
                  you.
                </p>
              </div>
            </div>
          </div>
        </div>
      </search>

      <section className="Accident-claim">
        <div className="container">
          <div className="row">
            <div className="needed">
              <h2 className="trusted-title">
                How to maximise your no-fault car <br />
                <span className="light"> accident compensation</span>
              </h2>
              <p className="complexities">
                After a no-fault collision, there are many steps that must be
                taken immediately to prove innocence and enhance one's claim.
                Here are some of the things you must do in order to increase
                your chances of getting full compensation from yourCoverbox
                insurance accident claim.{" "}
              </p>
            </div>
            <hr />
          </div>
          <div className="row" id="incdes">
            <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-12">
              <div className="Preparing">
                <ul>
                  <li>
                    <div className="seemingly">
                      <h5 className="Seek">Stay at the Scene :</h5>
                      <p>
                        {" "}
                        Don’t flee from the accident scene after a no-fault
                        accident. Instead, find a safe place and park there.{" "}
                      </p>
                    </div>
                  </li>
                  <li>
                    <div className="seemingly">
                      <h5 className="Seek">Call the police: </h5>
                      <p>
                        Call the police and report the accident within 24 hours.
                        Also, make sure to obtain a copy of the police report.
                      </p>
                    </div>
                  </li>
                  <li>
                    <div className="seemingly">
                      <h5 className="Seek">Gather Proof:</h5>
                      <p>
                        After a car accident, be sure to gather as much evidence
                        as you can from the scene of the accident, including
                        pictures of the accident itself, eyewitness accounts,
                        and any other vital piece of evidence that will support
                        your claim.
                      </p>
                    </div>
                  </li>
                  <li>
                    <div className="seemingly">
                      <h5 className="Seek">Seek Medical Attention</h5>
                      <p>
                        Get immediate medical attention for your injuries after
                        a non-fault accident. The longer you take before seeking
                        medical assistance for your injuries, the more difficult
                        it will be for you to prove that they were caused by an
                        accident.
                      </p>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
            <div
              className="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-12"
              id="dualcardiv"
            >
              <img src={dualcar} alt="" className="dualcar" />
            </div>
            <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-12">
              <div className="Preparing">
                <ul>
                  <li>
                    <div className="seemingly">
                      <h5 className="Seek">
                        Stay away from first offer settlements:
                      </h5>
                      <p>
                        Avoid accepting initial settlement offers by insurance
                        companies since these are quick, lowball settlements.
                        Once you accept this settlement, it becomes impossible
                        to further pursue the claim.{" "}
                      </p>
                    </div>
                  </li>
                  <li>
                    <div className="seemingly">
                      <h5 className="Seek">
                        Communicate with the other driver{" "}
                      </h5>
                      <p>
                        Exchange important information about yourself and the
                        car with another driver, such as his name, vehicle
                        details such as registration number and make, insurance
                        policy details and driver’s licence number.
                      </p>
                    </div>
                  </li>
                  <li>
                    <div className="seemingly">
                      <h5 className="Seek">
                        Organise for recovery of vehicle:
                      </h5>
                      <p>
                        If your vehicle is not in a movable state, make
                        necessary arrangements for its recovery by calling
                        assist in recovering it by calling the{" "}
                        <strong> Coverbox accident number. </strong>{" "}
                      </p>
                    </div>
                  </li>
                  <li>
                    <div className="seemingly">
                      <h5 className="Seek">Make your claim </h5>
                      <p>
                        You should contact our claims department through{" "}
                        <strong> Coverbox claims contact number </strong> after
                        collecting all the evidence and having all the papers
                        required.
                      </p>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="whyus">
        <div className="container">
          <div className="row">
            <div className="col-xxl-12 col-md-12">
              <div className="why">
                <h2 className="choose">
                  Our simple and direct Coverbox insurance{" "}
                  <span className="light">accident claim process.</span>
                </h2>
                <p className="complexities">
                  You can contact us on the{" "}
                  <strong> Coverbox car insurance claim number </strong>to make
                  a successful claim with us. We have cut the red tape
                  associated with claims and made them as uncomplicated as
                  possible.
                </p>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-xxl-3 col-xl-6 col-lg-6 col-md-6 col-12">
              <div className="box-service">
                <img src={check} className="check" alt="" />
                {/* <img src={experience} className="experience" alt="" /> */}
                <h4>Talk to our experts</h4>
                <p>
                  Reach us on the{" "}
                  <strong> Coverbox accident claim phone number </strong>
                  for car accident reporting and the beginning of your claim
                  process.
                </p>
              </div>
            </div>
            <div className="col-xxl-3 col-xl-6 col-lg-6 col-md-6 col-12">
              <div className="box-service">
                <img src={check} className="check" alt="" />
                <h4>Share accident details</h4>
                <p>
                  You need to let us know all information pertaining to your
                  no-fault case, such as date, time, place, and cause of the
                  accident among others.
                </p>
              </div>
            </div>
            <div className="col-xxl-3 col-xl-6 col-lg-6 col-md-6 col-12">
              <div className="box-service">
                <img src={check} className="check" alt="" />
                <h4>Submit crucial evidence</h4>
                <p>
                  To prove that you are entitled to compensation, we may require
                  vital evidence. Therefore, contact our team and deliver all
                  necessary evidence that you have collected at the scene of the
                  incident to our solicitors.{" "}
                </p>
              </div>
            </div>
            <div className="col-xxl-3 col-xl-6 col-lg-6 col-md-6 col-12">
              <div className="box-service">
                <img src={check} className="check" alt="" />
                <h4>Claim resolution</h4>
                <p>
                  When we have gathered all essential particulars about your
                  claim in this regard, we shall launch it for you so that
                  everything is completed on your behalf in order to secure fair
                  compensation for your injuries experienced by you.{" "}
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="faqs">
        <div className="container">
          <div className="row">
            <div className="col-xxl-12 col-xl-12 col-md-12 col-12">
              <div className="fq">
                <h2>
                  {" "}
                  Frequently Asked{" "}
                  <span className="light">Questions (FAQ)</span>
                </h2>
                <p className="complexities">
                  Got more questions about our no-fault{" "}
                  <strong>Coverbox insurance accident claim.</strong>We have
                  them answered below.
                </p>
              </div>
              <div className="allfaq">
                <Accordion defaultActiveKey="0">
                  <Accordion.Item eventKey="0">
                    <Accordion.Header>
                      Would using Coverbox insurance accident support services
                      impact my insurance premiums?
                    </Accordion.Header>
                    <Accordion.Body>
                      No, they will not. As far as our service remains
                      uncompromisable against your own policy, there is no way
                      whatsoever it would affect insurance rates or cost extra
                      money.
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="1">
                    <Accordion.Header>
                      How long can you take to get me another car in case of a
                      no-fault accident?
                    </Accordion.Header>
                    <Accordion.Body>
                      We understand that dealing with no-fault accidents is a
                      very stressful affair. As a result, we will get a
                      replacement vehicle of the same type within 24 hours for
                      you. Until your vehicle is completely restored to its
                      pre-accident state, you may use it.
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="2">
                    <Accordion.Header>
                      What if I want my car fixed by my choice repairer?
                    </Accordion.Header>
                    <Accordion.Body>
                      We give you the freedom to choose your favourite workshop
                      to fix your vehicle. You can tell us about your preferred
                      repairer, and then we shall coordinate the repair process
                      together with them.
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="3">
                    <Accordion.Header>
                      When will my damaged vehicle be repaired?{" "}
                    </Accordion.Header>
                    <Accordion.Body>
                      Various factors determine how soon a car is restored, such
                      as the damage’s magnitude and the availability of
                      manufacturer-approved parts among others. We shall ask the
                      repairer to quote for us and let you know how long it will
                      take for the repairs to be completed.
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="4">
                    <Accordion.Header>
                      What are the damages and losses that I can ask for when
                      claiming against the other party?
                    </Accordion.Header>
                    <Accordion.Body>
                      One may claim for the cost of repairing the vehicle,
                      payment for medical bills, loss of earnings as well as the
                      emotional, physical and psychological pain experienced due
                      to the incident. For more advice on what you can claim,
                      call the Coverbox claims contact number.
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="5">
                    <Accordion.Header>
                      How much time will it take for my Coverbox insurance
                      accident claim to settle?{" "}
                    </Accordion.Header>
                    <Accordion.Body>
                      The duration of your case settlement might depend on
                      several factors. Nevertheless, upon contacting us through
                      our{" "}
                      <strong>
                        {" "}
                        Coverbox accident claims helpline, we shall hasten your
                        claim process to make sure it is resolved in no time.
                      </strong>
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="6">
                    <Accordion.Header>
                      Should I accept an out-of-court settlement from the
                      insurance company of a person who caused the accident?{" "}
                    </Accordion.Header>
                    <Accordion.Body>
                      No. You must prevent yourself from rushing to accept
                      out-of-court settlements by insurance firms. Your claim
                      will die once you take their offer and as a result, lose
                      your right to sue for adequate compensation.
                    </Accordion.Body>
                  </Accordion.Item>
                </Accordion>
              </div>
            </div>
          </div>
        </div>
      </section>

      <Footer />
    </>
  );
};

export default Coverbox;
