import Footer from "../components/footer";
import newblog from "../assets/images/newblogimg.jpg";
import phonecall from "../assets/images/phonecall.png";
import backgroundImage from '../assets/images/car-auto-motor-insurance-reimbursement-vehicle-concept.jpg';
import Gotop from "../components/gotop";
const Blog = () => {
  return (
    <div>
      <section className="innernav">
        <div className="container">
          <div className="row">
            <div className="col-md-6 col-sm-6">
              <div className="left-logo">
                {/* <img src={insurancelogo} className='insurancelogo' alt='Insurance Logo' /> */}
                <h3>Accident Claims</h3>
              </div>
            </div>
            <div className="col-md-6 col-sm-6">
              <div className="left-cta">
                <div class="headercnt">
                  <div class="chatmsg">
                    <img
                      src={phonecall}
                      className="phonecall"
                      alt="phonecall "
                    />
                  </div>
                  <div class="box-body">
                    <h3 class="nav-info-box-title">Toll Free Number </h3>
                    <p>
                      <a href="tel:0800 772 0850">0800 772 0850</a>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <div className="box" style={{ backgroundImage: `url(${backgroundImage})` }}>
        <div className="box-content">
          <h1>Blog</h1>
        </div>

      </div>
      <div className="container">
        <div className="blog-parent">
          <div className="row">
            <div className="col-md-4 col-sm-4">
              <div className="blog-image">
                <a href="/Top-5-car-insurance-claim-companies-in-the-uk"><img src={newblog} alt="" /> </a>
                <div className="blog-text1">
                  <a href="/Top-5-car-insurance-claim-companies-in-the-uk">       <h4>Top 5 car insurance claim companies in the UK</h4> </a>
                  <p>Being involved in a car accident is a traumatic experience, but what’s even more frustrating is dealing with a tedious claims process on your own</p>
                  <div className="meta1">
                    <h6 className="author">
                      <a href="/Top-5-car-insurance-claim-companies-in-the-uk"><span>Read More</span></a>
                    </h6>
                  </div>
                </div>
              </div>
            </div> </div>
        </div></div>

      <Footer />
      <Gotop />
    </div>
  )
}

export default Blog;
