import React, { useRef } from "react";
import Accordion from "react-bootstrap/Accordion";
import check from "../assets/images/check.png";
import dualcar from "../../src/assets/images/dualcar.webp";
import carbnr1 from "../../src/assets/images/carbnr1.webp";
import Sidebarinner from "../components/Sidebar-inner";
import phonecall from "../assets/images/phonecall.png";
import Footer from "../components/footer";
import { Helmet } from "react-helmet";

const Marmalade = () => {
  const sidebarRef = useRef(null);

  const handleScrollToSidebar = () => {
    if (sidebarRef.current) {
      sidebarRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Marmalade Car Insurance Accident Claim Phone Number:</title>
        <meta
          name="description"
          content="Want to file a claim? Contact our dedicated team via the Marmalade Car Insurance accident claim phone number 0800 772 0850 for quick and hassle-free claim support."
        />
        <link
          rel="canonical"
          href="https://insurance-accident-claims.co.uk/marmalade-car-insurance"
        />
      </Helmet>
      <section className="innernav">
        <div className="container">
          <div className="row">
            <div className="col-md-6 col-sm-6">
              <div className="left-logo">
                <h3>
                  Accident<span className="sipo"> Claims</span>
                </h3>
              </div>
            </div>
            <div className="col-md-6 col-sm-6">
              <div className="left-cta">
                <div class="headercnt">
                  <div class="chatmsg">
                    <img
                      src={phonecall}
                      className="phonecall"
                      alt="phonecall "
                    />
                  </div>
                  <div class="box-body">
                    <h3 class="nav-info-box-title">Toll Free Number </h3>
                    <p>
                      <a href="tel:0800 772 0850">0800 772 0850</a>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section
        className="newbnr"
        style={{ backgroundImage: "url(" + carbnr1 + ")" }}
      >
        <div className="container">
          <div className="row">
            <div className="col-xxl-8 col-xl-8 col-md-10 col-12">
              <div className="innerbnr">
                <h1>
                  <span className="onespan">We specialise in </span>{" "}
                  <span className="sci">no-fault</span> car accident
                  insurance claims.
                </h1>
                <p className="brokerage">
                  We take the stress away from you by doing everything including
                  evaluating and fixing your damaged vehicle, supplying another
                  one while yours is being fixed and managing your claim from
                  start to finish.
                </p>
                <div className="bnrbtn">
                  <a class="db-btn-border btn-rollover phone" href="tel:0800 772 0850">
                    Road Accident Claim Help?{" "}
                    <i class="fa-solid fa-arrow-right"></i>
                  </a>
                </div>
                <div className="bnrbtn">
                <a className="db-btn-border btn-rollover desktop"  onClick={handleScrollToSidebar}>
                    Road Accident Claim Help? <i className="fa-solid fa-arrow-right"></i>
                </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="stickysection">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="group-4-8">
                <div className="cl8 tf-tab">
                  <div className="content-tab">
                    <div className="inner-content">
                      <h2>
                        We are your reliable accident
                        <br />
                        <span className="light">
                          management partners in the UK.{" "}
                        </span>{" "}
                      </h2>
                      <p className="disciplines">
                        We know that road crashes can be earth-shattering, and
                        that is why we are with you every step of the way. We
                        stand by you in all your car accident consequences with
                        our wide-ranging accident management service to make
                        everything easy for you. In case of a car collision,
                        please call us at the{" "}
                        <strong> Marmalade claims number </strong> , and we will
                        assist in making your recovery process smoother than
                        before.
                      </p>
                      <hr />
                      <p className="bold-text">Zero insurance excess</p>
                      <p className="bottom-te">
                        We will not make any claim under your own insurance
                        policy. This means you do not pay any excess towards
                        your premium.
                      </p>
                      <hr />
                      <p className="bold-text">No loss of no-claims bonus</p>
                      <p className="bottom-te">
                        As long as it is proved that the other party caused the
                        accident, we will prevent your precious discounts and
                        premiums gained over time from being affected.
                      </p>
                      <hr />
                      <p className="bold-text">Like-for-like replacement</p>
                      <p className="bottom-te">
                        Having no access to a vehicle following an accident
                        would hinder one’s activities. We will provide you with
                        a like-for-like replacement vehicle so that your life
                        can continue without interruption.
                      </p>
                      <hr />
                      <p className="bold-text">First class repairs</p>
                      <p className="bottom-te">
                        This is where our vast network of UK-approved repairers
                        comes into play which specialises in providing
                        guaranteed and quality repairs.
                      </p>
                      <hr />
                      <p className="bold-text">Fast claims handling</p>
                      <p className="bottom-te">
                        Our team works around the clock together with our
                        experienced solicitors who put speed into every aspect
                        of the <strong> Marmalade car insurance claim </strong>
                        process.
                      </p>
                      <hr />
                      <p className="bold-text">No-obligation advice</p>
                      <p className="bottom-te">
                        To talk to solicitors and receive no-obligation advice
                        regarding your claim, call us on the{" "}
                        <strong> Marmalade car insurance claim number. </strong>
                      </p>
                      <hr />
                      <p className="bold-text">Round-the-clock assistance </p>
                      <p className="bottom-te">
                        Since we know car accidents can happen at any time, we
                        are here for you twenty-four hours a day offering
                        immediate roadside assistance when needed.
                      </p>
                      <hr />
                      <p className="bold-text">Experienced solicitors</p>
                      <p className="bottom-te">
                        Our solicitors have experience in dealing with no-fault
                        accident claims. They can smoothly handle your claim
                        from inception to settlement.
                      </p>
                      <hr />
                      <p className="bold-text">Fast & effective action</p>
                      <p className="bottom-te">
                        We understand what it means to have quick help after a
                        car crash. Should you call us using the{" "}
                        <strong> Marmalade car insurance claim number </strong>{" "}
                        our team will promptly answer your call and assist you
                        so that you do not suffer a lot.{" "}
                      </p>
                      <hr />

                      <p className="bold-text">Nationwide coverage</p>
                      <p className="bottom-te">
                        To prevent any delays and get back on the road as
                        quickly as possible, we promise that our networks cover
                        all areas of the UK.{" "}
                      </p>
                    </div>
                  </div>
                </div>
                <div className="cl4" ref={sidebarRef}>
                  <Sidebarinner />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="photographs">
        <div className="container">
          <div className="row">
            <h2 className="road">
              We make sure you are safe and comfortable{" "}
              <span className="light">
                after <br />a car accident happens
              </span>{" "}
            </h2>
            <div className="col-xxl-6 col-xl-6 col-md-12 col-12">
              <div className="fourbox">
                <ul>
                  <li>
                    <img src={check} className="check" alt="" />
                    In case of an emergency situation please call the{" "}
                    <strong>
                      {" "}
                      Marmalade car insurance claim phone number{" "}
                    </strong>{" "}
                    so as to reduce stress levels and ease anything that might
                    come up post-incident.
                  </li>
                  <hr />
                  <li>
                    <img src={check} className="check" alt="" />
                    If your vehicle is damaged, we will arrange for its repairs
                    to be done by an approved repairer.
                  </li>
                  <hr />
                  <li>
                    <img src={check} className="check" alt="" />
                    You can call the{" "}
                    <strong>
                      {" "}
                      Marmalade car accident claim phone number{" "}
                    </strong>{" "}
                    so that we can offer you a replacement car for you to use
                    while your own car is being repaired.
                  </li>
                  <hr />
                  <li>
                    <img src={check} className="check" alt="" />
                    Our experienced accident management team will handle the{" "}
                    <strong> Marmalade car insurance claim </strong> from
                    commencement till completion. This way, we can aid you get
                    fair compensation for your losses.
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-xxl-6 col-xl-6 col-md-12 col-12">
              <div className="fourbox">
                <ul>
                  <li>
                    <img src={check} className="check" alt="" />
                    We are going to make sure that good advice and legal support
                    are available to help you obtain damages for your injuries
                    and loss arising from the accident.
                  </li>
                  <hr />
                  <li>
                    <img src={check} className="check" alt="" />
                    Our claim experts will provide guidance on each step of the
                    process, 24/7.
                  </li>
                  <hr />
                  <li>
                    <img src={check} className="check" alt="" />
                    Our solicitors will negotiate with the insurer of the
                    opposite party in order to achieve full financial recovery
                    on behalf of our clients.
                  </li>
                  <hr />
                  <li>
                    <img src={check} className="check" alt="" />
                    After the completion of repairing your vehicle, we shall
                    plan how it will be brought back to you.
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="carprocess">
        <div className="container ">
          <div className="row">
            <div className="col-xxl-12 col-xxl-12 col-md-12 col-12">
              <h2 className="trusted-title">
                {" "}
                Our services
                <br />
                <span className="light"></span>{" "}
              </h2>
            </div>
          </div>
          <div className="row" id="possible">
            <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-12">
              <div className="claim">
                <ul>
                  <li>
                    <span className="sleej">Vehicle recovery:</span>Are you
                    looking for expert and quick vehicle rescue services in the
                    United Kingdom? Call our insurance claims number{" "}
                    <a href="tel:0800 772 0850">0800 772 0850</a> , and we’ll
                    immediately send our rescue professionals to recover your
                    crashed vehicle and transport it to a safe place.
                  </li>
                  <li>
                    <span className="sleej">Approved repair work:</span>If there
                    is damage to your vehicle, you can get in touch with our
                    team through the <strong> Marmalade claims number. </strong>{" "}
                    We will organise your vehicle repairs via a
                    manufacturer-accredited repair centre in the UK that
                    specialises in providing fast and effective repair services.
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-12">
              <div className="claim" id="blue">
                <ul>
                  <li>
                    <span className="sleej">Safe storage:</span> The place where
                    you park your car after a road accident is very important
                    because it prevents further damage and minimises theft
                    risks. Our storage spaces will keep your car safe from
                    damage, theft or vandalism. Therefore it might be wise to
                    give us a call at the{" "}
                    <strong> Marmalade car accident claim number </strong> so as
                    to ensure that your vehicle remains safe under our dedicated
                    vehicle storage services.
                  </li>
                  <li>
                    <span className="sleej">Replacement car:</span>In
                    circumstances where you are involved in an accident which is
                    not your fault, we shall get an identical car for you. You
                    may continue using the temporary replacement car till yours
                    is finally repaired. In case your vehicle becomes completely
                    damaged, retain the replacement one until such a time when
                    you obtain rightful compensation equating to the market
                    price of your vehicle.
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-12">
              <div className="claim">
                <ul>
                  <li>
                    <span className="sleej">Personal injury support:</span>
                    Need a solicitor for your personal injury claim? Contact us
                    through our
                    <strong> Marmalade insurance claims number. </strong>Our
                    solicitors will get you the most exceptional legal advice
                    and service to have you paid for your injuries.
                  </li>
                  <li>
                    <span className="sleej">Claim management:</span>Our
                    dedicated solicitors will stand with you from the time of
                    filling a claim to its completion throughout the process of
                    making a <strong> Marmalade car insurance claim. </strong>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="yellowcta">
        <div className="container">
          <div className="row">
            <div className="cta2">
              <div className="col-xxl-8 col-xl-8 col-lg-8 col-12">
                <div className="legal">
                  <h3 className="experienced">
                    Got more questions?{" "}
                    <span className="light">Talk to our specialists</span>{" "}
                  </h3>
                  <p className="professionals">
                    Get in touch with our no-fault claim experts via{" "}
                    <strong>
                      {" "}
                      car insurance claims number{" "}
                      <a className="cpt-no" href="tel:0800 772 0850">
                        {" "}
                        0800 772 0850{" "}
                      </a>{" "}
                    </strong>{" "}
                    to get the best advice for your{" "}
                    <strong> Marmalade car insurance claim. </strong>
                  </p>
                </div>
              </div>
              <div className="col-xxl-4 col-xl-4 col-lg-4 col-12">
              <div class="process">
                  <a class="tf-button style-1 phone" href="tel:0800 772 0850">
                    Get in touch<span class="icon-keyboard_arrow_right"></span>
                  </a>
                </div>
                <div class="process">
                    <a className="tf-button style-1 desktop"  onClick={handleScrollToSidebar}>
                        Get in touch<span className="icon-keyboard_arrow_right"></span>
                    </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <search className="updatedform">
        <div className="container">
          <div className="row text-center">
            <div className="col-xxl-12 col-xl-12 col-md-12 col-12">
              <h2 className="whiteheading">
                {" "}
                Why choose us for your <br />
                <span className="light"> accident management needs?</span>
              </h2>
              <p className="sustained">
                Our accident managers are the best when it comes to dealing with
                incidents in which someone else caused damage to your vehicle.
                By calling us on the{" "}
                <strong> Marmalade car insurance claim number </strong> , you
                can be assured that there is a committed team and experienced
                solicitors working for your best interests to gain maximum
                compensation.
              </p>
            </div>
          </div>
          <div className="row">
            <div className="col-xxl-4 col-xl-4 col-lg-6 col-md-12 col-12">
              <div className="Claimant">
                <h5>No cost to you</h5>
                <p className="collision">
                  There is no charge for using our accident management services.
                  The expenses incurred during our engagement are reimbursed by
                  the other party’s insurer.
                </p>

                <h5>Manufacturer-approved repair network</h5>
                <p className="collision">
                  We possess an extensive manufacturer-endorsed repairer
                  network, specialising in high-quality, guaranteed repairs
                  using genuine parts and paint.{" "}
                </p>

                <h5>Instant replacement vehicle</h5>
                <p className="collision">
                  In case the accident wasn’t your fault, we will make sure you
                  are back on the road within 24 hours by providing a
                  replacement car similar to your wrecked one.
                </p>
              </div>
            </div>
            <div className="col-xxl-4 col-xl-4 col-lg-6 col-md-12 col-12">
              <div className="Claimant">
                <h5>Dependable and trustworthy network</h5>
                <p className="collision">
                  We collaborate with a reliable team of repairers, experienced
                  solicitors, recovery agents and replacement car providers who
                  offer excellent services that surpass expectations.
                </p>
                <h5>Safe and secure vehicle storage</h5>
                <p className="collision">
                  Our accident vehicle storage facilities serve as secure and
                  convenient places where you can keep your vehicle to avoid
                  further damage or theft.
                </p>

                <h5>Emergency roadside assistance</h5>
                <p className="collision">
                  Ring our{" "}
                  <strong> Marmalade car accident claim number </strong> in any
                  emergency following an accident. We respond promptly ensuring
                  quick, safe and reliable roadside assistance.
                </p>
              </div>
            </div>
            <div className="col-xxl-4 col-xl-4 col-lg-12 col-md-12 col-12">
              <div className="Claimant">
                <h5>Full-time availability</h5>
                <p className="collision">
                  By maintaining an all-day presence we assure prompt support
                  whenever it is needed most.
                </p>

                <h5>Dedicated claims handler</h5>
                <p className="collision">
                  You will get personalised support from us through a dedicated
                  claims handler who guides you throughout the{" "}
                  <strong> Marmalade car insurance claim </strong> process.
                </p>

                <h5>Constant communication</h5>
                <p className="collision">
                  Your claim will always be monitored with you informed at every
                  stage so that there is no moment when you are not aware of how
                  far your claim has gone.
                </p>
              </div>
            </div>
          </div>
        </div>
      </search>

      <section className="Accident-claim">
        <div className="container">
          <div className="row">
            <div className="needed">
              <h2 className="trusted-title">
                How can you increase your <br />
                <span className="light"> claim value? </span>
              </h2>
              <p className="complexities">
                In case you have been a victim of a no-fault car accident, you
                are eligible for compensation. However, botching up the process
                may reduce your compensation and claim value. Here are some ways
                to improve your claim’s worth when it comes to a no-fault
                accident.{" "}
              </p>
            </div>
            <hr />
          </div>
          <div className="row" id="incdes">
            <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-12">
              <div className="Preparing">
                <ul>
                  <li>
                    <div className="seemingly">
                      <h5 className="Seek">Get medical help :</h5>
                      <p>
                        {" "}
                        If in the event of a motor vehicle accident, you are
                        injured, make sure you see an authorised doctor who can
                        attend to your injuries immediately.{" "}
                      </p>
                    </div>
                  </li>
                  <li>
                    <div className="seemingly">
                      <h5 className="Seek">Notify authorities: </h5>
                      <p>
                        Contact the police within 24 hours after the crash and
                        obtain a report which may have important facts
                        supporting your claim.
                      </p>
                    </div>
                  </li>
                  <li>
                    <div className="seemingly">
                      <h5 className="Seek">Gather evidence:</h5>
                      <p>
                        The more proof that you collect from the scene of the
                        accident, the greater will be your chance of getting
                        maximum compensation. For example, such evidence may
                        include photographs of the scene, testimonies from
                        witnesses as well as CCTV footage.
                      </p>
                    </div>
                  </li>
                  <li>
                    <div className="seemingly">
                      <h5 className="Seek">Exchange information</h5>
                      <p>
                        To quicken the processing of{" "}
                        <strong> Marmalade car insurance claims </strong> ,
                        exchange vital details with each other party like names,
                        personal details, vehicle registration numbers and
                        insurance policy details.
                      </p>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
            <div
              className="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-12"
              id="dualcardiv"
            >
              <img src={dualcar} alt="" className="dualcar" />
            </div>
            <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-12">
              <div className="Preparing">
                <ul>
                  <li>
                    <div className="seemingly">
                      <h5 className="Seek">
                        Don’t settle for the first settlement offer:
                      </h5>
                      <p>
                        Initial settlements offered by insurance companies may
                        not be fair compensations for your losses and damages.
                        Call a no-fault claim specialist to establish how much
                        you should get paid before accepting such proposals.{" "}
                      </p>
                    </div>
                  </li>
                  <li>
                    <div className="seemingly">
                      <h5 className="Seek"> Call eye-witnesses</h5>
                      <p>
                        Get in touch with witnesses of the accident and record
                        their contacts so that you can call them later on.
                      </p>
                    </div>
                  </li>
                  <li>
                    <div className="seemingly">
                      <h5 className="Seek">
                        Talk to a no-fault claims expert:
                      </h5>
                      <p>
                        Consult an experienced no-fault claims advisor about
                        your rights and options. Dial the{" "}
                        <strong>
                          Marmalade car insurance claim phone number{" "}
                        </strong>{" "}
                        , and we will put you through to one of our skilled
                        claim specialists who will assist and support you
                        throughout the process of making a claim.{" "}
                      </p>
                    </div>
                  </li>
                  <li>
                    <div className="seemingly">
                      <h5 className="Seek">Begin Your Claim</h5>
                      <p>
                        Once you have gathered all relevant documents and
                        evidence, reach out to us via our{" "}
                        <strong>
                          {" "}
                          Marmalade car accident claims phone number{" "}
                        </strong>{" "}
                        to inform us about your intent to file a claim.
                      </p>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="whyus">
        <div className="container">
          <div className="row">
            <div className="col-xxl-12 col-md-12">
              <div className="why">
                <h2 className="choose">
                  Start your claim in four{" "}
                  <span className="light">simple steps </span>
                </h2>
                <p className="complexities">
                  Are you thinking about making a car accident claim but don’t
                  want the paperwork hassle? You can initiate the{" "}
                  <strong> Marmalade car insurance claim </strong>within four
                  simple steps with our streamlined and stress-free claims
                  process. Call us at{" "}
                  <strong>
                    <a href="tel:0800 772 0850">0800 772 0850</a>.
                  </strong>
                  to get started with us.{" "}
                </p>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-xxl-3 col-xl-6 col-lg-6 col-md-6 col-12">
              <div className="box-service">
                <img src={check} className="check" alt="" />
                {/* <img src={experience} className="experience" alt="" /> */}
                <h4>Contact us</h4>
                <p>
                  Use the{" "}
                  <strong> Marmalade car accident claim phone number </strong>{" "}
                  to get in touch with our team and we’ll provide you with free
                  impartial advice regarding your claim.
                </p>
              </div>
            </div>
            <div className="col-xxl-3 col-xl-6 col-lg-6 col-md-6 col-12">
              <div className="box-service">
                <img src={check} className="check" alt="" />
                <h4>Tell us about the accident</h4>
                <p>
                  Give all information concerning accidents along with the
                  parties involved.
                </p>
              </div>
            </div>
            <div className="col-xxl-3 col-xl-6 col-lg-6 col-md-6 col-12">
              <div className="box-service">
                <img src={check} className="check" alt="" />
                <h4>Give us proof and documents</h4>
                <p>
                  Make sure you submit any evidence or relevant documents that
                  may have been collected from this scene of an accident so that
                  your claim can be processed quickly.{" "}
                </p>
              </div>
            </div>
            <div className="col-xxl-3 col-xl-6 col-lg-6 col-md-6 col-12">
              <div className="box-service">
                <img src={check} className="check" alt="" />
                <h4>Claim processing</h4>
                <p>
                  After receiving the above information, we shall begin
                  processing your claim and keep you updated on its progress at
                  every step.{" "}
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="faqs">
        <div className="container">
          <div className="row">
            <div className="col-xxl-12 col-xl-12 col-md-12 col-12">
              <div className="fq">
                <h2>
                  {" "}
                  Frequently Asked{" "}
                  <span className="light">Questions (FAQ)</span>
                </h2>
                <p className="complexities">
                  Got questions about the{" "}
                  <strong> Marmalade car insurance claim? </strong>We have them
                  answered here.
                </p>
              </div>
              <div className="allfaq">
                <Accordion defaultActiveKey="0">
                  <Accordion.Item eventKey="0">
                    <Accordion.Header>
                      What should I do when my car gets immobilised due to an
                      accident?
                    </Accordion.Header>
                    <Accordion.Body>
                      In case your car has ended up immobile as a result of a
                      non-fault car accident, call the{" "}
                      <strong> Marmalade car insurance claim number </strong>
                      now. We will retrieve it free of charge from there and
                      then take it into one of our storage yards for
                      safekeeping.
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="1">
                    <Accordion.Header>
                      Can I get another vehicle if my own vehicle is being
                      repaired?
                    </Accordion.Header>
                    <Accordion.Body>
                      Certainly! In fact, we will offer you another vehicle
                      which is at par with your own so that you can continue
                      doing what you usually do while yours remains off the
                      road.
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="2">
                    <Accordion.Header>
                      Can I choose my own repairer for my vehicle repairs?
                    </Accordion.Header>
                    <Accordion.Body>
                      Yes, you can. Just dial the{" "}
                      <strong> Marmalade claims number </strong>and tell us
                      about your preferred repairer. Then we will contact your
                      chosen garage to fix your car.
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="3">
                    <Accordion.Header>
                      Are your accident management services available 24/7?{" "}
                    </Accordion.Header>
                    <Accordion.Body>
                      Yes, they are. We have a dedicated team who are ready to
                      take care of you at any time of the day so as to ensure
                      you can get emergency assistance when it is most required
                      by you.
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="4">
                    <Accordion.Header>
                      Will using your accident management services affect my
                      insurance premiums?
                    </Accordion.Header>
                    <Accordion.Body>
                      No, using our accident management services has no bearing
                      on your insurance premium since there would be no claim
                      made against your policy.
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="5">
                    <Accordion.Header>
                      What happens if my vehicle is unrepairable?{" "}
                    </Accordion.Header>
                    <Accordion.Body>
                      When involved in an accident after which the insurer
                      declares it as unrepairable, we give you a temporary
                      replacement car for use over that period. Furthermore, our
                      solicitors will liaise with the other party’s insurance
                      company to ascertain that your compensation is paid out as
                      soon as possible.
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="6">
                    <Accordion.Header>
                      Are there any costs associated with using your accident
                      management services?{" "}
                    </Accordion.Header>
                    <Accordion.Body>
                      No, there aren’t. Our accident management service comes at
                      no fee whatsoever. All expenses connected with using our
                      accident management services will be claimed back from the
                      third-party insurer.
                    </Accordion.Body>
                  </Accordion.Item>
                </Accordion>
              </div>
            </div>
          </div>
        </div>
      </section>

      <Footer />
    </>
  );
};

export default Marmalade;
