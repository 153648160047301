import React, { useRef } from "react";
import Accordion from "react-bootstrap/Accordion";
import check from "../assets/images/check.png";
import dualcar from "../../src/assets/images/dualcar.webp";
import homebnr from "../../src/assets/images/homebnr.webp";
import Sidebarinner from "../components/Sidebar-inner";
import phonecall from "../assets/images/phonecall.png";
import Footer from "../components/footer";
import { Helmet } from "react-helmet";

const Cuvva = () => {
    const sidebarRef = useRef(null);

    const handleScrollToSidebar = () => {
        if (sidebarRef.current) {
            sidebarRef.current.scrollIntoView({ behavior: "smooth" });
        }
    };
    return (
        <>
        <Helmet>
        <meta charSet="utf-8" />
        <title>Cuvva Car Insurance Accident Claim Phone Number</title>
        <meta
          name="description"
          content="We’re here to help you provide expert claim assistance for your insurance claim needs. Call us on the Cuvva Car Insurance Accident Claim Phone Number 0800 772 0850.  "
        />
        <link
          rel="canonical"
          href="https://insurance-accident-claims.co.uk/Cuvva"
        />
      </Helmet>
            <section className="innernav">
                <div className="container">
                    <div className="row">
                        <div className="col-md-6 col-sm-6">
                            <div className="left-logo">
                                <h3>
                                    Accident<span className="sipo"> Claims</span>
                                </h3>
                            </div>
                        </div>
                        <div className="col-md-6 col-sm-6">
                            <div className="left-cta">
                                <div class="headercnt">
                                    <div class="chatmsg">
                                        <img
                                            src={phonecall}
                                            className="phonecall"
                                            alt="phonecall "
                                        />
                                    </div>
                                    <div class="box-body">
                                        <h3 class="nav-info-box-title">Toll Free Number </h3>
                                        <p>
                                            <a href="tel:0800 772 0850">0800 772 0850</a>
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section
                className="newbnr"
                style={{ backgroundImage: "url(" + homebnr + ")" }}
            >
                <div className="container">
                    <div className="row">
                        <div className="col-xxl-7 col-xl-7 col-md-10 col-12">
                            <div className="innerbnr">
                                <h1>
                                    <span className="onespan">No-fault car accident </span>{" "}
                                    <span className="sci">claims </span> phone number
                                </h1>
                                <p className="brokerage">
                                We will assist you through all the stages of a no-fault car accident, from recovering your vehicle to claim management and everything in between.
                                </p>
                                <div className="bnrbtn">
                                    <a class="db-btn-border btn-rollover phone" href="tel:0800 772 0850">
                                        Road Accident Claim Help?{" "}
                                        <i class="fa-solid fa-arrow-right"></i>
                                    </a>
                                </div>
                                <div className="bnrbtn">
                                    <a className="db-btn-border btn-rollover desktop" onClick={handleScrollToSidebar}>
                                        Road Accident Claim Help? <i className="fa-solid fa-arrow-right"></i>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="stickysection">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="group-4-8">
                                <div className="cl8 tf-tab">
                                    <div className="content-tab">
                                        <div className="inner-content">
                                            <h2>
                                            Professional claims support without
                                                
                                                <span className="light"> financial burden</span>{" "}
                                            </h2>
                                            <p className="disciplines">
                                            Our main objective in offering accident management services is to help victims of no-fault road accidents mitigate the consequences and get back on track with minimal hassles. From vehicle recovery to claim management, our team will take care of everything, removing the pain from it.
                                            </p>
                                            <hr />
                                            <p className="bold-text">Zero Excess</p>
                                            <p className="bottom-te">  We deal directly with the offender of an accident when claiming, without claiming against your own insurance policy and saving you from making any excess payments                            </p>
                                            <hr />
                                            <p className="bold-text">Preserved no-claims bonus</p>
                                            <p className="bottom-te">
                                            Don’t worry about losing that NCB build-up over time due to years of safe driving when you choose us to file your claim.                                       </p>
                                            <hr />
                                            <p className="bold-text">
                                            Manufacturer approved repairers 
                                            </p>
                                            <p className="bottom-te">
                                            We have close relationships with manufacturer-approved repairers to ensure genuine parts and paint can be used to return your car back to its initial condition.
                                            </p>
                                            <hr />
                                            <p className="bold-text">Like-for-like replacement vehicle </p>
                                            <p className="bottom-te">
                                            Our premium services will provide you with a like-for-like replacement vehicle of the same quality, size and style as your own.                                           </p>
                                            <hr />
                                            <p className="bold-text">Expedited claims process </p>
                                            <p className="bottom-te">
                                            It may feel like a daunting task to deal with a non-fault claim. Nevertheless, as soon as you make a claim by calling our<strong> Cuvva car accident claim phone number, </strong>  we shall expedite the claims process for quick results.                                        </p>
                                            <hr />
                                            <p className="bold-text">No-obligation advice</p>
                                            <p className="bottom-te">
                                            Feel free to call our claims line at <strong>
                                            {" "}
                                            <a href="tel:0800 772 0850">0800 772 0850</a>{" "}
                                        </strong> in order to obtain no-cost advice on how to receive compensation for injuries or losses sustained.
                                            </p>
                                            <hr />
                                            <p className="bold-text">Dedicated claims manager </p>
                                            <p className="bottom-te">
                                            Calling us using the <strong> Cuvva Insurance claim number </strong> ensures you will be assigned an experienced claims handler.                                      </p>
                                            <hr />
                                            <p className="bold-text">Prompt response</p>
                                            <p className="bottom-te">
                                            Contact us when you need immediate information or help following an accident. We will offer quick support without any delays. 
                                        </p>
                                            <hr />
                                            <p className="bold-text">Complete assistance</p>
                                            <p className="bottom-te">
                                            From recovering your vehicle, managing repairs and arranging replacement cars to dealing with your claim, we will take care of everything.                          </p>
                                            <hr />

                                            <p className="bold-text">Inspection and post-repair verification</p>
                                            <p className="bottom-te">
                                            When your car has been repaired, we will take our time to do a thorough examination of the vehicle so as to ensure that its repair meets the highest quality and safety standards. 
                                                                             </p>
                                        </div>
                                    </div>
                                </div>
                                <div className="cl4" ref={sidebarRef}>
                                    <Sidebarinner />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="photographs">
                <div className="container">
                    <div className="row">
                        <h2 className="road">
                        We will save you from the hassles of dealing with road {" "}
                            <span className="light">
                              
                            traffic   <br /> accidents on your own
                            </span>{" "}
                        </h2>
                        <div className="col-xxl-6 col-xl-6 col-md-12 col-12">
                            <div className="fourbox">
                                <ul>
                                    <li>
                                        <img src={check} className="check" alt="" />
                                        You can reach us through the <strong>  Cuvva insurance claim number </strong> if you want to get your vehicle recovered from the accident scene.

                                    </li>
                                    <hr />
                                    <li>
                                        <img src={check} className="check" alt="" />
                                        We shall see to it that after an accident, the damaged vehicle is recovered fast from the accident scene, minimising stress and saving you money.                              </li>
                                    <hr />
                                    <li>
                                        <img src={check} className="check" alt="" />
                                        One of our engineers will perform a damage assessment on your car to determine the extent of the damage.  
                                    </li>
                                    <hr />
                                    <li>
                                        <img src={check} className="check" alt="" />
                                        If they decide that your car is repairable, we’ll make arrangements for fixing it through the repair shop of your choice.                </li>
                                </ul>
                            </div>
                        </div>
                        <div className="col-xxl-6 col-xl-6 col-md-12 col-12">
                            <div className="fourbox">
                                <ul>
                                    <li>
                                        <img src={check} className="check" alt="" />
                                        Don’t wait any longer. Just call us using the <strong> Cuvva claims line  </strong> and we will offer you a substitute vehicle of equal value within the shortest possible period.                           </li>
                                    <hr />
                                    <li>
                                        <img src={check} className="check" alt="" />
                                        Our solicitors will communicate with different people depending on what happens during negotiations with their insurance company.                           </li>
                                    <hr />
                                    <li>
                                        <img src={check} className="check" alt="" />
                                        We will constantly check your vehicle’s repair progress and keep you updated throughout the process.                               </li>
                                    <hr />
                                    <li>
                                        <img src={check} className="check" alt="" />
                                        Our solicitors will make certain that all expenses incurred as a result of a road incident are paid by the other driver's insurance company.    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="carprocess">
                <div className="container ">
                    <div className="row">
                        <div className="col-xxl-12 col-xxl-12 col-md-12 col-12">
                            <h2 className="trusted-title">
                                {" "}
                                Accident management service <br />
                                <span className="light"> we offer  </span>{" "}
                            </h2>
                        </div>
                    </div>
                    <div className="row" id="possible">
                        <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-12">
                            <div className="claim">
                                <ul>
                                    <li>
                                        <span className="sleej">Car recovery:</span>    If your car is not in a position to move, communicate with us via the <strong> Cuvva insurance claim number </strong> for professional car pick-up. We will swiftly organise the recovery of your car from the accident scene to the storage yards. 

                                    </li>
                                    <li>
                                        <span className="sleej">Accidental repairs :</span>   If your car has been damaged in an accident, ring our <strong> Cuvva claims line. </strong>  We can connect you with an approved repairer from our wide network of quality-guaranteed repairs.

                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-12">
                            <div className="claim" id="blue">
                                <ul>
                                    <li>
                                        <span className="sleej">Car storage:</span>   If you have had an accident, contact us through the <strong> Cuvva insurance claims number </strong> so that we can store your vehicle safely. Meanwhile, we will hold it at one of our safe storage yards until its valuation is done to ensure that no more harm is caused to your vehicle.  Our storage facilities provide secure places where clients can park their cars following involvement in accidents.
                                    </li>
                                    <li>
                                        <span className="sleej">Temporary car :</span> We will give you another one to use while the damaged one is being fixed. The replacement car could be with you either for the time it takes to repair your car or until your claim is settled. Call us at the <strong> Cuvva car insurance claim phone number </strong> now so that we can send an alternative replacement vehicle to your door.
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-12">
                            <div className="claim">
                                <ul>
                                    <li>
                                        <span className="sleej">Personal injury support </span>
                                        Our solicitors are always available if you get injured in a road traffic accident. Simply ring the <strong> Cuvva insurance claims number, </strong> and our solicitors will advise on how best to initiate personal injury claims.
                                        </li>
                                    <li>
                                        <span className="sleej">Claim management:</span>  We do everything that we can in terms of taking care of documentation and negotiating with all parties to ensure that the settlement process does not take long.   
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="yellowcta">
                <div className="container">
                    <div className="row">
                        <div className="cta2">
                            <div className="col-xxl-8 col-xl-8 col-lg-8 col-12">
                                <div className="legal">
                                    <h3 className="experienced">
                                    Got more questions? Talk {" "}
                                        <span className="light"> to our specialists
                                        </span>{" "}
                                    </h3>
                                    <p className="professionals">
                                    Get in touch with our no-fault claim experts via 
                                        {" "}
                                        <strong> car insurance contact number{" "}
                                            <a className="cpt-no" href="tel:0800 772 0850">
                                                {" "}
                                                0800 772 0850{" "}
                                            </a>{" "}
                                        </strong>
                                        to get the best advice for the 
                                        <strong> Cuvva car insurance claim. </strong>
                                    </p>
                                </div>
                            </div>
                            <div className="col-xxl-4 col-xl-4 col-lg-4 col-12">
                                <div class="process">
                                    <a class="tf-button style-1 phone" href="tel:0800 772 0850">
                                        Get in touch<span class="icon-keyboard_arrow_right"></span>
                                    </a>
                                </div>
                                <div class="process">
                                    <a className="tf-button style-1 desktop" onClick={handleScrollToSidebar}>
                                        Get in touch<span className="icon-keyboard_arrow_right"></span>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <search className="updatedform">
                <div className="container">
                    <div className="row text-center">
                        <div className="col-xxl-12 col-xl-12 col-md-12 col-12">
                            <h2 className="whiteheading">
                                {" "}
                                What sets us
                                <span className="light">  apart?
                                </span>
                            </h2>
                            <p className="sustained">
                            We have the UK’s best team of experienced solicitors who have helped the victims of no-fault car accidents recover from the consequences of car accidents and secure maximum compensation. Please call 
                            <strong>
                                            {" "}
                                            <a href="tel:0800 772 0850">0800 772 0850</a>{" "}
                                        </strong> when there is an emergency caused by a crash on the roads.
                                        </p>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-xxl-4 col-xl-4 col-lg-6 col-md-12 col-12">
                            <div className="Claimant">
                                <h5>No cost to you</h5>
                                <p className="collision">
                                In regard to our accident management services, we will not ask you for any direct payments since all the expenses will be recovered from the other party’s insurer.


                                </p>

                                <h5>No increase in insurance premiums </h5>
                                <p className="collision">
                                By making your claim with us, you save your insurance premium because we don’t make any claims on your own insurance policy.                       </p>

                                <h5>24/7 availability </h5>
                                <p className="collision">
                                You can have peace of mind by knowing that there is always available assistance and support whenever you need it.

                                </p>
                            </div>
                        </div>
                        <div className="col-xxl-4 col-xl-4 col-lg-6 col-md-12 col-12">
                            <div className="Claimant">
                                <h5>Comprehensive assistance  
                                </h5>
                                <p className="collision">
                                We provide a wide range of roadside assistance, from vehicle recovery to giving out replacement cars, leading to reduced stress and problems associated with being involved in an accident.  </p>
                                <h5>Protected vehicle storage</h5>
                                <p className="collision">
                                Our yards keep your damaged car temporarily so as to secure its safety against potential risks or losses.                      </p>
                                <h5>Quality repairs </h5>
                                <p className="collision">
                                We are committed to ensuring that you get quality repairs for your car using original parts and paintwork. Contact us through the<strong> Cuvva claims line </strong>  for high-quality repairs to your vehicle. 
                                </p>
                            </div>
                        </div>
                        <div className="col-xxl-4 col-xl-4 col-lg-12 col-md-12 col-12">
                            <div className="Claimant">
                                <h5>Instant replacement car</h5>
                                <p className="collision">
                                We can mitigate the inconvenience arising from a road accident by delivering you a replacement vehicle within one day.                            </p>

                                <h5>Tailored legal advice</h5>
                                <p className="collision">
                                Our solicitors will comprehend your circumstances and provide expert assistance based on your unique circumstances.                 </p>

                                <h5>No- win no- fee</h5>
                                <p className="collision">
                                If our solicitors do not succeed in obtaining compensation for your case, then you do not have to pay any legal fees.                           </p>
                            </div>
                        </div>
                    </div>
                </div>
            </search>

            <section className="Accident-claim">
                <div className="container">
                    <div className="row">
                        <div className="needed">
                            <h2 className="trusted-title">
                            How to get more out of the Cuvva car <br />
                                <span className="light">insurance claim?</span>
                            </h2>
                            <p className="complexities">
                            After being involved in a no-fault car accident, you need to file a claim so that you can get compensated for various damages and losses. Here are tips on how you can increase your settlement amount and gain fair compensation.               </p>
                        </div>
                        <hr />
                    </div>
                    <div className="row" id="incdes">
                        <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-12">
                            <div className="Preparing">
                                <ul>
                                    <li>
                                        <div className="seemingly">
                                            <h5 className="Seek">Report the accident to the police :</h5>
                                            <p>
                                                {" "}
                                                The first thing you must do after being involved in an accident is call the police and let them know what happened. The police report could contain crucial information which may be very useful for your case.                                  </p>
                                        </div>
                                    </li>
                                    <li>
                                        <div className="seemingly">
                                            <h5 className="Seek">
                                            Access emergency services :{" "}
                                            </h5>
                                            <p>
                                            Whenever you find yourself stranded on the road, contact emergency services immediately. Call the<strong> Cuvva car insurance claims phone number, </strong> and we will assist promptly and get you back behind wheels again as quickly as possible.                                    </p>
                                        </div>
                                    </li>
                                    <li>
                                        <div className="seemingly">
                                            <h5 className="Seek">Keep the expense records:</h5>
                                            <p>  Keep track of any costs you have incurred, such as medical fees, travel fees, solicitor’s charges and other expenses.                                 </p>
                                        </div>
                                    </li>
                                    <li>
                                        <div className="seemingly">
                                            <h5 className="Seek">Obtain evidence:</h5>
                                            <p>
                                            Obtain proof by collecting all relevant details at the scene of the accident that will support your claim. Some of these may include photos showing how the accident happened and people who were hurt in it, statements from eyewitnesses or dashcam footage.                                            </p>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div
                            className="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-12"
                            id="dualcardiv"
                        >
                            <img src={dualcar} alt="" className="dualcar" />
                        </div>
                        <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-12">
                            <div className="Preparing">
                                <ul>
                                    <li>
                                        <div className="seemingly">
                                            <h5 className="Seek">Stay off social media:</h5>
                                            <p>
                                            Do not post anything on social media until your claim begins. Such preferences can be used against you by a defendant's solicitor to weaken your case.                             </p>
                                        </div>
                                    </li>
                                    <li>
                                        <div className="seemingly">
                                            <h5 className="Seek">Exchange contacts: </h5>
                                            <p>
                                            Swap details with other drivers involved in the accident, including driver’s names and addresses, insurance policy number, vehicle registration number, and driver’s licence number.                                            </p>
                                        </div>
                                    </li>
                                    <li>
                                        <div className="seemingly">
                                            <h5 className="Seek">
                                            Get a professional involved :
                                            </h5>
                                            <p>
                                            Phone us via the <strong> Cuvva car accident claims contact number,</strong> and we will link you up with an experienced solicitor knowledgeable about car crash case complexities.
                                            </p>
                                        </div>
                                    </li>
                                    <li>
                                        <div className="seemingly">
                                            <h5 className="Seek">
                                            Start the process  :
                                            </h5>
                                            <p>
                                            Just give us a call using our Cuvva claims line for beginning once you have collected all the documents and proofs needed. {" "}
                                                 
                                            </p>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="whyus">
                <div className="container">
                    <div className="row">
                        <div className="col-xxl-12 col-md-12">
                            <div className="why">
                                <h2 className="choose">
                                Initiate your claim with few  {" "}
                                    <span className="light"> simple steps  </span>
                                </h2>
                                <p className="complexities">
                                You can start your claim quickly and easily with our uncomplicated claims procedure without any need for paperwork. Just call us using the <strong> Cuvva accident claims number, </strong>  and we will do the rest.                         </p>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-xxl-3 col-xl-6 col-lg-6 col-md-6 col-12">
                            <div className="box-service">
                                <img src={check} className="check" alt="" />
                                {/* <img src={experience} className="experience" alt="" /> */}
                                <h4>Get in touch</h4>
                                <p>
                                Call our team on the Wiser Choice car insurance contact number to discuss your claim.When you want a free consultation from our team of claim experts, call us on the <strong> Cuvva insurance claim number.</strong>   </p>
                            </div>
                        </div>
                        <div className="col-xxl-3 col-xl-6 col-lg-6 col-md-6 col-12">
                            <div className="box-service">
                                <img src={check} className="check" alt="" />
                                <h4>Provide information </h4>
                                <p>
                                Tell us about the accident, including details such as date and time, location where it happened and what caused it.</p>
                            </div>
                        </div>
                        <div className="col-xxl-3 col-xl-6 col-lg-6 col-md-6 col-12">
                            <div className="box-service">
                                <img src={check} className="check" alt="" />
                                <h4>Evidence submission & documentation </h4>
                                <p>
                                If there is anything you have as evidence pertaining to your compensation claim, kindly share it with us. Additionally, submit relevant documents that shall be used during the progression of your case.                             </p>
                            </div>
                        </div>
                        <div className="col-xxl-3 col-xl-6 col-lg-6 col-md-6 col-12">
                            <div className="box-service">
                                <img src={check} className="check" alt="" />
                                <h4>Claim initiation & follow up </h4>
                                <p>
                                After initiating your claim, we will keep updating you on how far your claim has gone.

                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="faqs">
                <div className="container">
                    <div className="row">
                        <div className="col-xxl-12 col-xl-12 col-md-12 col-12">
                            <div className="fq">
                                <h2>
                                    {" "}
                                    Frequently Asked{" "}
                                    <span className="light">Questions</span>
                                </h2>
                                <p className="complexities">
                                Got questions about the <strong>Cuvva car insurance claim?</strong>  We have them answered here.

                                </p>
                            </div>
                            <div className="allfaq">
                                <Accordion defaultActiveKey="0">
                                    <Accordion.Item eventKey="0">
                                        <Accordion.Header>
                                        Do I have to pay any hidden charges?                                  </Accordion.Header>
                                        <Accordion.Body>
                                        No. We won’t impose additional fees on you, and we also promise that there will be no hidden costs associated with our services.                    </Accordion.Body>
                                    </Accordion.Item>
                                    <Accordion.Item eventKey="1">
                                        <Accordion.Header>
                                        Will I get a replacement car?
                                        </Accordion.Header>
                                        <Accordion.Body>
                                        Certainly, we shall remove your car from the scene of the collision and deliver it to a manufacturer-approved repair centre.
                                        </Accordion.Body>
                                    </Accordion.Item>
                                    <Accordion.Item eventKey="2">
                                        <Accordion.Header>
                                        Will my NCB be affected if I make a claim?  
                                        </Accordion.Header>
                                        <Accordion.Body>
                                        No. Making a claim does not mean losing NCB if you were not at fault for the accident.             </Accordion.Body>
                                    </Accordion.Item>
                                    <Accordion.Item eventKey="3">
                                        <Accordion.Header>
                                        Is there any guarantee for vehicle repairs?
                                        </Accordion.Header>
                                        <Accordion.Body>
                                        Yes, we will arrange for your vehicle to be repaired by United Kingdom manufacturer-approved repairers.  So, definitely, your vehicle repairs will be guaranteed.
                                        </Accordion.Body>
                                    </Accordion.Item>
                                    <Accordion.Item eventKey="4">
                                        <Accordion.Header>
                                        My car was written off. How can you help me with this?

                                        </Accordion.Header>
                                        <Accordion.Body>
                                        If your vehicle becomes a write-off, we will give you a like-for-like replacement until a settlement is received from the third party.                          </Accordion.Body>
                                    </Accordion.Item>
                                    <Accordion.Item eventKey="5">
                                        <Accordion.Header>
                                        Will I need to pay anything more if the accident isn’t my fault?{" "}
                                        </Accordion.Header>
                                        <Accordion.Body>
                                        There are no extra charges imposed on you in case of an accident not caused by you. All these costs go directly to other insurers.             </Accordion.Body>
                                    </Accordion.Item>
                                    <Accordion.Item eventKey="6">
                                        <Accordion.Header>
                                        How long does it take for me to get another car after getting into a road traffic accident?{" "}
                                        </Accordion.Header>
                                        <Accordion.Body>
                                        We will ensure you get another car within a day from when the accident happened so that your normal daily schedules are maintained without interruption.

                                        </Accordion.Body>
                                    </Accordion.Item>
                                </Accordion>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <Footer />
        </>
    );
};

export default Cuvva;
