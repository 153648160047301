import React, { useRef } from "react";
import Accordion from "react-bootstrap/Accordion";
import check from "../assets/images/check.png";
import dualcar from "../../src/assets/images/dualcar.webp";
import carbnr1 from "../../src/assets/images/carbnr1.webp";
import Sidebarinner from "../components/Sidebar-inner";
import phonecall from "../assets/images/phonecall.png";
import Footer from "../components/footer";
import { Helmet } from "react-helmet";

const Greenflag = () => {
  const sidebarRef = useRef(null);

  const handleScrollToSidebar = () => {
    if (sidebarRef.current) {
      sidebarRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Green Flag Car Insurance Accident Claim Phone Number:</title>
        <meta
          name="description"
          content="Need expert accident claim assistance and support? Get in touch with our professional team on the Green Flag Car Insurance accident claim phone number 0800 772 0850. "
        />
        <link
          rel="canonical"
          href="https://insurance-accident-claims.co.uk/green-flag"
        />
      </Helmet>
      <section className="innernav">
        <div className="container">
          <div className="row">
            <div className="col-md-6 col-sm-6">
              <div className="left-logo">
                <h3>
                  Accident<span className="sipo"> Claims</span>
                </h3>
              </div>
            </div>
            <div className="col-md-6 col-sm-6">
              <div className="left-cta">
                <div class="headercnt">
                  <div class="chatmsg">
                    <img
                      src={phonecall}
                      className="phonecall"
                      alt="phonecall "
                    />
                  </div>
                  <div class="box-body">
                    <h3 class="nav-info-box-title">Toll Free Number </h3>
                    <p>
                      <a href="tel:0800 772 0850">0800 772 0850</a>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section
        className="newbnr"
        style={{ backgroundImage: "url(" + carbnr1 + ")" }}
      >
        <div className="container">
          <div className="row">
            <div className="col-xxl-8 col-xl-8 col-md-10 col-12">
              <div className="innerbnr">
                <h1>
                  <span className="onespan">Car accident</span>{" "}
                  <span className="sci">claims </span>management
                </h1>
                <p className="brokerage">
                  We handle all the processes that involve no-fault car
                  accidents, from vehicle recovery to claims management.
                </p>
                <div className="bnrbtn">
                  <a class="db-btn-border btn-rollover phone" href="tel:0800 772 0850">
                    Road Accident Claim Help?{" "}
                    <i class="fa-solid fa-arrow-right"></i>
                  </a>
                </div>
                <div className="bnrbtn">
                <a className="db-btn-border btn-rollover desktop"  onClick={handleScrollToSidebar}>
                    Road Accident Claim Help? <i className="fa-solid fa-arrow-right"></i>
                </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="stickysection">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="group-4-8">
                <div className="cl8 tf-tab">
                  <div className="content-tab">
                    <div className="inner-content">
                      <h2>
                        Total accident support{" "}
                        <span className="light">without excess payments </span>{" "}
                      </h2>
                      <p className="disciplines">
                        We understand how road traffic accidents can disrupt
                        your life. That is why we provide inclusive accident
                        management and{" "}
                        <strong> Green Flag car insurance claim </strong>
                        assistance to get you safely and quickly back on the
                        road after a non-fault road traffic collision. In cases
                        of emergency or for claims advice, call toll-free number{" "}
                        <a href="tel:0800 772 0850">0800 772 0850</a> for car
                        insurance claims.
                      </p>
                      <hr />
                      <p className="bold-text">Without any policy excess</p>
                      <p className="bottom-te">
                        Rather than claim against your own insurer, we will make
                        a direct <strong>Green Flag insurance claim</strong>{" "}
                        with the other driver’s insurer, which means you will
                        not have to pay any excess under your own policy in this
                        way, protecting yourself from the financial risk of
                        paying the excess on your insurance policy.{" "}
                      </p>
                      <hr />
                      <p className="bold-text">Protected bonus for no-claims</p>
                      <p className="bottom-te">
                        Making a compensation claim through us directly against
                        the other party who was at fault ensures that your
                        private cover remains unaffected as it maintains its
                        no-claim bonus status intact.
                      </p>
                      <hr />
                      <p className="bold-text">Dedicated claims handler</p>
                      <p className="bottom-te">
                        We are available on the{" "}
                        <strong>Green Flag car insurance claim number </strong>
                        for immediate assistance. We have a specially assigned
                        claims handler for your{" "}
                        <strong>
                          {" "}
                          Green Flag insurance accident claim{" "}
                        </strong>{" "}
                        that helps to cut down on time and departments.
                      </p>
                      <hr />
                      <p className="bold-text">Top-notch repairs </p>
                      <p className="bottom-te">
                        Our network of repairers has expertise in making quality
                        repairs using genuine parts and paint.{" "}
                      </p>
                      <hr />
                      <p className="bold-text">Like-for-like replacement</p>
                      <p className="bottom-te">
                        Call us at{" "}
                        <strong>
                          the Green Flag car accident claim number{" "}
                        </strong>
                        if you want a replacement vehicle after a car accident.
                        In order that you can keep doing your daily activities,
                        we will organise an alternative like-for-like vehicle.
                      </p>
                      <hr />
                      <p className="bold-text">Prompt response</p>
                      <p className="bottom-te">
                        No more waiting for hours after dialling the{" "}
                        <strong>
                          {" "}
                          Green Flag insurance accident claim number.{" "}
                        </strong>{" "}
                        We are always there to help you out. If you need
                        immediate assistance, our dedicated staff members will
                        be there for you 24/7.
                      </p>
                      <hr />
                      <p className="bold-text">Fast claims settlement</p>
                      <p className="bottom-te">
                        We have made it simple as well as quick so that your{" "}
                        <strong>Green Flag car insurance claim</strong>{" "}
                        procedure can be done with ease in order for you to get
                        compensated in full for any losses or injuries incurred.
                      </p>
                      <hr />
                      <p className="bold-text">Finest advice on claims</p>
                      <p className="bottom-te">
                        Our solicitors’ panel has handled thousands of{" "}
                        <strong>Green Flag insurance claims </strong>
                        before. Therefore, expect nothing less than the best
                        guidance and necessary support at each stage of the
                        claims process.{" "}
                      </p>
                      <hr />
                      <p className="bold-text">24/7 claim handling</p>
                      <p className="bottom-te">
                        For your <strong>Green Flag insurance claim</strong>,
                        you can always count on our team for help and advice.
                        Dial the car accident claim number{" "}
                        <a href="tel:0800 772 0850">0800 772 0850</a> to get the
                        maximum reimbursement for your claim.
                      </p>
                      <hr />

                      <p className="bold-text">
                        Free advice without obligations
                      </p>
                      <p className="bottom-te">
                        Speak with our solicitors via the{" "}
                        <strong> Green Flag car accident claim number </strong>{" "}
                        after the non-fault accident and get free no-obligation
                        advice regarding your claim.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="cl4" ref={sidebarRef}>
                  <Sidebarinner />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="photographs">
        <div className="container">
          <div className="row">
            <h2 className="road">
              We make sure that after an accident, everything goes{" "}
              <span className="light">
                smoothly
                <br />
                and without much trouble
              </span>{" "}
            </h2>
            <div className="col-xxl-6 col-xl-6 col-md-12 col-12">
              <div className="fourbox">
                <ul>
                  <li>
                    <img src={check} className="check" alt="" />
                    In case you suffer a no-fault car accident, we will assist
                    you in recovering all your losses as well as damages from
                    the faulty person.
                  </li>
                  <hr />
                  <li>
                    <img src={check} className="check" alt="" />
                    Once you call us through the{" "}
                    <strong> Green Flag car insurance claim number </strong>
                    Green Flag car insurance claim number
                  </li>
                  <hr />
                  <li>
                    <img src={check} className="check" alt="" />
                    If you are looking for a replacement car, we can supply you
                    with a courtesy vehicle and arrange vehicle repairs,
                    allowing you to hit the road again within hours of any sort
                    of breakdown.
                  </li>
                  <hr />
                  <li>
                    <img src={check} className="check" alt="" />
                    To ensure a timely and amicable settlement of your{" "}
                    <stong> Green Flag compensation claims </stong>, we shall
                    liaise with other parties involved.
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-xxl-6 col-xl-6 col-md-12 col-12">
              <div className="fourbox">
                <ul>
                  <li>
                    <img src={check} className="check" alt="" />
                    Your vehicle will be repaired in a timely and professional
                    manner through coordination with reputable repairers.
                  </li>
                  <hr />
                  <li>
                    <img src={check} className="check" alt="" />
                    Our solicitors will help you in every stage of your{" "}
                    <strong>Green Flag insurance accident claim </strong>
                    process, from filling in documents to controlling records.
                  </li>
                  <hr />
                  <li>
                    <img src={check} className="check" alt="" />A dedicated
                    claims handler will be assigned to you, who will remain the
                    only point of contact throughout the{" "}
                    <strong> Green Flag car insurance claim </strong>process.
                  </li>
                  <hr />
                  <li>
                    <img src={check} className="check" alt="" />
                    We shall assess the extent of your loss and negotiate with
                    the other party’s insurer for the best settlement possible.
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="carprocess">
        <div className="container ">
          <div className="row">
            <div className="col-xxl-12 col-xxl-12 col-md-12 col-12">
              <h2 className="trusted-title">
                {" "}
                Our wide range of <br />
                <span className="light">services</span>{" "}
              </h2>
            </div>
          </div>
          <div className="row" id="possible">
            <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-12">
              <div className="claim">
                <ul>
                  <li>
                    <span className="sleej">Vehicle recovery:</span>Should there
                    be any declaration that your vehicle can’t get back on the
                    road, we will immediately make the necessary arrangements to
                    ensure it is recovered from an accident scene and taken to
                    either a repair centre or our secure storage facilities.
                  </li>
                  <li>
                    <span className="sleej">Approved repairs:</span>After
                    assessing your vehicle for repairs by an independent
                    engineer, we will schedule them at manufacturer-approved
                    repairers within our network.
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-12">
              <div className="claim" id="blue">
                <ul>
                  <li>
                    <span className="sleej">Secure storage:</span> As you wait
                    for your vehicle to get fixed or assessed for claims, it
                    gets stored in our safe storage area where it does not
                    experience additional damage or deterioration. Please call
                    us through the{" "}
                    <strong> Green Flag car accident claim number </strong>so
                    that we can recover and store your car quickly.
                  </li>
                  <li>
                    <span className="sleej">Replacement car:</span>In case your
                    car becomes undrivable, you can contact us through the{" "}
                    <strong> Green Flag insurance accident claim number</strong>
                    , and our team will arrange a like-for-like replacement
                    vehicle to ensure that you get back on track with your daily
                    routines as normal.
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-12">
              <div className="claim">
                <ul>
                  <li>
                    <span className="sleej">Injury claim support :</span>
                    Did you suffer an injury of any kind because of the
                    accident? Call our solicitors via
                    <strong> Green Flag car insurance claim number</strong>, and
                    they will give professional advice about claiming
                    compensation for your injuries and losses.
                  </li>
                  <li>
                    <span className="sleej">Claims management:</span>From
                    initiation of your Green Flag car insurance Claim to
                    settlement, our dedicated claims handler is there to assist
                    you every step of the way.
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="yellowcta">
        <div className="container">
          <div className="row">
            <div className="cta2">
              <div className="col-xxl-8 col-xl-8 col-lg-8 col-12">
                <div className="legal">
                  <h3 className="experienced">
                    Got more questions?{" "}
                    <span className="light">Talk to our specialists</span>{" "}
                  </h3>
                  <p className="professionals">
                    Get in touch with our no-fault claim experts via{" "}
                    <strong>
                      car insurance claim number{" "}
                      <a className="cpt-no" href="tel:0800 772 0850">
                        {" "}
                        0800 772 0850{" "}
                      </a>{" "}
                    </strong>
                    to get the best advice regarding the{" "}
                    <strong> Green Flag insurance accident claim. </strong>
                  </p>
                </div>
              </div>
              <div className="col-xxl-4 col-xl-4 col-lg-4 col-12">
              <div class="process">
                  <a class="tf-button style-1 phone" href="tel:0800 772 0850">
                    Get in touch<span class="icon-keyboard_arrow_right"></span>
                  </a>
                </div>
                <div class="process">
                    <a className="tf-button style-1 desktop"  onClick={handleScrollToSidebar}>
                        Get in touch<span className="icon-keyboard_arrow_right"></span>
                    </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <search className="updatedform">
        <div className="container">
          <div className="row text-center">
            <div className="col-xxl-12 col-xl-12 col-md-12 col-12">
              <h2 className="whiteheading">
                {" "}
                Why are we a one-stop shop for the best <br />
                <span className="light">accident management solutions?</span>
              </h2>
              <p className="sustained">
                We are here to help you recover from a non-fault road traffic
                accident and have become trusted partners to this end. Our
                services include dedicated claims support as well as various
                types of accident management solutions aimed at saving you from
                having to deal alone with a{" "}
                <strong> Green Flag insurance claim</strong>-related matter.
              </p>
            </div>
          </div>
          <div className="row">
            <div className="col-xxl-4 col-xl-4 col-lg-6 col-md-12 col-12">
              <div className="Claimant">
                <h5>24/7 availability</h5>
                <p className="collision">
                  Our helpful team works twenty-four-seven, providing fast help
                  after a road traffic accident without any delays whatsoever.
                </p>

                <h5>Sophisticated repairs </h5>
                <p className="collision">
                  In the UK, we have a vast number of repairers who will restore
                  your car to what it was before the accident with genuine
                  vehicle parts and paint.{" "}
                </p>

                <h5>Free of charge</h5>
                <p className="collision">
                  You may phone through{" "}
                  <strong> Green Flag Insurance accident claim number </strong>{" "}
                  to avail yourself of the end-to-end accident management
                  services with zero excess payments. You won’t worry about this
                  as all costs will be claimed back from the at-fault party’s
                  insurer.
                </p>
              </div>
            </div>
            <div className="col-xxl-4 col-xl-4 col-lg-6 col-md-12 col-12">
              <div className="Claimant">
                <h5>Comprehensive assistance</h5>
                <p className="collision">
                  We can recover your vehicle, help you settle your claims and
                  provide other services to get you back on track.
                </p>
                <h5>No win, no fee</h5>
                <p className="collision">
                  If your claim is unsuccessful, our solicitors will not ask you
                  for a penny in terms of fees for the services they have
                  rendered to you.
                </p>

                <h5>Individualised support and advice</h5>
                <p className="collision">
                  Our solicitors will handle all your{" "}
                  <strong>Green Flag compensation claims </strong> diligently
                  and give personalised guidance throughout to ensure the best
                  possible outcome for your case.
                </p>
              </div>
            </div>
            <div className="col-xxl-4 col-xl-4 col-lg-12 col-md-12 col-12">
              <div className="Claimant">
                <h5>Fast recovery of vehicles</h5>
                <p className="collision">
                  You can rely on us for prompt retrieval of your car from an
                  accident scene given that we have an available team round the
                  clock.
                </p>

                <h5>Accelerated claims process </h5>
                <p className="collision">
                  Your <strong> Green Flag insurance accident claim </strong>is
                  speedily settled through our efficient processes, which
                  guarantee communication at every step along its path.
                </p>

                <h5>No false promises</h5>
                <p className="collision">
                  There are no false promises. During the entire{" "}
                  <strong> Green Flag insurance claim </strong> process we will
                  be in touch with you and notify you of how your claim is going
                  on.
                </p>
              </div>
            </div>
          </div>
        </div>
      </search>

      <section className="Accident-claim">
        <div className="container">
          <div className="row">
            <div className="needed">
              <h2 className="trusted-title">
                How can you maximise your compensation <br />
                <span className="light"> from Green Flag?</span>
              </h2>
              <p className="complexities">
                Are you left wondering what to do so as to get more out of the{" "}
                <strong> Green Flag car insurance accident claim? </strong>{" "}
                <br /> Having knowledge about the correct steps following an
                innocent accident can make a great difference in terms of
                compensation for that which one gets. These include some things
                that have to be done once a motor vehicle accident occurs so
                that the value of your case is maximised.{" "}
              </p>
            </div>
            <hr />
          </div>
          <div className="row" id="incdes">
            <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-12">
              <div className="Preparing">
                <ul>
                  <li>
                    <div className="seemingly">
                      <h5 className="Seek">Check injuries :</h5>
                      <p>
                        {" "}
                        Bring your car to a halt and check yourself and other
                        occupants if they got injured during the accident. In
                        case any person has been injured, immediate medical help
                        should be sought.{" "}
                      </p>
                    </div>
                  </li>
                  <li>
                    <div className="seemingly">
                      <h5 className="Seek">Gather evidence: </h5>
                      <p>
                        A lot of evidence increases the likelihood of getting
                        maximum damages. Therefore, collect enough information
                        to prove your point.
                      </p>
                    </div>
                  </li>
                  <li>
                    <div className="seemingly">
                      <h5 className="Seek">Document all expenses:</h5>
                      <p>
                        It is advisable that one records all the losses
                        sustained after an accident, including medical expenses,
                        lost earnings, property damage costs, transport charges
                        or any other related spending.
                      </p>
                    </div>
                  </li>
                  <li>
                    <div className="seemingly">
                      <h5 className="Seek">Never accept the first offer</h5>
                      <p>
                        The first settlement offer does not cover all the
                        damages and may not provide you with deserving
                        compensation. So, never agree to the first settlement
                        offer given by the insurance companies.
                      </p>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
            <div
              className="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-12"
              id="dualcardiv"
            >
              <img src={dualcar} alt="" className="dualcar" />
            </div>
            <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-12">
              <div className="Preparing">
                <ul>
                  <li>
                    <div className="seemingly">
                      <h5 className="Seek">Avoid using social media :</h5>
                      <p>
                        Refrain from posting anything on social media until your{" "}
                        <strong> Green Flag car insurance claim </strong> gets
                        settled. The other party solicitors and insurance
                        companies may use your social media activity against
                        you.
                      </p>
                    </div>
                  </li>
                  <li>
                    <div className="seemingly">
                      <h5 className="Seek"> Contact eyewitnesses </h5>
                      <p>
                        Get in touch with eyewitnesses of the accident and
                        record their statements. It can help you gather crucial
                        information and evidence to support your{" "}
                        <strong> Green Flag insurance claim. </strong>
                      </p>
                    </div>
                  </li>
                  <li>
                    <div className="seemingly">
                      <h5 className="Seek">
                        Speak to an experienced solicitor:
                      </h5>
                      <p>
                        Talk to an experienced no-fault claim solicitor to know
                        your rights and options after a no-fault car accident.
                        Call us on the{" "}
                        <strong> Green Flag car insurance claim number </strong>{" "}
                        , and we’ll connect you with an experienced solicitor in
                        the UK.{" "}
                      </p>
                    </div>
                  </li>
                  <li>
                    <div className="seemingly">
                      <h5 className="Seek">Start your claim</h5>
                      <p>
                        Once you’ve decided to file a claim against the other
                        party, reach out to our team via the{" "}
                        <strong> Green Flag car accident claim number. </strong>{" "}
                        We will initiate the{" "}
                        <strong> Green Flag compensation claims </strong>process
                        to help you get fair compensation.
                      </p>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="whyus">
        <div className="container">
          <div className="row">
            <div className="col-xxl-12 col-md-12">
              <div className="why">
                <h2 className="choose">
                  4 easy steps to start your Green{" "}
                  <span className="light">Flag Insurance Accident Claim</span>
                </h2>
                <p className="complexities">
                  Hope for making a claim if the accident is not your mistake?
                  You do not have any reason to search again, just call us using
                  the{" "}
                  <strong>Green Flag insurance accident claim number </strong>,
                  and you will make the claims by simply following four steps.
                </p>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-xxl-3 col-xl-6 col-lg-6 col-md-6 col-12">
              <div className="box-service">
                <img src={check} className="check" alt="" />
                {/* <img src={experience} className="experience" alt="" /> */}
                <h4>Contact us </h4>
                <p>
                  Just dial the car insurance claim number{" "}
                  <a href="tel:0800 772 0850">0800 772 0850 </a>
                  for immediate help after a non-fault motor vehicle collision.
                </p>
              </div>
            </div>
            <div className="col-xxl-3 col-xl-6 col-lg-6 col-md-6 col-12">
              <div className="box-service">
                <img src={check} className="check" alt="" />
                <h4>Discuss accident details </h4>
                <p>
                  Tell us everything about the accident- its location, date,
                  time as well as what caused it.
                </p>
              </div>
            </div>
            <div className="col-xxl-3 col-xl-6 col-lg-6 col-md-6 col-12">
              <div className="box-service">
                <img src={check} className="check" alt="" />
                <h4>Provide evidence </h4>
                <p>
                  Bring forward any compelling proof, such as photos or videos
                  from where the incident happened.{" "}
                </p>
              </div>
            </div>
            <div className="col-xxl-3 col-xl-6 col-lg-6 col-md-6 col-12">
              <div className="box-service">
                <img src={check} className="check" alt="" />
                <h4>Claim initiation</h4>
                <p>
                  Having given out every tangible item regarding the occurrence,
                  we rest our oars on initiating your claim while updating you
                  on its progress.{" "}
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="faqs">
        <div className="container">
          <div className="row">
            <div className="col-xxl-12 col-xl-12 col-md-12 col-12">
              <div className="fq">
                <h2>
                  {" "}
                  Frequently Asked{" "}
                  <span className="light">Questions (FAQ)</span>
                </h2>
                <p className="complexities">
                  Got questions about the{" "}
                  <strong> Green Flag compensation claims? </strong>We have them
                  answered here.
                </p>
              </div>
              <div className="allfaq">
                <Accordion defaultActiveKey="0">
                  <Accordion.Item eventKey="0">
                    <Accordion.Header>
                      Can I be given another car in case of a no-fault accident?
                    </Accordion.Header>
                    <Accordion.Body>
                      Certainly. After a no-fault accident, we will provide you
                      with an alternative car which is not different from the
                      one you were using before so that your daily life after a
                      no-fault car accident can go on smoothly as usual.
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="1">
                    <Accordion.Header>
                      Should I pay anything extra for this kind of managing
                      accident service?
                    </Accordion.Header>
                    <Accordion.Body>
                      No. All the charges will be paid by the insurer of the
                      party responsible for the damage. Hence, there is no need
                      to make any excess payment.
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="2">
                    <Accordion.Header>
                      Can you arrange my vehicle repairs at my preferred garage?
                    </Accordion.Header>
                    <Accordion.Body>
                      Yes, just give us details about your repairer, and we
                      shall plan for your damaged car’s repair at your desired
                      UK-based repair centre.
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="3">
                    <Accordion.Header>
                      What kind of replacement vehicle do I get after an
                      accident that was not my fault?{" "}
                    </Accordion.Header>
                    <Accordion.Body>
                      If you have been involved in an accident that was not your
                      fault and it resulted in your car being written off
                      completely, we would provide you with another similar
                      vehicle of similar make, size and model as yours.
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="4">
                    <Accordion.Header>
                      What is the maximum period I can have a replacement
                      vehicle for?
                    </Accordion.Header>
                    <Accordion.Body>
                      You can keep the replacement vehicle until your own
                      vehicle is fixed. In case your car is totalled, you may
                      hold on to the other motor for compensation equivalent to
                      the full value of your car.
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="5">
                    <Accordion.Header>
                      What if the other party tries to settle out of court?{" "}
                    </Accordion.Header>
                    <Accordion.Body>
                      Never accept an early settlement offer from the insurer of
                      the other driver involved in your accident. Instead, talk
                      this over with your solicitor as it’s likely that the
                      first proposal may be inadequate in terms of a fair amount
                      of compensation for you relating to damages.
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="6">
                    <Accordion.Header>
                      Is my no-claims bonus affected if I claim on my insurance
                      and have had a non-fault RTA?{" "}
                    </Accordion.Header>
                    <Accordion.Body>
                      No. Your ‘no claims bonus’ will remain intact since we
                      will not bring any claim against your policy.
                    </Accordion.Body>
                  </Accordion.Item>
                </Accordion>
              </div>
            </div>
          </div>
        </div>
      </section>

      <Footer />
    </>
  );
};

export default Greenflag;
