import React, { useRef } from "react";
import Accordion from "react-bootstrap/Accordion";
import check from "../assets/images/check.png";
import dualcar from "../../src/assets/images/dualcar.webp";
import carbnr1 from "../../src/assets/images/carbnr1.webp";
import Sidebarinner from "../components/Sidebar-inner";
import phonecall from "../assets/images/phonecall.png";
import Footer from "../components/footer";
import { Helmet } from "react-helmet";

const Mceinsurance = () => {
    const sidebarRef = useRef(null);

    const handleScrollToSidebar = () => {
        if (sidebarRef.current) {
            sidebarRef.current.scrollIntoView({ behavior: "smooth" });
        }
    };
    return (
        <>
        <Helmet>
        <meta charSet="utf-8" />
        <title>MCE Insurance Car Insurance Claim Phone Number</title>
        <meta
          name="description"
          content="Have you been caught up in a no-fault car accident? Call us on  MCE Insurance's car insurance claim number 0800 772 0850 for comprehensive and timely support."
        />
        <link
          rel="canonical"
          href="https://insurance-accident-claims.co.uk/Mce-insurance"
        />
      </Helmet>
            <section className="innernav">
                <div className="container">
                    <div className="row">
                        <div className="col-md-6 col-sm-6">
                            <div className="left-logo">
                                <h3>
                                    Accident<span className="sipo"> Claims</span>
                                </h3>
                            </div>
                        </div>
                        <div className="col-md-6 col-sm-6">
                            <div className="left-cta">
                                <div class="headercnt">
                                    <div class="chatmsg">
                                        <img
                                            src={phonecall}
                                            className="phonecall"
                                            alt="phonecall "
                                        />
                                    </div>
                                    <div class="box-body">
                                        <h3 class="nav-info-box-title">Toll Free Number </h3>
                                        <p>
                                            <a href="tel:0800 772 0850">0800 772 0850</a>
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section
                className="newbnr"
                style={{ backgroundImage: "url(" + carbnr1 + ")" }}
            >
                <div className="container">
                    <div className="row">
                        <div className="col-xxl-7 col-xl-7 col-md-10 col-12">
                            <div className="innerbnr">
                                <h1>
                                    <span className="onespan">Road accident  </span>{" "}
                                    <span className="sci"> claims </span> assistance
                                </h1>
                                <p className="brokerage">
                                    We are experts in managing non-fault car accident situations, right from vehicle recovery to claims management.
                                </p>
                                <div className="bnrbtn">
                                    <a class="db-btn-border btn-rollover phone" href="tel:0800 772 0850">
                                        Road Accident Claim Help?{" "}
                                        <i class="fa-solid fa-arrow-right"></i>
                                    </a>
                                </div>
                                <div className="bnrbtn">
                                    <a className="db-btn-border btn-rollover desktop" onClick={handleScrollToSidebar}>
                                        Road Accident Claim Help? <i className="fa-solid fa-arrow-right"></i>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="stickysection">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="group-4-8">
                                <div className="cl8 tf-tab">
                                    <div className="content-tab">
                                        <div className="inner-content">
                                            <h2>
                                                No excess accident
                                                <br />
                                                <span className="light"> management  </span>{" "}
                                            </h2>
                                            <p className="disciplines">
                                                Are you a victim of a no-fault road traffic accident in the UK? In the event your vehicle is stranded, call us on
                                                <strong>
                                                    {" "}
                                                    <a href="tel:0800 772 0850">0800 772 0850</a>{" "}
                                                </strong> for emergency support. We offer premium <strong> MCE  insurance claim</strong> services with zero hidden fees or surprises.

                                            </p>
                                            <hr />
                                            <p className="bold-text">No upfront costs </p>
                                            <p className="bottom-te">
                                                In case you get involved in an unfortunate incident that results in a car crash, ring the <strong> MCE  car accident helpline, </strong>  and we shall be prompt to respond. Besides, there is no need to pay anything upfront because all costs will be met by the negligent third party’s insurer.                                           </p>
                                            <hr />
                                            <p className="bold-text">The NCB remains unaffected</p>
                                            <p className="bottom-te">
                                                When you go through your insurer, this could affect the discounts that you have earned so dearly. We do not sue against your record like insurance firms, keeping your bonus intact.                                           </p>
                                            <hr />
                                            <p className="bold-text">
                                                Assigned claims handler
                                            </p>
                                            <p className="bottom-te">
                                                Just dial our <strong> MCE accident claim phone number, </strong> and we will assign a dedicated claims handler to assist you during the entire process of making a claim.                                          </p>
                                            <hr />
                                            <p className="bold-text">Comprehensive claims assistance </p>
                                            <p className="bottom-te">
                                                No-fault accident claims are not a problem for our expert solicitors. That is why we can promise you the best legal advice and support for your <strong>MCE insurance accident claim.</strong>                                       </p>
                                            <hr />
                                            <p className="bold-text">Like-for-like replacement </p>
                                            <p className="bottom-te">
                                                The moment your car is taken to the garage, it will be replaced with our first-class vehicle, ensuring that you are mobile throughout the duration of your vehicle repairs. If you would like to keep up with your daily routine, please get in touch with us through accident claims helpline
                                                <strong>
                                                    {" "}
                                                    <a href="tel:0800 772 0850">0800 772 0850</a>{" "}
                                                </strong>  so we can help provide a similar like-for-like replacement vehicle for you.
                                            </p>
                                            <hr />
                                            <p className="bold-text">Superior quality repairs</p>
                                            <p className="bottom-te">
                                                Within the UK alone, over one thousand approved garages and repair experts who are proficient in providing peerless repair exist in our network.                                            </p>
                                            <hr />
                                            <p className="bold-text">Continuous repair monitoring</p>
                                            <p className="bottom-te">
                                                We will arrange for car repairs as well as manage everything on your behalf.                                           </p>
                                            <hr />
                                            <p className="bold-text">Fast claims processing & resolution</p>
                                            <p className="bottom-te">
                                                Our <strong> MCE insurance accident claim </strong> process is simple and straightforward, ensuring an expedient solution.                                           </p>
                                            <hr />
                                            <p className="bold-text">Experts in no-fault accident claims</p>
                                            <p className="bottom-te">
                                                We are dedicated professionals when it comes to handling your <strong> MCE insurance accident claim. </strong>  Our aim is, therefore, to get you the best result based on what we know and what we do.                                           </p>
                                            <hr />

                                            <p className="bold-text">Friendly and supportive team</p>
                                            <p className="bottom-te">
                                                Our completely dedicated specialists will go along with you in each stage, offering guidance in all steps, including helping you put all the necessary papers and documentation.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div className="cl4" ref={sidebarRef}>
                                    <Sidebarinner />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="photographs">
                <div className="container">
                    <div className="row">
                        <h2 className="road">
                            We fast-track your recovery process after a   {" "}
                            <span className="light">
                                non-fault <br />
                                car accident
                            </span>{" "}
                        </h2>
                        <div className="col-xxl-6 col-xl-6 col-md-12 col-12">
                            <div className="fourbox">
                                <ul>
                                    <li>
                                        <img src={check} className="check" alt="" />
                                        When you call us through our accident claims helpline,  <strong>
                                            {" "}
                                            <a href="tel:0800 772 0850">0800 772 0850</a>{" "}
                                        </strong> after a no-fault car mishap, we will send emergency aid to speed up your recovery.

                                    </li>
                                    <hr />
                                    <li>
                                        <img src={check} className="check" alt="" />
                                        Our solicitors’ board shall evaluate your <strong> MCE insurance accident claim </strong> and provide advice on how to proceed with it.                                   </li>
                                    <hr />
                                    <li>
                                        <img src={check} className="check" alt="" />
                                        Our team is going to look at your vehicle’s damages and arrange for repair from a panel of approved garages and repairers.                                   </li>
                                    <hr />
                                    <li>
                                        <img src={check} className="check" alt="" />
                                        A replacement vehicle will be brought to you within twenty-four hours so that you can get back on the road very quickly.                     </li>
                                </ul>
                            </div>
                        </div>
                        <div className="col-xxl-6 col-xl-6 col-md-12 col-12">
                            <div className="fourbox">
                                <ul>
                                    <li>
                                        <img src={check} className="check" alt="" />
                                        From covering excess of your policies as well as out-of-pocket expenses to dealing with personal injury claims, we are there for you in every step of the process.                                    </li>
                                    <hr />
                                    <li>
                                        <img src={check} className="check" alt="" />
                                        When you call us at the <strong> MCE claims contact number </strong>for accident management services and claims assistance, we will assign a dedicated claims handler who will handle everything about the claim.                                    </li>
                                    <hr />
                                    <li>
                                        <img src={check} className="check" alt="" />
                                        Promptly, we will negotiate with insurers and other parties involved so that your case may be settled.                                   </li>
                                    <hr />
                                    <li>
                                        <img src={check} className="check" alt="" />
                                        Your rights will be protected, and you’ll receive compensation for the monetary value of the losses and injuries you've suffered.                                   </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="carprocess">
                <div className="container ">
                    <div className="row">
                        <div className="col-xxl-12 col-xxl-12 col-md-12 col-12">
                            <h2 className="trusted-title">
                                {" "}
                                Accident management services <br />
                                <span className="light"> we offer </span>{" "}
                            </h2>
                        </div>
                    </div>
                    <div className="row" id="possible">
                        <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-12">
                            <div className="claim">
                                <ul>
                                    <li>
                                        <span className="sleej">Vehicle recovery:</span>   Has your car been wrecked because of a non-fault road traffic accident? Contact us using our vehicle insurance claim number
                                        <strong>
                                            {" "}
                                            <a href="tel:0800 772 0850">0800 772 0850</a>{" "}
                                        </strong> to schedule the recovery of your car from the accident scene into our safe storage yards.
                                    </li>
                                    <li>
                                        <span className="sleej">Vehicle repairs :</span>   You might get involved in an accident which can damage your car to the point where you cannot use it anymore. We will assess its damages and repair it at any approved garage you choose.
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-12">
                            <div className="claim" id="blue">
                                <ul>
                                    <li>
                                        <span className="sleej">Vehicle storage:</span>    After a no-fault incident, one should not park their vehicle on the road. Thus, as soon as you call the <strong> MCE claims contact number, </strong>  we make arrangements to store your damaged vehicle in our storage yards until it can be assessed again or when you are paid for compensation for repairs done.
                                    </li>
                                    <li>
                                        <span className="sleej">Replacement car :</span> A no-fault accident in which you are involved while driving within the UK could entitle you to a replacement vehicle of equal standard. We will provide a credit hire car for you promptly so that there is no delay in getting back on the road as soon as possible. Call us at the <strong> MCE accident claim phone number, </strong>  and it’s simple to get another vehicle.

                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-12">
                            <div className="claim">
                                <ul>
                                    <li>
                                        <span className="sleej">Injury compensation:</span>
                                        Our experienced solicitors can help you reclaim your expenses or seek compensation for injuries you've suffered. Contact us on the <strong> MCE claims contact number  </strong> to get advice from our experienced solicitors and make a successful claim.                              </li>
                                    <li>
                                        <span className="sleej">Claims management:</span>Use our contact details provided by the <strong>  MCE accident claims phone number </strong>to file your case via us. From vehicle recovery to claims settlement, we will take care of everything.
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="yellowcta">
                <div className="container">
                    <div className="row">
                        <div className="cta2">
                            <div className="col-xxl-8 col-xl-8 col-lg-8 col-12">
                                <div className="legal">
                                    <h3 className="experienced">
                                        Need further assistance? Speak to  {" "}
                                        <span className="light"> our experts
                                        </span>{" "}
                                    </h3>
                                    <p className="professionals">
                                        Get in touch with our no-fault claim specialists to get professional advice for your <strong> MCE insurance accident claim. </strong>
                                    </p>
                                </div>
                            </div>
                            <div className="col-xxl-4 col-xl-4 col-lg-4 col-12">
                                <div class="process">
                                    <a class="tf-button style-1 phone" href="tel:0800 772 0850">
                                        Get in touch<span class="icon-keyboard_arrow_right"></span>
                                    </a>
                                </div>
                                <div class="process">
                                    <a className="tf-button style-1 desktop" onClick={handleScrollToSidebar}>
                                        Get in touch<span className="icon-keyboard_arrow_right"></span>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <search className="updatedform">
                <div className="container">
                    <div className="row text-center">
                        <div className="col-xxl-12 col-xl-12 col-md-12 col-12">
                            <h2 className="whiteheading">
                                {" "}
                                How does our UK Accident Claims
                                <span className="light">  company differ from others?
                                </span>
                            </h2>
                            <p className="sustained">
                                Prompt settlement of your accident claims with top-end vehicle repairs, like-for-like replacement, and expert guidance comes at no cost to you as opposed to your insurer. Call us on
                                <strong>
                                    {" "}
                                    <a href="tel:0800 772 0850">0800 772 0850</a>{" "}
                                </strong> for emergency assistance after a no-fault car accident.
                            </p>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-xxl-4 col-xl-4 col-lg-6 col-md-12 col-12">
                            <div className="Claimant">
                                <h5> Rapid help</h5>
                                <p className="collision">
                                    Call the <strong> MCE accident claims number </strong>  in case of any emergency, and we will act promptly by ensuring that no significant interference is experienced.

                                </p>

                                <h5>Experienced solicitors </h5>
                                <p className="collision">
                                    Our team of well-skilled solicitors will take care of everything concerning your <strong> MCE insurance accident claim  </strong> and help you get maximum compensation.                                </p>

                                <h5>Top-notch repairs   </h5>
                                <p className="collision">
                                    For quality assurance purposes, we guarantee your vehicle repairs meet the required standards.                                </p>
                            </div>
                        </div>
                        <div className="col-xxl-4 col-xl-4 col-lg-6 col-md-12 col-12">
                            <div className="Claimant">
                                <h5>Manufacturer-approved parts and paints
                                </h5>
                                <p className="collision">
                                    The authorised repair centres use genuine materials and paint endorsed by vehicle manufacturers when repairing your vehicle so that it can go back to its original state.  </p>
                                <h5>24/7 support</h5>
                                <p className="collision">
                                    Right after a faultless road accident, a dedicated claims handler will be beside you, guiding you on how to make <strong> MCE insurance accident claims </strong>within the UK anytime                                </p>

                                <h5> Like-for-like replacement </h5>
                                <p className="collision">
                                    In such an instance, our accident management team will provide you with a replacement car similar to your own vehicle without any added costs to be incurred.
                                </p>
                            </div>
                        </div>
                        <div className="col-xxl-4 col-xl-4 col-lg-12 col-md-12 col-12">
                            <div className="Claimant">
                                <h5>Holistic claims assistance </h5>
                                <p className="collision">
                                    This encompasses the cost of hiring a like-for-like vehicle, payment of medical expenses, lost earnings and other relevant damages payable under the terms of the settlemen                              </p>

                                <h5>Fast resolution of claims</h5>
                                <p className="collision">
                                    A streamlined approach to processing claims allows us to deal with your claim promptly without unnecessary delays.                               </p>

                                <h5>Secure storage and retrieval facility </h5>
                                <p className="collision">
                                    Our team shall recover the damaged vehicle from the accident scene and move it into our safe storage facilities to prevent further damage and deterioration.                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </search>

            <section className="Accident-claim">
                <div className="container">
                    <div className="row">
                        <div className="needed">
                            <h2 className="trusted-title">
                                How can you maximise your  MCE insurance <br />
                                <span className="light">accident claim?</span>
                            </h2>
                            <p className="complexities">
                                Car accidents are traumatic. Here’s what you should do after a non-fault crash to get back to driving as soon as possible. In case of a no-fault collision, please call us immediately on
                                <strong>
                                    {" "}
                                    <a href="tel:0800 772 0850">0800 772 0850</a>{" "}
                                </strong> at the accident claims helpline so we can assist you instantly.

                            </p>
                        </div>
                        <hr />
                    </div>
                    <div className="row" id="incdes">
                        <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-12">
                            <div className="Preparing">
                                <ul>
                                    <li>
                                        <div className="seemingly">
                                            <h5 className="Seek"> Stay composed :</h5>
                                            <p>
                                                {" "}
                                                Drive cautiously and shift lanes along less crowded areas with little traffic to avoid any potential damage.                                          </p>
                                        </div>
                                    </li>
                                    <li>
                                        <div className="seemingly">
                                            <h5 className="Seek">
                                                Record the scene:{" "}
                                            </h5>
                                            <p>
                                                Take photographs and videos of the accident scene, damaged vehicles, and injuries sustained. Also, get the details of any witnesses you can find and make note of their names and contacts.                                           </p>
                                        </div>
                                    </li>
                                    <li>
                                        <div className="seemingly">
                                            <h5 className="Seek">Call police:</h5>
                                            <p>
                                                Once you have a clash with another vehicle, it’s mandatory that you report it to the police department within 24 hours.                                    </p>
                                        </div>
                                    </li>
                                    <li>
                                        <div className="seemingly">
                                            <h5 className="Seek"> Exchange information :</h5>
                                            <p>
                                                Among other things relating to any party involved in a car accident, passengers’ and drivers' names, insurance information, and time & date must also be exchanged.                                             </p>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div
                            className="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-12"
                            id="dualcardiv"
                        >
                            <img src={dualcar} alt="" className="dualcar" />
                        </div>
                        <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-12">
                            <div className="Preparing">
                                <ul>
                                    <li>
                                        <div className="seemingly">
                                            <h5 className="Seek">Photograph vehicle harm :</h5>
                                            <p>
                                                Take a snapshot of your damaged vehicle before repairing it while still at the place where it crashed. It will aid in establishing negligence by any third party.                                           </p>
                                        </div>
                                    </li>
                                    <li>
                                        <div className="seemingly">
                                            <h5 className="Seek"> Seek medical attention : </h5>
                                            <p>
                                                If you begin feeling some pain or discomfort, book an appointment with a qualified medical professional so that they can start treating your injuries without any delay.                                            </p>
                                        </div>
                                    </li>
                                    <li>
                                        <div className="seemingly">
                                            <h5 className="Seek">
                                                Do not go for early settlement  :
                                            </h5>
                                            <p>
                                                The insurance companies might pressurise you into accepting an early settlement, which may do no justice to you as far as damages and losses are concerned. Look at your damages before saying yes to primary settlements offered by insurance firms.
                                            </p>
                                        </div>
                                    </li>
                                    <li>
                                        <div className="seemingly">
                                            <h5 className="Seek">
                                                Contact the company you are insured with to make a claim :
                                            </h5>
                                            <p>
                                                Personal injury and property damage can occur after vehicle accidents. If you want to get your damages paid for and your losses compensated, dial <strong>
                                            {" "}
                                            <a href="tel:0800 772 0850">0800 772 0850</a>{" "}
                                        </strong> so that we start the process of making a claim..

                                            </p>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="whyus">
                <div className="container">
                    <div className="row">
                        <div className="col-xxl-12 col-md-12">
                            <div className="why">
                                <h2 className="choose">
                                    Make your claim with a hassle-free {" "}
                                    <span className="light">claims process </span>
                                </h2>
                                <p className="complexities">
                                    You can initiate your claim without any stress with our simple and streamlined claims process. For further assistance on any matter relating to <strong> MCE insurance accident claim, </strong> call us on the car insurance claim line number
                                    <strong>
                                        {" "}
                                        <a href="tel:0800 772 0850">0800 772 0850</a>{" "}
                                    </strong>  for quick assistance.                          </p>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-xxl-3 col-xl-6 col-lg-6 col-md-6 col-12">
                            <div className="box-service">
                                <img src={check} className="check" alt="" />
                                {/* <img src={experience} className="experience" alt="" /> */}
                                <h4>Talk to us  </h4>
                                <p>
                                    Following a non-fault car collision, connect with our team via the <strong> MCE car accident helpline </strong>  for immediate support and guidance</p>
                            </div>
                        </div>
                        <div className="col-xxl-3 col-xl-6 col-lg-6 col-md-6 col-12">
                            <div className="box-service">
                                <img src={check} className="check" alt="" />
                                <h4>Give details of the accident </h4>
                                <p>
                                    Supply us with relevant information concerning what happened, such as the time, date, place and reason behind it.</p>
                            </div>
                        </div>
                        <div className="col-xxl-3 col-xl-6 col-lg-6 col-md-6 col-12">
                            <div className="box-service">
                                <img src={check} className="check" alt="" />
                                <h4>Provide supporting evidence </h4>
                                <p>
                                    Additionally, share any relevant documents that might include photographs, police reports or even eyewitness statements which will prove your sincerity and entitlement to compensation.                                </p>
                            </div>
                        </div>
                        <div className="col-xxl-3 col-xl-6 col-lg-6 col-md-6 col-12">
                            <div className="box-service">
                                <img src={check} className="check" alt="" />
                                <h4>Claim assessment </h4>
                                <p>
                                    Our solicitors will evaluate your case before negotiating compensation terms with other parties involved.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="faqs">
                <div className="container">
                    <div className="row">
                        <div className="col-xxl-12 col-xl-12 col-md-12 col-12">
                            <div className="fq">
                                <h2>
                                    {" "}
                                    Frequently Asked{" "}
                                    <span className="light">Questions</span>
                                </h2>
                                <p className="complexities">
                                    Got questions about the <strong> MCE insurance accident claim? </strong> We have them answered here.
                                </p>
                            </div>
                            <div className="allfaq">
                                <Accordion defaultActiveKey="0">
                                    <Accordion.Item eventKey="0">
                                        <Accordion.Header>
                                            Which type of alternative replacement vehicle will I be given?                                      </Accordion.Header>
                                        <Accordion.Body>
                                            We have a large fleet of vehicles, and we can give you a free replacement car that is of similar quality or better so that your daily activities are not disrupted.                                     </Accordion.Body>
                                    </Accordion.Item>
                                    <Accordion.Item eventKey="1">
                                        <Accordion.Header>
                                            Who covers the costs of the services provided by you?
                                        </Accordion.Header>
                                        <Accordion.Body>
                                            According to us, as an innocent party in an accident, it becomes law for you to receive accident management services, which we provide without any excess. This means that third-party insurers will foot all the bills.                                      </Accordion.Body>
                                    </Accordion.Item>
                                    <Accordion.Item eventKey="2">
                                        <Accordion.Header>
                                            Will my premium be affected if I make a <strong> MCE insurance accident claim?</strong>
                                        </Accordion.Header>
                                        <Accordion.Body>
                                            No. This is because your own insurer does not become involved. As such, claims do not affect premiums and neither do they jeopardise no-claims bonuses.                                      </Accordion.Body>
                                    </Accordion.Item>
                                    <Accordion.Item eventKey="3">
                                        <Accordion.Header>
                                            Can I decide where my car should be repaired after being damaged?
                                        </Accordion.Header>
                                        <Accordion.Body>
                                            One of our strengths is flexibility. You are allowed to choose where your vehicle will go for repairs, among other things. Simply call us on the <strong>MCE car accident helpline </strong>  and give us more information about the repairer by who you would like your vehicle repaired.                                        </Accordion.Body>
                                    </Accordion.Item>
                                    <Accordion.Item eventKey="4">
                                        <Accordion.Header>
                                            What advantages should I expect while bringing forward my <strong> MCE insurance accident claims?</strong>

                                        </Accordion.Header>
                                        <Accordion.Body>
                                            Benefits offered when you select us as an accident management service provider include
                                         
                                         <li>   Zero mandatory excess </li>
                                         <li>    Manufacturer approved repairs</li>
                                         <li>    No impact on no claims bonus</li>
                                         <li>    Personalised help at every stage</li>
                                         <li>    Quick payment of claims </li>
                                            
                                        </Accordion.Body>
                                    </Accordion.Item>
                                    <Accordion.Item eventKey="5">
                                        <Accordion.Header>
                                        If my vehicle is written off, what kind of assistance can I expect from you?{" "}
                                        </Accordion.Header>
                                        <Accordion.Body>
                                        You will be provided with a temporary replacement vehicle similar to yours that you can use until you receive your settlement figure from the insurers.                                       </Accordion.Body>
                                    </Accordion.Item>
                                    <Accordion.Item eventKey="6">
                                        <Accordion.Header>
                                        How long can I use the hire car for? {" "}
                                        </Accordion.Header>
                                        <Accordion.Body>
                                        If repairable, the rental car may be retained until completion of your vehicle repairs. In case your vehicle is a total write-off due to an accident, you are entitled to keep the substitute car till such time when its full value is paid in the form of a cheque.
                                        </Accordion.Body>
                                    </Accordion.Item>
                                </Accordion>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <Footer />
        </>
    );
};

export default Mceinsurance;
