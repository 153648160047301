import React, { useRef } from "react";
import Accordion from "react-bootstrap/Accordion";
import check from "../assets/images/check.png";
import dualcar from "../../src/assets/images/dualcar.webp";
import carbnr1 from "../../src/assets/images/carbnr1.webp";
import Sidebarinner from "../components/Sidebar-inner";
import phonecall from "../assets/images/phonecall.png";
import Footer from "../components/footer";
import { Helmet } from "react-helmet";

const Staveleyhead = () => {
  const sidebarRef = useRef(null);

  const handleScrollToSidebar = () => {
    if (sidebarRef.current) {
      sidebarRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };
  return (
    <>
    <Helmet>
        <meta charSet="utf-8" />
        <title>Staveley Head Car Insurance Accident Claim Phone Number</title>
        <meta
          name="description"
          content="Contact the Staveley Head Car Insurance Accident Claim Phone Number 0800 772 0850 for dedicated claims support from our expert solicitors to get deserving compensation."
        />
        <link
          rel="canonical"
          href="https://insurance-accident-claims.co.uk/staveley-head"
        />
      </Helmet>
      <section className="innernav">
        <div className="container">
          <div className="row">
            <div className="col-md-6 col-sm-6">
              <div className="left-logo">
                <h3>
                  Accident<span className="sipo"> Claims</span>
                </h3>
              </div>
            </div>
            <div className="col-md-6 col-sm-6">
              <div className="left-cta">
                <div class="headercnt">
                  <div class="chatmsg">
                    <img
                      src={phonecall}
                      className="phonecall"
                      alt="phonecall "
                    />
                  </div>
                  <div class="box-body">
                    <h3 class="nav-info-box-title">Toll Free Number </h3>
                    <p>
                      <a href="tel:0800 772 0850">0800 772 0850</a>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section
        className="newbnr"
        style={{ backgroundImage: "url(" + carbnr1 + ")" }}
      >
        <div className="container">
          <div className="row">
            <div className="col-xxl-8 col-xl-8 col-md-10 col-12">
              <div className="innerbnr">
                <h1>
                  <span className="onespan">Expert no-fault</span>{" "}
                  <span className="sci">  insurance claims management </span> services in the UK
                </h1>
                <p className="brokerage">
                We ensure your peace of mind after a no-fault car accident with our wide range of services, from vehicle recovery to claims management.
                </p>
                <div className="bnrbtn">
                  <a class="db-btn-border btn-rollover phone" href="tel:0800 772 0850">
                    Road Accident Claim Help?{" "}
                    <i class="fa-solid fa-arrow-right"></i>
                  </a>
                </div>
                <div className="bnrbtn">
                <a className="db-btn-border btn-rollover desktop"  onClick={handleScrollToSidebar}>
                    Road Accident Claim Help? <i className="fa-solid fa-arrow-right"></i>
                </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="stickysection">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="group-4-8">
                <div className="cl8 tf-tab">
                  <div className="content-tab">
                    <div className="inner-content">
                      <h2>
                      Your trusted partners committed to making your 
                        <br />
                        <span className="light">
                        post-accident journey stress-free {" "}
                        </span>{" "}
                      </h2>
                      <p className="disciplines">
                      Our comprehensive and best-in-class accident management services take away the stress of dealing with a no-fault car accident. We do not charge any upfront fees for this. Please call our <strong> Staveley Head claims number </strong>to start your journey today.
                      </p>
                      <hr />
                      <p className="bold-text">No extra fee</p>
                      <p className="bottom-te">
                      We help drivers who have been involved in a no-fault car accident by filing a <strong> Staveley Head insurance claim </strong>{" "}
                      against the other driver’s insurance policy so as to avoid paying excess amounts on their own policies.{" "}
                      </p>
                      <hr />
                      <p className="bold-text">Unaffected no-claims bonus </p>
                      <p className="bottom-te">
                      While you pursue your <strong> Staveley Head car insurance claims </strong>with us,  you retain all those accumulated discounts and no-claim bonuses that have taken years to build up.
                      </p>
                      <hr />
                      <p className="bold-text">
                      First-rate car repairs
                      </p>
                      <p className="bottom-te">
                      In relation to the standards set by the industry, our partners in repairing vehicles are our trusted network of UK manufacturer-approved repairers.{" "}
                      </p>
                      <hr />
                      <p className="bold-text">
                      24/7 support{" "}
                      </p>
                      <p className="bottom-te">
                      We are available at any time when one has an emergency related to accidents. To get quick help following a non-fault car accident, reach us through the free phone line {" "}<a href="tel:0800 772 0850">0800 772 0850</a>
                      </p>
                      <hr />
                      <p className="bold-text">Like-for-like replacement</p>
                      <p className="bottom-te">
                      Accidents can cause a lot of inconvenience. Therefore, we will provide you with a similar car until your vehicle requires repairs or your{" "}
                        <strong> Staveley Head accident claims </strong>are settled.
                      </p>
                      <hr />
                      <p className="bold-text">Repair monitoring</p>
                      <p className="bottom-te">
                      We will be vigilant about all repairs on your vehicle to ensure that it is restored to its pre-crash condition as soon as possible.
                      </p>
                      <hr />
                      <p className="bold-text">No premiums increase</p>
                      <p className="bottom-te">
                      Making a claim with us won’t affect the premium rates as we will not initiate a claim against your own insurance policy.
                      </p>
                      <hr />
                      <p className="bold-text">Assigned claims officer</p>
                      <p className="bottom-te">
                      From beginning to end, one person will take care of your road accident compensation claim and keep you informed about your claim progress.{" "}
                      </p>
                      <hr />
                      <p className="bold-text">Fast payment process</p>
                      <p className="bottom-te">
                      It is tough to go through the process of claiming compensation after a road traffic accident. However, we operate quite efficiently to ensure not a single coin shall be delayed on our way to settling the claim quickly.{" "}
                      </p>
                      <hr />

                      <p className="bold-text">Transparent approach</p>
                      <p className="bottom-te">
                      We would thus make sure you are kept up to date with every step taken from the commencement up until the finality of the{" "}<strong> Staveley Head insurance accident claim </strong>by providing clear communication channels along this path.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="cl4" ref={sidebarRef}>
                  <Sidebarinner />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="photographs">
        <div className="container">
          <div className="row">
            <h2 className="road">
            We ensure your recovery with the  {" "}
              <span className="light">
              best <br />
                claims management assistance
              </span>{" "}
            </h2>
            <div className="col-xxl-6 col-xl-6 col-md-12 col-12">
              <div className="fourbox">
                <ul>
                  <li>
                    <img src={check} className="check" alt="" />
                    After an accident that was not your fault, we help you quickly and easily get back on the road.
                  </li>
                  <hr />
                  <li>
                    <img src={check} className="check" alt="" />
                    Dial {" "}
                    <strong> <a href="tel:0800 772 0850">0800 772 0850</a> </strong>to report the accident and receive expert advice about how you initiate a successful claim.
                  </li>
                  <hr />
                  <li>
                    <img src={check} className="check" alt="" />
                    To assist you in resuming normalcy, we will provide a replacement vehicle.
                  </li>
                  <hr />
                  <li>
                    <img src={check} className="check" alt="" />
                    Our solicitors will advise you on what to do about your case so as to protect your insurance rights.
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-xxl-6 col-xl-6 col-md-12 col-12">
              <div className="fourbox">
                <ul>
                  <li>
                    <img src={check} className="check" alt="" />
                    We make <strong> Staveley Head car insurance claims </strong>processes simple and focus on achieving the best outcome.
                  </li>
                  <hr />
                  <li>
                    <img src={check} className="check" alt="" />
                    Please ring us at the  <strong>  Staveley Head claims number </strong>for fast vehicle repairs. We will arrange your car repairs with manufacturer-approved repairers who will carry out any work needed.
                  </li>
                  <hr />
                  <li>
                    <img src={check} className="check" alt="" />
                    We will coordinate with drivers, eyewitnesses, insurers or solicitors on your behalf.
                  </li>
                  <hr />
                  <li>
                    <img src={check} className="check" alt="" />
                    Our solicitors will manage all aspects of your <strong>Staveley Head accident claim </strong> so that you are compensated completely.
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="carprocess">
        <div className="container ">
          <div className="row">
            <div className="col-xxl-12 col-xxl-12 col-md-12 col-12">
              <h2 className="trusted-title">
                {" "}
                Accident management services<br />
                <span className="light">we offer</span>{" "}
              </h2>
            </div>
          </div>
          <div className="row" id="possible">
            <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-12">
              <div className="claim">
                <ul>
                  <li>
                    <span className="sleej">Vehicle recovery:</span>Do you need help to move your unmovable vehicle to the garage or another place? We are here for you. Dial the <strong> Staveley Head car insurance claims number, </strong>and we will instantly organise a swift tow truck to collect your car from the accident scene.
                  </li>
                  <li>
                    <span className="sleej">Vehicle repairs:</span>Our committed team has created an extensive UK-wide network of approved repairers. When you contact our team on the{" "}
                    <strong> Staveley Head claims phone number, </strong>{" "}
                    we will arrange full repairs of your vehicle back to its original state. 
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-12">
              <div className="claim" id="blue">
                <ul>
                  <li>
                    <span className="sleej">Vehicle Storage:</span> Are you concerned about where to take your damaged car? Do not worry. While the time it takes for your repairs or settlement, we provide secure storage facilities for vehicles that are damaged in order to protect them with no extra charge or money needed at the front. Call our team at{" "}
                    <strong> Staveley Head claims number </strong>for vehicle storage services.
                  </li>
                  <li>
                    <span className="sleej">Comparable replacement:</span>It is not right for us to give you a temporary vehicle that fails in all aspects after a non-fault accident happens. Just call us on the phone line, <a href="tel:0800 772 0850">0800 772 0850</a> and let us be sure that while yours is off, there is an equal substitute available so as not to disrupt your daily activities.
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-12">
              <div className="claim">
                <ul>
                  <li>
                    <span className="sleej">Personal injury claim:</span>
                    Our main objective is to ensure that you are assisted by the best solicitors who will facilitate your claim and enable you to receive compensation for all damages and injuries suffered.
                  </li>
                  <li>
                    <span className="sleej">Claims management:</span>Do you feel stressed while following up on{" "} <strong> Staveley Head insurance claims? </strong>Contact the <strong> Staveley Head car insurance claims number </strong>for full support through the process of making a claim.
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="yellowcta">
        <div className="container">
          <div className="row">
            <div className="cta2">
              <div className="col-xxl-8 col-xl-8 col-lg-8 col-12">
                <div className="legal">
                  <h3 className="experienced">
                  Got more questions? {" "}
                    <span className="light">Contact our no-fault claim specialists</span>{" "}
                  </h3>
                  <p className="professionals">
                  Contact us today at{" "}
                    <strong>
                      {" "}
                      <a className="cpt-no" href="tel:0800 772 0850">
                        {" "}
                        0800 772 0850{" "}
                      </a>{" "}
                      
                    </strong>
                    to discuss your accident management needs and get the best guidance for your{" "}
                    <strong> Staveley Head accident claim. </strong>
                  </p>
                </div>
              </div>
              <div className="col-xxl-4 col-xl-4 col-lg-4 col-12">
              <div class="process">
                  <a class="tf-button style-1 phone" href="tel:0800 772 0850">
                    Get in touch<span class="icon-keyboard_arrow_right"></span>
                  </a>
                </div>
                <div class="process">
                    <a className="tf-button style-1 desktop"  onClick={handleScrollToSidebar}>
                        Get in touch<span className="icon-keyboard_arrow_right"></span>
                    </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <search className="updatedform">
        <div className="container">
          <div className="row text-center">
            <div className="col-xxl-12 col-xl-12 col-md-12 col-12">
              <h2 className="whiteheading">
                {" "}
                Why are we the most trusted  <br />
                <span className="light">claims handlers? </span>
              </h2>
              <p className="sustained">
              In case one is involved in an accident, it is difficult to deal with the process by oneself. As your relied-upon partner, we offer specialised solutions regarding no-fault car accident claims.{" "}
              </p>
            </div>
          </div>
          <div className="row">
            <div className="col-xxl-4 col-xl-4 col-lg-6 col-md-12 col-12">
              <div className="Claimant">
                <h5>Instant vehicle replacement</h5>
                <p className="collision">
                We shall ensure you get another car immediately after being involved in an auto crash. Call us via our <strong> Staveley Head claims phone number, </strong>and we will provide you with a like-for-like loan car while yours is repaired.
                </p>

                <h5>Expert solicitors</h5>
                <p className="collision">
                We have professional legal solicitors who will help you calm down after a road traffic accident so that you can think about making your{" "}
                  <strong>Staveley Head insurance claims </strong>wisely to save your rights under the policy.{" "}
                </p>

                <h5>No cost</h5>
                <p className="collision">
                When you call us on <strong> Staveley Head car accident phone number, </strong> you don't have to pay any excess or costs. We will recover all costs from the other party’s insurer.
                </p>
              </div>
            </div>
            <div className="col-xxl-4 col-xl-4 col-lg-6 col-md-12 col-12">
              <div className="Claimant">
                <h5>Courtesy Car</h5>
                <p className="collision">
                Simply call the <strong> Staveley Head claims number, </strong>and we will give you another car to use in a very short period of time after an accident that was not your fault. 
                </p>
                <h5>Speedy Repair for Vehicles</h5>
                <p className="collision">
                We guarantee that your damaged car will be as good as new within no time with us.
                </p>

                <h5>No-Win No Fee</h5>
                <p className="collision">
                Our services are governed by a no-win, no-fee basis. This gives you an opportunity to pursue your claim without having any financial vulnerability whatsoever.{" "}
                </p>
              </div>
            </div>
            <div className="col-xxl-4 col-xl-4 col-lg-12 col-md-12 col-12">
              <div className="Claimant">
                <h5>Manufacturer’s parts and paints</h5>
                <p className="collision">
                Our repairing network involves certified repairing facilities whose services include using real parts and colours from manufacturers for the restoration of your damaged vehicle.
                </p>

                <h5> Nationwide presence </h5>
                <p className="collision">
                We are spread across the country, offering complete accident management service to drivers who were not at fault throughout the UK.
                </p>

                <h5>Immediate response</h5>
                <p className="collision">
                You do not have to wait alone. Our usual fast pace ensures that emergency assistance is always available when needed most.
                </p>
              </div>
            </div>
          </div>
        </div>
      </search>

      <section className="Accident-claim">
        <div className="container">
          <div className="row">
            <div className="needed">
              <h2 className="trusted-title">
              How can you get the maximum value for your  <br />
                <span className="light"> Staveley Head claim compensation?</span>
              </h2>
              <p className="complexities">
              In case you have faced an accident and are unsure of what to do for a fair settlement? Do not worry. Familiarise yourself with handling a road traffic accident’s consequences and taking the right measures towards filing a successful <strong> Staveley Head car insurance claim. </strong>{" "}
              </p>
            </div>
            <hr />
          </div>
          <div className="row" id="incdes">
            <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-12">
              <div className="Preparing">
                <ul>
                  <li>
                    <div className="seemingly">
                      <h5 className="Seek">Stop driving and check yourself out:</h5>
                      <p>
                        {" "}
                        The first thing to do after being involved in a car crash is to park the car in a safe place and switch off the engine. Find out if you are injured or any other passenger(s).{" "}
                      </p>
                    </div>
                  </li>
                  <li>
                    <div className="seemingly">
                      <h5 className="Seek">Inform the police about the accident: </h5>
                      <p>
                      Make sure that you report the collision as soon as it happens to the police. It is important that you provide all information contained in the police report since it goes far in helping your{" "}
                        <strong> Staveley Head insurance claims. </strong>
                      </p>
                    </div>
                  </li>
                  <li>
                    <div className="seemingly">
                      <h5 className="Seek">See a doctor:</h5>
                      <p>
                      Even though there might not be any indications of injuries, one should visit a physician who will conduct internal examination tests. The diagnosis issued by a medic is significant when making injury claims.
                      </p>
                    </div>
                  </li>
                  <li>
                    <div className="seemingly">
                      <h5 className="Seek">Capture images and videos</h5>
                      <p>
                      The more proof you can offer, the higher the probability your claim will succeed. Thus, ensure that you take shots of the particular point where the accident occurred and the vehicles involved.
                      </p>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
            <div
              className="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-12"
              id="dualcardiv"
            >
              <img src={dualcar} alt="" className="dualcar" />
            </div>
            <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-12">
              <div className="Preparing">
                <ul>
                  <li>
                    <div className="seemingly">
                      <h5 className="Seek">Exchange necessary information:</h5>
                      <p>
                      Exchange contact details and insurance policy details with other parties involved in the accident.{" "}
                      </p>
                    </div>
                  </li>
                  <li>
                    <div className="seemingly">
                      <h5 className="Seek"> Reject initial offer for compensation </h5>
                      <p>
                      The insurance company of the other party might attempt to coerce you into receiving the first settlement agreement, which is usually lower than what you actually deserve. You should not accept premature liabilities, as they may not indemnify your losses.
                      </p>
                    </div>
                  </li>
                  <li>
                    <div className="seemingly">
                      <h5 className="Seek">Talk to a specialist in no-fault claims:</h5>
                      <p>
                      Contact us on{" "}
                        <strong> Staveley Head car insurance claims number </strong> and talk to our experienced no-fault claim experts who can enlighten you about your rights and help you know how to make a claim.{" "}
                      </p>
                    </div>
                  </li>
                  <li>
                    <div className="seemingly">
                      <h5 className="Seek"> Start your claim </h5>
                      <p>
                      Call our team on{" "} <strong> Staveley Head claims phone number </strong> to start a stress-free process of making a claim. We will get things going for you by starting a claim that will be resolved quickly and efficiently for you.
                      </p>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="whyus">
        <div className="container">
          <div className="row">
            <div className="col-xxl-12 col-md-12">
              <div className="why">
                <h2 className="choose">
                Submit your claim in {" "}
                  <span className="light">four easy steps</span>
                </h2>
                <p className="complexities">
                Our simple claim process ensures speedy resolution after a no-fault road accident. Observe our easy stages and begin your{" "}
                  <strong> Staveley Head car insurance claims </strong>instantly.
                </p>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-xxl-3 col-xl-6 col-lg-6 col-md-6 col-12">
              <div className="box-service">
                <img src={check} className="check" alt="" />
                {/* <img src={experience} className="experience" alt="" /> */}
                <h4>Contact us</h4>
                <p>
                Please contact us immediately on{" "} <a href="tel:0800 772 0850">0800 772 0850</a> in order for us to discuss how we can assist you regarding your accident.
                </p>
              </div>
            </div>
            <div className="col-xxl-3 col-xl-6 col-lg-6 col-md-6 col-12">
              <div className="box-service">
                <img src={check} className="check" alt="" />
                <h4>Share accident details</h4>
                <p>
                Give full details of the accident, including the date, time, place, weather conditions and cause of the accident.
                </p>
              </div>
            </div>
            <div className="col-xxl-3 col-xl-6 col-lg-6 col-md-6 col-12">
              <div className="box-service">
                <img src={check} className="check" alt="" />
                <h4>Claim assessment</h4>
                <p>
                Our team will assess your {" "}<strong>Staveley Head car insurance claim </strong>to verify if it is worth it or not.
                </p>
              </div>
            </div>
            <div className="col-xxl-3 col-xl-6 col-lg-6 col-md-6 col-12">
              <div className="box-service">
                <img src={check} className="check" alt="" />
                <h4>Claim resolution</h4>
                <p>
                Once your claim has been assessed, you will be compensated for all your losses and injuries.{" "}
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="faqs">
        <div className="container">
          <div className="row">
            <div className="col-xxl-12 col-xl-12 col-md-12 col-12">
              <div className="fq">
                <h2>
                  {" "}
                  Frequently Asked{" "}
                  <span className="light">Questions (FAQ)</span>
                </h2>
                <p className="complexities">
                Got questions about the {" "}
                  <strong> Staveley Head accident claim? </strong> We have them answered here.
                </p>
              </div>
              <div className="allfaq">
                <Accordion defaultActiveKey="0">
                  <Accordion.Item eventKey="0">
                    <Accordion.Header>
                    Is there a replacement vehicle provision after making a claim?
                    </Accordion.Header>
                    <Accordion.Body>
                    Absolutely! Just phone us at <strong> Staveley Head claims contact number, </strong>and we will offer you an equivalent model as a substitute car so that you can get back on the road.
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="1">
                    <Accordion.Header>
                    Should I pay my excess even though I am claiming through your agency?
                    </Accordion.Header>
                    <Accordion.Body>
                    No. This is another advantage when using us to make your claims because we go directly to the guilty driver’s insurer, meaning that nothing occurs to your own coverage policy, and you don't need to pay any excess charges.
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="2">
                    <Accordion.Header>
                    How long does it take to settle my claim following a car collision?
                    </Accordion.Header>
                    <Accordion.Body>
                    The duration within which such claims are settled depends on the circumstances of each case. For instance, some straightforward ones may take only several weeks, whereas others may last slightly longer because they are complicated in nature.
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="3">
                    <Accordion.Header>
                    May I select my own preferred accident repair centre?{" "}
                    </Accordion.Header>
                    <Accordion.Body>
                    Sure, you could choose your vehicle repairer, though we recommend that you go for the manufacturer-approved repair centre where you can be guaranteed quality repairs.
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="4">
                    <Accordion.Header>
                    How long will I be able to use the replacement vehicle?
                    </Accordion.Header>
                    <Accordion.Body>
                    You can keep the replacement car until your car is fixed or your <strong> Staveley Head  insurance claim </strong> is settled.
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="5">
                    <Accordion.Header>
                    Are vehicle repairs warranted?{" "}
                    </Accordion.Header>
                    <Accordion.Body>
                    For this purpose, we would advise you to pick a manufacturer-approved repair from our network. This will ensure that all your car’s repairs are made up to standard and genuine parts used.{" "}
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="6">
                    <Accordion.Header>
                    The other driver's insurance company contacted me to settle the claim. What should I do now?{" "}
                    </Accordion.Header>
                    <Accordion.Body>
                    The insurer for the responsible party can contact us with their questions if you choose us as your claim service provider, and then we will speak with them on your behalf.
                    </Accordion.Body>
                  </Accordion.Item>
                </Accordion>
              </div>
            </div>
          </div>
        </div>
      </section>

      <Footer />
    </>
  );
};

export default Staveleyhead;
