import React, { useRef } from "react";
import insurancelogo from "../../src/assets/images/insurancelogo.png";
import Accordion from "react-bootstrap/Accordion";
import check from "../assets/images/check.png";
import dualcar from "../../src/assets/images/dualcar.webp";
import carbnr1 from "../../src/assets/images/carbnr1.webp";
import Sidebarinner from "../components/Sidebar-inner";
import phonecall from "../assets/images/phonecall.png";
import Footer from "../components/footer";
import { Helmet } from "react-helmet";

const RACcarinsurance = () => {
  const sidebarRef = useRef(null);

  const handleScrollToSidebar = () => {
    if (sidebarRef.current) {
      sidebarRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>RAC Car Insurance Accident Claims Phone Number</title>
        <meta
          name="description"
          content="Need prompt claims assistance? Contact us at the RAC Car Insurance Accident Claims Phone Number 0800 772 0850 for round-the-clock claims assistance and guidance. "
        />
        <link
          rel="canonical"
          href="https://insurance-accident-claims.co.uk/RAC-car-insurance"
        />
      </Helmet>
      <section className="innernav">
        <div className="container">
          <div className="row">
            <div className="col-md-6 col-sm-6">
              <div className="left-logo">
                {/* <img src={insurancelogo} className='insurancelogo' alt='Insurance Logo' /> */}
                <h3>Accident Claims</h3>
              </div>
            </div>
            <div className="col-md-6 col-sm-6">
              <div className="left-cta">
                <div class="headercnt">
                  <div class="chatmsg">
                    <img
                      src={phonecall}
                      className="phonecall"
                      alt="phonecall "
                    />
                  </div>
                  <div class="box-body">
                    <h3 class="nav-info-box-title">Toll Free Number </h3>
                    <p>
                      <a href="tel:0800 772 0850">0800 772 0850</a>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section
        className="newbnr"
        style={{ backgroundImage: "url(" + carbnr1 + ")" }}
      >
        <div className="container">
          <div className="row">
            <div className="col-xxl-6 col-xl-6 col-md-10 col-12">
              <div className="innerbnr">
                <h1>
                  <span className="onespan">Hassle-Free </span> <br />
                  <span className="sci">Claims Assistance </span> In The UK
                </h1>
                <p className="brokerage">
                  We offer you peace of mind after a no-fault car accident with
                  our vast spectrum of services, from vehicle recovery to claims
                  management.
                </p>

                <div className="bnrbtn">
                  <a class="db-btn-border btn-rollover phone" href="tel:0800 772 0850">
                    Start your claim{" "}
                    <i class="fa-solid fa-arrow-right"></i>
                  </a>
                </div>
                <div className="bnrbtn">
                <a className="db-btn-border btn-rollover desktop"  onClick={handleScrollToSidebar}>
                  Start your claim <i className="fa-solid fa-arrow-right"></i>
                </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="stickysection">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="group-4-8">
                <div className="cl8 tf-tab">
                  <div className="content-tab">
                    <div className="inner-content">
                      <h2>
                        Making your post-accident journey
                        <span className="light"> smooth & effortless </span>
                      </h2>
                      <p className="disciplines">
                        We have the knowledge and expertise to take out the
                        stress of dealing with a no-fault car accident with our
                        comprehensive and best-in-class accident management
                        services without any upfront costs. Call us at the{" "}
                        <strong>RAC claims number</strong> to start your journey
                        with us.
                      </p>
                      <hr />
                      <p className="bold-text">No excess </p>
                      <p className="bottom-te">
                        We specialise in helping people get back on the road
                        after a car accident by making a{" "}
                        <strong>RAC insurance claim</strong> against the other
                        driver’s insurance policy, saving you from making excess
                        payments.
                      </p>
                      <hr />
                      <p className="bold-text">Unaffected no-claims bonus</p>
                      <p className="bottom-te">
                        While pursuing your{" "}
                        <strong>RAC car insurance claims</strong>, we ensure to
                        preserve your accumulated discounts and no-claims bonus
                        that you’ve earned over the years.
                      </p>
                      <hr />
                      <p className="bold-text">Top-notch vehicle repairs </p>
                      <p className="bottom-te">
                        Our vehicle repairs are carried out by our trusted and
                        reliable network of manufacturer-approved repairers in
                        the UK according to industry standards.
                      </p>
                      <hr />
                      <p className="bold-text">24/7 support </p>
                      <p className="bottom-te">
                        Regardless of when the accident happens, we’re available
                        round the clock to provide emergency support and
                        guidance. Get in touch with us at{" "}
                        <strong>
                          car insurance claims number{" "}
                          <a href="tel:0800 772 0850">0800 772 0850</a>
                        </strong>{" "}
                        for quick assistance after a no-fault car accident.
                      </p>
                      <hr />
                      <p className="bold-text">
                        Like-for-like replacement vehicle{" "}
                      </p>
                      <p className="bottom-te">
                        To ensure you carry on with daily activities with
                        minimum interruption after a no-fault accident, we will
                        provide a like-for-like replacement vehicle for the
                        duration of your vehicle repairs or{" "}
                        <strong>RAC accident claims</strong> settlement.
                      </p>
                      <hr />
                      <p className="bold-text">Repair monitoring </p>
                      <p className="bottom-te">
                        We will consistently monitor your vehicle repairs to
                        ensure your vehicle gets restored to its pre-accident
                        accident to the highest standards.
                      </p>
                      <hr />
                      <p className="bold-text">
                        No increase in insurance premium{" "}
                      </p>
                      <p className="bottom-te">
                        Making your <strong>RAC accident claim</strong> with us
                        won’t affect your insurance premium as we won’t initiate
                        your claim against your own insurance policy.
                      </p>
                      <hr />
                      <p className="bold-text">Dedicated claims handler </p>
                      <p className="bottom-te">
                        Your claims handler will manage your{" "}
                        <strong>RAC car accident claim</strong> from start to
                        finish and keep you informed and up to date.
                      </p>
                      <hr />
                      <p className="bold-text">Quick claim settlement </p>
                      <p className="bottom-te">
                        The process of claiming compensation after a road
                        traffic accident is daunting. However, our streamlined
                        approach to managing your claims ensures quick
                        settlement without any delay.
                      </p>
                      <hr />
                      <p className="bold-text">Transparent approach </p>
                      <p className="bottom-te">
                        We believe in transparency and honesty. So, we'll
                        provide clear communication about the progress of your{" "}
                        <strong>RAC insurance accident claim</strong> and keep
                        you involved in the process from claim initiation to
                        settlement.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="cl4" ref={sidebarRef}>
                  <Sidebarinner />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="photographs">
        <div className="container">
          <div className="row">
            <h2 className="road">
              We help you recover with our best <br />
              <span className="light"> claims management assistance</span>{" "}
            </h2>
            <div className="col-xxl-6 col-xl-6 col-md-12 col-12">
              <div className="fourbox">
                <ul>
                  <li>
                    <img src={check} className="check" alt="" />
                    We help you get back on the road efficiently and effectively
                    after a no-fault car accident.
                  </li>
                  <hr />
                  <li>
                    <img src={check} className="check" alt="" />
                    When you call us on our{" "}
                    <strong>
                      claims number{" "}
                      <a href="tel:0800 772 0850">0800 772 0850</a>
                    </strong>
                    , our team will record the accident details and provide
                    thorough guidance to qualify for our services.
                  </li>
                  <hr />
                  <li>
                    <img src={check} className="check" alt="" />
                    We will provide a temporary replacement vehicle to help you
                    back to routine.
                  </li>
                  <hr />
                  <li>
                    <img src={check} className="check" alt="" />
                    Our experts will guide you on how to handle your case and
                    safeguard your insurance rights.
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-xxl-6 col-xl-6 col-md-12 col-12">
              <div className="fourbox">
                <ul>
                  <li>
                    <img src={check} className="check" alt="" />
                    We help you get the best outcome while keeping the{" "}
                    <strong>RAC car insurance claims</strong> process simple and
                    straightforward.
                  </li>
                  <hr />
                  <li>
                    <img src={check} className="check" alt="" />
                    Contact us at the <strong>RAC claims number</strong> for
                    quick vehicle repairs. Our team will organise repairs for
                    your vehicle from manufacturer-approved repairers.
                  </li>
                  <hr />
                  <li>
                    <img src={check} className="check" alt="" />
                    We will relieve you of the burden of liaising with all the
                    parties involved, including drivers, eyewitnesses, insurance
                    companies and solicitors.
                  </li>
                  <hr />
                  <li>
                    <img src={check} className="check" alt="" />
                    Our solicitors will handle each aspect of your{" "}
                    <strong>RAC accident claim</strong> so that you get
                    deserving compensation.
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="carprocess">
        <div className="container ">
          <div className="row">
            <div className="col-xxl-12 col-xxl-12 col-md-12 col-12">
              <h2 className="trusted-title">
                Accident management <br />
                <span className="light">services we offer </span>
              </h2>
            </div>
          </div>
          <div className="row" id="possible">
            <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-12">
              <div className="claim">
                <ul>
                  <li>
                    <span className="sleej">Vehicle recovery</span> <br />
                    Do you need assistance in moving your undrivable vehicle to
                    the garage or safe place? We have you covered. Contact our
                    team on the <strong>RAC car insurance claims number</strong>
                    , and we’ll promptly arrange for the speedy recovery of your
                    vehicle from the accident scene.
                  </li>
                  <li>
                    <span className="sleej">Vehicle repairs </span>
                    <br />
                    Our dedicated team has created a vast network of
                    manufacturer-approved repairers in the UK. When you get in
                    touch with our team through{" "}
                    <strong>RAC claims phone number</strong>, we will organise
                    complete repairs of your vehicle to restore it to its
                    original condition.
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-12">
              <div className="claim" id="blue">
                <ul>
                  <li>
                    <span className="sleej">Vehicle storage </span> <br />
                    Worried about the safety of your damaged vehicle? Fret not.
                    We provide safe vehicle storage facilities to protect and
                    secure your damaged vehicle until the duration of your
                    repairs or claims settlement without any excess or upfront
                    costs. Reach out to our team on the{" "}
                    <strong>RAC claims number</strong> for expedited services.
                  </li>
                  <li>
                    <span className="sleej">Replacement vehicle </span> <br />
                    Following a no-fault car accident, it’s unfair to provide
                    you with a replacement vehicle that doesn’t match your needs
                    and specifications. When you contact us via{" "}
                    <strong>
                      car insurance claims number{" "}
                      <a href="tel:0800 772 0850">0800 772 0850</a>
                    </strong>
                    , we will ensure that while your vehicle is off the road,
                    you have a comparable replacement to continue your routine
                    activities.
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-12">
              <div className="claim">
                <ul>
                  <li>
                    <span className="sleej">Personal Injury claims </span>
                    <br />
                    We have a panel of experienced personal injury claim
                    solicitors who have handled thousands of cases. So, we will
                    make sure that you get the best legal assistance to get
                    compensation for your injuries and damages.
                  </li>
                  <li>
                    <span className="sleej">Claims management </span> <br />
                    Do you find the <strong>RAC insurance claims</strong>{" "}
                    process stressful? Call us at{" "}
                    <strong>RAC car insurance claims number</strong> to get
                    full-fledged claims assistance throughout the process.
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="yellowcta">
        <div className="container">
          <div className="row">
            <div className="cta2">
              <div className="col-xxl-8 col-xl-8 col-lg-8 col-12">
                <div className="legal">
                  <h3 className="experienced">
                    Got more questions?{" "}
                    <span className="light">
                      Contact our no-fault claim specialists{" "}
                    </span>
                  </h3>
                  <p className="professionals">
                    Contact us today at{" "}
                    <strong>
                      <a href="tel:0800 772 0850">0800 772 0850</a>
                    </strong>{" "}
                    to discuss your accident management needs and get the best
                    guidance for your <strong>RAC accident claim</strong>.
                  </p>
                </div>
              </div>
              <div className="col-xxl-4 col-xl-4 col-lg-4 col-12">
              <div class="process">
                  <a class="tf-button style-1 phone" href="tel:0800 772 0850">
                    Get in touch<span class="icon-keyboard_arrow_right"></span>
                  </a>
                </div>
                <div class="process">
                    <a className="tf-button style-1 desktop"  onClick={handleScrollToSidebar}>
                        Get in touch<span className="icon-keyboard_arrow_right"></span>
                    </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <search className="updatedform">
        <div className="container">
          <div className="row text-center">
            <div className="col-xxl-12 col-xl-12 col-md-12 col-12">
              <h2 className="whiteheading">
                What makes us your
                <span className="light"> trusted claim handler?</span>
              </h2>
              <p className="sustained">
                If you're involved in a car accident, it’s challenging to handle
                the process on your own. As your trusted partners, we offer
                customised solutions for no-fault car accident claims.
              </p>
            </div>
          </div>
          <div className="row">
            <div className="col-xxl-4 col-xl-4 col-lg-6 col-md-12 col-12">
              <div className="Claimant">
                <h5>Instant vehicle replacement </h5>
                <p className="collision">
                  We’ll ensure you’re never left without a vehicle after a car
                  accident. Get in touch with us via{" "}
                  <strong>RAC claims phone number</strong>, and we’ll provide
                  you with a like-for-like replacement vehicle until your
                  damaged vehicle gets repaired.
                </p>
                <h5>Expert advisors </h5>
                <p className="collision">
                  Our expert legal advisors guide you to stay calm after a road
                  traffic accident and manage your{" "}
                  <strong>RAC insurance claims</strong> thoughtfully to protect
                  your insurance rights.
                </p>
                <h5>No cost </h5>
                <p className="collision">
                  With our accident management company, you get an opportunity
                  to add additional perks, including vehicle repair, recovery
                  and storage, to your claims bucket at no extra cost.
                </p>
              </div>
            </div>
            <div className="col-xxl-4 col-xl-4 col-lg-6 col-md-12 col-12">
              <div className="Claimant">
                <h5>Free courtesy car</h5>
                <p className="collision">
                  Call our team on the RAC claims number for a quick replacement
                  vehicle after a no-fault car accident. We will deliver you a
                  replacement vehicle without putting you in a long wait.
                </p>
                <h5>Quick vehicle repairs </h5>
                <p className="collision">
                  With us, you can trust that your damaged vehicle will be
                  restored to its original condition in the shortest amount of
                  time.
                </p>
                <h5>Dedicated claims management</h5>
                <p className="collision">
                  Our experienced solicitors and dedicated claims management
                  team work with you to settle your{" "}
                  <strong>RAC car insurance claim</strong> quickly and recover
                  all the losses and costs from the other party’s insurer.
                </p>
              </div>
            </div>
            <div className="col-xxl-4 col-xl-4 col-lg-12 col-md-12 col-12">
              <div className="Claimant">
                <h5>Manufacturer-approved parts & paints </h5>
                <p className="collision">
                  Our repair network comprises manufacturer-approved repairers
                  who use genuine parts and paints to repair your damaged
                  vehicle.
                </p>
                <h5>Nationwide coverage </h5>
                <p className="collision">
                  Our team operates nationwide, offering a comprehensive range
                  of accident management solutions to non-faulty drivers
                  throughout the UK.
                </p>
                <h5>Zero waiting time </h5>
                <p className="collision">
                  We will never let you suffer alone. With our rapid response
                  time, you can be confident that emergency help is always
                  within your reach.
                </p>
                <h5>No win, no fee </h5>
                <p className="collision">
                  We work on a no-win, no-fee basis. So, you can pursue your
                  claim with us without any financial risk.
                </p>
              </div>
            </div>
          </div>
        </div>
      </search>

      <section className="Accident-claim">
        <div className="container">
          <div className="row">
            <div className="needed">
              <h2 className="trusted-title">
                How to maximise your
                <br />
                <span className="light"> RAC accident claim value </span>
              </h2>
              <p className="complexities">
                Have you been involved in a car accident and wondering what to
                do next for a fair settlement? Don’t stress. Learn how to deal
                with the consequences of a road traffic accident and take the
                correct steps to make a successful{" "}
                <strong>RAC car accident claim</strong>.
              </p>
            </div>
            <hr />
          </div>
          <div className="row" id="incdes">
            <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-12">
              <div className="Preparing">
                <ul>
                  <li>
                    <div className="seemingly">
                      <h5 className="Seek">
                        Stop the car and examine yourself{" "}
                      </h5>
                      <p>
                        The first thing you need to do after a car accident is
                        to park your car at a safe place and turn off the
                        engine. Check if you or any of your passengers have
                        sustained any injuries.
                      </p>
                    </div>
                  </li>
                  <li>
                    <div className="seemingly">
                      <h5 className="Seek">
                        Report the accident to the police{" "}
                      </h5>
                      <p>
                        Report the collision to the police as soon as possible.
                        The police report can provide crucial information and
                        evidence for your{" "}
                        <strong>RAC insurance accident claim</strong>.
                      </p>
                    </div>
                  </li>
                  <li>
                    <div className="seemingly">
                      <h5 className="Seek">See a doctor </h5>
                      <p>
                        Even if you haven’t suffered any injuries, visit a
                        doctor to get yourself examined for any internal
                        injuries. A doctor’s diagnosis is vital to claim
                        compensation for injuries.
                      </p>
                    </div>
                  </li>
                  <li>
                    <div className="seemingly">
                      <h5 className="Seek">
                        Get photos and videos of the accident scene
                      </h5>
                      <p>
                        The more evidence you have, the greater the chances of a
                        favourable outcome for your{" "}
                        <strong>RAC insurance claim</strong>. So, make sure to
                        take pictures and videos of the accident scene to make
                        your claim stronger.
                      </p>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
            <div
              className="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-12"
              id="dualcardiv"
            >
              <img src={dualcar} alt="" className="dualcar" />
            </div>
            <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-12">
              <div className="Preparing">
                <ul>
                  <li>
                    <div className="seemingly">
                      <h5 className="Seek">
                        Exchange details at the accident scene{" "}
                      </h5>
                      <p>
                        Exchange details with the other parties involved in the
                        accident, including the other driver, passengers and
                        eyewitnesses.
                      </p>
                    </div>
                  </li>
                  <li>
                    <div className="seemingly">
                      <h5 className="Seek">
                        Don’t accept an early settlement offer{" "}
                      </h5>
                      <p>
                        The other party’s insurer may pressurise you to accept
                        the first settlement offer, which often falls short of
                        what you deserve. Don’t jump at an early settlement
                        offer, as it may not provide fair compensation for your
                        losses.
                      </p>
                    </div>
                  </li>
                  <li>
                    <div className="seemingly">
                      <h5 className="Seek">Contact a no-fault claim expert </h5>
                      <p>
                        Call us on the{" "}
                        <strong>RAC car insurance claims number</strong> to talk
                        to our experienced no-fault claim experts and know your
                        rights and options to file a claim.
                      </p>
                    </div>
                  </li>
                  <li>
                    <div className="seemingly">
                      <h5 className="Seek">Start your claim </h5>
                      <p>
                        Get in touch with our team via{" "}
                        <strong>RAC claims phone number</strong> to embark on a
                        hassle-free claim journey. We will initiate a claim on
                        your behalf, ensuring swift and efficient resolution.
                      </p>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="whyus">
        <div className="container">
          <div className="row">
            <div className="col-xxl-12 col-md-12">
              <div className="why">
                <h2 className="choose">
                  Make your claim in
                  <span className="light">
                    <br /> 4 simple steps
                  </span>
                </h2>
                <p className="complexities">
                  Our straightforward claims process strives to provide a quick
                  resolution after a no-fault car accident. Follow our simple
                  steps to start your <strong>RAC car insurance claims</strong>{" "}
                  in no time.
                </p>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-xxl-3 col-xl-6 col-lg-6 col-md-6 col-12">
              <div className="box-service">
                <img src={check} className="check" alt="" />
                <h4>Get in touch</h4>
                <p>
                  Start your claim by calling our dedicated team on the{" "}
                  <strong>
                    claims phone number{" "}
                    <a href="tel:0800 772 0850">0800 772 0850</a>
                  </strong>{" "}
                  to discuss your claim.
                </p>
              </div>
            </div>
            <div className="col-xxl-3 col-xl-6 col-lg-6 col-md-6 col-12">
              <div className="box-service">
                <img src={check} className="check" alt="" />
                <h4>Share details </h4>
                <p>
                  Provide the accident details, including the date, time,
                  location, weather conditions, and the reason behind the
                  collision.
                </p>
              </div>
            </div>
            <div className="col-xxl-3 col-xl-6 col-lg-6 col-md-6 col-12">
              <div className="box-service">
                <img src={check} className="check" alt="" />
                <h4>Claim examination </h4>
                <p>
                  Our team will evaluate your{" "}
                  <strong>RAC car insurance claim</strong> and check whether
                  it's valid or not.
                </p>
              </div>
            </div>
            <div className="col-xxl-3 col-xl-6 col-lg-6 col-md-6 col-12">
              <div className="box-service">
                <img src={check} className="check" alt="" />
                <h4>Receive compensation </h4>
                <p>
                  After your claim examination, you’ll get the deserving
                  compensation for your losses and injuries.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="faqs">
        <div className="container">
          <div className="row">
            <div className="col-xxl-12 col-xl-12 col-md-12 col-12">
              <div className="fq">
                <h2>
                  {" "}
                  Frequently Asked{" "}
                  <span className="light">Questions (FAQ)</span>
                </h2>
                <p className="complexities">
                  Got questions about the RAC accident claim? We have them
                  answered here.{" "}
                </p>
              </div>
              <div className="allfaq">
                <Accordion defaultActiveKey="0">
                  <Accordion.Item eventKey="0">
                    <Accordion.Header>
                      Do I get a replacement car after I’ve made a claim?
                    </Accordion.Header>
                    <Accordion.Body>
                      Yes. Call our team on the{" "}
                      <strong>RAC claims number</strong>, and we’ll provide you
                      with a like-for-like replacement car to get you back on
                      the road again.
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="1">
                    <Accordion.Header>
                      Do I have to pay my insurance excess if I make a claim
                      through your services?
                    </Accordion.Header>
                    <Accordion.Body>
                      No. This is one of the benefits you enjoy when making your
                      claim through us. We claim directly from the at-fault
                      driver’s insurer. Therefore, your own insurance policy
                      won’t be affected, and you do not have to pay your excess.
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="2">
                    <Accordion.Header>
                      How long will my RAC car accident claim take to settle?
                    </Accordion.Header>
                    <Accordion.Body>
                      The time limit for the settlement of your claim depends on
                      the complexity of your case. Straightforward claims may
                      get settled within a few weeks, but complex claims may
                      take a little longer.
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="3">
                    <Accordion.Header>
                      Can I choose the accident repair centre of my own choice?
                    </Accordion.Header>
                    <Accordion.Body>
                      Yes, you can choose your vehicle repairer, but we
                      recommend you pick a manufacturer-approved repair centre
                      to ensure guaranteed repairs. However, it’s entirely your
                      choice from whom you want to get your vehicle repaired.
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="4">
                    <Accordion.Header>
                      For how long can I keep the replacement vehicle?
                    </Accordion.Header>
                    <Accordion.Body>
                      You can keep the replacement vehicle until your vehicle
                      gets repaired or your <strong>RAC insurance claim</strong>{" "}
                      gets settled.
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="5">
                    <Accordion.Header>
                      Are the vehicle repairs guaranteed?
                    </Accordion.Header>
                    <Accordion.Body>
                      We recommend that you choose a manufacturer-approved
                      repairer for your vehicle repairs. This will ensure your
                      vehicle repairs are done to the highest standards, using
                      genuine parts.
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="6">
                    <Accordion.Header>
                      I have been contacted by the other party’s insurer to
                      settle the claim. What should I do?
                    </Accordion.Header>
                    <Accordion.Body>
                      If you choose to make your claim with us, you can direct
                      the at-fault party’s insurer to us with their queries. We
                      will negotiate with them on your behalf.
                    </Accordion.Body>
                  </Accordion.Item>
                </Accordion>
              </div>
            </div>
          </div>
        </div>
      </section>

      <Footer />
    </>
  );
};

export default RACcarinsurance;
